import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ImageBlock from 'blocks/ImageBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import { PhotosStoreType } from 'screens/photos/PhotosStore';
import { assert } from 'utils/assert';
import { ROUTE_ALBUMS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import TextElement from 'components/TextElement/TextElement';

interface Props extends HistoryProps {
  photosStore: PhotosStoreType;
}

interface State {
  confirmDelete: boolean;
}

@inject('photosStore', 'applicationStore')
@observer
class PhotoDetail extends React.Component<Props, State> {
  state = { confirmDelete: false };

  componentDidMount() {
    this.loadPhoto();
  }

  loadPhoto() {
    const id = this.getId();
    this.props.photosStore.getPhoto(id);
  }

  getId() {
    const id = idFromMatch(this.props.match);
    assert(id);
    return id;
  }

  confirmDelete() {
    this.setState({ confirmDelete: true });
  }

  async performDelete() {
    const { photosStore } = this.props;

    try {
      await photosStore.removePhoto(this.getId());
    } catch (e) {
      this.finishDelete();
      return;
    }
    this.props.history.push(this.getBackUrl());
  }

  finishDelete() {
    this.setState({ confirmDelete: false });
  }

  getBackUrl() {
    const chapterId = chapterIdFromMatch(this.props.match);
    assert(chapterId);
    return ROUTE_ALBUMS + '/' + chapterId;
  }

  render() {
    const {
      photoItem,
      isPhotoItemLoading,
      isPhotoItemLoadingError,
      isPhotoItemUpdateError
    } = this.props.photosStore;
    const { confirmDelete } = this.state;
    const authorName = photoItem?.author?.name;

    return (
      <>
        <TopActionBlock>
          <BackLink to={this.getBackUrl()} />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Photo" />} text={authorName && (<><IconComponent icon="USER" fill="GRAY400" />
            <FormattedMessage
              id="By name"
              values={{
                name: authorName
              }}
            /></>)} />

          {isPhotoItemLoading && <LoadingOverlayComponent />}
          {isPhotoItemLoadingError && (
            <GenericErrorComponent
              onRetryClick={() => {
                this.loadPhoto();
              }}
            />
          )}
          {photoItem && photoItem.photo ? (
            <ImageBlock height={20.875} background="GRAY950" fullWidth={true}>
              <img src={photoItem.photo.shrinked} alt="" />
            </ImageBlock>
          ) : (
            <>
              <TextElement>
                <IconComponent icon="HOURGLASS" fill="PRIMARY" />
                <FormattedMessage id="Processing" />
              </TextElement>
            </>
          )}
          {isPhotoItemUpdateError && (
            <GenericErrorComponent
              onRetryClick={() => {
                this.confirmDelete();
              }}
            />
          )}
          <DeleteButton onClick={() => this.confirmDelete()} />
        </PageStackBlock>
        {confirmDelete && (
          <ConfirmBoxComponent
            header={<FormattedMessage id="confirm delete header" />}
            text={
              <FormattedMessage
                id="confirm delete item"
                values={{
                  item: <FormattedMessage id="This photo" />
                }}
              />
            }
            confirmText={<FormattedMessage id="Remove" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => this.performDelete()}
            onAbort={() => this.finishDelete()}
            confirmColor="RED"
          />
        )}
      </>
    );
  }
}

export default PhotoDetail;
