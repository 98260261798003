import React, { useEffect } from 'react';
import ItemStackBlock from 'blocks/ItemStackBlock';
import { FormType } from 'utils/hooks/useForm';
import BillingAddress from './BillingAddress';
import DeliveryAddress from './DeliveryAddress';
import ButtonBlock from 'blocks/ButtonBlock';
import { FormattedMessage } from 'react-intl';

interface CheckoutAddressProps {
  form: FormType;
  updateAddress: () => void;
  setBilling?: boolean;
}

const AddressForm = ({
  form,
  updateAddress,
  setBilling
}: CheckoutAddressProps) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  },        []);

  let renderForm = (
    <DeliveryAddress form={form} />
  );

  // Only display billing Form. Edit Checkout after review
  if (setBilling) {
    renderForm = (
      <BillingAddress
        form={form}
      />
    );
  }

  // Display both forms either after checkmark set or billing address was set by setBilling
  if (form.values.billing_address_set && !setBilling) {
    renderForm = (
      <>
        <DeliveryAddress form={form} />
        <BillingAddress
          form={form}
        />
      </>
    );
  }

  // Default show delivery address from and button
  return (
    <ItemStackBlock gap="M">
      {renderForm}
      <ButtonBlock background="PRIMARY" onClick={() => updateAddress()}>
        <FormattedMessage id="Next" />
      </ButtonBlock>
    </ItemStackBlock>
  );
};
export default AddressForm;
