import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_DASHBOARD } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import SharingComponent from 'components/SharingComponent';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';

interface OnboardingInviteScreenProps {
  applicationStore: ApplicationStoreType;
}

interface OnboardingInviteScreenState {
  didInteract?: boolean;
}

@inject('applicationStore')
@observer
export class OnboardingInviteScreen extends React.Component<
OnboardingInviteScreenProps & HistoryProps,
OnboardingInviteScreenState
> {
  state: OnboardingInviteScreenState = {};

  componentDidMount() {
    const { applicationStore } = this.props;

    if (!applicationStore.isManager || !applicationStore.book?.invitation_url) {
      this.props.history.replace(ROUTE_DASHBOARD);
    }
  }

  render() {
    const invitation_url = this.props.applicationStore.book?.invitation_url;

    if (!invitation_url) {
      return null;
    }

    return (
      <>
        <SignupHeaderComponent progressStatus={75} />

        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Invite students" />} text={<FormattedMessage id="onboarding invite text" />} />

          <SharingComponent
            label="personal invite link"
            buttonOutside={true}
            modalHeader="invite link"
            message="Nuggit invite text"
            buttonColor="WHATSAPP"
            url={invitation_url}
            onDidInteract={() => this.setState({ didInteract: true })}
          />

          <Divider />

          {this.state.didInteract ? (
            <ButtonBlock
              background="PRIMARY"
              color="WHITE"
              onClick={() => this.props.history.push(ROUTE_DASHBOARD)}
            >
              <FormattedMessage id="Next" />
            </ButtonBlock>
          ) : (
            <ButtonBlock
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              onClick={() => this.props.history.push(ROUTE_DASHBOARD)}
            >
              <FormattedMessage id="Later" />
            </ButtonBlock>
          )}
        </PageStackBlock>
      </>
    );
  }
}

export default OnboardingInviteScreen;
