import { intl } from 'i18n';
import React from 'react';

import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { ROUTE_MOTTOS_ORG } from 'utils/constants/routes';

export const MottoIdeasScreen = () => (
  <SupportTicketScreen
    subject="idealist_mottos"
    backTo={ROUTE_MOTTOS_ORG}
    organizerOnly={true}
    message={intl.formatMessage({ id: 'zap_ideas_mottos'})}
  />
);

export default MottoIdeasScreen;
