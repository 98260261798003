import React, { ReactNode } from 'react';
import ButtonBlock from 'blocks/ButtonBlock';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';

interface MiniCardProps {
  background?: keyof typeof COLORS;
  borderColor?: keyof typeof COLORS;
  centered?: boolean;
  sideToSide?: boolean;
}

export const MiniCardPadding = styled.div`
  flex-direction: column;
  display: flex;
  padding: 1rem;
  flex-grow: 1;

  & > ${ButtonBlock} {
    margin-top: auto;
  }

  > * + * {
    margin-top: 0.75rem;
  }
`;

export const MiniCardBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;

  > * + *,
  > * + ${TextElement} {
    margin-top: 0.75rem;
  }
`;

const MiniCardFooter = styled.div`
  background: ${COLORS.GRAY950};
  padding: 0.6875rem;
  margin-top: auto;
`;

export const MiniCardStatus = (
  props: { children: ReactNode }
) => (
  <MiniCardFooter>
    <Paragraph size="S" color="BLACK"  children={props.children}/>
  </MiniCardFooter>
);

export const MiniCardImage = styled.img`
  display: block;
  height: 3.8125rem;
  width: 100%;
  border-radius: 0.5625rem 0.5625rem 0 0;
  overflow: hidden;
  object-fit: cover;
  flex-shrink: 0;
`;

export const MiniCard = styled.div<MiniCardProps>`
  display: flex;
  flex-direction: column;
  min-height: 13.75rem;
  overflow: hidden;
  border-radius: 0.625rem;
  line-height: 1;
  border: 1px solid;
  border-color: ${(props) =>
    props.borderColor ? COLORS[props.borderColor] : COLORS.GRAY800};
  background: ${(props) =>
    props.background ? COLORS[props.background] : COLORS.WHITE};
  color: ${(props) => (props.background ? COLORS.WHITE : 'inherit')};
  ${(props) =>
    props.centered &&
    `
    & > ${MiniCardPadding} {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  `};
  ${(props) =>
    props.sideToSide &&
    `
    grid-column-start: 1;
    grid-column-end: -1;
    min-height: 100%;

    & > ${MiniCardPadding} {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      > * + * {
        margin-top: 0;
      }

      & > ${MiniCardBody} {
        flex-grow: 999;
        align-self: flex-start;
      }
      & > ${ButtonBlock} {
        flex-shrink: 1;
        margin: 0 auto;
        flex-basis: 7.6875rem;
      }
    }
    `};
`;
