import { BaseTextileDesignSetting } from 'api/textile_deals/fetchTextileDesignSetting';
import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import { EveryCard, EveryCardBody, EveryCardHeadline, EveryCardPadding } from 'blocks/EveryCard/EveryCard';
import TextBlock from 'blocks/ExpertsBlock/TextBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import NakedLink from 'elements/NakedLink';
import UppercaseHeading from 'elements/UppercaseHeading';
import { intl } from 'i18n';
import { useTeacherListQuery } from 'queries/textile_deals/useTeacherListQueries';
import { useOptimisticUpdateTextileDesignSettingQuery, useTextileDesignSettingQuery } from 'queries/textile_deals/useTextileDesignSettingQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const TeacherOverviewScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const flashMessageContext = useContext(FlashMessageContext);
  const teacherList = useTeacherListQuery(textileOrderId);
  const settings = useTextileDesignSettingQuery(textileOrderId);
  const updateSettings = useOptimisticUpdateTextileDesignSettingQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  if (!flashMessageContext) {
    throw Error('Component must be used within HasFlashMessageProvider')
  }

  useEffect(() => {
    const data = settings.data;

    if (data) {
      props.form.setField('show_teachers', data.show_teachers);
    }
    accessContext.checkSectionAccess('design');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, settings.data]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink
            to={textileOrderRoute(props.match, '/textile_design/back')}
          />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (teacherList.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => teacherList.refetch()} />
    );
  }

  if (settings.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => settings.refetch()} />
    );
  }

  if (
    teacherList.isLoading ||
    !teacherList.data ||
    settings.isLoading ||
    !settings.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const setShowTeachers = (show: boolean) => {
    if (!textileOrderId) {
      return;
    }

    const data = settings.data;

    const patch: BaseTextileDesignSetting = { ...data, show_teachers: show };

    updateSettings.mutate({ data: patch, textileOrderId}, {
      onSuccess: () => {
        props.form.setField("show_teachers", show);
        flashMessageContext.triggerFlashMessage(
          intl.formatMessage({ id: 'Saved' }),
          'success'
        );
      }
    })
  }

  const renderOverview = () => {
    const { form } = props;
    const teachers = teacherList.data;

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="textile virtual teacher" />}
          text={<FormattedMessage id="textile virtual teacher info" />}
        />

        <FormStackBlock>
          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="show_teachers"
              {...form.bindCheckbox('show_teachers')}
              onClick={(e: any) => setShowTeachers(e.target.value)}
            />
            <CustomCheckboxBlock.LabelElement htmlFor="show_teachers">
              <FormattedMessage id="back show teachers" />
            </CustomCheckboxBlock.LabelElement>
            {form.values.show_teachers ? (
              <TextElement id="">
                <FormattedMessage id="back show teachers active" />
              </TextElement>
            ) : (
              <TextElement>
                <FormattedMessage id="back show teachers inactive" />
              </TextElement>
            )}
          </CustomCheckboxBlock>
        </FormStackBlock>

        <NakedLink
          to={textileOrderRoute(props.match, '/management/teachers/add')}
        >
          <ButtonBlock background="PRIMARY">
            <FormattedMessage id="Add teacher" />
          </ButtonBlock>
        </NakedLink>

        <Divider />

        {teachers && teachers.length !== 0 ? (
          <>
            <UppercaseHeading>
              <FormattedMessage id="textile teacher" />
            </UppercaseHeading>
            <ListStackBlock>
              {teachers.map((teacher) => (
                <NakedLink
                  to={textileOrderRoute(
                    props.match,
                    '/management/teachers/edit/' + teacher.id
                  )}
                >
                  <EveryCard>
                    <EveryCardPadding>
                      <EveryCardBody>
                        <EveryCardHeadline>{teacher.name}</EveryCardHeadline>
                      </EveryCardBody>
                    </EveryCardPadding>
                  </EveryCard>
                </NakedLink>
              ))}
            </ListStackBlock>
          </>
        ) : (
          <TextBlock>
            <ItemStackBlock gap="S">
              <Paragraph size="L" color="TEXT_LIGHT">
                <FormattedMessage id="No candidates added yet" />
              </Paragraph>
              <Paragraph color="TEXT_LIGHT" size="S">
                <FormattedMessage id="textile teacher add info" />
              </Paragraph>
            </ItemStackBlock>
          </TextBlock>
        )}
      </>
    );
  }

  return renderOverview();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <TeacherOverviewScreen {...props} form={form} />;
};
