import styled, { AnyStyledComponent } from 'styled-components';

import COLORS from 'utils/constants/colors';
import Content from './Content';
import Item from './Item';

interface TabBarBlockProps {
  small?: boolean;
}
interface TabBarBlockElements {
  Content?: any;
  Item?: any;
}

const TabBarBlock: AnyStyledComponent & TabBarBlockElements = styled.div<
  TabBarBlockProps
>`
  align-items: flex-start;
  display: flex;

  > * {
    border-bottom: 3px solid;
    padding-bottom: 0.625em;
    font-size: 1em;
    line-height: 1.35714;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0.03125em;
    color: ${COLORS.SECONDARY_DARK};
  }

  > * + * {
    margin-left: 1.25em;
  }

  a {
    opacity: 0.5;
    border-color: transparent;

    &:hover {
      opacity: 1;
      border-color: inherit;
    }
  }

  ${props =>
    props.small &&
    `
    > * {
      font-size: 0.75em;
      border: none;
      font-weight: 500;
    }

    > *:not(a) {
      color: ${COLORS.PRIMARY};
    }
  `};
`;

TabBarBlock.Content = Content;
TabBarBlock.Item = Item;

export default TabBarBlock;
