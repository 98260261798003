import styled from 'styled-components';

import COLORS from 'utils/constants/colors';
import SPACES from 'utils/constants/spaces';

const TopActionBlock = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  margin-top: ${SPACES.S};
  margin-bottom: ${SPACES.XL};

  a {
    font-size: 0.875em;
    font-weight: 600;
    letter-spacing: 0;
    color: ${COLORS.GRAY10};
  }
`;

export default TopActionBlock;
