import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import LogoutButton from 'components/LogoutButton/LogoutButton';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import CancelLink from 'domain/Links/CancelLink';
import SelectGroup from 'domain/SelectGroup';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import NakedBlockButton from 'elements/NakedBlockButton';
import NakedLink from 'elements/NakedLink';
import { intl } from 'i18n';
import { AccountStoreType } from 'models/AccountStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfileModelType } from 'models/ProfileModel';
import { ProfilesStoreType } from 'models/ProfilesStore';
import COLORS from 'utils/constants/colors';
import ROLES, { UPDATE_ROLES } from 'utils/constants/roles';
import { ROUTE_ACCOUNT_MOBILE_NUMBER, ROUTE_DASHBOARD, ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch, isPush } from 'utils/history';
import useForm, {
  FormType,
  FormValues,
  handleFormError
} from 'utils/hooks/useForm';

export interface PublicStudentDetailScreenProps {
  mode?: 'name' | 'number' | 'confirm_number' | 'group' | 'print';
}

export interface StudentDetailScreenProps
  extends PublicStudentDetailScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
  accountStore: AccountStoreType;
  form: FormType;
}

@inject('applicationStore', 'profilesStore', 'accountStore')
@observer
class StudentDetailScreen extends React.Component<
StudentDetailScreenProps & HistoryProps
> {
  componentDidMount() {
    if (!this.props.applicationStore.isOrganizer) {
      this.props.history.replace(ROUTE_DASHBOARD);
    }

    const id = idFromMatch(this.props.match);
    if (id) {
      this.loadStudent(id);
    } else {
      // new student mode
      this.props.profilesStore.clearCurrentItem();
      this.props.form.reset();
    }
  }

  componentDidUpdate(prevProps: StudentDetailScreenProps & HistoryProps) {
    if (!this.props.applicationStore.isOrganizer) {
      this.props.history.replace(ROUTE_DASHBOARD);
    }

    const newId = idFromMatch(this.props.match);
    const prevId = idFromMatch(prevProps.match);

    if (newId) {
      if (prevId !== newId) {
        // ID in URL has changed, we need to fetch new item
        this.loadStudent(newId);
      }
    } else {
      if (prevId) {
        // new mode
        this.props.profilesStore.clearCurrentItem();
        this.props.form.reset();
      }
    }
  }

  async loadStudent(id: number) {
    const { profilesStore, form } = this.props;

    form.reset();

    const checkIfAlreadyInStore = !isPush(this.props.history);
    if (
      checkIfAlreadyInStore &&
      profilesStore.item &&
      profilesStore.item.id === id
    ) {
      this.fillForm(profilesStore.item);
      return;
    }

    const item = await profilesStore.getStudent(id, true);

    if (item) {
      this.fillForm(item);
    }

    return item;
  }

  fillForm(item?: ProfileModelType) {
    const { form } = this.props;

    if (!item) {
      form.reset();
      return;
    }

    form.setField('first_name', item.first_name || '');
    form.setField('last_name', item.last_name || '');
    form.setField('group_id', item.group?.id.toString() || '');

    form.setField('mobile_number_to_confirm', '');

    form.setField('print', item.print || false);
    form.setField('print_yearbook', item.print_yearbook || false);
  }

  isAddMode() {
    return !idFromMatch(this.props.match) ? true : false;
  }

  async handleNameSubmit() {
    const { form, profilesStore, applicationStore } = this.props;
    const { item } = profilesStore;

    if (!item) {
      return;
    }

    form.resetErrors();

    try {
      await profilesStore.updateName(
        item.id,
        form.values.first_name || '',
        form.values.last_name || ''
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }

    applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'name updated' })
    );
    this.redirectToOverview(item.id);
  }

  async handlePrintSubmit() {
    const { form, profilesStore, applicationStore } = this.props;
    const { item } = profilesStore;

    if (!item) {
      return;
    }

    form.resetErrors();

    try {
      await profilesStore.updatePrint(
        item.id,
        !!form.values.print,
        !!form.values.print_yearbook
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }

    applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'print setting updated' })
    );
    this.redirectToOverview(item.id);
  }

  async handleNumberSubmit() {
    const { form, accountStore, applicationStore } = this.props;
    const { currentAccount } = applicationStore;

    this.finishConfirm();

    if (!currentAccount) {
      return;
    }

    form.resetErrors();

    try {
      await accountStore.updatePhoneNumber(
        currentAccount.id,
        form.values.mobile_number_to_confirm
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }

    this.props.history.push(
      ROUTE_ACCOUNT_MOBILE_NUMBER + '/confirm'
    );
  }

  async handleAddSubmit() {
    const { form, profilesStore, applicationStore } = this.props;
    const { first_name, last_name, mobile_number, group_id } = form.values;

    form.resetErrors();

    try {
      const item = await profilesStore.createManuallyCreatedStudent(
        first_name || '',
        last_name || '',
        mobile_number,
        group_id
      );

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'student created' })
      );
      if (item) {
        this.redirectToOverview(item.id);
      } else {
        this.props.history.push(ROUTE_SETTINGS + '/students');
      }
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }
  }

  async updateGroup() {
    const { form, profilesStore, applicationStore } = this.props;
    const { item } = profilesStore;

    if (!item) {
      return;
    }

    try {
      await profilesStore.updateProfileGroup(
        item.id,
        parseInt(form.values.group_id)
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }

    applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'group updated' })
    );
    this.redirectToOverview(item.id);
  }

  async updateRole(newRole: keyof typeof UPDATE_ROLES) {
    const { profilesStore, applicationStore } = this.props;
    const { item } = profilesStore;

    this.finishConfirm();

    if (!item) {
      return;
    }

    try {
      await profilesStore.updateRole(item.id, newRole);

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'role changed' })
      );
    } catch (error: any) {
      let message = '';

      if (error && error.response && error.response.status === 422) {
        // check for error message
        if (
          error.body &&
          error.body.errors &&
          error.body.errors.role &&
          error.body.errors.role.length
        ) {
          message = intl.formatMessage(
            { id: 'role change error message' },
            {
              message: error.body.errors.role[0]
            }
          );
        }
      }

      applicationStore.setFlashMessage(
        intl.formatMessage(
          { id: 'role change error' },
          {
            message
          }
        ),
        'error'
      );
    }
  }

  askForDelete() {
    const id = idFromMatch(this.props.match);
    if (id) {
      this.props.history.push(
        ROUTE_SETTINGS + '/students/' + id + '/delete_student'
      );
    }
  }

  makeOrganizer() {
    this.updateRole('ORGANIZER');
  }

  revokeOrganizer() {
    this.updateRole('STUDENT');
  }

  setConfirm(topic: string) {
    this.props.form.setConfirm(topic);
  }

  finishConfirm() {
    this.props.form.setConfirm(false);
  }

  redirectToOverview(id?: number) {
    if (!id) {
      return;
    }

    this.props.history.push(ROUTE_SETTINGS + '/students/' + id);
  }

  renderPage(
    content: ReactNode,
    subPage: boolean = false,
    useBackLink: boolean = false
  ) {
    const { item } = this.props.profilesStore;

    const backToDashboard = !subPage && this.props.location.query?.my === 'yes';

    let route = '';
    if (!backToDashboard && subPage && item && item.id) {
      route = '/' + item.id;
    }

    return (
      <>
        <TopActionBlock>
          {backToDashboard ? (
            <BackToDashboardLink />
          ) : useBackLink ? (
            <BackLink
              to={ROUTE_SETTINGS + '/students' + route}
              messageId="Show manage students"
            />
          ) : (
            <CancelLink to={ROUTE_SETTINGS + '/students' + route} />
          )}
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  renderConfirm() {
    let id,
      headerId = 'confirm change permissions header',
      confirmText = 'Save',
      abortText = 'Cancel',
      confirmColor: keyof typeof COLORS = 'WHATSAPP',
      abortColor: keyof typeof COLORS = 'SECONDARY_DARK',
      onConfirm = () => { };

    const confirm = this.props.form.confirm;
    switch (confirm) {
      case 'organizer':
        id = 'confirm make organizer';
        headerId = 'confirm make organizer header';
        confirmText = 'give rights';
        onConfirm = () => this.makeOrganizer();
        break;
      case 'revoke_organizer':
        id = 'confirm revoke organizer';
        headerId = 'confirm revoke organizer header';
        confirmColor = 'RED';
        confirmText = 'revoke rights';
        onConfirm = () => this.revokeOrganizer();
        break;
      case 'group':
        id = 'Change group info';
        headerId = 'Change group header';
        confirmColor = 'RED';
        confirmText = 'Change group';
        onConfirm = () => this.updateGroup();
        break;
      case 'account_delete':
        id = 'Account delete info';
        headerId = 'confirm delete header account';
        onConfirm = () => this.askForDelete();
        confirmText = 'Remove';
        confirmColor = 'RED';
        break;
    }

    if (!id) {
      return null;
    }

    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id={headerId} />}
        text={<FormattedMessage id={id} />}
        confirmText={<FormattedMessage id={confirmText} />}
        confirmColor={confirmColor}
        abortText={<FormattedMessage id={abortText} />}
        onConfirm={onConfirm}
        onAbort={() => this.finishConfirm()}
        abortColor={abortColor}
      />
    );
  }

  renderChangeRole(profile: ProfileModelType) {
    if (!profile.mobile_number) {
      // only students with mobile number can become organizers
      return null;
    }

    if (profile.role !== ROLES.STUDENT) {
      return (
        <ButtonListBlockItem>
          <NakedBlockButton onClick={() => this.setConfirm('revoke_organizer')}>
            <ButtonListBlockBody>
              <FormattedMessage id="Make student" />
            </ButtonListBlockBody>
          </NakedBlockButton>
        </ButtonListBlockItem>
      );
    } else {
      return (
        <ButtonListBlockItem>
          <NakedBlockButton onClick={() => this.setConfirm('organizer')}>
            <ButtonListBlockBody>
              <FormattedMessage id="Make organizer" />
            </ButtonListBlockBody>
          </NakedBlockButton>
        </ButtonListBlockItem>
      );
    }
  }

  renderOverview() {
    const { profilesStore, applicationStore } = this.props;
    const { item } = profilesStore;

    if (!item) {
      // satisfy TS
      return this.renderPage(null);
    }

    const isMe = item.id === applicationStore.currentUserId;

    const route = ROUTE_SETTINGS + '/students/' + item.id;
    return this.renderPage(
      <>
        <NonGapStackBlock>
          {item.isManuallyCreated && (
            <TextElement>
              <FormattedMessage id="Virtual student" />
            </TextElement>
          )}
          {item.isOrganizer && (
            <TextElement>
              <FormattedMessage id="Organizer" />
            </TextElement>
          )}
          <PageHeader headline={item.name} text={item.group && (<><IconComponent icon="USER" />
            {item.group.name}</>)} />

        </NonGapStackBlock>

        <ListStackBlock>

          <ButtonListBlock>
            <ButtonListBlockItem>
              <NakedLink to={route + '/name'}>
                <ButtonListBlockBody>
                  {!item.name ? (
                    <FormattedMessage id="Set name" />
                  ) : (
                    <FormattedMessage id="Change name" />
                  )}
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            {!item.isManager && <>{this.renderChangeRole(item)}</>}

            {!!applicationStore.book?.groups_applied && (
              <ButtonListBlockItem>
                <NakedLink to={route + '/group'}>
                  <ButtonListBlockBody>
                    {!item.group ? (
                      <FormattedMessage id="Assign group" />
                    ) : (
                      <FormattedMessage id="Edit group" />
                    )}
                  </ButtonListBlockBody>
                </NakedLink>
              </ButtonListBlockItem>
            )}

            {!item.isManuallyCreated && (
              <ButtonListBlockItem>
                <NakedLink to={route + '/number'}>
                  <ButtonListBlockBody>
                    {!item.mobile_number ? (
                      <FormattedMessage id="Set mobile number" />
                    ) : (
                      <FormattedMessage id="Edit mobile number" />
                    )}
                  </ButtonListBlockBody>
                </NakedLink>
              </ButtonListBlockItem>
            )}

            {isMe && (
              <ButtonListBlockItem>
                <NakedLink to={ROUTE_SETTINGS + '/account/password'}>
                  <ButtonListBlockBody>
                    <FormattedMessage id="Change password" />
                  </ButtonListBlockBody>
                </NakedLink>
              </ButtonListBlockItem>
            )}

            <ButtonListBlockItem>
              <NakedLink to={route + '/print'}>
                <ButtonListBlockBody>
                  <FormattedMessage id="Print settings" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>
          </ButtonListBlock>
        </ListStackBlock>

        <Divider />

        {isMe ? (
          <LogoutButton />
        ) : (
          <DeleteButton
            messageId="Delete account"
            onClick={() => this.setConfirm('account_delete')}
          />
        )}

        {this.renderConfirm()}
      </>,
      false,
      true
    );
  }

  renderGroupView() {
    const { profilesStore, form } = this.props;
    const { item } = profilesStore;
    if (!item) {
      return null;
    }

    const isUpdateError = profilesStore.itemLoadingState === 'update_error';

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Edit group" />} text={item.name && (<>              <IconComponent icon="USER" />
          {item.name}</>)} />

        {isUpdateError && <GenericErrorComponent />}

        <SelectGroup form={form} />

        <ButtonBlock
          background="PRIMARY"
          disabled={form.values.group_id === ''}
          onClick={() => this.setConfirm('group')}
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
        {this.renderConfirm()}
      </>,
      true
    );
  }

  renderPrintView() {
    const { profilesStore, form } = this.props;
    const { item } = profilesStore;

    if (!item) {
      return null;
    }

    const isUpdateError = profilesStore.itemLoadingState === 'update_error';

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Print settings" />} text={item.name && (<> <IconComponent icon="USER" />
          {item.name}</>)} />

        <Paragraph>
          <FormattedMessage id="profile print settings info" />
        </Paragraph>

        {isUpdateError && <GenericErrorComponent />}

        <FormStackBlock>
          <NonGapStackBlock>
            <CustomCheckboxBlock>
              <CustomCheckboxBlock.CheckboxElement
                {...form.bindCheckbox('print')}
              />
              <CustomCheckboxBlock.LabelElement>
                <FormattedMessage id="Print factsheet" />
              </CustomCheckboxBlock.LabelElement>
            </CustomCheckboxBlock>

            <TextElement>
              <FormattedMessage id="print factsheet on" />
            </TextElement>
          </NonGapStackBlock>

          <NonGapStackBlock>

            <CustomCheckboxBlock>
              <CustomCheckboxBlock.CheckboxElement
                {...form.bindCheckbox('print_yearbook')}
              />
              <CustomCheckboxBlock.LabelElement>
                <FormattedMessage id="Print yearbook" />
              </CustomCheckboxBlock.LabelElement>
            </CustomCheckboxBlock>

            <TextElement>
              <FormattedMessage id="print yearbook on" />
            </TextElement>
          </NonGapStackBlock>
        </FormStackBlock>

        <ButtonBlock
          background="PRIMARY"
          onClick={() => this.handlePrintSubmit()}
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>,
      true
    );
  }

  renderNameView() {
    const { profilesStore } = this.props;
    const { item } = profilesStore;
    if (!item) {
      return null;
    }

    const isUpdateError = profilesStore.itemLoadingState === 'update_error';

    return this.renderPage(
      <>

        <PageHeader headline={<FormattedMessage id="Edit name" />} text={item.name && (<>            <IconComponent icon="USER" />
          {item.name}</>)} />

        <FormStackBlock>
          {isUpdateError && <GenericErrorComponent />}

          <TextInputComponent
            name="first_name"
            label={intl.formatMessage({ id: 'First name' })}
            {...this.props.form.bindInput('first_name')}
            autoFocus={true}
          />

          <TextInputComponent
            name="last_name"
            label={intl.formatMessage({ id: 'Last name' })}
            {...this.props.form.bindInput('last_name')}
          />

        </FormStackBlock>
        <ButtonBlock
          background="PRIMARY"
          onClick={() => this.handleNameSubmit()}
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>,
      true
    );
  }

  renderNumberConfirmView() {
    const { profilesStore, applicationStore } = this.props;
    const { item } = profilesStore;

    if (!item) {
      return null;
    }

    const isUpdateError = profilesStore.itemLoadingState === 'update_error';
    const route = ROUTE_SETTINGS + '/students/' + item.id;

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Number confirm header" />} text={<FormattedMessage id="Number confirm text" />} />

        {isUpdateError && <GenericErrorComponent />}

        {item.mobile_number_to_confirm && (
          <Headline.Medium>{item.mobile_number_to_confirm}</Headline.Medium>
        )}

        <ItemStackBlock gap="S">
          <ButtonBlock
            background="PRIMARY"
            onClick={() => {
              applicationStore.setFlashMessage(
                intl.formatMessage({ id: 'phone number updated' })
              );
              this.redirectToOverview(item.id);
            }}
          >
            <FormattedMessage id="OK" />
          </ButtonBlock>

          <Link to={route + '/number'}>
            <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK">
              <FormattedMessage id="signup change number" />
            </ButtonBlock>
          </Link>
        </ItemStackBlock>
      </>,
      true
    );
  }

  renderNumberView() {
    const { applicationStore, accountStore, profilesStore } = this.props;
    const { item } = profilesStore;
    const { currentAccount } = applicationStore;
    if (!currentAccount || !item) {
      return null;
    }

    const isUpdateError = accountStore.loadingState === 'update_error';

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="confirm edit number header" />} text={item.name && (<>                <IconComponent icon="USER" />
          {item.name}</>)} />

        <FormStackBlock>
          <TextInputComponent
            name="old_mobile_number"
            value={item.mobile_number}
            label={intl.formatMessage({ id: 'Old mobile number' })}
            readOnly={true}
            disabled={true}
          />

          <InputMessage warning={true}>
            <Paragraph>
              <FormattedMessage id="number edit info" />
            </Paragraph>
          </InputMessage>

          {isUpdateError && <GenericErrorComponent />}

          <TextInputComponent
            name="mobile_number_to_confirm"
            placeholder={intl.formatMessage({
              id: 'phone number placeholder'
            })}
            label={intl.formatMessage({ id: 'New mobile number' })}
            {...this.props.form.bindInput('mobile_number_to_confirm')}
            autoFocus={true}
            noHotjar={true}
          />
        </FormStackBlock>

        <ButtonBlock
          background="PRIMARY"
          onClick={() => this.handleNumberSubmit()}
        >
          <FormattedMessage id="Send confirm link" />
        </ButtonBlock>

      </>,
      true
    );
  }

  renderAddForm() {
    const { applicationStore, profilesStore, form } = this.props;
    const { book } = applicationStore;
    const isUpdateError = profilesStore.itemLoadingState === 'update_error';
    const showGroupSelect = book && book.hasActiveGroups

    return (
      <>
        <FormStackBlock>
          {isUpdateError && <GenericErrorComponent />}

          <TextInputComponent
            name="first_name"
            label={intl.formatMessage({ id: 'First name' })}
            {...form.bindInput('first_name')}
            autoFocus={true}
          />

          <TextInputComponent
            name="last_name"
            label={intl.formatMessage({ id: 'Last name' })}
            {...form.bindInput('last_name')}
          />

          { showGroupSelect && (
            <SelectGroup form={form}  showNoGroup={true}/>
          )}


        </FormStackBlock>
        <ButtonBlock
          background="PRIMARY"
          onClick={() => this.handleAddSubmit()}
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  }

  renderAddView() {
    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Add student" />} text={<FormattedMessage id="add student info" />} />

        {this.renderAddForm()}
      </>,
      false
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  render() {
    const { applicationStore, profilesStore, mode } = this.props;

    if (!applicationStore.isOrganizer) {
      return null;
    }

    if (profilesStore.isItemLoading) {
      return this.renderLoading();
    }

    if (profilesStore.itemLoadingState === 'error') {
      return this.renderError();
    }

    switch (mode) {
      case 'name':
        return this.renderNameView();

      case 'number':
        return this.renderNumberView();

      case 'confirm_number':
        return this.renderNumberConfirmView();

      case 'group':
        return this.renderGroupView();

      case 'print':
        return this.renderPrintView();
    }

    if (this.isAddMode()) {
      return this.renderAddView();
    }

    return this.renderOverview();
  }
}

export default (props: PublicStudentDetailScreenProps) => {
  const formValues: FormValues = {
    first_name: '',
    last_name: '',
    group_id: '',
    print: true,
    print_yearbook: true
  };

  const form = useForm(formValues);

  // @ts-ignore
  return <StudentDetailScreen {...props} form={form} />;
};
