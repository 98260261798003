import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { HistoryProps, idFromMatch } from 'utils/history';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import TopActionBlock from 'blocks/TopActionBlock';
import CancelLink from 'domain/Links/CancelLink';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import { LoadingOverlayElement } from 'blocks/LoadingBlock';
import { ProfileModelType } from 'models/ProfileModel';
import Headline from 'elements/Headline';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import { intl } from 'i18n';
import ButtonBlock from 'blocks/ButtonBlock';
import DeleteButton from 'domain/Buttons/DeleteButton';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import TextElement from 'components/TextElement/TextElement';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Divider from 'components/Divider/Divider';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';

interface TeacherFactsheetNameScreenProps {
  profilesStore: ProfilesStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('profilesStore', 'applicationStore')
@observer
class TeacherFactsheetNameScreen extends Component<
  TeacherFactsheetNameScreenProps & HistoryProps
> {
  componentDidMount() {
    const id = idFromMatch(this.props.match);

    if (id) {
      this.loadProfile(id);
    } else {
      // new teacher mode
      this.props.profilesStore.clearCurrentItem();
      this.props.form.reset();
    }
  }

  componentDidUpdate(prevProps: any) {
    const newId = idFromMatch(this.props.match);

    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new teacher mode
          this.props.profilesStore.clearCurrentItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        this.loadProfile(newId);
      }
    }
  }

  private async loadProfile(id: number) {
    const profile = await this.props.profilesStore.getStudent(id);

    if (profile) {
      this.fillForm(profile);
    }
  }

  private async handleSubmit() {
    const { match, profilesStore, form } = this.props;

    const chapterId = chapterIdFromMatch(match);
    let id = idFromMatch(match);

    try {
      if (id) {
        await profilesStore.updateProfile(id, {
          first_name: form.values.first_name || '',
          last_name: form.values.last_name || '',
          print: !!form.values.print
        });

        this.props.applicationStore.setFlashMessage(
          intl.formatMessage({ id: 'change saved' })
        );

        this.props.history.replace(ROUTE_FACTSHEETS + '/detail/' + id);
      } else {
        await profilesStore.createTeacher(
          form.values.first_name || '',
          form.values.last_name || '',
          chapterId
        );

        this.props.applicationStore.setFlashMessage(
          intl.formatMessage({ id: 'teacher created flash' })
        );

        this.props.history.replace(ROUTE_FACTSHEETS + '/' + chapterId + '?teachers=yes');
      }
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }
  }

  private fillForm(profile: ProfileModelType) {
    const { form } = this.props;

    form.reset();

    form.setField('first_name', profile.first_name || '');
    form.setField('last_name', profile.last_name || '');
    form.setField('print', profile.print || '');
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  async performDelete() {
    const id = idFromMatch(this.props.match);

    if (!id) {
      return;
    }

    try {
      await this.props.profilesStore.removeProfile(id);
    } catch (error: any) {
      // delete errors are handled by store internally
      return;
    }

    this.props.applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'teacher removed flash' })
    );

    this.props.history.replace(
      ROUTE_FACTSHEETS + '/' + chapterIdFromMatch(this.props.match)
    );
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  private isAddMode() {
    return !idFromMatch(this.props.match);
  }

  private renderPage(content: any) {
    const { match } = this.props;

    const teacherId = idFromMatch(match);
    const chapterId = chapterIdFromMatch(match);

    return (
      <>
        <TopActionBlock>
          <CancelLink
            to={
              ROUTE_FACTSHEETS +
              '/' +
              (teacherId ? 'detail/' + teacherId : chapterId)
            }
          />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage
              id={this.isAddMode() ? 'Add teacher' : 'Edit teacher'}
            />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  private renderForm() {
    const { form } = this.props;
    const { itemLoadingState } = this.props.profilesStore;

    const isEditMode = !this.isAddMode();

    return this.renderPage(
      <>
          {itemLoadingState === 'update_error' && <GenericErrorComponent />}

          <FormStackBlock>
          <TextInputComponent
            name="first_name"
            label={intl.formatMessage({ id: 'First name or title' })}
            {...form.bindInput('first_name')}
            autoFocus={true}
          />

          <TextInputComponent
            name="last_name"
            label={intl.formatMessage({ id: 'Last name' })}
            {...form.bindInput('last_name')}
          />

          {isEditMode && (
            <NonGapStackBlock>
              <CustomCheckboxBlock>
                <CustomCheckboxBlock.CheckboxElement
                  {...form.bindCheckbox('print')}
                />
                <CustomCheckboxBlock.LabelElement>
                  <FormattedMessage id="Print factsheet" />
                </CustomCheckboxBlock.LabelElement>
              </CustomCheckboxBlock>

              <TextElement>
                <FormattedMessage id="print teacher factsheet on" />
              </TextElement>
            </NonGapStackBlock>
          )}
          </FormStackBlock>

          <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
            <FormattedMessage id="Save" />
          </ButtonBlock>

        {isEditMode && (
          <>
            <Divider />
            <DeleteButton onClick={() => this.confirmDelete()} />

            {form.confirm && (
              <ConfirmBoxComponent
                header={<FormattedMessage id="confirm delete header" />}
                text={
                  <FormattedMessage
                    id="confirm delete item"
                    values={{
                      item: <FormattedMessage id="This teacher factsheet" />
                    }}
                  />
                }
                confirmText={<FormattedMessage id="Remove" />}
                abortText={<FormattedMessage id="Cancel" />}
                onConfirm={() => this.performDelete()}
                onAbort={() => this.finishDelete()}
                confirmColor="RED"
              />
            )}
          </>
        )}
      </>
    );
  }

  private renderError() {
    const id = idFromMatch(this.props.match);

    return this.renderPage(
      <GenericErrorComponent
        onRetryClick={id ? () => this.loadProfile(id) : undefined}
      />
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayElement />);
  }

  render() {
    const { isItemLoading, itemLoadingState } = this.props.profilesStore;

    if (isItemLoading) {
      return this.renderLoading();
    }

    if (itemLoadingState === 'error') {
      return this.renderError();
    }

    return this.renderForm();
  }
}

export default (props: any) => {
  const form = useForm({ print: true });
  return <TeacherFactsheetNameScreen {...props} form={form} />;
};
