import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import StickyBottomContainerBlock from 'blocks/StickyBottomContainerBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import InfoBox from 'components/InfoBoxComponent/InfoBoxComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import OrganizerConditional from 'domain/Conditionals/OrganizerConditional';
import StudentConditional from 'domain/Conditionals/StudentConditional';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import {
  ROUTE_DASHBOARD,
  ROUTE_MOTTOS,
  ROUTE_MOTTOS_ORG
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';
import MottosList from '../container/MottosList/MottosList';
import MottosVotingListItem from '../container/MottosList/MottosVotingListItem';
import { MottosStoreType } from '../MottosStore';
import SelectedMottoScreen from './SelectedMottoScreen';
import LinkBlock from 'blocks/LinkBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TextElement from 'components/TextElement/TextElement';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';

const MAX_VOTES = 3;

interface MottosVotingScreenProps {
  applicationStore: ApplicationStoreType;
  mottosStore: MottosStoreType;
  form: FormType;
}

@inject('applicationStore', 'mottosStore')
@observer
class MottosVotingScreen extends React.Component<
MottosVotingScreenProps & HistoryProps
> {
  componentDidMount() {
    if (!this.props.applicationStore.isBookMottoVotingEnabled) {
      this.props.history.replace(ROUTE_DASHBOARD);
    }
  }

  getSelected(): number[] {
    const { mottosStore, form } = this.props;

    const formSelected = form.values.selected;
    return !formSelected ? mottosStore.selectedMottosIds : formSelected;
  }

  mottoClick(id: number) {
    const { form } = this.props;
    const selected = this.getSelected().concat([]); // clone in order to update

    const index = selected.indexOf(id);
    if (index > -1) {
      // remove
      selected.splice(index, 1);
    } else if (selected.length < MAX_VOTES) {
      // add
      selected.push(id);
    }

    form.setField('selected', selected);
  }

  async vote() {
    const { history, applicationStore } = this.props;

    const selected = this.getSelected().concat([]); // clone just to be sure
    try {
      const ok = await this.props.mottosStore.vote(selected, true, false);

      if (ok) {
        if (applicationStore.isOrganizer) {
          history.push(ROUTE_MOTTOS_ORG);
        } else {
          history.push(ROUTE_DASHBOARD);
        }
      }
    } catch (error: any) {
      // TODO do we need to do something here?
    }
  }

  renderPage(content: any) {
    const { applicationStore } = this.props;

    return (
      <>
        <TopActionBlock>
          <OrganizerConditional profile={applicationStore.currentUser}>
            <CancelLink to={ROUTE_MOTTOS_ORG} />
          </OrganizerConditional>
          <StudentConditional profile={applicationStore.currentUser}>
            <BackToDashboardLink />
          </StudentConditional>
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="which motto to vote for" />
          </Headline.Large>

          <LinkBlock
            to={ROUTE_MOTTOS + '/new?voting=yes'}
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
          >
            <FormattedMessage id="mottos add proposal" />
          </LinkBlock>

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderVoting() {
    const { mottosStore } = this.props;
    const selected = this.getSelected();

    return this.renderPage(
      <>
        {mottosStore.isVoteError && (
          <InfoBox error={true}>
            <FormattedMessage id="motto vote error" />
          </InfoBox>
        )}

        <MottosList
          noSearch={true}
          itemComponent={MottosVotingListItem}
          selected={selected}
          onMottoClick={(_: any, id: number) => this.mottoClick(id)}
        />

        {!mottosStore.isListLoading && (
          <StickyBottomContainerBlock>
            <HorizontalStackBlock justified={true}>
              <NonGapStackBlock>
                <Paragraph weight="BOLD">
                  <FormattedMessage
                    id="motto votes max counter"
                    values={{
                      count: this.getSelected().length,
                      max: MAX_VOTES
                    }}
                  />
                </Paragraph>
                <TextElement>
                  <FormattedMessage id="multi vote mottos" />
                </TextElement>
              </NonGapStackBlock>


              <ButtonBlock
                inline={true}
                slim={true}
                background="PRIMARY"
                onClick={() => this.vote()}
              >
                <FormattedMessage id="Save" />
              </ButtonBlock>
            </HorizontalStackBlock>
          </StickyBottomContainerBlock>
        )}

        <HelpSubject subject="motto" hidden={true} />
      </>
    );
  }

  renderCurrentMotto() {
    return <SelectedMottoScreen />;
  }

  render() {
    const { mottosStore, applicationStore } = this.props;

    if (applicationStore.hasBookMotto) {
      return this.renderCurrentMotto();
    }

    if (mottosStore.isVoteLoading) {
      return this.renderLoading();
    }

    return this.renderVoting();
  }
}

export default (props: any) => {
  const form = useForm();
  return <MottosVotingScreen {...props} form={form} />;
};
