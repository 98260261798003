import { types, Instance } from 'mobx-state-tree';

// TODO create different models for photos and advertisements
const RemoteFileModel = types.model('RemoteFileModel', {
  // photo
  full: types.maybe(types.string), // uncropped for print
  cropped: types.maybe(types.string), // cropped for print - currently not sent by server
  shrinked: types.maybe(types.string), // uncropped large preview
  small: types.maybe(types.string), // uncropped small preview
  preview: types.maybe(types.string), // cropped small preview

  // advertisement
  filename: types.maybe(types.string),
  url: types.maybe(types.string)
});

export type RemoteFileModelType = Instance<typeof RemoteFileModel>;
export default RemoteFileModel;
