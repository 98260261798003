import React, { useContext, useEffect } from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import InputMessage from 'elements/InputMessage';
import UppercaseHeading from 'elements/UppercaseHeading';
import {
  useTextileProfileQuery,
  useUpdateTextileProfileQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const ProfilePrintScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const textileProfile = useTextileProfileQuery(id, textileOrderId);
  const updateTextileProfile = useUpdateTextileProfileQuery();

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('management_checkout');

    const data = textileProfile.data;
    if (data) {
      props.form.setField('print', data.print?.toString() || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileProfile.data]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileProfile.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileProfile.refetch()} />
    );
  }

  if (props.form.loading || textileProfile.isLoading || !textileProfile.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const save = () => {
    const { form, history } = props;

    if (!id || !textileOrderId) {
      return;
    }

    form.resetErrors();
    form.setLoading(true)

    updateTextileProfile.mutate(
      {
        data: { ...form.values, name_verified: true },
        id: id,
        textileOrderId
      },
      {
        onSuccess: () => {
          history.goBack()
        },
        onError: (error: any) => {
          form.setLoading(false)
          if (handleFormError(form, error)) {
            return;
          }
        }
      }
    );
  }

  const renderSaveButton = (next?: boolean) => {
    const { form } = props;
    return (
      <>
        {form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  };

  const renderForm = () => {
    const { form } = props;
    const profile = textileProfile.data;

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Edit name" />}
          text={
            <>
              <IconComponent icon="USER" /> {profile.name}
            </>
          }
        />

        {!profile.name_verified && !profile.print_data_locked && (
          <InputMessage warning={true}>
            <FormattedMessage id="textile checkout verify profile print" />
          </InputMessage>
        )}

        <FormStackBlock>
          <UppercaseHeading>
            <FormattedMessage id="Print" />
          </UppercaseHeading>

          <ListStackBlock>
            <RadioButtonComponent
              name="print"
              value={true}
              label={<FormattedMessage id="Print name" />}
              {...form.bindRadioButton('print', 'true')}
            />

            <RadioButtonComponent
              name="print"
              value={false}
              label={<FormattedMessage id="no print name" />}
              {...form.bindRadioButton('print', 'false')}
            />
          </ListStackBlock>
        </FormStackBlock>

        {renderSaveButton()}
      </>
    );
  };

  return renderForm();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <ProfilePrintScreen {...props} form={form} />;
};
