import React from 'react';
import { FormattedMessage } from 'react-intl';

import LinkBlock from 'blocks/LinkBlock';
import Center from 'elements/Center';
import { ROUTE_ACCOUNT_DASHBOARD } from 'utils/constants/routes';

import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import LargeEmoji from './LargeEmoji';

const LoggedInSuccess = ({
  emoji,
  headingId,
  messageId,
  link
}: {
  emoji: string,
  headingId?: string;
  messageId?: string;
  link?: string;
}) => (
  <Center>
    <PageStackBlock>
      <LargeEmoji>{emoji}</LargeEmoji>
        <PageHeader headline={<FormattedMessage id={headingId || 'funnel logged in success'} />} text={<FormattedMessage id={messageId || 'funnel logged in success info'} />} />

        <LinkBlock to={link ? link : ROUTE_ACCOUNT_DASHBOARD} background="PRIMARY">
          <FormattedMessage id="Back to dashboard long" />
        </LinkBlock>
    </PageStackBlock>
  </Center>
);

export default LoggedInSuccess;
