import { types, Instance } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';

const GroupModel = types.model('GroupModel', {
  id: types.identifierNumber,
  name: types.maybe(types.string),
  student_count: types.maybe(types.number),
  has_chapters: types.maybe(types.boolean),
});

export const createGroupModel = (data: any) => {
  if (!data) {
    return undefined;
  }

  return GroupModel.create({
    id: mandatoryId(data.id),
    name: data.name || undefined,
    student_count: numberOrUndefined(data.student_count),
    has_chapters: booleanOrUndefined(data.has_chapters),
  });
};

export type GroupModelType = Instance<typeof GroupModel>;
export default GroupModel;
