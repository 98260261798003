import React from 'react';

import styled from 'styled-components';

import FooterBlock from './FooterBlock';

const SPACE_BOTTOM = 8.75;

const WrapperContainer = styled.div`
  flex-grow: 1;
  padding-bottom: ${SPACE_BOTTOM}em;
`;

export default ({ children, noMarginBottom }: { children?: any, noMarginBottom?: true }) => (
  <>
    <WrapperContainer>{children}</WrapperContainer>
    <FooterBlock noMarginBottom={noMarginBottom}/>
  </>
);
