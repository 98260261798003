import {
  TextileTextBox
} from 'components/print/templates/TextileBackTemplateComponent';
import shrinkNameColumn from 'utils/chunker/NameColumnShrinker';

import { FIVE_COLUMN_SETTINGS, FOUR_COLUMN_SETTINGS, ONE_COLUMN_SETTINGS, THREE_COLUMN_SETTINGS, TWO_COLUMN_SETTINGS, getSettingsByColumn } from './textileNamesSettingsByColumn';
import { sliceNames } from './textileSliceNames';

const PADDING_BETWEEN_NAMES = 7; // 2 * 3,543 = 7 => 7 upscale to 360dpi
const LINE_HEIGHT_MULTIPLIER = 1.2;
const GROUP_NAMES_CONTAINER_HEIGHT = 202;

export const calculateNameListFontSize = (maxNameListHeight: number, names?: string[], namesByGroup?: { groupName?: string; names: string[], teacherNames: string[] }[]) => {
  let columnsCount: number;
  let nameColumns: string[][] = [];

  if (namesByGroup && namesByGroup.length > 0) {
    columnsCount = namesByGroup.length;
    namesByGroup.forEach((itm) => {
      if (itm.teacherNames.length > 0) {
        nameColumns.push(itm.names.concat(" ").concat(itm.teacherNames));
      } else {
        nameColumns.push(itm.names);
      }
    });
  } else if (names) {
    nameColumns = sliceNames(names, narrowDownColumnCount(names.length, maxNameListHeight));
    if (!nameColumns.length) {
      return null;
    }

    columnsCount = nameColumns.length;
  } else {
    return null
  }

  if (!nameColumns) {
    return null;
  }

  let fitted = false;
  while (!fitted) {
    const [columnFontSize, columnFailed] = fitNamesColumns(
      nameColumns,
      columnsCount,
      maxNameListHeight,
      namesByGroup
    );

    if (columnFailed) {
      if (columnsCount !== 5) {
        columnsCount++;
      } else {
        fitted = true;
      }
    } else {
      fitted = true;
      return {
        calculatedNamesFontSize: columnFontSize,
        calculatedColumns: columnsCount
      };
    }
  }

  return null;
}

const fitNamesColumns = (names: string[][], columns: number, maxNameListHeight: number, namesByGroup?: { groupName?: string; names: string[] }[]): [number, boolean] => {
  const columnSettings = getSettingsByColumn(columns);

  const columnTextBox: TextileTextBox = {
    boundary: [columnSettings.width, namesByGroup && namesByGroup.length > 0 ? maxNameListHeight - GROUP_NAMES_CONTAINER_HEIGHT : maxNameListHeight],
    minFontSize: columnSettings.minFontSize,
    maxFontSize: columnSettings.maxFontSize,
    step: columnSettings.steps
  };

  if (namesByGroup && namesByGroup.length > 0) {
    columnTextBox.minFontSize = 0;
  }

  let [columnFontSize, columnFailed] = [columnTextBox.maxFontSize, true];

  console.log(names)

  for (const nameColumn of names) {
    [columnFontSize, columnFailed] = fitFontSizeColumn(
      nameColumn,
      columnTextBox
    );

    if (!columnFailed) {
      columnTextBox.maxFontSize = columnFontSize;
    }

    if (columns === 5 && columnFontSize < FIVE_COLUMN_SETTINGS.softcapMinFontSize) {
      // Do something softcap reached
    }
  }

  return [columnFontSize, columnFailed];
}

const fitFontSizeColumn = (text: string[], TextileTextBox: TextileTextBox): [number, boolean]  => {
  const columnStyle =
    `line-height: 120%;font-family: 'Bebas Neue';justify-content:center;text-align:center;align-items:center;`;

  return shrinkNameColumn(
    text,
    TextileTextBox.maxFontSize,
    TextileTextBox.minFontSize,
    columnStyle,
    TextileTextBox.boundary,
    TextileTextBox.step
  );
}

const narrowDownColumnCount =(count: number, maxNameListHeight: number) => {
  const maxNamesOneColumn = Math.floor(
    maxNameListHeight /
      (ONE_COLUMN_SETTINGS.minFontSize * LINE_HEIGHT_MULTIPLIER +
        PADDING_BETWEEN_NAMES)
  );
  const maxNamesTwoColumns = Math.floor(
    (maxNameListHeight /
      (TWO_COLUMN_SETTINGS.minFontSize * LINE_HEIGHT_MULTIPLIER +
        PADDING_BETWEEN_NAMES)) *
      2
  );
  const maxNamesThreeColumns = Math.floor(
    (maxNameListHeight /
      (THREE_COLUMN_SETTINGS.minFontSize * LINE_HEIGHT_MULTIPLIER +
        PADDING_BETWEEN_NAMES)) *
      3
  );
  const maxNamesFourColumns = Math.floor(
    (maxNameListHeight /
      (FOUR_COLUMN_SETTINGS.minFontSize * LINE_HEIGHT_MULTIPLIER +
        PADDING_BETWEEN_NAMES)) *
      4
  );

  if (count <= maxNamesOneColumn) {
    return 1;
  } else if (count <= maxNamesTwoColumns) {
    return 2;
  } else if (count <= maxNamesThreeColumns) {
    return 3;
  } else if (count <= maxNamesFourColumns) {
    return 4;
  } else {
    return 5;
  }
}