import { inject, observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ImageBlock from 'blocks/ImageBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import TextPreviewComponent from 'components/print/layout-editor/TextPreviewComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import ChapterPreviewComponent from 'domain/ChapterPreviewComponent';
import LockedLabel from 'domain/Label/LockedLabel';
import BackLink from 'domain/Links/BackLink';
import BackToContentLink from 'domain/Links/BackToContentLink';
import PhotoEditScreen from 'domain/PhotoEditScreen/PhotoEditScreen';
import BreakingText from 'elements/BreakingText';
import Headline from 'elements/Headline';
import PreserveWhitespace from 'elements/PreserveWhitespace';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { TextModelType } from 'models/TextModel';
import { Boundary } from 'utils/chunker/ContentChunker';
import { ROUTE_TEXTS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import { FormType, handleFormError, useForm } from 'utils/hooks/useForm';
import shortenText from 'utils/shorten-text';

import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { TextsStoreType } from '../TextsStore';

const MAX_LENGTH = 20000;
const MAX_LENGTH_DISPLAY = 18000;

interface TextDetailProps {
  textsStore: TextsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
  edit?: boolean;
  photo?: boolean;
}

const removeHeaderFormats = (text?: string) => {
  if (typeof text !== 'string') {
    return text;
  }

  return text.replace(/^#{2,4}/gm, ' ');
};

@inject('textsStore', 'applicationStore')
@observer
class TextDetail extends Component<TextDetailProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore, textsStore } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature('texts', this.props.location.pathname)
    ) {
      return;
    }

    if (!textsStore.isItemLoading) {
      if (!textsStore.item || isPush(this.props.history)) {
        this.loadText();
      }
    }
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const chapterId = chapterIdFromMatch(this.props.match);
    const prevChapterId = chapterIdFromMatch(prevProps.match);

    if (chapterId !== prevChapterId) {
      this.loadText();
    }
  }

  fillForm(item: TextModelType) {
    const { form } = this.props;

    form.setField('content', item.content || '');
  }

  async loadText() {
    const { textsStore, form } = this.props;

    const id = chapterIdFromMatch(this.props.match);

    if (!id || textsStore.isItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const text = await textsStore.getTextByChapter(id);

    if (text) {
      this.fillForm(text);
    }

    return text;
  }

  navigateToDisplayView() {
    this.props.history.push(
      ROUTE_TEXTS + '/' + (chapterIdFromMatch(this.props.match) || '')
    );
  }

  async handleSubmit() {
    const { form, textsStore } = this.props;
    const { content } = form.values;

    form.resetErrors();

    textsStore.patchItem({
      content: !content ? '' : content.trim()
    });

    try {
      await textsStore.storeItem();
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by TextsStore internally
      return;
    }

    this.navigateToDisplayView();
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  renderDisplay() {
    const { textsStore, applicationStore } = this.props;
    const { item } = textsStore;
    const chapter = item?.chapter;

    const { isOrganizer } = applicationStore;

    if (!item || !chapter) {
      return null;
    }

    const isPhotoPresent = !!item.photo;
    const isPreviewPresent = !!item.photo?.photo?.preview;

    let photoUrl = '';
    if (isPhotoPresent) {
      photoUrl = '/' + item.photo!.id;
    }

    const hasPhoto = !!chapter.layout?.layoutDefinition.hasPhoto;

    return this.renderPage(
      <>
        <ChapterPreviewComponent
          chapter={chapter}
          organizersOnly={true}
          defaultTextId="Text"
          baseRoute={ROUTE_TEXTS}
          marginBottom="L"
        >
          {chapter?.layout?.layoutDefinition && (
            <FontPreloadComponent chapter={chapter} numPages={1}>
              <SliderBlock>
                <TextPreviewComponent
                  scaled={true}
                  config={chapter.layout?.layoutDefinition}
                  chapter={chapter}
                  text={item.content || ''}
                  picture={item.photo?.printPicture || null}
                />
              </SliderBlock>
            </FontPreloadComponent>
          )}
        </ChapterPreviewComponent>

        <ItemStackBlock gap="XS">
          <Headline.Medium>
            <FormattedMessage id="Content(plural)" />
          </Headline.Medium>

          {isOrganizer && (
            <Paragraph  color="TEXT_LIGHT">
              <FormattedMessage id="Add text and photo here" />
            </Paragraph>
          )}
        </ItemStackBlock>

        <Link to={ROUTE_TEXTS + '/' + chapter.id + '/edit'}>
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <Headline.Small color="BLACK">
                  <FormattedMessage id="Text" />
                </Headline.Small>
                {item.content ? (
                  <TextElement>
                    {shortenText(100, removeHeaderFormats(item.content))}
                  </TextElement>
                ) : (
                  <TextElement  color="TEXT_DARK">
                    <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />{' '}
                    <FormattedMessage id="Write text" />
                  </TextElement>
                )}
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </Link>

        {hasPhoto ? (
          <Link to={ROUTE_TEXTS + '/' + chapter.id + '/photo' + photoUrl}>
            <EveryCard>
              <EveryCardPadding>
                <ImageBlock
                  height={4.375}
                  width={4.375}
                  background="GRAY900"
                  bordered={true}
                >
                  {isPreviewPresent && (
                    <img src={item.photo!.photo!.preview} alt="" />
                  )}
                </ImageBlock>
                <EveryCardBody>
                  <Headline.Small color="BLACK">
                    <FormattedMessage id="Image" />
                  </Headline.Small>
                  <TextElement  color="TEXT_DARK">
                    {!isPhotoPresent && (
                      <>
                        <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />{' '}
                        <FormattedMessage id="Add photo" />
                      </>
                    )}
                    {isPhotoPresent && !isPreviewPresent && (
                      <>
                        <IconComponent icon="ARROW_ROTATE" fill="GREEN" />{' '}
                        <FormattedMessage id="Photo is being processed" />
                      </>
                    )}
                  </TextElement>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          </Link>
        ) : (
          <Paragraph>
            <FormattedMessage
              id="text photo layout hint"
              values={{
                link: (
                  <Link
                    to={
                      ROUTE_TEXTS +
                      '/' +
                      chapter.id +
                      '/settings?active=layout&from=list'
                    }
                  >
                    <FormattedMessage id="text photo layout hint link" />
                  </Link>
                )
              }}
            />
          </Paragraph>
        )}
      </>
    );
  }

  renderEditForm() {
    const { textsStore, applicationStore, form } = this.props;

    let extra: ReactNode | undefined;
    if (textsStore.itemLoadingState === 'update_error') {
      extra = <GenericErrorComponent />;
    }

    const chapter = textsStore.item?.chapter;
    if (!chapter) {
      return null;
    }

    if (!applicationStore.isEditAllowed) {
      return this.renderPage(
        <>
          <Headline.Large>
            {chapter.title || <FormattedMessage id="Text" />}
          </Headline.Large>

          <LockedLabel id="Texts" />

          <Paragraph>
            <BreakingText>{textsStore.item!.content}</BreakingText>
          </Paragraph>
        </>,
        true
      );
    }

    return this.renderPage(
      <>
        <Headline.Large>
          {chapter.title || <FormattedMessage id="Text" />}
        </Headline.Large>

        {extra}

        <PaddingBlock background="PRIMARY_LIGHT">
          <Paragraph>
            <PreserveWhitespace>
              <FormattedMessage id="Edit text headlines info" />
            </PreserveWhitespace>
          </Paragraph>
        </PaddingBlock>

        <FormStackBlock>
          <TextareaComponent
            name="content"
            placeholder={intl.formatMessage({ id: 'Enter text' })}
            height={20}
            maxLength={MAX_LENGTH}
            label={<FormattedMessage id="Text content" />}
            autoFocus={true}
            {...form.bindInput('content')}
          />

          <CharCounterComponent
            max={MAX_LENGTH}
            minDisplay={MAX_LENGTH_DISPLAY}
            value={form.values.content}
          />
        </FormStackBlock>

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>,
      true
    );
  }

  renderPage(content: any, backToDisplay = false) {
    const text = this.props.textsStore.item;
    const chapter = text?.chapter;

    return (
      <>
        <TopActionBlock>
          {backToDisplay && chapter ? (
            <BackLink to={ROUTE_TEXTS + '/' + chapter.id} />
          ) : (
            <BackToContentLink />
          )}
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="text" />
      </>
    );
  }

  renderPhoto() {
    const { item } = this.props.textsStore;
    if (!item) {
      return null;
    }

    let size: Boundary | undefined =
      item.chapter?.layout?.layoutDefinition?.photoSize;
    if (size) {
      if (size[0] > 220) {
        const fact = 220 / size[0];
        size[0] = 220;
        size[1] = Math.round(size[1] * fact);
      }
    } else {
      size = [220, 155];
    }

    return (
      <PhotoEditScreen
        photoId={item.photo?.id}
        photoType="text"
        parentId={item.id}
        frameWidth={size[0]}
        frameHeight={size[1]}
        fitToFrameEnabled={true}
        allowDelete={true}
        allowEditCaption={false}
        isImageRounded={false}
        isEditorEnabled={true}
        backRoute={ROUTE_TEXTS + '/' + item.chapter_id}
        backIsCancel={false}
      />
    );
  }

  render() {
    const { textsStore } = this.props;

    if (textsStore.isItemLoading) {
      return this.renderLoading();
    }

    if (textsStore.itemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (this.props.edit) {
      return this.renderEditForm();
    }

    if (this.props.photo) {
      return this.renderPhoto();
    }

    return this.renderDisplay();
  }
}

export default (props: any) => {
  const form = useForm();
  return <TextDetail {...props} form={form} />;
};
