import React, { Component } from 'react';

import { ProfileModelType, createProfileModel } from 'models/ProfileModel';

import YearbookTemplateComponent, {
  YearbookTemplateConfig
} from '../templates/YearbookTemplateComponent';
import { CommonLayoutPreviewProps } from './CommonLayoutPreviewProps';
import scalePreview from './scale-preview';

interface YearbookPreviewComponentProps extends CommonLayoutPreviewProps {
  config: YearbookTemplateConfig;
  profiles?: ProfileModelType[];
}

export class YearbookPreviewComponent extends Component<
  YearbookPreviewComponentProps
> {
  shouldComponentUpdate() {
    // preview will be forced to redraw on config update only
    return false;
  }

  private generateProfiles(count: number = 18): ProfileModelType[] {
    const profiles: ProfileModelType[] = [];

    for (let id = 1; id <= count; id++) {
      const photo = (id % 18) + 1;
      profiles.push(createProfileModel({
        id,
        first_name: 'Maxi',
        last_name: 'Mustermann',
        yearbook_text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
        yearbook_photo: {
          id: id + 1000,
          photo_type: 'yearbook',
          photo: {
            preview: `/images/content-preview/yearbook-mockup-${photo}.jpg`
          }
        },
      })!);
    }

    return profiles;
  }

  render() {
    const {
      config,
      colors,
      pageHeaderStyle,
      sectionHeaderStyle,
      background,
      start,
      scaled,
      chapter,
      profiles,
      firstPageOnly
    } = this.props;

    return (
      <YearbookTemplateComponent
        print={false}
        firstPageOnly={firstPageOnly}
        config={config}
        colors={colors || chapter?.color_scheme?.templateColors || []}
        pageHeaderStyle={pageHeaderStyle || chapter?.font?.pageHeaderStyle}
        sectionHeaderStyle={
          sectionHeaderStyle || chapter?.font?.sectionHeaderStyle
        }
        background={
          background ||
          (chapter?.background?.id || '') +
            (chapter?.background?.extension || '') ||
          undefined
        }
        startPosition={start || 'left'}
        startPage={this.props.startPage || 1}
        render={
          !scaled ? undefined : (content, key) => scalePreview(content, key)
        }
        title={chapter?.title}
        profiles={profiles || this.generateProfiles()}
      />
    );
  }
}

export default YearbookPreviewComponent;
