import { types, Instance } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import GroupModel, { createGroupModel } from './GroupModel';
import RolesEnum from './RolesEnum';

const ProfileAsAuthorModel = types
  .model('ProfileAsAuthorModel', {
    id: types.maybe(types.number),
    mobile_number: types.maybe(types.string),
    confirmed: types.maybe(types.boolean),
    first_name: types.maybe(types.string),
    last_name: types.maybe(types.string),
    name: types.maybe(types.string),
    group: types.maybe(GroupModel),
    role: types.maybe(RolesEnum)
  })
  .views((self) => {
    return {
      get fullName(): string | undefined {
        let name = self.first_name || '';

        if (self.last_name) {
          name = name + (name !== '' ? ' ' : '') + self.last_name;
        }

        return name === '' ? undefined : name;
      },
      get initials(): string {
        let initials: string | undefined;

        if (self.first_name) {
          initials = self.first_name.substring(0, !self.last_name ? 2 : 1);
        }

        if (self.last_name) {
          initials = initials + self.last_name.substring(0, !initials ? 2 : 1);
        }

        return initials?.toUpperCase() || '?';
      }
    };
  });

export const createProfileAsAuthorModel = (
  data: any
): ProfileAsAuthorModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return ProfileAsAuthorModel.create({
    id: numberOrUndefined(data.id),
    mobile_number: data.mobile_number || undefined,
    confirmed: booleanOrUndefined(data.confirmed),
    first_name: data.first_name || undefined,
    last_name: data.last_name || undefined,
    name: data.name || undefined,
    group: createGroupModel(data.group),
    role: RolesEnum.is(data.role) ? data.role : undefined
  });
};

export type ProfileAsAuthorModelType = Instance<typeof ProfileAsAuthorModel>;
export default ProfileAsAuthorModel;
