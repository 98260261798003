import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  FunnelCard,
  FunnelCardLabel,
  FunnelCardTop
} from 'blocks/FunnelCard/FunnelCard';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import IconComponent from 'components/IconComponent';
import {
  IconNameType,
  UppercaseIconNameType
} from 'components/IconComponent/svg-sprites-info';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Headline from 'elements/Headline';
import NakedButton from 'elements/NakedButton';
import { FormType } from 'utils/hooks/useForm';

const CLASS_SIZES = ['0-50', '50-100', '100-150', '150+'] as const;
type ClassSizeType = typeof CLASS_SIZES[number];
const CLASS_SIZES_ICON: {
  [key in ClassSizeType]: IconNameType | UppercaseIconNameType;
} = {
  '0-50': 'USER',
  '50-100': 'USERGROUP',
  '100-150': 'USERGROUP_LARGE',
  '150+': 'USERGROUP_HUGE'
};

const ClassSize = ({
  form,
  submit,
  errorMessages
}: {
  form: FormType;
  submit?: () => void;
  errorMessages?: ReactNode;
}) => (
  <PageStackBlock>
    <Headline.Large>
      <FormattedMessage id="funnel class" />
    </Headline.Large>

    {errorMessages}

    <TwoColumnBlock gap="M">
      {CLASS_SIZES.map((size) => (
        <NakedButton
          key={size}
          onClick={() => {
            form.setField('class_size', size);
            if (submit) {
              submit();
            }
          }}
        >
          <FunnelCard>
            <FunnelCardTop>
              <IconComponent
                icon={CLASS_SIZES_ICON[size]}
                fill="PRIMARY"
                size={5}
                circleBackground="PRIMARY_LIGHT"
              />
            </FunnelCardTop>
            <FunnelCardLabel>{size}</FunnelCardLabel>
          </FunnelCard>
        </NakedButton>
      ))}
    </TwoColumnBlock>
  </PageStackBlock>
);

export default ClassSize;
