import { inject, observer } from 'mobx-react';
import React, { JSXElementConstructor, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import { CandidateModelType } from 'models/CandidateModel';
import { RankingsStoreType } from 'screens/rankings/RankingsStore';
import useForm, { FormType } from 'utils/hooks/useForm';

import CandidatesListItem, {
  CandidatesListItemProps
} from './CandidatesListItem';

interface PublicCandidatesListProps {
  chapterId: number;
  selected?: number | number[];
  itemComponent?: JSXElementConstructor<CandidatesListItemProps>;
  onCandidateClick?: (e: any, candidateId: number) => void;
}

interface CandidatesListProps extends PublicCandidatesListProps {
  rankingsStore: RankingsStoreType;
  form: FormType;
}

@inject('rankingsStore')
@observer
class CandidatesList extends React.Component<CandidatesListProps> {
  componentDidMount() {
    // load/update candidates every time we display the list
    this.loadCandidates();
  }

  componentDidUpdate(prevProps: CandidatesListProps) {
    if (prevProps.chapterId !== this.props.chapterId) {
      this.loadCandidates();
    }
  }

  loadCandidates() {
    this.props.rankingsStore.getAllCandidates(this.props.chapterId);
  }

  renderError() {
    return <GenericErrorComponent onRetryClick={() => this.loadCandidates()} />;
  }

  renderLoading() {
    return <LoadingOverlayComponent />;
  }

  renderEmpty() {
    return <EmptyStateComponent headerId="No candidates added yet" />;
  }

  renderList() {
    // TODO Display some loading indicator for 'updating' loading state?

    const { rankingsStore, form, onCandidateClick, itemComponent } = this.props;
    const selected = !this.props.selected
      ? []
      : Array.isArray(this.props.selected)
      ? this.props.selected
      : [this.props.selected];

    let candidates: CandidateModelType[];
    let heading: ReactNode;

    if (!form.values.filter) {
      candidates = rankingsStore.allCandidates;

      if (candidates.length === 0) {
        return this.renderEmpty();
      }

      heading = (
        <FormattedMessage
          id="candidates count"
          values={{
            count: candidates.length
          }}
        />
      );
    } else {
      candidates = rankingsStore.filteredCandidates(form.values.filter);
      heading = (
        <FormattedMessage
          id="candidates filter count"
          values={{
            count: candidates.length
          }}
        />
      );
    }

    const Item = !itemComponent ? CandidatesListItem : itemComponent;

    return (
      <ItemStackBlock gap="L">
        <SearchListInput form={form} placeholderId="Find candidate" />

        <ItemStackBlock gap="M">
          <UppercaseHeading>{heading}</UppercaseHeading>

          {candidates.map((candidate: CandidateModelType) => (
            <Item
              key={candidate.id}
              candidate={candidate}
              selected={selected.includes(candidate.id)}
              onClick={onCandidateClick}
            />
          ))}
        </ItemStackBlock>
      </ItemStackBlock>
    );
  }

  render() {
    const { rankingsStore } = this.props;

    if (rankingsStore.candidatesLoadingState === 'loading') {
      return this.renderLoading();
    }

    if (rankingsStore.candidatesLoadingState === 'error') {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default (props: PublicCandidatesListProps) => {
  const form = useForm();
  // @ts-ignore
  return <CandidatesList {...props} form={form} />;
};
