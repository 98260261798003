import React from 'react';

import InputGroupBlock from 'blocks/InputGroupBlock';
import FormLabel from 'elements/FormLabel';
import InputMessage from 'elements/InputMessage';
import Textarea from 'elements/Textarea';

import InputInterface from './InputInterface';

// https://github.com/Microsoft/TypeScript/issues/26019#issuecomment-408547279
type TextareaProps = Parameters<typeof Textarea>[0];

export const TextareaComponent = React.forwardRef((props: InputInterface & TextareaProps, ref: any) => {
  const id = props.id ? props.id : '__formfield_' + props.name;
  return (
    <InputGroupBlock error={!!props.error}>
      {props.label && <FormLabel htmlFor={id}>{props.label}</FormLabel>}
      {props.error && <InputMessage>{props.error}</InputMessage>}
      <Textarea id={id} {...props} ref={ref} />
    </InputGroupBlock>
  );
});

export default TextareaComponent;
