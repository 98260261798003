import { OrderStateTextileType } from "api/textile_deals/fetchTextileAppState";

type LockType = 
  | 'atLeastOrderStarted'
  | 'atLeastPreorderStarted'
  | 'atLeastOrderPlaced'
  | 'atLeastOrderStartedOrPreparing'
  | 'atLeastOrderConfirmed'

export default function isTextileLocked(order_state: OrderStateTextileType, lockType: LockType):boolean {
  if (lockType === 'atLeastOrderStarted') {
    return order_state === 'order_started' || order_state === 'order_placed' || order_state === 'order_confirmed' || order_state === 'order_printing' || order_state === 'order_shipped' || order_state === 'done'
  }

  if (lockType === 'atLeastOrderStartedOrPreparing') {
    return order_state === 'preparing' || order_state === 'order_started' || order_state === 'order_placed' || order_state === 'order_confirmed' || order_state === 'order_printing' || order_state === 'order_shipped' || order_state === 'done'
  }

  if (lockType === 'atLeastOrderPlaced') {
    return order_state === 'order_placed' || order_state === 'order_confirmed' || order_state === 'order_printing' || order_state === 'order_shipped' || order_state === 'done'
  }

  if (lockType === 'atLeastOrderConfirmed') {
    return order_state === 'order_confirmed' || order_state === 'order_printing' || order_state === 'order_shipped' || order_state === 'done'
  }

  return false;
}
