import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard, EveryCardBody,
  EveryCardHeadline, EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import TagBlock from 'blocks/TagBlock';
import TextElement from 'components/TextElement/TextElement';
import { ProfileModelType } from 'models/ProfileModel';
import ROLES from 'utils/constants/roles';
import { ROUTE_SETTINGS } from 'utils/constants/routes';

type ProfileTagType = 'organizer' | 'virtual' | 'manuel';

export const CardTag = ({ type }: { type?: ProfileTagType }) => {
  switch (type) {
    case 'organizer':
      return (
        <TagBlock color="PURPLE" backgroundColor="PURPLE_LIGHT">
          <FormattedMessage id="Organizer short" />
        </TagBlock>
      );

    case 'virtual':
      return (
        <TagBlock color="PRIMARY" backgroundColor="PRIMARY_LIGHT">
          <FormattedMessage id="Virtual student short" />
        </TagBlock>
      );
    case 'manuel':
      return (
        <TagBlock color="PRIMARY" backgroundColor="PRIMARY_LIGHT">
          <FormattedMessage id="Manuel student short" />
        </TagBlock>
      );

    default:
  }

  return null;
};

export const ManageStudentsListItem = ({
  profile
}: {
  profile: ProfileModelType;
  // selected?: boolean;
  // onClick?: (e: any, studentId: Number) => void;
}) => {
  let roleId: ProfileTagType | undefined;
  if (
    profile.role === ROLES.ORGANIZER ||
    profile.role === ROLES.MANAGER ||
    profile.role === ROLES.MANUALLY_CREATED_ORGANIZER
  ) {
    roleId = 'organizer';
  } else if (profile.role === ROLES.MANUALLY_CREATED_STUDENT) {
    roleId = 'virtual';
  }

  return (
    <Link to={ROUTE_SETTINGS + '/students/' + profile.id}>
      <EveryCard topAlign={true}>
        <EveryCardPadding>
          <EveryCardBody>
            <EveryCardHeadline>{profile.name || profile.id}</EveryCardHeadline>

            <TextElement>
              {profile && profile.group ? (
                profile.group.name
              ) : (
                <FormattedMessage id="No group" />
              )}
            </TextElement>
          </EveryCardBody>

          <CardTag type={roleId} />
        </EveryCardPadding>
      </EveryCard>
    </Link>
  );
};

export default ManageStudentsListItem;
