import { inject, observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import LinkBlock from 'blocks/LinkBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import ChapterDescriptionComponent from 'domain/ChapterDescriptionComponent';
import BackLink from 'domain/Links/BackLink';
import CancelLink from 'domain/Links/CancelLink';
import PhotoEditScreen from 'domain/PhotoEditScreen/PhotoEditScreen';
import Headline from 'elements/Headline';
import WordWrap from 'elements/WordWrap';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FactsheetsStoreType } from 'screens/factsheets/FactsheetsStore';
import {
  ROUTE_CONTENT,
  ROUTE_SETTINGS,
  ROUTE_YEARBOOKS
} from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import shortenText from 'utils/shorten-text';
import YearbookPhotoItem from './YearbookPhotoItem';

interface YearbookDetailScreenBaseProps extends HistoryProps {
  photo?: boolean;
  text?: boolean;
}

interface YearbookDetailScreenProps extends YearbookDetailScreenBaseProps {
  factsheetsStore: FactsheetsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('factsheetsStore', 'applicationStore')
@observer
class YearbookDetailScreen extends Component<YearbookDetailScreenProps> {
  private async loadYearbook(id: number) {
    const { factsheetsStore, form } = this.props;

    if (factsheetsStore.isProfileItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const profile = await factsheetsStore.getYearbook(id);

    form.setField('yearbook_text', profile?.yearbook_text || '');
  }

  componentDidMount() {
    const id = idFromMatch(this.props.match);
    if (id) {
      this.loadYearbook(id);
    } else {
      this.props.factsheetsStore.clearCurrentProfileItem();
      this.props.form.reset();
    }
  }

  componentDidUpdate(prevProps: YearbookDetailScreenProps) {
    const newId = idFromMatch(this.props.match);
    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new item mode
          this.props.factsheetsStore.clearCurrentProfileItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        this.loadYearbook(newId);
      }
    }
  }

  private async updateText() {
    const { factsheetsStore, form } = this.props;

    const id = factsheetsStore.profileItem?.id;
    if (!id) {
      return;
    }

    try {
      await factsheetsStore.updateFactsheet(id, {
        yearbook_text: form.values.yearbook_text || ''
      });

      this.props.history.push(ROUTE_YEARBOOKS + '/detail/' + id);
    } catch (err) {
      handleFormError(form, err);
    }
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  private renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  private renderNoChapterError() {
    const { isOrganizer } = this.props.applicationStore;

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Yearbook" />} text={<FormattedMessage
          id={
            isOrganizer
              ? 'yearbook no chapter organizer'
              : 'yearbook no chapter student'
          }
        />} />
      </>
    );
  }

  private renderYearbook() {
    const { factsheetsStore, applicationStore } = this.props;
    const { currentUser, isOrganizer } = applicationStore;
    const chapter = factsheetsStore.profileItemChapter;
    const profile = factsheetsStore.profileItem;

    if (!currentUser || !profile || !chapter) {
      return null;
    }

    // const itIsMe = profile.id === currentUser.id;

    const route = ROUTE_YEARBOOKS + '/detail/' + profile.id;

    return this.renderPage(
      <>
        <Headline.Large>
          {profile.fullName || <FormattedMessage id="Yearbook" />}
        </Headline.Large>

        {isOrganizer && (
          <>
            <LinkBlock
              to={ROUTE_SETTINGS + '/students/' + profile.id}
              inline={true}
              slim={true}
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
            >
              <IconComponent icon="SETTINGS" />
              <FormattedMessage id="Account settings" />
            </LinkBlock>
          </>
        )}

        {/* chapter.layout?.layoutDefinition && profile.yearbook_photo && (
            <WideBlock padded={true}>
              <ItemStackBlock gap="M">
                <UppercaseHeading>
                  <FormattedMessage id="Design preview" />
                </UppercaseHeading>

                <FontPreloadComponent chapter={chapter} numPages={2}>
                  <SliderBlock>
                    <YearbookPreviewComponent
                      scaled={true}
                      config={chapter.layout.layoutDefinition}
                      chapter={chapter}
                      profiles={[profile]}
                    />
                  </SliderBlock>
                </FontPreloadComponent>
              </ItemStackBlock>
            </WideBlock>
          ) */}

        {chapter.editing_state === 'active' || isOrganizer ? (
          <>
            { !isOrganizer && (
              <ChapterDescriptionComponent chapter={chapter} />
            )}


            <ListStackBlock>
              <Headline.Medium>
                <FormattedMessage id="Photos" />
              </Headline.Medium>

              <Link to={route + '/photo'}>
                <YearbookPhotoItem photo={profile.yearbook_photo} />
              </Link>

              <Link to={route + '/text'}>
                <EveryCard>
                  <EveryCardPadding>
                    <EveryCardBody>
                      <EveryCardHeadline>
                        <FormattedMessage id="Slogan" />
                      </EveryCardHeadline>
                      <TextElement>
                        {!profile.yearbook_text ? (
                          <>
                            <IconComponent
                              icon="WARNING_CIRCLE"
                              fill="PRIMARY"
                            />
                            <FormattedMessage id="yearbook add text" />
                          </>
                        ) : (
                          <WordWrap>
                            {shortenText(100, profile.yearbook_text)}
                          </WordWrap>
                        )}
                      </TextElement>
                    </EveryCardBody>
                  </EveryCardPadding>
                </EveryCard>
              </Link>
            </ListStackBlock>
          </>
        ) : (
          <Paragraph>
            <FormattedMessage id="Yearbook not active" />
          </Paragraph>
        )}
      </>
    );
  }

  private renderText() {
    const { factsheetsStore, form } = this.props;
    const profile = factsheetsStore.profileItem;

    if (!profile) {
      return null;
    }

    let extra;
    if (factsheetsStore.isProfileItemError) {
      extra = <GenericErrorComponent />;
    }

    return this.renderPage(
      <>
        <Headline.Large>
          {profile.fullName || <FormattedMessage id="Text" />}
        </Headline.Large>

        {extra}

        <TextareaComponent
          name="yearbook_text"
          label={<FormattedMessage id="Text" />}
          {...form.bindInput('yearbook_text')}
          autoFocus={true}
        />

        <ButtonBlock background="PRIMARY" onClick={() => this.updateText()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>,
      true
    );
  }

  private renderPhoto() {
    const profile = this.props.factsheetsStore.profileItem;
    if (!profile) {
      return null;
    }

    const photo = profile.yearbook_photo;

    return (
      <PhotoEditScreen
        photoId={photo?.id}
        photoType="yearbook"
        parentId={profile.id}
        frameWidth={250}
        frameHeight={250}
        fitToFrameEnabled={false}
        allowDelete={true}
        allowEditCaption={false}
        isImageRounded={false}
        isEditorEnabled={true}
        backRoute={ROUTE_YEARBOOKS + '/detail/' + profile.id}
        backIsCancel={true}
      />
    );
  }

  private renderPage(content: ReactNode, cancelLink = false) {
    const chapter = this.props.factsheetsStore.profileItemChapter;

    return (
      <>
        <TopActionBlock>
          {cancelLink ? (
            <CancelLink
              to={ROUTE_YEARBOOKS + '/detail/' + idFromMatch(this.props.match)}
            />
          ) : (
            <BackLink
              to={
                this.props.applicationStore.isOrganizer && chapter
                  ? ROUTE_YEARBOOKS + '/' + chapter.id
                  : ROUTE_CONTENT
              }
            />
          )}
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="yearbook" />
      </>
    );
  }

  render() {
    const { factsheetsStore } = this.props;

    if (factsheetsStore.isProfileItemLoading) {
      return this.renderLoading();
    }

    if (factsheetsStore.profileItemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (factsheetsStore.profileItemLoadingState === 'no_profile_chapter') {
      // update errors need to keep displaying the form
      return this.renderNoChapterError();
    }

    if (this.props.text) {
      return this.renderText();
    }

    if (this.props.photo) {
      return this.renderPhoto();
    }

    return this.renderYearbook();
  }
}

export default (props: YearbookDetailScreenBaseProps) => {
  const form = useForm();
  // @ts-ignore
  return <YearbookDetailScreen {...props} form={form} />;
};
