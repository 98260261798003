import React from 'react';

import RankingsTemplateComponent, {
  Ranking,
  RankingsTemplateConfig
} from '../templates/RankingsTemplateComponent';
import { CommonLayoutPreviewProps } from './CommonLayoutPreviewProps';
import scalePreview from './scale-preview';

interface RankingsPreviewComponentProps extends CommonLayoutPreviewProps {
  config: RankingsTemplateConfig;

  rankings?: Ranking[];
}

export class RankingsPreviewComponent extends React.Component<
  RankingsPreviewComponentProps
> {
  shouldComponentUpdate() {
    // preview will be forced to redraw on config update only
    return false;
  }

  generateRankings(): Ranking[] {
    return [
      {
        title: 'Verwirrendstes Tafelbild',
        contestants: ['Herr Kohl', 'Frau Neustadt', 'Frau Osterhagen']
      },
      {
        title: 'Größte Handyabnahmegefahr',
        contestants: ['Herr Neudorf', 'Frau Pfaff', 'Herr Fisher']
      },
      {
        title: 'Wer kann nicht ohne Beamer?',
        contestants: ['Frau Baumgartner', 'Herr Roth', 'Herr Eiffel']
      },
      {
        title: 'Lustigste/r Lehrer/in',
        contestants: ['Frau Pfaff', 'Herr Kohl', 'Herr Fisher']
      },
      {
        title: 'Coolste Sprüche',
        contestants: ['Frau Strauss', 'Herr Saenger', 'Frau Eisenhower']
      },
      {
        title: 'Individuellster Kleidungsstil',
        contestants: ['Herr Roth', 'Herr Trommler', 'Frau Pfaff']
      },
      {
        title: 'Größte Allgemeinbildung',
        contestants: ['Herr Fisher', 'Frau Baumgartner', 'Frau Neustadt']
      },
      {
        title: 'Medienlegastheniker',
        contestants: ['Herr Saenger', 'Herr Kohl', 'Herr Eiffel']
      }
    ];
  }

  render() {
    const {
      print,
      config,
      colors,
      pageHeaderStyle,
      sectionHeaderStyle,
      background,
      start,
      scaled,
      chapter,
      title,
      rankings
    } = this.props;

    return (
      <RankingsTemplateComponent
        print={print}
        config={config}
        colors={colors || chapter?.color_scheme?.templateColors || []}
        pageHeaderStyle={pageHeaderStyle || chapter?.font?.pageHeaderStyle}
        sectionHeaderStyle={
          sectionHeaderStyle || chapter?.font?.sectionHeaderStyle
        }
        background={
          background ||
          (chapter?.background?.id || '') +
            (chapter?.background?.extension || '') ||
          undefined
        }
        startPosition={start || 'left'}
        startPage={this.props.startPage || 1}
        render={
          !scaled ? undefined : (content, key) => scalePreview(content, key)
        }
        title={title || chapter?.title}
        rankings={rankings || this.generateRankings()}
      />
    );
  }
}

export default RankingsPreviewComponent;
