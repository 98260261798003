import styled from 'styled-components';
import ButtonBlock from 'blocks/ButtonBlock';

export default styled.div`
  display: block;
  text-align: center;

  > ${ButtonBlock} {
    margin-top: 1.875rem;
  }
`;
