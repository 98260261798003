import { types, Instance } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import numberOrUndefined from 'utils/store/numberOrUndefined';

const ContentDashboardModel = types.model('ContentDashboardModel', {
  has_factsheet: types.maybe(types.boolean),
  questions_count: types.maybe(types.number),
  answers_count: types.maybe(types.number),
  has_slot1_photo: types.maybe(types.boolean),
  has_slot2_photo: types.maybe(types.boolean),
  has_slot3_photo: types.maybe(types.boolean),
  has_creative_page: types.maybe(types.boolean),
  has_text: types.maybe(types.boolean),

  comments_count: types.maybe(types.number),
  comments_as_author_count: types.maybe(types.number),

  has_yearbook_text: types.maybe(types.boolean),
  has_yearbook_photo: types.maybe(types.boolean)
});

export const createContentDashboardModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return ContentDashboardModel.create({
    has_factsheet: booleanOrUndefined(data.has_factsheet),
    questions_count: numberOrUndefined(data.questions_count),
    answers_count: numberOrUndefined(data.answers_count),
    has_slot1_photo: booleanOrUndefined(data.has_slot1_photo),
    has_slot2_photo: booleanOrUndefined(data.has_slot2_photo),
    has_slot3_photo: booleanOrUndefined(data.has_slot3_photo),
    has_creative_page: booleanOrUndefined(data.has_creative_page),
    has_text: booleanOrUndefined(data.has_text),

    comments_count: numberOrUndefined(data.comments_count),
    comments_as_author_count: numberOrUndefined(data.comments_as_author_count),

    has_yearbook_text: booleanOrUndefined(data.has_yearbook_text),
    has_yearbook_photo: booleanOrUndefined(data.has_yearbook_photo)
  });
};

export type ContentDashboardModelType = Instance<typeof ContentDashboardModel>;
export default ContentDashboardModel;
