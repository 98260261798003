export interface Color {
  name: string;
  value: string;
}

export type ColorScheme = Color[];

export const extractColor = (find: string, colors?: ColorScheme) => {
  if (!colors?.length) {
    return undefined;
  }

  for (const color of colors) {
    if (color.name === find) {
      return color.value;
    }
  }

  return undefined;
};
