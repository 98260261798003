import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import InfoBoxComponent from 'components/InfoBoxComponent/InfoBoxComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import TrustSymbolsComponent from 'components/TrustSymbolsComponent';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import Cookies from 'js-cookie';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { BookPriceStoreType } from 'models/BookPriceStore';
import { BindingType } from 'models/CheckoutModel';
import { CheckoutStoreType } from 'screens/checkout/CheckoutStore';
import {
  ROUTE_CHECKOUT,
  ROUTE_CHECKOUT_CHANGE_AUTHOR,
  ROUTE_DASHBOARD,
  ROUTE_OFFER,
  ROUTE_ORDER
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import { FormType, useForm } from 'utils/hooks/useForm';

import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { PriceTier } from 'models/BookModel';
import CalculatorButtons from './screens/CalculatorButtons';
import CalculatorForm from './screens/CalculatorForm';
import CalculatorPrice from './screens/CalculatorPrice';
import PrintFormat from './screens/PrintFormat';

interface Props {
  applicationStore: ApplicationStoreType;
  bookPriceStore: BookPriceStoreType;
  checkoutStore: CheckoutStoreType;
  form: FormType;
}

interface FormValues {
  page_count: number;
  pieces: number;
  binding_type: BindingType;
  price_tier: PriceTier;
}

@inject('applicationStore', 'bookPriceStore', 'checkoutStore')
@observer
class Calculator extends React.Component<Props & HistoryProps> {
  sendTimeoutHandle?: number;

  state = {
    showCheckoutStarted: false
  };

  componentDidMount() {
    if (this.props.applicationStore.onboardFeature('print')) {
      return;
    }

    this.loadBook();
  }

  changeInput = () => {
    this.props.form.setLoading(true);
    this.props.form.resetErrors();
    this.scheduleGetPrice();
  };

  scheduleGetPrice() {
    window.clearTimeout(this.sendTimeoutHandle);
    this.sendTimeoutHandle = window.setTimeout(() => this.getPrice(), 1000);
  }

  async getPrice() {
    const { form } = this.props;

    form.resetErrors();
    form.setLoading(true);

    form.values.pieces = form.values.pieces === '' ? 0 : form.values.pieces;
    form.values.page_count =
      form.values.page_count === '' ? 0 : form.values.page_count;

    await this.props.bookPriceStore.calculateBookPrice(
      form.values.page_count,
      form.values.pieces,
      form.values.binding_type,
      form.values.price_tier
    );

    const { bookPrice } = this.props.bookPriceStore;

    if (bookPrice) {
      if (bookPrice.is_pages_rounded) {
        form.setField('page_count', bookPrice.page_count);
      }
      if (bookPrice.is_pieces_rounded) {
        form.setField('pieces', bookPrice.pieces);
      }
    }

    form.setLoading(false);
  }

  async loadBook() {
    await this.props.applicationStore.getBook();
    const { book } = this.props.applicationStore;

    if (book) {
      if (
        book.orderAtLeastPaymentOpen &&
        !this.props.location.state?.redirected
      ) {
        this.props.history.push(ROUTE_ORDER);
        return;
      }

      const priceTier =
        book.price_tier === 'default' || !book.price_tier
          ? 'silver'
          : book.price_tier;
      if (book.isPreviewReady) {
        this.props.form.setField('page_count', book.rounded_pdf_pages_count);
      }
      if (book.custom_pdf_pages_count && priceTier === 'silver') {
        this.props.form.setField('page_count', book.custom_pdf_pages_count);
        if (book.custom_binding_type !== 'no_binding_type') {
          this.props.form.setField('binding_type', book.custom_binding_type);
        }
      }
      this.props.form.setField('price_tier', priceTier);
      this.getPrice();
      this.getCheckout();
    }
  }

  async getCheckout() {
    try {
      await this.props.checkoutStore.getCheckout();
    } catch (error: any) {
      // nothing to do here
    }
  }

  offerAsPDF() {
    this.props.history.push(ROUTE_OFFER);
  }

  /**
   * Create a new checkout or continues the existing with the new
   */
  async startCheckout() {
    const { checkoutStore, form, applicationStore } = this.props;
    const { book } = applicationStore;
    const { checkout } = checkoutStore;

    if (book && book.order_state !== 'open') {
      this.props.history.replace(ROUTE_ORDER);
      return;
    }

    const patch = {
      context: 'pricing',
      pieces: form.values.pieces,
      binding_type: form.values.binding_type
    } as const;
    let hasError = false;
    if (checkout) {
      try {
        await checkoutStore.updateCheckout(patch);
      } catch (error: any) {
        hasError = true;
        if (error.response.status === 401) {
          this.setState({
            showCheckoutStarted: true
          });
        }
      }
    } else {
      try {
        await checkoutStore.createCheckout(patch);
      } catch (error: any) {
        hasError = true;
        if (error.response.status === 401) {
          this.setState({
            showCheckoutStarted: true
          });
        }
      }
    }
    if (!hasError) {
      this.props.history.push(ROUTE_CHECKOUT);
    }
  }

  renderConfirmChangeAuthor() {
    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="Checkout already started header" />}
        text={<FormattedMessage id="Checkout already started text" />}
        confirmText={<FormattedMessage id="Checkout want to order" />}
        abortText={<FormattedMessage id="Cancel" />}
        onAbort={() => {
          this.setState({ showCheckoutStarted: false });
        }}
        onConfirm={() => {
          this.setState({ showCheckoutStarted: false });
          this.props.history.push(ROUTE_CHECKOUT_CHANGE_AUTHOR);
        }}
        confirmColor="PRIMARY"
      />
    );
  }

  renderPage(content: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_DASHBOARD} />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="Print service" />}
            text={<FormattedMessage id="Print service info" />}
          />
          {content}
          <HelpSubject subject="print" />
        </PageStackBlock>
      </>
    );
  }

  renderNoTierCalculator() {
    const { applicationStore, bookPriceStore, form } = this.props;
    const { isOrganizer, book } = applicationStore;
    const { loadingState, bookPrice, errors } = bookPriceStore;
    const bindingType: BindingType = form.values.binding_type;
    const isLoading = form.loading;

    if (!book) {
      return null;
    }

    return this.renderPage(
      <>
        <Divider />

        <Headline.Medium>
          <FormattedMessage id="Checkout order details" />
        </Headline.Medium>

        <PrintFormat />

        <ItemStackBlock gap="L">
          <CalculatorForm
            form={form}
            changeInput={this.changeInput}
            bookPrice={bookPrice}
            type={'calculator'}
            errors={errors}
            priceTier={'default'}
          />

          <Divider />

          {isLoading ? (
            this.renderLoading()
          ) : (
            <>
              {loadingState === 'loaded' && bookPrice && (
                <>
                  <CalculatorPrice
                    form={form}
                    bookPrice={bookPrice}
                    bindingType={bindingType}
                    type={'calculator'}
                  />
                  {!book.isLockedForOrder &&
                    isOrganizer &&
                    ((bookPrice.no_hardcover_allowed &&
                      form.values.binding_type === 'softcover') ||
                      !bookPrice.no_hardcover_allowed) && (
                      <CalculatorButtons
                        type={'calculator'}
                        priceTier="default"
                        hasCheckout={false}
                        offerAsPDF={() => this.offerAsPDF()}
                        startCheckout={() => this.startCheckout()}
                      />
                    )}
                </>
              )}
              {loadingState === 'error' && this.renderError()}
            </>
          )}

          {this.state.showCheckoutStarted && this.renderConfirmChangeAuthor()}

          <IconListBlock>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="Free shipping" />
            </IconListElement>
            <IconListDivider />
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="Climate neutral print" />
            </IconListElement>
          </IconListBlock>

          <TrustSymbolsComponent />
        </ItemStackBlock>
      </>
    );
  }

  renderSilverCalculator() {
    const {
      applicationStore,
      bookPriceStore,
      checkoutStore,
      form
    } = this.props;
    const { book, isOrganizer } = applicationStore;
    const { loadingState, bookPrice, errors } = bookPriceStore;
    const bindingType: BindingType = form.values.binding_type;
    const isLoading = form.loading;
    const hasCheckout = !!checkoutStore.checkout;

    if (!book || book.price_tier !== 'silver') {
      return null;
    }

    const calculatorType = book.silverReadyForCheckout
      ? 'checkout'
      : 'calculator';

    return this.renderPage(
      <>
        <Divider />

        <Headline.Medium>
          <FormattedMessage id="Checkout order details" />
        </Headline.Medium>

        <PrintFormat />

        <ItemStackBlock gap="L">
          <CalculatorForm
            form={form}
            changeInput={this.changeInput}
            bookPrice={bookPrice}
            type={calculatorType}
            errors={errors}
            priceTier={'silver'}
            customBindingType={book.custom_binding_type}
          />

          <Divider />

          {isLoading ? (
            this.renderLoading()
          ) : (
            <>
              {loadingState === 'loaded' && bookPrice && (
                <>
                  <CalculatorPrice
                    form={form}
                    bookPrice={bookPrice}
                    bindingType={bindingType}
                    type={calculatorType}
                  />
                  {!book.isLockedForOrder &&
                    isOrganizer &&
                    ((bookPrice.no_hardcover_allowed &&
                      form.values.binding_type === 'softcover') ||
                      !bookPrice.no_hardcover_allowed) && (
                      <CalculatorButtons
                        type={calculatorType}
                        priceTier="silver"
                        hasCheckout={hasCheckout}
                        offerAsPDF={() => this.offerAsPDF()}
                        startCheckout={() => this.startCheckout()}
                      />
                    )}
                </>
              )}
              {loadingState === 'error' && this.renderError()}
            </>
          )}

          {this.state.showCheckoutStarted && this.renderConfirmChangeAuthor()}

          <IconListBlock>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="Free shipping" />
            </IconListElement>
            <IconListDivider />
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="Climate neutral print" />
            </IconListElement>
          </IconListBlock>

          <TrustSymbolsComponent />
        </ItemStackBlock>
      </>
    );
  }

  renderCalculator() {
    const {
      applicationStore,
      bookPriceStore,
      checkoutStore,
      form
    } = this.props;
    const { loadingState, bookPrice, errors } = bookPriceStore;
    const { book, isOrganizer } = applicationStore;
    const hasCheckout = !!checkoutStore.checkout;

    if (!book || book.price_tier === undefined) {
      return null;
    }

    const calculatorType = book.isCheckoutReady ? 'checkout' : 'calculator';

    const bindingType: BindingType = form.values.binding_type;

    const isLoading = form.loading;
    return this.renderPage(
      <>
        <Divider />

        <Headline.Medium>
          <FormattedMessage id="Checkout order details" />
        </Headline.Medium>

        <PrintFormat />

        <ItemStackBlock gap="L">
          <CalculatorForm
            form={form}
            changeInput={this.changeInput}
            bookPrice={bookPrice}
            type={calculatorType}
            errors={errors}
            priceTier={book.price_tier || 'default'}
          />

          <Divider />

          {isLoading ? (
            this.renderLoading()
          ) : (
            <>
              {loadingState === 'loaded' && bookPrice && (
                <>
                  <CalculatorPrice
                    form={form}
                    bookPrice={bookPrice}
                    bindingType={bindingType}
                    type={calculatorType}
                  />
                  {!book.isLockedForOrder &&
                    isOrganizer &&
                    ((bookPrice.no_hardcover_allowed &&
                      form.values.binding_type === 'softcover') ||
                      !bookPrice.no_hardcover_allowed) && (
                      <CalculatorButtons
                        type={calculatorType}
                        priceTier={book.price_tier}
                        hasCheckout={hasCheckout}
                        offerAsPDF={() => this.offerAsPDF()}
                        startCheckout={() => this.startCheckout()}
                      />
                    )}
                </>
              )}
              {loadingState === 'error' && this.renderError()}
            </>
          )}

          {this.state.showCheckoutStarted && this.renderConfirmChangeAuthor()}

          <IconListBlock>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="Free shipping" />
            </IconListElement>
            <IconListDivider />
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="Climate neutral print" />
            </IconListElement>
          </IconListBlock>

          <TrustSymbolsComponent />
        </ItemStackBlock>
      </>
    );
  }

  render() {
    const { applicationStore } = this.props;
    const { book } = applicationStore;

    if (!book) {
      return <GenericErrorComponent onRetryClick={() => this.loadBook()} />;
    }

    switch (book.price_tier) {
      case 'default':
        return this.renderNoTierCalculator();
      case undefined:
        return this.renderNoTierCalculator();
      case 'silver':
        return this.renderSilverCalculator();
      default:
        return this.renderCalculator();
    }
  }

  renderLoading() {
    return <LoadingIndicatorComponent />;
  }

  renderError() {
    const { form } = this.props;
    const hasFormErrors = Object.keys(form.errors).length > 0;
    return hasFormErrors ? (
      <InfoBoxComponent error={true}>
        <FormattedMessage id="calculator error" />
      </InfoBoxComponent>
    ) : (
      <GenericErrorComponent onRetryClick={() => this.getPrice()} />
    );
  }
}

const CalculatorScreen = (props: any) => {
  const formValues: FormValues = {
    page_count: 100,
    pieces: 150,
    binding_type: 'softcover',
    price_tier: 'silver'
  };

  // Read last config from cookie
  const cookie = Cookies.get('pricing_config');
  if (cookie) {
    let cookieValues;
    try {
      cookieValues = JSON.parse(cookie);
    } catch (e) {}
    if (typeof cookieValues === 'object') {
      if (cookieValues.page_count !== undefined) {
        formValues.page_count = parseInt(cookieValues.page_count, 10);
      }
      if (cookieValues.pieces !== undefined) {
        formValues.pieces = parseInt(cookieValues.pieces, 10);
      }
      if (cookieValues.binding_type !== undefined) {
        formValues.binding_type = cookieValues.binding_type;
      }
    }
  }
  const form = useForm(formValues);
  return <Calculator {...props} form={form} />;
};

export default CalculatorScreen;
