import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import BackLink from 'domain/Links/BackLink';
import { intl } from 'i18n';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';

import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface TocScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  form: FormType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class TocAddEntry extends React.Component<TocScreenProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature('toc', this.props.location.pathname)
    ) {
      return;
    }

    this.loadChapter();
  }

  async loadChapter() {
    const chapterId = chapterIdFromMatch(this.props.match);
    if (chapterId) {
      await this.props.chaptersStore.getChapter(chapterId);
    }
    this.fillForm();
  }

  fillForm() {
    const { item } = this.props.chaptersStore;

    if (item) {
      this.props.form.setField('toc_title', item.title);
    }
  }

  getCurrentChapterId(): number | undefined {
    const id = this.props.history.location.state?.id || undefined;

    if (!id) {
      return undefined;
    }

    return typeof id === 'number' ? id : parseInt(id || '0', 10) || undefined;
  }

  navigateToChapter() {
    const id = this.getCurrentChapterId();
    if (!id) {
      return '/app/book/content';
    } else {
      return '/app/book/toc/' + id;
    }
  }

  async handleSubmit() {
    const { chaptersStore, applicationStore, form } = this.props;
    const chapter = chaptersStore.item;
    if (!chapter) {
      return;
    }

    form.setLoading(true);

    try {
      await chaptersStore.updateChapter(chapter.id, {
        toc_title: form.values.toc_title,
        list_in_toc: true
      });
    } catch (error: any) {
      if (handleFormError(form, error)) {
        form.setLoading(false);
      }

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'change save error' }),
        'error'
      );

      return;
    }
    applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'change saved' })
    );

    this.props.history.push(this.navigateToChapter());
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadChapter()} />
    );
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <BackLink to={this.navigateToChapter()} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="toc" />
      </>
    );
  }

  render() {
    const { chaptersStore, form } = this.props;

    if (chaptersStore.isItemLoading) {
      return this.renderLoading();
    }

    if (chaptersStore.isItemError) {
      return this.renderError();
    }

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Choose chapter title" />} text={<FormattedMessage id="Choose chapter text" />} />

        <TextInputComponent
          name="toc_title"
          label={<FormattedMessage id="toc chapter title" />}
          {...form.bindInput('toc_title')}
        />

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  }
}

const TocAddEntryScreen = (props: any) => {
  const form = useForm();
  return <TocAddEntry {...props} form={form} />;
};

export default TocAddEntryScreen;
