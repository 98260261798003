import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard, EveryCardHeadline, EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import { ExternalLinkBlock } from 'blocks/LinkBlock/LinkBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import config from 'config';
import TeamCallContainer from 'containers/TeamCallContainer/TeamCallContainer';
import BackClickLink from 'domain/Links/BackClickLink';
import BackLink from 'domain/Links/BackLink';
import StudentsList from 'domain/StudentsList/StudentsList';
import Headline from 'elements/Headline/index';
import NakedButton from 'elements/NakedButton';
import WordWrap from 'elements/WordWrap';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';

// @ts-ignore
import HELP_JSON from './help-de.json';

interface Help {
  name?: string;
  questions?: { question: string }[];
}

interface HelpScreenProps {
  applicationStore: ApplicationStoreType;
  form: FormType;
}

interface HelpScreenState {
  displayForm?: boolean;
  loadingState?: 'loading' | 'error' | 'called';
}

@inject('applicationStore')
@observer
class HelpScreen extends React.Component<
HelpScreenProps & HistoryProps,
HelpScreenState
> {
  state: HelpScreenState = {};

  private getKey() {
    return this.props.match.params.feature || undefined;
  }

  private getHelp(): Help {
    const key = this.getKey();
    if (!key) {
      return HELP_JSON._default;
    }

    return HELP_JSON[key] || HELP_JSON._default;
  }

  private async call(question?: string, isUserQuestion: boolean = false) {
    const { state: locationState } = this.props.location;

    this.setState({
      loadingState: 'loading'
    });

    const key = this.getKey();
    const help = this.getHelp();

    const referrer = locationState?.referrer || undefined;
    const message =
      (help.name || key) +
      (referrer ? ' (' + referrer + ')' : '') +
      (question ? ': ' +   (isUserQuestion ? "Custom: " + question : question)  : '');

    try {
      await this.props.applicationStore.createSupportTicket(
        isUserQuestion
          ? 'help_section_form' : 'help_section_click',
        message
      );

      this.setState({
        loadingState: 'called'
      });
    } catch (error: any) {
      this.setState({
        loadingState: 'error'
      });
    }
  }

  private renderPage(content?: any) {
    const backRoute = this.props.location.state?.backRoute;

    return (
      <>
        <TopActionBlock>
          {backRoute ? (
            <BackLink to={backRoute} replace={true} />
          ) : (
            <BackClickLink onClick={() => this.props.history.goBack()} />
          )}
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        {this.state.loadingState === 'loading' && <LoadingOverlayComponent />}

        <HelpSubject hidden={true} />
      </>
    );
  }

  private renderList() {
    const help = this.getHelp();

    const hasAnyQuestions = !!help.questions?.length;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="help questions header" />
        </Headline.Large>

        {this.state.loadingState === 'error' && <GenericErrorComponent />}

        <ListStackBlock>
          {!hasAnyQuestions
            ? null
            : help.questions!.map((question, idx) => (
              <NakedButton
                key={idx}
                onClick={() => this.call(question.question)}
              >
                <EveryCard>
                  <EveryCardPadding>
                    <EveryCardHeadline>
                      {question.question}
                    </EveryCardHeadline>
                  </EveryCardPadding>
                </EveryCard>
              </NakedButton>
            ))}
        </ListStackBlock>

        <ItemStackBlock gap="XS">
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => this.setState({ displayForm: true })}
          >
            <FormattedMessage
              id={hasAnyQuestions ? 'Other question' : 'I have a question'}
            />
          </ButtonBlock>

          <ExternalLinkBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            href={'tel:' + config.callSupportNumber}
          >
            <FormattedMessage id="Call support" />
          </ExternalLinkBlock>

          {/* <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => this.call()}
          >
            <FormattedMessage id="Call support" />
          </ButtonBlock> */}
        </ItemStackBlock>
      </>
    );
  }

  private renderForm() {
    const { form } = this.props;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Ask question" />
        </Headline.Large>

        {this.state.loadingState === 'error' && <GenericErrorComponent />}

        <TextareaComponent
          autoFocus={true}
          name="question"
          label={<FormattedMessage id="Your question" />}
          {...form.bindInput('question')}
        />

        <ButtonBlock
          background="PRIMARY"
          color="WHITE"
          onClick={() => this.call(form.values.question, true)}
        >
          <FormattedMessage id="Send" />
        </ButtonBlock>
      </>
    );
  }

  private renderCalled() {
    return this.renderPage(
      <TeamCallContainer
        backTo="none"
        onOkClick={() => {
          const backRoute = this.props.location.state?.backRoute;

          if (!backRoute) {
            this.props.history.goBack();
            return;
          }

          this.props.history.replace(backRoute);
        }}
      />
    );
  }

  private renderStudentView() {
    return this.renderPage(
      <>
        <Headline.Large>
          <WordWrap>
            <FormattedMessage id="help students header" />
          </WordWrap>
        </Headline.Large>

        <StudentsList organizers={true} />
      </>
    );
  }

  render() {
    if (!this.props.applicationStore.isOrganizer) {
      return this.renderStudentView();
    }

    if (this.state.loadingState === 'called') {
      return this.renderCalled();
    }

    if (this.state.displayForm) {
      return this.renderForm();
    }

    return this.renderList();
  }
}

export default (props: any) => {
  const form = useForm();
  // @ts-ignore
  return <HelpScreen {...props} form={form} />;
};
