import { Boundary, Space } from 'utils/chunker/ContentChunker';

export interface Column {
  size: Boundary;
  position: Boundary;
}

export interface MultiColumnLayout {
  primaryColumnsListLeft?: Column[];
  primaryColumnsListRight?: Column[];
  additionalColumnsListLeft?: Column[];
  additionalColumnsListRight?: Column[];
}

export const getColumns = (
  config: MultiColumnLayout,
  isAdditional: boolean = false,
  isRight: boolean = false
) => {
  let list: Column[] | undefined;

  if (isAdditional) {
    list = isRight
      ? config.additionalColumnsListRight ||
        config.additionalColumnsListLeft ||
        config.primaryColumnsListRight ||
        config.primaryColumnsListLeft
      : config.additionalColumnsListLeft || config.primaryColumnsListLeft;
  } else {
    list = isRight
      ? config.primaryColumnsListRight || config.primaryColumnsListLeft
      : config.primaryColumnsListLeft;
  }

  return list;
};

export const spaceFromConfig = (
  config: MultiColumnLayout,
  isFirstPageRight: boolean = false
): Space => {
  const space: Space = [];

  const primary = getColumns(config, false, isFirstPageRight);
  space.push(!primary ? [] : primary.map((column) => column.size));

  const additional = getColumns(config, true);
  space.push(!additional ? [] : additional.map((column) => column.size));

  return space;
};
