import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import ContainerBlock from 'blocks/ContainerBlock';
import GlobalStyleBlock from 'blocks/GlobalStyleBlock';
import Loading from 'screens/development/Loading';
import MottoDashboard from 'screens/development/MottoDashboard';
import PlainHTML from 'screens/development/PlainHTML';
import Redesign2020 from 'screens/development/Redesign2020';
import Split from 'screens/development/Split';
// import StyleGuide from 'screens/development/StyleGuide';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import FunnelRouter from 'screens/funnels/FunnelRouter';
import PrintRouter from 'screens/print/PrintRouter';
import LayoutPreviewContainer from 'screens/print/layout-preview/LayoutPreviewContainer';
import SignupRouter from 'screens/signup/SignupRouter';
import AuthorizeScreen from 'screens/welcome/AuthorizeScreen';
import IndexScreen from 'screens/welcome/IndexScreen';
import LoginScreen from 'screens/welcome/LoginScreen';
import PasswordResetScreen from 'screens/welcome/PasswordResetScreen';
import SupportScreen from 'screens/welcome/SupportScreen';
import {
  ROUTE_AUTH,
  ROUTE_FUNNELS,
  ROUTE_JOIN,
  ROUTE_LAYOUT_PREVIEW,
  ROUTE_SIGNUP,
  ROUTE_SUPPORT
} from 'utils/constants/routes';
import { history } from 'utils/history';

import useCustomQueryClient from 'api/CustomQueryClient';
import ApplicationFlashMessageComponent from 'components/FlashMessageComponent/ApplicationFlashMessageComponent';
import FlashMessageComponent from 'components/FlashMessageComponent/FlashMessageComponent';
import { FlashMessageProvider } from 'contexts/FlashMessageContext';
import AuthorizedRoutes from './AuthorizedRoutes';

// Create a client

const App = () => {
  const queryClient = useCustomQueryClient(history);
  return (

  <QueryClientProvider client={queryClient}>
    <Router history={history}>
      <GlobalStyleBlock
        noColor={history.location.pathname.split('/')[1] === 'print'}
      />
      <Switch>
        {process.env.NODE_ENV === 'development' && (
          <Route path="/print" component={PrintRouter} />
        )}

        <Route path={ROUTE_LAYOUT_PREVIEW} component={LayoutPreviewContainer} />
        <FlashMessageProvider>
          <ContainerBlock>
            <FlashMessageComponent />
            <ApplicationFlashMessageComponent />
            <Switch>
              <Route path="/" exact={true} component={IndexScreen} />

              <Route path="/reset/:token" component={PasswordResetScreen} />
              <Route path="/verify/:token" component={AuthorizeScreen} />
              <Route path="/login/:token" component={AuthorizeScreen} />
              <Route path="/login" component={LoginScreen} />

              <Route path={ROUTE_JOIN + '/:token'} component={SignupRouter} />
              <Route path={ROUTE_SIGNUP} component={SignupRouter} />
              <Route path={ROUTE_SUPPORT} component={SupportScreen} />
              <Route path={ROUTE_AUTH} component={AuthorizedRoutes} />

              <Route path={ROUTE_FUNNELS} component={FunnelRouter} />

              {process.env.NODE_ENV === 'development' && (
                <Switch>
                  <Route path="/loading" component={Loading} />
                  <Route path="/plainhtml" component={PlainHTML} />
                  {/* <Route path="/styleguide" component={StyleGuide} /> */}
                  <Route path="/redesign-2020" component={Redesign2020} />
                  <Route path="/split-screen" component={Split} />
                  <Route path="/mottodashboard" component={MottoDashboard} />
                </Switch>
              )}

              <NotFoundRoute />
            </Switch>
          </ContainerBlock>
        </FlashMessageProvider>
      </Switch>
    </Router>
    {process.env.NODE_ENV === 'development' && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </QueryClientProvider>
  )
}

export default App;
