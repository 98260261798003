import { Instance, types } from 'mobx-state-tree';

const SchoolModel = types.model('SchoolModel', {
  id: types.identifierNumber,
  name: types.string,
  sub_name: types.maybe(types.string),
  city_name: types.maybe(types.string),
  zip_code: types.maybe(types.string),
  address: types.maybe(types.string),
  country: types.maybe(types.string),
  created_by_user: types.maybe(types.boolean)
});

export type SchoolModelType = Instance<typeof SchoolModel>;
export default SchoolModel;
