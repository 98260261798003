import React from 'react';

import style from 'styled-components';
import sanitizeHtml from 'utils/chunker/sanitize-html';

interface NamesContainerProps {
  width: number;
  font?: string;
  color?: string;
  maxHeight?: number;
  marginTop?: number;
}

interface ColumnPorps {
  fontSize?: number;
  width?: number;
}

const NamesContainer = style.div<NamesContainerProps>`
  justify-content: center;
  display: flex;
  width: ${props => props.width}px;
  max-height: ${props => props.maxHeight}px;
  font-family: ${props => props.font};
  color: ${props => props.color};
  margin-top: ${props => props.marginTop}px;

`;

const ColumnComponent = style.div<ColumnPorps>`
  line-height: 120%;
  font-size: ${props => props.fontSize}px;
  text-align: center;
  width: ${props => props.width}px;
`

const ColumnItem =style.p`
  &:not(:last-of-type) {
    padding-bottom: 7px;
  }
`


export default ({
  names,
  width,
  color,
  font,
  maxHeight,
  fontSize,
  columnWidth,
  marginTop,
}: {
  names: string[][];
  width: number,
  color?: string;
  font?: string;
  maxHeight?: number;
  fontSize?: number;
  columnWidth?: number;
  marginTop?: number;
}) => {

  return (
    <NamesContainer font={font} color={color} maxHeight={maxHeight} width={width} marginTop={marginTop}>

      {names.map((nameArray, idx) => {
        return (
        <ColumnComponent key={'column#' + idx} fontSize={fontSize} width={columnWidth}>
          {nameArray.map((name, idx) => {
            return (
              <ColumnItem key={'columnItem#' + idx + name} dangerouslySetInnerHTML={{ __html: sanitizeHtml(name) || '' }}/>
            )
          })}
        </ColumnComponent>
        )
      })}
    </NamesContainer>
  );
};
