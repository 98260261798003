import { Instance, types } from 'mobx-state-tree';

export const ChapterTypeEnum = types.enumeration([
  'unknown',
  'album',
  'factsheet',
  'teacher_factsheet',
  'quote',
  'text',
  'sponsor',
  'ranking',
  'table_of_contents',
  'imprint',
  'cover',
  'yearbook'
]);

export type ChapterType = Instance<typeof ChapterTypeEnum>;
