import React from 'react';
import { FormattedMessage } from 'react-intl';

import { STEPS } from 'utils/constants/student-numbers';
import Item from '../../containers/SettingsItem';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';

export default ({
  onSelect,
  selected
}: {
  onSelect: (e: any, value: number) => void;
  selected?: number;
}) => (
  <ListStackBlock>
    {STEPS.map((step: number | [number, string]) =>
      Array.isArray(step) ? (
        <Item key={step[0]} onClick={e => onSelect(e, step[0])} active={selected === step[0]} noArrow={true}>
          <FormattedMessage id={step[1]} />
        </Item>
      ) : (
        <Item key={step} onClick={e => onSelect(e, step)} active={selected === step} noArrow={true}>
          <FormattedMessage
            id="number of students"
            values={{
              number: step
            }}
          />
        </Item>
      )
    )}
  </ListStackBlock>
);
