import React from 'react';

import styled from 'styled-components';
// TODO make this "import {} from 'styled-components/cssprop';" after updating TS
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from 'styled-components/cssprop';
import sanitizeHtml from 'utils/chunker/sanitize-html';

export interface TocRow {
  title: string;
  page: string;
}

export type Toc = TocRow[];

const TocContainer = styled.div<{ color?: string; font?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 480px;
  height: 1004px;
  left: 190px;
  top: 84px;
  position: absolute;
  font-family: ${(props) => props.font};
  color: ${(props) => props.color};
`;

const TocHeader = styled.p`
  width: 100%;
  margin: 0;
  margin-bottom: 40px;
  margin-right: 33px;
`;

const TocRowComp = styled.div`
  display: flex;
  height: auto;
  margin-bottom: 16px;
  width: 100%;
  flex-direction: row-reverse;
  align-items: flex-end;

  div.number {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    flex-shrink: 0;
    width: auto;
    padding-left: 10px;
    text-align: right;
  }

  div.container {
    width: 100%;
    overflow: hidden;
  }

  p.title {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    margin-right: 10px;
    max-width: 95%;
    word-wrap: keep-all;

    span.text {
      word-break: break-word;
      white-space: normal;
      margin: 0;
    }

    span.dots {
      height: 20px;
      font-size: 16px;
      line-height: px;
      flex-grow: 1;
      flex-basis: 50px;
      display: inline-block;
      width: 10px;
      overflow: visible;
      margin: 0;
      padding-left: 10px;
    }
  }
`;

const TocDots = () => (
  <span className="dots">
    ...............................................................................................................................................................................................................................................................................................
  </span>
);

export const TocComponent = ({
  toc,
  header,
  headerFontStyle,
  fontColor
}: {
  toc?: Toc;
  header?: string;
  headerFontStyle?: string;
  fontColor?: string;
}) => {
  if (!toc || !toc.length) {
    return null;
  }

  return (
    <TocContainer>
      {header && (
        <TocHeader>
          <span
            css={headerFontStyle}
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(header) || '' }}
          />
        </TocHeader>
      )}

      {toc.map((row, idx) => (
        <TocRowComp key={'toc#' + idx} style={{ color: fontColor }}>
          <div className="number">{row.page}</div>
          <div className="container">
            <p className="title">
              <span
                className="text"
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(row.title) || ''
                }}
              />
              <TocDots />
            </p>
          </div>
        </TocRowComp>
      ))}
    </TocContainer>
  );
};

export default TocComponent;
