import React from 'react';

import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

export class TextileOfferScreen extends React.Component<
  HistoryProps
> {
  render() {
    return (
      <SupportTicketScreen
      subject="offer"
      backTo={textileOrderRoute(this.props.match, '/calculator')}
      organizerOnly={true}
      textileOrderId={textileOrderIdFromMatch(this.props.match)}
    />
    )
  }
}

export default TextileOfferScreen;
