import styled, { AnyStyledComponent } from 'styled-components';

import CheckboxElement from './CheckboxElement';
import LabelElement from './LabelElement';
import COLORS from 'utils/constants/colors';

interface CheckboxBlockElements {
  LabelElement?: any;
  CheckboxElement?: any;
}

interface CheckboxProps {
  bordered?: boolean;
}

const CheckboxBlock: AnyStyledComponent & CheckboxBlockElements = styled.label<
  CheckboxProps
>`
  display: block;

  & > ${LabelElement} {
    &::before {
      flex-shrink: 0;
    }
  }

  ${(props) =>
    props.bordered &&
    `
    & > ${LabelElement} {
      padding: 1rem;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 1px solid ${COLORS.GRAY800};
        border-radius: 0.625rem;
      }
    }

    ${CheckboxElement}:checked + ${LabelElement}::after {
        border-color: ${COLORS.PRIMARY};
    }


    ${CheckboxElement}:checked + ${LabelElement}::before {
      background-color: ${COLORS.PRIMARY};
    }
  `};
`;

CheckboxBlock.LabelElement = LabelElement;
CheckboxBlock.CheckboxElement = CheckboxElement;
export default CheckboxBlock;
