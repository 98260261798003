import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard, EveryCardBody,
  EveryCardHeadline, EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import StickyBottomContainerBlock from 'blocks/StickyBottomContainerBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Logo from 'components/HeaderComponent/Logo';
import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { SplitScreenSliderImage } from 'components/SplitScreenSlider/SplitScreenSlider';
import TeamCallContainer from 'containers/TeamCallContainer/TeamCallContainer';
import BackLink from 'domain/Links/BackLink';
import BackToContentLink from 'domain/Links/BackToContentLink';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import NakedButton from 'elements/NakedButton';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ClientStates } from 'models/ClientStateModel';
import { Link } from 'react-router-dom';
import {
  ROUTE_CALCULATOR, ROUTE_CHECKOUT, ROUTE_CONTENT, ROUTE_DASHBOARD, ROUTE_LAYOUT, ROUTE_MOTTOS_ORG, ROUTE_PDF_PREVIEW, ROUTE_SETTINGS, ROUTE_TIMEPLAN
} from 'utils/constants/routes';
import { HistoryProps, paramFromMatch } from 'utils/history';
import OnboardDots from './OnboardDots';

// @ts-ignore
import SCREENS_JSON from './onboard-de.json';

interface OnboardScreen {
  image?: string;
  headline?: string;
  text?: string;
}

interface Onboard {
  screens?: OnboardScreen[];
}

interface OnboardDescription {
  state?: keyof typeof ClientStates;
  route: string;
  backTo?: 'dashboard' | 'content' | string;
}

interface OnboardDescriptionWithKey {
  key: string;
  state?: keyof typeof ClientStates;
  route: string;
  backTo?: 'dashboard' | 'content' | string;
}

const FEATURES: {
  [key: string]: OnboardDescription;
} = {
  dashboard_student: {
    route: ROUTE_DASHBOARD
  },
  dashboard_organizer: {
    route: ROUTE_DASHBOARD
  },
  class: {
    route: ROUTE_SETTINGS + '/students',
    backTo: 'dashboard'
  },
  groups: {
    route: ROUTE_SETTINGS + '/groups',
    backTo: 'dashboard'
  },
  content: {
    route: ROUTE_CONTENT,
    backTo: 'dashboard'
  },
  design: {
    route: ROUTE_LAYOUT,
    backTo: 'dashboard'
  },
  motto: {
    route: ROUTE_MOTTOS_ORG,
    backTo: 'dashboard'
  },
  pdfpreview: {
    route: ROUTE_PDF_PREVIEW,
    backTo: 'content'
  },
  print: {
    route: ROUTE_CALCULATOR,
    backTo: 'dashboard'
  },
  checkout: {
    route: ROUTE_CHECKOUT,
    backTo: ROUTE_CALCULATOR
  },
  timeplan: {
    route: ROUTE_TIMEPLAN,
    backTo: 'dashboard'
  },

  photoeditor: {
    route: ROUTE_CONTENT
    // backTo: 'content'
  },

  // default configuration for content features which will provide their back route via location state
  _default: {
    route: ROUTE_CONTENT,
    backTo: 'content'
  }
};

const SCREENS: { [key: string]: Onboard } = SCREENS_JSON;

interface OnboardScreenProps {
  applicationStore: ApplicationStoreType;
}

interface OnboardScreenState {
  display?: 'question' | 'call';
  loadingState?: 'loading' | 'error';
  screenIndex?: number;
}

@inject('applicationStore')
@observer
class OnboardScreen extends React.Component<
OnboardScreenProps & HistoryProps,
OnboardScreenState
> {
  state: OnboardScreenState = {};

  componentDidMount() {
    if (!this.getOnboard()) {
      const feature = this.getFeature();

      if (feature?.key && FEATURES[feature.key]) {
        // no onboard present for this feature, so just confirm as seen and go on
        this.confirm();
        return;
      }

      // unknown feature, go back to dashboard as we do not know where to redirect
      this.props.history.replace(ROUTE_DASHBOARD);
    }
  }

  componentDidUpdate() {
    this.scrollFix();
  }

  private scrollFix() {
    window.scrollTo(0, 0);
  }

  private getFeature(): OnboardDescriptionWithKey | undefined {
    const key = paramFromMatch(this.props.match, 'feature') || undefined;
    if (!key) {
      return undefined;
    }

    const feature = FEATURES[key] || FEATURES._default;
    return { ...feature, key };
  }

  private getOnboard(): Onboard | undefined {
    const key = paramFromMatch(this.props.match, 'feature') || undefined;
    if (!key) {
      return undefined;
    }

    return SCREENS[key] || undefined;
  }

  private getBackRoute(feature: OnboardDescription) {
    return this.props.location?.state?.backRoute || feature.route;
  }

  private continue() {
    const feature = this.getFeature();

    if (!feature) {
      return;
    }

    this.props.history.replace(this.getBackRoute(feature));
  }

  private async confirm(call = false) {
    const feature = this.getFeature();

    if (!feature) {
      return;
    }

    const { applicationStore } = this.props;

    this.setState({
      loadingState: 'loading'
    });

    try {
      await applicationStore.updateClientState(
        feature.state || 'ob_' + feature.key,
        call ? intl.formatMessage({id: 'zap_ob_' + feature.key}) : 'ok',
        !call,
        call
      );
    } catch (error: any) {
      if (!call) {
        return;
      }

      this.setState({
        loadingState: 'error'
      });
      return;
    }

    if (call) {
      this.setState({
        loadingState: undefined,
        display: 'call'
      });
      return;
    }

    this.continue();
  }

  private call() {
    this.confirm(true);
  }

  private next() {
    const onboard = this.getOnboard();
    const index = (this.state.screenIndex || 0) + 1;

    if (!onboard?.screens?.[index]) {
      if (this.props.applicationStore.isOrganizer) {
        this.setState({
          display: 'question'
        });
        return;
      }

      // students should not see question screen, so exit already
      this.confirm();
      return;
    }

    this.setState({
      screenIndex: index
    });
  }

  private goTo(screenIndex: number) {
    this.setState({
      screenIndex
    });
  }

  private renderTopAction() {
    const feature = this.getFeature();

    return feature?.backTo ? (
      <TopActionBlock>
        {feature.backTo === 'dashboard' ? (
          <BackToDashboardLink />
        ) : feature.backTo === 'content' ? (
          <BackToContentLink />
        ) : (
          <BackLink to={feature.backTo} />
        )}
      </TopActionBlock>
    ) : (
      <TopActionBlock>
        <NonGapStackBlock />
        <Link to="/app">
          <Logo />
        </Link>
      </TopActionBlock>
    );
  }

  private renderPage(content?: any) {
    return (
      <>
        {this.renderTopAction()}

        <PageStackBlock>
          {content}
          {this.state.loadingState === 'loading' && <LoadingOverlayComponent />}
        </PageStackBlock>

        <HelpSubject hidden={true} />
      </>
    );
  }

  private renderQuestion() {
    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="onboard question header" />} text={<FormattedMessage id="onboard question" />} />

        <ListStackBlock>
          <NakedButton onClick={() => this.confirm()}>
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="onboard confirm" />
                  </EveryCardHeadline>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          </NakedButton>

          <NakedButton onClick={() => this.call()}>
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="onboard call now" />
                  </EveryCardHeadline>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          </NakedButton>
        </ListStackBlock>
      </>
    );
  }

  private renderCall() {
    const feature = this.getFeature();

    return (
      <TeamCallContainer
        backTo={feature?.backTo}
        onOkClick={() => this.continue()}
      />
    );
  }

  private renderOnboarding() {
    const onboard = this.getOnboard();
    const index = this.state.screenIndex || 0;

    const screen = onboard?.screens?.[index];
    if (!screen) {
      return this.renderQuestion();
    }

    const count = onboard!.screens!.length;

    return this.renderPage(
      <>
        <PageHeader headline={screen.headline && (screen.headline)} text={screen.text && (screen.text)} />

        {screen.image && (
          <SplitScreenSliderImage>
            <img
              src={'/images/onboard/' + screen.image}
              alt=""
              key={screen.image}
            />
          </SplitScreenSliderImage>
        )}

        <StickyBottomContainerBlock border={false}>
          <HorizontalStackBlock
            justified={true}
            style={{ paddingLeft: '15%', paddingRight: '5%' }}
          >
            <OnboardDots
              count={count}
              activeIndex={index}
              onDotClick={(i) => this.goTo(i)}
            />

            <ButtonBlock
              background="PRIMARY"
              color="WHITE"
              style={{
                paddingLeft: '15%',
                paddingRight: '15%'
              }}
              slim={true}
              inline={true}
              onClick={() => this.next()}
            >
              <FormattedMessage id="Next" />
            </ButtonBlock>
          </HorizontalStackBlock>
        </StickyBottomContainerBlock>

        {this.state.loadingState === 'loading' && <LoadingOverlayComponent />}
      </>
    );
  }

  render() {
    const feature = this.getFeature();
    if (!feature) {
      return null;
    }

    switch (this.state.display) {
      case 'question':
        return this.renderQuestion();

      case 'call':
        return this.renderCall();

      default:
    }

    return this.renderOnboarding();
  }
}

export default OnboardScreen;
