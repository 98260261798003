import React from 'react';

// TODO remove fallback entirely
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ItemStackBlock from 'blocks/ItemStackBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import Divider from 'components/Divider/Divider';
import config from 'config';
import Headline from 'elements/Headline';
import NakedButton from 'elements/NakedButton';
import { inject, observer } from 'mobx-react';
import { ChapterModelType } from 'models/ChapterModel';
import { FontModelType } from 'models/print/FontModel';
import { LayoutStoreType } from 'models/print/LayoutStore';
import { FormattedMessage } from 'react-intl';
import { SelectionContainerComponent } from './SelectionContainerComponent';

interface ChapterFontComponentProps {
  layoutStore?: LayoutStoreType;
  chapter: ChapterModelType;
  fontSelected: (key: string) => void;
  currentKey?: string;
}

@inject('layoutStore')
@observer
export class ChapterFontComponent extends React.Component<
  ChapterFontComponentProps
> {
  componentDidMount(): void {
    const { layoutStore } = this.props;

    const { fonts } = layoutStore!;

    if (!fonts) {
      this.loadFonts();
    }
  }

  async loadFonts() {
    const { layoutStore, chapter } = this.props;

    try {
      await layoutStore!.getFonts(chapter.chapter_type);
    } catch (error: any) {}
  }

  async selectFont(key: string) {
    const { fontSelected } = this.props;
    fontSelected(key);
  }
  filterUsableAssets(fonts: FontModelType[]) {
    return fonts.filter(
      (fonts) =>
        !fonts.tags.includes('private') && !fonts.tags.includes('unindexed')
    );
  }

  findCustomFonts() {
    const { layoutStore } = this.props;

    const fonts = this.filterUsableAssets(layoutStore!.allFonts);

    if (!fonts || fonts.length === 0) {
      return [];
    }

    return fonts.filter((font) => font.tags.includes('onlyBookFiltered'));
  }

  findRecommendedFonts() {
    const { layoutStore, chapter } = this.props;

    const fonts = this.filterUsableAssets(layoutStore!.allFonts);
    const theme = chapter.book_theme_key;

    if (!fonts || fonts.length === 0) {
      return [];
    }

    return fonts.filter(
      (font) =>
        (font.tags.includes(`recommendTheme:${theme}`) ||
          font.tags.includes('onlyThemeFiltered')) &&
        !font.tags.includes('onlyBookFiltered')
    );
  }

  findGlobalFonts() {
    const { layoutStore, chapter } = this.props;

    const fonts = this.filterUsableAssets(layoutStore!.allFonts);
    const theme = chapter.book_theme_key;

    if (!fonts || fonts.length === 0) {
      return [];
    }

    return fonts.filter(
      (font) =>
        !font.tags.includes('onlyBookFiltered') &&
        !font.tags.includes(`recommendTheme:${theme}`) &&
        !font.tags.includes('onlyThemeFiltered')
    );
  }

  render() {
    const { currentKey } = this.props;
    // const selected = layoutKey || chapter.layout_key;
    const customFonts: FontModelType[] = this.findCustomFonts();
    const recommendedFonts: FontModelType[] = this.findRecommendedFonts();
    const globalFonts: FontModelType[] = this.findGlobalFonts();

    return (
      <ItemStackBlock gap="L">
        <Headline.Large>
          <FormattedMessage id="choose chapter font" />
        </Headline.Large>

        <Divider />

        {customFonts.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Custom assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {customFonts.map((font) => (
                <NakedButton
                  key={font.id}
                  onClick={() => this.selectFont(font.id)}
                >
                  <SelectionContainerComponent
                    selected={currentKey === font.id}
                  >
                    {font.preview ? (
                      <img
                        src={
                          config.layout.baseUrl +
                          '/fonts/previews/' +
                          font.preview
                        }
                        alt=""
                      />
                    ) : (
                      font.id
                    )}
                  </SelectionContainerComponent>
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </>
        )}

        {recommendedFonts.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Recommended assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {recommendedFonts.map((font) => (
                <NakedButton
                  key={font.id}
                  onClick={() => this.selectFont(font.id)}
                >
                  <SelectionContainerComponent
                    selected={currentKey === font.id}
                  >
                    {font.preview ? (
                      <img
                        src={
                          config.layout.baseUrl +
                          '/fonts/previews/' +
                          font.preview
                        }
                        alt=""
                      />
                    ) : (
                      font.id
                    )}
                  </SelectionContainerComponent>
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </>
        )}

        {globalFonts.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Global assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {globalFonts.map((font) => (
                <NakedButton
                  key={font.id}
                  onClick={() => this.selectFont(font.id)}
                >
                  <SelectionContainerComponent
                    selected={currentKey === font.id}
                  >
                    {font.preview ? (
                      <img
                        src={
                          config.layout.baseUrl +
                          '/fonts/previews/' +
                          font.preview
                        }
                        alt=""
                      />
                    ) : (
                      font.id
                    )}
                  </SelectionContainerComponent>
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </>
        )}
      </ItemStackBlock>
    );
  }
}

export default ChapterFontComponent;
