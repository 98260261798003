import React from 'react';

import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import config from 'config';
import { inject, observer } from 'mobx-react';
import { SetupStoreType } from 'models/SetupStore';
import {
  WrappedComponentProps,
  injectIntl
} from 'react-intl';
import { funnelSite } from 'utils/analytics';
import { ROUTE_SIGNUP } from 'utils/constants/routes';
import { HistoryProps, paramFromMatch } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import { local } from 'utils/storage';
import SignupSchoolAddScreen from './SignupAddSchoolScreen';
import SignupSchoolCityScreen from './SignupSchoolCityScreen';
import SignupSchoolSelectScreen from './SignupSchoolSelectScreen';

interface SignupSchoolScreenProps {
  setupStore: SetupStoreType;
  form: FormType;
  token?: string;
}

const SCREENS = [
  'city_search',
  'school_search',
  'add_school',
  'add_city_and_school',
  'school_year'
] as const;
type Screen = typeof SCREENS[number];
const screenUrl = (screen: Screen) => `?screen=${screen}`;

@inject('setupStore')
@observer
class SignupSchoolScreen extends React.Component<
  SignupSchoolScreenProps & HistoryProps & WrappedComponentProps
> {
  componentDidMount() {
    const { setupStore, form, history } = this.props;

    if (!setupStore.isSignupInitialized) {
      setupStore.initSignup(this.props.token);
    }

    if (setupStore.isSignupDone) {
      this.props.history.replace(ROUTE_SIGNUP + '/done');
      return;
    }

    if (this.hasToken()) {
      this.props.history.replace(ROUTE_SIGNUP + '/name');
      return;
    }

    if (setupStore.city) {
      this.searchSchoolsForCity(setupStore.city.id);
      return;
    }

    if (form.values.init === false) {
      history.replace(screenUrl(SCREENS[0]));
      form.setField('init', true);
    }

    if (
      local.get(config.signupRefName) === null &&
      local.get(config.affiliateTokenName) === null
    ) {
      this.setRefs();
    }

    funnelSite('signup');
  }

  hasToken() {
    return this.props.token || this.props.setupStore.token ? true : false;
  }

  setRefs() {
    const { query } = this.props.location;

    // Get ref from query string
    const ref = query?.ref?.trim();
    if (ref) {
      local.set(config.signupRefName, ref);
    }

    // Get affiliate token from URL
    const affiliateToken = paramFromMatch(
      this.props.match,
      'affiliate_token'
    )?.trim();
    if (affiliateToken) {
      local.set(config.affiliateTokenName, affiliateToken);
    }
  }

  async submitSearch() {
    const { setupStore, form } = this.props;

    form.resetErrors();

    try {
      await setupStore.searchCity(
        form.values.city_name ||
          ""
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      return;
    }
  }

  async searchSchoolsForCity(cityId: number, name?: string) {
    const { setupStore, form, history } = this.props;

    form.resetErrors();

    try {
      await setupStore.getCityWithSchools(cityId);
      if (name) {
        form.setField('city_name', name)
      }
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);
      return;
    }

    history.push(screenUrl('school_search'));
  }

  async setSelectedSchool(schoolId: number, name: string) {
    const { setupStore, form, history } = this.props;

    form.resetErrors();

    try {
      await setupStore.setSchoolFromResults(schoolId, name);
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);
      return;
    }

    history.push(ROUTE_SIGNUP + '/year');
  }

  async setNewSchool() {
    const { setupStore, form, history } = this.props;

    form.resetErrors();
    try {
      await setupStore.setNewSchool(
        form.values.school_name
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);
      return;
    }

    history.push(ROUTE_SIGNUP + '/year');
  }

  resetCities() {
    const { setupStore, form } = this.props;

    if (form.values.city_name) {
      form.setField('city_name', '');
    }

    setupStore.resetCities();
  }

  async newCitySchool() {
    const { setupStore, form, history } = this.props;

    form.resetErrors();
    try {
      await setupStore.setNewCityAndSchool(
        form.values.school_name,
        form.values.city_name
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);
      return;
    }

    history.push(ROUTE_SIGNUP + '/year');
  }

  renderLoading() {
    return <LoadingOverlayComponent />;
  }

  render() {
    const { location, setupStore, form } = this.props;

    if (setupStore.isSchoolsLoading) {
      return this.renderLoading();
    }

    let screen: Screen = SCREENS[0];
    if (location.query && SCREENS.includes(location.query.screen as Screen)) {
      screen = location.query.screen as Screen;
    }

    const foundSchools = setupStore.sortedSchools;
    let filteredSchools = foundSchools;

    if (form.values.filter) {
      filteredSchools = setupStore.filteredSchools(form.values.filter);
    }

    const schoolType: string[] = form.values.school_type
      ? [form.values.school_type]
      : [];
    if (form.values.school_type === 'other') {
      schoolType.push('trade_school', 'elementary_school');
    }

    return (
      <>
        {screen === 'city_search' && (
          <SignupSchoolCityScreen
            form={form}
            search={() => this.submitSearch()}
            selectCity={(id, name) => this.searchSchoolsForCity(id, name)}
            cities={setupStore.foundCities}
            loading={setupStore.isCitiesLoading}
            done={setupStore.isCitiesDone}
            resetCity={() => {
              setupStore.resetCities();
              this.props.history.push('/login');
            }}
          />
        )}

        {screen === 'school_search' && (
          <SignupSchoolSelectScreen
            form={form}
            schools={filteredSchools}
            backRoute='?screen=city'
            selectSchool={(id, name) => this.setSelectedSchool(id, name)}
          />
        )}

        {screen === 'add_school' && (
          <SignupSchoolAddScreen
            form={form}
            submit={() => this.setNewSchool()}
            withCity={false}
          />
        )}
        {screen === 'add_city_and_school' && (
          <SignupSchoolAddScreen
            form={form}
            submit={() => this.newCitySchool()}
            withCity={true}
          />
        )}
      </>
    );
  }
}

export default injectIntl((props: any) => {
  const form = useForm({
    init: false
  });
  return <SignupSchoolScreen {...props} form={form} />;
});
