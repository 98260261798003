import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { ROUTE_DASHBOARD, ROUTE_ONBOARDING } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface OnboardingScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
  done?: boolean;
}

interface OnboardingScreenState {
  notBecomeOrganizerSelected?: boolean;
}

@inject('applicationStore', 'profilesStore')
@observer
export class OnboardingCommitteeScreen extends React.Component<
OnboardingScreenProps & HistoryProps,
OnboardingScreenState
> {
  state: OnboardingScreenState = {};

  componentDidMount() {
    if (!this.props.applicationStore.isManager) {
      this.props.history.replace(ROUTE_DASHBOARD);
    }
  }

  // private async update(isOrganizer: boolean) {
  //   const { applicationStore, profilesStore, history } = this.props;

  //   if (applicationStore.currentUserId) {
  //     try {
  //       await profilesStore.setCommiteeMemberFlag(
  //         applicationStore.currentUserId,
  //         isOrganizer
  //       );
  //     } catch (e) {
  //       return;
  //     }
  //   }

  //   pushPhoneConfirmed(applicationStore.currentUser?.role || ROLES.APPLICANT);

  //   history.push(ROUTE_DASHBOARD);
  // }

  // private confirmNumber() {
  //   pushPhoneConfirmed(
  //     this.props.applicationStore.currentUser?.role || ROLES.APPLICANT
  //   );
  // }

  private async finish() {
    const { applicationStore ,history } = this.props;
    try {
      await applicationStore.completeOnboarding()
    } catch(errors: any) {}

    history.push(ROUTE_ONBOARDING + '/class');
  }

  private becomeOrganizer() {
    // this.confirmNumber();

    // nothing to do here
    this.finish();
  }

  private notBecomeOrganizer() {
    // this.confirmNumber();

    this.setState({
      notBecomeOrganizerSelected: true
    });
  }

  private renderPage(content: any) {
    return (
      <>
        <SignupHeaderComponent progressStatus={25} />

        {content}
      </>
    );
  }

  private renderNoOrganizer() {
    return this.renderPage(
      <SupportTicketScreen
        subject="signup_no_organizer"
        onOkClick={() => this.finish()}
        message={intl.formatMessage({id: "zap_sign_up_no_organizer"}, {type: "Book"})}
      />
    );
  }

  // private renderLoading() {
  //   return this.renderPage(<LoadingOverlayComponent />);
  // }

  render() {
    // const { profilesStore } = this.props;

    // if (profilesStore.isItemLoading) {
    //   return this.renderLoading();
    // }

    if (this.state.notBecomeOrganizerSelected) {
      return this.renderNoOrganizer();
    }

    return this.renderPage(
      <PageStackBlock>
        <PageHeader headline={<FormattedMessage id="onboarding organizer header" />} text={<FormattedMessage id="onboarding organizer text" />} />

        <IconListBlock>
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding organizer text 1" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding organizer text 2" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding organizer text 3" />

          </IconListElement>
        </IconListBlock>

        <ListStackBlock>
          {/* {profilesStore.isItemError && <GenericErrorComponent />} */}

          <ButtonBlock
            background="PRIMARY"
            onClick={() => this.becomeOrganizer()}
          >
            <FormattedMessage id="Become organizer" />
          </ButtonBlock>
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => this.notBecomeOrganizer()}
          >
            <FormattedMessage id="Not become organizer" />
          </ButtonBlock>
        </ListStackBlock>
      </PageStackBlock>
    );
  }
}

export default OnboardingCommitteeScreen;
