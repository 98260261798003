import { get, uri } from "api/BaseApi";

interface TextileContentNotPrinted {
  profiles_not_printed: MinimalProfile[];
  profiles_without_groups: MinimalProfile[];
  virtual_students_without_groups: MinimalProfile[];
  virtual_teachers_without_groups: MinimalProfile[];
}

interface MinimalProfile {
  id: number;
  first_name: string;
  last_name: string;
}

export const getTextileContentNotPrinted = (textileOrderId: number): Promise<TextileContentNotPrinted> => {
  return get('/textile_orders/' + uri(textileOrderId) + '/content_not_printed');
}