import { types, Instance } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import AlbumPagePhotoModel, {
  createAlbumPagePhoto
} from './AlbumPagePhotoModel';

const AlbumPageModel = types.model('AlbumPageModel', {
  id: types.identifierNumber,
  chapter_id: types.number,
  album_layout_key: types.string,
  album_page_photos: types.array(AlbumPagePhotoModel),
  sorting: types.maybe(types.number)
});

export const createAlbumPage = (data: {
  id?: number;
  chapter_id: number;
  sorting?: number;
  album_layout_key: string;
  album_page_photos: any;
}) =>
  AlbumPageModel.create({
    id: mandatoryId(data.id),
    chapter_id: data.chapter_id,
    sorting: numberOrUndefined(data.sorting),
    album_layout_key: data.album_layout_key,
    album_page_photos:
      data.album_page_photos !== undefined
        ? data.album_page_photos.map(createAlbumPagePhoto)
        : []
  });

export type AlbumPageModelType = Instance<typeof AlbumPageModel>;
export default AlbumPageModel;
