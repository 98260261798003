import React, { ReactNode } from 'react';
import styled from 'styled-components';

import IconComponent from 'components/IconComponent';
import { IconComponentProps } from 'components/IconComponent/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import COLORS from 'utils/constants/colors';

interface ImageCardProps {
  rounded?: boolean;
  square?: boolean;
  dim?: boolean;
  contain?: boolean;
  border?: boolean | keyof typeof COLORS;
}

export const ImageCard = styled.div<ImageCardProps>`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: ${COLORS.GRAY980};
  ${({ border = true }) => {
    if (border === true) {
      return `border: 1px solid ${COLORS.GRAY900};`;
    }
    if (border) {
      return `border: 2px solid ${COLORS[border]};`;
    }
  }}

  &::before {
    content: '';
    display: inline-block;
    height: 0;
    width: 1px;
    margin-right: -1px;
    padding-bottom: 141%;
    z-index: 50;
  }

  > ${TextElement} {
    background: ${COLORS.GRAY980};
    padding: 0.6875rem;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 60;
  }

  + ${TextElement} {
    padding-top: 0.4375em;
  }

  ${(props) =>
    props.square &&
    `
    &::before {
      padding-bottom: 100%;
    }
    }
  `};
  ${(props) =>
    props.rounded &&
    `
    border-radius: 0.625rem;

    img {
      border-radius: 0.625rem;
    }
  `};
  ${(props) =>
    props.dim &&
    `
    img {
      filter: grayscale(60%);
    }
  `};
  ${(props) =>
    props.contain &&
    `
    img {
      object-fit: contain;
    }
  `};
`;

export const ImageCardImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
`;

const StyledBody = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;

  > * + * {
    margin-top: 0.5em;
  }
`;

export const ImageCardBody = ({
  icon,
  iconFill,
  children
}: {
  icon?: IconComponentProps['icon'];
  iconFill?: IconComponentProps['fill'];
  children?: any;
}) => (
  <StyledBody>
    {icon && (
      <IconComponent icon={icon} fill={iconFill || 'PRIMARY'} size={2.5} />
    )}
    {children && (
      <Paragraph size="S" color="GRAY400" >
        {children}
      </Paragraph>
    )}
  </StyledBody>
);

export const ImageCardStatus = (
  props: { children: ReactNode }
) => <Paragraph size="S" color="BLACK"  children={props.children} />;

export const LabelledImageCard = styled.div``;

export const ImageCardLabel = (
  props: { children: ReactNode }
) => <Paragraph size="S" color="GRAY400"  children={props.children} />;
