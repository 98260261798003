import React, { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import IconComponent from 'components/IconComponent';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import Dot from 'elements/Dot';

import { TimeplanItemState } from './TimeplanItemState';
import Paragraph from 'components/Paragraph/Paragraph';

const Container = styled.div`
  margin: 1.375rem 0;
  padding: 1rem 1.375rem;
  border-radius: 10px;
  background-color: ${COLORS.GRAY950};
`;

const TimeplanDate = ({
  state,
  date,
  children
}: {
  state: TimeplanItemState;
  date: Date;
  children?: ReactNode;
}) => (
  <Container>
    <HorizontalStackBlock gap="M" centered={true}>
      <div>
        {state === 'open' && <Dot color="WHITE" />}
        {state === 'neutral' && <Dot color="PRIMARY" />}
        {state === 'success' && (
          <IconComponent icon="TICK_CIRCLE" fill="GREEN" size={1.375} />
        )}
        {state === 'waiting' && <IconComponent icon="WARNING_CIRCLE" fill="WARNING" size={1.375} />}
        {state === 'error' && (
          <IconComponent icon="CROSS_CIRCLE" fill="RED" size={1.375} />
        )}
      </div>
      <div>
        <Paragraph size="L" weight="BOLD">
          <FormattedDate
            value={date}
            year="numeric"
            month="2-digit"
            day="2-digit"
          />
        </Paragraph>
        <Paragraph size="S"  color="TEXT_LIGHT">{children}</Paragraph>
      </div>
    </HorizontalStackBlock>
  </Container>
);

export default TimeplanDate;
