import React from 'react';

import { Boundary } from 'utils/chunker/ContentChunker';
import replaceVariables, { PageParams } from 'utils/chunker/replace-variables';
import sanitizeHtml from 'utils/chunker/sanitize-html';

import CoverNamesComponent from '../elements/CoverNamesComponent';
import PicturePrintComponent, {
  PrintPicture
} from '../elements/PicturePrintComponent';
import { Fonts } from '../FontsPreloader';
import PageComponent, { PAGE_SIZE_A4 } from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import {
  CommonTemplateProps,
  pageParamsFromProps
} from './CommonTemplateProps';
import WaitForImages from './WaitForImages';

export const PAGE_SIZE_COVER: Boundary = [936, 1352]; // 234 x 338 mm
export const PAGE_SIZE_COVER_SOFT: Boundary = [852, 1212]; // 213 x 303
const BACK_OFFSET_SOFT = 72;
const BACK_OFFSET_A4 = 84;
const FRONT_SOFTCOVER_LEFT_OFFSET = -12;
const PAGE_A4_POSITION: Boundary = [96, 82];
// const PAGE_SOFTCOVER_OFFSET: Boundary = [84, 70];

const JOINT_STYLE =
  'width: 40px; height: 1352px; background-color: #222222; opacity: 0.6;';

const FONT = 'Bebas Neue';
const FONT_SIZE = 28;
export const COVER_PRELOAD_FONTS: Fonts = [[FONT, [400]]];

const FONT_STYLES: React.CSSProperties = {
  fontFamily: FONT,
  fontWeight: 400,
  fontSize: FONT_SIZE + 'px',
  lineHeight: '34px',
  letterSpacing: '2px',
  textAlign: 'center',

  // whiteSpace: 'nowrap',
  whiteSpace: 'pre',
  overflow: 'hidden'
};

const FRONT_TEXT_LEFT = 92;
const FRONT_TEXT_WIDTH = 679;
const FRONT_HEADER_TOP = 152;
// const FRONT_FOOTER_BOTTOM = 152;
// const FRONT_FOOTER_TOP = PAGE_SIZE_COVER[1] - FRONT_FOOTER_BOTTOM - FONT_SIZE;
const FRONT_FOOTER_TOP = 1166;

export const SPINE_PREVIEW_SIZE = 80;
// const SPINE_FONT = FONT;
const SPINE_FONT_SIZE = 20;
const SPINE_MIN_SIZE_FOR_TEXT = 32;
const SPINE_TOP_MARGIN = 122; // 72 (page offset) + 40
const SPINE_TEXT_HEIGHT = 1108; // 297mm*4px - 2*40px

const NAMES_TWO_COLUMNS = 60;
const NAMES_MAX = 140;

export type CoverPageType = 'front' | 'spine' | 'back';

export interface CoverTemplateConfig {
  // "background"
  front?: string;
  back?: string;
  namesBack?: string;

  // hint: backgroundColor is currently only used by outer spine
  backgroundColor?: string;
  fontColor?: string; // for header and footer
  fontBackgroundColor?: string;
  headerFontColor?: string;
  headerFontBackgroundColor?: string;
  backFontColor?: string;
  backHeaderFontColor?: string;

  spineBackgroundColor?: string;
  spineFontColor?: string;
}

export interface CoverTemplateProps extends CommonTemplateProps {
  // theme
  config: CoverTemplateConfig;

  // what/how to render
  display: CoverPageType | CoverPageType[];
  hardcover?: boolean;
  forceA4?: boolean;

  // frontType: 'template' | 'picture';
  backType?: 'default' | 'picture' | 'sponsor';

  // content
  header?: string;
  footer?: string;
  subtitle?: string;

  frontPicture?: PrintPicture; // will override front
  backPicture?: PrintPicture; // will override back
  backSponsorUrl?: string;

  spineInnerWidth?: number; // px (4px = 1mm)
  spineOuterWidth?: number;
  spineText?: string;

  namesHeader?: string;
  names?: string[];

  // preview options
  // will add an overlay for the joint if previewing hardcover picture front/back
  showJoint?: boolean;
}

export class CoverTemplateComponent extends React.Component<
  CoverTemplateProps
> {
  componentDidMount() {
    // we have nothing to place but still should tell parent we're done (doing nothing)
    const { onContentPlaced } = this.props;
    onContentPlaced && onContentPlaced(0);
  }

  generateReplaceParams(): PageParams {
    const params = pageParamsFromProps(this.props, 'left', 1);

    // params.variables!.push(['subtitle', sanitizeHtml(this.props.subtitle) || '']);

    return params;
  }

  generateOverlay(page: 'front' | 'back') {
    const left = page === 'front' ? 0 : PAGE_SIZE_COVER[0] - 40;
    return (
      '<div style="position: absolute; left: ' +
      left +
      'px; ' +
      JOINT_STYLE +
      '"></div>'
    );
  }

  getPageSize() {
    const { forceA4, print, hardcover } = this.props;

    if (forceA4) {
      return PAGE_SIZE_A4;
    }

    return print
      ? hardcover
        ? PAGE_SIZE_COVER
        : PAGE_SIZE_COVER_SOFT
      : PAGE_SIZE_A4;
  }

  getRightOffset() {
    const { forceA4, print, hardcover } = this.props;

    return print && !forceA4 ? (hardcover ? 0 : BACK_OFFSET_SOFT * -1) : BACK_OFFSET_A4 * -1;
  }

  renderFront(key: string | number = '') {
    const {
      config: {
        fontColor,
        fontBackgroundColor,
        headerFontColor,
        headerFontBackgroundColor,
        front
      },
      print,
      header,
      footer,
      frontPicture,
      hardcover,
      showJoint,
      subtitle
    } = this.props;

    const hasPicture = !!frontPicture;

    const background = hasPicture
      ? undefined
      : replaceVariables(front, this.generateReplaceParams(), [
          ['subtitle', sanitizeHtml(subtitle) || '']
        ]);

    const overlay =
      !print && hasPicture && hardcover && showJoint
        ? this.generateOverlay('front')
        : undefined;

    // TODO use other positions for hardcover?
    const textStyle: React.CSSProperties = {
      ...FONT_STYLES,
      position: 'absolute',
      left: FRONT_TEXT_LEFT + 'px',
      width: FRONT_TEXT_WIDTH + 'px'
      // textTransform: 'uppercase'
    };

    return (
      <PageComponent
        key={'front#' + key}
        print={print}
        printSize={PAGE_SIZE_COVER}
        previewSize={this.getPageSize()}
        leftOffset={0}
        forcedLeftOffset={hardcover ? 0 : FRONT_SOFTCOVER_LEFT_OFFSET}
        background={background}
        overlay={overlay}
      >
        <PageContentComponent>
          {hasPicture ? (
            <>{/* TODO front page with uploaded picture */}</>
          ) : (
            <div style={{ position: 'relative' }}>
              {header && (
                <div
                  style={{
                    ...textStyle,
                    color: headerFontColor || fontColor || '#000000',
                    // padding: '0 12px 0 12px', // TODO safe zone for preview only - do we want that?
                    position: 'absolute',
                    top: FRONT_HEADER_TOP + 'px'
                  }}
                >
                  <span
                    style={{
                      backgroundColor: headerFontBackgroundColor || undefined
                    }}
                  >
                    {header}
                  </span>
                </div>
              )}

              {footer && (
                <div
                  style={{
                    ...textStyle,
                    color: fontColor || '#000000',
                    top: FRONT_FOOTER_TOP + 'px'
                  }}
                >
                  <span
                    style={{
                      backgroundColor: fontBackgroundColor || undefined
                    }}
                  >
                    {footer}
                  </span>
                </div>
              )}
            </div>
          )}
        </PageContentComponent>
      </PageComponent>
    );
  }

  renderSpine(key: string | number = '') {
    const {
      config: { backgroundColor, spineBackgroundColor, spineFontColor },
      hardcover
    } = this.props;
    const { print, spineText } = this.props;

    const height = PAGE_SIZE_COVER[1] + 'px';
    const fontSize = SPINE_FONT_SIZE + 'px';

    const spineInnerWidth = this.props.spineInnerWidth || 0;
    const spineOuterWidth = this.props.spineOuterWidth || 0;

    const fullWidth = spineInnerWidth + spineOuterWidth * 2;

    const displayText =
      spineInnerWidth >= SPINE_MIN_SIZE_FOR_TEXT && !!spineText;

    const text = !displayText ? undefined : (
      <div
        style={{
          ...FONT_STYLES,

          position: 'absolute',
          right: SPINE_TOP_MARGIN + 'px',
          top: Math.floor((spineInnerWidth - SPINE_FONT_SIZE) / 2) + 'px',
          width: SPINE_TEXT_HEIGHT,

          // fontFamily: SPINE_FONT,
          fontSize,
          lineHeight: fontSize,
          color: spineFontColor || '#FFFFFF',
          // textTransform: 'uppercase',

          // whiteSpace: 'nowrap',
          textAlign: 'center',
          overflow: 'hidden'
        }}
      >
        {spineText}
      </div>
    );

    const inner = (
      <div
        style={{
          position: 'relative',
          backgroundColor: spineBackgroundColor || '#000000',
          width: height,
          height: spineInnerWidth + 'px'
        }}
      >
        {text}
      </div>
    );

    const outer = (
      <div
        style={{
          width: height,
          paddingTop: spineOuterWidth + 'px',
          paddingBottom: spineOuterWidth + 'px',
          transformOrigin: '0 0',
          transform: `rotate(270deg) translateX(-${height})`,
          backgroundColor
        }}
      >
        {inner}
      </div>
    );

    return (
      <PageComponent
        key={'spine#' + key}
        print={print}
        printSize={[fullWidth, PAGE_SIZE_COVER[1]]}
        previewSize={
          print
            ? [
                fullWidth,
                hardcover ? PAGE_SIZE_COVER[1] : PAGE_SIZE_COVER_SOFT[1]
              ]
            : [fullWidth, PAGE_SIZE_A4[1]]
        }
      >
        <PageContentComponent>{outer}</PageContentComponent>
      </PageComponent>
    );
  }

  sliceNames(names: string[]): string[][] | false {
    const count = names.length;

    if (count > NAMES_MAX) {
      return false;
    }

    const columns = count <= NAMES_TWO_COLUMNS ? 2 : 3;
    const c1 = Math.ceil(count / columns);

    const slices = [];
    for (let i = 1; i < columns; i++) {
      slices.push(names.slice((i - 1) * c1, i * c1));
    }

    slices.push(names.slice((columns - 1) * c1));

    return slices;
  }

  renderNames() {
    if (!this.props.names || !this.props.names) {
      return null;
    }

    const names = this.sliceNames(this.props.names);
    if (names === false || !names.length) {
      return null;
    }

    const {
      config: { backFontColor, backHeaderFontColor, fontColor },
      namesHeader
    } = this.props;

    return (
      <CoverNamesComponent
        names={names}
        font={FONT}
        color={backFontColor || fontColor || '#000000'}
        headlineColor={backHeaderFontColor}
        headline={namesHeader}
      />
    );
  }

  renderBack(key: string | number = '') {
    const {
      config: { back, namesBack },
      print,
      backPicture,
      hardcover,
      showJoint,
      names,
      backType,
      backSponsorUrl
    } = this.props;

    const hasPicture = backType === 'picture' && !!backPicture;
    const hasSponsor = backType === 'sponsor' && !!backSponsorUrl;
    const hasNames = backType === 'default' && !!names;

    // const background =
    //   hasPicture || hasSponsor
    //     ? undefined
    //     : replaceVariables(
    //         hasNames ? namesBack || back : back,
    //         this.generateReplaceParams()
    //       );
    const background = replaceVariables(
      hasNames || hasPicture || hasSponsor ? namesBack || back : back,
      this.generateReplaceParams()
    );

    const overlay =
      !print && showJoint && hardcover && (hasPicture || hasSponsor)
        ? this.generateOverlay('back')
        : undefined;

    return (
      <PageComponent
        key={'back#' + key}
        print={print}
        printSize={PAGE_SIZE_COVER}
        previewSize={this.getPageSize()}
        leftOffset={this.getRightOffset()}
        background={background}
        overlay={overlay}
      >
        <PageContentComponent>
          {hasPicture ? (
            <PicturePrintComponent
              picture={backPicture!}
              size={PAGE_SIZE_A4}
              position={PAGE_A4_POSITION}
            />
          ) : hasSponsor ? (
            <PicturePrintComponent
              picture={{
                url: backSponsorUrl!,
                fit: true
              }}
              size={PAGE_SIZE_A4}
              position={PAGE_A4_POSITION}
            />
          ) : hasNames ? (
            this.renderNames()
          ) : null}
        </PageContentComponent>
      </PageComponent>
    );
  }

  render() {
    const { render, print } = this.props;
    const display = Array.isArray(this.props.display)
      ? this.props.display
      : [this.props.display];

    const pages = display
      .map((type, idx) => {
        switch (type) {
          case 'front':
            return [false, this.renderFront(idx)];

          case 'spine':
            return [true, this.renderSpine(idx)];

          case 'back':
            return [false, this.renderBack(idx)];

          default:
            return undefined;
        }
      })
      .filter((elem) => !!elem);

    return (
      <>
        {pages.map((page, idx) =>
          !render ? page![1] : render(page![1], idx, { isSpine: page![0] })
        )}
        {print && <WaitForImages />}
      </>
    );
  }
}

export default CoverTemplateComponent;
