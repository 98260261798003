import { detach, flow, getEnv, Instance, types } from 'mobx-state-tree';
import ChapterModel from 'models/ChapterModel';
import SponsorModel, {
  createSponsorModel,
  SponsorModelType
} from 'models/SponsorModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { createMapWithTransform } from 'utils/create-map';
import { sortByName } from 'utils/sort-functions';

const SponsorsStore = types
  .model('SponsorsStore', {
    // list
    loadingState: types.maybe(types.string),
    sponsors: types.maybe(types.map(SponsorModel)),
    chapter: types.maybe(ChapterModel),
    // singe item
    itemLoadingState: types.maybe(types.string),
    item: types.maybe(SponsorModel)
  })
  .actions((self) => {
    const getAllSponsors = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';
        if (self.sponsors) {
          self.sponsors.clear();
        }
        self.chapter = undefined;

        const result = yield client.getAllSponsors();

        if (!Array.isArray(result) || !result.length) {
          self.loadingState = undefined;
          return;
        }

        self.sponsors = createMapWithTransform(result, createSponsorModel);
        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('SponsorsStore | getAllSponsors', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const getSponsor = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const item = yield client.getSponsor(id);

        self.item = createSponsorModel(item);
        self.itemLoadingState = undefined;

        return item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('SponsorsStore | getSponsor', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    const createSponsor = flow(function* (
      item: SponsorModelType,
      addToList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        item = yield client.createSponsor(item);
        const model = createSponsorModel(item);

        if (model) {
          if (addToList && self.sponsors) {
            self.sponsors.put(model);
          }

          self.item = createSponsorModel(item);
        }

        self.itemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('SponsorsStore | createSponsor', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateSponsor = flow(function* (item: SponsorModelType) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        item = yield client.updateSponsor(item);
        const model = createSponsorModel(item);

        if (model) {
          if (self.sponsors?.has(model.id.toString())) {
            self.sponsors.put(model);
          }

          if (self.item?.id === item.id) {
            self.item = createSponsorModel(item);
          }
        }

        self.itemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('SponsorsStore | updateSponsor', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const removeSponsor = flow(function* (id: number, removeFromList = true) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        yield client.removeSponsor(id);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('SponsorsStore | removeSponsor', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.itemLoadingState = 'update_error';
          throw error;
        }
      }

      if (removeFromList && self.sponsors) {
        self.sponsors.delete(id.toString());
      }

      if (self.item?.id === id) {
        self.item = undefined;
      }
      self.itemLoadingState = undefined;
    });

    const clearCurrentItem = () => {
      self.item = undefined;
      self.itemLoadingState = undefined;
    };

    const patchItem = (update: any) => {
      if (!self.item) {
        self.item = {
          id: -1,
          ...update
        };
        return;
      }

      self.item = {
        ...self.item,
        ...update
      };
    };

    const storeItem = flow(function* () {
      if (!self.item) {
        return;
      }

      if (self.item.id < 0) {
        return yield createSponsor(detach(self.item));
      }
      return yield updateSponsor(detach(self.item));
    });

    return {
      getAllSponsors,
      getSponsor,
      createSponsor,
      updateSponsor,
      removeSponsor,
      clearCurrentItem,
      patchItem,
      storeItem
    };
  })
  .views((self) => {
    return {
      allSponsors(
        display: 'all' | 'user' | 'global' = 'all'
      ): SponsorModelType[] {
        const items: SponsorModelType[] = [];
        if (!self.sponsors) {
          return items;
        }

        for (const item of self.sponsors.values()) {
          if (
            (display === 'user' && item.global) ||
            (display === 'global' && !item.global)
          ) {
            continue;
          }

          items.push(item);
        }

        items.sort(sortByName);
        return items;
      },
      get customSponsors(): SponsorModelType[] {
        const items: SponsorModelType[] = [];
        if (!self.sponsors) {
          return items;
        }

        for (const item of self.sponsors.values()) {
          if (!item.global) {
            items.push(item);
          }
        }

        items.sort(sortByName);
        return items;
      },
      filteredSponsors(
        text: string,
        display: 'all' | 'user' | 'global' = 'all'
      ): SponsorModelType[] {
        const textLowercase = text.trim().toLowerCase();

        const items: SponsorModelType[] = [];
        if (!self.sponsors) {
          return items;
        }

        for (const item of self.sponsors.values()) {
          if (
            (display === 'user' && item.global) ||
            (display === 'global' && !item.global)
          ) {
            continue;
          }

          if (
            item.name &&
            item.name.toLowerCase().indexOf(textLowercase) > -1
          ) {
            items.push(item);
          }
        }

        items.sort(sortByName);
        return items;
      },
      get unplacedSponsors() {
        const { sponsors } = self;
        if (!sponsors) {
          return [];
        }

        const items: SponsorModelType[] = [];
        for (const item of sponsors.values()) {
          if (!item.placed) {
            items.push(item);
          }
        }
        items.sort(sortByName);
        return items;
      },

      get placedSponsors() {
        const { sponsors } = self;
        if (!sponsors) {
          return [];
        }

        const items: SponsorModelType[] = [];
        for (const item of sponsors.values()) {
          if (item.placed) {
            items.push(item);
          }
        }
        items.sort(sortByName);
        return items;
      },

      get donationsSums() {
        const sums = {
          all: 0,
          global: 0,
          user: 0,
          placed: 0,
          placedGlobal: 0,
          collected: 0
        };

        if (!self.sponsors) {
          return sums;
        }

        for (const item of self.sponsors.values()) {
          if (item.donation) {
            sums.all = sums.all + item.donation;

            if (item.placed) {
              sums.placed = sums.placed + item.donation;
            }

            if (item.global) {
              sums.global = sums.global + item.donation;

              if (item.placed) {
                sums.collected = sums.collected + item.donation;
                sums.placedGlobal = sums.placedGlobal + item.donation;
              }
            } else {
              sums.collected = sums.collected + item.donation;
              sums.user = sums.placed + item.donation;
            }
          }
        }

        return sums;
      },

      get isListLoading(): boolean {
        return self.loadingState === 'loading' ? true : false;
      },
      get isListError(): boolean {
        return self.loadingState === 'error' ? true : false;
      },

      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading' ? true : false;
      },
      get isItemError(): boolean {
        return self.itemLoadingState === 'error' ||
          self.itemLoadingState === 'update_error'
          ? true
          : false;
      }
    };
  });

export type SponsorsStoreType = Instance<typeof SponsorsStore>;
export default SponsorsStore;
