import React, { SyntheticEvent } from 'react';
import Draggable from 'react-draggable';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomRadioBlock from 'blocks/CustomRadioBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import { intl } from 'i18n';

import TextElement from 'components/TextElement/TextElement';
import { ImageConfig } from '../../models/PhotoModel';

interface ImageEditorProps {
  src: string;
  imageWidth: number;
  imageHeight: number;
  frameWidth: number;
  frameHeight: number;
  fitToFrameEnabled: boolean;
  rotation: number;
  offsetX: number | undefined;
  offsetY: number | undefined;
  unit: string;
  fitToFrame: boolean | undefined;
  caption: string | undefined;
  allowEditCaption: boolean;
  handleSave: (
    imageConfig: ImageConfig,
    turn: boolean,
    caption?: string
  ) => void;
}

interface ImageEditorComponentState {
  cutOffLeft: number;
  cutOffTop: number;
  cutOffWidth: number;
  cutOffHeight: number;
  imageWidth: number;
  imageHeight: number;
  allowDrag: boolean;
  fitToFrame: boolean;
  imageOffsetTop: number;
  imageOffsetLeft: number;
  containerWidth: number;
  caption?: string;
}

interface FrameProps {
  width: number;
  height: number;
  imageWidth?: number;
  imageHeight?: number;
  round?: boolean;
  fitToFrame?: boolean;
  unit: string;
  imageOffsetTop?: number;
  imageOffsetLeft?: number;
}

const EditorContainer = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 300px;
  max-height: 300px;
  max-width: 100%;
`;

const FrameWrapper = styled.div<FrameProps>`
  margin: auto;
  width: inherit;
  height: ${(props) => props.height + props.unit};
  box-sizing: border-box;
`;

const FrameContainer = styled.div<FrameProps>`
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: ${(props) => (props.round ? '50%' : '0')};
  width: ${(props) => props.width + props.unit};
  height: ${(props) => props.height + props.unit};
  z-index: 10;
  box-shadow: 0 0 0 99em;
  color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  touch-action: none;
`;

const ImageContainer = styled.div<FrameProps>`
  position: absolute;
  top: ${(props) => -props.imageOffsetTop!}%;
  left: ${(props) => -props.imageOffsetLeft!}%;
  box-sizing: inherit;
  width: ${(props) =>
    props.imageWidth! > props.imageHeight! ? 'auto' : props.width + props.unit};
  height: ${(props) =>
    props.imageHeight! >= props.imageWidth!
      ? 'auto'
      : props.height + props.unit};
  min-width: ${(props) =>
    props.width >= props.height ? props.width + props.unit : 'auto'};
  min-height: ${(props) =>
    props.height > props.width ? props.height + props.unit : 'auto'};
  cursor: pointer;
`;

const SquareImageContainer = styled.div<FrameProps>`
  position: absolute;
  top: ${(props) => -props.imageOffsetTop!}%;
  left: ${(props) => -props.imageOffsetLeft!}%;
  box-sizing: inherit;
  width: ${(props) =>
    props.width > props.height ? props.width + props.unit : 'auto'};
  height: ${(props) =>
    props.height >= props.width ? props.height + props.unit : 'auto'};
  min-width: ${(props) =>
    props.width >= props.height ? props.width + props.unit : 'auto'};
  min-height: ${(props) =>
    props.height > props.width ? props.height + props.unit : 'auto'};
  cursor: pointer;
`;

const Image = styled.img`
  width: inherit;
  height: inherit;
`;

const ImageFitToFrameContainer = styled.div<FrameProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: inherit;
  justify-content: ${(props) => (props.fitToFrame ? 'center' : '')};
  align-items: ${(props) => (props.fitToFrame ? 'center' : '')};
  display: ${(props) => (props.fitToFrame ? 'flex' : 'block')};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  cursor: pointer;
`;

class ImageEditorComponent extends React.Component<ImageEditorProps> {
  imageRef: React.RefObject<HTMLImageElement>;
  editorContainerRef: React.RefObject<HTMLDivElement>;
  resizeHandler?: void;
  interval: number | undefined;

  constructor(props: ImageEditorProps) {
    super(props);
    this.imageRef = React.createRef();
    this.editorContainerRef = React.createRef();
  }

  state: ImageEditorComponentState = {
    cutOffLeft: 0,
    cutOffTop: 0,
    cutOffWidth: 100,
    cutOffHeight: 100,
    imageWidth: 0,
    imageHeight: 0,
    allowDrag: true,
    fitToFrame: false,
    imageOffsetTop: 0,
    imageOffsetLeft: 0,
    containerWidth: 0,
    caption: this.props.caption
  };

  calculateCutOffLeft(
    imageWidth: number,
    percentageFrameWidth: number,
    ui: any,
    offsetX: number | undefined
  ): number {
    const percentageCutOffLeft =
      ui.x <= 0
        ? -(Math.abs(ui.x * 100) / imageWidth)
        : Math.abs(ui.x * 100) / imageWidth;
    let cutOffLeft: number;
    if (offsetX === undefined || offsetX === 0) {
      if (percentageFrameWidth === 100) {
        cutOffLeft = 0;
      } else {
        cutOffLeft = (100 - percentageFrameWidth) / 2 - percentageCutOffLeft;
      }
    } else {
      if (percentageFrameWidth === 100) {
        cutOffLeft = 0;
      } else {
        cutOffLeft = offsetX - percentageCutOffLeft;
      }
    }
    // Workaround Offset cant be 0 if we crop, therefore offset need to have a small value
    if (cutOffLeft === 0) {
      cutOffLeft = 0.001;
    }
    return cutOffLeft;
  }

  calculateCutOffTop(
    imageHeight: number,
    percentageFrameHeight: number,
    ui: any,
    offsetY: number | undefined
  ): number {
    const percentageCutOffTop =
      ui.y <= 0
        ? -(Math.abs(ui.y * 100) / imageHeight)
        : Math.abs(ui.y * 100) / imageHeight;
    let cutOffTop: number;
    if (offsetY === undefined || offsetY === 0) {
      if (percentageFrameHeight === 100) {
        cutOffTop = 0;
      } else {
        cutOffTop = (100 - percentageFrameHeight) / 2 - percentageCutOffTop;
      }
    } else {
      if (percentageFrameHeight === 100) {
        cutOffTop = 0;
      } else {
        cutOffTop = offsetY - percentageCutOffTop;
      }
    }
    // Workaround Offset cant be 0 if we crop, therefore offset need to have a small value
    if (cutOffTop === 0) {
      cutOffTop = 0.001;
    }
    return cutOffTop;
  }

  handleDrag = (e: any, ui: any) => {
    const height = this.state.imageHeight;
    const width = this.state.imageWidth;
    const { imageWidth, imageHeight } = this.state;
    const { offsetX, offsetY, frameWidth, frameHeight } = this.props;
    const percentageFrameWidth = (frameWidth * 100) / imageWidth;
    const percentageFrameHeight = (frameHeight * 100) / imageHeight;

    // Case Image is square e.g. 50x50 150x150 ect.
    if (height === width) {
      // First Case Frame is upright
      if (frameHeight > frameWidth) {
        const cutOffLeft = this.calculateCutOffLeft(
          imageWidth,
          percentageFrameWidth,
          ui,
          offsetX
        );
        this.setState({
          cutOffLeft: Math.abs(cutOffLeft) / 100,
          cutOffTop: 0,
          cutOffHeight: 100,
          cutOffWidth: percentageFrameWidth
        });
      } else if (frameWidth > frameHeight) {
        // Second Case Frame is horizontal
        const cutOffTop = this.calculateCutOffTop(
          imageHeight,
          percentageFrameHeight,
          ui,
          offsetY
        );
        this.setState({
          cutOffLeft: 0,
          cutOffTop: Math.abs(cutOffTop) / 100,
          cutOffHeight: percentageFrameHeight,
          cutOffWidth: 100
        });
      }
      // Thrid Case Frame is also square no need to crop or cutOff
    } else if (height > width) {
      const frameRatio = frameHeight / frameWidth;
      const imageRatio = height / width;

      if (imageRatio > frameRatio) {
        // First Case Image Ratio bigger then Frame Ratio width: 100% height: percentageFrameHeight
        const cutOffTop = this.calculateCutOffTop(
          imageHeight,
          percentageFrameHeight,
          ui,
          offsetY
        );
        this.setState({
          cutOffLeft: 0,
          cutOffTop: Math.abs(cutOffTop) / 100,
          cutOffHeight: percentageFrameHeight,
          cutOffWidth: 100
        });
      } else if (frameRatio > imageRatio) {
        // Second Case Image Ratio smaller then Frame Ratio width: percentageFrameWidth height: 100%
        const cutOffLeft = this.calculateCutOffLeft(
          imageWidth,
          percentageFrameWidth,
          ui,
          offsetX
        );
        this.setState({
          cutOffLeft: Math.abs(cutOffLeft) / 100,
          cutOffTop: 0,
          cutOffHeight: 100,
          cutOffWidth: percentageFrameWidth
        });
      }
      // Third Case Image Ratio and Frame Ratio equal. No need to crop or cutOff
    } else if (width > height) {
      const frameRatio = frameWidth / frameHeight;
      const imageRatio = width / height;

      if (imageRatio > frameRatio) {
        // First Case Image Ratio bigger then Frame Ratio width: percentageFrameWidth height: 100%
        const cutOffLeft = this.calculateCutOffLeft(
          imageWidth,
          percentageFrameWidth,
          ui,
          offsetX
        );
        this.setState({
          cutOffLeft: Math.abs(cutOffLeft) / 100,
          cutOffTop: 0,
          cutOffHeight: 100,
          cutOffWidth: percentageFrameWidth
        });
      } else if (frameRatio > imageRatio) {
        // Second Case Image Ratio smaller then Frame Ratio width: 100% height: percentageFrameHeight
        const cutOffTop = this.calculateCutOffTop(
          imageHeight,
          percentageFrameHeight,
          ui,
          offsetY
        );
        this.setState({
          cutOffLeft: 0,
          cutOffTop: Math.abs(cutOffTop) / 100,
          cutOffHeight: percentageFrameHeight,
          cutOffWidth: 100
        });
      }
      // Third Case Image Ratio and Frame Ratio equal. No need to crop or cutOff
    }
  };

  setImageProperties = () => {
    const image = this.imageRef.current;
    const editorContainer = this.editorContainerRef.current;
    let editorContainerWidth: number;
    let imageWidth: number;
    let imageHeight: number;
    let imageOffsetTop: number;
    let imageOffsetLeft: number;

    if (image !== null && editorContainer !== null) {
      const clientRect = image.getBoundingClientRect();
      editorContainerWidth = editorContainer.getBoundingClientRect().width;
      imageWidth = clientRect.width;
      imageHeight = clientRect.height;
      imageOffsetTop = ((imageHeight - 300) / 2) * (100 / 300);
      imageOffsetLeft =
        ((imageWidth - editorContainerWidth) / 2) *
        (100 / editorContainerWidth);

      if (
        this.state.imageWidth !== imageWidth ||
        this.state.imageHeight !== imageHeight ||
        this.state.imageOffsetLeft !== imageOffsetLeft ||
        this.state.imageOffsetTop !== imageOffsetTop ||
        this.state.containerWidth !== editorContainerWidth
      ) {
        this.setCutOffProperties();
        this.setState({
          imageWidth,
          imageHeight,
          imageOffsetLeft,
          imageOffsetTop,
          containerWidth: editorContainerWidth
        });
      }
    }
  };

  cutOffLeftSetProperties(
    percentageFrameWidth: number,
    offsetX: number | undefined
  ): number {
    let cutOffLeft;
    if (offsetX === 0 || offsetX === undefined) {
      cutOffLeft = (100 - percentageFrameWidth) / 2;
    } else {
      cutOffLeft = offsetX;
    }
    cutOffLeft = cutOffLeft < 0 ? -cutOffLeft : cutOffLeft;

    return cutOffLeft;
  }

  cutOffTopSetProperties(
    percentageFrameHeight: number,
    offsetY: number | undefined
  ): number {
    let cutOffTop;
    if (offsetY === 0 || offsetY === undefined) {
      cutOffTop = (100 - percentageFrameHeight) / 2;
    } else {
      cutOffTop = offsetY;
    }
    cutOffTop = cutOffTop < 0 ? -cutOffTop : cutOffTop;

    return cutOffTop;
  }

  setCutOffProperties = () => {
    const { frameHeight, frameWidth, offsetY, offsetX } = this.props;
    const image = this.imageRef.current;

    if (image !== null) {
      const clientRect = image.getBoundingClientRect();
      const percentageFrameHeight = (frameHeight * 100) / clientRect.height;
      const percentageFrameWidth = (frameWidth * 100) / clientRect.width;

      // Case Image is square e.g. 50x50 150x150 ect.
      if (clientRect.height === clientRect.width) {
        if (frameHeight > frameWidth) {
          // First Case Frame is upright
          const cutOffLeft = this.cutOffLeftSetProperties(
            percentageFrameWidth,
            offsetX
          );
          this.setState({
            cutOffHeight: 100,
            cutOffWidth: percentageFrameWidth,
            cutOffLeft: Math.abs(cutOffLeft) / 100
          });
        } else if (frameWidth > frameHeight) {
          // Second Case Frame is horizontal
          const cutOffTop = this.cutOffTopSetProperties(
            percentageFrameHeight,
            offsetY
          );
          this.setState({
            cutOffHeight: percentageFrameHeight,
            cutOffWidth: 100,
            cutOffTop: Math.abs(cutOffTop) / 100
          });
        }
        // Thrid Case Frame is also square no need to crop or cutOff
      } else if (clientRect.height > clientRect.width) {
        const frameRatio = frameHeight / frameWidth;
        const imageRatio = clientRect.height / clientRect.width;
        if (imageRatio > frameRatio) {
          // First Case Image Ratio bigger then Frame Ratio width: 100% height: percentageFrameHeight
          const cutOffTop = this.cutOffTopSetProperties(
            percentageFrameHeight,
            offsetY
          );
          this.setState({
            cutOffHeight: percentageFrameHeight,
            cutOffWidth: 100,
            cutOffTop: Math.abs(cutOffTop) / 100
          });
        } else if (frameRatio > imageRatio) {
          // Second Case Image Ratio smaller then Frame Ratio width: percentageFrameWidth height: 100%
          const cutOffLeft = this.cutOffLeftSetProperties(
            percentageFrameWidth,
            offsetX
          );
          this.setState({
            cutOffHeight: 100,
            cutOffWidth: percentageFrameWidth,
            cutOffLeft: Math.abs(cutOffLeft) / 100
          });
        }
        // Third Case Image Ratio and Frame Ratio equal. No need to crop or cutOff
      } else if (clientRect.width > clientRect.height) {
        const frameRatio = frameWidth / frameHeight;
        const imageRatio = clientRect.width / clientRect.height;

        if (imageRatio > frameRatio) {
          // First Case Image Ratio bigger then Frame Ratio width: percentageFrameWidth height: 100%
          const cutOffLeft = this.cutOffLeftSetProperties(
            percentageFrameWidth,
            offsetX
          );
          this.setState({
            cutOffHeight: 100,
            cutOffWidth: percentageFrameWidth,
            cutOffLeft: Math.abs(cutOffLeft) / 100
          });
        } else if (frameRatio > imageRatio) {
          // Second Case Image Ratio smaller then Frame Ratio width: 100% height: percentageFrameHeight
          const cutOffTop = this.cutOffTopSetProperties(
            percentageFrameHeight,
            offsetY
          );
          this.setState({
            cutOffHeight: percentageFrameHeight,
            cutOffWidth: 100,
            cutOffTop: Math.abs(cutOffTop) / 100
          });
        }
        // Third Case Image Ratio and Frame Ratio equal. No need to crop or cutOff
      }
    }
  };

  componentDidUpdate() {
    this.resizeHandler = window.addEventListener(
      'resize',
      this.setImageProperties
    );

    this.startInterval();
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  componentDidMount() {
    this.startInterval();
    this.setCutOffProperties();
    this.setState({
      fitToFrame: this.props.fitToFrameEnabled ? this.props.fitToFrame : false
    });
  }

  clearInterval() {
    if (this.interval !== undefined) {
      window.clearInterval(this.interval);
    }
  }

  startInterval() {
    this.clearInterval();
    this.interval = window.setInterval(this.setImageProperties, 10);
  }

  fitToFrameChange = (event: SyntheticEvent) => {
    this.startInterval();
    const fitToFrame = (event.target as HTMLInputElement).value === 'true';
    this.setState({
      allowDrag: !fitToFrame,
      fitToFrame
    });
  };

  turn = () => {
    const { handleSave } = this.props;

    let newRotation = this.props.rotation - 90;
    if (newRotation >= 360) {
      newRotation = newRotation % 360;
    } else if (newRotation < 0) {
      newRotation = 360 + newRotation;
    }

    if (newRotation % 90 !== 0) {
      // wtf!?
      newRotation = 0;
    }

    const imageConfig: ImageConfig = {
      cutOff: {
        width: 100,
        height: 100,
        top: null,
        left: null
      },
      rotation: newRotation,
      crop: false
    };

    handleSave(imageConfig, true, this.state.caption);
  };

  setCaption = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ caption: event.currentTarget.value });
  };

  save = () => {
    const { handleSave } = this.props;

    const imageConfig: ImageConfig = {
      cutOff: {
        width: this.state.cutOffWidth,
        height: this.state.cutOffHeight,
        top: this.state.cutOffTop,
        left: this.state.cutOffLeft
      },
      rotation: this.props.rotation,
      crop: !this.state.fitToFrame
    };

    handleSave(imageConfig, false, this.state.caption);
  };

  calculateBounds = () => {
    const bounds: {
      bottom: number;
      top: number;
      left: number;
      right: number;
    } = {
      bottom: 0,
      top: 0,
      left: 0,
      right: 0
    };

    const { frameWidth, frameHeight, offsetX, offsetY } = this.props;
    const { imageHeight, imageWidth } = this.state;
    const percentageFrameWidth = (frameWidth * 100) / imageWidth;
    const percentageFrameHeight = (frameHeight * 100) / imageHeight;
    const percentageOffsetY =
      offsetY === undefined ? 0 : (100 - percentageFrameHeight) / 2 - offsetY;
    const percentageOffsetX =
      offsetX === undefined ? 0 : (100 - percentageFrameWidth) / 2 - offsetX;
    const offSetY = -(percentageOffsetY * (imageHeight / 100));
    const offSetX = -(percentageOffsetX * (imageWidth / 100));

    if (offsetY === undefined || offsetY === 0) {
      bounds.bottom =
        -(imageHeight - frameHeight) / 2 === 0
          ? -(imageHeight - frameHeight) / 2
          : -(imageHeight - frameHeight) / 2 - 1;
      bounds.top = -(imageHeight - frameHeight) / 2;
    } else {
      bounds.bottom = -((imageHeight - frameHeight) / 2) - offSetY;
      bounds.top =
        -((imageHeight - frameHeight) / 2) + offSetY === 0
          ? -((imageHeight - frameHeight) / 2) + offSetY
          : -((imageHeight - frameHeight) / 2) + offSetY - 1;
    }

    if (offsetX === undefined || offsetX === 0) {
      bounds.left = -(imageWidth - frameWidth) / 2;
      bounds.right =
        -(imageWidth - frameWidth) / 2 === 0
          ? -(imageWidth - frameWidth) / 2
          : -(imageWidth - frameWidth) / 2 - 1;
    } else {
      bounds.left =
        -((imageWidth - frameWidth) / 2) + offSetX === 0
          ? -((imageWidth - frameWidth) / 2) + offSetX
          : -((imageWidth - frameWidth) / 2) + offSetX - 1;
      bounds.right = -((imageWidth - frameWidth) / 2) - offSetX;
    }

    return bounds;
  };

  calculateOffset = () => {
    const offSet: { x: number; y: number } = { x: 0, y: 0 };

    const { frameWidth, frameHeight, offsetX, offsetY } = this.props;
    const { imageHeight, imageWidth } = this.state;
    const percentageFrameWidth = (frameWidth * 100) / imageWidth;
    const percentageFrameHeight = (frameHeight * 100) / imageHeight;

    if (offsetX && offsetX !== 0) {
      offSet.x = (100 - percentageFrameWidth) / 2 - offsetX + 100 / imageWidth;
    }

    if (offsetY && offsetY !== 0) {
      offSet.y =
        (100 - percentageFrameHeight) / 2 - offsetY + 100 / imageHeight;
    }

    return offSet;
  };

  compareFrameAndImageRatio = () => {
    const { frameWidth, frameHeight, imageHeight, imageWidth } = this.props;
    if (frameWidth > frameHeight) {
      const frameRatio = frameWidth / frameHeight;
      const imageRatio = imageWidth / imageHeight;
      return imageRatio < frameRatio;
    } else if (frameWidth < frameHeight) {
      const frameRatio = frameHeight / frameWidth;
      const imageRatio = imageHeight / imageWidth;
      return imageRatio < frameRatio;
    }
  };

  calculateFitToFrameWidthAndHeight = () => {
    const { frameWidth, frameHeight, imageHeight, imageWidth } = this.props;
    const imageSize: { width: number; height: number } = {
      width: frameWidth,
      height: frameHeight
    };

    if (imageWidth > imageHeight) {
      const imageRatio = imageWidth / imageHeight;
      for (let i = frameWidth; i > 0; i--) {
        const calculatedHeight = i / imageRatio;
        if (calculatedHeight <= frameHeight) {
          imageSize.width = i;
          imageSize.height = calculatedHeight;
          return imageSize;
        }
      }
    } else if (imageHeight >= imageWidth) {
      const imageRatio = imageHeight / imageWidth;
      for (let i = frameHeight; i > 0; i--) {
        const calculatedWidth = i / imageRatio;
        if (calculatedWidth <= frameWidth) {
          imageSize.height = i;
          imageSize.width = calculatedWidth;
          return imageSize;
        }
      }
    }

    return imageSize;
  };

  render() {
    const {
      allowEditCaption,
      fitToFrameEnabled,
      frameHeight,
      frameWidth,
      imageHeight,
      imageWidth,
      src,
      unit
    } = this.props;
    const {
      allowDrag,
      caption,
      containerWidth,
      fitToFrame,
      imageOffsetLeft,
      imageOffsetTop
    } = this.state;

    return (
      <ItemStackBlock gap="L">
        <EditorContainer ref={this.editorContainerRef}>
          <FrameWrapper width={containerWidth} height={imageHeight} unit={unit}>
            {fitToFrame ? (
              <>
                <FrameContainer
                  width={frameWidth}
                  height={frameHeight}
                  unit={unit}
                />
                <ImageFitToFrameContainer
                  imageWidth={imageWidth}
                  imageHeight={imageHeight}
                  width={this.calculateFitToFrameWidthAndHeight().width}
                  height={this.calculateFitToFrameWidthAndHeight().height}
                  unit={unit}
                  fitToFrame={fitToFrame}
                >
                  <Image draggable={false} src={src} ref={this.imageRef} />
                </ImageFitToFrameContainer>
              </>
            ) : (
              <>
                <FrameContainer
                  width={frameWidth}
                  height={frameHeight}
                  unit={unit}
                />
                {imageHeight === imageWidth ||
                  this.compareFrameAndImageRatio() ? (
                  <Draggable
                    axis={frameWidth <= frameHeight ? 'x' : 'y'}
                    onDrag={this.handleDrag}
                    bounds={{
                      right: -this.calculateBounds().right,
                      left: this.calculateBounds().left,
                      bottom: -this.calculateBounds().bottom,
                      top: this.calculateBounds().top
                    }}
                    disabled={!allowDrag}
                    positionOffset={{
                      x: `${this.calculateOffset().x}%`,
                      y: `${this.calculateOffset().y}%`
                    }}
                  >
                    <SquareImageContainer
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      width={frameWidth}
                      height={frameHeight}
                      unit={unit}
                      imageOffsetTop={imageOffsetTop}
                      imageOffsetLeft={imageOffsetLeft}
                    >
                      <Image draggable={false} src={src} ref={this.imageRef} />
                    </SquareImageContainer>
                  </Draggable>
                ) : (
                  <Draggable
                    axis={imageWidth > imageHeight ? 'x' : 'y'}
                    onDrag={this.handleDrag}
                    bounds={
                      imageWidth > imageHeight
                        ? {
                          right: -this.calculateBounds().right,
                          left: this.calculateBounds().left
                        }
                        : {
                          bottom: -this.calculateBounds().bottom,
                          top: this.calculateBounds().top
                        }
                    }
                    disabled={!allowDrag}
                    positionOffset={{
                      x: `${this.calculateOffset().x}%`,
                      y: `${this.calculateOffset().y}%`
                    }}
                  >
                    <ImageContainer
                      imageWidth={imageWidth}
                      imageHeight={imageHeight}
                      width={frameWidth}
                      height={frameHeight}
                      unit={unit}
                      imageOffsetTop={imageOffsetTop}
                      imageOffsetLeft={imageOffsetLeft}
                    >
                      <Image
                        draggable={false}
                        src={src}
                        ref={this.imageRef}
                        width={imageWidth}
                        height={imageHeight}
                      />
                    </ImageContainer>
                  </Draggable>
                )}
              </>
            )}
          </FrameWrapper>
        </EditorContainer>

        <ItemStackBlock gap="XS">
          <EveryCard as="button" onClick={this.turn}>
            <EveryCardPadding>
              <EveryCardBody>
                <HorizontalStackBlock gap="S" centered={true}>
                  <IconComponent icon="ARROW_ROTATE" fill="BLACK" size={1} />
                  <TextElement  color="TEXT_DARK">
                    <FormattedMessage id="Rotate photo" />
                  </TextElement>
                </HorizontalStackBlock>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>

          {fitToFrameEnabled && (
            <>
              <CustomRadioBlock>
                <CustomRadioBlock.RadioElement
                  name="fitToFrame"
                  value="false"
                  onChange={this.fitToFrameChange}
                  checked={!fitToFrame}
                />
                <CustomRadioBlock.LabelElement>
                  <TextElement  color="TEXT_DARK">
                    <FormattedMessage id="adjust photo" />
                  </TextElement>
                </CustomRadioBlock.LabelElement>
              </CustomRadioBlock>
              <CustomRadioBlock>
                <CustomRadioBlock.RadioElement
                  name="fitToFrame"
                  value="true"
                  onChange={this.fitToFrameChange}
                  checked={fitToFrame}
                />
                <CustomRadioBlock.LabelElement>
                  <TextElement  color="TEXT_DARK">
                    <FormattedMessage id="fit photo to frame" />
                  </TextElement>
                </CustomRadioBlock.LabelElement>
              </CustomRadioBlock>
            </>
          )}
        </ItemStackBlock>

        {allowEditCaption && (
          <TextareaComponent
            name="caption"
            value={caption}
            label={<FormattedMessage id="Photo caption label" />}
            placeholder={intl.formatMessage({
              id: 'Photo caption placeholder'
            })}
            onChange={this.setCaption}
          />
        )}

        <ButtonBlock background={'PRIMARY'} onClick={this.save}>
          <FormattedMessage id="Save photo" />
        </ButtonBlock>
      </ItemStackBlock>
    );
  }
}

export default ImageEditorComponent;
