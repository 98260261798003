import { BaseTextileDesignSetting } from 'api/textile_deals/fetchTextileDesignSetting';
import config from 'config';
import React, { useEffect, useState } from 'react';
import { HistoryProps } from 'utils/history';
import PrintExportErrorComponent from './PrintExportErrorComponent';
import PrintTextileBackComponent from './textiles/PrintTextileBackComponent';
import PrintTextileFrontComponent from './textiles/PrintTextileFrontComponent';
import { TextilePrintParams } from './textiles/PrintTextileProps';
const BASE_PATH_DEV = '/print-export';
const BASE_PATH_PROD = `${BASE_PATH_DEV}/${process.env.NUGGIT_ENVIRONMENT}`;

const PrintTextileContainer: React.FC<HistoryProps> = (props) => {
  const printParamsFromProps = (): TextilePrintParams => {
    const query = props.location.query || {};

    return {
      printId: query.printId || 'unknown',
      settingsId: query.settingsId || '0',
      textile: query.textile || 'front'
    };
  };
  const [loadingState, setLoadingState] = useState<string | undefined>(
    undefined
  );
  const params = printParamsFromProps();
  const [data, setData] = useState<any>(undefined);

  const loadSettings = async () => {
    const { printId, settingsId } = params;
    const basePath = config.env.development ? BASE_PATH_DEV : BASE_PATH_PROD;

    try {
      // fetch export JSON
      const response = await fetch(
        basePath + '/textile_' + printId + '/settings-' + settingsId + '.json'
      );
      const json = await response.json();

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('loadSettings result:', printId, settingsId, json);
      }

      setLoadingState('ready');
      setData(json);
    } catch (err) {
      console.error('loadSettings error:', err);
      setLoadingState('error');
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const renderMessage = (message: string) => {
    return <PrintExportErrorComponent message={message} />;
  };

  const renderSettings = () => {
    if (!data) {
      return renderMessage('no textile data present');
    }

    // TODO: fix print with correct design settings
    // const settings = createTextileDesignSettingsModel(data);
    const settings: BaseTextileDesignSetting = data;
    if (!settings) {
      return renderMessage('settings missing');
    }

    switch (params.textile) {
      case 'front':
        return <PrintTextileFrontComponent settings={settings!} />;
      case 'back':
        return (
          <PrintTextileBackComponent
            params={params}
            settings={settings!}
            data={data}
          />
        );
      default:
        return renderMessage('settings type not supported');
    }
  };

  switch (loadingState) {
    case 'loading':
    case 'error':
      return renderMessage('Print state: ' + loadingState);
    case 'ready':
      return renderSettings();

    default:
      return renderMessage('Print state: not initialized');
  }
};

export default PrintTextileContainer;
