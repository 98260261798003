import { Instance, types } from 'mobx-state-tree';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import stringOrUndefined from 'utils/store/stringOrUndefined';

import { PriceTierEnum } from './BookModel';
import { createInvoiceModel, InvoiceModel } from './InvoiceModel';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';

const BindingTypesEnum = types.enumeration(['softcover', 'hardcover']);

export type BindingType = Instance<typeof BindingTypesEnum>;

export const CheckoutStepEnum = types.enumeration([
  'pricing',
  'address',
  'printing',
  'age',
  'delivery_date',
  'agb',
  'complete'
]);
export type CheckoutStep = Instance<typeof CheckoutStepEnum>;

// Context for updating the checkout
export type UpdateCheckoutContext = Exclude<CheckoutStep, 'complete'>;

export type CheckoutNavigationStep = Exclude<
  CheckoutStep,
  'pricing' | 'complete'
>;

// Steps for navigation
export const CHECKOUT_NAVIGATION_STEPS: CheckoutNavigationStep[] = [
  // pricing missing on purpose
  'address',
  'printing',
  'age',
  'delivery_date',
  'agb'
  // complete missing on purpose
];

const CheckoutModel = types.model('CheckoutModel', {
  id: types.identifierNumber,

  // Pricing context
  binding_type: types.maybe(BindingTypesEnum),
  pieces: types.maybe(types.number),

  // Shipping context
  shipping_name: types.maybe(types.string),
  phone_number: types.maybe(types.string),
  shipping_address_extra: types.maybe(types.string),
  shipping_city: types.maybe(types.string),
  shipping_zip: types.maybe(types.string),
  shipping_street: types.maybe(types.string),
  shipping_housenumber: types.maybe(types.string),

  // Billing context
  billing_name: types.maybe(types.string),
  billing_address_extra: types.maybe(types.string),
  billing_city: types.maybe(types.string),
  billing_zip: types.maybe(types.string),
  billing_street: types.maybe(types.string),
  billing_housenumber: types.maybe(types.string),
  billing_address_set: types.boolean,

  // Printing context
  accept_print: types.maybe(types.boolean),

  // AGB context
  accept_agb: types.maybe(types.boolean),

  // Age context
  minor: types.maybe(types.boolean),

  last_invalid_step: CheckoutStepEnum,

  invoice: types.maybe(InvoiceModel),

  parental_approval: types.maybe(types.string),

  parental_approval_checked: types.maybe(types.boolean),

  payment_received: types.maybe(types.boolean),

  author: types.maybe(ProfileAsAuthorModel),
  price_tier: types.maybe(PriceTierEnum),
});

export const createCheckoutModel = (
  data?: any
): CheckoutModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return CheckoutModel.create({
    id: mandatoryId(data.id),

    binding_type: BindingTypesEnum.is(data.binding_type)
      ? data.binding_type
      : undefined,
    pieces: numberOrUndefined(data.pieces),

    shipping_name: data.shipping_name || undefined,
    phone_number: data.phone_number || undefined,
    shipping_address_extra: data.shipping_address_extra || undefined,
    shipping_city: data.shipping_city || undefined,
    shipping_zip: data.shipping_zip || undefined,
    shipping_street: data.shipping_street || undefined,
    shipping_housenumber: data.shipping_housenumber || undefined,

    billing_name: data.billing_name || undefined,
    billing_address_extra: data.billing_address_extra || undefined,
    billing_city: data.billing_city || undefined,
    billing_zip: data.billing_zip || undefined,
    billing_street: data.billing_street || undefined,
    billing_housenumber: data.billing_housenumber || undefined,
    billing_address_set: data.billing_address_set || false,

    accept_print: data.accept_print,

    accept_agb: data.accept_agb,

    minor: booleanOrUndefined(data.minor),

    last_invalid_step: data.last_invalid_step,

    invoice: createInvoiceModel(data.invoice),

    parental_approval: stringOrUndefined(data.parental_approval),
    parental_approval_checked: booleanOrUndefined(data.parental_approval_checked),

    payment_received: booleanOrUndefined(data.payment_received),

    author: createProfileAsAuthorModel(data.author),
    price_tier: PriceTierEnum.is(data.price_tier)
    ? data.price_tier
    : undefined,
  });
};

export type CheckoutModelType = Instance<typeof CheckoutModel>;
export default CheckoutModel;
