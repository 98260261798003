import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_COMMENTS } from 'utils/constants/routes';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import CommentDetailScreen from './screens/CommentDetail/CommentDetailScreen';
import MyCommentsListScreen from './screens/MyCommentsListScreen';
import AddCommentScreen from './screens/AddCommentScreen';
import SortCommentsScreen from './screens/SortComments/SortCommentsScreen';

export default () => (
  <Switch>
    <Route path={ROUTE_COMMENTS} exact={true} component={MyCommentsListScreen} />

    <Route path={ROUTE_COMMENTS + '/sort/:id'} component={SortCommentsScreen} />
    <Route path={ROUTE_COMMENTS + '/new'} component={AddCommentScreen} />
    <Route path={ROUTE_COMMENTS + '/:id'} component={CommentDetailScreen} />

    <NotFoundRoute />
  </Switch>
);
