import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import Divider from 'components/Divider/Divider';
import TextileHeaderComponent from 'components/HeaderComponent/TextileHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import SharingComponent from 'components/SharingComponent';
import Headline from 'elements/Headline';
import { useTextileStudentDashboardQuery } from 'queries/textile_deals/useTextileDashboardQueries';
import { FormattedMessage } from 'react-intl';
import COLORS from 'utils/constants/colors';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import isTextileLocked from 'utils/isTextileLocked';
import TextileMyProfileBlockComponent from './cards/TextileMyProfileBlockComponent';
import TextilePreorderStudentBlockComponent from './cards/TextilePreorderStudentBlockComponent';

const TextileStudentDashboardScreen: React.FC<HistoryProps> = ({ match }) => {
  const textileOrderId = textileOrderIdFromMatch(match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const { data, isError, isLoading, refetch } = useTextileStudentDashboardQuery(
    textileOrderId
  );

  const renderPage = (content?: any) => {
    return (
      <>
        <TextileHeaderComponent
          linkHome={textileOrderRoute(match, '')}
          linkAccount={textileOrderRoute(match, '/account')}
        />

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (!textileOrderId) {
    return null;
  }

  if (isError) {
    return <GenericErrorComponent onRetryClick={() => refetch()} />;
  }

  if (isLoading || !data || !textileAppState) {
    return <LoadingIndicatorComponent />;
  }

  return renderPage(
    <>
      <PageHeader
        headline={<FormattedMessage id="textile dashboard" />}
        text={<FormattedMessage id="textile dashboard info" />}
      />

      {data.requested_organizer_role && (
        <>
          <PaddingBlock style={{ backgroundColor: COLORS.PRIMARY_LIGHT }}>
            <Headline.Small>
              <FormattedMessage id="role change requested header" />
            </Headline.Small>
            <Paragraph>
              <FormattedMessage id="role change requested" />
            </Paragraph>
          </PaddingBlock>
          <Divider />
        </>
      )}

      <SharingComponent
        label="invite your fellow students"
        buttonOutside={false}
        modalHeader="invite link"
        message="Nuggit invite text"
        buttonColor="WHATSAPP"
        url={textileAppState.textile_order.invitation_url}
      />

      <Divider />

      <ItemStackBlock gap="S">
        <TextileMyProfileBlockComponent
          textileOrderId={textileOrderId}
          locked={isTextileLocked(
            textileAppState.textile_order.order_state,
            'atLeastOrderStarted'
          )}
          nameVerified={data.name_verified}
          printDataLocked={data.print_data_locked}
          printDataOpen={textileAppState.textile_order.print_data_edit_open}
        />

        <Divider />

        <TextilePreorderStudentBlockComponent
          textileOrderId={textileOrderId}
          locked={isTextileLocked(
            textileAppState.textile_order.order_state,
            'atLeastOrderStartedOrPreparing'
          )}
          preorderOpen={textileAppState.textile_order.preorder_open}
          preorderState={data.preorder_state}
        />
      </ItemStackBlock>
    </>
  );
};

export default TextileStudentDashboardScreen;
