/* eslint-disable react/style-prop-object */
import React from 'react';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import Headline from 'elements/Headline';
import { BookPriceModelType } from 'models/BookPriceModel';
import { BindingType } from 'models/CheckoutModel';
import { DiscountModelType } from 'models/DiscountModel';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { assert } from 'utils/assert';
import COLORS from 'utils/constants/colors';
import { FormType } from 'utils/hooks/useForm';

interface CalculatorPriceProps {
  form: FormType;
  bookPrice: BookPriceModelType;
  bindingType: BindingType;
  type: 'checkout' | 'calculator';
}

const IconWrapper = styled.div`
  flex-shrink: 0;
`;

const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: ${COLORS.GRAY950};
  border-radius: 50%;
  line-height: 1;
`;

const DiscountLine = ({
  name,
  value,
  checked,
  info
}: {
  name: React.ReactNode;
  value: number;
  checked: boolean;
  info?: string;
}) => (
  <>
    <HorizontalStackBlock justified={true} centered={true}>
      <HorizontalStackBlock gap="S">
        <IconWrapper>
          {checked ? (
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
          ) : (
            <Dot />
          )}
        </IconWrapper>
        <div>
          <Paragraph size="L" weight="BOLD" >
            {name}
          </Paragraph>
          {info && (
            <Paragraph size="S"  color="TEXT_LIGHT">
              {info}
            </Paragraph>
          )}
        </div>
      </HorizontalStackBlock>
      <Paragraph  color="TEXT_LIGHT" noBreak={true}>
        -&nbsp;
        <FormattedNumber value={value} style="currency" currency="EUR" />
      </Paragraph>
    </HorizontalStackBlock>
    <Divider useStackBlockGap={true} />
  </>
);

const SponsorLine = ({
  name,
  value,
  checked,
  message,
  placed,
  total
}: {
  name: React.ReactNode;
  value: number;
  checked: boolean;
  message: string;
  placed: number;
  total: number;
}) => (
  <>
    <HorizontalStackBlock justified={true} centered={true}>
      <HorizontalStackBlock gap="S">
        <IconWrapper>
          {checked ? (
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
          ) : (
            <Dot />
          )}
        </IconWrapper>
        <div>
          <Paragraph size="L" weight="BOLD" >
            {name}
          </Paragraph>
          <Paragraph size="S"  color="TEXT_LIGHT">
            <FormattedMessage
              id={message}
              values={{
                total,
                placed
              }}
            />
          </Paragraph>
        </div>
      </HorizontalStackBlock>
      <Paragraph  color="TEXT_LIGHT" noBreak={true}>
        -&nbsp;
        <FormattedNumber value={value} style="currency" currency="EUR" />
      </Paragraph>
    </HorizontalStackBlock>
    <Divider useStackBlockGap={true} />
  </>
);

export const Discount = ({
  discount,
  calculatorType
}: {
  discount: DiscountModelType;
  calculatorType: 'checkout' | 'calculator';
}) => {
  let info: string | undefined;
  if (discount.checked) {
    info = discount.info_done;
  } else {
    if (calculatorType === 'calculator') {
      info = discount.info;
    } else {
      info = discount.info_not_used;
    }
  }
  return (
    <DiscountLine
      name={discount.name}
      value={discount.value}
      info={info}
      checked={discount.checked}
    />
  );
};

export const Sponsors = ({
  id,
  value,
  checked,
  message,
  placed,
  total
}: {
  id: string;
  value: number;
  checked: boolean;
  message: string;
  placed: number;
  total: number;
}) => (
  <SponsorLine
    name={<FormattedMessage id={id} />}
    value={value}
    checked={checked}
    message={message}
    placed={placed}
    total={total}
  />
);

const CalculatorPrice = ({
  form,
  bookPrice,
  bindingType,
  type
}: CalculatorPriceProps) => {
  const formPriceTier: 'silver' | 'gold' | 'platinum' = form.values.price_tier === 'platinum_light' ? 'gold' : form.values.price_tier
  const priceForBinding = bookPrice.price[formPriceTier as keyof typeof bookPrice.price][bindingType];
  if (!priceForBinding) {
    return null;
  }
  assert(priceForBinding);

  const placedSponsorsCount = bookPrice.nuggit_sponsors.placed_count;
  const totalSponsorsCount = bookPrice.nuggit_sponsors.total_count;
  const placedSponsorsValue = bookPrice.nuggit_sponsors.placed;
  let totalSponsorsValue = bookPrice.nuggit_sponsors.total;

  let sponsorTextID = 'Sponsor all placed';

  let sponsorChecked = totalSponsorsCount === placedSponsorsCount;

  if (placedSponsorsCount === 0) {
    sponsorTextID = 'Sponsor default amount';
  } else if (totalSponsorsCount > placedSponsorsCount) {
    sponsorTextID = 'Sponsor guaranteed';
  }

  if (type === 'checkout') {
    sponsorTextID = 'Sponsor count placed';
    sponsorChecked = placedSponsorsCount > 0;
    totalSponsorsValue = placedSponsorsValue;
  }

  const totalPrice =
    type === 'calculator'
      ? priceForBinding.possible_price
      : priceForBinding.end_price;

  const pricePerPiece = totalPrice / bookPrice.pieces;

  return (
    <>
      <ItemStackBlock gap="M">
        <Headline.Medium>
          <FormattedMessage id="Price and discounts" />
        </Headline.Medium>
        {/* Gross price */}

        <ItemStackBlock gap="XXS">
          <HorizontalStackBlock justified={true}>
            <Paragraph  color="TEXT_LIGHT">
              <FormattedMessage id="Price without discounts" />
            </Paragraph>
            <Paragraph  color="TEXT_LIGHT">
              <FormattedNumber
                value={priceForBinding.price}
                style="currency"
                currency="EUR"
              />
            </Paragraph>
          </HorizontalStackBlock>
          <Divider useStackBlockGap={true} />
        </ItemStackBlock>

        <ItemStackBlock gap="S">
          <Sponsors
            id="Nuggit sponsors"
            value={totalSponsorsValue}
            checked={sponsorChecked}
            message={sponsorTextID}
            placed={placedSponsorsCount}
            total={totalSponsorsCount}
          />

          {bookPrice.discounts.map((discount, index) => (
            <Discount
              key={`${index}-${discount.identifier}`}
              discount={discount}
              calculatorType={type}
            />
          ))}
        </ItemStackBlock>

        <ItemStackBlock gap="M">
          <HorizontalStackBlock justified={true}>
            <ItemStackBlock gap="XXS">
              <Paragraph size="L" weight="BOLD" >
                {type === 'calculator' ? (
                  <FormattedMessage id="Total price possible" />
                ) : (
                  <FormattedMessage id="Total price" />
                )}
              </Paragraph>
              <TextElement>
                <FormattedMessage id="VAT info" />
              </TextElement>
            </ItemStackBlock>

            <Paragraph
              size="L"
              noBreak={true}
            >
              <FormattedNumber
                value={totalPrice}
                style="currency"
                currency="EUR"
              />
            </Paragraph>
          </HorizontalStackBlock>
          <HorizontalStackBlock justified={true}>
            <Paragraph  color="TEXT_LIGHT">
              <FormattedMessage id="Price per book" />
            </Paragraph>
            <Paragraph
              noBreak={true}
            >
              <FormattedNumber
                value={pricePerPiece}
                style="currency"
                currency="EUR"
              />
            </Paragraph>
          </HorizontalStackBlock>
        </ItemStackBlock>
      </ItemStackBlock>
      <Divider />
    </>
  );
};

export default CalculatorPrice;
