import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_TOC } from 'utils/constants/routes';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import TocScreen from './TocScreen';
import TocSettings from './TocSettings';
import TocNewEntry from './TocNewEntry';
import TocAddEntryScreen from './TocAddEntry';
import TocEditEntryScreen from './TocEditEntry';

export default () => (
  <Switch>
    <Route
      path={ROUTE_TOC + '/:chapterId'}
      exact={true}
      component={TocScreen}
    />

    <Route
      path={ROUTE_TOC + '/:chapterId/settings'}
      component={TocSettings}
    />

    <Route path={ROUTE_TOC + '/:chapterId/new'} component={TocNewEntry} />
    <Route path={ROUTE_TOC + '/:chapterId/add/:chapterId'} component={TocAddEntryScreen} />
    <Route path={ROUTE_TOC + '/:chapterId/edit/:chapterId'} component={TocEditEntryScreen} />

    <NotFoundRoute />
  </Switch>
);
