import { createGlobalStyle } from 'styled-components';

import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

interface StyleBlockProps {
  noColor?: boolean;
}

export default createGlobalStyle<StyleBlockProps>`
  /* stylelint-disable selector-list-comma-newline-after */
  /* stylelint-disable declaration-block-no-shorthand-property-overrides */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    border: 0;
    padding: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 100%;

    @media screen and (min-width: 36em) {
      font-size: 125%;
    }

    @media screen and (min-width: 64em) {
      font-size: 130%;
    }
  }

  /* stylelint-disable no-duplicate-selectors */
  body {
    ${(props) => !props.noColor && (
      `background: ${COLORS.GRAY950};`
    )}
    font-family: ${FONTS.DEFAULT};
    font-weight: 400;
  }

  a {
    font-size: 1em;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0.03125em;
    color: ${COLORS.PRIMARY};

    &:hover {
      color: ${COLORS.SECONDARY_DARK};
    }
  }

  button {
    cursor: pointer;
  }

  span.emoji {
    font-family: "Noto Color Emoji", "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  @keyframes sk-rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes sk-bounce {
    0%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    50% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;
