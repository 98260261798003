import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_ALBUM_PAGE, ROUTE_NEW_ALBUM_PAGE } from 'utils/constants/routes';

import AlbumPage from './AlbumPage';
import NewAlbumPage from './NewAlbumPage';

export default () => (
  <Switch>
    <Route path={ROUTE_NEW_ALBUM_PAGE} component={NewAlbumPage} />
    <Route path={ROUTE_ALBUM_PAGE} component={AlbumPage} exact={true} />

    <NotFoundRoute />
  </Switch>
);
