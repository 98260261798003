import React from 'react';
import { FormattedMessage } from 'react-intl';
import ItemStackBlock from 'blocks/ItemStackBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';

const PrintFormat = () => (
  <>
    <ItemStackBlock gap="XS">
      <HorizontalStackBlock justified={true}>
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="Checkout format" />
        </Paragraph>
        <TextElement size="M" color="TEXT_DARK">
          <FormattedMessage id="Checkout format info" />
        </TextElement>
      </HorizontalStackBlock>
      <HorizontalStackBlock justified={true}>
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="Checkout paper inside" />
        </Paragraph>
        <TextElement size="M"  color="TEXT_DARK">
          <FormattedMessage id="Checkout paper inside info" />
        </TextElement>
      </HorizontalStackBlock>
      <HorizontalStackBlock justified={true}>
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="Checkout paper cover" />
        </Paragraph>
        <TextElement size="M"  color="TEXT_DARK">
          <FormattedMessage id="Checkout paper cover info" />
        </TextElement>
      </HorizontalStackBlock>
      <HorizontalStackBlock justified={true}>
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="Checkout refining" />
        </Paragraph>
        <TextElement size="M"  color="TEXT_DARK">
          <FormattedMessage id="Checkout refining info" />
        </TextElement>
      </HorizontalStackBlock>
    </ItemStackBlock>
  </>
);

export default PrintFormat;
