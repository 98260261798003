import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { TextileAppState } from "api/textile_deals/fetchTextileAppState"
import { restartCheckout } from "api/textile_deals/fetchTextileCheckout"
import { BaseTextileOrder, TextileOrderForGroups, TextileOrderForManagement, TextileOrderForPreorder, createTextileOrder, createTextileSupportTicket, generateTextilePreview, getAllTextileOrders, getTextileOrderForCheckoutById, getTextileOrderForGroupsById, getTextileOrderForManagementById, getTextileOrderForPreorderById, pausePreorder, requestTextileDesignTicket, startPreorder, unpausePreorder, updateTextileOrder } from "api/textile_deals/fetchTextileOrders"


export const useTextileOrdersQuery = () => {
  return useQuery({
    queryKey: ['textile_orders'],
    queryFn: () => getAllTextileOrders(),
    staleTime: 600000 // we dont want to fetch this every time, this data can be stale for a while in the normal app context
  })
}

export const useTextileOrderForGroupsQuery = (id?: number) => {
  return useQuery({
    queryKey: ['textile_order', id],
    queryFn: () => getTextileOrderForGroupsById(id!),
    enabled: !!id,
  })
}

export const useTextileOrderForManagementQuery = (id?: number) => {
  return useQuery({
    queryKey: ['textile_order', id],
    queryFn: () => getTextileOrderForManagementById(id!),
    enabled: !!id,
  })
}

export const useTextileOrderForPreorderQuery = (id?: number) => {
  return useQuery({
    queryKey: ['textile_order', id],
    queryFn: () => getTextileOrderForPreorderById(id!),
    enabled: !!id,
  })
}

export const useTextileOrderForCheckoutQuery = (id?: number) => {
  return useQuery({
    queryKey: ['textile_order', id],
    queryFn: () => getTextileOrderForCheckoutById(id!),
    enabled: !!id,
  })
}

export const useRequestTextileDesignTicketQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      requestTextileDesignTicket(id),
      onSettled: () => {
        queryClient.invalidateQueries(['textile_app_state', textileOrderId]);
      }
    });
}

export const useOptimisticUpdateTextileOrderQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: {data: any, textileOrderId: number}) =>
    updateTextileOrder(patch.data, patch.textileOrderId),
      onMutate: async (patch) => {
        await queryClient.cancelQueries(['textile_order', patch.textileOrderId]);
        const previousState:
          | BaseTextileOrder | TextileOrderForGroups | TextileOrderForManagement | TextileOrderForPreorder
          | undefined = queryClient.getQueryData([
          'textile_order',
          patch.textileOrderId
        ]);

        if (!previousState) {
          return;
        }


        const newState = patch.data
        queryClient.setQueryData(['textile_order', patch.textileOrderId], newState);

        return { previousState };
      },
      onError: (err, patch, context) => {
        queryClient.setQueryData(
          ['textile_order', patch.textileOrderId],
          context?.previousState
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['textile_order', textileOrderId]);
      }
  });
};

export const useStartPreorderQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (textileOrderId: number) =>
    startPreorder(textileOrderId),
      onMutate: async (_textileOrderId) => {
        await queryClient.cancelQueries(['textile_order', _textileOrderId]);
        await queryClient.cancelQueries(['textile_app_state', _textileOrderId]);
        const previousState:
          | BaseTextileOrder | TextileOrderForGroups | TextileOrderForManagement | TextileOrderForPreorder
          | undefined = queryClient.getQueryData([
          'textile_order',
          _textileOrderId
        ]);

        const previousStateApp: TextileAppState | undefined =  queryClient.getQueryData([
          'textile_app_state',
          _textileOrderId
        ]);

        if (!previousState || !previousStateApp) {
          return;
        }

        const newState = {...previousState, order_state: 'preorder'}
        queryClient.setQueryData(['textile_order', _textileOrderId], newState);
        const newAppState = {...previousStateApp, textile_order: {...previousStateApp.textile_order, order_state: 'preorder' }}
        queryClient.setQueryData(['textile_app_state', _textileOrderId], newAppState);

        return { previousState, previousStateApp };
      },
      onError: (err, _textileOrderId, context) => {
        queryClient.setQueryData(
          ['textile_order', _textileOrderId],
          context?.previousState
        );
        queryClient.setQueryData(
          ['textile_app_state', _textileOrderId],
          context?.previousStateApp
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['textile_order', textileOrderId]);
        queryClient.invalidateQueries(['textile_app_state', textileOrderId]);
      }
  });
}

export const usePausePreorderQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (textileOrderId: number) =>
    pausePreorder(textileOrderId),
      onMutate: async (_textileOrderId) => {
        await queryClient.cancelQueries(['textile_order', _textileOrderId]);
        await queryClient.cancelQueries(['textile_app_state', _textileOrderId]);
        const previousState:
          | BaseTextileOrder | TextileOrderForGroups | TextileOrderForManagement | TextileOrderForPreorder
          | undefined = queryClient.getQueryData([
          'textile_order',
          _textileOrderId
        ]);

        const previousStateApp: TextileAppState | undefined =  queryClient.getQueryData([
          'textile_app_state',
          _textileOrderId
        ]);

        if (!previousState || !previousStateApp) {
          return;
        }

        const newState = {...previousState, order_state: 'preorder_paused'}
        queryClient.setQueryData(['textile_order', _textileOrderId], newState);
        const newAppState = {...previousStateApp, textile_order: {...previousStateApp.textile_order, order_state: 'preorder_paused' }}
        queryClient.setQueryData(['textile_app_state', _textileOrderId], newAppState);

        return { previousState, previousStateApp };
      },
      onError: (err, _textileOrderId, context) => {
        queryClient.setQueryData(
          ['textile_order', _textileOrderId],
          context?.previousState
        );
        queryClient.setQueryData(
          ['textile_app_state', _textileOrderId],
          context?.previousStateApp
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['textile_order', textileOrderId]);
        queryClient.invalidateQueries(['textile_app_state', textileOrderId]);
      }
  });
}

export const useUnpausePreorderQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (textileOrderId: number) =>
    unpausePreorder(textileOrderId),
      onMutate: async (_textileOrderId) => {
        await queryClient.cancelQueries(['textile_order', _textileOrderId]);
        await queryClient.cancelQueries(['textile_app_state', _textileOrderId]);
        const previousState:
          | BaseTextileOrder | TextileOrderForGroups | TextileOrderForManagement | TextileOrderForPreorder
          | undefined = queryClient.getQueryData([
          'textile_order',
          _textileOrderId
        ]);

        const previousStateApp: TextileAppState | undefined =  queryClient.getQueryData([
          'textile_app_state',
          _textileOrderId
        ]);

        if (!previousState || !previousStateApp) {
          return;
        }

        const newState = {...previousState, order_state: 'preorder'}
        queryClient.setQueryData(['textile_order', _textileOrderId], newState);
        const newAppState = {...previousStateApp, textile_order: {...previousStateApp.textile_order, order_state: 'preorder' }}
        queryClient.setQueryData(['textile_app_state', _textileOrderId], newAppState);

        return { previousState, previousStateApp };
      },
      onError: (err, _textileOrderId, context) => {
        queryClient.setQueryData(
          ['textile_order', _textileOrderId],
          context?.previousState
        );
        queryClient.setQueryData(
          ['textile_app_state', _textileOrderId],
          context?.previousStateApp
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['textile_order', textileOrderId]);
        queryClient.invalidateQueries(['textile_app_state', textileOrderId]);
      }
  });
}

export const useRestartCheckoutQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (textileOrderId: number) =>
    restartCheckout(textileOrderId),
      onMutate: async (_textileOrderId) => {
        await queryClient.cancelQueries(['textile_order', _textileOrderId]);
        await queryClient.cancelQueries(['textile_app_state', _textileOrderId]);
        const previousState:
          | BaseTextileOrder | TextileOrderForGroups | TextileOrderForManagement | TextileOrderForPreorder
          | undefined = queryClient.getQueryData([
          'textile_order',
          _textileOrderId
        ]);

        const previousStateApp: TextileAppState | undefined =  queryClient.getQueryData([
          'textile_app_state',
          _textileOrderId
        ]);

        if (!previousState || !previousStateApp) {
          return;
        }

        const newState = {...previousState, order_state: 'order_started'}
        queryClient.setQueryData(['textile_order', _textileOrderId], newState);
        const newAppState = {...previousStateApp, textile_order: {...previousStateApp.textile_order, order_state: 'order_started' }}
        queryClient.setQueryData(['textile_app_state', _textileOrderId], newAppState);

        return { previousState, previousStateApp };
      },
      onError: (err, _textileOrderId, context) => {
        queryClient.setQueryData(
          ['textile_order', _textileOrderId],
          context?.previousState
        );
        queryClient.setQueryData(
          ['textile_app_state', _textileOrderId],
          context?.previousStateApp
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['textile_order', textileOrderId]);
        queryClient.invalidateQueries(['textile_app_state', textileOrderId]);
      }
  });
}

export const useGenerateTextilePreviewQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (textileOrderId: number) =>
    generateTextilePreview(textileOrderId),
      onMutate: async (_textileOrderId) => {
        await queryClient.cancelQueries(['textile_order', _textileOrderId]);
        const previousState:
          | BaseTextileOrder | TextileOrderForGroups | TextileOrderForManagement | TextileOrderForPreorder
          | undefined = queryClient.getQueryData([
          'textile_order',
          _textileOrderId
        ]);

        if (!previousState) {
          return;
        }


        const newState = {...previousState, print_state: 'generating'}
        queryClient.setQueryData(['textile_order', _textileOrderId], newState);

        return { previousState };
      },
      onError: (err, _textileOrderId, context) => {
        queryClient.setQueryData(
          ['textile_order', _textileOrderId],
          context?.previousState
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['textile_order', textileOrderId]);
      }
  });
};

export const useCreateTextileOrderQuery = () => {
  return useMutation({
    mutationFn: () =>
    createTextileOrder()
  });
}

export const useTextileSupportTicketQuery = () => {
  return useMutation({
    mutationFn: (patch: {subject?: string, message?: string, textileOrderId?: number}) =>
    createTextileSupportTicket(patch.subject, patch.message, patch.textileOrderId),
    });
}