import React, { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import Divider from 'components/Divider/Divider';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import SharingComponent from 'components/SharingComponent';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const TextileOnboardingInviteScreen: React.FC<HistoryProps> = (props) => {
  const [didInteract, setDidInteract] = useState<boolean>(
    false
  );
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  useEffect(() => {
    if (!textileAppState?.textile_profile.is_manager) {
      props.history.replace(textileOrderRoute(props.match, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textileAppState?.textile_profile])

  if (!textileAppState) {
    return <LoadingIndicatorComponent />
  }

  return (
    <>
      <SignupHeaderComponent progressStatus={50} />

      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Invite students" />}
          text={<FormattedMessage id="onboarding invite text" />}
        />

        <SharingComponent
          label="personal invite link"
          buttonOutside={true}
          modalHeader="invite link"
          message="Nuggit invite text"
          buttonColor="WHATSAPP"
          url={textileAppState?.textile_order.invitation_url}
          onDidInteract={() => setDidInteract(true)}
        />

        <Divider />

        {didInteract ? (
          <ButtonBlock
            background="PRIMARY"
            color="WHITE"
            onClick={() =>
              props.history.push(textileOrderRoute(props.match, ''))
            }
          >
            <FormattedMessage id="Next" />
          </ButtonBlock>
        ) : (
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() =>
              props.history.push(textileOrderRoute(props.match, ''))
            }
          >
            <FormattedMessage id="Later" />
          </ButtonBlock>
        )}
      </PageStackBlock>
    </>
  );
};

export default TextileOnboardingInviteScreen;
