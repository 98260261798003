import { types, Instance } from 'mobx-state-tree';

import VoteCandidateModel from './VoteCandidateModel';

const VoteModel = types.model('VoteModel', {
  candidates: types.maybe(types.map(VoteCandidateModel)),
  count: types.maybe(types.number),
  excluded: types.maybe(types.boolean),
  ranking_exclusion_id: types.maybe(types.number)
});

export type VoteModelType = Instance<typeof VoteModel>;
export default VoteModel;
