import React, { Component } from 'react';

import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import config from 'config';
import {
  inject,
  observer
} from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import {
  finishFunnel,
  funnelSite
} from 'utils/analytics';
import { HistoryProps } from 'utils/history';
import useForm, {
  FormType,
  handleFormError
} from 'utils/hooks/useForm';
import { local } from 'utils/storage';

import ClassSize from '../ClassSize';
import Design from '../Design';
import FormErrors from '../FormErrors';
import FunnelPage from '../FunnelPage';
import LoggedInSuccess from '../LoggedInSuccess';
import PersonalData from '../PersonalData';
import PublicSuccess from '../PublicSuccess';
import SchoolType from '../SchoolType';
import Year from '../Year';
import ClothingTextile from './ClothingTextile';

interface Props extends HistoryProps {
  applicationStore: ApplicationStoreType;
  form: FormType;
}

const SCREENS = [
  'textile',
  'design',
  'year',
  'school_type',
  'class_size',
  'logged_in_success',
  'personal_data',
  'public_success'
] as const;
type Screen = typeof SCREENS[number];

const screenUrl = (screen: Screen) => `?screen=${screen}`;

@inject('applicationStore')
@observer
class ClothingFunnelScreen extends Component<Props> {
  isAuthenticated = false;
  fromDashboard?: string;
  textileOrderId?: string;

  componentDidMount() {
    const { form, history } = this.props;

    this.setRef();

    // Sets backlink and logo link to dashboard if user came from dashboard
    this.fromDashboard = history.location.state?.fromDashboard;
    this.textileOrderId = history.location.state?.textileOrderId;
    if (form.values.init === false) {
      history.replace(screenUrl(SCREENS[0]));
      form.setField('init', true);
    }

    funnelSite('textile');
  }

  setRef() {
    const { query } = this.props.location;

    // Get ref from query string
    const ref = query?.ref?.trim();
    if (ref) {
      local.set(config.signupRefName, ref);
    }
  }

  async decidePath() {
    const { applicationStore, history } = this.props;
    this.isAuthenticated = await applicationStore.checkAuthenticated();
    if (this.isAuthenticated) {
      this.submit();
    } else {
      history.push(screenUrl('personal_data'));
    }
  }

  async submit() {
    const { applicationStore, form, history } = this.props;

    delete form.values.init;
    try {
      await applicationStore.submitFunnel(form.values);
    } catch (error: any) {
      handleFormError(form, error);
      return;
    }
    if (this.isAuthenticated && applicationStore.currentAccount ) {
      finishFunnel('textile', applicationStore.currentAccount.mobile_number);
    } else {
      finishFunnel('textile', form.values.mobile_number);
    }
    history.push(
      screenUrl(this.isAuthenticated ? 'logged_in_success' : 'public_success')
    );
  }

  render() {
    const { location, applicationStore, form } = this.props;
    const { formLoadingState } = applicationStore;

    if (formLoadingState === 'loading') {
      return <LoadingOverlayComponent />;
    }

    let screen: Screen = SCREENS[0];
    if (location.query && SCREENS.includes(location.query.screen as Screen)) {
      screen = location.query.screen as Screen;
    }

    const errorMessages = formLoadingState === 'error' && (
      <FormErrors errors={form.errors} />
    );

    const backToSource = [SCREENS[0], 'logged_in_success', 'public_success'].includes(screen);
    const lastPage = ['logged_in_success', 'public_success'].includes(screen);

    return (
      <FunnelPage lastPage={lastPage} backToSource={backToSource} fromDashboard={this.fromDashboard}>
        {screen === 'textile' && <ClothingTextile form={form} />}
        {screen === 'design' && <Design form={form} />}
        {screen === 'year' && <Year form={form} />}
        {screen === 'school_type' && <SchoolType form={form} />}
        {screen === 'class_size' && (
          <ClassSize
            form={form}
            submit={() => this.decidePath()}
            errorMessages={errorMessages}
          />
        )}
        {screen === 'logged_in_success' && <LoggedInSuccess emoji="✉️" link={this.fromDashboard}/>}
        {screen === 'personal_data' && (
          <PersonalData
            form={form}
            showEmail={false}
            onSubmit={() => this.submit()}
            messageId="funnel clothing personal data message"
            headingId="funnel clothing personal data heading"
            infoId="funnel clothing personal data info"
            submitMessageId="funnel get offer"
            errorMessages={errorMessages}
          />
        )}
        {screen === 'public_success' && <PublicSuccess />}
      </FunnelPage>
    );
  }
}

export default (props: any) => {
  const form = useForm({
    init: false,
    type: 'clothingfunnel'
  });
  return <ClothingFunnelScreen {...props} form={form} />;
};
