export const shortenText = (
  max: number,
  text?: string,
  add: string = '...'
) => {
  if (typeof text !== 'string' || text.length <= max) {
    return text;
  }

  return text.substring(0, max - add.length) + add;
};

export default shortenText;
