/* eslint-disable react/style-prop-object */
import React from 'react';

import { TextileDiscount } from 'api/textile_deals/fetchTextileDiscounts';
import { TextilePrice } from 'api/textile_deals/fetchTextilePrices';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import AmountDiscountListItem from 'screens/textile_dashboard/components/AmountDiscountListItem';
import TextileDiscountListItem from 'screens/textile_dashboard/components/TextileDiscountListItem';
import TextileDiscountList from 'screens/textile_dashboard/components/TextileDiscountsList';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import { discountsValue, usedDiscountsValue } from 'utils/usedDiscountsValue';
import AmountDiscountItem from './AmountDiscountItem';

interface TextileCalculatorPriceProps {
  textilePrice?: TextilePrice;
  isCheckout?: boolean;
  discountsLink?: string;
}

const IconWrapper = styled.div`
  flex-shrink: 0;
`;

const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  background-color: ${COLORS.GRAY950};
  border-radius: 50%;
  line-height: 1;
`;

const DiscountLine = ({
  name,
  value,
  checked,
  info,
  totalAmount
}: {
  name: React.ReactNode;
  value: number;
  checked: boolean;
  info?: string;
  totalAmount: number;
}) => (
  <>
    <HorizontalStackBlock justified={true} centered={true}>
      <HorizontalStackBlock gap="S">
        <IconWrapper>
          {checked ? (
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
          ) : (
            <Dot />
          )}
        </IconWrapper>
        <div>
          <Paragraph size="S" weight="BOLD">
            {name}
          </Paragraph>
          {info && <Paragraph size="S">{info}</Paragraph>}
        </div>
      </HorizontalStackBlock>
      <div>
        <Paragraph color="PRIMARY" textAlign="right">
          -&nbsp;
          <FormattedNumber
            value={value * totalAmount}
            style="currency"
            currency="EUR"
          />
        </Paragraph>
        <Paragraph weight="BOLD" noBreak={true}>
          <FormattedNumber value={value} style="currency" currency="EUR" />
          <FormattedMessage id="per piece" />
        </Paragraph>
      </div>
    </HorizontalStackBlock>
    <Divider useStackBlockGap={true} />
  </>
);

export const Discount = ({
  discount,
  totalAmount
}: {
  discount: TextileDiscount;
  totalAmount: number;
}) => {
  let info: string | undefined;
  if (discount.checked) {
    info = discount.info_done;
  } else {
    info = discount.info;
  }

  return (
    <DiscountLine
      name={discount.name}
      value={discount.value}
      info={info}
      checked={discount.checked}
      totalAmount={totalAmount}
    />
  );
};

const TextileCalculatorPrice = ({
  textilePrice,
  isCheckout,
  discountsLink
}: TextileCalculatorPriceProps) => {
  if (!textilePrice || !textilePrice.price) {
    return null;
  }


  const totalAmount = textilePrice.price?.items.reduce((sum, itm) => sum += itm.amount, 0);
  const preordersAmount = textilePrice.preorders_amount;

  if (!totalAmount) {
    return null;
  }

  const items = textilePrice.price.items;
  const price = textilePrice.price.price;
  const formTextilePrice = textilePrice.price.form_textile_price;
  const endPrice = textilePrice.price.end_price;
  const discounts = textilePrice.discounts || [];
  const currentDiscountsValue = isCheckout
    ? usedDiscountsValue(discounts)
    : discountsValue(discounts, totalAmount);

  const textileDiscountsWithoutAmountDiscounts = discounts.filter(
    (discount) =>
      discount.identifier !== 'nuggit_volume_65' &&
      discount.identifier !== 'nuggit_volume_90'
  );

  const amountDiscounts = discounts.filter(
    (discount) =>
      discount.identifier === 'nuggit_volume_65' ||
      discount.identifier === 'nuggit_volume_90'
  );

  const usedDiscounts: TextileDiscount[] = textileDiscountsWithoutAmountDiscounts.filter(
    (discount) => discount.checked
  );

  return (
    <>
      <ItemStackBlock gap="M">
        <ItemStackBlock gap="XXS">
          <Headline.Medium>
            <HorizontalStackBlock gap="XXS" centered={true} noMarginLeft={true}>
              <FormattedMessage id="Price and discounts" />
              {discountsLink && (
                <NakedLink to={discountsLink}>
                  <IconComponent icon="INFO_CIRCLE" />
                </NakedLink>
              )}
            </HorizontalStackBlock>
          </Headline.Medium>
        </ItemStackBlock>

        <HorizontalStackBlock justified={true}>
          <Paragraph color="TEXT_LIGHT">
            <FormattedMessage id="textile price without discounts" />
          </Paragraph>
          <Paragraph color="TEXT_LIGHT">
            <FormattedNumber value={price} style="currency" currency="EUR" />
          </Paragraph>
        </HorizontalStackBlock>

        <Divider useStackBlockGap={true} />

        <TextileDiscountList>
          {isCheckout ? (
            <AmountDiscountListItem
              discounts={amountDiscounts}
              preordersAmount={totalAmount}
            />
          ) : (
            <AmountDiscountItem
              discounts={amountDiscounts}
              formAmount={totalAmount}
              preordersAmount={preordersAmount || 0}
            />
          )}
          {textileDiscountsWithoutAmountDiscounts.map(
            (discount: TextileDiscount) => {
              return (
                <TextileDiscountListItem
                  discount={discount}
                  active={usedDiscounts.includes(discount)}
                  isCheckout={isCheckout}
                  key={discount.identifier}
                />
              );
            }
          )}
        </TextileDiscountList>

        <Headline.Medium>
          {isCheckout ? (
            <FormattedMessage id="Textile discount price" />
          ) : (
            <FormattedMessage id="Textile discount price possible" />
          )}
        </Headline.Medium>

        <ItemStackBlock gap="XXS">
          {items.map((itm) => {
            return (
              <div key={itm.name}>
                <ItemStackBlock gap="XXS">
                  <HorizontalStackBlock justified={true}>
                    <Paragraph size="M" weight="BOLD">
                      {itm.name}
                    </Paragraph>

                    <Paragraph size="M" weight="BOLD">
                      <FormattedNumber
                        value={
                          isCheckout ? itm.end_price : itm.form_possible_price
                        }
                        style="currency"
                        currency="EUR"
                      />
                    </Paragraph>
                  </HorizontalStackBlock>
                  <HorizontalStackBlock justified={true}>
                    <Paragraph size="S" color="TEXT_LIGHT">
                      {itm.amount} <FormattedMessage id="textile pieces" />
                    </Paragraph>

                    <Paragraph size="S" color="TEXT_LIGHT">
                      <FormattedMessage
                        id="textile discount amount info"
                        values={{
                          amount: (
                            <FormattedNumber
                              value={currentDiscountsValue}
                              style="currency"
                              currency="EUR"
                            />
                          )
                        }}
                      />
                    </Paragraph>
                  </HorizontalStackBlock>
                </ItemStackBlock>

                <Divider />
              </div>
            );
          })}
        </ItemStackBlock>

        <ItemStackBlock gap="S">
          <ItemStackBlock gap="XXS">
            <HorizontalStackBlock gap="XXS" centered={true}>
              <TextElement size="L" weight="BOLD" color="BLACK">
                {isCheckout ? (
                  <FormattedMessage id="Gross price textile" />
                ) : (
                  <FormattedMessage id="Gross price possible" />
                )}
              </TextElement>
              {discountsLink && (
                <NakedLink to={discountsLink}>
                  <IconComponent icon="INFO_CIRCLE" />
                </NakedLink>
              )}
            </HorizontalStackBlock>
            <Paragraph color="TEXT_LIGHT" size="XS">
              {isCheckout ? (
                <FormattedMessage id="textile include vat and used discounts" />
              ) : (
                <FormattedMessage id="textile include vat and discounts" />
              )}
            </Paragraph>
          </ItemStackBlock>

          <Paragraph size="HEADLINE_L" weight="EXTRA_BOLD">
            {isCheckout ? (
              <FormattedNumber
                value={endPrice}
                style="currency"
                currency="EUR"
              />
            ) : (
              <FormattedNumber
                value={formTextilePrice}
                style="currency"
                currency="EUR"
              />
            )}
          </Paragraph>
        </ItemStackBlock>
      </ItemStackBlock>
    </>
  );
};

export default TextileCalculatorPrice;
