import {
  flow,
  getEnv,
  Instance,
  types
} from 'mobx-state-tree';
import createDeliveryDateModel from 'utils/store/createDeliveryDateModel';

import {
  DeliveryDateModel,
  DeliveryDateModelType
} from './DeliveryDateModel';
import { AdvancedStoreEnv } from './StoreEnv';

export type DeliveryDateType = "book" | 'textile'

const DeliveryDatesStore = types
  .model('DeliveryDatesStore', {
    loadingState: types.enumeration(['initial', 'loading', 'loaded', 'error']),
    deliveryDates: types.maybe(types.array(DeliveryDateModel))
  })
  .actions((self) => {
    const getDeliveryDates = flow(function* (
      type: DeliveryDateType,
      textileOrderId?: number
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      self.loadingState = 'loading';

      self.deliveryDates = undefined;

      try {
        const result = yield client.getDeliveryDates(type, textileOrderId);

        self.deliveryDates = result.map((rawDeliveryDate: any) =>
          createDeliveryDateModel(rawDeliveryDate)
        );

        self.loadingState = 'loaded';
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'DeliveryDatesStore | getDeliveryDates',
            error,
            error.body
          );
        }

        self.loadingState = 'error';

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw error;
      }
    });

    const getDeliveryDatesForTimePlan = flow(function* (
      type: DeliveryDateType,
      textileOrderId?: number

    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      self.loadingState = 'loading';

      self.deliveryDates = undefined;

      try {
        const result = yield client.getDeliveryDatesForTimePlan(type, textileOrderId);

        self.deliveryDates = result.map((rawDeliveryDate: any) =>
          createDeliveryDateModel(rawDeliveryDate)
        );

        self.loadingState = 'loaded';
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'DeliveryDatesStore | getDeliveryDatesForTimePlan',
            error,
            error.body
          );
        }

        self.loadingState = 'error';

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw error;
      }
    });

    const chooseDeliveryDate = flow(function* (
      deliveryDate: DeliveryDateModelType,
      afterCheckout: boolean,
      type: DeliveryDateType,
      textileOrderId?: number
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      self.loadingState = 'loading';

      try {
        if (afterCheckout) {
          yield client.changeDeliveryDateAfterCheckout(deliveryDate.id, type, textileOrderId);
        } else {
          yield client.chooseDeliveryDate(deliveryDate.id, type, textileOrderId);
        }

        self.loadingState = 'loaded';
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'DeliveryDatesStore | chooseDeliveryDate',
            error,
            error.body
          );
        }

        self.loadingState = 'error';

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw error;
      }
    });

    return {
      getDeliveryDates,
      getDeliveryDatesForTimePlan,
      chooseDeliveryDate
    };
  });

export type DeliveryDatesStoreType = Instance<typeof DeliveryDatesStore>;

export default DeliveryDatesStore;
