import { BaseTextileDesignSetting, TextilePhoto } from 'api/textile_deals/fetchTextileDesignSetting';

export const getTextileBackCreativePhoto = (settings?: BaseTextileDesignSetting): TextilePhoto | null => {
  if (!settings) {
    return null;
  }
  const photo = settings.textile_back_creative_photo;

  return photo || null;
};
