/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Preorder } from 'api/textile_deals/fetchPreorders';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import InputMessage from 'elements/InputMessage';
import { useProfilePreorderOverview } from 'queries/textile_deals/useOrderOverviewQueries';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import {
  useCurrentTextileProfileQuery,
  useTextileProfileReopenPreorderQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import TextilePriceComponent from 'screens/preorders/components/TextilePriceComponent';
import TextileVariantCartComponent from 'screens/preorders/components/TextileVariantCartComponent';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import isTextileLocked from 'utils/isTextileLocked';

const OwnPreorderSummaryScreen: React.FC<HistoryProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const currentTextileProfile = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );
  const orderOverview = useProfilePreorderOverview(
    textileAppState?.textile_profile.id,
    textileOrderId
  );
  const reopenPreorderQuery = useTextileProfileReopenPreorderQuery(
    textileAppState?.textile_profile.id
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (textileAppState?.textile_order.order_state === 'preparing') {
      props.history.replace(textileOrderRoute(props.match, '/preorder'));
    }
    accessContext.checkSectionAccess('own_preorder');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileAppState?.textile_order.order_state]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={currentTextileProfile.data?.preorder_state === 'rejected' ? textileOrderRoute(props.match, '/preorder/own') : textileOrderRoute(props.match, '/preorder')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="preorder own" />}
            text={<FormattedMessage id="preorder summary info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (currentTextileProfile.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => currentTextileProfile.refetch()}
      />
    );
  }

  if (orderOverview.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => orderOverview.refetch()} />
    );
  }
  if (
    loading ||
    !textileAppState ||
    currentTextileProfile.isLoading ||
    !currentTextileProfile.data ||
    orderOverview.isLoading ||
    !orderOverview.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const reopenPreorder = () => {
    if (!textileOrderId || !currentTextileProfile.data) {
      return;
    }

    setLoading(true);

    reopenPreorderQuery.mutate(
      {
        id: currentTextileProfile.data.id,
        textileOrderId
      },
      {
        onSettled: () => {
          props.history.push(textileOrderRoute(props.match, '/preorder/own'));
        }
      }
    );
  };

  const renderRejected = () => {
    return renderPage(
      <>
        <InputMessage error={true}>
          <FormattedMessage id="preorder rejected" />
        </InputMessage>

        {currentTextileProfile.data.preorder_notification && (
          <InputMessage info={true}>
            <Paragraph weight="BOLD" size="L">
              <FormattedMessage id="message from organiser" />
            </Paragraph>

            <Divider color="PURPLE" />

            <Paragraph>
              {currentTextileProfile.data.preorder_notification.notification}
            </Paragraph>
            <Paragraph>
              <IconComponent icon="ORGANIZER" fill="PURPLE" />
              {currentTextileProfile.data.preorder_notification.author_name}
            </Paragraph>
          </InputMessage>
        )}
      </>
    );
  };

  const renderCart = () => {
    const layout = textileAppState.textile_order.theme;
    const { preorders, price, textiles } = orderOverview.data;

    if (!layout || !textiles || textiles.length === 0) {
      return null;
    }

    if (currentTextileProfile.data.preorder_state === 'pending' ) {
      return renderPage(<LoadingIndicatorComponent />);
    }

    if (currentTextileProfile.data.preorder_state === 'rejected') {
      return renderRejected();
    }

    return renderPage(
      <>
        {currentTextileProfile.data.preorder_state === 'submitted' && (
          <Paragraph>
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
            <FormattedMessage id="preorder submitted" />
          </Paragraph>
        )}

        {preorders && preorders.length > 0 && price && (
          <>
            {preorders.map((preorder: Preorder) => {
              const textile = textiles.find(
                (itm) => itm.name === preorder.textile_name
              );

              if (!textile || !textile.possible_price || !textile.end_price) {
                return null;
              }
              const variant = textile?.textile_variants?.find(
                (itm) => itm.id === preorder.textile_variant_id
              );
              return (
                <TextileVariantCartComponent
                  textileVariant={variant}
                  layout={layout}
                  size={preorder.size}
                  textileName={preorder.textile_name}
                  preorderId={preorder.id}
                  key={preorder.id}
                  possiblePrice={textile.possible_price}
                  endPrice={textile.end_price}
                />
              );
            })}
          </>
        )}

        {price && (
          <TextilePriceComponent
            endPrice={price.price.end_price}
            possiblePrice={price.price.possible_price}
            range={
              !isTextileLocked(
                textileAppState.textile_order.order_state,
                'atLeastOrderStarted'
              )
            }
            link={textileOrderRoute(props.match, '/discounts')}
          />
        )}

        {(textileAppState.textile_profile.is_organizer) && (
          <>
            <ButtonBlock
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              onClick={() => reopenPreorder()}
            >
              <FormattedMessage id="preorder change" />
            </ButtonBlock>
          </>
        )}
      </>
    );
  };

  return renderCart();
};

export default OwnPreorderSummaryScreen;
