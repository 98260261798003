import { destroy, flow, getEnv, Instance, types } from 'mobx-state-tree';
import ChapterModel, { createChapterModel } from 'models/ChapterModel';
import { SponsorModelType } from 'models/SponsorModel';
import SponsorPageModel, {
  createSponsorPage as createSponsorPageModel
} from 'models/SponsorPageModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { assert } from 'utils/assert';
import { createArrayWithTransform } from 'utils/create-map';

export enum LoadingState {
  INITIAL = 'initial',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error'
}

const SponsorPagesStore = types
  .model('SponsorPagesStore', {
    // List of sponsor pages
    sponsorPages: types.maybe(types.array(SponsorPageModel)),
    sponsorPagesLoadingState: types.optional(
      types.enumeration(Object.values(LoadingState)),
      LoadingState.INITIAL
    ),

    // Single sponsor page
    sponsorPage: types.maybe(SponsorPageModel),
    sponsorPageLoadingState: types.optional(
      types.enumeration(Object.values(LoadingState)),
      LoadingState.INITIAL
    ),

    chapter: types.maybe(ChapterModel)
  })
  .actions((self) => {
    const getSponsorPages = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.sponsorPagesLoadingState = LoadingState.LOADING;
        self.sponsorPages = undefined;
        self.chapter = undefined;

        const result = yield client.getSponsorPages(chapterId);

        self.sponsorPages = createArrayWithTransform(
          result.sponsor_pages,
          createSponsorPageModel
        );
        self.chapter = createChapterModel(result.chapter || { id: chapterId });

        self.sponsorPagesLoadingState = LoadingState.LOADED;

        return self.sponsorPages;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'SponsorPagesStore | getSponsorPages',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.sponsorPagesLoadingState = LoadingState.INITIAL;
        } else {
          self.sponsorPagesLoadingState = LoadingState.ERROR;
        }
        throw error;
      }
    });

    const getSponsorPage = flow(function* (id: number) {
      const { client } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.sponsorPageLoadingState = LoadingState.LOADING;
        self.sponsorPage = undefined;

        const result: any = yield client.getSponsorPage(id);

        self.sponsorPage = createSponsorPageModel(result);

        self.sponsorPageLoadingState = LoadingState.LOADED;

        return self.sponsorPage;
      } catch (error: any) {
        handleSponsorPageError('getSponsorPage', error);
      }
    });

    const createSponsorPage = flow(function* (
      chapterId: number,
      layoutKey: string
    ) {
      const { client } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.sponsorPageLoadingState = LoadingState.LOADING;
        self.sponsorPage = undefined;

        const sponsorPageForSave = createSponsorPageModel({
          chapter_id: chapterId,
          sponsor_layout_key: layoutKey,
          sponsor_page_sponsors: []
        });

        const result = yield client.createSponsorPage(sponsorPageForSave);

        self.sponsorPage = createSponsorPageModel(result);

        self.sponsorPageLoadingState = LoadingState.LOADED;

        return self.sponsorPage;
      } catch (error: any) {
        handleSponsorPageError('createSponsorPage', error);
      }
    });

    /**
     * Places the given sponsor on the current sponsor page at the given position (slot)
     */
    const placeSponsor = flow(function* (
      sponsor: SponsorModelType,
      position: number
    ) {
      const { client } = getEnv<AdvancedStoreEnv>(self);
      const { sponsorPage } = self;
      assert(sponsorPage, 'No sponsor page loaded');

      try {
        self.sponsorPageLoadingState = LoadingState.LOADING;

        const result = yield client.placeSponsorOnSponsorPage(
          sponsor,
          sponsorPage,
          position
        );

        self.sponsorPage = createSponsorPageModel(result);

        self.sponsorPageLoadingState = LoadingState.LOADED;

        return self.sponsorPage;
      } catch (error: any) {
        handleSponsorPageError('placeSponsor', error);
      }
    });

    /**
     * Removes the given sponsor from the current sponsor page
     */
    const removeSponsor = flow(function* (sponsor: SponsorModelType) {
      const { client } = getEnv<AdvancedStoreEnv>(self);
      const { sponsorPage } = self;

      assert(sponsorPage, 'No sponsor page loaded');

      try {
        self.sponsorPageLoadingState = LoadingState.LOADING;

        yield client.removeSponsorFromSponsorPage(sponsor, sponsorPage);

        destroySponsorPageSponsor(sponsor.id);

        self.sponsorPageLoadingState = LoadingState.LOADED;
      } catch (error: any) {
        handleSponsorPageError('placeSponsor', error);
      }
    });

    /**
     * Removes the given sponsor page
     */
    const removeSponsorPage = flow(function* () {
      const { client } = getEnv<AdvancedStoreEnv>(self);
      const { sponsorPage } = self;

      assert(sponsorPage, 'No sponsor page loaded');

      try {
        self.sponsorPagesLoadingState = LoadingState.LOADING;

        yield client.removeSponsorPage(sponsorPage.id);

        self.sponsorPageLoadingState = LoadingState.LOADED;
      } catch (error: any) {
        handleSponsorPageError('removeSponsorPage', error);
      }
    });

    /**
     * Places the given sponsor on the back cover
     */
    const placeCoverSponsor = flow(function* (
      sponsor: SponsorModelType,
      position: number
    ) {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.sponsorPagesLoadingState = LoadingState.LOADING;

        const result = yield client.placeCoverSponsor(sponsor, position);

        self.sponsorPage = createSponsorPageModel(result);
        self.sponsorPageLoadingState = LoadingState.LOADED;
      } catch (error: any) {
        handleSponsorPageError('placeCoverSponsor', error);
      }
    });

    /**
     * Removes the back cover sponsor
     */
    const removeCoverSponsor = flow(function* () {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.sponsorPagesLoadingState = LoadingState.LOADING;

        yield client.removeCoverSponsor();

        self.sponsorPageLoadingState = LoadingState.LOADED;
      } catch (error: any) {
        handleSponsorPageError('removeCoverSponsor', error);
      }
    });

    // Private helper functions

    /**
     * Removes the sponsor from the MST sponsor page
     */
    const destroySponsorPageSponsor = (sponsorId: number) => {
      const { sponsorPage } = self;

      assert(sponsorPage, 'No sponsor page loaded');

      const oldSponsorPageSponsor = sponsorPage.sponsor_page_sponsors.find(
        (candidate) => candidate.sponsor.id === sponsorId
      );
      if (oldSponsorPageSponsor) {
        destroy(oldSponsorPageSponsor);
      }
    };

    /**
     * Handles a sponsor page error
     */
    const handleSponsorPageError = (methodName: string, error: any) => {
      const { applicationStore } = getEnv<AdvancedStoreEnv>(self);

      if (process.env.NODE_ENV !== 'production') {
        console.error(`SponsorPagesStore | ${methodName}`, error, error.body);
      }

      if (applicationStore.handleAppError(error)) {
        self.sponsorPageLoadingState = LoadingState.INITIAL;
      } else {
        self.sponsorPageLoadingState = LoadingState.ERROR;
      }
      throw error;
    };

    return {
      getSponsorPages,
      getSponsorPage,
      createSponsorPage,
      placeSponsor,
      removeSponsor,
      removeSponsorPage,
      placeCoverSponsor,
      removeCoverSponsor
    };
  })
  .views((self) => {
    return {
      get sponsorPagesAreLoading() {
        return self.sponsorPagesLoadingState === LoadingState.LOADING;
      },
      get sponsorPagesAreError() {
        return self.sponsorPagesLoadingState === LoadingState.ERROR;
      },
      get sponsorPageIsLoading() {
        return self.sponsorPageLoadingState === LoadingState.LOADING;
      },
      get sponsorPageIsError() {
        return self.sponsorPageLoadingState === LoadingState.ERROR;
      }
    };
  });

export type SponsorPagesStoreType = Instance<typeof SponsorPagesStore>;
export default SponsorPagesStore;
