import React, { useContext, useEffect } from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import {
  useOptimisticUpdateTextileDesignSettingQuery,
  useTextileDesignSettingQuery
} from 'queries/textile_deals/useTextileDesignSettingQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const PrintOrderScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const textileDesignSetting = useTextileDesignSettingQuery(textileOrderId);
  const updateTextileDesignSettings = useOptimisticUpdateTextileDesignSettingQuery(
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    const data = textileDesignSetting.data;

    if (data) {
      props.form.setField('names_order', data.names_order || undefined);
    }
    accessContext.checkSectionAccess('management');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileDesignSetting.data]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/management')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="Print sorting order" />}
            text={<FormattedMessage id="print sorting order info textile" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileDesignSetting.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => textileDesignSetting.refetch()}
      />
    );
  }

  if (textileDesignSetting.isLoading || !textileDesignSetting.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const save = () => {
    const { match, form, history } = props;

    if (!textileOrderId) {
      return;
    }

    form.setLoading(true);
    const patch = { ...textileDesignSetting.data, ...form.values };

    updateTextileDesignSettings.mutate(
      { data: patch, textileOrderId },
      {
        onSuccess: () => {
          history.push(textileOrderRoute(match, '/management'));
        },
        onSettled: () => {
          form.setLoading(false);
        }
      }
    );
  };

  const renderForm = () => {
    const { form } = props;

    return renderPage(
      <>
        <FormStackBlock>
          <RadioButtonComponent
            name="names_order"
            value="first_name"
            label={<FormattedMessage id="back sorting first_name" />}
            {...form.bindRadioButton('names_order', 'first_name')}
          />

          <RadioButtonComponent
            name="names_order"
            value="last_name"
            label={<FormattedMessage id="back sorting last_name" />}
            {...form.bindRadioButton('names_order', 'last_name')}
          />
        </FormStackBlock>

        <ButtonBlock background="PRIMARY" onClick={() => save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  };

  return renderForm();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <PrintOrderScreen {...props} form={form} />;
};
