import { useQuery } from "@tanstack/react-query"
import { getAllTeachers, getAllTeachersWithoutGroups } from "api/textile_deals/fetchTeacherList"

export const useTeacherListQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['teacher_list', textileOrderId],
    queryFn: () => getAllTeachers(textileOrderId!),
    enabled: !!textileOrderId,
  })
}

export const useTeacherListWithoutGroupsQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['teacher_list', textileOrderId],
    queryFn: () => getAllTeachersWithoutGroups(textileOrderId!),
    enabled: !!textileOrderId,
  })
}