import { inject, observer } from 'mobx-react';
import React from 'react';
import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import CancelLink from 'domain/Links/CancelLink';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import StudentsWithoutGroup from './StudentsWithoutGroup';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';

interface StudentsWithoutGroupScreenProps {
  profilesStore: ProfilesStoreType;
}

@inject('profilesStore')
@observer
class StudentsWithoutGroupScreen extends React.Component<StudentsWithoutGroupScreenProps & HistoryProps> {
  async componentDidMount() {
    const {profilesStore, history} = this.props;

    await profilesStore.getStudents();
    if (profilesStore.studentsWithoutGroup.length === 0) {
      history.push(ROUTE_SETTINGS + '/groups')
    }
  }

  backLink() {
    const {history} = this.props;

    if (history.location?.query?.back) {
      return <BackToDashboardLink />

    } else {
      return <CancelLink to={ROUTE_SETTINGS + '/groups'} />
    }
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          {/* query param back to dashboard */}
          {this.backLink()}
        </TopActionBlock>

        {content}
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  render() {
    const { profilesStore } = this.props;
    const students = profilesStore.studentsWithoutGroup;

    if (!students) {
      return null;
    }

    if (profilesStore.isStudentsLoading) {
      return this.renderLoading();
    }

    if (profilesStore.isStudentsError) {
      return this.renderError();
    }

    return <StudentsWithoutGroup students={students} />
  }
}

export default (props: any) => {
  return <StudentsWithoutGroupScreen {...props} />;
};
