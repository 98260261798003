import { flow, getEnv, Instance, types } from 'mobx-state-tree';

import { PriceTier } from './BookModel';
import {
  BookPriceErrorModel,
  createBookPriceErrorModel
} from './BookPriceErrorModel';
import { BookPriceModel, createBookPriceModel } from './BookPriceModel';
import { BindingType } from './CheckoutModel';
import { AdvancedStoreEnv } from './StoreEnv';

const BookPriceStore = types
  .model('BookPriceStore', {
    loadingState: types.enumeration(['initial', 'loading', 'loaded', 'error']),
    bookPrice: types.maybe(BookPriceModel),
    errors: types.maybe(BookPriceErrorModel)
  })
  .actions((self) => {
    const calculateBookPrice = flow(function* (
      pageCount: number,
      pieces: number,
      bindingType: BindingType,
      priceTier: PriceTier
    ) {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.loadingState = 'loading';

        self.bookPrice = undefined;
        self.errors = undefined;

        const result = yield client.calculateBookPrice(
          pageCount,
          pieces,
          bindingType,
          priceTier
        );

        if (result.pricing_json) {
          self.bookPrice = createBookPriceModel(result.pricing_json);
          self.loadingState = 'loaded';
        }

        if (result.error_json) {
          self.errors = createBookPriceErrorModel(result.error_json);
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CheckoutStore | finishCheckout', error, error.body);
        }

        self.loadingState = 'error';
      }
    });

    return { calculateBookPrice };
  });

export type BookPriceStoreType = Instance<typeof BookPriceStore>;

export default BookPriceStore;
