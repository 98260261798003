import { inject, observer } from 'mobx-react';
import React from 'react';
import { Transition } from 'react-transition-group';

import InfoBox from 'components/InfoBoxComponent/InfoBoxComponent';
import { ApplicationStoreType } from 'models/ApplicationStore';
import styled from 'styled-components';

const TIMEOUT = 2500;

interface FlashMessageDisplayComponentProps {
  onHide: () => void;
  message?: string;
  type?: string;
  hidden?: boolean;
}

const Fade = styled.div`
  opacity: ${({ state }: { state: any }) => (state === 'entered' ? 1 : 0)};
  transition: 0.5s;
`;

class FlashMessageDisplayComponent extends React.Component<
  FlashMessageDisplayComponentProps
> {
  timer: any;

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  setTimer() {
    this.clearTimer();

    this.timer = setTimeout(() => {
      this.clearMessage();
    }, TIMEOUT);
  }

  clearMessage() {
    this.props.onHide();
  }

  clear() {
    this.clearTimer();
    this.clearMessage();
  }

  componentDidMount() {
    const { message } = this.props;

    this.clearTimer();

    if (message) {
      this.setTimer();
    }
  }

  componentDidUpdate(prevProps: FlashMessageDisplayComponentProps) {
    const { message, type, hidden } = this.props;

    if (!message || hidden) {
      // message removed, stop timer
      this.clearTimer();
      return;
    }

    const prevMessage = prevProps.message;
    const prevType = prevProps.type;
    const prevHidden = prevProps.hidden;

    if (message !== prevMessage || type !== prevType || hidden !== prevHidden) {
      // timer needs to be reset
      this.setTimer();
    }
  }

  render() {
    const { message, type, hidden } = this.props;
    const visible = !!message && !hidden;

    return (
      <Transition
        in={visible}
        timeout={500}
        unmountOnExit={true}
        mountOnEnter={true}
        enter={false}
      >
        {(state: any) => (
          <Fade state={state}>
            <InfoBox
              fixed={true}
              error={type === 'error'}
              warning={type === 'warning'}
              success={type === 'success'}
              onClick={() => this.clear()}
            >
              <span>{message}</span>
            </InfoBox>
          </Fade>
        )}
      </Transition>
    );
  }
}

interface ApplicationFlashMessageComponentProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class ApplicationFlashMessageComponent extends React.Component<
ApplicationFlashMessageComponentProps
> {
  render() {
    const { applicationStore } = this.props;

    return (
      <FlashMessageDisplayComponent
        message={applicationStore.flashMessage}
        type={applicationStore.flashMessageType}
        hidden={applicationStore.flashMessageHidden}
        onHide={() => applicationStore.hideFlashMessage()}
      />
    );
  }
}

export default (props: any) => <ApplicationFlashMessageComponent {...props} />;