import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

interface CardBlockProps {
  active?: boolean;
  error?: boolean;
  disabled?: boolean;
}

const CardBlock = styled.div<CardBlockProps>`
  display: block;
  overflow: hidden;

  /* box-shadow: 0 0.25em 1.5625em 0 rgba(0, 0, 0, 0.08); */
  border: 1px solid ${COLORS.GRAY800};
  border-radius: 0.625rem;
  min-height: 3.375rem;
  padding: 0;
  background: ${COLORS.WHITE};

  /* When <CardBlock as="button"> is used, we need to inherit the font-family */
  font-family: inherit;
  font-size: 1rem;
  line-height: 1;
  -webkit-appearence: none;
  text-align: left;
  color: inherit;
  ${(props) =>
    props.active &&
    `
    background: rgba(${COLORS.PRIMARY_RGB}, 0.2);
    box-shadow:
    0 0.25em 1.5625em 0 rgba(0, 0, 0, 0.08),
    0 0 0 2px ${COLORS.PRIMARY} inset;
    `};
  ${(props) =>
    props.error &&
    `
    background: rgba(${COLORS.RED_RGB}, 0.2);
    box-shadow:
      0 0.25em 1.5625em 0 rgba(0, 0, 0, 0.08),
      0 0 0 2px ${COLORS.RED} inset;
  `};

  &:disabled {
    color: ${COLORS.TEXT_LIGHT};
    border-color: ${COLORS.GRAY900};
  }
`;

export default CardBlock;
