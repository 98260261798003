import { Instance, types } from 'mobx-state-tree';
import { createArrayWithTransform } from 'utils/create-map';

import { PriceTierEnum } from './BookModel';
import { createDiscountModel, DiscountModel } from './DiscountModel';

export const PriceForBinding = types.model('PriceForBinding', {
  price: types.number,
  end_price: types.number,
  possible_price: types.number
});

export type PriceForBindingType = Instance<typeof PriceForBinding>;

export const BookPriceModel = types.model('BookPriceModel', {
  page_count: types.number,
  pieces: types.number,
  price_tier: types.maybe(PriceTierEnum),
  price: types.model({
    silver: types.model({
      hardcover: types.maybe(PriceForBinding),
      softcover: PriceForBinding,
    }),
    gold: types.model({
      hardcover: types.maybe(PriceForBinding),
      softcover: PriceForBinding,
    }),
    platinum: types.model({
      hardcover: types.maybe(PriceForBinding),
      softcover: PriceForBinding,
    }),
    valid_till: types.Date
  }),
  nuggit_sponsors: types.model({
    placed: types.number,
    total: types.number,
    placed_count: types.number,
    total_count: types.number,
    valid_till: types.Date
  }),
  discounts: types.array(DiscountModel),
  is_pages_rounded: types.boolean,
  is_pieces_rounded: types.boolean,
  no_hardcover_allowed: types.boolean,
  hardcover_max_pages: types.number
});

export const createBookPriceModel = (
  data?: any
): BookPriceModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return BookPriceModel.create({
    ...data,
    price: {
      ...data.price,
      valid_till: new Date(data.price.valid_till)
    },
    nuggit_sponsors: {
      ...data.nuggit_sponsors,
      valid_till: new Date(data.nuggit_sponsors.valid_till)
    },
    discounts: createArrayWithTransform(data.discounts, createDiscountModel),
    is_pages_rounded: data.is_pages_rounded,
    is_pieces_rounded: data.is_pieces_rounded,
    no_hardcover_allowed: data.no_hardcover_allowed,
    hardcover_max_pages: data.hardcover_max_pages,
    price_tier: PriceTierEnum.is(data.price_tier)
    ? data.price_tier
    : undefined,
  });
};

export type BookPriceModelType = Instance<typeof BookPriceModel>;
