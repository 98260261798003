import { IntlShape } from 'react-intl';

const stub = { formatMessage: () => {}, formatNumber: () => {} };

// keep global reference to IntlProvider to use i18n outside of components
let _intl = (stub as any) as IntlShape;

export function storeIntlRef(ref: IntlShape | null) {
  if (!ref) {
    _intl = (stub as any) as IntlShape;
    return;
  }

  _intl = ref;
}

export { _intl as intl };
// export default _intl;  // does not work
