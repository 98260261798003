import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Headline from 'elements/Headline';

import SettingNotAvailableComponent from '../../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from '../SettingsDetailProps';
import Paragraph from 'components/Paragraph/Paragraph';

const FactsheetCreativePageSettingsContainer = ({
  form,
  chapter,
  onCommitSettings
}: SimpleSettingsDetailProps) => {
  if (!chapter.isFactsheetChapter) {
    return <SettingNotAvailableComponent />;
  }

  const isTeacherFactsheets = chapter.chapter_type === 'teacher_factsheet';

  return (
    <>
      <Headline.Large>
        <FormattedMessage id="Creative page" />
      </Headline.Large>

      <ItemStackBlock gap="L">
        <Paragraph>
          <FormattedMessage
            id={
              isTeacherFactsheets
                ? 'teacher creative page settings info'
                : 'creative page settings info'
            }
          />
        </Paragraph>

        <CustomCheckboxBlock>
          <CustomCheckboxBlock.CheckboxElement
            id="checkbox"
            {...form.bindCheckbox('creative_page')}
          />
          <CustomCheckboxBlock.LabelElement htmlFor="checkbox">
            <FormattedMessage id="Creative page enabled" />
          </CustomCheckboxBlock.LabelElement>
        </CustomCheckboxBlock>

        <ButtonBlock
          background="PRIMARY"
          onClick={() =>
            onCommitSettings({
              creative_page: form.values.creative_page ? true : false
            })
          }
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </ItemStackBlock>
    </>
  );
};

export default FactsheetCreativePageSettingsContainer;
