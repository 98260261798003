import {
  flow,
  getEnv,
  Instance,
  types
} from 'mobx-state-tree';
import createBookModel from 'utils/store/createBookModel';
import BookModel, { BookModelType } from './BookModel';

import { bookCreated } from 'utils/analytics';
import { AdvancedStoreEnv } from './StoreEnv';

const BooksStore = types
  .model('BooksStore', {
    loadingState: types.maybe(types.string),
    books: types.maybe(types.map(BookModel)),
    book: types.maybe(BookModel)
  })
  .actions((self) => {
    const availableBook = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';
        self.books = undefined;

        const result = yield client.availableBook();

        self.book = createBookModel(result);

        self.loadingState = undefined;

        return self.book;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('BooksStore | getAllBooks', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const createBook = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';
        self.book = undefined;

        const result: any = yield client.createBook();

        self.book = createBookModel(result);
        self.loadingState = undefined;

        bookCreated(applicationStore.currentAccount?.mobile_number)
        return self.book;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('BooksStore | createBook', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    return { availableBook, createBook };
  }).views((self) => {
    return {
      get allBooks(): BookModelType[] {
        const books: BookModelType[] = [];

        if (!self.books || self.books.size === 0) {
          return books;
        }

        for (const book of self.books.values()) {
          books.push(book);
        }

        return books;
      },
    }
  });

export type BooksStoreType = Instance<typeof BooksStore>;

export default BooksStore;
