import React from 'react';
import { Route, Switch } from 'react-router';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_CONTENT, ROUTE_PDF_PREVIEW } from 'utils/constants/routes';

import ContentDashboardScreen from './ContentDashboardScreen/ContentDashboardScreen';
import CreateChapterScreen from './CreateChapter/CreateChapterScreen';
import GeneratePdfScreen from './GeneratePdfScreen/GeneratePdfScreen';
import SortChaptersScreen from './SortChaptersScreen/SortChaptersScreen';

export default () => (
  <Switch>
    <Route path={ROUTE_CONTENT + '/create'} component={CreateChapterScreen} />
    <Route path={ROUTE_CONTENT + '/sort'} component={SortChaptersScreen} />
    <Route path={ROUTE_PDF_PREVIEW} component={GeneratePdfScreen} />
    <Route path={ROUTE_CONTENT} component={ContentDashboardScreen} />

    <NotFoundRoute />
  </Switch>
);
