import React, { ReactNode } from 'react';
import ItemStackBlock from 'blocks/ItemStackBlock';

const ListStackBlock = ({ children }: { children: ReactNode }) => (
  <ItemStackBlock gap="XS">
    {children}
  </ItemStackBlock>
);

export default ListStackBlock;
