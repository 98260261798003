import { SchoolModelType } from 'models/SchoolModel';
import mandatoryId from './mandatoryId';

export default (item: any): SchoolModelType | undefined => {
  if (!item) {
    return undefined;
  }

  return {
    id: mandatoryId(item.id),
    name: item.name || '',
    sub_name: item.sub_name || undefined,
    city_name: item.city_name || undefined,
    zip_code: item.zip_code || undefined,
    address: item.address || undefined,
    country: item.country || undefined,
    created_by_user: item.created_by_user || false
  };
};
