import { TextileThemeLayout } from 'api/textile_deals/fetchTextileThemes';
import { TextileVariant } from 'api/textile_deals/fetchTextileVariants';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import React from 'react';
import { Link } from 'react-router-dom';
import { MiniPreview } from 'screens/textile_design_settings/screens/colors/MiniPreview';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import { ProductImage, productImage } from 'utils/product-image';
import TextileProductImageComponent from './TextileProductImageComponent';

const ImageBackground = styled.div`
  display: flex;
  align-items: flex-end;
  width: 250px;
  height: 250px;
  border-radius: 10px;
  background-color: ${COLORS.GRAY980};
  flex-direction: column;
  padding: 0.625rem;
  position: relative;

  > .colorDots {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
`

const TextileImagePreview = ({
  textileVariants,
  layout,
  link
}: {
  textileVariants: TextileVariant[];
  layout: TextileThemeLayout;
  link: string;
}) => {
  if (textileVariants.length === 0) {
    return null;
  }

  const image: ProductImage = productImage(textileVariants[0], 'thumbnail', layout);

  return (
    <Link
      to={link}
    >
      <ImageBackground>
        <TextileProductImageComponent productImage={image} height="220px" align="center" justify="center"/>

        <HorizontalStackBlock className='colorDots'>
          {textileVariants.map((variant) => {
            if (variant.textile_color && variant.textile_color.color_hex) {
              return (
                <MiniPreview colorCode={variant.textile_color.color_hex} noBorder={true} key={variant.id} small={true}/>
              )
            } else {
              return null;
            }

          })}
        </HorizontalStackBlock>
      </ImageBackground>
    </Link>
  );
};

export default TextileImagePreview;
