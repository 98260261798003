import styled from 'styled-components';

interface InputAppendProps {
  onClick?: (e: any) => void;
  disabled?: boolean;
}

const InputAppend = styled.button.attrs((props: InputAppendProps) => ({
  disabled: !props.onClick ? true : props.disabled
}))<InputAppendProps>`
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  margin-left: -1px;
  border-left: none;
  box-sizing: padding-box;
  padding: 0 0.375em;
  font-size: 1em;
  -webkit-appearance: none;
  line-height: 1.5;

  svg {
    min-width: 1.75em;
  }

  ${(props) =>
    !props.onClick
      ? ''
      : `
        cursor: pointer;
      `};
`;

export default InputAppend;
