import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import IconComponent from 'components/IconComponent';
import SliderButtonsComponent from 'components/SliderButtonsComponent';
import { SliderButtonsClickEvent } from 'components/SliderButtonsComponent/SliderButtonsComponent';
import COLORS from 'utils/constants/colors';

// == Hint =======================================================
// This component is used to display page preview inside chapters.
// ===============================================================

interface ScaledPagePreviewComponentProps {
  children?: React.ReactNode;
  spine?: boolean;
  border?: boolean;
  stack?: boolean;
  onButtonsClick?: SliderButtonsClickEvent;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
  add?: boolean;
  addMessage?: string;
  pageTitle?: string | React.ReactNode;
  description?: React.ReactNode;
  linkTo?: LinkProps['to'];
}

interface PageContainerProps {
  spine?: boolean;
  border?: boolean;
  stack?: boolean;
  pageTitle?: string | React.ReactNode;
  add?: boolean;
}

interface PageTitleProps {
  description?: React.ReactNode;
}

interface PageDescriptionProps {
  buttons?: boolean;
}

const PageContainer = styled.div<PageContainerProps>`
  position: relative;
  z-index: 3;
  flex-shrink: 0;
  ${props => props.add && 'justify-content: center;'}
  ${props => props.add && 'align-items: center;'}
  ${props => props.add && 'flex-direction: column;'}
  display: ${props => (!props.add ? 'block' : 'flex')};
  ${props => props.add && 'padding: 3em;'}
  margin-bottom: ${props => (props.title ? '0em' : '1.5em')};
  border: ${props =>
    props.add
      ? '2px dashed orange'
      : props.border || props.stack
      ? `1px solid ${COLORS.GRAY800}`
      : undefined};
  border-radius: ${props => (!props.add ? '0px' : '10px')};
  width: ${props => (!props.spine ? 245 : 25)}px;
  height: 346px;
  font-size: 0.875em;
  line-height: 1.2;
  font-weight: 300;
  text-align: ${props => (!props.add ? 'left' : 'center')};
  color: ${COLORS.TEXT_LIGHT};
  ${props =>
    props.stack &&
    `
    &:before {
      position: absolute;
      top: 0;
      content: "";
      display: block;
      border: 1px solid ${COLORS.GRAY800};
      width: 100%;
      height: 100%;
      transform: rotate(1.5deg);
    }
    &:after {
      position: absolute;
      z-index: -1;
      top: 0;
      content: "";
      display: block;
      border: 1px solid ${COLORS.GRAY800};
      width: 100%;
      height: 100%;
      transform: rotate(-1.5deg);
    }
  `};

  @media (min-width: 36em) {
    width: ${props => (!props.spine ? 380 : 38)}px;
    height: 536.5px;
  }
`;

const PageWrapper = styled.div`
  flex-direction: column;
  display: flex;
`;

const PageTitle = styled.h3<PageTitleProps>`
  opacity: 0.8;
  padding: ${props => (props.description ? '1.2em 0 0.5em' : '1.2em 0 1.5em')};
  font-size: 0.75em;
  font-weight: 500;
  color: ${COLORS.GRAY10};
`;

const PageDescription = styled.p<PageDescriptionProps>`
  opacity: 0.5;
  padding-bottom: ${props => (!props.buttons ? '1.5em' : '0.5em')};
  font-size: 0.75em;
  font-weight: 500;
  color: ${COLORS.TEXT_LIGHT};
`;

const PageCaption = styled.span`
  display: inline-block;
  max-width: 245px;
  overflow: hidden;
  line-height: 1.1em;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 36em) {
    max-width: 380px;
  }
`;

const AddDescription = styled.p<PageDescriptionProps>`
  padding-bottom: 1em;
`;

const PagePreview = styled.div`
  transform: scale(0.29);
  transform-origin: left top;

  @media (min-width: 36em) {
    transform: scale(0.45);
  }
`;

export const ScaledPagePreviewComponent = ({
  children,
  spine,
  border,
  stack,
  onButtonsClick,
  leftDisabled,
  rightDisabled,
  add,
  addMessage = 'Add a new chapter',
  pageTitle,
  description,
  linkTo
}: ScaledPagePreviewComponentProps) => {
  const content = (
    <>
      {!add ? (
        <PageContainer
          spine={spine}
          border={border}
          stack={stack}
          pageTitle={pageTitle}
        >
          <PagePreview>{children}</PagePreview>
        </PageContainer>
      ) : (
        <PageContainer
          spine={spine}
          border={border}
          stack={stack}
          pageTitle={pageTitle}
          add={add}
        >
          <AddDescription>
            <FormattedMessage id={addMessage} />
          </AddDescription>
          <IconComponent icon="PLUS" size={2} fill="PRIMARY" />
        </PageContainer>
      )}
      {pageTitle && (
        <PageTitle description={description}>
          <PageCaption>{pageTitle}</PageCaption>
        </PageTitle>
      )}
      {description && (
        <PageDescription buttons={!!onButtonsClick}>
          <PageCaption>{description}</PageCaption>
        </PageDescription>
      )}
    </>
  );

  return (
    <PageWrapper>
      {!linkTo ? content : <Link to={linkTo}>{content}</Link>}
      {onButtonsClick && (
        <SliderButtonsComponent
          onClick={onButtonsClick}
          leftDisabled={leftDisabled}
          rightDisabled={rightDisabled}
        />
      )}
    </PageWrapper>
  );
};

export default ScaledPagePreviewComponent;
