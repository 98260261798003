import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import CheckoutModel, {
  CheckoutModelType,
  createCheckoutModel,
  UpdateCheckoutContext
} from 'models/CheckoutModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { assert } from 'utils/assert';

export type UpdateCheckout = Partial<CheckoutModelType> & {
  context: UpdateCheckoutContext;
};

export type LoadingState = 'initial' | 'loading' | 'loaded' | 'error';

const loadingStates: LoadingState[] = ['initial', 'loading', 'loaded', 'error'];

const CheckoutStore = types
  .model('CheckoutStore', {
    createLoadingState: types.enumeration(loadingStates),
    getLoadingState: types.enumeration(loadingStates),
    updateLoadingState: types.enumeration(loadingStates),
    finishLoadingState: types.enumeration(loadingStates),
    checkout: types.maybe(CheckoutModel)
  })
  .actions((self) => {
    const createCheckout = flow(function* (
      pricingInfo: Pick<CheckoutModelType, 'pieces' | 'binding_type'>
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.getLoadingState = 'loading';

        const checkout = yield client.createCheckout(pricingInfo);
        self.checkout = createCheckoutModel(checkout);
        applicationStore.setBook(checkout.book);

        self.getLoadingState = 'loaded';

        return self.checkout;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CheckoutStore | getCheckout', error, error.body);
        }

        self.getLoadingState = 'error';

        throw error;
      }
    });

    const getCheckout = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.getLoadingState = 'loading';
        self.checkout = undefined;

        const checkout = yield client.getCheckout();
        self.checkout = createCheckoutModel(checkout);
        applicationStore.setBook(checkout.book);

        self.getLoadingState = 'loaded';

        return self.checkout;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CheckoutStore | getCheckout', error, error.body);
        }

        self.getLoadingState = 'error';

        throw error;
      }
    });

    const updateCheckout = flow(function* (patch: UpdateCheckout) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.updateLoadingState = 'loading';

        const checkout = yield client.updateCheckout(patch);
        self.checkout = createCheckoutModel(checkout);
        applicationStore.setBook(checkout.book);

        self.updateLoadingState = 'loaded';

        return self.checkout;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CheckoutStore | updateCheckout', error, error.body);
        }

        if (client.isFormError(error)) {
          self.updateLoadingState = 'loaded';
        } else {
          self.updateLoadingState = 'error';
        }

        throw error;
      }
    });

    const finishCheckout = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      assert(self.checkout);

      try {
        self.finishLoadingState = 'loading';

        const checkout = yield client.finishCheckout();
        self.checkout = createCheckoutModel(checkout);
        applicationStore.setBook(checkout.book);

        self.finishLoadingState = 'loaded';

        return self.checkout;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CheckoutStore | finishCheckout', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.finishLoadingState = 'initial';
          return;
        }

        self.finishLoadingState = 'error';

        throw error;
      }
    });

    const uploadParentalApproval = flow(function* (patch: {
      parental_approval: {
        file: File;
      };
    }) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      assert(self.checkout);

      try {
        self.updateLoadingState = 'loading';

        const checkout = yield client.uploadParentalApproval(patch);
        self.checkout = createCheckoutModel(checkout);
        applicationStore.setBook(checkout.book);

        self.updateLoadingState = 'loaded';

        return self.checkout;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CheckoutStore | finishCheckout', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.updateLoadingState = 'initial';
          return;
        }

        self.updateLoadingState = 'error';

        throw error;
      }
    });

    return {
      createCheckout,
      getCheckout,
      updateCheckout,
      finishCheckout,
      uploadParentalApproval
    };
  });

export type CheckoutStoreType = Instance<typeof CheckoutStore>;

export default CheckoutStore;
