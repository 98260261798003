import style from 'styled-components';

import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

export default style.label`
  display: block;
  margin: 0 0 0.625em;
  font-size: 1rem;
  line-height: 1.25;
  font-family: ${FONTS.DEFAULT};
  font-weight: 400;
  color: ${COLORS.GRAY10};
`;
