import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

export default styled.div`
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 0.75em 0 rgba(0, 0, 0, 0.22);
  border-radius: 0.3125em;
  width: calc(100% - 2em);
  max-width: 20em;
  max-height: 90vh;
  background: ${COLORS.WHITE};
  color: inherit;
`;
