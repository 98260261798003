import React from 'react';

// TODO remove fallback entirely
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ItemStackBlock from 'blocks/ItemStackBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import ColorSwatchesComponent from 'components/ColorSwatchesComponent';
import Divider from 'components/Divider/Divider';
import Headline from 'elements/Headline';
import NakedButton from 'elements/NakedButton';
import { inject, observer } from 'mobx-react';
import { ChapterModelType } from 'models/ChapterModel';
import { ColorSchemeModelType } from 'models/print/ColorSchemeModel';
import { LayoutStoreType } from 'models/print/LayoutStore';
import { FormattedMessage } from 'react-intl';
import { SelectionContainerComponent } from './SelectionContainerComponent';

interface ChaptercolorComponentProps {
  layoutStore?: LayoutStoreType;
  chapter: ChapterModelType;
  colorSelected: (key: string) => void;
  currentKey?: string;
}

@inject('layoutStore')
@observer
export class ChaptercolorComponent extends React.Component<
  ChaptercolorComponentProps
> {
  componentDidMount(): void {
    const { layoutStore } = this.props;

    const { colorSchemes } = layoutStore!;

    if (!colorSchemes) {
      this.loadColors();
    }
  }

  async loadColors() {
    const { layoutStore, chapter } = this.props;

    try {
      await layoutStore!.getColorSchemes(chapter.chapter_type);
    } catch (error: any) {}
  }

  async selectColor(key: string) {
    const { colorSelected } = this.props;
    colorSelected(key);
  }

  filterUsableAssets(colors: ColorSchemeModelType[]) {
    return colors.filter(
      (colors) =>
        !colors.tags.includes('private') && !colors.tags.includes('unindexed')
    );
  }

  findCustomColors() {
    const { layoutStore } = this.props;

    const colors = this.filterUsableAssets(layoutStore!.allColorSchemes);

    if (!colors || colors.length === 0) {
      return [];
    }

    return colors.filter((color) => color.tags.includes('onlyBookFiltered'));
  }

  findRecommendedColors() {
    const { layoutStore, chapter } = this.props;

    const colors = this.filterUsableAssets(layoutStore!.allColorSchemes);
    const theme = chapter.book_theme_key;

    if (!colors || colors.length === 0) {
      return [];
    }

    return colors.filter(
      (color) =>
      (color.tags.includes(`recommendTheme:${theme}`) || color.tags.includes('onlyThemeFiltered')) &&
      !color.tags.includes('onlyBookFiltered')
    );
  }

  findGlobalColors() {
    const { layoutStore, chapter } = this.props;

    const colors = this.filterUsableAssets(layoutStore!.allColorSchemes);
    const theme = chapter.book_theme_key;

    if (!colors || colors.length === 0) {
      return [];
    }

    return colors.filter(
      (color) => !color.tags.includes('onlyBookFiltered') && !color.tags.includes(`recommendTheme:${theme}`) && !color.tags.includes('onlyThemeFiltered')
    );
  }

  render() {
    const { currentKey } = this.props;
    // const selected = layoutKey || chapter.layout_key;
    const customColors: ColorSchemeModelType[] = this.findCustomColors();
    const recommendedColors: ColorSchemeModelType[] = this.findRecommendedColors();
    const globalColors: ColorSchemeModelType[] = this.findGlobalColors();

    return (
      <ItemStackBlock gap="L">
        <Headline.Large>
          <FormattedMessage id="choose chapter color" />
        </Headline.Large>

        <Divider />

        {customColors.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Custom assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {customColors.map((color) => {
                const previews = color.previewColors;
                return (
                  <NakedButton
                    key={color.id}
                    onClick={() => this.selectColor(color.id)}
                  >
                    <SelectionContainerComponent
                      selected={currentKey === color.id}
                    >
                      <ColorSwatchesComponent
                        first={previews[0]}
                        second={previews[1]}
                        third={previews[2]}
                      />
                    </SelectionContainerComponent>
                  </NakedButton>
                );
              })}
            </TwoColumnBlock>
          </>
        )}

        {recommendedColors.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Recommended assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {recommendedColors.map((color) => {
                const previews = color.previewColors;
                return (
                  <NakedButton
                    key={color.id}
                    onClick={() => this.selectColor(color.id)}
                  >
                    <SelectionContainerComponent
                      selected={currentKey === color.id}
                    >
                      <ColorSwatchesComponent
                        first={previews[0]}
                        second={previews[1]}
                        third={previews[2]}
                      />
                    </SelectionContainerComponent>
                  </NakedButton>
                );
              })}
            </TwoColumnBlock>
          </>
        )}

        {globalColors.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Global assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {globalColors.map((color) => {
                const previews = color.previewColors;
                return (
                  <NakedButton
                    key={color.id}
                    onClick={() => this.selectColor(color.id)}
                  >
                    <SelectionContainerComponent
                      selected={currentKey === color.id}
                    >
                      <ColorSwatchesComponent
                        first={previews[0]}
                        second={previews[1]}
                        third={previews[2]}
                      />
                    </SelectionContainerComponent>
                  </NakedButton>
                );
              })}
            </TwoColumnBlock>
          </>
        )}
      </ItemStackBlock>
    );
  }
}

export default ChaptercolorComponent;
