import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import MessageBlock from 'blocks/MessageBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import LockedLabel from 'domain/Label/LockedLabel';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import { FormType, handleFormError, useForm } from 'utils/hooks/useForm';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

interface GroupDetailScreenProps {
  profilesStore: ProfilesStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('profilesStore', 'applicationStore')
@observer
class GroupDetailScreen extends Component<
GroupDetailScreenProps & HistoryProps
> {
  async loadGroup(id: number) {
    const { profilesStore, form } = this.props;

    if (profilesStore.isGroupItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const group = await profilesStore.getGroup(id);

    if (group) {
      form.setField('name', group.name || '');
    }

    return group;
  }

  async componentDidMount() {
    const id = idFromMatch(this.props.match);
    if (id) {
      await this.loadGroup(id);
    } else {
      // new group mode
      this.props.profilesStore.clearCurrentGroupItem();
      this.props.form.reset();
    }
  }

  async componentDidUpdate(prevProps: any) {
    const newId = idFromMatch(this.props.match);
    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new group mode
          this.props.profilesStore.clearCurrentGroupItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        await this.loadGroup(newId);
      }
    }
  }

  navigateToList() {
    this.props.history.replace(ROUTE_SETTINGS + '/groups');
  }

  async handleSubmit() {
    const { form, profilesStore, applicationStore } = this.props;
    const { name } = form.values;

    if (!profilesStore.groupItem?.id) {
      return;
    }

    form.resetErrors();

    const patch: any = {
      name: !name ? '' : name.trim()
    };

    try {
      await profilesStore.updateGroup(profilesStore.groupItem.id, patch);
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }

    applicationStore.setDefaultFlashMessage('saved');
    this.navigateToList();
  }

  confirmDelete() {
    const { profilesStore, history } = this.props;
    const { groupItem } = profilesStore;

    if (groupItem && groupItem.has_chapters) {
      return history.replace(ROUTE_SETTINGS + '/groups/' + groupItem.id + '/chapters');
    }

    this.props.form.setConfirm(true);
  }

  async performDelete() {
    const { profilesStore, form } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return;
    }

    this.finishDelete();

    try {
      await profilesStore.removeGroup(id);
    } catch (error: any) {
      handleFormError(form, error);

      // delete errors are handled by store internally
      return;
    }

    this.navigateToList();
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  isAddMode() {
    return !idFromMatch(this.props.match) ? true : false;
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  renderReadOnly() {
    const { profilesStore } = this.props;

    return this.renderPage(
      <>
        <LockedLabel id="Group" />

        <Paragraph>
          {profilesStore.groupItem && profilesStore.groupItem.name}
        </Paragraph>
      </>
    );
  }

  renderEditForm() {
    const { profilesStore, form } = this.props;
    const isAddMode = this.isAddMode();

    let extra;
    if (form.confirm) {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm delete header" />}
          text={
            <FormattedMessage
              id="confirm delete item"
              values={{
                item: <FormattedMessage id="This group" />
              }}
            />
          }
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => this.performDelete()}
          onAbort={() => this.finishDelete()}
          confirmColor="RED"
        />
      );
    } else if (form.errors.base) {
      extra = (
        <MessageBlock error={true} static={true}>
          <Paragraph>{form.errors.base}</Paragraph>
        </MessageBlock>
      );
    } else if (profilesStore.groupItemLoadingState === 'update_error') {
      extra = <GenericErrorComponent />;
    }

    return this.renderPage(
      <>
        {extra}

        <TextInputComponent
          name="name"
          label={intl.formatMessage({ id: 'Name' })}
          {...this.props.form.bindInput('name')}
          autoFocus={true}
        />

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id={isAddMode ? 'Add' : 'Save'} />
        </ButtonBlock>

        {!isAddMode && (
          <>
            <Divider />
            <DeleteButton onClick={() => this.confirmDelete()} />
          </>
        )}
      </>
    );
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_SETTINGS + '/groups'} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Edit group" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { profilesStore, applicationStore } = this.props;

    if (profilesStore.isGroupItemLoading) {
      return this.renderLoading();
    }

    if (profilesStore.groupItemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (!this.isAddMode() && !applicationStore.isEditAllowed) {
      return this.renderReadOnly();
    }

    return this.renderEditForm();
  }
}

export default (props: any) => {
  const form = useForm();
  return <GroupDetailScreen {...props} form={form} />;
};
