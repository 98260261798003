import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

import LabelElement from './LabelElement';

interface CheckboxElementProps {
  error?: boolean;
}

export default styled.input.attrs({
  type: 'checkbox'
})<CheckboxElementProps>`
  position: absolute;
  width: 1.3125rem;
  height: 1.3125rem;
  margin: 0;
  padding: 0;
  opacity: 0;

  /* stylelint-disable selector-type-no-unknown */
  & + ${LabelElement}::before {
    content: '';
    display: block;
    width: 1.3125rem;
    height: 1.3125rem;
    background: ${COLORS.WHITE};
    border: ${COLORS.GRAY800} 1px solid;
    border-radius: 0.1875rem;
    margin-right: 0.75rem;
  }

  &:checked + ${LabelElement}::before {
    background-color: ${COLORS.GREEN};
    border-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.38 8.96c.05-.02.1-.06.14-.1l7.34-7.34c.21-.2.21-.55 0-.75l-.38-.38a.53.53 0 00-.75 0l-6.59 6.6-2.92-2.93a.53.53 0 00-.75 0l-.38.38c-.2.2-.2.55 0 .75l3.67 3.67c.17.17.42.2.62.1z' fill='%23fff'/%3E%3C/svg%3E");
    background-size: 0.8125rem;
    background-repeat: no-repeat;
    background-position: 0.1875rem 0.375rem;
  }

  ${(props) =>
    props.error &&
    `
    /* stylelint-disable selector-type-no-unknown */
    &:not(:checked) + ${LabelElement}::before {
      border-color: ${COLORS.RED};
    }
  `}
`;
