import styled from 'styled-components';

import COLORS from 'utils/constants/colors';
import SPACES from 'utils/constants/spaces';

const ContainerBlock = styled.div`
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  max-width: 40em;
  min-height: 100vh;
  padding: 1px 1.25em 1.25em 1.25em;
  padding-bottom: ${SPACES.XXL};
  background: ${COLORS.WHITE};
`;

export default ContainerBlock;
