import { VoteModelType } from 'models/VoteModel';
import createMap from 'utils/create-map';
import booleanOrUndefined from './booleanOrUndefined';
import numberOrUndefined from './numberOrUndefined';

export default (vote: any): VoteModelType | undefined => {
  if (!vote) {
    return undefined;
  }

  return {
    candidates: !vote.candidates ? undefined : createMap(vote.candidates),
    count: numberOrUndefined(vote.count),
    excluded: booleanOrUndefined(vote.excluded),
    ranking_exclusion_id: numberOrUndefined(vote.ranking_exclusion_id)
  };
};
