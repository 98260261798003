import React from 'react';

import { extractColor } from 'utils/chunker/color-scheme';
import replaceVariables, { PageParams } from 'utils/chunker/replace-variables';

import TocComponent, { Toc } from '../elements/TocComponent';
import PageComponent, {
  PAGE_SIZE_A4,
  PAGE_SIZE_PRINT
} from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import {
  CommonTemplateProps,
  pageParamsFromProps
} from './CommonTemplateProps';
import WaitForImages from './WaitForImages';

const DEFAULT_TITLE = 'Inhalt'; // TODO i18n!?

export interface TocTemplateConfig {
  leftBackground?: string;
  rightBackground?: string;
}

export interface TocTemplateProps extends CommonTemplateProps {
  // theme
  config: TocTemplateConfig;

  // content
  title?: string;
  toc?: Toc;
}

export class TocTemplateComponent extends React.Component<TocTemplateProps> {
  componentDidMount() {
    // we have nothing to place but still should tell parent we're done (doing nothing)
    const { onContentPlaced } = this.props;
    onContentPlaced && onContentPlaced(1);
  }

  generateReplaceParams(): PageParams {
    const { startPosition, startPage } = this.props;

    const params = pageParamsFromProps(this.props, startPosition, startPage);
    return params;
  }

  generateBackground(): string {
    const { config, startPosition } = this.props;

    const background =
      startPosition === 'right' && !!config.rightBackground
        ? config.rightBackground
        : config.leftBackground;
    if (!background) {
      return '';
    }

    const params = this.generateReplaceParams();
    params.variables = params.variables || [];

    return replaceVariables(background, params) || '';
  }

  render() {
    const { print, render, pageHeaderStyle, title, toc, colors } = this.props;

    const headlineColor = extractColor('headline', colors);
    const headlineColorBgColor = extractColor('headline_background', colors);
    const textColor = extractColor('text', colors);

    const page = (
      <PageComponent
        key="toc"
        print={print}
        previewSize={print ? PAGE_SIZE_PRINT : PAGE_SIZE_A4}
        background={this.generateBackground()}
      >
        <PageContentComponent>
          <TocComponent
            toc={toc}
            header={title || DEFAULT_TITLE}
            fontColor={textColor}
            headerFontStyle={
              (pageHeaderStyle || '') +
              ';' +
              (headlineColor ? 'color: ' + headlineColor + ';' : '') +
              (headlineColorBgColor
                ? 'background-color: ' + headlineColorBgColor + ';'
                : '')
            }
          />
        </PageContentComponent>
      </PageComponent>
    );

    return (
      <>
        {!render ? page : render(page, 0)}
        {print && <WaitForImages />}
      </>
    );
  }
}

export default TocTemplateComponent;
