import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import LinkBlock from 'blocks/LinkBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import CancelLink from 'domain/Links/CancelLink';
import SelectGroup from 'domain/SelectGroup';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { assert } from 'utils/assert';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';

interface MyGroupScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
  form: FormType;
}

@inject('applicationStore', 'profilesStore')
@observer
class MyGroupScreen extends React.Component<MyGroupScreenProps & HistoryProps> {
  state = {
    showGroupConfirm: false
  };
  async componentDidMount() {
    const { applicationStore, form } = this.props;
    const { currentUser } = applicationStore;
    assert(currentUser);

    form.setField('group_id', currentUser.group?.id.toString() || '');
  }

  async updateGroup() {
    const { applicationStore, form, profilesStore } = this.props;
    if (!applicationStore.currentUser) {
      return;
    }

    try {
      await profilesStore.updateProfileGroup(
        applicationStore.currentUser.id,
        parseInt(form.values.group_id)
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by ProfilesStore internally
      return;
    }

    applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'group updated' })
    );
    this.props.history.push(ROUTE_SETTINGS + '/account');
  }

  renderPage(content: ReactNode) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_SETTINGS + '/account'} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  renderConfirm() {
    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="Change group header" />}
        text={<FormattedMessage id="Change group info" />}
        confirmText={<FormattedMessage id="Change group" />}
        confirmColor="RED"
        abortText={<FormattedMessage id="Cancel" />}
        onConfirm={() => this.updateGroup()}
        onAbort={() =>
          this.setState({
            showGroupConfirm: false
          })
        }
      />
    );
  }

  renderView() {
    const { applicationStore, profilesStore, form } = this.props;
    const { currentUser, isOrganizer } = applicationStore;

    if (!currentUser) {
      return null;
    }

    const isUpdateError =
      profilesStore.itemLoadingState === 'update_error' ? true : false;

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Edit group" />} text={<><IconComponent icon="USER" /> {currentUser.name}</>} />


        {isOrganizer && (
          <LinkBlock
            to={ROUTE_SETTINGS + '/groups'}
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
          >
            <FormattedMessage id="Go to groups" />
          </LinkBlock>
        )}

        {isUpdateError && <GenericErrorComponent />}

        <SelectGroup form={form} />

        <ButtonBlock
          background="PRIMARY"
          disabled={form.values.group_id === ''}
          onClick={() =>
            this.setState({
              showGroupConfirm: true
            })
          }
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
        {this.state.showGroupConfirm && this.renderConfirm()}
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  render() {
    const { applicationStore, profilesStore } = this.props;

    if (applicationStore.isRefreshing) {
      return this.renderLoading();
    }

    if (profilesStore.itemLoadingState === 'error') {
      return this.renderError();
    }

    return this.renderView();
  }
}

export default (props: any) => {
  const form = useForm();
  return <MyGroupScreen {...props} form={form} />;
};
