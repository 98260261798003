/* eslint-disable react/style-prop-object */
import { TextileDiscount } from 'api/textile_deals/fetchTextileDiscounts';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import { IconListElement } from 'blocks/IconListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const TextileDiscountListItem = ({
  discount,
  active,
  isCheckout
}: {
  discount: TextileDiscount;
  active: boolean;
  isCheckout?: boolean;
}) => {
  let discountText = discount.info;

  if (active) {
    discountText = discount.info_done;
  } else if (isCheckout) {
    discountText = discount.info_not_used;
  }

  return (
    <>
      <IconListElement
        icon={active ? 'TICK_CIRCLE' : 'DOT'}
        fill={active ? 'GREEN' : 'GRAY950'}
      >
        <ItemStackBlock gap="XS">
          <HorizontalStackBlock justified={true}>
            <Paragraph>{discount.name}</Paragraph>
            <Paragraph noBreak={true}>
              <FormattedNumber
                value={discount.value}
                style="currency"
                currency="EUR"
              />
              <FormattedMessage id="per piece" />
            </Paragraph>
          </HorizontalStackBlock>
          <TextElement>{discountText}</TextElement>
        </ItemStackBlock>
      </IconListElement>
    </>
  )
}

export default TextileDiscountListItem;
