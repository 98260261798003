import React, { ReactNode, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import InputMessage from 'elements/InputMessage';
import { FormType } from 'utils/hooks/useForm';

import NextButton from './NextButton';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import { intl } from 'i18n';

const PersonalData = ({
  form,
  onSubmit,
  showEmail,
  messageId,
  headingId,
  infoId,
  submitMessageId,
  errorMessages
}: {
  form: FormType;
  onSubmit: () => void;
  showEmail: boolean;
  messageId?: string;
  headingId: string;
  infoId: string;
  submitMessageId: string;
  errorMessages: ReactNode;
}) => {
  const emailRef = useRef<HTMLInputElement>(null);
  return (
    <PageStackBlock>
      {messageId && (
        <InputMessage ok={true}>
          <FormattedMessage id={messageId} />
        </InputMessage>
      )}

      <PageHeader headline={<FormattedMessage id={headingId} />} text={<FormattedMessage id={infoId} />} />

      <FormStackBlock>
        <TextInputComponent
          name="first_name"
          label={<FormattedMessage id="First name" />}
          {...form.bindInput('first_name')}
          autoFocus={true}
        />

        <TextInputComponent
          type="tel"
          name="mobile_number"
          label={<FormattedMessage id="Your number (WhatsApp)" />}
          autoComplete="tel"
          {...form.bindInput('mobile_number')}
          placeholder={intl.formatMessage({ id: 'phone number placeholder' })}
        />

        {showEmail && (
          <TextInputComponent
            ref={emailRef}
            type="email"
            name="email"
            required={true}
            autoComplete="email"
            label={<FormattedMessage id="e-mail" />}
            {...form.bindInput('email')}
            placeholder="email@adresse.de"
          />
        )}
      </FormStackBlock>

      {errorMessages}

      <NextButton
        condition={
          !form.loading &&
          form.values.first_name &&
          form.values.mobile_number &&
          (showEmail ? emailRef.current?.validity.valid === true : true)
        }
        onClick={onSubmit}
      >
        <FormattedMessage id={submitMessageId} />
      </NextButton>

      <IconListBlock>
        <IconListElement icon="TICK_CIRCLE" fill="GREEN">
          <FormattedMessage id="Nonbinding" />
        </IconListElement>
        <IconListDivider />
        <IconListElement icon="TICK_CIRCLE" fill="GREEN">
          <FormattedMessage id="No ads" />
        </IconListElement>
      </IconListBlock>
    </PageStackBlock>
  );
};

export default PersonalData;
