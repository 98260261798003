import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import BackLink from 'domain/Links/BackLink';
import BackToContentLink from 'domain/Links/BackToContentLink';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import { ROUTE_CONTENT, ROUTE_DASHBOARD } from 'utils/constants/routes';
import history from 'utils/history';
import ExpertsBlock from 'blocks/ExpertsBlock';
import HelpHideByChild from 'components/HelpButton/HelpHideByChild';
import Paragraph from 'components/Paragraph/Paragraph';

interface TeamCallContainerProps {
  title?: ReactNode;
  text?: ReactNode;
  backTo?: 'none' | 'dashboard' | 'content' | string;
  onOkClick?: () => void;
}

export const TeamCallContainer = ({
  backTo,
  title,
  text,
  onOkClick
}: TeamCallContainerProps) => (
  <>
    {backTo && (
      backTo === 'none' ? null : (
        <TopActionBlock>
          {backTo === 'dashboard' ? (
            <BackToDashboardLink />
          ) : backTo === 'content' ? (
            <BackToContentLink />
          ) : (
            <BackLink to={backTo} />
          )}
        </TopActionBlock>
      )
    )}

    <ItemStackBlock marginTop="XL" gap="L">
      <ExpertsBlock>
        <ExpertsBlock.AvatarInfo>
          <img src="/images/experts.jpg" alt="" />

          <Paragraph  color="TEXT_LIGHT">
            <FormattedMessage id="team call experts" />
          </Paragraph>
        </ExpertsBlock.AvatarInfo>

        <ExpertsBlock.TextBlock>
          <Headline.Medium color="BLACK">
            {title || <FormattedMessage id="team call header" />}
          </Headline.Medium>

          <Paragraph size="L"  color="TEXT_LIGHT">
            {text || <FormattedMessage id="team call text" />}
          </Paragraph>

          {onOkClick || backTo ? (
            <ButtonBlock
              background="PRIMARY"
              color="WHITE"
              onClick={
                onOkClick
                  ? onOkClick
                  : () =>
                      history.push(
                        backTo === 'dashboard'
                          ? ROUTE_DASHBOARD
                          : backTo === 'content'
                          ? ROUTE_CONTENT
                          : backTo!
                      )
              }
            >
              <FormattedMessage id="OK" />
            </ButtonBlock>
          ) : null}
        </ExpertsBlock.TextBlock>
      </ExpertsBlock>
    </ItemStackBlock>

    <HelpHideByChild />
  </>
);

export default TeamCallContainer;
