import React, {
  MouseEvent,
  ReactNode
} from 'react';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent
  from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import SharingComponent from 'components/SharingComponent';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import {
  inject,
  observer
} from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfileModelType } from 'models/ProfileModel';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { FormattedMessage } from 'react-intl';
import { ROUTE_DASHBOARD } from 'utils/constants/routes';
import useForm, { FormType } from 'utils/hooks/useForm';

import StudentsListItem, { StudentListItemType } from './StudentsListItem';

interface PublicStudentsListProps {
  organizers?: boolean;
  noSearch?: boolean;
  showFilter?: boolean;
  showSorting?: boolean;
  selected?: number | number[];
  hideCurrentUser?: boolean;
  onlyGroupId?: number;
  itemComponent?: StudentListItemType;
  onStudentClick?: (e: MouseEvent, studentId: number) => void;
  onAcceptClick?: (e: MouseEvent, studentId: number) => void;
  onRejectClick?: (e: MouseEvent, studentId: number) => void;
  onFilterClick?: () => void;
  studentFilter?: string;
  groupName?: string;
  ref?: React.RefObject<unknown>;
  sortingLink?: string;
}

interface StudentsListProps extends PublicStudentsListProps {
  form: FormType;
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
}

@inject('applicationStore', 'profilesStore')
@observer
class StudentsList extends React.Component<StudentsListProps> {
  componentDidMount() {
    // load/update students every time we display the list
    this.loadStudents();
  }

  loadStudents() {
    this.props.profilesStore.getStudents();
  }

  renderError() {
    return <GenericErrorComponent onRetryClick={() => this.loadStudents()} />;
  }

  renderLoading() {
    return <LoadingOverlayComponent />;
  }

  renderList() {
    // TODO Display some loading indicator for 'updating' loading state?

    const {
      applicationStore,
      profilesStore,
      form,
      onStudentClick,
      hideCurrentUser,
      itemComponent,
      organizers,
      onlyGroupId,
      onFilterClick,
      studentFilter,
      groupName,
      showFilter,
      showSorting,
      sortingLink
    } = this.props;
    const { book, currentUserId } = applicationStore;

    if (!book) {
      return null;
    }

    const noSearch = this.props.noSearch || organizers;
    const hasSearch = !noSearch;

    const selected = !this.props.selected
      ? []
      : Array.isArray(this.props.selected)
        ? this.props.selected
        : [this.props.selected];

    let students: ProfileModelType[];
    let heading: ReactNode;

    if (organizers) {
      students = profilesStore.organizers;
    } else if (noSearch || (!form.values.filter && !studentFilter)) {
      students = profilesStore.sortedStudents(onlyGroupId);
      if (hideCurrentUser) {
        students = students.filter((profile) => profile.id !== currentUserId);
      }
      heading = (
        <FormattedMessage
          id="members count"
          values={{
            count: students.length
          }}
        />
      );
    } else {
      students = profilesStore.filteredStudents(
        form.values.filter,
        onlyGroupId,
        studentFilter
      );
      if (hideCurrentUser) {
        students = students.filter((profile) => profile.id !== currentUserId);
      }
      heading = (
        <FormattedMessage
          id="students filter count"
          values={{
            count: students.length
          }}
        />
      );
    }

    const Item: StudentListItemType = !itemComponent
      ? StudentsListItem
      : itemComponent;

    return (
      <ItemStackBlock gap="L">
        {hasSearch && (
          <SearchListInput form={form} placeholderId="Find student" />
        )}

        {showSorting && (
                    <LinkBlock
                    inline={true}
                    slim={true}
                    background="PRIMARY_LIGHT"
                    color="PRIMARY_DARK"
                    to={sortingLink || ROUTE_DASHBOARD}
                  >
                    <IconComponent icon="sort_v" />
                    <FormattedMessage id="Print sorting order" />
                  </LinkBlock>
        )}

        {showFilter && (
          <EveryCard as="button" onClick={() => onFilterClick && onFilterClick()}>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  {studentFilter && groupName ? groupName : studentFilter ? studentFilter : (
                    <FormattedMessage id="All students" />
                  )}
                </EveryCardHeadline>
              </EveryCardBody>
              <IconComponent icon="FILTER" size={1.5} />
            </EveryCardPadding>
          </EveryCard>
        )}

        <ItemStackBlock gap="M">
          <UppercaseHeading>{heading}</UppercaseHeading>

          {students.map((profile) => (
            <Item
              key={profile.id}
              profile={profile}
              selected={selected.includes(profile.id)}
              onClick={onStudentClick}
              isCurrentUser={profile.id === currentUserId}
              hideCurrentUser={hideCurrentUser}
            />
          ))}
        </ItemStackBlock>

        {hasSearch && students.length === 0 && (
          <SharingComponent
            label="invite header"
            buttonOutside={true}
            modalHeader="invite link"
            message="Nuggit invite text"
            buttonColor="WHATSAPP"
            url={book.invitation_url}
          />
        )}
      </ItemStackBlock>
    );
  }

  render() {
    const { profilesStore } = this.props;

    if (profilesStore.isStudentsLoading || profilesStore.isItemLoading) {
      // TODO Add mode which only shows loader on 'loading' state?
      return this.renderLoading();
    }

    if (profilesStore.studentsLoadingState === 'error') {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default (props: PublicStudentsListProps) => {
  const form = useForm();
  // @ts-ignore
  return <StudentsList {...props} form={form} />;
};
