import React from 'react';

import {
  ChunkedContent,
  Content,
  createBox,
  fillColumns,
  updatePages
} from 'utils/chunker/ContentChunker';
import replaceVariables, {
  PageParams,
  PagePosition
} from 'utils/chunker/replace-variables';
import sanitizeHtml from 'utils/chunker/sanitize-html';

import NotAvailableComponent from '../processing/NotAvailableComponent';
import ColumnComponent from '../structure/ColumnComponent';
import PageComponent, {
  PAGE_SIZE_A4,
  PAGE_SIZE_PRINT
} from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import {
  getColumns,
  spaceFromConfig,
  MultiColumnLayout
} from './column-layout';
import {
  CommonTemplateProps,
  pageParamsFromProps
} from './CommonTemplateProps';
import WaitForImages from './WaitForImages';

export interface QuotesTemplateConfig extends MultiColumnLayout {
  leftBackground?: string;
  rightBackground?: string;
  leftAdditionalBackground?: string;
  rightAdditionalBackground?: string;

  quotesBlock?: string;
  quotesBlockMargin?: number;
}

export interface QuotesTemplateProps extends CommonTemplateProps {
  // theme
  config: QuotesTemplateConfig;

  // content
  content?: Content;

  quotes: string[];
}

export interface QuotesTemplateState {
  content?: Content;
  pages?: ChunkedContent[];
}

export default class extends React.Component<
  QuotesTemplateProps,
  QuotesTemplateState
> {
  constructor(props: QuotesTemplateProps) {
    super(props);

    this.state = {
      content: props.content
    };
  }

  componentDidMount() {
    this.placeContent();
  }

  placeContent() {
    const { config, startPosition, startPage, onContentPlaced } = this.props;

    const isFirstPageRight = startPosition === 'right';
    const space = spaceFromConfig(config, isFirstPageRight);
    // console.log('new', space);
    // console.log('old', [
    //   [
    //     // primary page
    //     getColumn1Size(config, false, isFirstPageRight),
    //     getColumn2Size(config, false, isFirstPageRight)
    //   ],
    //   [
    //     // additional page 1
    //     getColumn1Size(config, true),
    //     getColumn2Size(config, true)
    //   ]
    // ]);

    const content = this.generateContent();
    const pages = fillColumns(space, content);

    updatePages(startPosition, startPage, content, pages);

    this.setState({
      content,
      pages
    });

    if (onContentPlaced) {
      onContentPlaced(pages.length);
    }
  }

  generateBackground(
    position: PagePosition,
    isAdditional: boolean,
    page?: number
  ): string {
    const { config } = this.props;

    const [leftBG, rightBG] = isAdditional
      ? [config.leftAdditionalBackground, config.rightAdditionalBackground]
      : [config.leftBackground, config.rightBackground];

    const background = position === 'right' && rightBG ? rightBG : leftBG;
    if (!background) {
      return '';
    }

    const params = this.generateReplaceParams(position, page);
    return replaceVariables(background, params) || '';
  }

  generateReplaceParams(position: PagePosition, page?: number): PageParams {
    const params = pageParamsFromProps(this.props, position, page);
    return params;
  }

  generateContent() {
    const { content, config, quotes } = this.props;

    const generated: Content = [];

    const params = this.generateReplaceParams('left', 1);

    if (quotes && quotes.length) {
      for (const quote of quotes) {
        generated.push(
          createBox({
            type: 'box',
            template: config.quotesBlock,
            bottomMargin: config.quotesBlockMargin,
            params,
            contentVariables: [['quote', sanitizeHtml(quote) || '']]
          })
        );
      }
    }

    return content ? generated.concat(content) : generated;
  }

  render() {
    const {
      print,
      config,
      startPosition,
      startPage,
      render,
      firstPageOnly
    } = this.props;

    const { content } = this.state;
    let { pages } = this.state;

    if (!pages || !content) {
      return <NotAvailableComponent print={print} />;
    }

    const isRight = startPosition === 'right';

    let isCurrentRight = !isRight;

    if (firstPageOnly && pages.length) {
      pages = [pages[0]];
    }

    return (
      <>
        {pages.map((page, pageIdx) => {
          isCurrentRight = !isCurrentRight;

          const currentPageNumber = startPage + pageIdx;
          const isAdditional = pageIdx !== 0;

          const background = this.generateBackground(
            isCurrentRight ? 'right' : 'left',
            isAdditional,
            currentPageNumber
          );

          const columns = getColumns(config, isAdditional, isCurrentRight);

          const result = !columns ? null : (
            <PageComponent
              key={pageIdx}
              print={print}
              previewSize={print ? PAGE_SIZE_PRINT : PAGE_SIZE_A4}
              background={background}
            >
              <PageContentComponent>
                {page.map((column, columnIndex) => (
                  <ColumnComponent
                    key={'c' + columnIndex}
                    size={columns[columnIndex].size}
                    position={columns[columnIndex].position}
                  >
                    {column.map((box, boxIndex) => (
                      <div
                        key={boxIndex}
                        style={{
                          marginBottom: !content[box.sourceIndex].bottomMargin
                            ? 0
                            : content[box.sourceIndex].bottomMargin + 'px'
                        }}
                      >
                        {box.type === 'floating' ? (
                          <div
                            css={content[box.sourceIndex].textStyle}
                            dangerouslySetInnerHTML={{
                              __html: box.text || ''
                            }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: content[box.sourceIndex].content || ''
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </ColumnComponent>
                ))}
              </PageContentComponent>
            </PageComponent>
          );

          return !render ? result : render(result, pageIdx);
        })}

        {print && <WaitForImages />}
      </>
    );
  }
}
