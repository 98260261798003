import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_MOTTOS, ROUTE_MOTTOS_ORG } from 'utils/constants/routes';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import MottoDetailScreen from './screens/MottoDetailScreen';
import MottosSelectScreen from './screens/MottoSelectScreen';
import MottosRankingScreen from './screens/MottosRankingScreen';
import MottosVotingScreen from './screens/MottosVotingScreen';
import MottoIdeasScreen from './screens/MottoIdeasScreen';

export default () => (
  <Switch>
    <Route path={ROUTE_MOTTOS} exact={true} component={MottosVotingScreen} />
    <Route path={ROUTE_MOTTOS_ORG} component={MottosRankingScreen} />
    <Route path={ROUTE_MOTTOS + '/ideas'} component={MottoIdeasScreen} />

    <Route path={ROUTE_MOTTOS + '/select'} component={MottosSelectScreen} />

    <Route path={ROUTE_MOTTOS + '/:id'} component={MottoDetailScreen} />

    <NotFoundRoute />
  </Switch>
);
