import NuggitApi from 'api/NuggitApi';
import AccountStore from 'models/AccountStore';
import ApplicationStore from 'models/ApplicationStore';
import BookPriceStore from 'models/BookPriceStore';
import BooksStore from 'models/BooksStore';
import ChaptersStore from 'models/ChaptersStore';
import ContentNotPrintedStore from 'models/ContentNotPrintedStore';
import DeliveryDatesStore from 'models/DeliveryDatesStore';
import LogoutStore from 'models/LogoutStore';
import ProfilesStore from 'models/ProfilesStore';
import SetupStore from 'models/SetupStore';
import {
  AdvancedStoreEnv
} from 'models/StoreEnv';
import LayoutStore from 'models/print/LayoutStore';
import AlbumPagesStore from 'screens/album_pages/AlbumPagesStore';
import CheckoutStore from 'screens/checkout/CheckoutStore';
import CommentsStore from 'screens/comments/CommentsStore';
import FactsheetsStore from 'screens/factsheets/FactsheetsStore';
import MottosStore from 'screens/mottos/MottosStore';
import PhotosStore from 'screens/photos/PhotosStore';
import QuotesStore from 'screens/quotes/QuotesStore';
import RankingsStore from 'screens/rankings/RankingsStore';
import PlaceholderSponsorPagesStore from 'screens/sponsor_pages/PlaceholderSponsorPagesStore';
import SponsorPageSortingStore from 'screens/sponsor_pages/SponsorPageSortingStore';
import SponsorPagesStore from 'screens/sponsor_pages/SponsorPagesStore';
import SponsorsStore from 'screens/sponsors/SponsorsStore';
import TextsStore from 'screens/texts/TextsStore';

export default function configureStores(options: { client: NuggitApi }) {
  const applicationStore = ApplicationStore.create(
    {},
    {
      client: options.client
    }
  );

  const advancedStoreEnv: AdvancedStoreEnv = {
    client: options.client,
    applicationStore
  };

  const layoutStore = LayoutStore.create({}, advancedStoreEnv);

  const setupStore = SetupStore.create({}, advancedStoreEnv);

  const profilesStore = ProfilesStore.create({}, advancedStoreEnv);

  const chaptersStore = ChaptersStore.create(
    {},
    {
      client: options.client,
      applicationStore,
      layoutStore
    }
  );

  const quotesStore = QuotesStore.create({}, advancedStoreEnv);

  const commentsStore = CommentsStore.create({}, advancedStoreEnv);

  const rankingsStore = RankingsStore.create({}, advancedStoreEnv);

  const sponsorsStore = SponsorsStore.create({}, advancedStoreEnv);

  const sponsorPagesStore = SponsorPagesStore.create({}, advancedStoreEnv);

  const placeholderSponsorPagesStore = PlaceholderSponsorPagesStore.create({}, advancedStoreEnv);

  const sponsorPageSortingStore = SponsorPageSortingStore.create({}, advancedStoreEnv);

  const textsStore = TextsStore.create({}, advancedStoreEnv);

  const photosStore = PhotosStore.create({}, advancedStoreEnv);

  const albumPagesStore = AlbumPagesStore.create({}, advancedStoreEnv);

  const mottosStore = MottosStore.create({}, advancedStoreEnv);

  const factsheetsStore = FactsheetsStore.create({}, advancedStoreEnv);

  const booksStore = BooksStore.create({}, advancedStoreEnv);

  const accountStore = AccountStore.create({}, advancedStoreEnv);

  const logoutStore = LogoutStore.create(
    {},
    {
      applicationStore,
      chaptersStore
    }
  );

  const bookPriceStore = BookPriceStore.create(
    {
      loadingState: 'initial'
    },
    advancedStoreEnv
  );

  const deliveryDatesStore = DeliveryDatesStore.create(
    {
      loadingState: 'initial'
    },
    advancedStoreEnv
  );

  const checkoutStore = CheckoutStore.create(
    {
      createLoadingState: 'initial',
      getLoadingState: 'initial',
      updateLoadingState: 'initial',
      finishLoadingState: 'initial'
    },
    advancedStoreEnv
  );

  const contentNotPrintedStore = ContentNotPrintedStore.create(
    {},
    advancedStoreEnv
  );

  return {
    applicationStore,
    setupStore,
    profilesStore,
    chaptersStore,
    quotesStore,
    commentsStore,
    rankingsStore,
    sponsorsStore,
    sponsorPagesStore,
    placeholderSponsorPagesStore,
    sponsorPageSortingStore,
    textsStore,
    photosStore,
    albumPagesStore,
    mottosStore,
    factsheetsStore,
    layoutStore,
    logoutStore,
    bookPriceStore,
    deliveryDatesStore,
    checkoutStore,
    contentNotPrintedStore,
    booksStore,
    accountStore
  };
}
