export const extractLocalTime = (date: Date): string => {
  let currentHours = date.getHours().toString();
  currentHours = ('0' + currentHours).slice(-2);

  let currentMinutes = date.getMinutes().toString();
  currentMinutes = ('0' + currentMinutes).slice(-2);

  return currentHours + ':' + currentMinutes;
};

export default extractLocalTime;
