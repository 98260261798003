import React from 'react';

import { ALBUM_PAGE_GRIDS } from '../templates/album-page-grids';
import ImagePageTemplateComponent from '../templates/ImagePageTemplateComponent';
import { CommonLayoutPreviewProps } from './CommonLayoutPreviewProps';
import scalePreview from './scale-preview';
import { generateAlbumPageTemplateConfig } from '../templates/generate-album-page-template-config';

interface AlbumPreviewComponentProps extends CommonLayoutPreviewProps {
  config: any; // will be ignored anyway
}

export class AlbumPreviewComponent extends React.Component<
  AlbumPreviewComponentProps
> {
  shouldComponentUpdate() {
    // preview will be forced to redraw on config update only
    return false;
  }

  render() {
    const {
      colors,
      pageHeaderStyle,
      sectionHeaderStyle,
      background,
      start,
      scaled,
      chapter,
      firstPageOnly
    } = this.props;

    const finalColors = colors || chapter?.color_scheme?.templateColors || [];
    const finalPageHeaderStyle =
      pageHeaderStyle || chapter?.font?.pageHeaderStyle;
    const finalSectionHeaderStyle =
      sectionHeaderStyle || chapter?.font?.sectionHeaderStyle;
    const finalBackground =
      background ||
      (chapter?.background?.id || '') +
        (chapter?.background?.extension || '') ||
      undefined;

    return (
      <>
        <ImagePageTemplateComponent
          print={false}
          config={generateAlbumPageTemplateConfig(
            ALBUM_PAGE_GRIDS['grid-4-titled']
          )}
          colors={finalColors}
          pageHeaderStyle={finalPageHeaderStyle}
          sectionHeaderStyle={finalSectionHeaderStyle}
          background={finalBackground}
          startPosition={start || 'left'}
          startPage={this.props.startPage || 1}
          render={
            !scaled ? undefined : (content, key) => scalePreview(content, key)
          }
          title={chapter?.title}
          images={{
            1: {
              slotId: 1,
              fit: true,
              url: '/images/content-preview/album_landscape_3.jpg'
            },
            2: {
              slotId: 2,
              fit: true,
              url: '/images/content-preview/album_landscape_1.jpg',
              caption: 'Beschriftungstext'
            }
          }}
        />

        {!firstPageOnly && (
          <ImagePageTemplateComponent
            print={false}
            config={generateAlbumPageTemplateConfig(
              ALBUM_PAGE_GRIDS['grid-1-titled']
            )}
            colors={finalColors}
            pageHeaderStyle={finalPageHeaderStyle}
            sectionHeaderStyle={finalSectionHeaderStyle}
            background={finalBackground}
            startPosition={start || 'right'}
            startPage={this.props.startPage || 2}
            render={
              !scaled ? undefined : (content, key) => scalePreview(content, key)
            }
            images={{
              1: {
                slotId: 1,
                fit: true,
                url: '/images/content-preview/album_portrait_1.jpg'
              }
            }}
          />
        )}
      </>
    );
  }
}

export default AlbumPreviewComponent;
