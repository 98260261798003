import { ImagePageImageMap } from 'components/print/templates/ImagePageTemplateComponent';
import { SponsorPageModelType } from 'models/SponsorPageModel';

/**
 * Reduces the album page photos to an object consumed by ImagePagePreviewComponent
 */
const reduceSponsorPageSponsor = (sponsorPage: SponsorPageModelType) => {
  return sponsorPage.sponsor_page_sponsors.reduce<ImagePageImageMap>(
    (result, sponsorPageSponsor) => {
      const { sponsor } = sponsorPageSponsor;
      if (sponsor.advertisement) {
        result[sponsorPageSponsor.position] = {
          slotId: sponsorPageSponsor.position,
          fit: true,
          url: sponsor.advertisement.url!
        };
      }
      return result;
    },
    {}
  );
};

export default reduceSponsorPageSponsor;
