import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import Headline from 'elements/Headline';
import { HEADER_MAX_LENGTH } from 'screens/factsheets/screens/QuestionHeaderText/QuestionHeaderTextScreen';

import SettingNotAvailableComponent from '../../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from '../SettingsDetailProps';
import Divider from 'components/Divider/Divider';
import Paragraph from 'components/Paragraph/Paragraph';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';

const FactsheetCommentsSettingsContainer = ({
  form,
  chapter,
  onCommitSettings
}: SimpleSettingsDetailProps) => {
  if (!chapter.isFactsheetChapter) {
    return <SettingNotAvailableComponent />;
  }

  const isTeacherFactsheets = chapter.chapter_type === 'teacher_factsheet';

  return (
    <PageStackBlock>
      <PageHeader headline={<FormattedMessage id="Comments" />} text={<FormattedMessage
        id={
          isTeacherFactsheets
            ? 'teacher comments settings info'
            : 'comments settings info'
        }
      />} />

      <CustomCheckboxBlock>
        <CustomCheckboxBlock.CheckboxElement
          id="checkbox1"
          {...form.bindCheckbox('comments_on')}
        />
        <CustomCheckboxBlock.LabelElement htmlFor="checkbox1">
          <FormattedMessage id="Comments enabled" />
        </CustomCheckboxBlock.LabelElement>
      </CustomCheckboxBlock>


      {form.values.comments_on && (
        <>
          <Divider />

          <ItemStackBlock gap="XS">
            <Headline.Medium>
              <FormattedMessage id="comment anonymity" />
            </Headline.Medium>

            <Paragraph  color="TEXT_LIGHT">
              <FormattedMessage id="comment anonymity info" />
            </Paragraph>
          </ItemStackBlock>

          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="checkbox2"
              {...form.bindCheckbox('print_comment_name')}
            />
            <CustomCheckboxBlock.LabelElement htmlFor="checkbox2">
              <FormattedMessage id="print comment name" />
            </CustomCheckboxBlock.LabelElement>
          </CustomCheckboxBlock>

          <Divider />

          <ItemStackBlock gap="XS">
            <Headline.Medium>
              <FormattedMessage id="Headline" />
            </Headline.Medium>

            <Paragraph  color="TEXT_LIGHT">
              <FormattedMessage id="comments header info" />
            </Paragraph>
          </ItemStackBlock>

          <FormStackBlock>
            <TextInputComponent
              name="comments_header"
              label={<FormattedMessage id="Factsheet comments header" />}
              maxLength={HEADER_MAX_LENGTH}
              {...form.bindInput('comments_header')}
            />

            <CharCounterComponent
              max={HEADER_MAX_LENGTH}
              value={form.values.comments_header}
            />
          </FormStackBlock>
        </>
      )}

      <ButtonBlock
        background="PRIMARY"
        onClick={() =>
          onCommitSettings({
            comments_on: form.values.comments_on ? true : false,
            // We're using anonymous_comments in the data but the form uses the inverse print_comment_name
            anonymous_comments: form.values.print_comment_name ? false : true,
            comments_header: form.values.comments_header || ''
          })
        }
      >
        <FormattedMessage id="Save" />
      </ButtonBlock>
    </PageStackBlock>
  );
};

export default FactsheetCommentsSettingsContainer;
