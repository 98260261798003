// This helper creates a valid snapshot for mobx state tree 'map' type
// from an array containing objects. These snapshots can be used to
// initialize a map in a state tree model.
// Objects need to have an 'id' property in order to be added to the map.

export const createMapWithTransform = (
  arr?: any,
  transform?: (item: any) => any,
  identifier = 'id'
) => {
  if (!Array.isArray(arr) || !arr.length) {
    return {};
  }

  const map: any = {};
  arr.forEach((item: any) => {
    const add: any = !transform ? item : transform(item);
    if (add[identifier]) {
      map[add[identifier]] = add;
    }
  });

  return map;
};

export const createArrayWithTransform = (
  arr?: any,
  transform?: (item: any) => any
) => {
  if (!Array.isArray(arr) || !arr.length) {
    return [];
  }

  const array: any = [];
  arr.forEach((item: any) => {
    const add: any = !transform ? item : transform(item);
    array.push(add);
  });

  return array;
};

export const nullToUndefined = (obj: any) => {
  const newObj = { ...obj };
  for (const fieldName in newObj) {
    if (newObj.hasOwnProperty(fieldName) && newObj[fieldName] === null) {
      newObj[fieldName] = undefined;
    }
  }

  return newObj;
};

export const createMap = (arr?: any) =>
  createMapWithTransform(arr, nullToUndefined);
export const createArray = (arr?: any) =>
  createArrayWithTransform(arr, nullToUndefined);

export const createArrayWithoutTransform = (arr?: any) =>
  createArrayWithTransform(arr);

export default createMap;
