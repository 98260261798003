import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

const StickyBottomContainerBlock = styled.div<{ border?: boolean }>`
  position: fixed;
  bottom: 0;
  margin: 0 -1.25em;
  max-width: 40em;
  ${({ border = true }) => border && `border-top: 1px solid ${COLORS.GRAY900};`}
  padding: 1.25em 1.25em 2.5em 1.25em;
  background: ${COLORS.WHITE};
`;

export default StickyBottomContainerBlock;
