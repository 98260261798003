import style from 'styled-components';
import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';
import FONT_SIZES from 'utils/constants/fontSizes';
import FONT_WEIGHTS from 'utils/constants/fontWeights';

export interface TextProps {
  size?: keyof typeof FONT_SIZES;
  color?: keyof typeof COLORS;
  weight?: keyof typeof FONT_WEIGHTS;
  noBreak?: boolean;
  textAlign?: 'center' | 'right' | 'left';
  textDecoration?: 'underline';
}

const TextElement = style.span<TextProps>`
  font-size: ${(props) => (props.size ? FONT_SIZES[props.size] : FONT_SIZES.S)};
  line-height: 1.3;
  font-family: ${FONTS.DEFAULT};
  font-weight: ${(props) =>
    props.weight ? FONT_WEIGHTS[props.weight] : FONT_WEIGHTS.NORMAL};
  letter-spacing: 0;
  color: ${(props) => (props.color ? COLORS[props.color] : COLORS.TEXT_LIGHT)};
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'inherit')};
  ${(props) =>
    props.noBreak &&
    `
    white-space: nowrap;
  `}

  ${(props) =>
    props.textDecoration &&
    `
    text-decoration: underline;
  `};

  > svg:first-child {
    margin-right: 0.25em;
    vertical-align: -0.1em;
  }

  > svg:last-child {
    margin-left: 0.25em;
    vertical-align: -0.1em;
  }

  > svg:not(:first-child):not(:last-child) {
    margin-left: 0.25em;
    margin-right: 0.25em;
    vertical-align: -0.1em;
  }
`;

export default TextElement;
