import React, { Component } from 'react';

import CardBlock from 'blocks/CardBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import ConfirmBoxComponent
  from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import { observer } from 'mobx-react';
import { DeliveryDateModelType } from 'models/DeliveryDateModel';
import {
  DeliveryDatesStoreType,
  DeliveryDateType
} from 'models/DeliveryDatesStore';
import { FormattedMessage } from 'react-intl';

import DeliveryDate from './DeliveryDate';

interface ChooseDeliveryDateProps {
  deliveryDatesStore: DeliveryDatesStoreType;
  deliveryDateSet: () => void;
  checkoutFinished?: boolean;
  type: DeliveryDateType;
  textileOrderId?: number;
}

interface ChooseDeliveryDateState {
  showConfirmBox: boolean;
  selectedDeliveryDate?: DeliveryDateModelType;
}

@observer
export default class ChooseDeliveryDate extends Component<
  ChooseDeliveryDateProps
> {
  state: ChooseDeliveryDateState = {
    showConfirmBox: false,
    selectedDeliveryDate: undefined
  };

  componentDidMount() {
    const { deliveryDatesStore, type, textileOrderId } = this.props;

    deliveryDatesStore.getDeliveryDates(type, textileOrderId);
    window.scrollTo(0, 0);
  }

  renderConfirmFinish() {
    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="Confirm selection" />}
        text={<FormattedMessage id="Choose delivery date final" />}
        confirmText={<FormattedMessage id="Choose delivery date" />}
        abortText={<FormattedMessage id="Cancel" />}
        onAbort={() => {
          this.setState({ showConfirmBox: false });
        }}
        onConfirm={() => {
          this.setState({ showConfirmBox: false });
          this.changeDeliverDateAfterCheckout();
        }}
        confirmColor="PRIMARY"
      />
    );
  }

  async changeDeliverDateAfterCheckout() {
    // TODO: UPDATE for Textile Delivery Date
    const { type, textileOrderId } = this.props;
    const { selectedDeliveryDate } = this.state;
    if (selectedDeliveryDate) {
      await this.props.deliveryDatesStore.chooseDeliveryDate(
        selectedDeliveryDate,
        true,
        type,
        textileOrderId
      );
      this.props.deliveryDateSet();
    }
  }

  async chooseDeliveryDate(deliveryDate: DeliveryDateModelType) {
    // TODO: UPDATE for Textile Delivery Date
    const { type, textileOrderId } = this.props;

    await this.props.deliveryDatesStore.chooseDeliveryDate(
      deliveryDate,
      false,
      type,
      textileOrderId
    );
    this.props.deliveryDateSet();
  }

  renderDeliveryDates(deliveryDates?: DeliveryDateModelType[]) {
    if (!deliveryDates) {
      return <LoadingIndicatorComponent />;
    }

    return deliveryDates.map((deliveryDate) => {
      const availableBooks = Math.max(0, deliveryDate.available_amount);
      return (
        <CardBlock
          key={deliveryDate.id}
          as="button"
          disabled={availableBooks === 0}
          onClick={() => {
            if (this.props.checkoutFinished) {
              this.setState({
                showConfirmBox: true,
                selectedDeliveryDate: deliveryDate
              });
            } else {
              this.chooseDeliveryDate(deliveryDate);
            }
          }}
        >
          <PaddingBlock>
            <DeliveryDate deliveryDate={deliveryDate} />
          </PaddingBlock>
        </CardBlock>
      );
    });
  }

  render() {
    const { deliveryDates } = this.props.deliveryDatesStore;

    return (
      <>
        <Headline.Large>
          <FormattedMessage id="Choose delivery date" />
        </Headline.Large>

        <InputMessage warning={true}>
          <FormattedMessage id="Choose delivery date info" />
        </InputMessage>
        <FormStackBlock>
          {this.renderDeliveryDates(deliveryDates)}
        </FormStackBlock>

        {this.state.showConfirmBox && this.renderConfirmFinish()}
      </>
    );
  }
}
