import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import TextileHeaderComponent from 'components/HeaderComponent/TextileHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import SharingComponent from 'components/SharingComponent';
import Headline from 'elements/Headline';
import { useTextileOrganizerDashboardQuery } from 'queries/textile_deals/useTextileDashboardQueries';
import { FormattedMessage } from 'react-intl';
import { CardTag } from 'screens/settings/screens/AccountScreen/ManageStudentsListItem';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import isTextileLocked from 'utils/isTextileLocked';
import TextileRoleChangeComponent from './TextileRoleChangeRequest';
import TextileCalculatorBlockComponent from './cards/TextileCalculatorBlockComponent';
import TextileCheckoutBlockComponent from './cards/TextileCheckoutBlockComponent';
import TextileDesignBlockComponent from './cards/TextileDesignBlockComponent';
import TextileManagementBlockComponent from './cards/TextileManagementBlockComponent';
import TextilePreorderBlockComponent from './cards/TextilePreorderBlockComponent';

const TextileOrganizerDashboardScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const {
    data,
    isError,
    isLoading,
    refetch
  } = useTextileOrganizerDashboardQuery(textileOrderId);

  const renderPage = (content?: any) => {
    return (
      <>
        <TextileHeaderComponent
          linkHome={textileOrderRoute(props.match, '')}
          linkAccount={textileOrderRoute(props.match, '/account')}
        />

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (!textileOrderId) {
    return null;
  }

  if (isError) {
    return <GenericErrorComponent onRetryClick={() => refetch()} />;
  }

  if (isLoading || !data || !textileAppState) {
    return <LoadingIndicatorComponent />;
  }

  return renderPage(
    <>
      <ItemStackBlock gap="XXS">
        <CardTag type="organizer" />

        <PageHeader
          headline={<FormattedMessage id="textile dashboard" />}
          text={<FormattedMessage id="textile dashboard info" />}
        />
      </ItemStackBlock>

      <TextileRoleChangeComponent textileOrderId={textileOrderId} />

      <SharingComponent
        label="invite your fellow students"
        buttonOutside={false}
        modalHeader="invite link"
        message="Nuggit invite text"
        buttonColor="WHATSAPP"
        url={textileAppState.textile_order.invitation_url}
      />

      <Divider />

      <Headline.Medium>
        <FormattedMessage id="Your textile order" />
      </Headline.Medium>

      <Divider useStackBlockGap={true} />

      <TextileDesignBlockComponent
        textileOrderId={textileOrderId}
        activeDesignTicket={
          textileAppState.textile_order.has_active_design_ticket
        }
        finishedDesignTicket={
          textileAppState.textile_order.has_finished_design_ticket
        }
        layout_key={data.layout_key}
        locked={isTextileLocked(
          textileAppState.textile_order.order_state,
          'atLeastOrderStarted'
        )}
      />

      <Divider />

      <TextileManagementBlockComponent
        textileOrderId={textileOrderId}
        locked={isTextileLocked(
          textileAppState.textile_order.order_state,
          'atLeastOrderStarted'
        )}
        printDataOpen={textileAppState.textile_order.print_data_edit_open}
      />

      <Divider />

      <TextilePreorderBlockComponent
        order_state={textileAppState.textile_order.order_state}
        textileOrderId={textileOrderId}
        locked={isTextileLocked(
          textileAppState.textile_order.order_state,
          'atLeastOrderStarted'
        )}
        preorderOpen={textileAppState.textile_order.preorder_open}
      />

      <Divider />

      <TextileCalculatorBlockComponent
        textileOrderId={textileOrderId}
        locked={isTextileLocked(
          textileAppState.textile_order.order_state,
          'atLeastOrderPlaced'
        )}
      />

      <Divider />

      <TextileCheckoutBlockComponent
        textileOrderId={textileOrderId}
        order_state={textileAppState.textile_order.order_state}
        checkoutPresent={textileAppState.textile_order.checkout_present}
        isCheckoutAuthor={textileAppState.textile_profile.is_checkout_author}
        preordersAmount={data.preorders_amount}
        atLeastOrderPlaced={isTextileLocked(
          textileAppState.textile_order.order_state,
          'atLeastOrderPlaced'
        )}
        atLeastOrderStarted={isTextileLocked(
          textileAppState.textile_order.order_state,
          'atLeastOrderStarted'
        )}
      />
    </>
  );
};

export default TextileOrganizerDashboardScreen;
