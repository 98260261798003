import React from 'react';

import InputGroupBlock from 'blocks/InputGroupBlock';
import FormLabel from 'elements/FormLabel';
import InputMessage from 'elements/InputMessage';
import MultiFileUploadInput, { MultiFileUploadInputInterface } from './MultiFileUploadInput';

export const MultiFileUploadComponent = (props: MultiFileUploadInputInterface) => {
  const id = props.id ? props.id : '__formfield_' + props.name;
  return (
    <InputGroupBlock error={!!props.error}>
      {props.label && <FormLabel htmlFor={id}>{props.label}</FormLabel>}
      {props.error && <InputMessage>{props.error}</InputMessage>}
      <MultiFileUploadInput id={id} {...props} />
    </InputGroupBlock>
  );
};

export default MultiFileUploadComponent;
