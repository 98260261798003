import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Headline from 'elements/Headline';
import SettingNotAvailableComponent from '../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from './SettingsDetailProps';
import Paragraph from 'components/Paragraph/Paragraph';

const TYPES_EXCLUDED = ['unknown', 'table_of_contents', 'sponsor', 'cover'];

export default ({
  form,
  chapter,
  onCommitChapter
}: SimpleSettingsDetailProps) => {
  if (TYPES_EXCLUDED.indexOf(chapter.chapter_type) >= 0) {
    return <SettingNotAvailableComponent />;
  }

  return (
    <>
      <Headline.Large>
        <FormattedMessage id="Print settings" />
      </Headline.Large>

      <ItemStackBlock gap="L">
        <Paragraph>
          <FormattedMessage id="print settings info" />
        </Paragraph>

        <CustomCheckboxBlock>
          <CustomCheckboxBlock.CheckboxElement
            id="print_checkbox"
            {...form.bindCheckbox('print')}
          />
          <CustomCheckboxBlock.LabelElement htmlFor="print_checkbox">
            <FormattedMessage id="Chapter will be printed" />
          </CustomCheckboxBlock.LabelElement>
        </CustomCheckboxBlock>

        <ButtonBlock
          background="PRIMARY"
          onClick={() =>
            onCommitChapter({
              print: form.values.print ? true : false
            })
          }
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </ItemStackBlock>
    </>
  );
};
