import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import React from 'react';

const TrustSymbolsComponent = () => (
  <HorizontalStackBlock justifyCenter={true} gap="M">
    <img src="/images/trust-symbols/trustSymbolGoogle.png" alt="Google" />
    <img src="/images/trust-symbols/trustSymbolOrder.png" alt="Order" />
    <img src="/images/trust-symbols/trustSymbolGDPR.png" alt="GDPR" />
  </HorizontalStackBlock>
);

export default TrustSymbolsComponent;
