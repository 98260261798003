import { ProfileModelType } from 'models/ProfileModel';
import ROLES from 'utils/constants/roles';

export interface RoleConditionalProps {
  profile?: ProfileModelType;
  children?: any;
}

export const RoleConditional = (
  { profile, children }: RoleConditionalProps,
  type: keyof typeof ROLES | (keyof typeof ROLES)[]
) => {
  if (!Array.isArray(type)) {
    type = [ type ];
  }

  const roles: any = type.map(elem => ROLES[elem]);

  if (!profile?.role || roles.indexOf(profile.role) < 0) {
    return null;
  }
  return children || null;
};

export default RoleConditional;
