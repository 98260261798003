import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CardBlock from 'blocks/CardBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import MediaBlock from 'blocks/MediaBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import GroupsList from 'domain/GroupsList/GroupsList';
import BackClickLink from 'domain/Links/BackClickLink';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChapterModelType } from 'models/ChapterModel';
import {
  AllowedGroupSpecificChapter,
  ChaptersStoreType
} from 'models/ChaptersStore';
import { ChapterType } from 'models/ChapterTypeEnum';
import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { assert } from 'utils/assert';
import {
  ROUTE_CONTENT,
  ROUTE_TOC,
  ROUTE_YEARBOOKS
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import SharingComponent from 'components/SharingComponent';
import chapterPath from 'utils/chapter-path';
import ChapterTypeItem from './ChapterTypeItem';

interface CreateChapterScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  form: FormType;
}

type CandidateRankingType = 'candidate ranking';

type StageEnum =
  | 'title'
  | 'factsheet'
  | 'yearbook'
  | 'group'
  | 'ranking'
  | 'invite'
  | null;

type GroupSpecificChapterType =
  | Exclude<AllowedGroupSpecificChapter, false>
  | 'has_global';

interface CreateChapterScreenState {
  stage?: StageEnum;
  selectedType?: ChapterType | CandidateRankingType;
  factsheetType?: GroupSpecificChapterType;
  yearbookType?: GroupSpecificChapterType;
  candidateRanking?: boolean;
  inviteRoute?: string;
  didInvite?: boolean;
}

@inject('applicationStore', 'chaptersStore')
@observer
class CreateChapterScreen extends React.Component<
  CreateChapterScreenProps & HistoryProps,
  CreateChapterScreenState
> {
  state: CreateChapterScreenState = {};

  componentDidMount() {
    this.loadChapters();
  }

  async loadChapters() {
    const { chaptersStore } = this.props;

    // just to be sure
    chaptersStore.clearCurrentItem();

    // refresh book first so we know if we have groups
    await this.props.applicationStore.getBook();

    await chaptersStore.getAllChapters();
    if (!chaptersStore.isListError && !chaptersStore.allowedChapterTypes) {
      // something went very wrong
      this.props.history.replace(ROUTE_CONTENT);
    }
  }

  async createToc() {
    const { chaptersStore } = this.props;

    try {
      const chapter = await chaptersStore.createChapter({
        chapter_type: 'table_of_contents',
        title: intl.formatMessage({ id: 'chapter type table_of_contents' })
      });

      if (!chaptersStore.isItemError) {
        this.props.history.push(ROUTE_TOC + '/' + chapter?.id);
      }
    } catch (error: any) {
      handleFormError(this.props.form, error);
    }
  }

  async createChapter(chapterType?: ChapterType, title?: string) {
    const { chaptersStore, form } = this.props;
    const { selectedType, candidateRanking } = this.state;

    const finalChapterType = chapterType || selectedType;

    const chapter: any = {
      chapter_type: finalChapterType,
      title:
        title ||
        form.values.title?.toString().trim() ||
        intl.formatMessage({ id: 'Chapter' })
    };

    if (
      (finalChapterType === 'factsheet' || finalChapterType === 'yearbook') &&
      form.values.scope === 'group'
    ) {
      chapter.group_id = form.values.group_id;
    } else if (finalChapterType === 'ranking') {
      chapter.settings = {
        ranking_type: candidateRanking ? 'candidate_ranking' : 'profile_ranking'
      };
    } else if (finalChapterType === 'sponsor') {
      chapter.list_in_toc = false;
    }

    try {
      const chapterModel = await chaptersStore.createChapter(chapter);

      if (!chaptersStore.isItemError) {
        this.redirectToChapter(chapterModel);
      }
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  redirectToChapter(chapter?: ChapterModelType) {
    const {
      history,
      applicationStore: { book }
    } = this.props;

    if (chapter) {
      try {
        const path = chapterPath(chapter.chapter_type, chapter.id);

        if (!chapter.isStudentEditable || !book?.invitation_url) {
          history.push(path);
          return;
        }

        this.setState({
          stage: 'invite',
          inviteRoute: path
        });
        return;
      } catch (e) {}
    }

    history.push(ROUTE_CONTENT);
  }

  selectType(chapterType: ChapterType | 'candidate ranking') {
    switch (chapterType) {
      case 'sponsor':
        this.createChapter(chapterType, intl.formatMessage({ id: 'Sponsors' }));
        return;

      default:
    }

    this.props.form.setField(
      'title',
      intl.formatMessage({ id: 'chapter type ' + chapterType })
    );

    this.setState({
      stage: 'title',
      selectedType: chapterType
    });
  }

  selectTypeFactsheet() {
    const { book } = this.props.applicationStore;
    const { allowedChapterTypes } = this.props.chaptersStore;
    assert(book);
    assert(allowedChapterTypes);

    const type: GroupSpecificChapterType =
      allowedChapterTypes.factsheet || 'has_global';

    this.props.form.setField(
      'title',
      intl.formatMessage({ id: 'chapter type factsheet' })
    );
    this.props.form.setField('scope', type === 'group' ? 'group' : 'global');
    this.props.form.setField('group_id', undefined);

    if (type === 'any' && !book?.groups_applied) {
      // first factsheet chapter when groups are not applied yet - do not display scope selection
      this.setState({
        stage: 'title',
        selectedType: 'factsheet',
        factsheetType: 'global'
      });
      return;
    }

    this.setState({
      stage: 'factsheet',
      selectedType: 'factsheet',
      factsheetType: type
    });
  }

  selectTypeYearbook() {
    const { book } = this.props.applicationStore;
    const { allowedChapterTypes } = this.props.chaptersStore;
    assert(book);
    assert(allowedChapterTypes);

    const type: GroupSpecificChapterType =
      allowedChapterTypes.yearbook || 'has_global';

    this.props.form.setField(
      'title',
      intl.formatMessage({ id: 'chapter type yearbook' })
    );
    this.props.form.setField('scope', type === 'group' ? 'group' : 'global');
    this.props.form.setField('group_id', undefined);

    if (type === 'any' && !book?.groups_applied) {
      // first yearbook chapter when groups are not applied yet - do not display scope selection
      this.setState({
        stage: 'title',
        selectedType: 'yearbook',
        factsheetType: 'global'
      });
      return;
    }

    this.setState({
      stage: 'yearbook',
      selectedType: 'yearbook',
      yearbookType: type
    });
  }

  selectTypeRanking(candidateRanking = false) {
    this.props.form.setField(
      'title',
      intl.formatMessage({
        id: candidateRanking
          ? 'chapter type candidate ranking'
          : 'chapter type ranking'
      })
    );

    this.setState({
      stage: 'title',
      selectedType: 'ranking',
      candidateRanking
    });
  }

  renderTypeSelection() {
    const { allowedChapterTypes } = this.props.chaptersStore;
    if (!allowedChapterTypes) {
      return null;
    }

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="create chapter header" />
        </Headline.Large>

        <Paragraph color="TEXT_LIGHT">
          <FormattedMessage id="create chapter text" />
        </Paragraph>

        <ListStackBlock>
          <ChapterTypeItem
            chapterType="factsheet"
            onClick={() => this.selectTypeFactsheet()}
          />

          <ChapterTypeItem
            chapterType="teacher_factsheet"
            onClick={(t) => this.selectType(t)}
          />

          <ChapterTypeItem
            chapterType="ranking"
            onClick={() => this.selectTypeRanking(false)}
          />

          <ChapterTypeItem
            chapterType="candidate ranking"
            onClick={() => this.selectTypeRanking(true)}
          />

          <ChapterTypeItem
            chapterType="album"
            onClick={(t) => this.selectType(t)}
          />

          <ChapterTypeItem
            chapterType="quote"
            onClick={(t) => this.selectType(t)}
          />

          <ChapterTypeItem
            chapterType="text"
            onClick={(t) => this.selectType(t)}
          />

          <ChapterTypeItem
            chapterType="yearbook"
            onClick={() => this.selectTypeYearbook()}
          />

          <ChapterTypeItem
            chapterType="sponsor"
            onClick={(t) => this.selectType(t)}
          />

          {allowedChapterTypes.table_of_contents && (
            <ChapterTypeItem
              chapterType="table_of_contents"
              onClick={() => this.createToc()}
            />
          )}
        </ListStackBlock>
      </>,
      'dashboard'
    );
  }

  renderTitleForm() {
    const { form, chaptersStore } = this.props;

    const buttonDisabled = !form.values.title?.toString().trim().length;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="name chapter header" />
        </Headline.Large>

        {chaptersStore.isItemError && (
          <GenericErrorComponent onRetryClick={() => this.createChapter()} />
        )}

        <Paragraph color="TEXT_LIGHT">
          <FormattedMessage id="chapter name info" />
        </Paragraph>

        <TextInputComponent
          name="title"
          label={<FormattedMessage id="Chapter name" />}
          {...form.bindInput('title')}
          autoFocus={true}
        />

        <ButtonBlock
          background={buttonDisabled ? 'GRAY800' : 'PRIMARY'}
          disabled={buttonDisabled}
          onClick={() => this.createChapter()}
        >
          <FormattedMessage id="Add chapter" />
        </ButtonBlock>
      </>
    );
  }

  renderScopeSelection(type: GroupSpecificChapterType) {
    const { form } = this.props;

    const isGroupOnly = form.values.scope === 'group';
    const buttonDisabled = isGroupOnly && !form.values.group_id;

    return (
      <>
        {type === 'any' && (
          <FormStackBlock>
            <RadioButtonComponent
              name="scope"
              value="global"
              label={<FormattedMessage id="All students" />}
              {...form.bindRadioButton('scope', 'global')}
            />

            <RadioButtonComponent
              name="scope"
              value="group"
              label={<FormattedMessage id="Students in group" />}
              {...form.bindRadioButton('scope', 'group')}
            />
          </FormStackBlock>
        )}

        {isGroupOnly && (
          <CardBlock
            as="button"
            onClick={() => this.setState({ stage: 'group' })}
          >
            <MediaBlock>
              <PaddingBlock arrow={true}>
                <ItemStackBlock gap="XXS">
                  <Headline.Small color="GRAY10">
                    <FormattedMessage id="Group with access" />
                  </Headline.Small>

                  <Paragraph>
                    {!form.values.group_name ? (
                      <FormattedMessage id="No group selected" />
                    ) : (
                      form.values.group_name
                    )}
                  </Paragraph>
                </ItemStackBlock>
              </PaddingBlock>
            </MediaBlock>
          </CardBlock>
        )}

        <ButtonBlock
          background={buttonDisabled ? 'GRAY800' : 'PRIMARY'}
          disabled={buttonDisabled}
          onClick={() => this.setState({ stage: 'title' })}
        >
          <FormattedMessage id="Next" />
        </ButtonBlock>
      </>
    );
  }

  renderCreateFactsheet() {
    const { factsheetType } = this.state;
    assert(factsheetType);

    if (factsheetType === 'has_global') {
      return this.renderPage(
        <SupportTicketScreen
          subject="split_factsheets_required"
          onOkClick={() => this.setState({ stage: null })}
          message={intl.formatMessage({id: "zap_split_factsheets_required"})}
        />
      );
    }

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Add factsheet chapter" />
        </Headline.Large>

        <Paragraph color="TEXT_LIGHT">
          <FormattedMessage id="factsheet group selection" />
        </Paragraph>

        {this.renderScopeSelection(factsheetType)}
      </>
    );
  }

  renderCreateYearbook() {
    const { yearbookType } = this.state;
    assert(yearbookType);

    if (yearbookType === 'has_global') {
      const { firstYearbookChapter } = this.props.chaptersStore;
      assert(firstYearbookChapter);
      const splitRoute = `${ROUTE_YEARBOOKS}/${firstYearbookChapter.id}/split`;
      return this.renderPage(
        <>
          <Headline.Large>
            <FormattedMessage id="Yearbook already exists" />
          </Headline.Large>

          <Paragraph>
            <FormattedMessage id="Yearbook already exists info 1" />
          </Paragraph>
          <Paragraph>
            <FormattedMessage id="Yearbook already exists info 2" />
          </Paragraph>

          <LinkBlock to={splitRoute} background="PRIMARY" color="WHITE">
            <FormattedMessage id="chapter split button" />
          </LinkBlock>

          <ButtonBlock
            onClick={() => this.props.history.goBack()}
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
          >
            <FormattedMessage id="Back" />
          </ButtonBlock>
        </>
      );
    }

    return this.renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Add yearbook chapter" />}
          text={<FormattedMessage id="yearbook group selection" />}
        />

        {this.renderScopeSelection(yearbookType)}
      </>
    );
  }

  renderGroupSelection() {
    const { form, chaptersStore } = this.props;
    const { selectedType } = this.state;
    // Groups work for these chapter types only
    assert(selectedType === 'factsheet' || selectedType === 'yearbook');

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="chapter rights header" />
        </Headline.Large>

        <Paragraph color="TEXT_LIGHT">
          <FormattedMessage id="chapter group settings info" />
        </Paragraph>

        <GroupsList
          allowAdd={false}
          selected={form.values.group_id}
          ignoreGroupIds={chaptersStore.getChapterGroupIds(selectedType)}
          onGroupClick={(_, id, name) => {
            if (id && name) {
              form.setField('group_id', id);
              form.setField('group_name', name);
            } else {
              form.setField('group_id', undefined);
              form.setField('group_name', undefined);
            }
            this.setState({ stage: selectedType });
          }}
          noGroupsComponent={() => (
            <Paragraph>
              <FormattedMessage id="no groups for chapter" />
            </Paragraph>
          )}
        />

        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          onClick={() => this.setState({ stage: selectedType })}
        >
          <FormattedMessage id="Cancel" />
        </ButtonBlock>

        <HelpSubject subject="settings_access" />
      </>,
      selectedType
    );
  }

  renderInvite() {
    const { didInvite, inviteRoute } = this.state;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Invite students" />
        </Headline.Large>

        <ItemStackBlock gap="L">
          <Paragraph color="TEXT_LIGHT">
            <FormattedMessage id="onboarding invite text" />
          </Paragraph>

          <SharingComponent
            label="personal invite link"
            buttonOutside={true}
            modalHeader="invite link"
            message="Nuggit invite text"
            buttonColor="WHATSAPP"
            url={this.props.applicationStore.book?.invitation_url}
            onDidInteract={() => this.setState({ didInvite: true })}
          />

          <Divider />

          {inviteRoute &&
            (didInvite ? (
              <LinkBlock background="PRIMARY" color="WHITE" to={inviteRoute}>
                <FormattedMessage id="Next" />
              </LinkBlock>
            ) : (
              <LinkBlock
                background="PRIMARY_LIGHT"
                color="PRIMARY_DARK"
                to={inviteRoute}
              >
                <FormattedMessage id="Later" />
              </LinkBlock>
            ))}
        </ItemStackBlock>
      </>,
      'dashboard'
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="create chapter header" />
        </Headline.Large>

        <GenericErrorComponent
          onRetryClick={() => {
            this.loadChapters();
            this.setState({ stage: null });
          }}
        />
      </>
    );
  }

  renderPage(content: any, backTo: StageEnum | 'dashboard' = null) {
    return (
      <>
        <TopActionBlock>
          {backTo === 'dashboard' ? (
            <CancelLink to={ROUTE_CONTENT} />
          ) : (
            <BackClickLink
              onClick={() => {
                this.setState({ stage: backTo });
              }}
            />
          )}
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  }

  render() {
    const { applicationStore, chaptersStore } = this.props;

    if (
      applicationStore.isBookLoading ||
      chaptersStore.isListLoading ||
      chaptersStore.isItemLoading
    ) {
      return this.renderLoading();
    }

    // TODO handle error when toc could not be created
    if (chaptersStore.isListError) {
      return this.renderError();
    }

    const { stage } = this.state;

    if (stage === 'title') {
      return this.renderTitleForm();
    }

    if (stage === 'factsheet') {
      return this.renderCreateFactsheet();
    }

    if (stage === 'yearbook') {
      return this.renderCreateYearbook();
    }

    if (stage === 'group') {
      return this.renderGroupSelection();
    }

    if (stage === 'invite') {
      return this.renderInvite();
    }

    return this.renderTypeSelection();
  }
}

export default (props: any) => {
  const form = useForm();
  return <CreateChapterScreen {...props} form={form} />;
};
