import styled from "styled-components";
import COLORS from "utils/constants/colors";

interface SelectionContainerComponentProps {
  selected: boolean;
}

export const SelectionContainerComponent = styled.div<SelectionContainerComponentProps>`
  border: 1px solid
    ${(props) => (props.selected ? COLORS.PRIMARY : COLORS.BLACK)};

  img {
    display: block;
    width: 100%;
  }
`;