import { ROUTE_DASHBOARD } from 'utils/constants/routes';

export const chapterIdFromMatch = (match?: any): number | undefined =>
  parseInt(match?.params?.chapterId || '0', 10) || undefined;

export const backRouteFromMatch = (
  baseRoute: string,
  match?: any,
  fallbackToDashboard = true
) => {
  const id = match?.params?.chapterId;
  if (!id) {
    return fallbackToDashboard ? ROUTE_DASHBOARD : baseRoute;
  }
  return baseRoute + '/' + id;
};

export default chapterIdFromMatch;
