/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Preorder } from 'api/textile_deals/fetchPreorders';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { Textile } from 'api/textile_deals/fetchTextiles';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Center from 'elements/Center';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import { useVirtualOrderOverviewQuery } from 'queries/textile_deals/useOrderOverviewQueries';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { useRemoveTexitlePreorderQuery } from 'queries/textile_deals/useTextilePreorderQueries';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import TextileImagePreview from 'screens/preorders/components/TextileImagePreview';
import TextilePriceComponent from 'screens/preorders/components/TextilePriceComponent';
import TextileVariantCartComponent from 'screens/preorders/components/TextileVariantCartComponent';
import styled from 'styled-components';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import isTextileLocked from 'utils/isTextileLocked';

const TextilePreviewBlock = styled.div`
  width: fit-content;
`;

const PerorderVirtualOverviewScreen: React.FC<HistoryProps> = (props) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [preorderId, setPreorderId] = useState<number | undefined>(undefined);

  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const orderOverview = useVirtualOrderOverviewQuery(textileOrderId);
  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const removeTextilePreorder = useRemoveTexitlePreorderQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (textileAppState?.textile_order.order_state === 'preparing') {
      props.history.replace(textileOrderRoute(props.match, '/preorder'));
    }
    accessContext.checkSectionAccess('preorder');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileAppState?.textile_order.order_state]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/preorder')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="preorder virtual overview" />}
            text={<FormattedMessage id="preorder virtual overview info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (orderOverview.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => orderOverview.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (
    !textileAppState ||
    orderOverview.isLoading ||
    !orderOverview.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const removeVirtualOrder = (id: number) => {
    setShowConfirm(true);
    setPreorderId(id);
  }

  const performDelete = () => {
    if (!textileOrderId || !preorderId) {
      return;
    }

    removeTextilePreorder.mutate({ id: preorderId, textileOrderId });

    setShowConfirm(false);
    setPreorderId(undefined);
  };

  const abortDelete = () => {
    setShowConfirm(false);
    setPreorderId(undefined);
  };

  const possiblePrice = (): number => {
    const { preorders, textiles } = orderOverview.data;

    return preorders?.reduce((sum, itm) => sum += textiles?.find((textile) => textile.name === itm.textile_name)?.possible_price || 0, 0) || 0
  }

  const endPrice = (): number => {
    const { preorders, textiles } = orderOverview.data;

    return preorders?.reduce((sum, itm) => sum += textiles?.find((textile) => textile.name === itm.textile_name)?.end_price || 0, 0) || 0
  }

  const renderOverview = () => {
    const preorderOverview = orderOverview.data;

    const { price, preorders, textiles } = preorderOverview;

    if (!textiles || textiles.length === 0) {
      return null;
    }

    const layout = textileAppState.textile_order.theme;

    if (!layout) {
      return null;
    }

    let extra;
    if (showConfirm) {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm remove preorder" />}
          text={<FormattedMessage id="confirm remove manuell preorder info" />}
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => performDelete()}
          onAbort={() => abortDelete()}
          confirmColor="RED"
        />
      );
    }

    return renderPage(
      <>
        {extra}
        <SliderBlock noScrollBar={true}>
          {textiles.map((textile: Textile) => {
            const textileVariants = textile.textile_variants || [];
            return (
              <TextilePreviewBlock key={textile.id}>
                <ItemStackBlock gap="XXS">
                  <TextileImagePreview
                    textileVariants={textileVariants}
                    layout={layout}
                    link={textileOrderRoute(
                      props.match,
                      '/preorder/virtual/add/' + textile.id
                    )}
                  />

                  <NakedLink
                    to={textileOrderRoute(
                      props.match,
                      '/preorder/virtual/add/' + textile.id
                    )}
                  >
                    <Paragraph>
                      {textile.name}{' '}
                      <IconComponent icon="ARROW_RIGHT" fill="BLACK" />
                    </Paragraph>
                  </NakedLink>
                  {isTextileLocked(
                    textileAppState.textile_order.order_state,
                    'atLeastOrderStarted'
                  ) && textile.end_price ? (
                    <TextElement>
                      <FormattedNumber
                        value={textile.end_price}
                        style="currency"
                        currency="EUR"
                      />
                    </TextElement>
                  ) : (
                    <TextElement>
                      {textile.possible_price} - {textile.end_price} €
                    </TextElement>
                  )}
                </ItemStackBlock>
              </TextilePreviewBlock>
            );
          })}
        </SliderBlock>

        {preorders && preorders.length === 0 && (
          <>
            <Divider />

            <ItemStackBlock gap="XS">
              <Center>
                <Paragraph color="TEXT_LIGHT" size="L" weight="BOLD">
                  <FormattedMessage id="no preorder" />
                </Paragraph>
              </Center>
              <Center>
                <Paragraph color="TEXT_LIGHT" size="S">
                  <FormattedMessage id="no preorder info" />
                </Paragraph>
              </Center>
            </ItemStackBlock>
          </>
        )}

        {preorders && preorders.length > 0 && (
          <>
            <Divider />
            <Headline.Medium>
              <FormattedMessage id="all manuell preorders" />
            </Headline.Medium>
            {preorders.map((preorder: Preorder) => {
              const textile = textiles.find(
                (itm) => itm.name === preorder.textile_name
              );

              if (!textile || !textile.possible_price || !textile.end_price) {
                return null;
              }
              const variant = textile?.textile_variants?.find((itm) => itm.id === preorder.textile_variant_id)
              return (
                <TextileVariantCartComponent
                  textileVariant={variant}
                  layout={layout}
                  size={preorder.size}
                  removePreorder={(id: number) => removeVirtualOrder(id)}
                  name={preorder.name}
                  textileName={preorder.textile_name}
                  preorderId={preorder.id}
                  key={preorder.id}
                  possiblePrice={textile.possible_price}
                  endPrice={textile.end_price}
                />
              );
            })}

            <TextilePriceComponent
              endPrice={price?.price.end_price || endPrice()}
              possiblePrice={price?.price.possible_price || possiblePrice()}
              range={
                !isTextileLocked(
                  textileAppState.textile_order.order_state,
                  'atLeastOrderStarted'
                )
              }
              link={textileOrderRoute(props.match, '/discounts')}
            />
          </>
        )}
      </>
    );
  };

  return renderOverview();
};

export default PerorderVirtualOverviewScreen;
