import { types, Instance } from 'mobx-state-tree';

import { DiscountModel, createDiscountModel } from './DiscountModel';
import SchoolModel from './SchoolModel';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import createSchoolModel from 'utils/store/createSchoolModel';
import { createMapWithTransform } from 'utils/create-map';
import config from 'config';

const MINIMUM_AFFILIATE_AMOUNT = config.minimumAffiliateAmount;

export const NuggitSponsorsModel = types.model('NuggitSponsorsModel', {
  placed_count: types.number,
  placed: types.number,
  total_count: types.number,
  total: types.number
});

export type NuggitSponsorsModelType = Instance<typeof NuggitSponsorsModel>;

export const createNuggitSponsorsModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return NuggitSponsorsModel.create({
    placed_count: numberOrUndefined(data.placed_count) || 0,
    placed: numberOrUndefined(data.placed) || 0,
    total_count: numberOrUndefined(data.total_count) || 0,
    total: numberOrUndefined(data.total) || 0
  });
};

export const AffiliateBookModel = types.model('DiscountDashboardModel', {
  id: types.identifierNumber,
  reward: types.maybe(types.string),
  reward_amount: types.maybe(types.number),
  book: types.maybe(
    types.model({
      year: types.maybe(types.number),
      has_placed_order: types.maybe(types.boolean),
      school: types.maybe(SchoolModel)
    })
  )
});

export type AffiliateBookModelType = Instance<typeof AffiliateBookModel>;

const createAffiliateBookModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return AffiliateBookModel.create({
    id: mandatoryId(data.id),
    reward: data.reward || undefined,
    reward_amount: 50, // TODO fetch from server
    book: data.book
      ? {
          year: numberOrUndefined(data.book.year),
          has_placed_order: booleanOrUndefined(data.book.has_placed_order),
          school: createSchoolModel(data.book.school)
        }
      : undefined
  });
};

const DiscountDashboardModel = types
  .model('DiscountDashboardModel', {
    affiliate_token: types.maybe(types.string),
    affiliate_books: types.map(AffiliateBookModel),

    next_year_book_present: types.maybe(types.boolean),
    next_year_students_count: types.maybe(types.number),
    next_year_students_required: types.maybe(types.number),

    nuggit_sponsors: types.maybe(NuggitSponsorsModel),

    discounts: types.maybe(types.map(DiscountModel))
  })
  .views((self) => {
    return {
      get orderedAffiliateBooksCount(): number {
        if (!self.affiliate_books.size) {
          return 0;
        }

        let count = 0;
        for (const affiliate of self.affiliate_books.values()) {
          if (affiliate.book?.has_placed_order) {
            count++;
          }
        }

        return count;
      },
      get orderedAffiliateBooksSum(): number {
        if (!self.affiliate_books.size) {
          return 0;
        }

        let sum = 0;
        for (const affiliate of self.affiliate_books.values()) {
          if (affiliate.book?.has_placed_order) {
            sum = sum + (affiliate.reward_amount || 0);
          }
        }

        return sum;
      },

      get possibleAffiliateBooksCount(): number {
        return self.affiliate_books.size;
      },
      possibleAffiliateBooksSum(useMinimum = true): number {
        if (!self.affiliate_books.size) {
          return useMinimum ? MINIMUM_AFFILIATE_AMOUNT : 0;
        }

        let sum = 0;
        for (const affiliate of self.affiliate_books.values()) {
          sum = sum + (affiliate.reward_amount || 0);
        }

        return !sum ? MINIMUM_AFFILIATE_AMOUNT : sum;
      },

      get sortedAffiliateBooks(): AffiliateBookModelType[] {
        if (!self.affiliate_books.size) {
          return [];
        }

        const list: AffiliateBookModelType[] = [];
        for (const affiliate of self.affiliate_books.values()) {
          list.push(affiliate);
        }

        list.sort((a: AffiliateBookModelType, b: AffiliateBookModelType) => {
          if (a.book?.has_placed_order && !b.book?.has_placed_order) {
            return 1;
          }
          if (!a.book?.has_placed_order && b.book?.has_placed_order) {
            return -1;
          }

          const aYear = a.book?.year || 0;
          const bYear = b.book?.year || 0;
          if (aYear > bYear) {
            return 1;
          }
          if (aYear < bYear) {
            return -1;
          }

          const aName = a.book?.school?.name || '';
          const bName = b.book?.school?.name || '';
          if (aName > bName) {
            return 1;
          }
          if (aName < bName) {
            return -1;
          }

          return 0;
        });

        return list;
      }
    };
  });

export const createDiscountDashboardModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return DiscountDashboardModel.create({
    affiliate_token: data.affiliate_token || undefined,
    affiliate_books: createMapWithTransform(
      data.affiliate_books,
      createAffiliateBookModel
    ),

    next_year_book_present: booleanOrUndefined(data.next_year_book_present),
    next_year_students_count: numberOrUndefined(data.next_year_students_count),
    next_year_students_required: numberOrUndefined(
      data.next_year_students_required
    ),

    nuggit_sponsors: createNuggitSponsorsModel(data.nuggit_sponsors),

    discounts: createMapWithTransform(
      data.discounts,
      createDiscountModel,
      'identifier'
    )
  });
};

export type DiscountDashboardModelType = Instance<
  typeof DiscountDashboardModel
>;
export default DiscountDashboardModel;
