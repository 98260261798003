import { inject, observer } from 'mobx-react';
import React from 'react';

import { ApplicationStoreType } from 'models/ApplicationStore';

interface HelpHideByChildProps {
  applicationStore?: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class HelpHideByChild extends React.Component<HelpHideByChildProps> {
  componentDidMount() {
    this.props.applicationStore!.setHelpHiddenByChild(true);
  }

  componentWillUnmount() {
    this.props.applicationStore!.setHelpHiddenByChild(false);
  }

  render() {
    return null;
  }
}

export default HelpHideByChild;
