import React from 'react';

import QuotesTemplateComponent, {
  QuotesTemplateConfig
} from '../templates/QuotesTemplateComponent';
import { CommonLayoutPreviewProps } from './CommonLayoutPreviewProps';
import scalePreview from './scale-preview';

interface QuotesPreviewComponentProps extends CommonLayoutPreviewProps {
  config: QuotesTemplateConfig;

  quotes?: string[];
}

export class QuotesPreviewComponent extends React.Component<
  QuotesPreviewComponentProps
> {
  shouldComponentUpdate() {
    // preview will be forced to redraw on config update only
    return false;
  }

  generateQuotes() {
    return [
      'Wo ein Wille ist, ist auch ein Weg.',
      'Die, die nichts zu sagen haben, reden viel. Die, die was zu sagen haben, hingegen kaum.',
      'Don’t follow me, I’m lost too',
      "Ich bin perfekt wie ein Gemälde von Monet\nIch bin komplett, auch mit 'nem leeren Portmonnaie (-monnaie)\nIch bin ein krasser Motherfucker, von der Mähne bis zum Zeh\nIch bin ein Ego ist okay, ein bisschen Ego ist okay - Alligatoah & Sido",
      "I'm not your friend or anything\nDamn, you think that you're the man\nI think, therefore, I am\nI'm not your friend or anything\nDamn, you think that you're the man\nI think, therefore, I am - Billie Eilish",
      'Fitze Fitze Fatze, Fitze Fitze Fatz.\nFitze Fitze Fatze, Fitze Fitze Fatz.\nFitze Fitze Fatze, Fitze Fitze Fatz.\nFitze Fitze Fatz. - Helge Schneider',
      'Wenn du einmal Erfolg hast,\nkann es Zufall sein.\nWenn du zweimal Erfolg hast,\nkann es Glück sein.\nWenn du dreimal Erfolg hast,\nso ist es Fleiß und Tüchtigkeit.',
      'Je größer der Dachschaden desto besser der Blick auf die Sterne.',
      'I will never leave your side, my love\nStanding right beside you is enough - David Guetta',
      "Caribbean queen\nNow we're sharing the same dream\nAnd our hearts they beat as one\nNo more love on the run - Billy Ocean",
      "The midnight sky's the road I'm takin'\nHead high up in the clouds, oh-oh - Miley Cyrus"
    ];
  }

  render() {
    const {
      print,
      config,
      colors,
      pageHeaderStyle,
      sectionHeaderStyle,
      background,
      start,
      scaled,
      chapter,
      title,
      quotes,
      firstPageOnly
    } = this.props;

    return (
      <QuotesTemplateComponent
        print={print}
        firstPageOnly={firstPageOnly}
        config={config}
        colors={colors || chapter?.color_scheme?.templateColors || []}
        pageHeaderStyle={pageHeaderStyle || chapter?.font?.pageHeaderStyle}
        sectionHeaderStyle={
          sectionHeaderStyle || chapter?.font?.sectionHeaderStyle
        }
        background={
          background ||
          (chapter?.background?.id || '') +
            (chapter?.background?.extension || '') ||
          undefined
        }
        startPosition={start || 'left'}
        startPage={this.props.startPage || 1}
        render={
          !scaled ? undefined : (content, key) => scalePreview(content, key)
        }
        title={title || chapter?.title}
        quotes={quotes || this.generateQuotes()}
      />
    );
  }
}

export default QuotesPreviewComponent;
