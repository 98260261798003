import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import PlaceholderSponsorPageModel, { createPlaceholderSponsorPageModel } from 'models/PlaceholderSponsorPageModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { assert } from 'utils/assert';
import { createMapWithTransform } from 'utils/create-map';

export enum LoadingState {
  INITIAL = 'initial',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error'
}

const PlaceholderSponsorPagesStore = types
  .model('PlaceholderSponsorPagesStore', {
    // List of sponsor pages
    placeholderPages: types.maybe(types.map(PlaceholderSponsorPageModel)),
    pagesLoadingState: types.optional(
      types.enumeration(Object.values(LoadingState)),
      LoadingState.INITIAL
    ),

    // Single sponsor page
    placeholderPage: types.maybe(PlaceholderSponsorPageModel),
    pageLoadingState: types.optional(
      types.enumeration(Object.values(LoadingState)),
      LoadingState.INITIAL
    ),
  })
  .actions((self) => {
    const getPlaceholderSponsorPages = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.pagesLoadingState = LoadingState.LOADING;
        self.placeholderPages = undefined;

        const result = yield client.getPlaceholderSponsorPages();

        self.placeholderPages = createMapWithTransform(result, createPlaceholderSponsorPageModel);

        self.pagesLoadingState = LoadingState.LOADED;

        return self.placeholderPages;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'PlaceholderSponsorPagesStore | getPlaceholderSponsorPages',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.pagesLoadingState = LoadingState.INITIAL;
        } else {
          self.pagesLoadingState = LoadingState.ERROR;
        }
        throw error;
      }
    });

    const getPlaceholderSponsorPage = flow(function* (id: number) {
      const { client } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.pageLoadingState = LoadingState.LOADING;
        self.placeholderPage = undefined;

        const result: any = yield client.getPlaceholderSponsorPage(id);

        self.placeholderPage = createPlaceholderSponsorPageModel(result);

        self.pageLoadingState = LoadingState.LOADED;

        return self.placeholderPage;
      } catch (error: any) {
        handleSponsorPageError('getPlaceholderSponsorPage', error);
      }
    });

    const createPlaceholderSponsorPage = flow(function* (
      chapterId: number
    ) {
      const { client } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.pageLoadingState = LoadingState.LOADING;
        self.placeholderPage = undefined;

        const placeholderPageForSave = createPlaceholderSponsorPageModel({
          chapter_id: chapterId,
        });

        const result = yield client.createPlaceholderSponsorPage(placeholderPageForSave);

        self.placeholderPage = createPlaceholderSponsorPageModel(result);

        self.pageLoadingState = LoadingState.LOADED;

        return self.placeholderPage;
      } catch (error: any) {
        handleSponsorPageError('createPlaceholderSponsorPage', error);
      }
    });

    /**
     * Removes the given sponsor page
     */
    const removePlaceholderSponsorPage = flow(function* () {
      const { client } = getEnv<AdvancedStoreEnv>(self);
      const { placeholderPage } = self;

      assert(placeholderPage, 'No placeholder sponsor page loaded');

      try {
        self.pagesLoadingState = LoadingState.LOADING;

        yield client.removePlaceholderSponsorPage(placeholderPage.id);

        self.pageLoadingState = LoadingState.LOADED;
      } catch (error: any) {
        handleSponsorPageError('removePlaceholderSponsorPage', error);
      }
    });

    /**
     * Handles a sponsor page error
     */
    const handleSponsorPageError = (methodName: string, error: any) => {
      const { applicationStore } = getEnv<AdvancedStoreEnv>(self);

      if (process.env.NODE_ENV !== 'production') {
        console.error(`PlaceholderSponsorPagesStore | ${methodName}`, error, error.body);
      }

      if (applicationStore.handleAppError(error)) {
        self.pageLoadingState = LoadingState.INITIAL;
      } else {
        self.pageLoadingState = LoadingState.ERROR;
      }
      throw error;
    };

    return {
      getPlaceholderSponsorPages,
      getPlaceholderSponsorPage,
      createPlaceholderSponsorPage,
      removePlaceholderSponsorPage
    };
  })
  .views((self) => {
    return {
      get placeholderPagesAreLoading() {
        return self.pagesLoadingState === LoadingState.LOADING;
      },
      get placeholderPagesAreError() {
        return self.pagesLoadingState === LoadingState.ERROR;
      },
      get placeholderPageIsLoading() {
        return self.pageLoadingState === LoadingState.LOADING;
      },
      get placeholderPageIsError() {
        return self.pageLoadingState === LoadingState.ERROR;
      }
    };
  });

export type PlaceholderSponsorPagesStoreType = Instance<typeof PlaceholderSponsorPagesStore>;
export default PlaceholderSponsorPagesStore;
