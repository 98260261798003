import React, { ReactNode } from 'react';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TextElement from 'components/TextElement/TextElement';
import NakedButton from 'elements/NakedButton';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { BookPriceStoreType } from 'models/BookPriceStore';
import {
  CHECKOUT_NAVIGATION_STEPS,
  CheckoutNavigationStep,
  CheckoutStep,
  UpdateCheckoutContext
} from 'models/CheckoutModel';
import { DeliveryDatesStoreType } from 'models/DeliveryDatesStore';
import { FormattedMessage } from 'react-intl';
import { assert } from 'utils/assert';
import { ROUTE_CALCULATOR, ROUTE_ORDER } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

import PageHeader from 'components/PageHeader/PageHeader';
import { CheckoutStoreType } from '../CheckoutStore';
import AddressForm from './AddressForm';
import AgeScreen from './AgeScreen';
import CheckoutProgress from './CheckoutProgress';
import CheckoutReview from './CheckoutReview';
import ChooseDeliveryDate from './ChooseDeliveryDate';
import PrintingScreen from './PrintingScreen';

interface CheckoutScreenProps {
  applicationStore: ApplicationStoreType;
  checkoutStore: CheckoutStoreType;
  deliveryDatesStore: DeliveryDatesStoreType;
  bookPriceStore: BookPriceStoreType;
  form: FormType;
}

interface CheckoutScreenState {
  step: CheckoutNavigationStep | 'billing';
  editMode: boolean;
}

@inject(
  'applicationStore',
  'checkoutStore',
  'deliveryDatesStore',
  'bookPriceStore'
)
@observer
class CheckoutScreen extends React.Component<
  CheckoutScreenProps & HistoryProps,
  CheckoutScreenState
> {
  state: CheckoutScreenState = {
    step: CHECKOUT_NAVIGATION_STEPS[0],
    editMode: false
  };

  componentDidMount() {
    this.loadData(true);
  }

  async loadData(mayOnboard = false) {
    await this.getCheckout(mayOnboard);
  }

  async getCheckout(mayOnboard = false) {
    const { applicationStore, checkoutStore } = this.props;

    try {
      // This also gets the book
      await checkoutStore.getCheckout();
    } catch (error: any) {
      // No checkout
      this.backToCalculator();
      throw error;
    }

    const { checkout } = checkoutStore;
    const { book } = applicationStore;
    assert(book);
    assert(checkout);

    // Redirect if the book is not in the right state
    if (
      applicationStore.currentUser &&
      checkout.author &&
      applicationStore.currentUser.id !== checkout.author.id
    ) {
      // TODO Display message instead
      this.backToCalculator();
      return;
    }

    if (book.order_state !== 'open') {
      this.props.history.push(ROUTE_ORDER);
      return;
    }

    if (
      book.priceTierAtLeastGold &&
      book.editing_state !== 'previewing' &&
      book.editing_state !== 'generating'
    ) {
      this.backToCalculator();
      return;
    }

    const lastInvalidStep = checkout.last_invalid_step;
    if (lastInvalidStep === 'pricing') {
      this.backToCalculator();
      return;
    }

    if (mayOnboard && applicationStore.onboardFeature('checkout')) {
      return;
    }

    this.setState({
      step: this.normalizeStep(lastInvalidStep)
    });
    this.fillForm();
  }

  async regeneratePDF() {
    try {
      await this.props.applicationStore.generateBookPreview();
    } catch (error: any) {}
  }

  /**
   * Translates last_invalid_step to navigation steps
   */
  normalizeStep(
    lastInvalidStep: CheckoutStep | 'billing'
  ): CheckoutNavigationStep | 'billing' {
    assert(lastInvalidStep !== 'pricing');
    return lastInvalidStep === 'complete' ? 'agb' : lastInvalidStep;
  }

  backToCalculator() {
    this.props.history.replace(ROUTE_CALCULATOR, {
      redirected: true
    } as any);
  }

  fillForm() {
    const { checkoutStore, form } = this.props;
    const { checkout } = checkoutStore;
    assert(checkout);

    form.setField('shipping_name', checkout.shipping_name || '');
    form.setField('phone_number', checkout.phone_number || '');
    form.setField(
      'shipping_address_extra',
      checkout.shipping_address_extra || ''
    );
    form.setField('shipping_street', checkout.shipping_street || '');
    form.setField('shipping_housenumber', checkout.shipping_housenumber || '');
    form.setField('shipping_zip', checkout.shipping_zip || '');
    form.setField('shipping_city', checkout.shipping_city || '');

    form.setField('billing_name', checkout.billing_name || '');
    form.setField(
      'billing_address_extra',
      checkout.billing_address_extra || ''
    );
    form.setField('billing_street', checkout.billing_street || '');
    form.setField('billing_housenumber', checkout.billing_housenumber || '');
    form.setField('billing_zip', checkout.billing_zip || '');
    form.setField('billing_city', checkout.billing_city || '');
    form.setField('billing_address_set', checkout.billing_address_set);
    form.setField('accept_print', checkout.accept_print);
    form.setField('accept_agb', checkout.accept_agb);
    if (checkout.minor === undefined) {
      form.setField('minor', undefined);
    } else {
      form.setField('minor', checkout.minor ? 'true' : 'false');
    }
  }

  async updateCheckout(context: UpdateCheckoutContext) {
    const { checkoutStore, form } = this.props;
    const { checkout } = checkoutStore;
    assert(checkout);

    form.resetErrors();
    try {
      await checkoutStore.updateCheckout({
        ...form.values,
        context
      });
    } catch (error: any) {
      handleFormError(form, error);
      return;
    }

    if (this.state.editMode) {
      this.leaveEditMode();
    } else {
      this.setNextStep();
    }
  }

  setNextStep() {
    const { checkoutStore } = this.props;
    const { checkout } = checkoutStore;
    assert(checkout);

    if (checkout.last_invalid_step !== 'complete') {
      this.setState({
        step: this.normalizeStep(checkout.last_invalid_step),
        editMode: false
      });
    } else {
      this.setState({
        step: 'agb',
        editMode: false
      });
    }
  }

  stepBack() {
    if (this.state.editMode) {
      this.leaveEditMode();
    } else {
      const currentStep = this.state.step;
      if (currentStep === 'billing') {
        return null;
      }
      if (currentStep === 'address') {
        this.backToCalculator();
      } else {
        const index = CHECKOUT_NAVIGATION_STEPS.indexOf(currentStep);
        this.setState({
          step: CHECKOUT_NAVIGATION_STEPS[index - 1]
        });
      }
    }
  }

  /**
   * Leaves the edit mode and jumps to the last step.
   */
  leaveEditMode() {
    this.setState({
      step: CHECKOUT_NAVIGATION_STEPS[CHECKOUT_NAVIGATION_STEPS.length - 1],
      editMode: false
    });
  }

  async finishCheckout() {
    const { checkoutStore, history } = this.props;
    const { checkout } = checkoutStore;
    assert(checkout);

    await checkoutStore.finishCheckout();

    history.push(ROUTE_ORDER);
  }

  renderContent(content: ReactNode) {
    return (
      <>
        <TopActionBlock>
          <NakedButton onClick={() => this.stepBack()}>
            <TextElement color="GRAY10">
              <HorizontalStackBlock gap="S">
                <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />
                <FormattedMessage id="Back" />
              </HorizontalStackBlock>
            </TextElement>
          </NakedButton>
        </TopActionBlock>
        {content}
      </>
    );
  }

  renderError() {
    return this.renderContent(
      <GenericErrorComponent onRetryClick={() => this.loadData(true)} />
    );
  }

  switchToEditState(step: CheckoutStep | 'billing') {
    if (step === 'pricing') {
      this.backToCalculator();
    } else {
      this.setState({
        editMode: true,
        step: this.normalizeStep(step)
      });
    }
  }

  renderGenerating() {
    return this.renderContent(
      <PageStackBlock>
        <CheckoutProgress
          activeStep={'printing'}
          steps={CHECKOUT_NAVIGATION_STEPS}
        />

        <PageHeader
          headline={<FormattedMessage id="Checkout generating" />}
          text={<FormattedMessage id="Checkout generating info" />}
        />
        <LoadingIndicatorComponent />
        <HelpSubject subject="checkout" />
      </PageStackBlock>
    );
  }

  renderStep() {
    const {
      form,
      applicationStore,
      checkoutStore,
      bookPriceStore
    } = this.props;
    const { checkout } = checkoutStore;
    const { book } = applicationStore;

    assert(checkout);
    assert(book);

    switch (this.state.step) {
      case 'address':
        return (
          <AddressForm
            form={form}
            updateAddress={() => this.updateCheckout('address')}
          />
        );
      case 'billing':
        return (
          <AddressForm
            form={form}
            updateAddress={() => this.updateCheckout('address')}
            setBilling={true}
          />
        );
      case 'printing':
        return (
          <PrintingScreen
            form={form}
            updatePrinting={() => this.updateCheckout('printing')}
            regeneratePDF={() => this.regeneratePDF()}
            book={book}
          />
        );
      case 'age':
        return (
          <AgeScreen form={form} updateAge={() => this.updateCheckout('age')} />
        );
      case 'delivery_date':
        return (
          <ChooseDeliveryDate
            deliveryDatesStore={this.props.deliveryDatesStore}
            deliveryDateSet={() => this.getCheckout()}
            type="book"
          />
        );
      case 'agb':
        return (
          <CheckoutReview
            form={form}
            handleEdit={(step) => this.switchToEditState(step)}
            finishCheckout={() => this.finishCheckout()}
            applicationStore={applicationStore}
            checkoutStore={checkoutStore}
            bookPriceStore={bookPriceStore}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { checkoutStore, applicationStore } = this.props;
    const { checkout, getLoadingState, updateLoadingState } = checkoutStore;
    const { book } = applicationStore;

    if (getLoadingState === 'loading') {
      return this.renderContent(<LoadingIndicatorComponent />);
    }

    if (getLoadingState === 'error' || !checkout) {
      return this.renderError();
    }

    if (book?.editing_state === 'generating') {
      return this.renderGenerating();
    }

    const step = this.state.step;

    return this.renderContent(
      <PageStackBlock>
        {!this.state.editMode && step !== 'billing' && (
          <CheckoutProgress
            activeStep={step}
            steps={CHECKOUT_NAVIGATION_STEPS}
          />
        )}

        {this.renderStep()}
        {updateLoadingState === 'error' && <GenericErrorComponent />}
        <HelpSubject subject="checkout" />
      </PageStackBlock>
    );
  }
}
export default (props: any) => {
  const form = useForm();
  return <CheckoutScreen {...props} form={form} />;
};
