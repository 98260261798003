import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import { EveryCard, EveryCardPadding } from 'blocks/EveryCard/EveryCard';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { CommentsStoreType } from 'screens/comments/CommentsStore';
import {
  ROUTE_COMMENTS,
  ROUTE_FACTSHEETS,
  ROUTE_CONTENT
} from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Divider from 'components/Divider/Divider';
import Paragraph from 'components/Paragraph/Paragraph';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import TextElement from 'components/TextElement/TextElement';

interface CommentDetailScreenProps {
  commentsStore: CommentsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('commentsStore', 'applicationStore')
@observer
class CommentDetailScreen extends React.Component<
CommentDetailScreenProps & HistoryProps
> {
  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: any) {
    if (this.getId() !== idFromMatch(prevProps.match)) {
      this.load();
    }
  }

  private async load() {
    const { commentsStore, form, history } = this.props;

    if (commentsStore.isItemLoading) {
      // do not interrupt running request
      return;
    }

    const id = this.getId();

    if (!id) {
      history.replace(ROUTE_CONTENT);
      return;
    }

    const comment = await commentsStore.getComment(id);

    if (!comment) {
      history.replace(ROUTE_CONTENT);
      return;
    }

    form.reset();

    form.setField('comment', comment.comment || '');

    form.setField('profile_id', comment.profile?.id);
    form.setField(
      'profile_name',
      comment.profile?.fullName || comment.profile?.id
    );

    form.setField('author_id', comment.author?.id);
    form.setField(
      'author_name',
      comment.author?.fullName || comment.author?.id
    );
  }

  private getId() {
    return idFromMatch(this.props.match);
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  async handleSubmit() {
    const { applicationStore, commentsStore, form } = this.props;

    try {
      if (!commentsStore.item?.id) {
        return;
      }

      const comment: any = {
        comment: form.values.comment || ''
      };

      await commentsStore.updateComment(commentsStore.item.id, comment);

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'comment saved' })
      );

      this.navigateToList(false, commentsStore.item?.profile?.id);
    } catch (err) {
      handleFormError(form, err);
    }
  }

  getFromUrl(profileId?: number) {
    const { applicationStore, location } = this.props;

    if (applicationStore.isOrganizer) {
      if (!profileId) {
        return ROUTE_CONTENT;
      }

      return ROUTE_FACTSHEETS + '/detail/' + profileId + '/comments';
    }

    if (
      location.query?.from === 'factsheet' &&
      applicationStore.currentUserId
    ) {
      return (
        ROUTE_FACTSHEETS +
        '/detail/' +
        applicationStore.currentUserId +
        '/comments'
      );
    }

    return ROUTE_COMMENTS;
  }

  navigateToList(replace = false, profileId?: number) {
    (replace ? this.props.history.replace : this.props.history.push)(
      this.getFromUrl(profileId)
    );
  }

  async performDelete() {
    const { applicationStore, commentsStore } = this.props;

    const id = commentsStore.item?.id;
    if (!id) {
      return;
    }

    const profileId = commentsStore.item?.profile?.id;

    this.finishDelete();

    try {
      await commentsStore.removeComment(id);

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'comment removed' })
      );
    } catch (error: any) {
      // delete errors are handled by store internally
      return;
    }

    this.navigateToList(true, profileId);
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  renderEditForm() {
    const { commentsStore, form } = this.props;
    const { isOrganizer } = this.props.applicationStore;

    const disabled = !form.values.comment;

    return this.renderPage(
      <>
        <TextElement>
          <IconComponent icon="USER" />
          <FormattedMessage
            id="for name"
            values={{
              name: form.values.profile_name
            }}
          />
        </TextElement>

        {commentsStore.itemLoadingState === 'update_error' && (
          <GenericErrorComponent />
        )}

        <FormStackBlock>
          {isOrganizer && (
            <TextInputComponent
              name="author_name"
              label={intl.formatMessage({ id: 'comment from' })}
              value={form.values.author_name || ''}
              disabled={true}
              readOnly={true}
            />
          )}

          <TextareaComponent
            name="comment"
            label={intl.formatMessage({ id: 'Comment' })}
            placeholder={intl.formatMessage({ id: 'Enter text' })}
            height={20}
            {...form.bindInput('comment')}
            autoFocus={true}
          />
        </FormStackBlock>

        <ButtonBlock
          background={disabled ? 'GRAY800' : 'PRIMARY'}
          onClick={() => this.handleSubmit()}
          disabled={disabled}
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>

        <Divider />

        <DeleteButton onClick={() => this.confirmDelete()} />
      </>
    );
  }

  renderReadOnly(viewMode?: boolean) {
    const { item } = this.props.commentsStore;
    if (!item) {
      return null;
    }

    return this.renderPage(
      <>
        {!viewMode && <TextElement color="TEXT_LIGHT">
          <FormattedMessage
            id="comment locked info"
          />
        </TextElement>}

        <Paragraph>
          <IconComponent icon="user" />
          {item.author?.name}
        </Paragraph>

        <EveryCard>
          <EveryCardPadding>
            <Paragraph>{item.comment}</Paragraph>
          </EveryCardPadding>
        </EveryCard>


        {viewMode && (
          <>
            <Divider />
            <DeleteButton onClick={() => this.confirmDelete()} />
          </>
        )}
      </>,
      true
    );
  }

  renderPage(content: ReactNode, viewMode?: boolean) {
    const { form } = this.props;

    let extra;
    if (form.confirm) {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm delete header" />}
          text={
            <FormattedMessage
              id="confirm delete item"
              values={{
                item: <FormattedMessage id="This comment" />
              }}
            />
          }
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => this.performDelete()}
          onAbort={() => this.finishDelete()}
          confirmColor="RED"
        />
      );
    }

    return (
      <>
        <TopActionBlock>
          <CancelLink to={this.getFromUrl(this.props.form.values.profile_id)} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            {viewMode ? (
              <FormattedMessage id="Read comment" />
            ) : (
                <FormattedMessage id="Edit comment" />
              )}
          </Headline.Large>

          {content}
          {extra}
        </PageStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.load()} />
    );
  }

  render() {
    const { commentsStore, applicationStore } = this.props;

    if (commentsStore.isItemLoading) {
      return this.renderLoading();
    }

    if (commentsStore.itemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (applicationStore.isBookLocked) {
      return this.renderReadOnly();
    }

    if (
      commentsStore.item &&
      !applicationStore.isOrganizer
    ) {
      if (commentsStore.item.chapter_editing_state === 'closed') {
        return this.renderReadOnly();
      }

      if (!commentsStore.isItemOwnComment) {
        // student is viewing comment for own profile
        return this.renderReadOnly(true);
      }
    }

    return this.renderEditForm();
  }
}

export default (props: any) => {
  const form = useForm();
  return <CommentDetailScreen {...props} form={form} />;
};
