import styled from 'styled-components';

interface ProgressbarBlockProps {
  isStatic?: boolean;
}

const ProgressbarBlock = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
}) <ProgressbarBlockProps>`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  width: 100%;
  max-width: 40rem;
  height: 0.375rem;
  z-index: 10;
  ${props => props.isStatic && `
    position: static;
    transform: translateX(0);
    max-width: auto;
    height: 0.625rem;
  `};
`;

export default ProgressbarBlock;