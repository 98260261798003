import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import PasswordInputComponent from 'components/Inputs/PasswordInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import config from 'config';
import { SetupStoreType } from 'models/SetupStore';
import { ROUTE_SIGNUP } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';

interface SignupPasswordScreenProps {
  setupStore: SetupStoreType;
  form: FormType;
  token?: string;
}

const MIN_LENGTH = config.passwordMinLength;

@inject('setupStore')
@observer
class SignupPasswordScreen extends React.Component<
  SignupPasswordScreenProps & HistoryProps & WrappedComponentProps
> {
  componentDidMount() {
    const {
      setupStore,
      history: { location },
      form
    } = this.props;

    if (setupStore.isSignupDone) {
      this.props.history.replace(ROUTE_SIGNUP + '/done');
      return;
    }

    if (!setupStore.isSignupInitialized) {
      this.props.history.replace(ROUTE_SIGNUP);
      return;
    }

    form.setField(
      'password',
      setupStore.account && setupStore.account.password
        ? setupStore.account.password
        : ''
    );

    if (location.state && location.state.error) {
      form.setError('password', location.state.error);
    }
  }

  async handleSubmit() {
    const { form, intl, setupStore } = this.props;

    form.resetErrors();

    const password = form.values.password ? form.values.password.trim() : '';
    if (password.length < MIN_LENGTH) {
      form.setError(
        'password',
        intl.formatMessage({ id: 'form error short' }, { count: MIN_LENGTH })
      );
      return;
    }

    setupStore.setPassword(form.values.password);
    this.finish();
  }

  finish() {
    this.props.history.push(ROUTE_SIGNUP + '/terms');
  }

  renderPage(content?: any) {
    return (
      <>
        <SignupHeaderComponent
          backRoute={ROUTE_SIGNUP + '/number'}
          progressStatus={(100 / 10) * 8}
        />

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="Set password" />}
            text={<FormattedMessage id="signup password text" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderForm() {
    const { intl } = this.props;

    return this.renderPage(
      <>
        <PasswordInputComponent
          name="password"
          label={intl.formatMessage({ id: 'Your password' })}
          {...this.props.form.bindInput('password')}
          autoFocus={true}
          noHotjar={true}
        />

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id="Next" />
        </ButtonBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    return this.renderForm();
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  return <SignupPasswordScreen {...props} form={form} />;
});
