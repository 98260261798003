import { inject, observer } from 'mobx-react';
import React from 'react';

import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_DASHBOARD, ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface OwnFactsheetScreenProps {
  comments?: boolean;
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class OwnFactsheetScreen extends React.Component<
  OwnFactsheetScreenProps & HistoryProps
> {
  componentDidMount() {
    const { applicationStore, comments, history } = this.props;

    if (!applicationStore.currentUserId) {
      history.replace(ROUTE_DASHBOARD);
      return;
    }

    const add = comments ? '/comments' : '';
    history.replace(
      ROUTE_FACTSHEETS + '/detail/' + applicationStore.currentUserId + add
    );
  }

  render() {
    return null;
  }
}

export default OwnFactsheetScreen;
