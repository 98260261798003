import { get, patch, post, uri } from 'api/BaseApi';
import { OrderStateTextileType } from './fetchTextileAppState';
import { PrintStateTextile } from './fetchTextileCheckout';
import { TextileDeliveryDate } from './fetchTextileDeliveryDate';
import { NamesOrder } from './fetchTextileDesignSetting';
import { TextileVariant } from './fetchTextileVariants';

export interface BaseTextileOrder {
  id: number;
  name: string;
  invitation_url?: string;
  layout_key?: string;
  active_students_amount?: number;
  manager_name?: string;
  order_state: OrderStateTextileType;
}

export interface TextileOrderForGroups extends BaseTextileOrder {
  students_without_groups_count?: number;
  teachers_without_groups_count?: number;
}

export interface TextileOrderForManagement extends BaseTextileOrder {
  active_students_amount?: number;
  print_data_submitted_students_amount?: number;
  edit_print_data_deadline?: Date;
  allow_edit_print_data?: boolean;
  names_order?: NamesOrder;
  organizer_print_data_message?: string;
}

export interface TextileOrderForPreorder extends BaseTextileOrder {
  preorder_amount?: number;
  preorder_deadline?: Date;
  organizer_preorder_message?: string;
  order_variants?: TextileVariant[];
}

export interface TextileOrderForCheckout extends BaseTextileOrder {
  active_students_amount?: number;
  preorder_submitted_students_amount?: number;
  textile_delivery_date: TextileDeliveryDate;
  delivery_date_reservation_time: Date;
  print_state?: PrintStateTextile;
  order_state: OrderStateTextileType;
}

export const getAllTextileOrders = (): Promise<BaseTextileOrder[]> => {
  return get('/textile_orders');
}

export const getTextileOrderById = (
  id: number
): Promise<BaseTextileOrder> => {
  return get(
    '/textile_orders/' + uri(id)
  );
};

export const getTextileOrderForGroupsById = (
  id: number
): Promise<TextileOrderForGroups> => {
  return get(
    '/textile_orders/' + uri(id) + '?context=groups'
  );
};

export const getTextileOrderForManagementById = (
  id: number
): Promise<TextileOrderForManagement> => {
  return get(
    '/textile_orders/' + uri(id) + '?context=management'
  );
};

export const getTextileOrderForPreorderById = (
  id: number
): Promise<TextileOrderForPreorder> => {
  return get(
    '/textile_orders/' + uri(id) + '?context=preorder'
  );
};

export const getTextileOrderForCheckoutById = (
  id: number
): Promise<TextileOrderForCheckout> => {
  return get(
    '/textile_orders/' + uri(id) + '?context=checkout'
  );
};

export const requestTextileDesignTicket = (id: number):Promise<BaseTextileOrder> => {
  return post('/textile_orders/' + uri(id) + '/request_design_ticket', {});
}

export const updateTextileOrder = (data: any, textileOrderId: number): Promise<BaseTextileOrder> =>{
  return patch('/textile_orders/' + uri(textileOrderId), {
    textile_order: data
  });
}

export const startPreorder = (id: number):Promise<TextileOrderForPreorder> => {
  return get('/textile_orders/' + uri(id) + '/start_preorder');
}

export const pausePreorder = (id: number):Promise<TextileOrderForPreorder> => {
  return get('/textile_orders/' + uri(id) + '/pause_preorder');
}

export const unpausePreorder = (id: number):Promise<TextileOrderForPreorder> => {
  return get('/textile_orders/' + uri(id) + '/unpause_preorder');
}

export const generateTextilePreview = (textileOrderId: number) => {
  return post('/textile_orders/' + uri(textileOrderId)+ '/generate_preview', {});
}

export const createTextileOrder = () => {
  return post('/textile_orders', {});
}

export const createTextileSupportTicket = (subject?: string, message?: string, textileOrderId?: number) => {
  return post('/support/call_support', {
    subject,
    message,
    textile_order_id: textileOrderId
  });
}
