import { ROUTE_AUTH } from 'utils/constants/routes';

import { textileOrderIdFromMatch } from './textile-order-id-from-match';

export const textileOrderRoute = (match: any, url: string, currentId?: number): string => {
  const id = textileOrderIdFromMatch(match) || currentId;

  if (!id) {
    return ROUTE_AUTH;
  }

  return ROUTE_AUTH + "/textile_order/" + id + url;
}

