import { Instance, types } from 'mobx-state-tree';

export const BookPriceErrorModel = types.model('BookPriceErrorModel', {
  page_count_error: types.array(types.string),
  pieces_error: types.array(types.string),
  binding_type_error: types.maybe(types.string)
});

export const createBookPriceErrorModel = (
  data?: any
): BookPriceErrorModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return BookPriceErrorModel.create({
    page_count_error: data.page_count_error || [],
    pieces_error: data.pieces_error || [],
    binding_type_error: data.binding_type_error
  });
};

export type BookPriceErrorModelType = Instance<typeof BookPriceErrorModel>;
