import React from 'react';

import ScaledPagePreviewComponent from 'components/print/structure/ScaledPagePreviewComponent';
import { TemplateRenderFunc } from '../templates/CommonTemplateProps';

export const scalePreview: TemplateRenderFunc = (content, key, params) => {
  return (
    <ScaledPagePreviewComponent
      key={key}
      border={true}
      spine={params?.isSpine ? true : false}
    >
      {content}
    </ScaledPagePreviewComponent>
  );
};

export default scalePreview;
