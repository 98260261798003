import React from 'react';
import { Link } from 'react-router-dom';

import isExternalUrl from 'utils/isExternalUrl';

import SponsorBannerBlock from 'blocks/SponsorBannerBlock';

interface SponsorBannerProps {
  link: string;
  imagePath: string;
}

export const SponsorBanner = (
  { link, imagePath }: SponsorBannerProps
) => {

  if (isExternalUrl(link)) {
    return (
      <SponsorBannerBlock>
        <a href={link} target="_blank" rel="noopener">
          <img src={imagePath} alt="" />
        </a>
      </SponsorBannerBlock>
    );
  } else {
    return (
      <SponsorBannerBlock>
        <Link to={link}>
          <img src={imagePath} alt="" />
        </Link>
      </SponsorBannerBlock>
    );
  }
};

export default SponsorBanner;
