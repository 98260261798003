import { TextileDiscount } from "api/textile_deals/fetchTextileDiscounts";

export const usedDiscountsValue = (discounts?: TextileDiscount[]): number => {
  let value: number = 0;

  if (!discounts || discounts.length === 0) {
    return value;
  }

  value = discounts.reduce((sum, itm) => sum += itm.value, 0)
  return value;
}

export const discountsValue = (discounts: TextileDiscount[], amount: number): number => {
  let value: number = 0;

  if (!discounts || discounts.length === 0) {
    return value;
  }

  value = discounts.reduce((sum, itm) => {
    if (itm.identifier === 'nuggit_volume_65') {
      if (amount && (amount < 65 || amount >= 90)) {
        return sum;
      }
    }

    if (itm.identifier === 'nuggit_volume_90') {
      if (amount && amount < 90) {
        return sum;
      }
    }
    return sum += itm.value
  }, 0)
  return value;
}