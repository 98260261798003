import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import TextileOrderScreen from 'screens/order/screens/textiles/TextileOrderScreen';
import { ROUTE_TEXTILE_ORDER_OVERVIEW, ROUTE_TEXTILE_ORDER_OVERVIEW_TEXTILES, ROUTE_TEXTILE_ORDER_PLACED_OVERVIEW } from 'utils/constants/routes';

import TextileOrderOverviewScreen from './TextileOrderOverview';
import TextileOrderPlacedOverviewScreen from './TextileOrderPlacedOverview';

export default () => (
  <Switch>
    <Route
      path={ROUTE_TEXTILE_ORDER_OVERVIEW_TEXTILES}
      component={TextileOrderOverviewScreen}
    />
    <Route path={ROUTE_TEXTILE_ORDER_PLACED_OVERVIEW} component={TextileOrderPlacedOverviewScreen} exact={true} />
    <Route
      path={ROUTE_TEXTILE_ORDER_OVERVIEW}
      component={TextileOrderScreen}
      exact={true}
    />

    <NotFoundRoute />
  </Switch>
);
