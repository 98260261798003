import React from 'react';

import { CoverChapterSettingsModelType } from 'models/chapter-settings/CoverChapterSettingsModel';
import { PhotoModelType } from 'models/PhotoModel';
import { NAMES } from 'utils/constants/names';

import { PrintPicture } from '../elements/PicturePrintComponent';
import CoverTemplateComponent, {
  CoverTemplateConfig,
  CoverTemplateProps
} from '../templates/CoverTemplateComponent';
import { CommonLayoutPreviewProps } from './CommonLayoutPreviewProps';
import scalePreview from './scale-preview';

interface CoverPreviewComponentProps extends CommonLayoutPreviewProps {
  config: CoverTemplateConfig;
  display?: CoverTemplateProps['display'];
  backSponsorUrl?: string;
  header?: string;
  footer?: string;
  subtitle?: string;
  spineText?: string;
  forceNames?: boolean;
}

export class CoverPreviewComponent extends React.Component<
  CoverPreviewComponentProps
> {
  shouldComponentUpdate() {
    // preview will be forced to redraw on config update only
    return false;
  }

  generateNames(count: number) {
    if (count < 1) {
      return undefined;
    }

    return NAMES.slice(0, count);
  }

  render() {
    const {
      print,
      config,
      colors,
      scaled,
      chapter,
      display,
      backSponsorUrl,
      header,
      footer,
      subtitle,
      spineText,
      forceNames
    } = this.props;

    if (!config) {
      return null;
    }

    const settings = chapter?.settings as
      | CoverChapterSettingsModelType
      | undefined;

    let backPicture: PrintPicture | undefined;
    let backType: CoverTemplateProps['backType'] = 'default';
    switch (settings?.back_design) {
      case 'creative':
        backType = 'picture';
        // Find back cover creative photo
        const photo = (settings.photos as PhotoModelType[]).find(
          (candidate) => candidate.photo_type === 'cover_back_creative'
        );
        const url = photo?.photo?.preview;
        if (url) {
          backPicture = { url, fit: !!photo!.fitToFrame };
        }
        break;

      case 'sponsor':
        backType = 'sponsor';
        break;

      default:
    }

    return (
      <>
        <CoverTemplateComponent
          print={print}
          hardcover={false}
          showJoint={false}
          config={config}
          colors={colors || []}
          startPosition="left"
          startPage={0}
          render={
            scaled
              ? (content, key, params) => scalePreview(content, key, params)
              : undefined
          }
          display={display || ['front', 'spine', 'back']}
          spineInnerWidth={80}
          header={settings?.front_header || header}
          footer={settings?.front_footer || footer}
          subtitle={settings?.sub_title || subtitle}
          spineText={settings?.spine_text || spineText}
          backType={backType}
          backPicture={backPicture}
          backSponsorUrl={backSponsorUrl}
          names={
            settings?.back_design === 'names' || forceNames
              ? this.generateNames(56)
              : undefined
          }
          namesHeader={settings?.back_header}
        />
      </>
    );
  }
}

export default CoverPreviewComponent;
