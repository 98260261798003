import { Instance, types } from 'mobx-state-tree';

// ClientStateModel.identifier may be any string [a-z0-9_]{1,50} but we use this enum as a helper to avoid typos etc.
export enum ClientStates {
  group_select_dismissed = 'group_select_dismissed',
  teams_selected = 'teams_selected',

  ob_dashboard_student = 'ob_dashboard_student',
  ob_dashboard_organizer = 'ob_dashboard_organizer',
  ob_class = 'ob_class',
  ob_groups = 'ob_groups',
  ob_content = 'ob_content',
  ob_design = 'ob_design',
  ob_motto = 'ob_motto',
  ob_pdfpreview = 'ob_pdfpreview',
  ob_print = 'ob_print',
  ob_checkout = 'ob_checkout',
  // ob_timeplan = 'ob_timeplan',
  ob_chaptersettings = 'ob_chaptersettings',
  ob_rankings = 'ob_rankings',
  ob_quotes = 'ob_quotes',
  ob_texts = 'ob_texts',
  ob_toc = 'ob_toc',
  ob_albums = 'ob_albums',
  ob_albumpages = 'ob_albumpages',
  ob_sponsors = 'ob_sponsors',
  ob_sponsorpages = 'ob_sponsorpages',
  ob_factsheets = 'ob_factsheets',
  ob_photoeditor = 'ob_photoeditor',

  inquired_textiles = 'inquired_textiles',
  inquired_travel = 'inquired_travel',

  join_book_role_select = 'join_book_role_select',
}

const ClientStateModel = types.model('ClientStateModel', {
  identifier: types.identifier,
  value: types.maybe(types.string),
  created_at: types.maybe(types.string),
  modified_at: types.maybe(types.string)
});

export const createClientStateModel = (data?: any) => {
  if (!data?.identifier) {
    return undefined;
  }

  return ClientStateModel.create({
    identifier: data.identifier,
    value: data.value || undefined,
    created_at: data.created_at || undefined,
    modified_at: data.modified_at || undefined
  });
};

export type ClientStateModelType = Instance<typeof ClientStateModel>;
export default ClientStateModel;
