import { intl } from 'i18n';

export const convertDonation = (donation?: string): number | null => {
  if (!donation) {
    return 0;
  }

  // const filtered = donation.replace(/[^0-9.,]/g, '');
  // if (filtered === '') {
  //   return 0;
  // }

  if (!donation.match(/^[0-9]*(\.|,)?[0-9]*$/)) {
    return null;
  }

  return parseFloat(donation.replace(',', '.'));
};

export const formatDonation = (donation?: number) =>
  intl.formatNumber(donation || 0, {
    useGrouping: false,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
