import { flow, getEnv, Instance, types } from 'mobx-state-tree';

import ContentNotPrintedModel from './ContentNotPrintedModel';
import { AdvancedStoreEnv } from './StoreEnv';

const ContentNotPrintedStore = types
  .model('ContentNotPrintedStore', {
    loadingState: types.maybe(types.enumeration(['loading', 'error'])),
    contentNotPrinted: types.maybe(ContentNotPrintedModel)
  })
  .actions((self) => {
    const getContentNotPrinted = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      self.loadingState = 'loading';

      try {
        const contentNotPrinted = yield client.getContentNotPrinted();

        self.contentNotPrinted = contentNotPrinted;

        self.loadingState = undefined;

        return self.contentNotPrinted;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ContentNotPrintedStore | getContentNotPrinted',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        self.loadingState = 'error';

        throw error;
      }
    });

    return { getContentNotPrinted };
  });

export type ContentNotPrintedStoreType = Instance<
  typeof ContentNotPrintedStore
>;

export default ContentNotPrintedStore;
