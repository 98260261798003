import style from 'styled-components';

import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

interface ChipProps {
  color?: keyof typeof COLORS;
  strong?: boolean;
  hasInlineLink?: boolean;
}

export default style.span<ChipProps>`
  margin-top: 0.1875rem;
  margin-left: 0.5rem;
  border: 1px solid ${COLORS.GRAY800};
  border-radius: 0.125rem;
  padding: 0 0.1875rem;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  font-family: ${FONTS.DEFAULT};
  font-weight: 400;
  color: ${COLORS.TEXT_LIGHT};
`;
