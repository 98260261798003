import React, { useContext, useState } from 'react';
import {
  FormattedMessage
} from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { BaseTextileProfile } from 'api/textile_deals/fetchTextileProfiles';
import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import LogoutButton from 'components/LogoutButton/LogoutButton';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import NakedBlockButton from 'elements/NakedBlockButton';
import NakedLink from 'elements/NakedLink';
import { intl } from 'i18n';
import { useCurrentTextileProfileQuery } from 'queries/textile_deals/useTextileProfileQueries';
import { useCreateTextileRoleChangeQuery } from 'queries/textile_deals/useTextileRoleChangeRequestQueries';
import {
  ROUTE_ACCOUNT_CHANGE_PASSWORD,
  ROUTE_ACCOUNT_MOBILE_NUMBER,
  ROUTE_AUTH
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const TextileAccountScreen: React.FC<HistoryProps> = (props) => {
  const flashMessageContext = useContext(FlashMessageContext);
  const [confirmRoleChange, setConfirmRoleChange] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  // Query with current profile
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const { data, isLoading, isError, refetch } = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );
  const roleChangeRequest = useCreateTextileRoleChangeQuery();


  if (!flashMessageContext) {
    throw Error('Component must be used within a FlashMessageProvider');
  }

  if (isError) {
    return <GenericErrorComponent onRetryClick={() => refetch()}/>
  }

  if (isLoading || !data || !textileAppState) {
    return <LoadingIndicatorComponent />
  }

  const requestOrganizerRole = () => {
    if (!data) {
      return;
    }

    const currentTextileProfile: BaseTextileProfile = data;

    if (!textileOrderId || !currentTextileProfile) {
      return;
    }

    setLoadingState(true);
    setConfirmRoleChange(false);

    roleChangeRequest.mutate(
      { textileOrderId: textileOrderId, requestType: 'student_to_organizer' },
      {
        onSuccess: () => {
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'role change requested flash' }),
            'success'
          );
        },
        onError: () => {
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'role change request error flash' }),
            'error'
          );
        },
        onSettled: () => {
          setLoadingState(false)
          refetch()
        }
      }
    );
  };

  const renderPage = (content: any) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="My account" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  };

  return renderPage(
    <>
      <ListStackBlock>
        <ButtonListBlock>
          {data.role === 'student' &&
            !data.has_role_change_request &&
            !loadingState && (
              <ButtonListBlockItem>
                <NakedBlockButton
                  onClick={() => setConfirmRoleChange(true)}
                >
                  <ButtonListBlockBody>
                    <FormattedMessage id="request organizer role" />
                  </ButtonListBlockBody>
                </NakedBlockButton>
              </ButtonListBlockItem>
            )}

          <ButtonListBlockItem>
            <NakedLink to={ROUTE_ACCOUNT_MOBILE_NUMBER}>
              <ButtonListBlockBody>
                <FormattedMessage id="Edit mobile number" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>

          <ButtonListBlockItem>
            <NakedLink to={ROUTE_ACCOUNT_CHANGE_PASSWORD}>
              <ButtonListBlockBody>
                <FormattedMessage id="Change password" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>

          <ButtonListBlockItem>
            <NakedLink to={ROUTE_AUTH + '/account'}>
              <ButtonListBlockBody>
                <FormattedMessage id="Account dashboard" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>
        </ButtonListBlock>
      </ListStackBlock>

      <ItemStackBlock gap="XS" marginTop="XL">
        <LogoutButton />
      </ItemStackBlock>

      {confirmRoleChange && (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm role change request header" />}
          text={<FormattedMessage id="confirm role change request" />}
          confirmColor="GREEN"
          confirmText={
            <FormattedMessage id="send confirm role change request" />
          }
          onConfirm={() => requestOrganizerRole()}
          abortText={<FormattedMessage id="Cancel" />}
          onAbort={() => setConfirmRoleChange(false)}
        />
      )}
      {loadingState && <LoadingOverlayComponent />}
    </>
  );
};

export default TextileAccountScreen;
