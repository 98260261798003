import React from 'react';

import { Textile } from 'api/textile_deals/fetchTextiles';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import Divider from 'components/Divider/Divider';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import { FormattedMessage } from 'react-intl';

const TextileInfo = ({
  textile,
}: {
  textile: Textile
}) => {
  if (!textile) {
    return null;
  }

  let materialText;
  let grammage;

  switch(textile.internal_identifier){
    case "deluxeHoodie":
      materialText = "85% gekämmte Bio-Baumwolle, 15% recyceltes Polyester";
      grammage = "350 GSM";
      break;
    case "organicHoodie":
      materialText = "85% gekämmte Bio-Baumwolle, 15% recyceltes Polyester";
      grammage = "280 GSM";
      break;
    case "organicShirt":
      materialText = "100% gekämmte Bio-Baumwolle";
      grammage = "150 GSM";
      break;
  }

  return (
    <>
      <Headline.Medium>
        <FormattedMessage id="textile info header" />
      </Headline.Medium>

      <ListStackBlock>

        <Paragraph>
          <FormattedMessage id="Details" />
        </Paragraph>
        <Divider useStackBlockGap={true} color="BLACK" />

        <HorizontalStackBlock justified={true} gap='XS' noMarginRight={true}>
          <Paragraph noBreak={true}>
            <FormattedMessage id="Material" />
          </Paragraph>
          <Paragraph textAlign='right'>
            {materialText}
          </Paragraph>
        </HorizontalStackBlock>
        <Divider useStackBlockGap={true}/>

        <HorizontalStackBlock justified={true} gap='XS' noMarginRight={true}>
          <Paragraph>
            <FormattedMessage id="Grammage" />
          </Paragraph>
          <Paragraph>
            {grammage}
          </Paragraph>
        </HorizontalStackBlock>
        <Divider useStackBlockGap={true}/>
      </ListStackBlock>

    </>
  );
};

export default TextileInfo;
