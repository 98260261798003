import { useQuery } from "@tanstack/react-query";
import { getTextileOrganizerDashboard, getTextileStudentDashboard } from "api/textile_deals/fetchTextileDashboards";

export const useTextileOrganizerDashboardQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_organizer_dashboard', textileOrderId],
    queryFn: () => getTextileOrganizerDashboard(textileOrderId!),
    enabled: !!textileOrderId
  })
}

export const useTextileStudentDashboardQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_student_dashboard', textileOrderId],
    queryFn: () => getTextileStudentDashboard(textileOrderId!),
    enabled: !!textileOrderId
  })
}