import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

import LabelElement from './LabelElement';

interface RadioElementProps {
  error?: boolean;
}

export default styled.input.attrs({
  type: 'radio'
})<RadioElementProps>`
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  margin: -1px;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;

  /* stylelint-disable selector-type-no-unknown */
  &:checked + ${LabelElement}::before {
    border-color: ${COLORS.PRIMARY};
    background-color: ${COLORS.PRIMARY};
  }

  &:checked + ${LabelElement}::after {
    border-color: ${COLORS.PRIMARY};
  }

  &:disabled + ${LabelElement} {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: ${COLORS.GRAY950};
  }

  ${(props) =>
    props.error &&
    `
    /* stylelint-disable selector-type-no-unknown */
    
    &:checked + ${LabelElement}::before{
      border-color: ${COLORS.RED};
      background-color: ${COLORS.RED};
    }

    &:checked + ${LabelElement}::after{
      border-color: ${COLORS.RED};
    }
  `}
`;
