export const ICONS_VERSION = '1701073650811';

export type IconNameType =
  | 'affinity'
  | 'arrow_double_right'
  | 'arrow_left'
  | 'arrow_right'
  | 'arrow_rotate'
  | 'arrow_stroke'
  | 'account'
  | 'basket'
  | 'block'
  | 'books'
  | 'brush'
  | 'calculator'
  | 'calendar'
  | 'chapter_album'
  | 'chapter_creative'
  | 'chapter_factsheets'
  | 'chapter_quotes'
  | 'chapter_rankings'
  | 'chapter_text'
  | 'chapter_toc'
  | 'chapter_yearbook'
  | 'clock_circle'
  | 'cocktail'
  | 'comments'
  | 'content'
  | 'copy'
  | 'cross'
  | 'cross_circle'
  | 'download'
  | 'drag'
  | 'drink'
  | 'edit'
  | 'empty_state'
  | 'filter'
  | 'frame'
  | 'hands'
  | 'hoody'
  | 'hourglass'
  | 'indesign'
  | 'journey'
  | 'lightbulb'
  | 'loader'
  | 'lock'
  | 'lock_outline'
  | 'magnify'
  | 'make_hidden'
  | 'make_visible'
  | 'money'
  | 'motto'
  | 'multiple_students'
  | 'nuggit_logo'
  | 'number_1'
  | 'number_2'
  | 'number_3'
  | 'order'
  | 'organizer'
  | 'pen'
  | 'pen_ruler'
  | 'preorder'
  | 'questionmark'
  | 'questionmark_circle'
  | 'settings'
  | 'sort_h'
  | 'sort_v'
  | 'speechbubble'
  | 'tick'
  | 'tick_circle'
  | 'trash'
  | 'tshirt'
  | 'tshirt_and_hoody'
  | 'upload'
  | 'user'
  | 'usergroup'
  | 'usergroup_huge'
  | 'usergroup_large'
  | 'user_circle'
  | 'warning'
  | 'warning_circle'
  | 'warning_triangle'
  | 'arrow-down'
  | 'arrow-up'
  | 'box'
  | 'dot'
  | 'drawing-utensils'
  | 'info_circle'
  | 'pause'
  | 'plus';

export type UppercaseIconNameType =
  | 'AFFINITY'
  | 'ARROW_DOUBLE_RIGHT'
  | 'ARROW_LEFT'
  | 'ARROW_RIGHT'
  | 'ARROW_ROTATE'
  | 'ARROW_STROKE'
  | 'ACCOUNT'
  | 'BASKET'
  | 'BLOCK'
  | 'BOOKS'
  | 'BRUSH'
  | 'CALCULATOR'
  | 'CALENDAR'
  | 'CHAPTER_ALBUM'
  | 'CHAPTER_CREATIVE'
  | 'CHAPTER_FACTSHEETS'
  | 'CHAPTER_QUOTES'
  | 'CHAPTER_RANKINGS'
  | 'CHAPTER_TEXT'
  | 'CHAPTER_TOC'
  | 'CHAPTER_YEARBOOK'
  | 'CLOCK_CIRCLE'
  | 'COCKTAIL'
  | 'COMMENTS'
  | 'CONTENT'
  | 'COPY'
  | 'CROSS'
  | 'CROSS_CIRCLE'
  | 'DOWNLOAD'
  | 'DRAG'
  | 'DRINK'
  | 'EDIT'
  | 'EMPTY_STATE'
  | 'FILTER'
  | 'FRAME'
  | 'HANDS'
  | 'HOODY'
  | 'HOURGLASS'
  | 'INDESIGN'
  | 'JOURNEY'
  | 'LIGHTBULB'
  | 'LOADER'
  | 'LOCK'
  | 'LOCK_OUTLINE'
  | 'MAGNIFY'
  | 'MAKE_HIDDEN'
  | 'MAKE_VISIBLE'
  | 'MONEY'
  | 'MOTTO'
  | 'MULTIPLE_STUDENTS'
  | 'NUGGIT_LOGO'
  | 'NUMBER_1'
  | 'NUMBER_2'
  | 'NUMBER_3'
  | 'ORDER'
  | 'ORGANIZER'
  | 'PEN'
  | 'PEN_RULER'
  | 'PREORDER'
  | 'QUESTIONMARK'
  | 'QUESTIONMARK_CIRCLE'
  | 'SETTINGS'
  | 'SORT_H'
  | 'SORT_V'
  | 'SPEECHBUBBLE'
  | 'TICK'
  | 'TICK_CIRCLE'
  | 'TRASH'
  | 'TSHIRT'
  | 'TSHIRT_AND_HOODY'
  | 'UPLOAD'
  | 'USER'
  | 'USERGROUP'
  | 'USERGROUP_HUGE'
  | 'USERGROUP_LARGE'
  | 'USER_CIRCLE'
  | 'WARNING'
  | 'WARNING_CIRCLE'
  | 'WARNING_TRIANGLE'
  | 'ARROW-DOWN'
  | 'ARROW-UP'
  | 'BOX'
  | 'DOT'
  | 'DRAWING-UTENSILS'
  | 'INFO_CIRCLE'
  | 'PAUSE'
  | 'PLUS';

export const ICON_SIZES: { [key in IconNameType]: [number, number] } = {
  'affinity': [48, 48],
  'arrow_double_right': [12, 10],
  'arrow_left': [32, 100],
  'arrow_right': [32, 100],
  'arrow_rotate': [93, 100],
  'arrow_stroke': [6, 10],
  'account': [17, 17],
  'basket': [24, 23],
  'block': [90, 100],
  'books': [96, 100],
  'brush': [60, 100],
  'calculator': [29, 29],
  'calendar': [87, 100],
  'chapter_album': [92, 100],
  'chapter_creative': [84, 100],
  'chapter_factsheets': [64, 100],
  'chapter_quotes': [93, 100],
  'chapter_rankings': [82, 100],
  'chapter_text': [81, 100],
  'chapter_toc': [79, 100],
  'chapter_yearbook': [79, 100],
  'clock_circle': [85, 100],
  'cocktail': [37, 46],
  'comments': [103, 100],
  'content': [75, 100],
  'copy': [92, 100],
  'cross': [79, 100],
  'cross_circle': [85, 100],
  'download': [84, 100],
  'drag': [103, 100],
  'drink': [23, 28],
  'edit': [20, 18],
  'empty_state': [59, 56],
  'filter': [96, 100],
  'frame': [89, 100],
  'hands': [103, 100],
  'hoody': [94, 100],
  'hourglass': [85, 100],
  'indesign': [45, 46],
  'journey': [89, 100],
  'lightbulb': [82, 100],
  'loader': [92, 100],
  'lock': [66, 100],
  'lock_outline': [80, 100],
  'magnify': [87, 100],
  'make_hidden': [122, 100],
  'make_visible': [122, 100],
  'money': [96, 100],
  'motto': [108, 100],
  'multiple_students': [29, 29],
  'nuggit_logo': [45, 46],
  'number_1': [39, 100],
  'number_2': [60, 100],
  'number_3': [60, 100],
  'order': [29, 29],
  'organizer': [62, 100],
  'pen': [82, 100],
  'pen_ruler': [91, 100],
  'preorder': [29, 29],
  'questionmark': [29, 46],
  'questionmark_circle': [85, 100],
  'settings': [85, 100],
  'sort_h': [87, 100],
  'sort_v': [87, 100],
  'speechbubble': [100, 100],
  'tick': [97, 100],
  'tick_circle': [85, 100],
  'trash': [76, 100],
  'tshirt': [47, 43],
  'tshirt_and_hoody': [71, 46],
  'upload': [84, 100],
  'user': [62, 100],
  'usergroup': [88, 100],
  'usergroup_huge': [61, 31],
  'usergroup_large': [51, 31],
  'user_circle': [21, 22],
  'warning': [19, 100],
  'warning_circle': [85, 100],
  'warning_triangle': [93, 100],
  'arrow-down': [24, 24],
  'arrow-up': [24, 24],
  'box': [22, 24],
  'dot': [18, 18],
  'drawing-utensils': [36, 60],
  'info_circle': [18, 18],
  'pause': [15, 16],
  'plus': [100, 100]
};
