import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import { useTextileGroupsQuery } from 'queries/textile_deals/useTextileGroupsQueries';
import { useCreateTextileName } from 'queries/textile_deals/useTextileNamesQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import TextileNameForm from './TextileNameForm';

interface FormProps {
  form: FormType;
}

const AddVirtualTextileName: React.FC<HistoryProps & FormProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const accessContext = useContext(HasAccessContext);
  const textileGroups = useTextileGroupsQuery(textileOrderId);
  const createName = useCreateTextileName();

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    const data = textileGroups.data;
    const textileOrder = textileAppState?.textile_order;

    if (data && textileOrder) {
      props.form.setField('first_name', '');
      if (textileOrder.show_groups) {
        props.form.setField('textile_group_id', data[0]?.id.toString());
      }
    }

    accessContext.checkSectionAccess('management');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileGroups.data, textileAppState?.textile_order]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/management')} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileGroups.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileGroups.refetch()} />
    );
  }

  if (
    !textileAppState ||
    props.form.loading ||
    textileGroups.isLoading ||
    !textileGroups.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const save = () => {
    const { form } = props;
    if (!textileOrderId) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    const newName = { ...form.values, role: 'student' };

    createName.mutate(
      { textileOrderId, data: newName },
      {
        onSuccess: () => {
          props.history.push(textileOrderRoute(props.match, '/management'));
        },
        onError: (error: any) => {
          form.setLoading(false);
          if (!handleFormError(form, error)) {
            form.setError('base', true);
          }
        }
      }
    );
  };

  const renderForm = () => {
    const { form } = props;

    const groups = textileGroups.data;
    const showGroups = textileAppState.textile_order.show_groups;
    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="textile virtual student" />}
          text={<FormattedMessage id="textile virtual student edit info" />}
        />

        <TextileNameForm
          form={form}
          onSave={() => save()}
          textileGroups={groups}
          showGroups={showGroups}
        />
      </>
    );
  };

  return renderForm();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <AddVirtualTextileName {...props} form={form} />;
};
