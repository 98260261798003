import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import FooterWrapper from 'blocks/FooterBlock/FooterWrapper';
import MessageBlock from 'blocks/MessageBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import CopyTextInput from 'components/Inputs/CopyTextInput';
import PasswordInputComponent from 'components/Inputs/PasswordInputComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import SuccessContainerComponent from 'components/SuccessContainerComponent';
import config from 'config';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_HOME } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import ItemStackBlock from 'blocks/ItemStackBlock';

interface LoginScreenProps {
  applicationStore: ApplicationStoreType;
  form: FormType;
}

// TODO Move logic to LoginForm component?
@inject('applicationStore')
@observer
class LoginScreen extends React.Component<
  LoginScreenProps & HistoryProps & WrappedComponentProps
> {
  async componentDidMount() {
    const { applicationStore } = this.props;

    applicationStore.resetAuthState();
    await applicationStore.checkAuthenticated(false, true);
  }

  async handleSubmit(event: React.SyntheticEvent, submittedPassword: boolean) {
    event.preventDefault();

    const { applicationStore, form, intl } = this.props;

    form.resetErrors();

    let password: string | undefined;
    if (submittedPassword) {
      if (!form.values.password) {
        form.setError(
          'password',
          intl.formatMessage({ id: 'form error mandatory' })
        );
        return;
      } else {
        password = form.values.password;
      }
    }

    try {
      await applicationStore.login(form.values.mobile_number || '', password);
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  resendLink(viaParam = false) {
    let mobileNumber;

    if (viaParam) {
      const {
        history: { location }
      } = this.props;

      if (!location.state || !location.state.mobile_number) {
        return;
      }

      mobileNumber = location.state.mobile_number;
    }

    this.resetPassword(mobileNumber);
  }

  async resetPassword(mobileNumber?: string) {
    const { applicationStore, form } = this.props;

    if (!mobileNumber) {
      if (
        !applicationStore.authState ||
        !applicationStore.authState.mobileNumber
      ) {
        return;
      }

      mobileNumber = applicationStore.authState.mobileNumber;
    }

    try {
      await applicationStore.initiatePasswordReset(mobileNumber);
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  resetNumber() {
    this.props.form.reset();
    this.props.applicationStore.resetAuthState();
  }

  renderPage(content?: any) {
    return (
      <FooterWrapper>
        <SignupHeaderComponent logoRoute={ROUTE_HOME} />

        <PageStackBlock>{content}</PageStackBlock>
      </FooterWrapper>
    );
  }

  renderRequested() {
    const { applicationStore } = this.props;
    const { authState } = applicationStore;

    const password = authState && authState.tokenRequested === 'password';
    return (
      <>
        <SignupHeaderComponent logoRoute={ROUTE_HOME} />

        <SuccessContainerComponent>
          <PageHeader
            headline={<FormattedMessage id="support token requested header" />}
            text={
              <FormattedMessage
                id={
                  password
                    ? 'support password token requested text'
                    : 'support token requested text'
                }
              />
            }
          />
        </SuccessContainerComponent>

        {config.env.devOrStaging && applicationStore.hint && (
          <CopyTextInput name="login-hint" value={applicationStore.hint} />
        )}
      </>
    );
  }

  getAuthParam() {
    const {
      history: { location }
    } = this.props;

    return location.query ? location.query.auth : null;
  }

  renderMessage() {
    // TODO Do not render if form has been submitted?

    const { applicationStore } = this.props;

    if (applicationStore.isLoginError) {
      return null;
    }

    const param = this.getAuthParam();
    if (!param) {
      return null;
    }

    switch (param) {
      case 'unauthorized':
        return (
          <MessageBlock warning={true} static={true}>
            <Paragraph>
              <FormattedMessage id="login unauthorized" />
            </Paragraph>
          </MessageBlock>
        );

      case 'failed':
        return (
          <MessageBlock error={true} static={true}>
            <Paragraph>
              <FormattedMessage id="login failed" />
            </Paragraph>
          </MessageBlock>
        );

      case 'logout':
        return (
          <MessageBlock success={true} static={true}>
            <Paragraph>
              <FormattedMessage id="login logout" />
            </Paragraph>
          </MessageBlock>
        );

      case 'error':
        return <GenericErrorComponent />;
    }
  }

  renderNumberForm() {
    const { applicationStore, form, intl } = this.props;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Welcome back" />
        </Headline.Large>

        <form onSubmit={(event) => this.handleSubmit(event, false)}>
          <ItemStackBlock gap="L">
            {this.renderMessage()}
            {applicationStore.isLoginError && <GenericErrorComponent />}

            <TextInputComponent
              type="tel"
              name="mobile_number"
              label={intl.formatMessage({ id: 'Your number' })}
              placeholder={intl.formatMessage({
                id: 'phone number placeholder short'
              })}
              {...form.bindInput('mobile_number')}
              autoFocus={true}
              noHotjar={true}
              autoComplete="tel"
            />

            <ButtonBlock type="submit" background="PRIMARY">
              <FormattedMessage id="Next" />
            </ButtonBlock>
          </ItemStackBlock>
        </form>

        <Divider />

        <ItemStackBlock gap="S">
          <Paragraph size="L" color="TEXT_LIGHT">
            <FormattedMessage id="No account yet?" />
          </Paragraph>
          <Link to="/signup">
            <ButtonBlock color="PRIMARY_DARK" background="PRIMARY_LIGHT">
              <FormattedMessage id="Create account" />
            </ButtonBlock>
          </Link>
        </ItemStackBlock>
      </>
    );
  }

  renderPasswordForm() {
    const { applicationStore, form, intl } = this.props;
    const { authState } = applicationStore;

    return this.renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Enter password" />}
          text={<FormattedMessage id="Your number" />}
        />

        {applicationStore.isLoginError && <GenericErrorComponent />}

        <Paragraph>
          {authState && authState.mobileNumber
            ? authState.mobileNumber
            : form.values.mobile_number}
          {' - '}
          <ButtonBlock
            link={true}
            nowrap={true}
            background="WHITE"
            color="PRIMARY"
            onClick={() => this.resetNumber()}
          >
            <Paragraph>
              <FormattedMessage id="Not your number?" />
            </Paragraph>
          </ButtonBlock>
        </Paragraph>

        <form onSubmit={(event) => this.handleSubmit(event, true)}>
          <ItemStackBlock gap="L">
            <PasswordInputComponent
              name="password"
              label={intl.formatMessage({ id: 'Your password' })}
              {...form.bindInput('password')}
              autoFocus={true}
              noHotjar={true}
              autoComplete="current-password"
            />

            <ButtonBlock type="submit" background="PRIMARY">
              <FormattedMessage id="Login(verb)" />
            </ButtonBlock>
          </ItemStackBlock>
        </form>

        <Divider />

        <ItemStackBlock gap="S">
          <Paragraph size="L" color="TEXT_LIGHT">
            <FormattedMessage id="Forgot your password?" />
          </Paragraph>
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => this.resetPassword()}
          >
            <FormattedMessage id="Reset password" />
          </ButtonBlock>
        </ItemStackBlock>
      </>
    );
  }

  renderUnconfirmed(viaParam = false) {
    let allowResend = true;
    if (viaParam) {
      const {
        history: { location }
      } = this.props;

      allowResend =
        location.state && location.state.mobile_number ? true : false;
    }

    return this.renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="verify number header" />}
          text={<FormattedMessage id="verify number text" />}
        />

        {allowResend && (
          <>
            <Divider />

            <ItemStackBlock gap="S">
              <Paragraph size="L" color="TEXT_LIGHT">
                <FormattedMessage id="verify number no link" />
              </Paragraph>
              <ButtonBlock
                background="PRIMARY_LIGHT"
                color="PRIMARY_DARK"
                onClick={() => this.resendLink(viaParam)}
              >
                <FormattedMessage id="Resend link" />
              </ButtonBlock>
            </ItemStackBlock>
          </>
        )}
      </>
    );
  }

  renderLogin() {
    const {
      applicationStore: { authState },
      form
    } = this.props;

    // password form should not be displayed if there is an error for the mobile number
    // as mobile number needs to be corrected first
    if (authState && authState.mobileNumber && !form.errors.mobile_number) {
      if (authState.error === 'profile_unconfirmed') {
        return this.renderUnconfirmed();
      }

      if (authState.passwordRequired) {
        return this.renderPasswordForm();
      }
    }

    return this.renderNumberForm();
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const { applicationStore } = this.props;

    if (applicationStore.isAuthenticating) {
      return this.renderLoading();
    }

    if (applicationStore.isTokenRequested) {
      return this.renderRequested();
    }

    if (this.getAuthParam() === 'unconfirmed') {
      return this.renderUnconfirmed(true);
    }

    return this.renderLogin();
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  return <LoginScreen {...props} form={form} />;
});
