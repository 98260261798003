import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import NakedLink from 'elements/NakedLink';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const PreOrderInfoBox = ({
  link
}: {
  link: string
}) => (
  <NakedLink to={link}>
    <EveryCard>
      <EveryCardPadding>
        <ItemStackBlock gap="XXS">
          <EveryCardHeadline>
            <FormattedMessage id="How does it works" />
          </EveryCardHeadline>
          <Paragraph>
            <FormattedMessage id="how it works short" />
          </Paragraph>
        </ItemStackBlock>
        <EveryCardBody right={true}>
          <IconComponent icon="BOX" fill="BLACK" size={2}/>
        </EveryCardBody>
      </EveryCardPadding>
    </EveryCard>
  </NakedLink>
);

export default PreOrderInfoBox;
