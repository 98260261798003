import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import GroupsList from 'domain/GroupsList/GroupsList';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { ROUTE_DASHBOARD } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import ButtonBlock from 'blocks/ButtonBlock';
import { ClientStates } from 'models/ClientStateModel';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

interface SettingsFinalizeGroupSelectionProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
}

interface SettingsFinalizeGroupSelectionState {
  dismissState?: 'loading' | 'error';
}

@inject('applicationStore', 'profilesStore')
@observer
class SettingsFinalizeGroupSelection extends React.Component<
SettingsFinalizeGroupSelectionProps & HistoryProps,
SettingsFinalizeGroupSelectionState
> {
  state: SettingsFinalizeGroupSelectionState = {};

  private goToDashboard() {
    const { history } = this.props;

    if (history.location.state && history.location.state.backRoute) {
      return history.replace(history.location.state.backRoute)
    }
    history.replace(ROUTE_DASHBOARD);
  }

  private async addProfileToGroup(id: number) {
    const { applicationStore, profilesStore } = this.props;

    if (!applicationStore.currentUserId) {
      return;
    }

    try {
      await profilesStore.updateProfileGroup(
        applicationStore.currentUserId,
        id
      );

      this.goToDashboard();
    } catch (error: any) {
      // will be handled by store
    }
  }

  private async dismiss() {
    const { applicationStore } = this.props;

    this.setState({
      dismissState: 'loading'
    });

    try {
      await applicationStore.updateClientState(
        ClientStates.group_select_dismissed,
        '1',
        false
      );

      this.goToDashboard();
    } catch (error: any) {
      this.setState({
        dismissState: 'error'
      });
    }
  }

  private renderPage(content?: any) {
    return (
      <>
        <SignupHeaderComponent progressStatus={90} />

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="onboarding group selection header" />
          </Headline.Large>

          {content}
        </PageStackBlock>

      </>
    );
  }

  render() {
    const { profilesStore } = this.props;
    const { dismissState } = this.state;

    if (profilesStore.isItemLoading) {
      return this.renderPage(<LoadingOverlayComponent />);
    }

    return this.renderPage(
      <>
        {dismissState === 'loading' && <LoadingOverlayComponent />}
        {profilesStore.isItemError && <GenericErrorComponent />}

        <Paragraph>
          <FormattedMessage id="onboarding group selection select group" />
        </Paragraph>

        <GroupsList onGroupClick={(_e, id) => this.addProfileToGroup(id)} />

        {dismissState === 'error' && <GenericErrorComponent />}

        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          onClick={() => this.dismiss()}
        >
          <FormattedMessage id="Non of these groups" />
        </ButtonBlock>
      </>
    );
  }
}

export default SettingsFinalizeGroupSelection;
