import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import { SPONSOR_PAGE_GRID_LIST } from 'components/print/templates/sponsor-page-grids';
import BackLink from 'domain/Links/BackLink';
import { FormattedMessage } from 'react-intl';
import { ROUTE_SPONSORS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

import TwoColumnBlock from 'blocks/TwoColumnBlock';
import WideBlock from 'blocks/WideBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import NakedButton from 'elements/NakedButton';
import { ApplicationStoreType } from 'models/ApplicationStore';
import styled from 'styled-components';
import { assert } from 'utils/assert';
import COLORS from 'utils/constants/colors';
import { PlaceholderSponsorPagesStoreType } from './PlaceholderSponsorPagesStore';
import { SponsorPagesStoreType } from './SponsorPagesStore';

const GridImage = styled.img`
  width: 100%;
  border: 1px solid ${COLORS.GRAY900};
`;
interface Props {
  sponsorPagesStore: SponsorPagesStoreType;
  placeholderSponsorPagesStore: PlaceholderSponsorPagesStoreType;
  applicationStore: ApplicationStoreType;
}

@inject('sponsorPagesStore', 'placeholderSponsorPagesStore', 'applicationStore')
@observer
class NewSponsorPage extends Component<Props & HistoryProps> {
  private lastLayoutKey?: string;

  componentDidMount() {
    this.props.applicationStore.onboardFeature(
      'sponsorpages',
      this.props.location?.pathname
    );

    this.loadPlaceholders();
  }

  async loadPlaceholders() {
    const { placeholderSponsorPagesStore } = this.props;
    await placeholderSponsorPagesStore.getPlaceholderSponsorPages();
  }

  async createPage(layoutKey?: string) {
    if (!layoutKey) {
      return;
    }
    const { match, history, sponsorPagesStore } = this.props;
    const chapterId = chapterIdFromMatch(match);
    assert(chapterId);
    this.lastLayoutKey = layoutKey;
    const sponsorPage = await sponsorPagesStore.createSponsorPage(
      chapterId,
      layoutKey
    );
    history.push(
      sponsorPage
        ? `${ROUTE_SPONSORS}/${chapterId}/pages/${sponsorPage.id}`
        : `${ROUTE_SPONSORS}/${chapterId}`
    );
  }

  async createPlaceholderSponsorPage() {
    const { match, placeholderSponsorPagesStore, history } = this.props;
    const chapterId = chapterIdFromMatch(match);
    assert(chapterId);
    await placeholderSponsorPagesStore.createPlaceholderSponsorPage(
      chapterId
    );
    history.push(`${ROUTE_SPONSORS}/${chapterId}`);
  }

  render() {
    const { sponsorPageIsError } = this.props.sponsorPagesStore;
    const { placeholderPageIsError, placeholderPagesAreLoading, placeholderPages } = this.props.placeholderSponsorPagesStore;

    if (sponsorPageIsError) {
      return (
        <GenericErrorComponent
          onRetryClick={() => this.createPage(this.lastLayoutKey)}
        />
      );
    }

    if (placeholderPageIsError) {
      return (
        <GenericErrorComponent
          onRetryClick={() => this.createPlaceholderSponsorPage()}
        />
      );
    }

    if (placeholderPagesAreLoading) {
      return <LoadingIndicatorComponent />;
    }

    const chapterId = chapterIdFromMatch(this.props.match);

    return (
      <>
        <TopActionBlock>
          <BackLink to={`${ROUTE_SPONSORS}/${chapterId}`} />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="New sponsor page heading" />} text={<FormattedMessage id="New sponsor page intro" />} />


          <WideBlock padded={true}>
            <TwoColumnBlock gap="M">
              { (placeholderPages?.size || 0) < 7 && (
                <NakedButton onClick={() => this.createPlaceholderSponsorPage()}>
                  <GridImage src='/images/sponsor-grids/placeholder.png' alt='Placeholder' />
                </NakedButton>
              )}
              {SPONSOR_PAGE_GRID_LIST.map((gridTemplate) => (
                <NakedButton
                  key={gridTemplate.key}
                  onClick={() => this.createPage(gridTemplate.key)}
                >
                  {gridTemplate.preview ? (
                    <GridImage
                      src={'/images/sponsor-grids/' + gridTemplate.preview}
                      alt={gridTemplate.key}
                    />
                  ) : (
                    gridTemplate.key
                  )}
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </WideBlock>
        </PageStackBlock>
      </>
    );
  }
}

export default NewSponsorPage;
