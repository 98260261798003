import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SliderBlock from 'blocks/SliderBlock';
import TabBarBlock from 'blocks/TabBarBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import AlbumPagePreviewComponent from 'components/print/preview/AlbumPagePreviewComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import SliderButtonsComponent from 'components/SliderButtonsComponent';
import ChapterPreviewComponent from 'domain/ChapterPreviewComponent';
import LockedLabel from 'domain/Label/LockedLabel';
import BackToContentLink from 'domain/Links/BackToContentLink';
import Chip from 'elements/Chip';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { AlbumPageModelType } from 'models/AlbumPageModel';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChapterModelType } from 'models/ChapterModel';
import { PhotoModelType } from 'models/PhotoModel';
import { AlbumPagesStoreType } from 'screens/album_pages/AlbumPagesStore';
import reduceAlbumPagePhotos from 'screens/album_pages/reduceAlbumPagePhotos';
import { PhotosStoreType } from 'screens/photos/PhotosStore';
import { assert } from 'utils/assert';
import { ROUTE_ALBUMS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import PhotosListItem from './PhotosListItem';

const UNPLACED = 'unplaced';
const PLACED = 'placed';
const ALL = 'all';
type TabKey = typeof UNPLACED | typeof PLACED | typeof ALL;

const PhotoListItemLink = ({
  photo,
  asLink,
  chapterId
}: {
  photo: PhotoModelType;
  asLink: boolean;
  chapterId: number;
}) => {
  const item = <PhotosListItem key={photo.id} photo={photo} />;
  if (asLink) {
    return (
      <Link to={ROUTE_ALBUMS + '/' + chapterId + '/' + photo.id}>{item}</Link>
    );
  }
  return item;
};

interface PhotosListProps {
  applicationStore: ApplicationStoreType;
  photosStore: PhotosStoreType;
  albumPagesStore: AlbumPagesStoreType;
}

@inject('applicationStore', 'photosStore', 'albumPagesStore')
@observer
class PhotosList extends React.Component<PhotosListProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore, photosStore, albumPagesStore } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature('albums', this.props.location.pathname)
    ) {
      return;
    }

    if (!photosStore.isPhotosLoading) {
      if (!photosStore.photos || isPush(this.props.history)) {
        this.loadPhotos();
      }
    }

    if (applicationStore.isOrganizer) {
      const chapterId = this.getChapterId();
      albumPagesStore.getAlbumPages(chapterId);
    }
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const prevChapterId = chapterIdFromMatch(prevProps.match);

    if (this.getChapterId() !== prevChapterId) {
      this.loadPhotos();
    }
  }

  loadPhotos() {
    const { photosStore } = this.props;
    const chapterId = this.getChapterId();
    photosStore.getPhotosByChapter(chapterId);
  }

  async swapAlbumPageSorting(
    albumPage: AlbumPageModelType,
    index: number,
    direction: 'left' | 'right'
  ) {
    const { applicationStore, albumPagesStore } = this.props;
    const { sortedAlbumPages } = albumPagesStore;

    assert(sortedAlbumPages, 'albumPages are undefined');

    const { id } = albumPage;
    const swapId = sortedAlbumPages[index + (direction === 'left' ? -1 : 1)].id;

    await this.props.albumPagesStore.swapAlbumPageSorting(id, swapId);
    applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'Album pages swapped flash' })
    );
  }

  chapterUpdated(chapter: ChapterModelType) {
    this.props.photosStore.setChapter(chapter);
  }

  getChapterId() {
    const chapterId = chapterIdFromMatch(this.props.match);
    assert(chapterId, 'Chapter id not given');
    return chapterId;
  }

  renderAlbumPages() {
    const {
      applicationStore: { isEditAllowed },
      albumPagesStore: { sortedAlbumPages },
      photosStore: { chapter }
    } = this.props;
    const chapterId = this.getChapterId();

    return sortedAlbumPages ? (
      <>
        {isEditAllowed && (
          <Link to={`${ROUTE_ALBUMS}/${chapterId}/pages/new`}>
            <ButtonBlock background="PRIMARY">
              <FormattedMessage id="Add new album page" />
            </ButtonBlock>
          </Link>
        )}

        { sortedAlbumPages.length === 0 && (
          <Paragraph color="TEXT_LIGHT">
              <FormattedMessage id="Empty pages" />
          </Paragraph>
        )}
        <SliderBlock>
          <FontPreloadComponent chapter={chapter}>
            {sortedAlbumPages.map((albumPage, index) => {
              const pagePreview = (
                <AlbumPagePreviewComponent
                  chapter={chapter}
                  layoutKey={albumPage.album_layout_key}
                  images={reduceAlbumPagePhotos(albumPage)}
                  page={index + 1}
                  position={index % 2 === 0 ? 'left' : 'right'}
                />
              );
              return (
                <div key={albumPage.id}>
                  {isEditAllowed ? (
                    <>
                      <Link
                        to={`${ROUTE_ALBUMS}/${chapterId}/pages/${albumPage.id}`}
                      >
                        {pagePreview}
                      </Link>
                      <SliderButtonsComponent
                        leftDisabled={index === 0}
                        rightDisabled={index === sortedAlbumPages.length - 1}
                        onClick={(_event, direction) => {
                          this.swapAlbumPageSorting(albumPage, index, direction);
                        }}
                      />
                    </>
                  ) : (
                    pagePreview
                  )}
                </div>
              );
            })}


          </FontPreloadComponent>
        </SliderBlock>
      </>
    ) : (
      <LoadingOverlayComponent />
    );
  }

  renderPage(content: ReactNode) {
    const { applicationStore, photosStore } = this.props;
    const { isOrganizer, isEditAllowed } = applicationStore;
    const { chapter } = photosStore;

    const isChapterEditAllowed =
      chapter &&
      isEditAllowed &&
      applicationStore.isChapterEditAllowed(chapter);

    return (
      <>
        <TopActionBlock>
          <BackToContentLink />
        </TopActionBlock>

        <PageStackBlock>
          <ChapterPreviewComponent
            chapter={chapter}
            defaultTextId="Album"
            baseRoute={ROUTE_ALBUMS}
            onChapterUpdated={(updatedChapter) =>
              this.chapterUpdated(updatedChapter)
            }
          >
            {isOrganizer && this.renderAlbumPages()}
          </ChapterPreviewComponent>

          {isChapterEditAllowed ? (
            <>
              <ItemStackBlock gap="XS">
                {isOrganizer && (
                  <>
                    <Headline.Medium>
                      <FormattedMessage id="photo collection" />
                    </Headline.Medium>

                    <Paragraph color="TEXT_LIGHT">
                      <FormattedMessage id="organize photos" />
                    </Paragraph>
                  </>
                )}
              </ItemStackBlock>
              <p>
                <ButtonBlock
                  background="PRIMARY"
                  onClick={() =>
                    this.props.history.push(
                      ROUTE_ALBUMS + '/' + chapter?.id + '/new'
                    )
                  }
                >
                  <FormattedMessage id="Add photo" />
                </ButtonBlock>
              </p>
            </>
          ) : (
            <LockedLabel id="Albums" />
          )}

          <Divider />

          {content}
        </PageStackBlock>

        <HelpSubject subject="album" />
      </>
    );
  }

  renderStudentView() {
    const { applicationStore, photosStore } = this.props;
    const { currentUserId, isEditAllowed } = applicationStore;
    const { chapter } = photosStore;

    const items = photosStore.myPhotos(currentUserId);

    return this.renderPage(
      !items.length ? (
        <EmptyStateComponent
          headerId="no photos uploaded"
          textId="go upload some photos"
        />
      ) : (
        <TwoColumnBlock gap="M">
          {items.map((photo) => (
            <PhotoListItemLink
              key={photo.id}
              photo={photo}
              asLink={isEditAllowed}
              chapterId={chapter?.id || 0}
            />
          ))}
        </TwoColumnBlock>
      )
    );
  }

  renderOrganizerView() {
    const { applicationStore, photosStore } = this.props;
    const { isEditAllowed } = applicationStore;
    const { chapter, allPhotos, placedPhotos, unplacedPhotos } = photosStore;

    const hash = this.props.history.location.hash.substring(1);
    const activeTab: TabKey =
      hash === UNPLACED || hash === PLACED || hash === ALL ? hash : ALL;

    const hasAllPhotos = allPhotos.length > 0;
    const tabs: { [key in TabKey]: PhotoModelType[] } = {
      all: allPhotos,
      placed: placedPhotos,
      unplaced: unplacedPhotos
    };
    const photos = tabs[activeTab];

    return this.renderPage(
      <>
        <TabBarBlock>
          {Object.entries(tabs).map(([tab, tabPhotos]) => {
            const message = <FormattedMessage id={`Filtered photos ${tab}`} />;
            return (
              <React.Fragment key={tab}>
                {activeTab === tab ? (
                  <Paragraph>{message}</Paragraph>
                ) : (
                  <Link to={`#${tab}`} replace={true}>
                    {message}
                  </Link>
                )}
                <Chip>{tabPhotos.length}</Chip>
              </React.Fragment>
            );
          })}
        </TabBarBlock>

        <TabBarBlock.Content>
          {!hasAllPhotos && (
            <EmptyStateComponent
              headerId="no photos uploaded"
              textId="go upload some photos"
            />
          )}

          {hasAllPhotos && photos.length === 0 && (
            <TextElement color="TEXT_LIGHT">
              <FormattedMessage id="photos count" values={{ count: 0 }} />
            </TextElement>
          )}

          {photos.length > 0 && (
            <TwoColumnBlock gap="M">
              {photos.map((photo) => (
                <PhotoListItemLink
                  key={photo.id}
                  photo={photo}
                  asLink={isEditAllowed}
                  chapterId={chapter?.id || 0}
                />
              ))}
            </TwoColumnBlock>
          )}
        </TabBarBlock.Content>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadPhotos()} />
    );
  }

  render() {
    const { applicationStore, photosStore } = this.props;

    if (photosStore.isPhotosLoading) {
      return this.renderLoading();
    }

    if (photosStore.isPhotosError) {
      return this.renderError();
    }

    if (applicationStore.isOrganizer) {
      return this.renderOrganizerView();
    }

    return this.renderStudentView();
  }
}

export default PhotosList;
