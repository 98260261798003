/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Preorder } from 'api/textile_deals/fetchPreorders';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { Textile } from 'api/textile_deals/fetchTextiles';
import ButtonBlock from 'blocks/ButtonBlock';
import CheckboxBlock from 'blocks/CheckboxBlock';
import TextBlock from 'blocks/ExpertsBlock/TextBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import NakedLink from 'elements/NakedLink';
import { intl } from 'i18n';
import { useProfilePreorderOverview } from 'queries/textile_deals/useOrderOverviewQueries';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { useRemoveTexitlePreorderQuery } from 'queries/textile_deals/useTextilePreorderQueries';
import {
  useCurrentTextileProfileQuery,
  useTextileProfileSubmitPreorderQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import TextileImagePreview from 'screens/preorders/components/TextileImagePreview';
import TextilePriceComponent from 'screens/preorders/components/TextilePriceComponent';
import TextileVariantCartComponent from 'screens/preorders/components/TextileVariantCartComponent';
import styled from 'styled-components';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import isTextileLocked from 'utils/isTextileLocked';

const TextilePreviewBlock = styled.div`
  width: fit-content;
`;

interface PreorderCartState {
  showConfirm: boolean;
  showConfirmOrder: boolean;
  agb_accepted: boolean;
  preorderId?: number;
  error?: string;
  loading?: boolean;
}

const PreorderCartScreen: React.FC<HistoryProps> = (props) => {
  const [cartState, setCartState] = useState<PreorderCartState>({
    showConfirm: false,
    showConfirmOrder: false,
    preorderId: undefined,
    agb_accepted: false,
    error: undefined,
    loading: false
  });

  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const currentTextileProfile = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );
  const orderOverview = useProfilePreorderOverview(
    textileAppState?.textile_profile.id,
    textileOrderId
  );
  const removeTextilePreorder = useRemoveTexitlePreorderQuery(
    textileOrderId,
    textileAppState?.textile_profile.id
  );
  const submitPreorder = useTextileProfileSubmitPreorderQuery(
    textileAppState?.textile_profile.id
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (textileAppState?.textile_order.order_state === 'preparing') {
      props.history.replace(textileOrderRoute(props.match, '/preorder'));
    }
    accessContext.checkSectionAccess('own_preorder');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileAppState?.textile_order.order_state]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/preorder/own')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="preorder cart" />}
            text={<FormattedMessage id="preorder cart info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (currentTextileProfile.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => currentTextileProfile.refetch()}
      />
    );
  }

  if (orderOverview.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => orderOverview.refetch()} />
    );
  }

  if (
    cartState.loading ||
    !textileAppState ||
    currentTextileProfile.isLoading ||
    !currentTextileProfile.data ||
    orderOverview.isLoading ||
    !orderOverview.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const possiblePrice = (): number => {
    const { preorders, textiles } = orderOverview.data;

    return (
      preorders?.reduce(
        (sum, itm) =>
          (sum +=
            textiles?.find((textile) => textile.name === itm.textile_name)
              ?.possible_price || 0),
        0
      ) || 0
    );
  };

  const endPrice = (): number => {
    const { preorders, textiles } = orderOverview.data;

    return (
      preorders?.reduce(
        (sum, itm) =>
          (sum +=
            textiles?.find((textile) => textile.name === itm.textile_name)
              ?.end_price || 0),
        0
      ) || 0
    );
  };

  const confirmPreOrder = () => {
    if (!textileOrderId || !currentTextileProfile) {
      return;
    }

    if (!cartState.agb_accepted) {
      setCartState({
        ...cartState,
        error: intl.formatMessage({
          id: 'preorder submit agb missing'
        })
      });
    }

    if (cartState.agb_accepted) {
      setCartState({
        ...cartState,
        loading: true
      });
      submitPreorder.mutate(
        { id: currentTextileProfile.data.id, textileOrderId },
        {
          onSettled: () => {
            setCartState({
              ...cartState,
              loading: false
            });
            props.history.push(
              textileOrderRoute(props.match, '/preorder/own/summary')
            );
          }
        }
      );
    } else {
      setCartState({ ...cartState, showConfirmOrder: false, loading: false });

      return;
    }
  };

  const removePreorder = (id: number) => {
    setCartState({ ...cartState, showConfirm: true, preorderId: id });
  };

  const performDelete = () => {
    if (!textileOrderId || !cartState.preorderId) {
      return;
    }

    setCartState({
      ...cartState,
      loading: true
    });

    removeTextilePreorder.mutate(
      { id: cartState.preorderId, textileOrderId, profileId: currentTextileProfile.data.id },
      {
        onSettled: () => {
          setCartState({
            ...cartState,
            loading: false,
            showConfirm: false,
            preorderId: undefined
          });
        }
      }
    );
  };

  const abortDelete = () => {
    setCartState({ ...cartState, showConfirm: false, preorderId: undefined });
  };

  const renderPreorderClosed = () => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/preorder')} />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Preorder" />} text={''} />
          <InputMessage error={true}>
            <Paragraph color="BLACK">
              <FormattedMessage id="textile preorder closed info" />
            </Paragraph>
          </InputMessage>
        </PageStackBlock>
      </>
    );
  };

  const renderTextilePreview = (textile: Textile) => {
    const layout = textileAppState.textile_order.theme;

    if (!layout) {
      return null;
    }

    const textileVariants = textile.textile_variants || [];

    return (
      <TextilePreviewBlock key={textile.id}>
        <ItemStackBlock gap="XXS">
          <TextileImagePreview
            textileVariants={textileVariants}
            layout={layout}
            link={textileOrderRoute(
              props.match,
              '/preorder/own/add/' + textile.id
            )}
          />

          <NakedLink
            to={textileOrderRoute(
              props.match,
              '/preorder/own/add/' + textile.id
            )}
          >
            <Paragraph>
              {textile.name}{' '}
              <IconComponent icon="ARROW_STROKE" fill="BLACK" size={0.8} />
            </Paragraph>
          </NakedLink>
          {isTextileLocked(
            textileAppState.textile_order.order_state,
            'atLeastOrderStarted'
          ) && textile.end_price ? (
            <TextElement>
              <FormattedNumber
                value={textile.end_price}
                style="currency"
                currency="EUR"
              />
            </TextElement>
          ) : (
            <TextElement>
              {textile.possible_price} - {textile.end_price} €
            </TextElement>
          )}
        </ItemStackBlock>
      </TextilePreviewBlock>
    );
  };

  const renderEmptyCart = () => {
    const textiles = orderOverview.data.textiles;

    if (!textiles) {
      return null;
    }

    return renderPage(
      <>
        <Divider />
        <TextBlock>
          <Paragraph>
            <FormattedMessage id="preorder empty cart" />
          </Paragraph>
          <Paragraph>
            <FormattedMessage id="preorder add to cart" />
          </Paragraph>
        </TextBlock>
        <Divider />

        <SliderBlock noScrollBar={true}>
          {textiles.map((textile: Textile) => {
            return renderTextilePreview(textile);
          })}
        </SliderBlock>
      </>
    );
  };

  const renderCart = () => {
    if (
      !textileAppState.textile_order.preorder_open &&
      (textileAppState.textile_profile.is_student ||
        textileAppState.textile_profile.is_applicant)
    ) {
      return renderPreorderClosed();
    }

    if (currentTextileProfile.data.preorder_state === 'pending') {
      return renderPage(<LoadingIndicatorComponent />);
    }

    const layout = textileAppState.textile_order.theme;
    const { preorders, textiles } = orderOverview.data;

    if (!layout || !textiles || textiles.length === 0) {
      return null;
    }

    if (preorders && preorders.length === 0) {
      return renderEmptyCart();
    }

    const textilePrice = orderOverview.data.price;
    let extra;
    if (cartState.showConfirm) {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm remove preorder" />}
          text={<FormattedMessage id="confirm remove preorder info" />}
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => performDelete()}
          onAbort={() => abortDelete()}
          confirmColor="RED"
        />
      );
    }

    if (cartState.showConfirmOrder) {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm preorder submit" />}
          text={<FormattedMessage id="confirm preorder submit info" />}
          confirmText={<FormattedMessage id="Submit" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => confirmPreOrder()}
          onAbort={() =>
            setCartState({
              ...cartState,
              showConfirmOrder: false
            })
          }
        />
      );
    }

    const day = intl.formatDate(textileOrder.data.preorder_deadline, {
      day: '2-digit'
    });
    const month = intl.formatDate(textileOrder.data.preorder_deadline, {
      month: '2-digit'
    });
    const date = day + '/' + month;

    return renderPage(
      <>
        {extra}
        {!textileAppState.textile_profile.is_organizer && (
          <>
            {textileAppState.textile_order.preorder_open ? (
              <Paragraph>
                <IconComponent icon="CLOCK_CIRCLE" fill="BLACK" />
                {textileOrder.data.preorder_deadline ? (
                  <>
                    <FormattedMessage id="preorder open til" /> {date}
                  </>
                ) : (
                  <FormattedMessage id="preorder open time" />
                )}
              </Paragraph>
            ) : (
              <Paragraph>
                <IconComponent icon="PAUSE" fill="BLACK" />
                <FormattedMessage id="editing state paused" />
              </Paragraph>
            )}
          </>
        )}

        {currentTextileProfile.data.preorder_notification && (
          <InputMessage info={true}>
            <Paragraph weight="BOLD" size="L">
              <FormattedMessage id="message from organiser" />
            </Paragraph>

            <Divider color="PURPLE" />

            <Paragraph>
              {currentTextileProfile.data.preorder_notification.notification}
            </Paragraph>
            <Paragraph>
              <IconComponent icon="ORGANIZER" fill="PURPLE" />
              {currentTextileProfile.data.preorder_notification.author_name}
            </Paragraph>
          </InputMessage>
        )}

        {preorders && preorders.length > 0 && textilePrice && (
          <>
            <Divider />
            <Headline.Medium>
              <FormattedMessage id="all your preorders" />
            </Headline.Medium>
            {preorders.map((preorder: Preorder) => {
              const textile = textiles.find(
                (itm) => itm.name === preorder.textile_name
              );

              if (!textile || !textile.possible_price || !textile.end_price) {
                return null;
              }
              const variant = textile?.textile_variants?.find(
                (itm) => itm.id === preorder.textile_variant_id
              );
              return (
                <TextileVariantCartComponent
                  textileVariant={variant}
                  layout={layout}
                  size={preorder.size}
                  removePreorder={(id: number) => removePreorder(id)}
                  textileName={preorder.textile_name}
                  preorderId={preorder.id}
                  key={preorder.id}
                  possiblePrice={textile.possible_price}
                  endPrice={textile.end_price}
                />
              );
            })}
          </>
        )}

        <CheckboxBlock>
          <CheckboxBlock.CheckboxElement
            id="accept_agb"
            onChange={() => {
              const currentStatus = cartState.agb_accepted;
              setCartState({ ...cartState, agb_accepted: !currentStatus });
            }}
          />
          <CheckboxBlock.LabelElement htmlFor="accept_agb">
            <Paragraph size="L">
              <FormattedMessage id="textile agb accept" />
            </Paragraph>
          </CheckboxBlock.LabelElement>
        </CheckboxBlock>

        {textilePrice && (
          <TextilePriceComponent
            endPrice={textilePrice?.price.end_price || endPrice()}
            possiblePrice={
              textilePrice?.price.possible_price || possiblePrice()
            }
            range={
              !isTextileLocked(
                textileAppState.textile_order.order_state,
                'atLeastOrderStarted'
              )
            }
            link={textileOrderRoute(props.match, '/discounts')}
          />
        )}

        {cartState.error && (
          <InputMessage error={true}>
            <Paragraph>{cartState.error}</Paragraph>
          </InputMessage>
        )}

        <ButtonBlock
          background="PRIMARY"
          onClick={() => {
            if (cartState.agb_accepted) {
              setCartState({ ...cartState, showConfirmOrder: true });
            } else {
              setCartState({
                ...cartState,
                error: intl.formatMessage({
                  id: 'preorder submit agb missing'
                })
              });
            }
          }}
        >
          <FormattedMessage id="confirm preorder" />
        </ButtonBlock>
      </>
    );
  };

  return renderCart();
};

export default PreorderCartScreen;
