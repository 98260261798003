import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import {
  usePausePreorderQuery,
  useTextileOrderForPreorderQuery,
  useUnpausePreorderQuery
} from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const PreorderToggleScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const flashMessageContext = useContext(FlashMessageContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const pausePreorder = usePausePreorderQuery(textileOrderId);
  const unpausePreorder = useUnpausePreorderQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  if (!flashMessageContext) {
    throw Error('Component must be used within HasFlashMessageProvider');
  }

  useEffect(() => {
    if (textileAppState?.textile_order.order_state === 'preparing') {
      props.history.replace(textileOrderRoute(props.match, '/preorder'));
    }
    accessContext.checkSectionAccess('preorder');

    const orderState = textileAppState?.textile_order.order_state;

    if (orderState) {
      props.form.setField('preorder', orderState === 'preorder' || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileAppState?.textile_order.order_state]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/preorder')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="preorder deadline" />}
            text={<FormattedMessage id="preorder deadline activate info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (textileOrder.isLoading || !textileOrder.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const setPreorderState = (preorderOpen: boolean) => {
    if (!textileOrderId) {
      return;
    }

    if (preorderOpen) {
      unpausePreorder.mutate(textileOrderId, {
        onSuccess: () => {
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'Saved' }),
            'success'
          );
        }
      });
    } else {
      pausePreorder.mutate(textileOrderId, {
        onSuccess: () => {
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'Saved' }),
            'success'
          );
        }
      });
    }
  };

  const renderActivate = () => {
    const { form } = props;

    if (form.values.preorder === undefined) {
      return null;
    }

    return renderPage(
      <>
        <FormStackBlock>
          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="preorder"
              {...form.bindCheckbox('preorder')}
              onClick={(e: any) => setPreorderState(e.target.checked)}
            />
            <CustomCheckboxBlock.LabelElement htmlFor="allow_edit_print_data">
              <FormattedMessage id="activate preorder deadline" />
            </CustomCheckboxBlock.LabelElement>
            {form.values.preorder ? (
              <TextElement size="S">
                <FormattedMessage id="textile edit allowed" />
              </TextElement>
            ) : (
              <TextElement size="S">
                <FormattedMessage id="textile edit not allowed" />
              </TextElement>
            )}
          </CustomCheckboxBlock>
        </FormStackBlock>

        {form.values.preorder && (
          <>
            <Divider />
            <ItemStackBlock gap="M">
              <Headline.Medium>
                <FormattedMessage id="Deadline" />
              </Headline.Medium>
              <Paragraph>
                <FormattedMessage id="preorder deadline info" />
              </Paragraph>
            </ItemStackBlock>

            <Link
              to={textileOrderRoute(
                props.match,
                '/preorder/preorder_state/deadline'
              )}
            >
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      {textileOrder.data.preorder_deadline ? (
                        <TextElement>
                          <FormattedMessage id="preorder open til" />{' '}
                          <FormattedDate
                            value={textileOrder.data.preorder_deadline}
                            year="numeric"
                            month="2-digit"
                            day="2-digit"
                          />
                        </TextElement>
                      ) : (
                        <FormattedMessage id="set preorder deadline" />
                      )}
                    </EveryCardHeadline>
                  </EveryCardBody>
                  <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
                </EveryCardPadding>
              </EveryCard>
            </Link>
          </>
        )}
      </>
    );
  };

  return renderActivate();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <PreorderToggleScreen {...props} form={form} />;
};
