import React from 'react';

import { LoadingDotElement, LoadingSpinnerElement } from 'blocks/LoadingBlock';

/**
 * Inline loading spinner. Use when a part of the page is being loaded.
 */
const LoadingIndicatorComponent = ({ noMargin }: { noMargin?: boolean }) => (
  // div is used becaue styled_components is buggy and changes load sequence of styles
  <div>
    <LoadingSpinnerElement style={noMargin ? {} : { margin: 'auto' }}>
      <LoadingDotElement />
      <LoadingDotElement />
    </LoadingSpinnerElement>
  </div>
);

export default LoadingIndicatorComponent;
