import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EveryCard, EveryCardHeadline, EveryCardPadding } from 'blocks/EveryCard/EveryCard';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import NakedButton from 'elements/NakedButton';
import { SetupStoreType } from 'models/SetupStore';
import { ROUTE_SIGNUP } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import { validYears } from 'utils/validYears';

interface SignupYearScreenProps {
  setupStore: SetupStoreType;
}

@inject('setupStore')
@observer
class SignupYearScreen extends React.Component<
SignupYearScreenProps & HistoryProps
> {
  componentDidMount() {
    const { setupStore } = this.props;

    if (setupStore.isSignupDone) {
      this.props.history.replace(ROUTE_SIGNUP + '/done');
      return;
    }

    if (!setupStore.isSignupInitialized) {
      this.props.history.replace(ROUTE_SIGNUP);
      return;
    }
  }

  selectYear(year?: number) {
    if (!year) {
      return;
    }

    this.props.setupStore.setYear(year);

    this.props.history.push(ROUTE_SIGNUP + '/name');
  }

  render() {
    return (
      <>
        <SignupHeaderComponent
          backRoute={ROUTE_SIGNUP + '?screen=school'}
          progressStatus={(100 / 10) * 5}
        />

        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Your year" />} text={<FormattedMessage id="signup year info" />} />

          <ListStackBlock>
            {validYears().map((year: number) => (
              <NakedButton key={year} onClick={() => this.selectYear(year)}>
                <EveryCard>
                  <EveryCardPadding>
                    <EveryCardHeadline>{year}</EveryCardHeadline>
                  </EveryCardPadding>
                </EveryCard>
              </NakedButton>
            ))}
          </ListStackBlock>
        </PageStackBlock>
      </>
    );
  }
}

export default SignupYearScreen;
