import React from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import {
  ROUTE_TEXTILE_MANAGEMENT
} from 'utils/constants/routes';
import ManageTextileProfilesScreen from './ManageTextileProfilesScreen';
import AllowEditPrintDataScreen from './screens/AllowEditPrintDataScreen';
import DeadlineFormScreen from './screens/DeadlineFormScreen';
import OverviewScreen from './screens/OverviewScreen';
import OverwriteProfileDataScreen from './screens/OverwriteProfileDataScreen';
import PrintDataMessageFormScreen from './screens/PrintDataMessageFormScreen';
import PrintOrderScreen from './screens/PrintOrderScreen';
import ProfileGroupSelectScreen from './screens/ProfileGroupSelectScreen';
import ProfilePrintScreen from './screens/ProfilePrintScreen';
import SetProfileDataSceen from './screens/SetProfileDataSceen';
import TextileOrderInviteScreen from './screens/TextileOrderInviteScreen';
import AddTeacherTextileName from './screens/textile_names/AddTeacherTextileName';
import AddVirtualTextileName from './screens/textile_names/AddVirtualTextileName';
import EditTeacherTextileName from './screens/textile_names/EditTeacherTextileName';
import EditVirtualTextileName from './screens/textile_names/EditVirtualTextileName';
import TeacherOverviewScreen from './screens/textile_names/TeacherOverviewScreen';
import GroupSelectionScreen from './screens/textile_names/VirtualGroupSelectScreen';

export default () => (
  <Switch>
    {/* Routes virtual textile profile */}
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/virtual/add'}
      component={AddVirtualTextileName}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/virtual/edit/:id'}
      component={EditVirtualTextileName}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/virtual/group_selection/:id'}
      component={GroupSelectionScreen}
    />

    {/* Routes virtual textile teachers */}
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/teachers/add'}
      component={AddTeacherTextileName}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/teachers/edit/:id'}
      component={EditTeacherTextileName}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/teachers'}
      component={TeacherOverviewScreen}
    />

    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/overview/:id'}
      component={OverviewScreen}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/set/:id'}
      component={SetProfileDataSceen}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/overwrite/:id'}
      component={OverwriteProfileDataScreen}
    />

    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/print_profile/:id'}
      component={ProfilePrintScreen}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/group_selection/:id'}
      component={ProfileGroupSelectScreen}
    />

    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/edit_print_data/deadline'}
      component={DeadlineFormScreen}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/edit_print_data'}
      component={AllowEditPrintDataScreen}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/print_data_message'}
      component={PrintDataMessageFormScreen}
    />

    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/textile_invite'}
      component={TextileOrderInviteScreen}
    />
    <Route
      path={ROUTE_TEXTILE_MANAGEMENT + '/print_order'}
      component={PrintOrderScreen}
    />

    <Route
      path={ROUTE_TEXTILE_MANAGEMENT}
      component={ManageTextileProfilesScreen}
      exact={true}
    />
    <NotFoundRoute />
  </Switch>
);
