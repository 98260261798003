import React, { JSXElementConstructor, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import { MottoModelType } from 'models/MottoModel';
import TextElement from 'components/TextElement/TextElement';

export interface MottoListItemProps {
  motto: MottoModelType;
  selected?: boolean;
  onClick?: (e: MouseEvent, studentId: number) => void;
}

export type MottoListItemType = JSXElementConstructor<MottoListItemProps>;

const MottosSelectListItem = ({ motto, onClick }: MottoListItemProps) => (
  <EveryCard
    as={onClick && 'button'}
    onClick={onClick && ((e: MouseEvent) => onClick(e, motto.id))}
  >
    <EveryCardPadding>
      <EveryCardBody>
        <EveryCardHeadline>{motto.name}</EveryCardHeadline>
        <TextElement>
          <FormattedMessage
            id="votes count short"
            values={{
              count: motto.votes_count
            }}
          />
        </TextElement>
      </EveryCardBody>
    </EveryCardPadding>
  </EveryCard>
);

export default MottosSelectListItem;
