import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTextileNotification, updateTextileNotification } from "api/textile_deals/fetchTextileNotification";

export const useCreateTextileNotificationQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { textileOrderId: number; data: any }) =>
      createTextileNotification(data.data, data.textileOrderId),
      onSettled: () => {
          queryClient.invalidateQueries(['textile_profile', textileOrderId]);
          queryClient.invalidateQueries(['current_textile_profile', textileOrderId]);
      }
  });
};

export const useUpdateTextileNotificationQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: {data: any, id: number, textileOrderId: number}) =>
      updateTextileNotification(patch.data, patch.id, patch.textileOrderId, ),
      onSettled: () => {
        queryClient.invalidateQueries(['textile_profile', textileOrderId]);
        queryClient.invalidateQueries(['current_textile_profile', textileOrderId]);
    }
  });
};