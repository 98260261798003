import { get, post, uri } from 'api/BaseApi';

export type TextileRoleChangeRequestType =
  | 'applicant_to_student'
  | 'student_to_organizer'
  | 'applicant_to_organizer';


export interface TextileRoleChangeRequest {
  id: number;
  request_type: TextileRoleChangeRequestType;
  created_at: string;
  textile_profile_id: number;
  textile_profile_name: string;
}

export const getTextileRoleChangeRequestsByTextileOrderId = (
  textileOrderId: number
): Promise<TextileRoleChangeRequest[]> => {
  return get(
    '/textile_role_change_requests?textile_order_id=' + textileOrderId
  );
};


export const acceptTextileRoleChangeRequest = (id: number, textileOrderId: number): Promise<TextileRoleChangeRequest> => {
  return post('/textile_role_change_requests/' + uri(id) + '/accept', {
    textile_order_id: textileOrderId
  });
}

export const rejectTextileRoleChangeRequest = (id: number, textileOrderId: number): Promise<TextileRoleChangeRequest> => {
  return post('/textile_role_change_requests/' + uri(id) + '/reject', {
    textile_order_id: textileOrderId
  });
}

export const requestTextileRoleChange = (textileOrderId: number, type: TextileRoleChangeRequestType) => {
  return post('/textile_role_change_requests', {
    textile_order_id: textileOrderId,
    textile_role_change_request: {
      request_type: type
    }
  });
}
