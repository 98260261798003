import { types, Instance } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';
import { ChapterEditingStateEnum } from './ChapterEditingStateEnum';

const CommentModel = types
  .model('CommentModel', {
    id: types.identifierNumber,
    comment: types.maybe(types.string),
    author: types.maybe(ProfileAsAuthorModel),
    profile: types.maybe(ProfileAsAuthorModel),
    // profile_id: types.maybe(types.number),
    print: types.maybe(types.boolean),
    sorting: types.maybe(types.number),
    chapter_editing_state: types.maybe(ChapterEditingStateEnum)
  })
  .views((self) => {
    return {
      get authorName(): string | undefined {
        return self.author?.fullName;
      }
    };
  });

export const createCommentModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return CommentModel.create({
    id: mandatoryId(data.id),
    comment: data.comment || undefined,
    author: createProfileAsAuthorModel(data.author),
    profile: createProfileAsAuthorModel(data.profile),
    // profile_id: numberOrUndefined(data.profile_id),
    print: booleanOrUndefined(data.print),
    sorting: numberOrUndefined(data?.sorting),
    chapter_editing_state: ChapterEditingStateEnum.is(
      data?.chapter_editing_state
    )
      ? data.chapter_editing_state
      : undefined
  });
};

export type CommentModelType = Instance<typeof CommentModel>;
export default CommentModel;
