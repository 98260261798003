import { ChapterType } from 'models/ChapterTypeEnum';

import { ROUTE_AUTH } from './constants/routes';

const chapterRoutesMap: {
  [chapterType in Exclude<
    ChapterType,
    'unknown' | 'imprint' | 'cover'
  >]: string;
} = {
  album: 'albums',
  factsheet: 'factsheets',
  teacher_factsheet: 'factsheets',
  yearbook: 'yearbook',
  quote: 'quotes',
  text: 'texts',
  sponsor: 'sponsors',
  ranking: 'rankings',
  table_of_contents: 'toc'
} as const;

/**
 * Returns the route path for a given chapter type and id.
 *
 * @param chapterType
 * @param id
 */
const chapterPath = (chapterType: ChapterType, id: number): string => {
  if (
    chapterType === 'unknown' ||
    chapterType === 'imprint' ||
    chapterType === 'cover'
  ) {
    throw new Error(`No route possible for ${chapterType} ${id}`);
  }

  let params = '';
  if (chapterType === 'teacher_factsheet') {
    params = '?teachers=yes';
  }

  return `${ROUTE_AUTH}/book/${chapterRoutesMap[chapterType]}/${id}${params}`;
};

export default chapterPath;
