import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

interface LabelElementProps {
  small?: boolean;
}

export default styled.span<LabelElementProps>`
  position: relative;
  display: block;
  min-height: 1.875rem;
  padding-right: 3.333rem;
  cursor: pointer;
  line-height: 1.6667rem;
  color: inherit;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transition: background 0.4s ease-out;
    border-radius: 0.9375em;
    width: 3.4375em;
    height: 1.875em;
    background: ${COLORS.GRAY800};
    font-size: 1rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.1875em;
    right: 1.6875em;
    transition: right 0.2s ease-out;
    border-radius: 100%;
    width: 1.5em;
    height: 1.5em;
    background: ${COLORS.WHITE};
    font-size: 1rem;
  }

  > span {
    display: block;
  }

  ${(props) =>
    props.small
      ? `
    font-size: 0.875rem
    font-weight: 400;
    `
      : `
      font-size: 1.125rem;
      font-weight: 500;
  `};
`;
