import { createIntlCache, createIntl } from 'react-intl';

import NuggitApi from 'api/NuggitApi';
import configureLocale from 'i18n/locales/de';
import configureStores from './configureStores';

export default function bootstrap() {
  const locale = configureLocale();
  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: locale.lang,
      messages: locale.messages
    },
    cache
  );

  const client = new NuggitApi();
  const stores = configureStores({
    client
  });

  return {
    stores,
    intl
  };
}
