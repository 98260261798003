
import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import ProgressbarComponent from 'components/ProgressbarComponent';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import CancelLink from 'domain/Links/CancelLink';
import TextilePreorderProfilesList from 'domain/textileStudentList/TextilePreorderProfilesList';
import NakedLink from 'elements/NakedLink';
import { intl } from 'i18n';
import { useStartPreorderQuery, useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import {
  useCurrentTextileProfileQuery,
  useTextileProfilesQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const PreorderManagementScreen: React.FC<HistoryProps & FormProps> = (
  props
) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const accessContext = useContext(HasAccessContext);
  const textileProfiles = useTextileProfilesQuery(textileOrderId);
  const currentTextileProfile = useCurrentTextileProfileQuery(textileAppState?.textile_profile.id, textileOrderId);
  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const startPreorderQuery = useStartPreorderQuery(textileOrderId)
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('preorder');
  }, [accessContext]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileProfiles.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileProfiles.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (currentTextileProfile.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => currentTextileProfile.refetch()}
      />
    );
  }

  if (
    !textileAppState ||
    textileProfiles.isLoading ||
    !textileProfiles.data ||
    textileOrder.isLoading ||
    !textileOrder.data ||
    currentTextileProfile.isLoading ||
    !currentTextileProfile.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const activeProfiles = (): number => {
    return textileProfiles.data.filter(
      (itm) =>
        itm.role === 'organizer' ||
        itm.role === 'manager' ||
        itm.role === 'student'
    ).length;
  };

  const submittedProfiles = (): number => {
    return textileProfiles.data.filter(
      (itm) => itm.preorder_state === 'submitted'
    ).length;
  };

  const startPreorder = () => {
    const { history, match } = props;

    if (!textileOrderId) {
      return;
    }

    startPreorderQuery.mutate(textileOrderId, {
      onError: () => {
        history.push(textileOrderRoute(match, ''));
      }
    })
  }


  const renderConfirm = () => {
    const { form } = props;
    form.setConfirm('preorder');
  }

  const renderNotAllowed = () => {
    const { form } = props;
    form.setConfirm('notAllowed');
  }

  const renderNoDesignSet = () =>  {
    const { form } = props;
    form.setConfirm('noDesign');
  }

  const renderStartPreorder = () => {
    const { form } = props;
    const orderData = textileOrder.data;
    let extra;

    if (form.confirm === 'preorder') {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm start preorder" />}
          text={<FormattedMessage id="confirm start preorder info" />}
          confirmText={<FormattedMessage id="Start" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => startPreorder()}
          onAbort={() => form.setConfirm('')}
          confirmColor="PRIMARY"
        />
      );
    }

    if (form.confirm === 'notAllowed') {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="choose textile variants" />}
          text={<FormattedMessage id="choose textile variants colors" />}
          confirmText={<FormattedMessage id="Ok" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() =>
            props.history.push(
              textileOrderRoute(
                props.match,
                '/textile_design/colors_selections'
              )
            )
          }
          onAbort={() => form.setConfirm('')}
          confirmColor="PRIMARY"
        />
      );
    }

    if (form.confirm === 'activeTicket') {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="textile order active design ticket" />}
          text={
            <FormattedMessage id="textile order active design ticket info" />
          }
          confirmText={<FormattedMessage id="Ok" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() =>
            props.history.push(textileOrderRoute(props.match, ''))
          }
          onAbort={() => form.setConfirm('')}
          confirmColor="PRIMARY"
        />
      );
    }

    if (form.confirm === 'noDesign') {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="textile order no design" />}
          text={<FormattedMessage id="textile order no design info" />}
          confirmText={<FormattedMessage id="Ok" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() =>
            props.history.push(
              textileOrderRoute(props.match, '/textile_design')
            )
          }
          onAbort={() => form.setConfirm('')}
          confirmColor="PRIMARY"
        />
      );
    }

    return (
      <>
        <TopActionBlock>
          <CancelLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>
        <PageStackBlock>
          {extra}
          <PageHeader
            headline={<FormattedMessage id="Preorders" />}
            text={<FormattedMessage id="start preorder info" />}
          />

          <IconListBlock>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <ItemStackBlock gap="XS">
                <Paragraph>
                  <FormattedMessage id="start preorder choose design" />
                </Paragraph>
                <TextElement>
                  <FormattedMessage id="start preorder choose design info" />
                </TextElement>
              </ItemStackBlock>
            </IconListElement>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <ItemStackBlock gap="XS">
                <Paragraph>
                  <FormattedMessage id="start preorder student order" />
                </Paragraph>
                <TextElement>
                  <FormattedMessage id="start preorder student order info" />
                </TextElement>
              </ItemStackBlock>
            </IconListElement>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <ItemStackBlock gap="XS">
                <Paragraph>
                  <FormattedMessage id="start preorder overview" />
                </Paragraph>
                <TextElement>
                  <FormattedMessage id="start preorder overview info" />
                </TextElement>
              </ItemStackBlock>
            </IconListElement>
          </IconListBlock>

          <ButtonBlock
            background="PRIMARY"
            onClick={() => {
              if (
                // textileOrder.all_colors_set &&
                // !textileOrder.hasActiveDesignTicket
                textileAppState.textile_order.all_colors_set
              ) {
                renderConfirm();
              } else if (
                !orderData.layout_key
              ) {
                renderNoDesignSet();
              // } else if (textileOrder.hasActiveDesignTicket) {
              //   this.renderActiveDesignTicket();
              } else if (!textileAppState.textile_order.all_colors_set) {
                renderNotAllowed();
              }
            }}
          >
            <FormattedMessage id="start preorder" />
          </ButtonBlock>

          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() =>
              props.history.push(textileOrderRoute(props.match, ''))
            }
          >
            <FormattedMessage id="Later" />
          </ButtonBlock>
        </PageStackBlock>
      </>
    );
  }

  const renderOverview = () => {
    const profilesCount = activeProfiles();
    const submittedProfilesCount = submittedProfiles();
    const orderData = textileOrder.data;

    if (textileAppState.textile_order.order_state === 'preparing') {
      return renderStartPreorder();
    }

    const day = intl.formatDate(orderData.preorder_deadline, {
      day: '2-digit'
    });
    const month = intl.formatDate(orderData.preorder_deadline, {
      month: '2-digit'
    });
    const date = day + '/' + month;

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Preorders" />}
          text={<FormattedMessage id="Preorder info" />}
        />

        <ItemStackBlock gap="XXS">
          {textileAppState.textile_order.preorder_open ? (
            <Paragraph>
              <IconComponent icon="CLOCK_CIRCLE" fill="BLACK" />
              {orderData.preorder_deadline ? (
                <>
                  <FormattedMessage id="preorder open til" /> {date}
                </>
              ) : (
                <FormattedMessage id="preorder open time" />
              )}
            </Paragraph>
          ) : textileAppState.textile_order.order_state ===
            'preorder_paused' ? (
            <Paragraph>
              <IconComponent icon="PAUSE" fill="BLACK" />
              <FormattedMessage id="preorder state paused" />
            </Paragraph>
          ) : (
            <Paragraph>
              <IconComponent icon="LOCK" fill="BLACK" />
              <FormattedMessage id="editing state locked" />
            </Paragraph>
          )}
          <ProgressbarComponent
            isStatic={true}
            progressStatus={(submittedProfilesCount / profilesCount) * 100}
            color="PRIMARY"
          />
          <TextElement>
            <FormattedMessage
              id="textile preorder students count"
              values={{
                count: submittedProfilesCount,
                number: profilesCount
              }}
            />
          </TextElement>
        </ItemStackBlock>

        <ButtonListBlock>
          <ButtonListBlockItem>
            <NakedLink
              to={textileOrderRoute(props.match, '/preorder/preorder_state')}
            >
              <ButtonListBlockBody>
                <FormattedMessage id="textile editing phase" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>

          <ButtonListBlockItem>
            <NakedLink
              to={textileOrderRoute(props.match, '/preorder/preorder_message')}
            >
              <ButtonListBlockBody>
                <FormattedMessage id="notification header" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>

          <ButtonListBlockItem>
            <NakedLink to={textileOrderRoute(props.match, '/order/overview')}>
              <ButtonListBlockBody>
                <FormattedMessage id="textile order overview" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>

          <ButtonListBlockItem>
            <NakedLink
              to={textileOrderRoute(props.match, '/management/textile_invite')}
            >
              <ButtonListBlockBody>
                <FormattedMessage id="textile invite" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>
        </ButtonListBlock>

        <NakedLink to={textileOrderRoute(props.match, '/preorder/own')}>
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="preorder own" />
                </EveryCardHeadline>
                <TextElement>
                  {currentTextileProfile.data.preorder_state === 'submitted' ? (
                    <>
                      <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                      <FormattedMessage id="preorder confirmed" />
                    </>
                  ) : currentTextileProfile.data.preorder_state === 'rejected' ? (
                    <>
                      <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
                      <FormattedMessage id="preorder rejected" />
                    </>
                  ) : (
                    <>
                      <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />
                      <FormattedMessage id="preorder open" />
                    </>
                  )}
                </TextElement>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </NakedLink>

        <NakedLink to={textileOrderRoute(props.match, '/preorder/virtual')}>
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="preorder add manuell" />
                </EveryCardHeadline>
                <Paragraph color="TEXT_LIGHT">
                  <FormattedMessage id="preorder add manuell info" />
                </Paragraph>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </NakedLink>

        <Divider />

        <TextilePreorderProfilesList
          textileOrderId={textileOrderIdFromMatch(props.match)}
          onProfileClick={(profileId) => {
            if (profileId === currentTextileProfile.data.id) {
              props.history.push(
                textileOrderRoute(props.match, '/preorder/own')
              );
            } else {
              props.history.push(
                textileOrderRoute(props.match, '/preorder/review/' + profileId)
              );
            }
          }}
          inviteLink={orderData.invitation_url}
          profiles={textileProfiles.data}
        />
      </>
    );
  };

  return renderOverview();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <PreorderManagementScreen {...props} form={form} />;
};
