import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ROUTE_MOTTOS, ROUTE_MOTTOS_ORG } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

import { MottosStoreType } from '../MottosStore';

import { ApplicationStoreType } from 'models/ApplicationStore';
import TopActionBlock from 'blocks/TopActionBlock';
import InfoBox from 'components/InfoBoxComponent/InfoBoxComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import MottosList from '../container/MottosList/MottosList';
import MottosSelectListItem from '../container/MottosList/MottosSelectListItem';
import LinkBlock from 'blocks/LinkBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

interface MottosSelectScreenProps {
  applicationStore: ApplicationStoreType;
  mottosStore: MottosStoreType;
}

@inject('applicationStore', 'mottosStore')
@observer
class MottosSelectScreen extends React.Component<
MottosSelectScreenProps & HistoryProps
> {
  select(id: number) {
    this.props.mottosStore.select(id);
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_MOTTOS_ORG} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="set which motto" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderVoting() {
    const { mottosStore } = this.props;

    return this.renderPage(
      <>
        <LinkBlock
          to={ROUTE_MOTTOS + '/new?select=yes'}
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
        >
          <FormattedMessage id="Set other motto" />
        </LinkBlock>

        {mottosStore.isSelectError && (
          <InfoBox error={true}>
            <FormattedMessage id="motto select error" />
          </InfoBox>
        )}

        <MottosList
          ranked={true}
          itemComponent={MottosSelectListItem}
          onMottoClick={(_: any, id: number) => this.select(id)}
        />
      </>
    );
  }

  render() {
    const { applicationStore, mottosStore } = this.props;

    if (!applicationStore.isOrganizer) {
      return null;
    }

    if (mottosStore.isSelectLoading) {
      return this.renderLoading();
    }

    return this.renderVoting();
  }
}

export default MottosSelectScreen;
