import React, { useContext, useEffect } from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import {
  useOptimisticUpdateTextileOrderQuery,
  useTextileOrderForManagementQuery
} from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const PrintDataMessageFormScreen: React.FC<HistoryProps & FormProps> = (
  props
) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const textileOrder = useTextileOrderForManagementQuery(textileOrderId);
  const updateTextileOrder = useOptimisticUpdateTextileOrderQuery(
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    const data = textileOrder.data;

    if (data) {
      props.form.setField(
        'organizer_print_data_message',
        data.organizer_print_data_message || undefined
      );
    }
    accessContext.checkSectionAccess('management');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileOrder.data]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/management')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="textile student notification" />}
            text={<FormattedMessage id="textile student notification info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (props.form.loading || textileOrder.isLoading || !textileOrder.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const save = () => {
    const { match, form, history } = props;

    if (!textileOrderId) {
      return;
    }

    form.setLoading(true);

    const patch = { ...textileOrder.data, ...form.values };

    updateTextileOrder.mutate(
      { data: patch, textileOrderId },
      {
        onSuccess: () => {
          history.push(textileOrderRoute(match, '/management'));
        },
        onError: (error: any) => {
          handleFormError(form, error);
          form.setLoading(false);
          return;
        }
      }
    );
  };

  const deleteMessage = () => {
    const { match, history, form } = props;

    if (!textileOrderId) {
      return;
    }

    form.setLoading(true);

    const patch = { ...textileOrder.data, organizer_print_data_message: null };
    updateTextileOrder.mutate(
      { data: patch, textileOrderId },
      {
        onSuccess: () => {
          history.push(textileOrderRoute(match, '/management'));
        },
        onSettled: () => {
          form.setLoading(false)
        }
      }
    );
  };

  const renderForm = () => {
    const { form } = props;

    return renderPage(
      <>
        <TextareaComponent
          name="organizer_print_data_message"
          height={7}
          {...form.bindInput('organizer_print_data_message')}
          maxLength={60}
        />

        <CharCounterComponent
          max={60}
          value={form.values.organizer_print_data_message}
        />

        <ButtonBlock background="PRIMARY" onClick={() => save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>

        {textileOrder.data.organizer_print_data_message && (
          <>
            <Divider />

            <DeleteButton onClick={() => deleteMessage()}>
              <FormattedMessage id="delete deadline" />
            </DeleteButton>
          </>
        )}
      </>
    );
  };

  return renderForm();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <PrintDataMessageFormScreen {...props} form={form} />;
};
