import { Instance, types } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import stringOrUndefined from 'utils/store/stringOrUndefined';

import ChapterModel, { createChapterModel } from './ChapterModel';
import PhotoModel, { createPhotoModel } from './PhotoModel';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';

const TextModel = types.model('TextModel', {
  id: types.identifierNumber,
  chapter_id: types.maybe(types.number),
  chapter: types.maybe(ChapterModel),
  content: types.maybe(types.string),
  author: types.maybe(ProfileAsAuthorModel),
  // photo: types.maybe(RemoteFileModel)
  photo: types.maybe(PhotoModel)
});

export const createTextModel = (data?: any): TextModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return TextModel.create({
    id: mandatoryId(data.id),
    chapter_id: numberOrUndefined(data.chapter_id),
    chapter: createChapterModel(data.chapter),
    // photo: createRemoteFileModel(data.photo) || undefined,
    photo: createPhotoModel(data.photo),
    author: createProfileAsAuthorModel(data.author),
    content: stringOrUndefined(data.content)
  });
};

export type TextModelType = Instance<typeof TextModel>;
export default TextModel;
