import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import CancelLink from 'domain/Links/CancelLink';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { CoverChapterSettingsModelType } from 'models/chapter-settings/CoverChapterSettingsModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ROUTE_LAYOUT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';

interface FrontTextsScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  form: FormType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class FrontTextsScreen extends React.Component<
FrontTextsScreenProps & HistoryProps
> {
  componentDidMount() {
    this.loadCover();
  }

  async loadCover() {
    const { chaptersStore, form } = this.props;

    form.reset();

    const cover = await chaptersStore.getCoverChapter();

    if (cover && !cover.layout_key) {
      this.props.history.replace(ROUTE_LAYOUT + '/theme');
      return;
    }

    const settings = cover?.settings as
      | CoverChapterSettingsModelType
      | undefined;

    form.setField('sub_title', settings?.sub_title || '');
    form.setField('front_header', settings?.front_header || '');
    form.setField('front_footer', settings?.front_footer || '');
  }

  async save() {
    const { chaptersStore, form } = this.props;

    const cover = chaptersStore.itemIfCover;
    if (!cover) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    try {
      await chaptersStore.updateChapterSettings(cover.id, {
        sub_title: form.values.sub_title,
        front_header: form.values.front_header,
        front_footer: form.values.front_footer
      });

      // form.setLoading(false);
      this.props.history.push(ROUTE_LAYOUT + '/cover');
    } catch (error: any) {
      if (!handleFormError(form, error)) {
        form.setError('base', true);
      }

      form.setLoading(false);
    }
  }


  renderForm() {
    const { form } = this.props;

    // const maxLength = !MAX_LENGTH[what] ? undefined : MAX_LENGTH[what];

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Edit front page" />} text={<FormattedMessage id="Edit front page info" />} />

        <FormStackBlock>
          <TextInputComponent
            name="front_header"
            label={
              <FormattedMessage
                id="Enter {what}"
                values={{ what: "Kopfzeile" }}
              />
            }
            {...form.bindInput('front_header')}
            autoFocus={true}
            maxLength={50}
          />

          <CharCounterComponent
            max={50}
            value={form.values["front_header"]}
          />

          <TextInputComponent
            name="sub_title"
            label={
              <FormattedMessage
                id="Enter {what}"
                values={{ what: "Untertitel" }}
              />
            }
            {...form.bindInput('sub_title')}
          />

          <TextInputComponent
            name="front_footer"
            label={
              <FormattedMessage
                id="Enter {what}"
                values={{ what: "Fußzeile" }}
              />
            }
            {...form.bindInput('front_footer')}
            maxLength={50}
          />

          <CharCounterComponent
            max={50}
            value={form.values["front_footer"]}
          />
        </FormStackBlock>


        {form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => this.save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_LAYOUT + '/cover'} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="cover" />
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadCover()} />
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const { chaptersStore, form } = this.props;

    if (chaptersStore.isItemLoading || form.loading) {
      return this.renderLoading();
    }

    if (chaptersStore.isItemLoadError) {
      return this.renderError();
    }

    return this.renderForm();
  }
}

export default (props: FrontTextsScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <FrontTextsScreen {...props} form={form} />;
};
