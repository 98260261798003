import React from 'react';

import { Boundary } from 'utils/chunker/ContentChunker';

interface ColumnComponentProps {
  size: Boundary;
  position: Boundary;
  children: React.ReactNode;
}

export const ColumnComponent = ({
  size,
  position,
  children,
}: ColumnComponentProps) => (
  <div
    style={{
      position: 'absolute',
      left: position[0] + 'px',
      top: position[1] + 'px',
      width: size[0] + 'px',
      height: size[1] + 'px',
    }}
  >
    {children}
  </div>
);

export default ColumnComponent;
