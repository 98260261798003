import React from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import MessageBlock from 'blocks/MessageBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import { BookModelType } from 'models/BookModel';
import { DeliveryDateModelType } from 'models/DeliveryDateModel';
import { FormattedMessage } from 'react-intl';
import TimeplanDate from 'screens/timeplan/TimeplanDate';
import { TimeplanItemState } from 'screens/timeplan/TimeplanItemState';
import TimeplanList from 'screens/timeplan/TimeplanList';
import TimeplanPeriodProgress from 'screens/timeplan/TimeplanPeriodProgress';
import { assert } from 'utils/assert';

const OrderTimeline = ({
  book,
  deliveryDate,
  paymentReceived,
  changeStateShowDeliveryDate,
  showButton
}: {
  book: BookModelType;
  deliveryDate?: DeliveryDateModelType;
  paymentReceived?: boolean;
  changeStateShowDeliveryDate: () => void;
  showButton: boolean;
}) => {
  assert(book);
  assert(deliveryDate);

  const currentDate = new Date();
  const paymentDeadline = deliveryDate.payment_deadline;
  const paymentPossible = deliveryDate.payment_possible;
  // This boolean represents the state if the payment deadline is exceeded but the current date is still smaller then the next occurring friday of the payment deadline
  const waitingForPaymentAfterDeadline = currentDate > paymentDeadline;

  const deliveryDateState: TimeplanItemState =
    book.order_state === 'shipped' || book.order_state === 'done'
      ? 'success'
      : 'open';
  const deliveryDateProgress =
    book.order_state === 'confirmed'
      ? 0.3
      : book.order_state === 'printing'
        ? 0.6
        : book.order_state === 'shipped' || book.order_state === 'done'
          ? 1
          : 0;

  const paymentDeadlineState: TimeplanItemState = book.orderAtLeastConfirmed || paymentReceived
    ? 'success'
    : paymentPossible
      ? waitingForPaymentAfterDeadline
        ? 'waiting'
        : 'open'
      : 'error';
  // Before the payment deadline, fill the progress bar 50%
  const paymentDeadlineProgress = paymentDeadlineState === 'open' ? 0.5 : 1;

  return (
    <>
      <ItemStackBlock gap="XS">

        <Headline.Medium>
          <FormattedMessage id="Delivery date" />
        </Headline.Medium>

        {paymentDeadlineState === 'error' && (
          <>
            <MessageBlock error={true} static={true}>
              <Paragraph>
                <FormattedMessage id="Order payment deadline exceeded" />
              </Paragraph>
            </MessageBlock>

            {showButton && (
              <ButtonBlock
                background="PRIMARY"
                onClick={() => changeStateShowDeliveryDate()}
              >
                <FormattedMessage id="Order new delivery date" />
              </ButtonBlock>
            )}
          </>
        )}

        {paymentDeadlineState === 'waiting' && (
          <MessageBlock warning={true} static={true}>
            <Paragraph>
              <FormattedMessage id="Order payment deadline exceeded waiting" />
            </Paragraph>
          </MessageBlock>
        )}

        {paymentDeadlineState !== 'waiting' && paymentDeadlineState !== 'error' && (
          <Paragraph>
            <FormattedMessage id="Order delivery date info" />
          </Paragraph>
        )}
      </ItemStackBlock>

      <TimeplanList>
        <TimeplanPeriodProgress
          percentElapsed={paymentDeadlineProgress}
          state={paymentDeadlineState}
        />
        <TimeplanDate state={paymentDeadlineState} date={paymentDeadline}>
          <FormattedMessage id="Payment deadline and form" />
        </TimeplanDate>

        <TimeplanPeriodProgress
          percentElapsed={deliveryDateProgress}
          state={paymentDeadlineState}
        />

        <TimeplanDate state={deliveryDateState} date={deliveryDate.delivery_date}>
          <FormattedMessage id="Delivery date" />
        </TimeplanDate>
      </TimeplanList>
    </>
  );
};

export default OrderTimeline;
