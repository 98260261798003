import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import EmptyStateComponent from 'components/EmptyStateComponent';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TocPreviewComponent from 'components/print/layout-editor/TocPreviewComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import ChapterPreviewComponent from 'domain/ChapterPreviewComponent';
import BackToContentLink from 'domain/Links/BackToContentLink';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { Link } from 'react-router-dom';
import { ROUTE_CONTENT, ROUTE_TOC } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

interface TocScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
}

interface TocScreenState {
  deleteState?: 'confirm' | 'loading' | 'error';
}

@inject('applicationStore', 'chaptersStore')
@observer
class TocScreen extends React.Component<
TocScreenProps & HistoryProps,
TocScreenState
> {
  state: TocScreenState = {};

  componentDidMount() {
    const { applicationStore } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature('toc', this.props.location.pathname)
    ) {
      return;
    }

    this.loadChapters();
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const prevId = chapterIdFromMatch(prevProps.match);
    const id = chapterIdFromMatch(this.props.match);

    if (id && prevId !== id) {
      this.loadChapters();
    }
  }

  async loadChapters() {
    await this.props.chaptersStore.getAllChapters();
  }

  confirmDelete() {
    this.setState({
      deleteState: 'confirm'
    });
  }

  abortDelete() {
    this.setState({
      deleteState: undefined
    });
  }

  async deleteChapter() {
    const { chaptersStore } = this.props;

    const chapterId = chapterIdFromMatch(this.props.match);
    if (!chapterId) {
      this.abortDelete();
      return;
    }

    this.setState({
      deleteState: 'loading'
    });

    try {
      await chaptersStore.removeChapter(chapterId);

      this.props.history.replace(ROUTE_CONTENT);
    } catch (e) {
      this.setState({
        deleteState: 'error'
      });
    }
  }

  renderPreview() {
    const { chaptersStore } = this.props;

    const chapterId = chapterIdFromMatch(this.props.match) || 0;
    const chapter = chaptersStore.chapters?.get(chapterId?.toString());

    if (!chapter || chapter.chapter_type !== 'table_of_contents') {
      return null;
    }

    const { toc } = chaptersStore;

    const { deleteState } = this.state;

    return this.renderPage(
      <>
        <ChapterPreviewComponent
          chapter={chapter}
          organizersOnly={true}
          noSettings={true}
          defaultTextId="Table of contents"
          baseRoute={ROUTE_TOC}
          marginBottom="M"
        >
          <FontPreloadComponent chapter={chapter} numPages={1}>
            <TocPreviewComponent
              scaled={true}
              chapter={chapter}
              toc={toc.map((item) => ({
                title: item.toc_title || '',
                page: '00'
              }))}
            />
          </FontPreloadComponent>
        </ChapterPreviewComponent>

        <PageHeader headline={<FormattedMessage id="toc chapter items" />} text={<FormattedMessage id="toc chapter info" />} />

        <Link to={ROUTE_TOC + '/' + chapterId + '/new'}>
          <ButtonBlock background="PRIMARY">
            <FormattedMessage id="toc chapter item add" />
          </ButtonBlock>
        </Link>

        <Divider />

        {toc.length !== 0 ? (
          <ItemStackBlock gap="S">
            <UppercaseHeading>
              <FormattedMessage
                id="toc entries"
                values={{
                  count: toc.length
                }}
              />
            </UppercaseHeading>
            <ListStackBlock>
              {toc.map((item, idx) => (
                <Link
                  to={{
                    pathname:
                      ROUTE_TOC + '/' + chapterId + '/edit/' + item.id,
                    state: {
                      id: chapterId || undefined
                    }
                  }}
                  key={idx}
                >
                  <EveryCard>
                    <EveryCardPadding>
                      <EveryCardBody>
                        <EveryCardHeadline>{item.toc_title}</EveryCardHeadline>
                        <Paragraph size="S"  color="TEXT_LIGHT">
                          <FormattedMessage
                            id={'chapter type ' + item.chapter_type}
                          />
                        </Paragraph>
                      </EveryCardBody>
                    </EveryCardPadding>
                  </EveryCard>
                </Link>
              ))}
            </ListStackBlock>
          </ItemStackBlock>
        ) : (
          <EmptyStateComponent
            headerId="no toc items"
            textId="add toc items"
          />
        )}
        <Divider />

        {deleteState === 'error' && <GenericErrorComponent />}

        <DeleteButton
          messageId="Remove chapter"
          onClick={() => this.confirmDelete()}
        />

        {deleteState === 'confirm' && (
          <ConfirmBoxComponent
            header={<FormattedMessage id="confirm delete header" />}
            text={
              <FormattedMessage
                id="confirm delete item"
                values={{
                  item: <FormattedMessage id="This chapter" />
                }}
              />
            }
            confirmText={<FormattedMessage id="Remove" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => this.deleteChapter()}
            onAbort={() => this.abortDelete()}
            confirmColor="RED"
          />
        )}
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadChapters()} />
    );
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <BackToContentLink />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="toc" />
      </>
    );
  }

  render() {
    const { chaptersStore } = this.props;

    if (chaptersStore.isListLoading || this.state.deleteState === 'loading') {
      return this.renderLoading();
    }

    if (chaptersStore.isListError) {
      return this.renderError();
    }

    return this.renderPreview();
  }
}

export default TocScreen;
