import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import MessageBlock from 'blocks/MessageBlock';
import Paragraph from 'components/Paragraph/Paragraph';

interface InfoBoxComponentProps {
  children: React.ReactChild | React.ReactChild[];
  warning?: boolean;
  error?: boolean;
  success?: boolean;
  fixed?: boolean;
  borderless?: boolean;
  onRetryClick?: (e: any) => void;
  onClick?: (e: any) => void;
}

export const InfoBoxComponent = ({
  children,
  warning,
  error,
  success,
  fixed,
  borderless,
  onRetryClick,
  onClick
}: InfoBoxComponentProps) => (
  <MessageBlock
    warning={warning}
    error={error}
    success={success}
    borderless={borderless}
    static={!fixed}
    onClick={onClick}
  >
    <ItemStackBlock gap="M">
    <Paragraph>{children}</Paragraph>

      {onRetryClick && (
        <Paragraph>
          <ButtonBlock onClick={onRetryClick} background="RED">
            <FormattedMessage id="Try again" />
          </ButtonBlock>
          </Paragraph>
      )}
    </ItemStackBlock>
  </MessageBlock>
);

export default InfoBoxComponent;
