import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { HistoryProps } from 'utils/history';
import TopActionBlock from 'blocks/TopActionBlock';
import BackLink from 'domain/Links/BackLink';
import { ROUTE_LAYOUT, ROUTE_CONTENT } from 'utils/constants/routes';
import Headline from 'elements/Headline';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import FontsPreloader, { Fonts } from 'components/print/FontsPreloader';
import { COVER_PRELOAD_FONTS } from 'components/print/templates/CoverTemplateComponent';
import PageLoadingComponent from 'components/print/layout-editor/PageLoadingComponent';
import { ChapterModelType } from 'models/ChapterModel';
import ChapterLayoutsListItem from './ChapterLayoutsListItem';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import HelpSubject from 'components/HelpButton/HelpSubject';
import EmptyStateComponent from 'components/EmptyStateComponent';
import { EveryCard, EveryCardBody, EveryCardHeadline, EveryCardPadding } from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';
import { Link } from 'react-router-dom';

interface ChapterLayoutsScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class ChapterLayoutsScreen extends React.Component<
ChapterLayoutsScreenProps & HistoryProps
> {
  componentDidMount() {
    // TODO check editing state and only display when editing is actually possible?
    this.load();
  }

  private load() {
    this.props.chaptersStore.getAllChapters();
  }

  private renderPage(content?: any) {
    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_LAYOUT} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Layout editor" />
          </Headline.Large>


          <Paragraph>
            <FormattedMessage id="chapter layouts info" />
          </Paragraph>

          <Link to={ROUTE_CONTENT}>
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="Content" />
                  </EveryCardHeadline>
                </EveryCardBody>
                <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
              </EveryCardPadding>
            </EveryCard>
          </Link>
          
          {content}
        </PageStackBlock>

        <HelpSubject subject="layout_editor" />
      </>
    );
  }

  renderPreviews(chapters: ChapterModelType[], withoutPreviews = false) {
    return (
      <TwoColumnBlock gap="M" gapVertical="L">
        {chapters.map((chapter) => (
          <ChapterLayoutsListItem
            key={chapter.id}
            withoutPreview={withoutPreviews}
            chapter={chapter}
          />
        ))}
      </TwoColumnBlock>
    );
  }

  private renderChapters() {
    const { chaptersStore } = this.props;
    const chapters = chaptersStore.sortedChaptersWithLayoutEditor;

    if (!chapters.length) {
      return this.renderPage(
        <EmptyStateComponent
          headerId="No chapters added yet"
          textId="no chapters info"
        />
      );
    }

    const fonts = (chaptersStore.allFonts as Fonts).concat(COVER_PRELOAD_FONTS);

    return this.renderPage(
      <FontsPreloader
        print={false}
        fonts={fonts}
        loadingComponent={<PageLoadingComponent numPages={1} />}
        errorComponent={() => this.renderPreviews(chapters, true)}
      >
        {this.renderPreviews(chapters)}
      </FontsPreloader>
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  private renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.load()} />
    );
  }

  render() {
    const { isListLoading, isListError } = this.props.chaptersStore;

    if (isListLoading) {
      return this.renderLoading();
    }

    if (isListError) {
      return this.renderError();
    }

    return this.renderChapters();
  }
}

export default ChapterLayoutsScreen;
