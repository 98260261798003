import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import Headline from 'elements/Headline';

import SettingNotAvailableComponent from '../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from './SettingsDetailProps';
import Paragraph from 'components/Paragraph/Paragraph';

const OrderSettingsContainer = ({
  form,
  chapter,
  onCommitSettings
}: SimpleSettingsDetailProps) =>
  !(chapter.isFactsheetChapter || chapter.chapter_type === 'yearbook') ? (
    <SettingNotAvailableComponent />
  ) : (
    <>
      <Headline.Large>
        <FormattedMessage id="Edit sorting order" />
      </Headline.Large>

      <ItemStackBlock gap="M">
        <Paragraph>
          <FormattedMessage id={`Sorting order info ${chapter.chapter_type}`} />
        </Paragraph>

        <RadioButtonComponent
          name="profile_order"
          value="first_name"
          label={<FormattedMessage id="order alphabetically by first name" />}
          {...form.bindRadioButton('profile_order', 'first_name')}
        />

        <RadioButtonComponent
          name="profile_order"
          value="last_name"
          label={<FormattedMessage id="order alphabetically by last name" />}
          {...form.bindRadioButton('profile_order', 'last_name')}
        />

        <ButtonBlock
          background="PRIMARY"
          onClick={() =>
            onCommitSettings({
              profile_order: form.values.profile_order || 'first_name'
            })
          }
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </ItemStackBlock>
    </>
  );

export default OrderSettingsContainer;
