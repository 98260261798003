import React from 'react';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { DeliveryDateModelType } from 'models/DeliveryDateModel';
import {
  FormattedDate,
  FormattedMessage
} from 'react-intl';
import styled from 'styled-components';

const AvailabilityDot = styled.span<{ availableBooks: number }>`
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  border-radius: 3px;
  width: 12px;
  height: 12px;
  background-color: ${({ availableBooks }) =>
    availableBooks > 20
      ? '#2EBE6D'
      : availableBooks > 10
      ? '#FF9400'
      : '#F42525'};
`;

const DeliveryDate = ({
  deliveryDate
}: {
  deliveryDate: DeliveryDateModelType;
}) => {
  const availableBooks = Math.max(0, deliveryDate.available_amount);
  return (
    <HorizontalStackBlock justified={true}>
      <div>
        <Paragraph weight="BOLD">
          <FormattedDate
            value={deliveryDate.delivery_date}
            year="numeric"
            month="2-digit"
            day="2-digit"
          />
        </Paragraph>
        <Paragraph  color="TEXT_LIGHT">
          <FormattedDate value={deliveryDate.delivery_date} weekday="long" />
        </Paragraph>
      </div>
      <Paragraph>
        <FormattedMessage
          id="Delivery date available books"
          values={{
            available_books: availableBooks
          }}
        />
        <AvailabilityDot availableBooks={availableBooks} />
      </Paragraph>
    </HorizontalStackBlock>
  );
};

export default DeliveryDate;
