import { types, Instance } from 'mobx-state-tree';

import convertLayoutTags from './convert-layout-tags';

export const ColorElementModel = types.model('ColorElementModel', {
  name: types.string,
  value: types.string
});
export type ColorElementModelType = Instance<typeof ColorElementModel>;

const ColorSchemeModel = types
  .model('ColorSchemeModel', {
    id: types.identifier,
    name: types.maybe(types.string),
    tags: types.array(types.string),
    origin: types.enumeration(['unknown', 'library', 'api']),
    colors: types.array(ColorElementModel)
  })
  .views((self) => {
    return {
      get templateColors(): ColorElementModelType[] {
        if (!self.colors.length) {
          return [];
        }

        return self.colors.map((elem) => ({
          name: elem.name,
          value: elem.value
        }));
      },
      get previewColors(): [string, string, string] {
        const preview: [string, string, string] = [
          '#000000',
          '#BBBBBB',
          '#DDDDDD'
        ];
        const positions = ['preview1', 'preview2', 'preview3'];

        let set = 0;
        for (const elem of self.colors) {
          const pos = positions.indexOf(elem.name);
          if (pos > -1) {
            preview[pos] = elem.value;
            set++;
          }
          if (set >= 3) {
            break;
          }
        }

        return preview;
      }
    };
  });

export const createColorSchemeModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  const origin = data.id ? 'api' : data.key ? 'library' : 'unknown';
  const id = !data.id ? data.key : data.id.toString();

  if (!id) {
    return undefined;
  }

  return ColorSchemeModel.create({
    id,
    name: data.name?.de || data.name || undefined,
    origin,
    tags: convertLayoutTags(data.tags),
    // TODO add createColorsArray function for this?
    colors: data.colors || []
  });
};

export type ColorSchemeModelType = Instance<typeof ColorSchemeModel>;
export default ColorSchemeModel;
