import React, { ReactNode } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import Divider from 'components/Divider/Divider';
import TextileHeaderComponent from 'components/HeaderComponent/TextileHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TextileOrganizersList from 'domain/textileStudentList/TextileOrganizersList';
import Headline from 'elements/Headline';
import { useTextileStudentDashboardQuery } from 'queries/textile_deals/useTextileDashboardQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const TextileApplicantDashboardScreen: React.FC<HistoryProps> = ({ match }) => {
  const textileOrderId = textileOrderIdFromMatch(match);
  const queryClient = useQueryClient();
  const { data, isError, isLoading, refetch } = useTextileStudentDashboardQuery(
    textileOrderId
  );
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const renderPage = (content?: any) => {
    return (
      <>
        <TextileHeaderComponent
          linkHome={textileOrderRoute(match, '')}
          linkAccount={textileOrderRoute(match, '/account')}
        />

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  const generateHeadline = (text?: ReactNode) => {
    return (
      <PageHeader
        headline={
          <>
            {' '}
            <FormattedMessage
              id="Hello {name}!"
              values={{ name: data?.first_name }}
            />
          </>
        }
        text={text}
      />
    );
  };

  if (!textileOrderId) {
    return null;
  }

  if (isError) {
    return <GenericErrorComponent onRetryClick={() => refetch()} />;
  }

  if (isLoading || !data || !textileAppState) {
    return <LoadingIndicatorComponent />;
  }

  return renderPage(
    <>
      {generateHeadline(
        <FormattedMessage id="dashboard applicant info student" />
      )}

      <Divider />

      <Headline.Medium>
        <FormattedMessage id="Your organizers" />
      </Headline.Medium>

      <TextileOrganizersList textileOrderId={textileOrderId} />
    </>
  );
};

export default TextileApplicantDashboardScreen;
