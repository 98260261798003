import { Instance, types } from 'mobx-state-tree';

import numberOrUndefined from 'utils/store/numberOrUndefined';

// TODO use string here to allow server to introduce new types?

// 'nuggit_next_year_discount', nuggit_textile, nuggit_journey still supported but they are not offered anymore
const DiscountIdentifierEnum = types.enumeration([
  'nuggit_next_year_discount',
  'nuggit_textile',
  'nuggit_special',
  'nuggit_journey',
  'profile'
]);

export type DiscountIdentifierEnumType = Instance<
  typeof DiscountIdentifierEnum
>;

export const DiscountModel = types.model('DiscountModel', {
  name: types.maybe(types.string),
  value: types.number,
  identifier: types.maybe(DiscountIdentifierEnum),
  info: types.maybe(types.string),
  info_done: types.maybe(types.string),
  info_not_used: types.maybe(types.string),
  checked: types.boolean
});

export const createDiscountModel = (
  data?: any
): DiscountModelType | undefined => {
  if (!data) {
    return undefined;
  }

  const info = data.info || undefined;

  return DiscountModel.create({
    name: data.name || undefined,
    value: numberOrUndefined(data.value) || 0,
    identifier: DiscountIdentifierEnum.is(data.identifier)
      ? data.identifier
      : undefined,
    info,
    info_done: data.info_done || info,
    info_not_used: data.info_not_used || undefined,
    checked: data.checked || false
  });
};

export type DiscountModelType = Instance<typeof DiscountModel>;
