import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';

export const CancelClickLink = ({ onClick }: { onClick: (e: any) => void }) => (
  <ButtonBlock link={true} onClick={onClick}>
    <TextElement  color="GRAY10">
      <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
      <FormattedMessage id="Cancel" />
    </TextElement>
  </ButtonBlock>
);

export default CancelClickLink;
