/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Preorder } from 'api/textile_deals/fetchPreorders';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import InputMessage from 'elements/InputMessage';
import { useProfilePreorderOverview } from 'queries/textile_deals/useOrderOverviewQueries';
import {
  useCreateTextileNotificationQuery,
  useUpdateTextileNotificationQuery
} from 'queries/textile_deals/useTextileNotificationQueries';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import {
  useTextileProfileQuery,
  useTextileProfileRejectPreorderQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import TextilePriceComponent from 'screens/preorders/components/TextilePriceComponent';
import TextileVariantCartComponent from 'screens/preorders/components/TextileVariantCartComponent';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';
import isTextileLocked from 'utils/isTextileLocked';

interface FormProps {
  form: FormType;
}

const PreorderReviewScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const [showRejectMessageForm, setShowRejectMessageFrom] = useState<boolean>(
    false
  );
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const textileProfile = useTextileProfileQuery(id, textileOrderId);
  const orderOverview = useProfilePreorderOverview(id, textileOrderId);
  const preorderReject = useTextileProfileRejectPreorderQuery(id);
  const updateTextileNotification = useUpdateTextileNotificationQuery(
    textileOrderId
  );
  const createTextileNotification = useCreateTextileNotificationQuery(
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (textileAppState?.textile_order.order_state === 'preparing') {
      props.history.replace(textileOrderRoute(props.match, '/preorder'));
    }

    accessContext.checkSectionAccess('preorder');

    const data = textileProfile.data;

    if (data && data.preorder_notification) {
      props.form.setField(
        'notification',
        data.preorder_notification.notification || ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessContext,
    textileAppState?.textile_order.order_state,
    textileProfile.data
  ]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink
            onClick={() =>
              showRejectMessageForm
                ? setShowRejectMessageFrom(false)
                : props.history.push(
                    textileOrderRoute(props.match, '/preorder')
                  )
            }
          />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileProfile.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileProfile.refetch()} />
    );
  }

  if (orderOverview.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => orderOverview.refetch()} />
    );
  }

  if (
    !textileAppState ||
    textileProfile.isLoading ||
    !textileProfile.data ||
    orderOverview.isLoading ||
    !orderOverview.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const saveTextileNotification = (textileOrderId: number) => {
    const { form, history, match } = props;
    const profile = textileProfile.data;
    const currentTextileProfile = textileAppState.textile_profile;

    form.setLoading(true);

    if (profile.preorder_notification) {
      const patch = {
        notification: form.values.notification,
        notification_type: 'preorder',
        author_id: currentTextileProfile.id
      };
      updateTextileNotification.mutate(
        {
          data: patch,
          id: profile.preorder_notification.id,
          textileOrderId
        },
        {
          onSuccess: () => {
            history.push(textileOrderRoute(match, '/preorder'));
          },
          onError: () => {
            form.setLoading(false);
          }
        }
      );
    } else {
      const patch = {
        notification: form.values.notification,
        notification_type: 'preorder',
        author_id: currentTextileProfile.id,
        textile_profile_id: profile.id
      };
      createTextileNotification.mutate(
        { data: patch, textileOrderId },
        {
          onSuccess: () => {
            history.push(textileOrderRoute(match, '/preorder'));
          },
          onError: () => {
            form.setLoading(true);
          }
        }
      );
    }
  };

  const preformReject = () => {
    if (!textileOrderId || !id) {
      return;
    }

    props.form.setLoading(true);

    preorderReject.mutate(
      { id, textileOrderId },
      {
        onSettled: () => {
          props.form.setLoading(false);
          saveTextileNotification(textileOrderId);
        }
      }
    );
  };

  const cancel = () => {
    props.form.setConfirm('');
    setShowRejectMessageFrom(false);
  };

  const renderRejected = () => {
    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Preorder" />}
          text={
            <>
              <IconComponent icon="USER" /> {textileProfile.data.name}
            </>
          }
        />

        {textileProfile.data.preorder_notification && (
          <InputMessage info={true}>
            <Paragraph weight="BOLD" size="L">
              <FormattedMessage id="message from organiser" />
            </Paragraph>

            <Divider color="PURPLE" />

            <Paragraph>
              {textileProfile.data.preorder_notification.notification}
            </Paragraph>
            <Paragraph>
              <IconComponent icon="ORGANIZER" fill="PURPLE" />
              {textileProfile.data.preorder_notification.author_name}
            </Paragraph>
          </InputMessage>
        )}
      </>
    );
  };

  const renderMessageForm = () => {
    const { form } = props;

    let extra;
    if (form.confirm === 'edit') {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="preorder reject header" />}
          text={<FormattedMessage id="preorder reject info" />}
          confirmText={<FormattedMessage id="preorder reject confirm" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => preformReject()}
          onAbort={() => cancel()}
          confirmColor="RED"
        />
      );
    }

    return renderPage(
      <>
        {extra}
        <PageHeader
          headline={<FormattedMessage id="preorder reject reason" />}
          text={<FormattedMessage id="preorder reject reason info" />}
        />

        <FormStackBlock>
          <TextareaComponent
            name="notification"
            height={7}
            {...form.bindInput('notification')}
            maxLength={60}
          />

          <CharCounterComponent max={60} value={form.values.notification} />
        </FormStackBlock>
        <ButtonBlock
          background="PRIMARY"
          onClick={() => form.setConfirm('edit')}
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          onClick={() => form.setConfirm('edit')}
        >
          <FormattedMessage id="Skip" />
        </ButtonBlock>
      </>
    );
  };

  const renderReview = () => {
    const { preorders, textiles, price } = orderOverview.data;
    const profile_name = textileProfile.data.name;

    const layout = textileAppState.textile_order.theme;
    if (!layout || !textiles || textiles.length === 0) {
      return null;
    }

    if (textileProfile.data.preorder_state === 'rejected') {
      return renderRejected();
    }

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Preorder" />}
          text={
            <>
              <IconComponent icon="USER" /> {profile_name}
            </>
          }
        />
        {preorders && preorders.length > 0 && price && (
          <>
            {preorders.map((preorder: Preorder) => {
              const textile = textiles.find(
                (itm) => itm.name === preorder.textile_name
              );

              if (!textile || !textile.possible_price || !textile.end_price) {
                return null;
              }
              const variant = textile?.textile_variants?.find(
                (itm) => itm.id === preorder.textile_variant_id
              );
              return (
                <TextileVariantCartComponent
                  textileVariant={variant}
                  layout={layout}
                  size={preorder.size}
                  textileName={preorder.textile_name}
                  preorderId={preorder.id}
                  key={preorder.id}
                  possiblePrice={textile.possible_price}
                  endPrice={textile.end_price}
                />
              );
            })}
          </>
        )}

        {price && (
          <TextilePriceComponent
            endPrice={price.price.end_price}
            possiblePrice={price.price.possible_price}
            range={
              !isTextileLocked(
                textileAppState.textile_order.order_state,
                'atLeastOrderStarted'
              )
            }
            link={textileOrderRoute(props.match, '/discounts')}
          />
        )}

        <ButtonBlock
          background="RED_LIGHT"
          color="RED_DARK"
          onClick={() => setShowRejectMessageFrom(true)}
        >
          <FormattedMessage id="denie preorder" />
        </ButtonBlock>
      </>
    );
  };

  if (showRejectMessageForm) {
    return renderMessageForm();
  }

  return renderReview();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <PreorderReviewScreen {...props} form={form} />;
};
