import { ChapterType } from 'models/ChapterTypeEnum';
import { IconNameType } from 'components/IconComponent/svg-sprites-info';

const DEFAULT_ICON: IconNameType = 'chapter_factsheets';

const chapterIconsMap: {
  [chapterType in ChapterType]?: IconNameType;
} = {
  album: 'chapter_album',
  factsheet: 'chapter_factsheets',
  teacher_factsheet: 'chapter_factsheets',
  yearbook: 'chapter_yearbook',
  quote: 'chapter_quotes',
  text: 'chapter_text',
  sponsor: 'money',
  ranking: 'chapter_rankings',
  table_of_contents: 'chapter_toc'
} as const;

const chapterIcon = (chapterType: ChapterType): IconNameType => {
  return chapterIconsMap[chapterType] || DEFAULT_ICON;
};

export default chapterIcon;
