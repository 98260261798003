import styled from 'styled-components';

const TaglineBlock = styled.div`
  flex-wrap: wrap;
  align-items: center;
  display: flex;

  > * + * {
    margin-left: 0.3125rem;
  }
`;

export default TaglineBlock;
