import React from 'react';

import { PhotoTypeEnum } from 'models/PhotoModel';
import NotFoundScreen from 'screens/NotFoundScreen';
import FactsheetDetailScreen from './FactsheetDetailScreen';
import { HistoryProps } from 'utils/history';

export default (props: HistoryProps) => {
  const type = props.match.params.type;

  if (!PhotoTypeEnum.is(type)) {
    return <NotFoundScreen />;
  }

  return <FactsheetDetailScreen {...props} photoType={type} />;
};
