import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface InfoElementProps {
  color?: keyof typeof COLORS;
  size?: number;
}

export default styled.div<InfoElementProps>`
  margin: 0;
  font-size: ${(props) => (props.size ? props.size + 'rem' : '.75rem')};
  letter-spacing: 0;
  color: ${COLORS.TEXT_LIGHT};
`;
