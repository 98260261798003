import React from 'react';
import { FormattedMessage } from 'react-intl';

import IconComponent from 'components/IconComponent';
import { RankingModelType } from 'models/RankingModel';

export const RankingOwnVoteComponent = ({
  ranking
}: {
  ranking?: RankingModelType;
}) => {
  if (!ranking || !ranking.did_vote) {
    return (
      <>
        <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />{' '}
        <FormattedMessage id="Not voted yet" />
      </>
    );
  }

  if (ranking && ranking.own_votes && ranking.own_votes.size > 0) {
    const names = [];
    for (const c of ranking.own_votes.values()) {
      names.push(c.name);
    }

    return (
      <>
        <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
        <FormattedMessage
          id="voted for"
          values={{
            name: names.join(' & ')
          }}
        />
      </>
    );
  }

  return (
    <>
      <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
      <FormattedMessage id="Already voted" />
    </>
  );
};

export default RankingOwnVoteComponent;
