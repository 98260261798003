import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBlock from 'blocks/HeaderBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ProgressbarComponent from 'components/ProgressbarComponent';
import BackLink from 'domain/Links/BackLink';
import CancelLink from 'domain/Links/CancelLink';

import BackClickLink from 'domain/Links/BackClickLink';
import Logo from './Logo';

// img height to avoid flickering when image is not loaded yet. not required on inline svg.
export const SignupHeaderComponent = ({
  backRoute,
  cancelRoute,
  logoRoute,
  children,
  progressStatus,
  backClick
}: {
  backClick?: () => void;
  backRoute?: string;
  cancelRoute?: string;
  logoRoute?: string;
  children?: React.ReactNode;
  progressStatus?: number;
}) => {
  let placeholder;
  if (!children && !backRoute) {
    placeholder = <span />;
  }

  let logo = <Logo />;
  if (logoRoute) {
    logo = <Link to={logoRoute}>{logo}</Link>;
  }

  return (
    <>
      {progressStatus && (
        <ProgressbarComponent progressStatus={progressStatus} />
      )}
      <HeaderBlock>
        <HorizontalStackBlock justified={true}>
          {backClick && <BackClickLink onClick={() => backClick()} />}
          {backRoute && <BackLink to={backRoute} />}
          {cancelRoute && <CancelLink to={cancelRoute} />}

          {placeholder}
          {children}

          {logo}
        </HorizontalStackBlock>
      </HeaderBlock>
    </>
  );
};

export default SignupHeaderComponent;
