import { intl } from 'i18n';
import React, { createRef, useState } from 'react';

import TextInputComponent from './TextInputComponent';

type InputProps = Parameters<typeof TextInputComponent>[0];

const PasswordInputComponent = (props: InputProps) => {
  const [visible, setVisible] = useState(false);
  const ref = createRef<HTMLInputElement>();

  return (
    <TextInputComponent
      type={visible ? 'text' : 'password'}
      icon={visible ? 'make_hidden' : 'make_visible'}
      onIconClick={() => {
        setVisible(!visible);
        if (ref && ref.current) {
          const c = ref.current;
          c.focus();

          const l = c.value.length;
          setTimeout(() => c.setSelectionRange(l, l), 10);
        }
      }}
      iconSize={1.75}
      ref={ref}
      placeholder={intl.formatMessage({ id: 'Password' })}
      {...props}
    />
  );
};

export default PasswordInputComponent;
