import React from 'react';

import {
  Route,
  Switch
} from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_TEXTILE_DESIGN_GROUPS } from 'utils/constants/routes';
import StudentsWithoutTextileGroupScreen from './screens/StudentsWithoutTextileGroupScreen';

import TeachersWithoutTextileGroupScreen from './screens/TeachersWithoutTextileGroupScreen';
import TextileGroupAdd from './screens/TextileGroupAdd';
import TextileGroupEdit from './screens/TextileGroupEdit';
import TextileGroupOverview from './screens/TextileGroupOverview';
import TextileGroupsSorting from './screens/TextileGroupsSorting';

export default () => (
  <Switch>
    <Route path={ROUTE_TEXTILE_DESIGN_GROUPS + '/add'} component={TextileGroupAdd} />
    <Route path={ROUTE_TEXTILE_DESIGN_GROUPS + '/edit/:id'} component={TextileGroupEdit} />
    <Route path={ROUTE_TEXTILE_DESIGN_GROUPS + '/sorting'} component={TextileGroupsSorting} />
    <Route path={ROUTE_TEXTILE_DESIGN_GROUPS + '/students_without_group'} component={StudentsWithoutTextileGroupScreen} />
    <Route path={ROUTE_TEXTILE_DESIGN_GROUPS + '/teachers_without_group'} component={TeachersWithoutTextileGroupScreen} />
    <Route path={ROUTE_TEXTILE_DESIGN_GROUPS} exact={true} component={TextileGroupOverview} />

    <NotFoundRoute />
  </Switch>
);
