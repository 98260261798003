import React, { ReactNode } from 'react';

import TextElement from 'components/TextElement/TextElement';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface EveryCardProps {
  selected?: boolean;
  error?: boolean;
  disabled?: boolean;
  topAlign?: boolean; // needed when TagBlock is shown in the upper right corner
  slim?: boolean;
  extraSlim?: boolean;
}

interface EveryCardBodyProps {
  right?: boolean;
}

interface EveryCardPaddingProps {
  center?: boolean;
  extraSlim?: boolean;
  noAlignment?: boolean;
}

interface EveryCardFooterProps {
  textStart?: boolean;
  background?: keyof typeof COLORS;
}

/**
 * @example
 *
 * // ===========
 * // Minimal usage:
 * // ===========
 *
 * <EveryCard>
 *   <EveryCardPadding>
 *     <EveryCardBody>
 *       Vertically Aligned Text Elements go here
 *     </EveryCardBody>
 *   </EveryCardPadding>
 * </EveryCard>
 *
 * @example
 *
 * // ===========
 * // Full usage:
 * // ===========
 *
 * <EveryCard topAlign={true}>
 *   <EveryCardPadding>
 *     < Place optional Image or Icon here eg.ImageBlock >
 *
 *     <EveryCardBody>
 *       Vertically Aligned Text Elements go here
 *     </EveryCardBody>
 *
 *     <TagBlock></TagBlock>
 *   </EveryCardPadding>
 *
 *   <EveryCardFooter>
 *     <TextElement  color="TEXT_DARK">
 *       <IconComponent icon="WARNING_TRIANGLE" />
 *       Footer Content
 *     </TextElement>
 *   </EveryCardFooter>
 * </EveryCard>
 */
export const EveryCard = styled.div<EveryCardProps>`
  display: block;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  border: 1px solid ${COLORS.GRAY800};
  border-radius: 0.625rem;
  min-height: 3.375rem;
  background: ${COLORS.WHITE};

  /* Reset padding so <EveryCard as="button"> can be used */
  padding: 0;
  ${(props) =>
    props.selected &&
    `
    border: 1px solid ${COLORS.PRIMARY};
    `};
  ${(props) =>
    props.error &&
    `
    background: rgba(${COLORS.RED_RGB}, 0.2);
    box-shadow:
      0 0.25em 1.5625em 0 rgba(0, 0, 0, 0.08),
      0 0 0 2px ${COLORS.RED} inset;
  `};
  ${(props) =>
    props.disabled &&
    `
    color: ${COLORS.GRAY800};
    border-color: ${COLORS.GRAY900};
    background: ${COLORS.GRAY950};
    cursor: not-allowed;
  `}

  ${(props) =>
    props.topAlign &&
    `
    ${EveryCardPadding} {
        align-items: flex-start;
    `};

  ${(props) =>
    props.slim &&
    `
    width: fit-content;
    min-width: 90px;
  `};

  ${(props) =>
    props.extraSlim &&
    `
    width: fit-content;
    min-width: 70px;
    min-height: 2.5rem;
  `};
`;

export const EveryCardPadding = styled.div<EveryCardPaddingProps>`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  ${(porps) => !porps.noAlignment && (
    'align-items: center'
  )};

  > * + * {
    margin-left: 0.9375rem;
  }

  ${(props) =>
    props.center &&
    `
    justify-content: center;
  `};

  ${(props) =>
    props.extraSlim &&
    `
    padding: 0.8rem;
  `};
`;

export const EveryCardBody = styled.div<EveryCardBodyProps>`
  flex-direction: column;
  display: flex;
  align-items: ${(props) => (props.right ? 'flex-end' : 'flex-start')};
  flex-grow: 1;
  gap: 0.3125rem;
`;

export const EveryCardFooter = styled.div<EveryCardFooterProps>`
  padding: 0.6875rem 1rem;
  background: ${(props) => (props.background ? COLORS[props.background] : COLORS.RED_LIGHT)};
  text-align: ${(props) => (props.textStart ? 'start' : 'center')}
`;

// export const EveryCardHeadline = styled.h3`
//   font-size: ${FONT_SIZES.L};
//   font-weight: ${FONT_WEIGHTS.BOLD};
//   color: ${COLORS.BLACK};
// `

export const EveryCardHeadline = (props: {
  children: ReactNode;
  color?: keyof typeof COLORS;
}) => (
  <TextElement
    size="L"
    weight="BOLD"
    color={props.color ? props.color : 'BLACK'}
    children={props.children}
    as="h3"
  />
);
