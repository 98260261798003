import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import Headline from 'elements/Headline';
import { FormType } from 'utils/hooks/useForm';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

// TODO: Think about translations
const SCHOOL_TYPES = ['Gymnasium', 'Berufsschule', 'Realschule', 'Andere'];

const SchoolType = ({ form }: { form: FormType }) => (
  <PageStackBlock>
    <Headline.Large>
      <FormattedMessage id="funnel school" />
    </Headline.Large>

    <ListStackBlock>
      {SCHOOL_TYPES.map((school) => (
        <Link
          key={school}
          to="?screen=class_size"
          onClick={() => form.setField('school_type', school)}
        >
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>{school}</EveryCardHeadline>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </Link>
      ))}
    </ListStackBlock>
  </PageStackBlock>
);

export default SchoolType;
