import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  MiniCard, MiniCardBody, MiniCardImage, MiniCardPadding, MiniCardStatus
} from 'blocks/MiniCard/MiniCard';
import IconComponent, {
  IconComponentProps
} from 'components/IconComponent/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import ProgressbarComponent from 'components/ProgressbarComponent';
import Headline from 'elements/Headline';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

const StyledLinkExternal = styled.a`
  align-self: stretch;
`;
const StyledLink = styled(Link)`
  align-self: stretch;
`;

type Color = keyof typeof COLORS;

export const MINI_CARD_COLOR_SCHEMES: {
  [key: string]: {
    icon: Color;
    light: Color;
    lightContrast: Color;
    dark: Color;
    darkContrast: Color;
    title: Color;
    text: Color;
  };
} = {
  default: {
    icon: 'PRIMARY',
    light: 'PRIMARY_LIGHT',
    lightContrast: 'PRIMARY', // PRIMARY_DARK looks odd next to unexplored cards
    dark: 'PRIMARY',
    darkContrast: 'WHITE',
    title: 'BLACK',
    text: 'GRAY400'
  },
  blue: {
    icon: 'SECONDARY',
    light: 'SECONDARY_LIGHT',
    lightContrast: 'SECONDARY',
    dark: 'SECONDARY',
    darkContrast: 'WHITE',
    title: 'BLACK',
    text: 'GRAY400'
  }
};

interface MiniCardComponentProps {
  mode?: 'new' | 'default' | 'hint';
  scheme?: keyof typeof MINI_CARD_COLOR_SCHEMES;

  image?: string;
  imageAlt?: string;

  icon?: IconComponentProps['icon'] | 'working';
  iconColor?: IconComponentProps['fill'];
  title: React.ReactFragment;

  label?: React.ReactFragment;
  progress?: number;
  button?: React.ReactFragment;
  meta?: React.ReactFragment;

  wide?: boolean;

  disabled?: boolean;
  externalLink?: boolean;
  linkTo?: LinkProps['to'] | string;
  onClick?: () => void;

  children?: any;
}

export const MiniCardComponent = ({
  mode = 'default',
  scheme = 'default',
  icon,
  iconColor,
  title,

  image,
  imageAlt,

  children,
  label,
  progress,
  button,
  meta,

  wide,

  disabled,
  externalLink,
  linkTo,
  onClick
}: MiniCardComponentProps) => {
  const colors = MINI_CARD_COLOR_SCHEMES[scheme];

  const isHintMode = mode === 'hint';
  const actualIcon = icon === 'working' ? 'hourglass' : icon;

  let card;
  if (isHintMode) {
    card = (
      <MiniCard
        sideToSide={wide}
        background="BLACK_003"
        borderColor="BLACK_003"
        centered={true}
        as={!onClick || disabled ? undefined : 'button'}
        onClick={!disabled ? onClick : undefined}
      >
        <MiniCardPadding>
          {actualIcon && (
            <IconComponent
              icon={actualIcon}
              fill={iconColor || colors.icon}
              size={3}
            />
          )}

          <MiniCardBody>
            <Headline.Small color={colors.title}>{title}</Headline.Small>

            {children}

            {label && (
              <Paragraph size="XS" color={colors.text}>
                {label}
              </Paragraph>
            )}
          </MiniCardBody>
        </MiniCardPadding>
      </MiniCard>
    );
  } else {
    const isNewMode = mode === 'new';
    card = (
      <MiniCard
        sideToSide={wide}
        background={isNewMode ? colors.light : undefined}
        borderColor={isNewMode ? 'BLACK_003' : undefined}
        centered={isNewMode}
        as={!onClick || disabled ? undefined : 'button'}
        onClick={!disabled ? onClick : undefined}
      >
        {image && <MiniCardImage src={image} alt={imageAlt || ''} />}

        <MiniCardPadding>
          {actualIcon && !image && (
            <IconComponent
              icon={actualIcon}
              fill={iconColor || colors.icon}
              circleBackground={isNewMode ? undefined : colors.light}
              size={3}
            />
          )}

          <MiniCardBody>
            <Headline.Small color={colors.title}>{title}</Headline.Small>

            {children}

            {label && (
              <Paragraph size="XS" color={colors.text}>
                {label}
              </Paragraph>
            )}
          </MiniCardBody>

          {typeof progress === 'number' && (
            <ProgressbarComponent progressStatus={progress} isStatic={true} />
          )}

          {button && !disabled && (
            <ButtonBlock
              as="div"
              background={isNewMode ? colors.dark : colors.light}
              color={isNewMode ? colors.darkContrast : colors.lightContrast}
              slim={true}
            >
              {button}
            </ButtonBlock>
          )}
        </MiniCardPadding>

        {meta && <MiniCardStatus>{meta}</MiniCardStatus>}
      </MiniCard>
    );
  }

  if (linkTo && !disabled) {
    if (externalLink) {
      return (
        <StyledLinkExternal
          href={linkTo as string}
          onClick={onClick}
          // target="_blank"
          rel="noopener noreferrer"
          style={{
            alignSelf: 'stretch'
          }}
        >
          {card}
        </StyledLinkExternal>
      );
    }

    return (
      <StyledLink to={linkTo} onClick={onClick} style={{
        alignSelf: 'stretch'
      }}>
        {card}
      </StyledLink>
    );
  }

  return card;
};

export default MiniCardComponent;
