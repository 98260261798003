import { Boundary } from './ContentChunker';

export default function shrinkTeacherNames(
  textContent: string[],
  startingFontSize: number,
  minFontSize: number,
  style: string,
  [outerWidth, outerHeight]: Boundary,
  shrinkingStep: number,
  rowCount: number
): [number, boolean] {
  const outerElement = document.createElement('div');
  outerElement.setAttribute(
    'style',
    `display: block; visibility: hidden; width: ${outerWidth}px; height: ${outerHeight};`
  );

  const innerElement = document.createElement('div');
  outerElement.appendChild(innerElement);

  document.body.appendChild(outerElement);


  let fontSize = startingFontSize;
  while (true) {
    innerElement.replaceChildren();
    innerElement.setAttribute(
      'style',
      `${style} font-size: ${fontSize}px; width: fit-content;`
    );
    textContent.forEach((content, index) => {
      const item = document.createElement('p');
      item.setAttribute('style', 'padding: 0 7px')
      if (index !== 0) {
        item.innerText = ' • ' + content;
      } else {
        item.innerText = content;
      }
      innerElement.appendChild(item);
    });

    // measure
    const { height, width } = innerElement.getBoundingClientRect();

    const calculatedHeightAllRows = height * rowCount;

    if (fontSize < minFontSize) {
      outerElement.remove();
      return [minFontSize, true];
    }

    if (width <= outerWidth) {
      if (calculatedHeightAllRows <= outerHeight) {
        outerElement.remove();
        return [fontSize, false];
      }
    } else {
      if (rowCount !== 5) {
        outerElement.remove();
        return [minFontSize, true];
      }
    }
    fontSize -= shrinkingStep;
  }
}
