import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBox from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import LockedLabel from 'domain/Label/LockedLabel';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { RankingsStoreType } from 'screens/rankings/RankingsStore';
import { ROUTE_RANKINGS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import chapterIdFromMatch, {
  backRouteFromMatch
} from 'utils/history/chapter-id-from-match';
import { FormType, handleFormError, useForm } from 'utils/hooks/useForm';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

interface CandidateDetailScreenProps {
  rankingsStore: RankingsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('rankingsStore', 'applicationStore')
@observer
class CandidateDetailScreen extends Component<
  CandidateDetailScreenProps & HistoryProps
> {
  async loadCandidate(id: number) {
    const { rankingsStore, form } = this.props;

    if (rankingsStore.isCandidateItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const candidate = await rankingsStore.getCandidate(id);

    if (candidate) {
      form.setField('name', candidate.name || '');
    }
  }

  async componentDidMount() {
    const id = idFromMatch(this.props.match);
    if (id) {
      await this.loadCandidate(id);
    } else {
      // new item mode
      this.props.rankingsStore.clearCurrentCandidateItem();
      this.props.form.reset();
    }
  }

  async componentDidUpdate(prevProps: any) {
    const newId = idFromMatch(this.props.match);
    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new item mode
          this.props.rankingsStore.clearCurrentCandidateItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        await this.loadCandidate(newId);
      }
    }
  }

  navigateToList() {
    this.props.history.replace(
      ROUTE_RANKINGS +
        '/' +
        chapterIdFromMatch(this.props.match) +
        '/candidates'
    );
  }

  async handleSubmit() {
    const { form, rankingsStore, applicationStore } = this.props;
    const { name } = form.values;

    form.resetErrors();

    const patch: any = {
      name: !name ? '' : name.toString().trim()
    };

    try {
      if (this.isAddMode()) {
        patch.chapter_id = chapterIdFromMatch(this.props.match);

        await rankingsStore.createCandidate(patch);
      } else {
        const id = rankingsStore.candidateItem?.id;
        if (!id) {
          return;
        }

        await rankingsStore.updateCandidate(id, patch);
      }
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }

    applicationStore.setDefaultFlashMessage('saved');
    this.navigateToList();
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  async performDelete() {
    const { rankingsStore } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return;
    }

    this.finishDelete();

    try {
      await rankingsStore.removeCandidate(id);
    } catch (error: any) {
      // delete errors are handled by store internally
      return;
    }

    this.navigateToList();
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  isAddMode() {
    return !idFromMatch(this.props.match) ? true : false;
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  renderReadOnly() {
    const { rankingsStore } = this.props;

    return this.renderPage(
      <PageStackBlock>
        <LockedLabel id="Candidates" />

        <Paragraph>{rankingsStore.candidateItem?.name}</Paragraph>
      </PageStackBlock>
    );
  }

  renderEditForm() {
    const { rankingsStore, form } = this.props;
    const isAddMode = this.isAddMode();

    let extra;
    if (form.confirm) {
      extra = (
        <ConfirmBox
          header={<FormattedMessage id="confirm delete header" />}
          text={
            <FormattedMessage
              id="confirm delete item"
              values={{
                item: <FormattedMessage id="This candidate" />
              }}
            />
          }
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => this.performDelete()}
          onAbort={() => this.finishDelete()}
          confirmColor="RED"
        />
      );
    } else if (rankingsStore.candidateItemLoadingState === 'update_error') {
      extra = <GenericErrorComponent />;
    }

    return this.renderPage(
      <>
        {extra}

        <TextInputComponent
          name="name"
          label={intl.formatMessage({ id: 'Name' })}
          {...form.bindInput('name')}
          autoFocus={true}
        />

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id={isAddMode ? 'Add' : 'Save'} />
        </ButtonBlock>

        {!isAddMode && (
          <>
            <Divider />
            <DeleteButton onClick={() => this.confirmDelete()} />
          </>
        )}
      </>
    );
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink
            to={
              backRouteFromMatch(ROUTE_RANKINGS, this.props.match) +
              '/candidates'
            }
          />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage
              id={this.isAddMode() ? 'Add candidate' : 'Edit candidate'}
            />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { rankingsStore } = this.props;

    if (rankingsStore.isCandidateItemLoading) {
      return this.renderLoading();
    }

    if (rankingsStore.candidateItemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    // TODO send chapter to client and re-enable this?
    // if (
    //   !this.isAddMode() &&
    //   !applicationStore.isChapterEditAllowed(
    //     rankingsStore.candidateItem?.chapter
    //   )
    // ) {
    //   return this.renderReadOnly();
    // }

    return this.renderEditForm();
  }
}

export default (props: any) => {
  const form = useForm();
  return <CandidateDetailScreen {...props} form={form} />;
};
