import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TabBarBlock from 'blocks/TabBarBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import SharingComponent from 'components/SharingComponent';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import StudentsList from 'domain/StudentsList/StudentsList';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { ROUTE_DASHBOARD, ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

import ManageStudentsListItem from './ManageStudentsListItem';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import { EveryCard, EveryCardHeadline, EveryCardPadding } from 'blocks/EveryCard/EveryCard';
import useForm, { FormType } from 'utils/hooks/useForm';
import { intl } from 'i18n';
import NakedButton from 'elements/NakedButton';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';

interface ManageStudentsScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
  form: FormType;
}

@inject('applicationStore', 'profilesStore')
@observer
export class ManageStudentsScreen extends React.Component<
ManageStudentsScreenProps & HistoryProps
> {
  state = {
    showFilter: false
  }

  componentDidMount() {
    if (!this.props.applicationStore.isOrganizer) {
      this.props.history.replace(ROUTE_DASHBOARD);
    }

    this.props.applicationStore.onboardFeature('class');
    this.loadGroups();
  }

  loadGroups() {
    this.props.profilesStore.getGroups();
  }

  componentDidUpdate() {
    if (!this.props.applicationStore.isOrganizer) {
      this.props.history.replace(ROUTE_DASHBOARD);
    }
  }

  setStudentFilter(filter: string | number, name?: string) {
    // this.props.form.values.studentFilter = filter;

    this.props.form.setField('studentFilter', filter);
    if (name) {
      this.props.form.setField('groupName', name);
    } else {
      this.props.form.setField('groupName', undefined);
    }

    this.setState({ showFilter: false })
  }

  showFilter() {
    const { profilesStore, applicationStore } = this.props;

    const groups = profilesStore.sortedGroups;
    const { book } = applicationStore;


    return (
      <>
        <TopActionBlock>
          <NakedButton onClick={() => this.setState({ showFilter: false })}>
            <TextElement color="GRAY10">
              <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
              <FormattedMessage id="Cancel" />
            </TextElement>
          </NakedButton>
        </TopActionBlock>
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="filter list" />
          </Headline.Large>

          <ListStackBlock>
            <EveryCard onClick={() => this.setStudentFilter(intl.formatMessage({ id: 'All students' }))}>
              <EveryCardPadding>
                <EveryCardHeadline>
                  <FormattedMessage id="All students" />
                </EveryCardHeadline>
              </EveryCardPadding>
            </EveryCard>

            <EveryCard onClick={() => this.setStudentFilter(intl.formatMessage({ id: 'Organizers' }))}>
              <EveryCardPadding>
                <EveryCardHeadline>
                  <FormattedMessage id="Organizers" />
                </EveryCardHeadline>
              </EveryCardPadding>
            </EveryCard>


            <EveryCard onClick={() => this.setStudentFilter(intl.formatMessage({ id: 'Virtual students' }))}>
              <EveryCardPadding>
                <EveryCardHeadline>
                  <FormattedMessage id="Virtual students" />
                </EveryCardHeadline>
              </EveryCardPadding>
            </EveryCard>

            {book?.groups_applied && (
              <>
                {groups.map((group) => {
                  if (!group.name) {
                    return null;
                  }

                  return (
                    <EveryCard onClick={() => this.setStudentFilter(group.id, group.name)} key={group.id}>
                      <EveryCardPadding>
                        <EveryCardHeadline>
                          {group.name}
                        </EveryCardHeadline>
                      </EveryCardPadding>
                    </EveryCard>
                  )
                })}
              </>
            )}

            <EveryCard onClick={() => this.setStudentFilter(intl.formatMessage({ id: 'No group' }))}>
              <EveryCardPadding>
                <EveryCardHeadline>
                  <FormattedMessage id="No group" />
                </EveryCardHeadline>
              </EveryCardPadding>
            </EveryCard>
          </ListStackBlock>
        </PageStackBlock>
      </>
    )
  }

  private renderPage(content?: any) {
    const {
      applicationStore: { book }
    } = this.props;

    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="My class(students)" />} text={book?.school && book.year && (<><FormattedMessage
            id="school year"
            values={{ year: book.year }}
          />
            <br />
            {book.school.name}</>)} />

          <TabBarBlock>
            <Paragraph>
              <FormattedMessage id="Students" />
            </Paragraph>
            <Link to={ROUTE_SETTINGS + '/groups'}>
              <FormattedMessage id="Groups" />
            </Link>
          </TabBarBlock>

          <ItemStackBlock gap="XS">
            <Headline.Medium >
              <FormattedMessage id="Students" />
            </Headline.Medium>

            <Paragraph color="TEXT_LIGHT">
              <FormattedMessage id="manage students info" />
            </Paragraph>
          </ItemStackBlock>

          {content}
        </PageStackBlock>

        <HelpSubject subject="students" />
      </>
    );
  }

  render() {
    const { applicationStore, form } = this.props;
    const { book } = applicationStore;

    if (!book) {
      return null;
    }

    if (this.state.showFilter) {
      return (
        this.showFilter()
      )
    }

    return this.renderPage(
      <>
        <SharingComponent
          label="invite header"
          buttonOutside={true}
          modalHeader="invite link"
          message="Nuggit invite text"
          buttonColor="WHATSAPP"
          url={book.invitation_url}
        />
        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          onClick={() =>
            this.props.history.push(ROUTE_SETTINGS + '/students/add')
          }
        >
          <FormattedMessage id="add student link" />
        </ButtonBlock>

        <Divider />

        <StudentsList
          showFilter={true}
          studentFilter={form.values.studentFilter}
          groupName={form.values.groupName}
          itemComponent={ManageStudentsListItem}
          onFilterClick={() => {
            this.setState({
              showFilter: true
            })
          }} />
      </>
    );
  }
}

export default (props: ManageStudentsScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <ManageStudentsScreen {...props} form={form} />;
};
