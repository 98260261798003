import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

const StatusBlock = styled.div`
  background: ${COLORS.GRAY950};
  padding: 0.75rem 2.375em 0.75rem 1.25em;
`;

export default StatusBlock;
