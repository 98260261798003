/**
 * Thin wrapper around storages conforming to the sessionStorage/localStorage API
 * that stores objects/values as JSON.
 *
 * Silently absorbs all method calls if storage is unavailable.
 * @param storageName The type of storage (session or local)
 */
const storageFactory = (storageName: 'sessionStorage' | 'localStorage') => {
  let isEnabled: boolean;
  let storage: Storage;
  try {
    // Check whether we can access storage and write to it
    storage = window[storageName];
    const key = 'storageFactoryTest';
    const value = `foo-${new Date().getTime()}`;
    storage.setItem(key, value);
    isEnabled = storage.getItem(key) === value;
    storage.removeItem(key);
  } catch (e) {
    isEnabled = false;
  }

  /** Proxy storage to prevent runtime errors */
  const storageProxy = {
    getItem(key: string) {
      if (isEnabled) {
        return storage.getItem(key);
      } else {
        return null;
      }
    },
    setItem(key: string, val: string) {
      if (isEnabled) {
        return storage.setItem(key, val);
      }
    },
    removeItem(key: string) {
      if (isEnabled) {
        return storage.removeItem(key);
      }
    }
  };

  // Public API
  return {
    /** Whether the storage is enabled */
    enabled: isEnabled,

    /** Fetches a stored value by key and returns the parsed value. */
    get(key: string) {
      const json = storageProxy.getItem(key);
      if (json !== null) {
        return JSON.parse(json);
      } else {
        return null;
      }
    },

    /** Stores a key/value pair and returns the serialized JSON. */
    set(key: string, value: any) {
      const json = JSON.stringify(value);
      storageProxy.setItem(key, json);
      return json;
    },

    /** Removes an entry by key. */
    remove(key: string) {
      return storageProxy.removeItem(key);
    }
  };
};

export const session = storageFactory('sessionStorage');
export const local = storageFactory('localStorage');
