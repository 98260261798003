import styled, { AnyStyledComponent } from 'styled-components';

import ListItemElement from './ListItemElement';
import NumberElement from './NumberElement';

interface NumberListBlockElements {
  ListItemElement?: any;
  NumberElement ?: any;
}

interface NumberListBlockProps {
  arrow?: boolean;
}

const NumberListBlock: AnyStyledComponent & NumberListBlockElements = styled.ul<NumberListBlockProps>`
  list-style-type: none;
  counter-reset: section;
`;

NumberListBlock.ListItemElement = ListItemElement;
NumberListBlock.NumberElement = NumberElement;
export default NumberListBlock;
