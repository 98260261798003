import styled from 'styled-components';

import SPACES from 'utils/constants/spaces';

interface HorizontalStackBlockProps {
  justified?: boolean;
  centered?: boolean;
  gap?: keyof typeof SPACES;
  noMarginRight?: boolean;
  noMarginLeft?: boolean;
  justifyCenter?: boolean;
  wrap?: boolean;
}

const HorizontalStackBlock = styled.div<HorizontalStackBlockProps>`
  display: flex;
  ${(props) =>
    props.justified &&
    `
    justify-content: space-between;
    width: 100%;
  `};
  ${(props) =>
    props.centered &&
    `
    align-items: center;
  `};
  ${(props) =>
    props.justifyCenter &&
    `
    justify-content: center;
    width: 100%;
  `};
  ${(props) =>
    props.gap &&
    `
    margin-left: calc(${SPACES[props.gap]} / -2);
    margin-right: calc(${SPACES[props.gap]} / -2);

    > * {
      margin-left: calc(${SPACES[props.gap]} / 2);
      margin-right: calc(${SPACES[props.gap]} / 2);
    }
  `};
  ${(props) =>
    props.noMarginRight &&
    `
    margin-right: 0;

    > * {
      margin-right: 0;
    }
  `}
  ${(props) =>
    props.noMarginLeft &&
    `
    margin-left: 0;
  `}
  ${(props) => props.wrap && `
    flex-wrap: wrap;
  `}
`;

export default HorizontalStackBlock;
