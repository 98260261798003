import style from 'styled-components';

import COLORS from 'utils/constants/colors';

interface ColoredTextProps {
  color: keyof typeof COLORS;
}

export default style.span<ColoredTextProps>`
  color: ${props => COLORS[props.color]};
`;
