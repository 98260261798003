import React, { MouseEvent } from 'react';

import { TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import {
  EveryCard,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';

export interface TextileGroupsListItemProps {
  group: TextileGroup;
  selected?: boolean;
  groupStudentsCount?: number;
  onClick?: (e: MouseEvent, groupId: number, name?: string) => void;
}

export const TextileGroupsListItem = ({
  group,
  selected,
  onClick
}: TextileGroupsListItemProps) => (
  <EveryCard
    as={onClick && 'button'}
    onClick={onClick && ((e: MouseEvent) => onClick(e, group.id, group.name))}
    selected={selected}
  >
    <EveryCardPadding>
      <EveryCardHeadline>{group.name || group.id}</EveryCardHeadline>
    </EveryCardPadding>
  </EveryCard>
);

export default TextileGroupsListItem;
