import React from 'react';
import Headline from 'elements/Headline';
import { FormattedMessage } from 'react-intl';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import { BookModelType } from 'models/BookModel';
import { CheckoutModelType } from 'models/CheckoutModel';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import CheckoutLink from 'components/CheckoutLink/CheckoutLink';

const CheckoutAge = ({
  checkout,
  book,
  handleEdit
}: {
  checkout: CheckoutModelType;
  book: BookModelType;
  handleEdit: () => void;
}) => (
  <>
    <Headline.Medium>
      <FormattedMessage id="Checkout age header" />
    </Headline.Medium>
    <HorizontalStackBlock gap="S">
      <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
      <Paragraph size="L" weight="BOLD">
        {checkout.minor ? (
          <FormattedMessage id="Checkout minor text" />
        ) : (
          <FormattedMessage id="Checkout of age text" />
        )}
      </Paragraph>
    </HorizontalStackBlock>

    {handleEdit && (
      <CheckoutLink text="Checkout change age" onClick={() => handleEdit()} />
    )}
  </>
);

export default CheckoutAge;
