import React from 'react';

import COLORS from 'utils/constants/colors';

import ProgressbarBlock from 'blocks/ProgressbarBlock';

interface ProgressbarComponentProps {
  progressStatus: number;
  color?: keyof typeof COLORS;
  isStatic?: boolean;
}

function ProgressbarComponent({
  progressStatus,
  color,
  isStatic = false
}: ProgressbarComponentProps) {
  return (
    <ProgressbarBlock isStatic={isStatic}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect
          fill={isStatic ? COLORS.GRAY950 : COLORS.GRAY800}
          width="100%"
          height={isStatic ? '10' : '6'}
          rx={isStatic ? '3' : '0'}
          ry={isStatic ? '3' : '0'}
        />
        <rect
          fill={COLORS[color || 'PRIMARY']}
          width={progressStatus === 0 ? '0.625rem' : `${progressStatus}%`}
          height={isStatic ? '10' : '6'}
          rx={isStatic ? '3' : '0'}
          ry={isStatic ? '3' : '0'}
        />
      </g>
    </ProgressbarBlock>
  );
}

export default ProgressbarComponent;
