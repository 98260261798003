import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBox from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import FileUploadComponent from 'components/Inputs/FileUploadComponent';
import { ACCEPT_IMAGES_AND_PDFS } from 'components/Inputs/FileUploadInput';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import LockedLabel from 'domain/Label/LockedLabel';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { SponsorModelType } from 'models/SponsorModel';
import { convertDonation, formatDonation } from 'screens/sponsors/helpers';
import { SponsorsStoreType } from 'screens/sponsors/SponsorsStore';
import { HistoryProps, idFromMatch, isPush } from 'utils/history';
import { FormType, handleFormError, useForm } from 'utils/hooks/useForm';

import CardBlock from 'blocks/CardBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { Link } from 'react-router-dom';
import AdvertImageComponent from './AdvertImageComponent';

interface SponsorDetailProps {
  sponsorsStore: SponsorsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('sponsorsStore', 'applicationStore')
@observer
class SponsorDetail extends Component<SponsorDetailProps & HistoryProps> {
  fillForm(item: SponsorModelType) {
    const { form } = this.props;

    form.setField('name', item.name || '');

    if (item.donation) {
      const donation = formatDonation(item.donation);
      if (donation) {
        form.setField('donation', donation);
      }
    }

    form.setField('paid', item.payment_state === 'paid');

    form.setField('type', item.page_type || 'full');
    form.setField(
      'advert',
      item.advertisement
        ? {
          old: {
            url: item.advertisement.url,
            preview: item.advertisement.preview
          }
        }
        : null
    );
  }

  async loadSponsor(id: number) {
    const { sponsorsStore, form } = this.props;

    if (sponsorsStore.isItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const checkIfAlreadyInStore = !isPush(this.props.history);
    if (
      checkIfAlreadyInStore &&
      sponsorsStore.item &&
      sponsorsStore.item.id === id
    ) {
      this.fillForm(sponsorsStore.item);
      return;
    }

    const item = await sponsorsStore.getSponsor(id);

    if (item) {
      this.fillForm(item);
    }

    return item;
  }

  async componentDidMount() {
    const id = idFromMatch(this.props.match);
    if (id) {
      await this.loadSponsor(id);
    } else {
      // new sponsor mode
      this.props.sponsorsStore.clearCurrentItem();
      this.props.form.reset();
    }
  }

  async componentDidUpdate(prevProps: any) {
    const newId = idFromMatch(this.props.match);
    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new sponsor mode
          this.props.sponsorsStore.clearCurrentItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        await this.loadSponsor(newId);
      }
    }
  }

  getCurrentChapterId(): number | undefined {
    const id = this.props.history.location.state?.id || undefined;

    if (!id) {
      return undefined;
    }

    return typeof id === 'number' ? id : parseInt(id || '0', 10) || undefined;
  }

  navigateToChapter() {
    const id = this.getCurrentChapterId();
    if (!id) {
      return '/app/book/content';
    } else {
      return '/app/book/sponsors/' + id;
    }
  }

  async handleSubmit() {
    const { form, sponsorsStore } = this.props;
    const { name, donation, advert, paid } = form.values;

    const donationFloat = convertDonation(donation);
    if (donationFloat === null) {
      form.setError(
        'donation',
        intl.formatMessage({ id: 'donation format error' })
      );
      return;
    }

    form.resetErrors();

    const patch: any = {
      name: name || '',
      donation: donationFloat || 0,
      payment_state: !paid ? 'no_state' : 'paid'
    };

    if (advert && advert.file) {
      patch.advertisement = advert;
    }

    try {
      if (!sponsorsStore.item || sponsorsStore.item.id < 0) {
        // new item
        await sponsorsStore.createSponsor(patch);
      } else {
        // update item
        patch.id = sponsorsStore.item.id;
        await sponsorsStore.updateSponsor(patch);
      }

      // await sponsorsStore.storeItem();
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by SponsorsStore internally
      return;
    }

    this.props.history.push(this.navigateToChapter());
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  async performDelete() {
    const { sponsorsStore } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return;
    }

    this.finishDelete();

    try {
      await sponsorsStore.removeSponsor(id);
    } catch (error: any) {
      // delete errors are handled by SponsorsStore internally
      return;
    }

    this.props.history.push(this.navigateToChapter());
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  isAddMode() {
    return !idFromMatch(this.props.match) ? true : false;
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  renderReadOnly() {
    const item = this.props.sponsorsStore.item;
    if (!item) {
      return this.renderPage(<LockedLabel id="Sponsors" />);
    }

    // TODO Is this styling correct?
    return this.renderPage(
      <>
        {!item.global && <LockedLabel id="Sponsors" />}

        <ItemStackBlock gap="S">
          <Paragraph>
            <FormattedMessage id="Sponsor name" />
          </Paragraph>
          <Paragraph>{item.name}</Paragraph>
        </ItemStackBlock>

        {item.donation && item.donation > 0 ? (
          <ItemStackBlock gap="S">
            <Paragraph>
              <FormattedMessage id="Donation" />
            </Paragraph>
            <Paragraph>
              {intl.formatNumber(item.donation, {
                style: 'currency',
                currency: 'EUR'
              })}
            </Paragraph>
          </ItemStackBlock>
        ) : null}

        {!item.global && (
          <Paragraph>
            <FormattedMessage
              id={
                item.payment_state === 'paid'
                  ? 'Donation paid'
                  : 'Donation not paid'
              }
            />
          </Paragraph>
        )}

        <ItemStackBlock gap="S">
          <Paragraph>
            <FormattedMessage id="Advert" />
          </Paragraph>
          <AdvertImageComponent advert={item.advertisement} />
        </ItemStackBlock>
      </>,
      item.global
    );
  }

  renderEditForm() {
    const { sponsorsStore, form } = this.props;
    const isAddMode = this.isAddMode();

    if (!isAddMode && sponsorsStore.item?.global) {
      return this.renderReadOnly();
    }

    let extra;
    if (form.confirm) {
      extra = (
        <ConfirmBox
          header={<FormattedMessage id="confirm delete header" />}
          text={
            <FormattedMessage
              id="confirm delete item"
              values={{
                item: <FormattedMessage id="This sponsor" />
              }}
            />
          }
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => this.performDelete()}
          onAbort={() => this.finishDelete()}
          confirmColor="RED"
        />
      );
    } else if (sponsorsStore.itemLoadingState === 'update_error') {
      extra = <GenericErrorComponent />;
    }

    const placed = sponsorsStore.item?.placed || false;
    const currentChapterId = this.getCurrentChapterId();
    const sponsorChapterId = sponsorsStore.item?.chapter_id;

    return this.renderPage(
      <>
        {extra}

        {placed && (
          <>
            <HorizontalStackBlock gap="S">
              <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
              <FormattedMessage id="Filtered photos placed" />
            </HorizontalStackBlock>

            {!!sponsorChapterId &&
              !!currentChapterId &&
              sponsorChapterId !== this.getCurrentChapterId() && (
                <>
                  <Paragraph>
                    <FormattedMessage id="Sponsor already placed" />
                  </Paragraph>

                  <CardBlock as="button">
                    <Link to={'/app/book/sponsors/' + sponsorChapterId}>
                      <PaddingBlock arrow={true}>
                        <TextElement  color="TEXT_DARK">
                          <FormattedMessage id="Jump to chapter" />
                        </TextElement>
                      </PaddingBlock>
                    </Link>
                  </CardBlock>
                </>
              )}
          </>
        )}

        <FormStackBlock>
          <TextInputComponent
            name="name"
            label={intl.formatMessage({ id: 'Sponsor name' })}
            {...form.bindInput('name')}
            autoFocus={true}
          />

          <FileUploadComponent
            name="advert"
            label={intl.formatMessage({ id: 'Advert' })}
            accept={ACCEPT_IMAGES_AND_PDFS}
            onUploadPhoto={() => undefined}
            isAddMode={isAddMode}
            {...form.bindInput('advert')}
          />
        </FormStackBlock>

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id={isAddMode ? 'Add' : 'Save'} />
        </ButtonBlock>

        {!isAddMode && (
          <>
            <Divider />
            <DeleteButton onClick={() => this.confirmDelete()} />
          </>
        )}
      </>
    );
  }

  renderPage(content: any, isGlobal = false) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={this.navigateToChapter()} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage
              id={
                isGlobal
                  ? 'Nuggit sponsor'
                  : this.isAddMode()
                    ? 'Add sponsor'
                    : 'Edit sponsor'
              }
            />
          </Headline.Large>

          {content}
        </PageStackBlock>
        <HelpSubject subject="sponsors" />
      </>
    );
  }

  render() {
    const { sponsorsStore, applicationStore } = this.props;

    if (sponsorsStore.isItemLoading) {
      return this.renderLoading();
    }

    if (sponsorsStore.itemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (!applicationStore.isEditAllowed) {
      return this.renderReadOnly();
    }

    return this.renderEditForm();
  }
}

export default (props: any) => {
  const form = useForm({ type: 'full' });
  return <SponsorDetail {...props} form={form} />;
};
