import React, { ReactNode } from 'react';
import ItemStackBlock from 'blocks/ItemStackBlock';

const PageStackBlock = ({ children }: { children: ReactNode }) => (
  <ItemStackBlock gap="L">
    {children}
  </ItemStackBlock>
);

export default PageStackBlock;
