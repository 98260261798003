import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TextileCheckout } from 'api/textile_deals/fetchTextileCheckout';
import {
  changeTextileDeliveryDateAfterCheckout,
  chooseTextileDeliveryDate,
  getTextileDeliveryDates
} from 'api/textile_deals/fetchTextileDeliveryDate';
import { BaseTextileOrder, TextileOrderForGroups, TextileOrderForManagement, TextileOrderForPreorder } from 'api/textile_deals/fetchTextileOrders';

export const useTextileDeliveryDateQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_delivery_dates', textileOrderId],
    queryFn: () => getTextileDeliveryDates(textileOrderId!),
    enabled: !!textileOrderId,
    staleTime: 300000
  });
};

export const useChooseTextileDeliveryDateQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { patch: any; id: number }) =>
      chooseTextileDeliveryDate(data.id, textileOrderId),
    onMutate: async (data) => {
      await queryClient.cancelQueries(['textile_checkout', textileOrderId]);
      await queryClient.cancelQueries(['textile_order', textileOrderId]);
      const previousState:
        | TextileCheckout
        | undefined = queryClient.getQueryData([
        'textile_checkout',
        textileOrderId
      ]);
      const previousStateTextileOrder:
      | BaseTextileOrder | TextileOrderForGroups | TextileOrderForManagement | TextileOrderForPreorder
      | undefined = queryClient.getQueryData([
      'textile_order',
      textileOrderId
    ]);


      if (previousState) {
        const newState: TextileCheckout = {
          ...previousState,
          delivery_date_selected: true,

        };
        queryClient.setQueryData(['textile_checkout', textileOrderId], newState);
      }

      if (previousStateTextileOrder) {

        const newState = {...previousStateTextileOrder, textile_delivery_date: data.patch}
        queryClient.setQueryData(['textile_order', textileOrderId], newState);
      }


      return { previousState, previousStateTextileOrder };
    },
    onError: (err, textileOrderId, context) => {
      queryClient.setQueryData(
        ['textile_checkout', textileOrderId],
        context?.previousState
      );
      queryClient.setQueryData(
        ['textile_order', textileOrderId],
        context?.previousState
      );
    },
    onSettled: (id) => {
      queryClient.invalidateQueries(['textile_delivery_date', id]);
    }
  });
};

export const useChooseTextileDeliveryDateAfterCheckoutQuery = (
  textileOrderId?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      changeTextileDeliveryDateAfterCheckout(id, textileOrderId),
    onSettled: (id) => {
      queryClient.invalidateQueries(['textile_delivery_date', id]);
    }
  });
};
