import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_QUOTES } from 'utils/constants/routes';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import QuoteDetail from './QuoteDetail/QuoteDetail';
import QuotesList from './QuotesList/QuotesList';
import QuotesSettings from './QuotesSettings';
import SortQuotesScreen from './SortQuotesScreen/SortQuotesScreen';

export default () => (
  <Switch>
    <Route
      path={ROUTE_QUOTES + '/:chapterId'}
      exact={true}
      component={QuotesList}
    />
    <Route
      path={ROUTE_QUOTES + '/:chapterId/settings'}
      component={QuotesSettings}
    />

    <Route path={ROUTE_QUOTES + '/:chapterId/new'} component={QuoteDetail} />
    <Route
      path={ROUTE_QUOTES + '/:chapterId/sort'}
      component={SortQuotesScreen}
    />
    <Route path={ROUTE_QUOTES + '/:chapterId/:id'} component={QuoteDetail} />

    <NotFoundRoute />
  </Switch>
);
