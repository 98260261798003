import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import AnswerModel, { createAnswerModel } from 'models/AnswerModel';
import ChapterModel, {
  ChapterModelType,
  createChapterModel
} from 'models/ChapterModel';
import ProfileModel, { ProfileModelType } from 'models/ProfileModel';
import QuestionModel, {
  createQuestionModel,
  QuestionModelType
} from 'models/QuestionModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { assert } from 'utils/assert';
import { createMapWithTransform } from 'utils/create-map';
import { moveItem } from 'utils/item-sorting';
import { sortByField, sortByFirstNameThenLastName, sortByLastNameThenFirstName } from 'utils/sort-functions';
import createProfileModel from 'utils/store/createProfileModel';

const FactsheetsStore = types
  .model('FactsheetsStore', {
    // factsheets list
    factsheetsLoadingState: types.maybe(
      types.enumeration(['loading', 'error'])
    ),
    factsheets: types.maybe(types.map(ProfileModel)),

    chapter: types.maybe(ChapterModel),

    // single item
    profileItemLoadingState: types.maybe(
      types.enumeration([
        'loading',
        'error',
        'no_profile_chapter',
        'update_error'
      ])
    ),
    profileItem: types.maybe(ProfileModel),
    profileItemChapter: types.maybe(ChapterModel),

    // question list
    questionsLoadingState: types.maybe(types.enumeration(['loading', 'error'])),
    questions: types.maybe(types.map(QuestionModel)),
    questionsChapter: types.maybe(ChapterModel),

    // question and answer items
    questionItemLoadingState: types.maybe(
      types.enumeration(['loading', 'error', 'update_error'])
    ),
    questionItem: types.maybe(QuestionModel),

    answerItemLoadingState: types.maybe(
      types.enumeration(['loading', 'error', 'update_error'])
    ),
    answerItem: types.maybe(AnswerModel),

    splitChapterLoadingState: types.maybe(
      types.enumeration(['loading', 'error'])
    )
  })
  .actions((self) => {
    const getFactsheetsByChapter = flow(function* (
      chapterId: number,
      expectedChapterType?:
        | 'any_factsheet'
        | 'factsheet'
        | 'teacher_factsheet'
        | 'yearbook'
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.factsheetsLoadingState = 'loading';
        self.factsheets = undefined;
        self.chapter = undefined;

        const result: any = yield client.getFactsheets(chapterId);

        if (!result) {
          throw new Error('No response from server');
        }

        const chapterType = result.chapter?.chapter_type;
        switch (expectedChapterType) {
          case 'factsheet':
          case 'teacher_factsheet':
          case 'yearbook':
            if (chapterType !== expectedChapterType) {
              throw new Error('Unexpected chapter type');
            }
            break;

          case 'any_factsheet':
            if (
              chapterType !== 'factsheet' &&
              chapterType !== 'teacher_factsheet'
            ) {
              throw new Error('Unexpected chapter type');
            }
            break;
        }

        if (Array.isArray(result.profiles)) {
          self.factsheets = createMapWithTransform(
            result.profiles,
            createProfileModel
          );
        }
        self.chapter = createChapterModel(result.chapter || { id: chapterId });

        self.factsheetsLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | getFactsheets', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.factsheetsLoadingState = undefined;
          return;
        }

        self.factsheetsLoadingState = 'error';
      }
    });

    const getFactsheetsWithStatsByChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.factsheetsLoadingState = 'loading';
        self.factsheets = undefined;
        self.chapter = undefined;

        const result: any = yield client.getFactsheetsWithStats(chapterId);

        if (!result) {
          throw new Error('No response from server');
        }

        if (Array.isArray(result.profiles)) {
          self.factsheets = createMapWithTransform(
            result.profiles,
            createProfileModel
          );
        }
        self.chapter = createChapterModel(result.chapter || { id: chapterId });

        self.factsheetsLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | getFactsheets', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.factsheetsLoadingState = undefined;
          return;
        }

        self.factsheetsLoadingState = 'error';
      }
    });

    const getFactsheet = flow(function* (profileId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.profileItemLoadingState = 'loading';
        self.profileItem = undefined;
        self.profileItemChapter = undefined;

        const result: any = yield client.getFactsheet(profileId);

        if (result?.profiles?.length !== 1) {
          throw new Error('Invalid response from server');
        }

        const profile = result.profiles[0];

        self.profileItemChapter = createChapterModel(result);

        // create questions with answers list
        let questions = Array.isArray(result.questions) ? result.questions : [];
        if (questions.length) {
          const answersByQuestion: any = {};
          if (Array.isArray(profile.answers)) {
            for (const answer of profile.answers) {
              if (answer.question_id) {
                answersByQuestion[answer.question_id] = answer;
              }
            }
          }

          questions = questions.map((question: any) => {
            if (!question?.id) {
              return undefined;
            }
            return {
              ...question,
              answer: answersByQuestion[question.id] || undefined
            };
          });

          profile.questions = questions;
        }

        self.profileItem = createProfileModel(profile);

        self.profileItemLoadingState = undefined;
        return self.profileItem;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | getFactsheet', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.profileItemLoadingState = undefined;
          return;
        }

        if (
          client.isNotFound(error) &&
          error.body?.error === 'FactsheetMissing'
        ) {
          self.profileItemLoadingState = 'no_profile_chapter';
          return;
        }

        self.profileItemLoadingState = 'error';
      }
    });

    const getYearbook = flow(function* (profileId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.profileItemLoadingState = 'loading';
        self.profileItem = undefined;
        self.profileItemChapter = undefined;

        const result: any = yield client.getYearbook(profileId);

        if (result?.profiles?.length !== 1) {
          throw new Error('Invalid response from server');
        }

        const profile = result.profiles[0];

        self.profileItemChapter = createChapterModel(result);
        self.profileItem = createProfileModel(profile);

        self.profileItemLoadingState = undefined;
        return self.profileItem;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console
          console.error('FactsheetsStore | getYearbook', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.profileItemLoadingState = undefined;
          return;
        }

        if (
          client.isNotFound(error) &&
          error.body?.error === 'YearbookMissing'
        ) {
          self.profileItemLoadingState = 'no_profile_chapter';
          return;
        }

        self.profileItemLoadingState = 'error';
      }
    });

    const updateFactsheet = flow(function* (profileId: number, patch: any) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.profileItemLoadingState = 'loading';

        const result = yield client.updateProfile({
          ...patch,
          id: profileId
        });

        self.profileItemLoadingState = undefined;

        return createProfileModel(result);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | updateFactsheet', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.profileItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.profileItemLoadingState = undefined;
          throw error;
        }

        self.profileItemLoadingState = 'update_error';
        throw error;
      }
    });

    const clearCurrentProfileItem = () => {
      self.profileItem = undefined;
      self.profileItemChapter = undefined;
      self.profileItemLoadingState = undefined;
    };

    /**
     * The first call requests the split,
     * the second call (given allow_split_factsheet has been set to true) performs the split.
     */
    const splitFactsheetChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.splitChapterLoadingState = 'loading';

        yield client.splitFactsheetChapter(chapterId);

        self.splitChapterLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'FactsheetsStore | splitFactsheetChapter',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.splitChapterLoadingState = undefined;
          return;
        }

        self.splitChapterLoadingState = 'error';

        throw error;
      }
    });

    const splitYearbookChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.splitChapterLoadingState = 'loading';

        yield client.splitYearbookChapter(chapterId);

        self.splitChapterLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'FactsheetsStore | splitYearbookChapter',
            error,
            error.body
          );
          console.error(
            'FactsheetsStore | splitFactsheetChapter',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.splitChapterLoadingState = undefined;
          return;
        }

        self.splitChapterLoadingState = 'error';

        throw error;
      }
    });

    const setChapter = (chapter?: ChapterModelType) => {
      self.chapter = createChapterModel(chapter);
    };

    // == questions ==
    const getQuestionsByChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.questionsLoadingState = 'loading';
        self.questions = undefined;
        self.questionsChapter = undefined;

        const result: any = yield client.getAllQuestions(chapterId);

        if (!result) {
          throw new Error('No response from server');
        }

        if (Array.isArray(result.questions)) {
          self.questions = createMapWithTransform(
            result.questions,
            createQuestionModel
          );
        }
        self.questionsChapter = createChapterModel(
          result.chapter || { id: chapterId }
        );

        self.questionsLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'FactsheetsStore | getQuestionsByChapter',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.questionsLoadingState = undefined;
          return;
        }

        self.questionsLoadingState = 'error';
      }
    });

    const getQuestion = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.questionItemLoadingState = 'loading';
        self.questionItem = undefined;

        const question = yield client.getQuestion(id);

        self.questionItem = createQuestionModel(question);
        self.questionItemLoadingState = undefined;

        return self.questionItem;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | getQuestion', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.questionItemLoadingState = undefined;
          return;
        }

        self.questionItemLoadingState = 'error';
      }
    });

    const createQuestion = flow(function* (
      question: QuestionModelType,
      addToList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.questionItemLoadingState = 'loading';
        self.questionItem = undefined;

        const result = yield client.createQuestion(question);
        const model = createQuestionModel(result);

        if (model) {
          if (addToList) {
            self.questions?.put(model);
          }

          self.questionItem = createQuestionModel(result);
        }

        self.questionItemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | createQuestion', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.questionItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.questionItemLoadingState = undefined;
          throw error;
        }

        self.questionItemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateQuestion = flow(function* (questionId: number, patch: any) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.questionItemLoadingState = 'loading';

        const question: any = yield client.updateQuestion(questionId, patch);
        const model = createQuestionModel(question);

        if (model) {
          if (self.questions?.has(model.id.toString())) {
            self.questions.put(model);
          }

          if (self.questionItem?.id === model.id) {
            self.questionItem = createQuestionModel(question);
          }
        }
        self.questionItemLoadingState = undefined;

        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | updateQuestion', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.questionItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.questionItemLoadingState = undefined;
          throw error;
        }

        self.questionItemLoadingState = 'update_error';
        throw error;
      }
    });

    const removeQuestion = flow(function* (
      questionId: number,
      removeFromList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.questionItemLoadingState = 'loading';

        yield client.removeQuestion(questionId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | removeQuestion', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.questionItemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (client.isNotFound(error)) {
          return;
        }

        self.questionItemLoadingState = 'update_error';
        throw error;
      }

      if (removeFromList) {
        self.questions?.delete(questionId.toString());
      }

      if (self.questionItem && self.questionItem.id === questionId) {
        self.questionItem = undefined;
      }
      self.questionItemLoadingState = undefined;
    });

    const moveQuestion = flow(function* (oldIndex: number, newIndex: number) {
      assert(self.questions);

      const { items, map } = moveItem(self.questions, oldIndex, newIndex);

      self.questions = map;

      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        // Do not set loading state, this would block the UI
        self.questionsLoadingState = undefined;

        yield client.setQuestionSorting(items as QuestionModelType[]);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | moveQuestion', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.questionsLoadingState = undefined;
          return;
        }

        self.questionsLoadingState = 'error';
        throw error;
      }

      self.questionsLoadingState = undefined;
    });

    const clearCurrentQuestionItem = () => {
      self.questionItem = undefined;
      self.questionItemLoadingState = undefined;
    };

    const setQuestionChapter = (chapter: ChapterModelType) => {
      self.questionsChapter = chapter;
    };

    // == answers ==
    const getAnswer = flow(function* (
      id: number,
      updateQuestionItem: boolean = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.answerItemLoadingState = 'loading';
        self.answerItem = undefined;
        if (updateQuestionItem) {
          self.questionItem = undefined;
        }

        const result: any = yield client.getAnswer(id);

        self.answerItem = createAnswerModel(result);

        if (updateQuestionItem && result.question) {
          self.questionItem = createQuestionModel(result.question);
        }

        self.answerItemLoadingState = undefined;
        return self.answerItem;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | getAnswer', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.answerItemLoadingState = undefined;
          return;
        }

        self.answerItemLoadingState = 'error';
      }
    });

    const createAnswer = flow(function* (answer: any, addToList = true) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.answerItemLoadingState = 'loading';
        self.answerItem = undefined;

        const result = yield client.createAnswer(answer);
        const model = createAnswerModel(result);

        if (model) {
          // TODO update profile model?
          // if (addToList) {
          //   self.questions?.put(model);
          // }

          self.answerItem = createAnswerModel(result);
          self.answerItemLoadingState = undefined;

          return model;
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | createAnswer', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.answerItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.answerItemLoadingState = undefined;
          throw error;
        }

        self.answerItemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateAnswer = flow(function* (answerId: number, patch: any) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.answerItemLoadingState = 'loading';

        const result: any = yield client.updateAnswer(answerId, patch);
        const model = createAnswerModel(result);

        if (model) {
          // TODO update profile model?
          // if (self.questions?.has(model.id.toString())) {
          //   self.questions.put(model);
          // }

          if (self.answerItem?.id === model.id) {
            self.answerItem = createAnswerModel(result);
          }
        }
        self.answerItemLoadingState = undefined;

        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | updateAnswer', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.answerItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.answerItemLoadingState = undefined;
          throw error;
        }

        self.answerItemLoadingState = 'update_error';
        throw error;
      }
    });

    const removeAnswer = flow(function* (
      answerId: number,
      removeFromList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.answerItemLoadingState = 'loading';

        yield client.removeAnswer(answerId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | removeAnswer', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.answerItemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (client.isNotFound(error)) {
          return;
        }

        self.answerItemLoadingState = 'update_error';
        throw error;
      }

      // TODO update profile model?
      // if (removeFromList) {
      //   self.questions?.delete(questionId.toString());
      // }

      if (self.answerItem?.id === answerId) {
        self.questionItem = undefined;
      }
      self.answerItemLoadingState = undefined;
    });

    const clearCurrentAnswerItem = () => {
      self.answerItem = undefined;
      self.answerItemLoadingState = undefined;
    };

    return {
      getFactsheetsByChapter,
      getFactsheetsWithStatsByChapter,
      getFactsheet,
      getYearbook,
      updateFactsheet,
      clearCurrentProfileItem,
      setChapter,

      splitFactsheetChapter,
      splitYearbookChapter,

      getQuestionsByChapter,
      getQuestion,
      createQuestion,
      updateQuestion,
      removeQuestion,
      moveQuestion,
      clearCurrentQuestionItem,
      setQuestionChapter,

      getAnswer,
      createAnswer,
      updateAnswer,
      removeAnswer,
      clearCurrentAnswerItem
    };
  })
  .views((self) => {
    return {
      /**
       * Questions sorted by sorting id
       */
      get allQuestions(): QuestionModelType[] {
        const questions: QuestionModelType[] = [];
        if (!self.questions) {
          return questions;
        }

        for (const question of self.questions.values()) {
          questions.push(question);
        }

        questions.sort(sortByField('sorting'));
        return questions;
      },

      get isQuestionListLoading(): boolean {
        return self.questionsLoadingState === 'loading' ? true : false;
      },
      get isQuestionListError(): boolean {
        return self.questionsLoadingState === 'error' ? true : false;
      },

      get isQuestionItemLoading(): boolean {
        return self.questionItemLoadingState === 'loading' ? true : false;
      },
      get isQuestionItemError(): boolean {
        return self.questionItemLoadingState === 'error' ||
          self.questionItemLoadingState === 'update_error'
          ? true
          : false;
      },

      get isAnswerItemLoading(): boolean {
        return self.answerItemLoadingState === 'loading' ? true : false;
      },
      get isAnswerItemError(): boolean {
        return self.answerItemLoadingState === 'error' ||
          self.answerItemLoadingState === 'update_error'
          ? true
          : false;
      },

      get isProfileItemLoading(): boolean {
        return self.profileItemLoadingState === 'loading' ? true : false;
      },
      get isProfileItemError(): boolean {
        return self.profileItemLoadingState === 'error' ||
          self.profileItemLoadingState === 'no_profile_chapter' ||
          self.profileItemLoadingState === 'update_error'
          ? true
          : false;
      },

      // factsheets
      get isFactsheetsLoading(): boolean {
        return self.factsheetsLoadingState === 'loading' ? true : false;
      },
      get isFactsheetsError(): boolean {
        return self.factsheetsLoadingState === 'error' ? true : false;
      },

      allFactsheets(
        nameFilter?: string,
        sortByLastName: boolean = false
      ): ProfileModelType[] {
        if (!self.factsheets?.size) {
          return [];
        }

        const nameLowercase = !nameFilter
          ? undefined
          : nameFilter.trim().toLowerCase();

        const factsheets: ProfileModelType[] = [];
        for (const profile of self.factsheets.values()) {
          if (
            nameLowercase &&
            profile.name &&
            profile.name.toLowerCase().indexOf(nameLowercase) < 0
          ) {
            continue;
          }

          factsheets.push(profile);
        }

        if (sortByLastName) {
          factsheets.sort(sortByLastNameThenFirstName);
        } else {
          factsheets.sort(sortByFirstNameThenLastName);
        }

        return factsheets;
      },
      get factsheetsCount(): number {
        return self.factsheets?.size || 0;
      },
      get includesOwnFactsheet(): boolean {
        const { applicationStore } = getEnv<AdvancedStoreEnv>(self);

        const id = applicationStore.currentUserId;
        if (!self.factsheets || self.factsheets.size < 1 || !id) {
          return false;
        }

        for (const profile of self.factsheets.values()) {
          if (profile.id === id) {
            return true;
          }
        }

        return false;
      },

      question(id: number): QuestionModelType | undefined {
        if (self.questionItem?.id === id) {
          return self.questionItem;
        }
        return self.profileItem?.questions?.get(id.toString());
      }
    };
  });

export type FactsheetsStoreType = Instance<typeof FactsheetsStore>;
export default FactsheetsStore;
