import { inject, observer } from 'mobx-react';
import React from 'react';
// @ts-ignore
import Recaptcha from 'react-google-invisible-recaptcha';

import { LegalEnum, SetupStoreType } from 'models/SetupStore';
import { ROUTE_SIGNUP } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import LegalContainer from '../containers/LegalContainer';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import config from 'config';

interface SignupLegalScreenProps {
  setupStore: SetupStoreType;
  type: LegalEnum;
  progressStatus?: number;
}

interface SignupLegalScreenState {
  captchaReady?: boolean;
}

@inject('setupStore')
@observer
class SignupLegalScreen extends React.Component<
  SignupLegalScreenProps & HistoryProps,
  SignupLegalScreenState
> {
  state: SignupLegalScreenState = {};
  engagement?: boolean = undefined;

  recaptcha: any = undefined;

  componentDidMount() {
    const { setupStore } = this.props;

    if (setupStore.isSignupDone) {
      this.props.history.replace(ROUTE_SIGNUP + '/done');
      return;
    }

    if (!setupStore.isSignupInitialized) {
      this.props.history.replace(ROUTE_SIGNUP);
      return;
    }
  }

  submit() {
    if (this.recaptcha) {
      this.recaptcha.execute();
    } else {
      // just to be sure, but will fail on server if recaptcha was not available on client
      this.resolved();
    }
  }

  resolved() {
    const { setupStore } = this.props;

    if (this.recaptcha) {
      setupStore.setCaptchaKey(this.recaptcha.getResponse().toString() || '');
    }

    // done page will submit
    this.props.history.push(ROUTE_SIGNUP + '/done');
  }

  captchaReady() {
    this.setState({
      captchaReady: true
    });
  }

  handleAccept = (type: LegalEnum, engagementAccepted?: boolean) => {
    switch (type) {
      case 'tos':
        this.props.history.push(ROUTE_SIGNUP + '/privacy');
        return;
      case 'privacy':
        this.submit();
        return;
      default:
    }
  };

  render() {
    const { type, progressStatus } = this.props;

    let backRoute;
    let loader;
    switch (type) {
      case 'tos':
        backRoute = ROUTE_SIGNUP + '/password';
        break;
      case 'privacy':
        if (!this.state.captchaReady) {
          loader = <LoadingOverlayComponent />;
        }

        backRoute = ROUTE_SIGNUP + '/terms';
        break;
      default:
        return null;
    }

    return (
      <>
        <LegalContainer
          signup={true}
          type={type}
          backRoute={backRoute}
          progressStatus={progressStatus}
          onAccept={this.handleAccept}
        />
        {type === 'privacy' ? (
          <>
            <Recaptcha
              ref={(ref: any) => (this.recaptcha = ref)}
              sitekey={config.recaptchaSiteKey}
              onLoaded={() => this.captchaReady()}
              onResolved={() => this.resolved()}
            />
            {loader}
          </>
        ) : null}
      </>
    );
  }
}

export default SignupLegalScreen;
