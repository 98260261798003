import React, { useContext, useEffect } from 'react';

import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import CardBlock from 'blocks/CardBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import WideBlock from 'blocks/WideBlock';
import IconComponent from 'components/IconComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import UppercaseHeading from 'elements/UppercaseHeading';
import { useTextileDesignSettingQuery } from 'queries/textile_deals/useTextileDesignSettingQueries';

import { useTextileVariantsQuery } from 'queries/textile_deals/useTextileVariantsQueries';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import TextileProductImageComponent from 'screens/preorders/components/TextileProductImageComponent';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import { ProductImage, productImage } from 'utils/product-image';
import textileThemesImagePath from 'utils/textile-theme-image-path';

const TextileDesignSettingsScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const accessContext = useContext(HasAccessContext);
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  const textileDesignSetting = useTextileDesignSettingQuery(textileOrderId);
  const textileVariants = useTextileVariantsQuery(textileOrderId);

  useEffect(() => {
    accessContext.checkSectionAccess('design');
    const data = textileDesignSetting.data;

    if (data) {
      const hasRequiredSettings = data.layout_key && data.theme;
      if (!hasRequiredSettings) {
        const route = textileOrderRoute(props.match, '/textile_design/layout');
        props.history.push(route);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileDesignSetting.data]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="Design textile order" />}
            text={<FormattedMessage id="textile design edit" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileDesignSetting.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => textileDesignSetting.refetch()}
      />
    );
  }

  if (textileVariants.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileVariants.refetch()} />
    );
  }

  if (
    textileVariants.isLoading ||
    !textileVariants.data ||
    textileDesignSetting.isLoading ||
    !textileDesignSetting.data ||
    !textileAppState
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderPreviewImage = () => {
    const settings = textileDesignSetting.data;
    const textileOrder = textileAppState.textile_order;
    const variants = textileVariants.data;

    if (!settings || !textileOrder || !variants) {
      return null;
    }

    const layout = settings.theme;

    if (!layout) {
      return null;
    }

    const hasVariants = variants.length > 0;

    return (
      <WideBlock padded={true}>
        <ItemStackBlock gap="S">
          <UppercaseHeading>
            <FormattedMessage id="Design preview" />
          </UppercaseHeading>
          {hasVariants ? (
            <>
              <SliderBlock>
                {variants.map((variant) => {
                  const frontPreview: ProductImage = productImage(
                    variant,
                    'preview_front',
                    layout
                  );
                  return (
                    <TextileProductImageComponent
                      productImage={frontPreview}
                      key={variant.id}
                    />
                  );
                })}
              </SliderBlock>
            </>
          ) : (
            <>
              {layout.frontPreview_deluxeHoodie ? (
                <>
                  <img
                    src={textileThemesImagePath(
                      layout.key,
                      layout.frontPreview_deluxeHoodie
                    )}
                    alt="Layout"
                  />
                </>
              ) : (
                <Paragraph>{layout.name}</Paragraph>
              )}
            </>
          )}

          <LinkBlock
            inline={true}
            slim={true}
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            to={textileOrderRoute(props.match, '/textile_design/themes')}
          >
            <IconComponent icon="PEN" />
            <FormattedMessage id="Change design" />
          </LinkBlock>
        </ItemStackBlock>
      </WideBlock>
    );
  };

  const renderPreview = () => {
    const settings = textileDesignSetting.data;
    const textileOrder = textileAppState.textile_order;

    if (!settings || !textileOrder) {
      return null;
    }

    const layout = settings.theme;

    if (!layout) {
      return null;
    }

    return renderPage(
      <>
        {renderPreviewImage()}

        <Link to={textileOrderRoute(props.match, '/textile_design/back')}>
          <CardBlock>
            <PaddingBlock arrow={true}>
              <Headline.Small color="GRAY10">
                <FormattedMessage id="textile design back settings" />
              </Headline.Small>
              <TextElement>
                <FormattedMessage id="textile design select back settings" />
              </TextElement>
            </PaddingBlock>
          </CardBlock>
        </Link>
      </>
    );
  };

  return renderPreview();
};

export default TextileDesignSettingsScreen;
