import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import { useStudentListWithoutGroupsQuery } from 'queries/textile_deals/useStudentListQueries';
import React, { useContext, useEffect } from 'react';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import StudentsWithoutTextileGroup from './StudentsWithoutTextileGroup';

const StudentsWithoutTextileGroupScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const studentList = useStudentListWithoutGroupsQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('design');
  }, [accessContext]);

  const renderPage = (content: any) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/groups')} />
        </TopActionBlock>

        {content}
      </>
    );
  };

  if (studentList.isError) {
    return renderPage(<GenericErrorComponent onRetryClick={() => studentList.refetch()} />);
  }

  if (studentList.isLoading || !studentList.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }


  return (
    <StudentsWithoutTextileGroup
      students={studentList.data}
      textileOrderId={textileOrderIdFromMatch(props.match)}
    />
  );
};

export default StudentsWithoutTextileGroupScreen;
