import React from 'react';
import { FormattedMessage } from 'react-intl';

import InfoBox from './InfoBoxComponent';

export const GenericErrorComponent = ({ onRetryClick }: { onRetryClick?: (e: any) => void }) => (
  <InfoBox error={true} onRetryClick={onRetryClick}>
    <FormattedMessage id="error generic" />
  </InfoBox>
);

export default GenericErrorComponent;
