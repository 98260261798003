import { ImagePageImageMap } from 'components/print/templates/ImagePageTemplateComponent';
import { AlbumPageModelType } from 'models/AlbumPageModel';

/**
 * Reduces the album page photos to an object consumed by ImagePagePreviewComponent
 */
const reduceAlbumPagePhotos = (
  albumPage: AlbumPageModelType,
  exportMode = false
) => {
  return albumPage.album_page_photos.reduce<ImagePageImageMap>(
    (result, albumPagePhoto) => {
      const photo = exportMode
        ? albumPagePhoto.photo.exportedPrintPicture
        : albumPagePhoto.photo.printPicture;

      if (photo) {
        result[albumPagePhoto.position] = {
          slotId: albumPagePhoto.position,
          ...photo
        };
      }
      return result;
    },
    {}
  );
};

export default reduceAlbumPagePhotos;
