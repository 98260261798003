import React from 'react';

import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import ContactOnWhatsappButton from 'domain/Buttons/ContactOnWhatsappButton';
import { FormattedMessage } from 'react-intl';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';

export default () => (
  <>
    <SignupHeaderComponent />
    <PageStackBlock>
      <PageHeader headline={<FormattedMessage id="Support" />} text={<FormattedMessage id="support info" />} />
      <ContactOnWhatsappButton />

    </PageStackBlock>
  </>
);
