import React from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_TEXTILE_PREORDER } from 'utils/constants/routes';
import PreorderManagementScreen from './PreOrderManagementScreen';
import PreorderDeadlineScreen from './screens/PreorderDeadlineScreen';
import PreorderMessageFormScreen from './screens/PreorderMessageFormScreen';
import PreorderToggleScreen from './screens/PreorderToggleScreen';
import HowItWorksScreen from './screens/info/HowItWorksScreen';
import OwnPreorderAddScreen from './screens/students/OwnPreorderAddScreen';
import OwnPreorderCartScreen from './screens/students/OwnPreorderCartScreen';
import OwnPreorderScreen from './screens/students/OwnPreorderScreen';
import OwnPreorderSummaryScreen from './screens/students/OwnPreorderSummaryScreen';
import PreorderReviewScreen from './screens/students/PreorderReviewScreen';
import PreorderVirtualAddScreen from './screens/virtual/PreorderVirtualAddScreen';
import PerorderVirtualOverviewScreen from './screens/virtual/PreorderVirtualOverviewScreen';

export default () => (
  <Switch>
    <Route
      path={ROUTE_TEXTILE_PREORDER + '/how-it-works'}
      component={HowItWorksScreen}
    />

    {/* THIS SCREEN IS CURRENTLY NOT USED */}
    {/* <Route
      path={ROUTE_TEXTILE_PREORDER + '/size-guide/:id'}
      component={SizeGuideScreen}
    /> */}

    {/* virtual student preorder */}

    <Route
      path={ROUTE_TEXTILE_PREORDER + '/virtual/add/:id'}
      component={PreorderVirtualAddScreen}
    />
    <Route
      path={ROUTE_TEXTILE_PREORDER + '/virtual'}
      component={PerorderVirtualOverviewScreen}
    />

    {/* student preorder */}

    <Route
      path={ROUTE_TEXTILE_PREORDER + '/own/cart'}
      component={OwnPreorderCartScreen}
    />
    <Route
      path={ROUTE_TEXTILE_PREORDER + '/own/summary'}
      component={OwnPreorderSummaryScreen}
    />
    <Route
      path={ROUTE_TEXTILE_PREORDER + '/own/add/:id'}
      component={OwnPreorderAddScreen}
    />
    <Route
      path={ROUTE_TEXTILE_PREORDER + '/own'}
      component={OwnPreorderScreen}
    />

    {/* organizer review */}
    <Route
      path={ROUTE_TEXTILE_PREORDER + '/review/:id'}
      component={PreorderReviewScreen}
    />

    {/* Management */}

    <Route
      path={ROUTE_TEXTILE_PREORDER + '/preorder_state/deadline'}
      component={PreorderDeadlineScreen}
    />
    <Route
      path={ROUTE_TEXTILE_PREORDER + '/preorder_state'}
      component={PreorderToggleScreen}
    />

    <Route
      path={ROUTE_TEXTILE_PREORDER + '/preorder_message'}
      component={PreorderMessageFormScreen}
    />

    <Route
      path={ROUTE_TEXTILE_PREORDER}
      component={PreorderManagementScreen}
      exact={true}
    />

    <NotFoundRoute />
  </Switch>
);
