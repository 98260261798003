import {
  Instance,
  types
} from 'mobx-state-tree';
import ROLES from 'utils/constants/roles';
import { createMapWithTransform } from 'utils/create-map';
import { sortByField } from 'utils/sort-functions';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import stringOrUndefined from 'utils/store/stringOrUndefined';

import CommentModel, {
  CommentModelType,
  createCommentModel
} from './CommentModel';
import GroupModel, { createGroupModel } from './GroupModel';
import PhotoModel, { createPhotoModel } from './PhotoModel';
import QuestionModel, {
  createQuestionModel,
  QuestionModelType
} from './QuestionModel';
import RolesEnum from './RolesEnum';

export const TeamsEnum = types.enumeration([
  'book',
  'clothing',
  'travel',
  'prom',
  'other'
]);
export type Teams = Instance<typeof TeamsEnum>;

export const TEAMS: Teams[] = ['book', 'clothing', 'travel', 'prom', 'other'];

const ProfileModel = types
  .model('ProfileModel', {
    id: types.identifierNumber,
    auth_token: types.maybe(types.string),
    mobile_number: types.maybe(types.string),
    mobile_number_to_confirm: types.maybe(types.string),
    first_name: types.maybe(types.string),
    last_name: types.maybe(types.string),
    name: types.maybe(types.string),
    engagement: types.maybe(types.boolean),
    group: types.maybe(GroupModel),
    textile_group: types.maybe(GroupModel),
    role: types.maybe(RolesEnum),
    has_role_change_request: types.maybe(types.boolean),
    // teams: types.array(types.string),

    profile_text: types.maybe(types.string),
    yearbook_text: types.maybe(types.string),

    questions: types.maybe(types.map(QuestionModel)),
    questions_count: types.maybe(types.number),
    answers_count: types.maybe(types.number),
    answers_finished: types.maybe(types.boolean),

    slot1_photo: types.maybe(PhotoModel),
    slot2_photo: types.maybe(PhotoModel),
    slot3_photo: types.maybe(PhotoModel),
    creative_page_photo: types.maybe(PhotoModel),
    yearbook_photo: types.maybe(PhotoModel),
    photos_count: types.maybe(types.number),
    photos_target_count: types.maybe(types.number),
    creative_page_enabled: types.maybe(types.boolean),
    creative_page_done: types.maybe(types.boolean),

    print: types.maybe(types.boolean),
    print_yearbook: types.maybe(types.boolean),
    target: types.maybe(types.number),
    done: types.maybe(types.number),

    comments_count: types.maybe(types.number),
    comments: types.maybe(types.map(CommentModel)),

    // field to store password during account setup process
    // TODO move this somewhere to temporary field in SetupStore?
    password: types.maybe(types.string)
  })
  .views((self) => {
    return {
      get hasPrintableQuestions(): boolean {
        if (!self.questions) {
          return false;
        }

        for (const question of self.questions.values()) {
          if (question.print) {
            return true;
          }
        }

        return false;
      },
      get printableQuestions(): QuestionModelType[] {
        if (!this.hasPrintableQuestions) {
          return [];
        }

        const questions: QuestionModelType[] = [];

        for (const question of self.questions!.values()) {
          if (question.print) {
            questions.push(question);
          }
        }

        questions.sort(sortByField('sorting'));
        return questions;
      },

      get printableComments(): CommentModelType[] {
        if (!self.comments?.size) {
          return [];
        }

        const comments: CommentModelType[] = [];

        for (const comment of self.comments.values()) {
          if (comment.print) {
            comments.push(comment);
          }
        }

        comments.sort(sortByField('sorting'));
        return comments;
      },

      get fullName(): string | undefined {
        let name = self.first_name || '';

        if (self.last_name) {
          name = name + (name !== '' ? ' ' : '') + self.last_name;
        }

        return name === '' ? undefined : name;
      },
      get fullNameLastFirst(): string | undefined {
        let name = self.last_name || '';

        if (self.first_name) {
          name = name + (name !== '' ? ' ' : '') + self.first_name;
        }

        return name === '' ? undefined : name;
      },

      /**
       * Whether the profile is a manager.
       * This is the same logic as in ApplicationStore#isManager.
       */
      get isManager(): boolean {
        return self.role === ROLES.MANAGER;
      },

      /**
       * Whether the profile is an organizer or manager.
       * This is the same logic as in ApplicationStore#isOrganizer.
       */
      get isOrganizer(): boolean {
        return self.role === ROLES.ORGANIZER || self.role === ROLES.MANAGER;
      },

      get isManuallyCreated(): boolean {
        // TODO is it required to add manually_created_teacher here?
        return (
          self.role === 'manually_created_student' ||
          self.role === 'manually_created_organizer'
        );
      },
      get isTeacher(): boolean {
        return self.role === 'manually_created_teacher';
      }
    };
  });

export const createProfileModel = (data: any): ProfileModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return ProfileModel.create({
    id: mandatoryId(data.id),
    auth_token: data.auth_token || undefined,
    mobile_number: data.mobile_number || undefined,
    mobile_number_to_confirm: data.mobile_number_to_confirm || undefined,
    first_name: data.first_name || undefined,
    last_name: data.last_name || undefined,
    name: data.name || undefined,
    engagement: booleanOrUndefined(data.engagement),
    group: createGroupModel(data.group),
    textile_group: createGroupModel(data.group),
    role: RolesEnum.is(data.role) ? data.role : undefined,
    has_role_change_request: booleanOrUndefined(data.has_role_change_request),
    // teams: data.teams || [],

    profile_text: stringOrUndefined(data.profile_text),
    yearbook_text: stringOrUndefined(data.yearbook_text),

    questions: Array.isArray(data.questions)
      ? createMapWithTransform(data.questions, createQuestionModel)
      : undefined,
    questions_count: numberOrUndefined(data.questions_count),
    answers_count: numberOrUndefined(data.answers_count),
    answers_finished: booleanOrUndefined(data.answers_finished),

    slot1_photo: createPhotoModel(data.slot1_photo),
    slot2_photo: createPhotoModel(data.slot2_photo),
    slot3_photo: createPhotoModel(data.slot3_photo),
    creative_page_photo: createPhotoModel(data.creative_page_photo),
    yearbook_photo: createPhotoModel(data.yearbook_photo),
    photos_count: numberOrUndefined(data.photos_count),
    photos_target_count: numberOrUndefined(data.photos_target_count),
    creative_page_enabled: booleanOrUndefined(data.creative_page_enabled),
    creative_page_done: booleanOrUndefined(data.creative_page_done),

    print: booleanOrUndefined(data.print),
    print_yearbook: booleanOrUndefined(data.print_yearbook),
    target: numberOrUndefined(data.target),
    done: numberOrUndefined(data.done),

    comments_count: numberOrUndefined(data.comments_count),
    comments: Array.isArray(data.comments)
      ? createMapWithTransform(data.comments, createCommentModel)
      : undefined,

    password: data.password || undefined
  });
};

export type ProfileModelType = Instance<typeof ProfileModel>;
export default ProfileModel;
