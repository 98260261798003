import React from 'react';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Headline from 'elements/Headline';
import TextElement from 'components/TextElement/TextElement';
import Paragraph from 'components/Paragraph/Paragraph';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';

export const CheckoutBookPrice = ({
  discount,
  endPrice
}: {
  discount: number;
  endPrice: number;
}) => (
  <NonGapStackBlock>
    {discount !== 0 && (
      <HorizontalStackBlock justified={true}>
        <div />
        <Paragraph weight="BOLD" color="GREEN">
          <FormattedMessage
            id="Total discounts amount"
            values={{
              discount: (
                <FormattedNumber
                  value={discount}
                  // eslint-disable-next-line react/style-prop-object
                  style="currency"
                  currency="EUR"
                />
              )
            }}
          />
        </Paragraph>
      </HorizontalStackBlock>
    )}
    <HorizontalStackBlock justified={true}>
      <NonGapStackBlock>
        <Headline.Small>
          <FormattedMessage id="Your price" />
        </Headline.Small>
        <TextElement  color="TEXT_DARK">
          <FormattedMessage id="VAT info" />
        </TextElement>
      </NonGapStackBlock>
      <Headline.Small>
        <FormattedNumber
          value={endPrice}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency="EUR"
        />
      </Headline.Small>
    </HorizontalStackBlock>
  </NonGapStackBlock>
);
