import { RankingModelType } from 'models/RankingModel';
import { VoteModelType } from 'models/VoteModel';
import { sortByField } from 'utils/sort-functions';

export interface PrintedRanking {
  title: string;
  contestants: [string?, string?, string?];
}

export const printRankings = (
  rankings: IterableIterator<RankingModelType>,
  ignoreRankingsWithoutVotes: boolean = false
): PrintedRanking[] => {
  const list: RankingModelType[] = [];

  for (const ranking of rankings) {
    if (!ranking.name) {
      continue;
    }

    list.push(ranking);
  }

  list.sort(sortByField('sorting'));

  return list
    .map((ranking) => {
      if (ranking.select_winner_manually) {
        return {
          title: ranking.name!,
          contestants: [
            ranking.first_place,
            ranking.second_place,
            ranking.third_place
          ]
        };
      } else {
        const votes = votesForRankingItem(ranking);
        if (!votes) {
          if (ignoreRankingsWithoutVotes) {
            return undefined;
          }

          return {
            title: ranking.name!,
            contestants: ['Platz 1', 'Platz 2', 'Platz 3']
          };
        }
        const firstPlace = votes?.filter((vote) => vote.rank === 1);
        const secondPlace = votes?.filter((vote) => vote.rank === 2);
        const thirdPlace = votes?.filter((vote) => vote.rank === 3);
        return {
          title: ranking.name!,
          contestants: [
            namesByRank(firstPlace),
            namesByRank(secondPlace),
            namesByRank(thirdPlace)
          ]
        };
      }
    })
    .filter((elem) => !!elem) as PrintedRanking[];
};

export const votesForRankingItem = (
  ranking: RankingModelType
):
  | {
      rank: number;
      count: number;
      vote: VoteModelType;
    }[]
  | null => {
  if (!ranking.votes || !ranking.votes.length) {
    return null;
  }

  const votes = [];
  for (const vote of ranking.votes.values()) {
    if (!vote) {
      continue;
    }
    votes.push({
      rank: 0,
      count: vote.count || 0,
      vote
    });
  }

  if (!votes.length) {
    return null;
  }

  votes.sort((a, b) => {
    // sort by number of votes (descending)
    const aCount = (a.vote && a.vote.count) || 0;
    const bCount = (b.vote && b.vote.count) || 0;

    if (aCount < bCount) {
      return 1;
    }
    if (aCount > bCount) {
      return -1;
    }

    return 0;
  });

  // set correct ranks
  let rank = 0;
  let last = 0;

  for (let i = 0; i < votes.length; i++) {
    if (!votes[i].vote.excluded) {
      if (votes[i].count !== last) {
        last = votes[i].count;
        rank += 1;
      }
      votes[i].rank = rank;
    } else {
      votes[i].rank = 0;
    }
  }

  return votes;
};

export const namesByRank = (rank: any) => {
  const rankNames = rank.map((elem: { vote: VoteModelType }) => {
    const { vote } = elem;
    let name: string | undefined;
    if (!vote || !vote.candidates) {
      return '';
    }
    if (vote.candidates.size === 1) {
      const c = vote.candidates.values().next().value;

      name = c.name;
    } else {
      const names = [];
      for (const c of vote.candidates.values()) {
        names.push(c.name);
      }

      name = names.join(' & ');
    }
    return name;
  });
  rankNames.sort();
  return rankNames.join(', ');
};
