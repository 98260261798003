import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

const AvatarBlock = styled.div`
  background: ${COLORS.SECONDARY_DARK};
  color: rgba(${COLORS.WHITE_RGB}, 0.5);
  font-size: 1.375rem;
  font-weight: 700;
  width: 3.375rem;
  height: 3.375rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.1em;
`;

export default AvatarBlock;
