import React from 'react';

import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import YearbookTemplateComponent from 'components/print/templates/YearbookTemplateComponent';
import { createProfileModel } from 'models/ProfileModel';
import { PrintChapterProps } from './PrintChapterProps';

export const PrintYearbookChapterComponent = ({
  data,
  chapter,
  params
}: PrintChapterProps) => {
  const profiles = (data.profiles || [])
    .map((item: any) => createProfileModel(item))
    .filter((item: any) => !!item);

  return (
    <FontPreloadComponent print={true} chapter={chapter}>
      <YearbookTemplateComponent
        print={true}
        config={chapter.layout?.layoutDefinition}
        colors={chapter?.color_scheme?.templateColors || []}
        pageHeaderStyle={chapter?.font?.pageHeaderStyle}
        sectionHeaderStyle={chapter?.font?.sectionHeaderStyle}
        background={
          (chapter?.background?.id || '') +
            (chapter?.background?.extension || '') || undefined
        }
        startPosition={params.startPostion}
        startPage={params.startPage}
        title={chapter?.title}
        profiles={profiles}
      />
    </FontPreloadComponent>
  );
};

export default PrintYearbookChapterComponent;
