import { inject, observer } from 'mobx-react';
import React from 'react';

import { ApplicationStoreType } from 'models/ApplicationStore';

interface HelpSubjectProps {
  subject?: string;
  hidden?: boolean;
  applicationStore?: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class HelpSubject extends React.Component<HelpSubjectProps> {
  componentDidMount() {
    this.update();
  }

  componentWillUnmount() {
    this.update(true);
  }

  componentDidUpdate(prevProps: HelpSubjectProps) {
    if (
      prevProps.subject !== this.props.subject ||
      prevProps.hidden !== this.props.hidden
    ) {
      this.update();
    }
  }

  private update(clear = false) {
    const { applicationStore, subject, hidden } = this.props;

    if (clear) {
      applicationStore!.setHelp(undefined, false);
    } else {
      applicationStore!.setHelp(subject, hidden);
    }
  }

  render() {
    return null;
  }
}

export default HelpSubject;
