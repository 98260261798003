import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

import { FactsheetsStoreType } from '../../FactsheetsStore';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

interface QuotesListProps {
  factsheetsStore: FactsheetsStoreType;
  chaptersStore: ChaptersStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

export const HEADER_MAX_LENGTH = 50;

@inject('factsheetsStore', 'chaptersStore', 'applicationStore')
@observer
class QuestionHeaderTextScreen extends React.Component<
  QuotesListProps & HistoryProps & WrappedComponentProps
> {
  componentDidMount() {
    const { factsheetsStore } = this.props;
    const chapterId = chapterIdFromMatch(this.props.match);

    if (!factsheetsStore.isFactsheetsLoading && chapterId) {
      if (!factsheetsStore.factsheets || isPush(this.props.history)) {
        this.loadChapter(chapterId);
      }
    }
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const chapterId = chapterIdFromMatch(this.props.match);
    const prevChapterId = chapterIdFromMatch(prevProps.match);

    if (chapterId && chapterId !== prevChapterId) {
      this.loadChapter(chapterId);
    }
  }

  async save() {
    const { chaptersStore, form } = this.props;

    const chapter = chaptersStore.item;
    if (!chapter?.isFactsheetChapter) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    const chapterId = chapter.id;

    try {
      await chaptersStore.updateChapterSettings(chapterId, {
        questions_header: form.values.questions_header || ''
      });

      // form.setLoading(false);
      this.props.history.push(
        ROUTE_FACTSHEETS + '/' + chapterId + '/questions'
      );
    } catch (error: any) {
      if (!handleFormError(form, error)) {
        form.setError('base', true);
      }

      form.setLoading(false);
    }
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError(chapterId: number) {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadChapter(chapterId)} />
    );
  }

  async loadChapter(chapterId: number) {
    const { chaptersStore } = this.props;

    await chaptersStore.getChapter(chapterId);

    this.fillForm();
  }

  fillForm() {
    const { chaptersStore, form } = this.props;
    const chapter = chaptersStore.item;

    if (!chapter) {
      return null;
    }

    const settings:
      | FactsheetChapterSettingsModelType
      | undefined = chapter.settings as FactsheetChapterSettingsModelType;

    form.setField('questions_header', settings.questions_header || '');
  }

  renderQuestionHeaderInput() {
    const { chaptersStore, form } = this.props;
    const chapter = chaptersStore.item;

    if (!chapter) {
      return null;
    }

    return this.renderPage(
      <>
        <Paragraph>
          <FormattedMessage id="Questions header info" />
        </Paragraph>

        <FormStackBlock>
          <TextInputComponent
            name="questions_header"
            label={<FormattedMessage id="Factsheet questions header" />}
            maxLength={HEADER_MAX_LENGTH}
            {...form.bindInput('questions_header')}
          />

          <CharCounterComponent
            max={HEADER_MAX_LENGTH}
            value={form.values.questions_header}
          />
        </FormStackBlock>

        {form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => this.save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  }

  renderPage(content: any) {
    const { chaptersStore } = this.props;
    const chapter = chaptersStore.item;

    if (!chapter) {
      return null;
    }

    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_FACTSHEETS + '/' + chapter.id + '/questions'} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Questions header" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { applicationStore, factsheetsStore } = this.props;
    const chapterId = chapterIdFromMatch(this.props.match);

    if (!applicationStore.isOrganizer) {
      return null;
    }

    if (factsheetsStore.isFactsheetsLoading || this.props.form.loading) {
      return this.renderLoading();
    }

    if (factsheetsStore.isFactsheetsError && chapterId) {
      return this.renderError(chapterId);
    }

    // TODO check if still editable here?
    return this.renderQuestionHeaderInput();
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  // @ts-ignore
  return <QuestionHeaderTextScreen {...props} form={form} />;
});
