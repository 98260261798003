import React from 'react';

import IconBlock from 'blocks/IconBlock';
import COLORS from 'utils/constants/colors';

import {
  ICONS_VERSION, ICON_SIZES,
  IconNameType,
  UppercaseIconNameType
} from './svg-sprites-info';

export interface IconComponentProps {
  icon: IconNameType | UppercaseIconNameType;
  title?: string;
  fill?: keyof typeof COLORS;
  fillOpacity?: number;
  size?: number;
  square?: boolean;
  circleBackground?: keyof typeof COLORS;
  roundBackground?: keyof typeof COLORS;
  iconOffsetCircle?: number;
}

function IconComponent({
  icon,
  title,
  fill,
  size,
  fillOpacity = 1,
  square,
  circleBackground,
  roundBackground,
  iconOffsetCircle
}: IconComponentProps) {
  const iconLower: IconNameType = icon.toLowerCase() as IconNameType;
  const [width, height] = ICON_SIZES[iconLower] || [undefined, undefined];

  const iconOffset = iconOffsetCircle ? iconOffsetCircle : 15;
  const iconWidth = 100 - 2 * iconOffset;

  return (
    <IconBlock
      size={size}
      square={square}
      width={width}
      height={height}
      circleBackground={circleBackground}
      roundBackground={roundBackground}
    >
      {title && <title>{title}</title>}
      <use
        xlinkHref={
          '/icons/icon-symbol-sprites.svg?v=' + ICONS_VERSION + '#' + iconLower
        }
        fill={COLORS[fill || 'CURRENT_COLOR']}
        fillOpacity={fillOpacity}
        x={circleBackground || roundBackground ? iconOffset + '%' : '0'}
        y={circleBackground || roundBackground ? iconOffset + '%' : '0'}
        height={circleBackground || roundBackground ? iconWidth + '%' : '100%'}
        width={circleBackground || roundBackground ? iconWidth + '%' : '100%'}
      />
    </IconBlock>
  );
}

export default IconComponent;
