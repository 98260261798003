import styled from 'styled-components';

import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

import TaglineBlock from 'blocks/TaglineBlock';

interface MediumProps {
  setHyphen?: boolean;
  color?: keyof typeof COLORS;
}

const Medium = styled.h2<MediumProps>`
  font-size: 1.625rem;
  line-height: 1.1875;
  font-family: ${FONTS.DEFAULT};
  font-weight: 900;
  letter-spacing: 0;
  color: ${(props) => (props.color ? COLORS[props.color] : 'inherit')};

  + ${TaglineBlock} {
    margin-top: 0.625em;
  }

  > svg {
    margin-right: 0.25em;
    vertical-align: -0.1em;
  }
`;

export default Medium;
