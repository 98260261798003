import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import { CommentModelType } from 'models/CommentModel';
import {
  CommentsStoreType,
  MY_COMMENTS_ID
} from 'screens/comments/CommentsStore';
import { ROUTE_COMMENTS, ROUTE_DASHBOARD } from 'utils/constants/routes';
import { HistoryWithQueryParams, isPush } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';

import { CommentsListItem } from './CommentsListItem';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import IconComponent from 'components/IconComponent';

interface PublicCommentsListProps {
  history: HistoryWithQueryParams;
  withEditLink?: boolean;
  editLinkFrom?: string;
  profileId?: number;
  checkChapter?: boolean;
  onRetryClick?: (e: any) => void;
}

interface CommentsListProps extends PublicCommentsListProps {
  commentsStore: CommentsStoreType;
  form: FormType;
}

@inject('commentsStore')
@observer
class CommentsList extends React.Component<CommentsListProps> {
  didMountWithProfileId(profileId: number) {
    const { commentsStore } = this.props;

    if (!commentsStore.isListLoading) {
      if (
        commentsStore.commentsProfileId !== profileId ||
        isPush(this.props.history)
      ) {
        this.loadProfileComments(profileId);
      }
    }
  }

  didMountMyComments() {
    const { commentsStore } = this.props;

    if (!commentsStore.isListLoading) {
      if (
        commentsStore.commentsProfileId !== MY_COMMENTS_ID ||
        isPush(this.props.history)
      ) {
        this.loadMyComments();
      }
    }
  }

  loadProfileComments(profileId: number) {
    this.props.commentsStore.getProfileComments(profileId);
  }

  async loadMyComments() {
    const { commentsStore } = this.props;

    await commentsStore.getMyComments();

    if (this.props.checkChapter) {
      const settings:
        | FactsheetChapterSettingsModelType
        | undefined = commentsStore.chapter?.settings as
        | FactsheetChapterSettingsModelType
        | undefined;

      if (!settings?.comments_on) {
        this.props.history.replace(ROUTE_DASHBOARD);
      }
    }
  }

  componentDidMount() {
    const { profileId } = this.props;

    if (profileId) {
      this.didMountWithProfileId(profileId);
    } else {
      this.didMountMyComments();
    }
  }

  renderError() {
    return <GenericErrorComponent onRetryClick={this.props.onRetryClick} />;
  }

  renderLoading() {
    return <LoadingOverlayComponent />;
  }

  renderList() {
    const {
      profileId,
      commentsStore,
      form,
      withEditLink,
      editLinkFrom
    } = this.props;

    if (!commentsStore.comments?.size && !profileId) {
      return (
        <EmptyStateComponent
          headerId="no comments yet"
          textId="add some comments"
        />
      );
    }

    const displayProfile = !profileId;
    const displayAuthor = !displayProfile;

    let comments: CommentModelType[];
    let heading: ReactNode;

    if (!form.values.filter) {
      comments = commentsStore.sortedComments;
      heading = (
        <FormattedMessage
          id="comments count"
          values={{
            count: comments.length
          }}
        />
      );
    } else {
      comments = commentsStore.filteredComments(form.values.filter);
      heading = (
        <FormattedMessage
          id="comments filter count"
          values={{
            count: comments.length
          }}
        />
      );
    }

    return (
      <ItemStackBlock gap="L">
        <SearchListInput form={form} placeholderId="Find comment" />

        { profileId && (
          <LinkBlock
            inline={true}
            slim={true}
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            to={ROUTE_COMMENTS + '/sort/' + profileId}
          >
            <IconComponent icon="sort_v" />
            <FormattedMessage id="Sorting order" />
          </LinkBlock>
        )}

        <ItemStackBlock gap="S">
          <UppercaseHeading>{heading}</UppercaseHeading>

          <ListStackBlock>
            {comments.map((comment) => (
              <CommentsListItem
                key={comment.id}
                comment={comment}
                displayAuthor={displayAuthor}
                displayProfile={displayProfile}
                asEditLink={withEditLink}
                editLinkFrom={editLinkFrom}
              />
            ))}
          </ListStackBlock>
        </ItemStackBlock>
      </ItemStackBlock>
    );
  }

  render() {
    const { commentsStore } = this.props;

    if (commentsStore.loadingState === 'loading') {
      return this.renderLoading();
    }

    if (commentsStore.loadingState === 'error') {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default (props: PublicCommentsListProps) => {
  const form = useForm();
  // @ts-ignore
  return <CommentsList {...props} form={form} />;
};
