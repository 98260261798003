import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import Center from 'elements/Center';
import NakedButton from 'elements/NakedButton';
import UppercaseHeading from 'elements/UppercaseHeading';
import { intl } from 'i18n';
import { CityModelType } from 'models/CityModel';
import { Link } from 'react-router-dom';
import { FormType } from 'utils/hooks/useForm';

const SignupSchoolCityScreen = ({
  form,
  search,
  selectCity,
  resetCity,
  cities,
  loading,
  done
}: {
  form: FormType;
  search: () => void;
  selectCity: (id: number, name?: string) => void;
  resetCity: () => void;
  cities?: CityModelType[];
  loading: boolean;
  done: boolean;
}) => {
  return (
    <>
      <SignupHeaderComponent
        progressStatus={(100 / 10) * 2}
        backClick={() => {
          resetCity();
        }}
      />
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Your city" />}
          text={<FormattedMessage id="signup city select" />}
        />

        <TextInputComponent
          icon="MAGNIFY"
          iconFill="TEXT_LIGHT"
          name="city_name"
          label={<FormattedMessage id="Find city" />}
          {...form.bindInput('city_name')}
          placeholder={intl.formatMessage({ id: 'Find city' })}
        />
        <ButtonBlock background="PRIMARY" onClick={search}>
          <FormattedMessage id="Find city" />
        </ButtonBlock>

        {loading && <LoadingIndicatorComponent />}

        {cities && cities.length > 0 && (
          <>
            <Divider />
            <ItemStackBlock gap="M">
              <UppercaseHeading>
                <FormattedMessage
                  id="{count} cities"
                  values={{
                    count: cities.length
                  }}
                />
              </UppercaseHeading>
              <ListStackBlock>
                {cities.map((city) => (
                  <NakedButton
                    key={city.id}
                    onClick={() => {
                      selectCity(city.id, city.name);
                    }}
                  >
                    <EveryCard>
                      <EveryCardPadding>
                        <EveryCardBody>
                          <EveryCardHeadline>{city.name}</EveryCardHeadline>
                          <Paragraph size="S" color="TEXT_LIGHT">
                            {city.county}
                          </Paragraph>
                        </EveryCardBody>
                      </EveryCardPadding>
                    </EveryCard>
                  </NakedButton>
                ))}
              </ListStackBlock>
            </ItemStackBlock>
          </>
        )}

        {done && (
          <>
            <Divider />
            {cities && cities.length === 0 && (
              <Center>
                <ItemStackBlock gap="XXS">
                  <Paragraph color="TEXT_LIGHT" size="L">
                    <FormattedMessage id="signup no city found" />
                  </Paragraph>
                  <Paragraph size="S" color="TEXT_LIGHT">
                    <FormattedMessage id="signup no city found info" />
                  </Paragraph>
                </ItemStackBlock>
              </Center>
            )}
            <Link to="?screen=add_city_and_school">
              <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK">
                <FormattedMessage id="Add city" />
              </ButtonBlock>
            </Link>
          </>
        )}
      </PageStackBlock>
    </>
  );
};

export default SignupSchoolCityScreen;
