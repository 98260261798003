import styled, { AnyStyledComponent } from 'styled-components';

import CheckboxElement from './CheckboxElement';
import LabelElement from './LabelElement';

interface CustomCheckboxBlockElements {
  LabelElement?: any;
  CheckboxElement?: any;
}

const CustomCheckboxBlock: AnyStyledComponent &
  CustomCheckboxBlockElements = styled.label`
  display: block;
`;

CustomCheckboxBlock.LabelElement = LabelElement;
CustomCheckboxBlock.CheckboxElement = CheckboxElement;
export default CustomCheckboxBlock;
