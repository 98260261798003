import React from 'react';

import ColorSwatchesBlock from 'blocks/ColorSwatchesBlock';

interface ColorSwatchesComponentProps {
  first?: string;
  second?: string;
  third?: string;
}

function ColorSwatchesComponent({ first, second, third }: ColorSwatchesComponentProps) {
  return (
    <ColorSwatchesBlock>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          fill={first ? first : '#888'}
          width="18"
          height="56"
          x="12"
          y="12"
          rx="2"
          ry="2"
        />
        <rect
          fill={second ? second : '#888'}
          width="18"
          height="56"
          x="31"
          y="12"
          rx="2"
          ry="2"
        />
        <rect
          fill={third ? third : '#888'}
          width="18"
          height="56"
          x="50"
          y="12"
          rx="2"
          ry="2"
        />
      </g>
    </ColorSwatchesBlock>
  );

}

export default ColorSwatchesComponent;
