import styled from 'styled-components';

import Input from 'elements/Input';
import InputAppend from 'elements/InputAppend';
import InputMessage from 'elements/InputMessage';

import COLORS from 'utils/constants/colors';
import TextElement from 'components/TextElement/TextElement';

interface InputGroupProps {
  error?: boolean;
  ok?: boolean;
}

const InputGroupBlock = styled.div<InputGroupProps>`
  display: block;
  width: 100%;

  ${TextElement} {
    display: inline-block;
    margin-top: 0.5em;
  }

  ${(props) =>
    props.error &&
    `
    ${Input},
    ${InputAppend} {
      border-color: ${COLORS.RED};
    }

    ${InputMessage} {
      color: ${COLORS.BLACK};
      background: ${COLORS.RED_LIGHT};
    }
  `};
  ${(props) =>
    props.ok &&
    `
    ${Input},
    ${InputAppend} {
      border-color: ${COLORS.GREEN};
    }
    ${Input}:focus {
      outline: none;
    }

    ${InputMessage} {
      color: ${COLORS.GREEN};
      background: ${COLORS.GREEN_LIGHT};
    }
  `};
`;

export default InputGroupBlock;
