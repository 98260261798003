import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import UppercaseHeading from 'elements/UppercaseHeading';
import { intl } from 'i18n';
import { useTextileGroupsQuery } from 'queries/textile_deals/useTextileGroupsQueries';
import {
  useCreateTextileNotificationQuery,
  useUpdateTextileNotificationQuery
} from 'queries/textile_deals/useTextileNotificationQueries';
import { useTextileOrderForManagementQuery } from 'queries/textile_deals/useTextileOrderQueries';
import {
  useTextileProfileQuery,
  useUpdateTextileProfileQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface EditProfileFromBaseProps extends HistoryProps {
  what: 'set' | 'overwrite';
}

interface FormProps {
  form: FormType;
}

const EditProfileForm: React.FC<EditProfileFromBaseProps & FormProps> = (
  props
) => {
  const [showNotificationForm, setShowNotificationForm] = useState<boolean>(
    false
  );
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const textileProfile = useTextileProfileQuery(id, textileOrderId);
  const textileGroups = useTextileGroupsQuery(textileOrderId);
  const textileOrder = useTextileOrderForManagementQuery(textileOrderId);
  const updateTextileProfile = useUpdateTextileProfileQuery();
  const updateTextileNotification = useUpdateTextileNotificationQuery(
    textileOrderId
  );
  const createTextileNotification = useCreateTextileNotificationQuery(
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('management');
    const data = textileProfile.data;
    const groups = textileGroups.data;
    const appStateOrder = textileAppState?.textile_order;

    if (data && groups && appStateOrder) {
      props.form.setField('first_name', data.first_name || '');
      props.form.setField('last_name', data.last_name || '');
      if (appStateOrder.show_groups) {
        props.form.setField(
          'textile_group_id',
          data.textile_group?.id.toString() || groups[0]?.id.toString() || ''
        );
      }
      props.form.setField('print', data.print?.toString() || 'false');
      props.form.setField(
        'notification',
        data.print_data_notification?.notification || ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileProfile.data, textileGroups.data, textileAppState?.textile_order]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink
            to={
              props.what === 'overwrite'
                ? textileOrderRoute(
                    props.match,
                    '/management/overview/' + idFromMatch(props.match)
                  )
                : textileOrderRoute(props.match, '/management')
            }
          />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileProfile.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileProfile.refetch()} />
    );
  }

  if (textileGroups.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileGroups.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (
    props.form.loading ||
    !textileAppState ||
    textileProfile.isLoading ||
    !textileProfile.data ||
    textileGroups.isLoading ||
    !textileGroups.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const save = () => {
    const { form, match, history, what } = props;

    if (!id || !textileOrderId) {
      return;
    }

    const isMyProfile = id === textileAppState.textile_profile.id;

    form.resetErrors();
    form.setLoading(true);

    updateTextileProfile.mutate(
      {
        data: { ...form.values, name_verified: true },
        id: id,
        textileOrderId
      },
      {
        onSuccess: () => {
          if (what === 'overwrite' && !isMyProfile) {
            saveTextileNotification(textileOrderId);
          } else {
            if (isMyProfile) {
              history.push(
                textileOrderRoute(match, '/my_profile/overview/' + id)
              );
            } else {
              history.push(
                textileOrderRoute(match, '/management/overview/' + id)
              );
            }
          }
        },
        onError: (error: any) => {
          form.setLoading(false)
          if (handleFormError(form, error)) {
            return;
          }
        }
      }
    );
  };

  const saveTextileNotification = (
    textileOrderId: number
  ) => {
    const { form, history, match } = props;
    const profile = textileProfile.data;
    const currentTextileProfile = textileAppState.textile_profile;

    form.setLoading(true);
    if (profile.print_data_notification) {
      const patch = {
        notification: form.values.notification,
        notification_type: 'print_data',
        author_id: currentTextileProfile.id
      };
      updateTextileNotification.mutate({
        data: patch,
        id: profile.print_data_notification.id,
        textileOrderId
      }, {
        onSuccess: () => {
          history.push(
            textileOrderRoute(match, '/management/overview/' + id)
          );
        }
      });
    } else {
      const patch =         {
        notification: form.values.notification,
        notification_type: 'print_data',
        author_id: currentTextileProfile.id,
        textile_profile_id: profile.id
      }
      createTextileNotification.mutate({ data: patch, textileOrderId}, {
        onSuccess: () => {
          history.push(
            textileOrderRoute(match, '/management/overview/' + id)
          );
        }
      })
    }
  };

  const confirmEdit = () => {
    props.form.setConfirm('edit');
  };

  const preformEdit = () => {
    save();
  };

  const cancel = () => {
    props.form.setConfirm(false);
  };

  const renderSaveButton = (next?: boolean, isMyProfile?: boolean) => {
    const { form } = props;
    return (
      <>
        {form.errors.base && <GenericErrorComponent />}

        {isMyProfile ? (
          <ButtonBlock background="PRIMARY" onClick={() => preformEdit()}>
            <FormattedMessage id="Save" />
          </ButtonBlock>
        ) : next ? (
          <ButtonBlock
            background="PRIMARY"
            onClick={() => setShowNotificationForm(true)}
          >
            <FormattedMessage id="Next" />
          </ButtonBlock>
        ) : (
          <ButtonBlock background="PRIMARY" onClick={() => confirmEdit()}>
            <FormattedMessage id="Save" />
          </ButtonBlock>
        )}
      </>
    );
  };

  const renderForm = () => {
    const { form, what } = props;
    const profile = textileProfile.data;

    const isMyProfile = profile.id === textileAppState.textile_profile.id;

    const groups: TextileGroup[] = textileGroups.data;
    const showGroups = textileAppState.textile_order.show_groups;

    let extra;
    if (form.confirm === 'edit') {
      if (what === 'set') {
        extra = (
          <ConfirmBoxComponent
            header={<FormattedMessage id="confirm edit student header" />}
            text={<FormattedMessage id="confirm edit student info" />}
            confirmText={<FormattedMessage id="Confirm" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => preformEdit()}
            onAbort={() => cancel()}
            confirmColor="PRIMARY"
          />
        );
      }
    }

    const day = intl.formatDate(textileOrder.data.edit_print_data_deadline, {
      day: '2-digit'
    });
    const month = intl.formatDate(textileOrder.data.edit_print_data_deadline, {
      month: '2-digit'
    });
    const date = day + '/' + month;

    return renderPage(
      <>
        {extra}
        <PageHeader
          headline={<FormattedMessage id="Edit name" />}
          text={
            <>
              <IconComponent icon="USER" /> {profile.name}
            </>
          }
        />

        <Paragraph size="L" color="TEXT_LIGHT">
          <FormattedMessage id="textile profile edit info" />
        </Paragraph>

        {textileOrder.data.edit_print_data_deadline &&
        textileOrder.data.allow_edit_print_data ? (
          <Paragraph>
            <IconComponent icon="CLOCK_CIRCLE" fill="BLACK" />
            <FormattedMessage id="preorder open til" /> {date}
          </Paragraph>
        ) : (
          <Paragraph>
            <IconComponent icon="PAUSE" fill="BLACK" />
            <FormattedMessage id="editing state paused" />
          </Paragraph>
        )}

        <Divider />

        <FormStackBlock>
          <>
            <UppercaseHeading>
              <FormattedMessage id="Print" />
            </UppercaseHeading>

            <ListStackBlock>
              <RadioButtonComponent
                name="print"
                value={true}
                label={<FormattedMessage id="Print name" />}
                {...form.bindRadioButton('print', 'true')}
              />

              <RadioButtonComponent
                name="print"
                value={false}
                label={<FormattedMessage id="no print name" />}
                {...form.bindRadioButton('print', 'false')}
              />
            </ListStackBlock>
          </>

          {form.values.print === 'true' && (
            <>
              <TextInputComponent
                name="first_name"
                label={<FormattedMessage id="First name" />}
                {...form.bindInput('first_name')}
                autoFocus={true}
              />

              <TextInputComponent
                name="last_name"
                label={<FormattedMessage id="Last name" />}
                {...form.bindInput('last_name')}
              />

              {showGroups && groups && groups.length !== 0 && (
                <>
                  <UppercaseHeading>
                    <FormattedMessage id="Groups" />
                  </UppercaseHeading>
                  <ListStackBlock>
                    {groups.map((group: TextileGroup) => (
                      <RadioButtonComponent
                        key={group.id}
                        name="group_id"
                        value={group.id}
                        label={group.name}
                        {...form.bindRadioButton(
                          'textile_group_id',
                          group.id.toString()
                        )}
                      />
                    ))}
                  </ListStackBlock>
                </>
              )}
            </>
          )}
        </FormStackBlock>
        {renderSaveButton(what === 'overwrite', isMyProfile)}
      </>
    );
  };

  const renderNotificationForm = () => {
    const { form } = props;

    let extra;
    if (form.confirm === 'edit') {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm overwrite student header" />}
          text={<FormattedMessage id="confirm overwrite student info" />}
          confirmText={<FormattedMessage id="Confirm" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => preformEdit()}
          onAbort={() => cancel()}
          confirmColor="PRIMARY"
        />
      );
    }

    return renderPage(
      <>
        {extra}
        <PageHeader
          headline={<FormattedMessage id="change reason" />}
          text={<FormattedMessage id="change reason info" />}
        />

        <FormStackBlock>
          <TextareaComponent
            name="notification"
            height={7}
            {...form.bindInput('notification')}
            maxLength={60}
          />

          <CharCounterComponent max={60} value={form.values.notification} />
        </FormStackBlock>
        {renderSaveButton()}
        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          onClick={() => confirmEdit()}
        >
          <FormattedMessage id="Skip" />
        </ButtonBlock>
      </>
    );
  };

  if (showNotificationForm) {
    return renderNotificationForm();
  }

  return renderForm();
};

export default (props: EditProfileFromBaseProps) => {
  const form = useForm();
  // @ts-ignore
  return <EditProfileForm {...props} form={form} />;
};
