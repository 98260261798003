import React, { ReactNode } from 'react';

import CardBlock from 'blocks/CardBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import {
  inject,
  observer
} from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { BookPriceStoreType } from 'models/BookPriceStore';
import { DeliveryDatesStoreType } from 'models/DeliveryDatesStore';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CheckoutStoreType } from 'screens/checkout/CheckoutStore';
import ChooseDeliveryDate from 'screens/checkout/screens/ChooseDeliveryDate';
import { assert } from 'utils/assert';
import {
  ROUTE_CALCULATOR,
  ROUTE_DASHBOARD,
  ROUTE_ORDER,
  ROUTE_ORDER_OVERVIEW
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, {
  FormType,
  handleFormError
} from 'utils/hooks/useForm';

import DownloadInvoiceButton from './DownloadInvoiceButton';
import OrderAgeVerification from './OrderAgeVerification';
import OrderPaymentInfo from './OrderPaymentInfo';
import OrderTimeline from './OrderTimeline';

interface OrderScreenProps {
  applicationStore: ApplicationStoreType;
  checkoutStore: CheckoutStoreType;
  deliveryDatesStore: DeliveryDatesStoreType;
  bookPriceStore: BookPriceStoreType;
  form: FormType;
}

/**
 * This Screen represents first 5 Screens figma Order "Bestellung abschließen/ Bestellung abgeschlossen"
 */
@inject(
  'applicationStore',
  'checkoutStore',
  'deliveryDatesStore',
  'bookPriceStore'
)
@observer
class OrderScreen extends React.Component<OrderScreenProps & HistoryProps> {
  state = {
    showDeliveryDate: false
  };

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    await this.getCheckout();
  }

  async getCheckout() {
    const { applicationStore, checkoutStore, history } = this.props;

    try {
      // This also gets the book
      await checkoutStore.getCheckout();
    } catch (error: any) {
      // No checkout, redirect
      history.push(ROUTE_CALCULATOR);
      throw error;
    }

    // Check if prerequisites are met
    const { book } = applicationStore;
    const { checkout } = checkoutStore;
    assert(book);
    assert(checkout);
    if (
      applicationStore.currentUser &&
      checkout.author &&
      applicationStore.currentUser.id !== checkout.author.id
    ) {
      // TODO Display message instead
      this.backToCalculator();
      return;
    }

    if (
      (book && book.order_state === 'open') ||
      !(checkout && checkout.invoice)
    ) {
      history.push(ROUTE_CALCULATOR);
    }

    this.setState({ showDeliveryDate: false });
  }

  backToCalculator() {
    this.props.history.replace(ROUTE_CALCULATOR, {
      redirected: true
    } as any);
  }

  renderContent(content: ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackLink
            to={this.state.showDeliveryDate ? ROUTE_ORDER : ROUTE_DASHBOARD}
            messageId={
              this.state.showDeliveryDate ? 'Back' : 'Back to dashboard long'
            }
          />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="order" />
      </>
    );
  }

  renderError() {
    return this.renderContent(
      <GenericErrorComponent onRetryClick={() => this.loadData()} />
    );
  }

  async handleUploadParentalApproval(file: File) {
    const { checkoutStore, form } = this.props;
    const { checkout } = checkoutStore;
    assert(checkout);

    form.resetErrors();
    try {
      await checkoutStore.uploadParentalApproval({
        parental_approval: {
          file
        }
      });
    } catch (error: any) {
      handleFormError(form, error);
      return;
    }
  }

  render() {
    const { applicationStore, checkoutStore, form } = this.props;
    const { currentUser, book } = applicationStore;
    const { checkout, getLoadingState, updateLoadingState } = checkoutStore;

    if (getLoadingState === 'loading') {
      return this.renderContent(<LoadingIndicatorComponent />);
    }

    if (
      getLoadingState === 'error' ||
      !book ||
      !checkout ||
      !checkout.invoice ||
      !checkout.author ||
      !currentUser
    ) {
      return this.renderError();
    }

    const { invoice } = checkout;

    const isAuthor = currentUser.id === checkout.author.id;

    return this.renderContent(
      <>
        {this.state.showDeliveryDate ? (
          <ChooseDeliveryDate
            deliveryDatesStore={this.props.deliveryDatesStore}
            deliveryDateSet={() => this.getCheckout()}
            checkoutFinished={true}
            type='book'
          />
        ) : (
          <>
            <Headline.Large>
              <FormattedMessage id="Order finish" />
            </Headline.Large>

            <Link to={ROUTE_ORDER_OVERVIEW}>
              <CardBlock>
                <PaddingBlock arrow={true}>
                  <Paragraph>
                    <FormattedMessage id="Order show details" />
                  </Paragraph>
                </PaddingBlock>
              </CardBlock>
            </Link>

            {checkout.minor &&
              (updateLoadingState === 'loading' ? (
                <LoadingIndicatorComponent />
              ) : (
                <>
                  {updateLoadingState === 'error' && <GenericErrorComponent />}
                  <OrderAgeVerification
                    checkout={checkout}
                    form={form}
                    handleUploadParentalApproval={(file) =>
                      this.handleUploadParentalApproval(file)
                    }
                  />
                </>
              ))}

            <Divider />

            <OrderPaymentInfo
              paymentReceived={checkout.payment_received || false}
              price={invoice.price}
              usage={'b-' + book.id}
            />

            {invoice.invoice_pdf && <DownloadInvoiceButton invoice={invoice} />}

            <Divider />

            <OrderTimeline
              book={book}
              deliveryDate={book.delivery_date}
              paymentReceived={checkout.payment_received || false}
              changeStateShowDeliveryDate={() =>
                this.setState({
                  showDeliveryDate: true
                })
              }
              showButton={isAuthor}
            />
          </>
        )}
      </>
    );
  }
}

export default (props: any) => {
  const form = useForm();
  return <OrderScreen {...props} form={form} />;
};
