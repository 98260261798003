import React from 'react';

import { ProfileModelType } from 'models/ProfileModel';
import {
  CommonTemplateProps,
  pageParamsFromProps
} from './CommonTemplateProps';
import { ImagePageSlot } from './ImagePageTemplateComponent';
import replaceVariables, {
  PagePosition,
  PageParams
} from 'utils/chunker/replace-variables';
import NotAvailableComponent from '../processing/NotAvailableComponent';
import PageComponent, {
  PAGE_SIZE_PRINT,
  PAGE_SIZE_A4
} from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import WaitForImages from './WaitForImages';
import sanitizeHtml from 'utils/chunker/sanitize-html';

const PLACEHOLDER_PREVIEW = '/images/content-preview/yearbook-placeholder.jpg';
const PLACEHOLDER_PRINT = '/images/print/yearbook-placeholder.jpg';

export interface YearbookTemplateConfig {
  leftBackground?: string;
  rightBackground?: string;
  leftAdditionalBackground?: string;
  rightAdditionalBackground?: string;

  imageBlock?: string;
  fittedImageBlock?: string; // hint: currently not supported!

  titleMargin?: number;
  slots?: ImagePageSlot[];
  additionalSlots?: ImagePageSlot[];
}

export interface YearbookTemplateProps extends CommonTemplateProps {
  // theme
  config: YearbookTemplateConfig;

  // content
  profiles: ProfileModelType[];
}

export default class YearbookTemplateComponent extends React.Component<
  YearbookTemplateProps
> {
  generateReplaceParams(
    position: PagePosition = 'left',
    page: number = 1
  ): PageParams {
    return pageParamsFromProps(this.props, position, page);
  }

  generateBackground(
    position: PagePosition,
    isAdditional: boolean,
    page?: number
  ): string {
    const { config } = this.props;

    const [leftBG, rightBG] = isAdditional
      ? [config.leftAdditionalBackground, config.rightAdditionalBackground]
      : [config.leftBackground, config.rightBackground];

    const background = position === 'right' && rightBG ? rightBG : leftBG;
    if (!background) {
      return '';
    }

    const params = this.generateReplaceParams(position, page);
    return replaceVariables(background, params) || '';
  }

  splitProfiles(returnEmptyPage: boolean = true): ProfileModelType[][] {
    const {
      profiles,
      config: { slots, additionalSlots }
    } = this.props;

    const numSlots: number = slots?.length || 0;
    const numAdditionalSlots: number = additionalSlots?.length || numSlots;

    if (!profiles.length || !numSlots) {
      return returnEmptyPage ? [[]] : [];
    }

    const pages: ProfileModelType[][] = [];

    pages.push([]);
    let currentPage = 0;

    let max = numSlots;
    let count = 0;

    for (const profile of profiles) {
      pages[currentPage].push(profile);
      count++;

      if (count >= max) {
        pages.push([]);
        currentPage++;

        max = numAdditionalSlots;
        count = 0;
      }
    }

    if (pages.length && !pages[pages.length - 1].length) {
      delete pages[pages.length - 1];
    }

    return pages;
  }

  renderSlotBlock(slot: ImagePageSlot, profile?: ProfileModelType) {
    const { print } = this.props;
    const { imageBlock } = this.props.config;

    const hasPhoto = !!profile?.yearbook_photo?.photo;

    // we currently do not support fitted images
    // const block =
    //   hasPhoto && profile!.yearbook_photo!.fitToFrame
    //     ? fittedImageBlock
    //     : imageBlock;
    const block = imageBlock;

    const url = !hasPhoto
      ? print
        ? PLACEHOLDER_PRINT
        : PLACEHOLDER_PREVIEW
      : print
      ? profile!.yearbook_photo!.photo?.cropped
      : profile!.yearbook_photo!.photo?.preview;

    const blockWithContent =
      replaceVariables(block, this.generateReplaceParams(), [
        ['url', sanitizeHtml(url) || ''],
        ['left', slot.position[0].toString() + 'px'],
        ['top', slot.position[1].toString() + 'px'],
        ['width', slot.size[0].toString() + 'px'],
        ['height', slot.size[1].toString() + 'px'],
        ['caption_title', sanitizeHtml(profile!.fullName) || ''],
        ['caption', sanitizeHtml(profile!.yearbook_text) || '']
      ]) || '';

    return <div dangerouslySetInnerHTML={{ __html: blockWithContent }} />;
  }

  render() {
    const {
      print,
      title,
      config: { slots, additionalSlots, titleMargin },
      startPosition,
      startPage,
      render,
      firstPageOnly
    } = this.props;

    let pages = this.splitProfiles();

    if (!pages.length || !slots) {
      return <NotAvailableComponent print={print} />;
    }

    if (firstPageOnly && pages.length) {
      pages = [pages[0]];
    }

    const slotAreaMargin = !!title && titleMargin ? titleMargin + 'px' : 0;
    const isRight = startPosition === 'right';
    let isCurrentRight = !isRight;

    let currentSlots = slots;

    return (
      <>
        {pages.map((page, pageIdx) => {
          if (pageIdx === 1) {
            currentSlots = !additionalSlots?.length ? slots : additionalSlots;
          }

          isCurrentRight = !isCurrentRight;

          const currentPageNumber = startPage + pageIdx;
          const isAdditional = pageIdx !== 0;

          const background = this.generateBackground(
            isCurrentRight ? 'right' : 'left',
            isAdditional,
            currentPageNumber
          );

          const result = (
            <PageComponent
              key={pageIdx}
              print={print}
              previewSize={print ? PAGE_SIZE_PRINT : PAGE_SIZE_A4}
              background={background}
            >
              <PageContentComponent>
                <div
                  style={{
                    position: 'relative',
                    marginTop: pageIdx === 0 ? slotAreaMargin : 0
                  }}
                >
                  {page.map((profile, profileIndex) => {
                    const slot = currentSlots[profileIndex];
                    if (!slot) {
                      return null;
                    }

                    return (
                      <React.Fragment key={profileIndex}>
                        {this.renderSlotBlock(slot, profile)}
                      </React.Fragment>
                    );
                  })}
                </div>
              </PageContentComponent>
            </PageComponent>
          );

          return !render ? result : render(result, pageIdx);
        })}

        {print && <WaitForImages />}
      </>
    );
  }
}
