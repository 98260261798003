import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  FunnelCard,
  FunnelCardLabel,
  FunnelCardTop
} from 'blocks/FunnelCard/FunnelCard';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import IconComponent from 'components/IconComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Headline from 'elements/Headline';
import { FormType } from 'utils/hooks/useForm';

const Design = ({ form }: { form: FormType }) => (
  <PageStackBlock>
    <Headline.Large>
      <FormattedMessage id="funnel design" />
    </Headline.Large>

    <TwoColumnBlock gap="M">
      <Link
        to="?screen=year"
        onClick={() => form.setField('design', 'indesign')}
      >
        <FunnelCard>
          <FunnelCardTop>
            <IconComponent
              icon="INDESIGN"
              fill="PRIMARY"
              size={5}
              circleBackground="PRIMARY_LIGHT"
            />
          </FunnelCardTop>
          <FunnelCardLabel>
            <FormattedMessage id="Indesign" />
          </FunnelCardLabel>
        </FunnelCard>
      </Link>

      <Link
        to="?screen=year"
        onClick={() => form.setField('design', 'nuggit design')}
      >
        <FunnelCard>
          <FunnelCardTop>
            <IconComponent
              icon="NUGGIT_LOGO"
              fill="PRIMARY"
              size={5}
              circleBackground="PRIMARY_LIGHT"
            />
          </FunnelCardTop>
          <FunnelCardLabel>
            <FormattedMessage id="Nuggit design" />
          </FunnelCardLabel>
        </FunnelCard>
      </Link>

      <Link
        to="?screen=year"
        onClick={() => form.setField('design', 'affinity')}
      >
        <FunnelCard>
          <FunnelCardTop>
            <IconComponent
              icon="AFFINITY"
              fill="PRIMARY"
              size={5}
              circleBackground="PRIMARY_LIGHT"
            />
          </FunnelCardTop>
          <FunnelCardLabel>
            <FormattedMessage id="Affinity" />
          </FunnelCardLabel>
        </FunnelCard>
      </Link>

      <Link to="?screen=year" onClick={() => form.setField('design', 'other')}>
        <FunnelCard>
          <FunnelCardTop>
            <IconComponent
              icon="QUESTIONMARK"
              fill="PRIMARY"
              size={5}
              circleBackground="PRIMARY_LIGHT"
            />
          </FunnelCardTop>
          <FunnelCardLabel>
            <FormattedMessage id="Other" />
          </FunnelCardLabel>
        </FunnelCard>
      </Link>
    </TwoColumnBlock>
  </PageStackBlock>
);

export default Design;
