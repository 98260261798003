import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import BackClickLink from 'domain/Links/BackClickLink';
import InputMessage from 'elements/InputMessage';
import { intl } from 'i18n';
import { AccountStoreType } from 'models/AccountStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import {
  ROUTE_ACCOUNT_MOBILE_NUMBER
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface AccountNumberScreenProps {
  applicationStore: ApplicationStoreType;
  accountStore: AccountStoreType;
  form: FormType;
}

@inject('applicationStore', 'accountStore')
@observer
class AccountNumberScreen extends React.Component<
  AccountNumberScreenProps & HistoryProps
> {
  async componentDidMount() {
    const { form } = this.props;

    form.setField('mobile_number_to_confirm', '');
  }

  async handleSubmit() {
    const { applicationStore, accountStore, form } = this.props;
    if (!applicationStore.currentAccount) {
      return;
    }

    form.resetErrors();

    try {
      await accountStore.updatePhoneNumber(
        applicationStore.currentAccount.id,
        form.values.mobile_number_to_confirm || ''
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by ProfilesStore internally
      return;
    }

    this.props.history.push(ROUTE_ACCOUNT_MOBILE_NUMBER + '/confirm');
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => this.props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  }

  renderView() {
    const { applicationStore, accountStore, form } = this.props;
    const { currentAccount } = applicationStore;

    if (!currentAccount) {
      // satisfy TS
      return this.renderPage(null);
    }

    return this.renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="confirm edit number header" />}
          text={
            <>
              <IconComponent icon="USER" /> {currentAccount.first_name}
            </>
          }
        />

        <FormStackBlock>
          <TextInputComponent
            name="old_mobile_number"
            value={currentAccount.mobile_number}
            label={intl.formatMessage({ id: 'Old mobile number' })}
            readOnly={true}
            disabled={true}
          />

          <InputMessage warning={true}>
            <Paragraph>
              <FormattedMessage id="number edit info" />
            </Paragraph>
          </InputMessage>

          {accountStore.loadingState === 'update_error' && (
            <GenericErrorComponent />
          )}

          <TextInputComponent
            name="mobile_number_to_confirm"
            placeholder={intl.formatMessage({
              id: 'phone number placeholder'
            })}
            label={intl.formatMessage({ id: 'New mobile number' })}
            {...form.bindInput('mobile_number_to_confirm')}
            autoFocus={true}
            noHotjar={true}
          />
        </FormStackBlock>

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id="Send confirm link" />
        </ButtonBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  render() {
    const { applicationStore, accountStore } = this.props;

    if (applicationStore.isRefreshing || accountStore.loadingState) {
      return this.renderLoading();
    }

    if (accountStore.loadingState === 'error') {
      return this.renderError();
    }

    return this.renderView();
  }
}

export default (props: any) => {
  const form = useForm();
  return <AccountNumberScreen {...props} form={form} />;
};
