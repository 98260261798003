import styled from 'styled-components';

import {
  PAGE_SIZE_A4,
  PAGE_SIZE_PRINT,
  TOP_LEFT_A4
} from 'components/print/structure/PageComponent';

export const LayoutPreviewPrintBorder = styled.div`
  position: absolute;
  top: ${TOP_LEFT_A4[0]}px;
  left: ${TOP_LEFT_A4[0]}px;
  width: ${PAGE_SIZE_A4[0]}px;
  height: ${PAGE_SIZE_A4[1]}px;
  border: 2px dashed red;
`;

export const LayoutPreviewPageBorder = styled.div<{
  visible?: boolean;
  margin?: boolean;
}>`
  display: inline-block;
  position: relative;
  width: ${PAGE_SIZE_PRINT[0]}px;
  height: ${PAGE_SIZE_PRINT[1]}px;
  ${({ visible }) => (visible ? 'outline: 2px dashed red;' : undefined)}
  ${({ margin }) => (margin ? 'margin: 10px;' : undefined)}
`;
