import React from 'react';
import { FormattedMessage } from 'react-intl';

import config from 'config';

import SupportButtonBlock from 'blocks/HeaderBlock/SupportButtonBlock';
import TextElement from 'components/TextElement/TextElement';

export default ({
  intlId,
  useErrorNumber
}: {
  intlId?: string;
  useErrorNumber?: boolean;
}) => (
  <SupportButtonBlock
    background="SECONDARY_DARK"
    onClick={() =>
      window.open(
        'https://api.whatsapp.com/send?phone=' +
        encodeURIComponent(
          useErrorNumber
            ? config.whatsappSupportError
            : config.whatsappSupport
        )
      )
    }
  >

    <TextElement  color="TEXT_DARK">
      <FormattedMessage id={intlId || 'Support'} />
    </TextElement>
  </SupportButtonBlock>
);
