import React from 'react';

import {
  LoadingDotElement,
  LoadingOverlayElement,
  LoadingSpinnerElement
} from 'blocks/LoadingBlock';

/**
 * Loading overlay, covers the whole screen.
 */
const LoadingOverlayComponent = () => (
  <LoadingOverlayElement>
    <LoadingSpinnerElement style={{ margin: 'auto' }}>
      <LoadingDotElement />
      <LoadingDotElement />
    </LoadingSpinnerElement>
  </LoadingOverlayElement>
);

export default LoadingOverlayComponent;
