import { intl } from 'i18n';
import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import MottoModel, {
  createMottoModel,
  MottoModelType
} from 'models/MottoModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { ROUTE_MOTTOS_ORG } from 'utils/constants/routes';
import { createMapWithTransform } from 'utils/create-map';
import history from 'utils/history';
import { sortByName } from 'utils/sort-functions';

export interface RankedMottoType {
  rank: number;
  count: number;
  motto: MottoModelType;
}

const MottosStore = types
  .model('MottosStore', {
    // list
    loadingState: types.maybe(types.string),
    mottos: types.map(MottoModel),
    mottosFetched: types.maybe(types.boolean),
    // single item
    itemLoadingState: types.maybe(types.string),
    item: types.maybe(MottoModel),
    // other loading states
    voteLoadingState: types.maybe(types.string),
    selectLoadingState: types.maybe(types.string)
  })
  .actions((self) => {
    const getAllMottos = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';
        self.mottos.clear();

        const result = yield client.getAllMottos();

        self.mottosFetched = true;

        if (!Array.isArray(result) || !result.length) {
          self.loadingState = undefined;
          return;
        }

        self.mottos = createMapWithTransform(result, createMottoModel);
        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('MottosStore | getAllMottos', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const getMotto = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const motto = yield client.getMotto(id);

        self.item = createMottoModel(motto);
        self.itemLoadingState = undefined;

        return motto;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('MottosStore | getMotto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    const createMotto = flow(function* (
      motto: MottoModelType,
      addToList = true,
      selectMotto = false
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        motto = yield client.createMotto(motto, selectMotto);

        if (addToList) {
          // @ts-ignore
          self.mottos.put(createMottoModel(motto));
        }

        if (selectMotto && applicationStore.book) {
          applicationStore.setBook({
            ...applicationStore.book,
            motto
          });
        }

        self.item = createMottoModel(motto);
        self.itemLoadingState = undefined;

        return motto;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('MottosStore | createMotto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateMotto = flow(function* (
      motto: MottoModelType,
      selectMotto = false
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        motto = yield client.updateMotto(motto, selectMotto);

        if (self.mottos.has(motto.id.toString())) {
          // @ts-ignore
          self.mottos.put(createMottoModel(motto));
        }

        if (selectMotto && applicationStore.book) {
          applicationStore.setBook({
            ...applicationStore.book,
            motto
          });
        }

        if (self.item && self.item.id === motto.id) {
          self.item = createMottoModel(motto);
        }
        self.itemLoadingState = undefined;

        return motto;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('MottosStore | updateMotto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const removeMotto = flow(function* (
      mottoId: number,
      removeFromList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        yield client.removeMotto(mottoId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('MottosStore | removeMotto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.itemLoadingState = 'update_error';
          throw error;
        }
      }

      if (removeFromList) {
        self.mottos.delete(mottoId.toString());
      }

      if (self.item && self.item.id === mottoId) {
        self.item = undefined;
      }
      self.itemLoadingState = undefined;
    });

    const clearCurrentItem = () => {
      self.item = undefined;
      self.itemLoadingState = undefined;
    };

    const patchItem = (update: any) => {
      if (!self.item) {
        self.item = {
          id: -1,
          ...update
        };
        return;
      }

      self.item = {
        ...self.item,
        ...update
      };
    };

    const storeItem = flow(function* (selectMotto = false) {
      if (!self.item) {
        return;
      }

      if (self.item.id < 0) {
        return yield createMotto(self.item, true, selectMotto);
      }
      return yield updateMotto(self.item, selectMotto);
    });

    const vote = flow(function* (
      mottoIds: number[],
      refetchList = true,
      quiet = false
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.voteLoadingState = 'loading';

        yield client.voteForMottos(mottoIds);

        if (refetchList) {
          yield getAllMottos();
        }

        self.voteLoadingState = undefined;

        if (!quiet) {
          applicationStore.setFlashMessage(
            intl.formatMessage({ id: 'motto vote success' })
          );
        }

        return true;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('MottosStore | vote', error, error.body);
        }

        if (
          error.body.errors !== undefined &&
          error.body.errors.motto_ids.length > 0
        ) {
          applicationStore.setFlashMessage(
            error.body.errors.motto_ids[0],
            'error'
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.voteLoadingState = undefined;
          return false;
        }

        if (client.lockedErrorType(error) === 'Motto already selected') {
          self.voteLoadingState = undefined;

          if (!quiet) {
            applicationStore.setFlashMessage(
              intl.formatMessage({ id: 'motto selected error' }),
              'error'
            );
          }
          return true;
        }

        self.voteLoadingState = 'error';
        return false;
      }
    });

    const select = flow(function* (mottoId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.selectLoadingState = 'loading';

        const result = yield client.selectMotto(mottoId);

        if (result.book) {
          applicationStore.setBook(result.book);
        }

        self.selectLoadingState = undefined;
        applicationStore.setFlashMessage(
          intl.formatMessage({ id: 'motto select success' })
        );

        history.push(ROUTE_MOTTOS_ORG);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('MottosStore | select', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.selectLoadingState = undefined;
          return;
        }

        self.selectLoadingState = 'error';
      }
    });

    const unselect = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.selectLoadingState = 'loading';

        const result = yield client.unselectMotto();

        if (result.book) {
          applicationStore.setBook(result.book);
        }

        self.selectLoadingState = undefined;
        applicationStore.setFlashMessage(
          intl.formatMessage({ id: 'motto unselect success' })
        );
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('MottosStore | unselect', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.selectLoadingState = undefined;
          return;
        }

        self.selectLoadingState = 'error';
      }
    });

    return {
      getAllMottos,
      getMotto,
      createMotto,
      updateMotto,
      removeMotto,
      clearCurrentItem,
      patchItem,
      storeItem,
      vote,
      select,
      unselect
    };
  })
  .views((self) => {
    return {
      get allMottos(): MottoModelType[] {
        const mottos: MottoModelType[] = [];
        for (const motto of self.mottos.values()) {
          mottos.push(motto);
        }

        mottos.sort(sortByName);
        return mottos;
      },
      filteredMottos(name: string): MottoModelType[] {
        const nameLowercase = name.trim().toLowerCase();

        const mottos: MottoModelType[] = [];
        for (const motto of self.mottos.values()) {
          if (
            motto.name &&
            motto.name.toLowerCase().indexOf(nameLowercase) > -1
          ) {
            mottos.push(motto);
          }
        }

        mottos.sort(sortByName);
        return mottos;
      },

      // rank calculation removed, see https://github.com/9elements/nuggit-client/pull/89/files
      get rankedMottos(): MottoModelType[] {
        if (!self.mottos) {
          return [];
        }

        const mottos: MottoModelType[] = [];
        for (const motto of self.mottos.values()) {
          if (!motto) {
            continue;
          }
          mottos.push(motto);
        }

        if (!mottos.length) {
          return [];
        }

        mottos.sort((a, b) => {
          // sort by number of votes (descending)
          const aCount = a.votes_count || 0;
          const bCount = b.votes_count || 0;

          if (aCount < bCount) {
            return 1;
          }
          if (aCount > bCount) {
            return -1;
          }

          // same rank, sort by name (ascending)
          const aName = a.name || '';
          const bName = b.name || '';

          if (aName > bName) {
            return 1;
          }
          if (aName < bName) {
            return -1;
          }

          return 0;
        });

        return mottos;
      },
      get votesCount(): number {
        let count = 0;

        for (const motto of self.mottos.values()) {
          if (motto.votes_count) {
            count = count + motto.votes_count;
          }
        }

        return count;
      },
      get hasOwnVote(): boolean {
        for (const motto of self.mottos.values()) {
          if (motto.has_own_vote) {
            return true;
          }
        }

        return false;
      },
      get selectedMottosIds(): number[] {
        const ids: number[] = [];

        for (const motto of self.mottos.values()) {
          if (motto.has_own_vote) {
            ids.push(motto.id);
          }
        }

        return ids;
      },

      get isListLoading(): boolean {
        return self.loadingState === 'loading' ? true : false;
      },
      get isListError(): boolean {
        return self.loadingState === 'error' ? true : false;
      },

      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading' ? true : false;
      },
      get isItemError(): boolean {
        return self.itemLoadingState === 'error' ||
          self.itemLoadingState === 'update_error'
          ? true
          : false;
      },

      get isVoteLoading(): boolean {
        return self.voteLoadingState === 'loading' ? true : false;
      },
      get isVoteError(): boolean {
        return self.voteLoadingState === 'error' ? true : false;
      },
      get isSelectLoading(): boolean {
        return self.selectLoadingState === 'loading' ? true : false;
      },
      get isSelectError(): boolean {
        return self.selectLoadingState === 'error' ? true : false;
      }
    };
  });

export type MottosStoreType = Instance<typeof MottosStore>;
export default MottosStore;
