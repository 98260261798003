import React from 'react';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { GroupsListItemProps } from 'domain/GroupsList/GroupsListItem';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import {
  EveryCard,
  EveryCardPadding,
  EveryCardBody,
  EveryCardHeadline
} from 'blocks/EveryCard/EveryCard';
import TextElement from 'components/TextElement/TextElement';

export const ManageGroupsListItem = ({
  group,
  groupStudentsCount
}: GroupsListItemProps) => {
  return (
    <Link to={ROUTE_SETTINGS + '/groups/' + group.id}>
      <EveryCard>
        <EveryCardPadding>
          <EveryCardBody>
            <EveryCardHeadline>{group.name || group.id}</EveryCardHeadline>

            <TextElement>
              <FormattedMessage
                id="members count"
                values={{
                  count: groupStudentsCount
                }}
              />
            </TextElement>
          </EveryCardBody>
        </EveryCardPadding>
      </EveryCard>
    </Link>
  );
};

export default ManageGroupsListItem;
