import config from 'config';
import { parse } from 'query-string';
import { local } from 'utils/storage';

export const transformQueryString = (location: any) => {
  if (location && location.search) {
    location.query = parse(location.search);
  }
};

export const queryStringListener = (history: any) => {
  history.listen((location: any) => {
    transformQueryString(location);
  });
};

export const setRef = (location: any) => {
  if (location && location.query) {
    // Get ref from query string
    const ref = location.query?.ref?.trim();
    if (ref) {
      local.set(config.signupRefName, ref);
    }
  }
}
