import styled from 'styled-components';

export default styled.span<{ bigger?: boolean }>`
  display: flex;
  align-items: center;
  line-height: 1.2;
  ${({ bigger = true }) => bigger && `
    font-size: 1.125rem;
    font-weight: 500;
  `}
`;
