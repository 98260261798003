import React from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import config from 'config';
import { FormattedMessage } from 'react-intl';

export const openWhatsapp = (text?: string) => {
  const message = text ? text : encodeURIComponent(config.whatsappMessage);
  window.open(
    'https://api.whatsapp.com/send?phone=' +
      encodeURIComponent(config.whatsappSupport) +
      '&text=' +
      message
  );
};

export default ({ messageId, text }: { messageId?: string; text?: string }) => (
  <ButtonBlock background="WHATSAPP" onClick={() => openWhatsapp(text)}>
    <FormattedMessage id={messageId || 'Contact us on WhatsApp'} />
  </ButtonBlock>
);
