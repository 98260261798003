import { inject, observer } from 'mobx-react';
import React from 'react';

import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_DASHBOARD } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface SignupAuthCheckContainerProps {
  applicationStore?: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class SignupAuthCheckContainer extends React.Component<SignupAuthCheckContainerProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore } = this.props;
    if (applicationStore!.isAuthenticated) {
      this.props.history.push(ROUTE_DASHBOARD);
      return;
    }

    if (!applicationStore!.isAuthenticating) {
      // make request to check if user is logged in
      applicationStore!.checkAuthenticated(false, true);
    }
  }

  render() {
    if (this.props.applicationStore!.isAuthenticating) {
      return <LoadingOverlayComponent />;
    }

    return this.props.children;
  }
}

export default SignupAuthCheckContainer;
