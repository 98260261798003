import { get } from 'api/BaseApi';

export type PreorderStateType =
  | 'open'
  | 'submitted'
  | 'rejected';
export interface TextileOrganizerDashboard {
  preorders_amount: number;
  layout_key: string
}

export interface TextileStudentDashboard {
  first_name: string;
  is_checkout_author: boolean;
  name_verified: boolean;
  print_data_locked: boolean;
  preorder_state: PreorderStateType;
  requested_organizer_role: boolean
}
export const getTextileOrganizerDashboard = (
  textileOrderId: number
): Promise<TextileOrganizerDashboard> => {
  return get(
    '/textile_dashboard/organizer?textile_order_id=' + textileOrderId
  );
};

export const getTextileStudentDashboard = (
  textileOrderId: number
): Promise<TextileStudentDashboard> => {
  return get(
    '/textile_dashboard/student?textile_order_id=' + textileOrderId
  );
};
