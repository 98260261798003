import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBox from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import SponsorPagePreviewComponent from 'components/print/preview/SponsorPagePreviewComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { assert } from 'utils/assert';
import { ROUTE_SPONSORS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { PlaceholderSponsorPagesStoreType } from './PlaceholderSponsorPagesStore';
import { PlaceholderImage } from './placeholder_image';

interface Props {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  placeholderSponsorPagesStore: PlaceholderSponsorPagesStoreType;
}

interface State {
  confirmRemove: boolean;
}

@inject('applicationStore', 'chaptersStore', 'placeholderSponsorPagesStore')
@observer
class SponsorPlaceholderPage extends React.Component<
  Props & HistoryProps,
  State
> {
  state: State = {
    confirmRemove: false
  };

  componentDidMount() {
    if (
      this.props.applicationStore.onboardFeature(
        'sponsorpages',
        this.props.location?.pathname
      )
    ) {
      return;
    }

    this.loadInitialData();
  }

  loadInitialData() {
    this.getPlaceholderPage();
    this.getChapter();
  }

  getSponsorPageId() {
    const id = idFromMatch(this.props.match);
    assert(id, 'Sponsor page id not given');
    return id;
  }

  getChapterId() {
    const id = chapterIdFromMatch(this.props.match);
    assert(id, 'Chapter id not given');
    return id;
  }

  getPlaceholderPage() {
    const { match, placeholderSponsorPagesStore } = this.props;
    const id = idFromMatch(match);
    assert(id, 'Sponsor page id not given');

    placeholderSponsorPagesStore.getPlaceholderSponsorPage(id);
  }

  getChapter() {
    this.props.chaptersStore.getChapter(this.getChapterId());
  }

  startRemove() {
    this.setState({ confirmRemove: true });
  }

  async removeSponsorPage() {
    await this.props.placeholderSponsorPagesStore.removePlaceholderSponsorPage();
    this.props.history.push(`${ROUTE_SPONSORS}/${this.getChapterId()}`);
  }

  finishRemove() {
    this.setState({ confirmRemove: false });
  }

  // Render methods
  // --------------

  renderBackToChapter(children: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackLink to={`${ROUTE_SPONSORS}/${this.getChapterId()}`} />
        </TopActionBlock>

        <PageStackBlock>{children}</PageStackBlock>
      </>
    );
  }

  renderPlaceholderPage() {
    const { placeholderPageIsError } = this.props.placeholderSponsorPagesStore;
    return this.renderBackToChapter(
      <>
        <Headline.Large>
          <FormattedMessage id="Sponsor page select position heading" />
        </Headline.Large>

        <SponsorPagePreviewComponent
          layoutKey={'sponsor-grid-1'}
          positionMode={true}
          images={PlaceholderImage}
        />

        <ItemStackBlock gap="XS">
          <Headline.Medium>
            <FormattedMessage id="Sponsor placeholder header" />
          </Headline.Medium>
          <Paragraph>
            <FormattedMessage id="Sponsor placeholder text" />
          </Paragraph>
        </ItemStackBlock>

        <Divider />

        <DeleteButton
          onClick={() => this.startRemove()}
          messageId="Sponsor page remove"
        />
        {placeholderPageIsError && <GenericErrorComponent />}

        {this.state.confirmRemove && (
          <ConfirmBox
            header={<FormattedMessage id="confirm delete header" />}
            text={
              <FormattedMessage
                id="confirm delete item"
                values={{
                  item: <FormattedMessage id="This sponsor page" />
                }}
              />
            }
            confirmText={<FormattedMessage id="Remove" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => this.removeSponsorPage()}
            onAbort={() => this.finishRemove()}
            confirmColor="RED"
          />
        )}
      </>
    );
  }

  render(): React.ReactNode {
    const {
      item: chapter,
      isItemLoadError: chapterLoadingError
    } = this.props.chaptersStore;
    const {
      placeholderPage,
      placeholderPageIsError,
      placeholderPageIsLoading
    } = this.props.placeholderSponsorPagesStore;
    if (chapterLoadingError || placeholderPageIsError) {
      return this.renderBackToChapter(
        <GenericErrorComponent onRetryClick={() => this.loadInitialData()} />
      );
    }
    if (!chapter || !placeholderPage) {
      return this.renderBackToChapter(<LoadingIndicatorComponent />);
    }

    if (placeholderPageIsLoading) {
      return <LoadingIndicatorComponent />;
    }

    return this.renderPlaceholderPage();
  }
}

export default SponsorPlaceholderPage;
