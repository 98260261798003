import { TextileThemeLayout } from 'api/textile_deals/fetchTextileThemes';
import { TextileVariant } from 'api/textile_deals/fetchTextileVariants';
import textileThemesImagePath from './textile-theme-image-path';

type ImageType =
  | 'thumbnail'
  | 'detail_1'
  | 'detail_2'
  | 'detail_3'
  | 'preview_front'
  | 'preview_back';
export type ProductImage = {
  topLayer: string | undefined;
  midLayer: string | undefined;
  botLayer: string | undefined;
};

export const productImage = (
  variant: TextileVariant,
  type: ImageType,
  layout?: TextileThemeLayout
): ProductImage => {
  let botLayer: string | undefined;
  let midLayer: string | undefined;
  let topLayer: string | undefined;
  const textileName = variant.textile_internal_identifier;
  if (!textileName) {
    return {
      botLayer,
      midLayer,
      topLayer
    };
  }

  if (layout) {
    const id = layout.key;
    midLayer = textileThemesImagePath(
      id,
      midLayerByTextile(textileName, type, layout)
    );
  }

  switch (type) {
    case 'thumbnail':
      botLayer = variant.thumbnail_bottom;
      topLayer = variant.thumbnail_top;
      break;
    case 'detail_1':
      botLayer = variant.detail_1_bottom;
      topLayer = variant.detail_1_top;
      break;
    case 'detail_2':
      botLayer = variant.detail_2_bottom;
      topLayer = variant.detail_2_top;
      break;
    case 'detail_3':
      botLayer = variant.detail_3_bottom;
      topLayer = variant.detail_3_top;
      break;
    case 'preview_front':
      botLayer = variant.front_preview_bottom;
      topLayer = variant.front_preview_top;
      break;
    case 'preview_back':
      botLayer = variant.back_preview_bottom;
      topLayer = variant.back_preview_top;
      break;
    default:
      break;
  }

  return {
    botLayer,
    midLayer,
    topLayer
  };
};

const midLayerByTextile = (
  textileName: string,
  type: ImageType,
  layout: TextileThemeLayout
) => {
  let midLayer: string | undefined;

  switch (type) {
    case 'thumbnail':
      // @ts-ignore
      midLayer = layout[`thumbnail_${textileName}`];
      break;
    case 'detail_1':
      // @ts-ignore
      midLayer = layout[`detail_1_${textileName}`];

      break;
    case 'detail_2':
      // @ts-ignore
      midLayer = layout[`detail_2_${textileName}`];
      break;
    case 'detail_3':
      // @ts-ignore
      midLayer = layout[`detail_3_${textileName}`];
      break;
    case 'preview_front':
      // @ts-ignore
      midLayer = layout[`frontPreview_${textileName}`];
      break;
    default:
      break;
  }

  return midLayer;
};
