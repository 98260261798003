import React from 'react';

import ItemStackBlock from 'blocks/ItemStackBlock';
import TextElement from 'components/TextElement/TextElement';

export const CharCounterComponent = ({
  value,
  max,
  minDisplay
}: {
  value: any;
  max?: number;
  minDisplay?: number;
}) => {
  if (!max) {
    return null;
  }

  const len = (value || '').toString().length;
  if (minDisplay && len < minDisplay) {
    return null;
  }

  return (
    <ItemStackBlock gap="XXS" style={{ textAlign: 'right' }}>
      <TextElement size="M" >
        {len} / {max}
      </TextElement>
    </ItemStackBlock>
  );
};

export default CharCounterComponent;
