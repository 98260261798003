import {
  flow,
  getEnv,
  Instance,
  types
} from 'mobx-state-tree';
import AccountModel, { createAccountModel } from './AccountModel';

import { AdvancedStoreEnv } from './StoreEnv';

const AccountStore = types
  .model('AccountStore', {
    loadingState: types.maybe(types.string),
    account: types.maybe((AccountModel)),
  })
  .actions((self) => {
    const changeOwnPassword = flow(function* (
      oldPassword: string,
      newPassword: string
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';

        yield client.changePassword(oldPassword, newPassword);

        applicationStore.patchCurrentAccount({
          no_password: false
        });

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('AccountStore | changeOwnPassword', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          throw error;
        }

        if (client.isFormError(error)) {
          self.loadingState = undefined;
          throw error;
        }

        // not found is not an error when removing
        self.loadingState = 'update_error';
        throw error;
      }
    });

    const updatePhoneNumber = flow(function* (
      profileId: number,
      phoneNumber: string
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';

        const account = yield client.updateMobileNumber(profileId, phoneNumber);

        if (account) {
          if (
            account.mobile_number_to_confirm &&
            applicationStore.currentAccount &&
            applicationStore.currentAccount.id === account.id
          ) {
            applicationStore.patchCurrentAccount({
              mobile_number_to_confirm: account.mobile_number_to_confirm
            });
          }

          if (self.account && self.account.id === account.id) {
            self.account = createAccountModel(account);
          }
        }

        self.loadingState = undefined;

        return self.account;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('AccountStore | updatePhoneNumber', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.loadingState = undefined;
          throw error;
        }

        self.loadingState = 'update_error';
        throw error;
      }
    });

    return { changeOwnPassword, updatePhoneNumber };
  })

export type AccountStoreType = Instance<typeof AccountStore>;

export default AccountStore;
