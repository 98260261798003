import { patch, post, uri } from "api/BaseApi";

export interface Notification {
  id: number;
  notification?: string;
  author_name?: string;
}


export const createTextileNotification = (textileNotificaiton: any, textileOrderId: number): Promise<Notification> => {
  return post('/textile_notifications', {
    textile_notification: textileNotificaiton,
    textile_order_id: textileOrderId
  });
}

export const updateTextileNotification = (
  textileNotificaiton: any,
  notificationId: number,
  textileOrderId: number
): Promise<Notification> => {
  return patch('/textile_notifications/' + uri(notificationId), {
    textile_notification: textileNotificaiton,
    textile_order_id: textileOrderId
  });
}