import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import Headline from 'elements/Headline';
import { CHAPTER_TYPES_WITH_SETTINGS } from 'models/ChapterModel';
import SettingNotAvailableComponent from '../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from './SettingsDetailProps';
import Paragraph from 'components/Paragraph/Paragraph';

export default ({
  form,
  chapter,
  onCommitChapter
}: SimpleSettingsDetailProps) => {
  if (CHAPTER_TYPES_WITH_SETTINGS.indexOf(chapter.chapter_type) < 0) {
    return <SettingNotAvailableComponent />;
  }

  return (
    <>
      <Headline.Large>
        <FormattedMessage id="Chapter name" />
      </Headline.Large>

      <ItemStackBlock gap="L">
        <Paragraph>
          <FormattedMessage id="title settings info" />
        </Paragraph>

        <TextInputComponent
          name="title"
          label={<FormattedMessage id="Chapter name" />}
          autoFocus={true}
          {...form.bindInput('title')}
        />

        <ButtonBlock
          background="PRIMARY"
          onClick={() =>
            onCommitChapter({
              title: form.values.title || ''
            })
          }
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </ItemStackBlock>
    </>
  );
};
