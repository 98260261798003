import { Link } from 'react-router-dom';

import styled from 'styled-components';

const NakedLink = styled(Link)`
  font-weight: inherit;
  color: inherit;
`;

export default NakedLink;
