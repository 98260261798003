import React from 'react';

import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
// TODO remove fallback entirely
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import TextTemplateComponent from 'components/print/templates/TextTemplateComponent';
import { createPhotoModel } from 'models/PhotoModel';

import { PrintChapterProps } from './PrintChapterProps';

export const PrintTextChapterComponent = ({
  data,
  chapter,
  params
}: PrintChapterProps) => {
  const text = data?.text || {};
  const photo = createPhotoModel(text.photo);

  const layout = chapter.layout?.layoutDefinition;

  return (
    <FontPreloadComponent print={true} chapter={chapter}>
      <TextTemplateComponent
        print={true}
        config={layout}
        colors={chapter?.color_scheme?.templateColors || []}
        pageHeaderStyle={chapter?.font?.pageHeaderStyle}
        sectionHeaderStyle={chapter?.font?.sectionHeaderStyle}
        background={
          (chapter?.background?.id || '') +
            (chapter?.background?.extension || '') || undefined
        }
        startPosition={params.startPostion}
        startPage={params.startPage}
        title={chapter?.title}
        text={text.content || ''}
        picture={photo?.exportedPrintPicture}
      />
    </FontPreloadComponent>
  );
};

export default PrintTextChapterComponent;
