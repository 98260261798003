import {
  Instance,
  types
} from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

const SchoolYearModel = types
  .model('SchoolYearModel', {
    id: types.identifierNumber,
    year: types.maybe(types.number),
    school_id: types.maybe(types.string)
  })

export const createSchoolYearModel = (data: any): SchoolYearModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return SchoolYearModel.create({
    id: mandatoryId(data.id),
    year: data.year || undefined,
    school_id: data.school_id || undefined
  });
};

export type SchoolYearModelType = Instance<typeof SchoolYearModel>;
export default SchoolYearModel;
