export const MIN = 20;
export const MIN_LABEL = 'less than 30';

export const MAX = 160;
export const MAX_LABEL = 'more than 150';

export const STEPS: (number | [number, string])[] = [
  [MIN, MIN_LABEL],
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  110,
  120,
  130,
  140,
  150,
  [MAX, MAX_LABEL]
];
