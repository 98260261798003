import React, { Component } from 'react';

import AccountRoutes from 'AccountRoutes';
import BookRoutes from 'BookRoutes';
import TextileRoutes from 'TextileRoutes';
import HeaderComponent from 'components/HeaderComponent/HeaderComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { Route, Switch } from 'react-router-dom';
import HelpScreen from 'screens/HelpScreen/HelpScreen';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import OnboardScreen from 'screens/OnboardScreen/OnboardScreen';
import JoinBookScreen from 'screens/join/JoinBookScreen';
import { isLoggedIn } from 'utils/analytics';
import {
  ROUTE_ACCOUNT_DASHBOARD,
  ROUTE_AUTH,
  ROUTE_BOOK,
  ROUTE_HELP,
  ROUTE_TEXTILE_ORDER
} from 'utils/constants/routes';

interface AuthorizedRoutesProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class AuthorizedRoutes extends Component<AuthorizedRoutesProps> {
  componentDidMount() {
    const store = this.props.applicationStore;
    if (!store.isAuthenticated && !store.isAuthenticating) {
      // make request to check if user is logged in
      store.checkAuthenticated(true);
    }
  }

  renderLoader() {
    return (
      <>
        <HeaderComponent />
        <LoadingOverlayComponent />
      </>
    );
  }

  render() {
    if (this.props.applicationStore.isAuthenticating) {
      return this.renderLoader();
    }

    if (!this.props.applicationStore.isAuthenticated) {
      // this should not happen as ApplicationStore redirects to login
      // but just to be sure
      return this.renderLoader();
    }

    isLoggedIn();

    return (
      <>
        <Switch>
          <Route
            path={ROUTE_AUTH + '/onboard/:feature'}
            component={OnboardScreen}
          />

          <Route
            path={ROUTE_AUTH + '/join_book'}
            component={JoinBookScreen}
          />
          <Route path={ROUTE_HELP + '/:feature'} component={HelpScreen} />
          <Route path={ROUTE_ACCOUNT_DASHBOARD} component={AccountRoutes} />

          <Route path={ROUTE_TEXTILE_ORDER} component={TextileRoutes} />
          <Route path={ROUTE_BOOK} component={BookRoutes} />

          <Route
            path={ROUTE_AUTH}
            exact={true}
            component={AccountRoutes}
          />

          <NotFoundRoute />
        </Switch>
      </>
    );
  }
}

export default AuthorizedRoutes;
