import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import COLORS from 'utils/constants/colors';

interface DiscountCardProps {
  background?: keyof typeof COLORS;
  wide?: boolean;
}

export const DiscountCard = styled.div<DiscountCardProps>`
  display: flex;
  height: 12.0625rem;
  width: ${(props) => props.wide ? '18rem' : '12.0625rem'};
  overflow: hidden;
  border: 1px solid ${COLORS.GRAY800};
  border-radius: 0.625rem;
  background: ${(props) =>
    props.background ? COLORS[props.background] : COLORS.WHITE};
  line-height: 1;
  -webkit-appearence: none;
  position: relative;
  padding: 1rem;

  > ${TextElement} {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    width: calc(100% - 2rem);
  }
`;

export const DiscountCardInfo = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;

  > ${TextElement} {
    margin-top: 0.25rem;
  }
`;

export const DiscountCardFooter = (
  props: { children: ReactNode }
) => <Paragraph size="L" children={props.children}/>;

