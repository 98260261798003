import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';

import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_LOGIN_PAGE } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import Divider from 'components/Divider/Divider';
import Paragraph from 'components/Paragraph/Paragraph';

interface IndexScreenProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class IndexScreen extends React.Component<IndexScreenProps & HistoryProps> {
  componentDidMount() {
    if (process.env.NODE_ENV === 'development') {
      this.props.applicationStore.checkAuthenticated(false, true);
      return;
    }

    this.props.history.replace(ROUTE_LOGIN_PAGE);
  }

  render() {
    if (process.env.NODE_ENV === 'development') {
      if (this.props.applicationStore.isAuthenticating) {
        return <LoadingOverlayComponent />;
      }

      return (
        <>
          <SignupHeaderComponent />

          <Headline.Large>Welcome!</Headline.Large>

          <Paragraph>
            <Link to="/login">Login</Link>
          </Paragraph>
          <Divider />

          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/loading">Loading</Link>
              </li>
              <li>
                {/* <Link to="/styleguide">StyleGuide</Link> */}
              </li>
              <li>
                <Link to="/plainhtml">PlainHTML</Link>
              </li>
              <li>
                <Link to="/mottodashboard">Motto Dashboard</Link>
              </li>
            </ul>
          </nav>
        </>
      );
    }

    return null;
  }
}

export default IndexScreen;
