import React, { useContext, useEffect } from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import NakedLink from 'elements/NakedLink';
import { useTextileProfileQuery } from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const OverviewScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const textileProfile = useTextileProfileQuery(id, textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('management')
  }, [accessContext])

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/management')} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  }

  if (textileProfile.isError) {
    return renderPage(<GenericErrorComponent onRetryClick={() => textileProfile.refetch()} />)
  }

  if (textileProfile.isLoading || !textileProfile.data) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const renderOverview = () => {
    const profile = textileProfile.data;
    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="textile print settings" />}
          text={
            <>
              <IconComponent icon="USER" /> {profile.name}
            </>
          }
        />

        <ItemStackBlock gap="XS">
          <Paragraph>
            <FormattedMessage id="textile checkout print" />
          </Paragraph>
          <Divider color="BLACK" useStackBlockGap={true} />
          <HorizontalStackBlock justified={true} centered={true}>
            <TextElement>
              <FormattedMessage id="Name" />
            </TextElement>
            <TextElement>{profile.name}</TextElement>
          </HorizontalStackBlock>

          <Divider useStackBlockGap={true} />

          {profile.textile_group && (
            <>
              <HorizontalStackBlock justified={true} centered={true}>
                <TextElement>
                  <FormattedMessage id="Group" />
                </TextElement>
                <TextElement>{profile.textile_group.name}</TextElement>
              </HorizontalStackBlock>

              <Divider useStackBlockGap={true} />
            </>
          )}
          <HorizontalStackBlock justified={true} centered={true}>
            <TextElement>
              <FormattedMessage id="Print textile" />
            </TextElement>
            <TextElement>
              <FormattedMessage
                id={
                  profile.print
                    ? 'textile profile print'
                    : 'textile profile no print'
                }
              />
            </TextElement>
          </HorizontalStackBlock>

          <Divider useStackBlockGap={true} />

          <NakedLink
            to={textileOrderRoute(
              props.match,
              '/management/overwrite/' + profile.id
            )}
          >
            <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK">
              <FormattedMessage id="Overwrite" />
            </ButtonBlock>
          </NakedLink>
        </ItemStackBlock>
      </>
    );
  }

  return renderOverview();
}

export default OverviewScreen;
