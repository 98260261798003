import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfileModelType } from 'models/ProfileModel';
import { FactsheetsStoreType } from 'screens/factsheets/FactsheetsStore';
import { assert } from 'utils/assert';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';

import QuestionsAnswerListItem from '../FactsheetDetail/QuestionsAnswerListItem';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';

interface Props {
  factsheetsStore: FactsheetsStoreType;
  applicationStore: ApplicationStoreType;
}

@inject('factsheetsStore', 'applicationStore')
@observer
class AnswersScreen extends Component<Props & HistoryProps> {
  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const { factsheetsStore } = this.props;
    factsheetsStore.getFactsheet(this.getId());
  }

  getId() {
    const id = idFromMatch(this.props.match);
    assert(id);
    return id;
  }

  renderPage(content: React.ReactNode) {
    const { factsheetsStore } = this.props;
    const { chapter } = factsheetsStore;
    const isTeacherFactsheets = chapter?.chapter_type === 'teacher_factsheet';

    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_FACTSHEETS + '/detail/' + this.getId()} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        {isTeacherFactsheets ? (
          <HelpSubject subject="teachers" />
        ) : (
          <HelpSubject subject="factsheets" />
        )}
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadData()} />
    );
  }

  renderList(profile: ProfileModelType) {
    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Questions" />
        </Headline.Large>

        <ListStackBlock>
          {profile.printableQuestions.map((question) => {
            const to =
              ROUTE_FACTSHEETS +
              '/detail/' +
              profile.id +
              '/answer/' +
              (!question.answer ? 'add/' + question.id : question.answer.id);
            return (
              <Link to={to} key={question.id}>
                <QuestionsAnswerListItem question={question} />
              </Link>
            );
          })}
        </ListStackBlock>
      </>
    );
  }

  render() {
    const { factsheetsStore } = this.props;
    const { profileItem, profileItemLoadingState } = factsheetsStore;

    if (profileItemLoadingState === 'loading') {
      return this.renderLoading();
    }

    if (profileItemLoadingState === 'error' || !profileItem) {
      return this.renderError();
    }

    return this.renderList(profileItem);
  }
}

export default AnswersScreen;
