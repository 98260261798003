import styled, { AnyStyledComponent } from 'styled-components';

import AvatarInfo from './AvatarInfo';
import TextBlock from './TextBlock';

interface ExpertsBlockElements {
  AvatarInfo?: any;
  TextBlock?: any;
}

const ExpertsBlock: AnyStyledComponent & ExpertsBlockElements = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > * + * {
    margin-top: 2.5rem;
  }
`;

ExpertsBlock.AvatarInfo = AvatarInfo;
ExpertsBlock.TextBlock = TextBlock;
export default ExpertsBlock;
