import React from 'react';

import InputBlock from 'blocks/InputBlock';
import InputGroupBlock from 'blocks/InputGroupBlock';
import IconComponent, {
  IconComponentProps
} from 'components/IconComponent/IconComponent';
import FormLabel from 'elements/FormLabel';
import Input from 'elements/Input';
import InputAppend from 'elements/InputAppend';
import InputMessage from 'elements/InputMessage';
import COLORS from 'utils/constants/colors';

import InputInterface from './InputInterface';
import { FormattedMessage } from 'react-intl';
import ButtonBlock from 'blocks/ButtonBlock';
import { intl } from 'i18n';

// https://github.com/Microsoft/TypeScript/issues/26019#issuecomment-408547279
type InputProps = Parameters<typeof Input>[0];

export interface TextInputComponentProps extends InputInterface {
  icon?: IconComponentProps['icon'];
  iconFill?: keyof typeof COLORS;
  iconSize?: number;
  onIconClick?: (event: any) => void;
  buttonInside?: boolean;
  onButtonInsideClick?: () => void;
}

export const TextInputComponent = React.forwardRef<
  HTMLInputElement,
  TextInputComponentProps & InputProps
>((props, ref) => {
  const { name, label, icon, error, success, buttonInside } = props;

  const id = props.id ? props.id : '__formfield_' + name;
  const inputProps = { ...props };
  delete inputProps.onIconClick;
  delete inputProps.onButtonInsideClick;

  const input = <Input id={id} {...inputProps} ref={ref} placeholder={intl.formatMessage({ id: 'input placeholder' })}/>;

  return (
    <InputGroupBlock error={!!error} ok={!!success}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      {(error || success) && <InputMessage>{error || success}</InputMessage>}
      {icon ? (
        <InputBlock>
          {input}
          <InputAppend type="button" onClick={props.onIconClick}>
            <IconComponent
              icon={icon}
              fill={props.iconFill}
              size={props.iconSize}
            />
          </InputAppend>
        </InputBlock>
      ) : buttonInside ? (
        <InputBlock
          rounded={true}
          backgroundColor="GRAY950"
          borderColor="GRAY950"
        >
          {input}
          <InputAppend as="div">
            <ButtonBlock
              background="WHATSAPP"
              color="WHITE"
              slim={true}
              onClick={props.onButtonInsideClick}
            >
              <FormattedMessage id="share" />
            </ButtonBlock>
          </InputAppend>
        </InputBlock>
      ) : (
        input
      )}
    </InputGroupBlock>
  );
});

export default TextInputComponent;
