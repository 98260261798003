import React from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import Headline from 'elements/Headline';
import { ChapterModelType } from 'models/ChapterModel';
import BreakingText from 'elements/BreakingText';
import PaddingBlock from 'blocks/PaddingBlock';
import Paragraph from 'components/Paragraph/Paragraph';

export default ({
  chapter,
  organizerHeader
}: {
  chapter?: ChapterModelType;
  organizerHeader?: boolean;
}) => {
  if (!chapter?.description) {
    return null;
  }

  const desc = chapter.description.toString().trim();
  if (!desc) {
    return null;
  }

  return (
    <PaddingBlock background="PRIMARY_LIGHT">
      <ItemStackBlock gap="XS">
        <Headline.Small>
          <FormattedMessage
            id={
              organizerHeader
                ? 'Explanation for students'
                : 'chapter description'
            }
          />
        </Headline.Small>

        <Paragraph>
          <BreakingText>{desc}</BreakingText>
        </Paragraph>
      </ItemStackBlock>
    </PaddingBlock>
  );
};
