import React from 'react';

import { TextileOrderForCheckout } from 'api/textile_deals/fetchTextileOrders';
import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import MessageBlock from 'blocks/MessageBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import { DeliveryDateModelType } from 'models/DeliveryDateModel';
import { FormattedMessage } from 'react-intl';
import TimeplanDate from 'screens/timeplan/TimeplanDate';
import { TimeplanItemState } from 'screens/timeplan/TimeplanItemState';
import TimeplanList from 'screens/timeplan/TimeplanList';
import TimeplanPeriodProgress from 'screens/timeplan/TimeplanPeriodProgress';
import isTextileLocked from 'utils/isTextileLocked';

const TextileOrderTimeline = ({
  textileOrder,
  deliveryDate,
  paymentReceived,
  changeStateShowDeliveryDate,
  showButton
}: {
  textileOrder: TextileOrderForCheckout;
  deliveryDate?: DeliveryDateModelType;
  paymentReceived?: boolean;
  changeStateShowDeliveryDate: () => void;
  showButton: boolean;
}) => {
  if (!textileOrder || !deliveryDate) {
    return null;
  }

  const currentDate = new Date();
  const paymentDeadline = deliveryDate.payment_deadline;
  const paymentPossible = deliveryDate.payment_possible;
  // This boolean represents the state if the payment deadline is exceeded but the current date is still smaller then the next occurring friday of the payment deadline
  const waitingForPaymentAfterDeadline = currentDate > paymentDeadline;

  const deliveryDateState: TimeplanItemState =
    textileOrder.order_state === 'order_shipped' ||
    textileOrder.order_state === 'done'
      ? 'success'
      : 'open';
  const deliveryDateProgress =
    textileOrder.order_state === 'order_confirmed'
      ? 0.3
      : textileOrder.order_state === 'order_printing'
      ? 0.6
      : textileOrder.order_state === 'order_shipped' ||
        textileOrder.order_state === 'done'
      ? 1
      : 0;

  const paymentDeadlineState: TimeplanItemState =
    isTextileLocked(textileOrder.order_state, 'atLeastOrderConfirmed') ||
    paymentReceived
      ? 'success'
      : paymentPossible
      ? waitingForPaymentAfterDeadline
        ? 'waiting'
        : 'open'
      : 'error';
  // Before the payment deadline, fill the progress bar 50%
  const paymentDeadlineProgress = paymentDeadlineState === 'open' ? 0.5 : 1;

  return (
    <>
      <ItemStackBlock gap="XS">
        <Headline.Medium>
          <FormattedMessage id="Delivery date" />
        </Headline.Medium>

        {paymentDeadlineState === 'error' && (
          <>
            <MessageBlock error={true} static={true}>
              <Paragraph>
                <FormattedMessage id="Order payment deadline exceeded" />
              </Paragraph>
            </MessageBlock>

            {showButton && (
              <ButtonBlock
                background="PRIMARY"
                onClick={() => changeStateShowDeliveryDate()}
              >
                <FormattedMessage id="Order new delivery date" />
              </ButtonBlock>
            )}
          </>
        )}

        {paymentDeadlineState === 'waiting' && (
          <MessageBlock warning={true} static={true}>
            <Paragraph>
              <FormattedMessage id="Order payment deadline exceeded waiting" />
            </Paragraph>
          </MessageBlock>
        )}

        {paymentDeadlineState !== 'waiting' &&
          paymentDeadlineState !== 'error' && (
            <Paragraph>
              <FormattedMessage id="Order delivery date info textile" />
            </Paragraph>
          )}
      </ItemStackBlock>

      <TimeplanList>
        <TimeplanPeriodProgress
          percentElapsed={paymentDeadlineProgress}
          state={paymentDeadlineState}
        />
        <TimeplanDate state={paymentDeadlineState} date={paymentDeadline}>
          <FormattedMessage id="Payment deadline and form" />
        </TimeplanDate>

        <TimeplanPeriodProgress
          percentElapsed={deliveryDateProgress}
          state={paymentDeadlineState}
        />

        <TimeplanDate
          state={deliveryDateState}
          date={deliveryDate.delivery_date}
        >
          <FormattedMessage id="Delivery date" />
        </TimeplanDate>
      </TimeplanList>
    </>
  );
};

export default TextileOrderTimeline;
