import React from 'react';

import style from 'styled-components';
import sanitizeHtml from 'utils/chunker/sanitize-html';

interface TeacherContainerProps {
  width: number;
  font?: string;
  color?: string;
  height?: number;
  marginTop?: number;
  fontSize?: number;
}

const TeachersContainer = style.div<TeacherContainerProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-family: ${(props) => props.font};
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => props.color};
  margin-top: ${(props) => props.marginTop}px;
`;

const TeacherComponent = style.p`
  padding: 0 7px;
`;

const RowComponent = style.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 7px;
  white-space: nowrap;
`

export default ({
  teachers,
  width,
  color,
  font,
  height,
  fontSize,
  marginTop
}: {
  teachers: string[][];
  width: number;
  color: string;
  font?: string;
  height?: number;
  fontSize?: number;
  marginTop?: number;
}) => {
  return (
    <TeachersContainer
      font={font}
      color={color}
      height={height}
      width={width}
      marginTop={marginTop}
      fontSize={fontSize}
    >
      {teachers.map((nameArray, idx) => {
        return (
          <RowComponent key={'column#' + idx}>
            {nameArray.map((name, idx) => {
              return (
                <TeacherComponent key={'columnItem#' + idx + name} dangerouslySetInnerHTML={{ __html: sanitizeHtml(idx !== 0 ? ' • ' + name : name ) || '' }}/>
              );
            })}
          </RowComponent>
        );
      })}
    </TeachersContainer>
  );
};
