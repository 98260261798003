import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LogoutButton from 'components/LogoutButton/LogoutButton';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import {
  ROUTE_ACCOUNT_CHANGE_PASSWORD,
  ROUTE_ACCOUNT_DASHBOARD,
  ROUTE_ACCOUNT_MOBILE_NUMBER,
  ROUTE_AUTH
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';


@inject('applicationStore')
@observer
class MyAccountScreen extends React.Component<
  HistoryProps & WrappedComponentProps
> {
  private renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_ACCOUNT_DASHBOARD} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="My account" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderAccount() {
    return this.renderPage(
      <>
        <ListStackBlock>
          <ButtonListBlock>
            <ButtonListBlockItem>
              <NakedLink to={ROUTE_ACCOUNT_MOBILE_NUMBER}>
                <ButtonListBlockBody>
                  <FormattedMessage id="Edit mobile number" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            <ButtonListBlockItem>
              <NakedLink to={ROUTE_ACCOUNT_CHANGE_PASSWORD}>
                <ButtonListBlockBody>
                  <FormattedMessage id="Change password" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            <ButtonListBlockItem>
              <NakedLink to={ROUTE_AUTH + '/account'}>
                <ButtonListBlockBody>
                  <FormattedMessage id="Account dashboard" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>
          </ButtonListBlock>
        </ListStackBlock>

        <ItemStackBlock gap="XS" marginTop="XL">
          <LogoutButton />
        </ItemStackBlock>
      </>
    );
  }

  render() {
    return this.renderAccount();
  }
}

export default injectIntl(MyAccountScreen);
