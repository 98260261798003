import React, { ReactNode } from 'react';
import ItemStackBlock from 'blocks/ItemStackBlock';

const FormStackBlock = ({ children }: { children: ReactNode }) => (
  <ItemStackBlock gap="S">
    {children}
  </ItemStackBlock>
);

export default FormStackBlock;
