export enum ROLES {
  APPLICANT = 'applicant',
  MANUALLY_CREATED_STUDENT = 'manually_created_student',
  MANUALLY_CREATED_ORGANIZER = 'manually_created_organizer',
  STUDENT = 'student',
  ORGANIZER = 'organizer',
  MANAGER = 'manager',
  MANUALLY_CREATED_TEACHER = 'manually_created_teacher',
}

export enum UPDATE_ROLES {
  STUDENT = 'student',
  ORGANIZER = 'organizer',
  MANAGER = 'manager',
  MANUALLY_CREATED_TEACHER = 'manually_created_teacher',
}

export default ROLES;
