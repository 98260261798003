import { types, Instance } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';
import PhotoModel, { createPhotoModel, PhotoModelType } from './PhotoModel';

const AlbumPagePhotoModel = types.model('AlbumPagePhotoModel', {
  id: types.identifierNumber,
  position: types.number,
  photo: PhotoModel
});

export const createAlbumPagePhoto = (data: {
  id?: number;
  position: number;
  photo: PhotoModelType;
}) => {
  const photo = createPhotoModel(data.photo);
  if (!photo) {
    throw new Error('Photo cannot be undefined');
  }

  return AlbumPagePhotoModel.create({
    id: mandatoryId(data.id),
    position: data.position,
    photo
  });
};

export type AlbumPagePhotoModelType = Instance<typeof AlbumPagePhotoModel>;
export default AlbumPagePhotoModel;
