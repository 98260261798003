import React, { ReactNode } from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import LinkBlock from 'blocks/LinkBlock';

const NextButton = (
  props: {
    condition: boolean;
    children: ReactNode;
  } & (
    | {
        onClick: () => void;
      }
    | {
        to: string;
      }
  )
) => {
  const { condition, children } = props;
  return condition ? (
    <>
      {'to' in props && (
        <LinkBlock to={props.to} background="PRIMARY">
          {children}
        </LinkBlock>
      )}

      {'onClick' in props && (
        <ButtonBlock onClick={props.onClick} background="PRIMARY">
          {children}
        </ButtonBlock>
      )}
    </>
  ) : (
    <ButtonBlock background="GRAY950" color="GRAY800" disabled={true}>
      {children}
    </ButtonBlock>
  );
};

export default NextButton;
