import React, { useContext, useEffect } from 'react';

import { ButtonListBlock, ButtonListBlockBody, ButtonListBlockItem } from 'blocks/ButtonListBlock/ButtonListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import NakedLink from 'elements/NakedLink';
import UppercaseHeading from 'elements/UppercaseHeading';
import { useTextileContentNotPrintedQuery } from 'queries/textile_deals/useTextileContentNotPrintedQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

interface TextileContentNotPrintedScreenProps {
  link: boolean;
}

const TextileContentNotPrintedScreen: React.FC<
  HistoryProps & TextileContentNotPrintedScreenProps
> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const textileContentNotPrinted = useTextileContentNotPrintedQuery(
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('checkout');
  }, [accessContext]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/checkout')} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileContentNotPrinted.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => textileContentNotPrinted.refetch()}
      />
    );
  }

  if (textileContentNotPrinted.isLoading || !textileContentNotPrinted.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderAllOk = () => {
    return renderPage(
      <>
        <PageHeader
          headline={
            <FormattedMessage id="textile checkout sources of errors" />
          }
          text=""
        />

        <InputMessage ok={true}>
          <FormattedMessage id="no textile checkout sources of errors info" />
        </InputMessage>
      </>
    );
  };

  const renderContentNotPrinted = () => {
    const { link } = props;


    const {
      profiles_not_printed,
      profiles_without_groups,
      virtual_students_without_groups,
      virtual_teachers_without_groups
    } = textileContentNotPrinted.data;

    const hasProfilesWithoutGroup = profiles_without_groups.length > 0;
    const hasProfilesNotPrinted = profiles_not_printed.length > 0;

    const hasVirtualStudentsWithoutGroups =
      virtual_students_without_groups.length > 0;
    const hasVirtualTeachersWithoutGroups =
      virtual_teachers_without_groups.length > 0;

    if (
      !hasProfilesWithoutGroup &&
      !hasProfilesNotPrinted &&
      !hasVirtualStudentsWithoutGroups &&
      !hasVirtualTeachersWithoutGroups
    ) {
      return renderAllOk()
    }

    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/checkout')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={
              <FormattedMessage id="textile checkout sources of errors" />
            }
            text={
              <FormattedMessage id="textile checkout sources of errors info" />
            }
          />
          <>
            <Divider />
          </>

          {hasProfilesWithoutGroup && (
            <section>
              <Headline.Medium>
                <FormattedMessage id="Students without groups" />
              </Headline.Medium>
              <ItemStackBlock gap="XXS" marginTop="M">
                <Paragraph>
                  <FormattedMessage id="Students without textile group check" />
                </Paragraph>
                <ListStackBlock>
                  <UppercaseHeading>
                    {profiles_without_groups.length}{' '}
                    <FormattedMessage id="Students" />
                  </UppercaseHeading>
                  <ButtonListBlock>
                    {profiles_without_groups.map((profile) => (
                      <ButtonListBlockItem key={profile.id}>
                        <NakedLink
                          to={textileOrderRoute(
                            props.match,
                            '/management/group_selection/' + profile.id
                          )}
                        >
                          <ButtonListBlockBody arrow={link}>
                            <TextElement color="TEXT_DARK">
                              {profile.first_name} {profile.last_name}
                            </TextElement>
                          </ButtonListBlockBody>
                        </NakedLink>
                      </ButtonListBlockItem>
                    ))}
                  </ButtonListBlock>
                </ListStackBlock>
              </ItemStackBlock>
              <Divider />
            </section>
          )}

          {hasProfilesNotPrinted && (
            <section>
              <Headline.Medium>
                <FormattedMessage id="Students not printed" />
              </Headline.Medium>
              <ItemStackBlock gap="XXS" marginTop="M">
                <Paragraph>
                  <FormattedMessage id="Students textile not printed check" />
                </Paragraph>
              </ItemStackBlock>
              <ListStackBlock>
                <UppercaseHeading>
                  {profiles_not_printed.length}{' '}
                  <FormattedMessage id="Students" />
                </UppercaseHeading>
                <ButtonListBlock>
                  {profiles_not_printed.map((profile) => (
                    <ButtonListBlockItem key={profile.id}>
                      <NakedLink
                        to={textileOrderRoute(
                          props.match,
                          '/management/print_profile/' + profile.id
                        )}
                      >
                        <ButtonListBlockBody arrow={link}>
                          <TextElement color="TEXT_DARK">
                            {profile.first_name} {profile.last_name}
                          </TextElement>
                        </ButtonListBlockBody>
                      </NakedLink>
                    </ButtonListBlockItem>
                  ))}
                </ButtonListBlock>
              </ListStackBlock>
              <Divider />
            </section>
          )}

          {hasVirtualStudentsWithoutGroups && (
            <section>
              <Headline.Medium>
                <FormattedMessage id="Virtual students without groups" />
              </Headline.Medium>
              <ItemStackBlock gap="XXS" marginTop="M">
                <Paragraph>
                  <FormattedMessage id="Students without textile group check" />
                </Paragraph>
                <ListStackBlock>
                  <UppercaseHeading>
                    {virtual_students_without_groups.length}{' '}
                    <FormattedMessage id="Students" />
                  </UppercaseHeading>
                  <ButtonListBlock>
                    {virtual_students_without_groups.map((profile) => (
                      <ButtonListBlockItem key={profile.id}>
                        <NakedLink
                          to={textileOrderRoute(
                            props.match,
                            '/management/virtual/group_selection/' + profile.id
                          )}
                        >
                          <ButtonListBlockBody arrow={link}>
                            <TextElement color="TEXT_DARK">
                              {profile.first_name} {profile.last_name}
                            </TextElement>
                          </ButtonListBlockBody>
                        </NakedLink>
                      </ButtonListBlockItem>
                    ))}
                  </ButtonListBlock>
                </ListStackBlock>
              </ItemStackBlock>
              <Divider />
            </section>
          )}

          {hasVirtualTeachersWithoutGroups && (
            <section>
              <Headline.Medium>
                <FormattedMessage id="Virtual teachers without groups" />
              </Headline.Medium>
              <ItemStackBlock gap="XXS" marginTop="M">
                <Paragraph>
                  <FormattedMessage id="Students without textile group check" />
                </Paragraph>
                <ListStackBlock>
                  <UppercaseHeading>
                    {virtual_teachers_without_groups.length}{' '}
                    <FormattedMessage id="Students" />
                  </UppercaseHeading>
                  <ButtonListBlock>
                    {virtual_teachers_without_groups.map((profile) => (
                      <ButtonListBlockItem key={profile.id}>
                        <NakedLink
                          to={textileOrderRoute(
                            props.match,
                            '/management/virtual/group_selection/' + profile.id
                          )}
                        >
                          <ButtonListBlockBody arrow={link}>
                            <TextElement color="TEXT_DARK">
                              {profile.first_name} {profile.last_name}
                            </TextElement>
                          </ButtonListBlockBody>
                        </NakedLink>
                      </ButtonListBlockItem>
                    ))}
                  </ButtonListBlock>
                </ListStackBlock>
              </ItemStackBlock>
              <Divider />
            </section>
          )}
        </PageStackBlock>
      </>
    );
  };

  return renderContentNotPrinted();
};

export default TextileContentNotPrintedScreen;
