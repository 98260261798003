import React from 'react';

import ImageBlock from 'blocks/ImageBlock';
// import IconComponent from 'components/IconComponent';
// import Bodytext from 'elements/Bodytext';

export const AdvertImageComponent = ({ advert }: { advert?: any }) => {
  if (!advert) {
    return null;
  }

  let preview;

  // if advert is file, display like this
  // preview = (
  //   <ImageBlock.ImageBodyElement>
  //     <IconComponent icon="UPLOAD" fill="PRIMARY" size={5} />
  //     {filename && <Bodytext>{filename}</Bodytext>}
  //   </ImageBlock.ImageBodyElement>
  // );

  // if advert is image or has preview, display like this
  if (advert.url) {
    preview = <img src={advert.url} alt="" />;
  } else if (advert.preview) {
    preview = <img src={advert.preview} alt="" />;
  }

  if (!preview) {
    return null;
  }

  return (
    <ImageBlock shrinkedWidth={true} background="GRAY950">
      {preview}
    </ImageBlock>
  );
};

export default AdvertImageComponent;
