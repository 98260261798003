import { ProductImage } from "./product-image";

export const previewImage = (
  themeImage: string
): ProductImage => {
  let botLayer: string | undefined;
  let midLayer: string | undefined;
  let topLayer: string | undefined;

  botLayer = '/images/textiles/placeholder-front_preview-bottom.png';
  midLayer = themeImage
  topLayer = '/images/textiles/placeholder-front_preview-top.png';

  return {
    botLayer,
    midLayer,
    topLayer
  };
};

