import styled, { css } from 'styled-components';
import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

export interface ButtonBaseProps {
  borderColor?: keyof typeof COLORS;
  background?: keyof typeof COLORS;
  color?: keyof typeof COLORS;
  slim?: boolean;
  inline?: boolean;
  round?: boolean;
  iconRight?: boolean;
}

export interface ButtonProps extends ButtonBaseProps {
  link?: boolean;
  onlyIcon?: boolean;
  nowrap?: boolean;
}

export const ButtonBaseStyles = css<ButtonBaseProps>`
  position: relative;
  display: block;
  box-shadow: none;
  border: 1px solid;
  border-color: ${(props) =>
    props.borderColor ? COLORS[props.borderColor] : COLORS.BLACK_003};
  width: 100%;
  padding: ${(props) => (props.slim ? '0.7rem 0.9rem' : '1rem 1rem')};
  background: ${(props) =>
    props.background ? COLORS[props.background] : COLORS.SECONDARY_DARK};
  background-origin: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  font-family: ${FONTS.DEFAULT};
  font-size: ${(props) => (props.slim ? '1rem' : '1.125rem')};
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.03125em;
  color: ${(props) => (props.color ? COLORS[props.color] : COLORS.WHITE)};
  text-align: center;

  &:hover {
    color: ${(props) => (props.color ? COLORS[props.color] : COLORS.WHITE)};
  }

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.2);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) =>
    props.inline &&
    `
    display: inline-block;
    max-width: max-content;
  `}

  ${({ round, slim, iconRight }) => round ? `
    padding: ${slim ? '0.8125rem 1em' : '1rem 1.5em'};
    border-radius: 3em;

    svg {
      margin-right: ${iconRight ? '-0.25em' : '0.5em'};
      margin-left: ${iconRight ? '0.5em' : '-0.25em'};
      vertical-align: -0.2em;
    }
  ` : `
    border-radius: 0.625em;

    svg {
      margin-right: 0.25em;
      vertical-align: -0.1em;
    }
  `};
`;

const ButtonBlock = styled.button<ButtonProps>`
  ${ButtonBaseStyles}
  ${(props) =>
    props.link &&
    `
    display: inline-block;
    padding: 0;
    background: transparent;
    width: auto;
    align-self: flex-start;
    border-color: transparent;

    & + & {
      padding-left: 1em;
    }
  `};
  ${(props) =>
    props.onlyIcon &&
    `
    width: auto;
    padding: 0.875em 0.625em 0.75em 0.875em;
  `};
  ${(props) =>
    props.nowrap &&
    `
    white-space: nowrap;
  `}
`;

export default ButtonBlock;
