import styled, { AnyStyledComponent } from 'styled-components';

import InnerElement from './InnerElement';

import COLORS from 'utils/constants/colors';

interface OverlayBlockElements {
  InnerElement?: any;
}

const OverlayBlock: AnyStyledComponent & OverlayBlockElements = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background: ${COLORS.GRAY800_060};
`;

OverlayBlock.InnerElement = InnerElement;
export default OverlayBlock;
