import React, { ChangeEvent } from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import ImageBlock from 'blocks/ImageBlock';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import VCenter from 'elements/VCenter';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

import InputInterface from './InputInterface';

export const ACCEPT_IMAGES = 'image/jpeg,image/png';
export const ACCEPT_IMAGES_AND_PDFS = ACCEPT_IMAGES + ',.pdf';

export interface FileUploadInterface extends InputInterface {
  value?: any;
  onChange?: (e: any) => void;
  id?: string;
  accept?: string;
  autoUpload?: boolean;
  onUploadPhoto: (file: File) => void;
  resetForm?: () => void;
  url?: string;
  isAddMode?: boolean;
  renderButton?: boolean;
  buttonColor?: keyof typeof COLORS;
}

// https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications#Using_a_label_element_to_trigger_a_hidden_file_input_element
export const VisuallyHiddenInput = styled.input`
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
`;

// TODO is this ok? can we keep this when photo editor is merged? if yes, move to separate file.
export const AddButton = styled.button`
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 3em;
  margin-bottom: 0;
  border: 2px dashed orange;
  border-radius: 10px;
  width: 100%;
  height: 346px;
  font-size: 0.875em;
  line-height: 1.2;
  font-weight: 300;
  text-align: center;
  background-color: white;
`;

class FileUploadInput extends React.Component<FileUploadInterface> {
  _inputRef?: any = null;

  componentDidMount() {
    const { value } = this.props;
    if (value && value.file instanceof File) {
      // if we received a File object on mount, we might need to recreate the preview
      if (value.url) {
        // revoke old preview first
        URL.revokeObjectURL(value.url);
      }

      if (value.file.type.startsWith('image/')) {
        // create new preview and persist it to value
        const newValue = {
          ...value
        };

        newValue.url = URL.createObjectURL(value.file);
        this.change(newValue);
      }
    }
  }

  componentWillUnmount() {
    this.revokePreview();
  }

  componentDidUpdate(prevProps: FileUploadInterface) {
    // If value is changed from outside, e.g. when form is reset, we might need
    // to free old preview from memory.
    const oldValue = prevProps.value;
    const newValue = this.props.value;

    if (oldValue && oldValue.url) {
      // there is an old preview, so check against new one
      if (!newValue || !newValue.url || newValue.url !== oldValue.url) {
        this.revokePreview(prevProps);
      }
    }
  }

  change(newValue?: any) {
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          value: newValue
        }
      });
    }
  }

  revokePreview(props: any = null) {
    if (!props) {
      props = this.props;
    }

    const { value } = props;
    if (value && value.url) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('FileUpload | revokePreview', value.url);
      }

      URL.revokeObjectURL(value.url);
    }
  }

  fileChanged(e: ChangeEvent<HTMLInputElement>) {
    if (!e || !e.target || !e.target.files || !e.target.files.length) {
      return;
    }
    const { onUploadPhoto, resetForm, autoUpload } = this.props;

    // revoke old preview if any before creating a new one
    this.revokePreview();

    const file = e.target.files[0];

    if (autoUpload) {
      if (resetForm) {
        resetForm();
      }

      onUploadPhoto(file);
    } else {
      let url = null;
      if (file.type.startsWith('image/')) {
        url = URL.createObjectURL(file);
      }

      this.change({
        file,
        url
      });
    }
  }

  selectFile() {
    if (this._inputRef) {
      this._inputRef.click();
    }
  }

  renderImage = (src: string) => {
    const { url, name, isAddMode } = this.props;

    // Render Image without cropper
    if (url || (name === 'advert' && src)) {
      const imgSrc = src ? src : url;
      return this.renderBox(<img src={imgSrc} alt="" />);
    }

    let image = src;
    if (!image && url) {
      image = url;
    }

    if (!image && !isAddMode) {
      return;
    }
  };

  renderBox(content: any) {
    return (
      <ImageBlock
        height={20.875}
        background="GRAY950"
        fullWidth={true}
        onClick={() => this.selectFile()}
      >
        {content}
      </ImageBlock>
    );
  }

  renderAdd() {
    return (
      <AddButton onClick={() => this.selectFile()}>
        <IconComponent icon="UPLOAD" size={3} fill="PRIMARY" />
      </AddButton>
    );
  }

  getTypeAndContent = () => {
    const { value, url } = this.props;
    let previewType;
    let previewContent;

    if (value.url || url) {
      // new image selected, display preview
      previewType = 'image';
      previewContent = value.url;
    } else if (value.file) {
      if (value.file.name) {
        // new file selected
        previewType = 'name';
        previewContent = value.file.name;
      } else {
        // new file selected, but no name available
        previewType = 'icon';
      }
    } else if (value.old) {
      if (value.old.url || value.old.preview) {
        // old image present, display preview
        previewType = 'image';
        previewContent = value.old.preview || value.old.url;
      } else if (value.old.name) {
        // old filename present
        previewType = 'name';
        previewContent = value.old.name;
      } else {
        // old file present, but no name available
        previewType = 'icon';
      }
    }

    return { previewType, previewContent };
  };

  render() {
    const { value, name, id, accept, url, renderButton, buttonColor } = this.props;

    let preview;

    if (value || url) {
      const { previewType, previewContent } = this.getTypeAndContent();

      switch (previewType) {
        case 'image':
          preview = this.renderImage(previewContent);
          break;

        case 'name':
          preview = this.renderBox(
            <VCenter>
              <IconComponent icon="UPLOAD" fill="PRIMARY" size={5} />
              {/* TODO custom style  */}
              <Paragraph>
                {previewContent}
              </Paragraph>
            </VCenter>
          );
          break;

        case 'icon':
        default:
          preview = this.renderBox(
            <IconComponent icon="UPLOAD" fill="PRIMARY" size={5} />
          );
          break;
      }
    }

    if (!preview) {
      if (renderButton) {
        preview = (
          <ButtonBlock background={buttonColor ? buttonColor : "PRIMARY"} onClick={() => this.selectFile()}>
            <FormattedMessage id="Upload form" />
          </ButtonBlock>
        );
      }
      if (this.props.isAddMode !== undefined) {
        preview = this.props.isAddMode
          ? this.renderAdd()
          : this.renderBox(
              <IconComponent icon="UPLOAD" fill="GRAY800" size={5} />
            );
      }
    }

    return (
      <>
        {preview}

        <VisuallyHiddenInput
          type="file"
          name={name}
          id={id}
          accept={!accept ? ACCEPT_IMAGES : accept}
          onChange={(e) => this.fileChanged(e)}
          ref={(r) => (this._inputRef = r)}
        />
      </>
    );
  }
}

export default FileUploadInput;
