import { BaseTextileProfile } from 'api/textile_deals/fetchTextileProfiles';
import ItemStackBlock from 'blocks/ItemStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import { useTextileOrganizerQuery } from 'queries/textile_deals/useTextileProfileQueries';
import React from 'react';


interface TextileOrganizersListProps {
  textileOrderId?: number;
}

const TextileOrganizersList: React.FC<TextileOrganizersListProps> = (props) => {
  const textileOrganizers = useTextileOrganizerQuery(props.textileOrderId);

  if (textileOrganizers.isError) {
    return (
      <GenericErrorComponent onRetryClick={() => textileOrganizers.refetch()} />
    );
  }

  if (textileOrganizers.isLoading || !textileOrganizers.data) {
    return <LoadingIndicatorComponent />;
  }

  const renderList = () => {
    return (
      <ItemStackBlock gap="M">
        {textileOrganizers.data.map((profile: BaseTextileProfile) => (
          <Paragraph>{profile.name}</Paragraph>
        ))}
      </ItemStackBlock>
    );
  };

  return renderList();
};

export default TextileOrganizersList;
