import { get, patch, uri } from 'api/BaseApi';
import { TextileThemeLayout } from './fetchTextileThemes';

export type LayoutOrigin = 'unkown' | 'api' | 'library';

export type BackDesign = 'neutral' | 'generated' | 'photo';
export type NamesOrder = 'first_name' | 'last_name';
export interface BaseTextileDesignSetting {
  id: number;
  back_headline?: string;
  back_subheadline?: string;
  back_footer?: string;
  layout_key?: string;
  names_order: NamesOrder;
  textile_back: BackDesign;
  show_teachers: boolean;
  show_frame: boolean;
  show_groups: boolean;
  theme?: TextileThemeLayout;
  textile_back_creative_photo: TextilePhoto
  font?: Font,
  background?: TextileBackground
}

export interface TextilePhoto {
  id: string;
  photo: RemoteFile;
  fit_to_frame: boolean;
}

export interface RemoteFile {
  preview: string;
  full: string;
  cropped: string;
}

export interface TextileBackground {
  key: number;
  name?: string;
  tags: string[];
  origin?: LayoutOrigin;
  print?: string;
  preview?: string;
}

export interface Font {
  key: number;
  name?: string;
  tags: string[];
  fonts: FontSpec[];
  pageHeaderStyle?: string;
  sectionHeaderStyle?: string;
  previewStyle?: string;
  preview?: string;
  sizeFactor?: number;
}

export interface FontSpec {
  name: string;
  weights: number[];
}

export const getTextileOrderDesignByTextileOrderId = (
  id: number
): Promise<BaseTextileDesignSetting> => {
  return get(
    '/textile_design_setting?textile_order_id=' + uri(id)
  );
};

export const updateTextileDesignSetting = (
  textileOrderId: number,
  data: BaseTextileDesignSetting
): Promise<BaseTextileDesignSetting> => {
  return patch('/textile_design_setting/', {
    settings: data,
    textile_order_id: textileOrderId
  });
}