import React from 'react';

import {
  Route,
  Switch
} from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_TEXTILE_PROFILES } from 'utils/constants/routes';

import ProfileOverviewScreen from './screens/ProfileOverview';
import ProfileEditScreen from './screens/TextileProfileEdit';

export default () => (
  <Switch>

    <Route path={ROUTE_TEXTILE_PROFILES + "/overview"} component={ProfileOverviewScreen} />
    <Route path={ROUTE_TEXTILE_PROFILES} exact={true} component={ProfileEditScreen} />

    <NotFoundRoute />
  </Switch>
);
