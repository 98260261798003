import { useMutation, useQueryClient } from '@tanstack/react-query';
import { OverviewPreorder } from 'api/textile_deals/fetchOverviewPreorder';
import {
  createTextilePreorder,
  removeTextilePreorder
} from 'api/textile_deals/fetchPreorders';

export const useRemoveTexitlePreorderQuery = (textileOrderId?: number, id?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number, profileId?: number }) =>
      removeTextilePreorder(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['order_overview', patch.textileOrderId]);
      await queryClient.cancelQueries([
        'virtual_order_overview',
        patch.textileOrderId
      ]);
      await queryClient.cancelQueries([
        'profile_preorder_overview',
        patch.textileOrderId
      ]);
      const previousStateOrderOverview:
        | OverviewPreorder
        | undefined = queryClient.getQueryData([
        'order_overview',
        patch.textileOrderId
      ]);

      const previousStateVirtualOrderOverview:
        | OverviewPreorder
        | undefined = queryClient.getQueryData([
        'virtual_order_overview',
        patch.textileOrderId
      ]);

      const previousStateProfileOrderOverview:
        | OverviewPreorder
        | undefined = queryClient.getQueryData([
        'profile_preorder_overview',
        patch.profileId
      ]);

      if (previousStateOrderOverview) {
        let overviewPreorders = previousStateOrderOverview.preorders;
        overviewPreorders = overviewPreorders?.filter((itm) => itm.id !== patch.id)
        const newState = {
          ...previousStateVirtualOrderOverview,
          preorders: overviewPreorders
        };
        queryClient.setQueryData(['order_overview', patch.textileOrderId], newState);
      }

      if (previousStateVirtualOrderOverview) {
        let virtualPreorders = previousStateVirtualOrderOverview.preorders;
        virtualPreorders = virtualPreorders?.filter((itm) => itm.id !== patch.id)
        const newState = {
          ...previousStateVirtualOrderOverview,
          preorders: virtualPreorders,
          price: undefined
        };
        queryClient.setQueryData(
          ['virtual_order_overview', patch.textileOrderId],
          newState
        );
      }

      if (previousStateProfileOrderOverview) {
        let profilePreorders = previousStateProfileOrderOverview.preorders;
        profilePreorders = profilePreorders?.filter((itm) => itm.id !== patch.id)
        const newState = {
          ...previousStateProfileOrderOverview,
          preorders: profilePreorders
        };
        queryClient.setQueryData(['profile_preorder_overview', patch.profileId], newState);
      }

      return {
        previousStateOrderOverview,
        previousStateVirtualOrderOverview,
        previousStateProfileOrderOverview
      };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['virtual_order_overview', patch.textileOrderId],
        context?.previousStateVirtualOrderOverview
      );
      queryClient.setQueryData(
        ['order_overview', patch.textileOrderId],
        context?.previousStateOrderOverview
      );
      queryClient.setQueryData(
        ['profile_preorder_overview', patch.id],
        context?.previousStateProfileOrderOverview
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['virtual_order_overview', textileOrderId]);
      queryClient.invalidateQueries(['order_overview', textileOrderId]);
      if (id) {
        queryClient.invalidateQueries(['profile_preorder_overview', id]);
      }
    }
  });
};

export const useCreateTexitlePreorderQuery = (
  textileOrderId?: number,
  id?: number
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { data: any; textileOrderId: number }) =>
      createTextilePreorder(patch.data, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['order_overview', patch.textileOrderId]);
      await queryClient.cancelQueries([
        'virtual_order_overview',
        patch.textileOrderId
      ]);
      await queryClient.cancelQueries([
        'profile_preorder_overview',
        patch.textileOrderId
      ]);
      const previousStateOrderOverview:
        | OverviewPreorder
        | undefined = queryClient.getQueryData([
        'order_overview',
        patch.textileOrderId
      ]);

      const previousStateVirtualOrderOverview:
        | OverviewPreorder
        | undefined = queryClient.getQueryData([
        'virtual_order_overview',
        patch.textileOrderId
      ]);

      const previousStateProfileOrderOverview:
        | OverviewPreorder
        | undefined = queryClient.getQueryData([
        'profile_preorder_overview',
        patch.data.textile_profile_id
      ]);

      if (previousStateOrderOverview) {
        const overviewPreorders = previousStateOrderOverview.preorders;
        overviewPreorders?.push(patch.data);
        const newState = {
          ...previousStateVirtualOrderOverview,
          preorders: overviewPreorders
        };
        queryClient.setQueryData(['order_overview', patch.textileOrderId], newState);
      }

      if (previousStateVirtualOrderOverview) {
        const virtualPreorders = previousStateVirtualOrderOverview.preorders;
        virtualPreorders?.push(patch.data);
        const newState = {
          ...previousStateVirtualOrderOverview,
          preorders: virtualPreorders,
          price: undefined
        };
        queryClient.setQueryData(
          ['virtual_order_overview', patch.textileOrderId],
          newState
        );
      }

      if (previousStateProfileOrderOverview) {
        const profilePreorders = previousStateProfileOrderOverview.preorders;
        profilePreorders?.push(patch.data);
        const newState = {
          ...previousStateProfileOrderOverview,
          preorders: profilePreorders
        };
        queryClient.setQueryData(['profile_preorder_overview', patch.data.textile_profile_id], newState);
      }

      return {
        previousStateOrderOverview,
        previousStateVirtualOrderOverview,
        previousStateProfileOrderOverview
      };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['virtual_order_overview', patch.textileOrderId],
        context?.previousStateVirtualOrderOverview
      );
      queryClient.setQueryData(
        ['order_overview', patch.textileOrderId],
        context?.previousStateOrderOverview
      );
      queryClient.setQueryData(
        ['profile_preorder_overview', patch.data.id],
        context?.previousStateProfileOrderOverview
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['virtual_order_overview', textileOrderId]);
      queryClient.invalidateQueries(['order_overview', textileOrderId]);
      if (id) {
        queryClient.invalidateQueries(['profile_preorder_overview', id]);
      }
    }
  });
};
