import { CoverChapterSettingsModelType } from 'models/chapter-settings/CoverChapterSettingsModel';
import { PhotoModelType } from 'models/PhotoModel';
import { ChapterModelType } from 'models/ChapterModel';

export const getCoverBackCreativePhoto = (coverChapter?: ChapterModelType): PhotoModelType | null => {
  if (!coverChapter) {
    return null;
  }
  const photos = (coverChapter.settings as
    | CoverChapterSettingsModelType
    | undefined)?.photos;
  if (!photos) {
    return null;
  }
  const coverBackCreativePhoto = (photos as PhotoModelType[]).find(
    (candidate) => candidate.photo_type === 'cover_back_creative'
  );
  return coverBackCreativePhoto || null;
};
