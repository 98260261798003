import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

export default styled.span`
  position: absolute;
  top: 0;
  display: inline-block;
  animation: sk-bounce 2s infinite ease-in-out;
  border-radius: 100%;
  width: 60%;
  height: 60%;
  background-color: ${COLORS.PRIMARY};

  & + & {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
  }
`;
