import React from 'react';
import { FormattedMessage } from 'react-intl';

import { OrderStateTextileType } from 'api/textile_deals/fetchTextileAppState';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import TextileDashboardArrowComponent from '../components/TextileDashboardArrowComponent';

export const TextilePreorderBlockComponent = ({
  textileOrderId,
  locked,
  order_state,
  preorderOpen
}: {
  textileOrderId: number;
  locked: boolean;
  order_state: OrderStateTextileType;
  preorderOpen: boolean;
}) => {
  if (locked) {
    return (
      <HorizontalStackBlock justified={true} gap="XS" centered={true}>
        <IconComponent
          icon="PREORDER"
          roundBackground="GRAY950"
          size={3.5}
          square={true}
          fill="GRAY800"
        />
        <ItemStackBlock gap="XXS">
          <Headline.Small color="GRAY560">
            <FormattedMessage id="Textile preorder" />
          </Headline.Small>

          <Paragraph color="GRAY560">
            <IconComponent icon="LOCK" fill="GRAY400" />
            <FormattedMessage id="Editing deactivated" />
          </Paragraph>
        </ItemStackBlock>
        <TextileDashboardArrowComponent status={'locked'} />
      </HorizontalStackBlock>
    );
  }

  return (
    <NakedLink to={textileOrderRoute(undefined, '/preorder', textileOrderId)}>
      <HorizontalStackBlock justified={true} gap="XS" centered={true}>
        <IconComponent
          icon="PREORDER"
          roundBackground="PRIMARY"
          size={3.5}
          square={true}
          fill="WHITE"
        />
        <ItemStackBlock gap="XXS">
          <Headline.Small>
            <FormattedMessage id="Textile preorder" />
          </Headline.Small>
          {order_state === 'preparing' ? (
            <Paragraph color="TEXT_LIGHT">
              <FormattedMessage id="textile preorder not started" />
            </Paragraph>
          ) : preorderOpen ? (
            <Paragraph color="TEXT_LIGHT">
              <IconComponent icon="CLOCK_CIRCLE" fill="GRAY400" />
              <FormattedMessage id="textile preorder open" />
            </Paragraph>
          ) : (
            <Paragraph color="TEXT_LIGHT">
              <IconComponent icon="PAUSE" fill="GRAY400" />
              <FormattedMessage id="textile preorder closed" />
            </Paragraph>
          )}
        </ItemStackBlock>
        <TextileDashboardArrowComponent status="open" />
      </HorizontalStackBlock>
    </NakedLink>
  );
};

export default TextilePreorderBlockComponent;
