import styled from 'styled-components';

import NakedButton from './NakedButton';

const NakedBlockButton = styled(NakedButton)`
  display: block;
  width: 100%;
`;

export default NakedBlockButton;
