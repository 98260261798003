import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { ApplicationStoreType } from 'models/ApplicationStore';
import { SponsorsStoreType } from 'screens/sponsors/SponsorsStore';
import { HistoryProps, idFromMatch } from 'utils/history';
import TopActionBlock from 'blocks/TopActionBlock';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import { Link } from 'react-router-dom';
import { ROUTE_DISCOUNTS } from 'utils/constants/routes';
import BackLink from 'domain/Links/BackLink';
import NotFoundScreen from 'screens/NotFoundScreen';
import CardBlock from 'blocks/CardBlock';
import MediaBlock from 'blocks/MediaBlock';
import ImageBlock from 'blocks/ImageBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TaglineBlock from 'blocks/TaglineBlock';
import AdvertImageComponent from 'screens/sponsors/screens/SponsorDetail/AdvertImageComponent';
import PreserveWhitespace from 'elements/PreserveWhitespace';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';

interface SponsorDiscountsScreenProps {
  applicationStore: ApplicationStoreType;
  sponsorsStore: SponsorsStoreType;
}

@inject('applicationStore', 'sponsorsStore')
@observer
class SponsorDiscountsScreen extends React.Component<
  SponsorDiscountsScreenProps & HistoryProps
> {
  componentDidMount() {
    this.load();
  }

  private load() {
    this.props.sponsorsStore.getAllSponsors();
  }

  private renderPage(content?: any, backLink = false) {
    return (
      <>
        <TopActionBlock>
          {backLink ? (
            <BackLink to={ROUTE_DISCOUNTS + '/sponsors'} />
          ) : (
            <BackToDashboardLink />
          )}
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>

        <HelpSubject subject="sponsors" />
      </>
    );
  }

  private renderList() {
    const { donationsSums, allSponsors } = this.props.sponsorsStore;
    const { isOrganizer } = this.props.applicationStore;

    const list = allSponsors('global');
    const hasAnyChecked = !!donationsSums.placed;

    return this.renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Sponsors" />}
          text={
            <FormattedMessage
              id={
                isOrganizer
                  ? 'sponsor discounts info organizers'
                  : 'sponsor discounts info students'
              }
            />
          }
        />

        <ItemStackBlock gap="XS">
          <HorizontalStackBlock justified={true}>
            <Paragraph size="L" weight="BOLD" >
              <FormattedMessage id="Possible discount" />
            </Paragraph>
            <Paragraph size="L" weight="BOLD" noBreak={true}>
              <FormattedNumber
                value={donationsSums.global}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="EUR"
              />
            </Paragraph>
          </HorizontalStackBlock>

          <Divider />

          <HorizontalStackBlock justified={true}>
            <ItemStackBlock gap="XXS">
              <Paragraph size="L" weight="BOLD" >
                <FormattedMessage id="Guaranteed discount" />
              </Paragraph>
              {hasAnyChecked && (
                <TextElement>
                  <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
                  <FormattedMessage id="Amount discounted automatically" />
                </TextElement>
              )}
            </ItemStackBlock>
            <Paragraph size="L" weight="BOLD" noBreak={true}>
              <FormattedNumber
                value={donationsSums.placedGlobal}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="EUR"
              />
            </Paragraph>
          </HorizontalStackBlock>
        </ItemStackBlock>

        <Divider />

        <Headline.Small>
          <FormattedMessage id="count ads" values={{ count: list.length }} />
        </Headline.Small>

        <ListStackBlock>
          {list.map((sponsor) => (
            <Link
              to={ROUTE_DISCOUNTS + '/sponsors/' + sponsor.id}
              key={sponsor.id}
            >
              <CardBlock>
                <MediaBlock>
                  {sponsor.advertisement?.url && (
                    <ImageBlock
                      // background="GRAY900"
                      width={3.0625}
                      height={3.0625}
                      // cover="true"
                      // radius="true"
                    >
                      <img src={sponsor.advertisement.url} alt="" />
                    </ImageBlock>
                  )}

                  <PaddingBlock>
                    <Paragraph size="L" weight="BOLD">
                      {sponsor.name}
                    </Paragraph>

                    {sponsor.placed ? (
                      <TaglineBlock>
                        <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                        <TextElement>
                          <FormattedMessage id="Placed" />
                        </TextElement>
                      </TaglineBlock>
                    ) : (
                      <TaglineBlock>
                        <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />
                        <TextElement>
                          <FormattedMessage id="Not yet placed" />
                        </TextElement>
                      </TaglineBlock>
                    )}
                  </PaddingBlock>
                </MediaBlock>
              </CardBlock>
            </Link>
          ))}
        </ListStackBlock>
      </>
    );
  }

  private renderDetail(id: number) {
    const sponsor = this.props.sponsorsStore.sponsors?.get(id.toString());
    if (!sponsor) {
      return <NotFoundScreen />;
    }

    const { isOrganizer } = this.props.applicationStore;

    return this.renderPage(
      <>
        {isOrganizer ? (
          <>
            <PageHeader
              headline={
                <FormattedMessage
                  id="about sponsor"
                  values={{ name: sponsor.name }}
                />
              }
              text={
                sponsor.placed ? (
                  <>
                    <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
                    <FormattedMessage id="Placed" />
                  </>
                ) : (
                  <>
                    <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />{' '}
                    <FormattedMessage id="Place now" />
                  </>
                )
              }
            />
          </>
        ) : (
          <Headline.Large>
            <FormattedMessage
              id="about sponsor"
              values={{ name: sponsor.name }}
            />
          </Headline.Large>
        )}

        {sponsor.advertisement && (
          <ItemStackBlock gap="XXS">
            <Headline.Small>
              <FormattedMessage id="Advert" />
            </Headline.Small>
            <AdvertImageComponent advert={sponsor.advertisement} />
          </ItemStackBlock>
        )}

        {sponsor.description && (
          <Paragraph>
            <PreserveWhitespace>{sponsor.description}</PreserveWhitespace>
          </Paragraph>
        )}
      </>,
      true
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  private renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.load()} />
    );
  }

  render() {
    const { isListLoading, isListError } = this.props.sponsorsStore;

    if (isListLoading) {
      return this.renderLoading();
    }

    if (isListError) {
      return this.renderError();
    }

    const id = idFromMatch(this.props.match);
    if (id) {
      return this.renderDetail(id);
    }

    return this.renderList();
  }
}

export default SponsorDiscountsScreen;
