import config from 'config';
import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import createMap, { createArray, createMapWithTransform } from 'utils/create-map';
import { sortByName } from 'utils/sort-functions';
import { local } from 'utils/storage';
import createBookModel from 'utils/store/createBookModel';
import createSchoolModel from 'utils/store/createSchoolModel';

import AccountModel, { createAccountModel } from './AccountModel';
import BookModel from './BookModel';
import CityModel, { CityModelType, createCityModel } from './CityModel';
import SchoolModel, { SchoolModelType } from './SchoolModel';
import SchoolYearModel, { createSchoolYearModel } from './SchoolYearModel';
import { AdvancedStoreEnv } from './StoreEnv';

export type LegalEnum = 'tos' | 'privacy' | 'engagement';

const SetupStore = types
  .model('SetupStore', {
    token: types.maybe(types.string),
    captchaKey: types.maybe(types.string),
    account: types.maybe(AccountModel),
    schoolYear: types.maybe(SchoolYearModel),
    school: types.maybe(SchoolModel),
    book: types.maybe(BookModel),
    // signup
    signupLoadingState: types.maybe(
      types.enumeration([
        'loading',
        'token_invalid',
        'error',
        'done'
      ])
    ),
    numberLoadingState: types.maybe(types.enumeration(['loading', 'error'])),
    numberExists: types.maybe(types.boolean),
    hint: types.maybe(types.string),
    // school search
    schoolsLoadingState: types.maybe(types.enumeration(['loading', 'error'])),
    citiesLoadingState: types.maybe(types.enumeration(['loading', 'error', 'done'])),
    cityLoadingState: types.maybe(types.enumeration(['loading', 'error', 'done'])),
    cities: types.maybe(types.array(CityModel)),
    schools: types.maybe(types.map(SchoolModel)),
    city: types.maybe(CityModel),
    newSchool: types.maybe(types.boolean),
    newCityAndSchool: types.maybe(types.boolean)
  })
  .actions((self) => {
    const initSignup = (token?: string) => {
      self.token = token || undefined;
      self.book = createBookModel({});
      self.account = createAccountModel({});
      self.school = createSchoolModel({});
      self.schoolYear = createSchoolYearModel({});
      self.numberExists = undefined;
      self.captchaKey = undefined;
    };

    const setName = (first_name: string, last_name: string) => {
      if (self.account) {
        self.account.first_name = first_name;
        self.account.last_name = last_name;
      } else {
        self.account = createAccountModel({
          first_name,
          last_name
        });
      }
    };

    const setNumber = (number: string) => {
      if (self.account) {
        self.account.mobile_number = number;
      } else {
        self.account = createAccountModel({
          mobile_number: number
        });
      }
    };

    const setPassword = (password: string) => {
      if (self.account) {
        self.account.password = password;
      } else {
        self.account = createAccountModel({
          password
        });
      }
    };

    const resetCities = () => {
      self.city = undefined;
      self.cities = undefined;
      self.citiesLoadingState = undefined;
    }

    const validateNumber = flow(function* (
      mobileNumber?: string,
      setIfValid: boolean = true
    ) {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.numberLoadingState = 'loading';
        self.numberExists = undefined;

        const result = yield client.validateNumber(mobileNumber || '');

        if (!result || !result.success) {
          throw new Error('no_success');
        }

        if (result.mobile_number) {
          if (setIfValid) {
            setNumber(result.mobile_number);
          }

          self.numberLoadingState = undefined;
          return result.mobile_number;
        }

        self.numberLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('SetupStore | validateNumber', error, error.body);
        }

        if (client.isFormError(error)) {
          self.numberLoadingState = undefined;

          if (error && error.body && error.body.exists) {
            self.numberExists = true;
          }

          throw error;
        }

        self.numberLoadingState = 'error';
        throw error;
      }
    });

    const setCaptchaKey = (key: string) => {
      self.captchaKey = key;
    };

    const signup = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.signupLoadingState = 'loading';


        if (!self.account) {
          throw new Error('missing_data');
        }


        let result;
        // let newSchool = false;
        if (self.token) {
          result = yield client.join(self.token, self.account, self.captchaKey);

          if (!result || !result.success) {
            throw new Error('no_success');
          }
        } else {
          if (!self.schoolYear || !self.school) {
            throw new Error('missing_data');
          }

          const signupRef = local.get(config.signupRefName);
          const affiliateToken = local.get(config.affiliateTokenName);

            // sign up with selected school
            result = yield client.signup(
              self.school,
              self.schoolYear,
              self.account,
              signupRef,
              affiliateToken,
              self.captchaKey
            );
        }

        local.remove(config.signupRefName);
        local.remove(config.affiliateTokenName);

        if (result && result.hint && result.hint.auth_url) {
          self.hint = result.hint.auth_url;
        }

        // self.signupLoadingState = newSchool ? 'done_new_school' : 'done';
        self.signupLoadingState = 'done';
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('SetupStore | signup', error, error.body);
        }

        // TODO do we need this here at all?
        if (applicationStore.handleAppError(error)) {
          self.signupLoadingState = undefined;
          return;
        }

        // do not throw anything else as caller only needs to handle a form error at the moment
        if (client.isFormError(error)) {
          self.signupLoadingState = undefined;
          throw error;
        }

        if (
          self.token &&
          error &&
          error.response &&
          error.response.status === 404
        ) {
          self.signupLoadingState = 'token_invalid';
          return;
        }

        self.signupLoadingState = 'error';
      }
    });

    const searchCity = flow(function* (city_name: string) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.citiesLoadingState = 'loading';
        if (self.cities) {
          self.cities.clear();
        }

        const result = yield client.searchCity({city_name});

        if (!Array.isArray(result) || !result.length) {
          self.cities = createArray([]);
          self.citiesLoadingState = 'done';

          return;
        }

        self.cities = createArray(result);
        self.citiesLoadingState = 'done';
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console
          console.error('SetupStore | searchCity', error, error.body);
        }

        // TODO do we need this here at all?
        if (applicationStore.handleAppError(error)) {
          self.citiesLoadingState = undefined;
          return;
        }

        // do not throw anything else as caller only needs to handle a form error at the moment
        if (client.isFormError(error)) {
          self.cities = undefined;
          self.citiesLoadingState = 'error';
          throw error;
        }

        self.citiesLoadingState = 'error';
      }
    });

    const getCityWithSchools = flow(function* (cityId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        self.schoolsLoadingState = 'loading';
        if (self.schools) {
          self.schools.clear();
          self.city = undefined
        }

        const result = yield client.getCityWithSchools(cityId);

        self.city = createCityModel(result)
        if (!Array.isArray(result.schools) || !result.schools.length) {
          self.schools = createMap([]);
          self.schoolsLoadingState = undefined;

          return;
        }

        self.schools = createMapWithTransform(result.schools, createSchoolModel);
        self.schoolsLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable-next-line: no-console
          console.error('SetupStore | searchCity', error, error.body);
        }

        // TODO do we need this here at all?
        if (applicationStore.handleAppError(error)) {
          self.schoolsLoadingState = undefined;
          return;
        }

        // do not throw anything else as caller only needs to handle a form error at the moment
        if (client.isFormError(error)) {
          self.schools = undefined;
          self.schoolsLoadingState = undefined;
          throw error;
        }

        self.schoolsLoadingState = 'error';
      }
    });

    const setSchoolFromResults = (id: number, name: string) => {
      self.school = createSchoolModel({id, name})
      self.newSchool = false
      self.newCityAndSchool = false
    };

    const setYear = (year: number) => {
      self.schoolYear = undefined;
      if (self.newSchool || self.newCityAndSchool) {
        self.schoolYear = createSchoolYearModel({year})
      } else {
        self.schoolYear = createSchoolYearModel({year, school_id: self.school?.id.toString()})
      }
    };

    const setNewCityAndSchool = (name: string, city: string) => {
      self.city = undefined;
      self.school = undefined;
      self.schoolYear = undefined;


      self.school = createSchoolModel({
        name,
        city_name: city,
        created_by_user: true,
      });
      self.newCityAndSchool = true;
      self.newSchool = false;
    };

    const setNewSchool = (name: string) => {
      self.school = undefined;
      self.schoolYear = undefined;

      if (!self.city) {
        throw new Error('City cant be empty');
      }

      self.school = createSchoolModel({
        name,
        city_name: self.city.name,
        created_by_user: true,
      });
      self.newSchool = true;
      self.newCityAndSchool = false
    }


    return {
      initSignup,
      setName,
      setNumber,
      setPassword,
      resetCities,
      validateNumber,
      setCaptchaKey,
      signup,
      searchCity,
      getCityWithSchools,
      setSchoolFromResults,
      setYear,
      setNewCityAndSchool,
      setNewSchool
    };
  })
  .views((self) => {
    return {
      get isSignupInitialized(): boolean {
        return !self.account ? false : true;
      },
      get isInfoComplete(): boolean {
        if (
          !self.account ||
          !self.account.first_name ||
          !self.account.last_name
        ) {
          return false;
        }

        if (
          !self.account ||
          !self.account.mobile_number
        ) {
          return false;
        }

        if (!self.token) {
          if (
            !self.school ||
            !self.school.id ||
            !self.schoolYear ||
            !self.schoolYear.year
          ) {
            return false;
          }
        }

        return true;
      },
      get mayStartSignup(): boolean {
        switch (self.signupLoadingState) {
          case 'loading':
          case 'done':
          case 'token_invalid':
            return false;
        }
        return true;
      },
      get isSignupDone(): boolean {
        switch (self.signupLoadingState) {
          case 'done':
          case 'error':
          case 'token_invalid':
            return true;
        }
        return false;
      },

      get isCitiesLoading(): boolean {
        return self.citiesLoadingState === 'loading';
      },
      get isCitiesDone(): boolean {
        return self.citiesLoadingState === 'done';
      },
      get isCitiesError(): boolean {
        return self.citiesLoadingState === 'error';
      },
      get isSchoolsLoading(): boolean {
        return self.schoolsLoadingState === 'loading';
      },
      get isSchoolsError(): boolean {
        return self.schoolsLoadingState === 'error';
      },
      get isSchoolsInitialized(): boolean {
        return !!self.schools;
      },
      get sortedSchools(): SchoolModelType[]  {
        const items: SchoolModelType[] = [];
        if (!self.schools) {
          return items;
        }

        for (const item of self.schools.values()) {
          items.push(item);
        }

        items.sort(sortByName);

        return items;
      },
      get schoolsCount(): number {
        return self.schools ? self.schools.size : 0;
      },

      get isNumberLoading(): boolean {
        return self.numberLoadingState === 'loading';
      },
      get isNumberError(): boolean {
        return self.numberLoadingState === 'error';
      },
      get foundCities(): CityModelType[] {
        const items: CityModelType[] = [];
        if (!self.cities) {
          return items;
        }

        for (const item of self.cities.values()) {
          items.push(item);
        }

        return items;
      },

      filteredSchools(name: string): SchoolModelType[] {
        const schools: SchoolModelType[] = [];
        if (!self.schools) {
          return schools;
        }

        const nameLowercase = name.trim().toLowerCase();

        for (const school of self.schools.values()) {
          if (
            school.name &&
            school.name.toLowerCase().indexOf(nameLowercase) > -1
          ) {
            schools.push(school);
          }
        }

        schools.sort(sortByName);
        return schools;
      },
    };
  });

export type SetupStoreType = Instance<typeof SetupStore>;
export default SetupStore;
