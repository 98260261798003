import React, { useContext, useEffect, useState } from 'react';

import { TextileStudent } from 'api/textile_deals/fetchStudentList';
import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import IconComponent from 'components/IconComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import CancelLink from 'domain/Links/CancelLink';
import TextileGroupList from 'domain/TextileGroupList/TextileGroupList';
import NakedBlockButton from 'elements/NakedBlockButton';
import NakedButton from 'elements/NakedButton';
import UppercaseHeading from 'elements/UppercaseHeading';
import { useAddTextileStudentToGroupQuery } from 'queries/textile_deals/useTextileNamesQueries';
import { useAddTextileProfileToGroupQuery } from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { assert } from 'utils/assert';
import { HistoryProps } from 'utils/history';
import { textileOrderRoute } from 'utils/history/textile-order-route';

interface StudentsWithoutTextileGroupProps {
  students: TextileStudent[];
  textileOrderId?: number;
}

const StudentsWithoutTextileGroup: React.FC<
  StudentsWithoutTextileGroupProps & HistoryProps
> = (props) => {
  const [selectedProfile, setSelectedProfile] = useState<
    TextileStudent | undefined
  >(undefined);
  const accessContext = useContext(HasAccessContext);

  const updateTextileProfile = useAddTextileProfileToGroupQuery(
    props.textileOrderId
  );
  const updateTextileName = useAddTextileStudentToGroupQuery(
    props.textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('design');
  }, [accessContext]);

  const addStudentToGroup = (id: number) => {
    assert(selectedProfile);
    if (!props.textileOrderId) {
      return;
    }

    if (selectedProfile.type === 'textile_profile') {
      updateTextileProfile.mutate({
        data: { textile_group_id: id },
        id: selectedProfile.id,
        textileOrderId: props.textileOrderId
      });
    }

    if (selectedProfile.type === 'textile_name') {
      updateTextileName.mutate({
        data: { textile_group_id: id },
        id: selectedProfile.id,
        textileOrderId: props.textileOrderId
      });
    }
    setSelectedProfile(undefined);
  };

  const cancelLink = () => {
    if (selectedProfile) {
      return (
        <NakedButton onClick={() => setSelectedProfile(undefined)}>
          <TextElement color="GRAY10">
            <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
            <FormattedMessage id="Cancel" />
          </TextElement>
        </NakedButton>
      );
    } else {
      return (
        <CancelLink
          to={textileOrderRoute(undefined, '/groups', props.textileOrderId)}
        />
      );
    }
  };

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>{cancelLink()}</TopActionBlock>

        {content}
      </>
    );
  };

  const renderGroupList = () => {
    if (!selectedProfile) {
      // wtf
      return null;
    }
    return (
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Select group for student" />}
          text={
            <>
              <IconComponent icon="USER" />
              <FormattedMessage
                id="Select group for student name"
                values={{
                  name: `${selectedProfile.first_name} ${selectedProfile.last_name}`
                }}
              />
            </>
          }
        />

        <TextileGroupList
          onGroupClick={(_event, id) => addStudentToGroup(id)}
          textileOrderId={props.textileOrderId}
        />
      </PageStackBlock>
    );
  };

  const renderStudentList = () => {
    const { students } = props;

    return (
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Students without groups" />}
          text={<FormattedMessage id="textile students no groups" />}
        />

        <ItemStackBlock gap="XXS">
          <UppercaseHeading>
            {students.length} <FormattedMessage id="Students" />
          </UppercaseHeading>

          <ListStackBlock>
            <ButtonListBlock>
              {students.map((profile: TextileStudent) => {
                if (profile.textile_group !== null) {
                  return null;
                }

                return (
                  <ButtonListBlockItem key={profile.id}>
                    <NakedBlockButton
                      onClick={() => setSelectedProfile(profile)}
                    >
                      <ButtonListBlockBody>
                        {profile.name || profile.id}
                      </ButtonListBlockBody>
                    </NakedBlockButton>
                  </ButtonListBlockItem>
                );
              })}
            </ButtonListBlock>
          </ListStackBlock>
        </ItemStackBlock>
      </PageStackBlock>
    );
  };

  return selectedProfile
    ? renderPage(renderGroupList())
    : renderPage(renderStudentList());
};

export default (props: StudentsWithoutTextileGroupProps) => (
  // @ts-ignore
  <StudentsWithoutTextileGroup {...props} />
);
