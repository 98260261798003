import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ItemSortingComponent from 'components/ItemSortingComponent/ItemSortingComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ROUTE_CONTENT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

interface Props {
  chaptersStore: ChaptersStoreType;
}

@inject('chaptersStore')
@observer
class SortChaptersScreen extends React.Component<Props & HistoryProps> {
  componentDidMount() {
    this.loadChapters();
  }

  loadChapters() {
    this.props.chaptersStore.getAllChapters();
  }

  onSortEnd(oldIndex: number, newIndex: number) {
    this.props.chaptersStore.moveChapter(oldIndex, newIndex);
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadChapters()} />
    );
  }

  renderPage(content: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackLink messageId="Done" to={ROUTE_CONTENT} />
        </TopActionBlock>

        {content}
      </>
    );
  }

  render() {
    const { chaptersStore } = this.props;

    if (chaptersStore.isListLoading) {
      return this.renderLoading();
    }

    if (chaptersStore.isListError) {
      return this.renderError();
    }

    return this.renderPage(
      <PageStackBlock>
        <Headline.Large>
          <FormattedMessage id="Sort chapters" />
        </Headline.Large>
        <ItemSortingComponent
          items={chaptersStore.sortedChaptersWithoutCover.map((chapter) => ({
            id: chapter.id,
            text: chapter.title || (
              <FormattedMessage id={`chapter type ${chapter.chapter_type}`} />
            )
          }))}
          onSortEnd={(oldIndex, newIndex) => this.onSortEnd(oldIndex, newIndex)}
        />
      </PageStackBlock>
    );
  }
}

export default SortChaptersScreen;
