import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

export const FunnelCardTop = styled.div`
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
  background: ${COLORS.PRIMARY_LIGHT};
`;

export const FunnelCardLabel = styled.div`
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${COLORS.BLACK};
  font-weight: 500;
`;

export const FunnelCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 12.0625rem;
  overflow: hidden;
  border-radius: 0.625rem;
  line-height: 1;
  border: 1px solid ${COLORS.GRAY800};
`;
