import React, { useContext } from 'react';

import InfoBox from 'components/InfoBoxComponent/InfoBoxComponent';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

interface FlashMessageDisplayComponentProps {
  message?: string;
  type?: string;
}

const Fade = styled.div`
  opacity: ${({ state }: { state: any }) => (state === 'entered' ? 1 : 0)};
  transition: 0.5s;
`;

const FlashMessageDisplayComponent: React.FC<FlashMessageDisplayComponentProps> = ({
  message,
  type
}) => {
  const visible = !!message;

  return (
    <>
      <Transition
        in={visible}
        timeout={500}
        unmountOnExit={true}
        mountOnEnter={true}
        enter={false}
      >
        {(state: any) => (
          <Fade state={state}>
            <InfoBox
              fixed={true}
              error={type === 'error'}
              warning={type === 'warning'}
              success={type === 'success'}
            >
              <span>{message}</span>
            </InfoBox>
          </Fade>
        )}
      </Transition>
    </>
  );
};

const FlashMessageComponent: React.FC = () => {
  const context = useContext(FlashMessageContext);

  if (!context) {
    throw new Error('FlashMessage must be used within a FlashMessageProvider');
  }

  const { flashMessage } = context;
  const { message, type } = flashMessage;

  return (
    <FlashMessageDisplayComponent
      message={message}
      type={type}
    />
  )
};

export default FlashMessageComponent;
