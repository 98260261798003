import React from 'react';

import PlainHtmlBlock from 'blocks/PlainHtmlBlock';

function PlainHTML() {
  return (
    <>
      <PlainHtmlBlock>
        <h1>This is a headline</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit nobis deleniti blanditiis, temporibus numquam vero nostrum inventore quod ipsam ab ratione ipsa ipsum praesentium esse at totam animi aspernatur dicta. Mollitia nemo veritatis repudiandae consequuntur doloremque voluptate sapiente similique voluptates.</p>
        <h2>Here we have a h2.</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam, laudantium.</p>
        <p>Obcaecati quos reprehenderit consectetur natus nihil recusandae voluptatem! Iure, facilis!</p>
        <h3>Look an unordered list (and this is a h3)</h3>
        <ul>
          <li>Lorem, ipsum.</li>
          <li>Modi, alias.</li>
          <li>Excepturi, aspernatur?</li>
          <li>Facere, quibusdam.</li>
        </ul>
        <p>Lorem, ipsum dolor.</p>
        <ol>
          <li>Lorem, ipsum.</li>
          <li>Modi, alias.</li>
          <li>Excepturi, aspernatur?</li>
          <li>Facere, quibusdam.</li>
        </ol>
      </PlainHtmlBlock>
    </>
  );
}

export default PlainHTML;