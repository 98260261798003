import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import WideBlock from 'blocks/WideBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import { ALBUM_PAGE_GRID_LIST } from 'components/print/templates/album-page-grids';
import BackLink from 'domain/Links/BackLink';
import NakedButton from 'elements/NakedButton';
import { AlbumPageModelType } from 'models/AlbumPageModel';
import { ApplicationStoreType } from 'models/ApplicationStore';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import { ROUTE_ALBUMS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

import PageHeader from 'components/PageHeader/PageHeader';
import { AlbumPagesStoreType, LoadingState } from './AlbumPagesStore';

const GridImage = styled.img`
  width: 100%;
  border: 1px solid ${COLORS.GRAY900};
`;

interface Props {
  albumPagesStore: AlbumPagesStoreType;
  applicationStore: ApplicationStoreType;
}

@inject('albumPagesStore', 'applicationStore')
@observer
class NewAlbumPage extends Component<Props & HistoryProps> {
  componentDidMount() {
    this.props.applicationStore.onboardFeature(
      'albumpages',
      this.props.location?.pathname
    );

    // @ts-ignore
    window.editor_selectLayout = (layout: string) => this.selectLayout(layout);
  }

  componentWillUnmount() {
    // @ts-ignore
    delete window.editor_selectLayout;
  }

  selectLayout(layout: string) {
    if (ALBUM_PAGE_GRID_LIST.findIndex((grid) => grid.key === layout) >= 0) {
      this.createPage(layout);
    }
  }

  async createPage(layoutKey: string) {
    const chapterId = chapterIdFromMatch(this.props.match);
    if (!chapterId) {
      return;
    }
    let albumPage: AlbumPageModelType;
    try {
      albumPage = await this.props.albumPagesStore.createAlbumPage(
        chapterId,
        layoutKey
      );
    } catch (error: any) {
      // TODO
      return;
    }
    this.props.history.push(
      `${ROUTE_ALBUMS}/${chapterId}/pages/${albumPage.id}`
    );
  }

  render() {
    const { albumPageLoadingState } = this.props.albumPagesStore;

    if (albumPageLoadingState === LoadingState.ERROR) {
      // TODO: Call createPage again with the last layoutKey
      return <GenericErrorComponent />;
    }

    const chapterId = chapterIdFromMatch(this.props.match);

    let gridList = ALBUM_PAGE_GRID_LIST;

    return (
      <>
        <TopActionBlock>
          <BackLink to={`${ROUTE_ALBUMS}/${chapterId}`} />
        </TopActionBlock>
        <ItemStackBlock gap="L">
          <PageHeader
            headline={<FormattedMessage id="New album page heading" />}
            text={<FormattedMessage id="New album page intro" />}
          />
          <WideBlock padded={true}>
            <TwoColumnBlock gap="M">
              {gridList.filter(grid => !grid.unindexed).map((gridTemplate) => (
                <NakedButton
                  key={gridTemplate.key}
                  onClick={() => this.createPage(gridTemplate.key)}
                >
                  {gridTemplate.preview ? (
                    <GridImage
                      src={'/images/album-grids/' + gridTemplate.preview}
                      alt={gridTemplate.key}
                    />
                  ) : (
                    gridTemplate.key
                  )}
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </WideBlock>
        </ItemStackBlock>
      </>
    );
  }
}

export default NewAlbumPage;
