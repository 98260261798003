import styled from 'styled-components';

import SPACES from 'utils/constants/spaces';

export interface ItemStackProps {
  gap: keyof typeof SPACES;
  margin?: keyof typeof SPACES;
  marginTop?: keyof typeof SPACES;
  marginBottom?: keyof typeof SPACES;
}

const ItemStackBlock = styled.div<ItemStackProps>`
  flex-direction: column;
  display: flex;
  margin: ${(props) =>
    props.margin ? SPACES[props.margin] + ' auto' : '0 auto'};
  margin-top: ${(props) => (props.marginTop ? SPACES[props.marginTop] : '0')};
  margin-bottom: ${(props) =>
    props.marginBottom ? SPACES[props.marginBottom] : '0'};
  width: 100%;

  > * + * {
    margin-top: ${(props) => (SPACES[props.gap])};
  }

  @supports (display: grid) {
    display: grid;
    grid-gap: ${(props) => (SPACES[props.gap])};

    /* Make sure the grid item width is capped at 100% of the grid container width */
    grid-auto-columns: 100%;

    > * {
      margin: 0;
      width: 100%;
    }
  }
`;

export default ItemStackBlock;
