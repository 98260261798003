import React from 'react';
import { Route, Switch } from 'react-router';

import {
  ROUTE_BOOK_FUNNEL,
  ROUTE_CLOTHING_FUNNEL
} from 'utils/constants/routes';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import BookFunnelScreen from './book/BookFunnelScreen';
import ClothingFunnelScreen from './clothing/ClothingFunnelScreen';

const FunnelRouter = () => (
  <Switch>
    <Route path={ROUTE_CLOTHING_FUNNEL} component={ClothingFunnelScreen} />
    <Route path={ROUTE_BOOK_FUNNEL} component={BookFunnelScreen} />

    {/*  travel funnel currently deactivated */}
    {/* <Route path={ROUTE_TRAVEL_FUNNEL} component={TravelFunnelScreen} /> */}
    {/* INFO PACKAGE FUNNELS CURRENTLY NOT USED */}

    {/* <Route
      path={ROUTE_INFO_PACKAGE_FUNNEL}
      component={InfoPackageFunnelScreen}
    />
        <Route
      path={ROUTE_INFO_PACKAGE_TEXTILES_FUNNEL}
      component={InfoPackageTextilesFunnelScreen}
    /> */}

    <NotFoundRoute />
  </Switch>
);

export default FunnelRouter;
