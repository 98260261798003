import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TopActionBlock from 'blocks/TopActionBlock';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { ROUTE_COMMENTS, ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch, isPush } from 'utils/history';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { CommentsStoreType } from 'screens/comments/CommentsStore';
import ItemSortingComponent from 'components/ItemSortingComponent/ItemSortingComponent';

interface Props {
  commentsStore: CommentsStoreType;
}

@inject('commentsStore')
@observer
class SortCommentsScreen extends React.Component<Props & HistoryProps> {
  didMountWithProfileId(profileId: number) {
    const { commentsStore } = this.props;

    if (!commentsStore.isListLoading) {
      if (
        commentsStore.commentsProfileId !== profileId ||
        isPush(this.props.history)
      ) {
        this.loadProfileComments(profileId);
      }
    }
  }

  loadProfileComments(profileId: number) {
    this.props.commentsStore.getProfileComments(profileId);
  }

  componentDidMount() {
    const { history } = this.props;
    const id = idFromMatch(this.props.match);

    if (id) {
      this.didMountWithProfileId(id);
    } else {
      history.push(ROUTE_COMMENTS);
    }
  }

  onSortEnd(oldIndex: number, newIndex: number) {
    this.props.commentsStore.moveComment(oldIndex, newIndex);
  }

  renderPage(content: React.ReactNode) {
    const id = idFromMatch(this.props.match);

    if (!id) {
      return null;
    }

    return (
      <>
        <TopActionBlock>
          <BackLink
            messageId="Done"
            to={ROUTE_FACTSHEETS +  '/detail/' + id + '/comments'}
          />
        </TopActionBlock>
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Sort comments" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent />
    );
  }

  render() {
    const { commentsStore } = this.props;

    if (commentsStore.isListLoading) {
      return this.renderLoading();
    }

    if (commentsStore.isListError) {
      return this.renderError();
    }

    return this.renderPage(
        <ItemSortingComponent
          items={commentsStore.sortedComments.map((comment) => ({
            id: comment.id,
            text: comment.comment || ''
          }))}
          onSortEnd={(oldIndex, newIndex) => this.onSortEnd(oldIndex, newIndex)}
        />
    );
  }
}

export default SortCommentsScreen;
