import React from 'react';
import styled from 'styled-components';
import { ProductImage } from 'utils/product-image';

interface ProductImageContainerProps {
  height?: string;
  width?: string;
  align?: string;
  justify?: string;
  heightAsRef?: boolean;
}

export const ProductImageContainer = styled.div<ProductImageContainerProps>`
  position: relative;
  aspect-ratio: 355/452;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  ${(props) => props.align && `align-self: ${props.align};`}
  ${(props) => props.justify && `justify-self: ${props.justify};`}

  > .productImage {
    position: absolute;
    top: 0;
    left: 0;

    ${(props) =>
      props.heightAsRef
        ? `
      width: auto;
      height: 100%;
    `
        : `width: 100%;
      height: auto;`}
  }
`;

const TextileProductImageComponent = ({
  productImage,
  height,
  width,
  heightAsRef,
  align,
  justify
}: {
  productImage: ProductImage;
  height?: string;
  width?: string;
  align?: string;
  justify?: string;
  heightAsRef?: boolean;
}) => {
  if (!productImage.botLayer) {
    return null;
  }

  return (
    <ProductImageContainer
      width={width}
      height={height}
      align={align}
      justify={justify}
      heightAsRef={heightAsRef}
    >
      <img
        src={productImage.botLayer}
        alt="vorschau bild"
        className="productImage"
        loading="lazy"
      />
      {productImage.midLayer && (
        <img
          src={productImage.midLayer}
          alt="vorschau bild"
          className="productImage"
          loading="lazy"
        />
      )}
      {productImage.topLayer && (
        <img
          src={productImage.topLayer}
          alt="vorschau bild"
          className="productImage"
          loading="lazy"
        />
      )}
    </ProductImageContainer>
  );
};

export default TextileProductImageComponent;
