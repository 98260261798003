import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Rank } from '../../RankingsStore';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import NumberListBlock from 'blocks/NumberListBlock';
import Headline from 'elements/Headline';
import TaglineBlock from 'blocks/TaglineBlock';
import ButtonBlock from 'blocks/ButtonBlock';
import IconComponent from 'components/IconComponent';
import {
  ButtonListBlockItem,
  ButtonListBlockItemPadding
} from 'blocks/ButtonListBlock/ButtonListBlock';
import TextElement from 'components/TextElement/TextElement';

export const RankingRankItem = ({
  item,
  onRemove
}: {
  item?: Rank;
  onRemove?: (candidateIds: number[]) => void;
}) => {
  if (!item?.vote?.candidates) {
    return null;
  }

  const { vote } = item;

  let name: string | undefined;
  let candidateIds: number[] | undefined;
  if (vote.candidates!.size === 1) {
    const c = vote.candidates!.values().next().value;

    name = c.name;
    candidateIds = [c.id];
  } else {
    candidateIds = [];
    const names = [];
    for (const c of vote.candidates!.values()) {
      names.push(c.name);
      candidateIds.push(c.id);
    }

    name = names.join(' & ');
  }

  return (
    <ButtonListBlockItem>
      <ButtonListBlockItemPadding>
        <HorizontalStackBlock justified={true}>
          <NumberListBlock>
            <NumberListBlock.ListItemElement noMargin={true}>
              <NumberListBlock.NumberElement>
                {item.rank > 0 ? item.rank : <>&nbsp;</>}
              </NumberListBlock.NumberElement>

              <Headline.Small>{name}</Headline.Small>

              <TaglineBlock>
                <TextElement  color="TEXT_DARK">
                  <FormattedMessage
                    id="votes count short"
                    values={{
                      count: item.count
                    }}
                  />
                </TextElement>
              </TaglineBlock>
            </NumberListBlock.ListItemElement>
          </NumberListBlock>

          {/* TODO vertically center button */}
          {onRemove && candidateIds && candidateIds.length && (
            <div>
              <ButtonBlock
                onlyIcon={true}
                background="RED_LIGHT"
                onClick={() => onRemove(candidateIds!)}
              >
                <IconComponent
                  icon="TRASH"
                  fill="RED_DARK"
                  size={1.25}
                  square={true}
                />
              </ButtonBlock>
            </div>
          )}
        </HorizontalStackBlock>
      </ButtonListBlockItemPadding>
    </ButtonListBlockItem>
  );
};

export default RankingRankItem;
