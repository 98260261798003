import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import {
  StudentType,
  TextileStudent
} from 'api/textile_deals/fetchStudentList';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import ProgressbarComponent from 'components/ProgressbarComponent';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import TextileProfilesList from 'domain/textileStudentList/TextileProfilesList';
import NakedLink from 'elements/NakedLink';
import { intl } from 'i18n';
import { useStudentListQuery } from 'queries/textile_deals/useStudentListQueries';
import { useTextileOrderForManagementQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { useCurrentTextileProfileQuery } from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import isTextileLocked from 'utils/isTextileLocked';

const ManageTextileProfilesScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const studentList = useStudentListQuery(textileOrderId);
  const textileOrder = useTextileOrderForManagementQuery(textileOrderId);
  const currentTextileProfile = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

useEffect(() => {
    accessContext.checkSectionAccess('management');
  }, [accessContext]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink
            onClick={() =>
              props.history.location?.state?.canGoBack
                ? props.history.goBack()
                : props.history.push(textileOrderRoute(props.match, ''))
            }
          />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="textile profiles names" />}
            text={<FormattedMessage id="textile profiles manage info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (studentList.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => studentList.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (currentTextileProfile.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => currentTextileProfile.refetch()}
      />
    );
  }

  if (
    !textileAppState ||
    studentList.isLoading ||
    !studentList.data ||
    textileOrder.isLoading ||
    !textileOrder.data ||
    currentTextileProfile.isLoading ||
    !currentTextileProfile.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const routeByType = (profileId: number, type?: StudentType) => {
    if (!currentTextileProfile.data) {
      return '';
    }
    const student: TextileStudent | undefined = studentList.data.find(
      (profile: TextileStudent) => profileId === profile.id
    );

    if (type === 'textile_name') {
      return '/management/virtual/edit/' + profileId;
    } else {
      if (student && student.id === currentTextileProfile.data.id) {
        return '/my_profile';
      }
      if (student && student.name_verified) {
        return '/management/overview/' + profileId;
      } else {
        return '/management/set/' + profileId;
      }
    }
  };

  const renderOverview = () => {
    const orderData = textileOrder.data;
    const activeStudents = orderData.active_students_amount
      ? orderData.active_students_amount
      : 0;
    const submittedStudents = orderData.print_data_submitted_students_amount
      ? orderData.print_data_submitted_students_amount
      : 0;

    const day = intl.formatDate(orderData.edit_print_data_deadline, {
      day: '2-digit'
    });
    const month = intl.formatDate(orderData.edit_print_data_deadline, {
      month: '2-digit'
    });
    const date = day + '/' + month;

    return renderPage(
      <>
        <ItemStackBlock gap="XXS">
          {orderData.allow_edit_print_data ? (
            <Paragraph>
              <IconComponent icon="CLOCK_CIRCLE" fill="BLACK" />
              {orderData.edit_print_data_deadline ? (
                <>
                  <FormattedMessage id="editing open til" /> {date}
                </>
              ) : (
                <FormattedMessage id="editing state open" />
              )}
            </Paragraph>
          ) : isTextileLocked(
              textileAppState.textile_order.order_state,
              'atLeastOrderStarted'
            ) ? (
            <Paragraph>
              <IconComponent icon="LOCK" fill="BLACK" />
              <FormattedMessage id="editing state locked" />
            </Paragraph>
          ) : (
            <Paragraph>
              <IconComponent icon="PAUSE" fill="BLACK" />
              <FormattedMessage id="editing state paused" />
            </Paragraph>
          )}
          <ProgressbarComponent
            isStatic={true}
            progressStatus={(submittedStudents / activeStudents) * 100}
            color="PRIMARY"
          />
          <TextElement>
            <FormattedMessage
              id="textile students name verified count"
              values={{
                count: submittedStudents,
                number: activeStudents
              }}
            />
          </TextElement>
        </ItemStackBlock>

        <ButtonListBlock>
          <ButtonListBlockItem>
            <NakedLink
              to={textileOrderRoute(props.match, '/management/edit_print_data')}
            >
              <ButtonListBlockBody>
                <FormattedMessage id="textile editing phase" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>

          <ButtonListBlockItem>
            <NakedLink
              to={textileOrderRoute(
                props.match,
                '/management/print_data_message'
              )}
            >
              <ButtonListBlockBody>
                <FormattedMessage id="notification header" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>

          <ButtonListBlockItem>
            <NakedLink
              to={textileOrderRoute(props.match, '/management/textile_invite')}
            >
              <ButtonListBlockBody>
                <FormattedMessage id="textile invite" />
              </ButtonListBlockBody>
            </NakedLink>
          </ButtonListBlockItem>
        </ButtonListBlock>

        <NakedLink
          to={{
            pathname: textileOrderRoute(props.match, '/my_profile'),
            state: {
              canGoBack: true
            }
          }}
        >
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="textile your print setting" />
                </EveryCardHeadline>
                <TextElement>
                  {currentTextileProfile.data.name_verified ? (
                    <>
                      <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                      <FormattedMessage id="texile print confirmed" />
                    </>
                  ) : (
                    <>
                      <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />
                      <FormattedMessage id="texile print need confirmation" />
                    </>
                  )}
                </TextElement>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </NakedLink>

        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          onClick={() =>
            props.history.push(
              textileOrderRoute(props.match, '/management/virtual/add')
            )
          }
        >
          <FormattedMessage id="textile add manuell" />
        </ButtonBlock>

        <Divider />

        <TextileProfilesList
          students={studentList.data}
          inviteUrl={textileAppState.textile_order.invitation_url}
          textileOrderId={textileOrderIdFromMatch(props.match)}
          showSorting={true}
          sortingLink={textileOrderRoute(
            props.match,
            '/management/print_order'
          )}
          sorting={orderData.names_order}
          onProfileClick={(e, profileId, type) =>
            props.history.push(
              textileOrderRoute(props.match, routeByType(profileId, type))
            )
          }
        />
      </>
    );
  };

  return renderOverview();
};

export default ManageTextileProfilesScreen;
