import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import ProfilePreviewComponent from 'components/print/layout-editor/ProfilePreviewComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import SharingComponent from 'components/SharingComponent';
import ChapterPreviewComponent from 'domain/ChapterPreviewComponent';
import BackToContentLink from 'domain/Links/BackToContentLink';
import Headline from 'elements/Headline';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import { ChapterModelType } from 'models/ChapterModel';
import { assert } from 'utils/assert';
import { ROUTE_FACTSHEETS, ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import useForm, { FormType } from 'utils/hooks/useForm';

import Divider from 'components/Divider/Divider';
import EmptyStateComponent from 'components/EmptyStateComponent';
import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { FactsheetsStoreType } from '../../FactsheetsStore';
import { FactsheetsListItem } from './FactsheetsListItem';

interface FactsheetsListProps {
  factsheetsStore: FactsheetsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('factsheetsStore', 'applicationStore')
@observer
class FactsheetsList extends React.Component<
  FactsheetsListProps & HistoryProps
> {
  componentDidMount() {
    const { applicationStore, factsheetsStore, location } = this.props;

    if (applicationStore.isOrganizer) {
      // TODO use history state instead?
      const isTeacherFactsheets = this.props.location.query?.teachers === 'yes';

      if (
        applicationStore.onboardFeature(
          isTeacherFactsheets ? 'teacher_factsheets' : 'factsheets',
          location.pathname + (location.search || '')
        )
      ) {
        return;
      }
    }

    if (!factsheetsStore.isFactsheetsLoading) {
      if (!factsheetsStore.factsheets || isPush(this.props.history)) {
        this.loadFactsheets();
      }
    }
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const chapterId = chapterIdFromMatch(this.props.match);
    const prevChapterId = chapterIdFromMatch(prevProps.match);

    if (chapterId !== prevChapterId) {
      this.loadFactsheets();
    }
  }

  loadFactsheets() {
    const { factsheetsStore } = this.props;
    const chapterId = chapterIdFromMatch(this.props.match);

    if (!chapterId) {
      return;
    }

    factsheetsStore.getFactsheetsByChapter(chapterId, 'any_factsheet');
  }

  chapterUpdated(chapter: ChapterModelType) {
    this.props.factsheetsStore.setChapter(chapter);
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadFactsheets()} />
    );
  }

  renderList() {
    const { factsheetsStore, form } = this.props;

    const { chapter } = factsheetsStore;
    if (!chapter) {
      return null;
    }

    const filter = form.values.filter || undefined;

    const sortByLastName =
      (chapter.settings as FactsheetChapterSettingsModelType)?.profile_order ===
      'last_name';
    const items = factsheetsStore.allFactsheets(filter, sortByLastName);
    // const items = factsheetsStore.allFactsheets('all', filter, sortByLastName);

    const route = ROUTE_FACTSHEETS + '/detail/';

    return this.renderPage(
      <>
        <SearchListInput form={form} placeholderId="Find factsheet" />

        {items.length === 0 && (
          <EmptyStateComponent
            headerId="Student not found"
            textId="student not found info"
            showImage={false}
          />
        )}

        {items.length > 0 && (
          <>
            <LinkBlock
              inline={true}
              slim={true}
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              to={
                ROUTE_FACTSHEETS +
                '/' +
                chapter?.id +
                '/settings?active=order&from=list'
              }
            >
              <IconComponent icon="sort_v" />
              <FormattedMessage id="Sorting order" />
            </LinkBlock>
            <ItemStackBlock gap="S">
              <UppercaseHeading>
                {filter ? (
                  <FormattedMessage
                    id="factsheets filter count"
                    values={{ count: items.length }}
                  />
                ) : (
                  <FormattedMessage
                    id="factsheets count"
                    values={{ count: items.length }}
                  />
                )}
              </UppercaseHeading>

              <ListStackBlock>
                {items.map((profile) => (
                  <Link key={profile.id} to={route + profile.id}>
                    <FactsheetsListItem
                      label={
                        <>
                          {profile.first_name} {profile.last_name}
                        </>
                      }
                      print={profile.print !== undefined ? profile.print : true}
                    />
                  </Link>
                ))}
              </ListStackBlock>
            </ItemStackBlock>
          </>
        )}
      </>
    );
  }

  renderPage(content: ReactNode) {
    const { applicationStore, factsheetsStore } = this.props;
    const { book } = applicationStore;
    const { chapter } = factsheetsStore;

    assert(book);

    const isTeacherFactsheets = chapter?.chapter_type === 'teacher_factsheet';

    return (
      <>
        <TopActionBlock>
          <BackToContentLink />
        </TopActionBlock>

        <PageStackBlock>
          <ChapterPreviewComponent
            chapter={chapter}
            organizersOnly={isTeacherFactsheets}
            defaultTextId={
              isTeacherFactsheets ? 'Teacher factsheets' : 'Factsheets'
            }
            baseRoute={ROUTE_FACTSHEETS}
            onChapterUpdated={(updatedChapter) =>
              this.chapterUpdated(updatedChapter)
            }
            marginBottom="L"
          >
            {chapter?.layout?.layoutDefinition && (
              <FontPreloadComponent chapter={chapter} numPages={2}>
                <SliderBlock>
                  <ProfilePreviewComponent
                    scaled={true}
                    config={chapter.layout.layoutDefinition}
                    chapter={chapter}
                  />
                </SliderBlock>
              </FontPreloadComponent>
            )}
          </ChapterPreviewComponent>

          <Headline.Medium>
            <FormattedMessage id="Factsheets" />
          </Headline.Medium>

          <Paragraph color="TEXT_LIGHT">
            <FormattedMessage
              id={
                isTeacherFactsheets
                  ? 'Manage teacher factsheets'
                  : 'Manage factsheets'
              }
            />
          </Paragraph>

          {isTeacherFactsheets ? (
            chapter?.id && (
              <LinkBlock
                to={ROUTE_FACTSHEETS + '/' + chapter.id + '/teacher/new'}
                background="PRIMARY"
                color="WHITE"
              >
                <FormattedMessage id="Add teacher" />
              </LinkBlock>
            )
          ) : (
            <>
              <Divider />

              <SharingComponent
                label="invite your fellow students"
                buttonOutside={false}
                modalHeader="invite link"
                message="Nuggit invite text"
                buttonColor="WHATSAPP"
                url={book.invitation_url}
              />

              <ListStackBlock>
                <Link to={ROUTE_SETTINGS + '/students'}>
                  <EveryCard>
                    <EveryCardPadding>
                      <EveryCardBody>
                        <EveryCardHeadline>
                          <FormattedMessage id="My class(short)" />
                        </EveryCardHeadline>
                      </EveryCardBody>
                      <IconComponent
                        icon="ARROW_RIGHT"
                        fill="BLACK"
                        size={1.5}
                      />
                    </EveryCardPadding>
                  </EveryCard>
                </Link>

                {factsheetsStore.includesOwnFactsheet && (
                  <Link to={ROUTE_FACTSHEETS}>
                    <EveryCard>
                      <EveryCardPadding>
                        <EveryCardBody>
                          <EveryCardHeadline>
                            <FormattedMessage id="My factsheet" />
                          </EveryCardHeadline>
                        </EveryCardBody>
                        <IconComponent
                          icon="ARROW_RIGHT"
                          fill="BLACK"
                          size={1.5}
                        />
                      </EveryCardPadding>
                    </EveryCard>
                  </Link>
                )}
              </ListStackBlock>
            </>
          )}

          <Divider />

          {content}
        </PageStackBlock>

        {isTeacherFactsheets ? (
          <HelpSubject subject="teachers" />
        ) : (
          <HelpSubject subject="factsheets" />
        )}
      </>
    );
  }

  render() {
    const { applicationStore, factsheetsStore } = this.props;

    if (!applicationStore.isOrganizer) {
      return null;
    }

    if (factsheetsStore.isFactsheetsLoading) {
      return this.renderLoading();
    }

    if (factsheetsStore.isFactsheetsError) {
      return this.renderError();
    }

    // TODO check if still editable here?

    return this.renderList();
  }
}

export default (props: any) => {
  const form = useForm();
  return <FactsheetsList {...props} form={form} />;
};
