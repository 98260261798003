import React from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import TopActionBlock from 'blocks/TopActionBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import SharingComponent from 'components/SharingComponent';
import BackClickLink from 'domain/Links/BackClickLink';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';

const TextileOrderInviteScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="textile student invite" />}
            text={<FormattedMessage id="textile student invite info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (!textileAppState) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  return renderPage(
    <>
      <SharingComponent
        label="Link invite"
        buttonOutside={true}
        modalHeader="invite link"
        message="Nuggit invite text"
        buttonColor="PRIMARY"
        url={textileAppState.textile_order.invitation_url}
      />
    </>
  );
};

export default TextileOrderInviteScreen;
