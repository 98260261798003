import React from 'react';
import { Link } from 'react-router-dom';

import CardBlock from 'blocks/CardBlock';
import MediaBlock from 'blocks/MediaBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import StatusBlock from 'blocks/StatusBlock';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';
import Paragraph from 'components/Paragraph/Paragraph';

const SettingsItem = ({
  children,
  onClick,
  link,
  active,
  statusInfo,
  noArrow
}: {
  children?: React.ReactNode;
  onClick?: (e: any) => void;
  link?: string;
  active?: boolean;
  statusInfo?: string;
  noArrow?: boolean;
}) => {
  const arrow = !!onClick || !!link;

  let statusBlock;
  if (statusInfo && statusInfo.length) {
    statusBlock = (
      <StatusBlock>
        <TextElement  color="GRAY10">
          <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />
          {statusInfo}
        </TextElement>
      </StatusBlock>
    );
  }

  const block = (
    <CardBlock
      as={!!onClick ? 'button' : undefined}
      onClick={onClick}
      active={active}
    >
      <MediaBlock>
        <PaddingBlock arrow={arrow && !noArrow}>
          <Paragraph  color="GRAY10">{children}</Paragraph>
        </PaddingBlock>
      </MediaBlock>
      {statusBlock}
    </CardBlock>
  );

  if (link) {
    return <Link to={link}>{block}</Link>;
  }

  return block;
};

export default SettingsItem;
