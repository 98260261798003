import {
  DeliveryDateModel,
  DeliveryDateModelType
} from 'models/DeliveryDateModel';

const createDeliveryDateModel = (
  data: any
): DeliveryDateModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return DeliveryDateModel.create({
    id: data.id,
    order_deadline: new Date(data.order_deadline),
    payment_deadline: new Date(data.payment_deadline),
    delivery_date: new Date(data.delivery_date),
    available_amount: data.available_amount,
    payment_possible: data.payment_possible
  });
};

export default createDeliveryDateModel;
