/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { Textile } from 'api/textile_deals/fetchTextiles';
import { EveryCard, EveryCardBody, EveryCardHeadline, EveryCardPadding } from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import InputMessage from 'elements/InputMessage';
import NakedLink from 'elements/NakedLink';
import { intl } from 'i18n';
import { useProfilePreorderOverview } from 'queries/textile_deals/useOrderOverviewQueries';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { useCurrentTextileProfileQuery } from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import CartIconComponent from 'screens/preorders/components/CartIconComponent';
import PreOrderInfoBox from 'screens/preorders/components/PreOrderInfoBox';
import TextileImagePreview from 'screens/preorders/components/TextileImagePreview';
import styled from 'styled-components';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import isTextileLocked from 'utils/isTextileLocked';

const TextilePreviewBlock = styled.div`
  width: fit-content;
`;

const OwnPreorderScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const currentTextileProfile = useCurrentTextileProfileQuery(textileAppState?.textile_profile.id, textileOrderId);
  const orderOverview = useProfilePreorderOverview(
    textileAppState?.textile_profile.id,
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (textileAppState?.textile_order.order_state === 'preparing') {
      props.history.replace(textileOrderRoute(props.match, '/preorder'));
    }

    const data = currentTextileProfile.data;
    if (data?.preorder_state === 'submitted') {
      props.history.replace(
        textileOrderRoute(props.match, '/preorder/own/summary')
      );
    }
    accessContext.checkSectionAccess('own_preorder');
  }, [
    accessContext,
    textileAppState?.textile_order.order_state,
    currentTextileProfile.data
  ]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/preorder')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="preorder own" />}
            text={<FormattedMessage id="preorder own info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (currentTextileProfile.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => currentTextileProfile.refetch()}
      />
    );
  }

  if (orderOverview.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => orderOverview.refetch()} />
    );
  }

  if (
    !textileAppState ||
    currentTextileProfile.isLoading ||
    !currentTextileProfile.data ||
    orderOverview.isLoading ||
    !orderOverview.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }
  const renderPreorderClosed = () => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/preorder')} />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Preorder" />} text={''} />
          <InputMessage error={true}>
            <Paragraph color="BLACK">
              <FormattedMessage id="textile preorder closed info" />
            </Paragraph>
          </InputMessage>
        </PageStackBlock>
      </>
    );
  };

  const renderTextilePreview = (textile: Textile) => {
    const layout = textileAppState.textile_order.theme;

    if (!layout) {
      return null;
    }

    if (!textile.possible_price || !textile.end_price) {
      return null;
    }

    const textileVariants = textile.textile_variants || [];

    let formattedPossiblePrice = intl.formatNumber(textile.possible_price, {
      style: 'currency',
      currency: 'EUR'
    });
    formattedPossiblePrice = formattedPossiblePrice.substring(
      0,
      formattedPossiblePrice.length - 2
    );

    let formattedEndPrice = intl.formatNumber(textile.end_price, {
      style: 'currency',
      currency: 'EUR'
    });
    formattedEndPrice = formattedEndPrice.substring(
      0,
      formattedEndPrice.length - 2
    );

    return (
      <TextilePreviewBlock key={textile.id}>
        <ItemStackBlock gap="XXS">
          <TextileImagePreview
            textileVariants={textileVariants}
            layout={layout}
            link={textileOrderRoute(
              props.match,
              '/preorder/own/add/' + textile.id
            )}
          />

          <NakedLink
            to={textileOrderRoute(
              props.match,
              '/preorder/own/add/' + textile.id
            )}
          >
            <Paragraph>
              {textile.name}{' '}
              <IconComponent icon="ARROW_STROKE" fill="BLACK" size={0.8} />
            </Paragraph>
          </NakedLink>
          {isTextileLocked(
            textileAppState.textile_order.order_state,
            'atLeastOrderStarted'
          ) && textile.end_price ? (
            <TextElement>
              <FormattedNumber
                value={textile.end_price}
                style="currency"
                currency="EUR"
              />
            </TextElement>
          ) : (
            <TextElement>
              {formattedPossiblePrice} - {formattedEndPrice} €
            </TextElement>
          )}
        </ItemStackBlock>
      </TextilePreviewBlock>
    );
  };

  const renderOwnPreorder = () => {
    const preorderOverview = orderOverview.data;
    const { textiles, preorders } = preorderOverview;

    if (
      !textileAppState.textile_order.preorder_open &&
      (textileAppState.textile_profile.is_student || textileAppState.textile_profile.is_applicant)
    ) {
      return renderPreorderClosed();
    }


    const day = intl.formatDate(textileOrder.data.preorder_deadline, {
      day: '2-digit'
    });
    const month = intl.formatDate(textileOrder.data.preorder_deadline, {
      month: '2-digit'
    });
    const date = day + '/' + month;

    return renderPage(
      <>
        {!(textileAppState.textile_profile.is_organizer) && (
          <>
            {textileAppState.textile_order.preorder_open ? (
              <Paragraph>
                <IconComponent icon="CLOCK_CIRCLE" fill="BLACK" />
                {textileOrder.data.preorder_deadline ? (
                  <>
                    <FormattedMessage id="preorder open til" /> {date}
                  </>
                ) : (
                  <FormattedMessage id="preorder open time" />
                )}
              </Paragraph>
            ) : (
              <Paragraph>
                <IconComponent icon="PAUSE" fill="BLACK" />
                <FormattedMessage id="editing state paused" />
              </Paragraph>
            )}
          </>
        )}
        {textileOrder.data.organizer_preorder_message && (
          <InputMessage info={true}>
            <Paragraph weight="BOLD" size="L">
              <FormattedMessage id="message from organiser" />
            </Paragraph>

            <Divider color="PURPLE" />

            <Paragraph>{textileOrder.data.organizer_preorder_message}</Paragraph>
          </InputMessage>
        )}

        {currentTextileProfile.data.preorder_notification && (
          <InputMessage info={true}>
            <Paragraph weight="BOLD" size="L">
              <FormattedMessage id="message from organiser" />
            </Paragraph>

            <Divider color="PURPLE" />

            <Paragraph>
              {currentTextileProfile.data.preorder_notification.notification}
            </Paragraph>
            <Paragraph>
              <IconComponent icon="ORGANIZER" fill="PURPLE" />
              {currentTextileProfile.data.preorder_notification.author_name}
            </Paragraph>
          </InputMessage>
        )}

        <NakedLink
          to={textileOrderRoute(props.match, '/preorder/own/cart')}
        >
          <EveryCard>
            <EveryCardPadding noAlignment={true}>
              <ItemStackBlock gap="XXS">
                <EveryCardHeadline>
                  <FormattedMessage id="Basket" />
                </EveryCardHeadline>
                {currentTextileProfile.data.preorder_state === 'open' && (
                  <Paragraph>
                    <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />
                    {preorders && preorders.length === 0 ? (
                      <>
                        <FormattedMessage id="preorder not submitted no items" />
                      </>
                    ) : (
                      <>
                        {preorders && preorders.length}{' '}
                        <FormattedMessage id="preorder not submitted" />
                      </>
                    )}
                  </Paragraph>
                )}
              </ItemStackBlock>

              <EveryCardBody right>
                <CartIconComponent amount={preorders?.length || 0} />
              </EveryCardBody>
            </EveryCardPadding>
            {currentTextileProfile.data.preorder_state === 'rejected' && (
              <InputMessage error={true} noMargin={true}>
                <Paragraph size="S" color="BLACK">
                  <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
                  <FormattedMessage id="preorder denied" />
                </Paragraph>
              </InputMessage>
            )}
          </EveryCard>
        </NakedLink>

        <SliderBlock noScrollBar={true}>
          {textiles?.map((textile: Textile) => {
            return renderTextilePreview(textile);
          })}
        </SliderBlock>

        <PreOrderInfoBox
          link={textileOrderRoute(props.match, '/preorder/how-it-works/')}
        />
      </>
    );
  }

  return renderOwnPreorder();
};

export default OwnPreorderScreen;
