import React, { Component } from 'react';

import TopActionBlock from 'blocks/TopActionBlock';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import BackLink from 'domain/Links/BackLink';
import {
  inject,
  observer
} from 'mobx-react';
import { DeliveryDateModelType } from 'models/DeliveryDateModel';
import { DeliveryDatesStoreType } from 'models/DeliveryDatesStore';
import { FormattedMessage } from 'react-intl';
import DeliveryDate from 'screens/checkout/screens/DeliveryDate';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import { ROUTE_CALCULATOR } from 'utils/constants/routes';

interface Props {
  deliveryDatesStore: DeliveryDatesStoreType;
}

@inject('deliveryDatesStore')
@observer
export default class DeliveryDatesOverviewScreen extends Component<Props> {
  componentDidMount() {
    this.props.deliveryDatesStore.getDeliveryDates('book');
  }

  renderDeliveryDates(deliveryDates?: DeliveryDateModelType[]) {
    if (!deliveryDates) {
      return <LoadingIndicatorComponent />;
    }

    return (
      <ListStackBlock>
        {deliveryDates.map((deliveryDate) => (
          <DeliveryDateListItem key={deliveryDate.id}>
            <DeliveryDate deliveryDate={deliveryDate} />
          </DeliveryDateListItem>
        ))}
      </ListStackBlock>
    );
  }

  render() {
    const { deliveryDatesStore } = this.props;
    const { deliveryDates } = deliveryDatesStore;

    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_CALCULATOR} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Delivery dates overview" />} text={<FormattedMessage id="Delivery dates overview info" />} />

          {this.renderDeliveryDates(deliveryDates)}
        </PageStackBlock>

      </>
    );
  }
}

const DeliveryDateListItem = styled.li`
  border-bottom: 1px solid ${COLORS.GRAY900};
  padding: 20px 0;
  list-style: none;

  &:last-child {
    border-bottom: none;
  }
`;
