import {
  Instance,
  types
} from 'mobx-state-tree';

export const DeliveryDateModel = types.model('DeliveryDateModel', {
  id: types.identifierNumber,
  order_deadline: types.Date,
  payment_deadline: types.Date,
  delivery_date: types.Date,
  available_amount: types.number,
  payment_possible: types.boolean
});

export type DeliveryDateModelType = Instance<typeof DeliveryDateModel>;
