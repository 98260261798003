import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { MainDashboardModelType } from 'models/MainDashboardModel';
import { ProfilesStoreType } from 'models/ProfilesStore';
import COLORS from 'utils/constants/colors';
import RoleChangeItemComponent from './RoleChangeItemComponent';

interface RoleChangeComponentProps {
  dashboard?: MainDashboardModelType;
  applicationStore?: ApplicationStoreType;
  profilesStore?: ProfilesStoreType;
}

interface RoleChangeComponentState {
  displayAll?: boolean;
  confirmRejectId?: number;
  loadingState?: 'loading';
}

const MAX_COLLAPSED = 3;

@inject('applicationStore', 'profilesStore')
@observer
class RoleChangeComponent extends React.Component<
  RoleChangeComponentProps & WrappedComponentProps,
  RoleChangeComponentState
> {
  state: RoleChangeComponentState = {};

  private async accept(requestId: number) {
    const {
      dashboard,
      profilesStore,
      applicationStore,
      intl: { formatMessage }
    } = this.props;

    const request = dashboard?.role_change_requests?.get(requestId.toString());
    if (!request) {
      return;
    }

    const id = request.id;

    this.setState({
      loadingState: 'loading'
    });

    try {
      await profilesStore!.acceptRoleChangeRequest(id);

      dashboard?.removeRoleChangeRequest(id);

      applicationStore!.setFlashMessage(
        formatMessage({ id: 'role change accepted flash' }),
        'success'
      );
    } catch (error: any) {
      applicationStore!.setFlashMessage(
        formatMessage({ id: 'role change accept error flash' }),
        'error'
      );
    }

    this.setState({
      loadingState: undefined
    });
  }

  private async reject(requestId: number, confirmed = false) {
    const {
      dashboard,
      profilesStore,
      applicationStore,
      intl: { formatMessage }
    } = this.props;

    const request = dashboard?.role_change_requests?.get(requestId.toString());
    if (!request) {
      return;
    }

    if (request.request_type === 'applicant_to_student' && !confirmed) {
      this.setState({
        confirmRejectId: request.id
      });
      return;
    }

    const id = request.id;

    this.setState({
      confirmRejectId: undefined,
      loadingState: 'loading'
    });

    try {
      await profilesStore!.rejectRoleChangeRequest(id);

      dashboard?.removeRoleChangeRequest(id);

      applicationStore!.setFlashMessage(
        formatMessage({ id: 'role change rejected flash' }),
        'warning'
      );
    } catch (error: any) {
      applicationStore!.setFlashMessage(
        formatMessage({ id: 'role change reject error flash' }),
        'error'
      );
    }

    this.setState({
      loadingState: undefined
    });
  }

  private maybeRenderConfirm() {
    const { confirmRejectId } = this.state;

    if (!confirmRejectId) {
      return null;
    }

    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="reject applicant confirm header" />}
        text={<FormattedMessage id="reject applicant confirm" />}
        confirmColor="RED"
        confirmText={<FormattedMessage id="Reject" />}
        onConfirm={() => this.reject(confirmRejectId, true)}
        abortText={<FormattedMessage id="Cancel" />}
        onAbort={() => this.setState({ confirmRejectId: undefined })}
      />
    );
  }

  private renderOrganizer() {
    const { dashboard } = this.props;
    const { displayAll, loadingState } = this.state;

    const count = dashboard?.roleChangeRequestsCount;
    if (!count) {
      return null;
    }

    const list = dashboard!.sortedRoleChangeRequests(
      displayAll ? undefined : MAX_COLLAPSED
    );

    return (
      <>
        <ItemStackBlock gap="S">
          <UppercaseHeading>
            <FormattedMessage id="new requests count" values={{ count }} />
          </UppercaseHeading>

          <ListStackBlock>
            {list.map((request) => (
              <RoleChangeItemComponent
                key={request.id}
                request={request}
                onAcceptClick={() => this.accept(request.id)}
                onRejectClick={() => this.reject(request.id)}
              />
            ))}
          </ListStackBlock>
        </ItemStackBlock>

        {displayAll ? (
          count > MAX_COLLAPSED ? (
            <ButtonBlock
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              onClick={() => this.setState({ displayAll: false })}
            >
              <FormattedMessage id="Show less" />
            </ButtonBlock>
          ) : null
        ) : count > list.length ? (
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => this.setState({ displayAll: true })}
          >
            <FormattedMessage id="Show all" />
          </ButtonBlock>
        ) : null}

        {this.maybeRenderConfirm()}
        {loadingState === 'loading' && <LoadingOverlayComponent />}
      </>
    );
  }

  private renderStudent() {
    const { dashboard } = this.props;
    const { currentUserId } = this.props.applicationStore!;

    if (
      !currentUserId ||
      !dashboard?.hasRequestedOrganizerRole(currentUserId)
    ) {
      return null;
    }

    return (
      <PaddingBlock style={{ backgroundColor: COLORS.PRIMARY_LIGHT }}>
        <Headline.Small>
          <FormattedMessage id="role change requested header" />
        </Headline.Small>
        <Paragraph>
          <FormattedMessage id="role change requested" />
        </Paragraph>
      </PaddingBlock>
    );
  }

  render() {
    const { isOrganizer } = this.props.applicationStore!;

    if (isOrganizer) {
      return this.renderOrganizer();
    }

    return this.renderStudent();
  }
}

export default injectIntl(RoleChangeComponent);
