import { Instance, types } from 'mobx-state-tree';
import { createMapWithTransform } from 'utils/create-map';
import { sortByField } from 'utils/sort-functions';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import stringOrUndefined from 'utils/store/stringOrUndefined';

import BannerModel, { createBannerModel } from './BannerModel';
import ChapterModel, { createChapterModel } from './ChapterModel';
import {
  createNuggitSponsorsModel,
  NuggitSponsorsModel
} from './DiscountDashboardModel';
import {
  createDiscountModel, DiscountIdentifierEnumType, DiscountModel, DiscountModelType
} from './DiscountModel';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';
import RoleChangeRequestModel, {
  createRoleChangeRequestModel,
  RoleChangeRequestModelType
} from './RoleChangeRequestModel';

const MainDashboardModel = types
  .model('MainDashboardModel', {
    // common fields
    organizers: types.maybe(types.map(ProfileAsAuthorModel)),

    affiliate_token: types.maybe(types.string),
    nuggit_sponsors: types.maybe(NuggitSponsorsModel),
    discounts: types.maybe(types.map(DiscountModel)),
    banner: types.maybe(BannerModel),

    has_own_content: types.maybe(types.boolean),
    has_any_chapters: types.maybe(types.boolean),

    cover: types.maybe(ChapterModel),

    role_change_requests: types.maybe(types.map(RoleChangeRequestModel)),

    // for organizers
    requests_count: types.maybe(types.number),
    profiles_count: types.maybe(types.number),
    motto_votes_count: types.maybe(types.number),

    checkout_present: types.maybe(types.boolean),
    checkout_is_author: types.maybe(types.boolean),
    parental_approval_uploaded: types.maybe(types.boolean),
    parental_approval_checked: types.maybe(types.boolean),
    payment_received: types.maybe(types.boolean),

    // for students
    has_motto_vote: types.maybe(types.boolean)
  })
  .actions((self) => {
    const removeRoleChangeRequest = (requestId: number) => {
      self.role_change_requests?.delete(requestId.toString());
    };

    return {
      removeRoleChangeRequest
    };
  })
  .views((self) => {
    return {
      sortedRoleChangeRequests(limit?: number): RoleChangeRequestModelType[] {
        if (!self.role_change_requests?.size) {
          return [];
        }

        const list: RoleChangeRequestModelType[] = [];

        for (const request of self.role_change_requests.values()) {
          list.push(request);
        }

        list.sort(sortByField('created_at'));

        if (limit) {
          return list.slice(0, limit);
        }

        return list;
      },
      get roleChangeRequestsCount(): number {
        return self.role_change_requests?.size || 0;
      },

      hasRequestedOrganizerRole(currentUserId: number): boolean {
        if (!self.role_change_requests?.size) {
          return false;
        }

        for (const request of self.role_change_requests.values()) {
          if (
            request.request_type === 'student_to_organizer' &&
            request.profile?.id === currentUserId
          ) {
            return true;
          }
        }

        return false;
      },

      firstDiscountByIdentifier(
        identifier: DiscountIdentifierEnumType
      ): DiscountModelType | undefined {
        if (!self.discounts?.size) {
          return undefined;
        }

        for (const discount of self.discounts.values()) {
          if (discount.identifier === identifier) {
            return discount;
          }
        }

        return undefined;
      },
    };
  });

export const createMainDashboardModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return MainDashboardModel.create({
    organizers: !data.organizers
      ? undefined
      : createMapWithTransform(data.organizers, createProfileAsAuthorModel),

    affiliate_token: stringOrUndefined(data.affiliate_token),
    nuggit_sponsors: createNuggitSponsorsModel(data.nuggit_sponsors),
    discounts: createMapWithTransform(
      data.discounts,
      createDiscountModel,
      'identifier'
    ),
    banner: createBannerModel(data.banner),

    has_own_content: booleanOrUndefined(data.has_own_content),
    has_any_chapters: booleanOrUndefined(data.has_any_chapters),

    cover: createChapterModel(data.cover),

    role_change_requests: data.role_change_requests
      ? createMapWithTransform(
          data.role_change_requests,
          createRoleChangeRequestModel
        )
      : undefined,

    // for organizers
    requests_count: numberOrUndefined(data.requests_count),
    profiles_count: numberOrUndefined(data.profiles_count),
    motto_votes_count: numberOrUndefined(data.motto_votes_count),

    checkout_present: booleanOrUndefined(data.checkout_present),
    checkout_is_author: booleanOrUndefined(data.checkout_is_author),
    parental_approval_uploaded: booleanOrUndefined(
      data.parental_approval_uploaded
    ),
    parental_approval_checked: booleanOrUndefined(
      data.parental_approval_checked
    ),
    payment_received: booleanOrUndefined(data.payment_received),

    // for students
    has_motto_vote: booleanOrUndefined(data.has_motto_vote)
  });
};

export type MainDashboardModelType = Instance<typeof MainDashboardModel>;
export default MainDashboardModel;
