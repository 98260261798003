import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import ICONS from 'utils/constants/icons';

interface PaddingBlockProps {
  arrow?: boolean;
  centeredArrow?: boolean;
  background?: keyof typeof COLORS;
}

const fillColor = COLORS.GRAY400.replace('#', '%23');

const PaddingBlock = styled.div<PaddingBlockProps>`
  flex-direction: column;
  display: flex;
  padding: 1rem 1.25rem 1.0625rem 1rem;
  height: 100%;
  ${({ background }) =>
    background && `background-color: ${COLORS[background]};`}
  ${(props) =>
    props.arrow &&
    `
    position: relative;
    padding-right: 2.375em;

    &::after {
      content: "";
      position: absolute;
      width: .9375em;
      height: .9375em;
      background-image: url('data:image/svg+xml;utf8,
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path fill="${fillColor}" fill-rule="evenodd" d="${ICONS.ARROW_RIGHT}"/>
        </svg>');
      background-size: cover;
      top: 1.1875em;
      right: 0.875em;
    }
  `};
  ${(props) =>
    props.centeredArrow &&
    `
    position: relative;
    padding-right: 2.375em;

    &::after {
      content: "";
      position: absolute;
      width: .9375em;
      height: .9375em;
      background-image: url('data:image/svg+xml;utf8,
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path fill="${fillColor}" fill-rule="evenodd" d="${ICONS.ARROW_RIGHT}"/>
        </svg>');
      background-size: cover;
      top: 50%;
      right: 0.875em;
      transform: translateY(-50%);
    }
  `};
`;

export default PaddingBlock;
