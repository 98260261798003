import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import CancelLink from 'domain/Links/CancelLink';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { assert } from 'utils/assert';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';

interface MyNameScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
  form: FormType;
}

@inject('applicationStore', 'profilesStore')
@observer
class MyNameScreen extends React.Component<MyNameScreenProps & HistoryProps> {
  async componentDidMount() {
    const { applicationStore, form } = this.props;
    const { currentUser } = applicationStore;
    assert(currentUser);

    form.setField('first_name', currentUser.first_name || '');
    form.setField('last_name', currentUser.last_name || '');
  }

  async handleSubmit() {
    const { applicationStore, form, profilesStore } = this.props;
    if (!applicationStore.currentUser) {
      return;
    }

    form.resetErrors();

    try {
      await profilesStore.updateName(
        applicationStore.currentUser.id,
        form.values.first_name || '',
        form.values.last_name || ''
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by ProfilesStore internally
      return;
    }

    applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'name updated' })
    );
    this.props.history.push(ROUTE_SETTINGS + '/account');
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_SETTINGS + '/account'} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  renderView() {
    const { applicationStore, profilesStore } = this.props;
    const { currentUser } = applicationStore;

    if (!currentUser) {
      // satisfy TS
      return this.renderPage(null);
    }

    const isUpdateError =
      profilesStore.itemLoadingState === 'update_error' ? true : false;

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Edit name" />} text={<><IconComponent icon="USER" /> {currentUser.name}</>} />

        {isUpdateError && <GenericErrorComponent />}

        <FormStackBlock>
          <TextInputComponent
            name="first_name"
            label={intl.formatMessage({ id: 'First name' })}
            {...this.props.form.bindInput('first_name')}
            autoFocus={true}
          />

          <TextInputComponent
            name="last_name"
            label={intl.formatMessage({ id: 'Last name' })}
            {...this.props.form.bindInput('last_name')}
          />
        </FormStackBlock>

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  render() {
    const { applicationStore, profilesStore } = this.props;

    if (applicationStore.isRefreshing) {
      return this.renderLoading();
    }

    if (profilesStore.itemLoadingState === 'error') {
      return this.renderError();
    }

    return this.renderView();
  }
}

export default (props: any) => {
  const form = useForm();
  return <MyNameScreen {...props} form={form} />;
};
