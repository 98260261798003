import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import OverlayBlock from 'blocks/OverlayBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { ProfilesStoreType } from 'models/ProfilesStore';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';

interface PublicAddGroupModalComponentProps {
  onClose?: () => void;
}

interface AddGroupModalComponentProps
  extends PublicAddGroupModalComponentProps {
  profilesStore: ProfilesStoreType;
  form: FormType;
}

@inject('profilesStore')
@observer
class AddGroupModalComponent extends React.Component<
AddGroupModalComponentProps
> {
  submit() {
    const { form, profilesStore, onClose } = this.props;

    try {
      profilesStore.createGroup({
        name: form.values.name || ''
      });

      onClose && onClose();
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  render() {
    const { form, onClose, profilesStore } = this.props;
    const loading = profilesStore.isItemLoading;

    return (
      <>
        <OverlayBlock>
          {loading && <LoadingOverlayComponent />}
          <OverlayBlock.InnerElement>
            <PaddingBlock>
              <PageStackBlock>
                <PageHeader headline={<FormattedMessage id="Create group" />} text={<FormattedMessage id="create group modal" />} />
                <TextInputComponent
                  autoFocus={true}
                  name="name"
                  {...form.bindInput('name')}
                />

                <ItemStackBlock gap="XS">
                  <ButtonBlock
                    background="PRIMARY"
                    onClick={() => this.submit()}
                  >
                    <FormattedMessage id="OK" />
                  </ButtonBlock>
                  <ButtonBlock
                    background="SECONDARY_DARK"
                    onClick={() => onClose && onClose()}
                  >
                    <FormattedMessage id="Cancel" />
                  </ButtonBlock>
                </ItemStackBlock>
              </PageStackBlock>
            </PaddingBlock>
          </OverlayBlock.InnerElement>
        </OverlayBlock>
      </>
    );
  }
}

export default (props: PublicAddGroupModalComponentProps) => {
  const form = useForm();
  // @ts-ignore
  return <AddGroupModalComponent {...props} form={form} />;
};
