import config from "config";

export const textileThemesImagePath = (
  key: string | number,
  image?: string
): string => {
  return `${config.layout.textileBaseUrl}/themes/${key}/assets/${image}`
};

export default textileThemesImagePath;
