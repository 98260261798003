import { IAnyModelType, IMSTMap } from 'mobx-state-tree';

import { createMapWithTransform } from './create-map';
import { sortByField } from './sort-functions';

interface Sortable {
  id: number;
  sorting?: number;
}

/**
 * Creates a map of item ids and sorting positions
 *
 * @param items The MST models
 */
export const sortPositions = (items: Sortable[]) =>
  items.reduce<{ [id: number]: number }>(
    (result, item) => {
      if (item.sorting !== undefined) {
        result[item.id] = item.sorting!;
      }
      return result;
    },
    {}
  );

/**
 * Based on a MobX State Tree Map, move an item in the list
 *
 * @param list MST Map of items
 * @param oldIndex The old index of the item
 * @param newIndex The new index of the item
 * @param padding Padding to add to the index
 *
 * @returns The new sorted items array and an object that can be assigned to the MST Map.
 */
export const moveItem = <Model extends IAnyModelType>(
  list: IMSTMap<Model> | Model[],
  oldIndex: number,
  newIndex: number,
  padding: number = 0
) => {
  const sortedItems = Array.isArray(list)
    ? list.slice().sort(sortByField('sorting'))
    : Array.from(list.values()).sort(sortByField('sorting'));

  const [movedQuestion] = sortedItems.splice(oldIndex, 1);
  sortedItems.splice(newIndex, 0, movedQuestion);

  // Set sorting to the item’s index
  sortedItems.forEach((item, index) => {
    // @ts-ignore
    item.sorting = index + padding;
  });

  const newMap = createMapWithTransform(sortedItems);
  return { items: sortedItems, map: newMap };
};
