import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import CancelLink from 'domain/Links/CancelLink';
import PhotoEditScreen from 'domain/PhotoEditScreen/PhotoEditScreen';
import Headline from 'elements/Headline';
import { CoverChapterSettingsModelType } from 'models/chapter-settings/CoverChapterSettingsModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ROUTE_BACK_COVER, ROUTE_LAYOUT } from 'utils/constants/routes';
import { getCoverBackCreativePhoto } from 'utils/getCoverBackCreativePhoto';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';

interface BackSettingsScreenBaseProps extends HistoryProps {
  what: 'back_design' | 'names_order' | 'back_header' | 'creative';
}

interface BackSettingsScreenProps extends BackSettingsScreenBaseProps {
  chaptersStore: ChaptersStoreType;
  form: FormType;
}

const MAX_LENGTH = {
  back_header: 30
};

@inject('chaptersStore')
@observer
class BackSettingsScreen extends React.Component<BackSettingsScreenProps> {
  componentDidMount() {
    this.loadCover();
  }

  async loadCover() {
    const { chaptersStore, form } = this.props;

    form.reset();

    const cover = await chaptersStore.getCoverChapter();

    if (cover && !cover.layout_key) {
      this.props.history.replace(ROUTE_LAYOUT + '/theme');
      return;
    }

    const settings = cover?.settings as
      | CoverChapterSettingsModelType
      | undefined;

    form.setField('back_design', settings?.back_design || 'neutral');
    form.setField('names_order', settings?.names_order || 'first_name');
    form.setField('back_header', settings?.back_header || '');
  }

  async save() {
    const { chaptersStore, form, what } = this.props;

    const cover = chaptersStore.itemIfCover;
    if (!cover) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    try {
      const value = form.values[what];
      await chaptersStore.updateChapterSettings(cover.id, {
        [what]: value
      });

      // form.setLoading(false);
      this.props.history.push(ROUTE_BACK_COVER);
    } catch (error: any) {
      if (!handleFormError(form, error)) {
        form.setError('base', true);
      }

      form.setLoading(false);
    }
  }

  handlePhotoSave = () => {
    this.props.history.push(ROUTE_BACK_COVER);
  };

  renderHeader() {
    switch (this.props.what) {
      case 'back_design':
        return <FormattedMessage id="Back design" />;

      case 'names_order':
        return <FormattedMessage id="Sorting order" />;

      case 'back_header':
        return <FormattedMessage id="Headline" />;

      case 'creative':
        return <FormattedMessage id="back design creative" />;

      default:
        return null;
    }
  }

  renderSaveButton() {
    const { form } = this.props;
    return (
      <>
        {form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => this.save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  }

  renderDesign() {
    const { form } = this.props;

    return this.renderPage(
      <>
        <FormStackBlock>
          <RadioButtonComponent
            name="back_design"
            value="neutral"
            label={<FormattedMessage id="back design neutral" />}
            {...form.bindRadioButton('back_design', 'neutral')}
          />

          <RadioButtonComponent
            name="back_design"
            value="names"
            label={<FormattedMessage id="back design names" />}
            {...form.bindRadioButton('back_design', 'names')}
          />

          <RadioButtonComponent
            name="back_design"
            value="creative"
            label={<FormattedMessage id="back design creative" />}
            {...form.bindRadioButton('back_design', 'creative')}
          />

          <RadioButtonComponent
            name="back_design"
            value="sponsor"
            label={<FormattedMessage id="back design sponsor" />}
            {...form.bindRadioButton('back_design', 'sponsor')}
          />
        </FormStackBlock>

        {this.renderSaveButton()}
      </>
    );
  }

  renderHeaderForm() {
    const { form } = this.props;

    return this.renderPage(
      <>
        <FormStackBlock>
          <TextInputComponent
            name="back_header"
            label={<FormattedMessage id="Headline" />}
            {...form.bindInput('back_header')}
            autoFocus={true}
            maxLength={MAX_LENGTH.back_header}
          />

          <CharCounterComponent
            max={MAX_LENGTH.back_header}
            value={form.values.back_header}
          />

        </FormStackBlock>
        {this.renderSaveButton()}
      </>
    );
  }

  renderSorting() {
    const { form } = this.props;

    return this.renderPage(
      <>
        <FormStackBlock>
          <RadioButtonComponent
            name="names_order"
            value="first_name"
            label={<FormattedMessage id="back sorting first_name" />}
            {...form.bindRadioButton('names_order', 'first_name')}
          />

          <RadioButtonComponent
            name="names_order"
            value="last_name"
            label={<FormattedMessage id="back sorting last_name" />}
            {...form.bindRadioButton('names_order', 'last_name')}
          />
        </FormStackBlock>

        {this.renderSaveButton()}
      </>
    );
  }

  renderPhotoEditScreen() {
    const {
      item: coverChapter,
      isItemLoading,
      isItemError
    } = this.props.chaptersStore;
    if (isItemLoading) {
      return <LoadingIndicatorComponent />;
    }
    if (isItemError) {
      return <GenericErrorComponent />;
    }
    if (!coverChapter) {
      return null;
    }

    const coverBackCreativePhoto = getCoverBackCreativePhoto(coverChapter);

    return (
      <PhotoEditScreen
        photoId={coverBackCreativePhoto?.id}
        photoType="cover_back_creative"
        parentId={coverChapter.id}
        frameWidth={210}
        frameHeight={297}
        allowDelete={true}
        allowEditCaption={false}
        fitToFrameEnabled={true}
        isImageRounded={false}
        isEditorEnabled={true}
        backRoute={ROUTE_BACK_COVER}
        handleSave={this.handlePhotoSave}
      />
    );
  }

  renderPage(content: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_BACK_COVER} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>{this.renderHeader()}</Headline.Large>
          {content}
        </PageStackBlock>
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadCover()} />
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const { chaptersStore, form, what } = this.props;

    if (chaptersStore.isItemLoading || form.loading) {
      return this.renderLoading();
    }

    if (chaptersStore.isItemLoadError) {
      return this.renderError();
    }

    switch (what) {
      case 'back_design':
        return this.renderDesign();

      case 'back_header':
        return this.renderHeaderForm();

      case 'names_order':
        return this.renderSorting();

      case 'creative':
        return this.renderPhotoEditScreen();

      default:
        return null;
    }
  }
}

export default (props: BackSettingsScreenBaseProps) => {
  const form = useForm();
  // @ts-ignore
  return <BackSettingsScreen {...props} form={form} />;
};
