import styled from 'styled-components';
import SPACES from 'utils/constants/spaces';

interface TwoColumnProps {
  gap?: keyof typeof SPACES;
  gapVertical?: keyof typeof SPACES;
}

const TwoColumnBlock = styled.div<TwoColumnProps>`
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    /* If this causes trouble, maybe put it behind a property */
    overflow: hidden;
    gap: ${(props) =>
        props.gapVertical
          ? SPACES[props.gapVertical]
          : props.gap
          ? SPACES[props.gap]
          : SPACES.S}
      ${(props) => (props.gap ? SPACES[props.gap] : SPACES.S)};

    > * {
      margin: 0;
      width: 100%;
    }
  }
`;

export default TwoColumnBlock;
