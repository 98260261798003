import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ROUTE_DASHBOARD } from 'utils/constants/routes';

import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';

export const BackToDashboardLink = () => (
  <Link to={ROUTE_DASHBOARD}>
    <TextElement  color="GRAY10">
      <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
      <FormattedMessage id="Back to dashboard" />
    </TextElement>
  </Link>
);

export default BackToDashboardLink;
