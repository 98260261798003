import {
  TextileTextBox
} from 'components/print/templates/TextileBackTemplateComponent';
import shrinkTeacherNames from 'utils/chunker/TeachersNameSchrinker';

import { sliceNames } from './textileSliceNames';
import { ContentSettings } from './textileTextFontSize';

export const calculateTeacherRowsAndFontSize = (fontSizeNames: number, teacherNames: string[] | undefined, teacherSettings: ContentSettings) => {
  if (!teacherNames) {
    return;
  }

  let rowCount: number = 0;
  let nameRow: string[][] = [teacherNames];

  let fitted = false;
  while (!fitted) {
    const [rowFontSize, rowFailed] = fitTeachers(
      nameRow,
      rowCount,
      fontSizeNames,
      teacherSettings
    );

    if (rowFailed) {
      if (rowCount !== 5) {
        rowCount++;
        nameRow = sliceNames(teacherNames, rowCount);
      } else {
        fitted = true;
      }
    } else {
      fitted = true;
      return {
        calculatedNamesFontSize: rowFontSize,
        calculatedRows: rowCount
      };
    }
  }

  return null;
}

const fitTeachers = (nameRows: string[][], rowCount: number, fontSize: number, settings: ContentSettings): [number, boolean] => {
  const softCapFontSize = fontSize * 0.45;

  const rowTextBox: TextileTextBox = {
    boundary: [settings.contentWidth, settings.contentHeight],
    minFontSize: 2,
    maxFontSize: fontSize,
    step: 1
  };

  const style = "display: flex; justify-content: center; align-items: center; white-space: nowrap;";

  let [rowFontSize, rowFailed] = [rowTextBox.maxFontSize, true];

  for (const nameRow of nameRows) {
    [rowFontSize, rowFailed] = fitFontSizeTeacher(
      nameRow,
      rowTextBox,
      style,
      rowCount
    );

    if (rowFailed) {
      return [rowFontSize, rowFailed];
    }

    if (!rowFailed) {
      rowTextBox.maxFontSize = rowFontSize;
    }

    if (rowCount === 5 && rowFontSize < softCapFontSize) {
      // Do something softcap reached
    }
  }

  return [rowFontSize, rowFailed];
}

const fitFontSizeTeacher = (
  text: string[] | undefined,
  TextileTextBox: TextileTextBox,
  style: string,
  rowCount: number
): [number, boolean] => {
  if (!text || !rowCount) {
    return [TextileTextBox.maxFontSize, true];
  }

  return shrinkTeacherNames(
    text,
    TextileTextBox.maxFontSize,
    TextileTextBox.minFontSize,
    style,
    TextileTextBox.boundary,
    1,
    rowCount
  );
}