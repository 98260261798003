import config from "config";

export const validYears = (): number[] => {
  const years: number[] = [];
  const now = new Date();

  const currentYear = now.getFullYear();
  let addYears = config.years;

  if (now.getMonth() < config.lastMonthForCurrentYear) {
    years.push(currentYear);
  } else {
    // don't add current year after october but add another year already
    addYears++;
  }

  for (let i = 1; i < addYears; i++) {
    years.push(currentYear + i);
  }

  return years;
}