import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextileThemeLayout } from 'api/textile_deals/fetchTextileThemes';
import { TextileVariant } from 'api/textile_deals/fetchTextileVariants';
import { Textile } from 'api/textile_deals/fetchTextiles';
import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import InputMessage from 'elements/InputMessage';
import { intl } from 'i18n';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { useCreateTexitlePreorderQuery } from 'queries/textile_deals/useTextilePreorderQueries';
import { useTextileQuery } from 'queries/textile_deals/useTextileQueries';
import { FormattedMessage } from 'react-intl';
import PreOrderInfoBox from 'screens/preorders/components/PreOrderInfoBox';
import TextileInfo from 'screens/preorders/components/TextileInfo';
import TextilePriceComponent from 'screens/preorders/components/TextilePriceComponent';
import TextileProductImageComponent from 'screens/preorders/components/TextileProductImageComponent';
import { MiniPreview } from 'screens/textile_design_settings/screens/colors/MiniPreview';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';
import isTextileLocked from 'utils/isTextileLocked';
import { ProductImage, productImage } from 'utils/product-image';

interface FormProps {
  form: FormType;
}

const PreorderVirtualAddScreen: React.FC<HistoryProps & FormProps> = (
  props
) => {
  const [errors, setErrors] = useState<string[]>([]);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const textile = useTextileQuery(id, textileOrderId);
  const createTextilePreorder = useCreateTexitlePreorderQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (textileAppState?.textile_order.order_state === 'preparing') {
      props.history.replace(textileOrderRoute(props.match, '/preorder'));
    }
    accessContext.checkSectionAccess('preorder');

    const data = textile.data;
    if (data && data.textile_variants && data.textile_variants.length !== 0) {
      props.form.setField('textile_variant', data.textile_variants[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileAppState?.textile_order.order_state, textile.data]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/preorder/virtual')} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (textile.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textile.refetch()} />
    );
  }

  if (
    props.form.loading ||
    !textileAppState ||
    textile.isLoading ||
    !textile.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const addOrderManuell = () => {
    const { form, match, history } = props;

    const currentTextileProfile = textileAppState.textile_profile;

    if (!textileOrderId || !currentTextileProfile) {
      return;
    }

    const currentErrors = [];
    if (!form.values.textile_variant) {
      currentErrors.push(
        intl.formatMessage({ id: 'preorder add missing variant' })
      );
      setErrors(currentErrors);
      form.setLoading(true);
    }

    if (!form.values.size) {
      currentErrors.push(
        intl.formatMessage({ id: 'preorder add missing size' })
      );
      setErrors(currentErrors);
    }

    if (!form.values.name) {
      currentErrors.push(
        intl.formatMessage({ id: 'preorder add missing name' })
      );
      setErrors(currentErrors);
    }

    if (form.values.textile_variant && form.values.size && form.values.name) {
      const patch = {
        textile_size_id: form.values.size,
        textile_variant_id: form.values.textile_variant.id,
        name: form.values.name,
        author_id: currentTextileProfile.id,
        textile_name: textile.data.name,
        size: form.values.size_name
      };
      form.setLoading(true);
      createTextilePreorder.mutate(
        { data: patch, textileOrderId },
        {
          onSettled: () => {
            form.setLoading(false);
            history.push(textileOrderRoute(match, '/preorder/virtual'));
          }
        }
      );
    }
  };

  const renderTextileVariant = (
    layout: TextileThemeLayout,
    textileVariant?: TextileVariant
  ) => {
    if (!textileVariant) {
      return null;
    }

    const detailImageOne: ProductImage = productImage(
      textileVariant,
      'detail_1',
      layout
    );
    const detailImageTwo: ProductImage = productImage(
      textileVariant,
      'detail_2',
      layout
    );
    const detailImageThree: ProductImage = productImage(
      textileVariant,
      'detail_3',
      layout
    );

    return (
      <>
        <SliderBlock scrollBarColor="GRAY560" noScrollBarBackground={true}>
          <TextileProductImageComponent
            productImage={detailImageOne}
            height="360px"
            width="283px"
          />
          <TextileProductImageComponent
            productImage={detailImageTwo}
            height="360px"
            width="283px"
          />
          <TextileProductImageComponent
            productImage={detailImageThree}
            height="360px"
            width="283px"
          />
        </SliderBlock>
      </>
    );
  };

  const renderSizesByTextileVariant = (textileVariant?: TextileVariant) => {
    const { form } = props;

    if (!textileVariant) {
      return null;
    }

    return (
      <SliderBlock noScrollBar={true} gap="XXS">
        {textileVariant.textile_sizes?.map((size) => {
          return (
            <EveryCard
              key={size.id}
              extraSlim={true}
              onClick={() => {
                form.setField('size', size.id);
                form.setField('size_name', size.title);
              }}
              selected={form.values.size === size.id}
            >
              <EveryCardPadding center={true} extraSlim={true}>
                <EveryCardHeadline>{size.title}</EveryCardHeadline>
              </EveryCardPadding>
            </EveryCard>
          );
        })}
      </SliderBlock>
    );
  };

  const renderPriceTextileVariant = (textile: Textile) => {
    return (
      <TextilePriceComponent
        endPrice={textile.end_price}
        possiblePrice={textile.possible_price}
        range={
          !isTextileLocked(
            textileAppState?.textile_order.order_state,
            'atLeastOrderStarted'
          )
        }
        link={textileOrderRoute(props.match, '/discounts')}
        piece={true}
      />
    );
  };

  const renderAddButton = () => {
    return (
      <ButtonBlock background="PRIMARY" onClick={() => addOrderManuell()}>
        <FormattedMessage id="preorder add manuell order" />
      </ButtonBlock>
    );
  };

  const renderFormErrors = () => {
    if (errors.length === 0) {
      return null;
    }

    return (
      <InputMessage error={true}>
        {errors.map((error) => {
          return (
            <Paragraph color="RED_RGB" key={error}>
              {' '}
              {error}{' '}
            </Paragraph>
          );
        })}
      </InputMessage>
    );
  };

  const renderAdd = () => {
    const { form } = props;
    const layout = textileAppState.textile_order.theme;

    if (!layout || !form.values.textile_variant) {
      return null;
    }

    return renderPage(
      <>
        {renderTextileVariant(layout, form.values.textile_variant)}
        <PageHeader headline={textile.data.name} text={''} />
        <FormStackBlock>
          <ItemStackBlock gap="XS">
            <Paragraph weight="BOLD">
              <FormattedMessage id="Color" />:
              <TextElement size="M">
                {' '}
                {form.values.textile_variant?.textile_color?.display_name}
              </TextElement>
            </Paragraph>

            <HorizontalStackBlock gap="XXS">
              {textile.data.textile_variants?.map((variant: TextileVariant) => {
                if (
                  variant.textile_color &&
                  form.values.textile_variant &&
                  variant.textile_color.color_hex
                ) {
                  return (
                    <MiniPreview
                      colorCode={variant.textile_color.color_hex}
                      key={variant.textile_color.id}
                      selected={
                        variant.textile_color.name ===
                        form.values.textile_variant.textile_color.name
                      }
                      onClick={() => form.setField('textile_variant', variant)}
                      big={true}
                    ></MiniPreview>
                  );
                }
                return null;
              })}
            </HorizontalStackBlock>
          </ItemStackBlock>

          <ItemStackBlock gap="XS">
            <HorizontalStackBlock justified={true}>
              <Paragraph weight="BOLD">
                <FormattedMessage id="Size" />:
              </Paragraph>

              <TextElement size="S" color="TEXT_LIGHT">
                <FormattedMessage id="my size?" />
              </TextElement>
            </HorizontalStackBlock>

            {renderSizesByTextileVariant(form.values.textile_variant)}
          </ItemStackBlock>

          <TextInputComponent
            name="name"
            label={<FormattedMessage id="Name" />}
            {...form.bindInput('name')}
          />
        </FormStackBlock>
        {renderPriceTextileVariant(textile.data)}

        {renderFormErrors()}

        {renderAddButton()}

        <Divider />

        <PreOrderInfoBox
          link={textileOrderRoute(props.match, '/preorder/how-it-works')}
        />

        <TextileInfo textile={textile.data} />
      </>
    );
  };

  return renderAdd();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <PreorderVirtualAddScreen {...props} form={form} />;
};
