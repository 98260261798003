import React from 'react';

import { CreatePhotoBody, UpdatePhotoBody } from 'api/NuggitApi';
import CardBlock from 'blocks/CardBlock';
import ImageBlock from 'blocks/ImageBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import ImageEditorComponent from 'components/ImageEditorComponent/ImageEditorComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import FileUploadComponent from 'components/Inputs/FileUploadComponent';
import { ACCEPT_IMAGES_AND_PDFS } from 'components/Inputs/FileUploadInput';
import MultiFileUploadComponent from 'components/Inputs/MultiFileUploadComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import DeleteButton from 'domain/Buttons/DeleteButton';
import LockedLabel from 'domain/Label/LockedLabel';
import BackLink from 'domain/Links/BackLink';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ImageConfig, PhotoModelType, PhotoType } from 'models/PhotoModel';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import { PhotosStoreType } from 'screens/photos/PhotosStore';
import { assert } from 'utils/assert';
import { HistoryProps, isPush } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

const ONBOARD_TYPES: PhotoType[] = [
  'text',
  'slot1',
  'slot2',
  'slot3',
  'creative_page',
  'cover_front',
  'cover_back',
  'cover_front_creative',
  'cover_back_creative'
];

interface PublicPhotoEditScreenProps extends HistoryProps {
  photoId?: number;
  photoType: PhotoType;
  parentId: number;
  frameWidth: number;
  frameHeight: number;
  allowDelete: boolean;
  allowEditCaption: boolean;
  fitToFrameEnabled: boolean;
  isImageRounded: boolean;
  isEditorEnabled: boolean;
  backRoute?: string;
  backIsCancel?: boolean;
  handleSave?: () => void;
  multiple?: boolean;
}

interface PhotoEditScreenProps extends PublicPhotoEditScreenProps {
  photosStore: PhotosStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('photosStore', 'applicationStore')
@observer
class PhotoEditScreen extends React.Component<
  PhotoEditScreenProps & HistoryProps
> {
  fillForm(item: PhotoModelType) {
    const { form } = this.props;

    form.setField(
      'photo',
      item.photo
        ? { old: { url: item.photo.shrinked, preview: item.photo.preview } }
        : null
    );

    form.setField('image_config', {
      // this is a hack to make Cropper recalculate aspect for new images
      // aspect: Aspect.LANDSCAPE,
    });
  }

  async loadPhoto(id: number) {
    const { photosStore, form, photoType } = this.props;
    const textileOrderId = textileOrderIdFromMatch(this.props.match);

    if (photosStore.isPhotoItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const checkIfAlreadyInStore = !isPush(this.props.history);
    if (
      checkIfAlreadyInStore &&
      photosStore.photoItem &&
      photosStore.photoItem.id === id
    ) {
      this.fillForm(photosStore.photoItem);
      return;
    }
    let photo;
    if (photoType === 'textile_back_creative' && textileOrderId) {
      photo = await photosStore.getPhotoTextile(id, textileOrderId);
    } else {
      photo = await photosStore.getPhoto(id);
    }

    if (photo) {
      this.fillForm(photo);
    }

    return photo;
  }

  async componentDidMount() {
    const {
      applicationStore,
      isEditorEnabled,
      photoType,
      location
    } = this.props;

    if (
      isEditorEnabled &&
      ONBOARD_TYPES.includes(photoType) &&
      applicationStore.onboardFeature('photoeditor', location?.pathname)
    ) {
      return;
    }

    const id = this.props.photoId;
    if (id) {
      await this.loadPhoto(id);
    } else {
      // new item mode
      this.props.photosStore.clearCurrentPhotoItem();
      this.props.form.reset();
    }
  }

  async componentDidUpdate(prevProps: PhotoEditScreenProps) {
    const newId = this.props.photoId;
    if (newId) {
      if (prevProps.photoId !== newId) {
        if (!newId) {
          // new item mode
          this.props.photosStore.clearCurrentPhotoItem();
          this.props.form.reset();
          return;
        }

        // ID has changed, we need to fetch new item
        await this.loadPhoto(newId);
      }
    }
  }

  navigateBack() {
    const { backRoute } = this.props;
    if (backRoute) {
      this.props.history.push(backRoute);
    }
  }

  async updatePhoto(navigateBack: boolean) {
    const { form, photosStore, photoType } = this.props;
    const { photo, image_config, caption } = form.values;
    const textileOrderId = textileOrderIdFromMatch(this.props.match);

    const { photoItem } = this.props.photosStore;
    assert(photoItem);

    const body: UpdatePhotoBody = {
      id: photoItem.id,
      photo,
      caption,
      image_config
    };

    let photoModel;
    // update item
    try {
      if (photoType === 'textile_back_creative' && textileOrderId) {
        photoModel = await photosStore.updatePhotoTextile(body, textileOrderId);
      } else {
        photoModel = await photosStore.updatePhoto(body);
      }
    } catch (error: any) {
      if (!handleFormError(form, error)) {
        throw error;
      }
    }

    if (navigateBack) {
      this.navigateBack();
    }

    return photoModel;
  }

  handleUploadPhoto = async (file: File, isMultiUpload: boolean) => {
    const { form, photosStore, parentId, photoType } = this.props;

    const textileOrderId = textileOrderIdFromMatch(this.props.match);

    if (!(parentId && photoType)) {
      throw new Error('parentId and photoType must be set');
    }

    form.setLoading(true);

    try {
      const { photoItem } = photosStore;
      if (isMultiUpload || !photoItem || photoItem.id < 0) {
        // New photo
        const body: CreatePhotoBody = {
          photo_type: photoType,
          parent_id: parentId,
          photo: { file },
          image_config: {
            cutOff: {
              width: 100,
              height: 100,
              top: 0,
              left: 0
            },
            rotation: 0,
            crop: false
          }
        };

        if (photoType === 'textile_back_creative' && textileOrderId) {
          await photosStore.createPhotoTextile(body, textileOrderId);
        } else {
          await photosStore.createPhoto(body);
        }

        if (!isMultiUpload) {
          this.waitAndNavigateBack('photo added flash');
        }
      } else {
        // Existing photo
        if (photoType === 'textile_back_creative' && textileOrderId) {
          await photosStore.updatePhotoTextile(
            { id: photoItem.id, photo: { file } },
            textileOrderId
          );
        } else {
          await photosStore.updatePhoto({ id: photoItem.id, photo: { file } });
        }

        this.waitAndNavigateBack('photo updated flash');
      }
    } catch (error: any) {
      form.setLoading(false);

      if (!handleFormError(form, error)) {
        throw error;
      }
    }
  };

  handleSinglePhotoUpload = (file: File) => this.handleUploadPhoto(file, false);

  handleMultiPhotoUpload = async (files: FileList) => {
    const fileArray = Array.from(files);
    for (const file of fileArray) {
      await this.handleUploadPhoto(file, true);
    }

    this.waitAndNavigateBack('photo added flash');
  };

  waitAndNavigateBack(flash?: string) {
    setTimeout(() => {
      this.props.form.setLoading(false);

      if (flash) {
        this.props.applicationStore.setFlashMessage(
          intl.formatMessage({ id: flash })
        );
      }

      this.navigateBack();
    }, 2000);
  }

  confirmDelete = () => {
    this.props.form.setConfirm(true);
  };

  performDelete = async () => {
    const { photoId, photosStore, photoType } = this.props;
    const textileOrderId = textileOrderIdFromMatch(this.props.match);

    let id = photoId;
    if (!id && photosStore.photoItem && photosStore.photoItem.id) {
      id = photosStore.photoItem.id;
    }

    if (!id) {
      return;
    }

    this.finishDelete();

    if (photoType === 'textile_back_creative' && textileOrderId) {
      await photosStore.removePhotoTextile(id, textileOrderId);
    } else {
      await photosStore.removePhoto(id);
    }

    this.navigateBack();
  };

  finishDelete = () => {
    this.props.form.setConfirm(false);
  };

  isAddMode() {
    return this.props.photoId === undefined;
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  renderReadOnly() {
    const item = this.props.photosStore.photoItem;
    if (!item || !item.photo || !item.photo.shrinked) {
      return this.renderPage(<LockedLabel id="Photos" />);
    }

    const { isImageRounded } = this.props;
    return this.renderPage(
      <>
        <LockedLabel id="Photos" />

        <Divider />

        <CardBlock>
          <ImageBlock
            height={20.875}
            background="GRAY900"
            rounded={isImageRounded}
            fullWidth={true}
          >
            <img src={item.photo.shrinked} alt="" />
          </ImageBlock>
        </CardBlock>
      </>
    );
  }

  resetForm = () => {
    const { form } = this.props;

    form.setField('image_config', {});
    form.setField('caption', undefined);
  };

  handlePhotoSave = (
    imageConfig: ImageConfig,
    turn: boolean,
    caption?: string
  ) => {
    const { values } = this.props.form;
    values.image_config = imageConfig;
    values.caption = caption;

    const { handleSave, photoType } = this.props;

    const navigateBack = photoType !== 'album' && !turn;
    this.updatePhoto(navigateBack);

    if (handleSave && !turn) {
      handleSave();
    }
  };

  renderForm() {
    const {
      photosStore: { photoItem, photoItemLoadingState },
      form
    } = this.props;

    const isAddMode = this.isAddMode();

    let extra;
    if (form.confirm) {
      const textComponent = (
        <FormattedMessage
          id="confirm delete item"
          values={{
            item: <FormattedMessage id="This photo" />
          }}
        />
      );
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm delete header" />}
          text={textComponent}
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={this.performDelete}
          onAbort={this.finishDelete}
          confirmColor="RED"
        />
      );
    } else if (photoItemLoadingState === 'update_error') {
      extra = <GenericErrorComponent />;
    }

    let url;
    let width;
    let height;
    let rotation = 0;
    let swap;
    let processing = false;
    let offsetX;
    let offsetY;
    let fitToFrame;
    let caption;

    if (photoItem) {
      if (photoItem.photo) {
        url = photoItem.photo.shrinked;
        width = photoItem.width;
        height = photoItem.height;
        rotation = photoItem.rotation || 0;
        offsetX = photoItem.offsetX;
        offsetY = photoItem.offsetY;
        fitToFrame = photoItem.fitToFrame;
        const turnPosition = rotation / 90;
        swap = turnPosition % 2 === 0 ? false : true;
        if (swap) {
          width = photoItem.height;
          height = photoItem.width;
        }
        caption = photoItem.caption;
      } else if (!isAddMode) {
        processing = true;
      }
    }

    const {
      allowDelete,
      allowEditCaption,
      fitToFrameEnabled,
      frameWidth,
      frameHeight,
      isEditorEnabled,
      multiple
    } = this.props;

    return this.renderPage(
      <>
        {extra}

        {processing ? (
          <PageHeader
            headline={<FormattedMessage id="Photo is being processed" />}
            text={<FormattedMessage id="processing info" />}
          />
        ) : !isAddMode && url && width && height && isEditorEnabled ? (
          <ImageEditorComponent
            src={url}
            frameWidth={frameWidth}
            frameHeight={frameHeight}
            imageWidth={width}
            imageHeight={height}
            fitToFrameEnabled={fitToFrameEnabled}
            fitToFrame={fitToFrame}
            rotation={rotation}
            handleSave={this.handlePhotoSave}
            offsetX={offsetX}
            offsetY={offsetY}
            unit={'px'}
            caption={caption}
            allowEditCaption={allowEditCaption || false}
          />
        ) : multiple ? (
          <MultiFileUploadComponent
            name="photo"
            accept={ACCEPT_IMAGES_AND_PDFS}
            autoUpload={true}
            resetForm={this.resetForm}
            onUploadPhotos={this.handleMultiPhotoUpload}
            url={url}
            isAddMode={isAddMode}
            {...form.bindInput('photo')}
          />
        ) : (
          <FileUploadComponent
            name="photo"
            accept={ACCEPT_IMAGES_AND_PDFS}
            autoUpload={true}
            resetForm={this.resetForm}
            onUploadPhoto={this.handleSinglePhotoUpload}
            url={url}
            isAddMode={isAddMode}
            {...form.bindInput('photo')}
          />
        )}

        {!isAddMode && allowDelete && (
          <DeleteButton onClick={this.confirmDelete} />
        )}
      </>
    );
  }

  renderPage(content: React.ReactNode) {
    const { backRoute, backIsCancel } = this.props;
    return (
      <>
        {backRoute && (
          <TopActionBlock>
            {backIsCancel ? (
              <CancelLink to={backRoute} />
            ) : (
              <BackLink to={backRoute} />
            )}
          </TopActionBlock>
        )}
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage
              id={this.isAddMode() ? 'Add photo' : 'Edit photo'}
            />
          </Headline.Large>
          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { photosStore, applicationStore, form } = this.props;
    const textileOrderId = textileOrderIdFromMatch(this.props.match);

    if (photosStore.isPhotoItemLoading || form.loading) {
      return this.renderLoading();
    }

    if (photosStore.photoItemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (!textileOrderId && !applicationStore.isEditAllowed) {
      return this.renderReadOnly();
    }

    return this.renderForm();
  }
}

export default withRouter((props: PublicPhotoEditScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <PhotoEditScreen {...props} form={form} />;
});
