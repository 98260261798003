import React, { MouseEvent } from 'react';

import {
  EveryCard,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import { GroupModelType } from 'models/GroupModel';

export interface GroupsListItemProps {
  group: GroupModelType;
  selected?: boolean;
  groupStudentsCount?: number;
  onClick?: (e: MouseEvent, groupId: number, name?: string) => void;
}

export const GroupsListItem = ({
  group,
  selected,
  onClick
}: GroupsListItemProps) => (
  <EveryCard
    as={onClick && 'button'}
    onClick={onClick && ((e: MouseEvent) => onClick(e, group.id, group.name))}
    selected={selected}
  >
    <EveryCardPadding>
      <EveryCardHeadline>{group.name || group.id}</EveryCardHeadline>
    </EveryCardPadding>
  </EveryCard>
);

export default GroupsListItem;
