import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ImageBlock from 'blocks/ImageBlock';
import IconComponent from 'components/IconComponent';
import { PhotoModelType } from 'models/PhotoModel';
import TextElement from 'components/TextElement/TextElement';

interface YearbookPhotoItemProps {
  photo?: PhotoModelType;
}

const YearbookPhotoItem = ({ photo }: YearbookPhotoItemProps) => {
  const isPhotoPresent = !!photo;
  const isPreviewPresent =
    photo && photo.photo && photo.photo.preview ? true : false;

  return (
    <>
      <EveryCard>
        <EveryCardPadding>
          <ImageBlock
            background="GRAY900"
            width={3.0625}
            height={3.0625}
            cover={true}
          >
            {!isPhotoPresent ? null : isPreviewPresent ? (
              <img src={photo!.photo!.preview} alt="" />
            ) : (
              <IconComponent icon="HOURGLASS" fill="PRIMARY" size={2} />
            )}
          </ImageBlock>
          <EveryCardBody>
            <EveryCardHeadline>
              <FormattedMessage id="Photo" />
            </EveryCardHeadline>

            <TextElement>
              {!isPhotoPresent ? (
                <>
                  <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />
                  <FormattedMessage id="Not yet placed" />
                </>
              ) : isPreviewPresent ? (
                <>
                  <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                  <FormattedMessage id="Placed" />
                </>
              ) : (
                <FormattedMessage id="Photo is being processed" />
              )}
            </TextElement>
          </EveryCardBody>
        </EveryCardPadding>
      </EveryCard>
    </>
  );
};

export default YearbookPhotoItem;
