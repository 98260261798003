import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  TextileAppState,
  completeOnboardingTextileOrder,
  getTextileAppStateById,
  joinTextileOrder
} from 'api/textile_deals/fetchTextileAppState';
import { TextileRoleChangeRequestType } from 'api/textile_deals/fetchTextileRoleChangeRequests';

export const useTextileAppStateQuery = (id?: number) => {
  return useQuery({
    queryKey: ['textile_app_state', id],
    queryFn: () => getTextileAppStateById(id!),
    staleTime: 60000, // we dont want to fetch this every time, this data can be stale for a while in the normal app context
    cacheTime: 10000,
    enabled: !!id
  });
};

// optimistic update of order_state and join_role_selected
export const useTextileOnboardingQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (textileOrderId: number) =>
      completeOnboardingTextileOrder(textileOrderId),
    onMutate: async (_textileOrderId) => {
      await queryClient.cancelQueries(['textile_app_state', _textileOrderId]);
      const previousState:
        | TextileAppState
        | undefined = queryClient.getQueryData([
        'textile_app_state',
        _textileOrderId
      ]);

      if (!previousState) {
        return;
      }

      const newState: TextileAppState = {
        textile_order: {
          ...previousState.textile_order,
          order_state: 'preparing'
        },
        textile_profile: {
          ...previousState.textile_profile,
          join_role_selected: true
        }
      };
      queryClient.setQueryData(['textile_app_state', _textileOrderId], newState);

      return { previousState };
    },
    onError: (err, _textileOrderId, context) => {
      queryClient.setQueryData(
        ['textile_app_state', _textileOrderId],
        context?.previousState
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_app_state', textileOrderId]);
    }
  });
};

// optimistic update of join_role_selected
export const useJoinTextileOrderQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      orderId: number;
      requestType?: TextileRoleChangeRequestType;
    }) => joinTextileOrder(data.orderId, data.requestType),
    onMutate: async (data) => {
      await queryClient.cancelQueries(['textile_app_state', data.orderId]);
      const previousState:
        | TextileAppState
        | undefined = queryClient.getQueryData([
        'textile_app_state',
        data.orderId
      ]);

      if (!previousState) {
        return;
      }

      const newState: TextileAppState = {
        ...previousState,
        textile_profile: {
          ...previousState.textile_profile,
          join_role_selected: true
        }
      };
      queryClient.setQueryData(['textile_app_state', data.orderId], newState);

      return { previousState };
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(
        ['textile_app_state', data.orderId],
        context?.previousState
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_app_state', textileOrderId])
    }
  });
};
