import { get, uri } from "api/BaseApi";
import { TextileGroup } from "./fetchTextileGroups";

export interface TextileTeacher {
  id: number;
  first_name?: string;
  last_name?: string;
  name?: string;
  textile_group?: TextileGroup;
  print?: boolean;
}

export const getAllTeachers = (id: number): Promise<TextileTeacher[]> => {
  return get('/textile_orders/' + uri(id) + '/all_teachers');
}

export const getAllTeachersWithoutGroups = (id: number): Promise<TextileTeacher[]> => {
  return get('/textile_orders/' + uri(id) + '/all_teachers_without_groups');
}