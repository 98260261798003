import { Instance, types } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import PlaceholderSponsorPageModel, { createPlaceholderSponsorPageModel } from './PlaceholderSponsorPageModel';
import SponsorPageModel, { createSponsorPage } from './SponsorPageModel';

const SponsorPageSortingModel = types.model('SponsorPageModel', {
  id: types.identifierNumber,
  sorting: types.maybe(types.number),
  sponsor_page: types.maybe(SponsorPageModel),
  placeholder_sponsor_page: types.maybe(PlaceholderSponsorPageModel)
});

export const createSponsorSortingPage = (data: {
  id?: number;
  sorting?: number;
  sponsor_page?: any;
  placeholder_sponsor_page?: any;
}) => {
  return SponsorPageSortingModel.create({
    id: mandatoryId(data.id),
    sorting: numberOrUndefined(data.sorting),
    sponsor_page: data.sponsor_page !== null ? createSponsorPage(data.sponsor_page): undefined,
    placeholder_sponsor_page: data.placeholder_sponsor_page !== null ? createPlaceholderSponsorPageModel(data.placeholder_sponsor_page) : undefined
   });
}

export type SponsorPageSortingModelType = Instance<typeof SponsorPageSortingModel>;
export default SponsorPageSortingModel;
