import { get, patch, post, uri } from 'api/BaseApi';
import { TextileGroup } from './fetchTextileGroups';
import { Notification } from './fetchTextileNotification';

export type TextileProfileRole =
  | 'guest'
  | 'applicant'
  | 'student'
  | 'organizer'
  | 'manager';

export type PreorderState = 'open' | 'submitted' | 'rejected' | 'pending';
export interface BaseTextileProfile {
  id: number;
  textile_order_id: number;
  first_name: string;
  last_name: string;
  name: string;
  role: TextileProfileRole;
  print?: boolean;
  name_verified?: boolean;
  print_data_locked?: boolean;
  preorder_state?: PreorderState;
  join_role_selected?: boolean;
  has_role_change_request?: boolean;
  textile_group?: TextileGroup;
  print_data_notification?: Notification;
  preorder_notification?: Notification;
}

export const getTextileProfileByTextileOrderId = (
  id: number,
  textileOrderId: number
): Promise<BaseTextileProfile> => {
  return get(
    '/textile_profiles/' + uri(id) + '?textile_order_id=' + textileOrderId
  );
};

export const getTextileProfilesByTextileOrderId = (
  textileOrderId: number
): Promise<BaseTextileProfile[]> => {
  return get('/textile_profiles?textile_order_id=' + textileOrderId);
};

export const updateTextileProfile = (
  id: number,
  textileOrderId: number,
  data: any
): Promise<BaseTextileProfile> => {
  return patch('/textile_profiles/' + uri(id), {
    textile_profile: data,
    textile_order_id: textileOrderId
  });
};

export const getAllOrganizers = (textileOrderId: number): Promise<BaseTextileProfile[]> => {
  return get(
    `/textile_profiles/organizers?textile_order_id=${textileOrderId}`
  );
}

export const submitPreorder = (id: number, textileOrderId: number): Promise<BaseTextileProfile> => {
  return get('/textile_profiles/' + uri(id) + '/submit_preorder?textile_order_id=' + textileOrderId);
}

export const reopenPreorder = (id: number, textileOrderId: number): Promise<BaseTextileProfile> => {
  return get('/textile_profiles/' + uri(id) + '/reopen_preorder?textile_order_id=' + textileOrderId);
}

export const rejectPreorder = (id: number, textileOrderId: number): Promise<BaseTextileProfile> => {
  return get('/textile_profiles/' + uri(id) + '/reject_preorder?textile_order_id=' + textileOrderId);
}

export const joinTextileOrder = (textileOrderId: number) => {
  return post('/textile_profiles/join_textile_order', {
    textile_order_id: textileOrderId
  });
}