import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBlock from 'blocks/HeaderBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import SupportButton from 'domain/Buttons/SupportButton';

import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import { FormattedMessage } from 'react-intl';
import Logo from './Logo';

export const TextileHeaderComponent = ({
  linkHome,
  linkAccount,
  noLogo,
  noAccount,
  children,
  showSupportButton
}: {
  linkHome: string;
  linkAccount: string;
  noLogo?: boolean;
  noAccount?: boolean;
  children?: React.ReactNode;
  showSupportButton?: boolean;
}) => (
  <HeaderBlock>
    <HorizontalStackBlock justified={true} centered={true}>
      <Link to={linkHome}>{!noLogo && <Logo />}</Link>

      {showSupportButton && <SupportButton />}

      <HorizontalStackBlock gap="XXS">
        <Link to={'/app/account'}>
          <Paragraph size="XS" color="BLACK">
            <HorizontalStackBlock gap="XXS">
              <FormattedMessage id="Project dashboard" />
              <IconComponent icon="ACCOUNT" size={1.2}/>
            </HorizontalStackBlock>
          </Paragraph>
        </Link>

        {!noAccount && (
          <Link to={linkAccount}>
            <Paragraph size="XS" color="BLACK">
              <IconComponent icon="USER_CIRCLE" size={1.2}/>
            </Paragraph>
          </Link>
        )}
      </HorizontalStackBlock>

      {children}
    </HorizontalStackBlock>
  </HeaderBlock>
);

export default TextileHeaderComponent;
