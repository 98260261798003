import React, { useContext, useEffect } from 'react';

import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { BaseTextileDesignSetting } from 'api/textile_deals/fetchTextileDesignSetting';
import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import TeaserCard from 'blocks/TeaserCard';
import WideBlock from 'blocks/WideBlock';
import IconComponent from 'components/IconComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import UppercaseHeading from 'elements/UppercaseHeading';
import { useTextileDesignSettingQuery } from 'queries/textile_deals/useTextileDesignSettingQueries';
import textileThemesImagePath from 'utils/textile-theme-image-path';

const TextileDesignLayoutScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const accessContext = useContext(HasAccessContext);
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  const textileDesignSetting = useTextileDesignSettingQuery(textileOrderId);

  useEffect(() => {
    accessContext.checkSectionAccess('design');
  }, [accessContext]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="Design textile order" />}
            text={<FormattedMessage id="textile layout overview info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileDesignSetting.isError) {
    return (
      renderPage(<GenericErrorComponent
        onRetryClick={() => textileDesignSetting.refetch()}
      />)
    );
  }

  if (
    textileDesignSetting.isLoading ||
    !textileDesignSetting.data ||
    !textileAppState
  ) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const renderLayout = (settings: BaseTextileDesignSetting) => {
    const { theme } = settings;

    if (!settings.layout_key || !theme) {
      return (
        <WideBlock>
          <TeaserCard>
            <TeaserCard.CardInfo>
              <Headline.Medium color="WHITE">
                <FormattedMessage id="discover designs header textile" />
              </Headline.Medium>

              <Paragraph size="L" color="WHITE">
                <FormattedMessage id="discover textile designs" />
              </Paragraph>

              <ButtonBlock
                background="PRIMARY"
                color="WHITE"
                onClick={() => props.history.push('themes')}
              >
                <FormattedMessage id="discover textile designs button" />
              </ButtonBlock>
            </TeaserCard.CardInfo>
          </TeaserCard>
        </WideBlock>
      );
    }

    return (
      <WideBlock padded={true}>
        <ItemStackBlock gap="S">
          {theme.frontPreview_deluxeHoodie ? (
            <>
              <UppercaseHeading>
                <FormattedMessage id="Design preview" />
              </UppercaseHeading>
              <img
                src={textileThemesImagePath(
                  theme.key,
                  theme.frontPreview_deluxeHoodie
                )}
                alt="Layout"
              />
            </>
          ) : (
            <Paragraph>{theme.name}</Paragraph>
          )}

          <LinkBlock
            inline={true}
            slim={true}
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            to={textileOrderRoute(props.match, '/textile_design/themes')}
          >
            <IconComponent icon="PEN" />
            <FormattedMessage id="Change design" />
          </LinkBlock>
        </ItemStackBlock>
      </WideBlock>
    );
  };

  const renderOverview = () => {
    const settings = textileDesignSetting.data;

    if (!settings) {
      return null;
    }

    return renderPage(
      <>
        {renderLayout(settings)}
      </>
    );
  };

  return renderOverview();
};

export default TextileDesignLayoutScreen;
