import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import CandidateModel, {
  CandidateModelType,
  createCandidateModel
} from 'models/CandidateModel';
import ChapterModel, {
  ChapterModelType,
  createChapterModel
} from 'models/ChapterModel';
import RankingModel, { RankingModelType } from 'models/RankingModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { VoteModelType } from 'models/VoteModel';
import { assert } from 'utils/assert';
import { createMapWithTransform } from 'utils/create-map';
import { moveItem } from 'utils/item-sorting';
import { PrintedRanking, printRankings } from 'utils/rankings/rankingHelpers';
import { sortByField, sortByName } from 'utils/sort-functions';
import createRankingModel from 'utils/store/createRankingModel';

export interface Rank {
  rank: number;
  count: number;
  vote: VoteModelType;
}

const RankingsStore = types
  .model('RankingsStore', {
    // list
    loadingState: types.maybe(types.string),
    rankings: types.maybe(types.map(RankingModel)),
    chapter: types.maybe(ChapterModel),
    // singe item
    itemLoadingState: types.maybe(types.string),
    item: types.maybe(RankingModel),
    // candidates
    candidatesLoadingState: types.maybe(types.string),
    candidateItemLoadingState: types.maybe(types.string),
    candidates: types.maybe(types.map(CandidateModel)),
    candidateItem: types.maybe(CandidateModel),
    addCandidateActive: types.maybe(types.boolean),
    // votes
    removeVoteWarning: types.maybe(
      types.enumeration(['not_locked', 'confirm'])
    ),
    removeVoteIds: types.maybe(types.array(types.number))
  })
  .actions((self) => {
    const getAllRankings = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';
        self.rankings = undefined;
        self.chapter = undefined;

        const result = yield client.getAllRankings();

        if (!Array.isArray(result) || !result.length) {
          self.loadingState = undefined;
          return;
        }

        self.rankings = createMapWithTransform(result, createRankingModel);
        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | getAllRankings', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const getRankingsByChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';
        self.rankings = undefined;
        self.chapter = undefined;

        const result = yield client.getAllRankings(chapterId);

        if (!result) {
          throw new Error('No response from server');
        }

        if (Array.isArray(result.rankings)) {
          self.rankings = createMapWithTransform(
            result.rankings,
            createRankingModel
          );
        }
        self.chapter = createChapterModel(result.chapter || { id: chapterId });

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'RankingsStore | getRankingsByChapter',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const getRanking = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;
        self.removeVoteIds = undefined;
        self.removeVoteWarning = undefined;

        const item = yield client.getRanking(id);

        self.item = createRankingModel(item);
        self.itemLoadingState = undefined;

        return self.item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | getRanking', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    const createRanking = flow(function* (
      item: RankingModelType,
      addToList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;
        self.removeVoteIds = undefined;
        self.removeVoteWarning = undefined;

        const result = yield client.createRanking(item);
        const model = createRankingModel(result);

        if (model) {
          if (addToList) {
            self.rankings?.put(model);
          }

          self.item = createRankingModel(result);
        }
        self.itemLoadingState = undefined;

        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | createRanking', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateRanking = flow(function* (item: RankingModelType) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        item = yield client.updateRanking(item);
        const model = createRankingModel(item);

        if (model) {
          if (self.rankings?.has(model.id.toString())) {
            self.rankings.put(model);
          }

          if (self.item?.id === model.id) {
            self.item = createRankingModel(item);
          }
        }

        self.itemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | updateRanking', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const removeRanking = flow(function* (
      rankingId: number,
      removeFromList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        yield client.removeRanking(rankingId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | removeRanking', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.itemLoadingState = 'update_error';
          throw error;
        }
      }

      if (removeFromList && self.rankings) {
        self.rankings.delete(rankingId.toString());
      }

      if (self.item?.id === rankingId) {
        self.item = undefined;
      }
      self.itemLoadingState = undefined;
    });

    const moveRanking = flow(function* (oldIndex: number, newIndex: number) {
      assert(self.rankings);

      const { items, map } = moveItem(self.rankings, oldIndex, newIndex);

      self.rankings = map;

      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        // Do not set loading state, this would block the UI
        self.loadingState = undefined;

        yield client.setRankingSorting(items as RankingModelType[]);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | moveQuestion', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
        throw error;
      }

      self.loadingState = undefined;
    });

    const clearCurrentItem = () => {
      self.item = undefined;
      self.itemLoadingState = undefined;
    };

    const setChapter = (chapter?: ChapterModelType) => {
      self.chapter = createChapterModel(chapter);
    };

    const placeVote = flow(function* (
      rankingId: number,
      candidateIds: number[]
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      self.itemLoadingState = 'loading';

      try {
        yield client.voteForRanking(rankingId, candidateIds);

        if (self.rankings) {
          const rankingIdx = rankingId.toString();
          const ranking = self.rankings.get(rankingIdx);
          if (ranking) {
            ranking.did_vote = true;
          }
        }

        self.itemLoadingState = undefined;
        return true;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | vote', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return false;
        }

        if (client.isNotFound(error)) {
          // ignore this error, so user will be redirected to list
          self.itemLoadingState = undefined;
          return true;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        return false;
      }
    });

    const setRemoveWarning = (
      state?: 'confirm',
      candidateIds?: number[]
    ) => {
      self.removeVoteWarning = state;
      self.removeVoteIds = cast(candidateIds);
    };

    const removeVote = flow(function* (
      rankingId: number,
      candidateIds: number[]
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      self.itemLoadingState = 'loading';

      try {
        yield client.unvote(rankingId, candidateIds);

        self.itemLoadingState = undefined;
        return true;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | removeVote', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return false;
        }

        if (client.isNotFound(error)) {
          // ignore this error, so user will be redirected to list
          self.itemLoadingState = undefined;
          return true;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        return false;
      }
    });

    const patchItem = (update: any) => {
      if (!self.item) {
        self.item = createRankingModel({
          id: -1,
          ...update
        });
        return;
      }

      self.item = createRankingModel({
        ...self.item,
        ...update
      });
    };

    const storeItem = flow(function* () {
      if (!self.item) {
        return;
      }

      if (self.item.id < 0) {
        return yield createRanking(self.item);
      }
      return yield updateRanking(self.item);
    });

    const getAllCandidates = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.candidatesLoadingState = 'loading';
        self.candidates = undefined;

        const result: any = yield client.getAllCandidates(chapterId);

        if (!Array.isArray(result?.candidates) || !result.candidates.length) {
          self.candidatesLoadingState = undefined;
          return;
        }

        self.candidates = createMapWithTransform(
          result.candidates,
          createCandidateModel
        );
        self.candidatesLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | getAllCandidates', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.candidatesLoadingState = undefined;
          return;
        }

        self.candidatesLoadingState = 'error';
      }
    });

    const getCandidate = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.candidateItemLoadingState = 'loading';
        self.candidateItem = undefined;

        const question = yield client.getCandidate(id);

        self.candidateItem = createCandidateModel(question);
        self.candidateItemLoadingState = undefined;

        return self.candidateItem;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | getCandidate', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.candidateItemLoadingState = undefined;
          return;
        }

        self.candidateItemLoadingState = 'error';
      }
    });

    const createCandidate = flow(function* (
      item: CandidateModelType,
      addToList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.candidateItemLoadingState = 'loading';
        self.candidateItem = undefined;

        item = yield client.createCandidate(item);

        const model = createCandidateModel(item);
        if (model) {
          if (addToList) {
            // TODO check if chapter of list matches chapter of candidate?
            self.candidates?.put(model);
          }

          self.candidateItem = createCandidateModel(item);
        }

        self.candidateItemLoadingState = undefined;
        return item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | createCandidate', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.candidateItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.candidateItemLoadingState = undefined;
          throw error;
        }

        self.candidateItemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateCandidate = flow(function* (candidateId: number, patch: any) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.candidateItemLoadingState = 'loading';

        const question: any = yield client.updateCandidate(candidateId, patch);
        const model = createCandidateModel(question);

        if (model) {
          if (self.candidates?.has(model.id.toString())) {
            self.candidates.put(model);
          }

          if (self.candidateItem?.id === model.id) {
            self.candidateItem = createCandidateModel(question);
          }
        }
        self.candidateItemLoadingState = undefined;

        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | updateCandidate', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.candidateItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.candidateItemLoadingState = undefined;
          throw error;
        }

        self.candidateItemLoadingState = 'update_error';
        throw error;
      }
    });

    const removeCandidate = flow(function* (
      candidateId: number,
      removeFromList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.candidateItemLoadingState = 'loading';

        yield client.removeCandidate(candidateId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('RankingsStore | removeCandidate', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.candidateItemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (client.isNotFound(error)) {
          return;
        }

        self.candidateItemLoadingState = 'update_error';
        throw error;
      }

      if (removeFromList) {
        self.candidates?.delete(candidateId.toString());
      }

      if (self.candidateItem?.id === candidateId) {
        self.candidateItem = undefined;
      }
      self.candidateItemLoadingState = undefined;
    });

    const clearCurrentCandidateItem = () => {
      self.candidateItem = undefined;
      self.candidateItemLoadingState = undefined;
    };

    const setAddCandidateActive = (active: boolean) => {
      self.addCandidateActive = active;
    };

    return {
      getAllRankings,
      getRankingsByChapter,
      getRanking,
      createRanking,
      updateRanking,
      removeRanking,
      moveRanking,
      clearCurrentItem,
      setChapter,
      placeVote,
      setRemoveWarning,
      removeVote,
      patchItem,
      storeItem,
      getAllCandidates,
      getCandidate,
      createCandidate,
      updateCandidate,
      removeCandidate,
      clearCurrentCandidateItem,
      setAddCandidateActive
    };
  })
  .views((self) => {
    return {
      get size(): number {
        if (!self.rankings) {
          return 0;
        }
        return self.rankings.size;
      },

      /**
       * Rankings sorted by sorting id
       */
      get allRankings(): RankingModelType[] {
        const items: RankingModelType[] = [];
        if (!self.rankings) {
          return items;
        }

        for (const item of self.rankings.values()) {
          items.push(item);
        }

        items.sort(sortByField('sorting'));
        return items;
      },
      filteredRankings(text: string): RankingModelType[] {
        const textLowercase = text.trim().toLowerCase();
        return this.allRankings.filter(
          (ranking) =>
            ranking.name &&
            ranking.name.toLowerCase().indexOf(textLowercase) > -1
        );
      },

      get allRankingsCount(): number {
        return self.rankings?.size || 0;
      },

      get voteStats(): {
        count: number;
        voted: number;
        notVoted: number;
        allVotesCount: number;
      } {
        if (!self.rankings) {
          return {
            count: 0,
            voted: 0,
            notVoted: 0,
            allVotesCount: 0
          };
        }

        const stats = {
          count: self.rankings.size,
          voted: 0,
          notVoted: 0,
          allVotesCount: 0
        };

        for (const item of self.rankings.values()) {
          stats.allVotesCount += item.votes_count ? item.votes_count : 0;
          item.did_vote ? stats.voted++ : stats.notVoted++;
        }

        return stats;
      },

      get itemRanking(): Rank[] | undefined {
        if (!self.item || !self.item.votes || !self.item.votes.length) {
          return undefined;
        }

        const votes = [];
        for (const vote of self.item.votes.values()) {
          if (!vote) {
            continue;
          }
          votes.push({
            rank: 0,
            count: vote.count || 0,
            vote
          });
        }

        if (!votes.length) {
          return undefined;
        }

        votes.sort((a, b) => {
          // sort by number of votes (descending)
          const aCount = (a.vote && a.vote.count) || 0;
          const bCount = (b.vote && b.vote.count) || 0;

          if (aCount < bCount) {
            return 1;
          }
          if (aCount > bCount) {
            return -1;
          }

          return 0;
        });

        // set correct ranks
        let rank = 0;
        let last = 0;

        for (let i = 0; i < votes.length; i++) {
          if (!votes[i].vote.excluded) {
            if (votes[i].count !== last) {
              last = votes[i].count;
              rank += 1;
            }
            votes[i].rank = rank;
          } else {
            votes[i].rank = 0;
          }
        }

        return votes;
      },

      get splittedItemRanking(): [Rank[] | undefined, Rank[] | undefined] {
        const ranking = this.itemRanking;
        if (!ranking) {
          return [undefined, undefined];
        }

        const splitted: [Rank[] | undefined, Rank[] | undefined] = [[], []];
        for (const rank of ranking) {
          splitted[rank.rank > 3 ? 1 : 0]!.push(rank);
        }

        if (!splitted[0]!.length) {
          splitted[0] = undefined;
        }
        if (!splitted[1]!.length) {
          splitted[1] = undefined;
        }

        return splitted;
      },

      get isListLoading(): boolean {
        return self.loadingState === 'loading' ? true : false;
      },
      get isListError(): boolean {
        return self.loadingState === 'error' ? true : false;
      },

      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading' ? true : false;
      },
      get isItemError(): boolean {
        return self.itemLoadingState === 'error' ||
          self.itemLoadingState === 'update_error'
          ? true
          : false;
      },

      get isCandidatesListLoading(): boolean {
        return self.candidatesLoadingState === 'loading' ? true : false;
      },
      get isCandidatesListError(): boolean {
        return self.candidatesLoadingState === 'error' ? true : false;
      },

      get isCandidateItemLoading(): boolean {
        return self.candidateItemLoadingState === 'loading' ? true : false;
      },
      get isCandidateItemError(): boolean {
        return self.candidateItemLoadingState === 'error' ||
          self.candidateItemLoadingState === 'update_error'
          ? true
          : false;
      },

      get allCandidates(): CandidateModelType[] {
        const items: CandidateModelType[] = [];
        if (!self.candidates) {
          return items;
        }

        for (const item of self.candidates.values()) {
          items.push(item);
        }

        items.sort(sortByName);
        return items;
      },
      filteredCandidates(text: string): CandidateModelType[] {
        const textLowercase = text.trim().toLowerCase();

        const items: CandidateModelType[] = [];
        if (!self.candidates) {
          return items;
        }

        for (const item of self.candidates.values()) {
          if (
            item.name &&
            item.name.toLowerCase().indexOf(textLowercase) > -1
          ) {
            items.push(item);
          }
        }

        items.sort(sortByName);
        return items;
      },
      get printedRankings(): PrintedRanking[] {
        if (!self.rankings?.size) {
          return [];
        }

        return printRankings(self.rankings.values());
      }
    };
  });

export type RankingsStoreType = Instance<typeof RankingsStore>;
export default RankingsStore;
