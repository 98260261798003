import React from 'react';

// TODO remove fallback entirely
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ItemStackBlock from 'blocks/ItemStackBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import Divider from 'components/Divider/Divider';
import config from 'config';
import Headline from 'elements/Headline';
import NakedButton from 'elements/NakedButton';
import { inject, observer } from 'mobx-react';
import { ChapterModelType } from 'models/ChapterModel';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import { LayoutModelType } from 'models/print/LayoutModel';
import {
  LayoutStoreType,
  chapterTypeToServerPath
} from 'models/print/LayoutStore';
import { FormattedMessage } from 'react-intl';
import { SelectionContainerComponent } from './SelectionContainerComponent';

interface ChapterLayoutComponentProps {
  layoutStore?: LayoutStoreType;
  chapter: ChapterModelType;
  layoutSelected: (key: string) => void;
  currentKey?: string;
}

@inject('layoutStore')
@observer
export class ChapterLayoutComponent extends React.Component<
  ChapterLayoutComponentProps
> {
  componentDidMount(): void {
    const { layoutStore } = this.props;

    const { layouts } = layoutStore!;

    if (!layouts) {
      this.loadLayouts();
    }
  }

  async loadLayouts() {
    const { layoutStore, chapter } = this.props;

    try {
      await layoutStore!.getLayouts(chapter.chapter_type);
    } catch (error: any) {}
  }

  async selectLayout(key: string) {
    const { layoutSelected } = this.props;
    layoutSelected(key);
  }

  filterUsableAssets(layouts: LayoutModelType[]) {
    const { chapter } = this.props;

    const settings = chapter.settings as FactsheetChapterSettingsModelType;

    if (chapter.chapter_type === 'factsheet') {
      return layouts.filter(
        (layouts) =>
          !layouts.tags.includes('private') &&
          !layouts.tags.includes('unindexed') &&
          layouts.tags.includes(`feature:photo${settings.photo_count}`)
      );
      // return layouts.filter((layouts) => !layouts.tags.includes("private") && !layouts.tags.includes("unindexed"))
    }
    return layouts.filter(
      (layouts) =>
        !layouts.tags.includes('private') && !layouts.tags.includes('unindexed')
    );
  }

  findCustomLayouts() {
    const { layoutStore } = this.props;

    const layouts = this.filterUsableAssets(layoutStore!.allLayouts);

    if (!layouts || layouts.length === 0) {
      return [];
    }

    return layouts.filter(
      (layout) =>
        layout.tags.includes('onlyBookFiltered')
    );
  }

  findRecommendedLayouts() {
    const { layoutStore, chapter } = this.props;

    const layouts = this.filterUsableAssets(layoutStore!.allLayouts);
    const theme = chapter.book_theme_key;

    if (!layouts || layouts.length === 0) {
      return [];
    }

    return layouts.filter(
      (layout) =>
        (layout.tags.includes(`recommendTheme:${theme}`) || layout.tags.includes(`onlyThemeFiltered`) ) && !layout.tags.includes('onlyBookFiltered')
    );
  }

  findGlobalLayouts() {
    const { layoutStore, chapter } = this.props;

    const layouts = this.filterUsableAssets(layoutStore!.allLayouts);
    const theme = chapter.book_theme_key;

    if (!layouts || layouts.length === 0) {
      return [];
    }

    return layouts.filter(
      (layout) => !layout.tags.includes('onlyBookFiltered') && !layout.tags.includes(`recommendTheme:${theme}`)  && !layout.tags.includes(`onlyThemeFiltered`)
    );
  }

  render() {
    const { chapter, currentKey } = this.props;
    const customLayouts: LayoutModelType[] = this.findCustomLayouts();
    const recommendedLayouts: LayoutModelType[] = this.findRecommendedLayouts();
    const globalLayouts: LayoutModelType[] = this.findGlobalLayouts();

    return (
      <ItemStackBlock gap="L">
        <Headline.Large>
          <FormattedMessage id="choose chapter layout" />
        </Headline.Large>

        <Divider />

        {customLayouts.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Custom assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {customLayouts.map((layout) => (
                <NakedButton
                  key={layout.id}
                  onClick={() => this.selectLayout(layout.id)}
                >
                  <SelectionContainerComponent
                    selected={currentKey === layout.id}
                  >
                    {layout.preview ? (
                      <img
                        src={
                          config.layout.baseUrl +
                          '/' +
                          chapterTypeToServerPath(chapter.chapter_type) +
                          '/previews/' +
                          layout.preview
                        }
                        alt=""
                      />
                    ) : (
                      layout.id
                    )}
                  </SelectionContainerComponent>
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </>
        )}

        {recommendedLayouts.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Recommended assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {recommendedLayouts.map((layout) => (
                <NakedButton
                  key={layout.id}
                  onClick={() => this.selectLayout(layout.id)}
                >
                  <SelectionContainerComponent
                    selected={currentKey === layout.id}
                  >
                    {layout.preview ? (
                      <img
                        src={
                          config.layout.baseUrl +
                          '/' +
                          chapterTypeToServerPath(chapter.chapter_type) +
                          '/previews/' +
                          layout.preview
                        }
                        alt=""
                      />
                    ) : (
                      layout.id
                    )}
                  </SelectionContainerComponent>
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </>
        )}

        {globalLayouts.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Global assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {globalLayouts.map((layout) => (
                <NakedButton
                  key={layout.id}
                  onClick={() => this.selectLayout(layout.id)}
                >
                  <SelectionContainerComponent
                    selected={currentKey === layout.id}
                  >
                    {layout.preview ? (
                      <img
                        src={
                          config.layout.baseUrl +
                          '/' +
                          chapterTypeToServerPath(chapter.chapter_type) +
                          '/previews/' +
                          layout.preview
                        }
                        alt=""
                      />
                    ) : (
                      layout.id
                    )}
                  </SelectionContainerComponent>
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </>
        )}
      </ItemStackBlock>
    );
  }
}

export default ChapterLayoutComponent;
