export enum COLORS {
  PRIMARY = '#FF9400',
  WARNING = '#FF9400',
  PRIMARY_LIGHT = '#FFF3E1',
  PRIMARY_DARK = '#6A3E00',

  SECONDARY = '#515CC2',
  SECONDARY_LIGHT = '#EAECFF',
  SECONDARY_DARK = '#1A1E47',

  RED = '#F42525',
  RED_LIGHT = '#FDE0E0',
  RED_DARK = '#DA4848',

  GREEN = '#2EBE6D',
  GREEN_LIGHT = '#CCF8DF',
  GREEN_DARK = '#439E6B',

  PURPLE = '#A250C8',
  PURPLE_LIGHT = '#F5E0FF',

  GRAY10 = '#0E0F20',
  GRAY400 = '#666666',
  GRAY560 = '#8F8F8F',
  GRAY800 = '#CCCCCC',
  GRAY800_060 = 'rgb(204 204 204 / 0.6)',
  GRAY900 = '#E6E6E6',
  GRAY900_070 = 'rgb(230 230 230 / 0.7)',
  GRAY950 = '#F2F2F2',
  GRAY980 = '#FAFAFA',
  GRAY980_080 = 'rgb(250 250 250 / 0.8)',
  BLACK = '#000',
  WHITE = '#FFF',

  BLACK_003 = 'rgb(0 0 0 / 0.03)',
  BLACK_060 = 'rgb(0 0 0 / 0.6)',

  WHATSAPP = '#39BA4B',
  CTA = '#264451',
  VIOLET = PRIMARY,

  CURRENT_COLOR = 'currentColor',

  PRIMARY_RGB = '255,148,0',
  SECONDARY_DARK_RGB = '29,31,65',
  GRAY10_RGB = '14,15,32',
  WHITE_RGB = '255,255,255',
  RED_RGB = '255,74,0',
  GREEN_RGB = '57, 186, 75',

  TEXT_DARK = BLACK,
  TEXT_LIGHT = GRAY400
}

export default COLORS;
