import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SplitFactsheetChapterScreen from 'screens/factsheets/screens/SplitFactsheetChapterScreen.tsx/SplitFactsheetChapterScreen';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';

import FactsheetsSettings from './FactsheetsSettings';
import AnswerDetailScreen from './screens/AnswerDetail/AnswerDetailScreen';
import AnswersScreen from './screens/AnswersScreen/AnswersScreen';
import FactsheetCommentsScreen from './screens/FactsheetCommentsScreen';
import FactsheetDetailScreen from './screens/FactsheetDetail/FactsheetDetailScreen';
import FactsheetPhotoScreen from './screens/FactsheetDetail/FactsheetPhotoScreen';
import FactsheetTextScreen from './screens/FactsheetDetail/FactsheetTextScreen';
import FactsheetsList from './screens/FactsheetsList/FactsheetsList';
import OwnFactsheetScreen from './screens/OwnFactsheetScreen';
import OwnProfileCommentsScreen from './screens/OwnProfileCommentsScreen';
import QuestionDetailScreen from './screens/QuestionDetail/QuestionDetailScreen';
import QuestionHeaderTextScreen from './screens/QuestionHeaderText/QuestionHeaderTextScreen';
import QuestionIdeasScreen from './screens/QuestionsList/QuestionIdeasScreen';
import QuestionsListScreen from './screens/QuestionsList/QuestionsListScreen';
import TeacherQuestionIdeasScreen from './screens/QuestionsList/TeacherQuestionIdeasScreen';
import SortQuestionsScreen from './screens/SortQuestions/SortQuestionsScreen';
import TeacherFactsheetNameScreen from './screens/TeacherFactsheetNameScreen';

export default () => (
  <Switch>
    <Route
      path={ROUTE_FACTSHEETS}
      exact={true}
      component={OwnFactsheetScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/comments'}
      exact={true}
      component={OwnProfileCommentsScreen}
    />

    <Route
      path={ROUTE_FACTSHEETS + '/detail/:id'}
      exact={true}
      component={FactsheetDetailScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/detail/:id/text'}
      component={FactsheetTextScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/detail/:id/comments'}
      component={FactsheetCommentsScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/detail/:id/photo/:type'}
      component={FactsheetPhotoScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/detail/:id/answers'}
      component={AnswersScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/detail/:id/answer/add/:questionId'}
      component={AnswerDetailScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/detail/:id/answer/:answerId'}
      component={AnswerDetailScreen}
    />

    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId'}
      exact={true}
      component={FactsheetsList}
    />

    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/settings'}
      component={FactsheetsSettings}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/split'}
      component={SplitFactsheetChapterScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/questions'}
      exact={true}
      component={QuestionsListScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/questions/sort'}
      exact={true}
      component={SortQuestionsScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/questions/header_text'}
      exact={true}
      component={QuestionHeaderTextScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/questions/ideas'}
      component={QuestionIdeasScreen}
    />
        <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/questions/ideas_teachers'}
      component={TeacherQuestionIdeasScreen}
    />

    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/questions/add'}
      component={QuestionDetailScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/questions/:id'}
      component={QuestionDetailScreen}
    />

    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/teacher/new'}
      component={TeacherFactsheetNameScreen}
    />
    <Route
      path={ROUTE_FACTSHEETS + '/:chapterId/teacher/:id'}
      component={TeacherFactsheetNameScreen}
    />

    <NotFoundRoute />
  </Switch>
);
