import { types, Instance } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';

const QuestionModel = types.model('QuestionModel', {
  id: types.identifierNumber,
  question: types.maybe(types.string),
  sorting: types.maybe(types.number),
  print: types.maybe(types.boolean),

  // TODO move to other file?
  answer: types.maybe(
    types.model('AnswerModel', {
      id: types.identifierNumber,
      answer: types.maybe(types.string),
      unanswered: types.maybe(types.boolean),
      print: types.maybe(types.boolean)
    })
  )
});

export const createQuestionModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return QuestionModel.create({
    id: mandatoryId(data.id),
    question: data.question || undefined,
    sorting: numberOrUndefined(data.sorting),
    print: booleanOrUndefined(data.print),

    answer: data.answer?.id
      ? {
          id: data.answer.id,
          answer: data.answer.answer || undefined,
          unanswered: booleanOrUndefined(data.answer.unanswered),
          print: booleanOrUndefined(data.answer.print)
        }
      : undefined
  });
};

export type QuestionModelType = Instance<typeof QuestionModel>;
export default QuestionModel;
