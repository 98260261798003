import { types, IAnyModelType, Instance } from 'mobx-state-tree';
import PhotoModel, { createPhotoModel } from 'models/PhotoModel';

import { createProfileOrderEnum, ProfileOrderEnum } from './ProfileOrderEnum';
import { createArrayWithTransform } from 'utils/create-map';

const BackDesignEnum = types.enumeration([
  'neutral',
  'names',
  'creative',
  'sponsor'
]);

type BackDesign = Instance<typeof BackDesignEnum>;

const DEFAULT_BACK_DESIGN: BackDesign = 'neutral';

const CoverChapterSettingsModel = types.model('CoverChapterSettingsModel', {
  layout_key: types.maybe(types.string),
  sub_title: types.maybe(types.string),
  front_header: types.maybe(types.string),
  front_footer: types.maybe(types.string),
  back_header: types.maybe(types.string),
  spine_text: types.maybe(types.string),
  back_design: BackDesignEnum,
  names_order: types.maybe(ProfileOrderEnum),
  photos: types.array(types.late((): IAnyModelType => PhotoModel))
});

export const createCoverChapterSettingsModel = (
  data?: any,
  createEmptyModel: boolean = true
): CoverChapterSettingsModelType | undefined => {
  if (!data) {
    return createEmptyModel
      ? CoverChapterSettingsModel.create({
          back_design: 'neutral',
          photos: []
        })
      : undefined;
  }

  return CoverChapterSettingsModel.create({
    layout_key: data.layout_key || undefined,
    sub_title: data.sub_title || undefined,
    front_header: data.front_header || undefined,
    front_footer: data.front_footer || undefined,
    back_header: data.back_header || undefined,
    spine_text: data.spine_text || undefined,
    back_design: BackDesignEnum.is(data.back_design)
      ? data.back_design
      : DEFAULT_BACK_DESIGN,
    names_order: createProfileOrderEnum(data.names_order),
    photos: createArrayWithTransform(data.photos, createPhotoModel)
  });
};

export type CoverChapterSettingsModelType = Instance<
  typeof CoverChapterSettingsModel
>;
export default CoverChapterSettingsModel;
