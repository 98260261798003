import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import LinkBlock from 'blocks/LinkBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import ProgressbarComponent from 'components/ProgressbarComponent';
import BackToContentLink from 'domain/Links/BackToContentLink';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import InviteLinkContainer from 'screens/settings/containers/InviteLinkContainer';
import {
  ROUTE_CHECKOUT,
  ROUTE_CONTENT,
  ROUTE_DASHBOARD,
  ROUTE_LAYOUT
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import ContentNotPrinted from '../ContentNotPrinted/ContentNotPrinted';

interface GeneratePdfScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
}

interface GeneratePdfScreenState {
  loadingState?: 'loading' | 'error' | 'generation_started';
  confirm?: 'dismiss';
}

@inject('applicationStore', 'chaptersStore')
@observer
class GeneratePdfScreen extends React.Component<
  GeneratePdfScreenProps & HistoryProps,
  GeneratePdfScreenState
> {
  state: GeneratePdfScreenState = {};

  componentDidMount() {
    // load book and check may_create_book to display onboarding
    this.load();
  }

  private async load() {
    const { applicationStore, chaptersStore } = this.props;

    const cover = await chaptersStore.getCoverChapter();

    if (cover && !cover.layout_key) {
      return;
    }

    const book = await applicationStore.getBook();

    if (book && !book.may_create_pdf && !chaptersStore.mainDashboard) {
      chaptersStore.getMainDashboard();
    }

    if (book && book.may_create_pdf) {
      if (this.props.applicationStore.onboardFeature('pdfpreview')) {
        return;
      }
    }
  }

  private async generate() {
    this.setState({
      confirm: undefined,
      loadingState: 'loading'
    });

    try {
      await this.props.applicationStore.generateBookPreview();

      this.setState({
        loadingState: 'generation_started'
      });
    } catch (error: any) {
      this.setState({
        loadingState: 'error'
      });
    }
  }

  private async dismiss() {
    this.setState({
      confirm: undefined,
      loadingState: 'loading'
    });

    try {
      await this.props.applicationStore.dismissBookPreview();

      this.props.history.push(ROUTE_CONTENT);
    } catch (error: any) {
      this.setState({
        loadingState: 'error'
      });
    }
  }

  private maybeRenderConfirm() {
    if (!this.state.confirm) {
      return null;
    }

    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="open editing confirm header" />}
        text={<FormattedMessage id="open editing confirm" />}
        confirmText={<FormattedMessage id="Open" />}
        abortText={<FormattedMessage id="Cancel" />}
        onConfirm={() => this.dismiss()}
        onAbort={() =>
          this.setState({
            confirm: undefined
          })
        }
      />
    );
  }

  private renderPage(content?: any, backToDashboard = false) {
    return (
      <>
        <TopActionBlock>
          {backToDashboard ? <BackToDashboardLink /> : <BackToContentLink />}
        </TopActionBlock>

        {content}
        {this.maybeRenderConfirm()}

        <HelpSubject subject="generate_preview" />
      </>
    );
  }

  private renderMayNotGenerate() {
    const { book } = this.props.applicationStore;
    const { mainDashboard: dashboard } = this.props.chaptersStore;

    return this.renderPage(
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="please invite students" />}
          text={<FormattedMessage id="please invite students info" />}
        />

        {!!dashboard?.profiles_count && !!book?.number_of_students && (
          <>
            <ProgressbarComponent
              isStatic={true}
              progressStatus={
                (dashboard.profiles_count / book.number_of_students) * 100
              }
              color="GREEN"
            />
            <TextElement>
              <FormattedMessage
                id="please invite students count"
                values={{
                  count: dashboard.profiles_count,
                  number: book.number_of_students
                }}
              />
            </TextElement>
          </>
        )}

        {book && (
          <>
            <Divider />
            <InviteLinkContainer book={book} onlyForm={true} />
          </>
        )}
      </PageStackBlock>
    );
  }

  private renderGenerating() {
    const hasError = this.state.loadingState === 'error';

    return this.renderPage(
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="pdf generating" />}
          text={<FormattedMessage id="pdf generating info" />}
        />

        {hasError && <GenericErrorComponent />}
        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY"
          onClick={() => this.dismiss()}
        >
          <FormattedMessage id="Abort generation" />
        </ButtonBlock>
      </PageStackBlock>,
      true
    );
  }

  private renderPreview() {
    const { book } = this.props.applicationStore;
    const hasError = this.state.loadingState === 'error';

    const isLocked = book?.isLockedForOrder;

    return this.renderPage(
      <PageStackBlock>
        {!isLocked && (
          <>
            <PageHeader
              headline={
                <FormattedMessage
                  id={isLocked ? 'Print PDFs' : 'Editing disabled'}
                />
              }
              text={<FormattedMessage id="pdf previewing info" />}
            />

            {hasError && <GenericErrorComponent />}
            <ButtonBlock
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              onClick={() =>
                this.setState({
                  confirm: 'dismiss'
                })
              }
            >
              <FormattedMessage id="Open editing" />
            </ButtonBlock>

            <Divider />
          </>
        )}

        <PageHeader
          headline={<FormattedMessage id="Print PDFs" />}
          text={<FormattedMessage id="print pdfs info" />}
        />

        <ItemStackBlock gap="S">
          {book?.preview_pdf && (
            <a
              href={book.preview_pdf}
              target="_blank"
              rel="noreferrer noopener"
            >
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="PDF in low resolution" />
                    </EveryCardHeadline>
                    <TextElement>
                      <FormattedMessage
                        id="low resolution info"
                        values={{
                          size: book.preview_pdf_size ? (
                            <FormattedMessage
                              id="pdf size"
                              values={{ size: book.preview_pdf_size }}
                            />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </TextElement>
                  </EveryCardBody>
                  <IconComponent icon="DOWNLOAD" fill="BLACK" size={2} />
                </EveryCardPadding>
              </EveryCard>
            </a>
          )}

          {book?.print_preview_pdf && (
            <a
              href={book.print_preview_pdf}
              target="_blank"
              rel="noreferrer noopener"
            >
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="PDF in high resolution" />
                    </EveryCardHeadline>
                    <TextElement>
                      <FormattedMessage
                        id="high resolution info"
                        values={{
                          size: book.print_preview_pdf_size ? (
                            <FormattedMessage
                              id="pdf size"
                              values={{ size: book.print_preview_pdf_size }}
                            />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </TextElement>
                  </EveryCardBody>
                  <IconComponent icon="DOWNLOAD" fill="BLACK" size={2} />
                </EveryCardPadding>
              </EveryCard>
            </a>
          )}
        </ItemStackBlock>

        <PaddingBlock background="PRIMARY_LIGHT">
          <ItemStackBlock gap="XS">
            <TextElement weight="BOLD" color="TEXT_DARK">
              <FormattedMessage id="white pages header" />
            </TextElement>

            <TextElement color="TEXT_DARK">
              <FormattedMessage id="white pages" />
            </TextElement>
          </ItemStackBlock>
        </PaddingBlock>

        {!isLocked && (
          <>
            <Divider />

            <Headline.Medium>
              <FormattedMessage id="Place order" />
            </Headline.Medium>

            <Paragraph color="TEXT_LIGHT">
              <FormattedMessage id="place order info" />
            </Paragraph>

            <ButtonBlock
              background="PRIMARY"
              color="WHITE"
              onClick={() => this.props.history.push(ROUTE_CHECKOUT)}
            >
              <FormattedMessage id="Order now" />
            </ButtonBlock>
          </>
        )}
      </PageStackBlock>,
      true
    );
  }

  private renderNoTheme() {
    return this.renderPage(
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Generate print PDF" />}
          text={<FormattedMessage id="generate pdf no theme" />}
        />

        <LinkBlock to={ROUTE_LAYOUT} background="PRIMARY" color="WHITE">
          <FormattedMessage id="Select theme now" />
        </LinkBlock>
      </PageStackBlock>
    );
  }

  private renderGenerateInfo() {
    const hasError = this.state.loadingState === 'error';

    if (!this.props.chaptersStore.cover?.layout_key) {
      return this.renderNoTheme();
    }

    return this.renderPage(
      <PageStackBlock>
        <Headline.Large>
          <FormattedMessage id="Generate print PDF" />
        </Headline.Large>

        <ContentNotPrinted link={true} />

        <Paragraph>
          <FormattedMessage id="generate pdf info" />
        </Paragraph>

        {hasError && <GenericErrorComponent />}
        <ButtonBlock
          background="PRIMARY"
          color="WHITE"
          onClick={() => this.generate()}
        >
          <FormattedMessage id="Generate print PDF" />
        </ButtonBlock>

        <LinkBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          to={ROUTE_CONTENT}
        >
          <FormattedMessage id="Check content" />
        </LinkBlock>
      </PageStackBlock>
    );
  }

  private renderGenerationStated() {
    return this.renderPage(
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="pdf generating" />}
          text={<FormattedMessage id="pdf generating startet" />}
        />

        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY"
          onClick={() => this.props.history.push(ROUTE_DASHBOARD)}
        >
          <FormattedMessage id="Go to dashboard" />
        </ButtonBlock>
      </PageStackBlock>,
      true
    );
  }

  private renderOrderPlaced() {
    const { book } = this.props.applicationStore;

    return this.renderPage(
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Order completed" />}
          text={<FormattedMessage id="Order completed no editing" />}
        />

        <Divider />

        <ListStackBlock>
          {book?.inside_pdf && (
            <a href={book.inside_pdf} target="_blank" rel="noreferrer noopener">
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="print inside pdf" />
                    </EveryCardHeadline>
                    <TextElement>
                      <FormattedMessage
                        id="PDF file info"
                        values={{
                          size: book.inside_pdf_size ? (
                            <FormattedMessage
                              id="pdf size"
                              values={{ size: book.inside_pdf_size }}
                            />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </TextElement>
                  </EveryCardBody>
                  <IconComponent icon="DOWNLOAD" fill="BLACK" size={2} />
                </EveryCardPadding>
              </EveryCard>
            </a>
          )}

          {book?.hardcover_pdf && (
            <a
              href={book.hardcover_pdf}
              target="_blank"
              rel="noreferrer noopener"
            >
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="print hardcover pdf" />
                    </EveryCardHeadline>
                    <TextElement>
                      <FormattedMessage
                        id="PDF file info"
                        values={{
                          size: book.hardcover_pdf_size ? (
                            <FormattedMessage
                              id="pdf size"
                              values={{ size: book.hardcover_pdf_size }}
                            />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </TextElement>
                  </EveryCardBody>
                  <IconComponent icon="DOWNLOAD" fill="BLACK" size={2} />
                </EveryCardPadding>
              </EveryCard>
            </a>
          )}

          {book?.softcover_pdf && (
            <a
              href={book.softcover_pdf}
              target="_blank"
              rel="noreferrer noopener"
            >
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="print softcover pdf" />
                    </EveryCardHeadline>
                    <TextElement>
                      <FormattedMessage
                        id="PDF file info"
                        values={{
                          size: book.softcover_pdf_size ? (
                            <FormattedMessage
                              id="pdf size"
                              values={{ size: book.softcover_pdf_size }}
                            />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </TextElement>
                  </EveryCardBody>
                  <IconComponent icon="DOWNLOAD" fill="BLACK" size={2} />
                </EveryCardPadding>
              </EveryCard>
            </a>
          )}
        </ListStackBlock>
      </PageStackBlock>,
      true
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  private renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.load()} />
    );
  }

  render() {
    const { applicationStore, chaptersStore } = this.props;
    const { loadingState } = this.state;

    if (loadingState === 'generation_started') {
      return this.renderGenerationStated();
    }

    if (
      applicationStore.isBookLoading ||
      chaptersStore.isItemLoading ||
      chaptersStore.mainDashboardLoadingState === 'loading' ||
      loadingState === 'loading'
    ) {
      return this.renderLoading();
    }

    const { book } = applicationStore;

    if (
      !book ||
      applicationStore.isBookError ||
      chaptersStore.isItemLoadError
    ) {
      return this.renderError();
    }

    if (book.editing_state === 'generating') {
      return this.renderGenerating();
    }

    if (book.editing_state === 'previewing' || book.order_state === 'pending') {
      return this.renderPreview();
    }

    if (book.orderAtLeastConfirmed) {
      return this.renderOrderPlaced();
    }

    if (!book?.may_create_pdf) {
      return this.renderMayNotGenerate();
    }

    return this.renderGenerateInfo();
  }
}

export default GeneratePdfScreen;
