import { useQuery } from "@tanstack/react-query"
import { getTextile, getTextiles } from "api/textile_deals/fetchTextiles"

export const useTextileQuery = (id?: number, textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile', id],
    queryFn: () => getTextile(id!, textileOrderId!),
    enabled: !!id && !!textileOrderId,
    staleTime: 600000, // we dont want to fetch this every time, this data can be stale for a while in the normal app context
  })
}

export const useTextilesQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textiles', textileOrderId],
    queryFn: () => getTextiles(textileOrderId!),
    enabled: !!textileOrderId,
    staleTime: 600000, // we dont want to fetch this every time, this data can be stale for a while in the normal app context
  })
}