import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';

export const BackClickLink = ({ onClick, messageId }: { onClick: (e: any) => void, messageId?: string }) => (
  <ButtonBlock link={true} onClick={onClick}>
    <TextElement  color="GRAY10">
      <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
      <FormattedMessage id={!messageId ? 'Back' : messageId} />
    </TextElement>
  </ButtonBlock>
);

export default BackClickLink;
