import Paragraph from 'components/Paragraph/Paragraph';
import React from 'react';
import { FormattedMessage } from 'react-intl';


export default () => (
  <Paragraph>
    <FormattedMessage id="setting not available for chapter" />
  </Paragraph>
);
