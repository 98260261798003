import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import BackLink from 'domain/Links/BackLink';
import CandidatesList from 'screens/rankings/containers/CandidatesList/CandidatesList';
import { ROUTE_RANKINGS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';

export default ({ history, match }: HistoryProps) => {
  const chapterId = chapterIdFromMatch(match);

  if (!chapterId) {
    return null;
  }

  const baseRoute = ROUTE_RANKINGS + '/' + chapterId + '/candidates/';

  return (
    <>
      <TopActionBlock>
        <BackLink to={ROUTE_RANKINGS + '/' + chapterId + '/settings'} />
      </TopActionBlock>
      <PageStackBlock>
        <PageHeader headline={<FormattedMessage id="Candidates" />} text={<FormattedMessage id="candidates info" />} />

        <ButtonBlock
          background="PRIMARY"
          onClick={() => history.push(baseRoute + 'new')}
        >
          <FormattedMessage id="Add candidate" />
        </ButtonBlock>

        <ListStackBlock>
          <CandidatesList
            chapterId={chapterId}
            onCandidateClick={(e, id) => history.push(baseRoute + id)}
          />
        </ListStackBlock>
      </PageStackBlock>
    </>
  );
};
