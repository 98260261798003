import React from 'react';

import {
  Boundary,
  ChunkedContent,
  Content,
  createBox,
  fillColumns,
  updatePages
} from 'utils/chunker/ContentChunker';
import replaceVariables, {
  PageParams,
  PagePosition
} from 'utils/chunker/replace-variables';
import sanitizeHtml from 'utils/chunker/sanitize-html';
import shrinkText from 'utils/chunker/TextShrinker';

import NotAvailableComponent from '../processing/NotAvailableComponent';
import ColumnComponent from '../structure/ColumnComponent';
import PageComponent, {
  PAGE_SIZE_A4,
  PAGE_SIZE_PRINT
} from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import {
  getColumns,
  MultiColumnLayout,
  spaceFromConfig
} from './column-layout';
import {
  CommonTemplateProps,
  pageParamsFromProps
} from './CommonTemplateProps';
import WaitForImages from './WaitForImages';

export interface TextBox {
  boundary: Boundary;
  minFontSize: number;
  maxFontSize: number;
}

export interface RankingsTemplateConfig extends MultiColumnLayout {
  leftBackground?: string;
  rightBackground?: string;
  leftAdditionalBackground?: string;
  rightAdditionalBackground?: string;

  rankingBlock?: string;
  rankingBlockMargin?: number;

  rankingNameBoxes: [TextBox] | [TextBox, TextBox, TextBox];
  rankingNameStyles: [string] | [string, string, string];
  useMinimumNameSize?: boolean;

  rankingTitleBox: TextBox;
  rankingTitleStyle: string;
}

export interface Ranking {
  title: string;
  contestants: [string?, string?, string?];
}

export interface RankingsTemplateProps extends CommonTemplateProps {
  // theme
  config: RankingsTemplateConfig;

  // content
  content?: Content;

  rankings: Ranking[];
}

export interface RankingsTemplateState {
  content?: Content;
  pages?: ChunkedContent[];
}

export default class extends React.Component<
  RankingsTemplateProps,
  RankingsTemplateState
> {
  constructor(props: RankingsTemplateProps) {
    super(props);

    this.state = {
      content: props.content
    };
  }

  componentDidMount() {
    this.placeContent();
  }

  placeContent() {
    const { config, startPosition, startPage, onContentPlaced } = this.props;

    const isFirstPageRight = startPosition === 'right';
    const space = spaceFromConfig(config, isFirstPageRight);

    const content = this.generateContent();
    const pages = fillColumns(space, content);

    updatePages(startPosition, startPage, content, pages);

    this.setState({
      content,
      pages
    });

    if (onContentPlaced) {
      onContentPlaced(pages.length);
    }
  }

  generateBackground(
    position: PagePosition,
    isAdditional: boolean,
    page?: number
  ): string {
    const { config } = this.props;

    const [leftBG, rightBG] = isAdditional
      ? [config.leftAdditionalBackground, config.rightAdditionalBackground]
      : [config.leftBackground, config.rightBackground];

    const background = position === 'right' && rightBG ? rightBG : leftBG;
    if (!background) {
      return '';
    }

    const params = this.generateReplaceParams(position, page);
    return replaceVariables(background, params) || '';
  }

  generateReplaceParams(position: PagePosition, page?: number): PageParams {
    const params = pageParamsFromProps(this.props, position, page);
    return params;
  }

  generateContent() {
    const { content, config, rankings } = this.props;

    const generated: Content = [];

    const params = this.generateReplaceParams('left', 1);

    const [firstStyle, secondStyle, thirdStyle] = this.getNameStyles();

    const titleStyle = this.props.config.rankingTitleStyle;
    const titleTextBox = this.props.config.rankingTitleBox;

    const [
      firstNameTextBox,
      secondNameTextBox,
      thirdNameTextBox
    ] = this.getNameTextBoxes();

    if (rankings && rankings.length) {
      for (const ranking of rankings) {
        const [titleFontSize, titleFailed] = this.fitFontSize(
          ranking.title,
          titleTextBox,
          titleStyle
        );

        let [firstNameFontSize, firstFailed] = this.fitFontSize(
          ranking.contestants[0],
          firstNameTextBox,
          firstStyle
        );

        let [secondNameFontSize, secondFailed] = this.fitFontSize(
          ranking.contestants[1],
          secondNameTextBox,
          secondStyle
        );

        let [thirdNameFontSize, thirdFailed] = this.fitFontSize(
          ranking.contestants[2],
          thirdNameTextBox,
          thirdStyle
        );

        if (titleFailed || firstFailed || secondFailed || thirdFailed) {
          // satisfy eslint for now
          // TODO something useful
        }

        if (config.useMinimumNameSize) {
          const min = Math.min(
            firstNameFontSize,
            secondNameFontSize,
            thirdNameFontSize
          );
          firstNameFontSize = min;
          secondNameFontSize = min;
          thirdNameFontSize = min;
        }

        generated.push(
          createBox({
            type: 'box',
            template: config.rankingBlock,
            bottomMargin: config.rankingBlockMargin,
            params,
            contentVariables: [
              ['title', sanitizeHtml(ranking.title) || ''],
              ['title_style', titleStyle],
              ['title_font_size', titleFontSize.toString() + 'px'],

              ['first', sanitizeHtml(ranking.contestants[0]) || ''],
              ['first_style', firstStyle || ''],
              ['first_font_size', firstNameFontSize.toString() + 'px'],

              ['second', sanitizeHtml(ranking.contestants[1]) || ''],
              ['second_style', secondStyle || ''],
              ['second_font_size', secondNameFontSize.toString() + 'px'],

              ['third', sanitizeHtml(ranking.contestants[2]) || ''],
              ['third_style', thirdStyle || ''],
              ['third_font_size', thirdNameFontSize.toString() + 'px']
            ]
          })
        );
      }
    }

    return content ? generated.concat(content) : generated;
  }

  fitFontSize(
    text: string | undefined,
    textBox: TextBox,
    style: string
  ): [number, boolean] {
    if (!text) {
      return [textBox.maxFontSize, true];
    }

    return shrinkText(
      text,
      textBox.maxFontSize,
      textBox.minFontSize,
      style,
      textBox.boundary
    );
  }

  getNameTextBoxes(): [TextBox, TextBox, TextBox] {
    const {
      config: { rankingNameBoxes }
    } = this.props;

    if (rankingNameBoxes.length === 1) {
      return [rankingNameBoxes[0], rankingNameBoxes[0], rankingNameBoxes[0]];
    } else {
      return rankingNameBoxes;
    }
  }

  getNameStyles(): [string, string, string] {
    const {
      config: { rankingNameStyles }
    } = this.props;

    if (rankingNameStyles.length === 1) {
      return [rankingNameStyles[0], rankingNameStyles[0], rankingNameStyles[0]];
    } else {
      return rankingNameStyles;
    }
  }

  render() {
    const { print, config, startPosition, startPage, render } = this.props;

    const { pages, content } = this.state;

    if (!pages || !content) {
      return <NotAvailableComponent print={print} />;
    }

    const isRight = startPosition === 'right';

    let isCurrentRight = !isRight;

    return (
      <>
        {pages.map((page, pageIndex) => {
          isCurrentRight = !isCurrentRight;

          const currentPageNumber = startPage + pageIndex;
          const isAdditional = pageIndex !== 0;

          const background = this.generateBackground(
            isCurrentRight ? 'right' : 'left',
            isAdditional,
            currentPageNumber
          );

          const columns = getColumns(config, isAdditional, isCurrentRight);

          const result = !columns ? null : (
            <PageComponent
              key={pageIndex}
              print={print}
              previewSize={print ? PAGE_SIZE_PRINT : PAGE_SIZE_A4}
              background={background}
            >
              <PageContentComponent>
                {page.map((column, columnIndex) => (
                  <ColumnComponent
                    key={'c' + columnIndex}
                    size={columns[columnIndex].size}
                    position={columns[columnIndex].position}
                  >
                    {column.map((box, boxIndex) => (
                      <div
                        key={boxIndex}
                        style={{
                          marginBottom: !content[box.sourceIndex].bottomMargin
                            ? 0
                            : content[box.sourceIndex].bottomMargin + 'px'
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content[box.sourceIndex].content || ''
                          }}
                        />
                      </div>
                    ))}
                  </ColumnComponent>
                ))}
              </PageContentComponent>
            </PageComponent>
          );

          return !render ? result : render(result, pageIndex);
        })}

        {print && <WaitForImages />}
      </>
    );
  }
}
