import React from 'react';

import NakedButton from 'elements/NakedButton';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import Dot from 'elements/Dot';

export const OnboardDots = ({
  count,
  activeIndex,
  onDotClick
}: {
  count?: number;
  activeIndex?: number;
  onDotClick?: (index: number) => void;
}) => {
  if (!count) {
    return null;
  }

  const dots = [];

  for (let i = 0; i < count; i++) {
    dots.push(
      <NakedButton key={i} onClick={() => onDotClick && onDotClick(i)}>
        <Dot size={0.625} color={i === activeIndex ? 'BLACK' : 'GRAY800'} />
      </NakedButton>
    );
  }

  return <HorizontalStackBlock gap="S">{dots}</HorizontalStackBlock>;
};

export default OnboardDots;
