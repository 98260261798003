import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import UppercaseHeading from 'elements/UppercaseHeading';
import { useTextileGroupsQuery } from 'queries/textile_deals/useTextileGroupsQueries';
import {
  useTextileProfileQuery,
  useUpdateTextileProfileQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const ProfileGroupSelectScreen: React.FC<HistoryProps & FormProps> = (
  props
) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const textileProfile = useTextileProfileQuery(id, textileOrderId);
  const textileGroups = useTextileGroupsQuery(textileOrderId);
  const updateTextileProfile = useUpdateTextileProfileQuery();

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('management_checkout');

    const data = textileProfile.data;
    if (data) {
      props.form.setField(
        'textile_group_id',
        data.textile_group?.id.toString() || ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileProfile.data]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileProfile.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileProfile.refetch()} />
    );
  }

  if (textileGroups.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileGroups.refetch()} />
    );
  }

  if (
    props.form.loading ||
    !textileAppState ||
    textileProfile.isLoading ||
    !textileProfile.data ||
    textileGroups.isLoading ||
    !textileGroups.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const save = () => {
    const { form, history } = props;

    if (!id || !textileOrderId) {
      return;
    }

    form.resetErrors();
    form.setLoading(true);

    updateTextileProfile.mutate(
      {
        data: { ...form.values },
        id: id,
        textileOrderId
      },
      {
        onSuccess: () => {
          history.goBack();
        },
        onError: (error: any) => {
          form.setLoading(false);

          if (handleFormError(form, error)) {
            return;
          }
        }
      }
    );
  };

  const renderSaveButton = (next?: boolean) => {
    const { form } = props;
    return (
      <>
        {form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  };

  const renderForm = () => {
    const { form } = props;
    const profile = textileProfile.data;
    const groups: TextileGroup[] = textileGroups.data;
    const showGroups = textileAppState.textile_order.show_groups;

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="Edit name" />}
          text={
            <>
              <IconComponent icon="USER" /> {profile.name}
            </>
          }
        />

        {showGroups && groups && groups.length !== 0 && (
          <>
            <UppercaseHeading>
              <FormattedMessage id="Groups" />
            </UppercaseHeading>
            <ListStackBlock>
              {groups.map((group: TextileGroup) => (
                <RadioButtonComponent
                  key={group.id}
                  name="group_id"
                  value={group.id}
                  label={group.name}
                  {...form.bindRadioButton(
                    'textile_group_id',
                    group.id.toString()
                  )}
                />
              ))}
            </ListStackBlock>
          </>
        )}
        {renderSaveButton()}
      </>
    );
  };

  return renderForm();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <ProfileGroupSelectScreen {...props} form={form} />;
};
