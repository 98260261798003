import React from 'react';

import { BaseTextileDesignSetting, Font, FontSpec } from 'api/textile_deals/fetchTextileDesignSetting';
import config from 'config';
import { ChapterModelType } from 'models/ChapterModel';
import FontsPreloader, { Fonts } from '../FontsPreloader';
import PageLoadingComponent from '../layout-editor/PageLoadingComponent';

interface FontPreloadComponent {
  print?: boolean;
  chapter?: ChapterModelType;
  textile?: BaseTextileDesignSetting;
  numPages?: number;
  additionalFonts?: Fonts;
  children?: any;
}

export default ({
  print,
  chapter,
  textile,
  numPages,
  additionalFonts,
  children
}: FontPreloadComponent) => {
  let fonts: Fonts = (config.layout.defaultFonts as Fonts) || [];

  const textileFontSpecs = (font?: Font) => {
    if (!font || !font.fonts) {
      return [];
    }

    const fontSpecs: FontSpec[] = [];

    font.fonts.forEach((itm: any) => {
      const name: string = itm[0];
      const weights: number[] = itm[1];

      fontSpecs.push({name, weights})
    })

    const specs: [string, number[]][] = [];

    fontSpecs.forEach((spec) => {
      specs.push([spec.name, spec.weights.map((elem) => elem)]);

    })

    return specs;
  };

  if (chapter) {
    if (chapter.layout?.fontSpecs) {
      fonts = fonts.concat(chapter.layout.fontSpecs);
    }

    if (chapter.font?.specs) {
      fonts = fonts.concat(chapter.font.specs);
    }
  }

  if (textile) {
    if (textileFontSpecs(textile.font)) {
      fonts = fonts.concat(textileFontSpecs(textile.font));
    }
  }

  if (additionalFonts) {
    fonts = fonts.concat(additionalFonts);
  }

  if (!fonts.length) {
    return children;
  }

  return (
    <FontsPreloader
      print={print}
      fonts={fonts}
      loadingComponent={
        !print ? <PageLoadingComponent numPages={numPages} /> : undefined
      }
      // errorComponent={errorComponent}
    >
      {children}
    </FontsPreloader>
  );
};
