import TextElement from 'components/TextElement/TextElement';
import styled from 'styled-components';

import SPACES from 'utils/constants/spaces';

const SuccessContainerBlock = styled.div`
  margin-top: ${SPACES.XL};
  margin-bottom: ${SPACES.XL};
  text-align: center;

  ${TextElement} {
    margin: 0 auto;
    max-width: 30em;
  }
`;

export default SuccessContainerBlock;
