import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TopActionBlock from 'blocks/TopActionBlock';
import ItemSortingComponent from 'components/ItemSortingComponent/ItemSortingComponent';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { FactsheetsStoreType } from 'screens/factsheets/FactsheetsStore';
import { assert } from 'utils/assert';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

interface Props {
  factsheetsStore: FactsheetsStoreType;
}

@inject('factsheetsStore')
@observer
class SortQuestionsScreen extends React.Component<Props & HistoryProps> {
  componentDidMount() {
    this.loadQuestions();
  }

  loadQuestions() {
    const { factsheetsStore } = this.props;
    const chapterId = this.getChapterId();
    factsheetsStore.getQuestionsByChapter(chapterId);
  }

  getChapterId() {
    const chapterId = chapterIdFromMatch(this.props.match);
    assert(chapterId);
    return chapterId;
  }

  onSortEnd(oldIndex: number, newIndex: number) {
    this.props.factsheetsStore.moveQuestion(oldIndex, newIndex);
  }

  renderPage(content: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackLink
            messageId="Done"
            to={ROUTE_FACTSHEETS + '/' + this.getChapterId() + '/questions'}
          />
        </TopActionBlock>
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Sort questions" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadQuestions()} />
    );
  }

  render() {
    const { factsheetsStore } = this.props;

    if (factsheetsStore.isQuestionListLoading) {
      return this.renderLoading();
    }

    if (factsheetsStore.isQuestionListError) {
      return this.renderError();
    }

    return this.renderPage(
      <ItemSortingComponent
        items={factsheetsStore.allQuestions.map((question) => ({
          id: question.id,
          text: question.question || ''
        }))}
        onSortEnd={(oldIndex, newIndex) => this.onSortEnd(oldIndex, newIndex)}
      />
    );
  }
}

export default SortQuestionsScreen;
