import styled, { AnyStyledComponent } from 'styled-components';

import ImageElement from './ImageElement';

interface HeaderBlockElements {
  ImageElement?: any;
}

const HeaderBlock: AnyStyledComponent & HeaderBlockElements = styled.header`
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 1.25em 0 2.5rem 0;
`;

HeaderBlock.ImageElement = ImageElement;
export default HeaderBlock;