import React from 'react';

import ChapterSettingsContainer from 'containers/ChapterSettingsContainer';
import { ROUTE_YEARBOOKS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

const YearbookSettings = (props: HistoryProps) => {
  const chapterId = chapterIdFromMatch(props.match);
  if (!chapterId) {
    return null;
  }

  const baseRoute = ROUTE_YEARBOOKS + '/' + chapterId;

  return (
    <ChapterSettingsContainer
      history={props.history}
      active={props.location?.query?.active}
      chapterId={chapterId}
      baseRoute={baseRoute + '/settings'}
      backRoute={baseRoute}
    />
  );
};

export default YearbookSettings;
