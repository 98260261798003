import React from 'react';

import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

// == Hint =======================================================
// This component is used to display page preview inside chapters.
// ===============================================================

interface ScaledTextilePreviewComponentProps {
  children?: React.ReactNode;
  width: number;
  height: number;
}

interface TextileContainerProps {
  width: number;
  height: number;
}

interface TextilePreviewScale {
  scale: number
}

const TextileContainer = styled.div<TextileContainerProps>`
  position: relative;
  z-index: 3;
  flex-shrink: 0;
  display: 'flex';
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: 0.875em;
  line-height: 1.2;
  font-weight: 300;
  text-align: 'center';
  color: ${COLORS.TEXT_LIGHT};
  margin: auto;
`;

const TextilePreview = styled.div<TextilePreviewScale>`
  transform: scale(${(props) => props.scale});
  transform-origin: left top;
`;

export const ScaledTextilePreviewComponent = ({
  children,
  width,
  height
}: ScaledTextilePreviewComponentProps) => {
  const scale = height / 5669;
  return (
    <TextileContainer width={width} height={height}>
      <TextilePreview scale={scale}>{children}</TextilePreview>
    </TextileContainer>
  );
};

export default ScaledTextilePreviewComponent;
