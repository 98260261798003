import { AuthStateModelType } from 'models/AuthStateModel';
import booleanOrUndefined from './booleanOrUndefined';

export default (authState: any): AuthStateModelType | undefined => {
  if (!authState) {
    return undefined;
  }

  return {
    loading: authState.loading || undefined,
    error: authState.error || undefined,
    tokenRequested: authState.tokenRequested || undefined,
    mobileNumber: authState.mobileNumber || undefined,
    passwordRequired: booleanOrUndefined(authState.passwordRequired),
    passwordReset: booleanOrUndefined(authState.passwordReset)
  };
};
