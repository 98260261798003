import React from 'react';

import AvatarBlock from 'blocks/AvatarBlock';
import ButtonBlock from 'blocks/ButtonBlock';
import CheckboxBlock from 'blocks/CheckboxBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import CustomRadioBlock from 'blocks/CustomRadioBlock';
import {
  DiscountCard,
  DiscountCardFooter,
  DiscountCardInfo
} from 'blocks/DiscountCard/DiscountCard';
import {
  EveryCard,
  EveryCardBody,
  EveryCardFooter,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ExpertsBlock from 'blocks/ExpertsBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ImageBlock from 'blocks/ImageBlock';
import {
  ImageCard,
  ImageCardBody,
  ImageCardImage,
  ImageCardLabel,
  ImageCardStatus,
  LabelledImageCard
} from 'blocks/ImageCard/ImageCard';
import InputBlock from 'blocks/InputBlock';
import InputGroupBlock from 'blocks/InputGroupBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import {
  MiniCard,
  MiniCardBody,
  MiniCardImage,
  MiniCardPadding,
  MiniCardStatus
} from 'blocks/MiniCard/MiniCard';
import SliderBlock from 'blocks/SliderBlock';
import TagBlock from 'blocks/TagBlock';
import TeaserCard from 'blocks/TeaserCard';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import WideBlock from 'blocks/WideBlock';
import IconComponent from 'components/IconComponent';
import ProgressbarComponent from 'components/ProgressbarComponent';
import FormLabel from 'elements/FormLabel';
import Headline from 'elements/Headline';
import Input from 'elements/Input';
import InputAppend from 'elements/InputAppend';
import InputMessage from 'elements/InputMessage';
import UppercaseHeading from 'elements/UppercaseHeading';
import Paragraph from 'components/Paragraph/Paragraph';
import Divider from 'components/Divider/Divider';
import TextElement from 'components/TextElement/TextElement';

function Redesign2020() {
  return (
    <>
      <Headline.Large>Redesign Button Variations</Headline.Large>

      <ItemStackBlock gap="L" marginBottom="L">
        <ButtonBlock background="PRIMARY" color="WHITE">
          Value
        </ButtonBlock>
        <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK">
          Value
        </ButtonBlock>
        <ButtonBlock>Value</ButtonBlock>
        <ButtonBlock background="SECONDARY_LIGHT" color="SECONDARY">
          Value
        </ButtonBlock>
        <ButtonBlock background="RED">Value</ButtonBlock>
        <ButtonBlock background="RED_LIGHT" color="RED">
          Value
        </ButtonBlock>
        <ButtonBlock background="GREEN">Value</ButtonBlock>
        <ButtonBlock background="GRAY950" color="GRAY800">
          <IconComponent icon="settings" />
          Value
        </ButtonBlock>
        <ButtonBlock background="GRAY950" color="GRAY800">
          <IconComponent icon="USER" />
          Value
        </ButtonBlock>
        <ButtonBlock background="WHATSAPP">Value</ButtonBlock>
        <ButtonBlock background="WHITE" color="GRAY400" borderColor="GRAY800">
          Value
        </ButtonBlock>
      </ItemStackBlock>

      <Divider />

      <Headline.Large>Slim Buttons</Headline.Large>

      <ItemStackBlock gap="L" marginBottom="L">
        <ButtonBlock slim={true} background="GREEN">
          Value
        </ButtonBlock>
        <ButtonBlock slim={true} background="GRAY950" color="GRAY800">
          <IconComponent icon="settings" size={1} />
          Value
        </ButtonBlock>
        <ButtonBlock slim={true} background="WHATSAPP">
          Value
        </ButtonBlock>
        <ButtonBlock
          slim={true}
          background="WHITE"
          color="GRAY400"
          borderColor="GRAY800"
        >
          Value
        </ButtonBlock>
      </ItemStackBlock>

      <Divider />

      <Headline.Large>Inline Buttons</Headline.Large>

      <ItemStackBlock gap="L" marginBottom="L">
        <ButtonBlock inline={true} slim={true} background="WHATSAPP">
          Value
        </ButtonBlock>
        <ButtonBlock inline={true} background="PRIMARY">
          Value
        </ButtonBlock>
        <ButtonBlock
          inline={true}
          background="SECONDARY_LIGHT"
          color="SECONDARY"
        >
          Value
        </ButtonBlock>
        <ButtonBlock inline={true} background="RED">
          Value
        </ButtonBlock>
        <ButtonBlock
          inline={true}
          background="WHITE"
          color="GRAY400"
          borderColor="GRAY800"
        >
          Value
        </ButtonBlock>

        <ButtonBlock
          inline={true}
          slim={true}
          round={true}
          iconRight={true}
          background="SECONDARY_DARK"
        >
          Help Button
          <IconComponent icon="QUESTIONMARK_CIRCLE" fill="WHITE" size={1.125} />
        </ButtonBlock>
        <ButtonBlock
          inline={true}
          slim={true}
          round={true}
          background="SECONDARY_DARK"
        >
          <IconComponent icon="QUESTIONMARK_CIRCLE" fill="WHITE" size={1.125} />
          Help Button
        </ButtonBlock>
      </ItemStackBlock>

      <Divider />

      <Headline.Large>Icons</Headline.Large>
      <ItemStackBlock gap="L">
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="ARROW_RIGHT" fill="GRAY400" size={2} />{' '}
          ARROW_RIGHT
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="ARROW_RIGHT" fill="GRAY400" size={2} />{' '}
          ARROW_RIGHT
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="ARROW_RIGHT" fill="GRAY400" size={2} />{' '}
          ARROW_RIGHT
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="ARROW_LEFT" fill="GRAY400" size={2} /> ARROW_LEFT
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="ARROW_LEFT" fill="GRAY400" size={2} /> ARROW_LEFT
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="ARROW_LEFT" fill="GRAY400" size={2} /> ARROW_LEFT
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="ARROW_ROTATE" fill="GRAY400" size={2} />{' '}
          ARROW_ROTATE
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="BRUSH" fill="GRAY400" size={2} /> BRUSH
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="CROSS" fill="GRAY400" size={2} /> CROSS
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="CONTENT" fill="GRAY400" size={2} /> CONTENT
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="COPY" fill="GRAY400" size={2} /> COPY
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="DRAG" fill="GRAY400" size={2} /> DRAG
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="MAKE_HIDDEN" fill="GRAY400" size={2} />{' '}
          MAKE_HIDDEN
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="HOODY" fill="GRAY400" size={2} /> HOODY
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="TICK_CIRCLE" fill="GRAY400" size={2} />{' '}
          TICK_CIRCLE
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="LIGHTBULB" fill="GRAY400" size={2} /> LIGHTBULB
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="MAGNIFY" fill="GRAY400" size={2} /> MAGNIFY
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="MAKE_VISIBLE" fill="GRAY400" size={2} />{' '}
          MAKE_VISIBLE
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="MAKE_HIDDEN" fill="GRAY400" size={2} />{' '}
          MAKE_HIDDEN
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="DRAG" fill="GRAY400" size={2} /> DRAG
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="MONEY" fill="GRAY400" size={2} /> MONEY
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="MOTTO" fill="GRAY400" size={2} /> MOTTO
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="JOURNEY" fill="GRAY400" size={2} /> JOURNEY
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="PEN" fill="GRAY400" size={2} /> PEN
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="PEN_RULER" fill="GRAY400" size={2} /> PEN_RULER
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="PLUS" fill="GRAY400" size={2} /> PLUS
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="SETTINGS" fill="GRAY400" size={2} /> SETTINGS
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="SORT_H" fill="GRAY400" size={2} /> SORT_H
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="SORT_V" fill="GRAY400" size={2} /> SORT_V
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="TICK_CIRCLE" fill="GRAY400" size={2} />{' '}
          TICK_CIRCLE
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="TICK" fill="GRAY400" size={2} /> TICK
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="TRASH" fill="GRAY400" size={2} /> TRASH
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="MAKE_VISIBLE" fill="GRAY400" size={2} />{' '}
          MAKE_VISIBLE
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="UPLOAD" fill="GRAY400" size={2} /> UPLOAD
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="USER" fill="GRAY400" size={2} /> USER
        </HorizontalStackBlock>
        <HorizontalStackBlock gap="M" centered={true}>
          <IconComponent icon="WARNING" fill="GRAY400" size={2} /> WARNING
        </HorizontalStackBlock>
      </ItemStackBlock>

      <Divider />

      <Headline.Large>Checkbox | Toggle</Headline.Large>
      <ItemStackBlock gap="M">
        <CustomCheckboxBlock>
          <CustomCheckboxBlock.CheckboxElement />
          <CustomCheckboxBlock.LabelElement>
            Label
          </CustomCheckboxBlock.LabelElement>
        </CustomCheckboxBlock>
        <CustomCheckboxBlock>
          <CustomCheckboxBlock.CheckboxElement />
          <CustomCheckboxBlock.LabelElement>
            Label
            <TextElement>
              Description
            </TextElement>
          </CustomCheckboxBlock.LabelElement>
        </CustomCheckboxBlock>
      </ItemStackBlock>

      <Divider />

      <Headline.Large>Checkbox | Toggle</Headline.Large>
      <ItemStackBlock gap="M">
        <CheckboxBlock>
          <CheckboxBlock.CheckboxElement />
          <CheckboxBlock.LabelElement>
            Das hier ist ein Label, was jetzt irgendwie so lang sein soll, dass
            der Text in mehreren Zeilen umbricht. Mal schauen ob das so klappt.
          </CheckboxBlock.LabelElement>
        </CheckboxBlock>
        <CheckboxBlock>
          <CheckboxBlock.CheckboxElement error={true} />
          <CheckboxBlock.LabelElement>
            Das hier ist ein Label, was jetzt irgendwie so lang sein soll, dass
            der Text in mehreren Zeilen umbricht. Mal schauen ob das so klappt.
          </CheckboxBlock.LabelElement>
        </CheckboxBlock>
        <CheckboxBlock bordered={true}>
          <CheckboxBlock.CheckboxElement />
          <CheckboxBlock.LabelElement>Label</CheckboxBlock.LabelElement>
        </CheckboxBlock>
      </ItemStackBlock>

      <Divider />

      <Headline.Large>Radiobutton | Toggle</Headline.Large>
      <ItemStackBlock gap="M">
        <CustomRadioBlock>
          <CustomRadioBlock.RadioElement name="redesign" />
          <CustomRadioBlock.LabelElement>Label</CustomRadioBlock.LabelElement>
        </CustomRadioBlock>
        <CustomRadioBlock>
          <CustomRadioBlock.RadioElement name="redesign" />
          <CustomRadioBlock.LabelElement>
            Label
            <TextElement>
              Description
            </TextElement>
          </CustomRadioBlock.LabelElement>
        </CustomRadioBlock>
        <CustomRadioBlock>
          <CustomRadioBlock.RadioElement error={true} checked={true} />
          <CustomRadioBlock.LabelElement>
            Label
            <TextElement>
              Description
            </TextElement>
          </CustomRadioBlock.LabelElement>
        </CustomRadioBlock>
        <CustomRadioBlock>
          <CustomRadioBlock.RadioElement disabled={true} />
          <CustomRadioBlock.LabelElement>
            Label
            <TextElement>
              Description
            </TextElement>
          </CustomRadioBlock.LabelElement>
        </CustomRadioBlock>
        <CustomRadioBlock>
          <CustomRadioBlock.RadioElement name="redesign" />
          <CustomRadioBlock.LabelElement>
            <CustomRadioBlock.InfoElement color="GRAY10">
              Price
            </CustomRadioBlock.InfoElement>
            Label
            <TextElement>
              Description
            </TextElement>
          </CustomRadioBlock.LabelElement>
        </CustomRadioBlock>
      </ItemStackBlock>

      <Divider />

      <IconComponent icon="arrow-down" size={3} />
      <IconComponent icon="arrow-up" size={3} />
      <IconComponent icon="drawing-utensils" size={3} />

      <Paragraph>
        <IconComponent icon="drawing-utensils" fill="GREEN" />
        Icon with text
      </Paragraph>

      <Headline.Large>
        <IconComponent icon="drawing-utensils" fill="RED" />
        Headline Icon with text
      </Headline.Large>

      <Divider />

      <Headline.Large>Mini Card</Headline.Large>

      <TwoColumnBlock gap="M">
        <MiniCard>
          <MiniCardPadding>
            <IconComponent
              icon="MOTTO"
              fill="PRIMARY"
              size={3}
              circleBackground="PRIMARY_LIGHT"
            />
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
            </MiniCardBody>
            <ButtonBlock
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              slim={true}
            >
              Bestellen
            </ButtonBlock>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard>
          <MiniCardPadding>
            <IconComponent
              icon="MOTTO"
              fill="PRIMARY"
              size={3}
              circleBackground="PRIMARY_LIGHT"
            />
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Description
              </Paragraph>
            </MiniCardBody>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard
          background="PRIMARY_LIGHT"
          borderColor="BLACK_003"
          centered={true}
        >
          <MiniCardPadding>
            <IconComponent icon="MOTTO" fill="PRIMARY" size={3} />
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Description
              </Paragraph>
            </MiniCardBody>
            <ButtonBlock background="PRIMARY" color="WHITE" slim={true}>
              Bestellen
            </ButtonBlock>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard>
          <MiniCardPadding>
            <IconComponent
              icon="MOTTO"
              fill="PRIMARY"
              size={3}
              circleBackground="PRIMARY_LIGHT"
            />
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <HorizontalStackBlock gap="XS">
                <Paragraph size="S"  color="TEXT_LIGHT">
                  Mitschüler
                </Paragraph>
                <Paragraph size="S">
                  10
                </Paragraph>
              </HorizontalStackBlock>
              <HorizontalStackBlock gap="XS">
                <Paragraph size="S"  color="TEXT_LIGHT">
                  Organisatoren
                </Paragraph>
                <Paragraph size="S">
                  1
                </Paragraph>
              </HorizontalStackBlock>
            </MiniCardBody>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard>
          <MiniCardImage src="https://picsum.photos/id/237/300/200" alt="" />
          <MiniCardPadding>
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Description
              </Paragraph>
            </MiniCardBody>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard>
          <MiniCardPadding>
            <IconComponent
              icon="MOTTO"
              fill="PRIMARY"
              size={3}
              circleBackground="PRIMARY_LIGHT"
            />
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Description
              </Paragraph>
            </MiniCardBody>
          </MiniCardPadding>
          <MiniCardStatus>
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
            Ausgewählt
          </MiniCardStatus>
        </MiniCard>

        <MiniCard>
          <MiniCardImage src={'https://picsum.photos/id/110/200/300'} />
          <MiniCardPadding>
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
            </MiniCardBody>
            <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY" slim={true}>
              Bestellen
            </ButtonBlock>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard>
          <MiniCardImage src={'https://picsum.photos/id/110/200/300'} />
          <MiniCardPadding>
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Description
              </Paragraph>
            </MiniCardBody>
          </MiniCardPadding>
          <MiniCardStatus>
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
            Ausgewählt
          </MiniCardStatus>
        </MiniCard>

        <MiniCard
          background="BLACK_003"
          borderColor="BLACK_003"
          centered={true}
        >
          <MiniCardPadding>
            <IconComponent icon="MOTTO" fill="PRIMARY" size={3} />
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Description
              </Paragraph>
            </MiniCardBody>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard>
          <MiniCardPadding>
            <IconComponent
              icon="MOTTO"
              fill="PRIMARY"
              size={3}
              circleBackground="PRIMARY_LIGHT"
            />
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Description
              </Paragraph>
            </MiniCardBody>
            <ProgressbarComponent progressStatus={30} isStatic={true} />
          </MiniCardPadding>
        </MiniCard>

        <MiniCard>
          <MiniCardPadding>
            <IconComponent
              icon="HOODY"
              fill="SECONDARY"
              size={3}
              circleBackground="SECONDARY_LIGHT"
            />
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
            </MiniCardBody>
            <ButtonBlock
              background="SECONDARY_LIGHT"
              color="SECONDARY"
              slim={true}
            >
              Bestellen
            </ButtonBlock>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard>
          <MiniCardPadding>
            <IconComponent
              circleBackground="SECONDARY_LIGHT"
              icon="HOODY"
              fill="SECONDARY"
              size={3}
            />
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Description
              </Paragraph>
            </MiniCardBody>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard sideToSide={true}>
          <MiniCardPadding>
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Description
              </Paragraph>
            </MiniCardBody>
            <ButtonBlock
              background="SECONDARY_LIGHT"
              color="SECONDARY"
              slim={true}
            >
              Bestellen
            </ButtonBlock>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard
          sideToSide={true}
          background="BLACK_003"
          borderColor="BLACK_003"
        >
          <MiniCardPadding>
            <MiniCardBody>
              <Headline.Small color="BLACK">Name</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                Ausgewählt
              </Paragraph>
            </MiniCardBody>
          </MiniCardPadding>
        </MiniCard>

        <MiniCard sideToSide={true}>
          <MiniCardPadding>
            <MiniCardBody>
              <Headline.Small color="BLACK">Mottoideen-Liste</Headline.Small>
              <Paragraph size="S"  color="TEXT_LIGHT">
                Über 200 Ideen für Mottos
              </Paragraph>
            </MiniCardBody>
            <IconComponent icon="LIGHTBULB" fill="BLACK" size={2} />
          </MiniCardPadding>
        </MiniCard>
      </TwoColumnBlock>

      <IconComponent
        icon="MOTTO"
        fill="PRIMARY"
        circleBackground="PRIMARY_LIGHT"
        size={3}
      />
      <IconComponent icon="MOTTO" fill="PRIMARY" size={3} />
      <Divider />
      <Headline.Large>Label</Headline.Large>
      <ItemStackBlock gap="L">
        <Paragraph>
          <IconComponent icon="user" />
          Value
        </Paragraph>
        <Paragraph>
          <IconComponent icon="usergroup" />
          Value
        </Paragraph>
        <Paragraph>
          <IconComponent icon="warning_circle" fill="PRIMARY" />
          Value
        </Paragraph>
        <Paragraph>
          <IconComponent icon="tick_circle" fill="GREEN" />
          Value
        </Paragraph>
        <Paragraph size="S">
          <IconComponent icon="user" />
          Value
        </Paragraph>
        <Paragraph size="S">
          <IconComponent icon="usergroup" />
          Value
        </Paragraph>
        <Paragraph size="S">
          <IconComponent icon="warning_circle" fill="PRIMARY" />
          Value
        </Paragraph>
        <Paragraph size="S">
          <IconComponent icon="tick_circle" fill="GREEN" />
          Value
        </Paragraph>
        <Headline.Small color="BLACK">
          <IconComponent icon="tick_circle" fill="GREEN" />
          Value
        </Headline.Small>
        <Headline.Medium color="BLACK">
          <IconComponent icon="tick_circle" fill="GREEN" />
          Value
        </Headline.Medium>
        <Headline.Large color="BLACK">
          <IconComponent icon="tick_circle" fill="GREEN" />
          Value
        </Headline.Large>
        <TextElement color="TEXT_DARK">
          <IconComponent icon="tick_circle" fill="GREEN" />
          Value
        </TextElement>
      </ItemStackBlock>

      <Divider />
      <Headline.Large>InviteBox</Headline.Large>
      <ItemStackBlock gap="L">
        <InputGroupBlock>
          <ItemStackBlock gap="L">
            <FormLabel>Link zum Einladen</FormLabel>
            <InputBlock backgroundColor="GRAY950" borderColor="GRAY950">
              <Input value="app.nuggit.de/0234hnhn4" disabled={true} />
              <InputAppend>
                <IconComponent icon="COPY" fill="BLACK" />
              </InputAppend>
            </InputBlock>
            <ButtonBlock background="WHATSAPP" color="WHITE">
              Link Teilen
            </ButtonBlock>
          </ItemStackBlock>
        </InputGroupBlock>

        <Divider />
        <InputGroupBlock>
          <FormLabel>Link zum Einladen</FormLabel>
          <ItemStackBlock gap="L">
            <InputBlock backgroundColor="GRAY950" borderColor="GRAY950">
              <Input value="app.nuggit.de/0234hnhn4" disabled={true} />
              <InputAppend>
                <IconComponent icon="COPY" fill="BLACK" />
              </InputAppend>
            </InputBlock>
            <ButtonBlock background="PRIMARY" color="WHITE">
              Link Teilen
            </ButtonBlock>
          </ItemStackBlock>
        </InputGroupBlock>

        <Divider />
        <InputGroupBlock>
          <FormLabel>Link zum Einladen</FormLabel>
          <ItemStackBlock gap="L">
            <InputBlock
              rounded={true}
              backgroundColor="GRAY950"
              borderColor="GRAY950"
            >
              <Input value="app.nuggit.de/0234hnhn4" disabled={true} />
              <InputAppend as="div">
                <ButtonBlock background="WHATSAPP" color="WHITE" slim={true}>
                  Teilen
                </ButtonBlock>
              </InputAppend>
            </InputBlock>
          </ItemStackBlock>
        </InputGroupBlock>
      </ItemStackBlock>

      <Divider />
      <InputGroupBlock>
        <InputBlock backgroundColor="WHITE" borderColor="GRAY900">
          <Input placeholder="..." />
        </InputBlock>
      </InputGroupBlock>

      <Divider />
      <InputGroupBlock error={true}>
        <InputBlock backgroundColor="WHITE" borderColor="GRAY900">
          <Input placeholder="..." />
        </InputBlock>
        <InputMessage>Hoppla, da ist wohl was schief gegangen!</InputMessage>
      </InputGroupBlock>

      <Divider />

      <Headline.Large>Image Card</Headline.Large>

      <TwoColumnBlock gap="M">
        <ImageCard square={true}>
          <ImageCardImage src={'https://picsum.photos/id/110/200/300'} />
        </ImageCard>

        <ImageCard square={true}>
          <ImageCardImage src={'https://picsum.photos/id/23/600/400'} />
        </ImageCard>

        <ImageCard rounded={true} square={true}>
          <ImageCardImage src={'https://picsum.photos/id/236/600/400'} />
        </ImageCard>

        <ImageCard rounded={true} square={true}>
          <ImageCardBody icon="HOURGLASS">In Verarbeitung</ImageCardBody>
        </ImageCard>

        <ImageCard rounded={true} square={true}>
          <ImageCardImage src={'https://picsum.photos/id/234/600/400'} />
          <ImageCardStatus>
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
            Platziert
          </ImageCardStatus>
        </ImageCard>

        <ImageCard square={true} dim={true}>
          <ImageCardImage src={'https://picsum.photos/id/237/600/400'} />
          <ImageCardStatus>
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
            Platziert
          </ImageCardStatus>
        </ImageCard>

        <ImageCard rounded={true}>
          <ImageCardStatus>
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
            Platziert
          </ImageCardStatus>
          <ImageCardImage src={'https://picsum.photos/id/137/600/400'} />
        </ImageCard>

        <ImageCard dim={true}>
          <ImageCardImage src={'https://picsum.photos/id/110/200/300'} />
        </ImageCard>

        <ImageCard>
          <ImageCardImage src={'https://picsum.photos/id/23/600/400'} />
        </ImageCard>

        <ImageCard rounded={true} square={true}>
          <ImageCardBody icon="HOURGLASS">In Verarbeitung</ImageCardBody>
        </ImageCard>

        <LabelledImageCard>
          <ImageCard>
            <ImageCardBody icon="HOURGLASS">In Verarbeitung</ImageCardBody>
          </ImageCard>
          <ImageCardLabel>Label</ImageCardLabel>
        </LabelledImageCard>

        <LabelledImageCard>
          <ImageCard square={true}>
            <ImageCardBody icon="HOURGLASS">In Verarbeitung</ImageCardBody>
          </ImageCard>
          <ImageCardLabel>Label</ImageCardLabel>
        </LabelledImageCard>

        <LabelledImageCard>
          <ImageCard square={true}>
            <ImageCardImage src={'https://picsum.photos/id/110/200/300'} />
          </ImageCard>
          <ImageCardLabel>Label</ImageCardLabel>
        </LabelledImageCard>

        <LabelledImageCard>
          <ImageCard square={true}>
            <ImageCardImage src={'https://picsum.photos/id/110/200/300'} />
          </ImageCard>
          <ImageCardLabel>Label</ImageCardLabel>
        </LabelledImageCard>

        <ImageCard contain={true}>
          <ImageCardImage src={'https://picsum.photos/id/23/600/400'} />
        </ImageCard>

        <ImageCard>
          <ImageCardImage src={'https://picsum.photos/id/23/600/400'} />
        </ImageCard>
      </TwoColumnBlock>

      <Divider />

      <Headline.Large>Discount Card</Headline.Large>

      <SliderBlock gap="S">
        <DiscountCard>
          <DiscountCardInfo>
            <Headline.Medium>100</Headline.Medium>
            <Paragraph size="L" color="TEXT_LIGHT" >
              2 of 6
            </Paragraph>
          </DiscountCardInfo>
          <DiscountCardFooter>Treue-Rabatt</DiscountCardFooter>
        </DiscountCard>
        <DiscountCard>
          <DiscountCardInfo>
            <Headline.Medium>100</Headline.Medium>
            <Paragraph size="L" color="TEXT_LIGHT" >
              2 of 6
            </Paragraph>
          </DiscountCardInfo>
          <IconComponent size={1.5} icon="LOCK" />
          <DiscountCardFooter>Treue-Rabatt</DiscountCardFooter>
        </DiscountCard>
        <DiscountCard background="BLACK_003">
          <DiscountCardInfo>
            <Headline.Medium>100</Headline.Medium>
          </DiscountCardInfo>
          <IconComponent size={1.5} icon="TICK_CIRCLE" fill="GREEN" />
          <DiscountCardFooter>
            Empfehlungsbonus wenn etwas länger
          </DiscountCardFooter>
        </DiscountCard>
        <DiscountCard background="BLACK_003">
          <DiscountCardInfo>
            <Headline.Medium color="BLACK">100€</Headline.Medium>
          </DiscountCardInfo>
          <IconComponent size={1.5} icon="LOCK" />
          <DiscountCardFooter>Sponsoren</DiscountCardFooter>
        </DiscountCard>
      </SliderBlock>

      <Divider />

      <Headline.Large>EveryCard</Headline.Large>

      <ItemStackBlock gap="L">
        <Headline.Medium>ImageCards</Headline.Medium>
        <EveryCard>
          <EveryCardPadding>
            <ImageBlock
              background="GRAY950"
              width={3.0625}
              height={3.0625}
              cover={true}
              radius={true}
            >
              <img src="https://picsum.photos/id/237/300/200" alt="" />
            </ImageBlock>
            <EveryCardBody>
              <EveryCardHeadline>Text</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="TICK_CIRCLE" fill="GREEN" /> Platziert
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>

        <EveryCard>
          <EveryCardPadding>
            <ImageBlock
              background="GRAY950"
              width={3.0625}
              height={3.0625}
              cover={true}
              radius={true}
            >
              <Paragraph size="L" color="GRAY800">
                1
              </Paragraph>
            </ImageBlock>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="TICK_CIRCLE" fill="GREEN" /> Platziert
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>

        <EveryCard>
          <EveryCardPadding>
            <ImageBlock
              background="GRAY950"
              width={3.0625}
              height={3.0625}
              cover={true}
              radius={true}
            >
              <IconComponent icon="HOURGLASS" fill="PRIMARY" size={2} />
            </ImageBlock>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="TICK_CIRCLE" fill="GREEN" /> In
                Verarbeitung
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <ImageBlock
              background="GRAY950"
              width={2.125}
              height={3.0625}
              cover={true}
            >
              <Paragraph size="L" color="GRAY800">
                1
              </Paragraph>
            </ImageBlock>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="warning_circle" fill="PRIMARY" /> Foto
                platzieren
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <ImageBlock background="GRAY900" width={2.125} height={3.0625}>
              <img src="https://picsum.photos/id/1035/200/300" alt="" />
            </ImageBlock>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="TICK_CIRCLE" fill="GREEN" /> Platzieren
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <ImageBlock background="GRAY900" width={2.125} height={3.0625}>
              <img src="https://picsum.photos/id/1033/200/300" alt="" />
            </ImageBlock>

            <EveryCardBody>
              <EveryCardHeadline>Text</EveryCardHeadline>
              <Paragraph size="S" color="TEXT_LIGHT">
                Description
              </Paragraph>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
        <Headline.Medium>NeutralCards</Headline.Medium>
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Steckbrief</EveryCardHeadline>
              <Paragraph size="S" color="TEXT_LIGHT">
                Description
              </Paragraph>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Steckbrief</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="TICK_CIRCLE" fill="GREEN" /> Platzieren
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
        <Headline.Medium>TagCards</Headline.Medium>
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
            </EveryCardBody>
          </EveryCardPadding>
          <EveryCardFooter>
            <TextElement color="TEXT_DARK">
              <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
              The Steckbrief will not be printed.
            </TextElement>
          </EveryCardFooter>
        </EveryCard>

        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
            </EveryCardBody>
            <TagBlock color="PURPLE" backgroundColor="PURPLE_LIGHT">
              Orga
            </TagBlock>
          </EveryCardPadding>
          <EveryCardFooter>
            <TextElement color="TEXT_DARK">
              <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
              The Steckbrief will not be printed.
            </TextElement>
          </EveryCardFooter>
        </EveryCard>

        <EveryCard topAlign={true}>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="warning_circle" fill="PRIMARY" /> Value
              </TextElement>
            </EveryCardBody>
            <TagBlock color="PURPLE" backgroundColor="PURPLE_LIGHT">
              Orga
            </TagBlock>
          </EveryCardPadding>
          <EveryCardFooter>
            <TextElement color="TEXT_DARK">
              <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
              The Steckbrief will not be printed.
            </TextElement>
          </EveryCardFooter>
        </EveryCard>
        <EveryCard topAlign={true}>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <Paragraph size="S" color="TEXT_LIGHT">
                Description
              </Paragraph>
            </EveryCardBody>
            <TagBlock color="PRIMARY" backgroundColor="PRIMARY_LIGHT">
              Neu
            </TagBlock>
          </EveryCardPadding>
        </EveryCard>

        <EveryCard topAlign={true}>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <Paragraph size="S" color="TEXT_LIGHT">
                Description
              </Paragraph>
            </EveryCardBody>
            <TagBlock color="GRAY400" backgroundColor="GRAY950">
              1
            </TagBlock>
          </EveryCardPadding>
        </EveryCard>

        <EveryCard topAlign={true}>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <Paragraph size="S" color="TEXT_LIGHT">
                Description
              </Paragraph>
            </EveryCardBody>
            <TagBlock color="BLACK" backgroundColor="RED_LIGHT">
              <IconComponent icon="warning_circle" fill="RED" /> Value
            </TagBlock>
          </EveryCardPadding>
        </EveryCard>

        <Headline.Medium>IconCards</Headline.Medium>

        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
            </EveryCardBody>
            <IconComponent icon="HOURGLASS" fill="PRIMARY" size={1.5} />
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <Paragraph size="S" color="TEXT_LIGHT">
                Description
              </Paragraph>
            </EveryCardBody>
            <IconComponent icon="HOURGLASS" fill="PRIMARY" size={1.5} />
          </EveryCardPadding>
        </EveryCard>
        <EveryCard selected={true}>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
            </EveryCardBody>
            <IconComponent icon="HOURGLASS" fill="PRIMARY" size={1.5} />
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <Paragraph size="S" color="TEXT_LIGHT">
                Description
              </Paragraph>
            </EveryCardBody>
            <IconComponent icon="HOURGLASS" fill="PRIMARY" size={1.5} />
          </EveryCardPadding>
        </EveryCard>

        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
            </EveryCardBody>
            <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={2} />
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
            </EveryCardBody>
            <IconComponent icon="DRAG" fill="GRAY800" size={1.5} />
          </EveryCardPadding>
        </EveryCard>
        <EveryCard topAlign={true}>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>Value</EveryCardHeadline>
              <Paragraph size="S" color="TEXT_LIGHT">
                Description
              </Paragraph>
            </EveryCardBody>
            <IconComponent icon="DRAG" fill="GRAY800" size={1.5} />
          </EveryCardPadding>
        </EveryCard>

        <Headline.Medium>ChapterCards</Headline.Medium>

        <EveryCard>
          <EveryCardPadding>
            <IconComponent
              circleBackground="PRIMARY_LIGHT"
              icon="CHAPTER_FACTSHEETS"
              fill="PRIMARY"
              size={3}
            />
            <EveryCardBody>
              <EveryCardHeadline>
                Steckbrief Lorem ipsum dolor, sit amet consectetur adipisicing
                elit. Maxime officiis, quos unde, laborum mollitia doloremque
                amet quaerat ex vitae, iusto ullam distinctio id sunt natus
                minus et earum. Aliquid amet repudiandae reiciendis molestiae?
                Qui aliquid eligendi nisi? In, dolore minima nam natus tempore
                obcaecati. Molestias recusandae incidunt ducimus odit nobis.
              </EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="USERGROUP" fill="BLACK" /> Alle
              </TextElement>
            </EveryCardBody>
            <IconComponent icon="DRAG" fill="GRAY800" size={1.5} />
          </EveryCardPadding>
        </EveryCard>

        <EveryCard>
          <EveryCardPadding>
            <IconComponent
              circleBackground="PRIMARY_LIGHT"
              icon="CHAPTER_RANKINGS"
              fill="PRIMARY"
              size={3}
            />
            <EveryCardBody>
              <EveryCardHeadline>Ranking</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="USERGROUP" fill="BLACK" /> Alle
              </TextElement>
            </EveryCardBody>
            <IconComponent icon="DRAG" fill="GRAY800" size={1.5} />
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <IconComponent
              circleBackground="PRIMARY_LIGHT"
              icon="CHAPTER_TEXT"
              fill="PRIMARY"
              size={3}
            />
            <EveryCardBody>
              <EveryCardHeadline>Text</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="USERGROUP" fill="BLACK" /> Organisatoren
              </TextElement>
            </EveryCardBody>
            <IconComponent icon="DRAG" fill="GRAY800" size={1.5} />
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <IconComponent
              circleBackground="PRIMARY_LIGHT"
              icon="CHAPTER_FACTSHEETS"
              fill="PRIMARY"
              size={3}
            />
            <EveryCardBody>
              <EveryCardHeadline>Text</EveryCardHeadline>
              <Paragraph size="S" color="TEXT_LIGHT">
                Description
              </Paragraph>
            </EveryCardBody>
          </EveryCardPadding>
          <EveryCardFooter>
            <TextElement color="TEXT_DARK">
              <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
              The Steckbrief will not be printed
            </TextElement>
          </EveryCardFooter>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <IconComponent
              circleBackground="PRIMARY_LIGHT"
              icon="CHAPTER_FACTSHEETS"
              fill="PRIMARY"
              size={3}
            />
            <EveryCardBody>
              <EveryCardHeadline>Text</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="USERGROUP" fill="BLACK" /> Alle
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
        <EveryCard>
          <EveryCardPadding>
            <IconComponent
              circleBackground="GRAY950"
              icon="CHAPTER_FACTSHEETS"
              fill="GRAY800"
              size={3}
            />
            <EveryCardBody>
              <EveryCardHeadline>Text</EveryCardHeadline>
              <TextElement color="TEXT_DARK">
                <IconComponent icon="LOCK" fill="BLACK" /> Value
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
          <EveryCardFooter>
            <TextElement color="TEXT_DARK">
              <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
              The Steckbrief will not be printed
            </TextElement>
          </EveryCardFooter>
        </EveryCard>
      </ItemStackBlock>

      <Divider />

      <Headline.Large>UppercaseHeading</Headline.Large>
      <UppercaseHeading>UppercaseHeading</UppercaseHeading>

      <Divider />

      <Headline.Large>WideBlock, padded</Headline.Large>
      <WideBlock padded={true}>
        <ItemStackBlock gap="S">
          <UppercaseHeading>
            UppercaseHeading light
          </UppercaseHeading>
          <Paragraph>The quick brown fox jumps over the lazy dog.</Paragraph>
        </ItemStackBlock>
      </WideBlock>

      <Divider />

      <Headline.Large>TeaserCard</Headline.Large>
      <ItemStackBlock gap="L">
        <WideBlock>
          <TeaserCard>
            <TeaserCard.CardInfo>
              <Headline.Medium color="WHITE">
                Entdecke den Layout-Editor
              </Headline.Medium>
              <Paragraph size="L" color="WHITE">
                Du siehst sofort eine Vorschau deiner Inhalte im Layout deiner
                Zeitung und kannst das Design bearbeiten.
              </Paragraph>
              <ButtonBlock background="PRIMARY" color="WHITE">
                Zum Layout Editor
              </ButtonBlock>
            </TeaserCard.CardInfo>
          </TeaserCard>
        </WideBlock>

        <WideBlock>
          <TeaserCard>
            <TeaserCard.CardInfo>
              <Headline.Medium color="WHITE">
                Entdecke unsere Design-Themes
              </Headline.Medium>
              <Paragraph size="L" color="WHITE">
                Du siehst sofort eine Vorschau deiner Inhalte im Layout deiner
                Zeitung und kannst das Design bearbeiten.
              </Paragraph>
              <ButtonBlock background="PRIMARY" color="WHITE">
                Design-Themes ansehen
              </ButtonBlock>
            </TeaserCard.CardInfo>
          </TeaserCard>
        </WideBlock>
      </ItemStackBlock>

      <Divider />

      <Headline.Large>ExpertsBlock</Headline.Large>
      <ItemStackBlock gap="L">
        <ExpertsBlock>
          <ExpertsBlock.AvatarInfo>
            <SliderBlock gap="S">
              {/* <AvatarBlock> */}
              <ImageBlock width={3.0625} height={3.0625} cover={true}>
                {/* <img src="https://picsum.photos/id/237/300/200" alt="" /> */}
                <img src="/images/expert1.png" alt="" />
              </ImageBlock>
              {/* </AvatarBlock> */}
              <AvatarBlock>
                <ImageBlock width={3.0625} height={3.0625} cover={true}>
                  {/* <img src="https://picsum.photos/id/1062/300/200" alt="" /> */}
                  <img src="/images/expert2.png" alt="" />
                </ImageBlock>
              </AvatarBlock>
              <AvatarBlock>
                <ImageBlock width={3.0625} height={3.0625} cover={true}>
                  {/* <img src="https://picsum.photos/id/64/300/200" alt="" /> */}
                  <img src="/images/expert3.png" alt="" />
                </ImageBlock>
              </AvatarBlock>
            </SliderBlock>
            <Paragraph  color="TEXT_LIGHT">nuggit Produkt-Experten</Paragraph>
          </ExpertsBlock.AvatarInfo>
          <ExpertsBlock.TextBlock>
            <Headline.Medium color="BLACK">
              Wir melden uns gleich
            </Headline.Medium>
            <Paragraph size="L"  color="TEXT_LIGHT">
              Einer unserer Produkt-Experten wird dich telefonisch oder auf
              WhatsApp zu deiner Anfrage kontaktieren.
            </Paragraph>
            <ButtonBlock background="PRIMARY" color="WHITE">
              Okay
            </ButtonBlock>
          </ExpertsBlock.TextBlock>
        </ExpertsBlock>
      </ItemStackBlock>
    </>
  );
}

export default Redesign2020;
