import { inject, observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ImageBlock from 'blocks/ImageBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import WideBlock from 'blocks/WideBlock';
import ConfirmBox from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import InfoBoxComponent from 'components/InfoBoxComponent/InfoBoxComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import AlbumPagePreviewComponent from 'components/print/preview/AlbumPagePreviewComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import { ALBUM_PAGE_GRIDS } from 'components/print/templates/album-page-grids';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import PhotoEditScreen from 'domain/PhotoEditScreen/PhotoEditScreen';
import Headline from 'elements/Headline';
import NakedButton from 'elements/NakedButton';
import UppercaseHeading from 'elements/UppercaseHeading';
import { AlbumPageModelType } from 'models/AlbumPageModel';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChapterModelType } from 'models/ChapterModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { PhotoModelType } from 'models/PhotoModel';
import { PhotosStoreType } from 'screens/photos/PhotosStore';
import PhotosListItem from 'screens/photos/screens/PhotosList/PhotosListItem';
import { assert } from 'utils/assert';
import { ROUTE_ALBUMS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { AlbumPagesStoreType, LoadingState } from './AlbumPagesStore';
import reduceAlbumPagePhotos from './reduceAlbumPagePhotos';

interface Props {
  chaptersStore: ChaptersStoreType;
  albumPagesStore: AlbumPagesStoreType;
  photosStore: PhotosStoreType;
  applicationStore: ApplicationStoreType;
}

interface State {
  currentScreen: 'selectPosition' | 'editSlot' | 'editPhoto';
  selectedPosition?: number;
  selectedPhoto?: PhotoModelType;
  confirmRemove: boolean;
}

@inject('chaptersStore', 'albumPagesStore', 'photosStore', 'applicationStore')
@observer
class AlbumPage extends Component<Props & HistoryProps, State> {
  state: State = {
    currentScreen: 'selectPosition',
    selectedPosition: undefined,
    selectedPhoto: undefined,
    confirmRemove: false
  };

  componentDidMount() {
    if (
      this.props.applicationStore.onboardFeature(
        'albumpages',
        this.props.location?.pathname
      )
    ) {
      return;
    }

    this.loadInitialData();
  }

  loadInitialData() {
    this.getAlbumPage();
    this.getChapter();
  }

  getAlbumPageId() {
    const id = idFromMatch(this.props.match);
    assert(id, 'Album page id not given');
    return id;
  }

  getChapterId() {
    const id = chapterIdFromMatch(this.props.match);
    assert(id, 'Chapter id not given');
    return id;
  }

  getAlbumPage() {
    this.props.albumPagesStore.getAlbumPage(this.getAlbumPageId());
  }

  getChapter() {
    this.props.chaptersStore.getChapter(this.getChapterId());
  }

  selectPosition(id: number) {
    this.setState({ currentScreen: 'editSlot', selectedPosition: id });
    this.loadPhotos();
  }

  loadPhotos() {
    this.props.photosStore.getUnplacedPhotosByChapter(this.getChapterId());
  }

  editPhoto(photo: PhotoModelType) {
    this.setState({ currentScreen: 'editPhoto', selectedPhoto: photo });
  }

  placePhoto = () => {
    const { albumPage } = this.props.albumPagesStore;
    const { selectedPhoto, selectedPosition } = this.state;
    assert(albumPage && selectedPhoto && selectedPosition);

    this.props.albumPagesStore.placePhoto(selectedPhoto, selectedPosition);

    this.setState({ currentScreen: 'selectPosition' });
  };

  removePhoto(photo: PhotoModelType) {
    const { albumPage } = this.props.albumPagesStore;
    assert(albumPage);
    this.props.albumPagesStore.removePhoto(photo);
    this.setState({ currentScreen: 'selectPosition' });
  }

  startRemove() {
    this.setState({ confirmRemove: true });
  }

  async removeAlbumPage() {
    await this.props.albumPagesStore.removeAlbumPage();
    this.props.history.push(`${ROUTE_ALBUMS}/${this.getChapterId()}`);
  }

  finishRemove() {
    this.setState({ confirmRemove: false });
  }

  // Render methods
  // --------------

  renderBackToChapter() {
    return (
      <TopActionBlock>
        <BackLink to={`${ROUTE_ALBUMS}/${this.getChapterId()}`} />
      </TopActionBlock>
    );
  }

  renderBackToScreen(screen: State['currentScreen']) {
    return (
      <TopActionBlock>
        <NakedButton onClick={() => this.setState({ currentScreen: screen })}>
          <TextElement  color="GRAY10">
            <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />
            <FormattedMessage id="Back" />
          </TextElement>
        </NakedButton>
      </TopActionBlock>
    );
  }

  renderSelectPosition(
    chapter: ChapterModelType,
    albumPage: AlbumPageModelType
  ): React.ReactNode {
    const { album_layout_key } = albumPage;
    const gridTemplate = ALBUM_PAGE_GRIDS[album_layout_key];

    const photos = reduceAlbumPagePhotos(albumPage);

    if (!gridTemplate) {
      return (
        <InfoBoxComponent error={true}>
          Album layout {album_layout_key} not found
        </InfoBoxComponent>
      );
    }
    return (
      <>
        <Headline.Large>
          <FormattedMessage id="Album page select position heading" />
        </Headline.Large>

        <WideBlock padded={true}>
          <ItemStackBlock gap="S">
            <UppercaseHeading>
              <FormattedMessage id="Page preview" />
            </UppercaseHeading>
            <FontPreloadComponent chapter={chapter}>
              <AlbumPagePreviewComponent
                chapter={chapter}
                layoutKey={album_layout_key}
                positionMode={true}
                images={photos}
              />
            </FontPreloadComponent>
          </ItemStackBlock>
        </WideBlock>

        <Headline.Medium>
          <FormattedMessage id="Album page slots" />
        </Headline.Medium>

        <ListStackBlock>
          {gridTemplate.slots.map((slot) => {
            const albumPagePhoto = albumPage.album_page_photos.find(
              (candidate) => candidate.position === slot.id
            );
            const photoPreviewURL = albumPagePhoto?.photo?.photo?.preview;
            return (
              <EveryCard
                key={slot.id}
                as="button"
                onClick={() => this.selectPosition(slot.id)}
              >
                <EveryCardPadding>
                  <ImageBlock
                    background="GRAY950"
                    width={4}
                    height={4}
                    cover={true}
                    radius={true}
                  >
                    {photoPreviewURL && <img src={photoPreviewURL} alt="" />}
                  </ImageBlock>

                  <EveryCardBody>
                    <Headline.Small color="BLACK">
                      <FormattedMessage
                        id="Album page select position button"
                        values={{ slot: slot.id }}
                      />
                    </Headline.Small>
                      <TextElement  color="TEXT_DARK">
                      {photos[slot.id] ? (
                        <>
                          <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
                          <FormattedMessage id="Placed" />
                        </>
                      ) : (
                        <>
                          <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />{' '}
                          <FormattedMessage id="Place now" />
                        </>
                      )}
                    </TextElement>
                  </EveryCardBody>
                </EveryCardPadding>
              </EveryCard>
            );
          })}
        </ListStackBlock>

        <Divider />

        <DeleteButton
          onClick={() => this.startRemove()}
          messageId="Album page remove album"
        />

        {this.state.confirmRemove && (
          <ConfirmBox
            header={<FormattedMessage id="confirm delete header" />}
            text={
              <FormattedMessage
                id="confirm delete item"
                values={{
                  item: <FormattedMessage id="This album page" />
                }}
              />
            }
            confirmText={<FormattedMessage id="Remove" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => this.removeAlbumPage()}
            onAbort={() => this.finishRemove()}
            confirmColor="RED"
          />
        )}
      </>
    );
  }

  renderEditSlot(albumPage: AlbumPageModelType): React.ReactNode {
    const { photos, isPhotosLoading, isPhotosError } = this.props.photosStore;

    const { selectedPosition } = this.state;
    const albumPagePhotoInPosition = albumPage.album_page_photos.find(
      (candidate) => candidate.position === selectedPosition
    );
    const photoInPosition = albumPagePhotoInPosition?.photo;

    return (
      <>
        <PageHeader
          headline={<FormattedMessage id="Album page edit slot heading" />}
          text={<FormattedMessage id="Album page edit slot select photo" />}
        />

        {/* Photo in current slot */}
        {photoInPosition && (
          <>
            <ImageBlock
              height={20.875}
              background="GRAY950"
              fullWidth={true}
              onClick={() => this.editPhoto(photoInPosition)}
            >
              <img src={photoInPosition.photo!.preview} alt="" />
            </ImageBlock>

            <ItemStackBlock gap="XS">
              <ButtonBlock
                background="PRIMARY"
                onClick={() => this.editPhoto(photoInPosition)}
              >
                <FormattedMessage id="Album page edit slot edit photo" />
              </ButtonBlock>
              <DeleteButton
                onClick={() => this.removePhoto(photoInPosition)}
                messageId="Album page edit slot remove photo"
                inline={false}
              />
            </ItemStackBlock>
          </>
        )}

        {/* Photo list */}
        {isPhotosLoading && <LoadingIndicatorComponent />}
        {isPhotosError && (
          <GenericErrorComponent onRetryClick={() => this.loadPhotos()} />
        )}
        {photos && photos.size > 0 && (
          <div>
            {photoInPosition && (
              <Headline.Medium>
                <FormattedMessage id="Album page edit slot select other photo" />
              </Headline.Medium>
            )}
            <TwoColumnBlock gap="M">
              {Array.from(photos.values(), (photo) => (
                <NakedButton
                  key={photo.id}
                  onClick={() => this.editPhoto(photo)}
                >
                  <PhotosListItem photo={photo} />
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </div>
        )}
        {photos && photos.size === 0 && (
          <Paragraph>
            <FormattedMessage id="no photos in album" />
          </Paragraph>
        )}
      </>
    );
  }

  getFrameSize(width: number, height: number) {
    // 280x280px is max Framesize
    const frameSize = {
      width: 280,
      height: 280
    };
    if (width > height) {
      frameSize.height = (height / width) * 280;
    } else if (height > width) {
      frameSize.width = (width / height) * 280;
    }

    return frameSize;
  }

  renderEditPhoto(albumPage: AlbumPageModelType): React.ReactNode {
    const { selectedPhoto, selectedPosition } = this.state;
    const { album_layout_key } = albumPage;
    const gridTemplate = ALBUM_PAGE_GRIDS[album_layout_key];
    const foundSlot = gridTemplate.slots.find(
      (slot) => slot.id === selectedPosition
    );
    if (!selectedPhoto || !selectedPosition || !foundSlot) {
      return <GenericErrorComponent />;
    }
    const [width, height] = foundSlot.size;

    if (!gridTemplate) {
      return (
        <InfoBoxComponent error={true}>
          Layout {album_layout_key} not found
        </InfoBoxComponent>
      );
    }

    const frameSize = this.getFrameSize(width, height);
    const showEditCaption = gridTemplate.slots[0].caption !== undefined;

    return (
      <PhotoEditScreen
        photoId={selectedPhoto.id}
        photoType="album"
        parentId={albumPage.id}
        frameWidth={frameSize.width}
        frameHeight={frameSize.height}
        allowDelete={false}
        allowEditCaption={showEditCaption}
        fitToFrameEnabled={true}
        isImageRounded={false}
        isEditorEnabled={true}
        backIsCancel={false}
        handleSave={this.placePhoto}
      />
    );
  }

  renderPage(content: ReactNode, backLink: ReactNode) {
    return (
      <>
        {backLink}
        <PageStackBlock>
          {content}
          <HelpSubject subject="album" />
        </PageStackBlock>
      </>
    );
  }

  render(): React.ReactNode {
    const {
      item: chapter,
      itemLoadingState: chapterLoadingState
    } = this.props.chaptersStore;
    const { albumPage, albumPageLoadingState } = this.props.albumPagesStore;
    if (
      chapterLoadingState === LoadingState.ERROR ||
      albumPageLoadingState === LoadingState.ERROR
    ) {
      return (
        <>
          {this.renderBackToChapter()}
          <GenericErrorComponent onRetryClick={() => this.loadInitialData()} />
        </>
      );
    }
    if (!chapter || !albumPage) {
      return (
        <>
          {this.renderBackToChapter()}
          <LoadingIndicatorComponent />
        </>
      );
    }

    const { currentScreen } = this.state;
    if (currentScreen === 'selectPosition') {
      return this.renderPage(
        this.renderSelectPosition(chapter, albumPage),
        this.renderBackToChapter()
      );
    }
    if (currentScreen === 'editSlot') {
      return this.renderPage(
        this.renderEditSlot(albumPage),
        this.renderBackToScreen('selectPosition')
      );
    }
    if (currentScreen === 'editPhoto') {
      return this.renderPage(
        this.renderEditPhoto(albumPage),
        this.renderBackToScreen('editSlot')
      );
    }
    return null;
  }
}

export default AlbumPage;
