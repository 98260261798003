import { inject, observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import RankingsPreviewComponent from 'components/print/layout-editor/RankingsPreviewComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import ChapterPreviewComponent from 'domain/ChapterPreviewComponent';
import BackToContentLink from 'domain/Links/BackToContentLink';
import Headline from 'elements/Headline';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChapterModelType } from 'models/ChapterModel';
import { RankingsStoreType } from 'screens/rankings/RankingsStore';
import { ROUTE_RANKINGS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import useForm, { FormType } from 'utils/hooks/useForm';

import Divider from 'components/Divider/Divider';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import RankingsListItem from './RankingsListItem';

interface RankingsListProps {
  rankingsStore: RankingsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('rankingsStore', 'applicationStore')
@observer
class RankingsList extends Component<RankingsListProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore, rankingsStore } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature('rankings', this.props.location.pathname)
    ) {
      return;
    }

    if (!rankingsStore.isListLoading) {
      if (!rankingsStore.rankings || isPush(this.props.history)) {
        this.loadRankings();
      }
    }
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const chapterId = chapterIdFromMatch(this.props.match);
    const prevChapterId = chapterIdFromMatch(prevProps.match);

    if (chapterId !== prevChapterId) {
      this.loadRankings();
    }
  }

  loadRankings() {
    const { rankingsStore } = this.props;
    const chapterId = chapterIdFromMatch(this.props.match);

    chapterId
      ? rankingsStore.getRankingsByChapter(chapterId)
      : rankingsStore.getAllRankings();
  }

  chapterUpdated(chapter: ChapterModelType) {
    this.props.rankingsStore.setChapter(chapter);
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadRankings()} />
    );
  }

  renderEmpty() {
    return this.renderPage(
      <>
        <Divider />

        <EmptyStateComponent
          headerId="empty rankings header"
          textId="empty rankings"
          showText={this.props.applicationStore.isOrganizer}
        />
      </>
    );
  }

  renderList() {
    const { rankingsStore, applicationStore, form } = this.props;
    const chapter = rankingsStore.chapter;


    let items;
    let heading;

    if (!form.values.filter) {
      items = rankingsStore.allRankings;

      if (items.length === 0) {
        return this.renderEmpty();
      }
    } else {
      items = rankingsStore.filteredRankings(form.values.filter);

      heading = (
        <FormattedMessage
          id="rankings filter count"
          values={{
            count: items.length
          }}
        />
      );
    }

    const { currentUser, isBookLocked } = applicationStore;
    return this.renderPage(
      <ItemStackBlock gap="L">

        <Divider />

        <SearchListInput form={form} placeholderId="Find ranking" />

        {currentUser?.isOrganizer && rankingsStore.allRankingsCount > 1 && (
          <>
            <LinkBlock
              inline={true}
              slim={true}
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              to={ROUTE_RANKINGS + '/' + chapter!.id + '/sort'}
            >
              <IconComponent icon="sort_v" />
              <FormattedMessage id="Sorting order" />
            </LinkBlock>
          </>
        )}

        <ItemStackBlock gap="S">
          <UppercaseHeading>
            {heading ? (
              heading
            ) : applicationStore.isOrganizer ? (
              <FormattedMessage
                id="rankings count"
                values={{
                  count: rankingsStore.voteStats.count
                }}
              />
            ) : (
              <FormattedMessage
                id="rankings stats"
                values={rankingsStore.voteStats}
              />
            )}
          </UppercaseHeading>

          <ListStackBlock>
            {items.map((item) => (
              <RankingsListItem
                key={item.id}
                ranking={item}
                currentUser={currentUser}
                locked={isBookLocked}
              />
            ))}
          </ListStackBlock>
        </ItemStackBlock>
      </ItemStackBlock>
    );
  }

  renderPage(content: ReactNode) {
    const { applicationStore, rankingsStore } = this.props;
    const chapter = rankingsStore.chapter;

    const isEditAllowed = chapter && applicationStore.isChapterEditAllowed(chapter);

    return (
      <>
        <TopActionBlock>
          <BackToContentLink />
        </TopActionBlock>

        <PageStackBlock>
          <ChapterPreviewComponent
            chapter={chapter}
            defaultTextId="Rankings"
            baseRoute={ROUTE_RANKINGS}
            onChapterUpdated={(updatedChapter) =>
              this.chapterUpdated(updatedChapter)
            }
            marginBottom="M"
          >
            {chapter?.layout?.layoutDefinition && (
              <FontPreloadComponent chapter={chapter} numPages={1}>
                <SliderBlock>
                  <RankingsPreviewComponent
                    scaled={true}
                    config={chapter.layout.layoutDefinition}
                    chapter={chapter}
                    rankings={rankingsStore.printedRankings}
                  />
                </SliderBlock>
              </FontPreloadComponent>
            )}
          </ChapterPreviewComponent>

          {applicationStore.isOrganizer && (
            <>
              <Divider />

              <ItemStackBlock gap="XS">
                <Headline.Medium>
                  <FormattedMessage id="Categories" />
                </Headline.Medium>

                <Paragraph color="TEXT_LIGHT">
                  <FormattedMessage id="ranking organizer info" />
                </Paragraph>
              </ItemStackBlock>

              {isEditAllowed && (
                <>
                  <LinkBlock
                    background="PRIMARY"
                    color="WHITE"
                    to={ROUTE_RANKINGS + '/' + chapter!.id + '/new'}
                  >
                    <FormattedMessage id="Add ranking" />
                  </LinkBlock>

                  <Link to={ROUTE_RANKINGS + '/' + chapter!.id + '/ideas'}>
                    <EveryCard>
                      <EveryCardPadding>
                        <EveryCardBody>
                          <EveryCardHeadline>
                            <FormattedMessage id="ranking ideas" />
                          </EveryCardHeadline>
                          <TextElement>
                            <FormattedMessage id="ranking ideas info" />
                          </TextElement>
                        </EveryCardBody>
                        <IconComponent icon="LIGHTBULB" fill="BLACK" size={2} />
                      </EveryCardPadding>
                    </EveryCard>
                  </Link>
                </>
              )}
            </>
          )}

          {content}
        </PageStackBlock>


        <HelpSubject subject="rankings" />
      </>
    );
  }

  render() {
    const { rankingsStore } = this.props;

    if (rankingsStore.isListLoading) {
      return this.renderLoading();
    }

    if (rankingsStore.isListError) {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default (props: any) => {
  const form = useForm();
  return <RankingsList {...props} form={form} />;
};
