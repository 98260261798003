import React from 'react';

import LayoutEditorComponent from 'components/print/layout-editor/LayoutEditorComponent';
import { CHAPTER_TYPES_WITH_LAYOUT_EDITOR } from 'models/ChapterModel';
import SettingNotAvailableComponent from '../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from './SettingsDetailProps';

export default ({
  chapter,
  onQuit,
  backLink,
}: SimpleSettingsDetailProps & { backLink?: any }) => {
  if (CHAPTER_TYPES_WITH_LAYOUT_EDITOR.indexOf(chapter.chapter_type) < 0) {
    return <SettingNotAvailableComponent />;
  }

  return (
    <LayoutEditorComponent
      chapter={chapter}
      backLink={backLink}
      onQuit={onQuit}
    />
  );
};
