import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import GroupsList from 'domain/GroupsList/GroupsList';
import NakedBlockButton from 'elements/NakedBlockButton';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ProfileModelType } from 'models/ProfileModel';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { assert } from 'utils/assert';
import { HistoryProps } from 'utils/history';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import TopActionBlock from 'blocks/TopActionBlock';
import CancelLink from 'domain/Links/CancelLink';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import TextElement from 'components/TextElement/TextElement';
import NakedButton from 'elements/NakedButton';

interface Props {
  profilesStore: ProfilesStoreType;
  students: ProfileModelType[];
  yearbook?: boolean;
}

interface State {
  selectedProfile?: ProfileModelType;
}

@inject('profilesStore')
@observer
class StudentsWithoutGroup extends React.Component<
Props & HistoryProps,
State
> {
  state: State = {};

  selectProfile(profile: ProfileModelType) {
    this.setState({ selectedProfile: profile });
  }

  async addStudentToGroup(id: number) {
    const { selectedProfile } = this.state;
    assert(selectedProfile);
    await this.props.profilesStore.updateProfileGroup(selectedProfile.id, id);
    this.setState({ selectedProfile: undefined });
  }

  cancelLink() {
    if (this.state.selectedProfile) {
      return (
        <NakedButton onClick={() => this.setState({ selectedProfile: undefined })}>
          <TextElement color="GRAY10">
            <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
            <FormattedMessage id="Cancel" />
          </TextElement>
        </NakedButton>
      )
    } else {
      return (
        <CancelLink to={ROUTE_SETTINGS + "/groups"} />
      )
    }
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          {this.cancelLink()}
        </TopActionBlock>

        {content}
      </>
    );
  }


  renderGroupList() {
    const { isItemLoading, isItemError } = this.props.profilesStore;

    if (isItemLoading) {
      return <LoadingOverlayComponent />;
    }

    const { selectedProfile } = this.state;
    assert(selectedProfile);

    return (
      <PageStackBlock>
        <PageHeader headline={<FormattedMessage id="Select group for student" />} text={<>
          <IconComponent icon="USER" />
          <FormattedMessage
            id="Select group for student name"
            values={{
              name: `${selectedProfile.first_name} ${selectedProfile.last_name}`
            }}
          /></>} />
        {isItemError && <GenericErrorComponent />}

        <GroupsList
          allowAdd={false}
          onGroupClick={(_event, id) => this.addStudentToGroup(id)}
          showGroupsCount={true}
        />

      </PageStackBlock>
    );
  }

  renderStudentList() {
    const { students, yearbook } = this.props;

    return (
      <PageStackBlock>
        <PageHeader headline={<FormattedMessage id="Students without groups" />} text={<FormattedMessage
          id={
            yearbook
              ? 'yearbook select student to set the group'
              : 'factsheet select student to set the group'
          }
        />} />

        <ItemStackBlock gap="XXS">
          <UppercaseHeading>
            {students.length} <FormattedMessage id="Students" />
          </UppercaseHeading>

          <ListStackBlock>
            <ButtonListBlock>
              {students.map((profile) => (
                <ButtonListBlockItem key={profile.id}>
                  <NakedBlockButton onClick={() => this.selectProfile(profile)}>
                    <ButtonListBlockBody>
                      {profile.name || profile.id}
                    </ButtonListBlockBody>
                  </NakedBlockButton>
                </ButtonListBlockItem>
              ))}
            </ButtonListBlock>
          </ListStackBlock>
        </ItemStackBlock>
      </PageStackBlock>
    );
  }

  render() {
    return this.state.selectedProfile
      ? this.renderPage(this.renderGroupList())
      : this.renderPage(this.renderStudentList());
  }
}

export default (props: {
  students: ProfileModelType[];
  yearbook?: boolean;
}) => (
  // @ts-ignore
  <StudentsWithoutGroup {...props} />
);
