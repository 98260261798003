import React, { useEffect } from 'react';

import { TextileCheckout } from 'api/textile_deals/fetchTextileCheckout';
import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import FileUploadComponent from 'components/Inputs/FileUploadComponent';
import { ACCEPT_IMAGES_AND_PDFS } from 'components/Inputs/FileUploadInput';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import { FormattedMessage } from 'react-intl';
import { FormType } from 'utils/hooks/useForm';

interface TextileAgeScreenProps {
  form: FormType;
  updateAge: (minor: boolean) => void;
  reloadData: () => void;
  handleUploadParentalApproval: (file: File) => void;
  textileCheckout: TextileCheckout;
}

const TextileAgeScreen = ({
  form,
  updateAge,
  reloadData,
  handleUploadParentalApproval,
  textileCheckout
}: TextileAgeScreenProps) => {
  const minor = form.bindRadioButton('minor', 'true');
  const ofAge = form.bindRadioButton('minor', 'false');

  if (form.values.minor === true) {
    form.values.minor = 'true';
  }
  if (form.values.minor === false) {
    form.values.minor = 'false';
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderAgeFormInfo = () => {
    return (
      <>
        <Divider />

        <ItemStackBlock gap="XXS">
          <Headline.Medium>
            <FormattedMessage id="Checkout age parental consent" />
          </Headline.Medium>
          <Paragraph size="L" color="TEXT_LIGHT">
            <FormattedMessage id="Checkout age parental consent info textiles" />
          </Paragraph>
        </ItemStackBlock>

        <a
          href="/documents/Einverständniserklärung-nuggit.pdf"
          download="Einverständniserklärung-nuggit.pdf"
          target="_blank"
          rel="noreferrer noopener"
        >
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="Download form" />
                </EveryCardHeadline>
                <TextElement size="M">
                  <FormattedMessage id="PDF from size" />
                </TextElement>
              </EveryCardBody>
              <IconComponent icon="DOWNLOAD" fill="BLACK" size={1.5} />
            </EveryCardPadding>
          </EveryCard>
        </a>

        { textileCheckout.parental_approval && !textileCheckout.parental_approval_checked && (
          <InputMessage warning={true}>
            <FormattedMessage id="Checkout parental approval check" />
          </InputMessage>
        )}

        { textileCheckout.parental_approval_checked && (
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="Form" />
                </EveryCardHeadline>
                <HorizontalStackBlock gap="XS" >
                <IconComponent icon="TICK_CIRCLE" fill='GREEN' />
                <TextElement color="BLACK">
                  <FormattedMessage id="Confirmed" />
                </TextElement>
                </HorizontalStackBlock>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        )}


        <FileUploadComponent
          name="parental_approval"
          accept={ACCEPT_IMAGES_AND_PDFS}
          autoUpload={true}
          onUploadPhoto={handleUploadParentalApproval}
          renderButton={true}
          buttonColor="SECONDARY_DARK"
          {...form.bindInput('parental_approval')}
        />

        <Divider />

        {/* <OrderAgeVerification
          checkout={textileCheckout}
          form={form}
          handleUploadParentalApproval={(file) =>
            handleUploadParentalApproval(file)
          }
        /> */}
      </>
    );
  };

  return (
    <>
      <PageHeader
        headline={<FormattedMessage id="Checkout age header" />}
        text={<FormattedMessage id="Checkout age info" />}
      />

      <FormStackBlock>
        <RadioButtonComponent
          name="minor"
          value="false"
          label={
            <>
              <p>
                <FormattedMessage id="Checkout of age" />
              </p>
            </>
          }
          {...ofAge}
          onClick={() => updateAge(false)}
        />
        <RadioButtonComponent
          name="minor"
          value="true"
          label={
            <>
              <p>
                <FormattedMessage id="Checkout minor" />
              </p>
            </>
          }
          {...minor}
          onClick={() => updateAge(true)}
        />
      </FormStackBlock>


      {(form.values.minor === true || form.values.minor === 'true') && (
        renderAgeFormInfo()
      )}

      <ButtonBlock
          background="PRIMARY"
          onClick={() => {
            reloadData()
          }}
        >
          <FormattedMessage id="Back to checkout" />
        </ButtonBlock>

    </>
  );
};
export default TextileAgeScreen;
