import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

interface SupportButtonBlockProps {
  background?: keyof typeof COLORS;
  color?: keyof typeof COLORS;
  right?: boolean;
}

const SupportButtonBlock = styled.button<SupportButtonBlockProps>`
  position: relative;
  display: block;
  box-shadow: none;
  border: none;
  border-radius: 0.1875em;
  width: auto;
  padding: 0.1875em 0.75em 0.375em 0.75em;
  background: ${props =>
    props.background ? COLORS[props.background] : COLORS.SECONDARY_DARK};
  cursor: pointer;
  font-size: 1em;
  line-height: 1.1875;
  font-weight: 600;
  letter-spacing: 0.03125em;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: ${props => (props.color ? COLORS[props.color] : COLORS.WHITE)};
  ${props =>
    props.right
      ? `
    margin-right: 0.625em;
    margin-left: auto;
  `
      : `
    margin-right: auto;
    margin-left: 0;
  `};

  &:hover::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.2);
  }

  &:focus {
    outline: none;
  }

  svg {
    &:first-child {
      margin-right: 0.5em;
    }

    &:last-child {
      margin-left: 0.5em;
    }
  }

  > span > span {
    letter-spacing: 0.03125em;
  }
`;

export default SupportButtonBlock;
