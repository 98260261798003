import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TextileStudent } from 'api/textile_deals/fetchStudentList';
import {
  TextileOrderForGroups
} from 'api/textile_deals/fetchTextileOrders';
import {
  BaseTextileProfile,
  getAllOrganizers,
  getTextileProfileByTextileOrderId,
  getTextileProfilesByTextileOrderId,
  joinTextileOrder,
  rejectPreorder,
  reopenPreorder,
  submitPreorder,
  updateTextileProfile
} from 'api/textile_deals/fetchTextileProfiles';

export const useCurrentTextileProfileQuery = (
  id?: number,
  textileOrderId?: number
) => {
  return useQuery({
    queryKey: ['current_textile_profile', id],
    queryFn: () => getTextileProfileByTextileOrderId(id!, textileOrderId!),
    enabled: !!textileOrderId && !!id
  });
};

export const useTextileProfileQuery = (
  id?: number,
  textileOrderId?: number
) => {
  return useQuery({
    queryKey: ['textile_profile', id],
    queryFn: () => getTextileProfileByTextileOrderId(id!, textileOrderId!),
    enabled: !!textileOrderId && !!id
  });
};

export const useTextileProfilesQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_profiles', textileOrderId],
    queryFn: () => getTextileProfilesByTextileOrderId(textileOrderId!),
    enabled: !!textileOrderId
  });
};

export const useTextileOrganizerQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_organizers', textileOrderId],
    queryFn: () => getAllOrganizers(textileOrderId!),
    enabled: !!textileOrderId,
    staleTime: 60000 // we dont want to fetch this every time, this data can be stale for a while in the normal app context
  });
};

export const useUpdateTextileProfileQuery = () => {
  return useMutation({
    mutationFn: (patch: { data: any; id: number; textileOrderId: number }) =>
      updateTextileProfile(patch.id, patch.textileOrderId, patch.data)
  });
};

export const useAddTextileProfileToGroupQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { data: any; id: number; textileOrderId: number }) =>
      updateTextileProfile(patch.id, patch.textileOrderId, patch.data),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['student_list', patch.textileOrderId]);
      await queryClient.cancelQueries(['textile_order', patch.textileOrderId]);
      const previousState:
        | TextileStudent[]
        | undefined = queryClient.getQueryData([
        'student_list',
        patch.textileOrderId
      ]);

      const previousStateOrder:
        | TextileOrderForGroups
        | undefined = queryClient.getQueryData([
        'textile_order',
        patch.textileOrderId
      ]);

      if (previousState) {
        queryClient.setQueryData(
          ['student_list', patch.textileOrderId],
          (old?: TextileStudent[]) => old?.filter((t) => t.id !== patch.id)
        );
      }

      if (previousStateOrder) {
        const studentsCount =
          previousStateOrder.students_without_groups_count !== undefined &&
          previousStateOrder.students_without_groups_count > 0
            ? previousStateOrder.students_without_groups_count - 1
            : 0;
        const newState = {
          ...previousStateOrder,
          students_without_groups_count:
            studentsCount
        };
        queryClient.setQueryData(
          ['textile_order', patch.textileOrderId],
          newState
        );
      }

      return { previousState, previousStateOrder };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['student_list', patch.textileOrderId],
        context?.previousState
      );
      queryClient.setQueryData(
        ['textile_order', patch.textileOrderId],
        context?.previousStateOrder
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['student_list', textileOrderId]);
      queryClient.invalidateQueries(['textile_order', textileOrderId]);
    }
  });
};

export const useTextileProfileSubmitPreorderQuery = (id?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number }) =>
      submitPreorder(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['current_textile_profile', patch.id]);
      const previousStateCurrent:
        | BaseTextileProfile
        | undefined = queryClient.getQueryData([
        'current_textile_profile',
        patch.id
      ]);

      if (previousStateCurrent) {
        const newState = {
          ...previousStateCurrent,
          preorder_state: 'pending'
        };
        queryClient.setQueryData(
          ['current_textile_profile', patch.id],
          newState
        );
      }

      return { previousStateCurrent };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['current_textile_profile', patch.id],
        context?.previousStateCurrent
      );
    },
    onSettled: (id) => {
      queryClient.invalidateQueries(['current_textile_profile', id]);
    }
  });
};

export const useTextileProfileReopenPreorderQuery = (id?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number }) =>
      reopenPreorder(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['current_textile_profile', patch.id]);
      const previousStateCurrent:
        | BaseTextileProfile
        | undefined = queryClient.getQueryData([
        'current_textile_profile',
        patch.id
      ]);

      if (previousStateCurrent) {
        const newState = {
          ...previousStateCurrent,
          preorder_state: 'pending'
        };
        queryClient.setQueryData(
          ['current_textile_profile', patch.id],
          newState
        );
      }

      return { previousStateCurrent };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['current_textile_profile', patch.id],
        context?.previousStateCurrent
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['current_textile_profile', id]);
    }
  });
};

export const useTextileProfileRejectPreorderQuery = (id?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number }) =>
      rejectPreorder(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['current_textile_profile', patch.id]);
      await queryClient.cancelQueries(['textile_profile', patch.id]);
      const previousStateCurrent:
        | BaseTextileProfile
        | undefined = queryClient.getQueryData([
        'current_textile_profile',
        patch.id
      ]);

      const previousState:
        | BaseTextileProfile
        | undefined = queryClient.getQueryData(['textile_profile', patch.id]);

      if (previousStateCurrent) {
        const newState = {
          ...previousStateCurrent,
          preorder_state: 'pending'
        };
        queryClient.setQueryData(
          ['current_textile_profile', patch.id],
          newState
        );
      }

      if (previousState) {
        const newState = {
          ...previousState,
          preorder_state: 'pending'
        };
        queryClient.setQueryData(['textile_profile', patch.id], newState);
      }

      return { previousStateCurrent, previousState };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['current_textile_profile', patch.id],
        context?.previousStateCurrent
      );
      queryClient.setQueryData(
        ['textile_profile', patch.id],
        context?.previousState
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['current_textile_profile', id]);
      queryClient.invalidateQueries(['textile_profile', id]);
    }
  });
};

export const useTextileProfileJoinQuery = () => {
  return useMutation({
    mutationFn: (textileOrderId: number) => joinTextileOrder(textileOrderId)
  });
};
