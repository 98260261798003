import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import CardBlock from 'blocks/CardBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import FontsPreloader from 'components/print/FontsPreloader';
import CoverPreviewComponent from 'components/print/layout-editor/CoverPreviewComponent';
import PageLoadingComponent from 'components/print/layout-editor/PageLoadingComponent';
import ScaledPagePreviewComponent from 'components/print/structure/ScaledPagePreviewComponent';
import { COVER_PRELOAD_FONTS } from 'components/print/templates/CoverTemplateComponent';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { CoverChapterSettingsModelType } from 'models/chapter-settings/CoverChapterSettingsModel';
import { ChapterModelType } from 'models/ChapterModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { SponsorPagesStoreType } from 'screens/sponsor_pages/SponsorPagesStore';
import { ROUTE_BACK_COVER, ROUTE_LAYOUT } from 'utils/constants/routes';
import { getCoverBackCreativePhoto } from 'utils/getCoverBackCreativePhoto';
import { HistoryProps } from 'utils/history';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import { ButtonListBlock, ButtonListBlockBody, ButtonListBlockItem } from 'blocks/ButtonListBlock/ButtonListBlock';
import NakedLink from 'elements/NakedLink';
import TextElement from 'components/TextElement/TextElement';
import HelpSubject from 'components/HelpButton/HelpSubject';

interface CoverScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  sponsorPagesStore: SponsorPagesStoreType;

  back?: boolean;
}

@inject('applicationStore', 'chaptersStore')
@observer
class CoverScreen extends React.Component<CoverScreenProps & HistoryProps> {
  componentDidMount() {
    if (this.props.applicationStore.onboardFeature('design')) {
      return;
    }

    this.loadData();
  }

  async loadData() {
    const { chaptersStore } = this.props;

    const cover = await chaptersStore.getCoverChapter();

    if (cover && !cover.layout_key) {
      this.props.history.replace(ROUTE_LAYOUT + '/theme');
    }
  }

  renderPage(content: React.ReactNode) {
    const { back } = this.props;

    return (
      <>
        <TopActionBlock>
          {back ? (
            <BackLink to={ROUTE_LAYOUT + '/cover'} />
          ) : (
            <BackLink to={ROUTE_LAYOUT} />
          )}
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage
              id={back ? 'Cover back page' : 'Cover'}
            />
          </Headline.Large>

          {content}
        </PageStackBlock>

        <HelpSubject subject="cover" />
      </>
    );
  }

  renderPreloader(
    cover: ChapterModelType,
    content?: React.ReactNode,
    numPages?: number
  ) {
    const layoutFonts = cover.layout?.fontSpecs || [];

    return (
      <FontsPreloader
        fonts={COVER_PRELOAD_FONTS.concat(layoutFonts)}
        print={false}
        loadingComponent={<PageLoadingComponent numPages={numPages || 2} />}
      // errorComponent={errorComponent}
      >
        {content}
      </FontsPreloader>
    );
  }

  renderPreview() {
    const {
      chaptersStore: { item: cover }
    } = this.props;
    if (!cover) {
      return null;
    }

    const { coverSponsor } = cover;
    const config = cover.layout?.layoutDefinition;

    return this.renderPage(
      <>
        {this.renderPreloader(
          cover,
          <SliderBlock>
            <ScaledPagePreviewComponent
              spine={false}
              border={true}
            >
              <CoverPreviewComponent
                scaled={false}
                config={config}
                chapter={cover}
                display="front"
              />
            </ScaledPagePreviewComponent>

            <ScaledPagePreviewComponent
              spine={true}
              border={true}
            >
              <CoverPreviewComponent
                scaled={false}
                config={config}
                chapter={cover}
                display="spine"
              />
            </ScaledPagePreviewComponent>

            <ScaledPagePreviewComponent
              spine={false}
              border={true}
            >
              <CoverPreviewComponent
                scaled={false}
                config={config}
                chapter={cover}
                display="back"
                backSponsorUrl={coverSponsor?.advertisement?.url}
              />
            </ScaledPagePreviewComponent>
          </SliderBlock>
        )}

        {/* <Link to={ROUTE_LAYOUT + '/theme'}>
          <CardBlock>
            <PaddingBlock arrow={true}>
              <Headline.Small color="GRAY10">
                <FormattedMessage id="Design theme" />
              </Headline.Small>
              {cover.layout?.name && (
                <TextElement>{cover.layout?.name}</TextElement>
              )}
            </PaddingBlock>
          </CardBlock>
        </Link> */}

        <ListStackBlock>
          <ButtonListBlock>
            <ButtonListBlockItem>
              <NakedLink to={ROUTE_LAYOUT + '/front'}>
                <ButtonListBlockBody>
                  <FormattedMessage id="Front page" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            <ButtonListBlockItem>
              <NakedLink to={ROUTE_LAYOUT + '/spine'}>
                <ButtonListBlockBody>
                  <FormattedMessage id="Spine" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            <ButtonListBlockItem>
              <NakedLink to={ROUTE_BACK_COVER}>
                <ButtonListBlockBody>
                  <FormattedMessage id="Back page" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>
          </ButtonListBlock>
        </ListStackBlock>
      </>
    );
  }

  renderBack() {
    const {
      chaptersStore: { item: cover }
    } = this.props;
    if (!cover) {
      return null;
    }

    const { coverSponsor } = cover;

    const settings = cover.settings as
      | CoverChapterSettingsModelType
      | undefined;

    const backDesign = settings?.back_design || 'neutral';
    const sorting =
      settings?.names_order === 'last_name' ? 'last_name' : 'first_name';

    const coverBackCreativePhoto = getCoverBackCreativePhoto(cover);

    const sponsorName = coverSponsor?.name || (
      <FormattedMessage id="Select sponsor" />
    );
    const backSponsorUrl = coverSponsor?.advertisement?.url;

    return this.renderPage(
      <PageStackBlock>
        <ListStackBlock>
          <Link to={ROUTE_BACK_COVER + '/design'}>
            <CardBlock>
              <PaddingBlock arrow={true}>
                <Headline.Small color="GRAY10">
                  <FormattedMessage id="Back design" />
                </Headline.Small>
                <TextElement>
                  <FormattedMessage id={'back design ' + backDesign} />
                </TextElement>
              </PaddingBlock>
            </CardBlock>
          </Link>

          {backDesign === 'names' && (
            <>
              <Link to={ROUTE_BACK_COVER + '/sorting'}>
                <CardBlock>
                  <PaddingBlock arrow={true}>
                    <Headline.Small color="GRAY10">
                      <FormattedMessage id="Sorting order" />
                    </Headline.Small>
                    <TextElement>
                      <FormattedMessage id={'back sorting ' + sorting} />
                    </TextElement>
                  </PaddingBlock>
                </CardBlock>
              </Link>

              <Link to={ROUTE_BACK_COVER + '/header'}>
                <CardBlock>
                  <PaddingBlock arrow={true}>
                    <Headline.Small color="GRAY10">
                      <FormattedMessage id="Headline" />
                    </Headline.Small>
                    {settings?.back_header && (
                      <TextElement>
                        {settings?.back_header}</TextElement>
                    )}
                  </PaddingBlock>
                </CardBlock>
              </Link>
            </>
          )}

          {backDesign === 'creative' && (
            <>
              <Link to={ROUTE_BACK_COVER + '/creative'}>
                <CardBlock>
                  <PaddingBlock arrow={true}>
                    <Headline.Small color="GRAY10">
                      <FormattedMessage id="Photo upload creative" />
                    </Headline.Small>
                    <TextElement>
                      {coverBackCreativePhoto ? (
                        coverBackCreativePhoto.photo ? (
                          <FormattedMessage id="Edit photo" />
                        ) : (
                          <>
                            <IconComponent icon="ARROW_ROTATE" fill="GREEN" />{' '}
                            <FormattedMessage id="Photo is being processed" />
                          </>
                        )
                      ) : (
                        <FormattedMessage id="Add photo" />
                      )}
                    </TextElement>
                  </PaddingBlock>
                </CardBlock>
              </Link>
            </>
          )}

          {backDesign === 'sponsor' && (
            <Link to={ROUTE_BACK_COVER + '/sponsor'}>
              <CardBlock>
                <PaddingBlock arrow={true}>
                  <Headline.Small color="GRAY10">
                    <FormattedMessage id="back design sponsor" />
                  </Headline.Small>
                  <TextElement>
                    {sponsorName}</TextElement>
                  {coverSponsor && !backSponsorUrl && (
                    <TextElement>
                      <FormattedMessage id="Advert still missing" />
                    </TextElement>
                  )}
                </PaddingBlock>
              </CardBlock>
            </Link>
          )}
        </ListStackBlock>
      </PageStackBlock>
    );
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadData()} />
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const {
      chaptersStore: { isItemLoading, isItemLoadError },
      back
    } = this.props;

    if (isItemLoading) {
      return this.renderLoading();
    }

    if (isItemLoadError) {
      return this.renderError();
    }

    if (back) {
      return this.renderBack();
    }

    return this.renderPreview();
  }
}

export default CoverScreen;
