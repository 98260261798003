import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_ALBUM, ROUTE_ALBUM_NEW_PHOTO, ROUTE_ALBUM_PHOTO, ROUTE_ALBUM_SETTINGS } from 'utils/constants/routes';

import PhotosSettings from './PhotosSettings';
import NewPhoto from './screens/NewPhoto/NewPhoto';
import PhotoDetail from './screens/PhotoDetail/PhotoDetail';
import PhotosList from './screens/PhotosList/PhotosList';

export default () => (
  <Switch>
    <Route path={ROUTE_ALBUM} exact={true} component={PhotosList} />
    <Route path={ROUTE_ALBUM_SETTINGS} component={PhotosSettings} />
    <Route path={ROUTE_ALBUM_NEW_PHOTO} component={NewPhoto} />
    <Route path={ROUTE_ALBUM_PHOTO} component={PhotoDetail} />

    <NotFoundRoute />
  </Switch>
);
