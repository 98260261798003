import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import {
  ROUTE_NEW_SPONSOR_PAGE,
  ROUTE_SPONSOR_PAGE
} from 'utils/constants/routes';

import NewSponsorPage from './NewSponsorPage';
import SponsorPage from './SponsorPage';

export default () => (
  <Switch>
    <Route path={ROUTE_NEW_SPONSOR_PAGE} component={NewSponsorPage} />
    <Route path={ROUTE_SPONSOR_PAGE} component={SponsorPage} exact={true} />

    <NotFoundRoute />
  </Switch>
);
