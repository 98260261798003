import {
  Instance,
  types
} from 'mobx-state-tree';

export const ProfileOrderEnum = types.enumeration(['first_name', 'last_name']);

type ProfileOrder = Instance<typeof ProfileOrderEnum>;

export const createProfileOrderEnum = (data: any): ProfileOrder | undefined => {
  return data === 'first_name' || data === 'last_name'
    ? ProfileOrderEnum.create(data)
    : undefined;
};
