import React from 'react';
import config from 'config';
import { FormattedMessage } from 'react-intl';
import {
  FormType,
  FormValues
} from 'utils/hooks/useForm';
import CheckboxBlock from 'blocks/CheckboxBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import CheckoutLink from 'components/CheckoutLink/CheckoutLink';

const CheckoutAGB = ({
  form,
  updateAgb
}: {
  form: FormType;
  updateAgb: (formValues: FormValues) => void;
}) => (
  <>
    <CheckoutLink text="Complete agb" color="TEXT_LIGHT" to={config.agbUrl} />

    <CheckboxBlock>
      <CheckboxBlock.CheckboxElement
        id="accept_agb"
        {...form.bindCheckbox('accept_agb', (_event, formValues) =>
          updateAgb(formValues)
        )}
      />
      <CheckboxBlock.LabelElement htmlFor="accept_agb">
        <Paragraph size="L">
          <FormattedMessage id="Checkout agb accept" />
        </Paragraph>
      </CheckboxBlock.LabelElement>
    </CheckboxBlock>
  </>
);

export default CheckoutAGB;
