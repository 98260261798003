import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  TextileRoleChangeRequest,
  TextileRoleChangeRequestType,
  acceptTextileRoleChangeRequest,
  getTextileRoleChangeRequestsByTextileOrderId,
  rejectTextileRoleChangeRequest,
  requestTextileRoleChange
} from 'api/textile_deals/fetchTextileRoleChangeRequests';

export const useTextileRoleChangeRequestsQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_role_change_requests', textileOrderId],
    queryFn: () => getTextileRoleChangeRequestsByTextileOrderId(textileOrderId!),
    enabled: !!textileOrderId
  });
};

// optimistic update of textile_role_change_requests list
export const useAcceptTextileRoleChangeQuery = (textileOrderId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: {id: number, textileOrderId: number}) =>
      acceptTextileRoleChangeRequest(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_role_change_requests',
        patch.textileOrderId
      ]);

      const previousTasks:
        | TextileRoleChangeRequest[]
        | undefined = queryClient.getQueryData([
        'textile_role_change_requests',
        patch.textileOrderId
      ]);

      queryClient.setQueryData(
        ['textile_role_change_requests', patch.textileOrderId],
        (old?: TextileRoleChangeRequest[]) => old?.filter((t) => t.id !== patch.id)
      );

      return { previousTasks };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_role_change_requests', patch.id],
        context?.previousTasks
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'textile_role_change_requests',
        textileOrderId
      ]);
    }
  });
};

// optimistic update of textile_role_change_requests list
export const useRejectTextileRoleChangeQuery = (textileOrderId: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: {id: number, textileOrderId: number}) =>
      rejectTextileRoleChangeRequest(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries([
        'textile_role_change_requests',
        patch.textileOrderId
      ]);

      const previousRequests:
        | TextileRoleChangeRequest[]
        | undefined = queryClient.getQueryData([
        'textile_role_change_requests',
        patch.textileOrderId
      ]);

      queryClient.setQueryData(
        ['textile_role_change_requests', patch.textileOrderId],
        (old?: TextileRoleChangeRequest[]) => old?.filter((t) => t.id !== patch.id)
      );

      return { previousRequests };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_role_change_requests', patch.textileOrderId],
        context?.previousRequests
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries([
        'textile_role_change_requests',
        textileOrderId
      ]);
    }
  });
};

export const useCreateTextileRoleChangeQuery = () => {
  return useMutation({
    mutationFn: (data: {
      textileOrderId: number;
      requestType: TextileRoleChangeRequestType;
    }) => requestTextileRoleChange(data.textileOrderId, data.requestType)
  });
};
