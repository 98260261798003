import React, { ChangeEvent } from 'react';

import IconComponent from 'components/IconComponent';

import { AddButton, VisuallyHiddenInput } from './FileUploadInput';
import InputInterface from './InputInterface';

export const ACCEPT_IMAGES = 'image/jpeg,image/png';
export const ACCEPT_IMAGES_AND_PDFS = ACCEPT_IMAGES + ',.pdf';

export interface MultiFileUploadInputInterface extends InputInterface {
  value?: any;
  onChange?: (e: any) => void;
  id?: string;
  accept?: string;
  autoUpload?: boolean;
  onUploadPhotos: (file: FileList) => void;
  resetForm?: () => void;
  url?: string;
  isAddMode?: boolean;
}

class MultiFileUploadInput extends React.Component<MultiFileUploadInputInterface> {
  _inputRef?: any = null;

  componentDidMount() {
    const { value } = this.props;
    if (value && value.file instanceof File) {
      // if we received a File object on mount, we might need to recreate the preview
      if (value.url) {
        // revoke old preview first
        URL.revokeObjectURL(value.url);
      }

      if (value.file.type.startsWith('image/')) {
        // create new preview and persist it to value
        const newValue = {
          ...value
        };

        newValue.url = URL.createObjectURL(value.file);
        this.change(newValue);
      }
    }
  }

  change(newValue?: any) {
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          value: newValue
        }
      });
    }
  }

  filesChanged(e: ChangeEvent<HTMLInputElement>) {
    if (!e || !e.target || !e.target.files || !e.target.files.length) {
      return;
    }
    const { onUploadPhotos, resetForm, autoUpload } = this.props;


    const files = e.target.files;

    if (autoUpload) {
      if (resetForm) {
        resetForm();
      }

      onUploadPhotos(files);
    } else {
      Array.from(files).forEach((file) => {
        let url = null;

        if (file.type.startsWith('image/')) {
          url = URL.createObjectURL(file);
        }

        this.change({
          file,
          url
        });
      });
    }
  }

  selectFile() {
    if (this._inputRef) {
      this._inputRef.click();
    }
  }

  renderAdd() {
    return (
      <AddButton onClick={() => this.selectFile()}>
        <IconComponent icon="UPLOAD" size={3} fill="PRIMARY" />
      </AddButton>
    );
  }

  render() {
    const {
      name,
      id,
      accept
    } = this.props;

    return (
      <>
        {this.renderAdd()}

        <VisuallyHiddenInput
          type="file"
          name={name}
          id={id}
          accept={!accept ? ACCEPT_IMAGES : accept}
          onChange={(e) => this.filesChanged(e)}
          ref={(r) => (this._inputRef = r)}
          multiple={true}
        />
      </>
    );
  }
}

export default MultiFileUploadInput;
