import { getEnv, types, Instance } from 'mobx-state-tree';

import { ApplicationStoreType } from './ApplicationStore';
import { ChaptersStoreType } from './ChaptersStore';

interface StoreEnv {
  chaptersStore: ChaptersStoreType;
  applicationStore: ApplicationStoreType;
}

// this store will perform any cleanup actions which are required on logout
const LogoutStore = types.model('LogoutStore', {}).actions((self) => {
  const logout = () => {
    const { chaptersStore, applicationStore } = getEnv<StoreEnv>(self);

    applicationStore.logout();

    chaptersStore.clear();
  };

  return {
    logout
  };
});

export type LogoutStoreType = Instance<typeof LogoutStore>;
export default LogoutStore;
