import { Instance, types } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import { createProfileOrderEnum, ProfileOrderEnum } from './ProfileOrderEnum';

const YearbookChapterSettingsModel = types.model(
  'YearbookChapterSettingsModel',
  {
    profile_order: types.maybe(ProfileOrderEnum),
    must_have_group: types.maybe(types.boolean)
  }
);

export const createYearbookChapterSettingsModel = (
  data?: any,
  createEmptyModel: boolean = true
): YearbookChapterSettingsModelType | undefined => {
  if (!data) {
    return createEmptyModel ? YearbookChapterSettingsModel.create() : undefined;
  }

  return YearbookChapterSettingsModel.create({
    profile_order: createProfileOrderEnum(data.profile_order),
    must_have_group: booleanOrUndefined(data.must_have_group)
  });
};

export type YearbookChapterSettingsModelType = Instance<
  typeof YearbookChapterSettingsModel
>;
export default YearbookChapterSettingsModel;
