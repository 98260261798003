import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import FooterWrapper from 'blocks/FooterBlock/FooterWrapper';
import TopActionBlock from 'blocks/TopActionBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import PasswordInputComponent from 'components/Inputs/PasswordInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import BackClickLink from 'domain/Links/BackClickLink';
import Headline from 'elements/Headline';
import { AccountStoreType } from 'models/AccountStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_ACCOUNT_DASHBOARD } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface ChangePasswordScreenProps {
  applicationStore: ApplicationStoreType;
  accountStore: AccountStoreType;
  form: FormType;
  setPassword?: boolean;
}

@inject('applicationStore', 'accountStore')
@observer
class ChangePasswordScreen extends React.Component<
  ChangePasswordScreenProps & HistoryProps & WrappedComponentProps
> {
  async handleSubmit() {
    const {
      form,
      setPassword,
      history,
      applicationStore,
      accountStore,
      intl
    } = this.props;

    form.resetErrors();

    const oldPassword = form.values.old_password || '';
    try {
      await accountStore.changeOwnPassword(
        oldPassword,
        form.values.password || ''
      );
    } catch (error: any) {
      handleFormError(form, error);
      return;
    }

    if (setPassword) {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'password set flash' })
      );
      history.replace(ROUTE_ACCOUNT_DASHBOARD);
    } else {
      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'password changed flash' })
      );
      history.replace(ROUTE_ACCOUNT_DASHBOARD);
    }
  }

  renderPage(content?: any) {
    if (this.props.setPassword) {
      return (
        <FooterWrapper>
          <SignupHeaderComponent />

          <PageStackBlock>
            <Headline.Large>
              <FormattedMessage id="Set password" />
            </Headline.Large>

            {content}
          </PageStackBlock>
        </FooterWrapper>
      );
    }

    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => this.props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Change password" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderForm() {
    const { intl, accountStore, setPassword } = this.props;

    return this.renderPage(
      <>
        {setPassword && (
          <Paragraph>
            <FormattedMessage id="set password text" />
          </Paragraph>
        )}

        {accountStore.loadingState === 'update_error' && (
            <GenericErrorComponent />
        )}

        <FormStackBlock>
          <PasswordInputComponent
            name="old_password"
            label={intl.formatMessage({ id: 'Current password' })}
            {...this.props.form.bindInput('old_password')}
            autoFocus={true}
            noHotjar={true}
          />

          <PasswordInputComponent
            name="password"
            label={intl.formatMessage({ id: 'New password' })}
            {...this.props.form.bindInput('password')}
            noHotjar={true}
          />
        </FormStackBlock>

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  render() {
    const { accountStore } = this.props;

    if (accountStore.loadingState === 'loading') {
      return this.renderLoading();
    }

    if (accountStore.loadingState === 'error') {
      return this.renderError();
    }

    return this.renderForm();
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  return <ChangePasswordScreen {...props} form={form} />;
});
