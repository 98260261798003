import React from 'react';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardPadding,
  EveryCardHeadline
} from 'blocks/EveryCard/EveryCard';
import OrganizerConditional from 'domain/Conditionals/OrganizerConditional';
import WordWrap from 'elements/WordWrap';
import { ProfileModelType } from 'models/ProfileModel';
import { QuoteModelType } from 'models/QuoteModel';
import { ROUTE_QUOTES } from 'utils/constants/routes';
import LineClamp from 'elements/LineClamp';
import TextElement from 'components/TextElement/TextElement';

export const QuotesListItem = ({
  quote,
  currentUser
}: {
  quote: QuoteModelType;
  currentUser?: ProfileModelType;
}) => {
  return (
    <Link to={ROUTE_QUOTES + '/' + (quote.chapter_id || '0') + '/' + quote.id}>
      <EveryCard>
        <EveryCardPadding>
          <EveryCardBody>
            <EveryCardHeadline>
              <LineClamp>
                <WordWrap>{quote.quote}</WordWrap>
              </LineClamp>
            </EveryCardHeadline>
            <OrganizerConditional profile={currentUser}>
              {quote.author && (
                <TextElement>
                  {quote.author.name}
                </TextElement>
              )}
            </OrganizerConditional>
          </EveryCardBody>
        </EveryCardPadding>
      </EveryCard>
    </Link>
  );
};

export default QuotesListItem;
