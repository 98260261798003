import { inject, observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import Headline from 'elements/Headline';
import TextElement from 'components/TextElement/TextElement'; import { ContentNotPrintedStoreType } from 'models/ContentNotPrintedStore';
import chapterPath from 'utils/chapter-path';
import { ROUTE_PDF_PREVIEW, ROUTE_SETTINGS } from 'utils/constants/routes';
import Divider from 'components/Divider/Divider';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

interface ContentNotPrintedScreenProps {
  link: boolean;
  contentNotPrintedStore: ContentNotPrintedStoreType;
}

@inject('contentNotPrintedStore')
@observer
class ContentNotPrinted extends Component<ContentNotPrintedScreenProps> {
  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.props.contentNotPrintedStore.getContentNotPrinted();
  }

  render() {
    const { link } = this.props;

    const MaybeLink = ({ to, children, query }: { to: string; children: ReactNode; query?: string }) =>
      link ? <Link to={{pathname: to, state: {backRoute: ROUTE_PDF_PREVIEW}, search: query}}>{children}</Link> : <>{children}</>;

    const {
      contentNotPrintedStore: { loadingState, contentNotPrinted }
    } = this.props;
    if (loadingState === 'loading') {
      return <LoadingOverlayComponent />;
    }
    if (loadingState === 'error') {
      return <GenericErrorComponent onRetryClick={() => this.loadData()} />;
    }

    if (!contentNotPrinted) {
      return null;
    }

    const {
      chapters_not_printed,
      profiles_not_printed_factsheet,
      profiles_not_printed_yearbook,
      profiles_without_groups,
      groups_without_factsheet_chapter,
      groups_without_yearbook_chapter,
      global_factsheet,
      global_yearbook
    } = contentNotPrinted;


    const hasChapters = chapters_not_printed.length > 0;
    const hasProfilesNotPrintedFactsheet =
      profiles_not_printed_factsheet.length > 0;
    const hasProfilesNotPrintedYearbook =
      profiles_not_printed_yearbook.length > 0;
    const hasProfilesWithoutGroup = profiles_without_groups.length > 0;
    const hasGroupsWithoutFactsheet = groups_without_factsheet_chapter.length > 0;
    const hasGroupsWithoutYearbook = groups_without_yearbook_chapter.length > 0;

    const factsheetSection =
      hasProfilesNotPrintedFactsheet || !global_factsheet;
    const yearbookSection = hasProfilesNotPrintedYearbook || !global_yearbook;

    return (
      <ItemStackBlock gap="L">
        <>
          <Divider />
        </>

        {hasChapters && (
          <>
            <section>
              <ItemStackBlock gap="XXS">
                <Headline.Medium>
                  <FormattedMessage id="Chapters not printed" />
                </Headline.Medium>
                <Paragraph>
                  <FormattedMessage id="Chapters not printed check" />
                </Paragraph>
              </ItemStackBlock>
              <ListStackBlock>
                <ButtonListBlock>
                  {chapters_not_printed.map((chapter) => (
                    <ButtonListBlockItem key={chapter.id}>
                      <MaybeLink
                        to={chapterPath(chapter.chapter_type, chapter.id) + '/settings'} query='?active=print'
                      >
                        <ButtonListBlockBody arrow={link}>
                          <TextElement color="TEXT_DARK">{chapter.title}</TextElement>
                        </ButtonListBlockBody>
                      </MaybeLink>
                    </ButtonListBlockItem>
                  ))}
                </ButtonListBlock>
              </ListStackBlock>
              <Divider />
            </section>
          </>
        )}

        {factsheetSection && (
          <Headline.Medium>
            <FormattedMessage id="Factsheets not printed" />
          </Headline.Medium>
        )}

        {hasProfilesNotPrintedFactsheet && (
          <section>
            <ItemStackBlock gap="XXS">
              <Paragraph>
                <FormattedMessage id="Students not printed check" />
              </Paragraph>
            </ItemStackBlock>
            <ListStackBlock>
              <ButtonListBlock>
                {profiles_not_printed_factsheet.map((profile) => (
                  <ButtonListBlockItem key={profile.id}>
                    <MaybeLink to={`${ROUTE_SETTINGS}/students/${profile.id}/print`}>
                      <ButtonListBlockBody arrow={link}>
                        <TextElement color="TEXT_DARK">
                          {profile.first_name} {profile.last_name}
                        </TextElement>
                      </ButtonListBlockBody>
                    </MaybeLink>
                  </ButtonListBlockItem>
                ))}
              </ButtonListBlock>
            </ListStackBlock>
            <Divider />
          </section>
        )}

        {!global_factsheet && (
          <>
            {hasProfilesWithoutGroup && (
              <section>
                <ItemStackBlock gap="M">
                  <Paragraph>
                    <FormattedMessage id="Students without group check" />
                  </Paragraph>
                  <ButtonListBlock>
                    {profiles_without_groups.map((profile) => (
                      <ButtonListBlockItem key={profile.id}>
                        <MaybeLink
                          to={`${ROUTE_SETTINGS}/students/${profile.id}/groups`}
                        >
                          <ButtonListBlockBody arrow={link}>
                            <TextElement color="TEXT_DARK">
                              {profile.first_name} {profile.last_name}
                            </TextElement>
                          </ButtonListBlockBody>
                        </MaybeLink>
                      </ButtonListBlockItem>
                    ))}
                  </ButtonListBlock>
                </ItemStackBlock>
                <Divider />
              </section>
            )}

            {hasGroupsWithoutFactsheet && (
              <section>
                <ItemStackBlock gap="M">
                  <Paragraph>
                    <FormattedMessage id="Groups without factsheet chapter check" />
                  </Paragraph>
                  <ButtonListBlock>
                    {groups_without_factsheet_chapter.map((group) => (
                      <ButtonListBlockItem key={group.id}>
                        <MaybeLink to={`${ROUTE_SETTINGS}/groups/${group.id}`}>
                          <ButtonListBlockBody arrow={link}>
                            <TextElement color="TEXT_DARK">{group.name}</TextElement>
                          </ButtonListBlockBody>
                        </MaybeLink>
                      </ButtonListBlockItem>
                    ))}
                  </ButtonListBlock>
                </ItemStackBlock>
                <Divider />
              </section>
            )}
          </>
        )}

        {yearbookSection && (
          <Headline.Medium>
            <FormattedMessage id="Yearbooks not printed" />
          </Headline.Medium>
        )}

        {hasProfilesNotPrintedYearbook && (
          <section>
            <ItemStackBlock gap="XXS">
              <Paragraph>
                <FormattedMessage id="Students yearbook not printed check" />
              </Paragraph>
            </ItemStackBlock>
            <ListStackBlock>
              <ButtonListBlock>
                {profiles_not_printed_yearbook.map((profile) => (
                  <ButtonListBlockItem key={profile.id}>
                    <MaybeLink to={`${ROUTE_SETTINGS}/students/${profile.id}/print`}>
                      <ButtonListBlockBody arrow={link}>
                        <TextElement color="TEXT_DARK">
                          {profile.first_name} {profile.last_name}
                        </TextElement>
                      </ButtonListBlockBody>
                    </MaybeLink>
                  </ButtonListBlockItem>
                ))}
              </ButtonListBlock>
            </ListStackBlock>
            <Divider />
          </section>
        )}

        {!global_yearbook && (
          <>
            {hasProfilesWithoutGroup && (
              <section>
                <Paragraph>
                  <FormattedMessage id="Students without group check yearbook" />
                </Paragraph>
                <ListStackBlock>
                  <ButtonListBlock>
                    {profiles_without_groups.map((profile) => (
                      <ButtonListBlockItem key={profile.id}>
                        <MaybeLink
                          to={`${ROUTE_SETTINGS}/students/${profile.id}/group`}
                        >
                          <ButtonListBlockBody arrow={link}>
                            <TextElement color="TEXT_DARK">
                              {profile.first_name} {profile.last_name}
                            </TextElement>
                          </ButtonListBlockBody>
                        </MaybeLink>
                      </ButtonListBlockItem>
                    ))}
                  </ButtonListBlock>
                </ListStackBlock>
                <Divider />
              </section>
            )}

            {hasGroupsWithoutYearbook && (
              <section>
                <Paragraph>
                  <FormattedMessage id="Groups without yearbook chapter check" />
                </Paragraph>
                <ListStackBlock>
                  <ButtonListBlock>
                    {groups_without_yearbook_chapter.map((group) => (
                      <ButtonListBlockItem key={group.id}>
                        <MaybeLink to={`${ROUTE_SETTINGS}/groups/${group.id}`}>
                          <ButtonListBlockBody arrow={link}>
                            <TextElement color="TEXT_DARK">{group.name}</TextElement>
                          </ButtonListBlockBody>
                        </MaybeLink>
                      </ButtonListBlockItem>
                    ))}
                  </ButtonListBlock>
                </ListStackBlock>
                <Divider />
              </section>
            )}
          </>
        )}
      </ItemStackBlock>
    );
  }
}

export default (props: any) => (
  // @ts-ignore
  <ContentNotPrinted {...props} />
);
