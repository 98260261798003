import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import COLORS from 'utils/constants/colors';

import AddGroupModalComponent from './AddGroupModalComponent';

interface AddGroupButtonComponentProps {
  background?: keyof typeof COLORS;
  color?: keyof typeof COLORS;
}

export const AddGroupButtonComponent = ({
  background,
  color
}: AddGroupButtonComponentProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ButtonBlock
        color={color ? color : 'WHITE'}
        background={background ? background : 'PRIMARY'}
        onClick={() => setShowModal(true)}
      >
        <FormattedMessage id="create group link" />
      </ButtonBlock>

      {showModal && (
        <AddGroupModalComponent onClose={() => setShowModal(false)} />
      )}
    </>
  );
};

export default AddGroupButtonComponent;
