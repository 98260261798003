import React from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import CardBlock from 'blocks/CardBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { RoleChangeRequestModelType } from 'models/RoleChangeRequestModel';
import { FormattedMessage } from 'react-intl';

interface RoleChangeItemComponentProps {
  request: RoleChangeRequestModelType;
  onAcceptClick: () => void;
  onRejectClick: () => void;
}

const ALLOWED_TYPES = ['applicant_to_student', 'student_to_organizer', 'applicant_to_organizer'];

export const RoleChangeItemComponent = ({
  request,
  request: { profile },
  onAcceptClick,
  onRejectClick
}: RoleChangeItemComponentProps) =>
  !profile || !ALLOWED_TYPES.includes(request.request_type) ? null : (
    <CardBlock>
      <PaddingBlock>
        <HorizontalStackBlock>
          <ItemStackBlock gap="XXS">
            <Paragraph  color="GRAY10">{profile.fullName}</Paragraph>
            <TextElement  color="TEXT_LIGHT">
              <FormattedMessage id={'role change ' + request.request_type} />
            </TextElement>
          </ItemStackBlock>

          <HorizontalStackBlock gap="S">
            <ButtonBlock
              background="RED"
              onClick={onRejectClick}
              onlyIcon={true}
            >
              <IconComponent icon="cross" fill="WHITE" size={1.25} square={true} />
            </ButtonBlock>
            <ButtonBlock
              background="GREEN"
              onClick={onAcceptClick}
              onlyIcon={true}
            >
              <IconComponent icon="TICK" fill="WHITE" size={1.25} square={true} />
            </ButtonBlock>
          </HorizontalStackBlock>
        </HorizontalStackBlock>
      </PaddingBlock>
    </CardBlock>
  );

export default RoleChangeItemComponent;
