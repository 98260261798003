import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import CardBlock from 'blocks/CardBlock';
import ImageBlock from 'blocks/ImageBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import MediaBlock from 'blocks/MediaBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import ConfirmBox from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import InfoBoxComponent from 'components/InfoBoxComponent/InfoBoxComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import SponsorPagePreviewComponent from 'components/print/preview/SponsorPagePreviewComponent';
import {
  SPONSOR_PAGE_GRIDS,
  SponsorPageSlot
} from 'components/print/templates/sponsor-page-grids';
import SponsorListItemComponent from 'components/SponsorListItemComponent/SponsorListItemComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import NakedButton from 'elements/NakedButton';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChapterModelType } from 'models/ChapterModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { SponsorModelType } from 'models/SponsorModel';
import { SponsorPageModelType } from 'models/SponsorPageModel';
import { SponsorsStoreType } from 'screens/sponsors/SponsorsStore';
import { assert } from 'utils/assert';
import { ROUTE_SPONSORS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import reduceSponsorPageSponsor from './reduceSponsorPageSponsor';
import { SponsorPagesStoreType } from './SponsorPagesStore';

interface Props {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  sponsorsStore: SponsorsStoreType;
  sponsorPagesStore: SponsorPagesStoreType;
}

interface State {
  currentScreen: 'selectPosition' | 'editSlot';
  selectedPosition?: number;
  selectedSponsor?: SponsorModelType;
  confirmRemove: boolean;
}

@inject(
  'applicationStore',
  'chaptersStore',
  'sponsorsStore',
  'sponsorPagesStore'
)
@observer
class SponsorPage extends React.Component<Props & HistoryProps, State> {
  state: State = {
    currentScreen: 'selectPosition',
    selectedPosition: undefined,
    selectedSponsor: undefined,
    confirmRemove: false
  };

  componentDidMount() {
    if (
      this.props.applicationStore.onboardFeature(
        'sponsorpages',
        this.props.location?.pathname
      )
    ) {
      return;
    }

    this.loadInitialData();
  }

  loadInitialData() {
    this.getSponsorPage();
    this.getChapter();
  }

  getSponsorPageId() {
    const id = idFromMatch(this.props.match);
    assert(id, 'Sponsor page id not given');
    return id;
  }

  getChapterId() {
    const id = chapterIdFromMatch(this.props.match);
    assert(id, 'Chapter id not given');
    return id;
  }

  getSponsorPage() {
    this.props.sponsorPagesStore.getSponsorPage(this.getSponsorPageId());
  }

  getChapter() {
    this.props.chaptersStore.getChapter(this.getChapterId());
  }

  selectPosition(id: number) {
    this.setState({ currentScreen: 'editSlot', selectedPosition: id });
    this.loadSponsors();
  }

  loadSponsors() {
    this.props.sponsorsStore.getAllSponsors();
  }

  async placeSponsor(sponsor: SponsorModelType) {
    const { sponsorPage } = this.props.sponsorPagesStore;
    const { selectedPosition } = this.state;
    assert(sponsorPage && sponsor && selectedPosition);

    await this.props.sponsorPagesStore.placeSponsor(sponsor, selectedPosition);

    this.setState({ currentScreen: 'selectPosition' });
  }

  startRemove() {
    this.setState({ confirmRemove: true });
  }

  async removeSponsorPage() {
    await this.props.sponsorPagesStore.removeSponsorPage();
    this.props.history.push(`${ROUTE_SPONSORS}/${this.getChapterId()}`);
  }

  finishRemove() {
    this.setState({ confirmRemove: false });
  }

  async removeSponsorInSelectedPosition() {
    const { selectedPosition } = this.state;
    if (selectedPosition === undefined) {
      return;
    }
    const sponsor = this.getSponsorForPosition(selectedPosition);
    if (!sponsor) {
      return;
    }

    await this.props.sponsorPagesStore.removeSponsor(sponsor);
    this.setState({ currentScreen: 'selectPosition' });
  }

  getSponsorForPosition(id: number) {
    const { sponsorPage } = this.props.sponsorPagesStore;
    assert(sponsorPage);
    const sponsorPageSponsor = sponsorPage.sponsor_page_sponsors.find(
      (candidate) => candidate.position === id
    );
    return sponsorPageSponsor ? sponsorPageSponsor.sponsor : null;
  }

  hasSponsorForPosition(id: number) {
    const sponsor = this.getSponsorForPosition(id);
    return sponsor ? true : false;
  }

  getSponsorAdvertismentURLForPosition(id: number) {
    const sponsor = this.getSponsorForPosition(id);
    return sponsor && sponsor.advertisement
      ? sponsor.advertisement.url
      : undefined;
  }

  // Render methods
  // --------------

  renderBackToChapter(children: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackLink to={`${ROUTE_SPONSORS}/${this.getChapterId()}`} />
        </TopActionBlock>

        <PageStackBlock>
          {children}
        </PageStackBlock>
      </>
    );
  }

  renderBackToScreen(screen: State['currentScreen'], content: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <NakedButton onClick={() => this.setState({ currentScreen: screen })}>
            <TextElement  color="GRAY10">
              <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />
              <FormattedMessage id="Back" />
            </TextElement>
          </NakedButton>
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="sponsors_pages" />
      </>
    );
  }

  renderSelectPosition(
    chapter: ChapterModelType,
    sponsorPage: SponsorPageModelType
  ): React.ReactNode {
    const { sponsorPageIsError } = this.props.sponsorPagesStore;
    const { sponsor_layout_key } = sponsorPage;
    const gridConfig = SPONSOR_PAGE_GRIDS[sponsor_layout_key];

    if (!gridConfig) {
      return (
        <InfoBoxComponent error={true}>
          Sponsor layout {sponsor_layout_key} not found
        </InfoBoxComponent>
      );
    }
    return this.renderBackToChapter(
      <>
        <Headline.Large>
          <FormattedMessage id="Sponsor page select position heading" />
        </Headline.Large>

        <FontPreloadComponent chapter={chapter}>
          <SponsorPagePreviewComponent
            layoutKey={sponsor_layout_key}
            positionMode={true}
            images={reduceSponsorPageSponsor(sponsorPage)}
          />
        </FontPreloadComponent>


        <ListStackBlock>
          {gridConfig.slots.map((slot) => (
            <CardBlock
              key={slot.id}
              as="button"
              onClick={() => this.selectPosition(slot.id)}
            >
              <MediaBlock>
                <ImageBlock background="GRAY900" width={5.875}>
                  {this.getSponsorAdvertismentURLForPosition(slot.id) && (
                    <img
                      src={this.getSponsorAdvertismentURLForPosition(slot.id)}
                      alt=""
                    />
                  )}
                </ImageBlock>
                <PaddingBlock>
                  <ItemStackBlock gap="XS">
                    <Headline.Small>
                      <FormattedMessage
                        id="Sponsor page select position button"
                        values={{
                          slot: slot.id
                        }}
                      />
                    </Headline.Small>
                    <Paragraph  color="TEXT_LIGHT">
                      {this.hasSponsorForPosition(slot.id) ? (
                        <>
                          <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                          <FormattedMessage id="Filtered photos placed" />
                        </>
                      ) : (
                        <FormattedMessage id="Sponsor page position empty" />
                      )}
                    </Paragraph>
                  </ItemStackBlock>
                </PaddingBlock>
              </MediaBlock>
            </CardBlock>
          ))}
        </ListStackBlock>
        <DeleteButton
          onClick={() => this.startRemove()}
          messageId="Sponsor page remove"
        />
        {sponsorPageIsError && <GenericErrorComponent />}

        {this.state.confirmRemove && (
          <ConfirmBox
            header={<FormattedMessage id="confirm delete header" />}
            text={
              <FormattedMessage
                id="confirm delete item"
                values={{
                  item: <FormattedMessage id="This sponsor page" />
                }}
              />
            }
            confirmText={<FormattedMessage id="Remove" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => this.removeSponsorPage()}
            onAbort={() => this.finishRemove()}
            confirmColor="RED"
          />
        )}
      </>
    );
  }

  renderEditSlot(sponsorPage: SponsorPageModelType): React.ReactNode {
    const { sponsorPageIsError } = this.props.sponsorPagesStore;
    const { selectedPosition } = this.state;
    assert(selectedPosition !== undefined);

    const gridConfig = SPONSOR_PAGE_GRIDS[sponsorPage.sponsor_layout_key];
    const slot = gridConfig.slots.find(
      (gridCandidate) => gridCandidate.id === selectedPosition
    );
    assert(slot);

    // Existing sponsor at the position
    const sponsorInPosition = this.getSponsorForPosition(selectedPosition);

    return this.renderBackToScreen('selectPosition', (
      <>
        <Headline.Large>
          <FormattedMessage
            id="Sponsor page edit slot heading"
            values={{
              slot: selectedPosition
            }}
          />
        </Headline.Large>

        {sponsorInPosition && (
          <>
            <ImageBlock height={20.875} background="GRAY950" fullWidth={true}>
              <img src={sponsorInPosition.advertisement?.url} alt="" />
            </ImageBlock>
            <ItemStackBlock gap="S">
              <DeleteButton
                onClick={() => this.removeSponsorInSelectedPosition()}
                messageId="Remove sponsor"
              />
              {sponsorPageIsError && <GenericErrorComponent />}
            </ItemStackBlock>
          </>
        )}
        {this.renderSponsorsSection(slot, sponsorInPosition)}
      </>
    ));
  }

  renderSponsorsSection(
    slot: SponsorPageSlot,
    sponsorInPosition: SponsorModelType | null
  ): React.ReactNode {
    const {
      sponsorsStore: { isListLoading, isListError, unplacedSponsors }
    } = this.props;

    if (isListLoading) {
      return <LoadingIndicatorComponent />;
    }
    if (isListError) {
      return <GenericErrorComponent onRetryClick={() => this.loadSponsors()} />;
    }

    /** Unplaced sponsors that fit into the slot */
    const sponsors = unplacedSponsors
      ? unplacedSponsors.filter(
        (sponsorCandidate) => sponsorCandidate.page_type === slot.pageType || sponsorCandidate.page_type === undefined
      )
      : null;
    if (!sponsors) {
      // Sponsors not available
      return null;
    }
    const sponsorsPresent = sponsors.length > 0;
    // If there is a sponsor and no alternatives, render nothing
    if (sponsorInPosition && !sponsorsPresent) {
      return null;
    }

    return (
      <>
        <Headline.Medium>
          <FormattedMessage
            id={
              sponsorInPosition
                ? 'Sponsor page edit slot select other sponsor'
                : 'Sponsor page edit slot select sponsor initially'
            }
          />
        </Headline.Medium>
        {sponsorsPresent ? (
          this.renderSponsorList(sponsors)
        ) : (
          <Paragraph>
            <FormattedMessage
              id="sponsors filter count"
              values={{ count: 0 }}
            />
          </Paragraph>
        )}
      </>
    );
  }

  renderSponsorList(sponsors: SponsorModelType[]): React.ReactNode {
    return (
      <TwoColumnBlock gap="M">
        {sponsors.map((sponsor) => (
          <NakedButton
            key={sponsor.id}
            onClick={() => this.placeSponsor(sponsor)}
          >
            <SponsorListItemComponent sponsor={sponsor} />
          </NakedButton>
        ))}
      </TwoColumnBlock>
    );
  }

  render(): React.ReactNode {
    const {
      item: chapter,
      isItemLoadError: chapterLoadingError
    } = this.props.chaptersStore;
    const {
      sponsorPage,
      sponsorPagesAreError,
      sponsorPageLoadingState
    } = this.props.sponsorPagesStore;
    if (chapterLoadingError || sponsorPagesAreError) {
      return this.renderBackToChapter(
        <GenericErrorComponent onRetryClick={() => this.loadInitialData()} />
      );
    }
    if (!chapter || !sponsorPage) {
      return this.renderBackToChapter(<LoadingIndicatorComponent />);
    }

    if (sponsorPageLoadingState === 'loading') {
      return <LoadingIndicatorComponent />;
    }

    const { currentScreen } = this.state;
    if (currentScreen === 'selectPosition') {
      return this.renderSelectPosition(chapter, sponsorPage);
    }
    if (currentScreen === 'editSlot') {
      return this.renderEditSlot(sponsorPage);
    }
    return null;
  }
}

export default SponsorPage;
