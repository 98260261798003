import React from 'react';
import { FormattedMessage } from 'react-intl';

import TextInputComponent from './TextInputComponent';

const TIMEOUT = 2500;

interface CopyTextInputProps {
  name: string;
  value?: string;
  label?: string;
  message?: string;
  copiedMessageId?: string;
  buttonInside?: boolean;
  buttonInsideClick?: () => void;
  showFlashMessage?: () => void;
}

interface CopyTextInputState {
  copied: boolean;
}

export class CopyTextInput extends React.Component<
  CopyTextInputProps,
  CopyTextInputState
> {
  _textAreaRef: HTMLTextAreaElement | null = null;
  _inputRef: HTMLInputElement | null = null;
  timer?: number;

  state = {
    copied: false
  };

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  showMessage() {
    this.clearTimer();

    this.setState({
      copied: true
    });

    this.timer = window.setTimeout(() => {
      this.hideMessage();
    }, TIMEOUT);
  }

  hideMessage() {
    this.setState({
      copied: false
    });
  }

  selectAndCopy() {
    if (this._textAreaRef) {
      this._textAreaRef.style.display = 'block';
      this._textAreaRef.select();
      this._textAreaRef.setSelectionRange(0, 99999); // for mobile

      document.execCommand('copy');

      if (this.props.showFlashMessage) {
        this.props.showFlashMessage();
      } else {
        this.showMessage();
      }

      // unselect again
      this._textAreaRef.setSelectionRange(0, 0);
      this._textAreaRef.blur();
      this._textAreaRef.style.display = 'none';
    }
  }

  selectAndCopyNumber() {
    if (this._inputRef) {
      this._inputRef.select();
      this._inputRef.setSelectionRange(0, 99999); // for mobile

      document.execCommand('copy');

      if (this.props.showFlashMessage) {
        this.props.showFlashMessage();
      } else {
        this.showMessage();
      }
    }
  }

  select() {
    if (this._inputRef) {
      this._inputRef.select();
      this._inputRef.setSelectionRange(0, 99999); // for mobile
    }
  }

  render() {
    const {
      name,
      value,
      label,
      buttonInside,
      buttonInsideClick,
      message
    } = this.props;

    let success;
    if (this.state.copied) {
      success = (
        <FormattedMessage id={this.props.copiedMessageId || 'Link copied'} />
      );
    }

    const copyMessage = message ? `${message}${value}` : value;

    if (buttonInside && buttonInsideClick) {
      return (
        <>
          <textarea
            readOnly={true}
            value={copyMessage}
            style={{ display: 'none' }}
            ref={(r) => (this._textAreaRef = r as HTMLTextAreaElement | null)}
          />
          <TextInputComponent
            name={name}
            value={value}
            readOnly={true}
            label={label}
            iconSize={1.75}
            success={success}
            buttonInside={true}
            onButtonInsideClick={() => buttonInsideClick()}
            onFocus={() => this.select()}
            ref={(r) => (this._inputRef = r as HTMLInputElement | null)}
          />
        </>
      );
    }

    return (
      <>
        <textarea
          name={name}
          readOnly={true}
          value={copyMessage}
          style={{ display: 'none' }}
          ref={(r) => (this._textAreaRef = r as HTMLTextAreaElement | null)}
        />
        <TextInputComponent
          name={name}
          value={value}
          readOnly={true}
          icon="COPY"
          onFocus={() => this.select()}
          onIconClick={() => this.selectAndCopyNumber()}
          label={label}
          iconSize={1.75}
          success={success}
          ref={(r) => (this._inputRef = r as HTMLInputElement | null)}
        />
      </>
    );
  }
}

export default CopyTextInput;