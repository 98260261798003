import React from 'react';

import { Boundary } from 'utils/chunker/ContentChunker';

import config from 'config';

// export const PAGE_SIZE_PRINT: Boundary = [216, 303];
// export const PAGE_SIZE_A4: Boundary = [210, 297];
export const PAGE_SIZE_PRINT: Boundary = [864, 1212];
export const PAGE_SIZE_A4: Boundary = [840, 1188];

export const TOP_LEFT_PRINT: Boundary = [0, 0];
export const TOP_LEFT_A4: Boundary = [12, 12];

interface PageComponentProps {
  print?: boolean; // adds page breaks
  printSize?: Boundary; // default: A4 + 3mm on each side
  previewSize?: Boundary; // defaults to value of printSize
  leftOffset?: number; // only for preview
  forcedLeftOffset?: number;
  background?: string;
  overlay?: string;
  children?: React.ReactNode;
  // printStyle?: React.CSSProperties;
  // previewOuterStyle?: React.CSSProperties;
  // previewInnerStyle?: React.CSSProperties;
}

const disableTextSelect: React.CSSProperties = {
  userSelect: 'none' /* supported by Chrome and Opera */,
  WebkitUserSelect: 'none' /* Safari */,
  KhtmlUserSelect: 'none' /* Konqueror HTML */,
  MozUserSelect: '-moz-none' /* Firefox */
  // MsUserSelect: 'none' /* Internet Explorer/Edge */
};

const defaultTextStyle: React.CSSProperties = {
  letterSpacing: 'normal',
  wordSpacing: 'normal',
  textAlign: 'left',
  fontFamily: config.layout.defaultFont,
  fontWeight: config.layout.defaultWeight,
  fontSize: config.layout.defaultSize,
  lineHeight: config.layout.defaultLineHeight,
  color: config.layout.defaultColor
};

export const PageComponent = ({
  children,
  print,
  printSize,
  previewSize,
  leftOffset,
  forcedLeftOffset,
  background,
  overlay
}: PageComponentProps) => {
  const units = 'px';

  if (!printSize) {
    printSize = PAGE_SIZE_PRINT;
  }
  if (!previewSize) {
    previewSize = printSize;
  }

  // when sizes are equal we do not need to reposition inner elements
  const isPreview =
    printSize[0] !== previewSize[0] || printSize[1] !== previewSize[1];
  const left =
    (forcedLeftOffset || 0) +
    (isPreview
      ? typeof leftOffset === 'number'
        ? leftOffset
        : (previewSize[0] - printSize[0]) / 2
      : 0);
  const top = isPreview ? (previewSize[1] - printSize[1]) / 2 : 0;

  const printWidth = printSize[0] + units;
  const printHeight = printSize[1] + units;

  return (
    <div
      style={{
        ...disableTextSelect,
        ...defaultTextStyle,
        // ...previewOuterStyle,
        width: previewSize[0] + units,
        height: previewSize[1] + units,
        overflow: 'hidden',
        pageBreakAfter: print ? 'always' : undefined
      }}
    >
      <div style={{ marginLeft: left + units, marginTop: top + units }}>
        <div
          style={{
            width: printWidth,
            height: printHeight,
            position: 'relative',
            // TODO remove backgroundColor but make sure that page background is always white?
            backgroundColor: '#FFFFFF'
          }}
        >
          {!background ? null : (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: printWidth,
                height: printHeight
              }}
              dangerouslySetInnerHTML={{ __html: background }}
            />
          )}

          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: printWidth,
              height: printHeight
            }}
          >
            {children}
          </div>

          {!overlay ? null : (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: printWidth,
                height: printHeight
              }}
              dangerouslySetInnerHTML={{ __html: overlay }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PageComponent;
