import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TextileStudent } from 'api/textile_deals/fetchStudentList';
import { TextileTeacher } from 'api/textile_deals/fetchTeacherList';
import {
  TextileName,
  createTextileName,
  getTextileName,
  removeTextileName,
  updateTextileName
} from 'api/textile_deals/fetchTextileNames';
import { TextileOrderForGroups } from 'api/textile_deals/fetchTextileOrders';

export const useTextileNameQuery = (textileOrderId?: number, id?: number) => {
  return useQuery({
    queryKey: ['textile_name', id],
    queryFn: () => getTextileName(id!, textileOrderId!),
    enabled: !!id && !!textileOrderId
  });
};

export const useUpdateTextileNameQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { data: any; id: number; textileOrderId: number }) =>
      updateTextileName(patch.id, patch.textileOrderId, patch.data),
    onSettled: () => {
      queryClient.invalidateQueries(['teacher_list', textileOrderId]);
      queryClient.invalidateQueries(['student_list', textileOrderId]);
    }
  });
};

export const useAddTextileStudentToGroupQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { data: any; id: number; textileOrderId: number }) =>
      updateTextileName(patch.id, patch.textileOrderId, patch.data),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['student_list', patch.textileOrderId]);
      await queryClient.cancelQueries(['textile_order', patch.textileOrderId]);

      const previousState:
        | TextileStudent[]
        | undefined = queryClient.getQueryData([
        'student_list',
        patch.textileOrderId
      ]);

      const previousStateOrder:
        | TextileOrderForGroups
        | undefined = queryClient.getQueryData([
        'textile_order',
        patch.textileOrderId
      ]);

      if (previousState) {
        queryClient.setQueryData(
          ['student_list', patch.textileOrderId],
          (old?: TextileStudent[]) => old?.filter((t) => t.id !== patch.id)
        );
      }

      if (previousStateOrder) {
        const studentsCount =
          previousStateOrder.students_without_groups_count !== undefined &&
          previousStateOrder.students_without_groups_count > 0
            ? previousStateOrder.students_without_groups_count - 1
            : 0;
        const newState = {
          ...previousStateOrder,
          students_without_groups_count: studentsCount
        };
        queryClient.setQueryData(
          ['textile_order', patch.textileOrderId],
          newState
        );
      }

      return { previousState, previousStateOrder };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['student_list', patch.textileOrderId],
        context?.previousState
      );
      queryClient.setQueryData(
        ['textile_order', patch.textileOrderId],
        context?.previousStateOrder
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['student_list', textileOrderId]);
      queryClient.invalidateQueries(['textile_order', textileOrderId]);
    }
  });
};

export const useAddTextileTeacherToGroupQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { data: any; id: number; textileOrderId: number }) =>
      updateTextileName(patch.id, patch.textileOrderId, patch.data),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['teacher_list', patch.textileOrderId]);
      await queryClient.cancelQueries(['textile_order', patch.textileOrderId]);

      const previousState:
        | TextileTeacher[]
        | undefined = queryClient.getQueryData([
        'teacher_list',
        patch.textileOrderId
      ]);

      const previousStateOrder:
        | TextileOrderForGroups
        | undefined = queryClient.getQueryData([
        'textile_order',
        patch.textileOrderId
      ]);

      if (previousState) {
        queryClient.setQueryData(
          ['teacher_list', patch.textileOrderId],
          (old?: TextileTeacher[]) => old?.filter((t) => t.id !== patch.id)
        );
      }

      if (previousStateOrder) {
        const studentsCount =
          previousStateOrder.teachers_without_groups_count !== undefined &&
          previousStateOrder.teachers_without_groups_count > 0
            ? previousStateOrder.teachers_without_groups_count - 1
            : 0;
        const newState = {
          ...previousStateOrder,
          teachers_without_groups_count: studentsCount
        };
        queryClient.setQueryData(
          ['textile_order', patch.textileOrderId],
          newState
        );
      }

      return { previousState, previousStateOrder };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['teacher_list', patch.textileOrderId],
        context?.previousState
      );
      queryClient.setQueryData(
        ['textile_order', patch.textileOrderId],
        context?.previousStateOrder
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['teacher_list', textileOrderId]);
      queryClient.invalidateQueries(['textile_order', textileOrderId]);
    }
  });
};

export const useCreateTextileName = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { textileOrderId: number; data: any }) =>
      createTextileName(data.data, data.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['teacher_list', patch.textileOrderId]);
      await queryClient.cancelQueries(['student_list', patch.textileOrderId]);
      const previousStateTeachers:
        | TextileTeacher[]
        | undefined = queryClient.getQueryData([
        'teacher_list',
        patch.textileOrderId
      ]);

      const previousStateStudents:
        | TextileName[]
        | undefined = queryClient.getQueryData([
        'student_list',
        patch.textileOrderId
      ]);

      if (previousStateTeachers && patch.data.role === 'teacher') {
        const newTeacher = {
          ...patch.data,
          id: -1,
          name: `${patch.data.first_name} ${patch.data.last_name}`
        };
        const newTeacherList = previousStateTeachers;
        newTeacherList.push(newTeacher);
        queryClient.setQueryData(
          ['teacher_list', patch.textileOrderId],
          newTeacherList
        );
      }

      if (previousStateStudents && patch.data.role === 'student') {
        const newStudent = {
          ...patch.data,
          id: -1,
          name: `${patch.data.first_name} ${patch.data.last_name}`
        };
        const newStudentList = previousStateStudents;
        newStudentList.push(newStudent);
        queryClient.setQueryData(
          ['student_list', patch.textileOrderId],
          newStudentList
        );
      }

      return { previousStateTeachers, previousStateStudents };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['teacher_list', patch.textileOrderId],
        context?.previousStateTeachers
      );
      queryClient.setQueryData(
        ['student_list', patch.textileOrderId],
        context?.previousStateStudents
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['teacher_list', textileOrderId]);
      queryClient.invalidateQueries(['student_list', textileOrderId]);
    }
  });
};

export const useDeleteTexitleNameQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (patch: { id: number; textileOrderId: number }) =>
      removeTextileName(patch.id, patch.textileOrderId),
    onMutate: async (patch) => {
      await queryClient.cancelQueries(['teacher_list', patch.textileOrderId]);
      await queryClient.cancelQueries(['student_list', patch.textileOrderId]);
      const previousStateTeachers:
        | TextileTeacher[]
        | undefined = queryClient.getQueryData([
        'teacher_list',
        patch.textileOrderId
      ]);

      const previousStateStudents:
        | TextileName[]
        | undefined = queryClient.getQueryData([
        'student_list',
        patch.textileOrderId
      ]);

      if (previousStateTeachers) {
        queryClient.setQueryData(
          ['teacher_list', patch.textileOrderId],
          (old?: TextileTeacher[]) => old?.filter((t) => t.id !== patch.id)
        );
      }

      if (previousStateStudents) {
        queryClient.setQueryData(
          ['student_list', patch.textileOrderId],
          (old?: TextileName[]) => old?.filter((t) => t.id !== patch.id)
        );
      }

      return { previousStateTeachers, previousStateStudents };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['teacher_list', patch.textileOrderId],
        context?.previousStateTeachers
      );
      queryClient.setQueryData(
        ['student_list', patch.textileOrderId],
        context?.previousStateStudents
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['teacher_list', textileOrderId]);
      queryClient.invalidateQueries(['student_list', textileOrderId]);
    }
  });
};
