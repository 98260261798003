import { NamesByGroup } from "components/print/layout-editor/TextileBackPreviewComponent";
import { CoverChapterSettingsModelType } from "models/chapter-settings/CoverChapterSettingsModel";
import { sortByFirstNameThenLastName, sortByLastNameThenFirstName } from "./sort-functions";

export interface NameRow {
  first_name?: string;
  last_name?: string;
  fullName?: string;
  fullNameLastFirst?: string;
  printName?: string;
}

export const namesFromResponseData = (
  data: any,
  order?: CoverChapterSettingsModelType['names_order']
): string[] | undefined => {
  const names: NameRow[] = (Array.isArray(data?.names)
    ? data.names
    : []
  ).map((row: NameRow) => ({
    first_name: row.first_name,
    last_name: row.last_name,
    fullName: (row.first_name || '') + ' ' + (row.last_name || ''),
    fullNameLastFirst: (row.last_name || '') + ' ' + (row.first_name || ''),
    printName:
      (row.first_name || '') +
      (row.first_name && row.last_name ? ' ' : '') +
      (row.last_name || '')
  }));

  if (order === 'last_name') {
    names.sort(sortByLastNameThenFirstName)
  } else {
    names.sort(sortByFirstNameThenLastName)
  }
  return names.map((elem) => elem.printName || '').filter((elem) => !!elem);
};

export const teachersFromResponseData = (data: any, order?: CoverChapterSettingsModelType['names_order']): string[] | undefined => {
  const names: NameRow[] = (Array.isArray(data?.teachers)
    ? data.teachers
    : []
  ).map((row: NameRow) => ({
    first_name: row.first_name,
    last_name: row.last_name,
    fullName: (row.first_name || '') + ' ' + (row.last_name || ''),
    fullNameLastFirst: (row.last_name || '') + ' ' + (row.first_name || ''),
    printName:
      (row.first_name || '') +
      (row.first_name && row.last_name ? ' ' : '') +
      (row.last_name || '')
  }));

  if (order === 'last_name') {
    names.sort(sortByLastNameThenFirstName)
  } else {
    names.sort(sortByFirstNameThenLastName)
  }

  return names.map((elem) => elem.printName || '').filter((elem) => !!elem);
};

export const namesGroupFromResponseData = (data: any, order?: CoverChapterSettingsModelType['names_order']): NamesByGroup[] | undefined => {
  const groupsWithNames: {
    group_name: string;
    names: NameRow[];
    teacher_names: NameRow[];
  }[] = Array.isArray(data?.groups_with_names)
    ? data.groups_with_names
    : [];


  let result: NamesByGroup[] = [];
  groupsWithNames.forEach((groupWithNames) => {
    let printNames: NameRow[] = groupWithNames.names.map((row: NameRow) => {
      return({
      first_name: row.first_name,
      last_name: row.last_name,
      fullName: (row.first_name || '') + ' ' + (row.last_name || ''),
      fullNameLastFirst: (row.last_name || '') + ' ' + (row.first_name || ''),
      printName:
        (row.first_name || '') +
        (row.first_name && row.last_name ? ' ' : '') +
        (row.last_name || '')
    })});

    let printTeacherNames: NameRow[] = groupWithNames.teacher_names.map((row: NameRow) => {
      return({
      first_name: row.first_name,
      last_name: row.last_name,
      fullName: (row.first_name || '') + ' ' + (row.last_name || ''),
      fullNameLastFirst: (row.last_name || '') + ' ' + (row.first_name || ''),
      printName:
        (row.first_name || '') +
        (row.first_name && row.last_name ? ' ' : '') +
        (row.last_name || '')
    })});


    if (order === 'last_name') {
      printNames.sort(sortByLastNameThenFirstName)
      printTeacherNames.sort(sortByLastNameThenFirstName)
    } else {
      printNames.sort(sortByFirstNameThenLastName)
      printTeacherNames.sort(sortByFirstNameThenLastName)
    }

    result.push({
      groupName: groupWithNames.group_name,
      names: printNames.map((elem) => elem.printName || '').filter((elem) => !!elem),
      teacherNames: printTeacherNames.map((elem) => elem.printName || '').filter((elem) => !!elem)
    });
  });

  return result;
};