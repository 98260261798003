import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_SET_GROUP } from 'utils/constants/routes';
import ItemStackBlock from 'blocks/ItemStackBlock';
import {
  EveryCard,
  EveryCardPadding,
  EveryCardBody,
  EveryCardHeadline
} from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import InputMessage from 'elements/InputMessage';

interface GroupSelectComponentProps {
  from: 'dashboard' | 'content';
  applicationStore?: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class GroupSelectComponent extends React.Component<GroupSelectComponentProps> {
  render() {
    const { applicationStore, from } = this.props;

    const groupSelectionRequired =
      applicationStore!.book?.hasActiveGroups &&
      !applicationStore!.currentUser?.group;

    if (!groupSelectionRequired || applicationStore!.book?.isLockedForPreview) {
      return null;
    }

    return (
      <ItemStackBlock gap="L">
        {from === 'content' && (
          <InputMessage warning={true}>
            <ItemStackBlock gap="XXS">
              <Paragraph size="L" weight="BOLD">
                <FormattedMessage id="content group select header" />
              </Paragraph>
              <Paragraph>
                <FormattedMessage id="content group select info" />
              </Paragraph>
            </ItemStackBlock>
          </InputMessage>
        )}

        <Link to={ROUTE_SET_GROUP}>
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="Select group" />
                </EveryCardHeadline>
              </EveryCardBody>
              <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={2} />
            </EveryCardPadding>
          </EveryCard>
        </Link>
      </ItemStackBlock>
    );
  }
}

export default GroupSelectComponent;
