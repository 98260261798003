/* eslint-disable react/style-prop-object */
import { TextileDiscount } from 'api/textile_deals/fetchTextileDiscounts';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import { IconListElement } from 'blocks/IconListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const AmountDiscountListItem = ({
  discounts,
  preordersAmount
}: {
  discounts: TextileDiscount[];
  preordersAmount: number;
}) => {
  const activeDiscount =
    discounts.find((discount) => discount.checked) || discounts[0];

  return (
    <>
      <IconListElement
        icon={activeDiscount.checked ? 'TICK_CIRCLE' : 'DOT'}
        fill={activeDiscount.checked ? 'GREEN' : 'GRAY950'}
      >
        <ItemStackBlock gap="XS">
          <HorizontalStackBlock justified={true}>
            <Paragraph>{activeDiscount.name}</Paragraph>
            <Paragraph noBreak={true}>
              <FormattedNumber
                value={activeDiscount.value}
                style="currency"
                currency="EUR"
              />
              <FormattedMessage id="per piece" />
            </Paragraph>
          </HorizontalStackBlock>
          <NonGapStackBlock>
            <TextElement size="S">
              <FormattedMessage
                id="current textile preorder amount"
                values={{ amount: preordersAmount }}
              />
            </TextElement>

            {discounts.map((discount) => {
              return (
                <TextElement key={discount.identifier}>
                  <FormattedMessage
                    id="textile preorder amount discount"
                    values={{
                      amount: discount.amount,
                      discounts: (
                        <FormattedNumber
                          value={discount.value}
                          style="currency"
                          currency="EUR"
                        />
                      )
                    }}
                  />
                </TextElement>
              );
            })}
          </NonGapStackBlock>
        </ItemStackBlock>
      </IconListElement>
    </>
  );
};

export default AmountDiscountListItem;
