import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface SliderButtonProps {
  left?: boolean;
}

const SliderButton = styled.button<SliderButtonProps>`
  display: block;
  margin-left: ${(props) => (!props.left ? '-1px' : '0')};
  border: 1px solid ${COLORS.GRAY800};
  border-radius: ${(props) =>
    props.left ? '5px 0px 0px 5px' : '0px 5px 5px 0px'};
  width: 100%;
  background: #fff;
  cursor: pointer;
  line-height: 1;
  color: ${(props) => (props.disabled ? COLORS.GRAY800 : COLORS.GRAY10)};
  appearance: none;
`;

export default SliderButton;
