import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import InfoBoxComponent from 'components/InfoBoxComponent/InfoBoxComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { SetupStoreType } from 'models/SetupStore';
import { Link } from 'react-router-dom';
import { ROUTE_JOIN, ROUTE_SIGNUP } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface SignupNumberScreenProps {
  setupStore: SetupStoreType;
  form: FormType;
  token?: string;
}

@inject('setupStore')
@observer
class SignupNumberScreen extends React.Component<
  SignupNumberScreenProps & HistoryProps & WrappedComponentProps
> {
  componentDidMount() {
    const {
      setupStore,
      history: { location },
      form
    } = this.props;

    if (setupStore.isSignupDone) {
      this.props.history.replace(ROUTE_SIGNUP + '/done');
      return;
    }

    if (!setupStore.isSignupInitialized) {
      this.props.history.replace(ROUTE_SIGNUP);
      return;
    }

    form.setField(
      'mobile_number',
      setupStore.account && setupStore.account.mobile_number
        ? setupStore.account.mobile_number
        : ''
    );

    if (location.state && location.state.error) {
      form.setError('mobile_number', location.state.error);
    }
  }

  async handleSubmit() {
    const { form, intl, setupStore } = this.props;

    form.resetErrors();

    const mobileNumber =
      form.values.mobile_number && form.values.mobile_number.trim();
    if (!mobileNumber) {
      form.setError(
        'mobile_number',
        intl.formatMessage({ id: 'form error mandatory' })
      );
      return;
    }

    try {
      await setupStore.validateNumber(mobileNumber);

      if (!setupStore.numberExists) {
        this.finish();
      }
    } catch (error: any) {
      if (!handleFormError(form, error) && !setupStore.numberExists) {
        // not a form error, just go on
        setupStore.setNumber(mobileNumber);
        this.finish();
      }

      if (setupStore.numberExists) {
        form.setError('mobile_number', null);
      }
    }
  }

  finish() {
    this.props.history.push(ROUTE_SIGNUP + '/password');
  }

  generateBackLink() {
    const { setupStore } = this.props;

    if (setupStore.token) {
      return ROUTE_JOIN + '/' + encodeURIComponent(setupStore.token);
    }

    return ROUTE_SIGNUP + '/name';
  }

  renderPage(content?: any) {
    return (
      <>
        <SignupHeaderComponent
          backRoute={this.generateBackLink()}
          progressStatus={(100 / 10) * 7}
        />

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="signup number header" />}
            text={<FormattedMessage id="signup number text" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderForm() {
    const { intl, setupStore } = this.props;

    return this.renderPage(
      <>
        {setupStore.numberExists && (
          <>
            <InfoBoxComponent warning={true}>
              <FormattedMessage
                id="signup number exists"
                values={{
                  link: (
                    <Link to="/login">
                      <FormattedMessage id="signup number exists link" />
                    </Link>
                  )
                }}
              />
            </InfoBoxComponent>
          </>
        )}

        <ItemStackBlock gap="M">
          <TextInputComponent
            name="mobile_number"
            label={intl.formatMessage({ id: 'Your number' })}
            placeholder={intl.formatMessage({ id: 'phone number placeholder' })}
            {...this.props.form.bindInput('mobile_number')}
            autoFocus={true}
            noHotjar={true}
          />

          <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
            <FormattedMessage id="Next" />
          </ButtonBlock>
        </ItemStackBlock>

        <HorizontalStackBlock centered={true} gap="XXS">
          <img src="/images/dsgvo.svg" alt="gdpr" />
          <Paragraph color="TEXT_LIGHT" size="S">
            <FormattedMessage
              id="gdpr text"
              values={{
                b: (chunks) => (
                  <strong style={{ fontWeight: '500' }}>{chunks}</strong>
                )
              }}
            />
          </Paragraph>
        </HorizontalStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const { setupStore } = this.props;

    if (setupStore.isNumberLoading) {
      return this.renderLoading();
    }

    return this.renderForm();
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  return <SignupNumberScreen {...props} form={form} />;
});
