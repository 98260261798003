import React from 'react';

import SliderButton from 'blocks/SliderButton';
import IconComponent from 'components/IconComponent';
import styled from 'styled-components';

export type SliderButtonsClickEvent = (
  e: any,
  direction: 'left' | 'right'
) => void;

interface SliderButtonsComponentProps {
  onClick?: SliderButtonsClickEvent;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
}

const SliderButtonsContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 1.5em;
`;

export const SliderButtonsComponent = ({
  onClick,
  leftDisabled,
  rightDisabled
}: SliderButtonsComponentProps) => (
  <SliderButtonsContainer>
    <SliderButton
      disabled={leftDisabled}
      left={true}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick(e, 'left');
      }}
    >
      <IconComponent icon="ARROW_LEFT" size={2} />
    </SliderButton>
    <SliderButton
      disabled={rightDisabled}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick(e, 'right');
      }}
    >
      <IconComponent icon="ARROW_RIGHT" size={2} />
    </SliderButton>
  </SliderButtonsContainer>
);

export default SliderButtonsComponent;
