import React from 'react';
import { FormattedMessage } from 'react-intl';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import CheckoutLink from 'components/CheckoutLink/CheckoutLink';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import Headline from 'elements/Headline';
import { BookModelType } from 'models/BookModel';
import { CheckoutModelType } from 'models/CheckoutModel';

const CheckoutDetails = ({
  checkout,
  book,
  handleEdit
}: {
  checkout: CheckoutModelType;
  book: BookModelType;
  handleEdit?: () => void;
}) => (
  <>
    <Headline.Medium>
      <FormattedMessage id="Checkout technical detail" />
    </Headline.Medium>
    <ItemStackBlock gap="XS">
      <HorizontalStackBlock justified={true}>
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="Checkout format" />
        </Paragraph>
        <TextElement size="M" color="TEXT_DARK">
          <FormattedMessage id="Checkout format info" />
        </TextElement>
      </HorizontalStackBlock>
      <HorizontalStackBlock justified={true}>
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="Checkout paper inside" />
        </Paragraph>
        <TextElement size="M" color="TEXT_DARK">
          <FormattedMessage id="Checkout paper inside info" />
        </TextElement>
      </HorizontalStackBlock>
      <HorizontalStackBlock justified={true}>
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="Checkout paper cover" />
        </Paragraph>
        <TextElement size="M" color="TEXT_DARK">
          <FormattedMessage id="Checkout paper cover info" />
        </TextElement>
      </HorizontalStackBlock>
      <HorizontalStackBlock justified={true}>
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="Checkout refining" />
        </Paragraph>
        <TextElement size="M" color="TEXT_DARK">
          <FormattedMessage id="Checkout refining info" />
        </TextElement>
      </HorizontalStackBlock>

      <HorizontalStackBlock justified={true}>
        <TextElement size="L" weight="BOLD" color="TEXT_DARK">
          <FormattedMessage id="Page count" />
        </TextElement>

        <TextElement size="L" color="TEXT_DARK">
          {book.checkoutPdfPagesCount}
        </TextElement>
      </HorizontalStackBlock>

      <HorizontalStackBlock justified={true}>
        <TextElement size="L" weight="BOLD" color="TEXT_DARK">
          <FormattedMessage id="Pieces" />
        </TextElement>
        <TextElement size="L" color="TEXT_DARK">
          {checkout.pieces}
        </TextElement>
      </HorizontalStackBlock>

      <HorizontalStackBlock justified={true}>
        <TextElement size="L" weight="BOLD" color="TEXT_DARK">
          <FormattedMessage id="Binding" />
        </TextElement>
        <TextElement size="L" color="TEXT_DARK">
          {checkout.binding_type === 'softcover' ? (
            <FormattedMessage id="Softcover" />
          ) : (
            <FormattedMessage id="Hardcover" />
          )}
        </TextElement>
      </HorizontalStackBlock>

      <HorizontalStackBlock justified={true}>
        <TextElement size="L" weight="BOLD" color="TEXT_DARK">
          <FormattedMessage id="price tier" />
        </TextElement>
        <TextElement size="L" color="TEXT_DARK">
          <FormattedMessage id={'price tier ' + checkout.price_tier} />
        </TextElement>
      </HorizontalStackBlock>
    </ItemStackBlock>

    {handleEdit && (
      <CheckoutLink
        text="Checkout change order details"
        onClick={() => handleEdit()}
      />
    )}
  </>
);

export default CheckoutDetails;
