import React, { useState } from 'react';

import { TextileDeliveryDate } from 'api/textile_deals/fetchTextileDeliveryDate';
import CardBlock from 'blocks/CardBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import {
  useChooseTextileDeliveryDateAfterCheckoutQuery,
  useChooseTextileDeliveryDateQuery,
  useTextileDeliveryDateQuery
} from 'queries/textile_deals/useTextileDeliveryDateQueries';
import { FormattedMessage } from 'react-intl';
import DeliveryDate from 'screens/checkout/screens/DeliveryDate';

interface ChooseDeliveryDateProps {
  deliveryDateSet: () => void;
  checkoutFinished?: boolean;
  textileOrderId?: number;
}

const TextileChooseDeliveryDateScreen: React.FC<ChooseDeliveryDateProps> = (
  props
) => {
  const [showConfirmBox, setShowConfirmBox] = useState<boolean>(false);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<
    TextileDeliveryDate | undefined
  >(undefined);
  const textileDeliveryDates = useTextileDeliveryDateQuery(
    props.textileOrderId
  );
  const chooseTextileDeliveryDate = useChooseTextileDeliveryDateQuery(
    props.textileOrderId
  );
  const chooseTextileDeliveryDateAfterCheckout = useChooseTextileDeliveryDateAfterCheckoutQuery(
    props.textileOrderId
  );

  if (textileDeliveryDates.isError) {
    return (
      <GenericErrorComponent
        onRetryClick={() => textileDeliveryDates.refetch()}
      />
    );
  }

  if (textileDeliveryDates.isLoading || !textileDeliveryDates.data) {
    return <LoadingIndicatorComponent />;
  }

  const changeDeliverDateAfterCheckout = () => {
    // TODO: UPDATE for Textile Delivery Date
    const { textileOrderId } = props;
    if (selectedDeliveryDate && textileOrderId) {
      chooseTextileDeliveryDateAfterCheckout.mutate(selectedDeliveryDate.id, {
        onSettled: () => {
          props.deliveryDateSet();
        }
      });
    }
  };

  const chooseDeliveryDate = (deliveryDate: TextileDeliveryDate) => {
    const { textileOrderId } = props;

    if (textileOrderId) {
      const patch = {...deliveryDate}

      chooseTextileDeliveryDate.mutate({patch, id: deliveryDate.id}, {
        onSettled: () => {
          props.deliveryDateSet();
        }
      });
    }
  };

  const renderConfirmFinish = () => {
    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="Confirm selection" />}
        text={<FormattedMessage id="Choose delivery date final" />}
        confirmText={<FormattedMessage id="Choose delivery date" />}
        abortText={<FormattedMessage id="Cancel" />}
        onAbort={() => {
          setShowConfirmBox(false);
        }}
        onConfirm={() => {
          setShowConfirmBox(false);
          changeDeliverDateAfterCheckout();
        }}
        confirmColor="PRIMARY"
      />
    );
  };

  const renderDeliveryDates = (deliveryDates?: TextileDeliveryDate[]) => {
    if (!deliveryDates) {
      return <LoadingIndicatorComponent />;
    }

    return deliveryDates.map((deliveryDate) => {
      const availableBooks = Math.max(0, deliveryDate.available_amount);
      return (
        <CardBlock
          key={deliveryDate.id}
          as="button"
          disabled={availableBooks === 0}
          onClick={() => {
            if (props.checkoutFinished) {
              setShowConfirmBox(true);
              setSelectedDeliveryDate(deliveryDate);
            } else {
              chooseDeliveryDate(deliveryDate);
            }
          }}
        >
          <PaddingBlock>
            <DeliveryDate deliveryDate={deliveryDate} />
          </PaddingBlock>
        </CardBlock>
      );
    });
  };

  return (
    <>
      <Headline.Large>
        <FormattedMessage id="Choose delivery date" />
      </Headline.Large>

      <InputMessage warning={true}>
        <FormattedMessage id="Choose delivery date info" />
      </InputMessage>
      <FormStackBlock>
        {renderDeliveryDates(textileDeliveryDates.data)}
      </FormStackBlock>

      {showConfirmBox && renderConfirmFinish()}
    </>
  );
};

export default TextileChooseDeliveryDateScreen;
