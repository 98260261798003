import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import GroupsList from 'domain/GroupsList/GroupsList';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import {
  ChapterModelType,
  STUDENT_EDITABLE_CHAPTER_TYPES
} from 'models/ChapterModel';
import { GroupModelType } from 'models/GroupModel';
import { assert } from 'utils/assert';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import history from 'utils/history';
import { FormType } from 'utils/hooks/useForm';

import SettingNotAvailableComponent from '../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from './SettingsDetailProps';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';

const ActivateGroups = () => (
  <>
    <Paragraph>
      <FormattedMessage id="groups explanation" />
    </Paragraph>

    <ButtonBlock
      background="PRIMARY"
      color="WHITE"
      onClick={() => history.push(ROUTE_SETTINGS + '/groups')}
    >
      <FormattedMessage id="Go to groups" />
    </ButtonBlock>
  </>
);

const AllStudentsRadioButton = ({ form }: { form: FormType }) => (
  <RadioButtonComponent
    name="access_level"
    value="open"
    label={<FormattedMessage id="All students" />}
    {...form.bindRadioButton('access_level', 'open')}
  />
);

const OrganizerRadioButton = ({ form }: { form: FormType }) => (
  <RadioButtonComponent
    name="access_level"
    value="organizer"
    label={<FormattedMessage id="Organizers only" />}
    {...form.bindRadioButton('access_level', 'organizer')}
  />
);

const GroupRadioButton = ({
  group,
  form
}: {
  group: GroupModelType;
  form: FormType;
}) => {
  // Unique value for the radio button
  const value = `group-${group.id}`;

  const onChange = () => {
    form.setField('group_id', group.id);
    form.setField('group_name', group.name);
  };
  return (
    <RadioButtonComponent
      name="access_level"
      value={value}
      label={group.name}
      {...form.bindRadioButton('access_level', value, onChange)}
    />
  );
};

const renderContent = (content: ReactNode) => (
  <>
    <Headline.Large>
      <FormattedMessage id="Chapter access" />
    </Headline.Large>
    <ItemStackBlock gap="M">{content}</ItemStackBlock>
  </>
);

/**
 * Access settings for splittable chapters (factsheet, yearbook)
 */
const SplittableChapterAccessSettings = ({
  groupsEnabled,
  chapter,
  backRoute
}: {
  groupsEnabled: boolean;
  chapter: ChapterModelType;
  backRoute?: string;
}) => {
  if (groupsEnabled) {
    if (chapter.group) {
      // Groups enabled, group set: Only students of a group have access. No options.
      return (
        <Paragraph>
          <FormattedMessage
            id="chapter access group"
            values={{ name: chapter.group.name }}
          />
        </Paragraph>
      );
    }
    // Groups enabled, group no set: Organizer may split into groups.
    assert(backRoute, 'backRoute must be given');
    return (
      <>
        <Paragraph>
          <FormattedMessage id="chapter split info" />
        </Paragraph>
        <LinkBlock to={`${backRoute}/split`}>
          <FormattedMessage id="chapter split" />
        </LinkBlock>
      </>
    );
  }
  // Groups not enabled. All students have access. Organizer may enable groups.
  return <ActivateGroups />;
};

/**
 * Access settings for non-splittable chapters
 */
const NormalChapterAccessSettings = ({
  groupsEnabled,
  form,
  onCommitChapter
}: {
  groupsEnabled: boolean;
  form: FormType;
  onCommitChapter: (patch: any) => void;
}) => {
  const accessLevelError = form.errors?.access_level;
  const groupIdError = form.errors?.group_id || undefined;

  return (
    <>
      <Paragraph>
        <FormattedMessage id="chapter access settings info" />
      </Paragraph>

      <AllStudentsRadioButton form={form} />

      <OrganizerRadioButton form={form} />

      {groupsEnabled && (
        <GroupsList
          selected={form.values.group_id}
          itemComponent={({ group }) => (
            <GroupRadioButton form={form} group={group} />
          )}
        />
      )}

      {(accessLevelError || groupIdError) && (
        <InputMessage error={true}>
          <TextElement>{accessLevelError || groupIdError}</TextElement>
        </InputMessage>
      )}

      <ButtonBlock
        onClick={() => {
          const access_level: string = form.values.access_level;
          const group_id: number = form.values.group_id;
          const patch = {
            // There is no access_level "group-*"" on the server, so translate it to "open".
            access_level: access_level === 'organizer' ? 'organizer' : 'open',
            group_id: /^group-/.test(access_level) ? group_id : null
          };
          onCommitChapter(patch);
        }}
      >
        <FormattedMessage id="Save" />
      </ButtonBlock>

      {!groupsEnabled && <ActivateGroups />}
    </>
  );
};

const AccessSettingsContainer = ({
  form,
  chapter,
  backRoute,
  onCommitChapter
}: SimpleSettingsDetailProps & { backRoute?: string }) => {
  if (STUDENT_EDITABLE_CHAPTER_TYPES.indexOf(chapter.chapter_type) < 0) {
    return <SettingNotAvailableComponent />;
  }

  const groupsEnabled = chapter.book_groups_applied || false;
  const isSplittable =
    chapter.chapter_type === 'factsheet' || chapter.chapter_type === 'yearbook';

  const content = isSplittable ? (
    <SplittableChapterAccessSettings
      groupsEnabled={groupsEnabled}
      chapter={chapter}
      backRoute={backRoute}
    />
  ) : (
    <NormalChapterAccessSettings
      groupsEnabled={groupsEnabled}
      form={form}
      onCommitChapter={onCommitChapter}
    />
  );

  return renderContent(content);
};

export default AccessSettingsContainer;
