import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import CardBlock from 'blocks/CardBlock';
import ImageBlock from 'blocks/ImageBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import SponsorListItemComponent from 'components/SponsorListItemComponent/SponsorListItemComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import CancelLink from 'domain/Links/CancelLink';
import ColoredText from 'elements/ColoredText';
import Headline from 'elements/Headline';
import NakedButton from 'elements/NakedButton';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { SponsorModelType } from 'models/SponsorModel';
import { SponsorPagesStoreType } from 'screens/sponsor_pages/SponsorPagesStore';
import { SponsorsStoreType } from 'screens/sponsors/SponsorsStore';
import {
  ROUTE_BACK_COVER,
  ROUTE_LAYOUT,
  ROUTE_SPONSOR_DETAILS
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

interface BackSponsorScreenProps extends HistoryProps {
  chaptersStore: ChaptersStoreType;
  sponsorsStore: SponsorsStoreType;
  sponsorPagesStore: SponsorPagesStoreType;
}

@inject('chaptersStore', 'sponsorsStore', 'sponsorPagesStore')
@observer
class BackSponsorScreen extends React.Component<BackSponsorScreenProps> {
  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const { chaptersStore } = this.props;

    // Load in background, do not await
    this.loadSponsors();

    const cover = await chaptersStore.getCoverChapter();

    if (cover && !cover.layout_key) {
      this.props.history.replace(ROUTE_LAYOUT + '/theme');
    }
  }

  loadSponsors() {
    this.props.sponsorsStore.getAllSponsors();
  }

  async placeCoverSponsor(sponsor: SponsorModelType) {
    await this.props.sponsorPagesStore.placeCoverSponsor(sponsor, 1);
    this.navigateBack();
  }

  async removeCoverSponsor() {
    await this.props.sponsorPagesStore.removeCoverSponsor();
    this.navigateBack();
  }

  navigateBack() {
    this.props.history.push(ROUTE_BACK_COVER);
  }

  renderPage(content: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_BACK_COVER} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Cover back sponsor" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadData()} />
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderSponsorList() {
    const {
      sponsorsStore: {
        unplacedSponsors,
        isListLoading: isSponsorsLoading,
        isListError: isSponsorsError
      }
    } = this.props;

    if (isSponsorsLoading) {
      return <LoadingIndicatorComponent />;
    }
    if (isSponsorsError) {
      return (
        <GenericErrorComponent
          onRetryClick={() => {
            this.loadSponsors();
          }}
        />
      );
    }

    if (!unplacedSponsors || unplacedSponsors.length === 0) {
      return (
        <Paragraph>
          <FormattedMessage id="sponsors filter count" values={{ count: 0 }} />
        </Paragraph>
      );
    }

    return (
      <TwoColumnBlock gap="M">
        {unplacedSponsors.map((sponsor) => (
          <NakedButton
            key={sponsor.id}
            onClick={() => this.placeCoverSponsor(sponsor)}
          >
            <SponsorListItemComponent sponsor={sponsor} />
          </NakedButton>
        ))}
      </TwoColumnBlock>
    );
  }

  render() {
    const {
      chaptersStore: {
        isItemLoading: isChapterLoading,
        isItemLoadError: isChapterError,
        item: cover
      },
      sponsorPagesStore: { sponsorPageIsError }
    } = this.props;

    if (isChapterLoading) {
      return this.renderLoading();
    }

    if (isChapterError) {
      return this.renderError();
    }

    const coverSponsor = cover?.coverSponsor;
    const sponsorAdUrl = coverSponsor?.advertisement?.url;

    return this.renderPage(
      <>
        {coverSponsor && (
          <>
            {sponsorAdUrl ? (
              <ImageBlock height={20.875} background="GRAY950" fullWidth={true}>
                <img src={sponsorAdUrl} alt="" />
              </ImageBlock>
            ) : (
              <>
                <Paragraph>

                  <FormattedMessage id="Advert still missing" />
                </Paragraph>
                <Link to={ROUTE_SPONSOR_DETAILS + '/' + coverSponsor.id}>
                  <CardBlock>
                    <PaddingBlock arrow={true}>
                      <ColoredText color="BLACK">
                        <FormattedMessage id="Edit sponsor" />
                      </ColoredText>
                    </PaddingBlock>
                  </CardBlock>
                </Link>
              </>
            )}
            <DeleteButton
              onClick={() => this.removeCoverSponsor()}
              messageId="Remove sponsor"
            />
          </>
        )}
        <Paragraph>
          <FormattedMessage
            id={
              coverSponsor
                ? 'Sponsor page edit slot select other sponsor'
                : 'Sponsor page edit slot select sponsor initially'
            }
          />
        </Paragraph>
        {sponsorPageIsError && <GenericErrorComponent />}
        {this.renderSponsorList()}
      </>
    );
  }
}

export default BackSponsorScreen;
