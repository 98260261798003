import { detach, flow, getEnv, Instance, types } from 'mobx-state-tree';
import ChapterModel, { createChapterModel } from 'models/ChapterModel';
import CommentModel, {
  CommentModelType,
  createCommentModel
} from 'models/CommentModel';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from 'models/ProfileAsAuthorModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { createMapWithTransform } from 'utils/create-map';
import { moveItem } from 'utils/item-sorting';
import { sortByField, sortById } from 'utils/sort-functions';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import { assert } from 'utils/assert';


export const MY_COMMENTS_ID = -1;

const CommentsStore = types
  .model('CommentsStore', {
    // list
    loadingState: types.maybe(types.string),
    comments: types.maybe(types.map(CommentModel)),
    commentsProfileId: types.maybe(types.number), // we need to know which user's comments are currently in our list
    commentsProfile: types.maybe(ProfileAsAuthorModel),
    chapter: types.maybe(ChapterModel),
    profileCommentsCount: types.maybe(types.number),

    // singe item
    itemLoadingState: types.maybe(types.string),
    item: types.maybe(CommentModel)
  })
  .actions((self) => {
    const getMyComments = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';

        self.commentsProfileId = undefined;
        self.commentsProfile = undefined;
        self.comments = undefined;
        self.chapter = undefined;
        self.profileCommentsCount = undefined;

        const result: any = yield client.getMyComments();

        self.commentsProfileId = MY_COMMENTS_ID;

        if (Array.isArray(result.comments)) {
          self.comments = createMapWithTransform(
            result.comments,
            createCommentModel
          );
        }

        self.chapter = createChapterModel(result.chapter);
        self.profileCommentsCount = numberOrUndefined(
          result.profile_comments_count
        );

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CommentsStore | getMyComments', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const getProfileComments = flow(function* (profileId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';

        self.commentsProfileId = undefined;
        self.commentsProfile = undefined;
        self.comments = undefined;
        self.chapter = undefined;
        self.profileCommentsCount = undefined;

        const result: any = yield client.getProfileComments(profileId);

        self.commentsProfileId = profileId;

        if (!Array.isArray(result.comments)) {
          self.loadingState = undefined;
          return;
        }

        self.comments = createMapWithTransform(
          result.comments,
          createCommentModel
        );
        self.commentsProfile = createProfileAsAuthorModel(result.profile);

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'CommentsStore | getProfileComments',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const getComment = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const comment: any = yield client.getComment(id);

        self.item = createCommentModel(comment);
        self.itemLoadingState = undefined;

        return self.item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CommentsStore | getComment', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    const createComment = flow(function* (comment: any, addToList = true) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        comment = yield client.createComment(comment);

        const model = createCommentModel(comment);
        if (model) {
          if (addToList) {
            // TODO check if commentsProfileId matches?
            self.comments?.put(model);
          }

          self.item = createCommentModel(comment);
        }

        self.itemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CommentsStore | createComment', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateComment = flow(function* (commentId: number, patch: any) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        const result: any = yield client.updateComment(commentId, patch);
        const model = createCommentModel(result);

        if (model) {
          if (self.comments?.has(model.id.toString())) {
            self.comments.put(model);
          }

          if (self.item?.id === model.id) {
            self.item = createCommentModel(result);
          }
        }

        self.itemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CommentsStore | updateComment', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const removeComment = flow(function* (
      commentId: number,
      removeFromList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        yield client.removeComment(commentId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('CommentsStore | removeComment', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.itemLoadingState = 'update_error';
          throw error;
        }
      }

      if (removeFromList) {
        self.comments?.delete(commentId.toString());
      }

      if (self.item?.id === commentId) {
        self.item = undefined;
      }
      self.itemLoadingState = undefined;
    });

    const moveComment = flow(function* (oldIndex: number, newIndex: number) {
      assert(self.comments);

      const { items, map } = moveItem(self.comments, oldIndex, newIndex);

      for (const comment of self.comments.values()) {
        detach(comment);
      }

      self.comments = map;

      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        // Do not set loading state, this would block the UI
        self.loadingState = undefined;

        yield client.setCommentSorting(items as CommentModelType[]);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | moveQuestion', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
        throw error;
      }

      self.loadingState = undefined;
    });

    const clearCurrentItem = () => {
      self.item = undefined;
      self.itemLoadingState = undefined;
    };
    return {
      getMyComments,
      getProfileComments,
      getComment,
      createComment,
      updateComment,
      removeComment,
      clearCurrentItem,
      moveComment
    };
  })
  .views((self) => {
    return {
      get allComments(): CommentModelType[] {
        if (!self.comments?.size) {
          return [];
        }

        const comments: CommentModelType[] = [];
        for (const comment of self.comments.values()) {
          comments.push(comment);
        }

        comments.sort(sortById);
        return comments;
      },
      get sortedComments() :CommentModelType[] {
        if (!self.comments?.size) {
          return [];
        }

        const comments: CommentModelType[] = [];
        for (const comment of self.comments.values()) {
          comments.push(comment);
        }

        comments.sort(sortByField('sorting'));
        return comments;
      },
      filteredComments(text: string): CommentModelType[] {
        if (!self.comments?.size) {
          return [];
        }

        const textLowercase = text.trim().toLowerCase();

        const comments: CommentModelType[] = [];
        for (const comment of self.comments.values()) {
          if (
            comment.comment &&
            comment.comment.toLowerCase().indexOf(textLowercase) > -1
          ) {
            comments.push(comment);
          }
        }

        comments.sort(sortByField('sorting'));
        return comments;
      },

      get allCommentsCount(): number {
        return self.comments?.size || 0;
      },

      get isListLoading(): boolean {
        return self.loadingState === 'loading' ? true : false;
      },
      get isListError(): boolean {
        return self.loadingState === 'error' ? true : false;
      },

      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading' ? true : false;
      },
      get isItemError(): boolean {
        return self.itemLoadingState === 'error' ||
          self.itemLoadingState === 'update_error'
          ? true
          : false;
      },

      get isItemOwnComment(): boolean {
        if (!self.item || !self.item.author) {
          return false;
        }

        const { applicationStore } = getEnv<AdvancedStoreEnv>(self);
        if (
          applicationStore.currentUserId &&
          self.item.author.id === applicationStore.currentUserId
        ) {
          return true;
        }
        return false;
      }

      // get isOwnCommentsCountLoading(): boolean {
      //   return self.ownCommentsCountLoadingState === 'loading' ? true : false;
      // },

      // get isMyCommentsCountLoading(): boolean {
      //   return self.myCommentsCountLoadingState === 'loading' ? true : false;
      // },
    };
  });

export type CommentsStoreType = Instance<typeof CommentsStore>;
export default CommentsStore;
