import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface NumberElementProps {
  disabled?: boolean;
}

const NumberElement = styled.span<NumberElementProps>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 2.1875em;
  height: 2.1875em;
  background: ${COLORS.GRAY950};
  font-size: 2em;
  line-height: 2.1875;
  font-weight: 700;
  text-align: center;
  color: rgba(${COLORS.SECONDARY_DARK_RGB}, 0.5);
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.32
  `};
`;

export default NumberElement;
