import React from 'react';

import { Boundary } from 'utils/chunker/ContentChunker';
import replaceVariables, {
  PageParams,
  PagePosition,
  sanitizeReplacement
} from 'utils/chunker/replace-variables';
import sanitizeHtml from 'utils/chunker/sanitize-html';

import PageComponent, {
  PAGE_SIZE_A4,
  PAGE_SIZE_PRINT
} from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import {
  CommonTemplateProps,
  pageParamsFromProps
} from './CommonTemplateProps';
import WaitForImages from './WaitForImages';

export interface ImagePageSlot {
  // basics
  id: number;
  size: Boundary; // [width, height]

  // additional layout params
  position: Boundary; // [left, top]

  caption?: {
    size: Boundary; // [width, height]
    style?: string;
  };
}

export interface ImagePageTemplateConfig {
  leftBackground?: string;
  rightBackground?: string;

  leftOverlay?: string;
  rightOverlay?: string;

  leftTitleBlock?: string;
  rightTitleBlock?: string;

  imageBlock: string;
  fittedImageBlock: string;

  captionBlock?: string;

  titleEnabled?: boolean;
  titleMargin?: number;

  slots: ImagePageSlot[];
}

interface ImagePageImage {
  slotId: number; // TODO remove this?
  url: string;
  fit?: boolean;
  caption?: string;
}

export interface ImagePageImageMap {
  [slotId: number]: ImagePageImage;
}

interface Props extends CommonTemplateProps {
  // theme
  config: ImagePageTemplateConfig;

  // content
  images?: ImagePageImageMap;

  // misc
  positionMode?: boolean; // will display slot numbers in empty slots
}

export default class ImagePageTemplateComponent extends React.Component<Props> {
  generateReplaceParams(
    position: PagePosition = 'left',
    page: number = 1
  ): PageParams {
    return pageParamsFromProps(this.props, position, page);
  }

  generateBackground(
    position: PagePosition,
    page?: number
  ): { background: string; overlay?: string; hasTitle: boolean } {
    const { config, title } = this.props;

    const background =
      position === 'right' && !!config.rightBackground
        ? config.rightBackground
        : config.leftBackground;

    const overlay =
      position === 'right' && !!config.rightOverlay
        ? config.rightOverlay
        : config.leftOverlay;

    if (!background && !overlay) {
      return { background: '', hasTitle: false };
    }

    const params = this.generateReplaceParams(position, page);

    let hasTitle = false;
    if (config.titleEnabled) {
      const sanitizedTitle = sanitizeHtml(title) || '';

      if (sanitizedTitle) {
        const titleBlock =
          position === 'right' && !!config.rightTitleBlock
            ? config.rightTitleBlock
            : config.leftTitleBlock;

        if (titleBlock) {
          hasTitle = true;

          params.variables!.push([
            'titleblock',
            replaceVariables(titleBlock, params) || ''
          ]);
        }
      } else {
        params.variables!.push(['titleblock', '']);
      }
    } else {
      params.variables!.push(['titleblock', '']);
    }

    return {
      background: replaceVariables(background, params) || '',
      overlay: replaceVariables(overlay, params),
      hasTitle
    };
  }

  renderEmptySlot(slot: ImagePageSlot) {
    // TODO center number
    return (
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          left: slot.position[0] + 'px',
          top: slot.position[1] + 'px',
          width: slot.size[0] + 'px',
          height: slot.size[1] + 'px',
          backgroundColor: '#eee',
          color: '#ccc'
        }}
      >
        <p
          style={{
            fontSize: '80px',
            fontWeight: 900
          }}
        >
          {slot.id}
        </p>
      </div>
    );
  }

  renderSlotBlock(slot: ImagePageSlot, image?: ImagePageImage) {
    if (!image) {
      if (this.props.positionMode) {
        return this.renderEmptySlot(slot);
      }
      return null;
    }

    const { imageBlock, fittedImageBlock, captionBlock } = this.props.config;
    let block = image.fit ? fittedImageBlock : imageBlock;

    block = block.replace(
      new RegExp('{url}', 'g'),
      sanitizeReplacement(
        image.url.toString().replace(/"/g, '&quot;').replace(/'/g, '&#039;')
      )
    );

    block = block.replace(
      new RegExp('{left}', 'g'),
      slot.position[0].toString() + 'px'
    );
    block = block.replace(
      new RegExp('{top}', 'g'),
      slot.position[1].toString() + 'px'
    );
    block = block.replace(
      new RegExp('{width}', 'g'),
      slot.size[0].toString() + 'px'
    );
    block = block.replace(
      new RegExp('{height}', 'g'),
      slot.size[1].toString() + 'px'
    );

    let caption = '';
    if (slot.caption && image.caption && captionBlock) {
      caption =
        replaceVariables(captionBlock, this.generateReplaceParams(), [
          ['width', slot.caption.size[0].toString() + 'px'],
          ['height', slot.caption.size[1].toString() + 'px'],
          ['style', slot.caption.style || ''],
          ['caption', sanitizeHtml(image.caption) || '']
        ]) || '';
    }

    block = block.replace(new RegExp('{caption}', 'g'), caption);

    return <div dangerouslySetInnerHTML={{ __html: block }} />;
  }

  render() {
    const { print, config, startPosition, startPage, render } = this.props;
    const images = this.props.images || {};

    const { background, overlay, hasTitle } = this.generateBackground(
      startPosition,
      startPage
    );

    const slotAreaMargin =
      hasTitle && config.titleMargin ? config.titleMargin : 0;

    const result = (
      <PageComponent
        key={0}
        print={print}
        previewSize={print ? PAGE_SIZE_PRINT : PAGE_SIZE_A4}
        background={background}
        overlay={overlay}
      >
        <PageContentComponent>
          <div style={{ position: 'relative', marginTop: slotAreaMargin }}>
            {config.slots.map((slot, idx) => (
              <React.Fragment key={idx}>
                {this.renderSlotBlock(slot, images[slot.id])}
              </React.Fragment>
            ))}
          </div>
        </PageContentComponent>
      </PageComponent>
    );

    return (
      <>
        {!render ? result : render(result, 0)}
        {print && <WaitForImages />}
      </>
    );
  }
}
