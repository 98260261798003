import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBox from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import LockedLabel from 'domain/Label/LockedLabel';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FactsheetsStoreType } from 'screens/factsheets/FactsheetsStore';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import chapterIdFromMatch, {
  backRouteFromMatch
} from 'utils/history/chapter-id-from-match';
import { FormType, handleFormError, useForm } from 'utils/hooks/useForm';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

interface QuestionDetailScreenProps {
  factsheetsStore: FactsheetsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('factsheetsStore', 'applicationStore')
@observer
class QuestionDetailScreen extends Component<
  QuestionDetailScreenProps & HistoryProps
> {
  async loadQuestion(id: number) {
    const { factsheetsStore, form } = this.props;

    if (factsheetsStore.isQuestionItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const question = await factsheetsStore.getQuestion(id);

    if (question) {
      form.setField('question', question.question || '');
      form.setField('print', question.print || false);
    }
  }

  async componentDidMount() {
    const id = idFromMatch(this.props.match);
    if (id) {
      await this.loadQuestion(id);
    } else {
      // new item mode
      this.props.factsheetsStore.clearCurrentQuestionItem();
      this.props.form.reset();
    }
  }

  async componentDidUpdate(prevProps: any) {
    const newId = idFromMatch(this.props.match);
    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new item mode
          this.props.factsheetsStore.clearCurrentQuestionItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        await this.loadQuestion(newId);
      }
    }
  }

  navigateToList() {
    this.props.history.replace(
      ROUTE_FACTSHEETS +
        '/' +
        chapterIdFromMatch(this.props.match) +
        '/questions'
    );
  }

  async handleSubmit() {
    const { form, factsheetsStore, applicationStore } = this.props;
    const { question, print } = form.values;

    form.resetErrors();

    const patch: any = {
      question: !question ? '' : question.trim(),
      print: !!print
    };

    try {
      if (this.isAddMode()) {
        patch.chapter_id = chapterIdFromMatch(this.props.match);

        await factsheetsStore.createQuestion(patch);
      } else {
        const id = factsheetsStore.questionItem?.id;
        if (!id) {
          return;
        }

        await factsheetsStore.updateQuestion(id, patch);
      }
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }

    applicationStore.setDefaultFlashMessage('saved');
    this.navigateToList();
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  async performDelete() {
    const { factsheetsStore } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return;
    }

    this.finishDelete();

    try {
      await factsheetsStore.removeQuestion(id);
    } catch (error: any) {
      // delete errors are handled by store internally
      return;
    }

    this.navigateToList();
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  isAddMode() {
    return !idFromMatch(this.props.match) ? true : false;
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  renderReadOnly() {
    const { factsheetsStore } = this.props;

    return this.renderPage(
      <>
        <LockedLabel id="Questions" />

        <Paragraph>{factsheetsStore.questionItem?.question}</Paragraph>

        <CustomCheckboxBlock>
          <CustomCheckboxBlock.CheckboxElement
            id="state_checkbox"
            checked={!!factsheetsStore.questionItem?.print}
            disabled={true}
          />
          <CustomCheckboxBlock.LabelElement htmlFor="state_checkbox">
            <FormattedMessage id="Will be printed" />
          </CustomCheckboxBlock.LabelElement>
        </CustomCheckboxBlock>
      </>
    );
  }

  renderEditForm() {
    const { factsheetsStore, form } = this.props;
    const isAddMode = this.isAddMode();

    let extra;
    if (form.confirm) {
      extra = (
        <ConfirmBox
          header={<FormattedMessage id="confirm delete header" />}
          text={
            <FormattedMessage
              id="confirm delete item"
              values={{
                item: <FormattedMessage id="This question" />
              }}
            />
          }
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => this.performDelete()}
          onAbort={() => this.finishDelete()}
          confirmColor="RED"
        />
      );
    } else if (factsheetsStore.questionItemLoadingState === 'update_error') {
      extra = <GenericErrorComponent />;
    }

    return this.renderPage(
      <>
        {extra}

        <FormStackBlock>
          <TextInputComponent
            name="question"
            label={intl.formatMessage({ id: 'Question' })}
            {...form.bindInput('question')}
            autoFocus={true}
          />

          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="state_checkbox"
              disabled={false}
              {...form.bindCheckbox('print')}
            />
            <CustomCheckboxBlock.LabelElement htmlFor="state_checkbox">
              <FormattedMessage id="Will be printed" />
            </CustomCheckboxBlock.LabelElement>
          </CustomCheckboxBlock>
        </FormStackBlock>

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id={isAddMode ? 'Add' : 'Save'} />
        </ButtonBlock>

        {!isAddMode && (
          <>
            <Divider />
            <DeleteButton onClick={() => this.confirmDelete()} />
          </>
        )}
      </>
    );
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink
            to={
              backRouteFromMatch(ROUTE_FACTSHEETS, this.props.match) +
              '/questions'
            }
          />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage
              id={this.isAddMode() ? 'Add question' : 'Edit question'}
            />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { factsheetsStore } = this.props;

    if (factsheetsStore.isQuestionItemLoading) {
      return this.renderLoading();
    }

    if (factsheetsStore.questionItemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    // TODO send chapter to client and re-enable this?
    // if (
    //   !this.isAddMode() &&
    //   !applicationStore.isChapterEditAllowed(
    //     factsheetsStore.questionItem?.chapter
    //   )
    // ) {
    //   return this.renderReadOnly();
    // }

    return this.renderEditForm();
  }
}

export default (props: any) => {
  const form = useForm({ print: true });
  return <QuestionDetailScreen {...props} form={form} />;
};
