import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';
import { IconComponentProps } from 'components/IconComponent/IconComponent';
import { ChapterModelType } from 'models/ChapterModel';
import {
  ROUTE_ALBUMS,
  ROUTE_QUOTES,
  ROUTE_RANKINGS
} from 'utils/constants/routes';
import TextElement from 'components/TextElement/TextElement';

const StudentChapterListItem = ({ chapter }: { chapter: ChapterModelType }) => {
  let icon: IconComponentProps['icon'] = 'chapter_factsheets',
    link,
    title,
    subline;

  switch (chapter.chapter_type) {
    case 'album':
      link = ROUTE_ALBUMS;
      icon = 'chapter_album';
      title = 'Album';

      subline = (
        <FormattedMessage
          id="photos added count"
          values={{
            count: chapter.own_photos_count || 0
          }}
        />
      );
      break;

    case 'quote':
      link = ROUTE_QUOTES;
      icon = 'chapter_quotes';
      title = 'Quotes';

      subline = (
        <FormattedMessage
          id="quotes added count"
          values={{
            count: chapter.own_quotes_count || 0
          }}
        />
      );
      break;

    case 'ranking':
      link = ROUTE_RANKINGS;
      icon = 'chapter_rankings';
      title = 'Rankings';

      const count = chapter.rankings_count || 0;
      const votes = chapter.own_votes_count || 0;

      subline = (
        <FormattedMessage
          id="rankings stats"
          values={{
            count,
            voted: votes
          }}
        />
      );
      // status =
      //   votes < count ? (
      //     <>
      //       <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />
      //       <FormattedMessage id="vote on all rankings" />
      //     </>
      //   ) : (
      //     <>
      //       <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
      //       <FormattedMessage id="voted on all rankings" />
      //     </>
      //   );
      break;

    default:
      return null;
  }

  return (
    <Link to={link + '/' + chapter.id}>
      <EveryCard>
        <EveryCardPadding>
          <IconComponent
            icon={icon}
            circleBackground="PRIMARY_LIGHT"
            fill="PRIMARY"
            size={3}
          />

          <EveryCardBody>
            <EveryCardHeadline>
              {chapter.title || <FormattedMessage id={title} />}
            </EveryCardHeadline>

            {subline && <TextElement  color="TEXT_DARK">{subline}</TextElement>}
          </EveryCardBody>
        </EveryCardPadding>

        {/* status && <EveryCardFooter>
          <TextElement  color="TEXT_DARK">
            {status}
          </TextElement>
        </EveryCardFooter> */}
      </EveryCard>
    </Link>
  );
};

export default StudentChapterListItem;
