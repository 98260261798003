import { inject, observer } from 'mobx-react';
import React from 'react';

import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import { HistoryProps } from 'utils/history';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { CommentsStoreType } from '../CommentsStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import {
  ROUTE_CONTENT,
  ROUTE_COMMENTS,
  ROUTE_FACTSHEETS
} from 'utils/constants/routes';
import TopActionBlock from 'blocks/TopActionBlock';
import BackLink from 'domain/Links/BackLink';
import BackClickLink from 'domain/Links/BackClickLink';
import Headline from 'elements/Headline';
import { FormattedMessage } from 'react-intl';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import ItemStackBlock from 'blocks/ItemStackBlock';
import {
  EveryCard,
  EveryCardPadding,
  EveryCardBody,
  EveryCardHeadline
} from 'blocks/EveryCard/EveryCard';
import NakedButton from 'elements/NakedButton';
import TextElement from 'components/TextElement/TextElement';import IconComponent from 'components/IconComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import { intl } from 'i18n';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import ButtonBlock from 'blocks/ButtonBlock';

interface AddCommentScreenProps {
  commentsStore: CommentsStoreType;
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
  form: FormType;
}

type AddCommentScreenStep =
  | 'student_intro'
  | 'organizer_intro'
  | 'select_student'
  | 'select_teacher'
  | 'form';

interface AddCommentScreenState {
  step: AddCommentScreenStep;
  backToIntro?: boolean;
}

@inject('commentsStore', 'applicationStore', 'profilesStore')
@observer
class AddCommentScreen extends React.Component<
  AddCommentScreenProps & HistoryProps,
  AddCommentScreenState
> {
  state: AddCommentScreenState = {
    step: 'student_intro'
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps: AddCommentScreenProps & HistoryProps) {
    if (this.getProfileId() !== this.getProfileId(prevProps)) {
      this.init();
    }
  }

  private getProfileId(props?: HistoryProps) {
    return (
      parseInt((props || this.props).location.query?.for || '', 10) || undefined
    );
  }

  private async init() {
    const { applicationStore, profilesStore, form, history } = this.props;

    await profilesStore.getAllProfiles();

    form.reset();

    if (applicationStore.isOrganizer) {
      const profileId = this.getProfileId();
      const profile =
        profileId && profilesStore.profileFromAllProfiles(profileId);

      if (!profile) {
        history.replace(ROUTE_CONTENT);
        return;
      }

      form.setField('profile_id', profile.id);
      form.setField('profile_name', profile.fullName);

      this.setState({
        step: 'organizer_intro'
      });
      return;
    }

    this.setState({
      step: profilesStore.hasAllProfilesTeachers
        ? 'student_intro'
        : 'select_student'
    });
  }

  private async handleSubmit() {
    const { applicationStore, commentsStore, form, history } = this.props;

    const profileId = form.values.profile_id;
    if (!profileId) {
      return;
    }

    const comment: any = {
      profile_id: profileId,
      comment: form.values.comment || ''
    };

    if (applicationStore.isOrganizer) {
      comment.author_id = form.values.author_id || undefined;
    }

    try {
      await commentsStore.createComment(comment);

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'comment saved' })
      );

      if (applicationStore.isOrganizer) {
        history.push(ROUTE_FACTSHEETS + '/detail/' + profileId + '/comments');
      } else {
        history.push(ROUTE_COMMENTS);
      }
    } catch (err) {
      handleFormError(form, err);
    }
  }

  private selectAuthorSelf() {
    const { form } = this.props;
    const { currentUser } = this.props.applicationStore;

    if (!currentUser) {
      return;
    }

    form.setField('author_id', currentUser.id);
    form.setField('author_name', currentUser.fullName);

    this.setState({
      step: 'form'
    });
  }

  private selectProfile(profileId: number) {
    const { applicationStore, profilesStore, form } = this.props;
    const { isOrganizer } = applicationStore;

    const profile = profilesStore.profileFromAllProfiles(profileId);

    if (!profile) {
      return;
    }

    if (isOrganizer) {
      form.setField('author_id', profile.id);
      form.setField('author_name', profile.fullName);
    } else {
      form.setField('profile_id', profile.id);
      form.setField('profile_name', profile.fullName);
    }

    this.setState({
      step: 'form'
    });
  }

  private renderPage(
    content: any,
    backToStep?: AddCommentScreenStep,
    backToProfile = false
  ) {
    const profileId = backToProfile && this.getProfileId();

    return (
      <>
        <TopActionBlock>
          {profileId ? (
            <BackLink to={ROUTE_FACTSHEETS + '/detail/' + profileId} />
          ) : backToStep ? (
            <BackClickLink
              onClick={() => this.setState({ step: backToStep })}
            />
          ) : (
            <BackLink to={ROUTE_COMMENTS} />
          )}
        </TopActionBlock>

        {content}
      </>
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  private renderStudentIntro() {
    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="select profile header" />
        </Headline.Large>

        <ItemStackBlock gap="S" marginTop="M">
          <NakedButton
            onClick={() =>
              this.setState({ step: 'select_student', backToIntro: true })
            }
          >
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="select profile student" />
                  </EveryCardHeadline>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          </NakedButton>

          <NakedButton
            onClick={() =>
              this.setState({ step: 'select_teacher', backToIntro: true })
            }
          >
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="select profile teacher" />
                  </EveryCardHeadline>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          </NakedButton>
        </ItemStackBlock>
      </>
    );
  }

  private renderOrganizerIntro() {
    const { hasAllProfilesTeachers } = this.props.profilesStore;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="select author header" />
        </Headline.Large>

        <ItemStackBlock gap="S" marginTop="M">
          <NakedButton onClick={() => this.selectAuthorSelf()}>
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="author type self" />
                  </EveryCardHeadline>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          </NakedButton>

          <NakedButton
            onClick={() => this.setState({ step: 'select_student' })}
          >
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="author type student" />
                  </EveryCardHeadline>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          </NakedButton>

          {hasAllProfilesTeachers && (
            <NakedButton
              onClick={() => this.setState({ step: 'select_teacher' })}
            >
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="author type teacher" />
                    </EveryCardHeadline>
                  </EveryCardBody>
                </EveryCardPadding>
              </EveryCard>
            </NakedButton>
          )}
        </ItemStackBlock>
      </>,
      undefined,
      true
    );
  }

  private renderProfileSelect() {
    const { isOrganizer } = this.props.applicationStore;
    const { students, teachers } = this.props.profilesStore.allProfilesSorted();

    const list = this.state.step === 'select_teacher' ? teachers : students;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage
            id={isOrganizer ? 'select author header' : 'select profile header'}
          />

          <ItemStackBlock gap="S" marginTop="M">
            {list.map((profile) => (
              <NakedButton
                key={profile.id}
                onClick={() => this.selectProfile(profile.id)}
              >
                <EveryCard>
                  <EveryCardPadding>
                    <EveryCardBody>
                      <EveryCardHeadline>
                        {profile.fullName || profile.id}
                      </EveryCardHeadline>
                    </EveryCardBody>
                  </EveryCardPadding>
                </EveryCard>
              </NakedButton>
            ))}
          </ItemStackBlock>
        </Headline.Large>
      </>,
      isOrganizer
        ? 'organizer_intro'
        : this.state.backToIntro
        ? 'student_intro'
        : undefined
    );
  }

  renderForm() {
    const { applicationStore, commentsStore, form } = this.props;

    const disabled = !form.values.comment;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="New comment" />
        </Headline.Large>

        <TextElement  color="TEXT_DARK">
          <IconComponent icon="USER" />
          <FormattedMessage
            id="for name"
            values={{
              name: form.values.profile_name
            }}
          />
        </TextElement>

        <ItemStackBlock gap="M" marginTop="M">
          {commentsStore.itemLoadingState === 'update_error' && (
            <GenericErrorComponent />
          )}

          {applicationStore.isOrganizer &&
            form.values.author_id !== applicationStore.currentUserId && (
              <TextInputComponent
                name="author_name"
                label={intl.formatMessage({ id: 'comment from' })}
                value={form.values.author_name || ''}
                disabled={true}
                readOnly={true}
              />
            )}

          <TextareaComponent
            name="comment"
            label={intl.formatMessage({ id: 'Comment' })}
            placeholder={intl.formatMessage({ id: 'Enter text' })}
            height={20}
            {...form.bindInput('comment')}
            autoFocus={true}
          />

          <ButtonBlock
            background={disabled ? 'GRAY800' : 'PRIMARY'}
            onClick={() => this.handleSubmit()}
            disabled={disabled}
          >
            <FormattedMessage id="Add" />
          </ButtonBlock>
        </ItemStackBlock>
      </>,
      applicationStore.isOrganizer
        ? 'organizer_intro'
        : this.state.backToIntro
        ? 'student_intro'
        : 'select_student'
    );
  }

  render() {
    const {
      isAllProfilesLoading,
      isAllProfilesError
    } = this.props.profilesStore;
    const { isItemLoading } = this.props.commentsStore;
    const { step } = this.state;

    if (isAllProfilesLoading || isItemLoading) {
      return this.renderLoading();
    }

    if (isAllProfilesError) {
      return this.renderPage(
        <GenericErrorComponent onRetryClick={() => this.init()} />
      );
    }

    switch (step) {
      case 'organizer_intro':
        return this.renderOrganizerIntro();

      case 'student_intro':
        return this.renderStudentIntro();

      case 'select_student':
      case 'select_teacher':
        return this.renderProfileSelect();

      case 'form':
        return this.renderForm();
    }
  }
}

export default (props: any) => {
  const form = useForm();
  return <AddCommentScreen {...props} form={form} />;
};
