import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import Input from 'elements/Input';
import InputAppend from 'elements/InputAppend';

interface InputBlockProps {
  rounded?: boolean;
  borderColor?: keyof typeof COLORS;
  backgroundColor?: keyof typeof COLORS;
}

const InputBlock = styled.div<InputBlockProps>`
  display: flex;
  width: 100%;

  ${InputAppend} {
    background: ${(props) =>
      props.backgroundColor ? COLORS[props.backgroundColor] : COLORS.WHITE};
    border: 1px solid
      ${(props) =>
        props.borderColor ? COLORS[props.borderColor] : COLORS.GRAY800};
    border-left: none;
  }

  ${Input} {
    border: 1px solid
      ${(props) =>
        props.borderColor ? COLORS[props.borderColor] : COLORS.GRAY800};
    background: ${(props) =>
      props.backgroundColor ? COLORS[props.backgroundColor] : COLORS.WHITE};
    flex-grow: 1;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 1%;

    &:focus {
      border-color: ${COLORS.PRIMARY};
    }

    &:focus ~ ${InputAppend} {
      border-color: ${COLORS.PRIMARY};
    }
  }

  ${(props) =>
    props.rounded &&
    `
& > ${Input}{
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
& > ${InputAppend}{
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}
`}
`;

export default InputBlock;
