import React, { useContext, useEffect } from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import { HasAccessContext } from 'contexts/HasAccessContext';
import { useCreateTextileGroup } from 'queries/textile_deals/useTextileGroupsQueries';

interface FormProps {
  form: FormType;
}

const TextileGroupAdd: React.FC<HistoryProps & FormProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);

  const createTextileGroup = useCreateTextileGroup(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('design');
  }, [accessContext]);

  const addGroup = () => {
    const { form } = props;

    if (!textileOrderId) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    createTextileGroup.mutate(
      { textileOrderId, name: form.values.name },
      {
        onSuccess: () => {
          props.history.push(textileOrderRoute(props.match, '/groups'));
        },
        onError: (error) => {
          form.setLoading(false);
          if (!handleFormError(form, error)) {
            form.setError('base', true);
          }
        }
      }
    );
  };

  const renderAddGroupButton = () => {
    const { form } = props;
    return (
      <>
        {form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => addGroup()}>
          <FormattedMessage id="create group link" />
        </ButtonBlock>
      </>
    );
  };

  const renderAddGroup = () => {
    const { form } = props;

    return renderPage(
      <>
        <FormStackBlock>
          <TextInputComponent
            name="name"
            label={<FormattedMessage id="Group" />}
            {...form.bindInput('name')}
            autoFocus={true}
          />
        </FormStackBlock>

        {renderAddGroupButton()}
      </>
    );
  };

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={textileOrderRoute(props.match, '/groups')} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="create group link" />
          </Headline.Large>
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (props.form.loading) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  return renderAddGroup();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <TextileGroupAdd {...props} form={form} />;
};
