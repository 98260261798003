import React from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import Center from 'elements/Center';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';

interface EmptyStateComponentProps {
  margin?: boolean;
  showText?: boolean;
  headerId?: string;
  textId?: string;
  showImage?: boolean;
}

export const EmptyStateComponent = ({
  margin = true,
  showText = true,
  headerId,
  textId,
  showImage = true
}: EmptyStateComponentProps) => {
  const emptyState = (
    <Center>
      <ItemStackBlock gap="L">
        { showImage && (
          <div>
            <IconComponent icon="empty_state" fill="SECONDARY" size={4} />
          </div>
        )}

        <ItemStackBlock gap="XXS">
          <Paragraph  color="TEXT_LIGHT">
            <FormattedMessage id={headerId || 'empty default header'} />
          </Paragraph>

          {showText && (
            <TextElement>
              <FormattedMessage id={textId || 'empty default'} />
            </TextElement>
          )}
        </ItemStackBlock>
      </ItemStackBlock>
    </Center>
  );

  if (!margin) {
    return emptyState;
  }

  return (
    <>
      {emptyState}
    </>
  );
};

export default EmptyStateComponent;
