import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ImageCard,
  ImageCardBody,
  ImageCardImage,
  ImageCardLabel,
  ImageCardStatus,
  LabelledImageCard
} from 'blocks/ImageCard/ImageCard';
import IconComponent from 'components/IconComponent';
import { SponsorModelType } from 'models/SponsorModel';

export const SponsorListItemComponent = ({
  sponsor
}: {
  sponsor: SponsorModelType;
}) => {
  const file = sponsor.advertisement;
  return (
    <LabelledImageCard>
      <ImageCard contain={true} dim={sponsor.placed}>
        {file && file.url ? (
          <ImageCardImage src={file.url} alt="" />
        ) : (
          <ImageCardBody icon="HOURGLASS">
            <FormattedMessage id="Processing" />
          </ImageCardBody>
        )}
        {sponsor.placed && (
          <ImageCardStatus>
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
            <FormattedMessage id="Placed" />
          </ImageCardStatus>
        )}
      </ImageCard>
      <ImageCardLabel>{sponsor.name}</ImageCardLabel>
    </LabelledImageCard>
  );
};

export default SponsorListItemComponent;
