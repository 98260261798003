import React from 'react';

import { ProfileModelType } from 'models/ProfileModel';
// TODO make this "import {} from 'styled-components/cssprop';" after updating TS
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as types from 'styled-components/cssprop';
import {
  ChunkedContent,
  Content,
  createBox,
  fillColumns,
  updatePages
} from 'utils/chunker/ContentChunker';
import replaceVariables, {
  PageParams,
  PagePosition
} from 'utils/chunker/replace-variables';
import sanitizeHtml from 'utils/chunker/sanitize-html';

import PicturePrintComponent, {
  PrintPicture
} from '../elements/PicturePrintComponent';
import NotAvailableComponent from '../processing/NotAvailableComponent';
import ColumnComponent from '../structure/ColumnComponent';
import PageComponent, {
  PAGE_SIZE_A4,
  PAGE_SIZE_PRINT,
  TOP_LEFT_A4,
  TOP_LEFT_PRINT
} from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import {
  getColumns,
  MultiColumnLayout,
  spaceFromConfig
} from './column-layout';
import {
  CommonTemplateProps,
  pageParamsFromProps
} from './CommonTemplateProps';
import WaitForImages from './WaitForImages';

export interface ProfileTemplateConfig extends MultiColumnLayout {
  leftBackground?: string;
  rightBackground?: string;
  leftAdditionalBackground?: string;
  rightAdditionalBackground?: string;

  // currentPhotoBlockLeft?: string;
  // currentPhotoBlockRight?: string;
  // pastPhotoBlockLeft?: string;
  // pastPhotoBlockRight?: string;

  photo1BlockLeft?: string;
  photo1BlockRight?: string;
  photo2BlockLeft?: string;
  photo2BlockRight?: string;
  photo3BlockLeft?: string;
  photo3BlockRight?: string;

  // columns?: Array<'questions' | 'text' | 'comments'>;
  // floating?: Array<'questions' | 'text' | 'comments'>;

  picturePageBackground?: string;

  questionsHeader?: string;
  questionBlock?: string;
  questionsHeaderMargin?: number;
  questionBlockMargin?: number;
  lastQuestionBlockMargin?: number;

  textHeader?: string;
  textHeaderMargin?: number;
  textStyle?: string;
  textLineHeight?: number;
  textMargin?: number;

  commentsHeader?: string;
  commentNameBlock?: string;
  commentTextStyle?: string;
  commentTextLineHeight?: number;
  commentsHeaderMargin?: number;
  commentNameBlockMargin?: number;
  commentTextMargin?: number;
  lastCommentTextMargin?: number;
}

export interface ProfileTemplateQuestion {
  question: string;
  answer: string;
}

export interface ProfileTemplateComment {
  author: string;
  comment: string;
}

export interface ProfileTemplateProps extends CommonTemplateProps {
  // theme
  config: ProfileTemplateConfig;

  picturePageEnabled?: boolean;
  anonymousComments?: boolean;

  questionsHeader?: string;
  textHeader?: string;
  commentsHeader?: string;

  // content
  content?: Content;
  profile: ProfileModelType;
  questions?: ProfileTemplateQuestion[];
  comments?: ProfileTemplateComment[];
  text?: string;
  picture?: PrintPicture;
}

interface ProfileTemplateComponentState {
  content?: Content;
  pages?: ChunkedContent[];
}

export class ProfileTemplateComponent extends React.Component<
  ProfileTemplateProps,
  ProfileTemplateComponentState
> {
  state: ProfileTemplateComponentState = {
    content: undefined,
    pages: undefined
  };

  componentDidMount() {
    // TODO run placeContent again when props are changed?
    this.placeContent();
  }

  generateReplaceParams(position: PagePosition, page?: number): PageParams {
    const { profile, questionsHeader, textHeader, commentsHeader } = this.props;

    const params = pageParamsFromProps(this.props, position, page);

    params.variables!.push([
      'firstname',
      sanitizeHtml(profile.first_name) || ''
    ]);
    params.variables!.push(['lastname', sanitizeHtml(profile.last_name) || '']);

    params.variables!.push([
      'header_questions',
      sanitizeHtml(questionsHeader) || ''
    ]);
    params.variables!.push(['header_text', sanitizeHtml(textHeader) || '']);
    params.variables!.push([
      'header_comments',
      sanitizeHtml(commentsHeader) || ''
    ]);

    return params;
  }

  generatePrimaryBackground(): string {
    const { config, startPosition, startPage, profile, print } = this.props;

    const background =
      startPosition === 'right' && !!config.rightBackground
        ? config.rightBackground
        : config.leftBackground;
    if (!background) {
      return '';
    }

    const params = this.generateReplaceParams(startPosition, startPage);
    params.variables = params.variables || [];

    // photo 1
    const photo1Url = print
      ? profile.slot1_photo?.photo?.cropped
      : profile.slot1_photo?.photo?.preview;
    const photo1Block =
      startPosition === 'right' && !!config.photo1BlockRight
        ? config.photo1BlockRight
        : config.photo1BlockLeft;
    if (photo1Block && photo1Url) {
      params.variables.push(['photo1_url', photo1Url]);

      params.variables.push([
        'photo1',
        replaceVariables(photo1Block, params) || ''
      ]);
    } else {
      // TODO use default or alternative block here?
      params.variables.push(['photo1', '']);
    }

    // photo 2
    const photo2Url = print
      ? profile.slot2_photo?.photo?.cropped
      : profile.slot2_photo?.photo?.preview;
    const photo2Block =
      startPosition === 'right' && !!config.photo2BlockRight
        ? config.photo2BlockRight
        : config.photo2BlockLeft;
    if (photo2Block && photo2Url) {
      params.variables.push(['photo2_url', photo2Url]);

      params.variables.push([
        'photo2',
        replaceVariables(photo2Block, params) || ''
      ]);
    } else {
      // TODO use default or alternative block here?
      params.variables.push(['photo2', '']);
    }

    // photo 3
    const photo3Url = print
      ? profile.slot3_photo?.photo?.cropped
      : profile.slot3_photo?.photo?.preview;
    const photo3Block =
      startPosition === 'right' && !!config.photo3BlockRight
        ? config.photo3BlockRight
        : config.photo3BlockLeft;
    if (photo3Block && photo3Url) {
      params.variables.push(['photo3_url', photo3Url]);

      params.variables.push([
        'photo3',
        replaceVariables(photo3Block, params) || ''
      ]);
    } else {
      // TODO use default or alternative block here?
      params.variables.push(['photo3', '']);
    }

    return replaceVariables(background, params) || '';
  }

  generateAdditionalBackground(position: PagePosition, page?: number): string {
    const { config } = this.props;

    const background =
      position === 'right' && !!config.rightAdditionalBackground
        ? config.rightAdditionalBackground
        : config.leftAdditionalBackground;
    if (!background) {
      return '';
    }

    const params = this.generateReplaceParams(position, page);
    return replaceVariables(background, params) || '';
  }

  generateContent() {
    const {
      questions,
      comments,
      text,
      content,
      config,
      textHeader,
      commentsHeader,
      questionsHeader
    } = this.props;

    const params = this.generateReplaceParams('left', 1);

    const generated: Content = [];

    if (questions && questions.length) {
      if (config.questionsHeader && questionsHeader) {
        generated.push(
          createBox({
            type: 'header',
            template: config.questionsHeader,
            bottomMargin: config.questionsHeaderMargin,
            params
          })
        );
      }

      const maxQuestionIndex = questions.length - 1;
      questions.forEach((question, questionIndex) => {
        const bottomMargin =
          questionIndex === maxQuestionIndex &&
          typeof config.lastQuestionBlockMargin === 'number'
            ? config.lastQuestionBlockMargin
            : config.questionBlockMargin;
        generated.push(
          createBox({
            type: 'box',
            template: config.questionBlock,
            bottomMargin,
            params,
            contentVariables: [
              ['question', sanitizeHtml(question.question) || ''],
              ['answer', sanitizeHtml(question.answer) || '']
            ]
          })
        );
      });
    }

    if (text) {
      if (config.textHeader && textHeader) {
        generated.push(
          createBox({
            type: 'header',
            template: config.textHeader,
            bottomMargin: config.textHeaderMargin,
            params
          })
        );
      }

      generated.push(
        createBox({
          type: 'floating',
          text: sanitizeHtml(text) || '',
          textStyle: config.textStyle,
          lineHeight: config.textLineHeight,
          bottomMargin: config.textMargin,
          params
        })
      );
    }

    if (comments && comments.length) {
      const { anonymousComments } = this.props;

      const maxCommentIndex = comments.length - 1;
      comments.forEach((comment, commentIndex) => {
        const commentText = sanitizeHtml(comment.comment) || '';
        const vars: [string, string][] = [
          ['author', sanitizeHtml(comment.author) || ''],
          ['comment', commentText]
        ];

        let headerTemplate: string | undefined;
        const nameTemplate =
          (!anonymousComments && config.commentNameBlock) || undefined;

        if (commentIndex === 0) {
          headerTemplate =
            config.commentsHeader && commentsHeader
              ? config.commentsHeader
              : undefined;
        }

        if (headerTemplate || nameTemplate) {
          generated.push(
            createBox({
              type: 'header',
              template: (headerTemplate || '') + (nameTemplate || ''),
              params,
              contentVariables: vars,
              bottomMargin: !nameTemplate
                ? config.commentsHeaderMargin
                : config.commentNameBlockMargin
            })
          );
        }

        const bottomMargin =
          commentIndex === maxCommentIndex &&
          typeof config.lastCommentTextMargin === 'number'
            ? config.lastCommentTextMargin
            : config.commentTextMargin;
        generated.push(
          createBox({
            type: 'floating',
            text: commentText,
            textStyle: config.commentTextStyle,
            lineHeight: config.commentTextLineHeight,
            bottomMargin,
            params
          })
        );
      });
    }

    return content ? generated.concat(content) : generated;
  }

  hasPicturePage() {
    return this.props.picturePageEnabled && !!this.props.picture;
  }

  placeContent() {
    const { config, startPosition, startPage, onContentPlaced } = this.props;

    const isFirstPageRight = startPosition === 'right';
    const space = spaceFromConfig(config, isFirstPageRight);

    const content = this.generateContent();
    const pages = fillColumns(space, content);

    updatePages(startPosition, startPage, content, pages);

    this.setState({
      content,
      pages
    });

    if (onContentPlaced) {
      onContentPlaced(this.hasPicturePage() ? pages.length + 1 : pages.length);
    }
  }

  renderPicturePage() {
    if (!this.hasPicturePage()) {
      return null;
    }

    const { print, render, picture, config } = this.props;

    const result = (
      <PageComponent
        key="picpage"
        print={print}
        previewSize={print ? PAGE_SIZE_PRINT : PAGE_SIZE_A4}
        background={config.picturePageBackground || ''}
      >
        <PageContentComponent>
          <PicturePrintComponent
            picture={picture!}
            size={PAGE_SIZE_PRINT}
            fitSize={PAGE_SIZE_A4}
            position={TOP_LEFT_PRINT}
            fitPosition={TOP_LEFT_A4}
          />
        </PageContentComponent>
      </PageComponent>
    );

    return !render ? result : render(result, 'picpage');
  }

  render() {
    const {
      print,
      startPosition,
      startPage,
      render,
      config,
      firstPageOnly
    } = this.props;

    const { content } = this.state;
    let { pages } = this.state;

    if (!pages || !content) {
      return <NotAvailableComponent print={print} />;
    }

    const isRight = startPosition === 'right';

    if (firstPageOnly && pages.length) {
      pages = [pages[0]];
    }

    let isCurrentRight = !isRight;
    return (
      <>
        {pages.map((page: ChunkedContent, pageIndex) => {
          isCurrentRight = !isCurrentRight;
          const isAdditional = pageIndex !== 0;
          const pageNum = startPage + pageIndex;

          const background = isAdditional
            ? this.generateAdditionalBackground(
                isCurrentRight ? 'right' : 'left',
                pageNum
              )
            : this.generatePrimaryBackground();

          const columns = getColumns(config, isAdditional, isCurrentRight);

          const result = !columns ? null : (
            <PageComponent
              key={pageIndex}
              print={print}
              previewSize={print ? PAGE_SIZE_PRINT : PAGE_SIZE_A4}
              background={background}
            >
              <PageContentComponent>
                {page.map((column, columnIndex) => (
                  <ColumnComponent
                    key={'c' + columnIndex}
                    size={columns[columnIndex].size}
                    position={columns[columnIndex].position}
                  >
                    {column.map((box, boxIndex) => (
                      <div
                        key={boxIndex}
                        style={{
                          marginBottom: !content[box.sourceIndex].bottomMargin
                            ? 0
                            : content[box.sourceIndex].bottomMargin + 'px'
                        }}
                      >
                        {box.type === 'floating' ? (
                          <div
                            css={content[box.sourceIndex].textStyle}
                            dangerouslySetInnerHTML={{
                              __html: box.text || ''
                            }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: content[box.sourceIndex].content || ''
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </ColumnComponent>
                ))}
              </PageContentComponent>
            </PageComponent>
          );

          return !render ? result : render(result, pageIndex);
        })}

        {!firstPageOnly && this.renderPicturePage()}
        {print && <WaitForImages />}
      </>
    );
  }
}

export default ProfileTemplateComponent;
