import { get } from 'api/BaseApi';
import { Preorder } from './fetchPreorders';
import { TextilePrice } from './fetchTextilePrices';
import { TextileColor, TextileVariant } from './fetchTextileVariants';
import { Textile } from './fetchTextiles';

export interface OverviewPreorder {
  preorders?: Preorder[];
  textiles?: Textile[];
  price?: TextilePrice;
  preorder_notification?: Notification;
  orders?: CompleteOrder[];
  textile_variants?: TextileVariant[];
  profile_name?: string;
  is_rejected?: boolean;
  active_students?: number;
  submitted_preorders_students?: number;
}

interface CompleteOrder {
  textile_name: string;
  textile_id: number;
  textile_amount: number;
  textile_variants: MinimalVariantAmount[];
}

export interface MinimalVariantAmount {
  textile_color: TextileColor;
  variant_amount: number;
  sizes: MinimalSizeAmount[];
}

export interface MinimalSizeAmount {
  size: string;
  size_amount: number;
}

export interface OverviewPlaced {
  preorders?: Preorder[];
  textiles?: Textile[];
  orders?: CompleteOrder[];
  invoice_items: InvoiceItem[];
  total_price: number;
}

interface InvoiceItem {
  name: string;
  price: number;
  quantity: number;
}

export const getTextilePreorderOverview = (
  textileOrderId: number
): Promise<OverviewPreorder> => {
  return get(
    `/textile_screen/overview_preorders?textile_order_id=${textileOrderId}`
  );
};


export const getTextileVirtualPreorderOverview = (textileOrderId: number): Promise<OverviewPreorder> => {
  return get(`/textile_screen/overview_virtual_preorders?textile_order_id=${textileOrderId}`);
}

export const getTextileProfilePreorderOverview = (id: number, textileOrderId: number): Promise<OverviewPreorder> => {
  return get(`/textile_screen/overview_profile_preorders?textile_order_id=${textileOrderId}&textile_profile_id=${id}`);
}

export const getTextileOrderPlacedOverview = (textileOrderId: number): Promise<OverviewPlaced> => {
  return get(`/textile_screen/order_placed_overview?textile_order_id=${textileOrderId}`);
}