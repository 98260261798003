import {
  TextileThemeLayout,
  TextileThemeMotto
} from 'api/textile_deals/fetchTextileThemes';
import { sortByFieldLowerCase, sortByName } from './sort-functions';

export const privateThemes = (
  themes: TextileThemeLayout[]
): TextileThemeLayout[] => {
  if (!themes.length) {
    return [];
  }

  const list: TextileThemeLayout[] = [];

  themes.forEach((theme) => {
    if (theme.tags.indexOf('filtered') >= 0) {
      list.push(theme);
    }
  });

  list.sort(sortByFieldLowerCase('id'));
  return list;
};

export const neutralThemes = (
  themes: TextileThemeLayout[]
): TextileThemeLayout[] => {
  if (!themes.length) {
    return [];
  }

  const list: TextileThemeLayout[] = [];

  themes.forEach((theme) => {
    if (
      theme.tags.indexOf('neutral') >= 0 &&
      theme.tags.indexOf('unindexed') > -1
    ) {
      list.push(theme);
    }
  });

  list.sort(sortByFieldLowerCase('id'));
  return list;
};

export const mottoThemes = (
  themes: TextileThemeLayout[],
  mottos: TextileThemeMotto[]
): { motto: TextileThemeMotto; themes: TextileThemeLayout[] }[] => {
  if (!themes.length) {
    return [];
  }

  const collection: {
    [key: string]: {
      motto: TextileThemeMotto;
      themes: TextileThemeLayout[];
    };
  } = {};

  mottos.forEach((motto) => {
    collection[motto.key] = {
      motto: motto,
      themes: []
    };
  });


  themes.forEach((theme) => {
    theme.tags.forEach((tag) => {
      if (tag.startsWith('motto:')) {
        const motto = mottos.find((motto) => motto.key === tag.substring(6));

        if (motto && collection[motto.key]) {
          collection[motto.key].themes.push(theme);
        }
      }
    });
  });

  const list = Object.values(collection);

  list.sort((a, b) => sortByName(a.motto, b.motto));
  return list;
};
