import { types, Instance } from 'mobx-state-tree';

import {
  createArrayWithTransform,
  createMapWithTransform
} from 'utils/create-map';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import createVoteModel from 'utils/store/createVoteModel';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import {
  createRankingTypeEnum,
  RankingTypeEnum
} from './chapter-settings/RankingChapterSettingsModel';
import ChapterModel, { createChapterModel } from './ChapterModel';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';
import VoteCandidateModel, {
  createVoteCandidateModel
} from './VoteCandidateModel';
import VoteModel from './VoteModel';

const RankingModel = types.model('RankingModel', {
  id: types.identifierNumber,
  chapter_id: types.maybe(types.number),
  chapter: types.maybe(ChapterModel),
  name: types.maybe(types.string),
  ranking_type: RankingTypeEnum,
  num_candidates: types.maybe(types.number),
  votes_count: types.maybe(types.number),
  did_vote: types.maybe(types.boolean),
  votes: types.maybe(types.array(VoteModel)),
  own_votes: types.maybe(types.map(VoteCandidateModel)),
  author: types.maybe(ProfileAsAuthorModel),
  sorting: types.maybe(types.number),
  first_place: types.maybe(types.string),
  second_place: types.maybe(types.string),
  third_place: types.maybe(types.string),
  select_winner_manually: types.maybe(types.boolean),
});

export const createRankingModel = (data: any): RankingModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return RankingModel.create({
    id: mandatoryId(data.id),
    chapter_id: numberOrUndefined(data.chapter_id),
    chapter: createChapterModel(data.chapter),
    name: data.name || undefined,
    ranking_type: createRankingTypeEnum(data.ranking_type),
    num_candidates: numberOrUndefined(data.num_candidates),
    votes_count: numberOrUndefined(data.votes_count),
    did_vote: booleanOrUndefined(data.did_vote),
    votes: !data.votes
      ? undefined
      : createArrayWithTransform(data.votes, createVoteModel),
    own_votes: !data.own_votes
      ? undefined
      : createMapWithTransform(data.own_votes, createVoteCandidateModel),
    author: createProfileAsAuthorModel(data.author),
    sorting: numberOrUndefined(data.sorting),
    first_place: data.first_place || '',
    second_place: data.second_place || '',
    third_place: data.third_place || '',
    select_winner_manually: booleanOrUndefined(data.select_winner_manually)
  });
};

export type RankingModelType = Instance<typeof RankingModel>;
export default RankingModel;
