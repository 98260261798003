import { get } from 'api/BaseApi';

export interface TextileVariant {
  id: number;
  textile_id: number;
  textile_name: string;
  textile_internal_identifier: string;
  inactive: boolean;
  textile_color?: TextileColor;
  textile_sizes?: TextileSize[];
  thumbnail_bottom?: string;
  thumbnail_top?: string;
  front_preview_bottom?: string;
  front_preview_top?: string;
  back_preview_bottom?: string;
  back_preview_top?: string;
  detail_1_bottom?: string;
  detail_1_top?: string;
  detail_2_bottom?: string;
  detail_2_top?: string;
  detail_3_bottom?: string;
  detail_3_top?: string;
}

export interface TextileColor {
  id: number;
  name: string;
  display_name: string;
  color_preview: string;
  color_hex: string;
}

interface TextileSize {
  id: number;
  title: string;
}

export const getTextileVariantsForTextileOrder = (
  textileOrderId: number
): Promise<TextileVariant[]> => {
  return get(
    '/textile_variants/?textile_order_id=' + textileOrderId
  );
};
