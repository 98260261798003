import style from 'styled-components';

export default style.span<{ lines?: number }>`
  /*
  These properties have -webkit prefixes but other browsers support them as well.
  See https://caniuse.com/css-line-clamp
  */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ lines }) => (lines === undefined ? 2 : lines)};
  -webkit-box-orient: vertical;
`;
