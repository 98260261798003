import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTE_DASHBOARD, ROUTE_SETTINGS } from 'utils/constants/routes';

import HeaderBlock from 'blocks/HeaderBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import SupportButton from 'domain/Buttons/SupportButton';

import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import { FormattedMessage } from 'react-intl';
import Logo from './Logo';

export const HeaderComponent = ({
  noLogo,
  noAccount,
  linkHome,
  children,
  showSupportButton,
  linkAccount,
  nolinkProjects
}: {
  noLogo?: boolean;
  noAccount?: boolean;
  linkHome?: string;
  children?: React.ReactNode;
  showSupportButton?: boolean;
  linkAccount?: string;
  nolinkProjects?: boolean;
}) => (
  <HeaderBlock>
    <HorizontalStackBlock justified={true} centered={true}>
      <Link to={linkHome ? linkHome : ROUTE_DASHBOARD}>
        {!noLogo && <Logo />}
      </Link>

      {showSupportButton && <SupportButton />}

      <HorizontalStackBlock gap="XXS">
        <>
          {!nolinkProjects && (
            <Link to={'/app/account'}>
              <Paragraph size="XS" color="BLACK">
                <HorizontalStackBlock gap="XXS">
                  <FormattedMessage id="Project dashboard" />
                  <IconComponent icon="ACCOUNT" size={1.2} />
                </HorizontalStackBlock>
              </Paragraph>
            </Link>
          )}
        </>

        {!noAccount && (
          <Link to={linkAccount ? linkAccount : ROUTE_SETTINGS + '/account'}>
            <Paragraph size="XS" color="BLACK">
              <IconComponent icon="USER_CIRCLE" size={1.2} />
            </Paragraph>
          </Link>
        )}
      </HorizontalStackBlock>

      {children}
    </HorizontalStackBlock>
  </HeaderBlock>
);

export default HeaderComponent;
