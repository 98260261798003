import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import Divider from 'components/Divider/Divider';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TextElement from 'components/TextElement/TextElement';
import config from 'config';
import { LegalEnum, SetupStoreType } from 'models/SetupStore';
import useForm, { FormType } from 'utils/hooks/useForm';

interface LegalContainerProps {
  setupStore: SetupStoreType;
  form: FormType;
  type: LegalEnum;
  progressStatus?: number;
  signup?: boolean;
  backRoute?: string;
  onAccept: (type: LegalEnum, engagementAccepted?: boolean) => void;
}

@inject('setupStore')
@observer
class LegalContainer extends React.Component<LegalContainerProps> {
  handleAccept() {
    const { type, onAccept } = this.props;
    switch (type) {
      case 'tos':
      case 'privacy':
        onAccept(type);
        return;
    }
  }

  renderPage(content?: any) {
    const { backRoute, progressStatus } = this.props;

    return (
      <>
        <SignupHeaderComponent
          backRoute={backRoute}
          progressStatus={progressStatus}
        />

        {content}
      </>
    );
  }

  renderLegal() {
    const { type } = this.props;

    return this.renderPage(
      <PageStackBlock>
        <PageHeader headline={<FormattedMessage id={`signup ${type} header`} />} text={<FormattedMessage id={`signup ${type} text`} />} />

        {type === 'tos' && (
          <IconListBlock>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="signup tos free usage" />
            </IconListElement>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="signup tos min age" />
            </IconListElement>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="signup tos bullying" />
            </IconListElement>
          </IconListBlock>
        )}

        {type === 'privacy' && (
          <IconListBlock>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="signup privacy save storage" />
            </IconListElement>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="signup privacy no data transfer" />
            </IconListElement>
          </IconListBlock>
        )}

        <a
          href={type === 'tos' ? config.tosUrl : config.privacyUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TextElement textDecoration='underline' color="TEXT_LIGHT">
            <FormattedMessage id={`signup ${type} text link`} />
          </TextElement>
        </a>

        <Divider />

        <ButtonBlock background="PRIMARY" onClick={() => this.handleAccept()}>
          <FormattedMessage id="Accept" />
        </ButtonBlock>
      </PageStackBlock>
    );
  }

  render() {
    return this.renderLegal();
  }
}

export default (props: any) => {
  const form = useForm();
  return <LegalContainer {...props} form={form} />;
};
