import { useQuery } from "@tanstack/react-query";
import { getOrderCalculatorOverview } from "api/textile_deals/fetchOrderCalculatorOverview";
import { getTextileOrderPlacedOverview, getTextilePreorderOverview, getTextileProfilePreorderOverview, getTextileVirtualPreorderOverview } from "api/textile_deals/fetchOverviewPreorder";

export const useOrderOverviewQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['order_overview', textileOrderId],
    queryFn: () => getTextilePreorderOverview(textileOrderId!),
    enabled: !!textileOrderId
  });
};

export const useVirtualOrderOverviewQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['virtual_order_overview', textileOrderId],
    queryFn: () => getTextileVirtualPreorderOverview(textileOrderId!),
    enabled: !!textileOrderId
  });
};

export const useProfilePreorderOverview = (id?: number, textileOrderId?: number) => {
  return useQuery({
    queryKey: ['profile_preorder_overview', id],
    queryFn: () => getTextileProfilePreorderOverview(id!, textileOrderId!),
    enabled: !!textileOrderId && !!id
  });
};

export const useCalculatorOverviewQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['calculator_overview', textileOrderId],
    queryFn: () => getOrderCalculatorOverview(textileOrderId!),
    enabled: !!textileOrderId
  });
};

export const useTextileOrderPlacedOverviewQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['order_placed_overview', textileOrderId],
    queryFn: () => getTextileOrderPlacedOverview(textileOrderId!),
    enabled: !!textileOrderId
  });
}