import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBlock from 'blocks/HeaderBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';

import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';

function Loading() {
  return (
    <>
      <LoadingOverlayComponent />
      <HeaderBlock>
        <HorizontalStackBlock justified={true}>
          <Link to="#">
            <HeaderBlock.ImageElement src="/images/nuggit-logo.svg" alt="Nuggit" />
          </Link>
          <Link to="#">
            <IconComponent icon="drag" fill="SECONDARY_DARK" size={1.375} />
          </Link>
        </HorizontalStackBlock>
      </HeaderBlock>
      <Paragraph>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, id a
        odit, voluptates doloremque quae vitae harum hic dolore laudantium autem
        iusto in officia non beatae reprehenderit nihil quas reiciendis eos,
        incidunt cum magni voluptate error fugit! In officiis explicabo et
        officia, omnis consectetur vitae suscipit unde blanditiis incidunt velit
        totam esse hic porro fuga obcaecati accusamus impedit quod vero libero
        repellat dignissimos dicta. Placeat voluptatibus ullam enim
        exercitationem, vel eum minus numquam alias libero consectetur!
        Accusantium eveniet officiis provident officia necessitatibus
        consequatur! Aliquam porro veniam adipisci blanditiis exercitationem
        pariatur quos iure non ea totam! Natus nobis earum voluptas sed!
      </Paragraph>
    </>
  );
}

export default Loading;
