import React from 'react';
import { FormattedMessage } from 'react-intl';

import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import { BookModelType } from 'models/BookModel';
import { CheckoutModelType } from 'models/CheckoutModel';

const CheckoutSummary = ({
  checkout,
  book
}: {
  checkout: CheckoutModelType;
  book: BookModelType;
}) => (
  <>
    <Headline.Medium>
      <FormattedMessage id="Checkout summary" />
    </Headline.Medium>

    <Paragraph size="L" >
      <FormattedMessage
        id="Checkout short summary"
        values={{
          page_count: book.checkoutPdfPagesCount,
          pieces: checkout.pieces,
          binding:
            checkout.binding_type === 'hardcover' ? 'Hardcover' : 'Softcover'
        }}
      />
    </Paragraph>
  </>
);

export default CheckoutSummary;
