import React from 'react';

import ProfileTemplateComponent, {
  ProfileTemplateComment,
  ProfileTemplateConfig,
  ProfileTemplateQuestion
} from 'components/print/templates/ProfileTemplateComponent';
import { ProfileModelType } from 'models/ProfileModel';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import createProfileModel from 'utils/store/createProfileModel';
import { PrintPicture } from '../elements/PicturePrintComponent';
import { CommonLayoutPreviewProps } from './CommonLayoutPreviewProps';
import scalePreview from './scale-preview';

interface ProfilePreviewComponentProps extends CommonLayoutPreviewProps {
  config: ProfileTemplateConfig;

  profile?: ProfileModelType;
  questions?: ProfileTemplateQuestion[];
  comments?: ProfileTemplateComment[];
  text?: string;
  picture?: PrintPicture | null;
}

export class ProfilePreviewComponent extends React.Component<
  ProfilePreviewComponentProps
> {
  shouldComponentUpdate() {
    // preview will be forced to redraw on config update only
    return false;
  }

  generateSampleQuestions() {
    const elems: ProfileTemplateQuestion[] = [];

    elems.push({
      question: 'Geburtstag',
      answer: '18.03.' + (new Date().getFullYear() - 17)
    });

    elems.push({
      question: 'Persönliches Zitat',
      answer:
        'Wenn sich eine Tür schließt, öffnet sich auch immer eine neue. Du musst nur den Mut haben hindurch zu gehen!\nSchlenther, Debby'
    });

    elems.push({
      question: 'Spitzname',
      answer: 'Liebl, Anni, Göttin'
    });

    elems.push({
      question: 'Zukunftspläne',
      answer:
        'Freiwilliges ökologisches Jahr in Brasilien machen,\ndanach studieren.'
    });

    elems.push({
      question: 'Letzte Worte',
      answer: 'Don’t lose a diamond chasing after glitter.'
    });

    return elems;
  }

  generateSampleComments() {
    const elems: ProfileTemplateComment[] = [];

    elems.push({
      author: 'Daniel',
      comment: 'Du geile Sau'
    });

    elems.push({
      author: 'Marie',
      comment: 'Bist die Beste 🥰'
    });

    elems.push({
      author: 'Anne',
      comment:
        'Ich werd immer daran denken wie wir in Wien ins Hotel eingebrochen sind 😇'
    });

    elems.push({
      author: 'Tobias',
      comment: 'Du hast noch meinen Kugelschreiber! 😋'
    });

    elems.push({
      author: 'Linettte',
      comment: 'Die beste Projektpartnerin! Wir haben den Scheiß gerockt!'
    });

    return elems;
  }

  generateSampleText() {
    return 'Es hatte ein Mann einen Esel, der schon lange Jahre die Säcke unverdrossen zur Mühle getragen hatte, dessen Kräfte aber nun zu Ende giengen, so daß er zur Arbeit immer untauglicher ward. Da dachte der Herr daran, ihn aus dem Futter zu schaffen, aber der Esel merkte daß kein guter Wind wehte, lief fort und machte sich auf den Weg nach Bremen: dort, meinte er, könnte er ja Stadtmusikant werden. Als er ein Weilchen fortgegangen war, fand er einen Jagdhund auf dem Wege liegen, der jappte wie einer, der sich müde gelaufen hat.„Nun, was jappst du so, Packan?“ fragte der Esel. „Ach,“ sagte der Hund, „weil ich alt bin und jeden Tag schwächer werde, auch auf der Jagd nicht mehr fort kann, hat mich mein Herr wollen todt schlagen, da hab ich Reißaus genommen; aber womit soll ich nun mein Brot verdienen?“ „Weißt du was,“ sprach der Esel, „ich gehe nach Bremen und werde dort Stadtmusikant, geh mit und laß dich auch bei der Musik annehmen. Ich spiele die Laute, und du schlägst die Pauken.“ Der Hund wars zufrieden, und sie giengen weiter. Es dauerte nicht lange, so saß da eine Katze an dem Weg und machte ein Gesicht wie drei Tage Regenwetter. „Nun, was ist dir in die Quere gekommen, alter Bartputzer?“ sprach der Esel.';
  }

  generateProfile(isTeacher?: boolean) {
    return createProfileModel({
      id: 1,
      first_name: isTeacher ? 'Frau' : 'Maxi',
      last_name: 'Mustermann',
      slot1_photo: {
        id: 2,
        photo_type: 'slot1',
        photo: {
          preview: '/images/content-preview/profile_1.jpg',
          cropped: '/images/content-preview/profile_1.jpg',
        }
      },
      slot2_photo: {
        id: 3,
        photo_type: 'slot2',
        photo: {
          preview: '/images/content-preview/profile_2.jpg',
          cropped: '/images/content-preview/profile_2.jpg',
        }
      },
      slot3_photo: {
        id: 4,
        photo_type: 'slot3',
        photo: {
          preview: '/images/content-preview/profile_1.jpg',
          cropped: '/images/content-preview/profile_1.jpg',
        }
      },
      creative_page_photo: {
        id: 5,
        photo_type: 'creative_page',
        photo: {
          preview: '/images/content-preview/profile_creative_page.jpg',
          url: '/images/content-preview/profile_creative_page.jpg',
          fitToFrame: true
        }
      }
    })!;
  }

  render() {
    const {
      print,
      chapter,
      config,
      colors,
      pageHeaderStyle,
      sectionHeaderStyle,
      background,
      start,
      scaled,
      profile,
      text,
      picture,
      questions,
      comments,
      firstPageOnly
    } = this.props;

    const settings = chapter?.settings as
      | FactsheetChapterSettingsModelType
      | undefined;

    let previewPicture;
    if (picture) {
      previewPicture = picture;
    } else if (picture !== null) {
      // if picture is null do not display default
      previewPicture = {
        url: '/images/content-preview/profile_creative_page.jpg',
        fit: true
      };
    }

    const isTeacher = chapter?.chapter_type === 'teacher_factsheet';

    return (
      <>
        <ProfileTemplateComponent
          print={print}
          firstPageOnly={firstPageOnly}
          config={config}
          colors={colors || chapter?.color_scheme?.templateColors || []}
          pageHeaderStyle={pageHeaderStyle || chapter?.font?.pageHeaderStyle}
          sectionHeaderStyle={
            sectionHeaderStyle || chapter?.font?.sectionHeaderStyle
          }
          background={
            background ||
            (chapter?.background?.id || '') +
              (chapter?.background?.extension || '') ||
            undefined
          }
          startPosition={start || 'left'}
          startPage={this.props.startPage || 1}
          profile={profile || this.generateProfile(isTeacher)}
          render={
            !scaled ? undefined : (content, key) => scalePreview(content, key)
          }
          picturePageEnabled={!settings || settings.creative_page}
          picture={previewPicture}
          questions={
            !settings || settings.questions_on
              ? questions || this.generateSampleQuestions()
              : undefined
          }
          questionsHeader={settings?.questions_header}
          comments={
            !settings || settings.comments_on
              ? comments || this.generateSampleComments()
              : undefined
          }
          anonymousComments={settings?.anonymous_comments}
          commentsHeader={settings?.comments_header}
          text={
            !settings || settings.text_on
              ? typeof text === 'string'
                ? text
                : this.generateSampleText()
              : undefined
          }
          textHeader={settings?.text_header || 'Über mich'}
          title={chapter?.title}
        />
      </>
    );
  }
}

export default ProfilePreviewComponent;
