import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

export const SplitScreenSliderOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
`;

export const SplitScreenSlider = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40em;
  margin: 0 auto;
  background: #fff;
  position: relative;

  @media screen and (min-height: 30em) {
    height: 100%;
    height: -webkit-fill-available;
  }
`;

export const SplitScreenSliderBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-height: 30em) {
    height: 100%;
    height: -webkit-fill-available;
    padding-bottom: 4.5rem;
  }
`;

export const SplitScreenSliderContent = styled.div`
  padding: 0 1.25em 1.25em 1.25em;
  flex-shrink: 0;
`;

export const SplitScreenSliderImage = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  min-height: 25rem;

  > img {
    display: block;
    width: calc(100% + 2.5rem);
    height: auto;
    object-fit: fill;
    object-position: center bottom;
    position: absolute;
    left: -1.25rem;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

export const SplitScreenSliderBar = styled.div`
  background: ${COLORS.WHITE};
  position: absolute;
  height: 4.5rem;
  left: 0;
  bottom: 0;
  width: 100%;
  display: grid;
  place-items: center;
`;
