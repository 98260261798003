import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import InputBlock from 'blocks/InputBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import Input from 'elements/Input';
import InputMessage from 'elements/InputMessage';
import {
  useOptimisticUpdateTextileOrderQuery,
  useTextileOrderForPreorderQuery
} from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const PreorderDeadlineScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const [noDeadline, setNoDeadline] = useState<boolean>(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const updateTextileOrder = useOptimisticUpdateTextileOrderQuery(
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    if (textileAppState?.textile_order.order_state === 'preparing') {
      props.history.replace(textileOrderRoute(props.match, '/preorder'));
    }
    accessContext.checkSectionAccess('preorder');

    const data = textileOrder.data;

    if (data) {
      const date = data.preorder_deadline
        ? new Date(data.preorder_deadline).toISOString().substring(0, 10)
        : undefined;
      props.form.setField('preorder_deadline', date || undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessContext,
    textileAppState?.textile_order.order_state,
    textileOrder.data
  ]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink
            to={textileOrderRoute(props.match, '/preorder/preorder_state')}
          />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="deadline en" />}
            text={<FormattedMessage id="deadline info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (props.form.loading || textileOrder.isLoading || !textileOrder.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const save = () => {
    const { match, form, history } = props;

    if (form.values.preorder_deadline === undefined) {
      setNoDeadline(true);
      return;
    } else {
      setNoDeadline(false);
    }

    if (!textileOrderId) {
      return;
    }

    form.resetErrors();
    form.setLoading(true);

    const patch = { ...textileOrder.data, ...form.values };
    updateTextileOrder.mutate(
      { data: patch, textileOrderId },
      {
        onSuccess: () => {
          history.push(textileOrderRoute(match, '/preorder/preorder_state'));
        },
        onError: (error: any) => {
          form.setLoading(false);

          handleFormError(form, error);
          return;
        }
      }
    );
  };

  const deleteDeadline = () => {
    const { match, history, form } = props;

    if (!textileOrderId) {
      return;
    }

    form.setLoading(true);

    const patch = { ...textileOrder.data, preorder_deadline: null };
    updateTextileOrder.mutate(
      { data: patch, textileOrderId },
      {
        onSuccess: () => {
          history.push(textileOrderRoute(match, '/preorder/preorder_state'));
        },
        onSettled: () => {
          form.setLoading(false);
        }
      }
    );
  };

  const renderForm = () => {
    const { form } = props;

    return renderPage(
      <>
        <InputBlock>
          <Input
            type="date"
            name="preorder_deadline"
            label={<FormattedMessage id="Deadline" />}
            {...form.bindInput('preorder_deadline')}
            min={new Date().toISOString().substring(0, 10)}
          ></Input>
        </InputBlock>

        {noDeadline && (
          <InputMessage error={true}>
            <Paragraph>
              <FormattedMessage id="no deadline set" />
            </Paragraph>
          </InputMessage>
        )}

        <ButtonBlock background="PRIMARY" onClick={() => save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>

        {textileOrder.data.preorder_deadline && (
          <>
            <Divider />

            <DeleteButton onClick={() => deleteDeadline()}>
              <FormattedMessage id="delete deadline" />
            </DeleteButton>
          </>
        )}
      </>
    );
  };

  return renderForm();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <PreorderDeadlineScreen {...props} form={form} />;
};
