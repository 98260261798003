import { SponsorPageType } from 'models/SponsorModel';

import { GridTemplate } from './grid-template';
import {
  ImagePageSlot,
  ImagePageTemplateConfig
} from './ImagePageTemplateComponent';

export interface SponsorPageSlot extends ImagePageSlot {
  pageType: SponsorPageType;
}

export interface SponsorGridTemplate extends GridTemplate {
  slots: SponsorPageSlot[];
}

export const SPONSOR_PAGE_GRID_LIST: SponsorGridTemplate[] = [
  {
    key: 'sponsor-grid-1',
    preview: 'sponsor-grid-1.jpg',
    slots: [
      {
        id: 1,
        size: [771, 1091],
        position: [46, 61],
        pageType: 'full'
      }
    ]
  },
  {
    key: 'sponsor-grid-2',
    preview: 'sponsor-grid-2.jpg',
    slots: [
      {
        id: 1,
        size: [771, 542],
        position: [46, 54],
        pageType: 'half'
      },
      {
        id: 2,
        size: [771, 542],
        position: [46, 612],
        pageType: 'half'
      }
    ]
  },
  {
    key: 'sponsor-grid-3',
    preview: 'sponsor-grid-3.jpg',
    slots: [
      {
        id: 1,
        size: [771, 542],
        position: [46, 59],
        pageType: 'half'
      },
      {
        id: 2,
        size: [380, 542],
        position: [46, 612],
        pageType: 'quarter'
      },
      {
        id: 3,
        size: [380, 542],
        position: [438, 612],
        pageType: 'quarter'
      }
    ]
  },
  {
    key: 'sponsor-grid-4',
    preview: 'sponsor-grid-4.jpg',
    slots: [
      {
        id: 1,
        size: [380, 542],
        position: [46, 64],
        pageType: 'quarter'
      },
      {
        id: 2,
        size: [380, 542],
        position: [438, 64],
        pageType: 'quarter'
      },
      {
        id: 3,
        size: [380, 542],
        position: [46, 612],
        pageType: 'quarter'
      },
      {
        id: 4,
        size: [380, 542],
        position: [438, 612],
        pageType: 'quarter'
      }
    ]
  }
];

export const SPONSOR_PAGE_GRIDS = SPONSOR_PAGE_GRID_LIST.reduce<{
  [key: string]: SponsorGridTemplate;
}>((result, gridTemplate) => {
  result[gridTemplate.key] = gridTemplate;
  return result;
}, {});

export const SPONSOR_PAGE_GRID_KEYS = SPONSOR_PAGE_GRID_LIST.map(
  (gridTemplate) => gridTemplate.key
);

const SPONSOR_IMAGE_BLOCK =
  '<img src="{url}" style="position: absolute; top: {top}; left: {left}; width: {width}; height: {height}; object-fit: contain;" />';

export const generateSponsorPageTemplateConfig = (
  grid: GridTemplate
): ImagePageTemplateConfig => {
  return {
    slots: grid.slots,

    imageBlock: '',
    fittedImageBlock: SPONSOR_IMAGE_BLOCK
  };
};
