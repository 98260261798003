import { Instance, types } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

const PlaceholderSponsorPageModel = types.model('PlaceholderSponsorPageModel', {
  id: types.identifierNumber,
  chapter_id: types.number,
  sponsor_layout_key: types.string,
  sponsor_id: types.maybe(types.number),
  page_sorting: types.maybe(types.number)
});

export const createPlaceholderSponsorPageModel = (data: {
  id?: number;
  chapter_id: number;
  sponsor_layout_key?: string;
  sponsor_id?: number;
}) => {
  return PlaceholderSponsorPageModel.create({
    id: mandatoryId(data.id),
    chapter_id: data.chapter_id,
    sponsor_layout_key: data.sponsor_layout_key || 'sponsor-grid-1',
    sponsor_id: data.sponsor_id || undefined
  });
}

export type PlaceholderSponsorPageModelType = Instance<typeof PlaceholderSponsorPageModel>;
export default PlaceholderSponsorPageModel;
