import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { HasAccessContext } from 'contexts/HasAccessContext';
import { intl } from 'i18n';
import { useTextileCheckoutQuery } from 'queries/textile_deals/useTextileCheckoutQueries';
import React, { useContext, useEffect } from 'react';

import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const ChangeAuthorTextileCheckout: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const textileCheckout = useTextileCheckoutQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('calculator');
  }, [accessContext]);

  if (textileCheckout.isError) {
    return (
      <GenericErrorComponent onRetryClick={() => textileCheckout.refetch()} />
    );
  }

  if (textileCheckout.isLoading || !textileCheckout.data) {
    return <LoadingOverlayComponent />;
  }

  return (
    <SupportTicketScreen
      subject="textile_checkout_change_person"
      backTo={textileOrderRoute(props.match, '/calculator/order')}
      organizerOnly={true}
      textileOrderId={textileOrderIdFromMatch(props.match)}
      message={intl.formatMessage(
        { id: 'zap_change_person_checkout' },
        { author: textileCheckout.data.author?.id }
      )}
    />
  );
};

export default ChangeAuthorTextileCheckout;
