import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { useTextileGroupsQuery } from 'queries/textile_deals/useTextileGroupsQueries';
import {
  useDeleteTexitleNameQuery,
  useTextileNameQuery,
  useUpdateTextileNameQuery
} from 'queries/textile_deals/useTextileNamesQueries';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import TextileNameForm from './TextileNameForm';

interface FormProps {
  form: FormType;
}

const EditTeacherTextileName: React.FC<HistoryProps & FormProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const teacher = useTextileNameQuery(textileOrderId, id);
  const textileGroups = useTextileGroupsQuery(textileOrderId);
  const updateTeacher = useUpdateTextileNameQuery(textileOrderId);
  const removeTeacher = useDeleteTexitleNameQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    const data = teacher.data;
    const textileOrder = textileAppState?.textile_order;
    const groups = textileGroups.data;

    if (data && groups && textileOrder) {
      props.form.setField('first_name', data.first_name || '');
      props.form.setField('last_name', data.last_name || '');
      if (textileOrder?.show_groups) {
        props.form.setField(
          'textile_group_id',
          data.textile_group?.id.toString() || groups[0]?.id.toString() || ''
        );
      }
    }

    accessContext.checkSectionAccess('design');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, teacher.data, textileAppState?.textile_order, textileGroups.data]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink
            to={textileOrderRoute(props.match, '/management/teachers')}
          />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (teacher.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => teacher.refetch()} />
    );
  }

  if (textileGroups.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileGroups.refetch()} />
    );
  }

  if (
    !textileAppState ||
    props.form.loading ||
    teacher.isLoading ||
    !teacher.data ||
    textileGroups.isLoading ||
    !textileGroups.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const save = () => {
    const { form } = props;

    if (!id || !textileOrderId) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    updateTeacher.mutate(
      { data: { ...form.values }, id, textileOrderId },
      {
        onSuccess: () => {
          props.history.push(
            textileOrderRoute(props.match, '/management/teachers')
          );
        },
        onError: (error) => {
          form.setLoading(false);
          if (!handleFormError(form, error)) {
            form.setError('base', true);
          }
        }
      }
    );
  };

  const deleteTeacher = () => {
    const { form } = props;

    if (!id || !textileOrderId) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    removeTeacher.mutate(
      { id, textileOrderId },
      {
        onSuccess: () => {
          props.history.push(
            textileOrderRoute(props.match, '/management/teachers')
          );
        },
        onError: (error) => {
          form.setLoading(false);
          handleFormError(form, error);

          return;
        }
      }
    );
  };

  const renderForm = () => {
    const { form } = props;

    const groups = textileGroups.data;
    const showGroups = textileAppState.textile_order.show_groups;
    return renderPage(
      <>
        <Headline.Large>{teacher.data.name}</Headline.Large>

        <TextileNameForm
          form={form}
          onSave={() => save()}
          onDelete={() => deleteTeacher()}
          textileGroups={groups}
          showGroups={showGroups}
          formOfAddress={true}
        />
      </>
    );
  };

  return renderForm();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <EditTeacherTextileName {...props} form={form} />;
};
