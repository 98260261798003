import styled from 'styled-components';

import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

const PlainHtmlBlock = styled.div`
  display: block;
  hyphens: auto;

  h1 {
    margin: 0 0 0.625em;
    font-size: 2rem;
    line-height: 1.1875;
    font-family: ${FONTS.DEFAULT};
    font-weight: 700;
    letter-spacing: 0;
  }

  h2 {
    margin: 0 0 0.778em;
    font-size: 1.125rem;
    line-height: 1.166;
    font-family: ${FONTS.DEFAULT};
    font-weight: 700;
    letter-spacing: 0;
  }

  h3 {
    margin: 0 0 1em;
    font-size: 0.875rem;
    line-height: 1.35714;
    font-family: ${FONTS.DEFAULT};
    font-weight: 700;
    letter-spacing: 0;
  }

  p {
    margin: 0 0 1em;
    font-size: 0.875rem;
    line-height: 1.35714;
    font-family: ${FONTS.DEFAULT};
    font-weight: 400;
    letter-spacing: 0;
    color: ${COLORS.GRAY10};
  }

  /* stylelint-disable selector-list-comma-newline-after */
  ul, ol {
    margin: 0 0 1em 1.5em;
    list-style-type: disc;
    font-size: 0.875rem;
    font-weight: 400;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-bottom: 0.5em;
  }
`;

export default PlainHtmlBlock;
