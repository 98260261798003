import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextileVariant } from 'api/textile_deals/fetchTextileVariants';
import { Textile } from 'api/textile_deals/fetchTextiles';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { useTextileQuery } from 'queries/textile_deals/useTextileQueries';
import { FormattedMessage } from 'react-intl';
import PreOrderInfoBox from 'screens/preorders/components/PreOrderInfoBox';
import TextileInfo from 'screens/preorders/components/TextileInfo';
import TextilePriceComponent from 'screens/preorders/components/TextilePriceComponent';
import TextileProductImageComponent from 'screens/preorders/components/TextileProductImageComponent';
import { MiniPreview } from 'screens/textile_design_settings/screens/colors/MiniPreview';
import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import isTextileLocked from 'utils/isTextileLocked';
import { ProductImage, productImage } from 'utils/product-image';

const CalculatorTextileView: React.FC<HistoryProps> = (props) => {
  const [currentColor, setCurrentColor] = useState<string | undefined>(
    undefined
  );
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const textile = useTextileQuery(id, textileOrderId);
  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('calculator');
  }, [accessContext]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textile.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textile.refetch} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch} />
    );
  }

  if (
    !textileAppState ||
    textile.isLoading ||
    !textile.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderTextileVariant = (textileVariant?: TextileVariant) => {
    if (!textileVariant) {
      return null;
    }

    const detailImageOne: ProductImage = productImage(
      textileVariant,
      'detail_1'
    );
    const detailImageTwo: ProductImage = productImage(
      textileVariant,
      'detail_2'
    );
    const detailImageThree: ProductImage = productImage(
      textileVariant,
      'detail_3'
    );
    return (
      <>
        <SliderBlock scrollBarColor="GRAY560" noScrollBarBackground={true}>
          <TextileProductImageComponent
            productImage={detailImageOne}
            height="360px"
            width="283px"
          />
          <TextileProductImageComponent
            productImage={detailImageTwo}
            height="360px"
            width="283px"
          />
          <TextileProductImageComponent
            productImage={detailImageThree}
            height="360px"
            width="283px"
          />
        </SliderBlock>
      </>
    );
  }

  const renderSizesByTextileVariant = (textileVariant?: TextileVariant) => {
    if (!textileVariant) {
      return null;
    }

    return (
      <>
        <Paragraph size="L" weight="BOLD">
          <FormattedMessage id="available sizes" />
        </Paragraph>

        <Divider useStackBlockGap={true} />

        <div>
          {textileVariant.textile_sizes?.map((size, index) => {
            return (
              <TextElement size="M" weight="BOLD" color="BLACK" key={size.id}>
                {index !== 0 && <> /</>} {size.title}
              </TextElement>
            );
          })}
        </div>
      </>
    );
  }

  const renderPriceTextileVariant = (textile: Textile) => {
    return (
      <TextilePriceComponent
        endPrice={textile.end_price}
        possiblePrice={textile.possible_price}
        range={isTextileLocked(textileAppState.textile_order.order_state, 'atLeastOrderStarted') ? false : true}
        link={textileOrderRoute(props.match, '/discounts')}
        piece={true}
        allDiscounts={true}
      />
    );
  }

  const textileView = () => {
    const layout = textileAppState.textile_order.theme;
    const textileVariants = textile.data.textile_variants || [];

    if (textileVariants.length === 0 || !layout) {
      return null;
    }

    const color =
      currentColor || textileVariants[0].textile_color?.display_name;

    const textileVariantToColor:
      | TextileVariant
      | undefined = textileVariants.find(
      (variant) => variant.textile_color?.display_name === color
    );

    if (!textileVariantToColor) {
      return null;
    }

    return renderPage(
      <>
        {renderTextileVariant(textileVariantToColor)}
        <PageHeader headline={textile.data.name} text={''} />

        <ItemStackBlock gap="XS">
          <Paragraph size="L" weight="BOLD">
            <FormattedMessage id="Color" />:
            <TextElement size="M"> {color}</TextElement>
          </Paragraph>

          <HorizontalStackBlock gap="XXS">
            {textileVariants.map((variant) => {
              if (variant.textile_color && variant.textile_color.color_hex) {
                return (
                  <MiniPreview
                    colorCode={variant.textile_color.color_hex}
                    key={variant.textile_color.id}
                    selected={variant.textile_color.display_name === color}
                    onClick={() =>
                      setCurrentColor(variant.textile_color?.display_name)
                    }
                  ></MiniPreview>
                );
              }
              return null;
            })}
          </HorizontalStackBlock>
        </ItemStackBlock>

        <ItemStackBlock gap="XS">
          {renderSizesByTextileVariant(textileVariantToColor)}
        </ItemStackBlock>
        {renderPriceTextileVariant(textile.data)}

        <Divider />

        <PreOrderInfoBox
          link={textileOrderRoute(props.match, '/preorder/how-it-works')}
        />

        <TextileInfo textile={textile.data} />
      </>
    );
  };

  return textileView();
};

export default CalculatorTextileView;
