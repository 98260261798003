import React from 'react';
import { FormattedMessage } from 'react-intl';

import Headline from 'elements/Headline';
import { FormType } from 'utils/hooks/useForm';

import Checkboxes from '../Checkboxes';
import NextButton from '../NextButton';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

const CONTENTS = [
  'Steckbriefe',
  'Ranking / Awards',
  'Zitatsammlung',
  'Fotoalben',
  'Kursberichte',
  'Andere'
];

const BookContents = ({ form }: { form: FormType }) => (
  <PageStackBlock>
    <Paragraph  color="TEXT_LIGHT">
      <FormattedMessage id="funnel book contents info" />
    </Paragraph>

    <Headline.Large>
      <FormattedMessage id="funnel book contents" />
    </Headline.Large>

    <Checkboxes
      form={form}
      options={CONTENTS}
      name="contents"
      translate={false}
    />

    <NextButton
      to="?screen=design"
      condition={form.values.contents.length > 0}
    >
      <FormattedMessage id="Next" />
    </NextButton>
  </PageStackBlock>
);

export default BookContents;
