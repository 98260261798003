import {
  TextileTextBox
} from 'components/print/templates/TextileBackTemplateComponent';
import shrinkText from 'utils/chunker/TextShrinker';

export interface ContentSettings {
  contentWidth: number;
  contentHeight: number;
}

export const calculateFontSize = (fontFamily: string, sizeFactor: number,styles: React.CSSProperties, contentSettings: ContentSettings, content: string | undefined
): number | undefined => {

  if (!contentSettings || !content) {
    return;
  }

  const maxFontSize = calculateMaxFontSize(sizeFactor, contentSettings.contentHeight)
  const softCapFontSize = calculateMinFontSize(maxFontSize);

  const style = Object.entries(styles)
    .map(([k, v]) => `${k}:${v}`)
    .join(';');

  const textBox: TextileTextBox = {
        boundary: [contentSettings.contentWidth, contentSettings.contentHeight],
        minFontSize: 2,
        maxFontSize,
        step: 1
      };

    const [columnFontSize, columnFailed] = fitFontSize(
      content,
      textBox,
      style,
      fontFamily,
      styles.letterSpacing?.toString()
    );

    if (columnFontSize < softCapFontSize) {
      // DO something
    }

    if (columnFailed) {
      // DO things
    }

    return columnFontSize;
}

const fitFontSize = (
  text: string | undefined,
  TextileTextBox: TextileTextBox,
  style: string,
  fontFamily: string,
  letterSpacing: string | undefined
): [number, boolean] => {
  if (!text) {
    return [TextileTextBox.maxFontSize, true];
  }

  return shrinkText(
    text,
    TextileTextBox.maxFontSize,
    TextileTextBox.minFontSize,
    style,
    TextileTextBox.boundary,
    1,
    fontFamily,
    letterSpacing
  );
}

const calculateMaxFontSize = (sizeFactor: number, height: number ):number => {
  return (height / 1.2) * sizeFactor;
}

const calculateMinFontSize = (maxFontSize:number ):number => {
  return maxFontSize * 0.45;
}