import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

export default styled.span`
  position: relative;
  display: block;
  padding: 1rem 1rem 1rem 2.8125rem;
  cursor: pointer;
  font-size: 1.125rem;
  line-height: 1.3125;
  font-weight: 500;
  letter-spacing: 0.03125em;
  color: inherit;

  &::before {
    content: '';
    position: absolute;
    top: 1rem;
    left: 1rem;
    transition: background 0.4s ease-out;
    border: 2px solid ${COLORS.GRAY800};
    border-radius: 50%;
    width: 1.3125em;
    height: 1.3125em;
    background: ${COLORS.WHITE};
    font-size: 1rem;
    font-weight: 400;
    box-shadow: inset 0 0 0 3px #fff;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${COLORS.GRAY800};
    border-radius: 0.625em;
  }

  > span {
    display: block;
  }
`;
