import { Instance, types } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import createRemoteFileModel from 'utils/store/createRemoteFileModel';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';
import RemoteFileModel from './RemoteFileModel';

const SponsorPageTypeEnum = types.enumeration(['full', 'half', 'quarter']);
export type SponsorPageType = Instance<typeof SponsorPageTypeEnum>;

const PaymentStateEnum = types.enumeration(['no_state', 'paid']);
export type PaymentState = Instance<typeof PaymentStateEnum>;

const SponsorModel = types.model('SponsorModel', {
  id: types.identifierNumber,
  author: types.maybe(ProfileAsAuthorModel),
  name: types.maybe(types.string),
  description: types.maybe(types.string),
  donation: types.maybe(types.number),
  page_type: types.maybe(SponsorPageTypeEnum),
  payment_state: types.maybe(PaymentStateEnum),
  global: types.maybe(types.boolean),
  placed: types.maybe(types.boolean),
  advertisement: types.maybe(RemoteFileModel),
  chapter_id: types.maybe(types.number)
});

export const createSponsorModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return SponsorModel.create({
    id: mandatoryId(data.id),
    author: createProfileAsAuthorModel(data.author),
    name: data.name || undefined,
    description: data.description || undefined,
    donation: numberOrUndefined(data.donation),
    page_type: SponsorPageTypeEnum.is(data.page_type)
      ? data.page_type
      : undefined,
    payment_state: PaymentStateEnum.is(data.payment_state)
      ? data.payment_state
      : undefined,
    global: booleanOrUndefined(data.global),
    placed: data.chapter_id ? true : false,
    advertisement: createRemoteFileModel(data.advertisement),
    chapter_id: data.chapter_id || undefined
  });
};

export type SponsorModelType = Instance<typeof SponsorModel>;
export default SponsorModel;
