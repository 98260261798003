import React from 'react';

import { ChapterModelType } from 'models/ChapterModel';
import { PagePosition } from 'utils/chunker/replace-variables';
import ImagePageTemplateComponent, {
  ImagePageImageMap
} from '../templates/ImagePageTemplateComponent';
import NoLayoutComponent from './NoLayoutComponent';
import { scaled, scaledStack } from './scaling';
import { generateAlbumPageTemplateConfig } from '../templates/generate-album-page-template-config';
import { ALBUM_PAGE_GRIDS } from '../templates/album-page-grids';

interface Props {
  chapter?: ChapterModelType;
  layoutKey: string;

  position?: PagePosition;
  page?: number;

  images?: ImagePageImageMap;

  positionMode?: boolean;
  chapterPreview?: boolean;
}

const AlbumPagePreviewComponent = ({
  chapter,
  layoutKey,
  images,
  positionMode,
  chapterPreview,
  position,
  page
}: Props) => {
  const layout = ALBUM_PAGE_GRIDS[layoutKey];
  if (!layout) {
    return <NoLayoutComponent />;
  }

  return (
    <ImagePageTemplateComponent
      config={generateAlbumPageTemplateConfig(layout)}
      colors={chapter?.color_scheme?.colors || []}
      background={
        !chapter?.background
          ? undefined
          : chapter.background.id + chapter.background.extension
      }
      pageHeaderStyle={
        !chapter?.font ? undefined : chapter.font.pageHeaderStyle
      }
      render={!chapterPreview ? scaled : scaledStack}
      startPage={page || 1}
      startPosition={position || 'left'}
      images={images}
      title={chapter?.title}
      positionMode={positionMode}
    />
  );
};

export default AlbumPagePreviewComponent;
