import ButtonBlock from 'blocks/ButtonBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { HasAccessContext } from 'contexts/HasAccessContext';
import { intl } from 'i18n';
import { useTextileOnboardingQuery } from 'queries/textile_deals/useTextileAppStateQueries';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { ROUTE_ACCOUNT_DASHBOARD } from 'utils/constants/routes';

import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const TextileOnboardingCommitteeScreen: React.FC<HistoryProps> = (props) => {
  const [chooseToBeOrganizer, setChooseToBeOrganizer] = useState<boolean>(
    false
  );
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const completeOnboardingTextile =useTextileOnboardingQuery(
    textileOrderId
  );

  const accessContext = useContext(HasAccessContext);
  const flashMessageContext = useContext(FlashMessageContext);
  if (!accessContext || !flashMessageContext) {
    throw Error('Component must be used witin context');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('onboarding');
  }, [accessContext])

  const finish = () => {
    const textileOrderId = textileOrderIdFromMatch(props.match);

    if (!textileOrderId) {
      return;
    }

    completeOnboardingTextile.mutate(textileOrderId, {
      onSuccess: () => {
        props.history.push(
          textileOrderRoute(props.match, '/onboarding/invite')
        );
      },
      onError: () => {
        flashMessageContext.triggerFlashMessage(
          intl.formatMessage({ id: 'error generic' }),
          'error'
        );
      }
    });
  };

  const becomeOrganizer = () => {
    finish();
  };

  const notBecomeOrganizer = () => {
    setChooseToBeOrganizer(true);
  };

  const renderPage = (content: JSX.Element) => {
    return (
      <>
        <SignupHeaderComponent
          progressStatus={50}
          backRoute={ROUTE_ACCOUNT_DASHBOARD}
        />

        {content}
      </>
    );
  };

  const renderNoOrganizer = () => {
    return renderPage(
      <SupportTicketScreen
        subject="textile_signup_no_organizer"
        onOkClick={() => finish()}
        textileOrderId={textileOrderIdFromMatch(props.match)}
        message={intl.formatMessage(
          { id: 'zap_sign_up_no_organizer' },
          { type: 'Textile' }
        )}
      />
    );
  };

  if (chooseToBeOrganizer) {
    return renderNoOrganizer();
  }

  return renderPage(
    <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="onboarding textile organizer header" />}
          text={<FormattedMessage id="onboarding textile organizer text" />}
        />

        <IconListBlock>
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding textile organizer text 1" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding textile organizer text 2" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding textile organizer text 3" />
          </IconListElement>
        </IconListBlock>

      <ListStackBlock>
        <ButtonBlock background="PRIMARY" onClick={() => becomeOrganizer()}>
          <FormattedMessage id="Become organizer" />
        </ButtonBlock>
        <ButtonBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          onClick={() => notBecomeOrganizer()}
        >
          <FormattedMessage id="Not become organizer" />
        </ButtonBlock>
      </ListStackBlock>
    </PageStackBlock>
  );
};

export default TextileOnboardingCommitteeScreen;
