import {
  Instance,
  types
} from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

const BannerModel = types.model('BannerModel', {
  id: types.identifierNumber,
  title: types.maybe(types.string),
  link: types.maybe(types.string),
  image: types.maybeNull(types.string)
});

export const createBannerModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return BannerModel.create({
    id: mandatoryId(data.id),
    title: data.title || undefined,
    link: data.link || undefined,
    image: data.image || null
  });
};

export type BannerModelType = Instance<typeof BannerModel>;
export default BannerModel;
