import React from 'react';

import { TextileThemeLayout } from 'api/textile_deals/fetchTextileThemes';
import { TextileVariant } from 'api/textile_deals/fetchTextileVariants';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import NakedButton from 'elements/NakedButton';
import { intl } from 'i18n';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import { ProductImage, productImage } from 'utils/product-image';
import TextileProductImageComponent from './TextileProductImageComponent';

const CartImageContainer = styled.div`
  height: 160px;
  min-width: 160px;

  background-color: ${COLORS.GRAY980};
`;

const BaselineItem = styled.div`
  justify-self: flex-end;
`;

const CartInfoWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-start;
`;

const TextileVariantCartComponent = ({
  preorderId,
  layout,
  possiblePrice,
  endPrice,
  textileVariant,
  removePreorder,
  size,
  name,
  textileName
}: {
  preorderId: number;
  layout: TextileThemeLayout;
  possiblePrice: number;
  endPrice: number;
  textileVariant?: TextileVariant;
  removePreorder?: (id: number) => void;
  size?: string;
  name?: string;
  textileName?: string;
}) => {
  if (!textileVariant || !size) {
    return null;
  }

  const image: ProductImage = productImage(textileVariant, 'thumbnail', layout);
  let formattedPossiblePrice = intl.formatNumber(possiblePrice, {
    style: 'currency',
    currency: 'EUR'
  });
  formattedPossiblePrice = formattedPossiblePrice.substring(
    0,
    formattedPossiblePrice.length - 2
  );

  let formattedEndPrice = intl.formatNumber(endPrice, {
    style: 'currency',
    currency: 'EUR'
  });
  formattedEndPrice = formattedEndPrice.substring(
    0,
    formattedEndPrice.length - 2
  );
  return (
    <div>
      <HorizontalStackBlock gap="S">
        <CartImageContainer>
          <TextileProductImageComponent productImage={image} height="160px" />
        </CartImageContainer>
        <CartInfoWrapper>
          <NonGapStackBlock>
            {name && (
              <Paragraph size="L" weight="BOLD">
                {name}
              </Paragraph>
            )}
            <ItemStackBlock gap="XXS">
              {textileName && <Paragraph size="L">{textileName}</Paragraph>}
              <HorizontalStackBlock gap="XXS">
                <Paragraph>{size}</Paragraph>
                <Paragraph>|</Paragraph>
                <Paragraph>
                  {textileVariant.textile_color?.display_name}
                </Paragraph>
              </HorizontalStackBlock>
            </ItemStackBlock>
          </NonGapStackBlock>

          <BaselineItem>
            {!!removePreorder && (
              <NakedButton onClick={() => removePreorder(preorderId)}>
                <Paragraph color="TEXT_LIGHT" textDecoration="underline">
                  <FormattedMessage id="Remove" />
                </Paragraph>
              </NakedButton>
            )}
            <Paragraph size="L" noBreak={true}>
              {formattedPossiblePrice} - {formattedEndPrice} €
            </Paragraph>
          </BaselineItem>
        </CartInfoWrapper>
      </HorizontalStackBlock>
      <Divider useStackBlockGap={true} />
    </div>
  );
};

export default TextileVariantCartComponent;
