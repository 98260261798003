import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';

import Link from '../SettingsLink';
import { ChapterSettingsListProps } from './ChapterSettingsListProps';
import Divider from 'components/Divider/Divider';

const FactsheetChapterSettingsListComponent = ({
  baseRoute,
  chapter
}: ChapterSettingsListProps) => (
  <>
    <ButtonListBlock>
      <ButtonListBlockItem>
        <RouterLink to={ROUTE_FACTSHEETS + '/' + chapter.id + '/questions'}>
          <ButtonListBlockBody arrow={true}>
            <FormattedMessage id="Questions" />
          </ButtonListBlockBody>
        </RouterLink>
      </ButtonListBlockItem>

      <ButtonListBlockItem>
        <Link basePath={baseRoute} active="photos">
          <ButtonListBlockBody arrow={true}>
            <FormattedMessage id="Photos" />
          </ButtonListBlockBody>
        </Link>
      </ButtonListBlockItem>

      <ButtonListBlockItem>
        <Link basePath={baseRoute} active="text">
          <ButtonListBlockBody arrow={true}>
            <FormattedMessage id="Long text" />
          </ButtonListBlockBody>
        </Link>
      </ButtonListBlockItem>

      <ButtonListBlockItem>
        <Link basePath={baseRoute} active="comments">
          <ButtonListBlockBody arrow={true}>
            <FormattedMessage id="Comments" />
          </ButtonListBlockBody>
        </Link>
      </ButtonListBlockItem>

      <ButtonListBlockItem>
        <Link basePath={baseRoute} active="creative">
          <ButtonListBlockBody arrow={true}>
            <FormattedMessage id="Creative page" />
          </ButtonListBlockBody>
        </Link>
      </ButtonListBlockItem>
    </ButtonListBlock>

    <Divider />
  </>
);

export default FactsheetChapterSettingsListComponent;
