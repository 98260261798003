import React, { ReactNode } from 'react';

import ItemStackBlock from 'blocks/ItemStackBlock';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

import { TimeplanItemState } from './TimeplanItemState';
import Paragraph from 'components/Paragraph/Paragraph';

const Li = styled.li<{
  percentElapsed: number;
  compact: boolean;
  color: string;
}>`
  position: relative;
  margin: 1.375rem 0;
  padding: ${({ compact }) =>
    compact ? '0.75rem 0 0.75rem 4rem' : '1rem 0 1rem 4rem'};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1.9rem;
    width: 0.375rem;
    ${({ percentElapsed, color }) =>
    `background-image: linear-gradient(to bottom, ${color}, ${color} ${percentElapsed * 100
    }%, ${COLORS.GRAY950} ${percentElapsed * 100}%, ${COLORS.GRAY950});`}
  }
`;

const TimeplanPeriodProgress = ({
  percentElapsed,
  title,
  duration,
  description,
  state
}: {
  percentElapsed: number;
  title?: ReactNode;
  duration?: ReactNode;
  description?: ReactNode;
  state?: TimeplanItemState;
}) => {
  const colorKey: keyof typeof COLORS =
    state === 'error' ? 'RED' : state === 'success' ? 'GREEN' : 'PRIMARY';
  const color = COLORS[colorKey];
  return (
    <Li
      percentElapsed={percentElapsed}
      compact={!(title || duration || description)}
      color={color}
    >
      <ItemStackBlock gap="XS">
        {title && <Paragraph weight="BOLD">{title}</Paragraph>}
        {duration && (
          < Paragraph size="S">
            {/* TODO: Use icon from icon library */}
            <svg width="12" height="12" viewBox="0 0 12 12">
              <path
                d="M5.95 0.0499878C2.66904 0.0499878 0 2.71903 0 5.99999C0 9.28095 2.66904 11.95 5.95 11.95C9.23096 11.95 11.9 9.28095 11.9 5.99999C11.9 2.71903 9.23096 0.0499878 5.95 0.0499878ZM8.77974 9.07758C8.68304 9.17427 8.55612 9.22293 8.4292 9.22293C8.30227 9.22293 8.17526 9.17427 8.07866 9.07758L5.59946 6.59847C5.50622 6.50578 5.4542 6.37976 5.4542 6.24793V3.02499C5.4542 2.7508 5.67627 2.52918 5.95 2.52918C6.22373 2.52918 6.4458 2.7508 6.4458 3.02499V6.04266L8.77974 8.3765C8.97357 8.57043 8.97357 8.88374 8.77974 9.07758Z"
                fill={COLORS.GRAY400}
              />
            </svg>
            {duration}
          </Paragraph>
        )}
        {description && (
          <Paragraph size="S" color="TEXT_LIGHT">
            {description}
          </Paragraph>

        )}
      </ItemStackBlock>
    </Li >
  );
};

export default TimeplanPeriodProgress;
