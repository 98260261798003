import styled from 'styled-components';

const NakedButton = styled.button`
  appearance: none;
  border-width: 0;
  padding: 0;
  background-color: transparent;
  font: inherit;
  vertical-align: middle;
  text-align: left;
`;

export default NakedButton;
