import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { intl } from 'i18n';
import { FormType } from 'utils/hooks/useForm';

const SignupSchoolAddScreen = ({
  form,
  submit,
  withCity
}: {
  form: FormType;
  submit: () => void;
  withCity: boolean;
}) => {
  return (
    <>
      {withCity ? (
        <SignupHeaderComponent
          backRoute="?screen=city"
          progressStatus={(100 / 10) * 4}
        />
      ) : (
        <SignupHeaderComponent
          backRoute="?screen=school"
          progressStatus={(100 / 10) * 4}
        />
      )}

      <PageStackBlock>
        <PageHeader headline={<FormattedMessage id="Add your school" />} text={<FormattedMessage id="add your school info" />} />

        <FormStackBlock>
          {withCity && (
            <TextInputComponent
              name={'city_name'}
              label={<FormattedMessage id="signup school city label" />}
              {...form.bindInput('city_name')}
              placeholder={intl.formatMessage({ id: 'Your city' })}
            />
          )}

          <TextInputComponent
            name={'school_name'}
            label={<FormattedMessage id="signup school name label" />}
            {...form.bindInput('school_name')}
            placeholder={intl.formatMessage({ id: 'Your school' })}
          />
        </FormStackBlock>

        <ButtonBlock background="PRIMARY" onClick={submit}>
          <FormattedMessage id="Next" />
        </ButtonBlock>
      </PageStackBlock>
    </>
  );
};

export default SignupSchoolAddScreen;
