import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';
import { useHistory } from 'react-router';

export const CancelLink = ({ to, replace }: { to: string, replace?: boolean }) => {
  const history = useHistory();
  const state = history.location.state as { backRoute: string }

  const checkHistoryState = () => {
    let backLink = to;

    if (state && state.backRoute) {
      return backLink = state.backRoute;
    }

    return backLink;
  }

  return (
    <Link to={checkHistoryState()} replace={replace}>
      <TextElement color="GRAY10">
        <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
        <FormattedMessage id="Cancel" />
      </TextElement>
    </Link>
  )
};

export default CancelLink;
