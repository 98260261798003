import { match as MatchInterface } from 'react-router-dom';

import { createBrowserHistory, History, Location } from 'history';

import { queryStringListener, setRef, transformQueryString } from './query-string';
import scrollToTop from './scroll-to-anchor-on-push';

type MatchParams = MatchInterface<{ [key: string]: string }>;

export const history = createBrowserHistory();

// enable transforming of query strings
queryStringListener(history);
// transform query strin in initial route
transformQueryString(history.location);
// setRef
setRef(history.location);

// make document to scroll to top (or anchor if set) on route push
scrollToTop(history);

export function isPush(historyObject: any): boolean {
  return historyObject && (historyObject.action === 'PUSH' || historyObject.action === 'REPLACE') ? true : false;
}

export function idFromMatch(
  match: MatchParams,
  fieldName: string = 'id'
): number | undefined {
  if (!match) {
    console.error('why does this happen', match, fieldName);
    // eslint-disable-next-line no-console
    console.trace();
  }
  if (!match || !match.params || !match.params[fieldName]) {
    return undefined;
  }
  return parseInt(match.params[fieldName], 10);
}

interface State {
  [key: string]: string;
}

interface QueryParams {
  query?: { [key: string]: string };
}

export interface HistoryWithQueryParams extends History<State> {
  location: Location<State> & QueryParams;
}

export function paramFromMatch(
  match: MatchParams,
  fieldName: string
): string | undefined {
  if (!match) {
    console.error('why does this happen', match, fieldName);
    // eslint-disable-next-line no-console
    console.trace();
  }
  if (!match || !match.params || !match.params[fieldName]) {
    return undefined;
  }
  return match.params[fieldName];
}

export interface HistoryProps {
  history: HistoryWithQueryParams;
  match: MatchInterface<State>;
  location: HistoryWithQueryParams['location'];
}

export default history;
