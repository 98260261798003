import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TeamCallContainer from 'containers/TeamCallContainer/TeamCallContainer';
import BackLink from 'domain/Links/BackLink';
import BackToContentLink from 'domain/Links/BackToContentLink';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';

interface SupportTicketScreenProps {
  organizerOnly?: boolean;
  title?: ReactNode;
  subject: string;
  message?: string;
  backTo?: 'dashboard' | 'content' | string;
  onOkClick?: (error?: boolean) => void;
  children?: ReactNode;
  applicationStore?: ApplicationStoreType;
  textileOrderId?: number;
}

interface SupportTicketScreenState {
  loadingState?: 'loading' | 'error';
}

@inject('applicationStore')
@observer
class SupportTicketScreen extends React.Component<
SupportTicketScreenProps,
SupportTicketScreenState
> {
  state: SupportTicketScreenState = {
    loadingState: 'loading'
  };

  componentDidMount() {
    this.callSupport();
  }

  private async callSupport() {
    const { applicationStore, organizerOnly, subject, message, textileOrderId } = this.props;
    const isOrganizer = applicationStore?.isOrganizer;

    if (organizerOnly && !isOrganizer) {
      // ignore call
      this.setState({
        loadingState: undefined
      });
      return;
    }

    // this.setState({
    //   loadingState: 'loading'
    // });

    try {
      await applicationStore!.createSupportTicket(subject, message, textileOrderId);

      this.setState({
        loadingState: undefined
      });
    } catch (error: any) {
      this.setState({
        loadingState: 'error'
      });
    }
  }

  private renderPage(content?: any) {
    const { backTo } = this.props;

    return (
      <>
        {backTo && (
          <TopActionBlock>
            {backTo === 'dashboard' ? (
              <BackToDashboardLink />
            ) : backTo === 'content' ? (
              <BackToContentLink />
            ) : (
              <BackLink to={backTo} />
            )}
          </TopActionBlock>
        )}

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  private renderDefaultPage() {
    const { title, backTo, onOkClick } = this.props;

    return (
      <TeamCallContainer
        title={title}
        backTo={backTo}
        onOkClick={onOkClick ? () => onOkClick() : undefined}
      />
    );
  }

  private renderError() {
    const { title, onOkClick } = this.props;

    return this.renderPage(
      <>
        {title && <Headline.Large>{title}</Headline.Large>}

        <ItemStackBlock marginTop="S" gap="L">
          <GenericErrorComponent onRetryClick={() => this.callSupport()} />

          {onOkClick && (
            <ButtonBlock
              background="PRIMARY"
              color="WHITE"
              onClick={() => onOkClick(true)}
            >
              <FormattedMessage id="OK" />
            </ButtonBlock>
          )}
        </ItemStackBlock>
      </>
    );
  }

  render() {
    if (this.state.loadingState === 'loading') {
      return this.renderPage(<LoadingOverlayComponent />);
    }

    if (this.state.loadingState === 'error') {
      return this.renderError();
    }

    if (this.props.children) {
      return this.renderPage(this.props.children);
    }

    return this.renderDefaultPage();
  }
}

export default SupportTicketScreen;
