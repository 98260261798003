import { SwapResponse } from 'api/NuggitApi';
import { destroy, flow, getEnv, Instance, types } from 'mobx-state-tree';
import AlbumPageModel, {
  createAlbumPage as createAlbumPageModel
} from 'models/AlbumPageModel';
import { PhotoModelType } from 'models/PhotoModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { assert } from 'utils/assert';
import { createArrayWithTransform } from 'utils/create-map';
import { sortByField } from 'utils/sort-functions';

export enum LoadingState {
  INITIAL = 'initial',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error'
}


const AlbumPagesStore = types
  .model('AlbumPagesStore', {
    // List of album pages
    albumPages: types.maybe(types.array(AlbumPageModel)),
    albumPagesLoadingState: types.optional(
      types.enumeration(Object.values(LoadingState)),
      LoadingState.INITIAL
    ),

    // Single album page
    albumPage: types.maybe(AlbumPageModel),
    albumPageLoadingState: types.optional(
      types.enumeration(Object.values(LoadingState)),
      LoadingState.INITIAL
    )
  })
  .actions((self) => {
    const getAlbumPages = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.albumPagesLoadingState = LoadingState.LOADING;
        self.albumPages = undefined;

        const result = yield client.getAlbumPages(chapterId);

        self.albumPages = createArrayWithTransform(
          result.album_pages,
          createAlbumPageModel
        );

        self.albumPagesLoadingState = LoadingState.LOADED;

        return self.albumPages;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('AlbumPagesStore | getAlbumPages', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.albumPagesLoadingState = LoadingState.INITIAL;
        } else {
          self.albumPagesLoadingState = LoadingState.ERROR;
        }

        throw error;
      }
    });

    const getAlbumPage = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.albumPageLoadingState = LoadingState.LOADING;
        self.albumPage = undefined;

        const result = yield client.getAlbumPage(id);

        self.albumPage = createAlbumPageModel(result);

        self.albumPageLoadingState = LoadingState.LOADED;

        return self.albumPage;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('AlbumPagesStore | getAlbumPage', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.albumPageLoadingState = LoadingState.INITIAL;
        } else {
          self.albumPageLoadingState = LoadingState.ERROR;
        }

        throw error;
      }
    });

    const createAlbumPage = flow(function* (
      chapterId: number,
      layoutKey: string
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.albumPageLoadingState = LoadingState.LOADING;
        self.albumPage = undefined;

        const albumPageForSave = createAlbumPageModel({
          chapter_id: chapterId,
          album_layout_key: layoutKey,
          album_page_photos: []
        });

        const result = yield client.createAlbumPage(albumPageForSave);

        self.albumPage = createAlbumPageModel(result);

        self.albumPageLoadingState = LoadingState.LOADED;

        return self.albumPage;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('AlbumPagesStore | createAlbumPage', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.albumPageLoadingState = LoadingState.INITIAL;
        } else {
          self.albumPageLoadingState = LoadingState.ERROR;
        }

        throw error;
      }
    });

    /**
     * Places the given photo on the current album page at the given position (slot)
     */
    const placePhoto = flow(function* (
      photo: PhotoModelType,
      position: number
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      const { albumPage } = self;

      assert(albumPage, 'No album page loaded');

      try {
        const result = yield client.placePhotoOnAlbumPage(
          photo,
          albumPage,
          position
        );

        self.albumPage = createAlbumPageModel(result);
      } catch (error: any) {
        if (!applicationStore.handleAppError(error)) {
          throw error;
        }
      }
    });

    /**
     * Removes the given photo from the current album page
     */
    const removePhoto = flow(function* (photo: PhotoModelType) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      const { albumPage } = self;

      assert(albumPage, 'No album page loaded');

      try {
        yield client.removePhotoFromAlbumPage(photo, albumPage);

        destroyAlbumPagePhoto(photo.id);
      } catch (error: any) {
        if (!applicationStore.handleAppError(error)) {
          throw error;
        }
      }
    });

    /**
     * Removes the given album page
     */
    const removeAlbumPage = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      const { albumPage } = self;

      assert(albumPage, 'No album page loaded');

      try {
        yield client.removeAlbumPage(albumPage.id);
      } catch (error: any) {
        if (!applicationStore.handleAppError(error)) {
          throw error;
        }
      }
    });

    const swapAlbumPageSorting = flow(function* (id: number, swapId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      const { albumPages } = self;

      assert(albumPages, 'No album pages loaded');

      try {
        const swap: SwapResponse = yield client.swapAlbumPageSorting(
          id,
          swapId
        );

        const firstElement = albumPages.find(
          (candidate) => candidate.id === swap.first_element.id
        );
        if (firstElement) {
          firstElement.sorting = swap.first_element.sorting;
        }

        const secondElement = albumPages.find(
          (candidate) => candidate.id === swap.second_element.id
        );
        if (secondElement) {
          secondElement.sorting = swap.second_element.sorting;
        }
      } catch (error: any) {
        if (!applicationStore.handleAppError(error)) {
          throw error;
        }
      }
    });

    const destroyAlbumPagePhoto = (photoId: number) => {
      const { albumPage } = self;

      assert(albumPage, 'No album page loaded');

      const oldAlbumPagePhoto = albumPage.album_page_photos.find(
        (candidate) => candidate.photo.id === photoId
      );
      if (oldAlbumPagePhoto) {
        destroy(oldAlbumPagePhoto);
      }
    };

    return {
      getAlbumPages,
      getAlbumPage,
      createAlbumPage,
      placePhoto,
      removePhoto,
      removeAlbumPage,
      swapAlbumPageSorting
    };
  })
  .views((self) => {
    return {
      get sortedAlbumPages() {
        const { albumPages } = self;
        return albumPages
          ? [...albumPages].sort(sortByField('sorting'))
          : undefined;
      }
    };
  });

export type AlbumPagesStoreType = Instance<typeof AlbumPagesStore>;
export default AlbumPagesStore;
