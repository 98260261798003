import React from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import { BookModelType } from 'models/BookModel';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { assert } from 'utils/assert';
import calculateDays from 'utils/calculate-days';
import extractLocalTime from 'utils/extract-local-time';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import CheckoutLink from 'components/CheckoutLink/CheckoutLink';

const CheckoutDeliveryDate = ({
  book,
  handleEdit
}: {
  book: BookModelType;
  handleEdit?: () => void;
}) => {
  // If there is a expired reservation, the delivery_date is empty but the reservation time remains.
  // In this case, the last wizard step (review) is shown where the user can reserve a new date.
  if (!book.delivery_date) {
    return (
      <>
        <Headline.Medium>
          <FormattedMessage id="Delivery date" />
        </Headline.Medium>

        <InputMessage warning={true}>
          <FormattedMessage id="Delivery date reservation expired" />
        </InputMessage>

        <ButtonBlock
          background="PRIMARY"
          onClick={() => handleEdit && handleEdit()}
        >
          <FormattedMessage id="Choose delivery date" />
        </ButtonBlock>
      </>
    );
  }

  assert(book.delivery_date_reservation_time);

  const deliveryDate = book.delivery_date?.delivery_date;
  const today = new Date();
  const paymentDeadline = calculateDays(
    today,
    book.delivery_date?.payment_deadline
  );
  const reservationTime = extractLocalTime(book.delivery_date_reservation_time);

  if (!handleEdit) {
    return (
      <>
        <Headline.Medium>
          <FormattedMessage id="Delivery date" />
        </Headline.Medium>
        <ItemStackBlock gap="XXS">
          <Headline.Medium>
            <FormattedDate
              value={deliveryDate}
              year="numeric"
              month="2-digit"
              day="2-digit"
            />
          </Headline.Medium>
          <Paragraph size="L" color="TEXT_LIGHT">
            <FormattedMessage id="Delivery date deadline" />
          </Paragraph>
        </ItemStackBlock>
      </>
    );
  }

  return (
    <>
      <ItemStackBlock gap="XXS">
        <Headline.Medium>
          <FormattedMessage id="Delivery date" />
        </Headline.Medium>
        <Paragraph size="L" color="TEXT_LIGHT">
          <FormattedMessage
            id="Delivery date notice"
            values={{
              date: paymentDeadline
            }}
          />
        </Paragraph>
      </ItemStackBlock>
      <ItemStackBlock gap="XXS">
        <Headline.Medium>
          <FormattedDate
            value={deliveryDate}
            year="numeric"
            month="2-digit"
            day="2-digit"
          />
        </Headline.Medium>
        <HorizontalStackBlock gap="S">
          <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
          <TextElement size="M">
            <FormattedMessage
              id="Delivery date reserved till"
              values={{ time: reservationTime }}
            />
          </TextElement>
        </HorizontalStackBlock>
      </ItemStackBlock>

      {handleEdit && (
        <CheckoutLink text="Change delivery date" onClick={() => handleEdit()} />
      )}
    </>
  );
};

export default CheckoutDeliveryDate;
