import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ImageCard,
  ImageCardBody,
  ImageCardImage,
  ImageCardStatus
} from 'blocks/ImageCard/ImageCard';
import IconComponent from 'components/IconComponent';
import { PhotoModelType } from 'models/PhotoModel';

export const PhotosListItem = ({ photo }: { photo: PhotoModelType }) => (
  <ImageCard
    square={true}
    contain={true}
    rounded={true}
    dim={photo.placed_in_album}
  >
    {photo.photo ? (
      <ImageCardImage src={photo.photo.small} alt="" />
    ) : (
      <ImageCardBody icon="HOURGLASS">
        <FormattedMessage id="Processing" />
      </ImageCardBody>
    )}
    {photo.placed_in_album && (
      <ImageCardStatus>
      <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
      <FormattedMessage id="Placed" />
    </ImageCardStatus>
    )}
  </ImageCard>
);

export default PhotosListItem;
