import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import LinkBlock from 'blocks/LinkBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import OrganizerConditional from 'domain/Conditionals/OrganizerConditional';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_LAYOUT, ROUTE_MOTTOS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import HelpSubject from 'components/HelpButton/HelpSubject';

import { MottosStoreType } from '../MottosStore';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { Link } from 'react-router-dom';
import PageHeader from 'components/PageHeader/PageHeader';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';

interface SelectedMottoScreenProps {
  applicationStore: ApplicationStoreType;
  mottosStore: MottosStoreType;
}

@inject('applicationStore', 'mottosStore')
@observer
class SelectedMottoScreen extends React.Component<
SelectedMottoScreenProps & HistoryProps
> {
  state = {
    showUnsetModal: false
  }

  setUnsetMotto() {
    this.setState({
      showUnsetModal: true
    })
  }

  unsetMotto() {
    this.props.mottosStore.unselect();

    this.props.history.push(ROUTE_MOTTOS + '/manage');
  }


  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="motto" />
      </>
    );
  }

  renderUnsetMottoConfirm() {
    return (
      <ConfirmBoxComponent
      header={<FormattedMessage id="motto unselect header" />}
      text={<FormattedMessage id="motto unselect info" />}
      confirmColor="PRIMARY"
      confirmText={<FormattedMessage id="motto unselect" />}
      onConfirm={() => this.unsetMotto()}
      abortText={<FormattedMessage id="Cancel" />}
      onAbort={() => this.setState({ showUnsetModal: false })}
    />
    )
  }

  renderMotto() {
    const { applicationStore, mottosStore } = this.props;

    const motto = applicationStore.book ? applicationStore.book.motto : null;

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Your(plural) motto" />} text={<FormattedMessage id="Changed your mind?" />} />

        {mottosStore.isSelectError && <GenericErrorComponent />}

        {motto && (
          <Paragraph size="L" weight="BOLD">{motto.name}</Paragraph>
        )}

        <OrganizerConditional profile={applicationStore.currentUser}>

          <ListStackBlock>
            <Link to={ROUTE_MOTTOS + '/select'}>
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="Set other motto" />
                    </EveryCardHeadline>
                  </EveryCardBody>
                </EveryCardPadding>
              </EveryCard>
            </Link>

            <EveryCard as="button" onClick={() => this.setUnsetMotto()}>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="Open voting again" />
                  </EveryCardHeadline>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          </ListStackBlock>

          {!applicationStore.isBookLocked && <>
            <Divider />

            <LinkBlock
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              to={ROUTE_LAYOUT}
            >
              <FormattedMessage id="Set design" />
            </LinkBlock>
          </>}
        </OrganizerConditional>

        {this.state.showUnsetModal && (
          this.renderUnsetMottoConfirm()
        )}
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const { mottosStore } = this.props;

    if (mottosStore.isSelectLoading) {
      return this.renderLoading();
    }

    return this.renderMotto();
  }
}

export default withRouter((props: any) => <SelectedMottoScreen {...props} />);
