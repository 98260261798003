import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import { intl } from 'i18n';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import React from 'react';
import { CheckoutStoreType } from 'screens/checkout/CheckoutStore';

import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { ROUTE_CALCULATOR, ROUTE_DASHBOARD } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface Props {
  applicationStore: ApplicationStoreType;
  checkoutStore: CheckoutStoreType;
}

@inject('applicationStore', 'checkoutStore')
@observer
export class CheckoutAuthorChangeScreen extends React.Component<
Props & HistoryProps
> {
  componentDidMount() {
    const { applicationStore, history } = this.props;
    if (!applicationStore.isOrganizer) {
      history.replace(ROUTE_DASHBOARD);
    }

    this.loadData();
  }

  async loadData() {
    const { checkoutStore } = this.props;

    try {
      await checkoutStore.getCheckout();
    } catch (e) {
      this.props.history.push(ROUTE_DASHBOARD);
    }
  }

  renderLoading() {
    return <LoadingIndicatorComponent />;
  }

  renderError() {
    return <GenericErrorComponent onRetryClick={() => this.loadData()} />;
  }

  render() {
    const { checkoutStore } = this.props;

    if (checkoutStore.getLoadingState === 'loading') {
      return this.renderLoading();
    }

    if (checkoutStore.getLoadingState === 'error') {
      return this.renderError();
    }

    const { checkout } = checkoutStore;

    if (!checkout || !checkout.author) {
      return null;
    }

    return (
      <SupportTicketScreen
      subject="checkout_change_person"
      backTo={ROUTE_CALCULATOR}
      organizerOnly={true}
      message={intl.formatMessage({ id: 'zap_change_person_checkout'}, {author: checkout.author.id} )}
    />
    )
  }
}

export default CheckoutAuthorChangeScreen;
