import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import Headline from 'elements/Headline';
import { STUDENT_EDITABLE_CHAPTER_TYPES } from 'models/ChapterModel';
import SettingNotAvailableComponent from '../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from './SettingsDetailProps';
import Paragraph from 'components/Paragraph/Paragraph';

export default ({
  form,
  chapter,
  onCommitChapter
}: SimpleSettingsDetailProps) => {
  if (STUDENT_EDITABLE_CHAPTER_TYPES.indexOf(chapter.chapter_type) < 0) {
    return <SettingNotAvailableComponent />;
  }

  return (
    <>
      <Headline.Large>
        <FormattedMessage id="Explanation for students" />
      </Headline.Large>

      <ItemStackBlock gap="L">
        <Paragraph>
          <FormattedMessage id="description settings info" />
        </Paragraph>

        <TextareaComponent
          name="description"
          height={7}
          label={<FormattedMessage id="Explanation text" />}
          autoFocus={true}
          {...form.bindInput('description')}
        />

        <ButtonBlock
          background="PRIMARY"
          onClick={() =>
            onCommitChapter({
              description: form.values.description || ''
            })
          }
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </ItemStackBlock>
    </>
  );
};
