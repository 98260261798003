import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

import LabelElement from './LabelElement';

interface CheckboxElementProps {
  red?: boolean;
}

export default styled.input.attrs({
  type: 'checkbox'
})<CheckboxElementProps>`
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  margin: -1px;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;

  /* stylelint-disable selector-type-no-unknown */
  &:checked + ${LabelElement}::before {
    background: ${COLORS.GREEN};
  }

  ${props => props.red && `
    /* stylelint-disable selector-type-no-unknown */
    &:not(:checked) + ${LabelElement}::before {
      background: ${COLORS.RED};
    }
  `}

  &:checked + ${LabelElement}::after {
    right: 0.1875em;
  }

  &[disabled] + ${LabelElement} {
    cursor: not-allowed;
  }

  &[disabled] + ${LabelElement}::before {
    opacity: 0.5;
  }
`;
