import React, { ReactElement } from 'react';

import CustomRadioBlock from 'blocks/CustomRadioBlock';
import Input from 'elements/Input';

import InputInterface from './InputInterface';

// https://github.com/Microsoft/TypeScript/issues/26019#issuecomment-408547279
type TextareaProps = Parameters<typeof Input>[0];

interface InfoTextProps {
  info?: ReactElement;
}

export const RadioButtonComponent = (
  props: InputInterface & TextareaProps & InfoTextProps
) => {
  const id = props.id
    ? props.id
    : '__formfield_radio_' + props.name + '_' + props.value;
  return (
    <CustomRadioBlock>
      <CustomRadioBlock.RadioElement id={id} {...props} />
      <CustomRadioBlock.LabelElement htmlFor={id}>
        {props.label}
        {props.info && (
          <CustomRadioBlock.InfoElement>
            {props.info}
          </CustomRadioBlock.InfoElement>
        )}
      </CustomRadioBlock.LabelElement>
    </CustomRadioBlock>
  );
};

export default RadioButtonComponent;
