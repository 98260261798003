import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import CardBlock from 'blocks/CardBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import SliderBlock from 'blocks/SliderBlock';
// import TabBarBlock from 'blocks/TabBarBlock';
import {
  ImageCard,
  ImageCardImage,
  ImageCardLabel,
  LabelledImageCard
} from 'blocks/ImageCard/ImageCard';
import TopActionBlock from 'blocks/TopActionBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import config from 'config';
import BackLink from 'domain/Links/BackLink';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { LayoutStoreType } from 'models/print/LayoutStore';
import { ROUTE_LAYOUT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface ThemesContainerProps {
  applicationStore: ApplicationStoreType;
  layoutStore: LayoutStoreType;
  chaptersStore: ChaptersStoreType;
  // form: FormType;

  detail?: boolean;
  switchTheme?: boolean; // -v2- mode to only switch between themes with the same base theme
}

interface ThemesContainerState {
  confirmUpdate?: boolean;
  selectedTheme?: string;

  updateState?: 'loading' | 'error';
}

@inject('applicationStore', 'layoutStore', 'chaptersStore')
@observer
class ThemesContainer extends React.Component<
ThemesContainerProps & HistoryProps,
ThemesContainerState
> {
  state: ThemesContainerState = {};

  componentDidMount() {
    this.loadThemes();
    this.loadCover();
  }

  componentDidUpdate(prevProps: ThemesContainerProps & HistoryProps) {
    if (this.props.detail) {
      if (!prevProps.detail) {
        // navigated to detail page
        this.loadCover();

        this.finishConfirmUpdate();
        this.finishUpdate();
      } else {
        // selected theme changed
        const key = this.props.match?.params?.key;
        const prevKey = prevProps.match?.params?.key;
        if (key !== prevKey) {
          this.finishConfirmUpdate();
          this.finishUpdate();
        }
      }

      return;
    }

    if (this.props.switchTheme && !prevProps.switchTheme) {
      this.loadCover();
    }
  }

  async loadThemes() {
    const { layoutStore } = this.props;

    if (!layoutStore.themesPresent) {
      await layoutStore.getThemes();
    }
  }

  async loadCover(force = false) {
    const { chaptersStore } = this.props;

    if (!force && chaptersStore.item?.chapter_type === 'cover') {
      return;
    }

    await chaptersStore.getCoverChapter();
  }

  async selectTheme(coverChapterId: number, themeKey: string) {
    const { chaptersStore } = this.props;

    this.setState({
      updateState: 'loading'
    });

    try {
      await chaptersStore.updateChapter(coverChapterId, {
        layout_key: themeKey
      });

      this.finishUpdate(true);
    } catch (error: any) {
      this.setState({
        updateState: 'error'
      });
    }
  }

  finishUpdate(redirect = false) {
    if (redirect) {
      this.props.chaptersStore.clearCurrentItem();
      this.props.history.push(
        this.props.switchTheme ? ROUTE_LAYOUT + '/front' : ROUTE_LAYOUT
      );
      return;
    }

    this.setState({
      updateState: undefined
    });
  }

  previewTheme(key: string) {
    this.props.history.push(ROUTE_LAYOUT + '/theme/' + key);
  }

  prepareThemeUpdate(key: string) {
    const cover = this.props.chaptersStore.item;

    if (
      !cover ||
      cover.chapter_type !== 'cover' ||
      !this.props.layoutStore.themeExists(key)
    ) {
      // wtf
      return undefined;
    }

    return cover;
  }

  doesUpdateResetSettings(oldKey: string, newKey: string) {
    if (oldKey === newKey) {
      return false;
    }

    const oldIdx = oldKey.indexOf('_');
    const newIdx = newKey.indexOf('_');
    if (oldIdx < 0 || newIdx < 0 || oldIdx !== newIdx) {
      // no base theme key, we don't know what is going on here
      // or length of base theme name is different, so we don't need to compare them to know they are different
      return true;
    }

    if (oldKey.substring(0, oldIdx) !== newKey.substring(0, newIdx)) {
      // different base theme names, update will reset all layout settings
      return true;
    }

    return false;
  }

  beginSelectTheme(key: string) {
    const cover = this.prepareThemeUpdate(key);
    if (!cover) {
      return;
    }

    if (cover.layout_key === key) {
      // nothing changed
      this.finishUpdate(true);
      return;
    }

    if (
      !cover.layout_key ||
      !this.doesUpdateResetSettings(cover.layout_key, key)
    ) {
      // first time selecting a theme or keeping same base theme, all good
      this.selectTheme(cover.id, key);
      return;
    }

    // theme will be updated and layout settings will be reset to defaults
    this.setState({
      confirmUpdate: true,
      selectedTheme: key
    });
  }

  finishConfirmUpdate(performSelect: boolean = false) {
    const { selectedTheme } = this.state;

    if (performSelect && selectedTheme) {
      const cover = this.prepareThemeUpdate(selectedTheme);
      if (cover) {
        this.selectTheme(cover.id, selectedTheme);
      }
    }

    this.setState({
      confirmUpdate: false,
      selectedTheme: undefined
    });
  }

  isNeutralActive() {
    return this.props.location?.hash === '#neutral';
  }

  renderDetail() {
    const { layoutStore, match } = this.props;
    const themeKey = match.params?.key;

    if (!themeKey || !layoutStore.themeExists(themeKey)) {
      return this.renderList();
    }

    const theme = layoutStore.themes!.get(themeKey)!;
    const preview = theme.previewLarge || theme.preview;

    // TODO preview
    return this.renderPage(
      <>
        <Headline.Large>
          {theme.name || <FormattedMessage id="Design theme" />}
        </Headline.Large>

        {preview && (
          <img
            src={config.layout.baseUrl + '/' + preview}
            alt="Cover"
            style={{
              width: '100%',
              height: 'auto'
            }}
          />
        )}

        {this.state.updateState === 'error' && <GenericErrorComponent />}

        <ButtonBlock
          background="PRIMARY"
          onClick={() => this.beginSelectTheme(theme.id)}
        >
          <FormattedMessage id="Select this theme" />
        </ButtonBlock>

        {theme.contentPreview && theme.contentPreview.length ? (
          <>
            <Headline.Medium>
              <FormattedMessage id="Page design preview" />
            </Headline.Medium>

            <SliderBlock fixSize="M">
              {theme.contentPreview.map((image, idx) => (
                <img
                  key={'page-' + idx}
                  src={config.layout.baseUrl + '/' + image}
                  alt="Seitenvorschu"
                />
              ))}
            </SliderBlock>
          </>
        ) : null}

        {this.state.confirmUpdate && (
          <ConfirmBoxComponent
            header={<FormattedMessage id="theme confirm header" />}
            text={<FormattedMessage id="theme confirm text" />}
            confirmText={<FormattedMessage id="Confirm" />}
            confirmColor="SECONDARY_DARK"
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => this.finishConfirmUpdate(true)}
            onAbort={() => this.finishConfirmUpdate(false)}
          />
        )}
      </>
    );
  }

  renderList() {
    const { chaptersStore, layoutStore } = this.props;

    const isNeutralActive = this.isNeutralActive();
    const privateThemes = !isNeutralActive ? layoutStore.privateThemes : [];

    const currentThemeId = chaptersStore.itemIfCover?.layout_key;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="choose theme" />
        </Headline.Large>

        <Paragraph>
          <FormattedMessage id="choose theme info" />
        </Paragraph>

        {/* <TabBarBlock>
            <TabBarBlock.Item to="#motto" active={!isNeutralActive}>
              <FormattedMessage id="Motto specific" />
            </TabBarBlock.Item>

            <TabBarBlock.Item to="#neutral" active={isNeutralActive}>
              <FormattedMessage id="No motto" />
            </TabBarBlock.Item>
          </TabBarBlock> */}

        {isNeutralActive ? (
          <>
            {layoutStore.neutralThemes.map((theme) => {
              return (
                <Link key={theme.id} to={ROUTE_LAYOUT + '/theme/' + theme.id}>
                  <CardBlock>
                    <PaddingBlock arrow={true}>
                      <Headline.Small color="GRAY10">
                        {theme.name || theme.id}
                      </Headline.Small>
                    </PaddingBlock>
                  </CardBlock>
                </Link>
              );
            })}
          </>
        ) : (
          <>
            {/* <LinkBlock
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              to={ROUTE_LAYOUT + '/theme/add'}
            >
              <FormattedMessage id="Add motto" />
            </LinkBlock> */}

            {!!privateThemes.length && (
              <ItemStackBlock gap="S">
                <Headline.Small line={true}>
                  <FormattedMessage id="Individual themes" />
                </Headline.Small>

                <TwoColumnBlock gap="M">
                  {privateThemes.map((theme) => (
                    <Link
                      to={ROUTE_LAYOUT + '/theme/' + theme.id}
                      key={theme.id}
                    >
                      <LabelledImageCard>
                        <ImageCard
                          contain={true}
                          border={
                            currentThemeId === theme.id ? 'PRIMARY' : false
                          }
                        >
                          <ImageCardImage
                            src={config.layout.baseUrl + '/' + theme.preview}
                            alt=""
                          />
                        </ImageCard>
                        <ImageCardLabel>
                          {theme.name || theme.id}
                        </ImageCardLabel>
                      </LabelledImageCard>
                    </Link>
                  ))}
                </TwoColumnBlock>
              </ItemStackBlock>
            )}

            {layoutStore.mottoThemes.map((item) => {
              if (!item.themes.length) {
                return null;
                // return (
                //   <Link
                //     key={item.motto.id}
                //     to={
                //       ROUTE_LAYOUT +
                //       '/theme/request?key=' +
                //       encodeURIComponent(item.motto.id) +
                //       '&motto=' +
                //       encodeURIComponent(item.motto.name || '')
                //     }
                //   >
                //     <CardBlock>
                //       <PaddingBlock arrow={true}>
                //         <Headline.Small color="GRAY10">
                //           {item.motto.name}
                //         </Headline.Small>
                //       </PaddingBlock>
                //     </CardBlock>
                //   </Link>
                // );
              }

              return (
                <ItemStackBlock key={item.motto.id} gap="S">
                  <Headline.Small>{item.motto.name}</Headline.Small>

                  <TwoColumnBlock gap="M">
                    {item.themes.map((theme) => (
                      <Link
                        to={ROUTE_LAYOUT + '/theme/' + theme.id}
                        key={theme.id}
                      >
                        <ImageCard
                          contain={true}
                          border={
                            currentThemeId === theme.id ? 'PRIMARY' : false
                          }
                        >
                          <ImageCardImage
                            src={config.layout.baseUrl + '/' + theme.preview}
                            alt=""
                          />
                        </ImageCard>
                        <ImageCardLabel>
                          {theme.name || theme.id}
                        </ImageCardLabel>
                      </Link>
                    ))}
                  </TwoColumnBlock>
                </ItemStackBlock>
              );
            })}
          </>
        )}
      </>
    );
  }

  renderTitleList() {
    // TODO -v2- Remove this as it is no longer needed?
    const { layoutStore, chaptersStore } = this.props;

    const cover = chaptersStore.itemIfCover;
    if (!cover || !cover.layout_key) {
      return null;
    }

    const key = cover.layout_key;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Select title" />
        </Headline.Large>

        <ButtonBlock
          background="PRIMARY"
          onClick={() =>
            this.props.history.push(ROUTE_LAYOUT + '/front/title/add')
          }
        >
          <FormattedMessage id="Use own title" />
        </ButtonBlock>

        <ListStackBlock>
          {layoutStore.relatedThemes(key).map((theme) => (
            <CardBlock
              key={theme.id}
              as="button"
              active={key === theme.id}
              onClick={() => this.selectTheme(cover.id, theme.id)}
            >
              <PaddingBlock>
                <Headline.Small color="GRAY10">{theme.name}</Headline.Small>
              </PaddingBlock>
            </CardBlock>
          ))}
        </ListStackBlock>
      </>
    );
  }

  renderPage(content: any) {
    let link;

    if (this.props.detail) {
      link = <BackLink to={ROUTE_LAYOUT + '/theme'} />;
    } else if (this.props.switchTheme) {
      link = <BackLink to={ROUTE_LAYOUT + '/front'} />;
    } else {
      const chapter = this.props.chaptersStore.item;

      if (!chapter || chapter.chapter_type !== 'cover' || !chapter.layout_key) {
        link = <BackToDashboardLink />;
      } else {
        link = <BackLink to={ROUTE_LAYOUT} />;
      }
    }

    return (
      <>
        <TopActionBlock>{link}</TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="theme" />
      </>
    );
  }

  renderError() {
    // TODO onRetry
    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Layout themes" />
        </Headline.Large>

        <GenericErrorComponent />
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const { layoutStore, chaptersStore, detail, switchTheme } = this.props;

    if (
      layoutStore.isLoading ||
      chaptersStore.isItemLoading ||
      this.state.updateState === 'loading'
    ) {
      return this.renderLoading();
    }

    if (layoutStore.isError) {
      return this.renderError();
    }

    if (detail) {
      if (chaptersStore.isItemLoadError) {
        return this.renderError();
      }

      return this.renderDetail();
    }

    if (switchTheme) {
      return this.renderTitleList();
    }

    return this.renderList();
  }
}

export default ThemesContainer;
