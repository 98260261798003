import config from 'config';

import { ColorScheme } from './color-scheme';
import sanitizeHtml from './sanitize-html';

export type LayoutType = 'print' | 'preview';
export type PagePosition = 'left' | 'right';
// export type ThemeBackground = 'light' | 'dark';

export interface CustomBackground {
  printLeft: string; // will be fallback for other values
  previewLeft?: string;
  printRight?: string;
  previewRight?: string;
}

export type TemplateVariables = [string, string][];

export interface PageParams {
  layout: LayoutType;
  position: PagePosition;
  page?: number;

  colors: ColorScheme;
  background?: string | CustomBackground;
  pageHeaderStyle?: string;
  sectionHeaderStyle?: string;

  variables?: TemplateVariables;
}

export interface PageParamsOverride {
  position?: PagePosition;
  page?: number;
  // variables?: TemplateVariables;
}

const getBackground = (
  bg: string | CustomBackground | undefined,
  layout: LayoutType,
  pos: PagePosition
) => {
  if (!bg) {
    return undefined;
  }

  if (typeof bg === 'string') {
    // pre defined background, use file from layout definitions
    // TODO add path to layout definitions directory
    return (
      config.layout.baseUrl +
      '/backgrounds/files/' +
      pos +
      '-' +
      layout +
      '-' +
      bg
    );
  }

  if (layout === 'print') {
    return pos === 'right' && bg.printRight ? bg.printRight : bg.printLeft;
  }

  // preview may fall back to print if no preview background is present
  if (pos === 'right') {
    if (bg.previewRight) {
      return bg.previewRight;
    }
    if (bg.printRight) {
      return bg.printRight;
    }
  }

  return bg.previewLeft ? bg.previewLeft : bg.printLeft;
};

export const sanitizeReplacement = (str: string): string => {
  if (typeof str !== 'string') {
    return str;
  }
  return str.replace(/\$/g, '$$$$');
};

export const replaceVariables = (
  input: any,
  params: PageParams,
  content?: TemplateVariables
): string | undefined => {
  if (input === '') {
    return '';
  }
  if (!input) {
    return undefined;
  }

  const {
    colors,
    layout,
    position,
    page,
    // scheme,
    background,
    variables,
    pageHeaderStyle,
    sectionHeaderStyle
  } = params;
  const replacements: Array<[string, string]> = [];

  replacements.push(['{layout}', layout]);
  replacements.push(['{position}', position]);
  replacements.push(['{page}', !page ? '' : page.toString()]);

  replacements.push(['{specs}', config.layout.baseUrl]);

  replacements.push([
    '{background}',
    getBackground(background, layout, position) || ''
  ]);

  // TODO remove variables/replace with comment
  replacements.push(['{pageheader}', sanitizeHtml(pageHeaderStyle, true) || '']);
  replacements.push([
    '{sectionheader}',
    sanitizeHtml(sectionHeaderStyle, true) || ''
  ]);

  for (const color of colors) {
    replacements.push(['{color_' + color.name + '}', color.value]);
  }

  if (content && content.length) {
    for (const elem of content) {
      replacements.push(['{content_' + elem[0] + '}', elem[1]]);
    }
  }

  if (variables) {
    variables.forEach((elem) =>
      replacements.push(['{' + elem[0] + '}', elem[1]])
    );
  }

  let output = input.toString();

  for (const replacement of replacements) {
    output = output.replace(
      new RegExp(replacement[0], 'g'),
      sanitizeReplacement(replacement[1])
    );
  }

  return output;
};

export default replaceVariables;
