import { inject, observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import QuotesPreviewComponent from 'components/print/layout-editor/QuotesPreviewComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import ChapterPreviewComponent from 'domain/ChapterPreviewComponent';
import LockedLabel from 'domain/Label/LockedLabel';
import BackToContentLink from 'domain/Links/BackToContentLink';
import Headline from 'elements/Headline';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChapterModelType } from 'models/ChapterModel';
import { QuoteModelType } from 'models/QuoteModel';
import { ROUTE_QUOTES } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import useForm, { FormType } from 'utils/hooks/useForm';

import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { QuotesStoreType } from '../QuotesStore';
import QuotesListItem from './containers/QuotesListItem/QuotesListItem';

interface QuotesListProps {
  quotesStore: QuotesStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('quotesStore', 'applicationStore')
@observer
class QuotesList extends Component<QuotesListProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore, quotesStore } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature('quotes', this.props.location.pathname)
    ) {
      return;
    }

    if (!quotesStore.isListLoading) {
      if (!quotesStore.quotes || isPush(this.props.history)) {
        this.loadQuotes();
      }
    }
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const chapterId = chapterIdFromMatch(this.props.match);
    const prevChapterId = chapterIdFromMatch(prevProps.match);

    if (chapterId !== prevChapterId) {
      this.loadQuotes();
    }
  }

  loadQuotes() {
    const { quotesStore } = this.props;
    const chapterId = chapterIdFromMatch(this.props.match);

    chapterId
      ? quotesStore.getQuotesByChapter(chapterId)
      : quotesStore.getAllQuotes();
  }

  chapterUpdated(chapter: ChapterModelType) {
    this.props.quotesStore.setChapter(chapter);
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadQuotes()} />
    );
  }

  renderEmpty() {
    return this.renderPage(
      <EmptyStateComponent
        headerId="quotes empty header"
        textId="quotes empty text"
      />
    );
  }

  renderList() {
    const { quotesStore, applicationStore, form } = this.props;
    const { isOrganizer } = applicationStore;
    const { chapter } = quotesStore;

    let quotes: QuoteModelType[];
    let heading: ReactNode;

    if (!form.values.filter) {
      quotes = quotesStore.allQuotes;

      if (quotes.length === 0) {
        return this.renderEmpty();
      }

      heading = (
        <FormattedMessage
          id="quotes count"
          values={{
            count: quotes.length
          }}
        />
      );
    } else {
      quotes = quotesStore.filteredQuotes(form.values.filter);

      heading = (
        <FormattedMessage
          id="quotes filter count"
          values={{
            count: quotes.length
          }}
        />
      );
    }

    return this.renderPage(
      <>
        <SearchListInput form={form} placeholderId="Find quote" />

        {chapter && isOrganizer && (
          <LinkBlock
            inline={true}
            slim={true}
            color="PRIMARY_DARK"
            background="PRIMARY_LIGHT"
            to={ROUTE_QUOTES + '/' + chapter.id + '/sort'}
          >
            <IconComponent icon="SORT_V" />
            <FormattedMessage id="Sorting order" />
          </LinkBlock>
        )}

        <ItemStackBlock gap="S">
          <UppercaseHeading>{heading}</UppercaseHeading>

          <ListStackBlock>
            {quotes.map((quote) => (
              <QuotesListItem
                key={quote.id}
                quote={quote}
                currentUser={applicationStore.currentUser}
              />
            ))}
          </ListStackBlock>
        </ItemStackBlock>
      </>
    );
  }

  renderPage(content: ReactNode) {
    const { applicationStore, quotesStore } = this.props;
    const { isOrganizer } = applicationStore;
    const { chapter } = quotesStore;

    return (
      <>
        <TopActionBlock>
          <BackToContentLink />
        </TopActionBlock>

        <PageStackBlock>
          <ChapterPreviewComponent
            chapter={chapter}
            defaultTextId="Quotes"
            baseRoute={ROUTE_QUOTES}
            onChapterUpdated={(updatedChapter) =>
              this.chapterUpdated(updatedChapter)
            }
            marginBottom="M"
          >
            {chapter?.layout?.layoutDefinition && (
              <FontPreloadComponent chapter={chapter} numPages={1}>
                <SliderBlock>
                  <QuotesPreviewComponent
                    scaled={true}
                    config={chapter.layout.layoutDefinition}
                    chapter={chapter}
                    quotes={quotesStore.printedQuotes}
                  />
                </SliderBlock>
              </FontPreloadComponent>
            )}
          </ChapterPreviewComponent>

          {isOrganizer && (
            <ItemStackBlock gap="XS">
              <Headline.Medium>
                <FormattedMessage id="Content" />
              </Headline.Medium>

              <Paragraph color="TEXT_LIGHT">
                <FormattedMessage id="manage quotes info" />
              </Paragraph>
            </ItemStackBlock>
          )}

          {chapter && applicationStore.isChapterEditAllowed(chapter) ? (
            <ButtonBlock
              background="PRIMARY"
              onClick={() =>
                this.props.history.push(
                  ROUTE_QUOTES + '/' + chapter.id + '/new'
                )
              }
            >
              <FormattedMessage id="Add quote" />
            </ButtonBlock>
          ) : (
            <LockedLabel id="Quotes" />
          )}

          <Divider />

          {content}
        </PageStackBlock>

        <HelpSubject subject="quotes" />
      </>
    );
  }

  render() {
    const { quotesStore } = this.props;

    if (quotesStore.isListLoading) {
      return this.renderLoading();
    }

    if (quotesStore.isListError) {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default (props: any) => {
  const form = useForm();
  return <QuotesList {...props} form={form} />;
};
