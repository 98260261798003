export enum FONT_SIZES {
  HEADLINE_L = '1.68rem',
  HEADLINE_M = '1.5rem',
  HEADLINE_S = '1.31rem',
  L = '1.125rem',
  M = '1rem',
  S = '0.875rem',
  XS = '0.75rem',
  LABEL = '0.75rem'
}

export default FONT_SIZES;
