import { useState } from 'react';

interface InputBinding {
  value: any;
  error: any;
  onChange: (event: any) => void;
}

interface CheckedBinding {
  checked: boolean;
  error: any;
  onChange: (event: any) => void;
}

export interface FormValues {
  [key: string]: any;
}

interface FormErrors {
  [key: string]: string;
}

type EventHandler = (event: Event, newValues: FormValues) => void;

export interface FormType {
  reset: () => void;
  setField: (name: string, newValue: any) => void;
  set: (value: FormValues) => void;
  values: FormValues;
  bindInput: (name: string, onChange?: EventHandler) => InputBinding;
  bindCheckbox: (name: string, onChange?: EventHandler) => CheckedBinding;
  bindRadioButton: (
    name: string,
    radioValue: string,
    onChange?: EventHandler
  ) => CheckedBinding;
  errors: FormValues;
  setError: (name: string, error?: any) => void;
  resetErrors: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  confirm: boolean | string | number;
  setConfirm: (confirm: boolean | string | number) => void;
}

export const useForm = (
  initialValues: FormValues = {},
  initialErrors: FormValues = {},
  initialLoading: boolean = false,
  initialConfirm: boolean | string | number = false
): FormType => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrors);
  const [loading, setLoading] = useState(initialLoading);
  const [confirm, setConfirm] = useState(initialConfirm);

  return {
    reset: () => {
      setValues(initialValues);
      setErrors(initialErrors);
      setLoading(initialLoading);
      setConfirm(initialConfirm);
    },
    setField: (name: string, newValue: any) => {
      setValues((oldValues) => ({
        ...oldValues,
        [name]: newValue
      }));
    },
    set: (newValue) => {
      setValues(newValue);
    },
    values,
    bindInput: (name: string, onChange?: EventHandler) => ({
      value: values[name] || '',
      error: errors[name],
      onChange: (event: Event) => {
        const newValues: FormValues = {
          ...values,
          [name]: (event.target as HTMLInputElement).value
        };
        setValues(newValues);
        if (onChange) {
          onChange(event, newValues);
        }
      }
    }),
    bindCheckbox: (name: string, onChange?: EventHandler) => ({
      checked: values[name] || false,
      error: errors[name],
      onChange: (event: Event) => {
        const newValues: FormValues = {
          ...values,
          [name]: (event.target as HTMLInputElement).checked
        };
        setValues(newValues);
        if (onChange) {
          onChange(event, newValues);
        }
      }
    }),
    bindRadioButton: (
      name: string,
      radioValue: string,
      onChange?: EventHandler
    ) => ({
      checked: values[name] === radioValue,
      error: errors[name],
      onChange: (event: Event) => {
        const newValues: FormValues = {
          ...values,
          [name]: (event.target as HTMLInputElement).value
        };
        setValues(newValues);
        if (onChange) {
          onChange(event, newValues);
        }
      }
    }),
    errors,
    setError: (name: string, error?: any) => {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          [name]: error
        };
      });
    },
    resetErrors: () => {
      setErrors(initialErrors);
    },
    loading,
    setLoading,
    confirm,
    setConfirm
  };
};

export const handleFormError = (
  form: FormType,
  error: any
): FormErrors | false => {
  if (!error || !error.response || error.response.status !== 422) {
    // this is not a form error
    return false;
  }

  if (!error.body || !error.body.errors) {
    // this is not a valid error response
    return false;
  }


  const errors: { [key: string]: any } = error.body.errors;
  const handledErrors: FormErrors = {};

  const hasFormError = handleErrorObject(form, handledErrors, errors);
  return hasFormError ? handledErrors : false;
};

const handleErrorObject = (
  form: FormType,
  handledErrors: FormErrors,
  errors: { [key: string]: any }
) => {
  let hasFormError = false;

  for (const fieldName in errors) {
    if (!errors.hasOwnProperty(fieldName)) {
      continue;
    }

    if (Array.isArray(errors[fieldName])) {
      // top level field error
      if (errors[fieldName].length) {
        const message = errors[fieldName][0];

        handledErrors[fieldName] = message;
        form.setError(fieldName, message);

        hasFormError = true;
      }
      continue;
    }

    if (typeof errors[fieldName] === 'object') {
      // embedded object error(s)
      if (handleErrorObject(form, handledErrors, errors[fieldName])) {
        hasFormError = true;
      }
    }
  }

  return hasFormError;
};

export default useForm;
