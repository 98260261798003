import React, { useState } from "react";

interface FlashMessage {
  message?: string;
  type?: string;
}

interface FlashMessageContextValue {
  flashMessage: FlashMessage;
  triggerFlashMessage: (message: string, type?: string) => void;
}

export const FlashMessageContext = React.createContext<FlashMessageContextValue | undefined>(undefined);

export const FlashMessageProvider: React.FC = ({ children }) => {
  const [flashMessage, setFlashMessage] = useState<FlashMessage>({});

  const triggerFlashMessage = (message: string, type: string = 'warning') => {
      setFlashMessage({ message, type });
      setTimeout(() => setFlashMessage({}), 2000); // auto-hide after 3 seconds
  };

  return (
      <FlashMessageContext.Provider value={{ flashMessage, triggerFlashMessage }}>
          {children}
      </FlashMessageContext.Provider>
  );
};
