import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import InputMessage from 'elements/InputMessage';
import NakedLink from 'elements/NakedLink';
import { useCurrentTextileProfileQuery } from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const ProfileOverviewScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const currentTextileProfile = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider')
  }

  useEffect(() => {
    accessContext.checkSectionAccess('profile')
  }, [accessContext])

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink
            to={
              textileAppState?.textile_profile.is_organizer
                ? textileOrderRoute(props.match, '/management')
                : textileOrderRoute(props.match, '')
            }
          />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  }

  if (currentTextileProfile.isError) {
    return renderPage(<GenericErrorComponent onRetryClick={() => currentTextileProfile.refetch()} />)
  }

  if (!textileAppState || currentTextileProfile.isLoading || !currentTextileProfile.data) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const renderOverview = () => {
    const profile = currentTextileProfile.data

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="textile print settings" />}
          text={""}
        />
        {profile.print_data_notification && (
          <InputMessage info={true}>
            <Paragraph weight="BOLD" size="L">
              <FormattedMessage id="message from organiser" />
            </Paragraph>

            <Divider color="PURPLE" />

            <Paragraph>
              {profile.print_data_notification.notification}
            </Paragraph>
            <Paragraph>
              <IconComponent icon="ORGANIZER" fill="PURPLE" />
              {profile.print_data_notification.author_name}
            </Paragraph>
          </InputMessage>
        )}

        <ItemStackBlock gap="XS">
          <Paragraph>
            <FormattedMessage id="textile print information" />
          </Paragraph>
          <Divider color="BLACK" useStackBlockGap={true} />
          <HorizontalStackBlock justified={true} centered={true}>
            <TextElement>
              <FormattedMessage id="Name" />
            </TextElement>
            <TextElement>{profile.name}</TextElement>
          </HorizontalStackBlock>

          <Divider useStackBlockGap={true} />

          {profile.textile_group && (
            <>
              <HorizontalStackBlock justified={true} centered={true}>
                <TextElement>
                  <FormattedMessage id="Group" />
                </TextElement>
                <TextElement>{profile.textile_group.name}</TextElement>
              </HorizontalStackBlock>

              <Divider useStackBlockGap={true} />
            </>
          )}
          <HorizontalStackBlock justified={true} centered={true}>
            <TextElement>
              <FormattedMessage id="Print textile" />
            </TextElement>
            <TextElement>
              <FormattedMessage
                id={
                  profile.print
                    ? 'textile profile print'
                    : 'textile profile no print'
                }
              />
            </TextElement>
          </HorizontalStackBlock>

          <Divider useStackBlockGap={true} />

          {textileAppState.textile_profile.is_organizer ? (
            <NakedLink
              to={textileOrderRoute(
                props.match,
                '/management/overwrite/' + profile.id
              )}
            >
              <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK">
                <FormattedMessage id="Change" />
              </ButtonBlock>
            </NakedLink>
          ) : (
            <TextElement>
              <FormattedMessage id="textile print questions orga" />
            </TextElement>
          )}

        </ItemStackBlock>
      </>
    );
  }

  return renderOverview();
}

export default ProfileOverviewScreen;
