import { destroy, get, patch, post, uri } from "api/BaseApi";
import { TextileGroup } from "./fetchTextileGroups";

type Role = 'student' | 'teacher';

export interface TextileName {
  id: number;
  first_name?: string;
  last_name?: string;
  name?: string;
  textile_group?: TextileGroup;
  role?: Role;
  textile_order_id?: string;
}

export const getAllTextileNames = (textileOrderId: number): Promise<TextileName[]> => {
  return get(`/textile_names?textile_order_id=${textileOrderId}`);
}

export const getTextileName = (id: number, textileOrderId: number): Promise<TextileName> => {
  return get(
    '/textile_names/' + uri(id) + '?textile_order_id=' + textileOrderId
  );
}

export const updateTextileName = (
  id: number,
  textileOrderId: number,
  data: any
): Promise<TextileName> => {
  return patch('/textile_names/' + uri(id), {
    textile_name: data,
    textile_order_id: textileOrderId
  });
}

export const createTextileName = (textileName: any, textileOrderId: number): Promise<TextileName> => {
  return post('/textile_names', {
    textile_name: textileName,
    textile_order_id: textileOrderId
  });
}

export const removeTextileName = (id: number, textileOrderId: number) => {
  return destroy(
    '/textile_names/' + uri(id) + '?textile_order_id=' + textileOrderId
  );
}