import IconComponent from 'components/IconComponent';
import { CheckoutStep } from 'models/CheckoutModel';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface CheckoutProgressComponentProps {
  steps: readonly CheckoutStep[];
  activeStep: CheckoutStep;
}

export const CheckoutProgress = ({
  steps,
  activeStep
}: CheckoutProgressComponentProps) => {
  const activeStepIndex =
    activeStep === 'complete' ? Infinity : steps.indexOf(activeStep);
  return (
    <>
      <Steps>
        {steps.map((step, index) => {
          return (
            <Step
              key={step}
              isFuture={index > activeStepIndex}
              style={{ width: `${100 / steps.length}%` }}
            >
              <StepDotWrapper>
                {index < activeStepIndex ? (
                  <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                ) : (
                  <StepDot
                    type={activeStepIndex === index ? 'active' : 'future'}
                  />
                )}
              </StepDotWrapper>
              <StepLabel>
                <FormattedMessage id={`Checkout step ${step}`} />
              </StepLabel>
            </Step>
          );
        })}
      </Steps>
    </>
  );
};

const Steps = styled.div`
  display: flex;
`;

const Step = styled.div<{ isFuture: boolean }>`
  text-align: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: calc(1em / 2 - 0.3125em / 2);
    left: calc(-1 * (100% / 2 - 1em / 2));
    width: calc(100% - 1em / 2);
    height: 0.3125em;
    background-color: ${({ isFuture }) =>
      isFuture ? COLORS.GRAY950 : COLORS.GREEN};
  }

  &:first-child::before {
    content: none;
  }
`;

const StepDotWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto 0.5em;
  width: 1em;
  height: 1em;
  background-color: ${COLORS.WHITE};
`;

/**
 * Used for active and future steps only
 */
const StepDot = styled.div<{ type: 'active' | 'future' }>`
  width: 1em;
  height: 1em;
  background-color: ${({ type }) =>
    type === 'active' ? COLORS.WHITE : COLORS.GRAY950};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ type }) =>
    type === 'active' ? COLORS.GREEN : COLORS.GRAY950};
  border-radius: 50%;
`;

const StepLabel = styled.span`
  font-size: 0.77777em;
`;

export default CheckoutProgress;
