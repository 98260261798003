import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_RANKINGS } from 'utils/constants/routes';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import CandidateDetailScreen from './screens/CandidateDetail/CandidateDetailScreen';
import CandidatesListScreen from './screens/CandidatesList/CandidatesListScreen';
import RankingDetail from './screens/RankingDetail/RankingDetail';
import RankingShow from './screens/RankingDetail/RankingShow';
import RankingsList from './screens/RankingsList/RankingsList';
import RankingsSettings from './screens/RankingsSettings';
import RankingVote from './screens/RankingDetail/RankingVote';
import SortRankingsScreen from './screens/SortRanking/SortRankingScreen';
import RankingSelectWinner from './screens/RankingDetail/RankingSelectWinner';
import RankingIdeasScreen from './screens/RankingIdeasScreen';
import StudentInviteScreen from './screens/StudentInvite/StudentInviteScreen';

export default () => (
  <Switch>
    <Route
      path={ROUTE_RANKINGS + '/:chapterId'}
      exact={true}
      component={RankingsList}
    />
    <Route
      path={ROUTE_RANKINGS + '/:chapterId/ideas'}
      component={RankingIdeasScreen}
    />
    <Route
      path={ROUTE_RANKINGS + '/:chapterId/settings'}
      component={RankingsSettings}
    />

    <Route
      path={ROUTE_RANKINGS + '/:chapterId/candidates/new'}
      component={CandidateDetailScreen}
    />
    <Route
      path={ROUTE_RANKINGS + '/:chapterId/candidates/:id'}
      component={CandidateDetailScreen}
    />
    <Route
      path={ROUTE_RANKINGS + '/:chapterId/candidates'}
      component={CandidatesListScreen}
    />

    <Route
      path={ROUTE_RANKINGS + '/:chapterId/invite'}
      component={StudentInviteScreen}
    />

    <Route
      path={ROUTE_RANKINGS + '/:chapterId/new'}
      component={RankingDetail}
    />
    <Route
      path={ROUTE_RANKINGS + '/:chapterId/sort'}
      component={SortRankingsScreen}
    />
    <Route
      path={ROUTE_RANKINGS + '/:chapterId/:id/edit'}
      component={RankingDetail}
    />
    <Route
      path={ROUTE_RANKINGS + '/:chapterId/:id/show'}
      component={RankingShow}
    />
    <Route
      path={ROUTE_RANKINGS + '/:chapterId/:id/winner'}
      component={RankingSelectWinner}
    />
    <Route path={ROUTE_RANKINGS + '/:chapterId/:id'} component={RankingVote} />

    <NotFoundRoute />
  </Switch>
);
