import { ColorScheme } from 'utils/chunker/color-scheme';
import {
  CustomBackground,
  PageParams,
  PagePosition
} from 'utils/chunker/replace-variables';
import sanitizeHtml from 'utils/chunker/sanitize-html';

export type TemplateRenderFunc = (
  content: any,
  key?: string | number,
  params?: { [key: string]: any }
) => any;

export interface CommonTemplateProps {
  title?: string; // chapter title
  print?: boolean;
  firstPageOnly?: boolean;

  // environment
  colors: ColorScheme;
  startPosition: PagePosition;
  startPage: number;
  pageHeaderStyle?: string;
  sectionHeaderStyle?: string;
  background?: string | CustomBackground;

  // display
  render?: TemplateRenderFunc;

  // events
  onContentPlaced?: (pageCount: number) => void;
}

export const pageParamsFromProps = (
  props: CommonTemplateProps,
  position: PagePosition,
  page?: number
): PageParams => {
  const {
    title,
    print,
    colors,
    pageHeaderStyle,
    sectionHeaderStyle,
    background
  } = props;

  const params: PageParams = {
    layout: print ? 'print' : 'preview',
    colors,
    position,
    page,
    pageHeaderStyle,
    sectionHeaderStyle,
    background,
    variables: [['title', sanitizeHtml(title) || '']]
  };

  return params;
};
