import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';
import { PriceTier } from 'models/BookModel';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTE_DELIVERY_DATES_OVERVIEW } from 'utils/constants/routes';

interface CalculatorButtonsProps {
  type: 'checkout' | 'calculator';
  hasCheckout: boolean;
  priceTier: PriceTier;
  offerAsPDF: () => void;
  startCheckout: () => void;
}

const CalculatorButtons = ({
  type,
  priceTier,
  hasCheckout,
  offerAsPDF,
  startCheckout
}: CalculatorButtonsProps) => {
  if (priceTier === 'default') {
    return (
      <>
        <Link to={ROUTE_DELIVERY_DATES_OVERVIEW}>
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="Available delivery dates" />
                </EveryCardHeadline>
              </EveryCardBody>
              <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
            </EveryCardPadding>
          </EveryCard>
        </Link>
        <ItemStackBlock gap="S">
          <ButtonBlock background="PRIMARY" onClick={() => offerAsPDF()}>
            <FormattedMessage id="Offer as PDF" />
          </ButtonBlock>
        </ItemStackBlock>
      </>
    );
  }

  return (
    <>
      <Link to={ROUTE_DELIVERY_DATES_OVERVIEW}>
        <EveryCard>
          <EveryCardPadding>
            <EveryCardBody>
              <EveryCardHeadline>
                <FormattedMessage id="Available delivery dates" />
              </EveryCardHeadline>
            </EveryCardBody>
            <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
          </EveryCardPadding>
        </EveryCard>
      </Link>

      <ItemStackBlock gap="XS">
        {type === 'calculator' && (
          <ItemStackBlock gap="S">
            <ButtonBlock background="PRIMARY" onClick={() => offerAsPDF()}>
              <FormattedMessage id="Offer as PDF" />
            </ButtonBlock>

            <ButtonBlock background="GRAY950" color="GRAY800" disabled={true}>
              <FormattedMessage id="Start checkout" />
            </ButtonBlock>

            <TextElement size="M" color="TEXT_LIGHT">
              <FormattedMessage id={priceTier === 'silver' ? "Checkout start info silver" : "Checkout start info"} />
            </TextElement>
          </ItemStackBlock>
        )}

        {type === 'checkout' && (
          <ItemStackBlock gap="S">
            <ButtonBlock background="PRIMARY" onClick={() => startCheckout()}>
              {hasCheckout ? (
                <FormattedMessage id="Continue checkout" />
              ) : (
                <FormattedMessage id="Start checkout" />
              )}
            </ButtonBlock>
          </ItemStackBlock>
        )}
      </ItemStackBlock>
    </>
  );
};

export default CalculatorButtons;
