import styled from 'styled-components';

import ImageBlock from 'blocks/ImageBlock';
import PaddingBlock from 'blocks/PaddingBlock';

const MediaBlock = styled.div`
  display: flex;

  > ${PaddingBlock} {
    flex-grow: 1;
  }

  > ${ImageBlock} {
    margin: 1rem 0 1.0625rem 1rem;
  }
`;

export default MediaBlock;
