import React from 'react';

import ItemStackBlock from 'blocks/ItemStackBlock';
import SuccessContainerBlock from 'blocks/SuccessContainerBlock';
import IconComponent from 'components/IconComponent';

interface SuccessContainerComponentProps {
  children: any;
}

function SuccessContainerComponent({
  children
}: SuccessContainerComponentProps) {
  return (
    <SuccessContainerBlock>
      <IconComponent icon="tick_circle" fill="PRIMARY" size={4.375} />

      <ItemStackBlock gap="L" marginTop="L">
        {children}
      </ItemStackBlock>
    </SuccessContainerBlock>
  );
}

export default SuccessContainerComponent;
