import React, { Component } from 'react';

import HeaderComponent from 'components/HeaderComponent/HeaderComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { Route, Switch } from 'react-router-dom';
import AccountChangePasswordScreen from 'screens/account_dashboard/AccountChangePasswordScreen';
import AccountConfirmNumberScreen from 'screens/account_dashboard/AccountConfirmNumberScreen';
import AccountDashboardScreen from 'screens/account_dashboard/AccountDashboardScreen';
import AccountNumberScreen from 'screens/account_dashboard/AccountNumberScreen';
import MyAccountScreen from 'screens/account_dashboard/MyAccountScreen';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import {
  ROUTE_ACCOUNT_CHANGE_PASSWORD,
  ROUTE_ACCOUNT_DASHBOARD,
  ROUTE_ACCOUNT_DASHBOARD_ACCOUNT,
  ROUTE_ACCOUNT_MOBILE_NUMBER,
  ROUTE_AUTH
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface TextileRoutesProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class TextileRoutes extends Component<TextileRoutesProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore } = this.props;
    if (!applicationStore.currentAccount) {
      applicationStore.checkAuthenticated(true);
    }
  }

  renderLoader() {
    return (
      <>
        <HeaderComponent />
        <LoadingOverlayComponent />
      </>
    );
  }

  render() {
    const { applicationStore } = this.props;

    if (applicationStore.isAuthenticating) {
      return this.renderLoader();
    }

    if (!applicationStore.isAuthenticated) {
      // this should not happen as ApplicationStore redirects to login
      // but just to be sure
      return this.renderLoader();
    }

    return (
      <>
        <Switch>
          <Route
            path={ROUTE_ACCOUNT_DASHBOARD}
            exact={true}
            component={AccountDashboardScreen}
          />

          <Route
            path={ROUTE_ACCOUNT_CHANGE_PASSWORD}
            component={AccountChangePasswordScreen}
          />

          <Route
            path={ROUTE_ACCOUNT_MOBILE_NUMBER + '/confirm'}
            component={AccountConfirmNumberScreen}
          />

          <Route
            path={ROUTE_ACCOUNT_MOBILE_NUMBER}
            component={AccountNumberScreen}
          />

          <Route
            path={ROUTE_ACCOUNT_DASHBOARD_ACCOUNT}
            component={MyAccountScreen}
          />

          <Route
            path={ROUTE_AUTH}
            exact={true}
            component={AccountDashboardScreen}
          />
          <NotFoundRoute />
        </Switch>
      </>
    );
  }
}

export default TextileRoutes;
