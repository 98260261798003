import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EveryCard,
  EveryCardBody,
  EveryCardFooter,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';

export const FactsheetsListItem = ({
  label,
  print
}: {
  label: ReactNode;
  print: boolean;
}) => {
  return (
    <EveryCard>
      <EveryCardPadding>
        <EveryCardBody>
          <EveryCardHeadline>{label}</EveryCardHeadline>
        </EveryCardBody>
      </EveryCardPadding>
      {!print && (
        <EveryCardFooter>
          <TextElement  color="TEXT_DARK">
            <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
            <FormattedMessage id="Will not be printed" />
          </TextElement>
        </EveryCardFooter>
      )}
    </EveryCard>
  );
};

export default FactsheetsListItem;
