import React from 'react';

import { intl } from 'i18n';
import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

export const TeacherQuestionIdeasScreen = ({ match }: HistoryProps) => (
  <SupportTicketScreen
    subject="idealist_lehrersteckbrief"
    backTo={
      ROUTE_FACTSHEETS +
      (!match.params.chapterId ? '' : '/' + match.params.chapterId) +
      '/questions'
    }
    organizerOnly={true}
    message={intl.formatMessage({ id: 'zap_ideas_questions'})}
  />
);

export default TeacherQuestionIdeasScreen;
