import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { BookModelType } from 'models/BookModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ROUTE_CONTENT, ROUTE_PDF_PREVIEW } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import HelpSubject from 'components/HelpButton/HelpSubject';

import GroupSelectComponent from '../containers/GroupSelectComponent';
import OrganizerChapterListItem from './OrganizerChapterListItem';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';

interface OrganizerContentContainerProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class OrganizerContentContainer extends React.Component<
  OrganizerContentContainerProps & HistoryProps
> {
  componentDidMount() {
    if (this.checkBookState()) {
      // no need to fetch everything again
      return;
    }

    if (this.props.applicationStore.onboardFeature('content')) {
      return;
    }

    this.load();
  }

  private async load() {
    await this.props.chaptersStore.getAllChapters(true);

    this.checkBookState();
  }

  private isPreviewing(book?: BookModelType) {
    return book?.isLockedForPreview || false;
  }

  private checkBookState() {
    if (this.isPreviewing(this.props.applicationStore.book)) {
      this.props.history.replace(ROUTE_CONTENT + '/preview');
      return true;
    }

    return false;
  }

  private renderPage(content?: any) {
    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>

        {content}

        <HelpSubject subject="content" />
      </>
    );
  }

  renderChapters() {
    const { chaptersStore } = this.props;

    const chapters = chaptersStore.sortedChaptersWithoutCover;

    return this.renderPage(
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Content" />}
          text={<FormattedMessage id="organizer content dashboard" />}
        />

        <GroupSelectComponent from="content" />

        <LinkBlock
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          to={ROUTE_PDF_PREVIEW}
        >
          <FormattedMessage id="Generate print PDF" />
        </LinkBlock>

        <Divider />

        <LinkBlock
          background="PRIMARY"
          color="WHITE"
          to={ROUTE_CONTENT + '/create'}
        >
          <FormattedMessage id="Add chapter" />
        </LinkBlock>

        {chapters.length > 1 && (
          <LinkBlock
            inline={true}
            slim={true}
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            to={ROUTE_CONTENT + '/sort'}
          >
            <IconComponent icon="sort_v" />
            <FormattedMessage id="Sorting order" />
          </LinkBlock>
        )}

        {!chapters.length ? (
          <EmptyStateComponent
            headerId="empty chapters header"
            textId="empty chapters"
          />
        ) : (
          <ItemStackBlock gap="S">
            <UppercaseHeading>
              <FormattedMessage
                id="{count} chapters"
                values={{ count: chapters.length }}
              />
            </UppercaseHeading>

            <ListStackBlock>
              {chapters.map((chapter) => (
                <OrganizerChapterListItem key={chapter.id} chapter={chapter} />
              ))}
            </ListStackBlock>
          </ItemStackBlock>
        )}
      </PageStackBlock>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <PageStackBlock>
        <Headline.Large>
          <FormattedMessage id="Content" />
        </Headline.Large>

        <GenericErrorComponent onRetryClick={() => this.load()} />
      </PageStackBlock>
    );
  }

  render() {
    const {
      applicationStore: { book },
      chaptersStore
    } = this.props;

    if (chaptersStore.isListLoading) {
      return this.renderLoading();
    }

    if (chaptersStore.isListError || !book) {
      return this.renderError();
    }

    if (this.isPreviewing(book)) {
      return null;
    }

    return this.renderChapters();
  }
}

export default OrganizerContentContainer;
