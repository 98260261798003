import React from 'react';

import { Boundary } from 'utils/chunker/ContentChunker';

export interface PrintPicture {
  url: string;
  fit?: boolean;
}

export interface PhotoPrintComponentProps {
  picture: PrintPicture;
  size: Boundary;
  fitSize?: Boundary;
  position?: Boundary;
  fitPosition?: Boundary;
}

// TODO make styled component from this?
const PicturePrintComponent = ({
  picture: { url, fit },
  size,
  fitSize,
  position,
  fitPosition,
}: PhotoPrintComponentProps) => {
  if (fit) {
    if (fitSize) {
      size = fitSize;
    }
    if (fitPosition) {
      position = fitPosition;
    }
  }

  const style: React.CSSProperties = {
    width: size[0] + 'px',
    height: size[1] + 'px',
  };

  if (position) {
    style.position = 'absolute';
    style.left = position[0] + 'px';
    style.top = position[1] + 'px';
  }

  if (fit) {
    style.objectFit = 'contain';
  }

  return <img src={url} alt="" style={style} draggable={false} />;
};

export default PicturePrintComponent;
