import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Headline from 'elements/Headline';
import SettingNotAvailableComponent from '../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from './SettingsDetailProps';
import { CHAPTER_TYPES_EXCLUDED_FROM_TOC } from 'models/ChapterModel';
import Paragraph from 'components/Paragraph/Paragraph';

const TYPES_EXCLUDED = CHAPTER_TYPES_EXCLUDED_FROM_TOC;

export default ({
  form,
  chapter,
  onCommitChapter
}: SimpleSettingsDetailProps) => {
  if (TYPES_EXCLUDED.indexOf(chapter.chapter_type) >= 0) {
    return <SettingNotAvailableComponent />;
  }

  return (
    <>
      <Headline.Large>
        <FormattedMessage id="Table of contents" />
      </Headline.Large>

      <ItemStackBlock gap="L">
        <Paragraph>
          <FormattedMessage id="toc settings info" />
        </Paragraph>

        <CustomCheckboxBlock>
          <CustomCheckboxBlock.CheckboxElement
            id="toc_checkbox"
            {...form.bindCheckbox('list_in_toc')}
          />
          <CustomCheckboxBlock.LabelElement htmlFor="toc_checkbox">
            <FormattedMessage id="List in TOC" />
          </CustomCheckboxBlock.LabelElement>
        </CustomCheckboxBlock>

        <ButtonBlock
          background="PRIMARY"
          onClick={() =>
            onCommitChapter({
              list_in_toc: form.values.list_in_toc ? true : false
            })
          }
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </ItemStackBlock>
    </>
  );
};
