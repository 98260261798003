import React, { ReactNode } from 'react';

import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import Headline from 'elements/Headline';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FormattedMessage } from 'react-intl';
import { CheckoutStoreType } from 'screens/checkout/CheckoutStore';
import CheckoutAddress from 'screens/checkout/screens/CheckoutAddress';
import { CheckoutBookPrice } from 'screens/checkout/screens/CheckoutBookPrice';
import CheckoutDeliveryDate from 'screens/checkout/screens/CheckoutDeliveryDate';
import CheckoutDetails from 'screens/checkout/screens/CheckoutDetails';
import CheckoutSummary from 'screens/checkout/screens/CheckoutSummary';
import { assert } from 'utils/assert';
import { ROUTE_CALCULATOR, ROUTE_ORDER } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import BackLink from 'domain/Links/BackLink';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

interface OrderScreenProps {
  applicationStore: ApplicationStoreType;
  checkoutStore: CheckoutStoreType;
}

/**
 * This Screen represents the last screen figma Order "Meine Bestellung"
 */
@inject('applicationStore', 'checkoutStore')
@observer
class OrderOverview extends React.Component<OrderScreenProps & HistoryProps> {
  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.getCheckout();
  }

  async getCheckout() {
    const { checkoutStore } = this.props;

    try {
      // This also gets the book
      await checkoutStore.getCheckout();
    } catch (error: any) {
      // No checkout
      this.props.history.push(ROUTE_CALCULATOR);
      throw error;
    }
  }

  renderContent(content: ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_ORDER} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  renderError() {
    return this.renderContent(
      <GenericErrorComponent onRetryClick={() => this.loadData()} />
    );
  }

  render() {
    const { applicationStore, checkoutStore } = this.props;
    const { book } = applicationStore;
    const { checkout, getLoadingState } = checkoutStore;

    if (getLoadingState === 'loading') {
      return this.renderContent(<LoadingIndicatorComponent />);
    }

    if (getLoadingState === 'error' || !checkout || !book) {
      return this.renderError();
    }

    assert(checkout.invoice);

    return this.renderContent(
      <>
        <Headline.Large>
          <FormattedMessage id="Order overview" />
        </Headline.Large>
        <Divider />

        <CheckoutDetails checkout={checkout} book={book} />
        <Divider />
        <CheckoutAddress checkout={checkout} />
        <Divider />
        <CheckoutDeliveryDate book={book} />
        <Divider />
        <CheckoutSummary checkout={checkout} book={book} />
        <Divider />
        <CheckoutBookPrice discount={0} endPrice={checkout.invoice.price} />
      </>
    );
  }
}
export default (props: any) => {
  return <OrderOverview {...props} />;
};
