import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import BackClickLink from 'domain/Links/BackClickLink';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';

const HowItWorksScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="How it works" />}
            text={<FormattedMessage id="how it works info" />}
          />
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (!textileAppState) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderStudent = () => {
    return renderPage(
      <IconListBlock>
        <IconListElement icon="TICK_CIRCLE" fill="GREEN">
          <ItemStackBlock gap="XS">
            <Paragraph>
              <FormattedMessage id="how it works student one" />
            </Paragraph>
            <TextElement>
              <FormattedMessage id="how it works student one info" />
            </TextElement>
          </ItemStackBlock>
        </IconListElement>
        <IconListDivider />
        <IconListElement icon="TICK_CIRCLE" fill="GREEN">
          <ItemStackBlock gap="XS">
            <Paragraph>
              <FormattedMessage id="how it works student two" />
            </Paragraph>
            <TextElement>
              <FormattedMessage id="how it works student two info" />
            </TextElement>
          </ItemStackBlock>
        </IconListElement>
        <IconListDivider />

        <IconListElement icon="TICK_CIRCLE" fill="GREEN">
          <ItemStackBlock gap="XS">
            <Paragraph>
              <FormattedMessage id="how it works student three" />
            </Paragraph>
            <TextElement>
              <FormattedMessage id="how it works student three info" />
            </TextElement>
          </ItemStackBlock>
        </IconListElement>
      </IconListBlock>
    );
  };

  const renderOrganizer = () => {
    return renderPage(
      <IconListBlock>
        <IconListElement icon="TICK_CIRCLE" fill="GREEN">
          <ItemStackBlock gap="XS">
            <Paragraph>
              <FormattedMessage id="how it works orga one" />
            </Paragraph>
            <TextElement>
              <FormattedMessage id="how it works orga one info" />
            </TextElement>
          </ItemStackBlock>
        </IconListElement>
        <IconListDivider />
        <IconListElement icon="TICK_CIRCLE" fill="GREEN">
          <ItemStackBlock gap="XS">
            <Paragraph>
              <FormattedMessage id="how it works orga two" />
            </Paragraph>
            <TextElement>
              <FormattedMessage id="how it works orga two info" />
            </TextElement>
          </ItemStackBlock>
        </IconListElement>
        <IconListDivider />

        <IconListElement icon="TICK_CIRCLE" fill="GREEN">
          <ItemStackBlock gap="XS">
            <Paragraph>
              <FormattedMessage id="how it works orga three" />
            </Paragraph>
            <TextElement>
              <FormattedMessage id="how it works orga three info" />
            </TextElement>
          </ItemStackBlock>
        </IconListElement>
      </IconListBlock>
    );
  };

  if (textileAppState.textile_profile.is_organizer) {
    return renderOrganizer();
  } else {
    return renderStudent();
  }
};

export default HowItWorksScreen;
