import React, { JSXElementConstructor, MouseEvent, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import UppercaseHeading from 'elements/UppercaseHeading';

import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import { useTextileGroupsQuery } from 'queries/textile_deals/useTextileGroupsQueries';
import { HistoryProps } from 'utils/history';
import TextileGroupsListItem, {
  TextileGroupsListItemProps
} from './TextileGroupListItem';

export type TextileGroupsListItemComponentType = JSXElementConstructor<
  TextileGroupsListItemProps
>;
export type NoGroupsComponentType = JSXElementConstructor<{}> | null;

interface PublicTextileGroupsListProps {
  allowAdd?: boolean;
  indicateUpdating?: boolean;
  itemComponent?: TextileGroupsListItemComponentType;
  noGroupsComponent?: NoGroupsComponentType;
  selected?: number | number[];
  ignoreGroupIds?: number[];
  showGroupsCount?: boolean;
  onGroupClick?: (e: MouseEvent, groupId: number, name?: string) => void;
  textileOrderId?: number
}

const TextileGroupsList: React.FC<
  PublicTextileGroupsListProps & HistoryProps
> = (props) => {
  const textileGroups = useTextileGroupsQuery(props.textileOrderId);

  const accessContext = useContext(HasAccessContext);
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('design');
  }, [accessContext]);

  if (textileGroups.isError) {
    return <GenericErrorComponent onRetryClick={() => textileGroups.refetch()} />
  }

  if (textileGroups.isLoading || !textileGroups.data) {
    return <LoadingOverlayComponent />
  }

  const renderListContainer = (list: any) => {
    if (!props.allowAdd) {
      return list;
    }

    return <ItemStackBlock gap="L">{list}</ItemStackBlock>;
  }

  const renderList = () => {
    const {
      onGroupClick,
      itemComponent,
      ignoreGroupIds,
      showGroupsCount,
      selected,
    } = props;


    const selectedIds = !selected
      ? []
      : Array.isArray(selected)
        ? selected
        : [selected];

    let groups = textileGroups.data;

    if (ignoreGroupIds?.length) {
      groups = groups.filter((group) => ignoreGroupIds.indexOf(group.id) < 0);
    }

    if (!groups.length) {
      return renderListContainer(
        <Paragraph>
          <FormattedMessage id="no groups" />
        </Paragraph>
      );
    }

    const Item = !itemComponent ? TextileGroupsListItem : itemComponent;

    return renderListContainer(
      <>
        <ItemStackBlock gap="XXS">
          {showGroupsCount && (
            <UppercaseHeading>
              <FormattedMessage
                id="Groups count"
                values={{
                  count: groups.length
                }}
              />
            </UppercaseHeading>
          )}

          <ListStackBlock>
            {groups.map((group) => (
              <Item
                key={group.id}
                group={group}
                selected={selectedIds.includes(group.id)}
                onClick={onGroupClick}
              />
            ))}
          </ListStackBlock>
        </ItemStackBlock>
      </>
    );
  }

  return renderList();
};

export default (props: PublicTextileGroupsListProps) => {
  // @ts-ignore
  return <TextileGroupsList {...props} />;
};
