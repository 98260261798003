import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import LogoutButton from 'components/LogoutButton/LogoutButton';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import NakedBlockButton from 'elements/NakedBlockButton';
import NakedLink from 'elements/NakedLink';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { ROUTE_AUTH, ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';

interface MyAccountScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
}

interface MyAccountScreenState {
  confirmRoleChange?: boolean;
  requestRoleLoadingState?: 'loading' | 'sent';
}

@inject('applicationStore', 'profilesStore')
@observer
class MyAccountScreen extends React.Component<
  MyAccountScreenProps & HistoryProps & WrappedComponentProps,
  MyAccountScreenState
> {
  state: MyAccountScreenState = {};

  componentDidMount() {
    const { applicationStore } = this.props;

    // if (applicationStore.isOrganizer && applicationStore.currentUserId) {
    //   this.props.history.replace(
    //     ROUTE_SETTINGS + '/students/' + applicationStore.currentUserId
    //     // + '?my=yes'
    //   );
    //   return;
    // }

    if (!applicationStore.isRefreshing && isPush(this.props.history)) {
      applicationStore.refreshSession();
    }
  }

  private async requestOrganizerRole() {
    const {
      profilesStore,
      applicationStore,
      intl: { formatMessage }
    } = this.props;

    this.setState({
      requestRoleLoadingState: 'loading',
      confirmRoleChange: undefined
    });

    try {
      await profilesStore!.requestOrganizerRole();

      applicationStore!.setFlashMessage(
        formatMessage({ id: 'role change requested flash' }),
        'success'
      );

      this.setState({
        requestRoleLoadingState: 'sent'
      });
    } catch (error: any) {
      applicationStore!.setFlashMessage(
        formatMessage({ id: 'role change request error flash' }),
        'error'
      );

      this.setState({
        requestRoleLoadingState: undefined
      });
    }
  }

  private renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="My account" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  private renderView() {
    const { applicationStore } = this.props;
    const { currentUser, isOrganizer } = applicationStore;
    const { requestRoleLoadingState } = this.state;

    if (!currentUser) {
      // satisfy TS
      return this.renderPage(null);
    }

    const isLocked = !!applicationStore.book?.isLockedForPreview;

    return this.renderPage(
      <>
        <ListStackBlock>
          <ButtonListBlock>
            {!isLocked && (
              <ButtonListBlockItem>
                <NakedLink to={ROUTE_SETTINGS + '/account/name'}>
                  <ButtonListBlockBody>
                    {!currentUser.name ? (
                      <FormattedMessage id="Set name" />
                    ) : (
                      <FormattedMessage id="Edit name" />
                    )}
                  </ButtonListBlockBody>
                </NakedLink>
              </ButtonListBlockItem>
            )}

            {!isOrganizer &&
              !currentUser.has_role_change_request &&
              requestRoleLoadingState !== 'sent' && (
                <ButtonListBlockItem>
                  <NakedBlockButton
                    onClick={() => this.setState({ confirmRoleChange: true })}
                  >
                    <ButtonListBlockBody>
                      <FormattedMessage id="request organizer role" />
                    </ButtonListBlockBody>
                  </NakedBlockButton>
                </ButtonListBlockItem>
              )}

            {!isLocked && applicationStore.book?.groups_applied ? (
              <ButtonListBlockItem>
                <NakedLink to={ROUTE_SETTINGS + '/account/group'}>
                  <ButtonListBlockBody>
                    {!currentUser.group ? (
                      <FormattedMessage id="Assign group" />
                    ) : (
                      <FormattedMessage id="Edit group" />
                    )}
                  </ButtonListBlockBody>
                </NakedLink>
              </ButtonListBlockItem>
            ) : null}

            <ButtonListBlockItem>
              <NakedLink to={ROUTE_AUTH + '/account/mobile_number'}>
                <ButtonListBlockBody>
                  {!currentUser.mobile_number ? (
                    <FormattedMessage id="Set mobile number" />
                  ) : (
                    <FormattedMessage id="Edit mobile number" />
                  )}
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            <ButtonListBlockItem>
              <NakedLink to={ROUTE_AUTH + '/account/password'}>
                <ButtonListBlockBody>
                  <FormattedMessage id="Change password" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>

            <ButtonListBlockItem>
              <NakedLink to={ROUTE_AUTH + '/account'}>
                <ButtonListBlockBody>
                  <FormattedMessage id="Account dashboard" />
                </ButtonListBlockBody>
              </NakedLink>
            </ButtonListBlockItem>
          </ButtonListBlock>
        </ListStackBlock>

        <ItemStackBlock gap="XS" marginTop="XL">
          <LogoutButton />
        </ItemStackBlock>

        {this.state.confirmRoleChange && (
          <ConfirmBoxComponent
            header={
              <FormattedMessage id="confirm role change request header" />
            }
            text={<FormattedMessage id="confirm role change request" />}
            confirmColor="GREEN"
            confirmText={
              <FormattedMessage id="send confirm role change request" />
            }
            onConfirm={() => this.requestOrganizerRole()}
            abortText={<FormattedMessage id="Cancel" />}
            onAbort={() => this.setState({ confirmRoleChange: undefined })}
          />
        )}
        {requestRoleLoadingState === 'loading' && <LoadingOverlayComponent />}
      </>
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent
        onRetryClick={() => this.props.applicationStore.refreshSession()}
      />
    );
  }

  render() {
    const { applicationStore } = this.props;

    if (applicationStore.isRefreshError) {
      return this.renderError();
    }

    if (applicationStore.isRefreshing) {
      return this.renderLoading();
    }

    return this.renderView();
  }
}

export default injectIntl(MyAccountScreen);
