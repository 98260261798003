import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BookModelType } from 'models/BookModel';
import MiniCardComponent from 'components/MiniCardComponent';
import { ROUTE_MOTTOS, ROUTE_MOTTOS_ORG } from 'utils/constants/routes';

export const MottoCardComponent = ({
  book,
  votesCount,
  isOrganizer,
  hasMottoVote,
  onboardingState
}: {
  book: BookModelType;
  votesCount?: number;
  hasMottoVote?: boolean;
  isOrganizer?: boolean;
  onboardingState?: string;
}) => {
  const hasMotto = !!book.motto;
  const isVotingEnabled = !!book.allow_motto_vote;

  if (!isOrganizer) {
    if (!hasMotto && !isVotingEnabled) {
      return null;
    }

    return (
      <MiniCardComponent
        mode={hasMotto ? 'hint' : 'default'}
        disabled={hasMotto}
        icon={hasMotto ? 'SPEECHBUBBLE' : 'MOTTO'}
        title={
          hasMotto ? (
            <FormattedMessage id="Motto" />
          ) : (
            <FormattedMessage id="Motto voting" />
          )
        }
        label={
          hasMotto ? (
            book.motto!.name
          ) : hasMottoVote ? (
            <FormattedMessage id="You voted" />
          ) : undefined
        }
        button={!hasMottoVote && <FormattedMessage id="Vote now" />}
        linkTo={ROUTE_MOTTOS}
      />
    );
  }

  const isNew = !onboardingState && !hasMotto && !isVotingEnabled;
  const hasButton = isNew || (!hasMotto && !isVotingEnabled);

  return (
    <MiniCardComponent
      mode={hasMotto ? 'hint' : isNew ? 'new' : 'default'}
      icon={hasMotto ? 'SPEECHBUBBLE' : 'MOTTO'}
      title={<FormattedMessage id="Motto" />}
      label={
        hasMotto ? (
          book.motto!.name
        ) : isVotingEnabled ? (
          <FormattedMessage
            id="{count} votes placed"
            values={{ count: votesCount || 0 }}
          />
        ) : (
          <FormattedMessage id="Set motto together" />
        )
      }
      button={hasButton && <FormattedMessage id="Start" />}
      linkTo={ROUTE_MOTTOS_ORG}
    />
  );
};

export default MottoCardComponent;
