import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface WideBlockProps {
  background?: keyof typeof COLORS;
  padded?: boolean;
}

const WideBlock = styled.div<WideBlockProps>`
  margin: 0 -1.25em;
  width: calc(100% + 2.5em);
  background-color: ${COLORS.GRAY980};
  ${(props) => (props.padded ? 'padding: 2.5em 1.25em;' : '')}
`;

export default WideBlock;
