import React from 'react';
import styled from 'styled-components';

const ImprintNuggit = styled.div`
  position: absolute;
  left: 92px;
  top: 660px;
`;

const ImprintSchool = styled.div`
  position: absolute;
  left: 251px;
  top: 660px;
`;

const ImprintHeader = styled.div`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #313131;
`;

const ImprintText = styled.div`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #313131;
`;

export const ImprintComponent = ({
  school,
  year,
  bookId
}: {
  school?: any;
  year?: any;
  bookId?: any;
}) => {
  return (
    <>
      <ImprintNuggit>
        <ImprintHeader>Gedruckt durch:</ImprintHeader>
        <ImprintText>
          nuggit GmbH
          <br />
          Schlesische Str. 28
          <br />
          10997 Berlin
          <br />
          Deutschland

          {bookId && <>
            <br /><br />
            {bookId}
          </>}
        </ImprintText>
      </ImprintNuggit>

      {school && (
        <ImprintSchool>
          <ImprintHeader>Verfasst von:</ImprintHeader>
          <ImprintText>
            {year ? (
              <>
                Jahrgang {year}
                <br />
              </>
            ) : null}
            {school}
          </ImprintText>
        </ImprintSchool>
      )}
    </>
  );
};

export default ImprintComponent;
