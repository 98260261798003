import { QueryCache, QueryClient } from "@tanstack/react-query";
import { History } from "history";


const useCustomQueryClient = (history: History<unknown> | string[]) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        // @ts-ignore
        if (error.response?.status === 401) {
          history.push('/login');
        }
      },
    }),
  });

  return queryClient;
};

export default useCustomQueryClient;
