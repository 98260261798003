import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import TeamCallContainer from 'containers/TeamCallContainer/TeamCallContainer';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { FactsheetsStoreType } from 'screens/factsheets/FactsheetsStore';
import { assert } from 'utils/assert';
import { ROUTE_CONTENT, ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

import StudentsWithoutGroup from '../../../settings/screens/StudentsWithoutGroup';
import TextElement from 'components/TextElement/TextElement';

interface Props {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  profilesStore: ProfilesStoreType;
  factsheetsStore: FactsheetsStoreType;
}

@inject('applicationStore', 'chaptersStore', 'profilesStore', 'factsheetsStore')
@observer
class SplitFactsheetChapterScreen extends React.Component<
  Props & HistoryProps
> {
  async componentDidMount() {
    const {
      applicationStore: { isOrganizer, book }
    } = this.props;
    assert(book);
    const groupsApplied = book.groups_applied;

    if (!(isOrganizer && groupsApplied)) {
      this.props.history.push(ROUTE_CONTENT);
    }

    await this.loadChapter();
    const settings = this.getChapterSettings();
    if (settings && !settings.allow_split_factsheet) {
      // Make the first API call that requests the split
      this.split();
    } else {
      this.loadStudents();
    }
  }

  loadStudents() {
    this.props.profilesStore.getStudents();
  }

  loadChapter() {
    const chapterId = this.getChapterId();
    assert(chapterId);

    return this.props.chaptersStore.getChapter(chapterId);
  }

  getChapterId() {
    return chapterIdFromMatch(this.props.match);
  }

  getChapter() {
    return this.props.chaptersStore.item;
  }

  getChapterSettings() {
    const settings = this.props.chaptersStore.item?.settings;
    return settings && 'allow_split_factsheet' in settings
      ? settings
      : undefined;
  }

  async split() {
    const id = this.getChapterId();
    assert(id);
    await this.props.factsheetsStore.splitFactsheetChapter(id);
  }

  /**
   * The second split API call that actually performs the split.
   */
  async splitSecondCall() {
    await this.split();
    this.props.applicationStore.setDefaultFlashMessage('saved');
    this.props.history.push(ROUTE_CONTENT);
  }

  backToChapter() {
    this.props.history.push(this.getChapterRoute());
  }

  getChapterRoute() {
    const id = this.getChapterId();
    assert(id);
    return ROUTE_FACTSHEETS + '/' + id;
  }

  renderBackToChapter() {
    return (
      <TopActionBlock>
        <Link to={this.getChapterRoute()}>
          <TextElement  color="GRAY10">
            <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
            <FormattedMessage id="Back" />
          </TextElement>
        </Link>
      </TopActionBlock>
    );
  }

  renderError(onRetryClick: () => void) {
    return (
      <>
        {this.renderBackToChapter()}
        <GenericErrorComponent onRetryClick={onRetryClick} />
      </>
    );
  }

  render() {
    const {
      chaptersStore: { itemLoadingState: chapterLoadingState },
      factsheetsStore: { splitChapterLoadingState: splitFactsheetChapterLoadingState },
      profilesStore: { isStudentsLoading, isStudentsError }
    } = this.props;

    // Loading states
    if (
      chapterLoadingState === 'loading' ||
      isStudentsLoading ||
      splitFactsheetChapterLoadingState === 'loading'
    ) {
      return <LoadingOverlayComponent />;
    }

    // Error states
    if (chapterLoadingState === 'error') {
      return this.renderError(() => this.loadChapter());
    }
    if (isStudentsError) {
      return this.renderError(() => this.loadStudents());
    }
    if (splitFactsheetChapterLoadingState === 'error') {
      return this.renderError(() => this.split());
    }

    const settings = this.getChapterSettings();
    if (!settings) {
      return null;
    }

    if (settings.allow_split_factsheet) {
      // Students without groups
      const { studentsWithoutGroup } = this.props.profilesStore;
      if (studentsWithoutGroup.length > 0) {
        // Render student list
        return (
          <>
            {this.renderBackToChapter()}
            <StudentsWithoutGroup students={studentsWithoutGroup} />
          </>
        );
      } else {
        // Render confirm
        return (
          <ConfirmBoxComponent
            header={<FormattedMessage id="chapter split confirm header" />}
            text={<FormattedMessage id="chapter split confirm text" />}
            confirmText={<FormattedMessage id="chapter split button" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => this.splitSecondCall()}
            onAbort={() => this.backToChapter()}
          />
        );
      }
    } else {
      // Render confirmation that the split has been requested
      return <TeamCallContainer backTo={this.getChapterRoute()} />;
    }
  }
}

export default SplitFactsheetChapterScreen;
