import React from 'react';
import { FormattedMessage } from 'react-intl';

import AlbumPreviewComponent from 'components/print/layout-editor/AlbumPreviewComponent';
import ProfilePreviewComponent from 'components/print/layout-editor/ProfilePreviewComponent';
import QuotesPreviewComponent from 'components/print/layout-editor/QuotesPreviewComponent';
import RankingsPreviewComponent from 'components/print/layout-editor/RankingsPreviewComponent';
import TextPreviewComponent from 'components/print/layout-editor/TextPreviewComponent';
import TocPreviewComponent from 'components/print/layout-editor/TocPreviewComponent';
import YearbookPreviewComponent from 'components/print/layout-editor/YearbookPreviewComponent';
import ScaledChapterPreviewComponent from 'components/print/structure/ScaledChapterPreviewComponent';
import { ChapterModelType } from 'models/ChapterModel';
import { ROUTE_AUTH } from 'utils/constants/routes';

export default ({
  chapter,
  withoutPreview
}: {
  chapter: ChapterModelType;
  withoutPreview?: boolean;
}) => {
  // let stack = true;
  let link;
  let preview;
  let image = 'albums';

  const layout = chapter.layout?.layoutDefinition;

  switch (chapter.chapter_type) {
    case 'table_of_contents':
      link = 'toc';
      image = 'toc';
      // stack = false;
      preview = <TocPreviewComponent firstPageOnly={true} chapter={chapter} />;
      break;

    case 'quote':
      link = 'quotes';
      image = 'quotes';
      preview = layout && (
        <QuotesPreviewComponent
          firstPageOnly={true}
          config={layout}
          chapter={chapter}
        />
      );
      break;

    case 'factsheet':
    case 'teacher_factsheet':
      link = 'factsheets';
      image = 'factsheets';
      preview = layout && (
        <ProfilePreviewComponent
          firstPageOnly={true}
          config={layout}
          chapter={chapter}
        />
      );
      break;

    case 'yearbook':
      link = 'yearbook';
      // image = 'yearbook';
      preview = layout && (
        <YearbookPreviewComponent
          firstPageOnly={true}
          config={layout}
          chapter={chapter}
        />
      );
      break;

    case 'album':
      link = 'albums';
      image = 'albums';
      preview = (
        <AlbumPreviewComponent
          firstPageOnly={true}
          config={layout}
          chapter={chapter}
        />
      );
      break;

    case 'text':
      link = 'texts';
      image = 'texts';
      preview = (
        <TextPreviewComponent
          firstPageOnly={true}
          config={layout}
          chapter={chapter}
        />
      );
      break;

    case 'sponsor':
      link = 'sponsors';
      image = 'sponsors';
      break;

    case 'ranking':
      link = 'rankings';
      image = 'rankings';
      preview = layout && (
        <RankingsPreviewComponent
          firstPageOnly={true}
          config={layout}
          chapter={chapter}
        />
      );
      break;

    default:
  }

  return (
    <ScaledChapterPreviewComponent
      border={true}
      stack={false}
      linkTo={
        !link
          ? undefined
          : ROUTE_AUTH +
            '/book/' +
            link +
            '/' +
            chapter.id +
            '/settings?active=layout&from=layouts'
      }
      pageTitle={
        chapter.title || (
          <FormattedMessage id={'chapter type ' + chapter.chapter_type} />
        )
      }
      // description="27 Seiten"
    >
      {!withoutPreview && preview ? (
        preview
      ) : (
        <img
          src={'/images/features/placeholder/' + image + '.jpg'}
          alt=""
          style={{ width: '840px', height: '1188px' }}
        />
      )}
    </ScaledChapterPreviewComponent>
  );
};
