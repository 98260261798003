import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import CopyTextInput from 'components/Inputs/CopyTextInput';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { BookModelType } from 'models/BookModel';
import PageHeader from 'components/PageHeader/PageHeader';
import Paragraph from 'components/Paragraph/Paragraph';

export default ({
  book,
  header,
  text,
  label,
  onlyForm,
  mediumHeadline
}: {
  book?: BookModelType;
  header?: string;
  text?: string;
  label?: string;
  onlyForm?: boolean;
  mediumHeadline?: boolean;
}) => {
  if (!book || !book.invitation_url) {
    return null;
  }

  const invite = () => {
    const link =
      // 'https://wa.me/?text=' +  // wtf? in Chrome this url only works in incognito mode!?
      'https://api.whatsapp.com/send?phone=&text=' +
      encodeURIComponent(
        intl.formatMessage(
          { id: 'invite message' },
          { link: book.invitation_url }
        )
      );

    window.open(link);
  };

  const HeadlineComp = mediumHeadline ? Headline.Medium : Headline.Large;
  return (
    <>
      <ItemStackBlock gap="M">
        {!onlyForm && (
          <PageHeader headline={<HeadlineComp><FormattedMessage id={header ? header : 'invite header'} /></HeadlineComp>} text={<FormattedMessage id={text ? text : 'invite text'} />}/>
        )}

        <ItemStackBlock gap="XS">
          <Paragraph>
            <FormattedMessage id={label ? label : 'invite link'} />
          </Paragraph>

          <CopyTextInput name="invite-link" value={book.invitation_url} />

          <ButtonBlock background="WHATSAPP" onClick={() => invite()}>
            <FormattedMessage id="invite whatsapp" />
          </ButtonBlock>
        </ItemStackBlock>
      </ItemStackBlock>
    </>
  );
};
