import { SwapResponse } from 'api/NuggitApi';
import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import ChapterModel, { createChapterModel } from 'models/ChapterModel';
import { PlaceholderSponsorPageModelType } from 'models/PlaceholderSponsorPageModel';
import { SponsorPageModelType } from 'models/SponsorPageModel';
import SponsorPageSortingModel, { createSponsorSortingPage, SponsorPageSortingModelType } from 'models/SponsorPageSortingModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { assert } from 'utils/assert';
import { createArrayWithTransform } from 'utils/create-map';
import { sortByField } from 'utils/sort-functions';

export enum LoadingState {
  INITIAL = 'initial',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error'
}

const SponsorPageSortingStore = types
  .model('SponsorPageSortingStore', {
    // List of sponsor pages
    sortedPages: types.maybe(types.array(SponsorPageSortingModel)),
    sortedPageLoadingState: types.optional(
      types.enumeration(Object.values(LoadingState)),
      LoadingState.INITIAL
    ),
    chapter: types.maybe(ChapterModel)
  })
  .actions((self) => {
    const getSponsorPageSorting = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.sortedPageLoadingState = LoadingState.LOADING;
        self.sortedPages = undefined;

        const result = yield client.getSponsorPageSortings(chapterId);

        self.sortedPages = createArrayWithTransform(
          result.sponsor_page_sortings,
          createSponsorSortingPage
        );
        self.chapter = createChapterModel(result.chapter || { id: chapterId });

        self.sortedPageLoadingState = LoadingState.LOADED;

        return self.sortedPages;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'SponsorPageSortingStore | getSponsorPageSorting',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.sortedPageLoadingState = LoadingState.INITIAL;
        } else {
          self.sortedPageLoadingState = LoadingState.ERROR;
        }
        throw error;
      }
    });

    const swapSponsorPageSorting = flow(function* (id: number, swapId: number) {
      const { client } = getEnv<AdvancedStoreEnv>(self);
      const { sortedPages } = self;
      assert(sortedPages, 'No SponsorPageSorting pages loaded');

      try {
        self.sortedPageLoadingState = LoadingState.LOADING;

        const swap: SwapResponse = yield client.swapSponsorPageSorting(
          id,
          swapId
        );

        const firstElement = sortedPages.find(
          (candidate) => candidate.id === swap.first_element.id
        );
        if (firstElement) {
          firstElement.sorting = swap.first_element.sorting;
        }

        const secondElement = sortedPages.find(
          (candidate) => candidate.id === swap.second_element.id
        );
        if (secondElement) {
          secondElement.sorting = swap.second_element.sorting;
        }

        self.sortedPageLoadingState = LoadingState.LOADED;
      } catch (error: any) {
        handleSponsorPageError('swapSponsorPageSorting', error);
      }
    });

    /**
     * Handles a sponsor page error
     */
    const handleSponsorPageError = (methodName: string, error: any) => {
      const { applicationStore } = getEnv<AdvancedStoreEnv>(self);

      if (process.env.NODE_ENV !== 'production') {
        console.error(`SponsorPageSortingStore | ${methodName}`, error, error.body);
      }

      if (applicationStore.handleAppError(error)) {
        self.sortedPageLoadingState = LoadingState.INITIAL;
      } else {
        self.sortedPageLoadingState = LoadingState.ERROR;
      }
      throw error;
    };

    return {
      getSponsorPageSorting,
      swapSponsorPageSorting
    };
  })
  .views((self) => {
    return {
      get sponsorPageSortingsAreLoading() {
        return self.sortedPageLoadingState === LoadingState.LOADING;
      },
      get sponsorPageSortingsAreError() {
        return self.sortedPageLoadingState === LoadingState.ERROR;
      },
      get placeholderSponsors(): PlaceholderSponsorPageModelType[] {
        const placeholderSponsors: PlaceholderSponsorPageModelType[] = [];

        if (!self.sortedPages || self.sortedPages.length === 0) {
          return placeholderSponsors;
        }

        for( const page of self.sortedPages) {
          if (page.placeholder_sponsor_page) {
            placeholderSponsors.push(page.placeholder_sponsor_page);
          }
        }

        return placeholderSponsors;
      },
      get sponsorPages(): SponsorPageModelType[] {
        const sponsorPages: SponsorPageModelType[] = [];

        if (!self.sortedPages || self.sortedPages.length === 0) {
          return sponsorPages;
        }

        for( const page of self.sortedPages) {
          if (page.sponsor_page) {
            sponsorPages.push(page.sponsor_page);
          }
        }

        return sponsorPages;
      },
      get allSortedPages(): SponsorPageSortingModelType[] {
        const sortedPages: SponsorPageSortingModelType[] = [];

        if (!self.sortedPages || self.sortedPages.length === 0) {
          return sortedPages;
        }

        for( const page of self.sortedPages) {
          sortedPages.push(page);
        }

        return sortedPages
          ? [...sortedPages].sort(sortByField('sorting'))
          : [];
      }
    };
  });

export type SponsorPageSortingStoreType = Instance<typeof SponsorPageSortingStore>;
export default SponsorPageSortingStore;
