import React from 'react';

import TextInputComponent from 'components/Inputs/TextInputComponent';
import { intl } from 'i18n';
import { FormType } from 'utils/hooks/useForm';

const SearchListInput = ({
  form,
  placeholderId
}: {
  form: FormType;
  placeholderId: string;
}) => (
  <TextInputComponent
    name="filter"
    placeholder={intl.formatMessage({ id: placeholderId })}
    icon={form.values.filter ? 'CROSS' : 'MAGNIFY'}
    iconFill="TEXT_LIGHT"
    onIconClick={
      form.values.filter ? () => form.setField('filter', '') : undefined
    }
    {...form.bindInput('filter')}
  />
);

export default SearchListInput;
