import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import {
  ROUTE_COMMENTS,
  ROUTE_DASHBOARD,
  ROUTE_FACTSHEETS,
  ROUTE_YEARBOOKS
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

import Divider from 'components/Divider/Divider';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import GroupSelectComponent from '../containers/GroupSelectComponent';
import StudentChapterListItem from './StudentChapterListItem';

interface StudentContentContainerProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class StudentContentContainer extends React.Component<
StudentContentContainerProps & HistoryProps
> {
  componentDidMount() {
    this.load();
  }

  load() {
    if (this.props.applicationStore.isApplicant) {
      return;
    }

    this.props.chaptersStore.getContentDashboard();
  }

  private renderFactsheet() {
    const { chaptersStore } = this.props;

    const chapter = chaptersStore.firstFactsheetChapter;
    const settings:
      | FactsheetChapterSettingsModelType
      | undefined = chapter?.settings as
      | FactsheetChapterSettingsModelType
      | undefined;
    const { contentDashboard: dashboard } = chaptersStore;

    if (!chapter || !dashboard || !settings || chapter.editing_state !== 'active') {
      return null;
    }

    // const textDone = !settings.text_on || dashboard.has_text;

    // const questionsOn = !!settings.questions_on;
    const questionsCount = dashboard.questions_count || 0;
    const answersCount = !dashboard.answers_count
      ? 0
      : dashboard.answers_count > questionsCount
        ? questionsCount
        : dashboard.answers_count;

    // const questionsDone =
    //   !questionsOn ||
    //   (dashboard.answers_count || 0) >= (dashboard.questions_count || 0);

    const photoCount = settings.photo_count || 0;
    let photosDone = true;
    if (photoCount > 0 && !dashboard.has_slot1_photo) {
      photosDone = false;
    }
    if (photosDone && photoCount > 1 && !dashboard.has_slot2_photo) {
      photosDone = false;
    }
    if (photosDone && photoCount > 2 && !dashboard.has_slot3_photo) {
      photosDone = false;
    }
    if (photosDone && settings.creative_page && !dashboard.has_creative_page) {
      photosDone = false;
    }

    // const done = textDone && questionsDone && photosDone;

    return (
      <>
        <Link to={ROUTE_FACTSHEETS}>
          <EveryCard>
            <EveryCardPadding>
              <IconComponent
                icon="chapter_factsheets"
                circleBackground="PRIMARY_LIGHT"
                fill="PRIMARY"
                size={3}
              />

              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="Your factsheet" />
                </EveryCardHeadline>

                <TextElement  color="TEXT_DARK">
                  <FormattedMessage
                    id="factsheets questions filled count"
                    values={{
                      count: questionsCount,
                      filledCount: answersCount
                    }}
                  />
                </TextElement>
              </EveryCardBody>
            </EveryCardPadding>

            {/* done ? null : (
              <EveryCardFooter>
                <ListStackBlock>
                  {!questionsDone && (
                    <TextElement  color="TEXT_DARK">
                      <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />
                      <FormattedMessage id="answer all questions" />
                    </TextElement>
                  )}
                  {!photosDone && (
                    <TextElement  color="TEXT_DARK">
                      <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />
                      <FormattedMessage id="add all photos" />
                    </TextElement>
                  )}
                  {!textDone && (
                    <TextElement  color="TEXT_DARK">
                      <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />
                      <FormattedMessage id="add text" />
                    </TextElement>
                  )}
                </ListStackBlock>
              </EveryCardFooter>
            ) */}
          </EveryCard>
        </Link>
      </>
    );
  }

  private renderComments() {
    const { contentDashboard: dashboard } = this.props.chaptersStore;

    if (!dashboard) {
      return null;
    }

    return (
      <Link to={ROUTE_COMMENTS}>
        <EveryCard>
          <EveryCardPadding>
            <IconComponent
              icon="comments"
              circleBackground="PRIMARY_LIGHT"
              fill="PRIMARY"
              size={3}
            />

            <EveryCardBody>
              <EveryCardHeadline>
                <FormattedMessage id="Write comments" />
              </EveryCardHeadline>

              <TextElement  color="TEXT_DARK">
                <FormattedMessage
                  id="comments stats"
                  values={{
                    count_added: dashboard.comments_as_author_count || 0,
                    count_received: dashboard.comments_count || 0
                  }}
                />
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
      </Link>
    );
  }

  private renderYearbook() {
    const { chaptersStore } = this.props;
    const { contentDashboard: dashboard } = chaptersStore;

    const chapter = chaptersStore.firstYearbookChapter;

    if (!chapter || !dashboard || chapter.editing_state !== 'active') {
      return null;
    }

    return (
      <Link to={ROUTE_YEARBOOKS}>
        <EveryCard>
          <EveryCardPadding>
            <IconComponent
              icon="chapter_yearbook"
              circleBackground="PRIMARY_LIGHT"
              fill="PRIMARY"
              size={3}
            />

            <EveryCardBody>
              <EveryCardHeadline>
                <FormattedMessage id="Yearbook" />
              </EveryCardHeadline>

              <TextElement  color="TEXT_DARK">
                {!dashboard.has_yearbook_photo ? (
                  <FormattedMessage id="yearbook add photo" />
                ) : !dashboard.has_yearbook_text ? (
                  <FormattedMessage id="yearbook add text" />
                ) : (
                  <FormattedMessage id="yearbook complete" />
                )}
              </TextElement>
            </EveryCardBody>
          </EveryCardPadding>
        </EveryCard>
      </Link>
    );
  }

  private renderChapters() {
    const chapters = this.props.chaptersStore.studentChapters;
    const factsheet = this.renderFactsheet();
    const yearbook = this.renderYearbook();

    if (!chapters.length && !factsheet && !yearbook) {
      return null;
    }

    let chapterCount = chapters.length;

    if (factsheet) {
      chapterCount++;
    }

    if (yearbook) {
      chapterCount++;
    }

    return (
      <ItemStackBlock gap="S">
        <UppercaseHeading>
          <FormattedMessage
            id="{count} chapters"
            values={{
              count: chapterCount
            }}
          />
        </UppercaseHeading>

        <ListStackBlock>
          {factsheet}
          {yearbook}
          {chapters.map((chapter) => (
            <StudentChapterListItem key={chapter.id} chapter={chapter} />
          ))}
        </ListStackBlock>
      </ItemStackBlock>
    );
  }

  private renderDashboard() {
    const chapters = this.renderChapters();

    return this.renderPage(
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Content" />}
          text={<FormattedMessage id="dashboard feature info student" />}
        />

        <GroupSelectComponent from="content" />

        {!chapters && (
          <Paragraph>
            <FormattedMessage id="dashboard feature info none" />
          </Paragraph>
        )}

        {this.renderComments()}

        <Divider />

        {chapters}
      </PageStackBlock>
    );
  }

  private renderClosed() {
    // TODO new design
    return this.renderPage(
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Content" />}
          text={<FormattedMessage id="dashboard feature info locked student" />}
        />

        <ButtonBlock
          color="WHITE"
          background="PRIMARY"
          onClick={() => this.props.history.push(ROUTE_DASHBOARD)}
        >
          <FormattedMessage id="OK" />
        </ButtonBlock>
      </PageStackBlock>
    );
  }

  private renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.load()} />
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  private renderPage(content: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>

        {content}

        <HelpSubject subject="content" />
      </>
    );
  }

  render() {
    const { chaptersStore } = this.props;

    if (chaptersStore.isListLoading) {
      return this.renderLoading();
    }

    if (chaptersStore.isListError) {
      return this.renderError();
    }

    const { book } = this.props.applicationStore;

    switch (book?.editing_state) {
      case 'active':
        return this.renderDashboard();

      default:
        return this.renderClosed();
    }
  }
}

export default StudentContentContainer;
