import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Headline from 'elements/Headline';
import { FormType } from 'utils/hooks/useForm';

import Checkboxes from '../Checkboxes';
import NextButton from '../NextButton';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

const RECIPIENTS = [
  'Absolventen',
  'Lehrer',
  'Verwandte',
  'Jüngere Mitschüler',
  'Andere'
];

const BookRecipients = ({
  form,
  errorMessages,
  onSubmit
}: {
  form: FormType;
  errorMessages: ReactNode,
  onSubmit: () => void;
}) => (
  <PageStackBlock>
    <Headline.Large>
      <FormattedMessage id="funnel book recipients" />
    </Headline.Large>

    <Checkboxes
      form={form}
      options={RECIPIENTS}
      name="recipients"
      translate={false}
    />

    {errorMessages}

    <NextButton
      condition={form.values.recipients.length > 0}
      onClick={onSubmit}
    >
      <FormattedMessage id="Next" />
    </NextButton>
  </PageStackBlock>
);

export default BookRecipients;
