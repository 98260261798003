import { TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import DeleteButton from 'domain/Buttons/DeleteButton';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import {
  useOptimisticDeleteTexitleGroupQuery,
  useOptimisticUpdateTexitleGroupQuery,
  useTextileGroupQuery
} from 'queries/textile_deals/useTextileGroupsQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { HistoryProps, idFromMatch } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const TextileGroupEdit: React.FC<HistoryProps & FormProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const id = idFromMatch(props.match);
  const textileGroup = useTextileGroupQuery(textileOrderId, id);
  const updateTextileGroup = useOptimisticUpdateTexitleGroupQuery(
    textileOrderId,
    id
  );
  const removeTextileGroup = useOptimisticDeleteTexitleGroupQuery(
    textileOrderId,
    id
  );
  console.log("In Edit Group")

  const accessContext = useContext(HasAccessContext);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    console.log("Test use Effect")
    accessContext.checkSectionAccess('design');

    const data = textileGroup.data;

    if (data) {
      props.form.setField('name', data.name || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileGroup.data]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={textileOrderRoute(props.match, '/groups')} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Edit group" />
          </Headline.Large>
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileGroup.isError) {
    return (
      renderPage(<GenericErrorComponent onRetryClick={() => textileGroup.refetch()} />)
    );
  }

  if (props.form.loading || textileGroup.isLoading || !textileGroup.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const updateGroup = () => {
    const { form } = props;

    if (!id || !textileOrderId) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    const patch: TextileGroup = {
      ...textileGroup.data,
      name: form.values.name
    };

    updateTextileGroup.mutate(
      { data: patch, textileOrderId },
      {
        onSuccess: () => {
          props.history.push(textileOrderRoute(props.match, '/groups'));
        },
        onError: (error: any) => {
          form.setLoading(false);
          if (!handleFormError(form, error)) {
            form.setError('base', true);
          }
        }
      }
    );

  };

  const removeGroup = () => {
    const { form } = props;

    if (!id || !textileOrderId) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    removeTextileGroup.mutate(
      { id, textileOrderId },
      {
        onSuccess: () => {
          props.history.push(textileOrderRoute(props.match, '/groups'));
        },
        onError: (error: any) => {
          form.setLoading(false);
          handleFormError(form, error);

          return;
        }
      }
    );
  }

  const finishRemove = () => {
    props.form.setConfirm(false);
  };

  const confirmRemove = () => {
    props.form.setConfirm(true);
  };

  const renderUpdateGroupButton = () => {
    const { form } = props;
    return (
      <>
        {form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => updateGroup()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  };

  const renderEditGroup = () => {
    const { form } = props;
    const group = textileGroup.data;
    if (!group) {
      return null;
    }

    let extra;
    if (form.confirm) {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm delete textile group" />}
          text={<FormattedMessage id="confirm delete textile group info" />}
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => removeGroup()}
          onAbort={() => finishRemove()}
          confirmColor="RED"
        />
      );
    }

    return renderPage(
      <>
        {extra}
        <FormStackBlock>
          <TextInputComponent
            name="name"
            label={<FormattedMessage id="Group name" />}
            {...form.bindInput('name')}
            autoFocus={true}
          />
        </FormStackBlock>

        {renderUpdateGroupButton()}

        <Divider />

        <DeleteButton
          onClick={() =>
            group.profile_names.length > 0 ? confirmRemove() : removeGroup()
          }
          messageId="Remove group"
        />
      </>
    );
  };

  return renderEditGroup();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <TextileGroupEdit {...props} form={form} />;
};
