import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { BaseTextileProfile } from 'api/textile_deals/fetchTextileProfiles';
import ButtonBlock from 'blocks/ButtonBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import TopActionBlock from 'blocks/TopActionBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import BackLink from 'domain/Links/BackLink';
import { intl } from 'i18n';
import { useJoinTextileOrderQuery } from 'queries/textile_deals/useTextileAppStateQueries';
import {
  useCurrentTextileProfileQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import { ROUTE_ACCOUNT_DASHBOARD } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

const JoinTextileScreen: React.FC<HistoryProps> = (props) => {
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const { data, isLoading, isError, refetch } = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );
  const joinTextileOrder = useJoinTextileOrderQuery(textileOrderId);
  const flashMessageContext = useContext(FlashMessageContext);

  useEffect(() => {
    if (textileAppState?.textile_profile.join_role_selected) {
      props.history.replace(textileOrderRoute(props.match, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textileAppState?.textile_profile]);

  if (!flashMessageContext) {
    throw Error('Component must be used within a FlashMessageProvider');
  }

  if (isError) {
    return <GenericErrorComponent onRetryClick={() => refetch()} />;
  }

  if (isLoading || !data) {
    return <LoadingIndicatorComponent />;
  }
  const requestStudent = () => {
    if (!data) {
      return;
    }

    const currentTextileProfile: BaseTextileProfile = data;


    if (!textileOrderId || !currentTextileProfile) {
      return;
    }

    setLoadingState(true);

    if (currentTextileProfile.role !== 'applicant') {
      joinTextileOrder.mutate({orderId: textileOrderId})
    }

    joinTextileOrder.mutate(
      { orderId: textileOrderId, requestType: 'applicant_to_student' },
      {
        onSuccess: () => {
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'role change requested flash' }),
            'success'
          );
        },
        onError: () => {
          setLoadingState(false);
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'role change request error flash' }),
            'error'
          );
        },
      }
    );
  };

  const requestOrganizerRole = () => {
    if (!data) {
      return;
    }

    const currentTextileProfile: BaseTextileProfile = data;

    if (!textileOrderId || !currentTextileProfile) {
      return;
    }

    setLoadingState(true);

    if (currentTextileProfile.role === 'applicant') {
      joinTextileOrder.mutate(
        { orderId: textileOrderId, requestType: 'applicant_to_organizer' },
        {
          onSuccess: () => {
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'role change requested flash' }),
              'success'
            );
          },
          onError: () => {
            setLoadingState(false);
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'role change request error flash' }),
              'error'
            );
          }
        }
      );
    }

    if (currentTextileProfile.role === 'student') {
      joinTextileOrder.mutate(
        { orderId: textileOrderId, requestType: 'student_to_organizer' },
        {
          onSuccess: () => {
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'role change requested flash' }),
              'success'
            );
          },
          onError: () => {
            setLoadingState(false);
            flashMessageContext.triggerFlashMessage(
              intl.formatMessage({ id: 'role change request error flash' }),
              'error'
            );
          }
        }
      );
    }
  };

  const renderPage = (content?: JSX.Element) => {
    return (
      <>
        {renderTopAction()}

        <PageStackBlock>{content}</PageStackBlock>

        <HelpSubject hidden={true} />
      </>
    );
  };

  const renderTopAction = () => {
    return (
      <TopActionBlock>
        <BackLink to={ROUTE_ACCOUNT_DASHBOARD} />
      </TopActionBlock>
    );
  };

  const renderOnboarding = () => {
    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="onboarding organizer header" />}
          text={<FormattedMessage id="onboarding textile organizer text" />}
        />

        <IconListBlock>
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding textile organizer text 1" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding textile organizer text 2" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding textile organizer text 3" />
          </IconListElement>
        </IconListBlock>

        <ListStackBlock>
          <ButtonBlock
            background="PRIMARY"
            onClick={() => requestOrganizerRole()}
          >
            <FormattedMessage id="Become organizer" />
          </ButtonBlock>
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => requestStudent()}
          >
            <FormattedMessage id="Not become organizer" />
          </ButtonBlock>
        </ListStackBlock>
      </>
    );
  };

  return (
    <>{loadingState ? <LoadingIndicatorComponent /> : renderOnboarding()}</>
  );
};

export default JoinTextileScreen;
