import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Prompt, RouteComponentProps, withRouter } from 'react-router';

import ButtonBlock from 'blocks/ButtonBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import appConfig from 'config';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ChapterModelType } from 'models/ChapterModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { LayoutStoreType } from 'models/print/LayoutStore';
import { ColorScheme } from 'utils/chunker/color-scheme';
import { CustomBackground } from 'utils/chunker/replace-variables';
import { ROUTE_LAYOUT } from 'utils/constants/routes';

import {
  EveryCard,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import CancelClickLink from 'domain/Links/CancelClickLink';
import NakedButton from 'elements/NakedButton';
import FontsPreloader, { Fonts } from '../FontsPreloader';
import AlbumPreviewComponent from './AlbumPreviewComponent';
import PageLoadingComponent from './PageLoadingComponent';
import ProfilePreviewComponent from './ProfilePreviewComponent';
import QuotesPreviewComponent from './QuotesPreviewComponent';
import RankingsPreviewComponent from './RankingsPreviewComponent';
import TextPreviewComponent from './TextPreviewComponent';
import TocPreviewComponent from './TocPreviewComponent';
import YearbookPreviewComponent from './YearbookPreviewComponent';
import ChapterBackgroundComponent from './components/ChapterBackgroundComponent';
import ChapterColorsComponent from './components/ChapterColorsComponent';
import ChapterFontComponent from './components/ChapterFontComponent';
import ChapterLayoutComponent from './components/ChapterLayoutComponent';

interface LayoutEditorComponentProps {
  layoutStore?: LayoutStoreType;
  chaptersStore?: ChaptersStoreType;

  chapter: ChapterModelType;
  backLink?: any;
  onQuit?: (goBack?: boolean) => void;
}

interface LayoutEditorComponentState {
  updateCounter: number;
  layoutLoadingState?: 'loading' | 'error';
  loadedLayoutKey?: string;

  activeTab: 'preview' | 'layout' | 'font' | 'background' | 'colors';

  layoutKey?: string;
  colorsKey?: string;
  fontKey?: string;
  backgroundKey?: string;

  changed?: boolean;
  chapterUpdateState?: 'loading' | 'error';
}

interface PreviewProps {
  key?: string | number;
  chapter?: ChapterModelType;
  colors?: ColorScheme;
  background?: string | CustomBackground;
  pageHeaderStyle?: string | undefined;
  sectionHeaderStyle?: string | undefined;
  scaled: boolean;
}

@inject('layoutStore', 'chaptersStore', 'applicationStore')
@observer
class LayoutEditorComponent extends React.Component<
  LayoutEditorComponentProps & RouteComponentProps,
  LayoutEditorComponentState
> {
  state: LayoutEditorComponentState = {
    activeTab: 'preview',
    updateCounter: 1
  };

  componentDidMount(): void {
    // Allow setting of 'unindexed' layouts via browser console.
    // We might need some mechanism to enable/disable specifig layouts for some books only,
    // but for now this will be done by nuggit Support through the console.
    // TODO Declare proper globalThis type if we are going to keep this in the long run.
    // @ts-ignore
    window.editor_selectLayout = (layout: string) => this.selectLayout(layout);
    // @ts-ignore
    window.editor_selectLayoutXL = (layout: string) => this.selectLayoutOption('_xl')
    // @ts-ignore
    window.editor_selectLayoutXS = (layout: string) => this.selectLayoutOption('_xs')
    const { layoutStore } = this.props;

    layoutStore!.reset();
  }

  componentWillUnmount(): void {
    // @ts-ignore
    delete window.editor_selectLayout;
    // @ts-ignore
    delete window.editor_selectLayoutXL;
    // @ts-ignore
    delete window.editor_selectLayoutXS;
  }

  async save(quitOnSuccess?: boolean) {
    const { onQuit } = this.props;
    const { layoutKey, backgroundKey, fontKey, colorsKey } = this.state;

    const patch: Partial<ChapterModelType> = {};
    let changed = false;

    if (layoutKey) {
      changed = true;
      patch.layout_key = layoutKey;
    }
    if (backgroundKey) {
      changed = true;
      patch.background_key = backgroundKey;
    }
    if (fontKey) {
      changed = true;
      patch.font_key = fontKey;
    }
    if (colorsKey) {
      changed = true;
      patch.color_scheme_key = colorsKey;
    }

    if (!changed) {
      if (quitOnSuccess) {
        onQuit && onQuit();
      }
      return;
    }

    this.setState({
      chapterUpdateState: 'loading'
    });

    try {
      await this.props.chaptersStore!.updateChapter(
        this.props.chapter.id,
        patch
      );

      if (quitOnSuccess) {
        onQuit && onQuit();
      } else {
        this.setState({
          chapterUpdateState: undefined,
          changed: false
        });
      }
    } catch (e) {
      this.setState({
        chapterUpdateState: 'error'
      });
    }
  }

  renderPage(content: any, cancelLink: boolean, withSaveButton?: boolean) {
    const { backLink } = this.props;

    // TODO properly format button and back link
    return (
      <>
        <TopActionBlock>
          <HorizontalStackBlock justified={true}>
            {cancelLink ? (
              <CancelClickLink
                onClick={() => this.setState({ activeTab: 'preview' })}
              />
            ) : (
              <>{backLink}</>
            )}

            {/* Special Button that is inline but does not use inline prop because the width is set directly */}
            {withSaveButton && (
              <ButtonBlock
                background="PRIMARY"
                disabled={this.state.layoutLoadingState === 'loading'}
                onClick={() => this.save(true)}
                slim={true}
                style={{
                  display: 'inline',
                  width: '40%'
                }}
              >
                <FormattedMessage id="Save" />
              </ButtonBlock>
            )}
          </HorizontalStackBlock>
        </TopActionBlock>

        {content}
      </>
    );
  }

  renderThemeRequired() {
    const { backLink, onQuit } = this.props;
    return (
      <>
        <TopActionBlock>{backLink}</TopActionBlock>

        <Headline.Large>
          <FormattedMessage id="theme required header" />
        </Headline.Large>

        <ItemStackBlock gap="L">
          <Paragraph>
            <FormattedMessage id="theme required text" />
          </Paragraph>

          <ItemStackBlock gap="XS">
            <ButtonBlock
              background="PRIMARY"
              onClick={() => this.props.history.push(ROUTE_LAYOUT + '/theme')}
            >
              <FormattedMessage id="theme required button" />
            </ButtonBlock>

            {onQuit && (
              <ButtonBlock
                background="SECONDARY_DARK"
                onClick={() => onQuit(true)}
              >
                <FormattedMessage id="Cancel" />
              </ButtonBlock>
            )}
          </ItemStackBlock>
        </ItemStackBlock>
      </>
    );
  }

  generatePreview() {
    const { chapter, layoutStore } = this.props;
    const {
      layoutKey,
      backgroundKey,
      colorsKey,
      fontKey,
      layoutLoadingState,
      loadedLayoutKey
    } = this.state;

    let layout;

    if (layoutKey) {
      layout = layoutStore!.layoutDefinition(layoutKey, chapter.chapter_type);
    } else {
      layout = chapter.layout;
    }
    const layoutFonts = layout?.fontSpecs || [];
    const config = layout?.layoutDefinition;

    let background;
    if (backgroundKey) {
      const selectedBackground = layoutStore!.background(backgroundKey);
      if (selectedBackground) {
        background =
          selectedBackground.id + (selectedBackground.extension || '');
      }
    } else if (chapter.background) {
      background = chapter.background.id + (chapter.background.extension || '');
    }

    // TODO use some default?
    let colors;
    if (colorsKey) {
      colors = layoutStore!.color(colorsKey)?.templateColors;
    } else {
      colors = chapter.color_scheme?.templateColors;
    }

    // TODO use some default?
    let fontSpecs;
    let pageHeaderStyle;
    let sectionHeaderStyle;
    if (fontKey) {
      const selectedFont = layoutStore!.font(fontKey);
      if (selectedFont) {
        fontSpecs = selectedFont.specs;
        pageHeaderStyle = selectedFont.pageHeaderStyle;
        sectionHeaderStyle = selectedFont.sectionHeaderStyle;
      }
    } else if (chapter.font) {
      fontSpecs = chapter.font.specs;
      pageHeaderStyle = chapter.font.pageHeaderStyle;
      sectionHeaderStyle = chapter.font.sectionHeaderStyle;
    }

    // collect props
    const previewProps: PreviewProps = {
      key: 'preview-' + this.state.updateCounter,
      chapter,
      scaled: true,
      background,
      colors,
      pageHeaderStyle,
      sectionHeaderStyle
    };

    let preview;
    let numPages = 1;
    switch (chapter.chapter_type) {
      case 'factsheet':
      case 'teacher_factsheet': // TODO use other sample data for teacher factsheet?
        numPages = 2;
        preview = config && (
          <ProfilePreviewComponent config={config} {...previewProps} />
        );
        break;

      case 'yearbook':
        numPages = 2;
        preview = config && (
          <YearbookPreviewComponent config={config} {...previewProps} />
        );
        break;

      case 'quote':
        numPages = 2;
        preview = config && (
          <QuotesPreviewComponent config={config} {...previewProps} />
        );
        break;

      case 'ranking':
        numPages = 2;
        preview = config && (
          <RankingsPreviewComponent config={config} {...previewProps} />
        );
        break;

      case 'text':
        numPages = 2;
        preview = config && (
          <TextPreviewComponent config={config} {...previewProps} />
        );
        break;

      case 'album':
        numPages = 2;
        preview = <AlbumPreviewComponent config={config} {...previewProps} />;
        break;

      case 'table_of_contents':
        numPages = 1;
        preview = <TocPreviewComponent config={config} {...previewProps} />;
        break;

      default:
    }

    switch (layoutLoadingState) {
      case 'loading':
        return <PageLoadingComponent numPages={numPages} />;

      case 'error':
        return (
          <GenericErrorComponent
            onRetryClick={
              !loadedLayoutKey
                ? undefined
                : () => this.selectLayout(loadedLayoutKey)
            }
          />
        );

      default:
    }

    if (!preview) {
      return null;
    }

    const preloadFonts = layoutFonts
      .concat(fontSpecs || [])
      .concat((appConfig.layout.defaultFonts as Fonts) || []);

    // TODO error, better loading
    // TODO loading indicator needs to use correct number of pages (depending on chapter config)
    // TODO preview area needs fixed height to avoid scrolling on updates
    return (
      <FontsPreloader
        fonts={preloadFonts}
        print={false}
        loadingComponent={<PageLoadingComponent numPages={numPages} />}
        // errorComponent={errorComponent}
      >
        <SliderBlock>{preview}</SliderBlock>
      </FontsPreloader>
    );
  }

  async selectLayout(key: string) {
    const { layoutStore, chapter } = this.props;

    if (!layoutStore!.layoutExists(key)) {
      return;
    }


    if (!layoutStore!.layoutDefinition(key)) {
      // layout needs to be fetched
      this.setState({
        layoutLoadingState: 'loading',
        loadedLayoutKey: key
      });

      try {
        await layoutStore!.getLayout(chapter.chapter_type, key);
      } catch (e) {
        this.setState({
          layoutLoadingState: 'error'
        });
        return;
      }
    }

    const { layoutKey, updateCounter } = this.state;
    const selected = layoutKey || chapter.layout_key;

    const changed = selected !== key;
    this.setState({
      activeTab: 'preview',
      layoutLoadingState: undefined,
      loadedLayoutKey: undefined,
      layoutKey: key,
      updateCounter: changed ? updateCounter + 1 : updateCounter,
      changed
    });
  }

  async selectLayoutOption(option: '_xl' | '_xs') {
    const { layoutStore, chapter } = this.props;
    const { layoutKey, updateCounter } = this.state;
    const layout = layoutKey || chapter.layout_key;

    if (!layout) {
      console.error(`There is no ${option} Layout for default layout`)
      return;
    }

    const baseLayout = layout.replace(/(_xl|_xs)$/, '');
    const selectedOption = baseLayout + option;

    if (!layoutStore!.layoutExists(selectedOption)) {
      console.error(`There is no ${option} Layout for layout_key: ${baseLayout}`)
      return;
    }

    if (!layoutStore!.layoutDefinition(selectedOption)) {
      // layout needs to be fetched
      this.setState({
        layoutLoadingState: 'loading',
        loadedLayoutKey: selectedOption
      });

      try {
        await layoutStore!.getLayout(chapter.chapter_type, selectedOption);
      } catch (e) {
        this.setState({
          layoutLoadingState: 'error'
        });
        return;
      }
    }

    const changed = layout !== selectedOption;
    this.setState({
      activeTab: 'preview',
      layoutLoadingState: undefined,
      loadedLayoutKey: undefined,
      layoutKey: selectedOption,
      updateCounter: changed ? updateCounter + 1 : updateCounter,
      changed
    });
  }

  selectFont(key: string) {
    if (!this.props.layoutStore!.font(key)) {
      return;
    }

    const { fontKey, updateCounter } = this.state;
    const selected = fontKey || this.props.chapter.font_key;

    const changed = selected !== key;
    this.setState({
      activeTab: 'preview',
      fontKey: key,
      updateCounter: changed ? updateCounter + 1 : updateCounter,
      changed
    });
  }

  selectBackground(key: string) {
    if (!this.props.layoutStore!.background(key)) {
      return;
    }

    const { backgroundKey, updateCounter } = this.state;
    const selected = backgroundKey || this.props.chapter.background_key;

    const changed = selected !== key;
    this.setState({
      activeTab: 'preview',
      backgroundKey: key,
      updateCounter: changed ? updateCounter + 1 : updateCounter,
      changed
    });
  }

  selectColor(key: string) {
    if (!this.props.layoutStore!.color(key)) {
      return;
    }

    const { colorsKey, updateCounter } = this.state;
    const selected = colorsKey || this.props.chapter.color_scheme_key;

    const changed = selected !== key;
    this.setState({
      activeTab: 'preview',
      colorsKey: key,
      updateCounter: changed ? updateCounter + 1 : updateCounter,
      changed
    });
  }

  renderPreview() {
    const { chapter } = this.props;
    const { changed, chapterUpdateState } = this.state;

    if (!chapter.book_theme_key) {
      return this.renderThemeRequired();
    }

    const showLayout =
      chapter.chapter_type === 'album' ||
      chapter.chapter_type === 'table_of_contents'
        ? false
        : true;
    // TODO render placeholder if preview is not available
    return this.renderPage(
      <ItemStackBlock gap="L">
        {chapter.title && <Headline.Large>{chapter.title}</Headline.Large>}
        {chapterUpdateState === 'error' && <GenericErrorComponent />}

        {this.generatePreview()}

        <SliderBlock noScrollBar={true} gap="XXS">
          {showLayout && (
            <NakedButton onClick={() => this.setState({ activeTab: 'layout' })}>
              <EveryCard slim={true}>
                <EveryCardPadding center={true}>
                  <EveryCardHeadline>
                    <FormattedMessage id="Layout" />
                  </EveryCardHeadline>
                </EveryCardPadding>
              </EveryCard>
            </NakedButton>
          )}
          <NakedButton
            onClick={() => this.setState({ activeTab: 'background' })}
          >
            <EveryCard slim={true}>
              <EveryCardPadding center={true}>
                <EveryCardHeadline>
                  <FormattedMessage id="Background" />
                </EveryCardHeadline>
              </EveryCardPadding>
            </EveryCard>
          </NakedButton>
          <NakedButton onClick={() => this.setState({ activeTab: 'font' })}>
            <EveryCard slim={true}>
              <EveryCardPadding center={true}>
                <EveryCardHeadline>
                  <FormattedMessage id="Font" />
                </EveryCardHeadline>
              </EveryCardPadding>
            </EveryCard>
          </NakedButton>
          <NakedButton onClick={() => this.setState({ activeTab: 'colors' })}>
            <EveryCard slim={true}>
              <EveryCardPadding center={true}>
                <EveryCardHeadline>
                  <FormattedMessage id="Colors" />
                </EveryCardHeadline>
              </EveryCardPadding>
            </EveryCard>
          </NakedButton>
        </SliderBlock>

        {changed && chapterUpdateState !== 'loading' && (
          <Prompt
            message={intl.formatMessage({ id: 'layout changed prompt' })}
          />
        )}
      </ItemStackBlock>,
      false,
      true
    );
  }

  renderLoading() {
    return <LoadingIndicatorComponent />;
  }

  renderError() {
    return <GenericErrorComponent />;
  }

  render() {
    const {
      activeTab,
      layoutKey,
      fontKey,
      backgroundKey,
      colorsKey
    } = this.state;
    const { layoutStore, chapter } = this.props;

    if (layoutStore?.isLoading) {
      return this.renderLoading();
    }

    if (layoutStore?.isError) {
      return this.renderError();
    }

    switch (activeTab) {
      case 'preview':
        return this.renderPreview();

      case 'layout':
        return this.renderPage(
          <ChapterLayoutComponent
            chapter={chapter}
            currentKey={layoutKey}
            layoutSelected={(key: string) => this.selectLayout(key)}
          />,
          true
        );

      case 'font':
        return this.renderPage(
          <ChapterFontComponent
            chapter={chapter}
            currentKey={fontKey}
            fontSelected={(key: string) => this.selectFont(key)}
          />,
          true
        );

      case 'background':
        return this.renderPage(
          <ChapterBackgroundComponent
            chapter={chapter}
            currentKey={backgroundKey}
            backgroundSelected={(key: string) => this.selectBackground(key)}
          />,
          true
        );
      case 'colors':
        return this.renderPage(
          <ChapterColorsComponent
            chapter={chapter}
            currentKey={colorsKey}
            colorSelected={(key: string) => this.selectColor(key)}
          />,
          true
        );

      default:
        return null;
    }
  }
}

export default withRouter(LayoutEditorComponent);
