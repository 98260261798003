import style from 'styled-components';
import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

export interface BodytextProps {
  color?: keyof typeof COLORS;
  strong?: boolean;
  size?: number;
  hasInlineLink?: boolean;
  noBreak?: boolean;
}

export default style.p<BodytextProps>`
  margin: 0;
  font-size: ${(props) => (props.size ? props.size + 'rem' : '1rem')};
  line-height: 1.35714;
  font-family: ${FONTS.DEFAULT};
  font-weight: ${(props) => (props.strong ? '500' : '400')};
  letter-spacing: 0;
  color: ${(props) => (props.color ? COLORS[props.color] : 'inherit')};
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  ${(props) =>
    props.noBreak &&
    `
    white-space: nowrap;
  `}

  ${(props) =>
    props.hasInlineLink &&
    `
    a {
      font-weight: ${props.strong ? '600' : '400'};
      text-decoration: underline;
      color: ${props.color ? COLORS[props.color] : 'inherit'};
    }
  `}

  > svg {
    margin-right: 0.25em;
    vertical-align: -0.1em;
  }
`;
