import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import TextBlock from 'blocks/ExpertsBlock/TextBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { HasAccessContext } from 'contexts/HasAccessContext';
import CancelLink from 'domain/Links/CancelLink';
import UppercaseHeading from 'elements/UppercaseHeading';
import { intl } from 'i18n';
import { useOptimisticUpdateTextileDesignSettingQuery, useTextileDesignSettingQuery } from 'queries/textile_deals/useTextileDesignSettingQueries';
import { useTextileGroupsQuery } from 'queries/textile_deals/useTextileGroupsQueries';
import { useTextileOrderForGroupsQuery } from 'queries/textile_deals/useTextileOrderQueries';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

type GroupsToggle = undefined | 'deactivate' | 'activate' | 'minimumGroups';

const TextileGroupOverview: React.FC<HistoryProps & FormProps> = (props) => {
  const [groupsToggle, setGroupsToggle] = useState<GroupsToggle>(undefined);
  const textileOrderId = textileOrderIdFromMatch(props.match);

  const textileDesignSetting = useTextileDesignSettingQuery(textileOrderId);
  const textileGroups = useTextileGroupsQuery(textileOrderId);
  const textileOrder = useTextileOrderForGroupsQuery(textileOrderId);
  const updateTextileDesignSettings = useOptimisticUpdateTextileDesignSettingQuery(
    textileOrderId
  );

  const accessContext = useContext(HasAccessContext);
  const flashMessageContext = useContext(FlashMessageContext);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  if (!flashMessageContext) {
    throw Error('Component must be used within HasFlashMessageProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('design');

    const data = textileDesignSetting.data;

    if (data) {
      const hasRequiredSettings = data.layout_key && data.theme;
      if (!hasRequiredSettings) {
        const route = textileOrderRoute(props.match, '/textile_design/layout');
        props.history.push(route);
      }

      props.form.setField('show_groups', data.show_groups || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileDesignSetting.data]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <CancelLink
            to={textileOrderRoute(props.match, '/textile_design/back')}
          />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="textile settings groups" />}
            text={<FormattedMessage id="textile settings groups info" />}
          />
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileGroups.isError) {
    return (
      renderPage(<GenericErrorComponent onRetryClick={() => textileGroups.refetch()} />)
    );
  }

  if (textileDesignSetting.isError) {
    return (
      renderPage(<GenericErrorComponent
        onRetryClick={() => textileDesignSetting.refetch()}
      />)
    );
  }

  if (textileOrder.isError) {
    return (
      renderPage(<GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />)
    );
  }

  if (
    textileGroups.isLoading ||
    !textileGroups.data ||
    textileDesignSetting.isLoading ||
    !textileDesignSetting.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const groupChange = (event: { target: { checked: any } }) => {
    const checked = event.target.checked;

    if (!checked) {
      setGroupsToggle('deactivate')
    }

    if (checked) {
      if (!textileGroups.data || textileGroups.data.length < 2) {
        setGroupsToggle('minimumGroups');
      } else {
        setGroupsToggle('activate');
      }
    }
  }

  const toggleGroups = (show: boolean) => {
    if (!textileOrderId) {
      return;
    }

    const settings = textileDesignSetting.data;

    const patch = { ...settings, show_groups: show };

    updateTextileDesignSettings.mutate(
      { data: patch, textileOrderId },
      {
        onSuccess: () => {
          props.form.setField("show_groups", show);
          flashMessageContext.triggerFlashMessage(
            intl.formatMessage({ id: 'Saved' }),
            'success'
          );
        }
      }
    );

    setGroupsToggle(undefined);
  };

  const renderOverview = () => {
    const { form } = props;

    const settings = textileDesignSetting.data;
    const groups = textileGroups.data;
    const hasStudentsWithoutGroups = !!(textileOrder.data.students_without_groups_count && textileOrder.data.students_without_groups_count >= 0);
    const hasTeachersWithoutGroups = !!(
      textileOrder.data.teachers_without_groups_count &&
      textileOrder.data.teachers_without_groups_count >= 0);

    if (!settings || !textileGroups) {
      return null;
    }

    let extra;

    if (groupsToggle === 'deactivate') {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="textile groups deactivate" />}
          text={<FormattedMessage id="textile groups deactivate info" />}
          confirmText={<FormattedMessage id="Deactivate" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => toggleGroups(false)}
          onAbort={() => setGroupsToggle(undefined)}
          confirmColor="RED"
        />
      );
    }

    if (groupsToggle === 'activate') {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="textile groups add" />}
          text={<FormattedMessage id="textile groups add info" />}
          confirmText={<FormattedMessage id="Activate" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => toggleGroups(true)}
          onAbort={() => setGroupsToggle(undefined)}
        />
      );
    }

    if (groupsToggle === 'minimumGroups') {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="textile groups minimum" />}
          text={<FormattedMessage id="textile groups minimum info" />}
          confirmText={<FormattedMessage id="Understood" />}
          onConfirm={() => setGroupsToggle(undefined)}
        />
      );
    }

    return renderPage(
      <>
        {extra}
        <FormStackBlock>
          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="show_groups"
              {...form.bindCheckbox('show_groups')}
              onChange={(e: { target: { checked: any } }) =>
                groupChange(e)
              }
            />
            <CustomCheckboxBlock.LabelElement htmlFor="show_groups">
              <FormattedMessage id="textile back show groups" />
            </CustomCheckboxBlock.LabelElement>
            {form.values.show_groups ? (
              <TextElement size="S">
                <FormattedMessage id="textile groups active" />
              </TextElement>
            ) : (
              <TextElement size="S">
                <FormattedMessage id="textile groups not active" />
              </TextElement>
            )}
          </CustomCheckboxBlock>
        </FormStackBlock>

        <Divider />

        {groups && groups.length < 5 ? (
          <ItemStackBlock gap="S">
            <Link to={textileOrderRoute(props.match, '/groups/add')}>
              <ButtonBlock background="PRIMARY">
                <FormattedMessage id="create group link" />
              </ButtonBlock>
            </Link>
            {groups.length === 0 && (
              <TextBlock>
                <ItemStackBlock gap="XS">
                  <Paragraph color="TEXT_LIGHT" size="L">
                    <FormattedMessage id="No group added" />
                  </Paragraph>
                  <Paragraph color="TEXT_LIGHT" size="S">
                    <FormattedMessage id="textile back show groups not allowed" />
                  </Paragraph>
                </ItemStackBlock>
              </TextBlock>
            )}
          </ItemStackBlock>
        ) : (
          <ItemStackBlock gap="XS">
            <ButtonBlock background="PRIMARY" disabled={true}>
              <FormattedMessage id="create group link" />
            </ButtonBlock>
            <Paragraph color="TEXT_LIGHT">
              <FormattedMessage id="max amount textile groups" />
            </Paragraph>
          </ItemStackBlock>
        )}

        {groups && groups.length > 0 && hasStudentsWithoutGroups && (
          <Link
            to={textileOrderRoute(
              props.match,
              '/groups/students_without_group'
            )}
          >
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage
                      id="students without group"
                      values={{
                        count:
                          textileOrder.data.students_without_groups_count || 0
                      }}
                    />
                  </EveryCardHeadline>
                </EveryCardBody>
                <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
              </EveryCardPadding>
            </EveryCard>
          </Link>
        )}

        {groups && groups.length > 0 && hasTeachersWithoutGroups && (
          <Link
            to={textileOrderRoute(
              props.match,
              '/groups/teachers_without_group'
            )}
          >
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage
                      id="teacher without group"
                      values={{
                        count:
                          textileOrder.data.teachers_without_groups_count || 0
                      }}
                    />
                  </EveryCardHeadline>
                </EveryCardBody>
                <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
              </EveryCardPadding>
            </EveryCard>
          </Link>
        )}

        {groups && groups.length > 0 && (
          <>
            <LinkBlock
              inline={true}
              slim={true}
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
              to={textileOrderRoute(props.match, '/groups/sorting')}
            >
              <IconComponent icon="sort_v" />
              <FormattedMessage id="Print sorting order" />
            </LinkBlock>
            <ItemStackBlock gap="S">
              <UppercaseHeading>
                <FormattedMessage id="Groups" />
              </UppercaseHeading>

              <ListStackBlock>
                {groups.map((group) => (
                  <Link
                    key={group.id}
                    to={textileOrderRoute(
                      props.match,
                      '/groups/edit/' + group.id
                    )}
                  >
                    <EveryCard>
                      <EveryCardPadding>
                        <EveryCardBody>
                          <EveryCardHeadline>{group.name}</EveryCardHeadline>
                        </EveryCardBody>
                      </EveryCardPadding>
                    </EveryCard>
                  </Link>
                ))}
              </ListStackBlock>
            </ItemStackBlock>
          </>
        )}
      </>
    );
  };

  return renderOverview();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <TextileGroupOverview {...props} form={form} />;
};
