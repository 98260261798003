import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import config from 'config';
import Headline from 'elements/Headline';
import Paragraph from 'components/Paragraph/Paragraph';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';

const OrderPaymentInfo = ({
  paymentReceived,
  price,
  usage
}: {
  paymentReceived: boolean;
  price: number;
  usage: string;
}) => (
  <>
    {!paymentReceived ? (
      <>
        <ItemStackBlock gap="XS">
          <Headline.Medium>
            <FormattedMessage id="Checkout payment" />
          </Headline.Medium>
          <Paragraph>
            <FormattedMessage id="Order payment transfer info" />
          </Paragraph>
        </ItemStackBlock>

        <ListStackBlock>
          <HorizontalStackBlock justified={true}>
            <FormattedMessage id="Checkout recipient" />
            <Paragraph>
              <FormattedMessage id="Nuggit" />
            </Paragraph>
          </HorizontalStackBlock>
          <HorizontalStackBlock justified={true}>
            <FormattedMessage id="IBAN" />
            <Paragraph>{config.iban}</Paragraph>
          </HorizontalStackBlock>
          <HorizontalStackBlock justified={true}>
            <FormattedMessage id="BIC" />
            <Paragraph>{config.bic}</Paragraph>
          </HorizontalStackBlock>
          <HorizontalStackBlock justified={true}>
            <FormattedMessage id="Amount" />
            <Paragraph>
              <FormattedNumber
                value={price}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="EUR"
              />
            </Paragraph>
          </HorizontalStackBlock>
          <HorizontalStackBlock justified={true}>
            <FormattedMessage id="Usage" />
            <Paragraph>{usage}</Paragraph>
          </HorizontalStackBlock>
        </ListStackBlock>
      </>
    ) : (
      <>
        <ItemStackBlock gap="XS">
          <Headline.Medium>
            <FormattedMessage id="Checkout payment" />
          </Headline.Medium>
          <Paragraph>
            <FormattedMessage id="Order payment transfer success long" />
          </Paragraph>
        </ItemStackBlock>
        <HorizontalStackBlock gap="S">
          <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
          <FormattedMessage id="Order payment transfer success" />
        </HorizontalStackBlock>
      </>
    )}
  </>
);

export default OrderPaymentInfo;
