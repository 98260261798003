import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Headline from 'elements/Headline';
import { SetupStoreType } from 'models/SetupStore';
import { ROUTE_SIGNUP } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';

interface SignupNumberScreenProps {
  setupStore: SetupStoreType;
  form: FormType;
  token?: string;
}

@inject('setupStore')
@observer
class SignupCheckNumberScreen extends React.Component<
  SignupNumberScreenProps & HistoryProps & WrappedComponentProps
> {
  componentDidMount() {
    const { setupStore, history } = this.props;

    if (setupStore.isSignupDone) {
      history.replace(ROUTE_SIGNUP + '/done');
      return;
    }

    if (!setupStore.isSignupInitialized) {
      history.replace(ROUTE_SIGNUP);
      return;
    }
  }

  finish() {
    this.props.history.push(ROUTE_SIGNUP + '/password');
  }

  changeNumber() {
    this.props.history.push(ROUTE_SIGNUP + '/number');
  }

  renderPage(content?: any) {
    return (
      <>
        <SignupHeaderComponent
          backRoute={ROUTE_SIGNUP + '/number'}
          progressStatus={(100 / 10) * 7}
        />

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="signup check number header" />}
            text={<FormattedMessage id="signup number text" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const { setupStore } = this.props;
    const { account } = setupStore;

    if (!account) {
      return <GenericErrorComponent />;
    }

    if (setupStore.isNumberLoading) {
      return this.renderLoading();
    }

    return this.renderPage(
      <>
        {account.mobile_number && (
          <Headline.Medium>{account.mobile_number}</Headline.Medium>
        )}

        <ListStackBlock>
          <ButtonBlock background="PRIMARY" onClick={() => this.finish()}>
            <FormattedMessage id="Next" />
          </ButtonBlock>
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => this.changeNumber()}
          >
            <FormattedMessage id="signup change number" />
          </ButtonBlock>
        </ListStackBlock>
      </>
    );
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  return <SignupCheckNumberScreen {...props} form={form} />;
});
