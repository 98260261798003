import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import { HEADER_MAX_LENGTH } from 'screens/factsheets/screens/QuestionHeaderText/QuestionHeaderTextScreen';

import SettingNotAvailableComponent from '../../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from '../SettingsDetailProps';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';

const FactsheetTextSettingsContainer = ({
  form,
  chapter,
  onCommitSettings
}: SimpleSettingsDetailProps) => {
  if (!chapter.isFactsheetChapter) {
    return <SettingNotAvailableComponent />;
  }

  return (
    <PageStackBlock>
      <PageHeader headline={<FormattedMessage id="Long text" />} text={<FormattedMessage id="long text settings info" />} />

      <FormStackBlock>
        <CustomCheckboxBlock>
          <CustomCheckboxBlock.CheckboxElement
            id="checkbox"
            {...form.bindCheckbox('text_on')}
          />
          <CustomCheckboxBlock.LabelElement htmlFor="checkbox">
            <FormattedMessage id="Long text enabled" />
          </CustomCheckboxBlock.LabelElement>
        </CustomCheckboxBlock>

        {form.values.text_on && (
          <>
            <TextInputComponent
              name="text_header"
              label={<FormattedMessage id="Factsheet text header" />}
              maxLength={HEADER_MAX_LENGTH}
              {...form.bindInput('text_header')}
            />

            <CharCounterComponent
              max={HEADER_MAX_LENGTH}
              value={form.values.text_header}
            />
          </>
        )}
      </FormStackBlock>

        <ButtonBlock
          background="PRIMARY"
          onClick={() =>
            onCommitSettings({
              text_on: form.values.text_on ? true : false,
              text_header: form.values.text_header
            })
          }
        >
          <FormattedMessage id="Save" />
        </ButtonBlock>
    </PageStackBlock>
  );
};

export default FactsheetTextSettingsContainer;
