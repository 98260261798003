import styled from 'styled-components';
import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

interface InputProps {
  type?: 'text' | 'number' | 'tel' | 'password' | 'email';
  textarea?: boolean;
  height?: number;
  noHotjar?: boolean;
}

const Input = styled.input.attrs((props: InputProps) => {
  // TODO Disable for password fields as well or let Hotjar filter it?
  const attrs: { type: string; 'data-hj-whitelist'?: string } = {
    type: props.type || 'text'
  };
  if (!props.noHotjar) {
    attrs['data-hj-whitelist'] = 'data-hj-whitelist';
  }
  return attrs;
})<InputProps>`
  display: block;
  box-shadow: none;
  width: 100%;
  height: calc(1.5em + 1.875em + 2px);
  padding: 0.9375em 1.25em;
  background: ${COLORS.WHITE};
  background-clip: padding-box;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0.03125em;
  color: ${COLORS.GRAY10};
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-width: 1px;
  border-style: solid;

  &:focus {
    outline: none;
    border-color: ${COLORS.PRIMARY};

    + * {
      border-color: ${COLORS.PRIMARY};
    }
  }

  &:disabled {
    background-color: ${COLORS.GRAY950};
  }

  ${(props) =>
    props.textarea
      ? `
    resize: vertical;
    height: ${
      props.height ? props.height + 'em' : 'calc(15em + 1.75rem + 2px)'
    };
    font-family: ${FONTS.DEFAULT};
  `
      : ''};
`;

export default Input;
