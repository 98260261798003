import React from 'react';

import {
  Route,
  Switch
} from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_TEXTILE_CALCULATOR } from 'utils/constants/routes';
import ChangeAuthorTextileCheckout from './ChangeAuthorTextileCheckout';

import CalculatorTextileView from './CalculatorTextileView';
import OrderCalculatorScreen from './OrderCalculatorScreen';
import TextileCalculatorScreen from './TextileCalculatorScreen';
import TextileOfferScreen from './TextileOfferScreen';

export default () => (
  <Switch>
    <Route path={ROUTE_TEXTILE_CALCULATOR + "/order"} component={OrderCalculatorScreen} />
    <Route path={ROUTE_TEXTILE_CALCULATOR + "/textile/:id"} component={CalculatorTextileView} />
    <Route path={ROUTE_TEXTILE_CALCULATOR + '/offer'} component={TextileOfferScreen} />
    <Route path={ROUTE_TEXTILE_CALCULATOR + '/change_author'} component={ChangeAuthorTextileCheckout} />
    <Route path={ROUTE_TEXTILE_CALCULATOR} exact={true} component={TextileCalculatorScreen} />

    <NotFoundRoute />
  </Switch>
);
