import React from 'react';

import { PagePosition } from 'utils/chunker/replace-variables';
import ImagePageTemplateComponent, {
  ImagePageImageMap
} from '../templates/ImagePageTemplateComponent';
import NoLayoutComponent from './NoLayoutComponent';
import { scaled, scaledStack } from './scaling';
import {
  generateSponsorPageTemplateConfig,
  SPONSOR_PAGE_GRIDS
} from '../templates/sponsor-page-grids';

interface Props {
  layoutKey: string;

  position?: PagePosition;
  page?: number;

  images?: ImagePageImageMap;

  positionMode?: boolean;
  chapterPreview?: boolean;
}

const SponsorPagePreviewComponent = ({
  layoutKey,
  images,
  positionMode,
  chapterPreview,
  position,
  page
}: Props) => {
  const layout = SPONSOR_PAGE_GRIDS[layoutKey];
  if (!layout) {
    return <NoLayoutComponent />;
  }

  return (
    <ImagePageTemplateComponent
      config={generateSponsorPageTemplateConfig(layout)}
      colors={[]}
      render={!chapterPreview ? scaled : scaledStack}
      startPage={page || 1}
      startPosition={position || 'left'}
      images={images}
      positionMode={positionMode}
    />
  );
};

export default SponsorPagePreviewComponent;
