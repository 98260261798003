import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import FooterWrapper from 'blocks/FooterBlock/FooterWrapper';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Logo from 'components/HeaderComponent/Logo';
import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';
import config from 'config';
import BackClickLink from 'domain/Links/BackClickLink';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const FunnelPage = ({
  children,
  backToSource,
  fromDashboard,
  lastPage
}: {
  children: ReactNode;
  backToSource?: boolean;
  fromDashboard?: string | undefined;
  lastPage?: boolean;
}) => {
  const history = useHistory();


  return (
    <FooterWrapper>
      <TopActionBlock>
        <HorizontalStackBlock justified={true}>
          {backToSource ? (
            fromDashboard ? (
              <BackClickLink onClick={() => history.replace(fromDashboard)} messageId="Back to dashboard long" />
            ) : (
              <a href={config.websiteUrl}>
                <TextElement  color="GRAY10">
                  <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
                  <FormattedMessage id="to homepage" />
                </TextElement>
              </a>
            )
          ) : (
            <BackClickLink
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            />
          )}

          {lastPage ? (
            <>
              {fromDashboard ? (
                <Link to={fromDashboard}>
                  <Logo />
                </Link>
              ) : (
                <a href={config.websiteUrl}>
                  <Logo />
                </a>
              )}
            </>
          ) : (
            <Logo />
          )}
        </HorizontalStackBlock>
      </TopActionBlock>

      {children}
    </FooterWrapper>
  );
};

export default FunnelPage;
