import { Instance, types } from 'mobx-state-tree';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import numberOrUndefined from 'utils/store/numberOrUndefined';

import { createProfileOrderEnum, ProfileOrderEnum } from './ProfileOrderEnum';

const FactsheetChapterSettingsModel = types.model(
  'FactsheetChapterSettingsModel',
  {
    questions_on: types.maybe(types.boolean),
    questions_header: types.maybe(types.string),
    text_on: types.maybe(types.boolean),
    text_header: types.maybe(types.string),
    creative_page: types.maybe(types.boolean),
    comments_on: types.maybe(types.boolean),
    comments_header: types.maybe(types.string),
    anonymous_comments: types.maybe(types.boolean),
    photo_count: types.maybe(types.number),
    slot1_photo_name: types.maybe(types.string),
    slot2_photo_name: types.maybe(types.string),
    slot3_photo_name: types.maybe(types.string),
    profile_order: types.maybe(ProfileOrderEnum),
    must_have_group: types.maybe(types.boolean),
    allow_split_factsheet: types.maybe(types.boolean)
  }
);

export const createFactsheetChapterSettingsModel = (
  data?: any,
  createEmptyModel: boolean = true
): FactsheetChapterSettingsModelType | undefined => {
  if (!data) {
    return createEmptyModel
      ? FactsheetChapterSettingsModel.create()
      : undefined;
  }

  return FactsheetChapterSettingsModel.create({
    questions_on: booleanOrUndefined(data.questions_on),
    questions_header: data.questions_header || undefined,
    text_on: booleanOrUndefined(data.text_on),
    text_header: data.text_header || undefined,
    creative_page: booleanOrUndefined(data.creative_page),
    comments_on: booleanOrUndefined(data.comments_on),
    comments_header: data.comments_header || undefined,
    anonymous_comments: booleanOrUndefined(data.anonymous_comments),
    photo_count: numberOrUndefined(data.photo_count),
    slot1_photo_name: data.slot1_photo_name || undefined,
    slot2_photo_name: data.slot2_photo_name || undefined,
    slot3_photo_name: data.slot3_photo_name || undefined,
    profile_order: createProfileOrderEnum(data.profile_order),
    must_have_group: booleanOrUndefined(data.must_have_group),
    allow_split_factsheet: booleanOrUndefined(data.allow_split_factsheet)
  });
};

export type FactsheetChapterSettingsModelType = Instance<
  typeof FactsheetChapterSettingsModel
>;
export default FactsheetChapterSettingsModel;
