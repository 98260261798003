import React from 'react';

import SliderBlock from 'blocks/SliderBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageComponent, { PAGE_SIZE_A4 } from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import ScaledPagePreviewComponent from '../structure/ScaledPagePreviewComponent';

export default ({ numPages }: { numPages?: number }) => {
  const a = [];
  for (let i = 0; i < (numPages || 1); i++) {
    a.push(i);
  }
  return (
    <SliderBlock>
      {a.map((i) => (
        <ScaledPagePreviewComponent key={i} border={true}>
          <PageComponent previewSize={PAGE_SIZE_A4}>
            <PageContentComponent>
              <div style={{ position: 'absolute', top: '585px', width: '864px' }}>
                <LoadingIndicatorComponent />
              </div>
            </PageContentComponent>
          </PageComponent>
        </ScaledPagePreviewComponent>
      ))}
    </SliderBlock>
  );
};
