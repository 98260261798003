import { inject, observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import SharingComponent from 'components/SharingComponent';
import YearbookPreviewComponent from 'components/print/layout-editor/YearbookPreviewComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import ChapterPreviewComponent from 'domain/ChapterPreviewComponent';
import BackToContentLink from 'domain/Links/BackToContentLink';
import Headline from 'elements/Headline';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChapterModelType } from 'models/ChapterModel';
import { ProfileModelType } from 'models/ProfileModel';
import { YearbookChapterSettingsModelType } from 'models/chapter-settings/YearbookChapterSettingsModel';
import { FactsheetsStoreType } from 'screens/factsheets/FactsheetsStore';
import { assert } from 'utils/assert';
import { ROUTE_SETTINGS, ROUTE_YEARBOOKS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import useForm, { FormType } from 'utils/hooks/useForm';
import YearbookListItem from './YearbookListItem';

interface YearbooksListProps {
  factsheetsStore: FactsheetsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('factsheetsStore', 'applicationStore')
@observer
class YearbooksList extends React.Component<YearbooksListProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore, factsheetsStore } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature(
        'yearbook',
        this.props.location.pathname
      )
    ) {
      return;
    }

    if (!factsheetsStore.isFactsheetsLoading) {
      if (!factsheetsStore.factsheets || isPush(this.props.history)) {
        this.loadFactsheets();
      }
    }
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const chapterId = chapterIdFromMatch(this.props.match);
    const prevChapterId = chapterIdFromMatch(prevProps.match);

    if (chapterId !== prevChapterId) {
      this.loadFactsheets();
    }
  }

  loadFactsheets() {
    const { factsheetsStore } = this.props;
    const chapterId = chapterIdFromMatch(this.props.match);

    if (!chapterId) {
      return;
    }

    factsheetsStore.getFactsheetsByChapter(chapterId, 'yearbook');
  }

  chapterUpdated(chapter: ChapterModelType) {
    this.props.factsheetsStore.setChapter(chapter);
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadFactsheets()} />
    );
  }

  renderList() {
    const { factsheetsStore, form } = this.props;

    const { chapter } = factsheetsStore;
    if (!chapter) {
      return null;
    }

    const filter = form.values.filter || undefined;

    const sortByLastName =
      (chapter.settings as YearbookChapterSettingsModelType)?.profile_order ===
      'last_name';
    const items = factsheetsStore.allFactsheets(filter, sortByLastName);

    const route = ROUTE_YEARBOOKS + '/detail/';

    return this.renderPage(
      <>
        <SearchListInput form={form} placeholderId="Find listing" />

        <LinkBlock
          inline={true}
          slim={true}
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
          to={
            ROUTE_YEARBOOKS +
            '/' +
            chapter?.id +
            '/settings?active=order&from=list'
          }
        >
          <IconComponent icon="sort_v" />
          <FormattedMessage id="Sorting order" />
        </LinkBlock>

        <ItemStackBlock gap="S">
          <UppercaseHeading>
            {filter ? (
              <FormattedMessage
                id="listings filter count"
                values={{ count: items.length }}
              />
            ) : (
              <FormattedMessage
                id="listings count"
                values={{ count: items.length }}
              />
            )}
          </UppercaseHeading>

          <ListStackBlock>
            {items.map((profile) => (
              <Link key={profile.id} to={route + profile.id}>
                <YearbookListItem
                  label={
                    <>
                      {profile.first_name} {profile.last_name}
                    </>
                  }
                  print={profile.print_yearbook !== undefined ? profile.print_yearbook : true}
                />
              </Link>
            ))}
          </ListStackBlock>
        </ItemStackBlock>
      </>,
      items.filter((item) => !!item.print_yearbook)
    );
  }

  renderPage(content: ReactNode, previewProfiles?: ProfileModelType[]) {
    const { applicationStore, factsheetsStore } = this.props;
    const { book } = applicationStore;
    const { chapter } = factsheetsStore;

    assert(book);

    return (
      <>
        <TopActionBlock>
          <BackToContentLink />
        </TopActionBlock>

        <PageStackBlock>
          <ChapterPreviewComponent
            chapter={chapter}
            defaultTextId="Yearbook"
            baseRoute={ROUTE_YEARBOOKS}
            onChapterUpdated={(updatedChapter) =>
              this.chapterUpdated(updatedChapter)
            }
            marginBottom="L"
          >
            {chapter?.layout?.layoutDefinition && (
              <FontPreloadComponent chapter={chapter} numPages={2}>
                <SliderBlock>
                  <YearbookPreviewComponent
                    scaled={true}
                    config={chapter.layout.layoutDefinition}
                    chapter={chapter}
                    profiles={previewProfiles || []}
                  />
                </SliderBlock>
              </FontPreloadComponent>
            )}
          </ChapterPreviewComponent>

          <ItemStackBlock gap="XS">
            <Headline.Medium>
              <FormattedMessage id="Yearbook" />
            </Headline.Medium>

            <Paragraph color="TEXT_LIGHT">
              <FormattedMessage id="Manage yearbook" />
            </Paragraph>
          </ItemStackBlock>

          <SharingComponent
            label="invite your fellow students"
            buttonOutside={false}
            modalHeader="invite link"
            message="Nuggit invite text"
            buttonColor="WHATSAPP"
            url={book.invitation_url}
          />

          <Divider />

          <ListStackBlock>
            <Link to={ROUTE_SETTINGS + '/students'}>
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="My class(short)" />
                    </EveryCardHeadline>
                  </EveryCardBody>
                  <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
                </EveryCardPadding>
              </EveryCard>
            </Link>

            {factsheetsStore.includesOwnFactsheet && (
              <Link to={ROUTE_YEARBOOKS}>
                <EveryCard>
                  <EveryCardPadding>
                    <EveryCardBody>
                      <EveryCardHeadline>
                        <FormattedMessage id="My listing" />
                      </EveryCardHeadline>
                    </EveryCardBody>
                    <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
                  </EveryCardPadding>
                </EveryCard>
              </Link>
            )}
          </ListStackBlock>

          <Divider />

          {content}
        </PageStackBlock>

        <HelpSubject subject="yearbook" />
      </>
    );
  }

  render() {
    const { applicationStore, factsheetsStore } = this.props;

    if (!applicationStore.isOrganizer) {
      return null;
    }

    if (factsheetsStore.isFactsheetsLoading) {
      return this.renderLoading();
    }

    if (factsheetsStore.isFactsheetsError) {
      return this.renderError();
    }

    // TODO check if still editable here?

    return this.renderList();
  }
}

export default (props: any) => {
  const form = useForm();
  return <YearbooksList {...props} form={form} />;
};
