import React, { useContext, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import ButtonBlock from 'blocks/ButtonBlock';
import CheckboxBlock from 'blocks/CheckboxBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import InputMessage from 'elements/InputMessage';
import UppercaseHeading from 'elements/UppercaseHeading';
import { intl } from 'i18n';
import { useTextileGroupsQuery } from 'queries/textile_deals/useTextileGroupsQueries';
import { useTextileOrderForManagementQuery } from 'queries/textile_deals/useTextileOrderQueries';
import {
  useCurrentTextileProfileQuery,
  useUpdateTextileProfileQuery
} from 'queries/textile_deals/useTextileProfileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const ProfileEditScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const [notVerified, setNotVerfied] = useState<boolean>(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const currentTextileProfile = useCurrentTextileProfileQuery(
    textileAppState?.textile_profile.id,
    textileOrderId
  );
  const textileGroups = useTextileGroupsQuery(textileOrderId);
  const textileOrder = useTextileOrderForManagementQuery(textileOrderId);
  const updateTextileProfile = useUpdateTextileProfileQuery();

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('profile');
    const data = currentTextileProfile.data;
    const groups = textileGroups.data;
    const textileOrder = textileAppState?.textile_order;

    if (data && (data.name_verified || data.print_data_locked)) {
      props.history.replace(
        textileOrderRoute(props.match, '/my_profile/overview')
      );
    }

    if (data && groups && textileOrder) {
      props.form.setField('first_name', data.first_name || '');
      props.form.setField('last_name', data.last_name || '');
      if (textileOrder.show_groups) {
        props.form.setField(
          'textile_group_id',
          data.textile_group?.id.toString() || groups[0]?.id.toString() || ''
        );
      }
      props.form.setField('print', data.print?.toString() || 'false');
      props.form.setField('name_verified', data.name_verified || undefined);
    }
  }, [
    currentTextileProfile.data,
    textileGroups.data,
    textileAppState?.textile_order,
    accessContext
  ]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink
            onClick={() =>
              props.history.location?.state?.canGoBack
                ? props.history.goBack()
                : props.history.push(textileOrderRoute(props.match, ''))
            }
          />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (currentTextileProfile.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => currentTextileProfile.refetch()}
      />
    );
  }

  if (textileGroups.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileGroups.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (
    !textileAppState ||
    currentTextileProfile.isLoading ||
    !currentTextileProfile.data ||
    textileGroups.isLoading ||
    !textileGroups.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const save = () => {
    const { form, history, match } = props;

    if (!form.values.name_verified) {
      setNotVerfied(true);
      return;
    } else {
      setNotVerfied(false);
    }

    if (!currentTextileProfile.data || !textileOrderId) {
      return;
    }

    form.resetErrors();

    updateTextileProfile.mutate(
      {
        data: { ...form.values },
        id: currentTextileProfile.data.id,
        textileOrderId
      },
      {
        onSuccess: () => {
          history.push(textileOrderRoute(match, '/my_profile/overview'));
        },
        onError: (error: any) => {
          if (handleFormError(form, error)) {
            return;
          }
        }
      }
    );
  };

  const renderLockedState = () => {
    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="textile print settings" />}
          text={''}
        />
        <InputMessage error={true}>
          <Paragraph color="BLACK">
            <FormattedMessage id="textile my profile locked info" />
          </Paragraph>
        </InputMessage>
      </>
    );
  };

  const renderSaveButton = () => {
    const { form } = props;

    return (
      <>
        {form.errors.base && <GenericErrorComponent />}

        {notVerified && (
          <InputMessage error={true}>
            <FormattedMessage id="textile print data verified" />
          </InputMessage>
        )}

        <ButtonBlock background="PRIMARY" onClick={() => save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  };

  const renderForm = () => {
    const { form } = props;

    if (
      !textileAppState.textile_order.print_data_edit_open &&
      !textileAppState.textile_profile.is_organizer
    ) {
      return renderLockedState();
    }

    const groups: TextileGroup[] = textileGroups.data;

    const showGroups = textileAppState.textile_order.show_groups;
    const orderData = textileOrder.data;

    const day = intl.formatDate(orderData.edit_print_data_deadline, {
      day: '2-digit'
    });
    const month = intl.formatDate(orderData.edit_print_data_deadline, {
      month: '2-digit'
    });
    const date = day + '/' + month;

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="textile checkout print" />}
          text={<FormattedMessage id="textile student edit info" />}
        />

        {orderData.edit_print_data_deadline &&
        orderData.allow_edit_print_data ? (
          <Paragraph>
            <IconComponent icon="CLOCK_CIRCLE" fill="BLACK" />
            <FormattedMessage id="preorder open til" /> {date}
          </Paragraph>
        ) : (
          <Paragraph>
            <IconComponent icon="PAUSE" fill="BLACK" />
            <FormattedMessage id="editing state paused" />
          </Paragraph>
        )}

        {orderData.organizer_print_data_message && (
          <InputMessage info={true}>
            <Paragraph weight="BOLD" size="L">
              <FormattedMessage id="message from organiser" />
            </Paragraph>

            <Divider color="PURPLE" />

            <Paragraph>{orderData.organizer_print_data_message}</Paragraph>
          </InputMessage>
        )}

        <Divider />

        <FormStackBlock>
          <>
            <UppercaseHeading>
              <FormattedMessage id="Print textile name" />
            </UppercaseHeading>

            <ListStackBlock>
              <RadioButtonComponent
                name="print"
                value={true}
                label={<FormattedMessage id="Print name" />}
                {...form.bindRadioButton('print', 'true')}
              />

              <RadioButtonComponent
                name="print"
                value={false}
                label={<FormattedMessage id="no print name" />}
                {...form.bindRadioButton('print', 'false')}
              />
            </ListStackBlock>
          </>

          {form.values.print === 'true' && (
            <>
              <TextInputComponent
                name="first_name"
                label={<FormattedMessage id="First name" />}
                {...form.bindInput('first_name')}
                autoFocus={true}
              />

              <TextInputComponent
                name="last_name"
                label={<FormattedMessage id="Last name" />}
                {...form.bindInput('last_name')}
              />

              {showGroups && groups && groups.length !== 0 && (
                <>
                  <UppercaseHeading>
                    <FormattedMessage id="Groups" />
                  </UppercaseHeading>
                  <ListStackBlock>
                    {groups.map((group: TextileGroup) => (
                      <RadioButtonComponent
                        key={group.id}
                        name="group_id"
                        value={group.id}
                        label={group.name}
                        {...form.bindRadioButton(
                          'textile_group_id',
                          group.id.toString()
                        )}
                      />
                    ))}
                  </ListStackBlock>
                </>
              )}
            </>
          )}

          <CheckboxBlock>
            <CheckboxBlock.CheckboxElement
              id="name_verified"
              {...form.bindCheckbox('name_verified')}
            />
            <CheckboxBlock.LabelElement htmlFor="confirm_data">
              <TextElement>
                {textileAppState.textile_profile.is_organizer ? (
                  <FormattedMessage id="confirm print profile orga" />
                ) : (
                  <FormattedMessage id="confirm print profile" />
                )}
              </TextElement>
            </CheckboxBlock.LabelElement>
          </CheckboxBlock>
        </FormStackBlock>

        {renderSaveButton()}
      </>
    );
  };

  return renderForm();
};

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <ProfileEditScreen {...props} form={form} />;
};
