import React, { MouseEvent } from 'react';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';

import { MottoListItemProps } from './MottosSelectListItem';
import TextElement from 'components/TextElement/TextElement';

export const MottosVotingListItem = ({
  motto,
  selected,
  onClick
}: MottoListItemProps) => (
  <EveryCard
    as="button"
    onClick={(e: MouseEvent) => onClick && onClick(e, motto.id)}
    selected={selected}
  >
    <EveryCardPadding>
      <EveryCardBody>
        <EveryCardHeadline>{motto.name}</EveryCardHeadline>
        {motto.tagline && <TextElement>{motto.tagline}</TextElement>}
      </EveryCardBody>

      {selected && <IconComponent icon="TICK_CIRCLE" fill="PRIMARY" size={2} />}
    </EveryCardPadding>
  </EveryCard>
);

export default MottosVotingListItem;
