import HeaderComponent from 'components/HeaderComponent/HeaderComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { HasAccessProvider } from 'contexts/HasAccessContext';
import { useTextileAppStateQuery } from 'queries/textile_deals/useTextileAppStateQueries';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import JoinTextileScreen from 'screens/join/JoinTextileScreen';
import TextileOrderRouter from 'screens/order/screens/textiles/TextileOrderRouter';
import PreOrderRouter from 'screens/preorders/PreOrderRouter';
import TextileCalculatorRouter from 'screens/textile_calculator/TextileCalculatorRouter';
import TextileCheckoutRouter from 'screens/textile_checkout/TextileCheckoutRouter';
import AccountScreen from 'screens/textile_dashboard/AccountScreen';
import TextileDashboardScreen from 'screens/textile_dashboard/TextileDashboardScreen';
import TextileDiscountsScreen from 'screens/textile_dashboard/TextileDiscountsScreen';
import TextileGroupsRouter from 'screens/textile_design_groups/TextileGroupsRouter';
import TextileDesignSettingsRouter from 'screens/textile_design_settings/TextileDesignSettingsRouter';
import TextileManagementRouter from 'screens/textile_management/TextileManagementRouter';
import TextileOnboardingRouter from 'screens/textile_onboarding/TextileOnboardingRouter';
import TextileProfilesRouter from 'screens/textile_profiles/TextileProfilesRouter';
import {
  ROUTE_LOGIN,
  ROUTE_TEXTILE_CALCULATOR,
  ROUTE_TEXTILE_CHECKOUT,
  ROUTE_TEXTILE_DESIGN,
  ROUTE_TEXTILE_DESIGN_GROUPS,
  ROUTE_TEXTILE_MANAGEMENT,
  ROUTE_TEXTILE_ONBOARDING,
  ROUTE_TEXTILE_ORDER,
  ROUTE_TEXTILE_ORDER_OVERVIEW,
  ROUTE_TEXTILE_PREORDER,
  ROUTE_TEXTILE_PROFILES
} from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';

const TextileRoutes: React.FC<HistoryProps> = ({ match, history }) => {
  const textileOrderId = textileOrderIdFromMatch(match);
  const { data, isError, isLoading } = useTextileAppStateQuery(
    textileOrderId
  );

  if (!textileOrderId) {
    return null;
  }

  if (isError) {
    history.push(ROUTE_LOGIN);
  }

  if (isLoading) {
    return (
      <>
        <HeaderComponent />
        <LoadingOverlayComponent />
      </>
    );
  }


  return (
    <HasAccessProvider textileAppState={data} history={history} match={match}>
      <Switch>
        <Route
          path={ROUTE_TEXTILE_ORDER + '/join_textile'}
          component={JoinTextileScreen}
        />

        <Route
          path={ROUTE_TEXTILE_ONBOARDING}
          component={TextileOnboardingRouter}
        />

        <Route
          path={ROUTE_TEXTILE_ORDER + '/account'}
          component={AccountScreen}
        />

        <Route
          path={ROUTE_TEXTILE_DESIGN}
          component={TextileDesignSettingsRouter}
        />

        <Route
          path={ROUTE_TEXTILE_DESIGN_GROUPS}
          component={TextileGroupsRouter}
        />

        <Route
          path={ROUTE_TEXTILE_MANAGEMENT}
          component={TextileManagementRouter}
        />

        <Route
          path={ROUTE_TEXTILE_PROFILES}
          component={TextileProfilesRouter}
        />

        <Route
          path={ROUTE_TEXTILE_ORDER + '/discounts'}
          component={TextileDiscountsScreen}
        />

        <Route path={ROUTE_TEXTILE_PREORDER} component={PreOrderRouter} />

        <Route
          path={ROUTE_TEXTILE_ORDER_OVERVIEW}
          component={TextileOrderRouter}
        />

        <Route
          path={ROUTE_TEXTILE_CALCULATOR}
          component={TextileCalculatorRouter}
        />

        <Route
          path={ROUTE_TEXTILE_CHECKOUT}
          component={TextileCheckoutRouter}
        />

        <Route
          path={ROUTE_TEXTILE_ORDER}
          exact={true}
          component={TextileDashboardScreen}
        />

        <NotFoundRoute />
      </Switch>
    </HasAccessProvider>
  );
};

export default TextileRoutes;
