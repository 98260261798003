import Paragraph from 'components/Paragraph/Paragraph';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface CheckoutLinkProps {
  text: string;
  onClick?: () => void;
  to?: string;
  color?: keyof typeof COLORS;
}

interface CheckoutLinkBlockProps {
  color?: keyof typeof COLORS;
}

const CheckoutLinkBlock = styled.div<CheckoutLinkBlockProps>`
  display: inline-block;
  text-decoration: underline ${(props) => props.color ? COLORS[props.color] : COLORS.PRIMARY};
`;


const CheckoutLink = ({
  text,
  onClick,
  to,
  color
}:
  CheckoutLinkProps
) => {

  if (to) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer">
        <CheckoutLinkBlock color={color}>
          <Paragraph color={color ? color : "PRIMARY"} size="M" weight="BOLD">
            <FormattedMessage id={text} />
          </Paragraph>
        </CheckoutLinkBlock>
      </a>
    )
  }

  if (!!onClick) {
    return (
      <CheckoutLinkBlock onClick={() => onClick()} color={color}>
        <Paragraph color={color ? color : "PRIMARY"} size="M" weight="BOLD">
          <FormattedMessage id={text} />
        </Paragraph>
      </CheckoutLinkBlock>
    )
  }

  return null
}

export default CheckoutLink;
