import { AccountModelType } from 'models/AccountModel';
import { AlbumPageModelType } from 'models/AlbumPageModel';
import { PriceTier } from 'models/BookModel';
import { CandidateModelType } from 'models/CandidateModel';
import { ChapterModelType } from 'models/ChapterModel';
import { BindingType, CheckoutModelType } from 'models/CheckoutModel';
import { CommentModelType } from 'models/CommentModel';
import { DeliveryDateType } from 'models/DeliveryDatesStore';
import { MottoModelType } from 'models/MottoModel';
import { ImageConfig, PhotoModelType, PhotoType } from 'models/PhotoModel';
import { PlaceholderSponsorPageModelType } from 'models/PlaceholderSponsorPageModel';
import { QuestionModelType } from 'models/QuestionModel';
import { QuoteModelType } from 'models/QuoteModel';
import { RankingModelType } from 'models/RankingModel';
import { SchoolModelType } from 'models/SchoolModel';
import { SchoolSignupModelType } from 'models/SchoolSignupModel';
import { SchoolYearModelType } from 'models/SchoolYearModel';
import { SponsorModelType } from 'models/SponsorModel';
import { SponsorPageModelType } from 'models/SponsorPageModel';
import { TextModelType } from 'models/TextModel';
import { sortPositions } from 'utils/item-sorting';
import { destroy, get, patch, post } from './BaseApi';

const uri = encodeURIComponent;

const convertBaseName = (name: string, baseName?: string) =>
  !baseName ? name : baseName + '[' + name + ']';

export const convertToFormData = (
  form: { [key: string]: any },
  baseName?: string,
  fd: FormData = new FormData()
) => {
  for (const fieldName in form) {
    if (form.hasOwnProperty(fieldName)) {
      const data = form[fieldName];
      if (!data && data !== '' && data !== 0 && typeof data !== 'boolean') {
        continue;
      }

      if (data.file && data.file instanceof File) {
        fd.append(convertBaseName(fieldName, baseName), data.file);
      } else if (typeof data === 'boolean') {
        fd.append(
          convertBaseName(fieldName, baseName),
          data ? 'true' : 'false'
        );
      } else if (typeof data === 'object') {
        convertToFormData(data, convertBaseName(fieldName, baseName), fd);
      } else {
        fd.append(convertBaseName(fieldName, baseName), data);
      }
    }
  }

  return fd;
};

export interface SwapResponse {
  first_element: {
    id: number;
    old_sorting: number;
    sorting: number;
  };
  second_element: {
    id: number;
    old_sorting: number;
    sorting: number;
  };
}

export interface CreatePhotoBody {
  photo_type: PhotoType;
  parent_id: number;
  photo: { file: File };
  image_config?: ImageConfig;
}

export interface UpdatePhotoBody {
  id: number;
  photo: { file: File };
  caption?: string;
  image_config?: ImageConfig;
}

export interface UploadAgeForm {
  parental_approval: { file: File };
}

export class NuggitApi {
  // == session ==
  login(mobileNumber: string, password?: string) {
    return post('/authentication/login', {
      mobile_number: mobileNumber,
      password: password || null
    });
  }

  authenticate(identifier: string) {
    return post('/authentication/authenticate', {
      auth_token: identifier
    });
  }

  requestPasswordReset(mobileNumber: string) {
    return post('/authentication/request_password_reset', {
      mobile_number: mobileNumber
    });
  }

  verifyPasswordResetToken(token: string) {
    return post('/authentication/verify_password_reset_token', {
      token
    });
  }

  resetPassword(token: string, newPassword: string) {
    return post('/authentication/reset_password', {
      token,
      password: newPassword
    });
  }

  checkAuthenticated(useRandomParameter = true) {
    let param = '';
    if (useRandomParameter) {
      // Chrome on Android sometimes fetches a cached version of /session API response
      // when navigating back to the web app, e.g. from an inquiry form. User is redirected
      // to login in this case even though they are logged in. Thus forcing Chrome to send
      // request to server by adding a random parameter.
      param = '?r=' + Math.floor(Math.random() * 899999 + 100000);
    }
    return get('/session' + param).catch((error) => {
      if (this.isNotFound(error)) {
        // user is not authenticated
        return Promise.resolve();
      }
      return Promise.reject(error);
    });
  }

  logout() {
    return destroy('/session');
  }

  changePassword(oldPassword: string, password: string) {
    return post('/authentication/change_password', {
      old_password: oldPassword,
      password
    });
  }

  // == signup ==
  join(token: string, account: AccountModelType, signupKey?: string) {
    return post('/authentication/sign_up_by_invitation_token', {
      book: {
        invitation_token: token
      },
      account,
      signup_key: signupKey || undefined
    });
  }

  signup(
    school: SchoolSignupModelType,
    schoolYear: SchoolYearModelType,
    account: AccountModelType,
    signupRef?: string,
    affiliateToken?: string,
    signupKey?: string
  ) {
    return post('/authentication/sign_up_manually', {
      school: school,
      school_year: schoolYear,
      signup_ref: signupRef,
      affiliate_token: affiliateToken,
      account,
      signup_key: signupKey || undefined
    });
  }

  validateNumber(mobileNumber: string) {
    return post('/authentication/validate_number', {
      mobile_number: mobileNumber
    });
  }

  searchSchool(query: string) {
    return post('/schools/search', {
      query
    });
  }

  searchCity(search: { city_name: string }) {
    return post('/cities/search', {
      search
    });
  }

  getCityWithSchools(cityId: number) {
    return get('/cities/' + uri(cityId) + '/city_with_schools');
  }

  signupWithSchool(
    school: SchoolModelType,
    year: number,
    account: AccountModelType,
    signupRef?: string,
    affiliateToken?: string,
    signupKey?: string
  ) {
    return post('/authentication/sign_up_manually', {
      book: {
        school: {
          name: school.name,
          city_name: school.city_name
        },
        year,
        signup_ref: signupRef,
        affiliate_token: affiliateToken
      },
      account,
      signup_key: signupKey || undefined
    });
  }

  // == Checkout ==

  createCheckout(
    pricingInfo: Pick<CheckoutModelType, 'pieces' | 'binding_type'>
  ) {
    return post('/checkout', {
      checkout: {
        // Context that provides the basic info
        context: 'pricing',
        ...pricingInfo
      }
    });
  }

  getCheckout() {
    return get('/checkout');
  }

  updateCheckout(checkoutPatch: any) {
    return patch('/checkout/', {
      checkout: checkoutPatch
    });
  }

  finishCheckout() {
    return patch('/checkout/finish_checkout', {});
  }

  uploadParentalApproval(uploadPatch: UploadAgeForm) {
    return patch(
      '/checkout/upload_age_form',
      convertToFormData(uploadPatch, 'checkout')
    );
  }

  calculateBookPriceCheckout(pricingPatch: any) {
    return post(`/checkout/calculate_price`, {
      pricing: pricingPatch
    });
  }

  // == TextileCheckout

  createTextileCheckout(textileOrderId: number) {
    return post('/textile_checkout', {
      textile_checkout: {},
      textile_order_id: textileOrderId
    });
  }

  getTextileCheckout(textileOrderId: number) {
    return get(`/textile_checkout?textile_order_id=${textileOrderId}`);
  }

  updateTextileCheckout(checkoutPatch: any, textileOrderId: number) {
    return patch('/textile_checkout/', {
      textile_checkout: checkoutPatch,
      textile_order_id: textileOrderId
    });
  }

  finishTextileCheckout(textileOrderId: number) {
    return patch('/textile_checkout/finish_checkout', {
      textile_order_id: textileOrderId
    });
  }

  uploadParentalApprovalTextile(
    uploadPatch: UploadAgeForm,
    textileOrderId: number
  ) {
    return patch(
      `/textile_checkout/upload_age_form?textile_order_id=${textileOrderId}`,
      convertToFormData(uploadPatch, 'textile_checkout')
    );
  }

  //  == TextileDashbaord ==
  getTextileDashboard(textileOrderId: number) {
    return get(`/textile_screen/overview?textile_order_id=${textileOrderId}`);
  }

  getTextileBackSetting(textileOrderId: number) {
    return get(`/textile_screen/overview_back_settings?textile_order_id=${textileOrderId}`);
  }

  getTextileVirtualPreorderOverview(textileOrderId: number) {
    return get(`/textile_screen/overview_virtual_preorders?textile_order_id=${textileOrderId}`);
  }

  getTextileProfilePreorderOverview(textileOrderId: number, textileProfileId?: number) {
    return get(`/textile_screen/overview_profile_preorders?textile_order_id=${textileOrderId}&textile_profile_id=${textileProfileId}`);
  }

  getTextilePreorderOverview(textileOrderId: number) {
    return get(`/textile_screen/overview_preorders?textile_order_id=${textileOrderId}`);
  }

  getOrderCalculatorOverview(textileOrderId: number) {
    return get(`/textile_screen/order_calculator?textile_order_id=${textileOrderId}`);
  }

  getStudentTextileDashboard(textileOrderId: number) {
    return get(`/textile_dashboard/student_dashboard?textile_order_id=${textileOrderId}`);
  }

  getOrderPlacedOverview(textileOrderId: number) {
    return get(`/textile_screen/order_placed_overview?textile_order_id=${textileOrderId}`);
  }


  // == TextileProfiles ==
  getAllTextileProfiles(textileOrderId: number) {
    return get(`/textile_profiles?textile_order_id=${textileOrderId}`);
  }

  getAllOrganizers(textileOrderId: number) {
    return get(
      `/textile_profiles/organizers?textile_order_id=${textileOrderId}`
    );
  }
  getTextileProfile(id: number, textileOrderId: number) {
    return get(
      '/textile_profiles/' + uri(id) + '?textile_order_id=' + textileOrderId
    );
  }

  joinTextileOrder(textileOrderId: number) {
    return post('/textile_profiles/join_textile_order', {
      textile_order_id: textileOrderId
    });
  }

  updateTextileProfile(profile: any, id: number, textileOrderId: number) {
    return patch('/textile_profiles/' + uri(id), {
      textile_profile: profile,
      textile_order_id: textileOrderId
    });
  }

  removeTextileProfile(id: number, textileOrderId: number) {
    return destroy(
      '/textile_profiles/' + uri(id) + '?textile_order_id=' + textileOrderId
    );
  }

  acceptTextileRoleChangeRequest(requestId: number, textileOrderId: number) {
    return post('/textile_role_change_requests/' + uri(requestId) + '/accept', {
      textile_order_id: textileOrderId
    });
  }

  rejectTextileRoleChangeRequest(requestId: number, textileOrderId: number) {
    return post('/textile_role_change_requests/' + uri(requestId) + '/reject', {
      textile_order_id: textileOrderId
    });
  }

  requestTextileOrganizerRole(textileOrderId: number) {
    return post('/textile_role_change_requests', {
      textile_order_id: textileOrderId,
      textile_role_change_request: {
        request_type: 'student_to_organizer'
      }
    });
  }

  requestTextileRoleChange(textileOrderId: number, type: string) {
    return post('/textile_role_change_requests', {
      textile_order_id: textileOrderId,
      textile_role_change_request: {
        request_type: type
      }
    });
  }


  createTextileNotification(textileNotificaiton: any, textileOrderId: number) {
    return post('/textile_notifications', {
      textile_notification: textileNotificaiton,
      textile_order_id: textileOrderId
    });
  }

  updateTextileNotification(
    textileNotificaiton: any,
    notificationId: number,
    textileOrderId: number
  ) {
    return patch('/textile_notifications/' + uri(notificationId), {
      textile_notification: textileNotificaiton,
      textile_order_id: textileOrderId
    });
  }

  submitPreorder(id: number, textileOrderId: number) {
    return get('/textile_profiles/' + uri(id) + '/submit_preorder?textile_order_id=' + textileOrderId);
  }

  rejectPreorder(id: number, textileOrderId: number) {
    return get('/textile_profiles/' + uri(id) + '/reject_preorder?textile_order_id=' + textileOrderId);
  }

  reopenPreorder(id: number, textileOrderId: number) {
    return get('/textile_profiles/' + uri(id) + '/reopen_preorder?textile_order_id=' + textileOrderId);
  }

  // == TextileNames ==
  getAllTextileNames(textileOrderId: number) {
    return get(`/textile_names?textile_order_id=${textileOrderId}`);
  }
  getTextileName(id: number, textileOrderId: number) {
    return get(
      '/textile_names/' + uri(id) + '?textile_order_id=' + textileOrderId
    );
  }

  createTextileName(textileName: any, textileOrderId: number) {
    return post('/textile_names', {
      textile_name: textileName,
      textile_order_id: textileOrderId
    });
  }

  updateTextileName(textileName: any, id: number, textileOrderId: number) {
    return patch('/textile_names/' + uri(id), {
      textile_name: textileName,
      textile_order_id: textileOrderId
    });
  }

  removeTextileName(id: number, textileOrderId: number) {
    return destroy(
      '/textile_names/' + uri(id) + '?textile_order_id=' + textileOrderId
    );
  }
  // == textile discount ==

  getAllTextileDiscounts(textileOrderId: number) {
    return get(`/textile_discounts?textile_order_id=${textileOrderId}`);
  }

  // == book ==
  getBook() {
    return get('/book/');
  }

  setClassSize(size?: number) {
    return patch('/book', {
      book: {
        number_of_students: size || null
      }
    });
  }

  setBookState(state: 'preparing' | 'active' | 'revising') {
    return patch('/book', {
      editing_state: state
    });
  }

  completeOnbaording() {
    return post('/book/complete_onboarding', {})
  }

  generateBookPreview() {
    return post('/book/generate_preview', {});
  }

  dismissBookPreview() {
    return post('/book/dismiss_preview', {});
  }

  requestDesignTicket() {
    return post('/book/request_design_ticket', {});
  }

  requestTextileDesignTicket(id: number) {
    return post('/textile_orders/' + uri(id) + '/request_design_ticket', {});
  }

  applyGroups() {
    return post('/book/apply_groups', {});
  }

  setAllowMottoVote(enable: boolean) {
    return patch('/book', {
      allow_motto_vote: enable
    });
  }

  setTimePlan(date: Date) {
    return post('/book/set_time_plan', {
      book: {
        time_plan: date.toJSON().substring(0, 10)
      }
    });
  }

  getContentNotPrinted() {
    return get('/book/content_not_printed');
  }

  // == textile content not printed ==
  getTextileContentNotPrinted(textileOrderId: number) {
    return get('/textile_orders/' + uri(textileOrderId) + '/content_not_printed');
  }

  // == chapter ==
  getAllChapters(includeBook = false) {
    return get('/chapters' + (includeBook ? '?include_book=true' : ''));
  }

  getChapter(id: number) {
    return get('/chapters/' + uri(id));
  }

  getCoverChapter() {
    return get('/chapters/cover');
  }

  createChapter(chapter: any) {
    return post('/chapters', {
      chapter,
      settings: chapter.settings
    });
  }

  updateChapter(id: number, chapterPatch: any) {
    return patch('/chapters/' + uri(id), {
      chapter: chapterPatch,
      // TODO remove dummy as soon as it isn't required anymore
      settings: { dummy: 'dummy' }
    });
  }

  swapChapters(id: number, swapId: number): Promise<SwapResponse> {
    return patch('/chapters/' + uri(id) + '/swap_sorting', {
      swap_id: swapId
    });
  }

  setChaptersSorting(chapters: ChapterModelType[]): Promise<SwapResponse> {
    return post('/chapters/sorting', {
      positions: sortPositions(chapters)
    });
  }

  updateChapterSettings(chapterId: number, settingsPatch: any) {
    return patch('/chapters/' + uri(chapterId) + '/settings', {
      settings: settingsPatch,
      // TODO remove dummy as soon as it isn't required anymore
      chapter: { dummy: 'dummy' }
    });
  }

  removeChapter(id: number) {
    return destroy('/chapters/' + uri(id));
  }

  splitFactsheetChapter(id: number) {
    return post('/factsheets/' + uri(id) + '/split', {});
  }

  splitYearbookChapter(id: number) {
    return post('/yearbooks/' + uri(id) + '/split', {});
  }

  // == profile ==
  getAllProfiles(includeTeachers: boolean = false) {
    return get('/profiles' + (includeTeachers ? '?include_teachers=true' : ''));
  }

  getProfile(id: number, includeBook: boolean = false) {
    return get(
      '/profiles/' + uri(id) + (includeBook ? '?include_book=yes' : '')
    );
  }

  createProfile(profile: any) {
    return post('/profiles/create_manual_profiles', {
      profile
    });
  }

  joinBook(bookId: number) {
    return post('/profiles/join_book', {
      book_id: bookId
    });
  }

  updateProfile(profile: { id: number; [key: string]: any }) {
    return patch('/profiles/' + uri(profile.id), {
      profile
    });
  }

  updateMobileNumber(accountId: number, mobileNumber: string) {
    return post(
      '/accounts/' + uri(accountId) + '/request_mobile_number_change',
      {
        account: {
          mobile_number_to_confirm: mobileNumber
        }
      }
    );
  }

  removeProfile(id: number) {
    return destroy('/profiles/' + uri(id));
  }

  finishProfile(id: number) {
    return post('/profiles/' + uri(id) + '/finish', {});
  }

  getCurrentPhoto(id: number) {
    return get('/profiles/' + uri(id) + '/current_photo');
  }

  getPastPhoto(id: number) {
    return get('/profiles/' + uri(id) + '/past_photo');
  }

  getFactsheets(chapterId: number) {
    return get(
      '/factsheets' + (!chapterId ? '' : '?chapter_id=' + uri(chapterId))
    );
    // return get('/factsheets/' + uri(chapterId) + '/stats');
  }

  getFactsheetsWithStats(chapterId: number) {
    return get('/factsheets/' + uri(chapterId) + '/stats');
  }

  getFactsheet(profileId: number) {
    return get('/profiles/' + uri(profileId) + '/factsheet');
  }

  getYearbook(profileId: number) {
    return get('/profiles/' + uri(profileId) + '/yearbook');
  }

  acceptRoleChangeRequest(requestId: number) {
    return post('/role_change_requests/' + uri(requestId) + '/accept', {});
  }

  rejectRoleChangeRequest(requestId: number) {
    return post('/role_change_requests/' + uri(requestId) + '/reject', {});
  }

  requestOrganizerRole() {
    return post('/role_change_requests', {
      role_change_request: {
        request_type: 'student_to_organizer'
      }
    });
  }

  requestRoleChange(type: string) {
    return post('/role_change_requests', {
      role_change_request: {
        request_type: type
      }
    });
  }

  // == groups ==
  getAllGroups() {
    return get('/groups');
  }

  getGroup(id: number) {
    return get('/groups/' + uri(id));
  }

  createGroup(group: { name: string }) {
    return post('/groups', {
      group
    });
  }

  updateGroup(groupId: number, groupPatch: any) {
    return patch('/groups/' + uri(groupId), {
      group: groupPatch
    });
  }

  removeGroup(id: number) {
    return destroy('/groups/' + uri(id));
  }

  // == textile groups ==

  getAllTextileGroups(textileOrderId: number) {
    return get(`/textile_groups?textile_order_id=${textileOrderId}`);
  }
  getTextileGroup(id: number, textileOrderId: number) {
    return get(
      '/textile_groups/' + uri(id) + '?textile_order_id=' + textileOrderId
    );
  }

  createTextileGroup(textile_group: { name: string }, textileOrderId: number) {
    return post('/textile_groups', {
      textile_group,
      textile_order_id: textileOrderId
    });
  }

  updateTextileGroup(groupId: number, groupPatch: any, textileOrderId: number) {
    return patch('/textile_groups/' + uri(groupId), {
      textile_group: groupPatch,
      textile_order_id: textileOrderId
    });
  }

  removeTextileGroup(id: number, textileOrderId: number) {
    return destroy(
      '/textile_groups/' + uri(id) + '?textile_order_id=' + textileOrderId
  )
  }

  // setTextileGroupsSorting(
  //   textileGroups: TextileGroupModelType[],
  //   textileOrderId: number
  // ): Promise<SwapResponse> {
  //   return post('/textile_groups/sorting', {
  //     positions: sortPositions(textileGroups),
  //     textile_order_id: textileOrderId
  //   });
  // }

  // == variants ==

  getAllTextileVariants(textileOrderId: number) {
    return get(`/textile_variants?textile_order_id=${textileOrderId}`);
  }

  getAllTextileOrdersTextileVariantsByTextile(textileOrderId: number, textileId: number) {
    return get(`/textile_variants/variants_by_textile?textile_order_id=${textileOrderId}&textile_id=${textileId}`);
  }

  // == quotes ==
  createQuote(quote: QuoteModelType) {
    return post('/quotes', {
      quote
    });
  }

  getAllQuotes(chapterId?: number) {
    return get('/quotes' + (!chapterId ? '' : '?chapter_id=' + uri(chapterId)));
  }

  getQuote(id: number) {
    return get('/quotes/' + uri(id));
  }

  updateQuote(quote: QuoteModelType) {
    return patch('/quotes/' + uri(quote.id), {
      quote
    });
  }

  removeQuote(id: number) {
    return destroy('/quotes/' + uri(id));
  }

  setQuotesSorting(questions: QuoteModelType[]): Promise<SwapResponse> {
    return post('/quotes/sorting', {
      positions: sortPositions(questions)
    });
  }

  // == texts ==
  createText(text: TextModelType) {
    return post('/texts', {
      text
    });
  }

  getAllTexts(chapterId?: number) {
    return get('/texts' + (!chapterId ? '' : '?chapter_id=' + uri(chapterId)));
  }

  getText(id: number) {
    return get('/texts/' + uri(id));
  }

  updateText(text: TextModelType) {
    return patch('/texts/' + uri(text.id), {
      text
    });
  }

  removeText(id: number) {
    return destroy('/texts/' + uri(id));
  }

  // == comments ==
  createComment(comment: any) {
    return post('/comments', {
      comment
    });
  }

  getMyComments() {
    return get('/comments');
  }

  getProfileComments(profileId: number) {
    return get('/profiles/' + uri(profileId) + '/comments');
  }

  getComment(id: number) {
    return get('/comments/' + uri(id));
  }

  updateComment(commentId: number, commentPatch: any) {
    return patch('/comments/' + uri(commentId), {
      comment: commentPatch
    });
  }

  removeComment(id: number) {
    return destroy('/comments/' + uri(id));
  }

  setCommentSorting(comments: CommentModelType[]): Promise<SwapResponse> {
    return post('/comments/sorting', {
      positions: sortPositions(comments)
    });
  }

  // getOwnCommentsCount() {
  //   return get('/comments/own_comments_count');
  // }

  // getMyCommentsCount() {
  //   return get('/comments/my_comments_count');
  // }

  // == rankings ==
  createRanking(ranking: RankingModelType) {
    return post('/rankings', {
      ranking
    });
  }

  getRanking(id: number) {
    return get('/rankings/' + uri(id));
  }

  getAllRankings(chapterId?: number) {
    return get(
      '/rankings' + (!chapterId ? '' : '?chapter_id=' + uri(chapterId))
    );
  }

  updateRanking(ranking: RankingModelType) {
    return patch('/rankings/' + uri(ranking.id), {
      ranking
    });
  }

  removeRanking(id: number) {
    return destroy('/rankings/' + uri(id));
  }

  setRankingSorting(rankings: RankingModelType[]): Promise<SwapResponse> {
    return post('/rankings/sorting', {
      positions: sortPositions(rankings)
    });
  }

  voteForRanking(rankingId: number, candidateIds: number[]) {
    return post('/rankings/' + uri(rankingId) + '/vote', {
      candidate_ids: candidateIds
    });
  }

  unvote(rankingId: number, candidateIds: number[]) {
    return post('/rankings/' + uri(rankingId) + '/unvote', {
      candidate_ids: candidateIds
    });
  }

  // == candidates ==
  createCandidate(candidate: CandidateModelType) {
    return post('/candidates', {
      candidate
    });
  }

  getCandidate(id: number) {
    return get('/candidates/' + uri(id));
  }

  getAllCandidates(chapterId?: number) {
    return get(
      '/candidates' + (!chapterId ? '' : '?chapter_id=' + uri(chapterId))
    );
  }

  updateCandidate(
    candidateId: number,
    candidatePatch: Partial<CandidateModelType>
  ) {
    return patch('/candidates/' + uri(candidateId), {
      candidate: candidatePatch
    });
  }

  removeCandidate(id: number) {
    return destroy('/candidates/' + uri(id));
  }

  // == sponsors ==
  createSponsor(sponsor: SponsorModelType) {
    return post('/sponsors', convertToFormData(sponsor, 'sponsor'));
  }

  getSponsor(id: number) {
    return get('/sponsors/' + uri(id));
  }

  getAllSponsors() {
    return get('/sponsors');
  }

  updateSponsor(sponsor: SponsorModelType) {
    return patch(
      '/sponsors/' + uri(sponsor.id),
      convertToFormData(sponsor, 'sponsor')
    );
  }

  removeSponsor(id: number) {
    return destroy('/sponsors/' + uri(id));
  }

  // == photos and albums ==
  getAllPhotos(chapterId?: number) {
    return get('/photos' + (!chapterId ? '' : '?chapter_id=' + uri(chapterId)));
  }

  getUnplacedPhotos(chapterId: number) {
    return get('/photos/unplaced?chapter_id=' + uri(chapterId));
  }

  getPhoto(id: number) {
    return get('/photos/' + uri(id));
  }

  getPhotoTextile(id: number, textileOrderId: number) {
    return get('/textile_photos/' + uri(id) + `?textile_order_id=${textileOrderId}`);
  }

  createPhoto(photo: CreatePhotoBody) {
    return post(
      `/photos`,
      convertToFormData(photo, 'photo')
    );
  }


  createPhotoTextile(photo: CreatePhotoBody, textileOrderId: number) {
    return post(
      `/textile_photos?textile_order_id=${textileOrderId}`,
      convertToFormData(photo, 'photo')
    );
  }

  updatePhoto(photo: UpdatePhotoBody) {
    return patch('/photos/' + uri(photo.id), convertToFormData(photo, 'photo'));
  }

  updatePhotoTextile(photo: UpdatePhotoBody, textileOrderId: number) {
    return patch('/textile_photos/' + uri(photo.id) + `?textile_order_id=${textileOrderId}`, convertToFormData(photo, 'photo'));
  }

  removePhoto(id: number) {
    return destroy('/photos/' + uri(id));
  }

  removePhotoTextile(id: number, textileOrderId: number) {
    return destroy('/textile_photos/' + uri(id) + `?textile_order_id=${textileOrderId}`);
  }

  // == Album pages ==
  getAlbumPages(chapterId: number) {
    return get('/album_pages?chapter_id=' + uri(chapterId));
  }

  getAlbumPage(id: number) {
    return get('/album_pages/' + uri(id));
  }

  createAlbumPage(albumPage: AlbumPageModelType) {
    return post('/album_pages', convertToFormData(albumPage, 'album_page'));
  }

  removeAlbumPage(id: number) {
    return destroy('/album_pages/' + uri(id));
  }

  swapAlbumPageSorting(id: number, swapId: number): Promise<SwapResponse> {
    return patch('/album_pages/' + uri(id) + '/swap_sorting', {
      swap_id: swapId
    });
  }

  placePhotoOnAlbumPage(
    photo: PhotoModelType,
    albumPage: AlbumPageModelType,
    position: number
  ) {
    return post(`/album_pages/${albumPage.id}/place_photo`, {
      photo_id: photo.id,
      position
    });
  }

  removePhotoFromAlbumPage(
    photo: PhotoModelType,
    albumPage: AlbumPageModelType
  ) {
    return destroy(`/album_pages/${albumPage.id}/remove_photo`, {
      photo_id: photo.id
    });
  }

  // == Placeholder sponsor pages ==
  getPlaceholderSponsorPages() {
    return get('/placeholder_sponsor_pages')
  }

  getPlaceholderSponsorPage(id: number) {
    return get('/placeholder_sponsor_pages/' + uri(id))
  }

  createPlaceholderSponsorPage(placeholderSponsorPage: PlaceholderSponsorPageModelType) {
    return post('/placeholder_sponsor_pages', convertToFormData(placeholderSponsorPage, 'placeholder_sponsor_page'))
  }

  removePlaceholderSponsorPage(id: number) {
    return destroy('/placeholder_sponsor_pages/' + uri(id))
  }

  // == sponsor page sortings ==

  getSponsorPageSortings(chapterId: number) {
    return get('/sponsor_page_sortings?chapter_id=' + uri(chapterId));
  }

  swapSponsorPageSorting(id: number, swapId: number): Promise<SwapResponse> {
    return patch('/sponsor_page_sortings/' + uri(id) + '/swap_sorting', {
      swap_id: swapId
    });
  }

  // == Sponsor pages ==
  getSponsorPages(chapterId: number) {
    return get('/sponsor_pages?chapter_id=' + uri(chapterId));
  }

  getSponsorPage(id: number) {
    return get('/sponsor_pages/' + uri(id));
  }

  createSponsorPage(sponsorPage: SponsorPageModelType) {
    return post(
      '/sponsor_pages',
      convertToFormData(sponsorPage, 'sponsor_page')
    );
  }

  removeSponsorPage(id: number) {
    return destroy('/sponsor_pages/' + uri(id));
  }

  placeSponsorOnSponsorPage(
    sponsor: SponsorModelType,
    sponsorPage: SponsorPageModelType,
    position: number
  ) {
    return post(`/sponsor_pages/${sponsorPage.id}/place_sponsor`, {
      sponsor_id: sponsor.id,
      position
    });
  }

  removeSponsorFromSponsorPage(
    sponsor: SponsorModelType,
    sponsorPage: SponsorPageModelType
  ) {
    return destroy(`/sponsor_pages/${sponsorPage.id}/remove_sponsor`, {
      sponsor_id: sponsor.id
    });
  }

  placeCoverSponsor(sponsor: SponsorModelType, position: number) {
    return post('/sponsor_pages/cover', {
      sponsor_id: sponsor.id,
      position
    });
  }

  removeCoverSponsor() {
    return destroy('/sponsor_pages/cover');
  }

  // == mottos ==
  createMotto(motto: MottoModelType, selectMotto = false) {
    const select = selectMotto ? '?select=yes' : '';
    return post('/mottos' + select, {
      motto
    });
  }

  getMotto(id: number) {
    return get('/mottos/' + uri(id));
  }

  getAllMottos() {
    return get('/mottos');
  }

  updateMotto(motto: MottoModelType, selectMotto = false) {
    const select = selectMotto ? '?select=yes' : '';
    return patch('/mottos/' + uri(motto.id) + select, {
      motto
    });
  }

  removeMotto(id: number) {
    return destroy('/mottos/' + uri(id));
  }

  voteForMottos(mottoIds: number[]) {
    return post('/mottos/vote', {
      motto_ids: mottoIds
    });
  }

  selectMotto(mottoId: number) {
    return post('/mottos/select', {
      selected_motto_id: mottoId
    });
  }

  unselectMotto() {
    return destroy('/mottos/select');
  }

  // == questions ==
  createQuestion(question: QuestionModelType) {
    return post('/questions', {
      question
    });
  }

  getQuestion(id: number) {
    return get('/questions/' + uri(id));
  }

  getAllQuestions(chapterId?: number) {
    return get(
      '/questions' + (!chapterId ? '' : '?chapter_id=' + uri(chapterId))
    );
  }

  updateQuestion(questionId: number, questionPatch: any) {
    return patch('/questions/' + uri(questionId), {
      question: questionPatch
    });
  }

  removeQuestion(id: number) {
    return destroy('/questions/' + uri(id));
  }

  setQuestionSorting(questions: QuestionModelType[]): Promise<SwapResponse> {
    return post('/questions/sorting', {
      positions: sortPositions(questions)
    });
  }

  // == answers ==
  createAnswer(answer: any) {
    return post('/answers', { answer });
  }

  getAnswer(id: number) {
    return get('/answers/' + uri(id));
  }

  updateAnswer(answerId: number, answerPatch: any) {
    return patch('/answers/' + uri(answerId), {
      answer: answerPatch
    });
  }

  removeAnswer(id: number) {
    return destroy('/answers/' + uri(id));
  }

  // == dashboards ==
  getMainDashboard() {
    return get('/dashboard/overview');
  }

  getContentDashboard() {
    return get('/dashboard/content');
  }

  getDiscountDashboard() {
    return get('/dashboard/discounts');
  }

  // == client states ==
  getClientStates() {
    return get('/client_states');
  }

  setClientState(
    identifier: string,
    value: string | null | undefined,
    callSupport: boolean = false,
    supportMessage?: string,
    supportSubject?: string
  ) {
    return patch('/client_states/' + uri(identifier), {
      value,
      call_support: callSupport || undefined,
      message: supportMessage || undefined,
      subject: supportSubject || undefined
    });
  }

  removeClientState(identifier: string) {
    return destroy('/client_states/' + uri(identifier));
  }

  // == textile client states ==

  getTextileClientStates(textileOrderId: number) {
    return get(`/textile_client_states?textile_order_id=${textileOrderId}`);
  }

  setTextileClientState(
    textileOrderId: number,
    identifier: string,
    value: string | null | undefined,
    callSupport: boolean = false,
    supportMessage?: string,
    supportSubject?: string
  ) {
    return patch('/textile_client_states/' + uri(identifier), {
      value,
      call_support: callSupport || undefined,
      message: supportMessage || undefined,
      subject: supportSubject || undefined,
      textile_order_id: textileOrderId
    });
  }

  removeTextileClientState(identifier: string, textileOrderId: number) {
    return destroy('/textile_client_states/' + uri(identifier) + `?textile_order_id=${textileOrderId}`);
  }

  createSupportTicket(subject?: string, message?: string, textileOrderId?: number) {
    return post('/support/call_support', {
      subject,
      message,
      textile_order_id: textileOrderId
    });
  }

  // == calculation ==
  calculateBookPrice(
    pageCount: number,
    pieces: number,
    bindingType: BindingType,
    priceTier: PriceTier
  ) {
    return get(
      `/pricing?pricing[page_count]=${pageCount}&pricing[pieces]=${pieces}&pricing[binding_type]=${bindingType}&pricing[price_tier]=${priceTier}`
    );
  }

  calculateTextilePriceFromForm(
    textiles: { id: number; amount: number }[],
    textileOrderId: number
  ) {
    return post(`/textile_pricing/calculate_form`, {
      textile_pricing: {
        textiles
      },
      textile_order_id: textileOrderId
    });
  }

  calculatePreorderPrice(preorderIds: number[], textileOrderId: number, validateCalculation?: boolean) {
    return post(`/textile_pricing/calculate`, {
      textile_preorder_ids: preorderIds,
      textile_order_id: textileOrderId,
      validate: validateCalculation
    });
  }

  // == delivery dates ==
  getDeliveryDates(type: DeliveryDateType, textileOrderId?: number) {
    if (type === 'book') {
      return get('/delivery_dates');
    }

    return get(`/textile_delivery_dates?textile_order_id=${textileOrderId}`);
  }

  getDeliveryDatesForTimePlan(type: DeliveryDateType, textileOrderId?: number) {
    if (type === 'book') {
      return get('/delivery_dates/for_time_plan');
    }
    return get(
      `/textile_delivery_dates/for_time_plan?textile_order_id=${textileOrderId}`
    );
  }

  chooseDeliveryDate(
    id: number,
    type: DeliveryDateType,
    textileOrderId?: number
  ) {
    if (type === 'book') {
      return patch(`/delivery_dates/${uri(id)}/choose`, undefined);
    }
    return patch(`/textile_delivery_dates/${uri(id)}/choose`, {
      textile_order_id: textileOrderId
    });
  }

  changeDeliveryDateAfterCheckout(
    id: number,
    type: DeliveryDateType,
    textileOrderId?: number
  ) {
    if (type === 'book') {
      return patch(
        `/delivery_dates/${uri(id)}/change_after_checkout`,
        undefined
      );
    }
    return patch(`/textile_delivery_dates/${uri(id)}/change_after_checkout`, {
      textile_order_id: textileOrderId
    });
  }
  // == layouts ==
  getThemes() {
    return get('/layouts/themes');
  }

  getBackgrounds() {
    return get('/layouts/backgrounds');
  }

  getColors() {
    return get('/layouts/colors');
  }

  getFonts() {
    return get('/layouts/fonts');
  }

  getFactsheetLayouts() {
    return get('/layouts/factsheets');
  }

  getQuoteLayouts() {
    return get('/layouts/quotes');
  }

  getRankingLayouts() {
    return get('/layouts/rankings');
  }

  getTextLayouts() {
    return get('/layouts/texts');
  }

  getYearbookLayouts() {
    return get('/layouts/yearbooks');
  }

  // == textile design settings ==
  getTextileDesignSettings(textileOrderId: number) {
    return get(
      '/textile_design_setting?textile_order_id=' + uri(textileOrderId)
    );
  }

  updateTextileDesignSettings(settingsPatch: any, textileOrderId: number) {
    return patch('/textile_design_setting', {
      settings: settingsPatch,
      textile_order_id: textileOrderId
    });
  }

  createTextileDesignSettings(textileOrderId: number) {
    return post('/textile_design_setting', {
      textile_order_id: textileOrderId
    });
  }

  // == books ==
  availableBook() {
    return get('/book/available_book');
  }

  createBook() {
    return post('/book', {});
  }

  // == Textile Themes ==

  getTextileThemes(textileOrderId: number) {
    return get('/textile_layouts/themes?textile_order_id=' + textileOrderId);
  }

  // == Textile Order ==

  getAllTextileOrders() {
    return get('/textile_orders');
  }

  getTextileOrder(id: number) {
    return get('/textile_orders/' + uri(id));
  }

  createTextileOrder() {
    return post('/textile_orders', {});
  }

  updateTextileOrder(settingsPatch: any, textileOrderId: number) {
    return patch('/textile_orders/' + uri(textileOrderId), {
      textile_order: settingsPatch
    });
  }

  generateTextilePreview(textileOrderId: number) {
    return post('/textile_orders/' + uri(textileOrderId)+ '/generate_preview', {});
  }

  getAllStudents(id: number) {
    return get('/textile_orders/' + uri(id) + '/all_students');
  }

  completeOnboardingTextile(id: number) {
    return get('/textile_orders/' + uri(id) + '/complete_onboarding');
  }

  pausePreorder(id: number) {
    return get('/textile_orders/' + uri(id) + '/pause_preorder');
  }

  unpausePreorder(id: number) {
    return get('/textile_orders/' + uri(id) + '/unpause_preorder');
  }

  restartCheckout(id: number) {
    return get('/textile_orders/' + uri(id) + '/restart_checkout');
  }

  startPreorder(id: number) {
    return get('/textile_orders/' + uri(id) + '/start_preorder');
  }

  setTimePlanTextile(date: Date, id: number) {
    return post('/textile_orders/' + uri(id) + '/set_time_plan', {
      textile_order: {
        time_plan: date.toJSON().substring(0, 10)
      }
    });
  }

  // == Textile Preorder ==

  getAllTextilePreorders(textileOrderId: number) {
    return get(`/textile_preorders?textile_order_id=${textileOrderId}`);
  }

  getAllSubmittedTextilePreorders(textileOrderId: number) {
    return get(`/textile_preorders/submitted?textile_order_id=${textileOrderId}`);
  }

  getTextilePreorder(id: number, textileOrderId: number) {
    return get(`/textile_preorders?textile_order_id=${textileOrderId}`);
  }

  createTextilePreorder(patch: any, textileOrderId: number) {
    return post('/textile_preorders', {
      textile_preorder: patch,
      textile_order_id: textileOrderId
    });
  }

  removeTextilePreorder(id: number, textileOrderId: number) {
    return destroy(
      '/textile_preorders/' + uri(id) + '?textile_order_id=' + textileOrderId
    );
  }

  // == Textiles ==

  getAllTextiles(textileOrderId: number) {
    return get(`/textiles?textile_order_id=${textileOrderId}`);
  }

  getTextile(id: number, textileOrderId: number) {
    return get('/textiles/' + uri(id) + `?textile_order_id=${textileOrderId}`);
  }

  // == Textile Colors ==
  getAllColors() {
    return get('/textile_colors');
  }

  // == Textile Checkout Textile Variants ==
  getAllTextileCheckoutTextileVariants(textileOrderId: number) {
    return get(
      `/textile_checkouts_textile_variants?textile_order_id=${textileOrderId}`
    );
  }

  // == Funnel forms ==
  submitFunnel(formData: {
    [key: string]: string | string[] | number | number[] | boolean;
  }) {
    return post('/forms/submit_funnel', formData);
  }

  // == helpers ==
  isUnauthorized(error: any) {
    return error && error.response && error.response.status === 401;
  }

  isNotFound(error: any) {
    return error && error.response && error.response.status === 404;
  }

  isFormError(error: any) {
    return error && error.response && error.response.status === 422;
  }

  isLockedError(error: any) {
    return error && error.response && error.response.status === 423;
  }

  lockedErrorType(error: any) {
    if (!this.isLockedError(error)) {
      return null;
    }

    return error.body && error.body.error
      ? error.body.error
      : 'Generic locked error';
  }
}

export default NuggitApi;
