import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import TrustSymbolsComponent from 'components/TrustSymbolsComponent';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { BookPriceStoreType } from 'models/BookPriceStore';
import { CheckoutStep } from 'models/CheckoutModel';
import { FormType, FormValues } from 'utils/hooks/useForm';

import { CheckoutStoreType } from '../CheckoutStore';
import CheckoutAddress from './CheckoutAddress';
import CheckoutAGB from './CheckoutAGB';
import CheckoutAge from './CheckoutAge';
import CheckoutCalculatePrice from './CheckoutCalculatePrice';
import CheckoutDeliveryDate from './CheckoutDeliveryDate';
import CheckoutDetails from './CheckoutDetails';
import CheckoutSummary from './CheckoutSummary';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

interface CheckoutReviewProps {
  form: FormType;
  handleEdit: (step: CheckoutStep | 'billing') => void;
  finishCheckout: () => void;
  applicationStore: ApplicationStoreType;
  checkoutStore: CheckoutStoreType;
  bookPriceStore: BookPriceStoreType;
}

@observer
export default class CheckoutReview extends Component<CheckoutReviewProps> {
  state = {
    showConfirmBox: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  async updateAgb(formValues: FormValues) {
    // Use the up-to-date form values instead of form.values
    await this.props.checkoutStore.updateCheckout({
      ...formValues,
      context: 'agb'
    });
  }

  finishCheckout() {
    this.props.finishCheckout();
  }

  renderConfirmFinish() {
    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="Checkout confirm choice header" />}
        text={
          <FormattedMessage id="Checkout confirm choice text" />
        }
        confirmText={<FormattedMessage id="Confirm" />}
        abortText={<FormattedMessage id="Cancel" />}
        onAbort={() => {
          this.setState({ showConfirmBox: false });
        }}
        onConfirm={() => {
          this.setState({ showConfirmBox: false });
          this.finishCheckout();
        }}
        confirmColor="PRIMARY"
        abortColor="SECONDARY_DARK"
      />
    );
  }

  render() {
    const {
      form,
      handleEdit,
      applicationStore,
      checkoutStore,
      bookPriceStore
    } = this.props;

    const { book } = applicationStore;
    const { checkout, finishLoadingState } = checkoutStore;

    if (!(book && checkout)) {
      return <GenericErrorComponent />;
    }

    const finishAllowed = form.values.accept_agb === true && book.delivery_date;

    return (
      <PageStackBlock>
        <Headline.Large>
          <FormattedMessage id="Checkout review header" />
        </Headline.Large>
        <Divider />

        <CheckoutDetails
          checkout={checkout}
          book={book}
          handleEdit={() => handleEdit('pricing')}
        />
        <Divider />

        <CheckoutAddress
          checkout={checkout}
          handleEdit={(addressType) => handleEdit(addressType)}
        />
        <Divider />

        <CheckoutAge
          checkout={checkout}
          book={book}
          handleEdit={() => handleEdit('age')}
        />
        <Divider />

        <CheckoutDeliveryDate
          book={book}
          handleEdit={() => handleEdit('delivery_date')}
        />
        <Divider />

        <CheckoutSummary checkout={checkout} book={book} />

        <CheckoutCalculatePrice
          applicationStore={applicationStore}
          bookPriceStore={bookPriceStore}
          checkoutStore={checkoutStore}
        />

        <Divider />
        <CheckoutAGB
          form={form}
          updateAgb={(formValues) => this.updateAgb(formValues)}
        />

        {/* Finish button */}

        <ButtonBlock
          background={finishAllowed ? 'PRIMARY' : 'GRAY950'}
          color={ finishAllowed ? 'WHITE' : 'GRAY800'}
          disabled={!finishAllowed || finishLoadingState === 'loading'}
          onClick={() => {
            this.setState({
              showConfirmBox: true
            });
          }}
        >
          <FormattedMessage id="confirm order" />
        </ButtonBlock>

        {finishLoadingState === 'loading' && <LoadingOverlayComponent />}

        {finishLoadingState === 'error' && <GenericErrorComponent />}

        {this.state.showConfirmBox && this.renderConfirmFinish()}

        <IconListBlock>
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="Free shipping" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="Climate neutral print" />
          </IconListElement>
        </IconListBlock>

        <TrustSymbolsComponent />
      </PageStackBlock>
    );
  }
}
