import React from 'react';

const INITIAL_TIMEOUT = 1000;
const POLLING_FREQUENCY = 100;

/**
 * Waits for all images to be loaded and notifies Electron.
 * See:
 * https://github.com/fraserxu/electron-pdf#to-generate-a-pdf-after-the-an-async-task-in-the-html
 */
class WaitForImagesTextiles extends React.Component {
  private handle?: number;

  componentDidMount() {
    // Start polling after a certain time
    this.handle = window.setTimeout(this.checkImages, INITIAL_TIMEOUT);
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  /**
   * Checks periodically if all images in the document are loaded successful.
   * If so, notify electron.
   */
  checkImages = () => {
    const images = document.getElementsByTagName('img');
    const loaded = Array.from(images).every((image) => image.complete);
    if (loaded) {
      this.setRenderReady();
    } else {
      this.handle = window.setTimeout(this.checkImages, POLLING_FREQUENCY);
    }
  };

  /**
   * Periodically sets class renderReady
   */
  setRenderReady() {
    this.handle = window.requestAnimationFrame(() => {
      if (!document.body.classList.contains('renderReady')) {
        document.body.classList.add('renderReady')
      } else {
        this.clearTimeouts();
      }
      this.setRenderReady();
    });
  }

  clearTimeouts() {
    if (this.handle) {
      window.clearTimeout(this.handle);
      window.cancelAnimationFrame(this.handle);
    }
  }

  render() {
    return null;
  }
}

export default WaitForImagesTextiles;
