import React, { MouseEvent } from 'react';

import {
  EveryCard,
  EveryCardHeadline,
  EveryCardPadding,
  EveryCardBody
} from 'blocks/EveryCard/EveryCard';
import { CandidateModelType } from 'models/CandidateModel';
import IconComponent from 'components/IconComponent';

export interface CandidatesListItemProps {
  candidate: CandidateModelType;
  selected?: boolean;
  onClick?: (e: MouseEvent, studentId: number) => void;
}

export const CandidatesListItem = ({
  candidate,
  selected,
  onClick
}: CandidatesListItemProps) => {
  return (
    <EveryCard
      as={onClick && 'button'}
      onClick={onClick && ((e: MouseEvent) => onClick(e, candidate.id))}
      selected={selected}
    >
      <EveryCardPadding>
        <EveryCardBody>
          <EveryCardHeadline>
            {candidate.name || candidate.id}
          </EveryCardHeadline>
        </EveryCardBody>

        {selected && (
          <IconComponent icon="TICK_CIRCLE" fill="PRIMARY" size={2} />
        )}
      </EveryCardPadding>
    </EveryCard>
  );
};

export default CandidatesListItem;
