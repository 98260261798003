import { Instance, types } from 'mobx-state-tree';

const ThemeMottoModel = types.model('ThemeMottoModel', {
  id: types.identifier,
  name: types.maybe(types.string)
});

export const createThemeMottoModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  const id = data.key || data.id;

  if (!id) {
    return undefined;
  }

  return ThemeMottoModel.create({
    id,
    name: data.name?.de || data.name || undefined
  });
};

export type ThemeMottoModelType = Instance<typeof ThemeMottoModel>;
export default ThemeMottoModel;
