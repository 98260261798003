/* eslint-disable react/style-prop-object */
import React from 'react';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import NakedLink from 'elements/NakedLink';
import { intl } from 'i18n';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const TextilePriceComponent = ({
  endPrice,
  possiblePrice,
  allDiscounts,
  piece,
  range,
  link
}: {
  endPrice?: number;
  possiblePrice?: number;
  allDiscounts?: boolean;
  piece?: boolean;
  range: boolean;
  link: string;
}) => {
  if (!endPrice || !possiblePrice) {
    return null;
  }

  if (range) {
    let formattedPossiblePrice = intl.formatNumber(possiblePrice, {
      style: 'currency',
      currency: 'EUR'
    });
    formattedPossiblePrice = formattedPossiblePrice.substring(
      0,
      formattedPossiblePrice.length - 2
    );

    let formattedEndPrice = intl.formatNumber(endPrice, {
      style: 'currency',
      currency: 'EUR'
    });
    formattedEndPrice = formattedEndPrice.substring(
      0,
      formattedEndPrice.length - 2
    );

    return (
      <>
        <HorizontalStackBlock justified={true} centered={true}>
          <ItemStackBlock gap="XXS">
            <HorizontalStackBlock gap="XXS" centered={true} noMarginLeft={true}>
              <TextElement size="L" weight="BOLD" color="BLACK">
                {piece ? (
                  <FormattedMessage id="Gross price possible piece" />
                ) : (
                  <FormattedMessage id="Gross price possible" />
                )}
              </TextElement>
              <NakedLink to={link}>
                <IconComponent icon="INFO_CIRCLE" />
              </NakedLink>
            </HorizontalStackBlock>
            <Paragraph color="TEXT_LIGHT" size="XS">
              {allDiscounts ? (
                <FormattedMessage id="textile include vat and discounts" />
              ) : (
                <FormattedMessage id="textile include vat and possible discounts" />
              )}
            </Paragraph>
          </ItemStackBlock>

          <Paragraph size="HEADLINE_L" weight="EXTRA_BOLD" noBreak={true}>
            {formattedPossiblePrice} - {formattedEndPrice} €
          </Paragraph>
        </HorizontalStackBlock>
      </>
    );
  } else {
    return (
      <>
        <HorizontalStackBlock justified={true} centered={true}>
          <ItemStackBlock gap="XXS">
            <HorizontalStackBlock gap="XXS" centered={true} noMarginLeft={true}>
              <TextElement size="L" weight="BOLD" color="BLACK">
                <FormattedMessage id="Gross price" />
              </TextElement>
              <NakedLink to={link}>
                <IconComponent icon="INFO_CIRCLE" />
              </NakedLink>
            </HorizontalStackBlock>
            <Paragraph color="TEXT_LIGHT" size="XS">
              <FormattedMessage id="textile include vat and used discounts" />
            </Paragraph>
          </ItemStackBlock>

          <Paragraph size="HEADLINE_L" weight="EXTRA_BOLD" noBreak={true}>
            <FormattedNumber value={endPrice} style="currency" currency="EUR" />
          </Paragraph>
        </HorizontalStackBlock>
      </>
    );
  }
};

export default TextilePriceComponent;
