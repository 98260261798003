// import locale from 'react-intl/locale-data/de';

import messages from './messages-de.json';

export default function() {
  return {
    lang: 'de-DE',
    // locale,
    messages
  };
}
