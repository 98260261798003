import { Instance, types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';

const RoleChangeRequestTypeEnum = types.enumeration([
  'invalid_request_type',
  'applicant_to_student',
  'student_to_organizer',
  'applicant_to_organizer'
]);

const RoleChangeRequestModel = types.model({
  id: types.identifierNumber,
  request_type: RoleChangeRequestTypeEnum,
  profile: types.maybe(ProfileAsAuthorModel),
  created_at: types.maybe(types.string),
  updated_at: types.maybe(types.string)
});

export const createRoleChangeRequestModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return RoleChangeRequestModel.create({
    id: mandatoryId(data.id),
    request_type: RoleChangeRequestTypeEnum.is(data.request_type)
      ? data.request_type
      : 'invalid_request_type',
    profile: createProfileAsAuthorModel(data.profile),
    created_at: data.created_at || undefined,
    updated_at: data.updated_at || undefined
  });
};

export type RoleChangeRequestModelType = Instance<
  typeof RoleChangeRequestModel
>;
export default RoleChangeRequestModel;
