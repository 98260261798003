import styled from 'styled-components';

interface ListItemelementProps {
  noMargin?: boolean;
}

export default styled.li<ListItemelementProps>`
  position: relative;
  margin-bottom: ${props => props.noMargin ? '0' : '1.25em'};
  min-height: 4.375em;
  padding: 0.625em 0 0 5.625em;
`;