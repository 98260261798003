import { intl } from 'i18n';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_MOTTOS, ROUTE_MOTTOS_ORG } from 'utils/constants/routes';
import { HistoryProps, idFromMatch, isPush } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import { MottosStoreType } from '../MottosStore';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import LockedLabel from 'domain/Label/LockedLabel';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import DeleteButton from 'domain/Buttons/DeleteButton';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

interface MottoDetailScreenProps {
  applicationStore: ApplicationStoreType;
  mottosStore: MottosStoreType;
  form: FormType;
}

@inject('applicationStore', 'mottosStore')
@observer
class MottoDetailScreen extends React.Component<
MottoDetailScreenProps & HistoryProps
> {
  async componentDidMount() {
    const { mottosStore, form } = this.props;

    const id = idFromMatch(this.props.match);
    if (id) {
      await this.loadMotto(id);
    } else {
      // new motto mode
      mottosStore.clearCurrentItem();
      form.reset();
    }
  }

  async componentDidUpdate(prevProps: any) {
    const newId = idFromMatch(this.props.match);
    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new text mode
          this.props.mottosStore.clearCurrentItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        await this.loadMotto(newId);
      }
    }
  }

  async loadMotto(id: number) {
    const { mottosStore, form } = this.props;

    if (mottosStore.isItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const checkIfAlreadyInStore = !isPush(this.props.history);
    if (
      checkIfAlreadyInStore &&
      mottosStore.item &&
      mottosStore.item.id === id
    ) {
      form.setField('name', mottosStore.item.name);
      return;
    }

    const motto = await mottosStore.getMotto(id);

    if (motto) {
      form.setField('name', motto.name);
    }

    return motto;
  }

  navigateToList() {
    const { applicationStore, history } = this.props;

    history.replace(
      applicationStore.isOrganizer && !this.isFromVoting()
        ? ROUTE_MOTTOS_ORG
        : ROUTE_MOTTOS
    );
  }

  // isSelectMode() {
  //   const {
  //     history: { location }
  //   } = this.props;

  //   return location.query && location.query.select === 'yes' ? true : false;
  // }

  getMode() {
    const {
      history: { location },
      match: { params }
    } = this.props;

    if (location.query?.select === 'yes') {
      return 'select';
    }

    if (params.id === 'new' || !params.id) {
      return 'new';
    }

    return 'edit';
  }

  isFromSetup() {
    const {
      history: { location }
    } = this.props;

    return location.query && location.query.setup === 'yes' ? true : false;
  }

  isFromVoting() {
    const {
      history: { location }
    } = this.props;

    return location.query && location.query.voting === 'yes' ? true : false;
  }

  async handleSubmit() {
    const { form, mottosStore } = this.props;
    const { name } = form.values;

    form.resetErrors();

    mottosStore.patchItem({
      name: !name ? '' : name.trim()
    });

    try {
      await mottosStore.storeItem(this.getMode() === 'select');
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by store internally
      return;
    }

    this.navigateToList();
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  async performDelete() {
    const { applicationStore, mottosStore } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return;
    }

    this.finishDelete();

    try {
      await mottosStore.removeMotto(id);

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'motto deleted flash' })
      );
    } catch (error: any) {
      // delete errors are handled by MottosStore internally
      return;
    }

    this.navigateToList();
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  renderReadOnly() {
    const { mottosStore } = this.props;

    return this.renderPage(
      <>
        <LockedLabel id="Mottos" />

        <Paragraph>{mottosStore.item && mottosStore.item.name}</Paragraph>
      </>
    );
  }

  renderEditForm() {
    const { form, mottosStore } = this.props;

    const mode = this.getMode();
    const isSelectMode = mode === 'select';
    const isEditMode = mode === 'edit';

    const votesCount = (isEditMode && mottosStore.item?.votes_count) || 0;

    return this.renderPage(
      <>
        {mottosStore.itemLoadingState === 'update_error' && (
          <GenericErrorComponent />
        )}

        <TextInputComponent
          name="name"
          label={intl.formatMessage({
            id: isSelectMode
              ? 'Your(plural) motto'
              : isEditMode
                ? 'Motto'
                : 'Your proposal'
          })}
          {...form.bindInput('name')}
          autoFocus={true}
        />

        {!!votesCount && (
          <Paragraph>
            <FormattedMessage
              id="votes count short"
              values={{ count: votesCount }}
            />
          </Paragraph>
        )}

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>

        {isEditMode && (
          <>
            <Divider />
            <DeleteButton messageId="Delete motto" onClick={() => this.confirmDelete()} />
          </>
        )}

        {form.confirm && (
          <ConfirmBoxComponent
            header={<FormattedMessage id="motto delete confirm" />}
            text={<FormattedMessage id="motto delete confirm text" />}
            confirmText={<FormattedMessage id="Remove" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => this.performDelete()}
            onAbort={() => this.finishDelete()}
            confirmColor="RED"
          />
        )}
      </>
    );
  }

  renderPage(content: any) {
    const mode = this.getMode();
    const isSelectMode = mode === 'select';
    const isEditMode = mode === 'edit';

    return (
      <>
        <TopActionBlock>
          <CancelLink
            to={
              this.isFromSetup() ||
                isEditMode ||
                (this.props.applicationStore.isOrganizer && !this.isFromVoting())
                ? ROUTE_MOTTOS_ORG
                : ROUTE_MOTTOS + (isSelectMode ? '/select' : '')
            }
          />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage
              id={
                isSelectMode
                  ? 'motto add select header'
                  : isEditMode
                    ? 'motto edit header'
                    : 'motto add header'
              }
            />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { mottosStore, applicationStore } = this.props;

    if (mottosStore.isItemLoading) {
      return this.renderLoading();
    }

    if (mottosStore.itemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (applicationStore.isBookLocked) {
      return this.renderReadOnly();
    }

    return this.renderEditForm();
  }
}

export default (props: any) => {
  const form = useForm();
  return <MottoDetailScreen {...props} form={form} />;
};
