import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardFooter,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import UppercaseHeading from 'elements/UppercaseHeading';
import WordWrap from 'elements/WordWrap';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import { createChapterModel } from 'models/ChapterModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import useForm, { FormType } from 'utils/hooks/useForm';

import Divider from 'components/Divider/Divider';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { FactsheetsStoreType } from '../../FactsheetsStore';

interface QuotesListProps {
  factsheetsStore: FactsheetsStoreType;
  chaptersStore: ChaptersStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('factsheetsStore', 'chaptersStore', 'applicationStore')
@observer
class QuestionsListScreen extends React.Component<
QuotesListProps & HistoryProps
> {
  componentDidMount() {
    const { factsheetsStore } = this.props;

    if (!factsheetsStore.isFactsheetsLoading) {
      if (!factsheetsStore.factsheets || isPush(this.props.history)) {
        this.loadQuestions();
      }
    }
  }

  componentDidUpdate(prevProps: HistoryProps) {
    const chapterId = chapterIdFromMatch(this.props.match);
    const prevChapterId = chapterIdFromMatch(prevProps.match);

    if (chapterId !== prevChapterId) {
      this.loadQuestions();
    }
  }

  loadQuestions() {
    const { factsheetsStore } = this.props;
    const chapterId = chapterIdFromMatch(this.props.match);

    if (!chapterId) {
      return;
    }

    factsheetsStore.getQuestionsByChapter(chapterId);
  }

  async changeQuestionsState(newState: boolean) {
    const { chaptersStore, factsheetsStore, form } = this.props;

    if (!factsheetsStore.questionsChapter) {
      return;
    }

    form.setLoading(true);

    const chapter = await chaptersStore.updateChapterSettings(
      factsheetsStore.questionsChapter.id,
      {
        questions_on: newState
      }
    );

    if (chapter) {
      factsheetsStore.setQuestionChapter(createChapterModel(chapter)!);
    }

    form.setLoading(false);
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadQuestions()} />
    );
  }

  renderList() {
    const { factsheetsStore } = this.props;
    const chapter = factsheetsStore.questionsChapter;

    if (!chapter) {
      return null;
    }

    const settings:
      | FactsheetChapterSettingsModelType
      | undefined = chapter.settings as FactsheetChapterSettingsModelType;
    const isActive = !!settings?.questions_on;

    const questionsHeader = settings.questions_header || '';
    const allQuestions =
      (isActive && factsheetsStore.allQuestions) || undefined;

    const isTeacherFactsheets = chapter.chapter_type === 'teacher_factsheet';

    const list = isActive ? (
      <>
        <Link
          to={ROUTE_FACTSHEETS + '/' + chapter.id + '/questions/header_text'}
        >
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="Factsheet text header" />
                </EveryCardHeadline>
                {questionsHeader && (
                  <TextElement>
                    <WordWrap>{questionsHeader}</WordWrap>
                  </TextElement>
                )}
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </Link>

        <Divider />

        <ButtonBlock
          background="PRIMARY"
          onClick={() =>
            this.props.history.push(
              ROUTE_FACTSHEETS + '/' + chapter.id + '/questions/add'
            )
          }
        >
          <FormattedMessage id="Add question" />
        </ButtonBlock>

        <Link to={isTeacherFactsheets ?  ROUTE_FACTSHEETS + '/' + chapter.id + '/questions/ideas_teachers' :  ROUTE_FACTSHEETS + '/' + chapter.id + '/questions/ideas'}>
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="question ideas" />
                </EveryCardHeadline>
                <TextElement>
                  <FormattedMessage id="question ideas info" />
                </TextElement>
              </EveryCardBody>
              <IconComponent icon="LIGHTBULB" fill="BLACK" size={2} />
            </EveryCardPadding>
          </EveryCard>
        </Link>

        <Divider />

        {!!allQuestions?.length ? (
          <>
            {allQuestions.length > 1 && (
              <ButtonBlock
                inline={true}
                slim={true}
                background="PRIMARY_LIGHT"
                color="PRIMARY_DARK"
                onClick={() =>
                  this.props.history.push(
                    ROUTE_FACTSHEETS + '/' + chapter.id + '/questions/sort'
                  )
                }
              >
                <IconComponent icon="sort_v" />
                <FormattedMessage id="Sorting order" />
              </ButtonBlock>
            )}

            <ItemStackBlock gap="S">
              <UppercaseHeading>
                <FormattedMessage
                  id="questions count"
                  values={{ count: allQuestions.length }}
                />
              </UppercaseHeading>

              <ListStackBlock>
                {allQuestions.map((question) => (
                  <Link
                    key={question.id}
                    to={
                      ROUTE_FACTSHEETS +
                      '/' +
                      chapter.id +
                      '/questions/' +
                      question.id
                    }
                  >
                    <EveryCard>
                      <EveryCardPadding>
                        <EveryCardBody>
                          <EveryCardHeadline>
                            <WordWrap>{question.question}</WordWrap>
                          </EveryCardHeadline>
                        </EveryCardBody>
                      </EveryCardPadding>
                      {!question.print && (
                        <EveryCardFooter>
                          <TextElement  color="TEXT_DARK">
                            <IconComponent icon="WARNING_CIRCLE" fill="RED" />
                            <FormattedMessage id="Will not be printed" />
                          </TextElement>
                        </EveryCardFooter>
                      )}
                    </EveryCard>
                  </Link>
                ))}
              </ListStackBlock>
            </ItemStackBlock>
          </>
        ) : (
          <EmptyStateComponent
            headerId="empty questions header"
            textId="empty questions"
          />
        )}
      </>
    ) : null;

    return this.renderPage(
      <>
        <Paragraph  color="TEXT_LIGHT">
          <FormattedMessage id={isTeacherFactsheets ? "teacher questions settings info" : "questions settings info"} />
        </Paragraph>

        <CustomCheckboxBlock>
          <CustomCheckboxBlock.CheckboxElement
            id="state_checkbox"
            checked={isActive}
            disabled={false}
            onChange={() => this.changeQuestionsState(isActive ? false : true)}
          />
          <CustomCheckboxBlock.LabelElement htmlFor="state_checkbox">
            <FormattedMessage id="Questions enabled" />
          </CustomCheckboxBlock.LabelElement>
        </CustomCheckboxBlock>

        {list}
      </>
    );
  }

  renderPage(content: any) {
    const { factsheetsStore } = this.props;
    const chapter = factsheetsStore.questionsChapter;

    if (!chapter) {
      return null;
    }

    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_FACTSHEETS + '/' + chapter.id + '/settings'} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Questions" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { applicationStore, factsheetsStore } = this.props;

    if (!applicationStore.isOrganizer) {
      return null;
    }

    if (factsheetsStore.isFactsheetsLoading || this.props.form.loading) {
      return this.renderLoading();
    }

    if (factsheetsStore.isFactsheetsError) {
      return this.renderError();
    }

    // TODO check if still editable here?

    return this.renderList();
  }
}

export default (props: any) => {
  const form = useForm();
  // @ts-ignore
  return <QuestionsListScreen {...props} form={form} />;
};
