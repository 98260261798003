import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EveryCard,
  EveryCardBody,
  EveryCardFooter,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';
import PreserveWhitespace from 'elements/PreserveWhitespace';
import WordWrap from 'elements/WordWrap';
import { QuestionModelType } from 'models/QuestionModel';
import TextElement from 'components/TextElement/TextElement';
import Paragraph from 'components/Paragraph/Paragraph';

export const QuestionsAnswerListItem = ({
  question
}: {
  question: QuestionModelType;
}) => {
  const answer = question.answer ? (
    question.answer.unanswered ? (
      <WordWrap>
        <TextElement  color="GRAY10">
          <FormattedMessage id="Answer unanswered" />
        </TextElement>
      </WordWrap>
    ) : question.answer.answer ? (
      <WordWrap>
        <PreserveWhitespace>
          <TextElement  color="GRAY10">{question.answer.answer}</TextElement>
        </PreserveWhitespace>
      </WordWrap>
    ) : undefined
  ) : (
    <WordWrap>
      <TextElement  color="GRAY10">
        <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />
        <FormattedMessage id="Answer still missing" />
      </TextElement>
    </WordWrap>
  );

  return (
    <EveryCard>
      <EveryCardPadding>
        <EveryCardBody>
          <EveryCardHeadline>{question.question}</EveryCardHeadline>
          {answer && <Paragraph>{answer}</Paragraph>}
        </EveryCardBody>
      </EveryCardPadding>
      {question.answer?.print === false && !question.answer?.unanswered && (
        <EveryCardFooter>
          <TextElement  color="TEXT_DARK">
            <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
            <FormattedMessage id="Will not be printed" />
          </TextElement>
        </EveryCardFooter>
      )}
    </EveryCard>
  );
};

export default QuestionsAnswerListItem;
