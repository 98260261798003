import { GridTemplate } from './grid-template';

export const ALBUM_PAGE_GRID_LIST: GridTemplate[] = [
  {
    key: 'grid-fullsize',
    preview: 'grid-fullsize.jpg',
    titleEnabled: false,
    noBackground: true,
    slots: [
      {
        id: 1,
        size: [840, 1188],
        position: [12, 12]
      }
    ]
  },
  {
    key: 'grid-1-titled',
    preview: 'grid-1-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [679, 949],
        position: [92, 171]
      }
    ]
  },
  {
    key: 'grid-1',
    preview: 'grid-1.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [679, 1028],
        position: [92, 92]
      }
    ]
  },
  {
    key: 'grid-2-titled',
    preview: 'grid-2-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [334, 468],
        position: [92, 171]
      },
      {
        id: 2,
        size: [334, 468],
        position: [438, 171]
      },
      {
        id: 3,
        size: [334, 468],
        position: [92, 651]
      },
      {
        id: 4,
        size: [334, 468],
        position: [438, 651]
      }
    ]
  },
  {
    key: 'grid-2',
    preview: 'grid-2.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [334, 508],
        position: [92, 92]
      },
      {
        id: 2,
        size: [334, 508],
        position: [438, 92]
      },
      {
        id: 3,
        size: [334, 508],
        position: [92, 612]
      },
      {
        id: 4,
        size: [334, 508],
        position: [438, 612]
      }
    ]
  },
  {
    key: 'grid-3-titled',
    preview: 'grid-3-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [218, 228],
        position: [92, 171]
      },
      {
        id: 2,
        size: [218, 228],
        position: [322, 171]
      },
      {
        id: 3,
        size: [218, 228],
        position: [553, 171]
      },
      {
        id: 4,
        size: [218, 228],
        position: [92, 411]
      },
      {
        id: 5,
        size: [218, 228],
        position: [322, 411]
      },
      {
        id: 6,
        size: [218, 228],
        position: [553, 411]
      },
      {
        id: 7,
        size: [218, 228],
        position: [92, 651]
      },
      {
        id: 8,
        size: [218, 228],
        position: [322, 651]
      },
      {
        id: 9,
        size: [218, 228],
        position: [553, 651]
      },
      {
        id: 10,
        size: [218, 228],
        position: [92, 891]
      },
      {
        id: 11,
        size: [218, 228],
        position: [322, 891]
      },
      {
        id: 12,
        size: [218, 228],
        position: [553, 891]
      }
    ]
  },
  {
    key: 'grid-3',
    preview: 'grid-3.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [218, 248],
        position: [92, 92]
      },
      {
        id: 2,
        size: [218, 248],
        position: [322, 92]
      },
      {
        id: 3,
        size: [218, 248],
        position: [553, 92]
      },
      {
        id: 4,
        size: [218, 248],
        position: [92, 352]
      },
      {
        id: 5,
        size: [218, 248],
        position: [322, 352]
      },
      {
        id: 6,
        size: [218, 248],
        position: [553, 352]
      },
      {
        id: 7,
        size: [218, 248],
        position: [92, 612]
      },
      {
        id: 8,
        size: [218, 248],
        position: [322, 612]
      },
      {
        id: 9,
        size: [218, 248],
        position: [553, 612]
      },
      {
        id: 10,
        size: [218, 248],
        position: [92, 872]
      },
      {
        id: 11,
        size: [218, 248],
        position: [322, 872]
      },
      {
        id: 12,
        size: [218, 248],
        position: [553, 872]
      }
    ]
  },
  {
    key: 'grid-4-titled',
    preview: 'grid-4-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [679, 468],
        position: [92, 171]
      },
      {
        id: 2,
        size: [679, 468],
        position: [92, 651]
      }
    ]
  },
  {
    key: 'grid-4',
    preview: 'grid-4.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [679, 508],
        position: [92, 92]
      },
      {
        id: 2,
        size: [679, 508],
        position: [92, 612]
      }
    ]
  },
  {
    key: 'grid-5-titled',
    preview: 'grid-5-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [679, 468],
        position: [92, 171]
      },
      {
        id: 2,
        size: [334, 468],
        position: [92, 651]
      },
      {
        id: 3,
        size: [334, 468],
        position: [438, 651]
      }
    ]
  },
  {
    key: 'grid-5',
    preview: 'grid-5.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [679, 508],
        position: [92, 92]
      },
      {
        id: 2,
        size: [334, 508],
        position: [92, 612]
      },
      {
        id: 3,
        size: [334, 508],
        position: [438, 612]
      }
    ]
  },
  {
    key: 'grid-6-titled',
    preview: 'grid-6-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [334, 468],
        position: [92, 171]
      },
      {
        id: 2,
        size: [334, 468],
        position: [438, 171]
      },
      {
        id: 3,
        size: [679, 468],
        position: [92, 651]
      }
    ]
  },
  {
    key: 'grid-6',
    preview: 'grid-6.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [334, 508],
        position: [92, 92]
      },
      {
        id: 2,
        size: [334, 508],
        position: [438, 92]
      },
      {
        id: 3,
        size: [679, 508],
        position: [92, 612]
      }
    ]
  },
  {
    key: 'grid-7-titled',
    preview: 'grid-7-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [218, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [218, 308],
        position: [322, 171]
      },
      {
        id: 3,
        size: [218, 308],
        position: [553, 171]
      },
      {
        id: 4,
        size: [218, 308],
        position: [92, 491]
      },
      {
        id: 5,
        size: [218, 308],
        position: [322, 491]
      },
      {
        id: 6,
        size: [218, 308],
        position: [553, 491]
      },
      {
        id: 7,
        size: [679, 308],
        position: [92, 811]
      }
    ]
  },
  {
    key: 'grid-7',
    preview: 'grid-7.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [218, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [218, 335],
        position: [322, 92]
      },
      {
        id: 3,
        size: [218, 335],
        position: [553, 92]
      },
      {
        id: 4,
        size: [218, 335],
        position: [92, 439]
      },
      {
        id: 5,
        size: [218, 335],
        position: [322, 439]
      },
      {
        id: 6,
        size: [218, 335],
        position: [553, 439]
      },
      {
        id: 7,
        size: [679, 335],
        position: [92, 785]
      }
    ]
  },
  {
    key: 'grid-8-titled',
    preview: 'grid-8-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [679, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [218, 308],
        position: [92, 491]
      },
      {
        id: 3,
        size: [218, 308],
        position: [322, 491]
      },
      {
        id: 4,
        size: [218, 308],
        position: [553, 491]
      },
      {
        id: 5,
        size: [218, 308],
        position: [92, 811]
      },
      {
        id: 6,
        size: [218, 308],
        position: [322, 811]
      },
      {
        id: 7,
        size: [218, 308],
        position: [553, 811]
      }
    ]
  },
  {
    key: 'grid-8',
    preview: 'grid-8.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [679, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [218, 335],
        position: [92, 439]
      },
      {
        id: 3,
        size: [218, 335],
        position: [322, 439]
      },
      {
        id: 4,
        size: [218, 335],
        position: [553, 439]
      },
      {
        id: 5,
        size: [218, 335],
        position: [92, 785]
      },
      {
        id: 6,
        size: [218, 335],
        position: [322, 785]
      },
      {
        id: 7,
        size: [218, 335],
        position: [553, 785]
      }
    ]
  },
  {
    key: 'grid-9-titled',
    preview: 'grid-9-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [218, 468],
        position: [92, 171]
      },
      {
        id: 2,
        size: [218, 468],
        position: [322, 171]
      },
      {
        id: 3,
        size: [218, 468],
        position: [553, 171]
      },
      {
        id: 4,
        size: [679, 468],
        position: [92, 651]
      }
    ]
  },
  {
    key: 'grid-9',
    preview: 'grid-9.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [218, 508],
        position: [92, 92]
      },
      {
        id: 2,
        size: [218, 508],
        position: [322, 92]
      },
      {
        id: 3,
        size: [218, 508],
        position: [553, 92]
      },
      {
        id: 4,
        size: [679, 508],
        position: [92, 612]
      }
    ]
  },
  {
    key: 'grid-10-titled',
    preview: 'grid-10-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [218, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [218, 308],
        position: [322, 171]
      },
      {
        id: 3,
        size: [218, 308],
        position: [553, 171]
      },
      {
        id: 4,
        size: [218, 308],
        position: [92, 491]
      },
      {
        id: 5,
        size: [218, 308],
        position: [322, 491]
      },
      {
        id: 6,
        size: [218, 308],
        position: [553, 491]
      },
      {
        id: 7,
        size: [218, 308],
        position: [92, 811]
      },
      {
        id: 8,
        size: [218, 308],
        position: [322, 811]
      },
      {
        id: 9,
        size: [218, 308],
        position: [553, 811]
      }
    ]
  },
  {
    key: 'grid-10',
    preview: 'grid-10.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [218, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [218, 335],
        position: [322, 92]
      },
      {
        id: 3,
        size: [218, 335],
        position: [553, 92]
      },
      {
        id: 4,
        size: [218, 335],
        position: [92, 439]
      },
      {
        id: 5,
        size: [218, 335],
        position: [322, 439]
      },
      {
        id: 6,
        size: [218, 335],
        position: [553, 439]
      },
      {
        id: 7,
        size: [218, 335],
        position: [92, 785]
      },
      {
        id: 8,
        size: [218, 335],
        position: [322, 785]
      },
      {
        id: 9,
        size: [218, 335],
        position: [553, 785]
      }
    ]
  },
  {
    key: 'grid-11-titled',
    preview: 'grid-11-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [334, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [334, 308],
        position: [438, 171]
      },
      {
        id: 3,
        size: [334, 308],
        position: [92, 491]
      },
      {
        id: 4,
        size: [334, 308],
        position: [438, 491]
      },
      {
        id: 5,
        size: [334, 308],
        position: [92, 811]
      },
      {
        id: 6,
        size: [334, 308],
        position: [438, 811]
      }
    ]
  },
  {
    key: 'grid-11',
    preview: 'grid-11.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [334, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [334, 335],
        position: [438, 92]
      },
      {
        id: 3,
        size: [334, 335],
        position: [92, 439]
      },
      {
        id: 4,
        size: [334, 335],
        position: [438, 439]
      },
      {
        id: 5,
        size: [334, 335],
        position: [92, 785]
      },
      {
        id: 6,
        size: [334, 335],
        position: [438, 785]
      }
    ]
  },
  {
    key: 'grid-12-titled',
    preview: 'grid-12-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [679, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [679, 308],
        position: [92, 491]
      },
      {
        id: 3,
        size: [679, 308],
        position: [92, 811]
      }
    ]
  },
  {
    key: 'grid-12',
    preview: 'grid-12.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [679, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [679, 335],
        position: [92, 439]
      },
      {
        id: 3,
        size: [679, 335],
        position: [92, 785]
      }
    ]
  },
  {
    key: 'grid-13-titled',
    preview: 'grid-13-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [679, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [679, 308],
        position: [92, 491]
      },
      {
        id: 3,
        size: [334, 308],
        position: [92, 811]
      },
      {
        id: 4,
        size: [334, 308],
        position: [438, 811]
      }
    ]
  },
  {
    key: 'grid-13',
    preview: 'grid-13.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [679, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [679, 335],
        position: [92, 439]
      },
      {
        id: 3,
        size: [334, 335],
        position: [92, 785]
      },
      {
        id: 4,
        size: [334, 335],
        position: [438, 785]
      }
    ]
  },
  {
    key: 'grid-14-titled',
    preview: 'grid-14-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [679, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [334, 308],
        position: [92, 491]
      },
      {
        id: 3,
        size: [334, 308],
        position: [438, 491]
      },
      {
        id: 4,
        size: [679, 308],
        position: [92, 811]
      }
    ]
  },
  {
    key: 'grid-14',
    preview: 'grid-14.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [679, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [334, 335],
        position: [92, 439]
      },
      {
        id: 3,
        size: [334, 335],
        position: [438, 439]
      },
      {
        id: 4,
        size: [679, 335],
        position: [92, 785]
      }
    ]
  },
  {
    key: 'grid-15-titled',
    preview: 'grid-15-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [334, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [334, 308],
        position: [438, 171]
      },
      {
        id: 3,
        size: [679, 308],
        position: [92, 491]
      },
      {
        id: 4,
        size: [679, 308],
        position: [92, 811]
      }
    ]
  },
  {
    key: 'grid-15',
    preview: 'grid-15.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [334, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [334, 335],
        position: [438, 92]
      },
      {
        id: 3,
        size: [679, 335],
        position: [92, 439]
      },
      {
        id: 4,
        size: [679, 335],
        position: [92, 785]
      }
    ]
  },
  {
    key: 'grid-16-titled',
    preview: 'grid-16-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [334, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [334, 308],
        position: [438, 171]
      },
      {
        id: 3,
        size: [334, 308],
        position: [92, 491]
      },
      {
        id: 4,
        size: [334, 308],
        position: [438, 491]
      },
      {
        id: 5,
        size: [679, 308],
        position: [92, 811]
      }
    ]
  },
  {
    key: 'grid-16',
    preview: 'grid-16.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [334, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [334, 335],
        position: [438, 92]
      },
      {
        id: 3,
        size: [334, 335],
        position: [92, 439]
      },
      {
        id: 4,
        size: [334, 335],
        position: [438, 439]
      },
      {
        id: 5,
        size: [679, 335],
        position: [92, 785]
      }
    ]
  },
  {
    key: 'grid-17-titled',
    preview: 'grid-17-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [334, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [334, 308],
        position: [438, 171]
      },
      {
        id: 3,
        size: [679, 308],
        position: [92, 491]
      },
      {
        id: 4,
        size: [334, 308],
        position: [92, 811]
      },
      {
        id: 5,
        size: [334, 308],
        position: [438, 811]
      }
    ]
  },
  {
    key: 'grid-17',
    preview: 'grid-17.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [334, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [334, 335],
        position: [438, 92]
      },
      {
        id: 3,
        size: [679, 335],
        position: [92, 439]
      },
      {
        id: 4,
        size: [334, 335],
        position: [92, 785]
      },
      {
        id: 5,
        size: [334, 335],
        position: [438, 785]
      }
    ]
  },
  {
    key: 'grid-18-titled',
    preview: 'grid-18-titled.jpg',
    titleEnabled: true,
    slots: [
      {
        id: 1,
        size: [679, 308],
        position: [92, 171]
      },
      {
        id: 2,
        size: [334, 308],
        position: [92, 491]
      },
      {
        id: 3,
        size: [334, 308],
        position: [438, 491]
      },
      {
        id: 4,
        size: [334, 308],
        position: [92, 811]
      },
      {
        id: 5,
        size: [334, 308],
        position: [438, 811]
      }
    ]
  },
  {
    key: 'grid-18',
    preview: 'grid-18.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [679, 335],
        position: [92, 92]
      },
      {
        id: 2,
        size: [334, 335],
        position: [92, 439]
      },
      {
        id: 3,
        size: [334, 335],
        position: [438, 439]
      },
      {
        id: 4,
        size: [334, 335],
        position: [92, 785]
      },
      {
        id: 5,
        size: [334, 335],
        position: [438, 785]
      }
    ]
  },
  {
    key: 'grid-portrait',
    preview: 'grid-portrait.jpg',
    titleEnabled: false,
    slots: [
      {
        id: 1,
        size: [218, 246],
        position: [92, 92],
        caption: {
          size: [218, 75]
        }
      },
      {
        id: 2,
        size: [218, 246],
        position: [322, 92],
        caption: {
          size: [218, 75]
        }
      },
      {
        id: 3,
        size: [218, 246],
        position: [553, 92],
        caption: {
          size: [218, 75]
        }
      },
      {
        id: 4,
        size: [218, 246],
        position: [92, 439],
        caption: {
          size: [218, 75]
        }
      },
      {
        id: 5,
        size: [218, 246],
        position: [322, 439],
        caption: {
          size: [218, 75]
        }
      },
      {
        id: 6,
        size: [218, 246],
        position: [553, 439],
        caption: {
          size: [218, 75]
        }
      },
      {
        id: 7,
        size: [218, 246],
        position: [92, 785],
        caption: {
          size: [218, 75]
        }
      },
      {
        id: 8,
        size: [218, 246],
        position: [322, 785],
        caption: {
          size: [218, 75]
        }
      },
      {
        id: 9,
        size: [218, 246],
        position: [553, 785],
        caption: {
          size: [218, 75]
        }
      }
    ]
  }
];

export const ALBUM_PAGE_GRIDS = ALBUM_PAGE_GRID_LIST.reduce<{
  [key: string]: GridTemplate;
}>((result, gridTemplate) => {
  result[gridTemplate.key] = gridTemplate;
  return result;
}, {});

export const ALBUM_PAGE_GRID_KEYS = ALBUM_PAGE_GRID_LIST.map(
  (gridTemplate) => gridTemplate.key
);
