import React from 'react';
import { FormattedMessage } from 'react-intl';

import { OrderStateTextileType } from 'api/textile_deals/fetchTextileAppState';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import TextileDashboardArrowComponent from '../components/TextileDashboardArrowComponent';

export const TextileCheckoutBlockComponent = ({
  textileOrderId,
  order_state,
  preordersAmount,
  atLeastOrderPlaced,
  isCheckoutAuthor,
  checkoutPresent,
  atLeastOrderStarted
}: {
  textileOrderId: number;
  order_state: OrderStateTextileType;
  preordersAmount?: number;
  atLeastOrderPlaced: boolean;
  atLeastOrderStarted: boolean;
  isCheckoutAuthor: boolean;
  checkoutPresent: boolean
}) => {
  const link = atLeastOrderPlaced
    ? textileOrderRoute(undefined, '/order', textileOrderId)
    : textileOrderRoute(undefined, '/checkout', textileOrderId);

  if (!preordersAmount || preordersAmount === 0) {
    return (
      <HorizontalStackBlock justified={true} gap="XS" centered={true}>
        <IconComponent
          icon="ORDER"
          roundBackground="GRAY950"
          size={3.5}
          square={true}
          fill="GRAY800"
        />
        <ItemStackBlock gap="XXS">
          <Headline.Small color="GRAY560">
            <FormattedMessage id="Textile checkout" />
          </Headline.Small>

          <Paragraph color="GRAY560">
            <IconComponent icon="LOCK" fill="GRAY400" />
            <FormattedMessage id="textile checkout no preorders" />
          </Paragraph>
        </ItemStackBlock>
        <TextileDashboardArrowComponent status={'locked'} />
      </HorizontalStackBlock>
    );
  }

  if (isCheckoutAuthor) {
    return (
      <NakedLink to={link}>
        <HorizontalStackBlock justified={true} gap="XS" centered={true}>
          <IconComponent
            icon="ORDER"
            roundBackground="PRIMARY"
            size={3.5}
            square={true}
            fill="WHITE"
          />
          <ItemStackBlock gap="XXS">
            <Headline.Small>
              <FormattedMessage id="Textile checkout" />
            </Headline.Small>
            {order_state === 'order_started' ? (
              <Paragraph color="TEXT_LIGHT">
                <IconComponent icon="CLOCK_CIRCLE" fill="GRAY400" />
                <FormattedMessage id="textile checkout started" />
              </Paragraph>
            ) : order_state === 'done' ?  (
              <Paragraph color="TEXT_LIGHT">
                <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                <FormattedMessage id="textile checkout done" />
              </Paragraph>
            ) : atLeastOrderPlaced ? (
              <Paragraph color="TEXT_LIGHT">
              <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />
              <FormattedMessage id="textile checkout placed" />
            </Paragraph>
            ) : (
              <Paragraph color="TEXT_LIGHT">
              <FormattedMessage id="textile checkout start" />
            </Paragraph>
            )}
          </ItemStackBlock>
          <TextileDashboardArrowComponent status="open" />
        </HorizontalStackBlock>
      </NakedLink>
    );
  } else {
    return (
      <NakedLink to={textileOrderRoute(undefined, '/calculator/order', textileOrderId)}>
        <HorizontalStackBlock justified={true} gap="XS" centered={true}>
          <IconComponent
            icon="ORDER"
            roundBackground="PRIMARY"
            size={3.5}
            square={true}
            fill="WHITE"
          />
          <ItemStackBlock gap="XXS">
            <Headline.Small>
              <FormattedMessage id="Textile checkout" />
            </Headline.Small>
            {atLeastOrderStarted || checkoutPresent ? (
              <Paragraph color="TEXT_LIGHT">
                <IconComponent icon="CLOCK_CIRCLE" fill="GRAY400" />
                <FormattedMessage id="textile checkout started" />
              </Paragraph>
            ) : (
              <Paragraph color="TEXT_LIGHT">
                <FormattedMessage id="textile checkout start" />
              </Paragraph>
            )}
          </ItemStackBlock>
          <TextileDashboardArrowComponent status="open" />
        </HorizontalStackBlock>
      </NakedLink>
    );
  }


};

export default TextileCheckoutBlockComponent;
