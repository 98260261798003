import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BookModelType } from 'models/BookModel';
import MiniCardComponent from 'components/MiniCardComponent';
import { ROUTE_CONTENT, ROUTE_PDF_PREVIEW } from 'utils/constants/routes';
import IconComponent from 'components/IconComponent';

export const ContentCardComponent = ({
  book,
  hasAnyChapters,
  hasOwnContent,
  isOrganizer,
  onboardingState
}: {
  book: BookModelType;
  hasAnyChapters?: boolean;
  hasOwnContent?: boolean;
  isOrganizer?: boolean;
  onboardingState?: string;
}) => {
  if (!isOrganizer) {
    if (!hasAnyChapters) {
      return null;
    }

    const isLocked = book.editing_state !== 'active';

    return (
      <MiniCardComponent
        disabled={isLocked}
        icon="CONTENT"
        title={<FormattedMessage id="Content" />}
        label={
          isLocked ? (
            <FormattedMessage id="hold the line" />
          ) : hasOwnContent ? (
            <FormattedMessage id="Add your content" />
          ) : undefined
        }
        button={!isLocked && !hasOwnContent && <FormattedMessage id="Start" />}
        meta={
          isLocked && (
            <>
              <IconComponent icon="LOCK" /> <FormattedMessage id="Locked" />
            </>
          )
        }
        linkTo={ROUTE_CONTENT}
      />
    );
  }

  if (book.editing_state === 'generating') {
    return (
      <MiniCardComponent
        mode="hint"
        icon="working"
        title={<FormattedMessage id="Content" />}
        label={<FormattedMessage id="pdf generating" />}
        linkTo={ROUTE_PDF_PREVIEW}
      />
    );
  }

  if (book.isLockedForPreview) {
    return (
      <MiniCardComponent
        icon="CONTENT"
        title={<FormattedMessage id="Content" />}
        label={<FormattedMessage id="Download PDF" />}
        meta={
          <>
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
            <FormattedMessage id="PDF created" />
          </>
        }
        linkTo={ROUTE_PDF_PREVIEW}
      />
    );
  }

  const isNew = !onboardingState;

  return (
    <MiniCardComponent
      mode={isNew ? 'new' : 'default'}
      icon="CONTENT"
      title={<FormattedMessage id="Content" />}
      label={
        isNew ? (
          <FormattedMessage id="Collect content together" />
        ) : hasAnyChapters ? (
          <FormattedMessage id="Book unlocked" />
        ) : undefined
      }
      button={
        (isNew || !hasAnyChapters) && (
          <FormattedMessage id={isNew ? 'Discover' : 'Start'} />
        )
      }
      linkTo={ROUTE_CONTENT}
    />
  );
};

export default ContentCardComponent;
