import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

const UppercaseHeading = styled.h2`
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${COLORS.GRAY400};
  letter-spacing: 0.06rem;
  line-height: 1.2rem;
`;

export default UppercaseHeading;
