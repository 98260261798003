import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import NotFoundScreen from 'screens/NotFoundScreen';
import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { HistoryProps } from 'utils/history';

interface DiscountScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
}

interface DiscountScreenState {
  callSupport?: boolean;
}

@inject('applicationStore', 'chaptersStore')
@observer
class DiscountScreen extends React.Component<
DiscountScreenProps & HistoryProps,
DiscountScreenState
> {
  state: DiscountScreenState = {};

  componentDidMount() {
    this.load();
  }

  private load() {
    this.props.chaptersStore.getDiscountDashboard();
  }

  private getDiscountType() {
    return this.props.match.params?.type || undefined;
  }

  private renderPage(content?: any) {
    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  }

  private renderSpecial() {
    const { discountDashboard } = this.props.chaptersStore;
    const discount = discountDashboard?.discounts?.get('nuggit_special');

    if (!discount) {
      return <NotFoundScreen />;
    }

    const { checked } = discount;

    // TODO proper layout
    return this.renderPage(
      <>
        <PageHeader
          headline={discount.name}
          text={
            <FormattedMessage
              id="discount special info"
              values={{ amount: discount.value }}
            />
          }
        />

        <ItemStackBlock gap="XS">
          <HorizontalStackBlock justified={true}>
            <ItemStackBlock gap="XXS">
              <Paragraph size="L" weight="BOLD">
                <FormattedMessage id="Possible discount" />
              </Paragraph>
              <TextElement>
                <FormattedMessage
                  id="discount special ask"
                  values={{ amount: discount.value }}
                />
              </TextElement>
            </ItemStackBlock>
            <Paragraph size="L" weight="BOLD" noBreak={true}>
              <FormattedNumber
                value={discount.value}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="EUR"
              />
            </Paragraph>
          </HorizontalStackBlock>

          <Divider />

          <HorizontalStackBlock justified={true}>
            <ItemStackBlock gap="XXS">
              <Paragraph size="L" weight="BOLD" >
                <FormattedMessage id="Guaranteed discount" />
              </Paragraph>
              {checked && (
                <TextElement>
                  <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
                  <FormattedMessage id="Amount discounted automatically" />
                </TextElement>
              )}
            </ItemStackBlock>
            <Paragraph size="L" weight="BOLD" noBreak={true}>
              <FormattedNumber
                value={checked ? discount.value : 0}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency="EUR"
              />
            </Paragraph>
          </HorizontalStackBlock>
        </ItemStackBlock>

        {!checked && (
          <>
            <Divider />

            <ButtonBlock
              background="PRIMARY"
              color="WHITE"
              onClick={() =>
                this.setState({
                  callSupport: true
                })
              }
            >
              <FormattedMessage id="Learn more" />
            </ButtonBlock>
          </>
        )}
      </>
    );
  }

  private renderCallSupportForSpecial() {
    return (
      <SupportTicketScreen subject="special_discount" backTo="dashboard" message={intl.formatMessage({id: "zap_special_discount"})} />
    );
  }


  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  private renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.load()} />
    );
  }

  render() {
    if (this.state.callSupport) {
      return this.renderCallSupportForSpecial();
    }

    const { discountDashboardLoadingState } = this.props.chaptersStore;

    if (discountDashboardLoadingState === 'loading') {
      return this.renderLoading();
    }

    if (discountDashboardLoadingState === 'error') {
      return this.renderError();
    }

    switch (this.getDiscountType()) {
      case 'special':
        if (!this.props.applicationStore.isOrganizer) {
          // not for students
          break;
        }
        return this.renderSpecial();
      default:
    }

    return <NotFoundScreen />;
  }
}

export default DiscountScreen;
