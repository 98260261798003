import React from 'react';
import { FormattedMessage } from 'react-intl';

import COLORS from 'utils/constants/colors';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import OverlayBlock from 'blocks/OverlayBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import PageHeader from 'components/PageHeader/PageHeader';

interface ConfirmBoxComponentProps {
  header?: React.ReactChild;
  text?: React.ReactChild;
  confirmText?: React.ReactChild;
  confirmColor?: keyof typeof COLORS;
  abortText?: React.ReactChild;
  abortColor?: keyof typeof COLORS;
  onConfirm?: () => void;
  onAbort?: () => void;
}

// TODO Handle ESC key press? Maybe using 'react-keyboard-event-handler'?
export const ConfirmBoxComponent = ({
  header,
  text,
  onConfirm,
  onAbort,
  confirmText,
  confirmColor,
  abortText,
  abortColor
}: ConfirmBoxComponentProps) => (
  <OverlayBlock>
    <OverlayBlock.InnerElement>
      <PaddingBlock>
        <ItemStackBlock gap="L">
          <PageHeader headline={header} text={text} />
          <ItemStackBlock gap="XS">
            {onConfirm && (
              <ButtonBlock
                background={confirmColor || 'PRIMARY'}
                onClick={onConfirm}
              >
                {confirmText ? confirmText : <FormattedMessage id="Yes" />}
              </ButtonBlock>
            )}
            {onAbort && (
              <ButtonBlock
                background={abortColor || 'SECONDARY_DARK'}
                onClick={onAbort}
              >
                {abortText ? abortText : <FormattedMessage id="No" />}
              </ButtonBlock>
            )}
          </ItemStackBlock>
        </ItemStackBlock>
      </PaddingBlock>
    </OverlayBlock.InnerElement>
  </OverlayBlock>
);

export default ConfirmBoxComponent;
