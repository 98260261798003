import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ChapterType } from 'models/ChapterTypeEnum';
import NakedButton from 'elements/NakedButton';
import {
  EveryCard,
  EveryCardPadding,
  EveryCardBody,
  EveryCardHeadline
} from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';
import chapterIcon from 'utils/chapter-icon';
import TextElement from 'components/TextElement/TextElement';

const ChapterTypeItem = ({
  chapterType,
  onClick
}: {
  chapterType: ChapterType | 'candidate ranking';
  onClick?: (chapterType: ChapterType | 'candidate ranking') => void;
}) => (
  <NakedButton onClick={onClick && (() => onClick(chapterType))}>
    <EveryCard>
      <EveryCardPadding>
        <IconComponent
          icon={chapterIcon(
            chapterType === 'candidate ranking' ? 'ranking' : chapterType
          )}
          circleBackground="PRIMARY_LIGHT"
          fill="PRIMARY"
          size={3}
        />

        <EveryCardBody>
          <EveryCardHeadline>
            <FormattedMessage id={'chapter type ' + chapterType} />
          </EveryCardHeadline>

          <TextElement>
            <FormattedMessage id={'chapter type ' + chapterType + ' label'} />
          </TextElement>
        </EveryCardBody>
      </EveryCardPadding>
    </EveryCard>
  </NakedButton>
);

export default ChapterTypeItem;
