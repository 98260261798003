import style from 'styled-components';
import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

interface InputMessageProps {
  error?: boolean;
  warning?: boolean;
  ok?: boolean;
  info?: boolean;
  noMargin?: boolean;
}

export default style.div<InputMessageProps>`
  display: block;
  margin: ${(props) => props.noMargin ? '0' : '0.5em 0 0.7857em'};
  padding: 0.9375em 1.25em;
  background: ${COLORS.GRAY900};
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: ${FONTS.DEFAULT};
  font-weight: 400;
  color: ${COLORS.GRAY10};
  ${(props) =>
    props.error &&
    `
    color: ${COLORS.RED};
    background: ${COLORS.RED_LIGHT};
  `};
  ${(props) =>
    props.ok &&
    `
    color: ${COLORS.GREEN};
    background: ${COLORS.GREEN_LIGHT};
  `};
  ${(props) =>
    props.warning &&
    `
    color: ${COLORS.PRIMARY};
    background: rgba(${COLORS.PRIMARY_RGB}, 0.1);
  `};
  ${(props) => props.info && `
    color: ${COLORS.BLACK};
    background: ${COLORS.PURPLE_LIGHT};
  `}
`;
