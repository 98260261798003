import { get } from 'api/BaseApi';

interface TextileThemes {
  themes: TextileThemeLayout[];
  mottos: TextileThemeMotto[];
}

export interface TextileThemeMotto {
  key: string;
  name: string;
}

export interface TextileThemeLayout {
  key: string;
  name?: string;
  tags: string[];
  defaultBackground?: string;
  defaultFont?: string;
  headlineFontColor?: string;
  subheadlineFontColor?: string;
  footerFontColor?: string;
  studentNamesFontColor?: string;
  teacherNamesFontColor?: string;
  groupsFontColor?: string;

  detail_1_organicHoodie?: string;
  detail_2_organicHoodie?: string;
  detail_3_organicHoodie?: string;
  frontPreview_organicHoodie?: string;
  thumbnail_organicHoodie?: string;

  detail_1_organicShirt?: string;
  detail_2_organicShirt?: string;
  detail_3_organicShirt?: string;
  frontPreview_organicShirt?: string;
  thumbnail_organicShirt?: string;

  detail_1_deluxeHoodie?: string;
  detail_2_deluxeHoodie?: string;
  detail_3_deluxeHoodie?: string;
  frontPreview_deluxeHoodie?: string;
  thumbnail_deluxeHoodie?: string;

  front?: string;
}

export const getTextileThemes = (
  textileOrderId: number
): Promise<TextileThemes> => {
  return get('/textile_layouts/themes?textile_order_id=' + textileOrderId);
};
