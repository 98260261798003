import React from 'react';
import { FormattedMessage } from 'react-intl';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import TextileDashboardArrowComponent from '../components/TextileDashboardArrowComponent';

export const TextileManagementBlockComponent = ({
  textileOrderId,
  locked,
  printDataOpen
}: {
  textileOrderId: number;
  locked: boolean;
  printDataOpen: boolean;
}) => {
  if (locked) {
    return (
      <HorizontalStackBlock justified={true} gap="XS" centered={true}>
        <IconComponent
          icon="MULTIPLE_STUDENTS"
          roundBackground="GRAY950"
          size={3.5}
          square={true}
          fill="GRAY800"
        />
        <ItemStackBlock gap="XXS">
          <Headline.Small color="GRAY560">
            <FormattedMessage id="Textile management" />
          </Headline.Small>

          <Paragraph color="GRAY560">
            <IconComponent icon="LOCK" fill="GRAY400" />
            <FormattedMessage id="Editing deactivated" />
          </Paragraph>
        </ItemStackBlock>
        <TextileDashboardArrowComponent status={'locked'} />
      </HorizontalStackBlock>
    );
  }

  return (
    <NakedLink to={textileOrderRoute(undefined, '/management', textileOrderId)}>
      <HorizontalStackBlock justified={true} gap="XS" centered={true}>
        <IconComponent
          icon="MULTIPLE_STUDENTS"
          roundBackground="PRIMARY"
          size={3.5}
          square={true}
          fill="WHITE"
        />
        <ItemStackBlock gap="XXS">
          <Headline.Small>
            <FormattedMessage id="Textile management" />
          </Headline.Small>

          {printDataOpen ? (
            <Paragraph color="TEXT_LIGHT">
              <IconComponent icon="CLOCK_CIRCLE" fill="GRAY400" />
              <FormattedMessage id="textile management open" />
            </Paragraph>
          ) : (
            <Paragraph color="TEXT_LIGHT">
              <IconComponent icon="PAUSE" fill="GRAY400" />
              <FormattedMessage id="textile management closed" />
            </Paragraph>
          )}
        </ItemStackBlock>
        <TextileDashboardArrowComponent status="open" />
      </HorizontalStackBlock>
    </NakedLink>
  );
};

export default TextileManagementBlockComponent;
