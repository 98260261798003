import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import {
  ButtonBaseProps,
  ButtonBaseStyles
} from 'blocks/ButtonBlock/ButtonBlock';
import styled from 'styled-components';

export type LinkBlockProps = LinkProps & ButtonBaseProps;

/**
 * Wrapper around `Link` that removes the `inline` prop
 * to suppress a React warning.
 */
const LinkWithoutInline = (props: LinkBlockProps) => {
  const propsWithoutInline = { ...props };
  delete propsWithoutInline.inline;
  return <Link {...propsWithoutInline} />;
};

/**
 * Link that looks like a button.
 * Shares most features with ButtonBlock.
 *
 * @example
 * <LinkBlock
 *   to={ROUTE_QUOTES + '/' + chapter.id}
 *   color="PRIMARY_DARK"
 *   background="PRIMARY_LIGHT"
 * >
 *   <FormattedMessage id="chapter" />
 * </LinkBlock>
 */
const LinkBlock = styled(LinkWithoutInline)
// @ts-ignore
` ${ButtonBaseStyles} `;

export const ExternalLinkBlock = styled.a<ButtonBaseProps>
// @ts-ignore
` ${ButtonBaseStyles} `;

export default LinkBlock;
