import { Instance, types } from 'mobx-state-tree';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';

import { ChapterEditingStateEnum } from './ChapterEditingStateEnum';
import { ChapterType, ChapterTypeEnum } from './ChapterTypeEnum';
import GroupModel, { createGroupModel } from './GroupModel';
import { SponsorModelType } from './SponsorModel';
import SponsorPageModel, { createSponsorPage } from './SponsorPageModel';
import CoverChapterSettingsModel, {
  createCoverChapterSettingsModel
} from './chapter-settings/CoverChapterSettingsModel';
import FactsheetChapterSettingsModel, {
  createFactsheetChapterSettingsModel
} from './chapter-settings/FactsheetChapterSettingsModel';
import RankingChapterSettingsModel, {
  createRankingChapterSettingsModel
} from './chapter-settings/RankingChapterSettingsModel';
import SponsorChapterSettingsModel, {
  createSponsorChapterSettingsModel
} from './chapter-settings/SponsorChapterSettingsModel';
import YearbookChapterSettingsModel, {
  createYearbookChapterSettingsModel
} from './chapter-settings/YearbookChapterSettingsModel';
import BackgroundModel, {
  createBackgroundModel
} from './print/BackgroundModel';
import ColorSchemeModel, {
  createColorSchemeModel
} from './print/ColorSchemeModel';
import FontModel, { createFontModel } from './print/FontModel';
import LayoutModel, { createLayoutModel } from './print/LayoutModel';

export const CHAPTER_TYPES_WITH_SETTINGS: ChapterType[] = [
  'album',
  'factsheet',
  'teacher_factsheet',
  'quote',
  'text',
  'sponsor',
  'ranking',
  'table_of_contents',
  'yearbook'
];

export const CHAPTER_TYPES_WITH_LAYOUT: ChapterType[] = [
  // 'cover',
  'factsheet',
  'teacher_factsheet',
  'yearbook',
  'quote',
  'ranking',
  'text'
];

export const CHAPTER_TYPES_WITH_LAYOUT_EDITOR: ChapterType[] = CHAPTER_TYPES_WITH_LAYOUT.concat(
  ['album', 'table_of_contents']
);

export const STUDENT_EDITABLE_CHAPTER_TYPES: ChapterType[] = [
  'album',
  'factsheet',
  'yearbook',
  'quote',
  'ranking'
];

export const CHAPTER_TYPES_EXCLUDED_FROM_TOC: ChapterType[] = [
  'unknown',
  'table_of_contents',
  'cover'
];

const ChapterAccessLevelEnum = types.enumeration(['open', 'organizer']);

const ChapterSettingsUnion = types.union(
  CoverChapterSettingsModel,
  FactsheetChapterSettingsModel,
  YearbookChapterSettingsModel,
  RankingChapterSettingsModel,
  SponsorChapterSettingsModel
);

const ChapterModel = types
  .model('ChapterModel', {
    id: types.identifierNumber,
    chapter_type: ChapterTypeEnum,
    title: types.maybe(types.string),
    toc_title: types.maybe(types.string),
    sorting: types.maybe(types.number),
    print: types.maybe(types.boolean),
    list_in_toc: types.maybe(types.boolean),
    description: types.maybe(types.string),
    editing_state: ChapterEditingStateEnum,

    access_level: ChapterAccessLevelEnum,
    group: types.maybe(GroupModel),
    book_groups_applied: types.maybe(types.boolean),

    // layout
    font_key: types.maybe(types.string),
    font: types.maybe(FontModel),
    layout_key: types.maybe(types.string),
    layout: types.maybe(LayoutModel),
    background_key: types.maybe(types.string),
    background: types.maybe(BackgroundModel),
    color_scheme_key: types.maybe(types.string),
    color_scheme: types.maybe(ColorSchemeModel),
    book_theme_key: types.maybe(types.string),

    // settings
    settings: types.maybe(ChapterSettingsUnion),

    // content info
    rankings_count: types.maybe(types.number),
    own_votes_count: types.maybe(types.number),
    own_quotes_count: types.maybe(types.number),
    own_photos_count: types.maybe(types.number),

    cover_sponsor_page: types.maybe(SponsorPageModel)
  })
  .views((self) => {
    return {
      get coverSponsor(): SponsorModelType | undefined {
        const chapter = self;
        if (!chapter || chapter.chapter_type !== 'cover') {
          return undefined;
        }
        const sponsorPage = chapter.cover_sponsor_page;
        if (!sponsorPage) {
          return undefined;
        }
        if (sponsorPage.sponsor_page_sponsors.length === 0) {
          return undefined;
        }
        return sponsorPage.sponsor_page_sponsors[0].sponsor;
      },

      get isFactsheetChapter(): boolean {
        return (
          self.chapter_type === 'factsheet' ||
          self.chapter_type === 'teacher_factsheet'
        );
      },

      get isStudentEditable(): boolean {
        if (!STUDENT_EDITABLE_CHAPTER_TYPES.includes(self.chapter_type)) {
          return false;
        }
        return self.access_level === 'open';
      }
    };
  });

const createSettings = (chapterType?: ChapterType, settings?: any) => {
  if (!settings) {
    return undefined;
  }

  switch (chapterType) {
    case 'cover':
      return createCoverChapterSettingsModel(settings);

    case 'factsheet':
    case 'teacher_factsheet':
      return createFactsheetChapterSettingsModel(settings);

    case 'yearbook':
      return createYearbookChapterSettingsModel(settings);

    case 'ranking':
      return createRankingChapterSettingsModel(settings);

    case 'sponsor':
      return createSponsorChapterSettingsModel(settings);

    default:
      return undefined;
  }
};

export const createChapterModel = (
  data?: any
): ChapterModelType | undefined => {
  if (!data) {
    return undefined;
  }

  const chapterType: ChapterType = ChapterTypeEnum.is(data?.chapter_type)
    ? data.chapter_type
    : 'unknown';

  return ChapterModel.create({
    id: mandatoryId(data?.id),
    chapter_type: chapterType,
    title: data?.title || undefined,
    toc_title: data?.toc_title || undefined,
    sorting: numberOrUndefined(data?.sorting),
    print: booleanOrUndefined(data?.print),
    list_in_toc: booleanOrUndefined(data?.list_in_toc),
    description: data?.description || undefined,
    editing_state: ChapterEditingStateEnum.is(data?.editing_state)
      ? data.editing_state
      : 'off',

    access_level: ChapterAccessLevelEnum.is(data?.access_level)
      ? data.access_level
      : 'open',
    group: data?.group ? createGroupModel(data.group) : undefined,
    book_groups_applied: booleanOrUndefined(data?.book?.groups_applied),

    font_key: data?.font_key || undefined,
    font: createFontModel(data?.font_definitions),

    layout_key: data?.layout_key || undefined,
    layout: createLayoutModel(data?.layout_definitions),

    background_key: data?.background_key || undefined,
    background: createBackgroundModel(data?.background_definitions),

    color_scheme_key: data?.color_scheme_key || undefined,
    color_scheme: createColorSchemeModel(data?.color_scheme_definitions),

    book_theme_key: data?.book?.cover?.layout_key || undefined,

    settings: createSettings(chapterType, data?.settings),

    // content info
    rankings_count: numberOrUndefined(data?.rankings_count),
    own_votes_count: numberOrUndefined(data?.own_votes_count),
    own_quotes_count: numberOrUndefined(data?.own_quotes_count),
    own_photos_count: numberOrUndefined(data?.own_photos_count),

    cover_sponsor_page: data?.cover_sponsor_page
      ? createSponsorPage(data?.cover_sponsor_page)
      : undefined
  });
};

export type ChapterModelType = Instance<typeof ChapterModel>;
export default ChapterModel;
