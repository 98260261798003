import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

export const Dot = styled.div<{ color: keyof typeof COLORS; size?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size || 1.375}em;
  height: ${({ size }) => size || 1.375}em;
  background-color: ${({ color }) => COLORS[color]};
  border-radius: 50%;
`;

export default Dot;
