import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  ROUTE_JOIN,
  ROUTE_SIGNUP,
  ROUTE_SIGNUP_WITH_AFFILIATE_TOKEN
} from 'utils/constants/routes';

import FooterWrapper from 'blocks/FooterBlock/FooterWrapper';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { HistoryProps } from 'utils/history';
import SignupAuthCheckContainer from './containers/SignupAuthCheckContainer';
import SignupDoneScreen from './screens/SignupDoneScreen';
import SignupEngagementScreen from './screens/SignupEngagementScreen';
import SignupNumberScreen from './screens/SignupNumberScreen';
import SignupPasswordScreen from './screens/SignupPasswordScreen';
import SignupPrivacyScreen from './screens/SignupPrivacyScreen';
import SignupScreen from './screens/SignupScreen';
import SignupTosScreen from './screens/SignupTosScreen';
import SignupYearScreen from './screens/SignupYearScreen';
import SignupCheckNumberScreen from './screens/SignupCheckNumberScreen';
import SignupNameScreen from './screens/SignupNameScreen';
import JoinScreen from './screens/JoinScreen';

export default (props: HistoryProps) => (
  <SignupAuthCheckContainer {...props}>
    <FooterWrapper>
      <Switch>
        <Route path={ROUTE_SIGNUP} exact={true} component={SignupScreen} />

        <Route path={ROUTE_SIGNUP + '/number'} component={SignupNumberScreen} />
        <Route
          path={ROUTE_SIGNUP + '/check_number'}
          component={SignupCheckNumberScreen}
        />
        <Route
          path={ROUTE_SIGNUP + '/password'}
          component={SignupPasswordScreen}
        />

        <Route path={ROUTE_SIGNUP + '/year'} component={SignupYearScreen} />
        <Route path={ROUTE_SIGNUP + '/name'} component={SignupNameScreen} />
        <Route path={ROUTE_SIGNUP + '/terms'} component={SignupTosScreen} />
        <Route
          path={ROUTE_SIGNUP + '/privacy'}
          component={SignupPrivacyScreen}
        />
        <Route
          path={ROUTE_SIGNUP + '/engagement'}
          component={SignupEngagementScreen}
        />
        <Route path={ROUTE_SIGNUP + '/done'} component={SignupDoneScreen} />
        <Route
          path={ROUTE_SIGNUP_WITH_AFFILIATE_TOKEN}
          component={SignupScreen}
        />

        <Route path={ROUTE_JOIN + '/:token'} component={JoinScreen} />
        <NotFoundRoute />
      </Switch>
    </FooterWrapper>
  </SignupAuthCheckContainer>
);
