import React from 'react';

import PhotoEditScreen from 'domain/PhotoEditScreen/PhotoEditScreen';
import { assert } from 'utils/assert';
import { ROUTE_ALBUMS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

const NewPhoto = (props: HistoryProps) => {
  const chapterId = chapterIdFromMatch(props.match);
  assert(chapterId, 'Chapter id not given');

  return (
    <PhotoEditScreen
      {...props}
      photoType="album"
      parentId={chapterId}
      frameWidth={280}
      frameHeight={280}
      allowDelete={false}
      allowEditCaption={false}
      fitToFrameEnabled={false}
      isImageRounded={false}
      isEditorEnabled={false}
      backRoute={ROUTE_ALBUMS + '/' + (chapterId || '')}
      multiple={true}
    />
  );
};

export default NewPhoto;
