import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import TopActionBlock from 'blocks/TopActionBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import BackLink from 'domain/Links/BackLink';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTE_TOC } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

interface TocScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class TocNewEntry extends React.Component<TocScreenProps & HistoryProps> {
  componentDidMount() {
    const { applicationStore } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature('toc', this.props.location.pathname)
    ) {
      return;
    }

    this.loadChapters();
  }

  async loadChapters() {
    await this.props.chaptersStore.getAllChapters();
  }

  renderPage(content: any) {
    const chapterId = chapterIdFromMatch(this.props.match);
    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_TOC + '/' + chapterId} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="toc" />
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadChapters()} />
    );
  }

  render() {
    const { chaptersStore } = this.props;

    const chapterId = chapterIdFromMatch(this.props.match);

    if (!chapterId) {
      return null;
    }

    const tocItemNotListed = chaptersStore.notListedInToc;

    if (chaptersStore.isListLoading) {
      return this.renderPage(<LoadingOverlayComponent />);
    }

    if (chaptersStore.isListError) {
      return this.renderError();
    }

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Choose chapter" />} text={<FormattedMessage id="Choose chapter text" />} />

        <ListStackBlock>
          {tocItemNotListed.length !== 0 ? (
            <>
              {tocItemNotListed.map((item, idx) => {
                if (item.print) {
                  return (
                    <Link
                      to={{
                        pathname: ROUTE_TOC + '/' + chapterId + '/add/' + item.id,
                        state: {
                          id: chapterId || undefined
                        }
                      }}
                      key={idx}
                    >
                      <EveryCard>
                        <EveryCardPadding>
                          <EveryCardBody>
                            <EveryCardHeadline>{item.title}</EveryCardHeadline>
                            <Paragraph size="S" color="TEXT_LIGHT">
                              <FormattedMessage
                                id={'chapter type ' + item.chapter_type}
                              />
                            </Paragraph>
                          </EveryCardBody>
                        </EveryCardPadding>
                      </EveryCard>
                    </Link>
                  );
                } else {
                  return (
                    <EveryCard disabled={true}>
                      <EveryCardPadding>
                        <EveryCardBody>
                          <EveryCardHeadline color="GRAY800">{item.title}</EveryCardHeadline>
                          <Paragraph size="S" color="GRAY800">
                            <FormattedMessage
                              id={'chapter type ' + item.chapter_type}
                            />
                          </Paragraph>
                        </EveryCardBody>
                      </EveryCardPadding>
                    </EveryCard>
                  )
                }
              })}
            </>
          ) : (
            <EmptyStateComponent
              headerId="toc all items placed"
              textId="toc all items placed info"
            />
          )}
        </ListStackBlock>
      </>
    );
  }
}

export default TocNewEntry;
