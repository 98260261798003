import { inject, observer } from 'mobx-react';
import React from 'react';

import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_DASHBOARD, ROUTE_YEARBOOKS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface OwnYearbookScreenProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class OwnYearbookScreen extends React.Component<
  OwnYearbookScreenProps & HistoryProps
> {
  componentDidMount() {
    const { applicationStore, history } = this.props;

    if (!applicationStore.currentUserId) {
      history.replace(ROUTE_DASHBOARD);
      return;
    }

    history.replace(
      ROUTE_YEARBOOKS + '/detail/' + applicationStore.currentUserId
    );
  }

  render() {
    return null;
  }
}

export default OwnYearbookScreen;
