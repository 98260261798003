import { useQuery } from "@tanstack/react-query"
import { getAllStudents, getAllStudentsWithoutGroups } from "api/textile_deals/fetchStudentList"


export const useStudentListQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['student_list', textileOrderId],
    queryFn: () => getAllStudents(textileOrderId!),
    enabled: !!textileOrderId,
  })
}

export const useStudentListWithoutGroupsQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['student_list', textileOrderId],
    queryFn: () => getAllStudentsWithoutGroups(textileOrderId!),
    enabled: !!textileOrderId,
  })
}