import styled from 'styled-components';

import COLORS from 'utils/constants/colors';
import SPACES from 'utils/constants/spaces';

interface SliderBlockProps {
  gap?: keyof typeof SPACES;
  gapTop?: keyof typeof SPACES;
  fixSize?: 'M' | 'S';
  noScrollBar?: boolean;
  scrollBarColor?: keyof typeof COLORS;
  noScrollBarBackground?: boolean;
}

const SliderBlock = styled.div<SliderBlockProps>`
  display: flex;
  overflow-x: auto;
  margin-right: -1.25rem;
  margin-left: -1.25rem;
  margin-top: ${(props) => (props.gapTop ? SPACES[props.gapTop] : 0)};

  /* Overwrite width: 100% */
  width: auto;
  padding-left: calc(
    1.25rem - (${(props) => (props.gap ? SPACES[props.gap] : SPACES.M)} / 2)
  );
  padding-bottom: 1rem;
  scroll-snap-type: x proximity;

  ::-webkit-scrollbar {
    height: ${(props) => (props.noScrollBar ? '0' : '0.375rem')};
  }

  ${(props) =>
    !props.noScrollBarBackground &&
    `::-webkit-scrollbar-track {
      background-color: rgba(${COLORS.GRAY10_RGB}, 0.1);
    }`}

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.scrollBarColor ? COLORS[props.scrollBarColor] : COLORS.PRIMARY};
    border-radius: 0.1875rem;
  }

  &::after {
    content: '';
    width: 1rem;
    flex-shrink: 0;
    align-self: stretch;
  }

  & > * {
    flex-shrink: 0;
    overflow: hidden;
    scroll-snap-align: center;
    margin-left: calc(
      ${(props) => (props.gap ? SPACES[props.gap] : SPACES.M)} / 2
    );
    margin-right: calc(
      ${(props) => (props.gap ? SPACES[props.gap] : SPACES.M)} / 2
    );
  }

  ${(props) =>
    props.fixSize && props.fixSize === 'M'
      ? `
          align-items: start;
          > * {
            width: 36vw;
            @media (min-width: 36.25rem) {
              width: 26vw;
            }
            @media (min-width: 50rem) {
              width: 10rem;
            }
          }
        `
      : props.fixSize === 'S'
      ? `
            align-items: start;
            > * {
              width: 20vw;
              @media (min-width: 36.25rem) {
                width: 5.125rem;
              }
            }
          `
      : null};
`;

export default SliderBlock;
