import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import ChapterModel, {
  ChapterModelType,
  createChapterModel
} from 'models/ChapterModel';
import QuoteModel, {
  createQuoteModel,
  QuoteModelType
} from 'models/QuoteModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { assert } from 'utils/assert';
import { createMapWithTransform } from 'utils/create-map';
import { moveItem } from 'utils/item-sorting';
import { sortByField } from 'utils/sort-functions';

const QuotesStore = types
  .model('QuotesStore', {
    // list
    loadingState: types.maybe(types.string),
    quotes: types.maybe(types.map(QuoteModel)),
    chapter: types.maybe(ChapterModel),
    // singe item
    itemLoadingState: types.maybe(types.string),
    item: types.maybe(QuoteModel)
  })
  .actions((self) => {
    const getAllQuotes = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';
        self.quotes = undefined;
        self.chapter = undefined;

        const result = yield client.getAllQuotes();

        if (!Array.isArray(result) || !result.length) {
          self.loadingState = undefined;
          return;
        }

        self.quotes = createMapWithTransform(result, createQuoteModel);
        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('QuotesStore | getAllQuotes', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const getQuotesByChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';
        self.quotes = undefined;
        self.chapter = undefined;

        const result: any = yield client.getAllQuotes(chapterId);

        if (!result) {
          throw new Error('No response from server');
        }

        if (Array.isArray(result.quotes)) {
          self.quotes = createMapWithTransform(result.quotes, createQuoteModel);
        }
        self.chapter = createChapterModel(result.chapter || { id: chapterId });

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('QuotesStore | getQuotesByChapter', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const getQuote = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const quote = yield client.getQuote(id);

        self.item = createQuoteModel(quote);
        self.itemLoadingState = undefined;

        return self.item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('QuotesStore | getQuote', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    const createQuote = flow(function* (
      quote: QuoteModelType,
      addToList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const result = yield client.createQuote(quote);
        const model = createQuoteModel(result);

        if (model) {
          if (addToList) {
            self.quotes?.put(model);
          }

          self.item = createQuoteModel(result);
        }
        self.itemLoadingState = undefined;

        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('QuotesStore | createQuote', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateQuote = flow(function* (quote: QuoteModelType) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        const result = yield client.updateQuote(quote);
        const model = createQuoteModel(result);

        if (model) {
          if (self.quotes?.has(model.id.toString())) {
            self.quotes.put(model);
          }

          if (self.item?.id === model.id) {
            self.item = createQuoteModel(result);
          }
        }
        self.itemLoadingState = undefined;

        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('QuotesStore | updateQuote', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const removeQuote = flow(function* (
      quoteId: number,
      removeFromList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        yield client.removeQuote(quoteId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('QuotesStore | removeQuote', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.itemLoadingState = 'update_error';
          throw error;
        }
      }

      if (removeFromList) {
        self.quotes?.delete(quoteId.toString());
      }

      if (self.item && self.item.id === quoteId) {
        self.item = undefined;
      }
      self.itemLoadingState = undefined;
    });

    const moveQuote = flow(function* (oldIndex: number, newIndex: number) {
      assert(self.quotes);

      const { items, map } = moveItem(self.quotes, oldIndex, newIndex);

      self.quotes = map;

      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        // Do not set loading state, this would block the UI
        self.loadingState = undefined;

        yield client.setQuotesSorting(items as QuoteModelType[]);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('FactsheetsStore | moveQuestion', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
        throw error;
      }

      self.loadingState = undefined;
    });

    const clearCurrentItem = () => {
      self.item = undefined;
      self.itemLoadingState = undefined;
    };

    const patchItem = (update: any) => {
      if (!self.item) {
        self.item = createQuoteModel({
          id: -1,
          ...update
        });
        return;
      }

      self.item = createQuoteModel({
        ...self.item,
        ...update
      });
    };

    const storeItem = flow(function* () {
      if (!self.item) {
        return;
      }

      if (self.item.id < 0) {
        return yield createQuote(self.item);
      }
      return yield updateQuote(self.item);
    });

    const setChapter = (chapter?: ChapterModelType) => {
      self.chapter = createChapterModel(chapter);
    };

    return {
      getAllQuotes,
      getQuotesByChapter,
      getQuote,
      createQuote,
      updateQuote,
      removeQuote,
      moveQuote,
      clearCurrentItem,
      patchItem,
      storeItem,
      setChapter
    };
  })
  .views((self) => {
    return {
      /**
       * Quotes sorted by sorting id
       */
      get allQuotes(): QuoteModelType[] {
        const quotes: QuoteModelType[] = [];
        if (!self.quotes) {
          return quotes;
        }

        for (const quote of self.quotes.values()) {
          quotes.push(quote);
        }

        quotes.sort(sortByField('sorting'));
        return quotes;
      },
      /**
       * Filters the quotes by text or author name.
       * @param text Needle
       */
      filteredQuotes(text: string): QuoteModelType[] {
        const textLowercase = text.trim().toLowerCase();
        return this.allQuotes.filter(
          ({ quote, author }) =>
            (quote && quote.toLowerCase().indexOf(textLowercase) > -1) ||
            (author &&
              (author.fullName || '').toLowerCase().indexOf(textLowercase) > -1)
        );
      },

      get ownQuotes(): QuoteModelType[] {
        const quotes: QuoteModelType[] = [];
        if (!self.quotes) {
          return quotes;
        }

        const { applicationStore } = getEnv<AdvancedStoreEnv>(self);
        const id = applicationStore.currentUser
          ? applicationStore.currentUser.id
          : null;

        for (const quote of self.quotes.values()) {
          if (!quote.author || quote.author.id !== id) {
            continue;
          }

          quotes.push(quote);
        }

        return quotes;
      },
      ownFilteredQuotes(text: string): QuoteModelType[] {
        const ownQuotes = this.ownQuotes;
        const textLowercase = text.toLowerCase();

        const quotes: QuoteModelType[] = [];
        for (const quote of ownQuotes) {
          if (
            quote.quote &&
            quote.quote.toLowerCase().indexOf(textLowercase) > -1
          ) {
            quotes.push(quote);
          }
        }

        return quotes;
      },

      get isListLoading(): boolean {
        return self.loadingState === 'loading' ? true : false;
      },
      get isListError(): boolean {
        return self.loadingState === 'error' ? true : false;
      },

      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading' ? true : false;
      },
      get isItemError(): boolean {
        return self.itemLoadingState === 'error' ||
          self.itemLoadingState === 'update_error'
          ? true
          : false;
      },

      get printedQuotes(): string[] {
        if (!self.quotes?.size) {
          return [];
        }

        const list: QuoteModelType[] = [];

        for (const quote of self.quotes.values()) {
          if (!quote.quote || !quote.print) {
            continue;
          }

          list.push(quote);
        }

        list.sort(sortByField('sorting'));
        return list.map((quote) => quote.quote!);
      }
    };
  });

export type QuotesStoreType = Instance<typeof QuotesStore>;
export default QuotesStore;
