import styled from 'styled-components';

const ColorSwatchesBlock = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: '0 0 80 80'
})`
  display: block;
`;

export default ColorSwatchesBlock;