import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import Paragraph from 'components/Paragraph/Paragraph';

export interface ItemProps extends LinkProps {
  active?: boolean;
}

export default (props: ItemProps) => {
  const linkProps = { ...props };
  delete linkProps.active;

  return props.active ? (
    <Paragraph>{props.children}</Paragraph>
  ) : (
    <Link {...linkProps} />
  );
};
