import React from 'react';
import { FormattedMessage } from 'react-intl';

import MiniCardComponent from 'components/MiniCardComponent';
import { BookModelType } from 'models/BookModel';
import { ROUTE_CALCULATOR, ROUTE_CHECKOUT } from 'utils/constants/routes';

export const PrintCardComponent = ({
  book,
  onboardingState,
  checkoutPresent,
  checkoutIsAuthor,
  parentalApprovalUploaded,
  parentalApprovalChecked,
  paymentReceived
}: {
  book: BookModelType;
  onboardingState?: string;
  checkoutPresent?: boolean;
  checkoutIsAuthor?: boolean;
  parentalApprovalUploaded?: boolean;
  parentalApprovalChecked?: boolean;
  paymentReceived?: boolean;
}) => {
  if (book.order_state === 'done') {
    return (
      <MiniCardComponent
        mode="hint"
        icon="tick_circle"
        iconColor="GREEN"
        title={<FormattedMessage id="Print" />}
        label={<FormattedMessage id="print card label done" />}
        linkTo={ROUTE_CHECKOUT}
      />
    );
  }

  if (book.order_state === 'shipped') {
    return (
      <MiniCardComponent
        icon="books"
        title={<FormattedMessage id="Print" />}
        label={<FormattedMessage id="print card label shipped" />}
        progress={80}
        linkTo={ROUTE_CHECKOUT}
      />
    );
  }

  if (book.order_state === 'printing') {
    return (
      <MiniCardComponent
        icon="books"
        title={<FormattedMessage id="Print" />}
        label={<FormattedMessage id="print card label printing" />}
        progress={64}
        linkTo={ROUTE_CHECKOUT}
      />
    );
  }

  if (book.order_state === 'confirmed') {
    return (
      <MiniCardComponent
        icon="books"
        title={<FormattedMessage id="Print" />}
        label={<FormattedMessage id="print card label confirmed" />}
        progress={48}
        linkTo={ROUTE_CHECKOUT}
      />
    );
  }

  if (checkoutIsAuthor) {
    if (book.order_state === 'pending') {
      const label =
        parentalApprovalUploaded === false ? (
          <FormattedMessage id="print card label pending" />
        ) : parentalApprovalChecked === false ? (
          <FormattedMessage id="print card label waiting for parental approval" />
        ) : paymentReceived === false ? (
          <FormattedMessage id="print card label pay now" />
        ) : (
          <FormattedMessage id="print card label processing" />
        );
      return (
        <MiniCardComponent
          icon="books"
          title={<FormattedMessage id="Print" />}
          label={label}
          progress={32}
          linkTo={ROUTE_CHECKOUT}
        />
      );
    }

    if (book.order_state === 'open') {
      return (
        <MiniCardComponent
          icon="books"
          title={<FormattedMessage id="Print" />}
          label={<FormattedMessage id="print card label open" />}
          progress={16}
          linkTo={ROUTE_CHECKOUT}
        />
      );
    }
  } else {
    // not author of checkout
    if (checkoutPresent) {
      return (
        <MiniCardComponent
          icon="books"
          title={<FormattedMessage id="Print" />}
          label={<FormattedMessage id="print card label locked" />}
          linkTo={ROUTE_CALCULATOR}
        />
      );
    }
  }

  if (!onboardingState) {
    return (
      <MiniCardComponent
        mode="new"
        icon="books"
        title={<FormattedMessage id="Print" />}
        label={<FormattedMessage id="print card label new" />}
        button={<FormattedMessage id="Discover" />}
        linkTo={ROUTE_CALCULATOR}
      />
    );
  }

  return (
    <MiniCardComponent
      mode="default"
      icon="books"
      title={<FormattedMessage id="Print" />}
      label={<FormattedMessage id="print card label default" />}
      linkTo={ROUTE_CALCULATOR}
    />
  );
};

export default PrintCardComponent;
