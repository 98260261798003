import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { FormType } from 'utils/hooks/useForm';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { intl } from 'i18n';

interface PublicSelectGroupProps {
  form: FormType;
  showNoGroup?: boolean;
}

interface SelectGroupProps extends PublicSelectGroupProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
}

@inject('profilesStore', 'applicationStore')
@observer
class SelectGroup extends React.Component<SelectGroupProps> {
  componentDidMount() {
    this.loadGroups();
  }

  loadGroups() {
    this.props.profilesStore.getGroups();
  }

  renderError() {
    return <GenericErrorComponent onRetryClick={() => this.loadGroups()} />;
  }

  renderLoading() {
    return <LoadingOverlayComponent />;
  }

  renderList() {
    const { profilesStore, form, showNoGroup } = this.props;

    const groups = profilesStore.sortedGroups;

    if (!groups.length) {
      return (
        <ItemStackBlock gap="XS">
          <Paragraph>
            <FormattedMessage id="no groups" />
          </Paragraph>
        </ItemStackBlock>
      );
    }

    return (
      <FormStackBlock>
        {showNoGroup && (
          <RadioButtonComponent
            name="group_id"
            label={intl.formatMessage({ id: 'No group' })}
            {...form.bindRadioButton('group_id', '')}
          />
        )}
        {groups.map((group) => (
          <RadioButtonComponent
            key={group.id}
            name="group_id"
            value={group.id}
            label={group.name}
            {...form.bindRadioButton('group_id', group.id.toString())}
          />
        ))}
      </FormStackBlock>
    );
  }

  render() {
    const { profilesStore } = this.props;

    // Do not indicate 'update'. Update silently in the background.
    if (profilesStore.groupsLoadingState === 'loading') {
      return this.renderLoading();
    }

    if (profilesStore.groupsLoadingState === 'error') {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default (props: PublicSelectGroupProps) => {
  // @ts-ignore
  return <SelectGroup {...props} />;
};
