import styled from 'styled-components';

import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

import TaglineBlock from 'blocks/TaglineBlock';


interface SmallProps {
  setHyphen?: boolean;
  line?: boolean;
  color?: keyof typeof COLORS;
}

const Small = styled.h3<SmallProps>`
  margin: 0;
  font-size: 1.3125rem;
  line-height: 1.3;
  font-family: ${FONTS.DEFAULT};
  font-weight: 900;
  letter-spacing: 0;
  color: ${(props) => (props.color ? COLORS[props.color] : 'inherit')};
  ${(props) =>
    props.line &&
    `
    display: flex;
    align-items: center;
    white-space: nowrap;

    &::after {
      content: "";
      flex-grow: 1;
      background: ${COLORS.GRAY800};
      height: 1px;
      width: 100%;
      margin-left: 0.625rem;
    }
  `};

  + ${TaglineBlock} {
    margin-top: 0.3125rem;
  }

  > svg:first-child {
    margin-right: 0.25em;
    vertical-align: -0.1em;
  }

  > svg:last-child {
    margin-left: 0.25em;
    vertical-align: -0.1em;
  }

  > svg:not(:first-child):not(:last-child) {
    margin-left: 0.25em;
    margin-right: 0.25em;
    vertical-align: -0.1em;
  }
`;

export default Small;
