import styled, { AnyStyledComponent } from 'styled-components';

import LabelElement from './LabelElement';
import RadioElement from './RadioElement';
import InfoElement from './InfoElement';

interface CustomRadioBlockElements {
  LabelElement?: any;
  RadioElement?: any;
  InfoElement?: any;
}

const CustomRadioBlock: AnyStyledComponent &
  CustomRadioBlockElements = styled.label`
  display: block;
  position: relative;
`;

CustomRadioBlock.LabelElement = LabelElement;
CustomRadioBlock.RadioElement = RadioElement;
CustomRadioBlock.InfoElement = InfoElement;
export default CustomRadioBlock;
