import styled from "styled-components";
import COLORS from "utils/constants/colors";

interface MiniPreviewProps {
  colorCode: string;
  noBorder?: boolean;
  selected?: boolean;
  big?: boolean;
  small?: boolean;
}

export const MiniPreview = styled.div<MiniPreviewProps>`
  width: ${(props) => (props.big ? '35px' : props.small ? '12px' : '25px')};
  height: ${(props) => (props.big ? '35px' : props.small ? '12px' : '25px')};
  background: ${(props) => props.colorCode};
  border: ${(props) => (props.noBorder ? '' : `1px solid ${COLORS.GRAY900}`)};
  border-radius: 50%;
  margin-right: 0.2rem;

  ${(props) =>
    props.selected &&
    `outline: 2px solid ${COLORS.PRIMARY};
    outline-offset: 2px;`}
`;