import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import LockedLabel from 'domain/Label/LockedLabel';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { AnswerModelType } from 'models/AnswerModel';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FactsheetsStoreType } from 'screens/factsheets/FactsheetsStore';
import { ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import { FormType, handleFormError, useForm } from 'utils/hooks/useForm';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import HelpSubject from 'components/HelpButton/HelpSubject';

interface BaseProps {
  factsheetsStore: FactsheetsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

type Props = BaseProps & HistoryProps;

// TODO remove print setting entirely

@inject('factsheetsStore', 'applicationStore')
@observer
class AnswerDetailScreen extends Component<Props> {
  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps: Props) {
    const isAddMode = this.isAddMode();

    if (isAddMode !== this.isAddMode(prevProps)) {
      this.load();
      return;
    }

    if (isAddMode) {
      if (
        idFromMatch(this.props.match, 'questionId') !==
        idFromMatch(prevProps.match, 'questionId')
      ) {
        this.load();
      }
    } else {
      if (
        idFromMatch(this.props.match, 'answerId') !==
        idFromMatch(prevProps.match, 'answerId')
      ) {
        this.load();
      }
    }
  }

  async load() {
    const { factsheetsStore, form, match } = this.props;

    form.reset();

    if (this.isAddMode()) {
      // need to load question if not present
      const questionId = idFromMatch(match, 'questionId');

      if (!questionId || !!factsheetsStore.question(questionId)) {
        // no id or question is already present in store, do not refresh
        return;
      }

      factsheetsStore.clearCurrentAnswerItem();
      await factsheetsStore.getQuestion(questionId);
    } else {
      // need to load answer
      const answerId = idFromMatch(match, 'answerId');

      if (!answerId) {
        return;
      }

      const answer:
        | AnswerModelType
        | undefined = await factsheetsStore.getAnswer(answerId);

      if (answer) {
        form.setField('answer', answer.answer);
        form.setField('unanswered', answer.unanswered);
        form.setField('print', answer.print);
      }
    }
  }

  async handleSubmit() {
    const { form, factsheetsStore, applicationStore, match } = this.props;

    const profileId = idFromMatch(match);
    if (!profileId) {
      return;
    }

    try {
      if (this.isAddMode()) {
        const questionId = idFromMatch(match, 'questionId');
        if (!questionId) {
          return;
        }

        const answer: any = {
          profile_id: profileId,
          question_id: questionId,
          answer: form.values.answer || '',
          unanswered: !!form.values.unanswered
        };

        if (applicationStore.isOrganizer) {
          answer.print = true; // !!form.values.print;
        }

        await factsheetsStore.createAnswer(answer);

        this.navigateToFactsheetAnswers(true);
      } else {
        const answerId = factsheetsStore.answerItem?.id;
        if (!answerId) {
          return;
        }

        const answer: any = {
          answer: form.values.answer || '',
          unanswered: !!form.values.unanswered
        };

        if (applicationStore.isOrganizer) {
          answer.print = !!form.values.print;
        }

        await factsheetsStore.updateAnswer(answerId, answer);

        this.navigateToFactsheetAnswers();
      }
    } catch (err) {
      handleFormError(form, err);
    }
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  async performDelete() {
    const { factsheetsStore } = this.props;

    const id = idFromMatch(this.props.match, 'answerId');
    if (!id) {
      return;
    }

    this.finishDelete();

    try {
      await factsheetsStore.removeAnswer(id);
    } catch (error: any) {
      // delete errors are handled by store internally
      return;
    }

    this.navigateToFactsheetAnswers(true);
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  navigateToFactsheetAnswers(replace = false) {
    const profileId = idFromMatch(this.props.match);
    if (!profileId) {
      return;
    }

    (replace ? this.props.history.replace : this.props.history.push)(
      ROUTE_FACTSHEETS + '/detail/' + profileId + '/answers'
    );
  }

  isAddMode(props?: Props) {
    const { match } = props || this.props;
    return !idFromMatch(match, 'answerId');
  }

  getQuestion() {
    const { factsheetsStore } = this.props;

    return this.isAddMode()
      ? factsheetsStore.question(
        idFromMatch(this.props.match, 'questionId') || -1
      )
      : factsheetsStore.questionItem;
  }

  renderPage(content: React.ReactNode) {
    const { factsheetsStore } = this.props;
    const { chapter } = factsheetsStore;
    const isTeacherFactsheets = chapter?.chapter_type === 'teacher_factsheet';

    return (
      <>
        <TopActionBlock>
          <CancelLink
            to={ROUTE_FACTSHEETS + '/detail/' + idFromMatch(this.props.match) + '/answers'}
          />
        </TopActionBlock>


        <PageStackBlock>
          {content}
        </PageStackBlock>

        {isTeacherFactsheets ? (
          <HelpSubject subject="teachers" />
        ) : (
          <HelpSubject subject="factsheets" />
        )}
      </>
    );
  }

  renderForm() {
    const { factsheetsStore, form } = this.props;
    const isAddMode = this.isAddMode();

    const question = this.getQuestion();

    let extra;
    if (form.confirm) {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm delete header" />}
          text={
            <FormattedMessage
              id="confirm delete item"
              values={{
                item: <FormattedMessage id="This answer" />
              }}
            />
          }
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => this.performDelete()}
          onAbort={() => this.finishDelete()}
          confirmColor="RED"
        />
      );
    } else if (factsheetsStore.questionItemLoadingState === 'update_error') {
      extra = <GenericErrorComponent />;
    }

    return this.renderPage(
      <>
        <Headline.Large>{question?.question}</Headline.Large>

        {extra}

        <FormStackBlock>
          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="unanswered_checkbox"
              {...form.bindCheckbox('unanswered')}
            />
            <CustomCheckboxBlock.LabelElement htmlFor="unanswered_checkbox">
              <FormattedMessage id="Do not answer" />
            </CustomCheckboxBlock.LabelElement>
          </CustomCheckboxBlock>

          {!form.values.unanswered && (
            <TextareaComponent
              name="answer"
              label={<FormattedMessage id="Answer" />}
              {...form.bindInput('answer')}
              autoFocus={true}
            />
          )}

          {/*applicationStore.isOrganizer && (
            <CustomCheckboxBlock>
              {!form.values.unanswered ? (
                <CustomCheckboxBlock.CheckboxElement
                  key="state_answered"
                  id="state_checkbox"
                  {...form.bindCheckbox('print')}
                />
              ) : (
                <CustomCheckboxBlock.CheckboxElement
                  key="state_unanswered"
                  id="state_checkbox"
                  disabled={true}
                />
              )}
              <CustomCheckboxBlock.LabelElement htmlFor="state_checkbox">
                <FormattedMessage id="Print answer" />
              </CustomCheckboxBlock.LabelElement>
            </CustomCheckboxBlock>
          )*/}
        </FormStackBlock>

        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>

        {!isAddMode && (
          <>
            <Divider />
            <DeleteButton onClick={() => this.confirmDelete()} />
          </>
        )}
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.load()} />
    );
  }

  renderLocked() {
    const { form } = this.props;
    const question = this.getQuestion();

    return this.renderPage(
      <>
        <Headline.Large>{question?.question}</Headline.Large>
        <LockedLabel id="Answers" />

        {!this.isAddMode() &&
          (form.values.unanswered ? (
            <CustomCheckboxBlock>
              <CustomCheckboxBlock.CheckboxElement
                id="unanswered_checkbox"
                {...form.bindCheckbox('unanswered')}
                disabled={true}
              />
              <CustomCheckboxBlock.LabelElement htmlFor="unanswered_checkbox">
                <FormattedMessage id="Do not answer" />
              </CustomCheckboxBlock.LabelElement>
            </CustomCheckboxBlock>
          ) : (
            <>
              <FormattedMessage id="Answer" />
              <Paragraph>{form.values.answer}</Paragraph>
            </>
          ))}
      </>
    );
  }

  render() {
    const { applicationStore, factsheetsStore } = this.props;

    if (applicationStore.isBookLocked) {
      return this.renderLocked();
    }

    if (factsheetsStore.answerItemLoadingState === 'error') {
      return this.renderError();
    }

    if (factsheetsStore.isAnswerItemLoading) {
      return this.renderLoading();
    }

    if (this.isAddMode()) {
      if (factsheetsStore.questionItemLoadingState === 'error') {
        return this.renderError();
      }

      if (factsheetsStore.isQuestionItemLoading) {
        return this.renderLoading();
      }
    }

    return this.renderForm();
  }
}

export default (props: any) => {
  const form = useForm({ print: true });
  return <AnswerDetailScreen {...props} form={form} />;
};
