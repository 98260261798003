import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { ROUTE_DASHBOARD, ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import ItemStackBlock from 'blocks/ItemStackBlock';
import UppercaseHeading from 'elements/UppercaseHeading';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import { ChaptersStoreType } from 'models/ChaptersStore';
import NakedLink from 'elements/NakedLink';
import { ButtonListBlock, ButtonListBlockBody, ButtonListBlockItem } from 'blocks/ButtonListBlock/ButtonListBlock';
import chapterPath from 'utils/chapter-path';

interface GroupsScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
  chaptersStore: ChaptersStoreType;
}


@inject('applicationStore', 'profilesStore', 'chaptersStore')
@observer
class GroupUsedChaptersScreen extends React.Component<
GroupsScreenProps & HistoryProps
> {
  componentDidMount() {
    const { applicationStore } = this.props;
    const id = idFromMatch(this.props.match);

    if (!applicationStore.isOrganizer) {
      this.props.history.replace(ROUTE_DASHBOARD);
    }

    if (id) {
      this.loadGroup(id);
      this.loadChapters();
    } else {
      this.props.history.replace(ROUTE_SETTINGS + '/groups/' + id);
    }
  }

  async loadChapters() {
    const { chaptersStore } = this.props;
    const id = idFromMatch(this.props.match);


    // just to be sure
    chaptersStore.clearCurrentItem();

    // refresh book first so we know if we have groups
    await this.props.applicationStore.getBook();

    await chaptersStore.getAllChapters();
    if (!chaptersStore.isListError && !chaptersStore.allowedChapterTypes) {
      // something went very wrong
      this.props.history.replace(ROUTE_SETTINGS + '/groups/' + id);
    }
  }

  async loadGroup(id: number) {
    const { profilesStore } = this.props;

    if (profilesStore.isGroupItemLoading) {
      // do not interrupt running request
      return;
    }

    await profilesStore.getGroup(id);
  }

  renderList() {
    const { profilesStore, chaptersStore } = this.props;
    const { groupItem } = profilesStore;

    if (!groupItem) {
      return null;
    }

    const chapters = chaptersStore.getChaptersByGroupId(groupItem.id);

    if (!chapters) {
      // TODO: Can't be possible but in Case return to Group Detail Screen
      this.props.history.replace(ROUTE_SETTINGS + '/groups/' + groupItem.id);
    }


    return this.renderPage(
      <>
        <ItemStackBlock gap="S">
          <UppercaseHeading>
            <FormattedMessage
              id="chapters filter count"
              values={{
                count: chapters.length
              }}
            />
          </UppercaseHeading>

          <ListStackBlock>
            <ButtonListBlock>
              {chapters.map((chapter) => {
                return (
                  <ButtonListBlockItem key={chapter.id}>
                    <NakedLink to={chapterPath(chapter.chapter_type, chapter.id)}>
                      <ButtonListBlockBody>
                        {chapter.title}
                      </ButtonListBlockBody>
                    </NakedLink>
                  </ButtonListBlockItem>
                );
              }
              )}
            </ButtonListBlock>
          </ListStackBlock>
        </ItemStackBlock>
      </>
    );
  }

  renderPage(content?: any) {
    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="group used header" />} text={<FormattedMessage id="group used info" />} />

          {content}
        </PageStackBlock>
      </>
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  render() {
    const { profilesStore } = this.props;

    if (profilesStore.isGroupItemLoading) {
      return this.renderLoading();
    }

    if (profilesStore.groupItemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    return this.renderList();
  }
}

export default GroupUsedChaptersScreen;
