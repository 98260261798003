import React from 'react';
import { FormattedMessage } from 'react-intl';

import CheckboxBlock from 'blocks/CheckboxBlock';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import { intl } from 'i18n';
import { FormType } from 'utils/hooks/useForm';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';

interface CheckoutAddressProps {
  form: FormType;
}

const DeliveryAddress = ({ form }: CheckoutAddressProps) => {
  return (
    <>
      <Headline.Large>
        <FormattedMessage id="Checkout shipping address" />
      </Headline.Large>
      <InputMessage warning={true}>
        <FormattedMessage id="Checkout shipping address info" />
      </InputMessage>

      <FormStackBlock>
        <TextInputComponent
          name="shipping_name"
          label={<FormattedMessage id="Checkout recipient name" />}
          autoComplete="name"
          {...form.bindInput('shipping_name')}
          placeholder="Max Mustermann"
        />
        <TextInputComponent
          type="tel"
          name="phone_number"
          label={<FormattedMessage id="Checkout phone number" />}
          autoComplete="tel"
          {...form.bindInput('phone_number')}
          
        />
        <TextInputComponent
          name="shipping_address_extra"
          label={<FormattedMessage id="address extra" />}
          autoComplete="address-line1"
          {...form.bindInput('shipping_address_extra')}
          placeholder="c/o Firma"
        />
        <TextInputComponent
          name="shipping_street"
          label={<FormattedMessage id="street" />}
          autoComplete="street-address"
          {...form.bindInput('shipping_street')}
          placeholder="Musterstraße"
        />
        <TextInputComponent
          name="shipping_housenumber"
          label={<FormattedMessage id="house number" />}
          {...form.bindInput('shipping_housenumber')}
          placeholder="1,2,3,..."
        />
        <TextInputComponent
          type="number"
          name="shipping_zip"
          minLength={5}
          maxLength={5}
          label={<FormattedMessage id="zip" />}
          autoComplete="postal-code"
          {...form.bindInput('shipping_zip')}
          placeholder="12345"
        />
        <TextInputComponent
          name="shipping_city"
          label={<FormattedMessage id="city" />}
          {...form.bindInput('shipping_city')}
          placeholder="Musterstadt"
        />
        <TextInputComponent
          name="country shipping"
          label={<FormattedMessage id="country" />}
          value={intl.formatMessage({ id: 'Germany' })}
          readOnly={true}
          disabled={true}
        />
        <CheckboxBlock>
          <CheckboxBlock.CheckboxElement
            id="billing_address_set"
            {...form.bindCheckbox('billing_address_set')}
          />
          <CheckboxBlock.LabelElement htmlFor="billing_address_set">
            <FormattedMessage id="Checkout billing address different" />
          </CheckboxBlock.LabelElement>
        </CheckboxBlock>
      </FormStackBlock>
    </>
  );
};
export default DeliveryAddress;
