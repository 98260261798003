import { post } from "api/BaseApi";
import { TextileDiscount } from "./fetchTextileDiscounts";

export interface TextilePrice {
  price: Price;
  discounts?: TextileDiscount[];
  preorders_amount?: number;
  errors: string[];
}

interface Price {
  price: number;
  end_price: number;
  possible_price: number;
  form_textile_price: number;
  items: TextilePriceItem[];
}

export interface TextilePriceItem {
  name?: string;
  amount: number;
  price: number;
  textile_id: number;
  possible_price: number;
  form_possible_price: number;
  end_price: number;
}

export const calculateTextilePriceFromForm = (
  textiles: { id: number; amount: number }[],
  textileOrderId: number
): Promise<TextilePrice> => {
  return post(`/textile_pricing/calculate_form`, {
    textile_pricing: {
      textiles
    },
    textile_order_id: textileOrderId
  });
}