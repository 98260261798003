import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';

import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface TocScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  form: FormType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class TocEditEntry extends React.Component<TocScreenProps & HistoryProps> {

  state = {
    showConfirmDelete: false
  };

  componentDidMount() {
    const { applicationStore } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature('toc', this.props.location.pathname)
    ) {
      return;
    }

    this.loadChapter();
  }

  async loadChapter() {
    const chapterId = chapterIdFromMatch(this.props.match);
    if (chapterId) {
      await this.props.chaptersStore.getChapter(chapterId);
    }
    this.fillForm();
  }

  fillForm() {
    const { item } = this.props.chaptersStore;

    if (item) {
      this.props.form.setField('toc_title', item.toc_title);
    }
  }

  getCurrentChapterId(): number | undefined {
    const id = this.props.history.location.state?.id || undefined;

    if (!id) {
      return undefined;
    }

    return typeof id === 'number' ? id : parseInt(id || '0', 10) || undefined;
  }

  navigateToChapter() {
    const id = this.getCurrentChapterId();
    if (!id) {
      return '/app/book/content';
    } else {
      return '/app/book/toc/' + id;
    }
  }

  handleSubmit() {
    this.update({ toc_title: this.props.form.values.toc_title });
  }

  async update(patch: any) {
    const { chaptersStore, applicationStore, form } = this.props;
    const chapter = chaptersStore.item;
    if (!chapter) {
      return;
    }

    form.setLoading(true);

    try {
      await chaptersStore.updateChapter(chapter.id, patch);
    } catch (error: any) {
      if (handleFormError(form, error)) {
        form.setLoading(false);
      }

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'change save error' }),
        'error'
      );
      return;
    }
    applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'change saved' })
    );

    this.props.history.push(this.navigateToChapter());
  }

  confirmDelete() {
    this.update({ list_in_toc: false });
  }

  renderConfirmDelete() {
    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="toc remove entry header" />}
        text={<FormattedMessage id="toc remove entry info" />}
        confirmText={<FormattedMessage id="toc remove entry" />}
        confirmColor="PRIMARY"
        abortText={<FormattedMessage id="Cancel" />}
        onConfirm={() => this.confirmDelete()}
        onAbort={() =>
          this.setState({
            showConfirmDelete: false
          })
        }
      />
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadChapter()} />
    );
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <BackLink to={this.navigateToChapter()} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        <HelpSubject subject="toc" />
      </>
    );
  }

  render() {
    const { chaptersStore, form } = this.props;
    const { item } = chaptersStore;

    if (chaptersStore.isItemLoading) {
      return this.renderLoading();
    }

    if (chaptersStore.isItemError) {
      return this.renderError();
    }

    if (!item) {
      return null;
    }

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="toc edit entry" />
        </Headline.Large>
        <FormStackBlock>
          <TextInputComponent
            name="chapter_type"
            value={intl.formatMessage({
              id: 'chapter type ' + item.chapter_type
            })}
            label={<FormattedMessage id="Chapter" />}
            readOnly={true}
            disabled={true}
          />

          <TextInputComponent
            name="toc_title"
            label={<FormattedMessage id="toc chapter title" />}
            {...form.bindInput('toc_title')}
          />
        </FormStackBlock>
        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
        <Divider />

        <DeleteButton
          messageId="toc remove entry"
          onClick={() => this.setState({ showConfirmDelete: true })}
        />

        {this.state.showConfirmDelete && (
          this.renderConfirmDelete()
        )}

      </>
    );
  }
}

const TocEditEntryScreen = (props: any) => {
  const form = useForm();
  return <TocEditEntry {...props} form={form} />;
};

export default TocEditEntryScreen;
