import React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult
} from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

import CardBlock from 'blocks/CardBlock';
import IconComponent from 'components/IconComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import LineClamp from 'elements/LineClamp';
import styled from 'styled-components';

const CustomCardBlock = styled(CardBlock)`
  list-style-type: none;
  margin-bottom: 1rem;
`;

const CustomPadding = styled.div`
  padding: 1em 1.25em;
`;

const SortLayout = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;

  /* EveryCard’s min-height */
  min-height: 3.375rem;
`;

const DragHandleDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.5em 0 3em;
  cursor: grab;
  user-select: none;
`;

interface Item {
  id: number;
  text: React.ReactFragment;
}

interface ItemSortingProps {
  items: Item[];
  onSortEnd: (oldIndex: number, newIndex: number) => void;
  text?: string
}

const ItemSortingComponent = ({ items, onSortEnd, text }: ItemSortingProps) => {
  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }
    onSortEnd(result.source.index, result.destination.index);
  };
  return (
    <>
      <Paragraph color="TEXT_LIGHT" >
        <FormattedMessage id={text ? text : "Item sorting info"} />
      </Paragraph>

      <ListStackBlock>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(
              provided: DroppableProvided,
              _snapshot: DroppableStateSnapshot
            ) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={String(item.id)}
                    index={index}
                  >
                    {(
                      providedDraggable: DraggableProvided,
                      _snapshotDraggable: DraggableStateSnapshot
                    ) => (
                      <CustomCardBlock
                        as="li"
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        style={providedDraggable.draggableProps.style}
                      >
                        <SortLayout>
                          <CustomPadding>
                            <LineClamp lines={1}>
                              <TextElement  color="GRAY10">{item.text}</TextElement>
                            </LineClamp>
                          </CustomPadding>
                          <DragHandleDiv>
                            <IconComponent
                              icon="DRAG"
                              fill="TEXT_LIGHT"
                              size={1.5}
                            />
                          </DragHandleDiv>
                        </SortLayout>
                      </CustomCardBlock>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </ListStackBlock>
    </>
  );
};

export default ItemSortingComponent;
