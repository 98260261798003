const SORT_LOCALE = 'de';

export const sortById = (a: any, b: any) => {
  const aId = a.id || 0;
  const bId = b.id || 0;

  if (aId < bId) {
    return -1;
  }
  if (aId > bId) {
    return 1;
  }
  return 0;
};

export const sortByName = (a: any, b: any) => {
  const aName = a.name?.toString() || '';
  const bName = b.name?.toString() || '';
  return aName.localeCompare(bName, SORT_LOCALE);
};

export const sortByTitle = (a: any, b: any) => {
  const aName = a.title?.toString() || '';
  const bName = b.title?.toString() || '';
  return aName.localeCompare(bName, SORT_LOCALE);
};

export const sortByField = (fieldName: string, desc = false) => {
  return (a: any, b: any) => {
    a = a[fieldName] || null;
    b = b[fieldName] || null;

    if (a > b) {
      return desc ? -1 : 1;
    }
    if (a < b) {
      return desc ? 1 : -1;
    }
    return 0;
  };
};

export const sortByFieldLowerCase = (fieldName: string, desc = false) => {
  return (a: any, b: any) => {
    a = a[fieldName]?.toString().toLowerCase() || '';
    b = b[fieldName]?.toString().toLowerCase() || '';

    if (a > b) {
      return desc ? -1 : 1;
    }
    if (a < b) {
      return desc ? 1 : -1;
    }
    return 0;
  };
};

export const sortByLastNameThenFirstName = (a: any, b: any) => {
  const aLastName = a.last_name?.toString() || '';
  const bLastName = b.last_name?.toString() || '';
  const aFirstName = a.first_name?.toString() || '';
  const bFirstName = b.first_name?.toString() || '';
  let s = aLastName.localeCompare(bLastName, SORT_LOCALE);

  if (s === 0) {
    s = aFirstName.localeCompare(bFirstName, SORT_LOCALE);
  }

  return s
};

export const sortByFirstNameThenLastName = (a: any, b: any) => {
  const aLastName = a.last_name?.toString() || '';
  const bLastName = b.last_name?.toString() || '';
  const aFirstName = a.first_name?.toString() || '';
  const bFirstName = b.first_name?.toString() || '';
  let s = aFirstName.localeCompare(bFirstName, SORT_LOCALE);

  if (s === 0) {
    s = aLastName.localeCompare(bLastName, SORT_LOCALE);
  }

  return s
};