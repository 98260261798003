import React, { useContext, useEffect, useState } from 'react';

import TopActionBlock from 'blocks/TopActionBlock';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import BackLink from 'domain/Links/BackLink';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import CardBlock from 'blocks/CardBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import Divider from 'components/Divider/Divider';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import Headline from 'elements/Headline';
import { useTextileCheckoutQuery } from 'queries/textile_deals/useTextileCheckoutQueries';
import { useTextileOrderForCheckoutQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import TextileChooseDeliveryDateScreen from 'screens/textile_checkout/screens/TextileChooseDeliveryDateScreen';
import DownloadInvoiceButton from '../DownloadInvoiceButton';
import OrderPaymentInfo from '../OrderPaymentInfo';
import TextileOrderTimeline from './TextileOrderTimeline';

const TextileOrderScreen: React.FC<HistoryProps> = (props) => {
  const [showDeliveryDate, setShowDeliveryDate] = useState<boolean>(false);
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const textileCheckout = useTextileCheckoutQuery(textileOrderId);
  const textileOrder = useTextileOrderForCheckoutQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('order');
  }, [accessContext]);

  const reloadData = () => {
    textileCheckout.refetch();
    textileOrder.refetch();
    setShowDeliveryDate(false);
  };

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileCheckout.isError) {
    props.history.replace(textileOrderRoute(props.match, '/checkout'));
  }

  if (
    !textileAppState ||
    textileCheckout.isLoading ||
    !textileCheckout.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderOrder = () => {
    const { invoice } = textileCheckout.data;

    const isAuthor =
      textileAppState?.textile_profile.id === textileCheckout.data.author?.id;

    if (!invoice) {
      return null;
    }

    return renderPage(
      <>
        {showDeliveryDate ? (
          <TextileChooseDeliveryDateScreen
            deliveryDateSet={() => reloadData()}
            checkoutFinished={true}
            textileOrderId={textileOrderId}
          />
        ) : (
          <>
            <Headline.Large>
              <FormattedMessage id="Order finish" />
            </Headline.Large>

            <Link to={textileOrderRoute(props.match, '/order/placed')}>
              <CardBlock>
                <PaddingBlock arrow={true}>
                  <Paragraph>
                    <FormattedMessage id="Order show details" />
                  </Paragraph>
                </PaddingBlock>
              </CardBlock>
            </Link>

            <Divider />

            <OrderPaymentInfo
              paymentReceived={textileCheckout.data.payment_received || false}
              price={invoice.price}
              usage={'t-' + textileOrder.data.id}
            />

            {invoice.invoice_pdf && <DownloadInvoiceButton invoice={invoice} />}

            <Divider />

            <TextileOrderTimeline
              textileOrder={textileOrder.data}
              deliveryDate={textileOrder.data.textile_delivery_date}
              paymentReceived={textileCheckout.data.payment_received || false}
              changeStateShowDeliveryDate={() => setShowDeliveryDate(true)}
              showButton={isAuthor}
            />
          </>
        )}
      </>
    );
  };

  return renderOrder();
};

export default TextileOrderScreen;