import { types, Instance } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import ChapterModel, { createChapterModel } from './ChapterModel';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';

const QuoteModel = types.model('QuoteModel', {
  id: types.identifierNumber,
  chapter_id: types.maybe(types.number),
  chapter: types.maybe(ChapterModel),
  quote: types.maybe(types.string),
  author: types.maybe(ProfileAsAuthorModel),
  print: types.maybe(types.boolean),
  sorting: types.maybe(types.number)
});

export const createQuoteModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return QuoteModel.create({
    id: mandatoryId(data.id),
    chapter_id: numberOrUndefined(data.chapter_id),
    chapter: createChapterModel(data.chapter),
    quote: data.quote || undefined,
    author: createProfileAsAuthorModel(data.author),
    print: booleanOrUndefined(data.print),
    sorting: numberOrUndefined(data.sorting)
  });
};

export type QuoteModelType = Instance<typeof QuoteModel>;
export default QuoteModel;
