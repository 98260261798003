import { Boundary } from './ContentChunker';

export default function shrinkNameColumn(
  textContent: string[],
  startingFontSize: number,
  minFontSize: number,
  styleColumn: string,
  [outerWidth, outerHeight]: Boundary,
  shrinkingStep: number
): [number, boolean] {
  const outerElement = document.createElement('div');
  outerElement.setAttribute(
    'style',
    `display: block; visibility: hidden; width: ${outerWidth}px; height: ${outerHeight}`
  );

  const innerElement = document.createElement('div');
  outerElement.appendChild(innerElement);

  document.body.appendChild(outerElement);

  let fontSize = startingFontSize;
  while (true) {
    innerElement.replaceChildren();
    innerElement.setAttribute(
      'style',
      `${styleColumn} font-size: ${fontSize}px`
    );
    textContent.forEach((content) => {
      const item = document.createElement('p');
      item.setAttribute('style', 'padding-bottom: 7px')
      item.innerText = content;
      innerElement.appendChild(item);
    });

    if (innerElement.lastElementChild) {
      innerElement.lastElementChild.setAttribute('style', 'padding-bottom: 0px');
    }

    // measure
    const { height, width } = innerElement.getBoundingClientRect();

    if (fontSize < minFontSize) {
      outerElement.remove();
      return [minFontSize, true];
    } else if (height < outerHeight && width <= outerWidth) {
      outerElement.remove();
      return [fontSize, false];
    }

    fontSize -= shrinkingStep;
  }
}
