import React, { Component } from 'react';
import TopActionBlock from 'blocks/TopActionBlock';
import BackLink from 'domain/Links/BackLink';
import { ROUTE_RANKINGS, ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Divider from 'components/Divider/Divider';
import SharingComponent from 'components/SharingComponent';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { Link } from 'react-router-dom';
import { EveryCard, EveryCardPadding, EveryCardBody, EveryCardHeadline } from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';
import PageHeader from 'components/PageHeader/PageHeader';

interface StudentInviteScreenProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class StudentInviteScreen extends Component<StudentInviteScreenProps & HistoryProps> {

  render() {
    const chapterId = chapterIdFromMatch(this.props.match);

    if (!chapterId) {
      return null;
    }

    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_RANKINGS + '/' + chapterId + '/settings'} />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Invite students" />} text={<FormattedMessage id="ranking invite students" />} />

          <SharingComponent
            label="personal invite link"
            buttonOutside={true}
            modalHeader="invite link"
            message="Nuggit invite text"
            buttonColor="WHATSAPP"
            url={this.props.applicationStore.book?.invitation_url}
          />

          <Divider />

          <Link to={ROUTE_SETTINGS + '/students'}>
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="Link manage students" />
                  </EveryCardHeadline>
                </EveryCardBody>
                <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
              </EveryCardPadding>
            </EveryCard>
          </Link>
        </PageStackBlock>
      </>
    );
  }
};

export default StudentInviteScreen;
