import React, { JSXElementConstructor } from 'react';

import {
  EveryCard, EveryCardBody, EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';

import { BaseTextileProfile } from 'api/textile_deals/fetchTextileProfiles';
import TagBlock from 'blocks/TagBlock';
import TextElement from 'components/TextElement/TextElement';
import { FormattedMessage } from 'react-intl';

type PreorderTagType = 'rejected' | 'open';

export const PreorderCardTag = ({ type }: { type?: PreorderTagType }) => {
  switch (type) {
    case 'rejected':
      return (
        <TagBlock color="RED" backgroundColor="RED_LIGHT">
          <FormattedMessage id="Rejected" />
        </TagBlock>
      );

    case 'open':
      return (
        <TagBlock color="PRIMARY" backgroundColor="PRIMARY_LIGHT">
          <FormattedMessage id="TBD" />
        </TagBlock>
      );

    default:
  }

  return null;
};

export interface TextileProfileListItemProps {
  profile: BaseTextileProfile;
  onClick?: (studentId: number) => void;
  disabled?: boolean;
}

export type TextileProfileListItemType = JSXElementConstructor<TextileProfileListItemProps>;

export const TextilePreorderProfileListItem: TextileProfileListItemType = ({
  profile,
  onClick,
  disabled
}: TextileProfileListItemProps) =>
  (
    <EveryCard
      as={onClick && 'button'}
      onClick={onClick && (() => onClick(profile.id))}
      disabled={disabled}
    >
      <EveryCardPadding>
        <EveryCardBody>
          <EveryCardHeadline>{profile.name || profile.id}</EveryCardHeadline>

        <TextElement>
              {profile && profile.textile_group ? (
                profile.textile_group.name
              ) : (
                <FormattedMessage id="No group" />
              )}
            </TextElement>
          </EveryCardBody>

          <PreorderCardTag type={profile.preorder_state === 'rejected' ? 'rejected' : profile.preorder_state === 'open' ? 'open' :undefined} />

      </EveryCardPadding>
    </EveryCard>
  )


export default TextilePreorderProfileListItem;
