import { get, uri } from 'api/BaseApi';
import { TextileVariant } from './fetchTextileVariants';

export interface Textile {
  id: number;
  name: string;
  internal_identifier: string;
  position: number;
  possible_price?: number;
  end_price?: number;
  textile_variants?: TextileVariant[];
  size_table_image?: string;
  measurement_table_image?: string;
}

export const getTextile = (
  id: number,
  textileOrderId: number
): Promise<Textile> => {
  return get('/textiles/' + uri(id) + `?textile_order_id=${textileOrderId}`);
};

export const getTextiles = (textileOrderId: number): Promise<Textile[]> => {
  return get(`/textiles?textile_order_id=${textileOrderId}`);
};
