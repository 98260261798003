import React from 'react';

import IconComponent from 'components/IconComponent';
import styled from 'styled-components';

// interface CartAmountProp {
//   twoDigit?: boolean;
// }

const CartIconContainer = styled.div`
  position: relative;
`;

// const CartAmount = styled.div<CartAmountProp>`
//   position: absolute;
//   top: ${(props) => props.twoDigit ? '-10px' : '-5px'};
//   right: ${(props) => props.twoDigit ? '-10px' : '-5px'};
//   width: ${(props) => props.twoDigit ? '25px' : '20px'};
//   height: ${(props) => props.twoDigit ? '25px' : '20px'};;
//   border-radius: 50%;
//   background-color: black;
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: ${FONT_SIZES.XS};
// `;

const CartIconComponent = ({ amount }: { amount: number }) => {
  return (
    <CartIconContainer>
      <IconComponent icon="BASKET" size={1.5}/>

      {/* {amount === 0 || amount === undefined ? (
        <CartAmount>
            +
        </CartAmount>
      ) : (
        <CartAmount twoDigit={amount >= 10}>
            {amount}
        </CartAmount>
      )} */}
    </CartIconContainer>
  );
};

export default CartIconComponent;
