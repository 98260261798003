import React from 'react';
import { FormattedMessage } from 'react-intl';

import COLORS from 'utils/constants/colors';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import OverlayBlock from 'blocks/OverlayBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import PageHeader from 'components/PageHeader/PageHeader';

export interface ConfirmOption {
  text: string;
  color: keyof typeof COLORS;
  action: () => void;
}

interface ConfirmBoxMultiComponentProps {
  header?: React.ReactChild;
  text?: React.ReactChild;
  options: ConfirmOption[];
  abortText?: React.ReactChild;
  abortColor?: keyof typeof COLORS;
  onAbort?: () => void;
}

// TODO Handle ESC key press? Maybe using 'react-keyboard-event-handler'?
export const ConfirmBoxMultiComponent = ({
  header,
  text,
  options,
  onAbort,
  abortText,
  abortColor
}: ConfirmBoxMultiComponentProps) => (
  <OverlayBlock>
    <OverlayBlock.InnerElement>
      <PaddingBlock>
        <PageHeader headline={header} text={text} />
        <ItemStackBlock gap="XS">
          {options.map((option, index) => {
            return (
              <ButtonBlock
                key={index}
                background={option.color || 'PRIMARY'}
                onClick={option.action}
              >
                {option.text}
              </ButtonBlock>
            );
          })}
          {onAbort && (
            <ButtonBlock
              background={abortColor || 'SECONDARY_DARK'}
              onClick={onAbort}
            >
              {abortText ? abortText : <FormattedMessage id="No" />}
            </ButtonBlock>
          )}
        </ItemStackBlock>
      </PaddingBlock>
    </OverlayBlock.InnerElement>
  </OverlayBlock>
);

export default ConfirmBoxMultiComponent;
