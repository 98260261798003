import React from 'react';

import {
  Route,
  Switch
} from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import TextileOrderScreen from 'screens/order/screens/textiles/TextileOrderScreen';
import { ROUTE_TEXTILE_CHECKOUT } from 'utils/constants/routes';

import TextileContentNotPrintedScreen from './screens/TextileContentNotPrintedScreen';
import TextileCheckoutScreen from './TextileCheckoutScreen';

export default () => (
  <Switch>
    <Route path={ROUTE_TEXTILE_CHECKOUT + '/order'} component={TextileOrderScreen} />

    {/* Currently not used */}
    <Route path={ROUTE_TEXTILE_CHECKOUT + '/not_printed'} component={TextileContentNotPrintedScreen} />

    <Route path={ROUTE_TEXTILE_CHECKOUT} exact={true} component={TextileCheckoutScreen} />

    <NotFoundRoute />
  </Switch>
);
