import { inject, observer } from 'mobx-react';
import React, { Component, MouseEvent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import EmptyStateComponent from 'components/EmptyStateComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import { MottoModelType } from 'models/MottoModel';
import useForm, { FormType } from 'utils/hooks/useForm';

import { MottosStoreType } from '../../MottosStore';
import { MottoListItemType } from './MottosSelectListItem';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';

interface PublicMottosListProps {
  noSearch?: boolean;
  selected?: number[];
  ranked?: boolean;
  itemComponent: MottoListItemType;
  onMottoClick?: (e: MouseEvent, mottoId: number) => void;
}

interface MottosListProps extends PublicMottosListProps {
  mottosStore: MottosStoreType;
  form: FormType;
}

@inject('mottosStore')
@observer
class MottosList extends Component<MottosListProps> {
  componentDidMount() {
    // load/update students every time we display the list
    this.loadMottos();
  }

  loadMottos() {
    this.props.mottosStore.getAllMottos();
  }

  renderError() {
    return <GenericErrorComponent onRetryClick={() => this.loadMottos()} />;
  }

  renderLoading() {
    return <LoadingOverlayComponent />;
  }

  renderEmpty() {
    return <EmptyStateComponent headerId="no mottos" textId="go add a motto" />;
  }

  renderList(allMottos: MottoModelType[]) {
    const {
      mottosStore,
      form,
      noSearch,
      onMottoClick,
      itemComponent,
      selected
    } = this.props;

    const hasSearch = !noSearch;

    let mottos: MottoModelType[];
    let heading: ReactNode;

    if (!form.values.filter) {
      mottos = allMottos;

      if (mottos.length === 0) {
        return this.renderEmpty();
      }

      heading = (
        <FormattedMessage
          id="mottos count"
          values={{
            count: mottos.length
          }}
        />
      );
    } else {
      mottos = mottosStore.filteredMottos(form.values.filter);
      heading = (
        <FormattedMessage
          id="mottos filter count"
          values={{
            count: mottos.length
          }}
        />
      );
    }

    const Item = itemComponent;

    return (
      <ItemStackBlock gap="L">
        {hasSearch && (
          <SearchListInput form={form} placeholderId="Find motto" />
        )}

        <ItemStackBlock gap="S">
          <UppercaseHeading>{heading}</UppercaseHeading>

          <ListStackBlock>
            {mottos.map((motto) => (
              <Item
                key={motto.id}
                motto={motto}
                selected={selected && selected.indexOf(motto.id) > -1}
                onClick={onMottoClick}
              />
            ))}
          </ListStackBlock>
        </ItemStackBlock>
      </ItemStackBlock>
    );
  }

  render() {
    const { mottosStore, ranked } = this.props;

    if (mottosStore.isListLoading) {
      return this.renderLoading();
    }

    if (mottosStore.isListError) {
      return this.renderError();
    }

    return ranked
      ? this.renderList(mottosStore.rankedMottos)
      : this.renderList(mottosStore.allMottos);
  }
}

export default (props: PublicMottosListProps) => {
  const form = useForm();
  return <MottosList {...(props as MottosListProps)} form={form} />;
};
