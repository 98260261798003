import React, { JSXElementConstructor, MouseEvent } from 'react';

import {
  EveryCard,
  EveryCardHeadline,
  EveryCardPadding,
  EveryCardBody
} from 'blocks/EveryCard/EveryCard';
import { ProfileModelType } from 'models/ProfileModel';
import IconComponent from 'components/IconComponent';

export interface StudentListItemProps {
  profile: ProfileModelType;
  isCurrentUser: boolean;
  selected?: boolean;
  onClick?: (e: MouseEvent, studentId: number) => void;
  hideCurrentUser?: boolean;
}

export type StudentListItemType = JSXElementConstructor<StudentListItemProps>;

export const StudentsListItem: StudentListItemType = ({
  profile,
  selected,
  onClick,
  isCurrentUser,
  hideCurrentUser
}: StudentListItemProps) =>
  hideCurrentUser && isCurrentUser ? null : (
    <EveryCard
      as={onClick && 'button'}
      onClick={onClick && ((e: MouseEvent) => onClick(e, profile.id))}
      selected={selected}
    >
      <EveryCardPadding>
        <EveryCardBody>
          <EveryCardHeadline>{profile.name || profile.id}</EveryCardHeadline>
        </EveryCardBody>

        {selected && (
          <IconComponent icon="TICK_CIRCLE" fill="PRIMARY" size={2} />
        )}
      </EveryCardPadding>
    </EveryCard>
  );

export default StudentsListItem;
