import React from 'react';

import { Invoice } from 'api/textile_deals/fetchTextileCheckout';
import CardBlock from 'blocks/CardBlock';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import IconComponent from 'components/IconComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import { InvoiceModelType } from 'models/InvoiceModel';
import { FormattedMessage } from 'react-intl';

const DownloadInvoiceButton = ({ invoice }: { invoice: InvoiceModelType | Invoice }) =>
  invoice.invoice_pdf ? (
    <a
      href={invoice.invoice_pdf}
      download={true}
      target="_blank"
      rel="noreferrer noopener"
    >
      <CardBlock>
        <PaddingBlock>
          <HorizontalStackBlock justified={true}>
            <Paragraph>
              <FormattedMessage id="Order download invoice" />
            </Paragraph>
            <IconComponent icon="DOWNLOAD" fill="BLACK" size={1.5} />
          </HorizontalStackBlock>
        </PaddingBlock>
      </CardBlock>
    </a>
  ) : null;

export default DownloadInvoiceButton;
