import { inject, observer } from 'mobx-react';
import React from 'react';

import { ApplicationStoreType } from 'models/ApplicationStore';
import { pushDashboardViewed } from 'utils/analytics';
import ROLES from 'utils/constants/roles';
import { HistoryProps } from 'utils/history';
import StudentContentContainer from './StudentContentContainer';
import OrganizerContentContainer from './OrganizerContentContainer';

interface ContentDashboardScreenProps {
  applicationStore: ApplicationStoreType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class ContentDashboardScreen extends React.Component<
  ContentDashboardScreenProps & HistoryProps
> {
  componentDidMount() {
    const { applicationStore } = this.props;

    if (applicationStore.currentUser) {
      pushDashboardViewed(applicationStore.currentUser?.role || ROLES.STUDENT);
    }
  }

  render() {
    const { history } = this.props;

    return this.props.applicationStore.isOrganizer ? (
      // @ts-ignore
      <OrganizerContentContainer history={history} />
    ) : (
      // @ts-ignore
      <StudentContentContainer history={history} />
    );
  }
}

export default ContentDashboardScreen;
