import React from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ImageBlock from 'blocks/ImageBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import TeaserCard from 'blocks/TeaserCard';
import TopActionBlock from 'blocks/TopActionBlock';
import WideBlock from 'blocks/WideBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import config from 'config';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import UppercaseHeading from 'elements/UppercaseHeading';
import {
  inject,
  observer
} from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChapterModelType } from 'models/ChapterModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTE_LAYOUT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface LayoutScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
}

@inject('applicationStore', 'chaptersStore')
@observer
class LayoutScreen extends React.Component<LayoutScreenProps & HistoryProps> {
  componentDidMount() {
    if (this.props.applicationStore.onboardFeature('design')) {
      return;
    }

    this.loadData();
  }

  private loadData() {
    this.props.chaptersStore.getCoverChapter();
  }

  private renderPage(content?: any) {
    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Design" />} text={<FormattedMessage id="layout overview info" />} />
          
          {content}
        </PageStackBlock>

        <HelpSubject subject="layout_editor" />
      </>
    );
  }

  private renderLayout(chapter: ChapterModelType) {
    const { layout } = chapter;

    if (!chapter.layout_key || !layout) {
      return (
        <WideBlock>
          <TeaserCard>
            <TeaserCard.CardInfo>
              <Headline.Medium color="WHITE">
                <FormattedMessage id="discover designs header" />
              </Headline.Medium>

              <Paragraph size="L"  color="WHITE">
                <FormattedMessage id="discover designs" />
              </Paragraph>

              <ButtonBlock
                background="PRIMARY"
                color="WHITE"
                onClick={() => this.props.history.push(ROUTE_LAYOUT + '/theme')}
              >
                <FormattedMessage id="discover designs button" />
              </ButtonBlock>
            </TeaserCard.CardInfo>
          </TeaserCard>
        </WideBlock>
      );
    }

    return (
      <WideBlock padded={true}>
        <ItemStackBlock gap="S">
          {layout.previewLarge ? (
            <>
              <UppercaseHeading>
                <FormattedMessage id="Design preview" />
              </UppercaseHeading>
              <img
                src={config.layout.baseUrl + '/' + layout.previewLarge}
                alt="Layout"
              />
            </>
          ) : (
            <Paragraph>{layout.name}</Paragraph>
          )}

          <LinkBlock
            inline={true}
            slim={true}
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            to={ROUTE_LAYOUT + '/theme'}
          >
            <IconComponent icon="PEN" />
            <FormattedMessage id="Change design" />
          </LinkBlock>
        </ItemStackBlock>
      </WideBlock>
    );
  }

  // private renderCustom() {
  //   const { book } = this.props.applicationStore;

  //   if (!book) {
  //     return null;
  //   }

  //   return (
  //     <>
  //       <Headline.Medium>
  //         <FormattedMessage id="Custom design" />
  //       </Headline.Medium>

  //       {book.design_ticket_state === 'no_ticket' ? (
  //         <>
  //           <Paragraph>
  //             <FormattedMessage id="custom design request" />
  //           </Paragraph>

  //           <ButtonBlock
  //             background="PRIMARY"
  //             color="WHITE"
  //             onClick={() => this.props.history.push(ROUTE_LAYOUT + '/request')}
  //           >
  //             <FormattedMessage id="Start now" />
  //           </ButtonBlock>
  //         </>
  //       ) : book.design_ticket_state === 'finished' ? (
  //         <>
  //           <Paragraph>
  //             <FormattedMessage id="custom design finished info" />
  //           </Paragraph>

  //           <Paragraph>
  //             <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
  //             <FormattedMessage id="custom design finished" />
  //           </Paragraph>
  //         </>
  //       ) : (
  //         <>
  //           <Paragraph>
  //             <FormattedMessage id="custom design progress info" />
  //           </Paragraph>

  //           <HorizontalStackBlock gap="S" centered={true}>
  //             <IconComponent icon="HOURGLASS" fill="PRIMARY" size={2} />
  //             <Paragraph>
  //               <FormattedMessage
  //                 id="custom design progress"
  //                 values={{
  //                   name: book.design_ticket_author?.fullName || 'N/A'
  //                 }}
  //               />
  //             </Paragraph>
  //           </HorizontalStackBlock>
  //         </>
  //       )}
  //     </>
  //   );
  // }

  private renderOverview() {
    const { item: chapter } = this.props.chaptersStore;

    if (!chapter) {
      return this.renderLoading();
    }

    const hasLayout = chapter.layout_key && !!chapter.layout;
    const designPreview = chapter?.layout?.cardPreview;

    return this.renderPage(
      <>
        {this.renderLayout(chapter)}

        <ListStackBlock>
          <Link to={ROUTE_LAYOUT + '/editor'}>
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="Layout editor" />
                  </EveryCardHeadline>
                  <TextElement>
                    <FormattedMessage id="design layout editor info" />
                  </TextElement>
                </EveryCardBody>
              </EveryCardPadding>
            </EveryCard>
          </Link>

          {hasLayout && (
            <Link to={ROUTE_LAYOUT + '/cover'}>
              <EveryCard>
                <EveryCardPadding>
                  {designPreview && (
                    <ImageBlock width={2.125} height={3.0625}>
                      <img
                        src={config.layout.baseUrl + '/' + designPreview}
                        alt=""
                      />
                    </ImageBlock>
                  )}

                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="Customize cover" />
                    </EveryCardHeadline>
                    <TextElement>
                      <FormattedMessage id="customize cover info" />
                    </TextElement>
                  </EveryCardBody>
                </EveryCardPadding>
              </EveryCard>
            </Link>
          )}
        </ListStackBlock>

        {/* <Divider />

        {this.renderCustom()} */}
      </>
    );
  }

  private renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadData()} />
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const {
      chaptersStore: { isItemLoading, isItemLoadError }
    } = this.props;

    if (isItemLoading) {
      return this.renderLoading();
    }

    if (isItemLoadError) {
      return this.renderError();
    }

    return this.renderOverview();
  }
}

export default LayoutScreen;
