import { inject, observer } from 'mobx-react';
import React, { JSXElementConstructor, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import ItemStackBlock from 'blocks/ItemStackBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import UppercaseHeading from 'elements/UppercaseHeading';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';

import AddGroupButtonComponent from './AddGroupButtonComponent';
import GroupsListItem, { GroupsListItemProps } from './GroupsListItem';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

export type GroupsListItemComponentType = JSXElementConstructor<
  GroupsListItemProps
>;
export type NoGroupsComponentType = JSXElementConstructor<{}> | null;

interface PublicGroupsListProps {
  allowAdd?: boolean;
  indicateUpdating?: boolean;
  itemComponent?: GroupsListItemComponentType;
  noGroupsComponent?: NoGroupsComponentType;
  selected?: number | number[];
  ignoreGroupIds?: number[];
  showGroupsCount?: boolean;
  onGroupClick?: (e: MouseEvent, groupId: number, name?: string) => void;
}

interface GroupsListProps extends PublicGroupsListProps {
  profilesStore: ProfilesStoreType;
  applicationStore: ApplicationStoreType;
}

@inject('profilesStore', 'applicationStore')
@observer
class GroupsList extends React.Component<GroupsListProps> {
  componentDidMount() {
    // load/update groups every time we display the list
    this.loadGroups();
  }

  loadGroups() {
    this.props.profilesStore.getGroups();
  }

  renderError() {
    return <GenericErrorComponent onRetryClick={() => this.loadGroups()} />;
  }

  renderLoading() {
    return <LoadingOverlayComponent />;
  }

  renderListContainer(list: any) {
    if (!this.props.allowAdd) {
      return list;
    }

    return <ItemStackBlock gap="L">{list}</ItemStackBlock>;
  }

  renderList() {
    const {
      profilesStore,
      applicationStore,
      onGroupClick,
      itemComponent,
      ignoreGroupIds,
      allowAdd,
      showGroupsCount,
      selected,
      noGroupsComponent
    } = this.props;

    const { isOrganizer } = applicationStore;

    const selectedIds = !selected
      ? []
      : Array.isArray(selected)
        ? selected
        : [selected];

    let groups = profilesStore.sortedGroups;

    if (ignoreGroupIds?.length) {
      groups = groups.filter((group) => ignoreGroupIds.indexOf(group.id) < 0);
    }

    if (!groups.length) {
      if (noGroupsComponent) {
        const NoGroups = noGroupsComponent;
        return this.renderListContainer(
          <>
            <NoGroups />

            {allowAdd && isOrganizer && <AddGroupButtonComponent />}
          </>
        );
      }

      return this.renderListContainer(
        <Paragraph>
          <FormattedMessage id="no groups" />
        </Paragraph>
      );
    }

    const Item = !itemComponent ? GroupsListItem : itemComponent;

    return this.renderListContainer(
      <>
        <ItemStackBlock gap="XXS">
          {showGroupsCount && (
            <UppercaseHeading>
              <FormattedMessage
                id="Groups count"
                values={{
                  count: groups.length
                }}
              />
            </UppercaseHeading>
          )}

          <ListStackBlock>
            {groups.map((group) => (
              <Item
                key={group.id}
                group={group}
                selected={selectedIds.includes(group.id)}
                onClick={onGroupClick}
                groupStudentsCount={group.student_count}
              />
            ))}
          </ListStackBlock>
        </ItemStackBlock>

        {allowAdd && isOrganizer && <AddGroupButtonComponent />}
      </>
    );
  }

  render() {
    const { profilesStore, indicateUpdating } = this.props;

    if (
      profilesStore.groupsLoadingState === 'loading' ||
      profilesStore.isGroupItemLoading ||
      (indicateUpdating && profilesStore.groupsLoadingState === 'updating')
    ) {
      return this.renderLoading();
    }

    if (profilesStore.groupsLoadingState === 'error') {
      return this.renderError();
    }

    return this.renderList();
  }
}

export default (props: PublicGroupsListProps) => {
  // @ts-ignore
  return <GroupsList {...props} />;
};
