import React from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { LogoutStoreType } from 'models/LogoutStore';
import ButtonBlock from 'blocks/ButtonBlock';

export const LogoutButton = inject('logoutStore')(
  observer(({ logoutStore }: { logoutStore?: LogoutStoreType }) => (
    <ButtonBlock
      inline={true}
      slim={true}
      background="WHITE"
      color="GRAY400"
      borderColor="GRAY800"
      onClick={() => logoutStore!.logout()}
    >
      <FormattedMessage id="Logout(verb)" />
    </ButtonBlock>
  ))
);

export default LogoutButton;
