import createEmojiRegex from 'emoji-regex';

// The emoji regex needs to be put in parentheses so `String.split()` will keep the
// delimiters (which are the actual emojis) as items in the returned array of fragments
const emojiRegex = new RegExp(`(${createEmojiRegex().source})`);

export const transformEmojis = (input?: string): string | undefined => {
  if (typeof input !== 'string') {
    return undefined;
  }

  const split = input.split(emojiRegex);
  return split
    .map((fragment, index) => {
      // Array from `split()` is formatted like `[text, emoji, text, emoji, text, ...]`, it always
      // starts (and ends) with a text fragment even if an emoji is first (or last) character
      if (index % 2 === 0) {
        return fragment;
      }
      return `<span class="emoji">${fragment}</span>`;
    })
    .join('');
};

export const sanitizeHtml = (
  input?: string,
  keepEmojis: boolean = false,
  replaceQuotes: boolean = true
): string | undefined => {
  if (input === '') {
    return '';
  }
  if (!input) {
    return undefined;
  }

  let sanitized = input
    .toString()
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/{/g, '&#123;');

  if (replaceQuotes) {
    sanitized = sanitized.replace(/"/g, '&quot;');
  }

  if (keepEmojis) {
    return sanitized;
  }

  // return transformEmojis(sanitized);
  return sanitized;
};

export default sanitizeHtml;
