import React, { useEffect } from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import Headline from 'elements/Headline';
import { FormattedMessage } from 'react-intl';
import { FormType } from 'utils/hooks/useForm';

interface AgeScreenProps {
  form: FormType;
  updateAge: () => void;
}

const AgeScreen = ({ form, updateAge }: AgeScreenProps) => {
  const minor = form.bindRadioButton('minor', 'true');
  const ofAge = form.bindRadioButton('minor', 'false');

  if (form.values.minor === true) {
    form.values.minor = 'true';
  }
  if (form.values.minor === false) {
    form.values.minor = 'false';
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderAgeFormInfo = () => {
    if (form.values.minor === true || form.values.minor === 'true') {
      return (
        <>
          <Divider />
          <ItemStackBlock gap="XXS">
            <Headline.Medium>
              <FormattedMessage id="Checkout age parental consent" />
            </Headline.Medium>
            <Paragraph size="L" color="TEXT_LIGHT">
              <FormattedMessage id="Checkout age parental consent info" />
            </Paragraph>
          </ItemStackBlock>

          <a
            href="/documents/Einverständniserklärung-nuggit.pdf"
            download="Einverständniserklärung-nuggit.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="Download form" />
                  </EveryCardHeadline>
                  <TextElement size="M">
                    <FormattedMessage id="PDF from size" />
                  </TextElement>
                </EveryCardBody>
                <IconComponent icon="DOWNLOAD" fill="BLACK" size={1.5} />
              </EveryCardPadding>
            </EveryCard>
          </a>
        </>
      );
    }
  };

  return (
    <>
      <PageHeader
        headline={<FormattedMessage id="Checkout age header" />}
        text={<FormattedMessage id="Checkout age info" />}
      />

      <FormStackBlock>
        <RadioButtonComponent
          name="minor"
          value="false"
          label={
            <>
              <p>
                <FormattedMessage id="Checkout of age" />
              </p>
            </>
          }
          {...ofAge}
        />
        <RadioButtonComponent
          name="minor"
          value="true"
          label={
            <>
              <p>
                <FormattedMessage id="Checkout minor" />
              </p>
            </>
          }
          {...minor}
        />
      </FormStackBlock>

      {renderAgeFormInfo()}

      <ButtonBlock
        background="PRIMARY"
        onClick={() => {
          updateAge();
        }}
      >
        <FormattedMessage id="Next" />
      </ButtonBlock>
    </>
  );
};
export default AgeScreen;
