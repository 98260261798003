import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBox from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import LockedLabel from 'domain/Label/LockedLabel';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_QUOTES } from 'utils/constants/routes';
import { HistoryProps, idFromMatch, isPush } from 'utils/history';
import chapterIdFromMatch, {
  backRouteFromMatch
} from 'utils/history/chapter-id-from-match';
import { FormType, handleFormError, useForm } from 'utils/hooks/useForm';

import { QuotesStoreType } from '../QuotesStore';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';

interface QuoteDetailProps {
  quotesStore: QuotesStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('quotesStore', 'applicationStore')
@observer
class QuoteDetail extends Component<QuoteDetailProps & HistoryProps> {
  async loadQuote(id: number) {
    const { quotesStore, form } = this.props;

    if (quotesStore.isItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const checkIfAlreadyInStore = !isPush(this.props.history);
    if (
      checkIfAlreadyInStore &&
      quotesStore.item &&
      quotesStore.item.id === id
    ) {
      form.setField('quote', quotesStore.item.quote || '');
      return;
    }

    const quote = await quotesStore.getQuote(id);

    if (quote) {
      form.setField('quote', quote.quote || '');
    }

    return quote;
  }

  async componentDidMount() {
    const id = idFromMatch(this.props.match);
    if (id) {
      await this.loadQuote(id);
    } else {
      // new quote mode
      this.props.quotesStore.clearCurrentItem();
      this.props.form.reset();
    }
  }

  async componentDidUpdate(prevProps: any) {
    const newId = idFromMatch(this.props.match);
    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new quote mode
          this.props.quotesStore.clearCurrentItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        await this.loadQuote(newId);
      }
    }
  }

  navigateToList() {
    this.props.history.replace(
      backRouteFromMatch(ROUTE_QUOTES, this.props.match)
    );
  }

  async handleSubmit() {
    const { form, quotesStore, applicationStore } = this.props;
    const { quote } = form.values;

    form.resetErrors();

    const patch: any = {
      quote: !quote ? '' : quote.trim()
    };

    if (this.isAddMode()) {
      patch.chapter_id = chapterIdFromMatch(this.props.match);
      patch.print = true;
    }

    quotesStore.patchItem(patch);

    try {
      await quotesStore.storeItem();
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by QuotesStore internally
      return;
    }

    applicationStore.setDefaultFlashMessage('saved');
    this.navigateToList();
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  async performDelete() {
    const { quotesStore } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return;
    }

    this.finishDelete();

    try {
      await quotesStore.removeQuote(id);
    } catch (error: any) {
      // delete errors are handled by QuotesStore internally
      return;
    }

    this.navigateToList();
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  isAddMode() {
    return !idFromMatch(this.props.match) ? true : false;
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  renderReadOnly() {
    const { quotesStore } = this.props;

    return this.renderPage(
      <>
        <LockedLabel id="Quotes" />

        <Paragraph>{quotesStore.item && quotesStore.item.quote}</Paragraph>
      </>
    );
  }

  renderEditForm() {
    const { quotesStore, applicationStore, form } = this.props;
    const isAddMode = this.isAddMode();

    let extra;
    if (form.confirm) {
      extra = (
        <ConfirmBox
          header={<FormattedMessage id="confirm delete header" />}
          text={
            <FormattedMessage
              id="confirm delete item"
              values={{
                item: <FormattedMessage id="This quote" />
              }}
            />
          }
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => this.performDelete()}
          onAbort={() => this.finishDelete()}
          confirmColor="RED"
        />
      );
    } else if (quotesStore.itemLoadingState === 'update_error') {
      extra = <GenericErrorComponent />;
    }

    return this.renderPage(
      <>
        {extra}

        {!isAddMode &&
          applicationStore.isOrganizer &&
          quotesStore.item &&
          quotesStore.item.author && (
            <TextElement>
              <IconComponent icon="USER" />{' '}
              <FormattedMessage
                id="Added by {name}"
                values={{
                  name: quotesStore.item.author.name
                }}
              />
            </TextElement>
          )}

        <TextareaComponent
          name="quote"
          label={intl.formatMessage({ id: 'Enter text' })}
          height={20}
          {...this.props.form.bindInput('quote')}
          autoFocus={true}
        />


        <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
          <FormattedMessage id={isAddMode ? 'Add' : 'Save'} />
        </ButtonBlock>

        {!isAddMode && (
          <>
            <Divider />
            <DeleteButton onClick={() => this.confirmDelete()} />
          </>
        )}
      </>
    );
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={backRouteFromMatch(ROUTE_QUOTES, this.props.match)} />
        </TopActionBlock>
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage
              id={this.isAddMode() ? 'Add quote' : 'Edit quote'}
            />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { quotesStore, applicationStore } = this.props;

    if (quotesStore.isItemLoading) {
      return this.renderLoading();
    }

    if (quotesStore.itemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (
      !this.isAddMode() &&
      !applicationStore.isChapterEditAllowed(quotesStore.item?.chapter)
    ) {
      return this.renderReadOnly();
    }

    return this.renderEditForm();
  }
}

export default (props: any) => {
  const form = useForm();
  return <QuoteDetail {...props} form={form} />;
};
