import { Instance, types } from 'mobx-state-tree';

import convertLayoutTags from './convert-layout-tags';

export const FontSpecModel = types
  .model('FontSpecModel', {
    name: types.string,
    weights: types.array(types.number)
  })
  .views((self) => {
    return {
      get spec(): [string, number[]] {
        return [self.name, self.weights.map((elem) => elem)];
      }
    };
  });

export type FontSpecModelType = Instance<typeof FontSpecModel>;

export const createFontSpec = (data: any): FontSpecModelType | undefined => {
  if (
    !Array.isArray(data) ||
    data.length < 2 ||
    typeof data[0] !== 'string' ||
    !Array.isArray(data[1]) ||
    !data[1].length
  ) {
    // this is not a valid font spec ([string, number[]])
    return undefined;
  }

  return FontSpecModel.create({
    name: data[0],
    weights: data[1].map((elem: any) => parseInt(elem, 10))
  });
};

export const convertFontSpecs = (fonts?: any): FontSpecModelType[] => {
  if (!Array.isArray(fonts)) {
    return [];
  }

  return fonts
    .map((elem: any) => createFontSpec(elem))
    .filter((elem: any) => !!elem) as FontSpecModelType[];
};

const FontModel = types
  .model('FontModel', {
    id: types.identifier,
    name: types.maybe(types.string),
    tags: types.array(types.string),
    fonts: types.array(FontSpecModel),
    pageHeaderStyle: types.maybe(types.string),
    sectionHeaderStyle: types.maybe(types.string),
    previewStyle: types.maybe(types.string),
    preview: types.maybe(types.string),
    sizeFactor: types.maybe(types.number)
  })
  .views((self) => {
    return {
      get specs(): ([string, number[]])[] {
        const specs: ([string, number[]])[] = [];

        for (const font of self.fonts) {
          specs.push(font.spec);
        }

        return specs;
      }
    };
  });

export const createFontModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  const id = !data.id ? data.key : data.id.toString();

  if (!id) {
    return undefined;
  }

  return FontModel.create({
    id,
    name: data.name?.de || data.name || undefined,
    tags: convertLayoutTags(data.tags),
    fonts: convertFontSpecs(data.fonts),
    pageHeaderStyle: data.pageHeaderStyle || undefined,
    sectionHeaderStyle: data.sectionHeaderStyle || undefined,
    previewStyle: data.previewStyle || undefined,
    preview: data.preview || undefined,
    sizeFactor: data.sizeFactor
  });
};

export type FontModelType = Instance<typeof FontModel>;
export default FontModel;
