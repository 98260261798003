import styled from 'styled-components';

export default styled.div`
  display: block;
  text-align: center;

  > img {
    max-width: 70%;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
  }
`;
