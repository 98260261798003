import ItemStackBlock from 'blocks/ItemStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import Headline from 'elements/Headline';
import React, { ReactNode } from 'react';

const PageHeader = ({ headline, text }: { headline: ReactNode, text: ReactNode }) => (
  <ItemStackBlock gap="XXS">
    <Headline.Large>
      {headline}
    </Headline.Large>

    <Paragraph size="L" color="TEXT_LIGHT" >
      {text}
    </Paragraph>
  </ItemStackBlock>
);

export default PageHeader;
