import React from 'react';

interface NotAvailableComponentProps {
  print?: boolean;
  fonts?: boolean;
  loading?: boolean;
}

export default ({ print, fonts }: NotAvailableComponentProps) => {
  if (!print) {
    // TODO display placeholder/loading spinner
  }

  if (fonts) {
    return <>nuggit generator: fonts not available</>;
  }

  return <>nuggit generator: content not available</>;
};
