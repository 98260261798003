import React from 'react';

import { Textile } from 'api/textile_deals/fetchTextiles';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import TextElement from 'components/TextElement/TextElement';
import Headline from 'elements/Headline';
import InputMessage from 'elements/InputMessage';
import NakedLink from 'elements/NakedLink';
import { FormattedMessage } from 'react-intl';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import { FormType } from 'utils/hooks/useForm';
import { snakeCase } from 'utils/snakeCase';

interface CalculatorFormProps {
  form: FormType;
  changeInput: (value: string, field: string) => void;
  errors?: string[];
  textiles: Textile[];
  match: any;
}

const TextileCalculatorForm = ({
  form,
  changeInput,
  textiles,
  errors,
  match
}: CalculatorFormProps) => {
  let totalAmount = 0;

  totalAmount = Object.values(form.values).reduce(
    (a: number, b: string) => a + parseInt(b === '' ? '0' : b),
    0
  );

  const errorMessages = errors;

  return (
    <>
      {textiles.map((textile) => {
        return (
          <ItemStackBlock key={textile.id} gap="S">
            <Headline.Small>
              <HorizontalStackBlock
                gap="XXS"
                noMarginLeft={true}
                centered={true}
              >
                {textile.name}
                <NakedLink
                  to={textileOrderRoute(
                    match,
                    '/calculator/textile/' + textile.id
                  )}
                >
                  <IconComponent icon="INFO_CIRCLE" size={0.8} />
                </NakedLink>
              </HorizontalStackBlock>
            </Headline.Small>

            <TextInputComponent
              name={'amount_' + snakeCase(textile.name)}
              type="number"
              label={<FormattedMessage id="textile count" />}
              onChange={(e: any) =>
                changeInput(e.target.value, 'amount_' + snakeCase(textile.name))
              }
              value={form.values['amount_' + snakeCase(textile.name)]}
              min="0"
            />
          </ItemStackBlock>
        );
      })}

      <EveryCard disabled={true}>
        <EveryCardPadding>
          <EveryCardHeadline>
            <FormattedMessage id="Total amount" />
          </EveryCardHeadline>
          <EveryCardBody right={true}>
            <TextElement color="TEXT_DARK" size="L" weight="BOLD">
              {totalAmount}
            </TextElement>
          </EveryCardBody>
        </EveryCardPadding>
      </EveryCard>

      {errorMessages && (
        <InputMessage error={true}>{[...errorMessages!]}</InputMessage>
      )}

      <Divider />
    </>
  );
};

export default TextileCalculatorForm;
