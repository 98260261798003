import {
  Instance,
  types
} from 'mobx-state-tree';

import convertLayoutTags from './convert-layout-tags';
import {
  convertFontSpecs,
  FontSpecModel
} from './FontModel';

const LayoutModel = types
  .model('LayoutModel', {
    id: types.identifier,
    name: types.maybe(types.string),
    tags: types.array(types.string),
    preview: types.maybe(types.string),
    previewLarge: types.maybe(types.string),
    cardPreview: types.maybe(types.string),
    contentPreview: types.maybe(types.array(types.string)),
    fonts: types.array(FontSpecModel),
    layout: types.maybe(types.string)
  })
  .views((self) => {
    return {
      get fontSpecs(): [string, number[]][] {
        const specs: [string, number[]][] = [];

        for (const font of self.fonts) {
          specs.push(font.spec);
        }

        return specs;
      },

      get layoutDefinition(): any {
        return JSON.parse(self.layout || 'null');
      }
    };
  });

export const createLayoutModel = (data?: any, layoutKey?: string) => {
  if (!data) {
    return undefined;
  }

  const key = data.key || data.id || layoutKey;
  if (!key) {
    return undefined;
  }

  return LayoutModel.create({
    id: key,
    name: data.name?.de || data.name || undefined,
    tags: convertLayoutTags(data.tags),
    preview: data.preview || undefined,
    previewLarge: data.previewLarge || undefined,
    cardPreview: data.cardPreview || undefined,
    contentPreview: !data.contentPreview
      ? undefined
      : convertLayoutTags(data.contentPreview),
    fonts: convertFontSpecs(data.fonts),
    layout: JSON.stringify(data) || undefined // TODO find better way to store entire layout config
  });
};

export type LayoutModelType = Instance<typeof LayoutModel>;
export default LayoutModel;
