import React from 'react';
import TextileProductImageComponent from 'screens/preorders/components/TextileProductImageComponent';
import { ProductImage } from 'utils/product-image';

import TextileMockUpComponent from '../structure/TextileMockUpComponent';
import WaitForImagesTextiles from './WaitForImagesTextiles';

export interface TextileFrontTemplateProps {
  productImage?: ProductImage;
}

export class TextileMockUpTemplateComponent extends React.Component<
  TextileFrontTemplateProps
> {
  render() {
    const { productImage } = this.props;

    if (!productImage) {
      return null;
    }

    return (
      <>
        <TextileMockUpComponent>
          <TextileProductImageComponent
            productImage={productImage}
            width="975px"
            height="1248px"
          />
        </TextileMockUpComponent>
        {<WaitForImagesTextiles />}
      </>
    );
  }
}

export default TextileMockUpTemplateComponent;
