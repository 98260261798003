import React from 'react';

const INITIAL_TIMEOUT = 50;
const POLLING_FREQUENCY = 5;

/**
 * Waits for all images to be loaded and notifies Electron.
 * See:
 * https://github.com/fraserxu/electron-pdf#to-generate-a-pdf-after-the-an-async-task-in-the-html
 */
class WaitForImages extends React.Component {
  private handle?: number;

  componentDidMount() {
    // Start polling after a certain time
    this.handle = window.setTimeout(this.checkImages, INITIAL_TIMEOUT);

    // Listen for event from Electron PDF
    document.body.addEventListener(
      'view-ready-acknowledged',
      this.viewReadyAcknowledged
    );
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  /**
   * Checks periodically if all images in the document are loaded successful.
   * If so, notify electron.
   */
  checkImages = () => {
    const images = document.getElementsByTagName('img');
    const loaded = Array.from(images).every((image) => image.complete);
    if (loaded) {
      this.notifyElectron();
    } else {
      this.handle = window.setTimeout(this.checkImages, POLLING_FREQUENCY);
    }
  };

  /**
   * Periodically notifies Electron PDF
   */
  notifyElectron() {
    this.handle = window.requestAnimationFrame(() => {
      document.body.dispatchEvent(new Event('view-ready'));
      this.notifyElectron();
    });
  }

  /**
   * Handler for Electron PDF’s `view-ready-acknowledged` event.
   */
  viewReadyAcknowledged = () => {
    this.clearTimeouts();
  };

  clearTimeouts() {
    if (this.handle) {
      window.clearTimeout(this.handle);
      window.cancelAnimationFrame(this.handle);
    }
  }

  render() {
    return null;
  }
}

export default WaitForImages;
