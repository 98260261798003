import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';

import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { AccountStoreType } from 'models/AccountStore';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { Link } from 'react-router-dom';
import { ROUTE_ACCOUNT_DASHBOARD, ROUTE_ACCOUNT_MOBILE_NUMBER } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface MyNumberScreenProps {
  applicationStore: ApplicationStoreType;
  accountStore: AccountStoreType;
}

@inject('applicationStore', 'accountStore')
@observer
class MyNumberConfirmScreen extends React.Component<
MyNumberScreenProps & HistoryProps
> {
  async componentDidMount() {
    const { applicationStore } = this.props;
    let { currentAccount } = applicationStore;

    if (!currentAccount || !applicationStore.isRefreshing) {
      // just to be sure
      await applicationStore.refreshSession();
      currentAccount = applicationStore.currentAccount;
    }
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_ACCOUNT_DASHBOARD} />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  renderView() {
    const { applicationStore } = this.props;
    const { currentAccount } = applicationStore;

    if (!currentAccount) {
      // satisfy TS
      return this.renderPage(null);
    }

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="signup check number header" />} text={<FormattedMessage id="signup number text" />} />

        {currentAccount.mobile_number_to_confirm && (
          <Headline.Medium>{currentAccount.mobile_number_to_confirm}</Headline.Medium>
        )}

        <ItemStackBlock gap="S">
          <ButtonBlock
            background="PRIMARY"
            onClick={() => {
              applicationStore.setFlashMessage(
                intl.formatMessage({ id: 'phone number updated' })
              );
              this.props.history.push(ROUTE_ACCOUNT_DASHBOARD);
            }}
          >
            <FormattedMessage id="OK" />
          </ButtonBlock>

          <Link to={ROUTE_ACCOUNT_MOBILE_NUMBER}>
            <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK">
              <FormattedMessage id="signup change number" />
            </ButtonBlock>
          </Link>
        </ItemStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  render() {
    const { applicationStore, accountStore } = this.props;

    if (applicationStore.isRefreshing || accountStore.loadingState) {
      return this.renderLoading();
    }

    if (accountStore.loadingState === 'error') {
      return this.renderError();
    }

    return this.renderView();
  }
}

export default (props: any) => {
  return <MyNumberConfirmScreen {...props} />;
};
