import React, { ReactNode } from 'react';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import IconComponent from 'components/IconComponent';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface ItemProps {
  withBorder?: boolean
}


/**
 * List of text, links or buttons with a thin divider line.
 */
export const ButtonListBlock = styled.ul``;

export const ButtonListBlockItem = styled.li<ItemProps>`
  border-bottom: 1px solid ${COLORS.GRAY900};

  &:last-child {
    ${(props) => props.withBorder ? `border-bottom: 1px solid ${COLORS.GRAY900};`: `border-bottom: 0`};
  }
`;

export const ButtonListBlockItemPadding = styled.div`
  padding: 1rem 0 1.0625rem;
`;

/**
 * Resets the text styles.
 */
export const ButtonListBlockText = styled.span`
  font-weight: 500;
  color: ${COLORS.BLACK};
`;

/**
 * List item body.
 * Adds padding and an optional arrow.
 */
export const ButtonListBlockBody = ({
  arrow = true,
  children
}: {
  arrow?: boolean;
  children: ReactNode;
}) => (
  <ButtonListBlockItemPadding>
    <HorizontalStackBlock gap="M" justified={true} centered={true}>
      <ButtonListBlockText>{children}</ButtonListBlockText>
      {/* This wrapper div is necessary so the flex layout
      does not shrink the icon when the children are wide. */}
      {arrow && (
        <div>
          <IconComponent icon="ARROW_RIGHT" fill="BLACK" />
        </div>
      )}
    </HorizontalStackBlock>
  </ButtonListBlockItemPadding>
);
