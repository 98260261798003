import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import SponsorChapter from 'screens/sponsor_pages/SponsorChapter';
import {
  ROUTE_SPONSOR_CHAPTER,
  ROUTE_SPONSOR_DETAILS,
  ROUTE_SPONSOR_PLACEHOLDER_PAGE,
  ROUTE_SPONSOR_SETTINGS
} from 'utils/constants/routes';

import SponsorPlaceholderPage from 'screens/sponsor_pages/SponsorPlaceholderPage';
import SponsorDetail from './screens/SponsorDetail/SponsorDetail';
import SponsorsSettings from './SponsorsSettings';

export default () => (
  <Switch>
    <Route path={ROUTE_SPONSOR_SETTINGS} component={SponsorsSettings} />
    <Route
      path={ROUTE_SPONSOR_CHAPTER}
      exact={true}
      component={SponsorChapter}
    />
    <Route path={ROUTE_SPONSOR_DETAILS + '/new'} component={SponsorDetail} />
    <Route path={ROUTE_SPONSOR_DETAILS + '/:id'} component={SponsorDetail} />

    <Route path={ROUTE_SPONSOR_PLACEHOLDER_PAGE} component={SponsorPlaceholderPage} />

    <NotFoundRoute />
  </Switch>
);
