import { destroy, post, uri } from "api/BaseApi";
import { BaseTextileProfile } from "./fetchTextileProfiles";

export interface Preorder {
  id: number;
  textile_order_id: number;
  textile_profile_id?: number;
  textile_variant_id: number;
  author?: BaseTextileProfile;
  size?: string;
  name?: string;
  textile_name?: string
}

export const createTextilePreorder = (patch: any, textileOrderId: number): Promise<Preorder> => {
  return post('/textile_preorders', {
    textile_preorder: patch,
    textile_order_id: textileOrderId
  });
}

export const removeTextilePreorder = (id: number, textileOrderId: number) => {
  return destroy(
    '/textile_preorders/' + uri(id) + '?textile_order_id=' + textileOrderId
  );
}