import { useQuery } from "@tanstack/react-query"
import { getTextileVariantsForTextileOrder } from "api/textile_deals/fetchTextileVariants"

export const useTextileVariantsQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_variants', textileOrderId],
    queryFn: () => getTextileVariantsForTextileOrder(textileOrderId!),
    enabled: !!textileOrderId,
    staleTime: 600000, // we dont want to fetch this every time, this data can be stale for a while in the normal app context
  })
}