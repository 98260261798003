import { get, patch, uri } from 'api/BaseApi';
import { TextileRoleChangeRequestType } from './fetchTextileRoleChangeRequests';
import { TextileThemeLayout } from './fetchTextileThemes';

export type OrderStateTextileType =
  | 'onboarding'
  | 'preparing'
  | 'preorder'
  | 'preorder_paused'
  | 'order_started'
  | 'order_placed'
  | 'order_confirmed'
  | 'order_printing'
  | 'order_shipped'
  | 'done'
  | 'lock_detected';

export type DesignTicketState = 'no_ticket' | 'requested' | 'in_progress' | 'finished'

export interface TextileOrderAppState {
  id: number;
  order_state: OrderStateTextileType;
  has_active_design_ticket: boolean;
  has_finished_design_ticket: boolean;
  all_colors_set: boolean;
  checkout_present: boolean;
  invitation_token: string;
  invitation_url: string;
  print_data_edit_open: boolean;
  preorder_open: boolean;
  design_ticket_state: DesignTicketState;
  design_ticket_author: TextileProfileAuthor;
  theme?: TextileThemeLayout;
  layout_key?: string;
  show_groups?: boolean
}

export interface TextileProfileAuthor {
  id: number;
  first_name?: string;
  last_name?: string;
  name?: string;
}

export interface TextileProfileAppState {
  id: number;
  is_organizer: boolean;
  is_student: boolean;
  is_applicant: boolean;
  is_manager: boolean;
  is_checkout_author: boolean;
  join_role_selected: boolean
}

export interface TextileAppState {
  textile_order: TextileOrderAppState;
  textile_profile: TextileProfileAppState
}

export const getTextileAppStateById = (
  id: number
): Promise<TextileAppState> => {
  return get(
    '/textile_orders/' + uri(id) + '/textile_app_state'
  );
};

export const completeOnboardingTextileOrder = (textileOrderId: number): Promise<TextileAppState> => {
  return patch('/textile_onboarding/complete_onboarding', {
    textile_order_id: textileOrderId
  });
}

export const joinTextileOrder = (textileOrderId: number, request_type?: TextileRoleChangeRequestType): Promise<TextileAppState> => {
  return patch('/textile_onboarding/join_textile_order', {
    textile_order_id: textileOrderId,
    role_change_request_type: request_type
  });
}
