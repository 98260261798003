import { inject, observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import SliderBlock from 'blocks/SliderBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import WideBlock from 'blocks/WideBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import ProfilePreviewComponent from 'components/print/layout-editor/ProfilePreviewComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import { ProfileTemplateQuestion } from 'components/print/templates/ProfileTemplateComponent';
import ChapterDescriptionComponent from 'domain/ChapterDescriptionComponent';
import BackLink from 'domain/Links/BackLink';
import CancelLink from 'domain/Links/CancelLink';
import PhotoEditScreen from 'domain/PhotoEditScreen/PhotoEditScreen';
import Headline from 'elements/Headline';
import UppercaseHeading from 'elements/UppercaseHeading';
import WordWrap from 'elements/WordWrap';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import { PhotoType } from 'models/PhotoModel';
import { FactsheetsStoreType } from 'screens/factsheets/FactsheetsStore';
import { assert } from 'utils/assert';
import {
  ROUTE_CONTENT,
  ROUTE_FACTSHEETS,
  ROUTE_SETTINGS
} from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import shortenText from 'utils/shorten-text';

import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import FactsheetPhotoItem from './FactsheetPhotoItem';

interface FactsheetDetailScreenBaseProps extends HistoryProps {
  photoType?: PhotoType;
  text?: boolean;
}

interface FactsheetDetailScreenProps extends FactsheetDetailScreenBaseProps {
  factsheetsStore: FactsheetsStoreType;
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('factsheetsStore', 'applicationStore')
@observer
class FactsheetDetailScreen extends Component<FactsheetDetailScreenProps> {
  async loadFactsheet(id: number) {
    const { factsheetsStore, form } = this.props;

    if (factsheetsStore.isProfileItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    const profile = await factsheetsStore.getFactsheet(id);

    form.setField('profile_text', profile?.profile_text || '');
  }

  componentDidMount() {
    const id = idFromMatch(this.props.match);
    if (id) {
      this.loadFactsheet(id);
    } else {
      this.props.factsheetsStore.clearCurrentProfileItem();
      this.props.form.reset();
    }
  }

  componentDidUpdate(prevProps: FactsheetDetailScreenProps) {
    const newId = idFromMatch(this.props.match);
    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new item mode
          this.props.factsheetsStore.clearCurrentProfileItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        this.loadFactsheet(newId);
      }
    }
  }

  async updateText() {
    const { factsheetsStore, form } = this.props;

    const id = factsheetsStore.profileItem?.id;
    if (!id) {
      return;
    }

    try {
      await factsheetsStore.updateFactsheet(id, {
        profile_text: form.values.profile_text || ''
      });

      this.props.history.push(ROUTE_FACTSHEETS + '/detail/' + id);
    } catch (err) {
      handleFormError(form, err);
    }
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(<GenericErrorComponent />);
  }

  renderNoChapterError() {
    const { isOrganizer } = this.props.applicationStore;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Factsheet" />
        </Headline.Large>

        <Paragraph>
          <FormattedMessage
            id={
              isOrganizer
                ? 'factsheet no chapter organizer'
                : 'factsheet no chapter student'
            }
          />
        </Paragraph>
      </>
    );
  }

  renderFactsheet() {
    const { factsheetsStore, applicationStore } = this.props;
    const { currentUser, isOrganizer } = applicationStore;
    const chapter = factsheetsStore.profileItemChapter;
    const profile = factsheetsStore.profileItem;

    if (!currentUser || !profile || !chapter) {
      return null;
    }

    const settings:
      | FactsheetChapterSettingsModelType
      | undefined = chapter.settings as
      | FactsheetChapterSettingsModelType
      | undefined;
    if (!settings) {
      return null;
    }

    const itIsMe = profile.id === currentUser.id;
    const isTeacherFactsheet = chapter.chapter_type === 'teacher_factsheet';

    let name = profile.first_name || '';
    if (name !== '') {
      name = name + ' ';
    }
    name = name + (profile.last_name || '');

    const photoCount = settings.photo_count || 0;
    const route = ROUTE_FACTSHEETS + '/detail/' + profile.id;

    const printableQuestionsAndAnswers = profile.printableQuestions
      .filter(
        (question) =>
          question.answer &&
          !question.answer.unanswered &&
          question.answer.print &&
          question.answer.answer
      )
      .map((question) => ({
        question: question.question,
        answer: question.answer!.answer
      })) as ProfileTemplateQuestion[];

    const questionsCount = profile.printableQuestions.length;
    const answersCount = profile.printableQuestions.filter(
      (question) =>
        question.answer &&
        question.answer.print &&
        (question.answer.unanswered || question.answer.answer)
    ).length;

    return this.renderPage(
      <>
        <Headline.Large>
          {name || <FormattedMessage id="Factsheet" />}
        </Headline.Large>

        {isOrganizer && (
          <>
            {isTeacherFactsheet ? (
              <LinkBlock
                to={
                  ROUTE_FACTSHEETS +
                  '/' +
                  chapter.id +
                  '/teacher/' +
                  profile.id
                }
                inline={true}
                slim={true}
                background="PRIMARY_LIGHT"
                color="PRIMARY_DARK"
              >
                <IconComponent icon="SETTINGS" />
                <FormattedMessage id="Settings" />
              </LinkBlock>
            ) : (
              <LinkBlock
                to={ROUTE_SETTINGS + '/students/' + profile.id}
                inline={true}
                slim={true}
                background="PRIMARY_LIGHT"
                color="PRIMARY_DARK"
              >
                <IconComponent icon="SETTINGS" />
                <FormattedMessage id="Account settings" />
              </LinkBlock>
            )}
          </>
        )}

        <WideBlock padded={true}>
          <ItemStackBlock gap="S">
            <UppercaseHeading>
              <FormattedMessage id="Design preview" />
            </UppercaseHeading>

            {chapter.layout?.layoutDefinition && (
              <FontPreloadComponent chapter={chapter} numPages={2}>
                <SliderBlock>
                  <ProfilePreviewComponent
                    scaled={true}
                    config={chapter.layout.layoutDefinition}
                    chapter={chapter}
                    profile={profile}
                    text={profile.profile_text || ''}
                    picture={
                      profile.creative_page_photo?.printPicture || null
                    }
                    questions={printableQuestionsAndAnswers}
                    comments={profile.printableComments.map((comment) => ({
                      author: comment.authorName || '',
                      comment: comment.comment || ''
                    }))}
                  />
                </SliderBlock>
              </FontPreloadComponent>
            )}
          </ItemStackBlock>
        </WideBlock>

        {chapter.editing_state === 'active' || isOrganizer ? (
          <>
            {!isOrganizer && (
              <ChapterDescriptionComponent chapter={chapter} />
            )}

            <Headline.Medium>
              <FormattedMessage id="Photos" />
            </Headline.Medium>

            <ListStackBlock>
              {photoCount > 0 && (
                <Link to={route + '/photo/slot1'}>
                  <FactsheetPhotoItem
                    photo={profile.slot1_photo}
                    settings={settings}
                    type="slot1"
                  />
                </Link>
              )}
              {photoCount > 1 && (
                <Link to={route + '/photo/slot2'}>
                  <FactsheetPhotoItem
                    photo={profile.slot2_photo}
                    settings={settings}
                    type="slot2"
                  />
                </Link>
              )}
              {photoCount > 2 && (
                <Link to={route + '/photo/slot3'}>
                  <FactsheetPhotoItem
                    photo={profile.slot3_photo}
                    settings={settings}
                    type="slot3"
                  />
                </Link>
              )}
            </ListStackBlock>

            {(settings.questions_on ||
              settings.comments_on ||
              settings.text_on) && (
                <>
                  <Divider />

                  <Headline.Medium >
                    <FormattedMessage id="Texts" />
                  </Headline.Medium>

                  <ListStackBlock>
                    {settings.questions_on && (
                      <Link to={route + '/answers'}>
                        <EveryCard>
                          <EveryCardPadding>
                            <EveryCardBody>
                              <EveryCardHeadline>
                                <FormattedMessage id="Questions" />
                              </EveryCardHeadline>
                              <Paragraph color="TEXT_LIGHT">
                                {questionsCount > answersCount ? (
                                  <>
                                    <IconComponent
                                      icon="WARNING_CIRCLE"
                                      fill="WARNING"
                                    />
                                    {itIsMe && (
                                      <FormattedMessage id="answer all questions" />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <IconComponent
                                      icon="TICK_CIRCLE"
                                      fill="GREEN"
                                    />
                                    {itIsMe && (
                                      <FormattedMessage id="all questions answered" />
                                    )}
                                  </>
                                )}
                                {!itIsMe &&
                                  (questionsCount === answersCount ? (
                                    <FormattedMessage id="all questions answered" />
                                  ) : (
                                    <FormattedMessage
                                      id="factsheets questions filled count"
                                      values={{
                                        count: questionsCount,
                                        filledCount: answersCount
                                      }}
                                    />
                                  ))}
                              </Paragraph>
                            </EveryCardBody>
                          </EveryCardPadding>
                        </EveryCard>
                      </Link>
                    )}

                    {settings.comments_on && (
                      <Link to={route + '/comments'}>
                        <EveryCard>
                          <EveryCardPadding>
                            <EveryCardBody>
                              <EveryCardHeadline>
                                <FormattedMessage id="Comments" />
                              </EveryCardHeadline>
                              <Paragraph color="TEXT_LIGHT">
                                <FormattedMessage
                                  id="comments count received"
                                  values={{
                                    count: profile.comments?.size || 0
                                  }}
                                />
                              </Paragraph>
                            </EveryCardBody>
                          </EveryCardPadding>
                        </EveryCard>
                      </Link>
                    )}

                    {settings.text_on && (
                      <Link to={route + '/text'}>
                        <EveryCard>
                          <EveryCardPadding>
                            <EveryCardBody>
                              <EveryCardHeadline>
                                <FormattedMessage id="Long text" />
                              </EveryCardHeadline>
                              {profile.profile_text !== undefined && (
                                <Paragraph color="TEXT_LIGHT">
                                  <WordWrap>
                                    {shortenText(100, profile.profile_text)}
                                  </WordWrap>
                                </Paragraph>
                              )}
                            </EveryCardBody>
                          </EveryCardPadding>
                        </EveryCard>
                      </Link>
                    )}
                  </ListStackBlock>
                </>
              )}

            {settings.creative_page && (
              <>
                <Divider />
                <ItemStackBlock gap="XS">
                  <Headline.Medium>
                    <FormattedMessage id="Creative page" />
                  </Headline.Medium>

                  <Paragraph color="TEXT_LIGHT">
                    <FormattedMessage id="creative page info" />
                  </Paragraph>
                </ItemStackBlock>

                <Link to={route + '/photo/creative_page'}>
                  <FactsheetPhotoItem
                    photo={profile.creative_page_photo}
                    settings={settings}
                    type="creative_page"
                  />
                </Link>
              </>
            )}
          </>
        ) : (
          <Paragraph>
            <FormattedMessage id="Factsheet not active" />
          </Paragraph>
        )}
      </>
    );
  }

  renderText() {
    const { factsheetsStore, form } = this.props;
    const profile = factsheetsStore.profileItem;

    if (!profile) {
      return null;
    }

    let extra;
    if (factsheetsStore.isProfileItemError) {
      extra = <GenericErrorComponent onRetryClick={() => this.updateText()} />;
    }

    return this.renderPage(
      <>
        <Headline.Large>
          {profile.first_name || ''} {profile.last_name || ''}
        </Headline.Large>

        {extra}

        <TextareaComponent
          name="profile_text"
          label={<FormattedMessage id="Long text" />}
          {...form.bindInput('profile_text')}
          autoFocus={true}
        />

        <ButtonBlock background="PRIMARY" onClick={() => this.updateText()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>,
      true
    );
  }

  renderPhoto() {
    const profile = this.props.factsheetsStore.profileItem;
    if (!profile) {
      return null;
    }

    const { photoType } = this.props;
    assert(photoType);

    let photo;
    switch (photoType) {
      case 'slot1':
        photo = profile.slot1_photo;
        break;

      case 'slot2':
        photo = profile.slot2_photo;
        break;

      case 'slot3':
        photo = profile.slot3_photo;
        break;

      case 'creative_page':
        photo = profile.creative_page_photo;
        break;

      default:
    }

    const isCreativePage = photoType === 'creative_page';

    return (
      <PhotoEditScreen
        photoId={photo?.id}
        photoType={photoType}
        parentId={profile.id}
        frameWidth={isCreativePage ? 210 : 250}
        frameHeight={isCreativePage ? 291 : 250}
        fitToFrameEnabled={isCreativePage}
        allowDelete={true}
        allowEditCaption={false}
        isImageRounded={false}
        isEditorEnabled={true}
        backRoute={ROUTE_FACTSHEETS + '/detail/' + profile.id}
        backIsCancel={true}
      />
    );
  }

  renderPage(content: ReactNode, cancelLink = false) {
    const chapter = this.props.factsheetsStore.profileItemChapter;
    const isTeacherFactsheets = chapter?.chapter_type === 'teacher_factsheet';

    return (
      <>
        <TopActionBlock>
          {cancelLink ? (
            <CancelLink
              to={ROUTE_FACTSHEETS + '/detail/' + idFromMatch(this.props.match)}
            />
          ) : (
            <BackLink
              to={
                this.props.applicationStore.isOrganizer && chapter
                  ? ROUTE_FACTSHEETS + '/' + chapter.id
                  : ROUTE_CONTENT
              }
            />
          )}
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>

        {isTeacherFactsheets ? (
          <HelpSubject subject="teachers" />
        ) : (
          <HelpSubject subject="factsheets" />
        )}
      </>
    );
  }

  render() {
    const { factsheetsStore } = this.props;

    if (factsheetsStore.isProfileItemLoading) {
      return this.renderLoading();
    }

    if (factsheetsStore.profileItemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (factsheetsStore.profileItemLoadingState === 'no_profile_chapter') {
      // update errors need to keep displaying the form
      return this.renderNoChapterError();
    }

    if (this.props.text) {
      return this.renderText();
    }

    if (this.props.photoType) {
      return this.renderPhoto();
    }

    return this.renderFactsheet();
  }
}

export default (props: FactsheetDetailScreenBaseProps) => {
  const form = useForm();
  // @ts-ignore
  return <FactsheetDetailScreen {...props} form={form} />;
};
