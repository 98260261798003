import { types, Instance } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';

const MottoModel = types.model('MottoModel', {
  id: types.identifierNumber,
  name: types.maybe(types.string),
  tagline: types.maybe(types.string),
  image: types.maybe(types.string),
  thumbnail: types.maybe(types.string),
  is_global: types.maybe(types.boolean),
  has_own_vote: types.maybe(types.boolean),
  votes_count: types.maybe(types.number),
  author: types.maybe(ProfileAsAuthorModel)
});

export const createMottoModel = (data?: any): MottoModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return MottoModel.create({
    id: mandatoryId(data.id),
    name: data.name,
    tagline: data.tagline === null || undefined ? undefined : data.tagline,
    image: data.image === null || undefined ? undefined : data.image,
    thumbnail:
      data.thumbnail === null || undefined ? undefined : data.thumbnail,
    is_global: data.global,
    has_own_vote: data.own_vote,
    votes_count: data.votes_count,
    author: createProfileAsAuthorModel(data.author)
  });
};

export type MottoModelType = Instance<typeof MottoModel>;
export default MottoModel;
