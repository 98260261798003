import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TopActionBlock from 'blocks/TopActionBlock';
import ItemSortingComponent from 'components/ItemSortingComponent/ItemSortingComponent';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { assert } from 'utils/assert';
import { ROUTE_RANKINGS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import { RankingsStoreType } from 'screens/rankings/RankingsStore';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

interface Props {
  rankingsStore: RankingsStoreType;
}

@inject('rankingsStore')
@observer
class SortRankingsScreen extends React.Component<Props & HistoryProps> {
  componentDidMount() {
    this.loadRankings();
  }

  loadRankings() {
    const { rankingsStore } = this.props;
    const chapterId = this.getChapterId();
    rankingsStore.getRankingsByChapter(chapterId);
  }

  getChapterId() {
    const chapterId = chapterIdFromMatch(this.props.match);
    assert(chapterId);
    return chapterId;
  }

  onSortEnd(oldIndex: number, newIndex: number) {
    this.props.rankingsStore.moveRanking(oldIndex, newIndex);
  }

  renderPage(content: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackLink
            messageId="Done"
            to={ROUTE_RANKINGS + '/' + this.getChapterId()}
          />
        </TopActionBlock>
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Sort rankings" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadRankings()} />
    );
  }

  render() {
    const { rankingsStore } = this.props;

    if (rankingsStore.isListLoading) {
      return this.renderLoading();
    }

    if (rankingsStore.isListError) {
      return this.renderError();
    }

    return this.renderPage(
      <ItemSortingComponent
        items={rankingsStore.allRankings.map((ranking) => ({
          id: ranking.id,
          text: ranking.name || ''
        }))}
        onSortEnd={(oldIndex, newIndex) => this.onSortEnd(oldIndex, newIndex)}
      />
    );
  }
}

export default SortRankingsScreen;
