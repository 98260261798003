import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { ROUTE_CONTENT } from 'utils/constants/routes';

import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';
import { useHistory } from 'react-router';

export const BackToContentLink = () => {
  const history = useHistory();
  const state = history.location.state as { backRoute: string }

  const checkHistoryState = () => {
    let backLink = ROUTE_CONTENT;

    if (state && state.backRoute) {
      return backLink = state.backRoute;
    }

    return backLink;
  }

  return (
    <Link to={checkHistoryState()}>
      <TextElement color="GRAY10">
        <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
        <FormattedMessage id="Back" />
      </TextElement>
    </Link>
  )
};

export default BackToContentLink;
