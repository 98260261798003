import React from 'react';
import { FormattedMessage } from 'react-intl';

import config from 'config';

import ButtonBlock from 'blocks/ButtonBlock';

export default () => (
  <ButtonBlock
    background="SECONDARY_DARK"
    onClick={() => {
      window.location.href = config.websiteUrl;
    }}
  >
    <FormattedMessage id="Go to nuggit website" />
  </ButtonBlock>
);
