import { intl } from 'i18n';
import {
  flow,
  getEnv,
  Instance,
  types
} from 'mobx-state-tree';
import ROLES, { UPDATE_ROLES } from 'utils/constants/roles';
import createMap, { createMapWithTransform } from 'utils/create-map';
import { sortByName } from 'utils/sort-functions';
import createProfileModel from 'utils/store/createProfileModel';

import { ClientStates } from './ClientStateModel';
import GroupModel, {
  createGroupModel,
  GroupModelType
} from './GroupModel';
import ProfileModel, { ProfileModelType } from './ProfileModel';
import { AdvancedStoreEnv } from './StoreEnv';

const ProfilesStore = types
  .model('ProfilesStore', {
    // list of all students
    students: types.maybe(types.map(ProfileModel)),
    studentsLoadingState: types.maybe(types.string),
    // single item
    itemLoadingState: types.maybe(types.string),
    item: types.maybe(ProfileModel),

    // list of all profiles (students and teachers)
    allProfiles: types.maybe(types.map(ProfileModel)),
    allProfilesLoadingState: types.maybe(types.string),

    // list of groups
    groups: types.maybe(types.map(GroupModel)),
    groupsLoadingState: types.maybe(types.string),
    // single item
    groupItemLoadingState: types.maybe(types.string),
    groupItem: types.maybe(GroupModel),

    // list of groups
    textileGroups: types.maybe(types.map(GroupModel)),
    textileGroupsLoadingState: types.maybe(types.string)
  })
  .actions((self) => {
    const getStudents = flow(function* (clear: boolean = false) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      let updating = false;
      try {
        // getStudents() will only update students list by default instead of refreshing it entirely
        if (clear || !self.students) {
          self.studentsLoadingState = 'loading';
          self.students = undefined;
        } else {
          self.students.clear();
          updating = true;
          self.studentsLoadingState = 'updating';
        }

        const result = yield client.getAllProfiles();

        if (!Array.isArray(result) || !result.length) {
          self.studentsLoadingState = undefined;
          return;
        }

        self.students = createMap(result);
        self.studentsLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | getStudents', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.studentsLoadingState = undefined;
          return;
        }

        self.studentsLoadingState = updating ? 'update_error' : 'error';
      }
    });

    const getStudentsIfNotPresent = flow(function* () {
      if (self.students && self.students.size > 0) {
        return;
      }

      yield getStudents();
    });

    const setStudents = (students: ProfileModelType[]) => {
      self.students = createMap(students);
    };

    const getStudent = flow(function* (
      id: number,
      includeBook: boolean = false
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const item: any = yield client.getProfile(id, includeBook);

        if (includeBook && item.book) {
          applicationStore.setBook(item.book);
        }

        self.item = createProfileModel(item);
        self.itemLoadingState = undefined;

        return self.item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | getStudent', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    // TODO refactor to use updateProfile
    const updateName = flow(function* (
      profileId: number,
      first_name: string,
      last_name: string
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        const item = yield client.updateProfile({
          id: profileId,
          first_name,
          last_name
        });

        if (item) {
          if (
            item.name &&
            applicationStore.currentUser &&
            applicationStore.currentUser.id === item.id
          ) {
            applicationStore.patchCurrentUser({
              name: item.name,
              first_name: item.first_name,
              last_name: item.last_name
            });
          }

          if (self.item && self.item.id === item.id) {
            self.item = createProfileModel(item);
          }
        }

        self.itemLoadingState = undefined;

        return item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | updatename', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          throw error; // throw error so screens abort update flow
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    // TODO refactor to use updateProfile
    const updatePrint = flow(function* (
      profileId: number,
      print: boolean,
      printYearbook: boolean
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        const item = yield client.updateProfile({
          id: profileId,
          print: !!print,
          print_yearbook: !!printYearbook
        });

        if (item) {
          if (
            item.name &&
            applicationStore.currentUser &&
            applicationStore.currentUser.id === item.id
          ) {
            applicationStore.patchCurrentUser({
              name: item.name,
              print: item.print,
              print_yearbook: item.print_yearbook
            });
          }

          if (self.item && self.item.id === item.id) {
            self.item = createProfileModel(item);
          }
        }

        self.itemLoadingState = undefined;

        return item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | updatePrint', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          throw error; // throw error so screens abort update flow
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    // TODO refactor to use updateProfile
    const updateRole = flow(function* (
      profileId: number,
      newRole: keyof typeof UPDATE_ROLES
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        const item = yield client.updateProfile({
          id: profileId,
          role: ROLES[newRole]
        });

        if (item) {
          if (
            item.name &&
            applicationStore.currentUser &&
            applicationStore.currentUser.id === item.id
          ) {
            applicationStore.patchCurrentUser({
              role: item.role
            });
          }

          if (self.item && self.item.id === item.id) {
            self.item = createProfileModel(item);
          }

          if (self.students && self.students.has(item.id)) {
            const itemModel = createProfileModel(item);
            if (itemModel) {
              self.students.put(itemModel);
            }
          }
        }

        self.itemLoadingState = undefined;

        return item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | updateRole', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    // TODO refactor to use updateProfile
    const updateTeams = flow(function* (
      profileId: number,
      teams: string[],
      description: string
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        const item = yield client.updateProfile({
          id: profileId,
          teams,
          team_description: description
        });

        if (item) {
          if (self.item && self.item.id === item.id) {
            self.item = createProfileModel(item);
          }
        }

        // reflect client state update performed on server in local store
        applicationStore.overrideLocalClientState(
          ClientStates.teams_selected,
          'yes'
        );
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | updateTeams', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        if (client.isFormError(error)) {
          throw error;
        }

        throw new Error('save_error');
      }
    });

    // TODO refactor to use updateProfile
    const updateProfileGroup = flow(function* (
      profileId: number,
      groupId: number | undefined
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        const item = yield client.updateProfile({
          id: profileId,
          group_id: groupId
        });

        if (
          item.name &&
          applicationStore.currentUser &&
          applicationStore.currentUser.id === item.id
        ) {
          applicationStore.patchCurrentUser({
            group: item.group
          });
        }

        // Update the item
        if (self.item && self.item.id === item.id) {
          self.item = createProfileModel(item);
        }

        // Update the profile in the students list
        if (self.students && self.students.has(profileId.toString())) {
          self.students.put(createProfileModel(item)!);
        }

        self.itemLoadingState = undefined;

        return item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ProfilesStore | updateProfileGroup',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    // TODO refactor to use updateProfile
    const setCommiteeMemberFlag = flow(function* (
      profileId: number,
      isCommitteeMember: boolean | null
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        yield client.updateProfile({
          id: profileId,
          committee_member: isCommitteeMember
        });

        self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ProfilesStore | setCommiteeMemberFlag',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const joinBook = flow(function* (
      bookId: number
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const item = yield client.joinBook(bookId);

        if (item) {
          self.item = createProfileModel(item);
        }

        self.itemLoadingState = undefined;

        return self.item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | joinBook', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const createManuallyCreatedStudent = flow(function* (
      first_name: string,
      last_name: string,
      mobile_number?: string,
      group_id?: number
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const newProfile: any = {
          first_name,
          last_name,
          mobile_number,
          group_id,
          role: 'manually_created_student'
        };

        const item = yield client.createProfile(newProfile);

        if (item) {
          self.item = createProfileModel(item);
        }

        self.itemLoadingState = undefined;

        return self.item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | createProfile', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const createTeacher = flow(function* (
      firstName: string,
      lastName: string,
      chapterId?: number
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const item = yield client.createProfile({
          first_name: firstName,
          last_name: lastName,
          teacher_chapter_id: chapterId,
          role: ROLES.MANUALLY_CREATED_TEACHER
        });

        if (item) {
          self.item = createProfileModel(item);
        }

        self.itemLoadingState = undefined;

        return self.item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | createTeacher', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateProfile = flow(function* (
      profileId: number,
      patch: Partial<ProfileModelType>
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        const item = yield client.updateProfile({
          id: profileId,
          ...patch
        });

        if (!item) {
          throw new Error('No response from server');
        }

        const model = createProfileModel(item)!;

        // update the item
        if (self.item && self.item.id === model.id) {
          self.item = model;
        }

        const stringId = model.id.toString();

        // update the profile in lists
        if (self.students?.has(stringId)) {
          self.students.put(createProfileModel(item)!);
        }

        if (self.allProfiles?.has(stringId)) {
          self.allProfiles.put(createProfileModel(item)!);
        }

        self.itemLoadingState = undefined;

        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | updatename', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          throw error; // throw error so screens abort update flow
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const removeProfile = flow(function* (id: number, removeFromList = true) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        yield client.removeProfile(id);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | removeProfile', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.itemLoadingState = 'update_error';
          throw error;
        }
      }

      if (removeFromList && self.students) {
        self.students.delete(id.toString());
      }

      if (self.item && self.item.id === id) {
        self.item = undefined;
      }
      self.itemLoadingState = undefined;
    });

    const clearCurrentItem = () => {
      self.item = undefined;
      self.itemLoadingState = undefined;
    };

    const getGroups = flow(function* (clear: boolean = false) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      let updating = false;
      try {
        // getGroups() will only update groups list by default instead of refreshing it entirely
        if (clear || !self.groups) {
          self.groupsLoadingState = 'loading';
          self.groups = undefined;
        } else {
          updating = true;
          self.groupsLoadingState = 'updating';
        }

        const result = yield client.getAllGroups();

        if (!Array.isArray(result) || !result.length) {
          self.groupsLoadingState = undefined;
          return;
        }

        self.groups = createMapWithTransform(result, createGroupModel);
        self.groupsLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | getAllGroups', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.groupsLoadingState = undefined;
          return;
        }

        self.groupsLoadingState = updating ? 'update_error' : 'error';
      }
    });
    const getGroupsIfNotPresent = flow(function* () {
      if (self.groups && self.groups.size > 0) {
        return;
      }

      yield getGroups();
    });

    const getGroup = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.groupItemLoadingState = 'loading';
        self.groupItem = undefined;

        const item = yield client.getGroup(id);

        self.groupItem = createGroupModel(item);
        self.groupItemLoadingState = undefined;

        return item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | getGroup', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.groupItemLoadingState = undefined;
          return;
        }

        self.groupItemLoadingState = 'error';
      }
    });

    const createGroup = flow(function* (
      item: { name: string },
      addToList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      self.groupItemLoadingState = 'loading';
      self.groupItem = undefined;

      try {
        const result: any = yield client.createGroup(item);

        if (!result) {
          throw new Error('No response from server');
        }

        const group = createGroupModel(result);
        self.groupItem = group;

        if (addToList && group) {
          // create new model as we cannot put the same model into the tree twice
          if (self.groups) {
            self.groups.put(createGroupModel(group)!);
          } else {
            // create list as we need to display it immediately after creation in GroupsList
            self.groups = createMapWithTransform([group], createGroupModel);
          }
        }

        self.groupItemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | createGroup', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.groupItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.groupItemLoadingState = undefined;
          throw error;
        }

        self.groupItemLoadingState = 'update_error';
      }
    });

    const updateGroup = flow(function* (groupId: number, patch: any) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        // self.itemLoadingState = 'loading';

        const group: any = yield client.updateGroup(groupId, patch);

        if (!group || !group.id) {
          throw new Error('No response from server');
        }

        if (self.groups && self.groups.has(group.id.toString())) {
          const model = createGroupModel(group);
          if (model) {
            self.groups.put(model);
          }
        }

        if (self.groupItem?.id === group.id) {
          self.groupItem = createGroupModel(group);
        }
        // self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | updateGroup', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          // self.itemLoadingState = undefined;
          throw error;
        }

        // self.itemLoadingState = 'update_error';
        throw new Error('update_error');
      }
    });

    const removeGroup = flow(function* (
      groupId: number,
      removeFromList = true
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      self.groupItemLoadingState = 'loading';

      try {
        yield client.removeGroup(groupId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | removeGroup', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.groupItemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.groupItemLoadingState = 'remove_error';
          throw error;
        }
      }

      if (removeFromList && self.groups) {
        self.groups.delete(groupId.toString());
      }

      if (self.groupItem?.id === groupId) {
        self.groupItem = undefined;
      }

      self.groupItemLoadingState = undefined;
    });

    const clearCurrentGroupItem = () => {
      self.item = undefined;
      self.itemLoadingState = undefined;
    };

    const acceptRoleChangeRequest = flow(function* (requestId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.acceptRoleChangeRequest(requestId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ProfilesStore | acceptRoleChangeRequest',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw new Error('save_error');
      }
    });

    const rejectRoleChangeRequest = flow(function* (requestId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.rejectRoleChangeRequest(requestId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ProfilesStore | rejectRoleChangeRequest',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw new Error('save_error');
      }
    });

    const requestOrganizerRole = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.requestOrganizerRole();
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ProfilesStore | requestOrganizerRole',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw new Error('save_error');
      }
    });

    const requestRoleChange = flow(function* (type: string) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        yield client.requestRoleChange(type);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ProfilesStore | requestRoleChange',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          return;
        }

        throw new Error('save_error');
      }
    });

    const getAllProfiles = flow(function* (clear: boolean = false) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      let updating = false;
      try {
        // getAllProfiles() will only update students list by default instead of refreshing it entirely
        if (clear || !self.allProfiles) {
          self.allProfilesLoadingState = 'loading';
          self.allProfiles = undefined;
        } else {
          self.allProfiles.clear();
          updating = true;
          self.allProfilesLoadingState = 'updating';
        }

        const result = yield client.getAllProfiles(true);

        if (!Array.isArray(result) || !result.length) {
          self.allProfilesLoadingState = undefined;
          return;
        }

        self.allProfiles = createMap(result);
        self.allProfilesLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ProfilesStore | getAllProfiles', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.allProfilesLoadingState = undefined;
          return;
        }

        self.allProfilesLoadingState = updating ? 'update_error' : 'error';
      }
    });

    return {
      getStudents,
      getStudentsIfNotPresent,
      getStudent,
      setStudents,
      updateName,
      updatePrint,
      updateRole,
      updateTeams,
      updateProfileGroup,
      setCommiteeMemberFlag,
      joinBook,
      createManuallyCreatedStudent,
      updateProfile,
      createTeacher,
      removeProfile,
      clearCurrentItem,
      getGroups,
      getGroupsIfNotPresent,
      getGroup,
      createGroup,
      updateGroup,
      removeGroup,
      clearCurrentGroupItem,
      acceptRoleChangeRequest,
      rejectRoleChangeRequest,
      requestOrganizerRole,
      requestRoleChange,
      getAllProfiles
    };
  })
  .views((self) => {
    const sortedStudents = (
      allStudents: IterableIterator<ProfileModelType>,
      onlyGroupId?: number
    ) => {
      const students: ProfileModelType[] = [];
      if (!allStudents) {
        return students;
      }

      for (const profile of allStudents) {
        if (profile.role === ROLES.APPLICANT) {
          continue;
        }

        if (onlyGroupId && onlyGroupId !== profile.group?.id) {
          continue;
        }

        students.push(profile);
      }

      students.sort(sortByName);
      return students;
    };

    return {
      get isStudentsLoading(): boolean {
        return self.studentsLoadingState === 'loading' ||
          self.studentsLoadingState === 'updating'
          ? true
          : false;
      },
      get isStudentsError(): boolean {
        return self.studentsLoadingState === 'error' ||
          self.studentsLoadingState === 'update_error'
          ? true
          : false;
      },

      student(id: number | string) {
        if (!self.students) {
          return undefined;
        }
        return self.students.get(id.toString());
      },

      sortedStudents(onlyGroupId?: number): ProfileModelType[] {
        if (!self.students) {
          return [];
        }

        return sortedStudents(self.students.values(), onlyGroupId);
      },
      filteredStudents(
        name?: string,
        onlyGroupId?: number,
        studentFilter?: string | number
      ): ProfileModelType[] {
        const students: ProfileModelType[] = [];
        if (!self.students) {
          return students;
        }

        const nameLowercase = name ? name.trim().toLowerCase() : '';

        for (const profile of self.students.values()) {
          if (profile.role === ROLES.APPLICANT) {
            continue;
          }

          if (onlyGroupId && onlyGroupId !== profile.group?.id) {
            continue;
          }

          if (studentFilter === intl.formatMessage({ id: 'Organizers' })) {
            if (!profile.isOrganizer) {
              continue;
            }
          }

          if (
            studentFilter === intl.formatMessage({ id: 'Virtual students' })
          ) {
            if (!profile.isManuallyCreated) {
              continue;
            }
          }

          if (studentFilter === intl.formatMessage({ id: 'No group' })) {
            if (profile.group) {
              continue;
            }
          }

          if (
            studentFilter &&
            typeof studentFilter === 'number' &&
            studentFilter !== profile.group?.id
          ) {
            continue;
          }

          if (
            profile.name &&
            profile.name.toLowerCase().indexOf(nameLowercase) > -1
          ) {
            students.push(profile);
          }
        }

        students.sort(sortByName);
        return students;
      },
      get studentsWithoutGroup(): ProfileModelType[] {
        if (!self.students) {
          return [];
        }

        return sortedStudents(self.students.values()).filter(
          (profile) => !profile.group
        );
      },
      get studentsCount(): number {
        if (!self.students) {
          return 0;
        }

        let count = 0;
        for (const profile of self.students.values()) {
          if (profile.role === ROLES.APPLICANT) {
            continue;
          }

          count++;
        }

        return count;
      },
      get organizers(): ProfileModelType[] {
        const organizers: ProfileModelType[] = [];
        if (!self.students) {
          return organizers;
        }

        for (const profile of self.students.values()) {
          if (
            profile.role === ROLES.ORGANIZER ||
            profile.role === ROLES.MANAGER ||
            profile.role === ROLES.MANUALLY_CREATED_ORGANIZER
          ) {
            organizers.push(profile);
          }
        }

        organizers.sort(sortByName);
        return organizers;
      },
      get organizersCount(): number {
        if (!self.students) {
          return 0;
        }

        let count = 0;
        for (const profile of self.students.values()) {
          if (
            profile.role === ROLES.ORGANIZER ||
            profile.role === ROLES.MANAGER
          ) {
            count++;
          }
        }

        return count;
      },
      get allCommentsCount(): number {
        if (!self.students) {
          return 0;
        }

        let commentsCount = 0;
        for (const profile of self.students.values()) {
          commentsCount += profile.comments_count ? profile.comments_count : 0;
        }

        return commentsCount;
      },
      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading';
      },
      get isItemError(): boolean {
        return (
          self.itemLoadingState === 'error' ||
          self.itemLoadingState === 'update_error'
        );
      },
      get isGroupsLoading(): boolean {
        return (
          self.groupsLoadingState === 'loading' ||
          self.groupsLoadingState === 'updating'
        );
      },
      get isGroupsError(): boolean {
        return (
          self.groupsLoadingState === 'error' ||
          self.groupsLoadingState === 'update_error'
        );
      },
      get isGroupItemLoading(): boolean {
        return self.groupItemLoadingState === 'loading';
      },
      get isGroupItemError(): boolean {
        return (
          self.groupItemLoadingState === 'error' ||
          self.groupItemLoadingState === 'update_error'
        );
      },
      get sortedGroups(): GroupModelType[] {
        const groups: GroupModelType[] = [];
        if (!self.groups) {
          return groups;
        }

        for (const group of self.groups.values()) {
          groups.push(group);
        }

        groups.sort(sortByName);
        return groups;
      },
      get groupsCount(): number {
        return self.groups?.size || 0;
      },
      get isAllProfilesLoading(): boolean {
        return self.allProfilesLoadingState === 'loading' ||
          self.allProfilesLoadingState === 'updating'
          ? true
          : false;
      },
      get isAllProfilesError(): boolean {
        return self.allProfilesLoadingState === 'error' ||
          self.allProfilesLoadingState === 'update_error'
          ? true
          : false;
      },
      allProfilesSorted(filter?: string) {
        const result: {
          students: ProfileModelType[];
          teachers: ProfileModelType[];
        } = { students: [], teachers: [] };

        if (!self.allProfiles?.size) {
          return result;
        }

        const lowercaseFilter = !filter
          ? undefined
          : filter.toLocaleLowerCase();

        for (const profile of self.allProfiles.values()) {
          if (lowercaseFilter) {
            const fullName = profile.fullName;
            if (!fullName) {
              continue;
            }

            if (fullName.toLocaleLowerCase().indexOf(lowercaseFilter) < 0) {
              continue;
            }
          }

          if (profile.isTeacher) {
            result.teachers.push(profile);
          } else {
            result.students.push(profile);
          }
        }

        result.students.sort(sortByName);
        result.teachers.sort(sortByName);

        return result;
      },
      profileFromAllProfiles(profileId: number): ProfileModelType | undefined {
        return self.allProfiles?.get(profileId.toString());
      },
      get hasAllProfilesStudents(): boolean {
        if (!self.allProfiles?.size) {
          return false;
        }

        for (const profile of self.allProfiles.values()) {
          if (!profile.isTeacher) {
            return true;
          }
        }

        return false;
      },
      get hasAllProfilesTeachers(): boolean {
        if (!self.allProfiles?.size) {
          return false;
        }

        for (const profile of self.allProfiles.values()) {
          if (profile.isTeacher) {
            return true;
          }
        }

        return false;
      },

      namesProfilesByTextileGroup(textileGroup: GroupModelType): string[] {
        const names: string[] = [];
        if (!self.allProfiles?.size) {
          return names;
        }

        for (const profile of self.allProfiles.values()) {
          if (profile.textile_group && profile.textile_group.id === textileGroup.id) {
            names.push(profile.fullName || '');
          }
        }

        return names;
      }
    };
  });

export type ProfilesStoreType = Instance<typeof ProfilesStore>;
export default ProfilesStore;
