import React from 'react';

import ScaledPagePreviewComponent from '../structure/ScaledPagePreviewComponent';
import { TemplateRenderFunc } from '../templates/CommonTemplateProps';

export const scaled: TemplateRenderFunc = (content, key) => {
  return (
    <ScaledPagePreviewComponent key={key} border={true}>{content}</ScaledPagePreviewComponent>
  );
};

export const scaledStack: TemplateRenderFunc = (content, key) => {
  return (
    <ScaledPagePreviewComponent key={key} stack={true}>
      {content}
    </ScaledPagePreviewComponent>
  );
};
