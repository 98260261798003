import config from 'config';
import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import { CHAPTER_TYPES_WITH_LAYOUT } from 'models/ChapterModel';
import { ChapterType, ChapterTypeEnum } from 'models/ChapterTypeEnum';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import createMap, { createMapWithTransform } from 'utils/create-map';
import { sortByFieldLowerCase, sortByName } from 'utils/sort-functions';

import BackgroundModel, {
  BackgroundModelType,
  createBackgroundModel
} from './BackgroundModel';
import ColorSchemeModel, {
  ColorSchemeModelType,
  createColorSchemeModel
} from './ColorSchemeModel';
import FontModel, { createFontModel, FontModelType } from './FontModel';
import LayoutModel, { createLayoutModel, LayoutModelType } from './LayoutModel';
import ThemeMottoModel, {
  createThemeMottoModel,
  ThemeMottoModelType
} from './ThemeMottoModel';

export const chapterTypeToServerPath = (chapterType: ChapterType) => {
  switch (chapterType) {
    case 'cover':
      return 'themes';

    case 'factsheet':
    case 'teacher_factsheet':
      return 'layouts/profiles';

    default:
      return 'layouts/' + chapterType + 's';
  }
};

export const chapterTypeToClientMethod = (
  chapterType: ChapterType,
  client: AdvancedStoreEnv['client']
) => {
  switch (chapterType) {
    case 'cover':
      return client.getThemes;

    case 'factsheet':
    case 'teacher_factsheet':
      return client.getFactsheetLayouts;

    case 'quote':
      return client.getQuoteLayouts;

    case 'ranking':
      return client.getRankingLayouts;

    case 'text':
      return client.getTextLayouts;

    case 'yearbook':
      return client.getYearbookLayouts;

    default:
      return undefined;
  }
};

const baseThemeKey = (key: string) => {
  const idx = key.indexOf('_');
  if (idx < 0) {
    return key;
  }
  return key.substring(0, idx);
};

interface Filterable {
  id: string;
  tags?: string[];
}
const filterItems = <T extends Filterable>(
  items: T[] | IterableIterator<T>,
  themeKey?: string,
  displayUnindexed?: boolean,
  mustIncludeTags?: string[],
  onlyIds?: string[]
): T[] => {
  const list: T[] = [];

  if (themeKey) {
    themeKey = baseThemeKey(themeKey);
  }

  const iterator = Array.isArray(items) ? items.values() : items;
  for (const item of iterator) {
    const tags = [];
    const onlyThemes = [];

    // TODO remove onlytheme: handling as server now does this?
    for (const tag of (item.tags || []).values()) {
      if (tag.startsWith('onlytheme:')) {
        onlyThemes.push(tag.substring(10));
      } else {
        tags.push(tag);
      }
    }

    const isFiltered = tags.indexOf('filtered') > -1;

    // TODO remove onlytheme: handling as server now does this?
    if (
      !isFiltered &&
      themeKey &&
      onlyThemes.length &&
      onlyThemes.indexOf(themeKey) < 0
    ) {
      // console.log(item.id, 'excluded by onlyThemes');
      continue;
    }

    if (onlyIds && onlyIds.indexOf(item.id) < 0) {
      // console.log(item.id, 'excluded by onlyIds');
      continue;
    }

    if (!displayUnindexed && tags.indexOf('unindexed') > -1 && !isFiltered) {
      // unindexed will only be hidden if it is not a private item marked with filtered tag
      continue;
    }

    if (mustIncludeTags) {
      let fail = false;
      for (const tag of mustIncludeTags) {
        if (tags.indexOf(tag) < 0) {
          // console.log(item.id, 'excluded by mustIncludeTags', tag);
          fail = true;
          break;
        }
      }
      if (fail) {
        continue;
      }
    }

    list.push(item);
  }

  return list;
};

const LayoutStore = types
  .model('LayoutStore', {
    loadingState: types.maybe(
      types.enumeration(['initializing', 'init_error', 'loading', 'error'])
    ),

    // basics
    colorSchemes: types.maybe(types.map(ColorSchemeModel)),
    fonts: types.maybe(types.map(FontModel)),
    backgrounds: types.maybe(types.map(BackgroundModel)),

    // layouts
    currentLayoutType: types.maybe(ChapterTypeEnum),
    layouts: types.maybe(types.map(LayoutModel)),
    layoutDefinitions: types.maybe(types.map(LayoutModel)),

    // themes
    themeMottos: types.maybe(types.map(ThemeMottoModel)), // TODO remove this?
    themes: types.maybe(types.map(LayoutModel)),

    // misc
    displayUnindexed: types.maybe(types.boolean),
    ignoreOnlytheme: types.maybe(types.boolean)
  })
  .actions((self) => {
    const initialize = flow(function* (
      chapterType?: ChapterType,
      resetStateOnSuccess: boolean = true,
      checkIfAlreadyInitialized: boolean = true
    ) {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      if (checkIfAlreadyInitialized) {
        if (
          self.backgrounds &&
          self.colorSchemes &&
          self.fonts &&
          chapterType &&
          chapterType === self.currentLayoutType
        ) {
          return;
        }
      }

      self.loadingState = 'initializing';

      self.backgrounds = undefined;
      self.colorSchemes = undefined;
      self.fonts = undefined;

      const loadLayouts =
        chapterType && CHAPTER_TYPES_WITH_LAYOUT.indexOf(chapterType) > -1;
      if (loadLayouts) {
        self.currentLayoutType = undefined;
        self.layoutDefinitions = undefined;
        self.layouts = undefined;
      }

      // fetch some basics needed in order to use the design editor
      try {
        // backgrounds
        // const backgrounds: any = yield (yield fetch(
        //   config.layout.baseUrl + '/backgrounds/index.json'
        // )).json();

        const backgrounds: any = yield client.getBackgrounds();

        if (!Array.isArray(backgrounds)) {
          throw new Error('Could not fetch backgrounds.');
        }

        self.backgrounds = createMap([]);

        for (const background of backgrounds) {
          const model = createBackgroundModel(background);
          if (model) {
            self.backgrounds!.put(model);
          }
        }

        // colors
        // const colors: any = yield (yield fetch(
        //   config.layout.baseUrl + '/colors/index.json'
        // )).json();

        const colors: any = yield client.getColors();

        if (!Array.isArray(colors)) {
          throw new Error('Could not fetch colors.');
        }

        self.colorSchemes = createMap([]);

        for (const color of colors) {
          const model = createColorSchemeModel(color);
          if (model) {
            self.colorSchemes!.put(model);
          }
        }

        // fonts
        // const fonts: any = yield (yield fetch(
        //   config.layout.baseUrl + '/fonts/index.json'
        // )).json();

        const fonts: any = yield client.getFonts();

        if (!Array.isArray(fonts)) {
          throw new Error('Could not fetch fonts.');
        }

        self.fonts = createMap([]);

        for (const font of fonts) {
          const model = createFontModel(font);
          if (model) {
            self.fonts!.put(model);
          }
        }

        // layouts
        if (loadLayouts) {
          const layoutFetchMethod = chapterTypeToClientMethod(
            chapterType!,
            client
          );

          if (!layoutFetchMethod) {
            throw new Error('No layouts available for chapter type.');
          }

          const layouts: any = yield layoutFetchMethod();

          if (!Array.isArray(layouts)) {
            throw new Error('Could not fetch layouts.');
          }

          self.layouts = createMap([]);

          for (const layout of layouts) {
            const model = createLayoutModel(layout);
            if (model) {
              self.layouts!.put(model);
            }
          }

          self.currentLayoutType = chapterType;
        }

        if (resetStateOnSuccess) {
          self.loadingState = undefined;
        }
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('LayoutStore | initialize', error);
        }

        self.loadingState = 'init_error';
      }
    });

    const getLayout = flow(function* (chapterType: ChapterType, key: string) {
      try {
        // TODO should we move this to an API call as well?
        const layout: any = yield (yield fetch(
          config.layout.baseUrl +
            '/' +
            chapterTypeToServerPath(chapterType) +
            '/' +
            key +
            '.json'
        )).json();

        if (!layout) {
          throw new Error('Could not fetch layout.');
        }

        const model = createLayoutModel(layout);
        if (!model) {
          throw new Error('Could not convert layout.');
        }

        if (model) {
          if (!self.layoutDefinitions) {
            self.layoutDefinitions = createMap([]);
          }
          self.layoutDefinitions!.put(model);
        }

        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('LayoutStore | getLayout', error);
        }

        throw error;
      }
    });

    const getThemes = flow(function* () {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      self.loadingState = 'loading';

      self.themeMottos = undefined;
      self.themes = undefined;

      try {
        // const result: any = yield (yield fetch(
        //   config.layout.baseUrl + '/themes/index-de.json'
        // )).json();

        const result: any = yield client.getThemes();

        if (!result) {
          throw new Error('Could not fetch themes list.');
        }

        self.themeMottos = createMapWithTransform(
          Array.isArray(result.mottos) ? result.mottos : [],
          createThemeMottoModel
        );
        self.themes = createMapWithTransform(
          Array.isArray(result.themes) ? result.themes : [],
          createLayoutModel
        );

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('LayoutStore | getThemes', error);
        }

        self.loadingState = 'error';
      }
    });

    const getLayouts = flow(function* (chapterType: ChapterType) {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      self.loadingState = 'loading';

      self.layouts = undefined;

      try {
        const layoutFetchMethod = chapterTypeToClientMethod(
          chapterType!,
          client
        );

        if (!layoutFetchMethod) {
          throw new Error('No layouts available for chapter type.');
        }

        const layouts: any = yield layoutFetchMethod();

        if (!Array.isArray(layouts)) {
          throw new Error('Could not fetch layouts.');
        }

        self.layouts = createMap([]);

        for (const layout of layouts) {
          const model = createLayoutModel(layout);
          if (model) {
            self.layouts!.put(model);
          }
        }

        self.currentLayoutType = chapterType;

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('LayoutStore | getLayouts', error);
        }

        self.loadingState = 'error';
      }
    });

    const getFonts = flow(function* (chapterType: ChapterType) {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      self.loadingState = 'loading';

      try {
        const fonts: any = yield client.getFonts();

        if (!Array.isArray(fonts)) {
          throw new Error('Could not fetch fonts.');
        }

        self.fonts = createMap([]);

        for (const font of fonts) {
          const model = createFontModel(font);
          if (model) {
            self.fonts!.put(model);
          }
        }

        self.currentLayoutType = chapterType;

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('LayoutStore | getFonts', error);
        }

        self.loadingState = 'error';
      }
    });

    const getBackgrounds = flow(function* (chapterType: ChapterType) {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      self.loadingState = 'loading';

      try {

        const backgrounds: any = yield client.getBackgrounds();

        if (!Array.isArray(backgrounds)) {
          throw new Error('Could not fetch backgrounds.');
        }

        self.backgrounds = createMap([]);

        for (const background of backgrounds) {
          const model = createBackgroundModel(background);
          if (model) {
            self.backgrounds!.put(model);
          }
        }

        self.currentLayoutType = chapterType;

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('LayoutStore | getBackgrounds', error);
        }

        self.loadingState = 'error';
      }
    });

    const getColorSchemes = flow(function* (chapterType: ChapterType) {
      const { client } = getEnv<AdvancedStoreEnv>(self);

      self.loadingState = 'loading';

      try {
        const colors: any = yield client.getColors();

        if (!Array.isArray(colors)) {
          throw new Error('Could not fetch colors.');
        }

        self.colorSchemes = createMap([]);

        for (const color of colors) {
          const model = createColorSchemeModel(color);
          if (model) {
            self.colorSchemes!.put(model);
          }
        }

        self.currentLayoutType = chapterType;

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('LayoutStore | getBackgrounds', error);
        }

        self.loadingState = 'error';
      }
    });

    const reset = () => {
      self.layouts = undefined;
      self.fonts = undefined;
      self.colorSchemes = undefined;
      self.backgrounds = undefined;
      self.currentLayoutType = undefined;
    }

    return {
      initialize,
      getLayout,
      getThemes,
      getLayouts,
      getFonts,
      getBackgrounds,
      getColorSchemes,
      reset
    };
  })
  .views((self) => {
    return {
      get isLoading(): boolean {
        return (
          self.loadingState === 'loading' ||
          self.loadingState === 'initializing'
        );
      },
      get isError(): boolean {
        return (
          self.loadingState === 'error' || self.loadingState === 'init_error'
        );
      },

      get isInitialized(): boolean {
        if (!self.backgrounds || !self.colorSchemes || !self.colorSchemes) {
          return false;
        }
        return true;
      },

      layoutExists(key: string, chapterType?: ChapterType): boolean {
        if (
          chapterType &&
          self.currentLayoutType &&
          chapterType !== self.currentLayoutType
        ) {
          return false;
        }
        return self.layouts?.has(key) || false;
      },
      layoutDefinition(
        key: string,
        type?: ChapterType
      ): LayoutModelType | undefined {
        if (type && type !== self.currentLayoutType) {
          return undefined;
        }
        return self.layoutDefinitions?.get(key);
      },

      font(key: string): FontModelType | undefined {
        return self.fonts?.get(key);
      },

      color(key: string | number): ColorSchemeModelType | undefined {
        return self.colorSchemes?.get(key.toString());
      },

      background(key: string | number): BackgroundModelType | undefined {
        return self.backgrounds?.get(key.toString());
      },

      get allBackgrounds(): BackgroundModelType[] {
        if (!self.backgrounds?.size) {
          return [];
        }

        const list: BackgroundModelType[] = [];

        for (const background of self.backgrounds.values()) {
          list.push(background);
        }

        list.sort(sortByName);
        return list;
      },

      get allColorSchemes(): ColorSchemeModelType[] {
        if (!self.colorSchemes?.size) {
          return [];
        }

        const list: ColorSchemeModelType[] = [];

        for (const color of self.colorSchemes.values()) {
          list.push(color);
        }

        list.sort(sortByName);
        return list;
      },
      filteredBackgrounds(themeKey?: string): BackgroundModelType[] {
        if (!self.backgrounds?.size) {
          return [];
        }

        const list = filterItems<BackgroundModelType>(
          self.backgrounds.values(),
          themeKey,
          self.displayUnindexed
        );

        list.sort(sortByName);
        return list;
      },

      get allFonts(): FontModelType[] {
        if (!self.fonts?.size) {
          return [];
        }

        const list: FontModelType[] = [];

        for (const font of self.fonts.values()) {
          list.push(font);
        }

        list.sort(sortByName);
        return list;
      },
      filteredFonts(themeKey?: string): FontModelType[] {
        if (!self.fonts?.size) {
          return [];
        }

        const list = filterItems<FontModelType>(
          self.fonts.values(),
          themeKey,
          self.displayUnindexed
        );

        list.sort(sortByName);
        return list;
      },

      get colorPreviews(): {
        id: string;
        preview: [string, string, string];
      }[] {
        if (!self.colorSchemes?.size) {
          return [];
        }

        const list: {
          id: string;
          preview: [string, string, string];
        }[] = [];

        for (const color of self.colorSchemes?.values()) {
          list.push({ id: color.id, preview: color.previewColors });
        }

        list.sort(sortByName);
        return list;
      },
      filteredColorPreviews(
        themeKey?: string
      ): {
        id: string;
        name?: string;
        preview: [string, string, string];
      }[] {
        if (!self.colorSchemes?.size) {
          return [];
        }

        const list = filterItems<ColorSchemeModelType>(
          self.colorSchemes.values(),
          themeKey,
          self.displayUnindexed
        ).map((color) => ({
          id: color.id,
          name: color.name,
          preview: color.previewColors
        }));

        list.sort(sortByName);
        return list;
      },

      get allLayouts(): LayoutModelType[] {
        if (!self.layouts?.size) {
          return [];
        }

        const list: LayoutModelType[] = [];

        for (const layout of self.layouts.values()) {
          list.push(layout);
        }

        list.sort(sortByName);
        return list;
      },
      filteredLayouts(
        themeKey?: string,
        mustIncludeTags?: string[]
      ): LayoutModelType[] {
        if (!self.layouts?.size) {
          return [];
        }

        const list = filterItems<LayoutModelType>(
          self.layouts.values(),
          themeKey,
          self.displayUnindexed,
          mustIncludeTags
        );

        list.sort(sortByName);
        return list;
      },

      get themesPresent(): boolean {
        return !!self.themes;
      },
      themeExists(key: string): boolean {
        return !!self.themes?.has(key);
      },

      get mottoThemes(): {
        motto: ThemeMottoModelType;
        themes: LayoutModelType[];
      }[] {
        if (!self.themeMottos?.size || !self.themes?.size) {
          return [];
        }

        const collection: {
          [key: string]: {
            motto: ThemeMottoModelType;
            themes: LayoutModelType[];
          };
        } = {};

        for (const motto of self.themeMottos.values()) {
          collection[motto.id] = {
            motto: createThemeMottoModel(motto)!,
            themes: []
          };
        }

        for (const theme of self.themes.values()) {
          for (const tag of theme.tags.values()) {
            if (tag.startsWith('motto:')) {
              const motto = self.themeMottos.get(tag.substring(6));

              if (!motto || !collection[motto.id]) {
                continue;
              }

              collection[motto.id].themes.push(createLayoutModel(theme)!);
            }
          }
        }

        const list = Object.values(collection);

        list.sort((a, b) => sortByName(a.motto, b.motto));
        return list;
      },
      get neutralThemes(): LayoutModelType[] {
        if (!self.themes?.size) {
          return [];
        }

        const list: LayoutModelType[] = [];

        for (const theme of self.themes.values()) {
          if (theme.tags.indexOf('neutral') < 0) {
            continue;
          }

          if (!self.displayUnindexed && theme.tags.indexOf('unindexed') > -1) {
            continue;
          }

          list.push(theme);
        }

        list.sort(sortByFieldLowerCase('id'));
        return list;
      },
      get privateThemes(): LayoutModelType[] {
        if (!self.themes?.size) {
          return [];
        }

        const list: LayoutModelType[] = [];

        for (const theme of self.themes.values()) {
          if (theme.tags.indexOf('filtered') < 0) {
            continue;
          }

          // filtered tag overrides unindexed, so do not skip unindexed here

          list.push(theme);
        }

        list.sort(sortByFieldLowerCase('id'));
        return list;
      },

      relatedThemes(themeKey?: string): LayoutModelType[] {
        if (!self.themes?.size || !themeKey) {
          return [];
        }

        const list: LayoutModelType[] = [];
        const baseKey = baseThemeKey(themeKey);

        for (const theme of self.themes.values()) {
          if (
            !self.displayUnindexed &&
            theme.tags.indexOf('unindexed') > -1 &&
            theme.tags.indexOf('filtered') < 0
          ) {
            continue;
          }

          if (baseThemeKey(theme.id) !== baseKey) {
            continue;
          }

          list.push(theme);
        }

        list.sort(sortByName);
        return list;
      }
    };
  });

export type LayoutStoreType = Instance<typeof LayoutStore>;
export default LayoutStore;
