import {
  Instance,
  types
} from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

const CityModel = types.model('CityModel', {
  id: types.identifierNumber,
  name: types.maybe(types.string),
  county: types.maybe(types.string)
});

export const createCityModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return CityModel.create({
    id: mandatoryId(data.id),
    name: data.name || undefined,
    county: data.county || undefined
  });
};

export type CityModelType = Instance<typeof CityModel>;
export default CityModel;
