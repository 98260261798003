import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface IconBlockProps {
  size?: number;
  square?: boolean;
  circleBackground?: keyof typeof COLORS;
  roundBackground?: keyof typeof COLORS;
}

const IconBlock = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg'
})<IconBlockProps>`
  display: inline-block;
  height: ${({ size }) => (size ? size + 'em' : '1em')};
  width: ${({ square, circleBackground, size }) =>
    square || circleBackground ? (size ? size + 'em' : '1em') : 'auto'};
  background-color: ${(props) =>
    props.circleBackground ? COLORS[props.circleBackground] : props.roundBackground ? COLORS[props.roundBackground] : 'transparent'};
  border-radius: ${(props) => (props.circleBackground ? '100%' : props.roundBackground ? '30%' : '0')};
  flex-shrink: 0;
`;

export default IconBlock;
