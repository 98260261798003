import React from 'react';
import { Route, Switch } from 'react-router';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import {
  ROUTE_TEXTILE_DESIGN,
  ROUTE_TEXTILE_DESIGN_BACK,
  ROUTE_TEXTILE_DESIGN_LAYOUT
} from 'utils/constants/routes';
import TextileDesignLayoutScreen from './screens/TextileDesignLayoutScreen';
import TextileDesignSettingsScreen from './screens/TextileDesignSettingScreen';
import TextileDesignSettingsBack from './screens/back/TextileDesignSettingsBack';
import TextileDesignSettingsBackCreative from './screens/back/TextileDesignSettingsBackCreative';
import TextileDesignSettingsBackFrame from './screens/back/TextileDesignSettingsBackFrame';
import TextileDesignSettingsBackHeader from './screens/back/TextileDesignSettingsBackHeader';
import TextileDesignSettingsBackOption from './screens/back/TextileDesignSettingsBackOption';
import TextileDesignThemeDetail from './screens/themes/TextileDesignThemeDetail';
import TextileDesignThemes from './screens/themes/TextileDesignThemes';

export default () => (
  <Switch>
    {/* NOTE: COLOR SELECTION removed: commit 6793e5718c27d9285956ff4802b7fe50b463cd1c */}
    <Route
      path={ROUTE_TEXTILE_DESIGN_BACK + '/design'}
      component={TextileDesignSettingsBackOption}
    />

    <Route
      path={ROUTE_TEXTILE_DESIGN_BACK + '/creative'}
      component={TextileDesignSettingsBackCreative}
    />
    <Route
      path={ROUTE_TEXTILE_DESIGN_BACK + '/header'}
      component={TextileDesignSettingsBackHeader}
    />
    <Route
      path={ROUTE_TEXTILE_DESIGN_BACK + '/frame'}
      component={TextileDesignSettingsBackFrame}
    />

    <Route
      path={ROUTE_TEXTILE_DESIGN_BACK}
      component={TextileDesignSettingsBack}
    />

    <Route
      path={ROUTE_TEXTILE_DESIGN_LAYOUT}
      component={TextileDesignLayoutScreen}
    />
    {/* <Route
      path={ROUTE_TEXTILE_DESIGN + '/theme/add'}
      component={TextileDesignCustomMottoScreen}
    /> */}
    <Route
      path={ROUTE_TEXTILE_DESIGN + '/themes'}
      component={TextileDesignThemes}
    />
    <Route
      path={ROUTE_TEXTILE_DESIGN + '/theme/:key'}
      component={TextileDesignThemeDetail}
    />

    {/* <Route
      path={ROUTE_TEXTILE_DESIGN + '/request'}
      exact={true}
      component={CustomTextileDesignScreen}
    /> */}

    <Route
      path={ROUTE_TEXTILE_DESIGN}
      component={TextileDesignSettingsScreen}
      exact={true}
    />

    <NotFoundRoute />
  </Switch>
);
