import { TextileDiscount } from 'api/textile_deals/fetchTextileDiscounts';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import ProgressbarComponent from 'components/ProgressbarComponent';
import SharingComponent from 'components/SharingComponent';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackClickLink from 'domain/Links/BackClickLink';
import { useTextileDiscountsQuery } from 'queries/textile_deals/useTextileDiscountQueries';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import AmountDiscountListItem from './components/AmountDiscountListItem';
import TextileDiscountListItem from './components/TextileDiscountListItem';
import TextileDiscountList from './components/TextileDiscountsList';

const TextileDiscountsScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const textileDiscounts = useTextileDiscountsQuery(textileOrderId);
  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);

  const accessContext = useContext(HasAccessContext)
  if (!accessContext) {
    throw  Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('discount')
  }, [accessContext])

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="Discounts possible" />}
            text={<FormattedMessage id="textile discounts info" />}
          />
          {content}
        </PageStackBlock>
      </>
    );
  }

  if (textileDiscounts.isError) {
    return renderPage(<GenericErrorComponent onRetryClick={() => textileDiscounts.refetch()} />)
  }

  if (textileOrder.isError) {
    return renderPage(<GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />)
  }


  if (textileDiscounts.isLoading || !textileDiscounts.data || textileOrder.isLoading || !textileOrder.data) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const renderDiscounts = () => {
    const discounts = textileDiscounts.data;

    if (discounts.length === 0) {
      return null;
    }

    const textileDiscountsWithoutAmountDiscounts = discounts.filter(
      (discount: TextileDiscount) =>
        discount.identifier !== 'nuggit_volume_65' &&
        discount.identifier !== 'nuggit_volume_90'
    );

    const amountDiscounts = discounts.filter(
      (discount: TextileDiscount) =>
        discount.identifier === 'nuggit_volume_65' ||
        discount.identifier === 'nuggit_volume_90'
    );

    const usedDiscounts: TextileDiscount[] = textileDiscountsWithoutAmountDiscounts.filter(
      (discount: TextileDiscount) => discount.checked
    );

    return renderPage(
      <>
        <ProgressbarComponent
          isStatic={true}
          progressStatus={
            (usedDiscounts.length /
              (textileDiscountsWithoutAmountDiscounts.length + 1)) *
            100
          }
          color="GREEN"
        />
        <TextElement>
          <FormattedMessage
            id="count textile discounts used"
            values={{
              current: usedDiscounts.length,
              final: textileDiscountsWithoutAmountDiscounts.length + 1
            }}
          />
        </TextElement>
        {/* Discount Progress  */}

        <SharingComponent
          label="Link invite"
          buttonOutside={true}
          modalHeader="invite link"
          message="Nuggit invite text"
          buttonColor="PRIMARY"
          url={textileOrder.data.invitation_url}
        />

        <Divider />

        <TextileDiscountList>
          <AmountDiscountListItem discounts={amountDiscounts} preordersAmount={textileOrder.data.preorder_amount || 0}/>
          {textileDiscountsWithoutAmountDiscounts.map(
            (discount: TextileDiscount) => {
              return (
                <TextileDiscountListItem
                  discount={discount}
                  active={usedDiscounts.includes(discount)}
                />
              );
            }
          )}
        </TextileDiscountList>
      </>
    );
  }

  return renderDiscounts();
}

export default TextileDiscountsScreen;
