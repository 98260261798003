import { flow, getEnv, Instance, types } from 'mobx-state-tree';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import TextModel, { createTextModel, TextModelType } from 'models/TextModel';
import { createMapWithTransform } from 'utils/create-map';
import { sortById } from 'utils/sort-functions';

const TextsStore = types
  .model('TextsStore', {
    // list
    loadingState: types.maybe(types.string),
    texts: types.maybe(types.map(TextModel)),
    // chapter: types.maybe(ChapterModel),
    // single item
    itemLoadingState: types.maybe(types.string),
    item: types.maybe(TextModel)
  })
  .actions((self) => {
    const getAllTexts = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.loadingState = 'loading';
        self.texts = undefined;
        // self.chapter = undefined;

        const result = yield client.getAllTexts();

        if (!Array.isArray(result) || !result.length) {
          self.loadingState = undefined;
          return;
        }

        self.texts = createMapWithTransform(result, createTextModel);

        self.loadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('TextsStore | getAllTexts', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.loadingState = undefined;
          return;
        }

        self.loadingState = 'error';
      }
    });

    const getText = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const text = yield client.getText(id);

        self.item = text;
        self.itemLoadingState = undefined;

        return text;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('TextsStore | getText', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    const getTextByChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        const result = yield client.getAllTexts(chapterId);

        let text;

        if (result.texts?.length === 1) {
          text = createTextModel({
            ...result.texts[0],
            chapter: result.chapter
          });

          self.item = text;
        }

        self.itemLoadingState = undefined;
        return text;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('TextsStore | getTextByChapter', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    const createText = flow(function* (text: TextModelType, addToList = false) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';
        self.item = undefined;

        text = yield client.createText(text);
        const model = createTextModel(text);

        if (model) {
          if (addToList) {
            self.texts?.put(text);
          }

          self.item = createTextModel(text);
        }

        self.itemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('TextsStore | createText', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    const updateText = flow(function* (text: TextModelType) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.itemLoadingState = 'loading';

        text = yield client.updateText(text);
        const model = createTextModel(text);

        if (model) {
          if (self.texts?.has(model.id.toString())) {
            self.texts.put(text);
          }

          if (self.item?.id === model.id) {
            self.item = createTextModel(text);
          }
        }

        self.itemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('TextsStore | updateText', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
        throw error;
      }
    });

    // const removeText = flow(function*(textId: number, removeFromList = true) {
    //   const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
    //   try {
    //     self.itemLoadingState = 'loading';

    //     yield client.removeText(textId);
    //   } catch (error: any) {
    //     if (process.env.NODE_ENV !== 'production') {
    //    //       console.error('TextsStore | removeText', error, error.body);
    //     }

    //     if (applicationStore.handleAppError(error)) {
    //       self.itemLoadingState = undefined;
    //       return;
    //     }

    //     // not found is not an error when removing
    //     if (!client.isNotFound(error)) {
    //       self.itemLoadingState = 'update_error';
    //       throw error;
    //     }
    //   }

    //   if (removeFromList) {
    //     self.texts.delete(textId.toString());
    //   }

    //   if (self.item && self.item.id === textId) {
    //     self.item = undefined;
    //   }
    //   self.itemLoadingState = undefined;
    // });

    const clearCurrentItem = () => {
      self.item = undefined;
      self.itemLoadingState = undefined;
    };

    const patchItem = (update: any) => {
      if (!self.item) {
        self.item = createTextModel({
          id: -1,
          ...update
        });
        return;
      }

      self.item = createTextModel({
        ...self.item,
        ...update
      });
    };

    const storeItem = flow(function* () {
      if (!self.item) {
        return;
      }

      if (self.item.id < 0) {
        return yield createText(self.item);
      }
      return yield updateText(self.item);
    });

    return {
      getAllTexts,
      getText,
      getTextByChapter,
      createText,
      updateText,
      // removeText,
      clearCurrentItem,
      patchItem,
      storeItem
    };
  })
  .views((self) => {
    return {
      get allTexts(): TextModelType[] {
        if (!self.texts) {
          return [];
        }

        const texts: TextModelType[] = [];

        for (const text of self.texts.values()) {
          texts.push(text);
        }

        texts.sort(sortById);
        return texts;
      },
      filteredTexts(text: string): TextModelType[] {
        if (!self.texts) {
          return [];
        }

        const textLowercase = text.trim().toLowerCase();

        const texts: TextModelType[] = [];
        for (const txt of self.texts.values()) {
          if (
            txt.content &&
            txt.content.toLowerCase().indexOf(textLowercase) > -1
          ) {
            texts.push(txt);
          }
        }

        texts.sort(sortById);
        return texts;
      },

      get isListLoading(): boolean {
        return self.loadingState === 'loading' ? true : false;
      },
      get isListError(): boolean {
        return self.loadingState === 'error' ? true : false;
      },

      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading' ? true : false;
      },
      get isItemError(): boolean {
        return self.itemLoadingState === 'error' ||
          self.itemLoadingState === 'update_error'
          ? true
          : false;
      }
    };
  });

export type TextsStoreType = Instance<typeof TextsStore>;
export default TextsStore;
