import React from 'react';

import style from 'styled-components';
import sanitizeHtml from 'utils/chunker/sanitize-html';

interface NamesContainerProps {
  width: number;
  font?: string;
  color?: string;
  maxHeight?: number;
  marginTop?: number;
}

interface ColumnPorps {
  fontSize?: number;
  width?: number;
}

interface ColumnHeaderProps {
  height: number;
  fontSize?: number;
  width?: number;
}

const NamesContainer = style.div<NamesContainerProps>`
  justify-content: center;
  display: flex;
  width: ${(props) => props.width}px;
  max-height: ${(props) => props.maxHeight}px;
  font-family: ${(props) => props.font};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.marginTop}px;

`;

const HeaderContainer = style.div<NamesContainerProps>`
  justify-content: center;
  display: flex;
  width: ${(props) => props.width}px;
  max-height: ${(props) => props.maxHeight}px;
  font-family: ${(props) => props.font};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.marginTop}px;

`;

const ColumnComponent = style.div<ColumnPorps>`
  width: ${(props) => props.width}px;
  line-height: 120%;
  font-size: ${(props) => props.fontSize}px;
  text-align: center;
  padding-bottom: 7px;
`;

const ColumnHeader = style.div<ColumnHeaderProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  line-height: 120%;
  font-size: ${(props) => props.fontSize}px;
  text-align: center;
  height: ${(props) => props.height}px;
  margin-bottom: 35px;
`;

const ColumnItem = style.p`
  &:not(:last-of-type) {
    padding-bottom: 7px;
  }
`;

export default ({
  namesByGroup,
  width,
  headerHeight,
  color,
  font,
  maxHeight,
  fontSize,
  headerFontSize,
  columnWidth,
  marginTop
}: {
  namesByGroup: {
    groupName?: string;
    names: string[];
    teacherNames: string[];
  }[];
  width: number;
  headerHeight: number;
  color?: string;
  font?: string;
  maxHeight?: number;
  fontSize?: number;
  headerFontSize?: number;
  columnWidth?: number;
  marginTop?: number;
}) => {
  return (
    <>
      <HeaderContainer
        font={font}
        color={color}
        maxHeight={headerHeight}
        width={width}
        marginTop={marginTop}
      >
        {namesByGroup.map((itm, idx) => {
          return (
            <ColumnComponent
              key={'column#' + idx}
              fontSize={headerFontSize}
              width={columnWidth}
            >
              <ColumnHeader
                fontSize={headerFontSize}
                width={columnWidth}
                height={headerHeight}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(itm.groupName) || ''
                }}
              />
            </ColumnComponent>
          );
        })}
      </HeaderContainer>
      <NamesContainer
        font={font}
        color={color}
        maxHeight={maxHeight}
        width={width}
      >
        {namesByGroup.map((itm, idx) => {
          return (
            <ColumnComponent
              key={'column#' + idx}
              fontSize={fontSize}
              width={columnWidth}
            >
              {itm.names.map((name, idx) => {
                return (
                  <ColumnItem
                    key={'columnItem#' + idx + name}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(name) || ''
                    }}
                  />
                );
              })}
              {itm.teacherNames.length > 0 && (
                <ColumnItem
                  key={'columnItemEmpty#' + idx}
                  dangerouslySetInnerHTML={{ __html: '&nbsp' }}
                />
              )}
              {itm.teacherNames.map((name, idx) => {
                return (
                  <ColumnItem
                    key={'columnItem#' + idx + name}
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(name) || ''
                    }}
                  />
                );
              })}
            </ColumnComponent>
          );
        })}
      </NamesContainer>
    </>
  );
};
