import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import RadioButtonComponent from 'components/Inputs/RadioButtonComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';

import SettingNotAvailableComponent from '../../SettingNotAvailableComponent';
import { SimpleSettingsDetailProps } from '../SettingsDetailProps';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';

const FactsheetPhotosSettingsContainer = ({
  form,
  chapter,
  onCommitSettings
}: SimpleSettingsDetailProps) => {
  if (!chapter.isFactsheetChapter) {
    return <SettingNotAvailableComponent />;
  }

  const count = parseInt(form.values.photo_count || '0', 10);

  return (
    <PageStackBlock>
      <PageHeader headline={<FormattedMessage id="Photos" />} text={<FormattedMessage id="photos settings info" />} />

      <FormStackBlock>
        <RadioButtonComponent
            name="photo_count"
            value="0"
            label={<FormattedMessage id="no photos" />}
            {...form.bindRadioButton('photo_count', '0')}
          />

        <RadioButtonComponent
          name="photo_count"
          value="1"
          label={<FormattedMessage id="one photo" />}
          {...form.bindRadioButton('photo_count', '1')}
        />

        <RadioButtonComponent
          name="photo_count"
          value="2"
          label={<FormattedMessage id="two photos" />}
          {...form.bindRadioButton('photo_count', '2')}
        />

        <RadioButtonComponent
          name="photo_count"
          value="3"
          label={<FormattedMessage id="three photos" />}
          {...form.bindRadioButton('photo_count', '3')}
        />


        {count > 0 && (
          <>
            <Paragraph>
              <FormattedMessage id="photos settings name info" />
            </Paragraph>

            <TextInputComponent
              name="slot1_photo_name"
              label={<FormattedMessage id="photo slot1 name" />}
              {...form.bindInput('slot1_photo_name')}
            />

            {count > 1 && (
              <TextInputComponent
                name="slot2_photo_name"
                label={<FormattedMessage id="photo slot2 name" />}
                {...form.bindInput('slot2_photo_name')}
              />
            )}

            {count > 2 && (
              <TextInputComponent
                name="slot3_photo_name"
                label={<FormattedMessage id="photo slot3 name" />}
                {...form.bindInput('slot3_photo_name')}
              />
            )}
          </>
        )}

      </FormStackBlock>

      <ButtonBlock
        background="PRIMARY"
        onClick={() =>
          onCommitSettings({
            photo_count: count,
            slot1_photo_name: form.values.slot1_photo_name || '',
            slot2_photo_name: form.values.slot2_photo_name || '',
            slot3_photo_name: form.values.slot3_photo_name || ''
          })
        }
      >
        <FormattedMessage id="Save" />
      </ButtonBlock>
    </PageStackBlock>
  );
};

export default FactsheetPhotosSettingsContainer;
