import React from 'react';
import { FormattedMessage } from 'react-intl';

import MiniCardComponent from 'components/MiniCardComponent';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import { BookModelType } from 'models/BookModel';
import Paragraph from 'components/Paragraph/Paragraph';

export const MyClassCardComponent = ({
  book,
  profilesCount,
  organizersCount,
  onboardingState
}: {
  book: BookModelType;
  profilesCount?: number;
  organizersCount?: number;
  onboardingState?: string;
}) => {
  if (book.isLockedForOrder || book.isLockedForPreview) {
    return (
      <MiniCardComponent
        mode="hint"
        icon="LOCK_OUTLINE"
        title={<FormattedMessage id="My class(short)" />}
        label={
          <FormattedMessage
            id={
              book.isLockedForPreview
                ? 'locked card preview'
                : 'locked card order'
            }
          />
        }
      />
    );
  }

  if (!onboardingState) {
    return (
      <MiniCardComponent
        mode="new"
        icon="hands"
        title={<FormattedMessage id="My class(short)" />}
        label={<FormattedMessage id="my class label new" />}
        button={<FormattedMessage id="Discover" />}
        linkTo={ROUTE_SETTINGS + '/students'}
      />
    );
  }

  const studentsCount = (profilesCount || 0) - (organizersCount || 0);
  const showInvite = studentsCount < 2;

  // TODO use other link if showInvite is true?
  return (
    <MiniCardComponent
      mode="default"
      icon="hands"
      title={<FormattedMessage id="My class(short)" />}
      button={showInvite && <FormattedMessage id="Invite" />}
      linkTo={ROUTE_SETTINGS + '/students'}
    >
      {!showInvite && (
        <>
          <HorizontalStackBlock gap="XXS">
            <Paragraph size="XS"  color="TEXT_LIGHT">
              <FormattedMessage id="Students" />
            </Paragraph>
            <Paragraph size="XS">
              {studentsCount}
            </Paragraph>
          </HorizontalStackBlock>

          <HorizontalStackBlock gap="XXS">
            <Paragraph size="XS"  color="TEXT_LIGHT">
              <FormattedMessage id="Organizers" />
            </Paragraph>
            <Paragraph size="XS">
              {organizersCount || 0}
            </Paragraph>
          </HorizontalStackBlock>
        </>
      )}
    </MiniCardComponent>
  );
};

export default MyClassCardComponent;
