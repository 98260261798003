import { intl } from 'i18n';
import React from 'react';

import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { ROUTE_SETTINGS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';

class DeleteStudentScreen extends React.Component<HistoryProps> {
  render() {
    return (
      <SupportTicketScreen
        subject="delete_student"
        backTo={ROUTE_SETTINGS + '/students'}
        organizerOnly={true}
        message={intl.formatMessage({ id: 'zap_delete_student'}, {student: idFromMatch(this.props.match)?.toString()} )}
      />
    );
  }
}

export default DeleteStudentScreen;
