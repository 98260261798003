import React from 'react';

// TODO remove fallback entirely
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ItemStackBlock from 'blocks/ItemStackBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import Divider from 'components/Divider/Divider';
import config from 'config';
import Headline from 'elements/Headline';
import NakedButton from 'elements/NakedButton';
import { inject, observer } from 'mobx-react';
import { ChapterModelType } from 'models/ChapterModel';
import { BackgroundModelType } from 'models/print/BackgroundModel';
import { LayoutStoreType } from 'models/print/LayoutStore';
import { FormattedMessage } from 'react-intl';
import { SelectionContainerComponent } from './SelectionContainerComponent';

interface ChapterBackgroundComponentProps {
  layoutStore?: LayoutStoreType;
  chapter: ChapterModelType;
  backgroundSelected: (key: string) => void;
  currentKey?: string;
}

@inject('layoutStore')
@observer
export class ChapterBackgroundComponent extends React.Component<
  ChapterBackgroundComponentProps
> {
  componentDidMount(): void {
    const { layoutStore } = this.props;

    const { backgrounds } = layoutStore!;

    if (!backgrounds) {
      this.loadBackgrounds();
    }
  }

  async loadBackgrounds() {
    const { layoutStore, chapter } = this.props;

    try {
      await layoutStore!.getBackgrounds(chapter.chapter_type);
    } catch (error: any) {}
  }

  async selectBackground(key: string) {
    const { backgroundSelected } = this.props;
    backgroundSelected(key);
  }

  filterUsableAssets(backgrounds: BackgroundModelType[]) {
    return backgrounds.filter(
      (backgrounds) =>
        !backgrounds.tags.includes('private') &&
        !backgrounds.tags.includes('unindexed')
    );
  }

  findCustomBackgrounds() {
    const { layoutStore } = this.props;

    const backgrounds = this.filterUsableAssets(layoutStore!.allBackgrounds);

    if (!backgrounds || backgrounds.length === 0) {
      return [];
    }

    return backgrounds.filter((background) =>
      background.tags.includes('onlyBookFiltered')
    );
  }

  findRecommendedBackgrounds() {
    const { layoutStore, chapter } = this.props;

    const backgrounds = this.filterUsableAssets(layoutStore!.allBackgrounds);
    const theme = chapter.book_theme_key;

    if (!backgrounds || backgrounds.length === 0) {
      return [];
    }

    return backgrounds.filter(
      (background) =>
        (background.tags.includes(`recommendTheme:${theme}`) || background.tags.includes('onlyThemeFiltered')) &&
        !background.tags.includes('onlyBookFiltered')
    );
  }

  findGlobalBackgrounds() {
    const { layoutStore, chapter } = this.props;

    const backgrounds = this.filterUsableAssets(layoutStore!.allBackgrounds);
    const theme = chapter.book_theme_key;

    if (!backgrounds || backgrounds.length === 0) {
      return [];
    }

    return backgrounds.filter(
      (background) => !background.tags.includes('onlyBookFiltered') && !background.tags.includes(`recommendTheme:${theme}`) && !background.tags.includes('onlyThemeFiltered')
    );
  }

  render() {
    const { currentKey } = this.props;
    // const selected = layoutKey || chapter.layout_key;
    const customBackgrounds: BackgroundModelType[] = this.findCustomBackgrounds();
    const recommendedBackgrounds: BackgroundModelType[] = this.findRecommendedBackgrounds();
    const globalBackgrounds: BackgroundModelType[] = this.findGlobalBackgrounds();

    return (
      <ItemStackBlock gap="L">
        <Headline.Large>
          <FormattedMessage id="choose chapter background" />
        </Headline.Large>

        <Divider />

        {customBackgrounds.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Custom assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {customBackgrounds.map((background) => (
                <NakedButton
                  key={background.id}
                  onClick={() => this.selectBackground(background.id)}
                >
                  <SelectionContainerComponent
                    selected={currentKey === background.id}
                  >
                    <img
                      src={
                        config.layout.baseUrl +
                        '/backgrounds/files/left-preview-' +
                        background.id +
                        (background.extension || '')
                      }
                      alt=""
                    />
                  </SelectionContainerComponent>
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </>
        )}

        {recommendedBackgrounds.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Recommended assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {recommendedBackgrounds.map((background) => (
                <NakedButton
                  key={background.id}
                  onClick={() => this.selectBackground(background.id)}
                >
                  <SelectionContainerComponent
                    selected={currentKey === background.id}
                  >
                    <img
                      src={
                        config.layout.baseUrl +
                        '/backgrounds/files/left-preview-' +
                        background.id +
                        (background.extension || '')
                      }
                      alt=""
                    />
                  </SelectionContainerComponent>
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </>
        )}

        {globalBackgrounds.length > 0 && (
          <>
            <Headline.Medium>
              <FormattedMessage id="Global assets" />
            </Headline.Medium>
            <TwoColumnBlock>
              {globalBackgrounds.map((background) => (
                <NakedButton
                  key={background.id}
                  onClick={() => this.selectBackground(background.id)}
                >
                  <SelectionContainerComponent
                    selected={currentKey === background.id}
                  >
                    <img
                      src={
                        config.layout.baseUrl +
                        '/backgrounds/files/left-preview-' +
                        background.id +
                        (background.extension || '')
                      }
                      alt=""
                    />
                  </SelectionContainerComponent>
                </NakedButton>
              ))}
            </TwoColumnBlock>
          </>
        )}
      </ItemStackBlock>
    );
  }
}

export default ChapterBackgroundComponent;
