import React from 'react';
import styled from 'styled-components';

import IconComponent from 'components/IconComponent';
// For some reason, TypeScript does not allow to re-export the interface
import { IconComponentProps } from 'components/IconComponent/IconComponent';

const Li = styled.li`
  position: relative;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
  padding: 0 0 0 1.625rem;
  line-height: 1.36;

  > svg {
    position: absolute;
    left: 0;
    top: 0.125rem;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

interface Props extends Pick<IconComponentProps, 'icon' | 'fill'> {
  children: React.ReactNode;
}

const IconListElement = ({ icon, fill, children }: Props) => (
  <Li>
    <IconComponent icon={icon} fill={fill} />
    {children}
  </Li>
);

export default IconListElement;
