import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import ImageBlock from 'blocks/ImageBlock';
import LinkBlock from 'blocks/LinkBlock';
import Center from 'elements/Center';
import { ROUTE_SIGNUP } from 'utils/constants/routes';

import LargeEmoji from './LargeEmoji';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';

const PublicSuccess = ({
  headingId,
  messageId
}: {
  headingId?: string;
  messageId?: string;
}) => (
  <PageStackBlock>
    <Center>
      <LargeEmoji>👌</LargeEmoji>

      <PageHeader headline={<FormattedMessage id={headingId || 'funnel public success'} />} text={<FormattedMessage id={messageId || 'funnel public success info'} />} />

      <LinkBlock to={ROUTE_SIGNUP} background="PRIMARY">
        <FormattedMessage id="Sign up" />
      </LinkBlock>
    </Center>

    <ImageBlock fullWidth={true}>
      <img src="/images/funnels/app.jpg" width="100%" alt="" />
    </ImageBlock>

    <IconListBlock>
      <IconListElement icon="TICK_CIRCLE" fill="GREEN">
        <FormattedMessage id="totally free" />
      </IconListElement>
      <IconListDivider />
      <IconListElement icon="TICK_CIRCLE" fill="GREEN">
        <FormattedMessage id="easy and fast" />
      </IconListElement>
      <IconListDivider />
      <IconListElement icon="TICK_CIRCLE" fill="GREEN">
        <FormattedMessage id="Design with app" />
      </IconListElement>
    </IconListBlock>
  </PageStackBlock>
);

export default PublicSuccess;
