import React from 'react';

import { Toc } from '../elements/TocComponent';
import TocTemplateComponent from '../templates/TocTemplateComponent';
import { CommonLayoutPreviewProps } from './CommonLayoutPreviewProps';
import scalePreview from './scale-preview';

// @ts-ignore
import tocLayout from '../templates/toc-layout.json';

interface TocPreviewComponentProps extends CommonLayoutPreviewProps {
  config?: any; // will be ignored anyway

  toc?: Toc;
}

export class TocPreviewComponent extends React.Component<
  TocPreviewComponentProps
> {
  shouldComponentUpdate() {
    // preview will be forced to redraw on config update only
    return false;
  }

  generateToc(): Toc {
    const toc: Toc = [];

    for (let i = 0; i < 10; i++) {
      toc.push({
        title: 'Kapitel ' + (i + 1),
        page: '00'
      });
    }

    return toc;
  }

  render() {
    const {
      colors,
      pageHeaderStyle,
      sectionHeaderStyle,
      background,
      start,
      scaled,
      chapter,
      toc
    } = this.props;

    return (
      <>
        <TocTemplateComponent
          print={false}
          config={tocLayout}
          colors={colors || chapter?.color_scheme?.templateColors || []}
          pageHeaderStyle={pageHeaderStyle || chapter?.font?.pageHeaderStyle}
          sectionHeaderStyle={
            sectionHeaderStyle || chapter?.font?.sectionHeaderStyle
          }
          background={
            background ||
            (chapter?.background?.id || '') +
              (chapter?.background?.extension || '') ||
            undefined
          }
          startPosition={start || 'left'}
          startPage={this.props.startPage || 1}
          render={
            !scaled ? undefined : (content, key) => scalePreview(content, key)
          }
          title={chapter?.title}
          toc={!toc?.length ? this.generateToc() : toc}
        />
      </>
    );
  }
}

export default TocPreviewComponent;
