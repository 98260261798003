import React from 'react';
import { Link } from 'react-router-dom';

export default ({
  basePath,
  active,
  children
}: {
  basePath: string;
  active?: string;
  children: any;
}) => (
  <Link
    to={{
      pathname: basePath,
      search: !active ? undefined : 'active=' + encodeURIComponent(active),
      state: {
        canGoBack: true
      }
    }}
  >
    {children}
  </Link>
);
