import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

/* z-index: OverlayBlock z-index + 1 */
export default styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgba(${COLORS.WHITE_RGB}, 1);
`;
