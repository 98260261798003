import useResizeObserver from '@react-hook/resize-observer';
import { BaseTextileDesignSetting, TextileBackground } from 'api/textile_deals/fetchTextileDesignSetting';
import { GroupNamesBack, TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import TextileBackPreviewComponent from 'components/print/layout-editor/TextileBackPreviewComponent';
import ScaledTextilePreviewComponent from 'components/print/structure/ScaledTextilePreviewComponent';
import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { ProductImageContainer } from './TextileProductImageComponent';


const PreviewContainer = styled.div`
  position: absolute;
  top: 19.55%;
  left: 23.7%;
`;

const useSize = (target: any): { width: number; height: number } => {
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0
  });

  useLayoutEffect(() => {
    if (target !== null && target.current !== null) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) =>
    setSize({
      width: entry.contentRect.width,
      height: entry.contentRect.height
    })
  );
  return size;
};

const TextileBackGeneratedPreviewComponent = ({
  top,
  bottom,
  width,
  students,
  teachers,
  settings,
  previewBackground,
  groups,
  useFakeData,
  shirt
}: {
  top: string;
  bottom: string;
  width?: string;
  students?: GroupNamesBack[];
  teachers?: GroupNamesBack[];
  settings?: BaseTextileDesignSetting;
  previewBackground?: TextileBackground | string;
  groups?: TextileGroup[];
  useFakeData?: boolean;
  shirt?: boolean;
}) => {
  if (!bottom || !top) {
    return null;
  }
  if (!settings) {
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const target = React.useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const size = useSize(target);

  if (!settings) {
    return null;
  }

  const layout = settings?.theme;
  const font = settings?.font;
  const background = settings?.background;

  if (!layout || !font || !background) {
    return null;
  }

  const previewSize = {
    width: (size.width / 100) * 52.6,
    height: (size.height / 100) * 60.9
  };


  return (
    <ProductImageContainer height={size.height.toString() + 'px'} width={width}>
      <img
        src={bottom}
        alt="vorschau bild"
        className="productImage"
        ref={target}
      />
      <PreviewContainer>
        <ScaledTextilePreviewComponent width={previewSize.width} height={previewSize.height}>
          <TextileBackPreviewComponent
            font={font}
            background={previewBackground || background}
            settings={settings}
            groups={groups}
            students={students || []}
            teachers={teachers || []}
            useFake={!!useFakeData}
          />
        </ScaledTextilePreviewComponent>
      </PreviewContainer>

      {top && <img src={top} alt="vorschau bild" className="productImage" />}
    </ProductImageContainer>
  );
};

export default TextileBackGeneratedPreviewComponent;
