import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Headline from 'elements/Headline';
import { FormType } from 'utils/hooks/useForm';
import { validYears } from 'utils/validYears';

const Year = ({ form }: { form: FormType }) => (
  <PageStackBlock>
    <Headline.Large>
      <FormattedMessage id="funnel year" />
    </Headline.Large>

    <ListStackBlock>
      {validYears().map((year) => (
        <Link
          key={year}
          to="?screen=school_type"
          onClick={() => form.setField('year', year)}
        >
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>{year}</EveryCardHeadline>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </Link>
      ))}
    </ListStackBlock>
  </PageStackBlock>
);

export default Year;
