import React, { useContext, useEffect, useState } from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import InputBlock from 'blocks/InputBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackLink from 'domain/Links/BackLink';
import Input from 'elements/Input';
import InputMessage from 'elements/InputMessage';
import { useOptimisticUpdateTextileOrderQuery, useTextileOrderForManagementQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import {
  textileOrderIdFromMatch
} from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const DeadlineFormScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const [noDeadline, setNoDealine] = useState<boolean>(false)
  const textileOrderId = textileOrderIdFromMatch(props.match)
  const accessContext = useContext(HasAccessContext)
  const textileOrder = useTextileOrderForManagementQuery(textileOrderId)
  const updateTextileOrder = useOptimisticUpdateTextileOrderQuery(textileOrderId)

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider')
  }

  useEffect(() => {
    const data = textileOrder.data
    if (data) {
      const date = data.edit_print_data_deadline ? new Date(data.edit_print_data_deadline).toISOString().substring(0, 10) : undefined
      props.form.setField('edit_print_data_deadline',
      date)
    }
    accessContext.checkSectionAccess('management')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileOrder.data])

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/management/edit_print_data')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="deadline en" />}
            text={<FormattedMessage id="deadline info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  }

  if (textileOrder.isError) {
    return renderPage(<GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />)
  }

  if (props.form.loading || textileOrder.isLoading || !textileOrder.data) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const save = () => {
    const { form, history, match } = props;

    if (form.values.edit_print_data_deadline === undefined) {
      setNoDealine(true)
      return;
    } else {
      setNoDealine(false)
    }

    if (!textileOrderId) {
      return;
    }

    form.setLoading(true);

    const patch = {...textileOrder.data, ...form.values}
    updateTextileOrder.mutate({data: patch, textileOrderId}, {
      onSuccess: () => {
        history.push(textileOrderRoute(match, '/management/edit_print_data'));
      },
      onError: (error: any) => {
      form.setLoading(false);
       handleFormError(form, error);
        return;
      }
    })
  }

  const deleteDeadline = () => {
    const { match, history, form } = props;

    if (!textileOrderId) {
      return;
    }

    form.setLoading(true);
    const patch = {...textileOrder.data, edit_print_data_deadline: null}
    updateTextileOrder.mutate({data: patch, textileOrderId}, {
      onSuccess: () => {
        history.push(textileOrderRoute(match, '/management/edit_print_data'));
      }
    })
  }

  const renderForm = () => {
    const { form } = props;

    return renderPage(
      <>
        <InputBlock>
          <Input
            type="date"
            name="edit_print_data_deadline"
            label={<FormattedMessage id="Deadline" />}
            {...form.bindInput('edit_print_data_deadline')}
            min={new Date().toISOString().substring(0, 10)}
          ></Input>
        </InputBlock>

        {noDeadline && (
          <InputMessage error={true}>
            <Paragraph>
              <FormattedMessage id="no deadline set" />
            </Paragraph>
          </InputMessage>
        )}

        <ButtonBlock background="PRIMARY" onClick={() => save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>

        {textileOrder.data.edit_print_data_deadline &&(
          <>
            <Divider />

            <DeleteButton onClick={() => deleteDeadline()}>
              <FormattedMessage id="delete deadline" />
            </DeleteButton>
          </>
        )}
      </>
    );
  }

  return renderForm()
}

export default (props: HistoryProps) => {

  const form = useForm();
  // @ts-ignore
  return <DeadlineFormScreen {...props} form={form} />;
};
