import React from 'react';
import { FormattedMessage } from 'react-intl';


import TopActionBlock from 'blocks/TopActionBlock';
import BackToContentLink from 'domain/Links/BackToContentLink';

import { HistoryProps } from 'utils/history';
import LinkBlock from 'blocks/LinkBlock';
import { ROUTE_COMMENTS } from 'utils/constants/routes';

import CommentsList from '../containers/CommentsList/CommentsList';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';

export default ({ history }: HistoryProps) => {
  return (
    <PageStackBlock>
      <TopActionBlock>
        <BackToContentLink />
      </TopActionBlock>

      <PageHeader
        headline={<FormattedMessage id="Write comments" />}
        text={<FormattedMessage id="comments info student" />}
      />

      {/* <ProfileCommentsComponent history={history} /> */}
      <LinkBlock
        background="PRIMARY"
        color="WHITE"
        to={ROUTE_COMMENTS + '/new'}
      >
        <FormattedMessage id="Add comment" />
      </LinkBlock>


      <Divider />

      <CommentsList history={history} withEditLink={true} checkChapter={true} />
    </PageStackBlock>
  );
};
