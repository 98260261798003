import {
  PageParams,
  PageParamsOverride,
  PagePosition,
  replaceVariables,
  TemplateVariables
} from './replace-variables';

// Input types

export type ContentType = 'box' | 'header' | 'floating';
export type Boundary = [number, number]; // width, height / left, top
export type Page = Boundary[];
export type Space = Page[];

export interface BoxDescription {
  type: ContentType;
  template?: string;
  templateRight?: string;
  text?: string;
  textStyle?: string;
  lineHeight?: number;
  bottomMargin?: number;
  params: PageParams;
  contentVariables?: TemplateVariables;
}

export interface Box {
  type: ContentType;
  content: string; // HTML for boxes, text for floating
  textStyle?: string; // for floating only: text style
  lineHeight?: number; // for floating only: line height
  bottomMargin?: number;
  description?: BoxDescription;
}

export interface Break {
  type: 'boundary' | 'page';
}

// Break omitted for now
export type Content = Box[];

// Output types

export type Column = {
  type: ContentType;
  sourceIndex: number;
  text?: string; // only if type is floating
  warning?: 'too_big'; // will be set if element of type box doesn't fit in available space
}[];
export type ChunkedContent = Column[];

// This chunker implementation throws errors at places where
// it should be avoidable by the developer. Those are not user errors
export class Chunker {
  private content: Content;
  private contentIndex: number = 0;

  private space: Space;
  private pageIndex: number = 0;
  private nextPageIndex: number | undefined = undefined;

  private numChunks: number = 0;

  private collectedChunkedContent: ChunkedContent = [];

  public static chunkContent(space: Space, content: Content): ChunkedContent {
    const context = new Chunker(space, content);

    return context.chunk();
  }

  private constructor(space: Space, content: Content) {
    if (content.length === 0) {
      throw new Error('Unable to chunk without content');
    }

    this.space = space;
    this.content = content;
  }

  // public interface
  private chunk(): ChunkedContent {
    if (this.nextPageIndex === undefined) {
      this.nextPageIndex = Math.min(this.pageIndex + 1, this.space.length - 1);
    }

    // Iterate through all pages
    while (this.contentIndex < this.content.length) {
      // for (const [width, height] of this.space[this.pageIndex]) {
      //   this.chunkPageSection(width, height);
      // }

      const max = this.space[this.pageIndex].length - 1;
      for (let i = 0; i <= max; i++) {
        const [width, height] = this.space[this.pageIndex][i];

        const [nextWidth, nextHeight] = this.space[this.pageIndex][i + 1]
          ? this.space[this.pageIndex][i + 1]
          : this.space[this.nextPageIndex][0];

        this.chunkPageSection(width, height, nextWidth, nextHeight);
      }

      this.pageIndex = Math.min(this.pageIndex + 1, this.space.length - 1);
      this.nextPageIndex = Math.min(this.pageIndex + 1, this.space.length - 1);
    }

    // Check whether the last column is empty and, if so, remove it
    const lastColumn = this.collectedChunkedContent.pop();
    if (lastColumn && lastColumn.length > 0) {
      this.collectedChunkedContent.push(lastColumn);
    }

    return this.collectedChunkedContent;
  }

  private chunkPageSection(
    width: number,
    height: number,
    nextWidth?: number,
    nextHeight?: number
  ) {
    const targetHeight = height;

    let totalHeight = 0;

    const column: Column = [];

    while (this.contentIndex < this.content.length) {
      const [current, next] = this.currentAndNextContent();

      if (current.type === 'box') {
        const boxHeight = measureHtmlHeight(current.content, width);

        if (totalHeight + boxHeight < targetHeight) {
          totalHeight += boxHeight;

          column.push({
            type: 'box',
            sourceIndex: this.contentIndex
          });
          this.contentIndex += 1;

          const boxMargin = current.bottomMargin || 0;
          if (totalHeight + boxMargin < targetHeight) {
            totalHeight += boxMargin;
          } else {
            break;
          }
        } else {
          if (column.length === 0) {
            // content was pushed to next column, check if we can actually place it here
            let placeInThisColumn = false;

            if (boxHeight <= targetHeight) {
              // content fits in this column
              placeInThisColumn = true;
            } else if (!nextWidth || !nextHeight) {
              // we do not know about another column so we have no other chance than to place it here
              placeInThisColumn = true;
            } else {
              // check if content fits in next column
              const boxHeightInNextColumn =
                nextWidth === width
                  ? boxHeight
                  : measureHtmlHeight(current.content, nextWidth);

              if (boxHeightInNextColumn > nextHeight) {
                // it does not fit in next column either so keep it in current column
                placeInThisColumn = true;
              }
            }

            if (placeInThisColumn) {
              column.push({
                type: 'box',
                sourceIndex: this.contentIndex,
                warning: 'too_big'
              });
              this.contentIndex += 1;
            }
          }
          break;
        }
      } else if (current.type === 'header' && next) {
        const headerHeight = measureHtmlHeight(current.content, width);

        const headerMargin = current.bottomMargin || 0;

        if (next.type === 'box') {
          const boxHeight = measureHtmlHeight(next.content, width);

          const totalBaseHeight = headerHeight + headerMargin + boxHeight;

          const boxMargin = current.bottomMargin || 0;

          if (totalHeight + totalBaseHeight < targetHeight) {
            totalHeight += totalBaseHeight;

            column.push({
              type: 'header',
              sourceIndex: this.contentIndex
            });
            this.contentIndex += 1;

            column.push({
              type: 'box',
              sourceIndex: this.contentIndex
            });
            this.contentIndex += 1;

            if (totalHeight + boxMargin < targetHeight) {
              totalHeight += boxMargin;
            } else {
              break;
            }
          } else {
            if (column.length === 0) {
              column.push({
                type: 'header',
                sourceIndex: this.contentIndex,
                warning: 'too_big'
              });
              this.contentIndex += 1;

              column.push({
                type: 'box',
                sourceIndex: this.contentIndex,
                warning: 'too_big'
              });
              this.contentIndex += 1;
            }

            break;
          }
        } else if (next.type === 'floating') {
          const firstLineHeight = measureTextHeight(
            'ABC',
            width,
            next.textStyle,
            next.lineHeight
          );

          const totalBaseHeight = headerHeight + headerMargin + firstLineHeight;

          if (totalHeight + totalBaseHeight < targetHeight) {
            totalHeight += headerHeight + headerMargin;

            column.push({
              type: 'header',
              sourceIndex: this.contentIndex
            });
            this.contentIndex += 1;
          } else {
            break;
          }
        } else {
          throw new Error(
            'Invalid box order. A header must always be followed by either a "box" or a "floating" box type'
          );
        }
      } else if (current.type === 'floating') {
        const chunkedText = preprocessText(current.content);

        const startNumChunks = this.numChunks;

        while (this.numChunks < chunkedText.length) {
          this.numChunks += 1;

          const textContent = chunkedText
            .slice(startNumChunks, this.numChunks)
            .join(' ');

          const floatingHeight = measureTextHeight(
            textContent,
            width,
            current.textStyle,
            current.lineHeight
          );

          if (totalHeight + floatingHeight < targetHeight) {
            continue;
          } else {
            this.numChunks -= 1;

            break;
          }
        }

        const partialTextContent = chunkedText
          .slice(startNumChunks, this.numChunks)
          .join(' ');

        column.push({
          type: 'floating',
          sourceIndex: this.contentIndex,
          text: partialTextContent
        });

        const floatingTextHeight = measureTextHeight(
          partialTextContent,
          width,
          current.textStyle,
          current.lineHeight
        );

        totalHeight += floatingTextHeight;

        if (this.numChunks >= chunkedText.length) {
          // All the words fit

          this.contentIndex += 1;
          this.numChunks = 0;
        } else {
          break;
        }

        // test if margin fits
        const floatingMargin = current.bottomMargin || 0;

        if (totalHeight + floatingMargin < targetHeight) {
          totalHeight += floatingMargin;
        } else {
          break;
        }
      } else {
        throw new Error('Invalid box order.');
      }
    }

    this.collectedChunkedContent.push(column);
  }

  private currentAndNextContent(): [Box, Box | undefined] {
    return [
      this.content[this.contentIndex],
      this.content[this.contentIndex + 1]
    ];
  }
}

function preprocessText(text: string): string[] {
  text = text.trimLeft();
  text = text.replace(/\r|\v|\f/g, '');
  text = text.replace(/\t/g, ' ');
  text = text.replace(/ *\n/g, '<br/> ');
  text = text.replace(/ {2,}/g, ' ');

  return text.split(' ');
}

function createMeasurementElement(width: number): HTMLDivElement {
  const fakeElement = document.createElement('div');
  fakeElement.setAttribute(
    'style',
    `position: absolute; display: block; visibility: hidden; padding-bottom: 1px; width: ${width}px`
  );

  return fakeElement;
}

function measureHtmlHeight(htmlContent: string, maxWidth: number): number {
  const fakeElement = createMeasurementElement(maxWidth);

  fakeElement.innerHTML = htmlContent;

  document.body.appendChild(fakeElement);

  const domRect = fakeElement.getBoundingClientRect();

  fakeElement.remove();

  return Math.ceil(domRect.height);
}

function stepUp(value: number, step: number) {
  const a = Math.ceil(value / step);
  return a * step;
}

export function measureTextHeight(
  textContent: string,
  maxWidth: number,
  textStyle?: string,
  lineHeight?: number
): number {
  const fakeElement = createMeasurementElement(maxWidth);

  const textElement = document.createElement('div');
  if (textStyle) {
    textElement.setAttribute('style', textStyle);
  }
  textElement.innerHTML = textContent;

  fakeElement.appendChild(textElement);

  document.body.appendChild(fakeElement);

  const domRect = fakeElement.getBoundingClientRect();

  fakeElement.remove();

  const height = Math.round(domRect.height);

  if (!lineHeight) {
    return height;
  }

  // '- 1' as first line is measured as lineHeight + 1
  // then we want to step up to next multiple of lineHeight
  return stepUp(height - 1, lineHeight);
}

export function createBox(
  description: BoxDescription,
  updatedParams: PageParamsOverride = {}
): Box {
  const newParams: PageParams = {
    ...description.params,
    ...updatedParams
  };

  const box: Box = {
    type: description.type,
    lineHeight: description.lineHeight,
    bottomMargin: description.bottomMargin,
    content: '',
    description: {
      ...description,
      params: newParams
    }
  };

  if (description.type === 'floating') {
    box.content = description.text || '';
    box.textStyle = replaceVariables(
      description.textStyle,
      newParams,
      description.contentVariables
    );
  } else {
    const template =
      newParams.position === 'right' && description.templateRight
        ? description.templateRight
        : description.template;

    box.content =
      replaceVariables(template, newParams, description.contentVariables) || '';
  }

  return box;
}

export function fillColumns(space: Space, content: Content) {
  if (!content.length || !space.length) {
    // if there is no content, create at least one dummy column
    return [[]];
  }

  const columns = Chunker.chunkContent(space, content);

  if (!columns.length) {
    // always create at least one dummy column
    return [[]];
  }

  const primaryColumnsCount = space[0].length;
  const additionalColumnsCount = !space[1]
    ? primaryColumnsCount
    : space[1].length;

  const pages: ChunkedContent[] = [];

  let first = true;
  while (columns.length) {
    const page = [];

    const max = first ? primaryColumnsCount : additionalColumnsCount;
    for (let i = 0; i < max; i++) {
      page.push(columns.shift() || []);
    }

    pages.push(page);
    first = false;
  }

  return pages;
}

export function updatePages(
  startPosition: PagePosition,
  startPage: number,
  content: Content,
  pages: ChunkedContent[],
  removeDescription = true
) {
  if (!pages.length || !content.length) {
    return;
  }

  let isRight = startPosition === 'right';
  let pageNum = startPage;

  for (const page of pages) {
    const position: PagePosition = isRight ? 'right' : 'left';

    for (const column of page) {
      for (const box of column) {
        const contentBox = content[box.sourceIndex];
        if (contentBox && contentBox.description) {
          const newBox = createBox(contentBox.description, {
            position,
            page: pageNum
          });

          if (removeDescription) {
            delete newBox.description;
          }

          content[box.sourceIndex] = newBox;
        }
      }
    }

    isRight = !isRight;
    pageNum++;
  }
}
