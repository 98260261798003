import styled from 'styled-components';

const CardInfo = styled.div`
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;

  > * + * {
    margin-top: 0.625rem;
  }
`;

export default CardInfo;
