import { types, Instance } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import ChapterModel, { createChapterModel } from './ChapterModel';

const CandidateModel = types.model('CandidateModel', {
  id: types.identifierNumber,
  chapter_id: types.maybe(types.number),
  chapter: types.maybe(ChapterModel),
  name: types.maybe(types.string)
});

export const createCandidateModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return CandidateModel.create({
    id: mandatoryId(data.id),
    chapter_id: numberOrUndefined(data.chapter_id),
    chapter: createChapterModel(data.chapter),
    name: data.name || undefined
  });
};

export type CandidateModelType = Instance<typeof CandidateModel>;
export default CandidateModel;
