import React from 'react';

import {
  Boundary,
  ChunkedContent,
  Content,
  createBox,
  fillColumns,
  updatePages
} from 'utils/chunker/ContentChunker';
import replaceVariables, {
  PageParams,
  PagePosition
} from 'utils/chunker/replace-variables';
import sanitizeHtml from 'utils/chunker/sanitize-html';

import { PrintPicture } from '../elements/PicturePrintComponent';
import NotAvailableComponent from '../processing/NotAvailableComponent';
import ColumnComponent from '../structure/ColumnComponent';
import PageComponent, {
  PAGE_SIZE_A4,
  PAGE_SIZE_PRINT
} from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import {
  CommonTemplateProps,
  pageParamsFromProps
} from './CommonTemplateProps';
import WaitForImages from './WaitForImages';
import shortenText from 'utils/shorten-text';
import {
  MultiColumnLayout,
  spaceFromConfig,
  getColumns
} from './column-layout';

// const LEFT_COLUMN_POS: Boundary = [92, 92];
// const RIGHT_COLUMN_POS: Boundary = [92, 92];
// const COLUMN_SIZE_WITHOUT_PICTURE: Boundary = [681, 1028];
// const COLUMN_SIZE_WITH_PICTURE: Boundary = [681, 517];

export interface TextTemplateConfig extends MultiColumnLayout {
  leftBackground?: string;
  rightBackground?: string;
  leftPictureBackground?: string;
  rightPictureBackground?: string;
  leftAdditionalBackground?: string;
  rightAdditionalBackground?: string;

  leftOverlay?: string;
  rightOverlay?: string;

  h1?: string;
  h1Margin?: number;
  h2?: string;
  h2Margin?: number;
  h3?: string;
  h3Margin?: number;

  textStyle?: string;
  textLineHeight?: number;
  textMargin?: number;

  hasPhoto?: boolean;
  photoSize?: Boundary;
  photoPosition?: Boundary;

  // primaryColumnSize: Boundary;
  // primaryColumnPosition: Boundary;
  // additionalColumnSize: Boundary;
  // additionalColumnPosition: Boundary;
}

export interface TextTemplateProps extends CommonTemplateProps {
  // theme
  config: TextTemplateConfig;

  // content
  text?: string;
  picture?: PrintPicture;
}

interface TextTemplateComponentState {
  content?: Content;
  pages?: ChunkedContent[];
}

export class TextTemplateComponent extends React.Component<
  TextTemplateProps,
  TextTemplateComponentState
> {
  state: TextTemplateComponentState = {
    content: undefined,
    pages: undefined
  };

  componentDidMount() {
    // TODO run placeContent again when props are changed?
    this.placeContent();
  }

  generateReplaceParams(position: PagePosition, page?: number): PageParams {
    const params = pageParamsFromProps(this.props, position, page);

    params.variables!.push(['title', sanitizeHtml(this.props.title) || '']);

    return params;
  }

  generatePrimaryBackground(): [string, string] {
    const { config, startPosition, startPage, picture } = this.props;

    const hasPicture = config.hasPhoto && !!picture;

    let background;
    if (hasPicture) {
      // check for background for text pages with photo
      // -> if this is not found next if will fall back to regular background
      background =
        startPosition === 'right' && !!config.rightPictureBackground
          ? config.rightPictureBackground
          : config.leftPictureBackground;
    }
    if (!background) {
      background =
        startPosition === 'right' && !!config.rightBackground
          ? config.rightBackground
          : config.leftBackground;
    }

    const overlay =
      startPosition === 'right' && !!config.rightOverlay
        ? config.rightOverlay
        : config.leftOverlay;

    if (!background && !overlay) {
      return ['', ''];
    }

    const params = this.generateReplaceParams(startPosition, startPage);

    return [
      replaceVariables(background, params) || '',
      replaceVariables(overlay, params) || ''
    ];
  }

  generateAdditionalBackground(
    position: PagePosition,
    page?: number
  ): [string, string] {
    const { config } = this.props;

    const background =
      position === 'right' && !!config.rightAdditionalBackground
        ? config.rightAdditionalBackground
        : config.leftAdditionalBackground;

    const overlay =
      position === 'right' && !!config.rightOverlay
        ? config.rightOverlay
        : config.leftOverlay;

    if (!background && !overlay) {
      return ['', ''];
    }

    const params = this.generateReplaceParams(position, page);

    return [
      replaceVariables(background, params) || '',
      replaceVariables(overlay, params) || ''
    ];
  }

  generateContent() {
    const { config } = this.props;

    const params = this.generateReplaceParams('left', 1);

    // sanitize text
    const textStr = (this.props.text || '').toString().trim().replace('\r', '');

    const SPLIT = /(^#{2,4}[^\n]+(\n+|$))/m;
    const CHECK = /^(#{2,4})\s+(.+)(\n|$)/m;

    // split text into headers and floatings
    const textParts = textStr.split(SPLIT);

    const generated: Content = [];

    if (!textParts.length) {
      return generated;
    }

    let headerLast = false;

    // create boxes from parts
    for (const part of textParts) {
      if (part === '' || part === '\n') {
        continue;
      }

      const header = part.match(CHECK);
      if (header) {
        // it's a header
        // TODO split long headers into header and text instead of only cropping the header?
        const headerText = shortenText(200, header[2]);

        let template = config.h3;
        let bottomMargin = config.h3Margin;
        switch (header[1]) {
          case '##':
            template = config.h1;
            bottomMargin = config.h1Margin;
            break;

          case '###':
            template = config.h2;
            bottomMargin = config.h2Margin;
            break;

          default:
        }

        if (
          generated.length &&
          generated[generated.length - 1].type === 'header'
        ) {
          // we need dummy text block between two headers
          // TODO merge multiple headers into one block to avoid page break between them?
          generated.push(
            createBox({
              type: 'box',
              template:
                '<span style="font-size: 0px; line-height: 0px;"></span>',
              bottomMargin: 0,
              params
            })
          );
        }

        generated.push(
          createBox({
            type: 'header',
            template,
            bottomMargin,
            params,
            contentVariables: [['text', sanitizeHtml(headerText) || '']]
          })
        );

        headerLast = true;
        continue;
      }

      // it's just text
      generated.push(
        createBox({
          type: 'floating',
          text: sanitizeHtml(part) || '',
          textStyle: config.textStyle,
          lineHeight: config.textLineHeight,
          bottomMargin: config.textMargin,
          params
        })
      );

      headerLast = false;
    }

    if (headerLast) {
      // add dummy block if text ends with header
      generated.push(
        createBox({
          type: 'floating',
          text: '',
          textStyle: 'font-size: 1px; line-height: 1px;',
          lineHeight: 1,
          bottomMargin: 0,
          params
        })
      );
    }

    return generated;
  }

  placeContent() {
    const { config, startPosition, startPage, onContentPlaced } = this.props;

    const isFirstPageRight = startPosition === 'right';
    const space = spaceFromConfig(config, isFirstPageRight);

    const content = this.generateContent();
    const pages = fillColumns(space, content);

    updatePages(startPosition, startPage, content, pages);

    this.setState({
      content,
      pages
    });

    if (onContentPlaced) {
      onContentPlaced(pages.length);
    }
  }

  renderPicture() {
    const { config, picture } = this.props;
    if (!config.hasPhoto || !picture) {
      return null;
    }

    if (!config.photoSize || !config.photoPosition) {
      return null;
    }

    return (
      <div
        style={{
          position: 'absolute',
          left: config.photoPosition[0] + 'px',
          top: config.photoPosition[1] + 'px'
        }}
      >
        <img
          src={picture.url}
          alt=""
          style={{
            width: config.photoSize[0] + 'px',
            height: config.photoSize[1] + 'px',
            objectFit: picture.fit ? 'contain' : undefined
          }}
        />
      </div>
    );
  }

  render() {
    const {
      config,
      print,
      startPosition,
      startPage,
      picture,
      render,
      firstPageOnly
    } = this.props;

    const { content } = this.state;
    let { pages } = this.state;
    if (!pages || !content) {
      return <NotAvailableComponent print={print} />;
    }

    const isRight = startPosition === 'right';

    if (firstPageOnly && pages.length) {
      pages = [pages[0]];
    }

    let isCurrentRight = !isRight;
    return (
      <>
        {pages.map((page: ChunkedContent, pageIdx) => {
          isCurrentRight = !isCurrentRight;
          const isAdditional = pageIdx !== 0;
          const pageNum = startPage + pageIdx;

          const [background, overlay] = isAdditional
            ? this.generateAdditionalBackground(
                isCurrentRight ? 'right' : 'left',
                pageNum
              )
            : this.generatePrimaryBackground();

          const columns = getColumns(config, isAdditional, isCurrentRight);

          const result = !columns ? null : (
            <PageComponent
              key={pageIdx}
              print={print}
              previewSize={print ? PAGE_SIZE_PRINT : PAGE_SIZE_A4}
              background={background}
              overlay={overlay}
            >
              <PageContentComponent>
                {page.map((column, columnIndex) => (
                  <ColumnComponent
                    key={'c' + columnIndex}
                    size={columns[columnIndex].size}
                    position={columns[columnIndex].position}
                  >
                    {column.map((box, boxIdx) => (
                      <div
                        key={boxIdx}
                        style={{
                          marginBottom: !content[box.sourceIndex].bottomMargin
                            ? 0
                            : content[box.sourceIndex].bottomMargin + 'px'
                        }}
                      >
                        {box.type === 'floating' ? (
                          <div
                            css={content[box.sourceIndex].textStyle}
                            dangerouslySetInnerHTML={{
                              __html: box.text || ''
                            }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: content[box.sourceIndex].content || ''
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </ColumnComponent>
                ))}

                {pageIdx === 0 && picture && this.renderPicture()}
              </PageContentComponent>
            </PageComponent>
          );

          return !render ? result : render(result, pageIdx);
        })}
        {print && <WaitForImages />}
      </>
    );
  }
}

export default TextTemplateComponent;
