import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTE_TEXTS } from 'utils/constants/routes';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import TextDetail from './TextDetail/TextDetail';
import TextEditScreen from './TextEditScreen';
import TextPhotoScreen from './TextPhotoScreen';
import TextsSettings from './TextsSettings';

export default () => (
  <Switch>
    <Route path={ROUTE_TEXTS + '/:chapterId'} exact={true} component={TextDetail} />
    <Route path={ROUTE_TEXTS + '/:chapterId/edit'} component={TextEditScreen} />
    
    <Route path={ROUTE_TEXTS + '/:chapterId/photo'} component={TextPhotoScreen} />
    <Route path={ROUTE_TEXTS + '/:chapterId/settings'} component={TextsSettings} />

    <NotFoundRoute />
  </Switch>
);
