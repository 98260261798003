import { Instance, types } from 'mobx-state-tree';

import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';

export const InvoiceModel = types.model('InvoiceModel', {
  id: types.number,
  price: types.number,
  invoice_pdf: types.maybe(types.string)
});

export const createInvoiceModel = (data: any): InvoiceModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return InvoiceModel.create({
    id: mandatoryId(data.id),
    price: numberOrUndefined(data.price) || 0,
    invoice_pdf: data.invoice_pdf || undefined
  });
};

export type InvoiceModelType = Instance<typeof InvoiceModel>;
export default InvoiceModel;
