import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SliderBlock from 'blocks/SliderBlock';
import TabBarBlock from 'blocks/TabBarBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import FontPreloadComponent from 'components/print/preview/FontPreloadComponent';
import SponsorPagePreviewComponent from 'components/print/preview/SponsorPagePreviewComponent';
import SliderButtonsComponent from 'components/SliderButtonsComponent';
import SponsorListItemComponent from 'components/SponsorListItemComponent/SponsorListItemComponent';
import ChapterPreviewComponent from 'domain/ChapterPreviewComponent';
import LockedLabel from 'domain/Label/LockedLabel';
import BackToContentLink from 'domain/Links/BackToContentLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { SponsorsStoreType } from 'screens/sponsors/SponsorsStore';
import { assert } from 'utils/assert';
import { ROUTE_SPONSOR_DETAILS, ROUTE_SPONSORS } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

import {
  ImageCard,
  ImageCardImage,
  ImageCardLabel,
  ImageCardStatus,
  LabelledImageCard
} from 'blocks/ImageCard/ImageCard';
import HelpSubject from 'components/HelpButton/HelpSubject';
import IconComponent from 'components/IconComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { SponsorPageSortingModelType } from 'models/SponsorPageSortingModel';
import { PlaceholderImage } from './placeholder_image';
import { PlaceholderSponsorPagesStoreType } from './PlaceholderSponsorPagesStore';
import reduceSponsorPageSponsor from './reduceSponsorPageSponsor';
import { SponsorPageSortingStoreType } from './SponsorPageSortingStore';
interface Props {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  sponsorPageSortingStore: SponsorPageSortingStoreType;
  sponsorsStore: SponsorsStoreType;
  placeholderSponsorPagesStore: PlaceholderSponsorPagesStoreType;
}

@inject(
  'applicationStore',
  'chaptersStore',
  'sponsorPageSortingStore',
  'placeholderSponsorPagesStore',
  'sponsorsStore'
)
@observer
class SponsorChapter extends React.Component<Props & HistoryProps> {
  componentDidMount() {
    const {
      applicationStore,
      sponsorsStore,
      location,
      history
    } = this.props;

    if (
      applicationStore.isOrganizer &&
      applicationStore.onboardFeature('sponsors', location.pathname)
    ) {
      return;
    }

    if (!sponsorsStore.isListLoading) {
      if (!sponsorsStore.sponsors || isPush(history)) {
        this.loadSponsors();
      }
    }
    if (applicationStore.isOrganizer) {
      this.loadSponsorPages();
    }

  }

  async loadSponsorPages() {
    const { sponsorPageSortingStore, placeholderSponsorPagesStore } = this.props;
    const chapterId = this.getChapterId();
    await sponsorPageSortingStore.getSponsorPageSorting(chapterId);
    await placeholderSponsorPagesStore.getPlaceholderSponsorPages();
  }

  async loadSponsors() {
    const { sponsorsStore } = this.props;
    await sponsorsStore.getAllSponsors();
  }

  async swapSponsorPageSorting(
    sponsorPage: SponsorPageSortingModelType,
    index: number,
    direction: 'left' | 'right'
  ) {
    const { applicationStore, sponsorPageSortingStore } = this.props;
    const sortedPages = sponsorPageSortingStore.allSortedPages;

    const { id } = sponsorPage;
    const swapId = sortedPages[index + (direction === 'left' ? -1 : 1)].id;

    await sponsorPageSortingStore.swapSponsorPageSorting(id, swapId);
    applicationStore.setFlashMessage(
      intl.formatMessage({ id: 'Sponsor chapters swapped flash' })
    );
  }

  getChapterId() {
    const chapterId = chapterIdFromMatch(this.props.match);
    assert(chapterId);
    return chapterId;
  }

  renderSponsorPages() {
    const {
      applicationStore: { isEditAllowed },
      sponsorPageSortingStore
    } = this.props;

    const { chapter } = sponsorPageSortingStore;
    const sortedPages = sponsorPageSortingStore.allSortedPages;
    const chapterId = this.getChapterId();

    return sortedPages ? (
      <>
        {isEditAllowed && (
          <Link to={`${ROUTE_SPONSORS}/${chapterId}/pages/new`}>
            <ButtonBlock background="PRIMARY">
              <FormattedMessage id="Add new sponsors page" />
            </ButtonBlock>
          </Link>
        )}

        {sortedPages.length === 0 && (
          <Paragraph color="TEXT_LIGHT">
            <FormattedMessage id="Empty pages" />
          </Paragraph>
        )}
        <SliderBlock>
          <FontPreloadComponent chapter={chapter}>
            {sortedPages.map((sponsorPage, index) => {
              let pagePreview;
              if (sponsorPage.placeholder_sponsor_page) {
                pagePreview = (
                  <SponsorPagePreviewComponent
                    layoutKey={'sponsor-grid-1'}
                    images={PlaceholderImage}
                  />
                );
              } else if (sponsorPage.sponsor_page) {
                pagePreview = (
                  <SponsorPagePreviewComponent
                    layoutKey={sponsorPage.sponsor_page.sponsor_layout_key}
                    images={reduceSponsorPageSponsor(sponsorPage.sponsor_page)}
                  />
                );
              }
              return (
                <div key={sponsorPage.id}>
                  {isEditAllowed ? (
                    <>
                      {sponsorPage.sponsor_page ? (
                        <Link
                          to={`${ROUTE_SPONSORS}/${chapterId}/pages/${
                            sponsorPage.sponsor_page.id
                          }`}
                        >
                          {pagePreview}
                        </Link>
                      ) : sponsorPage.placeholder_sponsor_page ? (
                        <Link
                          to={`${ROUTE_SPONSORS}/${chapterId}/placeholder_pages/${
                            sponsorPage.placeholder_sponsor_page.id
                          }`}
                        >
                          {pagePreview}
                        </Link>
                      ) : ""}
                      <SliderButtonsComponent
                        leftDisabled={index === 0}
                        rightDisabled={index === sortedPages.length - 1}
                        onClick={(_event, direction) => {
                          this.swapSponsorPageSorting(
                            sponsorPage,
                            index,
                            direction
                          );
                        }}
                      />
                    </>
                  ) : (
                    pagePreview
                  )}
                </div>
              );
            })}
          </FontPreloadComponent>
        </SliderBlock>
      </>
    ) : (
      <LoadingIndicatorComponent />
    );
  }

  renderPage(content: any) {
    const { applicationStore, sponsorPageSortingStore } = this.props;
    const { isEditAllowed } = applicationStore;
    const { chapter } = sponsorPageSortingStore;

    const isChapterEditAllowed =
      chapter &&
      isEditAllowed &&
      applicationStore.isChapterEditAllowed(chapter);

    console.log(isChapterEditAllowed, chapter)

    return (
      <>
        <TopActionBlock>
          <BackToContentLink />
        </TopActionBlock>

        <PageStackBlock>
          <ChapterPreviewComponent
            chapter={chapter}
            organizersOnly={true}
            noLayoutEditor={true}
            defaultTextId="Sponsor Chapter"
            baseRoute={ROUTE_SPONSORS}
          >
            {this.renderSponsorPages()}
          </ChapterPreviewComponent>

          {isChapterEditAllowed && chapter ? (
            <>
              <ItemStackBlock gap="XS">
                <Headline.Medium>
                  <FormattedMessage id="Ad collection" />
                </Headline.Medium>
                <Paragraph color="TEXT_LIGHT">
                  <FormattedMessage id="Upload sponsor" />
                </Paragraph>
              </ItemStackBlock>
              <ButtonBlock
                background="PRIMARY"
                onClick={() =>
                  this.props.history.push({
                    pathname: `${ROUTE_SPONSOR_DETAILS}/new`,
                    state: { id: chapter.id.toString() }
                  })
                }
              >
                <FormattedMessage id="Add sponsor" />
              </ButtonBlock>
            </>
          ) : (
            <LockedLabel id="Sponsors" />
          )}

          {content}
        </PageStackBlock>
        <HelpSubject subject="sponsors_pages" />
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadSponsors()} />
    );
  }

  renderOrganizerView() {
    const {
      applicationStore,
      sponsorsStore,
      sponsorPageSortingStore,
      placeholderSponsorPagesStore,
    } = this.props;
    const { isEditAllowed } = applicationStore;
    const { chapter } = sponsorPageSortingStore;

    const chapterId = chapter?.id;

    const sponsors = sponsorsStore.customSponsors;

    const placeholderSponsorsAmount =
      placeholderSponsorPagesStore.placeholderPages?.size || 0;

    return this.renderPage(
      <>
        <TabBarBlock.Content>
          <TwoColumnBlock gap="M">
            {sponsors.map((sponsor) => {
              const item = (
                <SponsorListItemComponent key={sponsor.id} sponsor={sponsor} />
              );
              if (isEditAllowed) {
                return (
                  <Link
                    key={sponsor.id}
                    to={{
                      pathname: `${ROUTE_SPONSOR_DETAILS}/${sponsor.id}`,
                      state: { id: chapterId || undefined }
                    }}
                  >
                    {item}
                  </Link>
                );
              }
              return item;
            })}
            {[...Array(7)].map((item, index) => {
              const placed = index < placeholderSponsorsAmount;
              return (
                <LabelledImageCard>
                  <ImageCard contain={true} dim={placed}>
                    <ImageCardImage
                      src="/images/sponsor-grids/placeholder.png"
                      alt="placeholder"
                    />
                    {placed && (
                      <ImageCardStatus>
                        <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                        <FormattedMessage id="Placed" />
                      </ImageCardStatus>
                    )}
                  </ImageCard>
                  <ImageCardLabel>nuggit Sponsor</ImageCardLabel>
                </LabelledImageCard>
              );
            })}
          </TwoColumnBlock>
        </TabBarBlock.Content>
      </>
    );
  }

  render() {
    const { applicationStore, sponsorsStore } = this.props;

    if (sponsorsStore.isListLoading) {
      return this.renderLoading();
    }

    if (sponsorsStore.isListError) {
      return this.renderError();
    }

    if (applicationStore.isOrganizer) {
      return this.renderOrganizerView();
    }

    return null;
  }
}

export default SponsorChapter;
