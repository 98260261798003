import { types, Instance } from 'mobx-state-tree';

export const RankingTypeEnum = types.enumeration([
  'profile_ranking',
  'candidate_ranking'
]);

export const createRankingTypeEnum = (type?: any) =>
  RankingTypeEnum.is(type || '') ? type : 'profile_ranking';

const RankingChapterSettingsModel = types.model('RankingChapterSettingsModel', {
  ranking_type: RankingTypeEnum
});

export const createRankingChapterSettingsModel = (
  data?: any,
  createEmptyModel: boolean = true
): RankingChapterSettingsModelType | undefined => {
  if (!data) {
    return createEmptyModel
      ? RankingChapterSettingsModel.create({
          ranking_type: 'profile_ranking'
        })
      : undefined;
  }

  return RankingChapterSettingsModel.create({
    ranking_type: createRankingTypeEnum(data?.ranking_type)
  });
};

export type RankingChapterSettingsModelType = Instance<
  typeof RankingChapterSettingsModel
>;
export default RankingChapterSettingsModel;
