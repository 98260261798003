import React from 'react';

import PageComponent, {
  PAGE_SIZE_A4,
  PAGE_SIZE_PRINT
} from '../structure/PageComponent';
import PageContentComponent from '../structure/PageContentComponent';
import { CommonTemplateProps } from './CommonTemplateProps';
import WaitForImages from './WaitForImages';
import { SchoolModelType } from 'models/SchoolModel';
import ImprintComponent from '../elements/ImprintComponent';

export interface ImprintTemplateConfig {
  leftBackground?: string;
  rightBackground?: string;
}

export interface ImprintTemplateProps extends CommonTemplateProps {
  school?: SchoolModelType;
  year?: string | number;
  bookId?: string | number;
}

export class ImprintTemplateComponent extends React.Component<
  ImprintTemplateProps
  > {
  componentDidMount() {
    // we have nothing to place but still should tell parent we're done (doing nothing)
    const { onContentPlaced } = this.props;
    onContentPlaced && onContentPlaced(1);
  }

  generateBackground() {
    return `<img src="/images/print/imprint.jpg" style="position: absolute; top: 0; bottom: 0; height: 100%; width: 100%;" />`;
  }

  render() {
    const { print, render, school, year, bookId } = this.props;

    const page = (
      <PageComponent
        key="school"
        print={print}
        previewSize={print ? PAGE_SIZE_PRINT : PAGE_SIZE_A4}
        background={this.generateBackground()}
      >
        <PageContentComponent>
          <ImprintComponent
            year={year}
            bookId={bookId}
            school={
              !school ? undefined : (
                <>
                  {school.name && (
                    <>
                      {school.name}
                      <br />
                    </>
                  )}
                  {school.address && (
                    <>
                      {school.address}
                      <br />
                    </>
                  )}
                  {school.zip_code && <>{school.zip_code} </>}
                  {school.city_name && (
                    <>
                      {school.city_name}
                      <br />
                    </>
                  )}
                  {school.country && <>{school.country}</>}
                </>
              )
            }
          />
        </PageContentComponent>
      </PageComponent>
    );

    return (
      <>
        {!render ? page : render(page, 0)}
        {print && <WaitForImages />}
      </>
    );
  }
}

export default ImprintTemplateComponent;
