import { RemoteFileModelType } from 'models/RemoteFileModel';

export default (data: any): RemoteFileModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    full: data.full || undefined,
    cropped: data.cropped || undefined,
    shrinked: data.shrinked || undefined,
    small: data.small || undefined,
    preview: data.preview || undefined,

    filename: data.filename || undefined,
    url: data.url || undefined
  };
};
