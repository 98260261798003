import { types, Instance } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

export const VoteCandidateModel = types.model('VoteCandidateModel', {
  id: types.identifierNumber,
  name: types.maybe(types.string)
});

export const createVoteCandidateModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return VoteCandidateModel.create({
    id: mandatoryId(data.id),
    name: data.name || undefined
  });
};

export default VoteCandidateModel;
export type VoteCandidateModelType = Instance<typeof VoteCandidateModel>;
