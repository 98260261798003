import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

// == Hint ==================================================================
// This component is used to display chapter preview on ChapterLayoutsScreen.
// ==========================================================================

interface ScaledChapterPreviewComponentProps {
  children?: React.ReactNode;
  border?: boolean;
  stack?: boolean;
  pageTitle?: string | React.ReactNode;
  description?: React.ReactNode;
  linkTo?: LinkProps['to'];
}

interface PageContainerProps {
  border?: boolean;
  stack?: boolean;
  pageTitle?: string | React.ReactNode;
}

const PageContainer = styled.div<PageContainerProps>`
  position: relative;
  z-index: 3;
  flex-shrink: 0;
  display: block;
  border: ${(props) =>
    props.border || props.stack ? `1px solid ${COLORS.GRAY800}` : undefined};
  width: 8rem;
  height: 11.25rem;
  font-size: 0.875em;
  line-height: 1.2;
  font-weight: 300;
  text-align: left;
  color: ${COLORS.TEXT_LIGHT};
  ${(props) =>
    props.stack &&
    `
    &:before {
      position: absolute;
      top: 0;
      content: "";
      display: block;
      border: 1px solid ${COLORS.GRAY800};
      width: 100%;
      height: 100%;
      transform: rotate(1.5deg);
    }
    &:after {
      position: absolute;
      z-index: -1;
      top: 0;
      content: "";
      display: block;
      border: 1px solid ${COLORS.GRAY800};
      width: 100%;
      height: 100%;
      transform: rotate(-1.5deg);
    }
  `};

  @media (min-width: 23.4375em) {
    width: 9.625rem;
    height: 13.5rem;
  }

  @media (min-width: 36em) {
    width: 12.5rem;
    height: 17.6rem;
  }

  @media (min-width: 50em) {
    width: 18.1rem;
    height: 25.6rem;
  }
`;

const PageWrapper = styled.div`
  flex-direction: column;
  display: flex;
`;

const PageTitle = styled.h3`
  padding: 0.375rem 0 0;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${COLORS.GRAY400};
`;

const PageCaption = styled.span`
  display: inline-block;
  max-width: 245px;
  overflow: hidden;
  line-height: 1.1em;
  text-overflow: ellipsis;

  @media (min-width: 36em) {
    max-width: 380px;
  }
`;

const PagePreview = styled.div`
  transform: scale(0.15);
  transform-origin: left top;

  @media (min-width: 23.4375em) {
    transform: scale(0.181);
  }

  @media (min-width: 36em) {
    transform: scale(0.296);
  }

  @media (min-width: 50em) {
    transform: scale(0.429);
  }

  @media (min-width: 64em) {
    transform: scale(0.445);
  }
`;

export const ScaledChapterPreviewComponent = ({
  children,
  border,
  stack,
  pageTitle,
  linkTo
}: ScaledChapterPreviewComponentProps) => {
  const content = (
    <>
      <PageContainer border={border} stack={stack} pageTitle={pageTitle}>
        <PagePreview>{children}</PagePreview>
      </PageContainer>

      {pageTitle && (
        <PageTitle>
          <PageCaption>{pageTitle}</PageCaption>
        </PageTitle>
      )}
    </>
  );

  return (
    <PageWrapper>
      {!linkTo ? content : <Link to={linkTo}>{content}</Link>}
    </PageWrapper>
  );
};

export default ScaledChapterPreviewComponent;
