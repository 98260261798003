import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  FunnelCard,
  FunnelCardLabel,
  FunnelCardTop
} from 'blocks/FunnelCard/FunnelCard';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import IconComponent from 'components/IconComponent';
import Headline from 'elements/Headline';
import { FormType } from 'utils/hooks/useForm';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

const ClothingTextile = ({ form }: { form: FormType }) => (
  <PageStackBlock>
    <Headline.Large>
      <FormattedMessage id="funnel clothing textile" />
    </Headline.Large>

    <TwoColumnBlock gap="M">
      <Link
        to="?screen=design"
        onClick={() => form.setField('textile', 't-shirts')}
      >
        <FunnelCard>
          <FunnelCardTop>
            <IconComponent
              icon="TSHIRT"
              fill="PRIMARY"
              size={5}
              circleBackground="PRIMARY_LIGHT"
            />
          </FunnelCardTop>
          <FunnelCardLabel>
            <FormattedMessage id="t-shirts" />
          </FunnelCardLabel>
        </FunnelCard>
      </Link>

      <Link
        to="?screen=design"
        onClick={() => form.setField('textile', 'hoodies')}
      >
        <FunnelCard>
          <FunnelCardTop>
            <IconComponent
              icon="HOODY"
              fill="PRIMARY"
              size={5}
              circleBackground="PRIMARY_LIGHT"
            />
          </FunnelCardTop>
          <FunnelCardLabel>
            <FormattedMessage id="hoodies" />
          </FunnelCardLabel>
        </FunnelCard>
      </Link>

      <Link
        to="?screen=design"
        onClick={() => form.setField('textile', 'both')}
      >
        <FunnelCard>
          <FunnelCardTop>
            <IconComponent
              icon="TSHIRT_AND_HOODY"
              fill="PRIMARY"
              size={6}
              circleBackground="PRIMARY_LIGHT"
            />
          </FunnelCardTop>
          <FunnelCardLabel>
            <FormattedMessage id="both" />
          </FunnelCardLabel>
        </FunnelCard>
      </Link>
    </TwoColumnBlock>
  </PageStackBlock>
);

export default ClothingTextile;
