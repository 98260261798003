import IconComponent from 'components/IconComponent';
import React from 'react';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

const TextileArrowWrapper = styled.div`
  height: 100%;
  background: ${COLORS['PRIMARY_LIGHT']};
  border-radius: 20%;
  display: flex;
  algin-items: center;
  padding: 0.2rem;
`;

// TODO: Explore needs to be added after onboarding screens for textiles are done
const TextileDashboardArrowComponent = ({
  status
}: {
  status: 'explore' | 'open' | 'locked';
}) => {
  if (status === 'explore') {
    return (
      <TextileArrowWrapper>
        <IconComponent
          icon="ARROW_DOUBLE_RIGHT"
          size={1.2}
          fill="PRIMARY"
          roundBackground="PRIMARY_LIGHT"
        />
      </TextileArrowWrapper>
    );
  }

  if (status === 'open') {
    return <IconComponent icon="ARROW_STROKE" size={1.2} fill='TEXT_LIGHT' />
  }

  if (status === 'locked') {
    return <IconComponent icon="ARROW_STROKE" size={1.2} fill="GRAY800" />;
  }

  return null;
};

export default TextileDashboardArrowComponent;
