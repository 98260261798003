import {
  IAnyModelType,
  Instance,
  types
} from 'mobx-state-tree';
import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import createRemoteFileModel from 'utils/store/createRemoteFileModel';
import mandatoryId from 'utils/store/mandatoryId';
import numberOrUndefined from 'utils/store/numberOrUndefined';
import stringOrUndefined from 'utils/store/stringOrUndefined';

import ChapterModel, { createChapterModel } from './ChapterModel';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';
import RemoteFileModel from './RemoteFileModel';

export const PhotoTypeEnum = types.enumeration([
  'album',
  'text',
  'yearbook',
  // Profile
  'slot1',
  'slot2',
  'slot3',
  'creative_page',
  // Cover
  'cover_front',
  'cover_back',
  'cover_front_creative',
  'cover_back_creative',
  'textile_back_creative'
]);

export type PhotoType = Instance<typeof PhotoTypeEnum>;

const PhotoModel = types
  .model('PhotoModel', {
    id: types.identifierNumber,
    chapter_id: types.maybe(types.number),
    chapter: types.maybe(types.late((): IAnyModelType => ChapterModel)),
    photo: types.maybe(RemoteFileModel),
    author: types.maybe(ProfileAsAuthorModel),
    parent_id: types.maybe(types.number),
    photo_type: types.maybe(PhotoTypeEnum),
    width: types.maybe(types.number),
    height: types.maybe(types.number),
    rotation: types.maybe(types.number),
    offsetX: types.maybe(types.number),
    offsetY: types.maybe(types.number),
    fitToFrame: types.maybe(types.boolean),
    caption: types.maybe(types.string),
    placed_in_album: types.maybe(types.boolean)
  })
  .views((self) => {
    return {
      get printPicture() {
        if (!self.photo?.preview) {
          return undefined;
        }

        return {
          url: self.photo.preview,
          fit: !!self.fitToFrame,
          caption: self.caption
        };
      },
      get exportedPrintPicture() {
        if (!self.photo) {
          return undefined;
        }

        const url = !self.fitToFrame ? self.photo.cropped : self.photo.full;
        if (!url) {
          return undefined;
        }

        return {
          url,
          fit: !!self.fitToFrame,
          caption: self.caption
        };
      }
    };
  });

export const createPhotoModel = (data?: any): PhotoModelType | undefined => {
  if (!data) {
    return undefined;
  }

  let offsetY: number | undefined;
  let offsetX: number | undefined;
  if (data.image_config?.cutOff) {
    if (
      data.image_config.cutOff.top !== undefined &&
      data.image_config.cutOff.top !== null
    ) {
      offsetY = parseFloat(data.image_config.cutOff.top) * 100;
    }

    if (
      data.image_config.cutOff.left !== undefined &&
      data.image_config.cutOff.left !== null
    ) {
      offsetX = parseFloat(data.image_config.cutOff.left) * 100;
    }
  }

  return PhotoModel.create({
    id: mandatoryId(data.id),
    chapter_id:
      data.parent_type === 'chapter'
        ? numberOrUndefined(data.parent_id)
        : undefined,
    chapter: createChapterModel(data.chapter),
    photo: createRemoteFileModel(data.photo) || undefined,
    author: createProfileAsAuthorModel(data.author),
    parent_id: numberOrUndefined(data.parent_id),
    photo_type: PhotoTypeEnum.is(data.photo_type) ? data.photo_type : undefined,
    width: data.metadata?.width || 0,
    height: data.metadata?.height || 0,
    offsetX,
    offsetY,
    // TODO fix server to return proper types here?
    rotation: parseInt(data.image_config?.rotation, 10) || 0,
    fitToFrame:
      data.image_config &&
      (data.image_config.crop === 'false' || data.image_config.crop === false)
        ? true
        : false,
    caption: stringOrUndefined(data.caption),
    placed_in_album: booleanOrUndefined(data.placed_in_album)
  });
};

interface CutOff {
  width: number | null;
  height: number | null;
  top: number | null;
  left: number | null;
}

export interface ImageConfig {
  cutOff: CutOff;
  rotation: number | null;
  crop: boolean | null;
}

export type PhotoModelType = Instance<typeof PhotoModel>;
export default PhotoModel;
