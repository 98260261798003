import React from 'react';
import { FormattedMessage } from 'react-intl';

import TextInputComponent from 'components/Inputs/TextInputComponent';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { FormType } from 'utils/hooks/useForm';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';

interface CheckoutAddressProps {
  form: FormType;
}

const BillingAddress = ({ form }: CheckoutAddressProps) => {
  form.values.billing_address_set = true;

  return (
    <>
      <Headline.Large>
        <FormattedMessage id="Checkout billing address" />
      </Headline.Large>

      <FormStackBlock>
        <TextInputComponent
          name="billing_name"
          label={<FormattedMessage id="Checkout recipient name" />}
          autoComplete="name"
          {...form.bindInput('billing_name')}
          placeholder="Max Mustermann"
        />

        <TextInputComponent
          name="billing_address_extra"
          label={<FormattedMessage id="address extra" />}
          autoComplete="address-line1"
          {...form.bindInput('billing_address_extra')}
          placeholder="c/o Firma"
        />

        <TextInputComponent
          name="billing_street"
          label={<FormattedMessage id="street" />}
          autoComplete="street-address"
          {...form.bindInput('billing_street')}
          placeholder="Musterstraße"
        />

        <TextInputComponent
          name="billing_housenumber"
          label={<FormattedMessage id="house number" />}
          {...form.bindInput('billing_housenumber')}
          placeholder="1,2,3,..."
        />

        <TextInputComponent
          type="number"
          name="billing_zip"
          minLength={5}
          maxLength={5}
          label={<FormattedMessage id="zip" />}
          autoComplete="postal-code"
          {...form.bindInput('billing_zip')}
          placeholder="12345"
        />

        <TextInputComponent
          name="billing_city"
          label={<FormattedMessage id="city" />}
          {...form.bindInput('billing_city')}
          placeholder="Musterstadt"
        />

        <TextInputComponent
          name="country billing"
          label={<FormattedMessage id="country" />}
          value={intl.formatMessage({ id: 'Germany' })}
          readOnly={true}
          disabled={true}
        />
      </FormStackBlock>
    </>
  );
};
export default BillingAddress;
