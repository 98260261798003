import { Instance, types } from 'mobx-state-tree';

const AuthStateModel = types.model('AuthStateModel', {
  loading: types.maybe(
    types.enumeration(['login', 'check', 'refresh', 'logout'])
  ),
  error: types.maybe(
    types.enumeration([
      'login_failed',
      'login_error',
      'check_error',
      'logout_error',
      'refresh_error',
      'profile_unconfirmed',
      'token_invalid',
      'reset_error'
    ])
  ),
  tokenRequested: types.maybe(
    types.enumeration(['verify', 'password', 'login'])
  ),
  mobileNumber: types.maybe(types.string),
  passwordRequired: types.maybe(types.boolean),
  passwordReset: types.maybe(types.boolean)
});

export type AuthStateModelType = Instance<typeof AuthStateModel>;
export default AuthStateModel;
