import React from 'react';
import replaceTextileVariables from 'utils/chunker/replace-textile-variables';


import TextileFrontComponent from '../structure/TextileFrontComponent';
import WaitForImagesTextiles from './WaitForImagesTextiles';

export interface TextileFrontTemplateConfig {
  front?: string;
}

export interface TextileFrontTemplateProps {
  front?: string;
  print?: boolean;
}

export class TextileFrontTemplateComponent extends React.Component<
  TextileFrontTemplateProps
> {
  render() {
    const {
      front
    } = this.props;


    if (!front) {
      return null;
    }

    const background = replaceTextileVariables(front);

    if (!background) {
      return null;
    }

    return (
      <>
        <TextileFrontComponent background={background}/>

        {<WaitForImagesTextiles />}
      </>
    );
  }
}

export default TextileFrontTemplateComponent;
