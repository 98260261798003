import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import IconComponent from 'components/IconComponent';

type DeleteButtonProps = Omit<
  Parameters<typeof ButtonBlock>[0],
  'as' | 'background' | 'color'
> & { messageId?: string };

export const DeleteButton = (props: DeleteButtonProps) => (
  <ButtonBlock
    {...props}
    inline={true}
    slim={true}
    background="RED_LIGHT"
    color="RED"
  >
    <IconComponent icon="TRASH" fill="RED" />{' '}
    <FormattedMessage id={props.messageId || 'Remove'} />
  </ButtonBlock>
);

export default DeleteButton;
