import React, { Component } from 'react';

import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import { observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { BookPriceStoreType } from 'models/BookPriceStore';
import { assert } from 'utils/assert';

import { CheckoutStoreType } from '../CheckoutStore';
import { CheckoutBookPrice } from './CheckoutBookPrice';

interface Props {
  applicationStore: ApplicationStoreType;
  checkoutStore: CheckoutStoreType;
  bookPriceStore: BookPriceStoreType;
}

@observer
export default class CheckoutCalculatePrice extends Component<Props> {
  componentDidMount() {
    this.getPrice();
  }

  getPrice() {
    const { applicationStore, checkoutStore, bookPriceStore } = this.props;
    const { book } = applicationStore;
    const { checkout } = checkoutStore;

    assert(book);
    assert(checkout);
    assert(checkout.pieces);
    assert(checkout.binding_type);
    assert(book.price_tier);

    const priceTier = book.price_tier === 'default' ? 'silver' : book.price_tier
    const page_count = book.checkoutPdfPagesCount

    assert(page_count)

    bookPriceStore.calculateBookPrice(
      page_count,
      checkout.pieces,
      checkout.binding_type,
      priceTier
    );
  }

  render() {
    const { checkoutStore, bookPriceStore, applicationStore } = this.props;
    const { checkout } = checkoutStore;
    const { book } = applicationStore
    const { bookPrice, loadingState } = bookPriceStore;

    assert(checkout);
    assert(book);

    const priceTier = book.price_tier === 'default' || !book.price_tier ? 'silver' : book.price_tier === 'platinum_light' ? 'gold' : book.price_tier

    if (loadingState === 'loading') {
      return <LoadingIndicatorComponent />;
    }

    if (loadingState === 'error' || !bookPrice) {
      return <GenericErrorComponent onRetryClick={() => this.getPrice()} />;
    }

    assert(checkout.binding_type);

    let endPrice: number | undefined;
    let price: number | undefined;

    if (checkout.binding_type === 'softcover') {
      endPrice = bookPrice.price[priceTier as keyof typeof bookPrice.price].softcover.end_price;
      price = bookPrice.price[priceTier as keyof typeof bookPrice.price].softcover.price;
    } else {
      if (bookPrice.price[priceTier as keyof typeof bookPrice.price].hardcover) {
        endPrice = bookPrice.price[priceTier as keyof typeof bookPrice.price].hardcover!.end_price;
        price = bookPrice.price[priceTier as keyof typeof bookPrice.price].hardcover!.price;
      }
    }

    if (!endPrice || !price) {
      return <GenericErrorComponent />;
    }

    const discount = price - endPrice;

    return <CheckoutBookPrice discount={discount} endPrice={endPrice} />;
  }
}
