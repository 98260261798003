import React from 'react';

import ItemStackBlock from 'blocks/ItemStackBlock';
import Headline from 'elements/Headline';
import { CheckoutModelType } from 'models/CheckoutModel';
import { FormattedMessage } from 'react-intl';
import Divider from 'components/Divider/Divider';
import TextElement from 'components/TextElement/TextElement';
import Paragraph from 'components/Paragraph/Paragraph';
import CheckoutLink from 'components/CheckoutLink/CheckoutLink';

type addressType = 'address' | 'billing';

const CheckoutAddress = ({
  checkout,
  handleEdit
}: {
  checkout: CheckoutModelType;
  handleEdit?: (addressType: addressType) => void;
}) => (
  <>
    <Headline.Medium>
      <FormattedMessage id="Checkout shipping address" />
    </Headline.Medium>

    <ItemStackBlock gap="XXS">
      <TextElement size="L" color="TEXT_DARK">{checkout.shipping_name}</TextElement>
      {checkout.shipping_address_extra && (
        <TextElement size="L" color="TEXT_DARK">{checkout.shipping_address_extra}</TextElement>
      )}
      <TextElement size="L" color="TEXT_DARK">
        {checkout.shipping_street} {checkout.shipping_housenumber}
      </TextElement>
      <TextElement size="L" color="TEXT_DARK">
        {checkout.shipping_zip} {checkout.shipping_city}
      </TextElement>
    </ItemStackBlock>

    {handleEdit && (
      <CheckoutLink text="Checkout edit shipping address" onClick={() => handleEdit('address')} />
    )}


    <Divider />

    <Headline.Medium>
      <FormattedMessage id="Checkout billing address" />
    </Headline.Medium>

    {checkout.billing_address_set ? (
      <>
        <ItemStackBlock gap="XXS">
          <TextElement size="L" color="TEXT_DARK">{checkout.billing_name}</TextElement>
          {checkout.billing_address_extra && (
            <TextElement size="L" color="TEXT_DARK">{checkout.billing_address_extra}</TextElement>
          )}
          <TextElement size="L" color="TEXT_DARK">
            {checkout.billing_street} {checkout.billing_housenumber}
          </TextElement>
          <TextElement size="L" color="TEXT_DARK">
            {checkout.billing_zip} {checkout.billing_city}
          </TextElement>
        </ItemStackBlock>

        {handleEdit && (
          <CheckoutLink text="Checkout edit billing address" onClick={() => handleEdit('billing')} />
        )}
      </>
    ) : (
      <>
        <Paragraph size="L">
          <FormattedMessage id="Checkout billing same as shipping" />
        </Paragraph>
        {handleEdit && (
          <CheckoutLink text="Checkout add billing address" onClick={() => handleEdit('billing')} />
        )}
      </>
    )}
  </>
);

export default CheckoutAddress;
