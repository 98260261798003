import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';


import IconComponent from 'components/IconComponent';
import TextElement from 'components/TextElement/TextElement';

export const BackToTextileDashboardLink = ({link}: {link: string}) => (
  <Link to={link}>
    <TextElement  color="GRAY10">
      <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
      <FormattedMessage id="Back to dashboard" />
    </TextElement>
  </Link>
);

export default BackToTextileDashboardLink;
