import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import CardBlock from 'blocks/CardBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import SharingComponent from 'components/SharingComponent';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_MOTTOS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

import MottosList from '../container/MottosList/MottosList';
import MottosManageListItem from '../container/MottosList/MottosManageListItem';
import { MottosStoreType } from '../MottosStore';
import SelectedMottoScreen from './SelectedMottoScreen';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import PageHeader from 'components/PageHeader/PageHeader';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import TextElement from 'components/TextElement/TextElement';

interface MottosRankingScreenProps {
  applicationStore: ApplicationStoreType;
  mottosStore: MottosStoreType;
}

interface MottosRankingScreenState {
  votingEnableLoadingState?: 'loading' | 'error';
}

@inject('applicationStore', 'mottosStore')
@observer
class MottosRankingScreen extends React.Component<
MottosRankingScreenProps & HistoryProps,
MottosRankingScreenState
> {
  state: MottosRankingScreenState = {};

  componentDidMount() {
    const { applicationStore } = this.props;

    if (!applicationStore.isOrganizer) {
      this.props.history.replace(ROUTE_MOTTOS);
      return;
    }

    if (applicationStore.onboardFeature('motto')) {
      return;
    }

    this.loadBook();
  }

  private loadBook() {
    this.props.applicationStore.getBook();
  }

  private async enableVoting() {
    const { applicationStore } = this.props;

    this.setState({
      votingEnableLoadingState: 'loading'
    });

    try {
      await applicationStore.setBookEnableMottoVote(true);

      this.setState({
        votingEnableLoadingState: undefined
      });
    } catch (error: any) {
      this.setState({
        votingEnableLoadingState: 'error'
      });
    }
  }

  private renderPage(content?: any) {
    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  private renderSetup() {
    const { votingEnableLoadingState } = this.state;

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Your(plural) motto" />} text={<FormattedMessage id="motto setup text" />} />

        {votingEnableLoadingState === 'error' && <GenericErrorComponent />}

        <ListStackBlock>
          <CardBlock as="button" onClick={() => this.enableVoting()}>
            <PaddingBlock>
              <TextElement  color="TEXT_DARK">
                <FormattedMessage id="Begin motto voting" />
              </TextElement>
            </PaddingBlock>
          </CardBlock>

          <Link to={ROUTE_MOTTOS + '/new?select=yes&setup=yes'}>
            <CardBlock>
              <PaddingBlock>
                <TextElement  color="TEXT_DARK">
                  <FormattedMessage id="Set motto" />
                </TextElement>
              </PaddingBlock>
            </CardBlock>
          </Link>
        </ListStackBlock>
      </>
    );
  }

  private renderBookError() {
    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Your(plural) motto" />
        </Headline.Large>

        <GenericErrorComponent onRetryClick={() => this.loadBook()} />
      </>
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const { applicationStore, mottosStore } = this.props;

    if (!applicationStore.isOrganizer) {
      return null;
    }

    if (
      applicationStore.isBookLoading ||
      this.state.votingEnableLoadingState === 'loading'
    ) {
      return this.renderLoading();
    }

    if (applicationStore.isBookError) {
      return this.renderBookError();
    }

    if (applicationStore.hasBookMotto) {
      return <SelectedMottoScreen />;
    }

    if (!applicationStore.isBookMottoVotingEnabled) {
      return this.renderSetup();
    }

    // const votesCount = mottosStore.votesCount;
    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="Motto voting" />} text={applicationStore.book?.invitation_url && <FormattedMessage id="motto invite info" />} />

        {applicationStore.book?.invitation_url && (
          <>
            <SharingComponent
              label="Link invite"
              buttonOutside={false}
              modalHeader="invite link"
              message="Nuggit invite text"
              buttonColor="WHATSAPP"
              url={applicationStore.book.invitation_url}
            />

            <Divider />

            <ItemStackBlock gap="XXS">
              <Headline.Medium>
                <FormattedMessage id="Result" />
              </Headline.Medium>

              <Paragraph  color="TEXT_LIGHT">
                <FormattedMessage id="motto result info" />
              </Paragraph>
            </ItemStackBlock>
          </>
        )}

        <ListStackBlock>
          <LinkBlock
            to={ROUTE_MOTTOS + '/select'}
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
          >
            <FormattedMessage id="Set motto" />
          </LinkBlock>

          <LinkBlock
            to={ROUTE_MOTTOS + '/new'}
            background="PRIMARY"
            color="WHITE"
          >
            <FormattedMessage id="mottos add proposal" />
          </LinkBlock>

          <Link to={ROUTE_MOTTOS + '/ideas'}>
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage id="motto ideas" />
                  </EveryCardHeadline>
                  <TextElement>
                    <FormattedMessage id="motto ideas info" />
                  </TextElement>
                </EveryCardBody>
                <IconComponent icon="LIGHTBULB" fill="BLACK" size={2} />
              </EveryCardPadding>
            </EveryCard>
          </Link>
        </ListStackBlock>

        <Divider />

        <Link to={ROUTE_MOTTOS}>
          <EveryCard>
            <EveryCardPadding>
              <EveryCardBody>
                <EveryCardHeadline>
                  <FormattedMessage id="Your vote" />
                </EveryCardHeadline>
                <TextElement>
                  {mottosStore.hasOwnVote ? (
                    <>
                      <IconComponent icon="TICK_CIRCLE" fill="GREEN" />{' '}
                      <FormattedMessage id="Already voted" />
                    </>
                  ) : (
                    <>
                      <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />{' '}
                      <FormattedMessage id="Not voted yet" />
                    </>
                  )}
                </TextElement>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        </Link>

        <ListStackBlock>
          <MottosList ranked={true} itemComponent={MottosManageListItem} />
        </ListStackBlock>
      </>
    );
  }
}

export default MottosRankingScreen;
