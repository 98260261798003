import TextElement from 'components/TextElement/TextElement';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const LockedLabel = ({ id }: { id: string }) => (
  <TextElement  color="TEXT_LIGHT">
    <FormattedMessage
      id="locked item info"
      values={{
        item: <FormattedMessage id={id} />
      }}
    />
  </TextElement>
);

export default LockedLabel;
