import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import OrganizerConditional from 'domain/Conditionals/OrganizerConditional';
import { ProfileModelType } from 'models/ProfileModel';
import { RankingModelType } from 'models/RankingModel';
import ROLES from 'utils/constants/roles';
import { ROUTE_RANKINGS } from 'utils/constants/routes';
import RankingOwnVoteComponent from '../RankingOwnVoteComponent';
import WordWrap from 'elements/WordWrap';
import {
  EveryCard,
  EveryCardPadding,
  EveryCardBody,
  EveryCardHeadline
} from 'blocks/EveryCard/EveryCard';
import TextElement from 'components/TextElement/TextElement';
import TagBlock from 'blocks/TagBlock';

export const RankingsListItem = ({
  ranking,
  locked,
  currentUser
}: {
  ranking: RankingModelType;
  locked?: boolean;
  currentUser?: ProfileModelType;
}) => {
  const isOrganizer =
    currentUser &&
    (currentUser.role === ROLES.ORGANIZER ||
      currentUser.role === ROLES.MANAGER);
  const isLocked = locked && !isOrganizer;

  const showUrl = isOrganizer ? '/show' : '';

  const count = !ranking.votes_count
    ? 0
    : ranking.num_candidates
      ? Math.floor(ranking.votes_count / ranking.num_candidates)
      : ranking.votes_count;

  const item = (
    <EveryCard topAlign={true}>
      <EveryCardPadding>
        <EveryCardBody>
          <EveryCardHeadline>
            <WordWrap>{ranking.name}</WordWrap>
          </EveryCardHeadline>

          <OrganizerConditional profile={currentUser}>
            <TextElement>
              <FormattedMessage
                id="People voted"
                values={{
                  count
                }}
              />
            </TextElement>
          </OrganizerConditional>

          <TextElement>
            <RankingOwnVoteComponent ranking={ranking} />
          </TextElement>
        </EveryCardBody>

        {ranking.num_candidates === 2 && (
          <TagBlock color="PRIMARY" backgroundColor="PRIMARY_LIGHT">
            <FormattedMessage id="Duo" />
          </TagBlock>
        )}
      </EveryCardPadding>
    </EveryCard>
  );

  if (isLocked) {
    return item;
  }

  return (
    <Link
      to={
        ROUTE_RANKINGS + '/' + ranking.chapter_id + '/' + ranking.id + showUrl
      }
    >
      {item}
    </Link>
  );
};

export default RankingsListItem;
