import React from 'react';
import { FormattedMessage } from 'react-intl';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import TextElement from 'components/TextElement/TextElement';
import config from 'config';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

const SPACE_BOTTOM = 8.75;

interface FooterProps {
  noMarginBottom?: boolean;
}

const FooterContainer = styled.div<FooterProps>`
  margin: 0;
  margin-bottom: calc(-${SPACE_BOTTOM}em + 3em);
  margin-top: ${(props) => (props.noMarginBottom ? `-4rem` : 0)};
  padding: 0;

  a {
    color: ${COLORS.TEXT_LIGHT};
  }
`;

export default ({ noMarginBottom }: { noMarginBottom?: true }) => (
  <FooterContainer noMarginBottom={noMarginBottom}>
    <HorizontalStackBlock gap="L" wrap={true}>
      <a href={config.imprintUrl} target="_blank" rel="noopener noreferrer">
        <TextElement color="GRAY400" weight="NORMAL">
          <FormattedMessage id="Imprint" />
        </TextElement>
      </a>

      <Link to={config.tosUrl} target="_blank" rel="noopener noreferrer">
        <TextElement color="GRAY400" weight="NORMAL">
          <FormattedMessage id="TOS" />
        </TextElement>
      </Link>

      <a href={config.privacyUrl} target="_blank" rel="noopener noreferrer">
        <TextElement color="GRAY400" weight="NORMAL">
          <FormattedMessage id="privacy short" />
        </TextElement>
      </a>
    </HorizontalStackBlock>
  </FooterContainer>
);
