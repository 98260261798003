import React from 'react';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

const Divider = styled.div`
  margin: 1.25rem;
  border-bottom: 1px solid ${COLORS.GRAY900};
`;

const IconListDivider = () => <Divider />;

export default IconListDivider;
