import React, { useContext, useEffect } from 'react';

import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ItemSortingComponent from 'components/ItemSortingComponent/ItemSortingComponent';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

import { TextileGroup } from 'api/textile_deals/fetchTextileGroups';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import { HasAccessContext } from 'contexts/HasAccessContext';
import { useMoveTextileGroup, useTextileGroupsQuery } from 'queries/textile_deals/useTextileGroupsQueries';
import { sortByField } from 'utils/sort-functions';

const TextileGroupsSorting: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const textileGroups = useTextileGroupsQuery(textileOrderId);
  const movetextileGroups = useMoveTextileGroup(textileOrderId);

  const accessContext = useContext(HasAccessContext);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('design');
  }, [accessContext]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink
            messageId="Save & exit"
            to={textileOrderRoute(props.match, '/groups')}
          />
        </TopActionBlock>
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Sort groups" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textileGroups.isError) {
    return (
      renderPage(<GenericErrorComponent onRetryClick={() => textileGroups.refetch()} />)
    );
  }

  if (textileGroups.isLoading || !textileGroups.data) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const moveItemTextileGroup = (
    oldIndex: number,
    newIndex: number,
    padding: number = 0
  ): TextileGroup[] => {
    const sortedItems = textileGroups.data.slice().sort(sortByField('sorting'))

    const [movedItem] = sortedItems.splice(oldIndex, 1);
    sortedItems.splice(newIndex, 0, movedItem);

    // Set sorting to the item’s index
    sortedItems.forEach((item, index) => {
      // @ts-ignore
      item.sorting = index + padding;
    });

    return sortedItems
  };


  const onSortEnd = (oldIndex: number, newIndex: number) => {
    if (!textileOrderId) {
      return;
    }

    movetextileGroups.mutate({groups: moveItemTextileGroup(oldIndex, newIndex), textileOrderId});
  }

  return renderPage(
    <ItemSortingComponent
      items={textileGroups.data.map((group) => ({
        id: group.id,
        text: group.name || ''
      }))}
      onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex)}
      text="Item sorting info textile groups"
    />
  );
};

export default TextileGroupsSorting;
