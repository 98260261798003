import { Instance, types } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';
import SponsorPageSponsorModel, {
  createSponsorPageSponsor
} from './SponsorPageSponsorModel';

const SponsorPageModel = types.model('SponsorPageModel', {
  id: types.identifierNumber,
  chapter_id: types.number,
  sponsor_layout_key: types.string,
  sponsor_page_sponsors: types.array(SponsorPageSponsorModel),
  page_sorting: types.maybe(types.number)
});

export const createSponsorPage = (data: {
  id?: number;
  chapter_id: number;
  sponsor_layout_key: string;
  sponsor_page_sponsors: any;
}) =>
  SponsorPageModel.create({
    id: mandatoryId(data.id),
    chapter_id: data.chapter_id,
    sponsor_layout_key: data.sponsor_layout_key,
    sponsor_page_sponsors:
      data.sponsor_page_sponsors !== undefined
        ? data.sponsor_page_sponsors.map(createSponsorPageSponsor)
        : []
  });

export type SponsorPageModelType = Instance<typeof SponsorPageModel>;
export default SponsorPageModel;
