import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Route } from 'react-router-dom';

import HeaderComponent from 'components/HeaderComponent/HeaderComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_DASHBOARD, ROUTE_HOME } from 'utils/constants/routes';

interface NotFoundScreenProps {
  applicationStore?: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class NotFoundScreen extends React.Component<NotFoundScreenProps> {
  render() {
    const { applicationStore } = this.props;
    const isLoggedIn = !!applicationStore!.currentUser;

    return (
      <>
        <HeaderComponent noAccount={!isLoggedIn} linkHome={'/'} />
        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Not found" />} text={<FormattedMessage id="not found text" />} />
          
          <Paragraph>
            {isLoggedIn ? (
              <Link to={ROUTE_DASHBOARD}>
                <FormattedMessage id="Back to dashboard" />
              </Link>
            ) : (
              <Link to={ROUTE_HOME}>
                <FormattedMessage id="Back to home page" />
              </Link>
            )}
          </Paragraph>
        </PageStackBlock>


      </>
    );
  }
}

export default NotFoundScreen;

export const NotFoundRoute = () => <Route component={NotFoundScreen} />;
