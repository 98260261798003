import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import TabBarBlock from 'blocks/TabBarBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import AddGroupButtonComponent from 'domain/GroupsList/AddGroupButtonComponent';
import GroupsList from 'domain/GroupsList/GroupsList';
import BackLink from 'domain/Links/BackLink';
import BackToDashboardLink from 'domain/Links/BackToDashboardLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { ROUTE_DASHBOARD, ROUTE_SETTINGS, ROUTE_SET_GROUP } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import ManageGroupsListItem from './ManageGroupsListItem';
import {
  EveryCard,
  EveryCardPadding,
  EveryCardHeadline,
  EveryCardBody
} from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';
import Divider from 'components/Divider/Divider';
import HelpSubject from 'components/HelpButton/HelpSubject';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import Paragraph from 'components/Paragraph/Paragraph';

interface GroupsScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
  activate?: boolean;
}

interface GroupsScreenState {
  activateConfirm?: boolean;
  applyLoadingState?: 'loading' | 'error';
}

@inject('applicationStore', 'profilesStore')
@observer
class GroupsScreen extends React.Component<
GroupsScreenProps & HistoryProps,
GroupsScreenState
> {
  state: GroupsScreenState = {};

  componentDidMount() {
    const { applicationStore } = this.props;

    if (!applicationStore.isOrganizer) {
      this.props.history.replace(ROUTE_DASHBOARD);
    }

    if (applicationStore.onboardFeature('groups')) {
      return;
    }

    if (!applicationStore.book?.groups_applied || this.props.activate) {
      this.loadBook();
    }

    this.loadStudents();
  }

  loadStudents() {
    this.props.profilesStore.getStudents();
  }

  private async loadBook() {
    const book = await this.props.applicationStore.getBook();

    if (this.props.activate && book?.groups_applied) {
      this.props.history.replace(ROUTE_SETTINGS + '/groups');
    }
  }

  private confirmActivate() {
    this.setState({
      activateConfirm: true
    });
  }

  private abortActivate() {
    this.setState({
      activateConfirm: undefined
    });
  }

  private async activate() {
    const { applicationStore, profilesStore, history } = this.props;

    if (!profilesStore.groupsCount) {
      return;
    }

    this.setState({
      applyLoadingState: 'loading',
      activateConfirm: undefined
    });

    try {
      await applicationStore.applyGroups();

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'groups activated' })
      );

      history.replace({
        pathname: ROUTE_SET_GROUP,
        state: { backRoute: ROUTE_SETTINGS + '/groups' }
      });
    } catch (error: any) {
      this.setState({
        applyLoadingState: 'error'
      });
    }
  }

  private renderPage(content?: any) {
    const {
      applicationStore: { book }
    } = this.props;

    return (
      <>
        <TopActionBlock>
          <BackToDashboardLink />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="My class(students)" />} text={book?.school && book.year && (
            <>
              <FormattedMessage
                id="school year"
                values={{ year: book.year }}
              />{' '}
              {book.school.name}
            </>
          )} />

          <TabBarBlock>
            <Link to={ROUTE_SETTINGS + '/students'}>
              <FormattedMessage id="Students" />
            </Link>
            <Paragraph>
              <FormattedMessage id="Groups" />
            </Paragraph>
          </TabBarBlock>

          <Headline.Medium>
            <FormattedMessage id="Groups" />
          </Headline.Medium>

          {content}
        </PageStackBlock>

        <HelpSubject subject="groups" />
      </>
    );
  }

  private renderActive() {
    const { profilesStore } = this.props;

    const students = profilesStore.studentsWithoutGroup;
    return this.renderPage(
      <>
        <Paragraph>
          <FormattedMessage id="manage groups info" />
        </Paragraph>

        <AddGroupButtonComponent
          background="PRIMARY_LIGHT"
          color="PRIMARY_DARK"
        />

        <Divider />

        {students.length > 0 && (
          <Link to={ROUTE_SETTINGS + '/students_without_group'}>
            <EveryCard>
              <EveryCardPadding>
                <EveryCardBody>
                  <EveryCardHeadline>
                    <FormattedMessage
                      id="students without group"
                      values={{
                        count: students.length
                      }}
                    />
                  </EveryCardHeadline>
                </EveryCardBody>
                <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
              </EveryCardPadding>
            </EveryCard>
          </Link>
        )}

        <GroupsList
          itemComponent={ManageGroupsListItem}
          indicateUpdating={true}
          showGroupsCount={true}
        />

      </>
    );
  }

  private renderInactive() {
    return this.renderPage(
      <>
        <Paragraph>
          <FormattedMessage id="groups explanation" />
        </Paragraph>

        <ButtonBlock
          background="PRIMARY"
          color="WHITE"
          onClick={() =>
            this.props.history.push(ROUTE_SETTINGS + '/groups/activate')
          }
        >
          <FormattedMessage id="Activate groups" />
        </ButtonBlock>
      </>
    );
  }

  renderActivate() {
    const { applyLoadingState, activateConfirm } = this.state;

    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_SETTINGS + '/groups'} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Activate groups" />} text={<FormattedMessage id="groups activate explanation" />} />

          <GroupsList
            allowAdd={true}
            // hideEmptyState={true}
            noGroupsComponent={() => null}
            indicateUpdating={true}
            showGroupsCount={true}
          />

          <Divider />

          {applyLoadingState === 'error' && <GenericErrorComponent />}

          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => this.confirmActivate()}
            disabled={!this.props.profilesStore.groupsCount}
          >
            <FormattedMessage id="Activate groups" />
          </ButtonBlock>

          {activateConfirm && (
            <ConfirmBoxComponent
              header={<FormattedMessage id="activate groups confirm header" />}
              text={<FormattedMessage id="activate groups confirm" />}
              abortText={<FormattedMessage id="Cancel" />}
              confirmText={<FormattedMessage id="Activate groups" />}
              confirmColor="PRIMARY"
              onConfirm={() => this.activate()}
              onAbort={() => this.abortActivate()}
            />
          )}

          {applyLoadingState === 'loading' && <LoadingOverlayComponent />}
        </PageStackBlock>


      </>
    );
  }

  private renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  private renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadBook()} />
    );
  }

  render() {
    const {
      applicationStore: { book, isBookLoading, isBookError },
      activate
    } = this.props;

    if (isBookLoading) {
      return this.renderLoading();
    }

    if (isBookError) {
      return this.renderError();
    }

    if (book?.groups_applied) {
      return this.renderActive();
    }

    if (activate) {
      return this.renderActivate();
    }

    return this.renderInactive();
  }
}

export default GroupsScreen;
