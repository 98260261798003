import { Instance, types } from 'mobx-state-tree';

import { ChapterTypeEnum } from './ChapterTypeEnum';

// Full model: ChapterModelType
const MinimalChapterModel = types.model('MinimalChapterModel', {
  id: types.identifierNumber,
  chapter_type: ChapterTypeEnum,
  title: types.maybe(types.string)
});

// Full model: ProfileModel
const MinimalProfileModel = types.model('MinimalProfileModel', {
  id: types.identifierNumber,
  first_name: types.maybe(types.string),
  last_name: types.maybe(types.string)
});

// Full model: GroupModel
const MinimalGroupModel = types.model('MinimalGroupModel', {
  id: types.identifierNumber,
  name: types.maybe(types.string)
});

const ContentNotPrintedModel = types.model('ContentNotPrintedModel', {
  chapters_not_printed: types.array(MinimalChapterModel),
  profiles_not_printed_factsheet: types.array(MinimalProfileModel),
  profiles_not_printed_yearbook: types.array(MinimalProfileModel),
  profiles_without_groups: types.array(MinimalProfileModel),
  groups_without_factsheet_chapter: types.array(MinimalGroupModel),
  groups_without_yearbook_chapter: types.array(MinimalGroupModel),
  global_factsheet: types.boolean,
  global_yearbook: types.boolean
});

export type ContentNotPrintedModelType = Instance<typeof ContentNotPrintedModel>;

export default ContentNotPrintedModel;
