import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import { RankingChapterSettingsModelType } from 'models/chapter-settings/RankingChapterSettingsModel';
import { ROUTE_RANKINGS } from 'utils/constants/routes';

import { ChapterSettingsListProps } from './ChapterSettingsListProps';

const RankingChapterSettingsListComponent = ({
  chapter
}: ChapterSettingsListProps) => {
  const settings = chapter.settings as RankingChapterSettingsModelType;

  if (settings.ranking_type !== 'candidate_ranking') {
    return (
      <>
        <ButtonListBlock>
          <ButtonListBlockItem withBorder={true}>
            <Link to={ROUTE_RANKINGS + '/' + chapter.id + '/invite'}>
              <ButtonListBlockBody>
                <FormattedMessage id="Students" />
              </ButtonListBlockBody>
            </Link>
          </ButtonListBlockItem>
        </ButtonListBlock>
      </>
    )
  }

  return (
    <>
      <ButtonListBlock>
        <ButtonListBlockItem withBorder={true}>
          <Link to={ROUTE_RANKINGS + '/' + chapter.id + '/candidates'}>
            <ButtonListBlockBody>
              <FormattedMessage id="Candidates" />
            </ButtonListBlockBody>
          </Link>
        </ButtonListBlockItem>
      </ButtonListBlock>
    </>
  );
};

export default RankingChapterSettingsListComponent;
