import React, { ReactNode } from 'react';

import ItemStackBlock from 'blocks/ItemStackBlock';
import SharingComponent from 'components/SharingComponent';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import { FormattedMessage } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

import { BaseTextileProfile } from 'api/textile_deals/fetchTextileProfiles';
import TextilePreorderProfileListItem from './TextilePreorderProfileListItem';

interface PublicTextilePreorderProfileListProps {
  onProfileClick?: (profileId: number) => void;
  textileOrderId?: number;
  inviteLink?: string;
  profiles: BaseTextileProfile[];
}

interface FormProps {
  form: FormType;
}

const TextilePreorderProfileList: React.FC<
  PublicTextilePreorderProfileListProps & FormProps
> = (props) => {
  const filteredProfiles = (): BaseTextileProfile[] => {
    const { form, profiles } = props;

    const filteredProfiles: BaseTextileProfile[] = [];
    const nameLowercase = form.values.filter
      ? form.values.filter.trim().toLowerCase()
      : '';

    profiles.forEach((itm) => {
      if (itm.name && itm.name.toLowerCase().indexOf(nameLowercase) > -1) {
        filteredProfiles.push(itm);
      }
    });

    return filteredProfiles;
  };

  const renderList = () => {
    const { form, onProfileClick, inviteLink } = props;

    let heading: ReactNode;

    const profiles = filteredProfiles();

    heading = (
      <FormattedMessage
        id="students filter count"
        values={{
          count: profiles.length
        }}
      />
    );

    return (
      <ItemStackBlock gap="L">
        <SearchListInput form={form} placeholderId="Find student" />

        <ItemStackBlock gap="M">
          <UppercaseHeading>{heading}</UppercaseHeading>

          {profiles.length !== 0 &&
            profiles.map((profile) => (
              <TextilePreorderProfileListItem
                key={profile.id}
                profile={profile}
                onClick={onProfileClick}
                disabled={!(profile.preorder_state === 'submitted')}
              />
            ))}
        </ItemStackBlock>

        {profiles.length === 0 && (
          <SharingComponent
            label="invite header"
            buttonOutside={true}
            modalHeader="invite link"
            message="Nuggit invite text"
            buttonColor="WHATSAPP"
            url={inviteLink}
          />
        )}
      </ItemStackBlock>
    );
  };

  return renderList();
};

export default (props: PublicTextilePreorderProfileListProps) => {
  const form = useForm();
  // @ts-ignore
  return <TextilePreorderProfileList {...props} form={form} />;
};
