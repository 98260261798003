import { get } from "api/BaseApi";

type DiscountIdentifier = 'nuggit_promotion' | 'nuggit_combination' | 'nuggit_volume_65' | 'nuggit_volume_90'

export interface TextileDiscount {
  id: number;
  value: number;
  name?: string;
  amount?: number;
  identifier?: DiscountIdentifier;
  info?: string;
  info_done?: string;
  info_not_used?: string;
  checked: boolean;
}

export const getAllTextileDiscounts = (textileOrderId: number): Promise<TextileDiscount[]> => {
  return get(`/textile_discounts?textile_order_id=${textileOrderId}`);
}