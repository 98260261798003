import ROLES from './constants/roles';

export const analyticsPush = (event: any) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log('analyticsPush', event);
  }

  const w: any = window;
  if (!w.dataLayer || typeof w.dataLayer.push !== 'function') {
    return;
  }

  w.dataLayer.push(event);
};

export const pushStartSignup = () => {
  analyticsPush({ event: 'startSignup' });
};

/**
 * Adds the role suffix to the event name.
 * Managers and organizers get the suffix, students no suffix.
 *
 * @param event The event name
 * @param role The user’s role
 */
const suffixEventWithRole = (event: string, role: ROLES) =>
  role === ROLES.MANAGER
    ? `${event}-manager`
    : role === ROLES.ORGANIZER
    ? `${event}-organizer`
    : event;

export const pushSuccessSignup = (phoneNumber: string | undefined) => {
  const formattedPhoneNumber = parsePhoneNumber(phoneNumber);
  analyticsPush({
    event: 'success-signup',
    'user-phone': formattedPhoneNumber
  });
};

export const pushDashboardViewed = (role: ROLES) => {
  analyticsPush({ event: suffixEventWithRole('dashboardViewed', role) });
};

export const funnelSite = (funnel: 'textile' | 'book' | 'signup') => {
  if (funnel === 'book') {
    analyticsPush({ event: 'pageview-quizfunnel-books' });
  }

  if (funnel === 'textile') {
    analyticsPush({ event: 'pageview-quizfunnel-textiles' });
  }

  if (funnel === 'signup') {
    analyticsPush({ event: 'pageview-signup-app' });
  }
};

const parsePhoneNumber = (phoneNumber: string | undefined) => {
  if (phoneNumber) {
    for (let i = 0; i <= phoneNumber.length; i++) {
      if (phoneNumber[i] !== '0' && phoneNumber[i] !== ' ') {
        if (phoneNumber[i] === '+') {
          return phoneNumber;
        }
        return '+49 ' + phoneNumber.substring(i);
      }
    }
    return phoneNumber;
  }

  return '';
};

export const finishFunnel = (
  funnel: 'textile' | 'book',
  phoneNumber: string | undefined
) => {
  const formattedPhoneNumber = parsePhoneNumber(phoneNumber);
  if (funnel === 'book') {
    analyticsPush({
      event: 'success-quizfunnel-books',
      'user-phone': formattedPhoneNumber
    });
  }

  if (funnel === 'textile') {
    analyticsPush({
      event: 'success-quizfunnel-textiles',
      'user-phone': formattedPhoneNumber
    });
  }
};

export const textileOrderCreated = (phoneNumber: string | undefined) => {
  const formattedPhoneNumber = parsePhoneNumber(phoneNumber);
  analyticsPush({
    event: 'success-signup-textiles-manager',
    'user-phone': formattedPhoneNumber
  });
};

export const bookCreated = (phoneNumber: string | undefined) => {
  const formattedPhoneNumber = parsePhoneNumber(phoneNumber);
  analyticsPush({
    event: 'success-signup-books-manager',
    'user-phone': formattedPhoneNumber
  });
};

export const isLoggedIn = () => {
  analyticsPush({ event: 'pageview-app-loggedIn' });
};
