import React from 'react';

import { arrayEquals } from 'utils/array_equals';
import { Boundary } from 'utils/chunker/ContentChunker';
import { LayoutType } from 'utils/chunker/replace-variables';
import sanitizeHtml from 'utils/chunker/sanitize-html';
import { calculateNameListFontSize } from 'utils/template/textileNameColumsAndFontSize';
import { getSettingsByColumn } from 'utils/template/textileNamesSettingsByColumn';
import { sliceNames } from 'utils/template/textileSliceNames';
import { calculateTeacherRowsAndFontSize } from 'utils/template/textileTeacherRowsAndFontSize';
import { calculateFontSize } from 'utils/template/textileTextFontSize';

import { Font, FontSpec, TextileBackground } from 'api/textile_deals/fetchTextileDesignSetting';
import config from 'config';
import PicturePrintComponent, {
  PrintPicture
} from '../elements/PicturePrintComponent';
import TextileNamesComponent from '../elements/TextileNamesComponent';
import TextileNamesWithGroupsComponent from '../elements/TextileNamesWithGroupsComponent';
import TextileTeachersComponent from '../elements/TextileTeachersComponent';
import { NamesByGroup } from '../layout-editor/TextileBackPreviewComponent';
import TextileBackComponent from '../structure/TextileBackComponent';
import TextileContentComponent from '../structure/TextileContentComponent';
import WaitForImagesTextiles from './WaitForImagesTextiles';

const COMPONENT_WIDTH = '884px';
const COMPONENT_HEIGHT = '1404px';

const NAME_FONT = 'Bebas Neue';

const MAX_CONTENT_HEIGHT = 4904; // 1384 * 3,543 = 4093,5 => 4094 upscale to 360dpi
const MAX_CONTENT_WIDTH = 3061; // 864 * 3,543 = 3061,1 => 3061 upscale to 360dpi

const TOP_MARGIN_NAMES = 71; // 20 * 3,543 = 70,8 => 71 upscale to 360dpi
const TOP_MARGIN_FOOTER = 71; // 20 * 3,543 = 70,8 => 71 upscale to 360dpi
const TOP_MARGIN_SHOW_TEACHER = 35; // 10 * 3,543 = 35,4 => 35 upscale to 360dpi
const CONTAINER_MARGIN = 383; // 108 * 3,543 = 382,6  => 383 upscale to 360dpi

const GROUP_NAMES_CONTAINER_HEIGHT = 202; // 57 * 3,543 = 201,9 => 202 upscale to 360dpi

const HEADLINE_SETTINGS = {
  contentWidth: MAX_CONTENT_WIDTH,
  contentHeight: 485 // 137 * 3,543 = 485,4 => 485 upscale to 360dpi
};

const SUBHEADLINE_SETTINGS = {
  contentWidth: MAX_CONTENT_WIDTH,
  contentHeight: 315 // 89 * 3,543 = 315,3 => 315 upscale to 360dpi
};

const FOOTER_SETTINGS = {
  contentWidth: MAX_CONTENT_WIDTH,
  contentHeight: 315 // 89 * 3,543 = 315,3 => 315 upscale to 360dpi
};

const TEACHER_SETTINGS = {
  contentWidth: MAX_CONTENT_WIDTH,
  contentHeight: 400 // 113 * 3,543 = 400,4 => 400 upscale to 360dpi
};

const HEADLINE_FONTSIZE_DEFAULT = 262 // 74 * 3,543 = 262,2 => 262 upscale to 360dpi
const SUBHEADLINE_FONTSIZE_DEFAULT = 152 // 43 * 3,543 = 152,3 => 152 upscale to 360dpi
const FOOTER_FONTSIZE_DEFAULT = 152 // 43 * 3,543 = 152,3 => 152 upscale to 360dpi

const FONT_STYLES: React.CSSProperties = {
  lineHeight: '120%',
  letterSpacing: '7px',
  textAlign: 'center',
  overflowWrap: 'break-word'
};

export interface TextileTextBox {
  boundary: Boundary;
  minFontSize: number;
  maxFontSize: number;
  step: number;
}
export interface TextileBackTemplateConfig {
  namesBack?: string;
  headlineFontColor?: string;
  subheadlineFontColor?: string;
  footerFontColor?: string; // for header and footer
  studentNamesFontColor?: string;
  teacherNamesFontColor?: string;
  groupsFontColor?: string;
}

export interface TextileBackTemplateProps {
  config: TextileBackTemplateConfig;
  previewSize?: { width: number; height: number };
  font: Font;
  background: TextileBackground | string;
  print?: boolean;
  names?: string[];
  namesHeader?: string;
  backPicture?: PrintPicture;
  headline?: string;
  subheadline?: string;
  footer?: string;
  teachers?: string[];
  namesByGroup?: NamesByGroup[];
  showBackground?: boolean;

  // frontType: 'template' | 'picture';
  backType?: 'default' | 'picture';
}

export interface TextileBackTemplateState {
  calculatedNamesFontSize?: number;
  calculatedColumns?: number;
  calculatedHeadlineFontSize?: number;
  calculatedSubHeadlineFontSize?: number;
  calculatedFooterFontSize?: number;
  calculatedTeacherFontSize?: number;
  calculatedTeacherRows?: number;
  calculationDone: boolean;
}

export class TextileBackTemplateComponent extends React.Component<
  TextileBackTemplateProps,
  TextileBackTemplateState
> {
  constructor(props: TextileBackTemplateProps) {
    super(props);

    this.state = {
      calculationDone: false
    };
  }

  componentDidMount() {
    if (!this.state.calculationDone) {
      this.placeContent();
    }
  }

  componentDidUpdate(prevProps: Readonly<TextileBackTemplateProps>): void {
    if (
      (prevProps.names &&
        this.props.names &&
        !arrayEquals(prevProps.names, this.props.names)) ||
      (prevProps.teachers &&
        this.props.teachers &&
        !arrayEquals(prevProps.teachers, this.props.teachers))
    ) {
      this.placeContent();
    }
  }

  placeContent() {
    const {
      names,
      namesByGroup,
      font,
      teachers,
      headline,
      subheadline,
      footer
    } = this.props;
    const sizeFactor = font.sizeFactor || 1;
    const fontFamily = font.fonts[0].name

    const nameList = calculateNameListFontSize(
      this.getMaxNameListHeight(),
      names,
      namesByGroup
    );

    const headlineFontSize = calculateFontSize(
      fontFamily,
      sizeFactor,
      FONT_STYLES,
      HEADLINE_SETTINGS,
      headline
    );
    const subHeadlineFontSize = calculateFontSize(
      fontFamily,
      sizeFactor,
      FONT_STYLES,
      SUBHEADLINE_SETTINGS,
      subheadline
    );
    const footerFontSize = calculateFontSize(
      fontFamily,
      sizeFactor,
      FONT_STYLES,
      FOOTER_SETTINGS,
      footer
    );

    if (nameList) {
      const teacherRowsAndFontSize = calculateTeacherRowsAndFontSize(
        nameList.calculatedNamesFontSize,
        teachers,
        TEACHER_SETTINGS
      );

      this.setState({
        calculatedNamesFontSize: nameList.calculatedNamesFontSize,
        calculatedColumns: nameList.calculatedColumns,
        calculationDone: true,
        calculatedHeadlineFontSize: headlineFontSize || HEADLINE_FONTSIZE_DEFAULT,
        calculatedSubHeadlineFontSize: subHeadlineFontSize || SUBHEADLINE_FONTSIZE_DEFAULT,
        calculatedFooterFontSize: footerFontSize || FOOTER_FONTSIZE_DEFAULT,
        calculatedTeacherFontSize:
          teacherRowsAndFontSize?.calculatedNamesFontSize ||
          nameList.calculatedNamesFontSize,
        calculatedTeacherRows: teacherRowsAndFontSize?.calculatedRows || 1
      });
    }
  }

  getMaxNameListHeight() {
    const {
      headline,
      subheadline,
      footer,
      teachers
    } = this.props;

    let nameListHeight = MAX_CONTENT_HEIGHT;

    if (headline) {
      nameListHeight -= HEADLINE_SETTINGS.contentHeight;
      nameListHeight -= TOP_MARGIN_NAMES;
    }

    if (subheadline) {
      nameListHeight -= SUBHEADLINE_SETTINGS.contentHeight;

      if (!headline) {
        nameListHeight -= TOP_MARGIN_NAMES;
      }
    }

    if (teachers) {
      nameListHeight -= TEACHER_SETTINGS.contentHeight;
      nameListHeight -= TOP_MARGIN_SHOW_TEACHER;
    }

    if (footer) {
      nameListHeight -= FOOTER_SETTINGS.contentHeight;
      nameListHeight -= TOP_MARGIN_FOOTER;
    }

    // if (namesByGroup) {
    //   nameListHeight -= GROUP_NAMES_CONTAINER_HEIGHT;
    // }

    return nameListHeight;
  }

  renderNames() {
    if (!this.props.names || this.props.names.length === 0) {
      return null;
    }

    const { calculatedNamesFontSize, calculatedColumns } = this.state;

    if (!calculatedNamesFontSize || !calculatedColumns) {
      return null;
    }

    const names = sliceNames(this.props.names, calculatedColumns);
    if (!names.length) {
      return null;
    }

    const {
      config: { studentNamesFontColor }
    } = this.props;

    return (
      <TextileNamesComponent
        names={names}
        width={MAX_CONTENT_WIDTH}
        font={NAME_FONT}
        color={this.addHashIfNotPresent(studentNamesFontColor)}
        maxHeight={this.getMaxNameListHeight()}
        fontSize={calculatedNamesFontSize}
        columnWidth={getSettingsByColumn(calculatedColumns).width}
        marginTop={TOP_MARGIN_NAMES}
      />
    );
  }

  renderNamesByGroup() {
    const { namesByGroup } = this.props;

    if (!namesByGroup) {
      return null;
    }

    const columns = namesByGroup.length;

    const {
      config: { groupsFontColor }
    } = this.props;

    const settings = getSettingsByColumn(columns);

    const { calculatedNamesFontSize } = this.state;

    return (
      <TextileNamesWithGroupsComponent
        namesByGroup={namesByGroup}
        width={MAX_CONTENT_WIDTH}
        headerHeight={GROUP_NAMES_CONTAINER_HEIGHT}
        font={NAME_FONT}
        color={this.addHashIfNotPresent(groupsFontColor)}
        maxHeight={this.getMaxNameListHeight() + GROUP_NAMES_CONTAINER_HEIGHT}
        headerFontSize={settings.maxFontSize}
        fontSize={calculatedNamesFontSize}
        columnWidth={settings.width}
        marginTop={TOP_MARGIN_NAMES}
      />
    );
  }

  renderTeachers() {
    const { teachers } = this.props;
    if (!teachers || teachers.length === 0) {
      return null;
    }

    const {
      config: { teacherNamesFontColor }
    } = this.props;

    const { calculatedTeacherFontSize, calculatedTeacherRows } = this.state;

    if (!calculatedTeacherFontSize || !calculatedTeacherRows) {
      return null;
    }

    const teacherNames = sliceNames(teachers, calculatedTeacherRows);

    if (!teacherNames.length) {
      return null;
    }

    return (
      <TextileTeachersComponent
        teachers={teacherNames}
        width={MAX_CONTENT_WIDTH}
        font={NAME_FONT}
        color={this.addHashIfNotPresent(teacherNamesFontColor)}
        height={TEACHER_SETTINGS.contentHeight}
        fontSize={calculatedTeacherFontSize}
        marginTop={TOP_MARGIN_SHOW_TEACHER}
      />
    );
  }

  calculatePreviewSize(): { width: string; height: string } {
    const { previewSize } = this.props;
    if (!previewSize) {
      return { width: COMPONENT_WIDTH, height: COMPONENT_HEIGHT };
    }

    return {
      width: Math.round(previewSize.width * 0.8185).toString() + 'px',
      height: Math.round(previewSize.height * 0.8775).toString() + 'px'
    };
  }

  addHashIfNotPresent(color?: string) {
    if (!color) {
      return '#000000';
    }

    if (color.charAt(0) !== '#') {
      return '#' + color;
    }
    return color;
  }

  render() {
    const {
      print,
      backPicture,
      backType,
      names,
      headline,
      subheadline,
      footer,
      teachers,
      namesByGroup,
      background,
      font,
      showBackground,
      config: { headlineFontColor, subheadlineFontColor, footerFontColor }
    } = this.props;

    let layout: LayoutType = 'preview';
    if (print) {
      layout = 'print';
    }

    let usedBackground;

    // TODO !!! Use image filenames from background spec instead of background.id !!!
    // TODO Accept string in `background` prop and use it as full URL for background image
    if (showBackground && backType === 'default') {
      if (typeof background === 'string') {
        usedBackground = background;
      } else {
        const backgroundPath = layout === 'print' ? background.print : background.preview;
        usedBackground =
          config.layout.textileBaseUrl +
          '/backgrounds/files/' + backgroundPath
      }
    }

    const hasPicture = backType === 'picture' && !!backPicture;
    const hasNames = backType === 'default' && !!names;

    const fontSpecs: FontSpec[] = [];

    font.fonts.forEach((itm: any) => {
      const name: string = itm[0];
      const weights: number[] = itm[1];

      fontSpecs.push({name, weights})
    })

    const textStyle: React.CSSProperties = {
      fontFamily: fontSpecs[0].name,
      fontWeight: fontSpecs[0].weights[0],
      ...FONT_STYLES
    };

    return (
      <>
        <TextileBackComponent background={usedBackground}>
          <TextileContentComponent
            height={COMPONENT_HEIGHT}
            width={COMPONENT_WIDTH}
          >
            {hasPicture ? (
              <PicturePrintComponent
                picture={backPicture!}
                size={[MAX_CONTENT_WIDTH, MAX_CONTENT_HEIGHT]}
                position={[CONTAINER_MARGIN, CONTAINER_MARGIN]}
              />
            ) : hasNames ? (
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: CONTAINER_MARGIN,
                    left: CONTAINER_MARGIN,
                    width: MAX_CONTENT_WIDTH + 'px'
                  }}
                >
                  {headline && (
                    <div
                      style={{
                        ...textStyle,
                        color: this.addHashIfNotPresent(headlineFontColor),
                        height: HEADLINE_SETTINGS.contentHeight,
                        width: '100%',
                        fontSize: this.state.calculatedHeadlineFontSize
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(headline) || ''
                        }}
                      />
                    </div>
                  )}

                  {subheadline && (
                    <div
                      style={{
                        ...textStyle,
                        color: this.addHashIfNotPresent(subheadlineFontColor),
                        height: SUBHEADLINE_SETTINGS.contentHeight,
                        width: '100%',
                        fontSize: this.state.calculatedSubHeadlineFontSize
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(subheadline) || ''
                        }}
                      />
                    </div>
                  )}

                  {namesByGroup
                    ? this.renderNamesByGroup()
                    : this.renderNames()}

                  {teachers && !namesByGroup && this.renderTeachers()}

                  {footer && (
                    <div
                      style={{
                        ...textStyle,
                        marginTop: TOP_MARGIN_FOOTER,
                        color: this.addHashIfNotPresent(footerFontColor),
                        height: FOOTER_SETTINGS.contentHeight,
                        width: '100%',
                        fontSize: this.state.calculatedFooterFontSize
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(footer) || ''
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </TextileContentComponent>
        </TextileBackComponent>

        {print && <WaitForImagesTextiles />}
      </>
    );
  }
}

export default TextileBackTemplateComponent;
