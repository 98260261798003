import { GridTemplate } from './grid-template';
import { ImagePageTemplateConfig } from './ImagePageTemplateComponent';

const BACKGROUND_LEFT =
  '<div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;background-color: {color_page_background};"></div>\n<img src="{background}" style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;">\n\n{titleblock}';
const BACKGROUND_RIGHT =
  '<div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;background-color: {color_page_background};"></div>\n<img src="{background}" style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;">\n\n{titleblock}';

const TITLE_LEFT =
  '<div style="position: absolute; top: 92px; left: 92px; text-align:left;width:681px;{pageheader}; color: {color_headline};font-size: 36px;line-height:36px;height: 72px;overflow:hidden"><span style="background-color: {color_headline_background};display:flex;align-items:center;min-height:100%">{title}</span></div>';
const TITLE_RIGHT =
  '<div style="position: absolute; top: 92px; left: 92px; text-align:right;width:681px;{pageheader}; color: {color_headline};font-size: 36px;line-height:36px;height: 72px;overflow:hidden"><span style="background-color: {color_headline_background};display:flex;align-items:center;min-height:100%">{title}</span></div>';

const OVERLAY_LEFT =
  '<div style="position: absolute; top: 1118px; left: 0px; padding: 9px; width: 70px; background-color: {color_pagination_background}; color: {color_pagination_text}; text-align: right; font-size: 10px; font-family: Roboto;">{page}</div>';
const OVERLAY_RIGHT =
  '<div style="position: absolute; top: 1118px; right: 0px; padding: 9px; width: 70px; background-color: {color_pagination_background}; color: {color_pagination_text}; font-size: 10px; font-family: Roboto;">{page}</div>';

const TITLE_MARGIN = 0;

const IMAGE_BLOCK = `
  <div style="position: absolute; top: {top}; left: {left};">
    <img src="{url}" style="display: block; margin: 0; width: {width}; height: {height};" />
    {caption}
  </div>
`;

// TODO center image in fitted block
const FITTED_IMAGE_BLOCK = `
  <div style="position: absolute; top: {top}; left: {left};">
    <img src="{url}" style="display: block; margin: 0; width: {width}; height: {height}; object-fit: contain;" />
    {caption}
  </div>
`;

const CAPTION_BLOCK = `
  <div style="padding-top: 10px; font-family: Roboto; font-weight: 400; font-size: 11px; line-height: 14px; text-align: center;">
    <div style="height: 14px; width: 218px; overflow: hidden; white-space: pre-line; font-weight: 700; {content_style}">{content_caption}</div>
    <div style="height: 56px; width: 218px; overflow: hidden;">
      <div style="margin-top: -14px; white-space: pre-line; {content_style}">{content_caption}</div>
    </div>
  </div>
`;

export const generateAlbumPageTemplateConfig = (
  grid: GridTemplate
): ImagePageTemplateConfig => {
  return {
    leftBackground: grid.noBackground ? undefined : BACKGROUND_LEFT,
    rightBackground: grid.noBackground ? undefined : BACKGROUND_RIGHT,
    leftOverlay: OVERLAY_LEFT,
    rightOverlay: OVERLAY_RIGHT,
    leftTitleBlock: TITLE_LEFT,
    rightTitleBlock: TITLE_RIGHT,

    titleEnabled: grid.titleEnabled,
    titleMargin: TITLE_MARGIN,

    slots: grid.slots,

    imageBlock: IMAGE_BLOCK,
    fittedImageBlock: FITTED_IMAGE_BLOCK,

    captionBlock: CAPTION_BLOCK
  };
};
