import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
  WrappedComponentProps
} from 'react-intl';

import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import BackClickLink from 'domain/Links/BackClickLink';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import { YearbookChapterSettingsModelType } from 'models/chapter-settings/YearbookChapterSettingsModel';
import {
  CHAPTER_TYPES_WITH_SETTINGS,
  ChapterModelType,
  STUDENT_EDITABLE_CHAPTER_TYPES
} from 'models/ChapterModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import Loading from 'screens/development/Loading';
import { ROUTE_CONTENT, ROUTE_LAYOUT } from 'utils/constants/routes';
import { isPush } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

import HelpSubject from 'components/HelpButton/HelpSubject';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import AccessSettingsContainer from './details/AccessSettingsContainer';
import DescriptionSettingsContainer from './details/DescriptionSettingsContainer';
import FactsheetCommentsSettingsContainer from './details/factsheet/FactsheetCommentsSettingsContainer';
import FactsheetCreativePageSettingsContainer from './details/factsheet/FactsheetCreativePageSettingsContainer';
import FactsheetPhotosSettingsContainer from './details/factsheet/FactsheetPhotosSettingsContainer';
import FactsheetTextSettingsContainer from './details/factsheet/FactsheetTextSettingsContainer';
import LayoutSettingsContainer from './details/LayoutSettingsContainer';
import OrderSettingsContainer from './details/OrderSettingsContainer';
import PrintSettingsContainer from './details/PrintSettingsContainer';
import TitleSettingsContainer from './details/TitleSettingsContainer';
import TocSettingsContainer from './details/TocSettingsContainer';
import FactsheetChapterSettingsListComponent from './lists/FactsheetChapterSettingsListComponent';
import RankingChapterSettingsListComponent from './lists/RankingChapterSettingsListComponent';
import SettingNotAvailableComponent from './SettingNotAvailableComponent';
import Link from './SettingsLink';

interface PublicChapterSettingsContainerProps {
  history: any;
  chapterId: number;
  chapter?: ChapterModelType;
  active?: string;
  skipPushCheck?: boolean;
  baseRoute: string;
  backRoute?: string;
}

interface ChapterSettingsContainerProps
  extends PublicChapterSettingsContainerProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  form: FormType;
  intl: IntlShape;
}

@inject('applicationStore', 'chaptersStore')
@observer
class ChapterSettingsContainer extends React.Component<
  ChapterSettingsContainerProps
> {
  componentDidMount() {
    if (
      !this.props.active &&
      this.props.applicationStore.onboardFeature(
        'chaptersettings',
        this.props.history.location?.pathname
      )
    ) {
      return;
    }

    if (this.isChapterReloadRequired()) {
      this.loadChapter(this.props.chapterId);
    } else {
      this.fillForm();
    }
  }

  componentDidUpdate(prevProps: ChapterSettingsContainerProps) {
    if (prevProps.active !== this.props.active) {
      this.fillForm();
    }
  }

  isChapterReloadRequired() {
    const { item } = this.props.chaptersStore;
    if (!item) {
      return true;
    }

    if (item.id !== this.props.chapterId) {
      return true;
    }

    if (!this.props.skipPushCheck && isPush(this.props.history)) {
      return true;
    }

    return false;
  }

  async loadChapter(chapterId: number) {
    const { chaptersStore } = this.props;

    await chaptersStore.getChapter(chapterId);

    this.fillForm();
  }

  fillForm() {
    const { chaptersStore, form, chapterId } = this.props;
    const chapter = chaptersStore.item;

    if (chapter && chapter.id === chapterId) {
      // fill form with common chapter parameters
      form.setField('title', chapter.title);
      form.setField('print', !chapter.print ? false : true);
      form.setField('list_in_toc', !chapter.list_in_toc ? false : true);
      form.setField('description', chapter.description);
      form.setField('access_level', chapter.access_level || 'open');

      const { group } = chapter;
      if (group) {
        form.setField('access_level', `group-${group.id}`);
        form.setField('group_id', group.id);
        form.setField('group_name', group.name);
      } else {
        form.setField('group_id', undefined);
        form.setField('group_name', undefined);
      }

      if (chapter.isFactsheetChapter) {
        const settings =
          (chapter.settings as FactsheetChapterSettingsModelType) || {};

        form.setField('text_on', !settings.text_on ? false : true);
        form.setField('text_header', settings.text_header || '');
        form.setField('creative_page', !settings.creative_page ? false : true);
        form.setField('comments_on', !settings.comments_on ? false : true);
        form.setField('comments_header', settings.comments_header || '');
        // We're using anonymous_comments in the data but the form uses the inverse print_comment_name
        form.setField(
          'print_comment_name',
          settings.anonymous_comments ? false : true
        );
        form.setField('photo_count', (settings.photo_count || 0).toString());
        form.setField('slot1_photo_name', settings.slot1_photo_name || '');
        form.setField('slot2_photo_name', settings.slot2_photo_name || '');
        form.setField('slot3_photo_name', settings.slot3_photo_name || '');
        form.setField('profile_order', settings.profile_order || 'first_name');
      }

      if (chapter.chapter_type === 'yearbook') {
        const settings =
          (chapter.settings as YearbookChapterSettingsModelType) || {};

        form.setField('profile_order', settings.profile_order || 'first_name');
      }
    }
  }

  hasSettings() {
    const chapter = this.props.chaptersStore.item;
    return !chapter ||
      CHAPTER_TYPES_WITH_SETTINGS.indexOf(chapter.chapter_type) < 0
      ? false
      : true;
  }

  isStudentEditable(chapter?: ChapterModelType) {
    if (!chapter) {
      chapter = this.props.chaptersStore.item;
    }

    if (
      !chapter ||
      STUDENT_EDITABLE_CHAPTER_TYPES.indexOf(chapter.chapter_type) < 0
    ) {
      return false;
    }

    return true;
  }

  updateChapter(patch: any) {
    this.update(patch, 'chapter');
  }

  updateSettings(patch: any) {
    this.update(patch, 'settings');
  }

  async update(patch: any, what: 'chapter' | 'settings') {
    const { chaptersStore, applicationStore, intl, form } = this.props;
    const chapter = chaptersStore.item;
    if (!chapter) {
      return;
    }

    form.setLoading(true);

    try {
      what === 'settings'
        ? await chaptersStore.updateChapterSettings(chapter.id, patch)
        : await chaptersStore.updateChapter(chapter.id, patch);

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'change saved' })
      );

      this.settingsSaved();
    } catch (error: any) {
      // TODO check if there are cases where we need to skip form error handling
      //      when server returns 422
      // if (
      //   what === 'chapter' &&
      //   chapter.chapter_type === 'factsheet' &&
      //   !!patch.access_level
      // ) {
      //   const formErrors = handleFormError(form, error);
      //   if (formErrors && !!formErrors.group_id) {
      //     // this needs to be displayed
      //     form.setLoading(false);
      //     return;
      //   }
      // }
      if (handleFormError(form, error)) {
        form.setLoading(false);
        return;
      }

      applicationStore.setFlashMessage(
        intl.formatMessage({ id: 'change save error' }),
        'error'
      );
    }

    this.fillForm();
    form.setLoading(false);
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  cancelDelete() {
    this.props.form.setConfirm(false);
  }

  async performDelete() {
    const { chaptersStore, chapterId, history } = this.props;

    try {
      await chaptersStore.removeChapter(chapterId);

      history.replace(ROUTE_CONTENT);
    } catch (error: any) {
      // will be handled by state
    }
  }

  settingsSaved() {
    this.props.history.push(this.backLink());
    this.props.form.reset();
  }

  goBack() {
    const { history } = this.props;
    if (history.location?.state?.canGoBack) {
      history.goBack();
      return;
    }

    history.push(this.backLink());
  }

  backLink() {
    const { backRoute, baseRoute, history } = this.props;
    const from = history.location?.query?.from;

    if (from === 'layouts') {
      return ROUTE_LAYOUT + '/editor';
    }

    return from === 'list' && backRoute ? backRoute : baseRoute;
  }

  renderBack(isOverview = false) {
    const { backRoute, history } = this.props;

    if (isOverview) {
      return !backRoute ? null : <BackLink to={backRoute} />;
    }

    if (history.location?.state?.canGoBack) {
      return <BackClickLink onClick={() => history.goBack()} />;
    }

    return <BackLink to={this.backLink()} />;
  }

  renderPage(content: any, isOverview = false) {
    return (
      <>
        <TopActionBlock>{this.renderBack(isOverview)}</TopActionBlock>

        {content}

        <HelpSubject subject="settings" />
      </>
    );
  }

  renderFeatureSettings(chapter?: ChapterModelType) {
    if (!chapter) {
      return null;
    }

    const { baseRoute } = this.props;

    switch (chapter.chapter_type) {
      case 'factsheet':
      case 'teacher_factsheet':
        return (
          <FactsheetChapterSettingsListComponent
            chapter={chapter}
            baseRoute={baseRoute}
          />
        );

      case 'ranking':
        return (
          <RankingChapterSettingsListComponent
            chapter={chapter}
            baseRoute={baseRoute}
          />
        );

      default:
    }

    return null;
  }

  renderCommonSettings(chapter?: ChapterModelType) {
    if (!chapter) {
      return null;
    }

    const { baseRoute } = this.props;

    const allowSetPrint = chapter.chapter_type !== 'sponsor';
    const isStudentEditable = this.isStudentEditable(chapter);

    return (
      <>
        <ButtonListBlock>
          <ButtonListBlockItem>
            <Link basePath={baseRoute} active="title">
              <ButtonListBlockBody arrow={true}>
                <FormattedMessage id="Chapter name" />
              </ButtonListBlockBody>
            </Link>
          </ButtonListBlockItem>

          {allowSetPrint && (
            <ButtonListBlockItem>
              <Link basePath={baseRoute} active="print">
                <ButtonListBlockBody>
                  <FormattedMessage id="Print settings" />
                </ButtonListBlockBody>
              </Link>
            </ButtonListBlockItem>
          )}

          {/* <ButtonListBlockItem>
            <Link basePath={baseRoute} active="toc">
              <ButtonListBlockBody>
                <FormattedMessage id="Table of contents" />
              </ButtonListBlockBody>
            </Link>
          </ButtonListBlockItem> */}

          {isStudentEditable && (
            <>
              <ButtonListBlockItem>
                <Link basePath={baseRoute} active="access">
                  <ButtonListBlockBody>
                    <FormattedMessage id="Chapter access" />
                  </ButtonListBlockBody>
                </Link>
              </ButtonListBlockItem>

              <ButtonListBlockItem>
                <Link basePath={baseRoute} active="description">
                  <ButtonListBlockBody>
                    <FormattedMessage id="Explanation for students" />
                  </ButtonListBlockBody>
                </Link>
              </ButtonListBlockItem>
            </>
          )}
        </ButtonListBlock>

        <DeleteButton
          messageId="Remove chapter"
          onClick={() => this.confirmDelete()}
        />
      </>
    );
  }

  renderOverview() {
    const { confirm, loading } = this.props.form;
    const chapter = this.props.chaptersStore.item;

    if (chapter?.chapter_type === 'table_of_contents') {
      return this.renderNoSettings();
    }

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Settings" />
        </Headline.Large>

        <NonGapStackBlock>
          {this.renderFeatureSettings(chapter)}
          {this.renderCommonSettings(chapter)}
        </NonGapStackBlock>

        {loading && <Loading />}
        {confirm && (
          <ConfirmBoxComponent
            header={<FormattedMessage id="confirm delete header" />}
            text={
              <FormattedMessage
                id="confirm delete item"
                values={{
                  item: <FormattedMessage id="This chapter" />
                }}
              />
            }
            confirmText={<FormattedMessage id="Remove" />}
            abortText={<FormattedMessage id="Cancel" />}
            onConfirm={() => this.performDelete()}
            onAbort={() => this.cancelDelete()}
          />
        )}
      </>,
      true
    );
  }

  renderDetail(): [any, boolean?] | null {
    const chapter = this.props.chaptersStore.item;
    const {book} = this.props.applicationStore;
    if (!chapter || !book) {
      return null;
    }

    const { form, backRoute } = this.props;

    switch (this.props.active) {
      // common settings
      case 'title':
        return [
          <TitleSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      case 'layout':
        return [
          <LayoutSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
            onQuit={(goBack) => (goBack ? this.goBack() : this.settingsSaved())}
            backLink={this.renderBack()}
          />,
          true
        ];

      case 'print':
        return [
          <PrintSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      case 'toc':
        return [
          <TocSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      case 'description':
        return [
          <DescriptionSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      case 'access':
        return [
          <AccessSettingsContainer
            chapter={chapter}
            form={form}
            backRoute={backRoute}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      // factsheet
      case 'text':
        return [
          <FactsheetTextSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      case 'creative':
        return [
          <FactsheetCreativePageSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      case 'comments':
        return [
          <FactsheetCommentsSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      case 'photos':
        return [
          <FactsheetPhotosSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      case 'order':
        return [
          <OrderSettingsContainer
            chapter={chapter}
            form={form}
            onCommitChapter={(p) => this.updateChapter(p)}
            onCommitSettings={(p) => this.updateSettings(p)}
          />
        ];

      default:
    }

    return [<SettingNotAvailableComponent />];
  }

  renderLoading() {
    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Settings" />
        </Headline.Large>
        <Loading />
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Settings" />
        </Headline.Large>

        <GenericErrorComponent
          onRetryClick={() => this.loadChapter(this.props.chapterId)}
        />
      </>
    );
  }

  renderNoSettings() {
    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Settings" />
        </Headline.Large>

        <SettingNotAvailableComponent />
      </>
    );
  }

  render() {
    const { chaptersStore, active } = this.props;

    if (chaptersStore.isItemLoading) {
      return this.renderLoading();
    }

    if (chaptersStore.isItemLoadError) {
      return this.renderError();
    }

    if (!this.hasSettings()) {
      return this.renderNoSettings();
    }

    if (active) {
      const detail = this.renderDetail();
      if (detail) {
        if (detail[1]) {
          return detail[0];
        }

        return this.renderPage(
          <>
            {detail[0]}
            {this.props.form.loading && <Loading />}
          </>
        );
      }
    }

    return this.renderOverview();
  }
}

export default injectIntl(
  (props: PublicChapterSettingsContainerProps & WrappedComponentProps) => {
    const form = useForm();
    // @ts-ignore
    return <ChapterSettingsContainer {...props} form={form} />;
  }
);
