import React, { useContext, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import { HasAccessContext } from 'contexts/HasAccessContext';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import TextileApplicantDashboardScreen from './TextileApplicantDashboardScreen';
import TextileOrganizerDashboardScreen from './TextileOrganizerDashboardScreen';
import TextileStudentDashboardScreen from './TextileStudentDashboardScreen';

const TextileDashboardScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const accessContext = useContext(HasAccessContext)
  if (!accessContext) {
    throw  Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('dashboard')
  }, [accessContext])

  if (!textileOrderId) {
    return null;
  }

  if (textileAppState && textileAppState.textile_profile.is_organizer) {
    return <TextileOrganizerDashboardScreen {...props} />;
  }

  if (textileAppState && textileAppState.textile_profile.is_student) {
    return <TextileStudentDashboardScreen {...props} />;
  }

  if (textileAppState && textileAppState.textile_profile.is_applicant) {
    return <TextileApplicantDashboardScreen {...props} />;
  }

  return <LoadingIndicatorComponent />;
};

export default TextileDashboardScreen;
