import styled from 'styled-components';

import COLORS from 'utils/constants/colors';

const SponsorBannerBlock = styled.div`
  display: block;
  overflow: hidden;
  box-shadow: none;
  border: none;
  width: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: ${COLORS.PRIMARY};

  img {
    transition: all ease 0.2s;
    width: 100%;
    height: auto;
  }

  > a {
    display: block;
    line-height: 0;
  }
`;

export default SponsorBannerBlock;
