import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextileTeacher } from 'api/textile_deals/fetchTeacherList';
import {
  ButtonListBlock,
  ButtonListBlockBody,
  ButtonListBlockItem
} from 'blocks/ButtonListBlock/ButtonListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import IconComponent from 'components/IconComponent';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TextElement from 'components/TextElement/TextElement';
import { HasAccessContext } from 'contexts/HasAccessContext';
import CancelLink from 'domain/Links/CancelLink';
import TextileGroupList from 'domain/TextileGroupList/TextileGroupList';
import NakedBlockButton from 'elements/NakedBlockButton';
import NakedButton from 'elements/NakedButton';
import UppercaseHeading from 'elements/UppercaseHeading';
import { useAddTextileTeacherToGroupQuery } from 'queries/textile_deals/useTextileNamesQueries';
import { assert } from 'utils/assert';
import { HistoryProps } from 'utils/history';
import { textileOrderRoute } from 'utils/history/textile-order-route';

interface TeachersWithoutGroupsProps {
  teachers: TextileTeacher[];
  textileOrderId?: number;
}

const TeachersWithoutTextileGroup: React.FC<
  HistoryProps & TeachersWithoutGroupsProps
> = (props) => {
  const [selectedTeacher, setSelectedTeacher] = useState<
    TextileTeacher | undefined
  >(undefined);
  const accessContext = useContext(HasAccessContext);
  const updateTextileName = useAddTextileTeacherToGroupQuery(
    props.textileOrderId
  );

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('design');
  }, [accessContext]);

  const addTeacherToGroup = (id: number) => {
    assert(selectedTeacher);
    if (!props.textileOrderId) {
      return;
    }

    updateTextileName.mutate({
      data: { textile_group_id: id },
      id: selectedTeacher.id,
      textileOrderId: props.textileOrderId
    });

    setSelectedTeacher(undefined);
  };

  const cancelLink = () => {
    if (selectedTeacher) {
      return (
        <NakedButton onClick={() => setSelectedTeacher(undefined)}>
          <TextElement color="GRAY10">
            <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
            <FormattedMessage id="Cancel" />
          </TextElement>
        </NakedButton>
      );
    } else {
      return (
        <CancelLink
          to={textileOrderRoute(undefined, '/groups', props.textileOrderId)}
        />
      );
    }
  };

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>{cancelLink()}</TopActionBlock>

        {content}
      </>
    );
  };

  const renderGroupList = () => {
    if (!selectedTeacher) {
      return null;
    }

    return (
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Select group for student" />}
          text={
            <>
              <IconComponent icon="USER" />
              <FormattedMessage
                id="Select group for student name"
                values={{
                  name: `${selectedTeacher.first_name} ${selectedTeacher.last_name}`
                }}
              />
            </>
          }
        />

        <TextileGroupList
          onGroupClick={(_event, id) => addTeacherToGroup(id)}
          textileOrderId={props.textileOrderId}
        />
      </PageStackBlock>
    );
  };

  const renderStudentList = () => {
    const { teachers } = props;
    return (
      <PageStackBlock>
        <PageHeader
          headline={<FormattedMessage id="Teachers without groups" />}
          text={<FormattedMessage id="textile teachers no groups" />}
        />

        <ItemStackBlock gap="XXS">
          <UppercaseHeading>
            {teachers.length} <FormattedMessage id="Teachers" />
          </UppercaseHeading>

          <ListStackBlock>
            <ButtonListBlock>
              {teachers.map((teacher) => {
                if (teacher.textile_group !== null) {
                  return null;
                }

                return (
                  <ButtonListBlockItem key={teacher.id}>
                    <NakedBlockButton
                      onClick={() => setSelectedTeacher(teacher)}
                    >
                      <ButtonListBlockBody>
                        {teacher.name || teacher.id}
                      </ButtonListBlockBody>
                    </NakedBlockButton>
                  </ButtonListBlockItem>
                );
              })}
            </ButtonListBlock>
          </ListStackBlock>
        </ItemStackBlock>
      </PageStackBlock>
    );
  };

  return selectedTeacher
    ? renderPage(renderGroupList())
    : renderPage(renderStudentList());
};

export default (props: TeachersWithoutGroupsProps) => (
  // @ts-ignore
  <TeachersWithoutTextileGroup {...props} />
);
