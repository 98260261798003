import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTE_MOTTOS } from 'utils/constants/routes';

import MottosSelectListItem, {
  MottoListItemProps
} from './MottosSelectListItem';

export default ({ motto }: MottoListItemProps) => (
  <Link to={ROUTE_MOTTOS + '/' + motto.id}>
    <MottosSelectListItem motto={motto} />
  </Link>
);
