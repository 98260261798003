import React from 'react';

import NotAvailableComponent from 'components/print/processing/NotAvailableComponent';

type FontSpec = [string, number[]];
export type Fonts = FontSpec[];

interface FontsPreloaderProps {
  fonts: Fonts;
  print?: boolean;
  loadingComponent?: any;
  errorComponent?: any;
}

enum PreloaderState {
  LOADING = 1,
  READY,
  ERROR
}

interface FontsPreloaderState {
  innerState: PreloaderState;
}

const FONT_SIZE = '16px';

export const arrUnique = (arr: any[]): any[] =>
  arr.filter((value, index, self) => self.indexOf(value) === index);

const uniqueFonts = (fonts: Fonts): Fonts => {
  const map: { [key: string]: FontSpec } = {};

  fonts.forEach((font) => {
    if (!map[font[0]]) {
      map[font[0]] = [font[0], font[1].slice()];
    } else {
      map[font[0]][1] = map[font[0]][1].concat(font[1]);
    }
  });

  return Object.values(map).map((font) => [font[0], arrUnique(font[1]).sort()]);
};

// global variable to keep track of fonts we already included in the page's styles
const alreadyIncludedFonts: string[] = [];

class FontsPreloader extends React.Component<
  FontsPreloaderProps,
  FontsPreloaderState
> {
  state = { innerState: PreloaderState.LOADING };
  private unmounted = false;
  private timeoutHandle: number | undefined;

  componentDidMount() {
    this.preload();
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutHandle);
    this.unmounted = true;
  }

  componentDidUpdate(prevProps: FontsPreloaderProps) {
    if (
      // this.state.innerState !== PreloaderState.LOADING &&
      JSON.stringify(prevProps.fonts) !== JSON.stringify(this.props.fonts)
    ) {
      this.setState({
        innerState: PreloaderState.LOADING
      });
      this.preload();
    }
  }

  preload() {
    const { fonts, print } = this.props;

    const willImportCss = uniqueFonts(fonts).map(([fontFamily, fontWeights]) =>
      createImportStrings(fontFamily, fontWeights, !!print)
    );

    Promise.all(willImportCss)
      .then((res) => {
        const importCss = res.flat().join('\n');

        const styleElement = document.createElement('style');
        styleElement.setAttribute('type', 'text/css');

        const cssNode = document.createTextNode(importCss);
        styleElement.appendChild(cssNode);

        document.head.appendChild(styleElement);

        this.timeoutHandle = window.setTimeout(() => {
          waitForFonts(fonts)
            .then(() => {
              if (!this.unmounted) {
                this.setState({ innerState: PreloaderState.READY });
              }
            })
            .catch(() => {
              if (!this.unmounted) {
                this.setState({ innerState: PreloaderState.ERROR });
              }
            });
        }, 100);
      })
      .catch(() => this.setState({ innerState: PreloaderState.ERROR }));
  }

  render() {
    const { children, print, loadingComponent, errorComponent } = this.props;
    const { innerState } = this.state;

    if (print) {
      if (
        innerState === PreloaderState.LOADING ||
        innerState === PreloaderState.ERROR
      ) {
        return <NotAvailableComponent fonts={true} />;
      } else if (innerState === PreloaderState.READY) {
        return children;
      } else {
        return null;
      }
    } else {
      if (innerState === PreloaderState.LOADING) {
        return loadingComponent
          ? typeof loadingComponent === 'function'
            ? loadingComponent()
            : loadingComponent
          : null;
      } else if (innerState === PreloaderState.ERROR) {
        return errorComponent
          ? typeof errorComponent === 'function'
            ? errorComponent()
            : errorComponent
          : null;
      } else if (innerState === PreloaderState.READY) {
        return children;
      } else {
        return null;
      }
    }
  }
}

export default FontsPreloader;

async function createImportStrings(
  fontFamily: string,
  fontWeights: number[],
  print: boolean
) {
  const encodedFontFamily = encodeURIComponent(fontFamily);

  let fontWeightStrings = [];

  if (print) {
    fontWeightStrings = fontWeights.map((w) => w.toString());
  } else {
    fontWeightStrings = [fontWeights.join(';')];
  }

  const urlCollection = [];

  for (const fontWeightString of fontWeightStrings) {
    const url = `https://fonts.googleapis.com/css2?family=${encodedFontFamily}:wght@${fontWeightString}&display=swap`;

    if (alreadyIncludedFonts.indexOf(url) > -1) {
      continue;
    }

    const fontStyles = await fetch(url).then((res) => res.text());

    alreadyIncludedFonts.push(url);
    urlCollection.push(fontStyles);
  }

  return urlCollection;
}

async function waitForFonts(fonts: Fonts): Promise<void> {
  for (const [fontFamily, fontWeights] of fonts) {
    for (const weight of fontWeights) {
      await document.fonts.load(`${weight} ${FONT_SIZE} '${fontFamily}'`);
    }
  }
}
