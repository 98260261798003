import React from 'react';
import style from 'styled-components';

import sanitizeHtml from 'utils/chunker/sanitize-html';

interface NamesContainerProps {
  font?: string;
  color?: string;
}

const NamesContainer = style.div<NamesContainerProps>`
  position: absolute;
  top: 156px;
  left: 156px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 729px;
  height: 1004px;
  font-family: ${props => props.font};
  color: ${props => props.color};
`;

const Header = style.p`
  margin: 0;
  margin-right: 33px;
  margin-bottom: 40px;
  width: 100%;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
`;

const Row = style.div`
  flex-shrink: 1;
  flex-basis: 58px;
  align-items: stretch;
  display: flex;
  height: auto;
`;

const TwoColumnsElement = style.p`
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  margin-right: 33px;
  width: 50%;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
`;

const ThreeColumnsElement = style.p`
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
  margin-right: 33px;
  width: 50%;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`;

export default ({
  names,
  headline,
  color,
  headlineColor,
  font
}: {
  names: string[][];
  headline?: string;
  color?: string;
  headlineColor?: string;
  font?: string;
}) => {
  const columns = names.length;
  if (columns < 2 || columns > 3) {
    return null;
  }

  return (
    <NamesContainer font={font} color={color}>
      {headline && (
        <Header
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(headline) || '' }}
          style={{
            color: headlineColor || color
          }}
        />
      )}

      {names[0].map((name, idx) => {
        if (columns === 2) {
          return (
            <Row key={'row#' + idx}>
              <TwoColumnsElement
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(name) || '' }}
              />
              <TwoColumnsElement
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(names[1][idx]) || ''
                }}
              />
            </Row>
          );
        }

        return (
          <Row key={'row#' + idx}>
            <ThreeColumnsElement
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(name) || '' }}
            />
            <ThreeColumnsElement
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(names[1][idx]) || ''
              }}
            />
            <ThreeColumnsElement
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(names[2][idx]) || ''
              }}
            />
          </Row>
        );
      })}
    </NamesContainer>
  );
};
