import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_YEARBOOK, ROUTE_YEARBOOKS } from 'utils/constants/routes';

import OwnYearbookScreen from './OwnYearbookScreen';
import SplitYearbookChapterScreen from './screens/SplitYearbookChapterScreen';
import YearbookDetailScreen from './screens/YearbookDetail/YearbookDetailScreen';
import YearbookPhotoScreen from './screens/YearbookDetail/YearbookPhotoScreen';
import YearbookTextScreen from './screens/YearbookDetail/YearbookTextScreen';
import YearbookList from './screens/YearbookList/YearbookList';
import YearbookSettings from './screens/YearbookSettings';

export default () => (
  <Switch>
    <Route path={ROUTE_YEARBOOKS} exact={true} component={OwnYearbookScreen} />

    <Route path={ROUTE_YEARBOOK} exact={true} component={YearbookList} />

    <Route
      path={ROUTE_YEARBOOKS + '/detail/:id'}
      exact={true}
      component={YearbookDetailScreen}
    />
    <Route
      path={ROUTE_YEARBOOKS + '/detail/:id/text'}
      component={YearbookTextScreen}
    />
    <Route
      path={ROUTE_YEARBOOKS + '/detail/:id/photo'}
      component={YearbookPhotoScreen}
    />

    <Route
      path={ROUTE_YEARBOOK + '/settings'}
      exact={true}
      component={YearbookSettings}
    />
    <Route
      path={ROUTE_YEARBOOK + '/split'}
      exact={true}
      component={SplitYearbookChapterScreen}
    />

    <NotFoundRoute />
  </Switch>
);
