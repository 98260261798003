import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TopActionBlock from 'blocks/TopActionBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import ItemSortingComponent from 'components/ItemSortingComponent/ItemSortingComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import BackLink from 'domain/Links/BackLink';
import { assert } from 'utils/assert';
import { ROUTE_QUOTES } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';

import { QuotesStoreType } from '../QuotesStore';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import HelpSubject from 'components/HelpButton/HelpSubject';

interface Props {
  quotesStore: QuotesStoreType;
}

@inject('quotesStore')
@observer
class SortQuotesScreen extends React.Component<Props & HistoryProps> {
  componentDidMount() {
    this.loadQuotes();
  }

  loadQuotes() {
    const { quotesStore } = this.props;
    const chapterId = this.getChapterId();
    quotesStore.getQuotesByChapter(chapterId);
  }

  getChapterId() {
    const chapterId = chapterIdFromMatch(this.props.match);
    assert(chapterId);
    return chapterId;
  }

  onSortEnd(oldIndex: number, newIndex: number) {
    this.props.quotesStore.moveQuote(oldIndex, newIndex);
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadQuotes()} />
    );
  }

  renderPage(content: React.ReactNode) {
    return (
      <>
        <TopActionBlock>
          <BackLink
            messageId="Done"
            to={ROUTE_QUOTES + '/' + this.getChapterId()}
          />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader headline={<FormattedMessage id="Sort quotes" />} text={<FormattedMessage id="Sort quotes info" />} />

          {content}
        </PageStackBlock>
        
        <HelpSubject subject="quotes" />
      </>
    );
  }

  render() {
    const { quotesStore } = this.props;

    if (quotesStore.isListLoading) {
      return this.renderLoading();
    }

    if (quotesStore.isListError) {
      return this.renderError();
    }

    return this.renderPage(
      <ItemSortingComponent
        items={quotesStore.allQuotes.map((quote) => ({
          id: quote.id,
          text: quote.quote || ''
        }))}
        onSortEnd={(oldIndex, newIndex) => this.onSortEnd(oldIndex, newIndex)}
      />
    );
  }
}

export default SortQuotesScreen;
