import React, { useEffect, useState } from 'react';

import config from 'config';
import { HistoryProps } from 'utils/history';

import { TextileThemeLayout } from 'api/textile_deals/fetchTextileThemes';
import { TextileVariant } from 'api/textile_deals/fetchTextileVariants';
import PrintExportErrorComponent from './PrintExportErrorComponent';
import PrintTextileMockUpComponent from './textiles/PrintTextileMockUpComponent';
import { TextilePrintMockUpParams } from './textiles/PrintTextileMockUpProps';
const BASE_PATH_DEV = '/print-export';
const BASE_PATH_PROD = `${BASE_PATH_DEV}/${process.env.NUGGIT_ENVIRONMENT}`;

const PrintTextileMockUpContainer: React.FC<HistoryProps> = (props) => {
  const printParamsFromProps = (): TextilePrintMockUpParams => {
    const query = props.location.query || {};

    return {
      printId: query.printId || 'unknown',
      variantId: query.variantId || '0',
      textile: query.textile || 'front'
    };
  };
  const [loadingState, setLoadingState] = useState<string | undefined>(
    undefined
  );
  const params = printParamsFromProps();
  const [data, setData] = useState<any>(undefined);

  const loadTextileVariant = async() => {
    const { printId, variantId } = params;
    const basePath = config.env.development ? BASE_PATH_DEV : BASE_PATH_PROD;

    try {
      // fetch export JSON
      const response = await fetch(
        basePath +
          '/textile_variant_' +
          printId +
          '/variantId-' +
          variantId +
          '.json'
      );
      const json = await response.json();

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('loadSettings result:', printId, variantId, json);
      }

      setLoadingState('ready');
      setData(json);
    } catch (err) {
      console.error('loadSettings error:', err);

      setLoadingState('error');
    }
  }

  useEffect(() => {
    loadTextileVariant();
  }, []);

  const renderSettings = () => {
    if (!data) {
      return renderMessage('no textile data present');
    }

    const theme: TextileThemeLayout = data.theme;
    const layoutKey = data.layout_key;
    const variant: TextileVariant = data.textile_variant;
    const backImage = data.back_image;
    if (!theme) {
      return renderMessage('textile_layout missing');
    }


    switch (params.textile) {
      case 'mockup_front':
        return (
          <PrintTextileMockUpComponent
            theme={theme!}
            layoutKey={layoutKey!}
            variant={variant!}
            type="front"
          />
        );
      case 'mockup_back':
        return (
          <PrintTextileMockUpComponent
            theme={theme!}
            layoutKey={layoutKey!}
            variant={variant!}
            type="back"
            backImage={backImage}
          />
        );
      default:
        return renderMessage('textile type not supported');
    }
  };

  const renderMessage = (message: string) => {
    return <PrintExportErrorComponent message={message} />;
  };

  switch (loadingState) {
    case 'loading':
    case 'error':
      return renderMessage('Print state: ' + loadingState);

    case 'ready':
      return renderSettings();

    default:
      return renderMessage('Print state: not initialized');
  }
};

export default PrintTextileMockUpContainer;
