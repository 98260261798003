import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NotFoundRoute } from 'screens/NotFoundScreen';
import { ROUTE_ONBOARDING } from 'utils/constants/routes';

import OnboardingCommitteeScreen from './screens/OnboardingCommitteeScreen';
import OnboardingSizeScreen from './screens/OnboardingSizeScreen';
import OnboardingInviteScreen from './screens/OnboardingInviteScreen';

export default () => (
  <Switch>
    <Route
      path={ROUTE_ONBOARDING}
      exact={true}
      component={OnboardingCommitteeScreen}
    />

    <Route
      path={ROUTE_ONBOARDING + '/class'}
      component={OnboardingSizeScreen}
    />

    <Route
      path={ROUTE_ONBOARDING + '/invite'}
      component={OnboardingInviteScreen}
    />

    <NotFoundRoute />
  </Switch>
);
