import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import IconComponent from 'components/IconComponent';
import BackLink from 'domain/Links/BackLink';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { CommentsStoreType } from 'screens/comments/CommentsStore';
import CommentsList from 'screens/comments/containers/CommentsList/CommentsList';
import { ROUTE_COMMENTS, ROUTE_FACTSHEETS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch } from 'utils/history';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';

interface FactsheetCommentsScreenProps {
  applicationStore: ApplicationStoreType;
  commentsStore: CommentsStoreType;
}

@inject('applicationStore', 'commentsStore')
@observer
class FactsheetCommentsScreen extends React.Component<
FactsheetCommentsScreenProps & HistoryProps
> {
  render() {
    const { commentsStore, applicationStore, history, match } = this.props;
    const profileId = idFromMatch(match);
    const itIsMe = applicationStore.currentUserId === profileId;

    const { commentsProfile } = commentsStore;

    return (
      <>
        <TopActionBlock>
          <BackLink to={ROUTE_FACTSHEETS + '/detail/' + profileId} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader headline={itIsMe ? (
            <FormattedMessage id="Comments for me" />
          ) : (
            <FormattedMessage id="Comments" />
          )} text={!itIsMe && commentsProfile && commentsProfile.id === profileId && (
            <>
              <IconComponent icon="user" />
              {commentsProfile.fullName}
            </>)} />

          {applicationStore.isOrganizer && applicationStore.isEditAllowed && (
            <>
              <ButtonBlock
                background="PRIMARY"
                onClick={() =>
                  history.push(ROUTE_COMMENTS + '/new?for=' + profileId)
                }
              >
                <FormattedMessage id="Add comment" />
              </ButtonBlock>
              <Divider />
            </>
          )}

          <CommentsList
            history={history}
            profileId={profileId}
            withEditLink={true}
            editLinkFrom="factsheet"
          />
        </PageStackBlock>
      </>
    );
  }
}

export default FactsheetCommentsScreen;
