import {
  CreatePhotoBody,
  UpdatePhotoBody
} from 'api/NuggitApi';
import {
  Instance,
  clone,
  flow,
  getEnv,
  types
} from 'mobx-state-tree';
import ChapterModel, {
  ChapterModelType,
  createChapterModel
} from 'models/ChapterModel';
import PhotoModel, { PhotoModelType } from 'models/PhotoModel';
import { AdvancedStoreEnv } from 'models/StoreEnv';
import { assert } from 'utils/assert';
import { createMapWithTransform } from 'utils/create-map';
import { sortById } from 'utils/sort-functions';
import createPhotoModel from 'utils/store/createPhotoModel';

const PhotosStore = types
  .model('PhotosStore', {
    // photos list
    photosLoadingState: types.maybe(types.enumeration(['loading', 'error'])),
    photos: types.maybe(types.map(PhotoModel)),
    chapter: types.maybe(ChapterModel),
    // album single item
    photoItemLoadingState: types.maybe(
      types.enumeration(['loading', 'error', 'update_error'])
    ),
    photoItem: types.maybe(PhotoModel)
  })
  .actions((self) => {
    const getAllPhotos = flow(function* () {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photosLoadingState = 'loading';
        self.photos = undefined;
        self.chapter = undefined;

        const result = yield client.getAllPhotos();
        if (!result?.length) {
          self.photosLoadingState = undefined;
          return;
        }

        self.photos = createMapWithTransform(result, createPhotoModel);

        self.photosLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | getAllPhotos', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photosLoadingState = undefined;
          return;
        }

        self.photosLoadingState = 'error';
      }
    });

    const getPhotosByChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photosLoadingState = 'loading';
        self.photos = undefined;
        self.chapter = undefined;

        const result = yield client.getAllPhotos(chapterId);

        if (!result) {
          throw new Error('No response from server');
        }

        if (Array.isArray(result.photos)) {
          self.photos = createMapWithTransform(result.photos, createPhotoModel);
        }
        self.chapter = createChapterModel(result.chapter || { id: chapterId });

        self.photosLoadingState = undefined;

        return self.photos;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | getPhotosByChapter', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photosLoadingState = undefined;
          return;
        }

        self.photosLoadingState = 'error';
      }
    });

    const getUnplacedPhotosByChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photosLoadingState = 'loading';
        self.photos = undefined;

        const result = yield client.getUnplacedPhotos(chapterId);

        self.photos = createMapWithTransform(result, createPhotoModel);
        self.photosLoadingState = undefined;

        return self.photos;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'PhotosStore | getUnplacedPhotosByChapter',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.photosLoadingState = undefined;
          return;
        }

        self.photosLoadingState = 'error';

        throw error;
      }
    });

    const getPhoto = flow(function* (id: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photoItemLoadingState = 'loading';
        self.photoItem = undefined;

        const item = yield client.getPhoto(id);

        self.photoItem = createPhotoModel(item);
        self.photoItemLoadingState = undefined;

        return self.photoItem;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | getPhoto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photoItemLoadingState = undefined;
          return;
        }

        self.photoItemLoadingState = 'error';
      }
    });

    const getPhotoTextile = flow(function* (id: number, textileOrderId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photoItemLoadingState = 'loading';
        self.photoItem = undefined;

        const item = yield client.getPhotoTextile(id, textileOrderId);

        self.photoItem = createPhotoModel(item);
        self.photoItemLoadingState = undefined;

        return self.photoItem;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | getPhoto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photoItemLoadingState = undefined;
          return;
        }

        self.photoItemLoadingState = 'error';
      }
    });

    const createPhoto = flow(function* (body: CreatePhotoBody) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photoItemLoadingState = 'loading';
        self.photoItem = undefined;

        if (
          [
            'creative_page',
            'cover_front_creative',
            'cover_back_creative'
          ].includes(body.photo_type)
        ) {
          body.image_config = {
            cutOff: {
              width: 100,
              height: 100,
              top: 0,
              left: 0
            },
            rotation: 0,
            crop: false
          };
        }

        const result = yield client.createPhoto(body);

        if (result) {
          self.photoItem = createPhotoModel(result);

          // Add to album
          if (body.photo_type === 'album') {
            const photo = createPhotoModel(result);
            assert(photo);
            self.photos?.put(photo);
          }
        }

        self.photoItemLoadingState = undefined;

        return self.photoItem;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | createPhoto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photoItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.photoItemLoadingState = undefined;
          throw error;
        }

        self.photoItemLoadingState = 'update_error';
        throw error;
      }
    });

    const createPhotoTextile = flow(function* (body: CreatePhotoBody, textileOrderId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photoItemLoadingState = 'loading';
        self.photoItem = undefined;

        const result = yield client.createPhotoTextile(body, textileOrderId);

        if (result) {
          self.photoItem = createPhotoModel(result);

          // Add to album
          if (body.photo_type === 'album') {
            const photo = createPhotoModel(result);
            assert(photo);
            self.photos?.put(photo);
          }
        }

        self.photoItemLoadingState = undefined;

        return self.photoItem;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | createPhoto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photoItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.photoItemLoadingState = undefined;
          throw error;
        }

        self.photoItemLoadingState = 'update_error';
        throw error;
      }
    });

    const updatePhoto = flow(function* (patch: UpdatePhotoBody) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photoItemLoadingState = 'loading';

        const result = yield client.updatePhoto(patch);
        const model = createPhotoModel(result);

        if (model) {
          if (self.photos?.has(model.id.toString())) {
            self.photos.put(model);
          }

          if (self.photoItem?.id === model.id) {
            self.photoItem = clone(model);
          }
        }

        self.photoItemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | updatePhoto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photoItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.photoItemLoadingState = undefined;
          throw error;
        }

        self.photoItemLoadingState = 'update_error';
        throw error;
      }
    });

    const updatePhotoTextile = flow(function* (patch: UpdatePhotoBody, textileOrderId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photoItemLoadingState = 'loading';

        const result = yield client.updatePhotoTextile(patch, textileOrderId);
        const model = createPhotoModel(result);

        if (model) {
          if (self.photos?.has(model.id.toString())) {
            self.photos.put(model);
          }

          if (self.photoItem?.id === model.id) {
            self.photoItem = clone(model);
          }
        }

        self.photoItemLoadingState = undefined;
        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | updatePhoto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photoItemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.photoItemLoadingState = undefined;
          throw error;
        }

        self.photoItemLoadingState = 'update_error';
        throw error;
      }
    });

    const removePhoto = flow(function* (id: number, removeFromList = true) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photoItemLoadingState = 'loading';

        yield client.removePhoto(id);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | removePhoto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photoItemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.photoItemLoadingState = 'update_error';
          throw error;
        }
      }

      if (removeFromList && self.photos) {
        self.photos.delete(id.toString());
      }

      if (self.photoItem && self.photoItem.id === id) {
        self.photoItem = undefined;
      }

      self.photoItemLoadingState = undefined;
    });

    const removePhotoTextile = flow(function* (id: number, textileOrderId: number) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        self.photoItemLoadingState = 'loading';

        yield client.removePhotoTextile(id, textileOrderId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('PhotosStore | removePhoto', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.photoItemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.photoItemLoadingState = 'update_error';
          throw error;
        }
      }

      if (self.photoItem && self.photoItem.id === id) {
        self.photoItem = undefined;
      }

      self.photoItemLoadingState = undefined;
    });

    const clearCurrentPhotoItem = () => {
      self.photoItem = undefined;
      self.photoItemLoadingState = undefined;
    };

    const setChapter = (chapter?: ChapterModelType) => {
      self.chapter = createChapterModel(chapter);
    };

    return {
      getAllPhotos,
      getPhotosByChapter,
      getUnplacedPhotosByChapter,
      getPhoto,
      createPhoto,
      updatePhoto,
      removePhoto,
      getPhotoTextile,
      createPhotoTextile,
      updatePhotoTextile,
      removePhotoTextile,
      clearCurrentPhotoItem,
      setChapter
    };
  })
  .views((self) => {
    return {
      get allPhotos(): PhotoModelType[] {
        const items: PhotoModelType[] = [];
        if (!self.photos) {
          return items;
        }

        for (const item of self.photos.values()) {
          items.push(item);
        }

        items.sort(sortById);
        return items;
      },
      myPhotos(myId: number): PhotoModelType[] {
        return this.allPhotos.filter(
          (item) => item.author && item.author.id === myId
        );
      },
      get placedPhotos(): PhotoModelType[] {
        return this.allPhotos.filter((item) => item.placed_in_album);
      },
      get unplacedPhotos(): PhotoModelType[] {
        return this.allPhotos.filter((item) => !item.placed_in_album);
      },

      get isPhotosLoading(): boolean {
        return self.photosLoadingState === 'loading';
      },
      get isPhotosError(): boolean {
        return self.photosLoadingState === 'error';
      },

      get isPhotoItemLoading(): boolean {
        return self.photoItemLoadingState === 'loading';
      },
      get isPhotoItemLoadingError(): boolean {
        return self.photoItemLoadingState === 'error';
      },
      get isPhotoItemUpdateError(): boolean {
        return self.photoItemLoadingState === 'update_error';
      },
      get isPhotoItemError(): boolean {
        return (
          self.photoItemLoadingState === 'error' ||
          self.photoItemLoadingState === 'update_error'
        );
      }
    };
  });

export type PhotosStoreType = Instance<typeof PhotosStore>;
export default PhotosStore;
