import { inject, observer } from 'mobx-react';
import React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { SetupStoreType } from 'models/SetupStore';
import { pushStartSignup } from 'utils/analytics';
import { ROUTE_SIGNUP } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType } from 'utils/hooks/useForm';

interface SignupNameScreenProps {
  setupStore: SetupStoreType;
  form: FormType;
  token?: string;
}

@inject('setupStore')
@observer
class SignupNameScreen extends React.Component<
  SignupNameScreenProps & HistoryProps & WrappedComponentProps
> {
  componentDidMount() {
    const { setupStore, history } = this.props;

    if (!setupStore.isSignupInitialized) {
      if (this.hasToken()) {
        setupStore.initSignup(this.props.token);
      } else {
        history.replace(ROUTE_SIGNUP);
      }
    } else if (setupStore.account) {
      this.props.form.setField(
        'first_name',
        setupStore.account.first_name || ''
      );
      this.props.form.setField('last_name', setupStore.account.last_name || '');
    }

    if (setupStore.isSignupDone) {
      this.props.history.replace(ROUTE_SIGNUP + '/done');
      return;
    }
  }

  hasToken() {
    return this.props.token || this.props.setupStore.token ? true : false;
  }

  handleSubmit() {
    const { form, intl, setupStore } = this.props;

    form.resetErrors();

    let hasError = false;

    if (!form.values.first_name || !form.values.first_name.trim()) {
      form.setError(
        'first_name',
        intl.formatMessage({ id: 'form error mandatory' })
      );
      hasError = true;
    }

    if (!form.values.last_name || !form.values.last_name.trim()) {
      form.setError(
        'last_name',
        intl.formatMessage({ id: 'form error mandatory' })
      );
      hasError = true;
    }

    if (hasError) {
      return;
    }

    setupStore.setName(form.values.first_name, form.values.last_name);

    pushStartSignup();

    this.props.history.push(ROUTE_SIGNUP + '/number');
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <SignupHeaderComponent
          backRoute={this.hasToken() ? ROUTE_SIGNUP : ROUTE_SIGNUP + '/year'}
          progressStatus={(100 / 10) * 6}
        />

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="signup name header" />}
            text={<FormattedMessage id="signup name text" />}
          />

          <FormStackBlock>
            <TextInputComponent
              name="first_name"
              label={intl.formatMessage({ id: 'First name' })}
              {...this.props.form.bindInput('first_name')}
              autoFocus={true}
            />

            <TextInputComponent
              name="last_name"
              label={intl.formatMessage({ id: 'Last name' })}
              {...this.props.form.bindInput('last_name')}
            />
          </FormStackBlock>

          <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
            <FormattedMessage id="Next" />
          </ButtonBlock>
          {this.hasToken() && (
            <>
              <Divider />

              <ItemStackBlock gap="S">
                <Paragraph size="L" color="TEXT_LIGHT">
                  <FormattedMessage id="Account already exists?" />
                </Paragraph>
                <Link to="/login">
                  <ButtonBlock color="PRIMARY_DARK" background="PRIMARY_LIGHT">
                    <FormattedMessage id="Login now" />
                  </ButtonBlock>
                </Link>
              </ItemStackBlock>
            </>
          )}
        </PageStackBlock>
      </>
    );
  }
}

export default injectIntl((props: any) => {
  const form = useForm();
  return <SignupNameScreen {...props} form={form} />;
});
