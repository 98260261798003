import React from 'react';

import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import IconComponent from 'components/IconComponent';
import Headline from 'elements/Headline';
import { FormattedMessage } from 'react-intl';
import { FormType } from 'utils/hooks/useForm';
import { CheckoutModelType } from 'models/CheckoutModel';
import { ACCEPT_IMAGES_AND_PDFS } from 'components/Inputs/FileUploadInput';
import FileUploadComponent from 'components/Inputs/FileUploadComponent';
import {
  EveryCard,
  EveryCardPadding,
  EveryCardBody,
  EveryCardHeadline
} from 'blocks/EveryCard/EveryCard';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';

const OrderAgeVerification = ({
  form,
  checkout,
  handleUploadParentalApproval
}: {
  form: FormType;
  checkout: CheckoutModelType;
  handleUploadParentalApproval: (file: File) => void;
}) => (
    <>
      {checkout.parental_approval_checked ? (
        <ItemStackBlock gap="XS">
          <Headline.Medium>
            <FormattedMessage id="Checkout age parental consent" />
          </Headline.Medium>
          <HorizontalStackBlock gap="S">
            <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
            <FormattedMessage id="Checkout parental approval success" />
          </HorizontalStackBlock>
        </ItemStackBlock>
      ) : (
          checkout.parental_approval ? (
            <ItemStackBlock gap="XS">
              <Headline.Medium>
                <FormattedMessage id="Checkout age parental consent" />
              </Headline.Medium>
              <HorizontalStackBlock gap="S" centered={true}>
                <IconComponent icon="HOURGLASS" fill="PRIMARY" size={2} />
                <FormattedMessage id="Checkout parental approval check" />
              </HorizontalStackBlock>
            </ItemStackBlock>
          ) : (
              <>
                <ItemStackBlock gap="XS">
                  <Headline.Medium>
                    <FormattedMessage id="Checkout age parental consent" />
                  </Headline.Medium>
                  <Paragraph color="TEXT_LIGHT">
                    <FormattedMessage id="Checkout age parental consent info" />
                  </Paragraph>
                </ItemStackBlock>

                <a
                  href="/documents/Einverständniserklärung-nuggit.pdf"
                  download="Einverständniserklärung-nuggit.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <EveryCard>
                    <EveryCardPadding>
                      <EveryCardBody>
                        <EveryCardHeadline>
                          <FormattedMessage id="Download form" />
                        </EveryCardHeadline>
                        <TextElement>
                          <FormattedMessage id="PDF from size" />
                        </TextElement>
                      </EveryCardBody>
                      <IconComponent icon="DOWNLOAD" fill="BLACK" size={1.5} />
                    </EveryCardPadding>
                  </EveryCard>
                </a>

                <FileUploadComponent
                  name="parental_approval"
                  accept={ACCEPT_IMAGES_AND_PDFS}
                  autoUpload={true}
                  onUploadPhoto={handleUploadParentalApproval}
                  renderButton={true}
                  {...form.bindInput('parental_approval')}
                />
              </>
            ))}
    </>
  );

export default OrderAgeVerification;
