import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import CheckboxBlock from 'blocks/CheckboxBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import { BookModelType } from 'models/BookModel';
import ContentNotPrinted from 'screens/dashboards/ContentNotPrinted/ContentNotPrinted';
import { FormType } from 'utils/hooks/useForm';

interface PrintingScreenProps {
  form: FormType;
  updatePrinting: () => void;
  regeneratePDF: () => void;
  book: BookModelType;
}

const PrintingScreen = ({
  form,
  updatePrinting,
  regeneratePDF,
  book
}: PrintingScreenProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (book.needs_pdf_regeneration) {
    return (
      <>
        <PageHeader headline={<FormattedMessage id="Checkout print header" />} text={<FormattedMessage id="Checkout print info regenerate" />} />

        <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK" onClick={() => regeneratePDF()}>
          <FormattedMessage id="Generate print PDF" />
        </ButtonBlock>
      </>
    )
    } else if (book.price_tier === 'silver') {
    return (
      <>
        <PageHeader headline={<FormattedMessage id="Checkout print header" />} text={<FormattedMessage id="Checkout print info silver" />} />

        <CheckboxBlock>
          <CheckboxBlock.CheckboxElement
            id="accept_print"
            {...form.bindCheckbox('accept_print')}
          />
          <CheckboxBlock.LabelElement htmlFor="accept_print">
            <FormattedMessage id="Checkout print Accept" />
          </CheckboxBlock.LabelElement>
        </CheckboxBlock>

        {form.values.accept_print ? (
          <ButtonBlock background="PRIMARY" onClick={() => updatePrinting()}>
            <FormattedMessage id="Next" />
          </ButtonBlock>
        ) : (
          <ButtonBlock
            background="GRAY950"
            color="GRAY800"
            disabled={true}
            onClick={() => updatePrinting()}
          >
            <FormattedMessage id="Next" />
          </ButtonBlock>
        )}
      </>
    );
  } else {
    return (
      <>
        <PageHeader headline={<FormattedMessage id="Checkout print header" />} text={<FormattedMessage id="Checkout print info" />} />

        <ContentNotPrinted link={false} />

        <p>
          <a
            href={book.print_preview_pdf}
            download="nuggit-Vorschau.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            <ButtonBlock background="PRIMARY_LIGHT" color="PRIMARY_DARK">
              <FormattedMessage id="Checkout check print" />
            </ButtonBlock>
          </a>
        </p>

        <CheckboxBlock>
          <CheckboxBlock.CheckboxElement
            id="accept_print"
            {...form.bindCheckbox('accept_print')}
          />
          <CheckboxBlock.LabelElement htmlFor="accept_print">
            <FormattedMessage id="Checkout print Accept" />
          </CheckboxBlock.LabelElement>
        </CheckboxBlock>

        {form.values.accept_print ? (
          <ButtonBlock background="PRIMARY" onClick={() => updatePrinting()}>
            <FormattedMessage id="Next" />
          </ButtonBlock>
        ) : (
          <ButtonBlock
            background="GRAY950"
            color="GRAY800"
            disabled={true}
            onClick={() => updatePrinting()}
          >
            <FormattedMessage id="Next" />
          </ButtonBlock>
        )}
      </>
    );

  }

};
export default PrintingScreen;
