import { types, Instance } from 'mobx-state-tree';
import mandatoryId from 'utils/store/mandatoryId';

import SponsorModel, {
  createSponsorModel,
  SponsorModelType
} from './SponsorModel';

const SponsorPageSponsorModel = types.model('SponsorPageSponsorModel', {
  id: types.identifierNumber,
  position: types.number,
  sponsor: SponsorModel
});

export const createSponsorPageSponsor = (data: {
  id?: number;
  position: number;
  sponsor: SponsorModelType;
}) => {
  const sponsor = createSponsorModel(data.sponsor);
  if (!sponsor) {
    throw new Error('Sponsor cant be undefined');
  }

  return SponsorPageSponsorModel.create({
    id: mandatoryId(data.id),
    position: data.position,
    sponsor
  });
};

export type SponsorPageSponsorModelType = Instance<
  typeof SponsorPageSponsorModel
>;
export default SponsorPageSponsorModel;
