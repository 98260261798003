import styled from 'styled-components';

import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';
import FONT_SIZES from 'utils/constants/fontSizes';
import FONT_WEIGHTS from 'utils/constants/fontWeights';

interface TagProps {
  backgroundColor?: keyof typeof COLORS;
  color?: keyof typeof COLORS;
}

const Tag = styled.span<TagProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  padding: 0.0625rem 0.25rem;
  font-size: ${FONT_SIZES.XS};
  border-radius: 0.125rem;
  line-height: 0.975rem;
  font-family: ${FONTS.DEFAULT};
  font-weight: ${FONT_WEIGHTS.BOLD};
  letter-spacing: 0;
  width: max-content;
  color: ${(props) => (props.color ? COLORS[props.color] : 'inherit')};
  background-color: ${(props) =>
    props.backgroundColor ? COLORS[props.backgroundColor] : 'inherit'};

  > svg {
    margin-right: 0.3125rem;
  }
`;

export default Tag;
