import styled from 'styled-components';

import COLORS from 'utils/constants/colors';
import FONTS from 'utils/constants/fonts';

interface LargeProps {
  setHyphen?: boolean;
  color?: keyof typeof COLORS;
}

const Large = styled.h1<LargeProps>`
  font-size: 2rem;
  line-height: 1.1875;
  font-family: ${FONTS.DEFAULT};
  font-weight: 900;
  letter-spacing: 0;
  color: ${(props) => (props.color ? COLORS[props.color] : 'inherit')};
  ${(props) =>
    props.setHyphen &&
    `
    hyphens: auto;
  `};

  > svg {
    margin-right: 0.25em;
    vertical-align: -0.1em;
  }
`;

export default Large;
