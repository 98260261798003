export const sliceNames = (names: string[], splits: number): string[][] => {
  const count = names.length;
  const unusedNames = names;

  const namesPerLineWithoutRemainder = Math.floor(
    count / splits
  );

  const namesRemainder = count % splits;

  const slices = [];
  let lastIndex = 0;

  if (splits !== 1) {
    for (let i = 1; i <= splits; i++) {
      let namesPerColumn = namesPerSplit(
        namesPerLineWithoutRemainder,
        namesRemainder,
        i,
        splits
      );


      const namesForSplit = unusedNames.slice(lastIndex, lastIndex + namesPerColumn);

      lastIndex += namesPerColumn;

      if (namesForSplit.length !== 0) {
        slices.push(namesForSplit);
      }
    }
  } else {
    slices.push(names);
  }

  return slices;
}

const namesPerSplit = (
  namesPerSplitWithoutRemainder: number,
  namesRemainder: number,
  split: number,
  numberOfSplits: number
): number  => {
  let namesPerColumn = namesPerSplitWithoutRemainder;

  if (namesRemainder === 1) {
    if (numberOfSplits !== 3 && split === 1) {
      namesPerColumn += 1;
    }

    if (numberOfSplits === 3 && split === 2) {
      namesPerColumn += 1;
    }
  }

  if (namesRemainder === 2) {
    if (numberOfSplits !== 4 && (split === 1 || split === 2)) {
      namesPerColumn += 1;
    }

    if (numberOfSplits === 4 && (split === 2 || split === 3)) {
      namesPerColumn += 1;
    }
  }

  if (namesRemainder === 3) {
    if (numberOfSplits === 4 && (split === 1 || split === 2 || split === 3)) {
      namesPerColumn += 1;
    }

    if (numberOfSplits === 5 && (split === 2 || split === 3 || split === 4)) {
      namesPerColumn += 1;
    }
  }

  if (namesRemainder === 4) {
    if (split === 1 || split === 2 || split === 3 || split === 4) {
      namesPerColumn += 1;
    }
  }
  return namesPerColumn;
}