import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardFooter,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import IconComponent from 'components/IconComponent';
import { ChapterModelType } from 'models/ChapterModel';
import {
  ROUTE_ALBUMS,
  ROUTE_FACTSHEETS,
  ROUTE_QUOTES,
  ROUTE_RANKINGS,
  ROUTE_SPONSORS,
  ROUTE_TEXTS,
  ROUTE_TOC,
  ROUTE_YEARBOOKS
} from 'utils/constants/routes';
import TextElement from 'components/TextElement/TextElement';
import chapterIcon from 'utils/chapter-icon';

const OrganizerChapterListItem = ({
  chapter,
  draggable
}: {
  chapter: ChapterModelType;
  draggable?: boolean;
}) => {
  let link: string;
  let linkExtra: string = '';
  let organizersOnly = false;

  switch (chapter.chapter_type) {
    case 'album':
      link = ROUTE_ALBUMS;
      break;

    case 'factsheet':
      link = ROUTE_FACTSHEETS;
      break;

    case 'teacher_factsheet':
      link = ROUTE_FACTSHEETS;
      linkExtra = '?teachers=yes';
      organizersOnly = true;
      break;

    case 'quote':
      link = ROUTE_QUOTES;
      break;

    case 'text':
      link = ROUTE_TEXTS;
      organizersOnly = true;
      break;

    case 'sponsor':
      link = ROUTE_SPONSORS;
      organizersOnly = true;
      break;

    case 'ranking':
      link = ROUTE_RANKINGS;
      break;

    case 'table_of_contents':
      link = ROUTE_TOC;
      organizersOnly = true;
      break;

    case 'yearbook':
      link = ROUTE_YEARBOOKS;
      break;

    default:
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          'OrganizerChapterListItem | Missing chapter type:',
          chapter.chapter_type
        );
      }
      return null;
  }

  const isOrganizerAccessOnly = organizersOnly || chapter.access_level === 'organizer';
  const isClosed = chapter.editing_state === 'closed';
  const isIconActive = chapter.print && (!isClosed || isOrganizerAccessOnly);

  return (
    <Link to={link + '/' + chapter.id + linkExtra}>
      <EveryCard>
        <EveryCardPadding>
          <IconComponent
            icon={chapterIcon(chapter.chapter_type)}
            circleBackground={isIconActive ? 'PRIMARY_LIGHT' : 'GRAY950'}
            fill={isIconActive ? 'PRIMARY' : 'GRAY800'}
            size={3}
          />

          <EveryCardBody>
            <EveryCardHeadline>
              {chapter.title || (
                <FormattedMessage id={`chapter type ${chapter.chapter_type}`} />
              )}
            </EveryCardHeadline>

            <TextElement color="TEXT_DARK">
              {isOrganizerAccessOnly ? (
                <>
                  <IconComponent icon="ORGANIZER" />{' '}
                  <FormattedMessage id="role label organizer" />
                </>
              ) : isClosed ? (
                <>
                  <IconComponent icon="LOCK" />{' '}
                  <FormattedMessage id="Editing disabled" />
                </>
              ) : chapter.group ? (
                <>
                  <IconComponent icon="USER" /> {chapter.group.name}
                </>
              ) : (
                <>
                  <IconComponent icon="USERGROUP" />{' '}
                  <FormattedMessage id="role label student" />
                </>
              )}
            </TextElement>
          </EveryCardBody>

          {draggable && <IconComponent icon="DRAG" fill="GRAY800" size={1.5} />}
        </EveryCardPadding>

        {!chapter.print && (
          <EveryCardFooter>
            <TextElement color="TEXT_DARK">
              <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
              <FormattedMessage id="Chapter not being printed" />
            </TextElement>
          </EveryCardFooter>
        )}
      </EveryCard>
    </Link>
  );
};

export default OrganizerChapterListItem;
