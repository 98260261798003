import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import {
  ButtonListBlock,
  ButtonListBlockItem,
  ButtonListBlockItemPadding
} from 'blocks/ButtonListBlock/ButtonListBlock';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import NumberListBlock from 'blocks/NumberListBlock';
import StickyBottomContainerBlock from 'blocks/StickyBottomContainerBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextareaComponent from 'components/Inputs/TextareaComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import DeleteButton from 'domain/Buttons/DeleteButton';
import OrganizerConditional from 'domain/Conditionals/OrganizerConditional';
import StudentConditional from 'domain/Conditionals/StudentConditional';
import BackLink from 'domain/Links/BackLink';
import CancelClickLink from 'domain/Links/CancelClickLink';
import CancelLink from 'domain/Links/CancelLink';
import StudentsList from 'domain/StudentsList/StudentsList';
import BreakingText from 'elements/BreakingText';
import Headline from 'elements/Headline';
import WordWrap from 'elements/WordWrap';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { createCandidateModel } from 'models/CandidateModel';
import { RankingModelType } from 'models/RankingModel';
import CandidatesList from 'screens/rankings/containers/CandidatesList/CandidatesList';
import { RankingsStoreType } from 'screens/rankings/RankingsStore';
import { ROUTE_RANKINGS } from 'utils/constants/routes';
import { HistoryProps, idFromMatch, isPush } from 'utils/history';
import chapterIdFromMatch, {
  backRouteFromMatch
} from 'utils/history/chapter-id-from-match';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';

import RankingOwnVoteComponent from '../RankingOwnVoteComponent';
import RankingRankItem from './RankingRankItem';
import HelpSubject from 'components/HelpButton/HelpSubject';
import Divider from 'components/Divider/Divider';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import PageHeader from 'components/PageHeader/PageHeader';
import TextElement from 'components/TextElement/TextElement';
import Paragraph from 'components/Paragraph/Paragraph';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import ExtraMarginBlock from 'blocks/ExtraMarginBlock/ExtraMarginBlock';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import UppercaseHeading from 'elements/UppercaseHeading';
import TagBlock from 'blocks/TagBlock';

interface RankingDetailProps {
  rankingsStore: RankingsStoreType;
  applicationStore: ApplicationStoreType;
  view?: string;
  form: FormType;
}

@inject('rankingsStore', 'applicationStore')
@observer
class RankingDetail extends React.Component<RankingDetailProps & HistoryProps> {
  getSelected(): number[] {
    const {
      rankingsStore: { item },
      form
    } = this.props;

    if (form.values.selected) {
      return form.values.selected;
    }

    if (!item) {
      return [];
    }

    const selected = [];
    if (item.own_votes) {
      for (const candidate of item.own_votes.values()) {
        selected.push(candidate.id);
      }
    }

    return selected;
  }

  candidateClick(candidateId: number) {
    const {
      form,
      rankingsStore: { item }
    } = this.props;
    if (!item) {
      return;
    }

    let selected = this.getSelected().concat([]);

    if (selected.includes(candidateId)) {
      selected = selected.filter((elem) => elem !== candidateId);
    } else {
      const max = item.num_candidates || 1;
      if (selected.length >= max) {
        return;
      }

      selected.push(candidateId);
    }

    form.setField('selected', selected);
  }

  async loadRanking(id: number, force = false) {
    const { rankingsStore, form } = this.props;

    if (rankingsStore.isItemLoading) {
      // do not interrupt running request
      return;
    }

    form.reset();

    if (!force) {
      const checkIfAlreadyInStore = !isPush(this.props.history);
      if (
        checkIfAlreadyInStore &&
        rankingsStore.item &&
        rankingsStore.item.id === id
      ) {
        form.setField('name', rankingsStore.item.name || '');
        return;
      }
    }

    const ranking = await rankingsStore.getRanking(id);

    if (ranking) {
      form.setField('name', ranking.name || '');
      form.setField('first_place', ranking.first_place || '');
      form.setField('second_place', ranking.second_place || '');
      form.setField('third_place', ranking.third_place || '');
      form.setField('select_winner_manually', ranking.select_winner_manually);
    }

    return ranking;
  }

  async componentDidMount() {
    // just to be sure
    this.finishRemoveFromRanking();

    const id = idFromMatch(this.props.match);
    if (id) {
      await this.loadRanking(id);
    } else {
      // new quote mode
      this.props.rankingsStore.clearCurrentItem();
      this.props.form.reset();
    }
  }

  async componentDidUpdate(prevProps: any) {
    const newId = idFromMatch(this.props.match);
    if (newId) {
      if (idFromMatch(prevProps.match) !== newId) {
        if (!newId) {
          // new quote mode
          this.props.rankingsStore.clearCurrentItem();
          this.props.form.reset();
          return;
        }

        // ID in URL has changed, we need to fetch new item
        await this.loadRanking(newId);
      }
    }
  }

  navigateToList() {
    this.props.history.replace(
      backRouteFromMatch(ROUTE_RANKINGS, this.props.match)
    );
  }

  async handleSubmit() {
    const { form, rankingsStore } = this.props;
    const {
      name,
      pair,
      first_place,
      second_place,
      third_place,
      select_winner_manually
    } = form.values;

    const chapterId = chapterIdFromMatch(this.props.match);

    form.resetErrors();

    const patch: any = {
      name: !name ? '' : name.trim(),
      first_place,
      second_place,
      third_place,
      select_winner_manually
    };

    if (this.isAddMode()) {
      if (!chapterId) {
        return;
      }

      patch.chapter_id = chapterId;

      if (pair) {
        patch.num_candidates = 2;
      }
    }

    rankingsStore.patchItem(patch);

    try {
      const item:
        | RankingModelType
        | undefined = await rankingsStore.storeItem();

      if (!item) {
        this.navigateToList();
        return;
      }

      this.props.history.replace(
        ROUTE_RANKINGS + '/' + item.chapter_id + '/' + item.id + '/show'
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by QuotesStore internally
      return;
    }
  }

  confirmDelete() {
    this.props.form.setConfirm(true);
  }

  async performDelete() {
    const { rankingsStore } = this.props;

    const id = idFromMatch(this.props.match);
    if (!id) {
      return;
    }

    this.finishDelete();

    try {
      await rankingsStore.removeRanking(id);
    } catch (error: any) {
      // delete errors are handled by QuotesStore internally
      return;
    }

    this.navigateToList();
  }

  finishDelete() {
    this.props.form.setConfirm(false);
  }

  initAddCandidate() {
    this.props.form.reset();
    this.props.form.setField('name', '');

    this.props.rankingsStore.setAddCandidateActive(true);
  }

  async performAddCandidate() {
    const { form, rankingsStore } = this.props;
    const { name } = form.values;

    const chapterId = rankingsStore.item?.chapter_id;
    if (!chapterId) {
      // we don't know what we should add
      this.navigateToList();
      return;
    }

    form.resetErrors();

    try {
      await rankingsStore.createCandidate(
        createCandidateModel({
          chapter_id: chapterId,
          name: name.trim()
        })!
      );
    } catch (error: any) {
      // let useForm check if form error
      handleFormError(form, error);

      // all other errors are handled by QuotesStore internally
      return;
    }

    this.finishAddCandidate();
  }

  finishAddCandidate() {
    this.props.rankingsStore.setAddCandidateActive(false);
  }

  isAddMode() {
    return !idFromMatch(this.props.match) ? true : false;
  }

  async vote(candidateIds: number[]) {
    const { applicationStore, rankingsStore } = this.props;

    const ranking = rankingsStore.item;
    if (!ranking) {
      return;
    }

    // TODO handle form error
    const voteSuccessful = await rankingsStore.placeVote(
      ranking.id,
      candidateIds.concat([])
    );

    // TODO Can we avoid flickering of students list after successful vote?
    if (voteSuccessful) {
      if (applicationStore.isOrganizer) {
        this.props.history.replace(ROUTE_RANKINGS + '/' + ranking.chapter_id + '/' + ranking.id + '/show');
      } else {
        this.navigateToList();
      }
    }
  }

  initRemoveFromRanking(candidateIds?: number[]) {
    const { rankingsStore } = this.props;

    if (!candidateIds || !rankingsStore.item?.chapter) {
      return;
    }

    rankingsStore.setRemoveWarning('confirm', candidateIds);
  }

  confirmRemoveFromRanking() {
    const { rankingsStore } = this.props;

    if (!rankingsStore.removeVoteIds) {
      this.finishRemoveFromRanking();
      return;
    }

    const candidateIds = [];
    for (const id of rankingsStore.removeVoteIds.values()) {
      candidateIds.push(id);
    }

    this.removeFromRanking(candidateIds);
  }

  finishRemoveFromRanking() {
    this.props.rankingsStore.setRemoveWarning(undefined, undefined);
  }

  async removeFromRanking(candidateIds?: number[]) {
    const { rankingsStore } = this.props;

    const ranking = rankingsStore.item;
    if (!ranking || !candidateIds) {
      return;
    }

    this.finishRemoveFromRanking();
    await rankingsStore.removeVote(ranking.id, candidateIds);

    this.loadRanking(ranking.id, true);
  }

  renderStudentVoting() {
    const { applicationStore, rankingsStore } = this.props;
    const { currentUser } = applicationStore;

    const ranking = rankingsStore.item;
    if (!ranking) {
      // satisfy TS
      return null;
    }

    const selected = this.getSelected();
    const multiVoting =
      ranking.num_candidates && ranking.num_candidates > 1
        ? ranking.num_candidates
        : 0;
    const multiDisabled = multiVoting !== selected.length;

    const onlyGroupId = ranking.chapter?.group?.id;

    return (
      <ExtraMarginBlock>
        <TopActionBlock>
          <StudentConditional profile={currentUser}>
            <CancelLink
              to={backRouteFromMatch(ROUTE_RANKINGS, this.props.match)}
            />
          </StudentConditional>
          <OrganizerConditional profile={currentUser}>
            <CancelLink
              to={
                ROUTE_RANKINGS +
                '/' +
                ranking.chapter_id +
                '/' +
                ranking.id +
                '/show'
              }
            />
          </OrganizerConditional>
        </TopActionBlock>

        <PageStackBlock>
          <ItemStackBlock gap="XXS">
            {ranking.num_candidates === 2 && (
              <TagBlock color="PRIMARY" backgroundColor="PRIMARY_LIGHT">
                <FormattedMessage id="Duo long" />
              </TagBlock>
            )}
            <PageHeader headline={<WordWrap>{ranking.name}</WordWrap>} text={<FormattedMessage id="ranking student info" />} />
          </ItemStackBlock>
          <Divider />

          {rankingsStore.isItemError && <GenericErrorComponent />}

          <ListStackBlock>
            <StudentsList
              onlyGroupId={onlyGroupId}
              selected={selected}
              onStudentClick={(_: any, id: number) => {
                multiVoting ? this.candidateClick(id) : this.vote([id]);
              }}
            />
          </ListStackBlock>

          {multiVoting > 0 && (
            <StickyBottomContainerBlock>
              <HorizontalStackBlock justified={true}>
                <NonGapStackBlock>
                  <Paragraph weight="BOLD">
                    <FormattedMessage
                      id="ranking multivotes counter"
                      values={{
                        count: selected.length,
                        max: multiVoting
                      }}
                    />
                  </Paragraph>
                  <TextElement>
                    <FormattedMessage id="multi vote ranking" />
                  </TextElement>
                </NonGapStackBlock>


                <ButtonBlock
                  inline={true}
                  slim={true}
                  background={multiDisabled ? 'GRAY800' : 'PRIMARY'}
                  onClick={() => this.vote(selected)}
                  disabled={multiDisabled}
                >
                  <FormattedMessage id="Save" />
                </ButtonBlock>
              </HorizontalStackBlock>
            </StickyBottomContainerBlock>
          )}
        </PageStackBlock>

        <HelpSubject subject="rankings" hidden={true} />
      </ExtraMarginBlock>
    );
  }

  renderCandidateVoting() {
    const { applicationStore, rankingsStore } = this.props;
    const { currentUser } = applicationStore;

    const ranking = rankingsStore.item;
    if (!ranking || !ranking.chapter_id) {
      // satisfy TS
      return null;
    }

    const selected = this.getSelected();
    const multiVoting =
      ranking.num_candidates && ranking.num_candidates > 1
        ? ranking.num_candidates
        : 0;
    const multiDisabled = multiVoting !== selected.length;

    return (
      <ExtraMarginBlock>
        <TopActionBlock>
          <StudentConditional profile={currentUser}>
            <CancelLink
              to={backRouteFromMatch(ROUTE_RANKINGS, this.props.match)}
            />
          </StudentConditional>
          <OrganizerConditional profile={currentUser}>
            <CancelLink
              to={
                ROUTE_RANKINGS +
                '/' +
                ranking.chapter_id +
                '/' +
                ranking.id +
                '/show'
              }
            />
          </OrganizerConditional>
        </TopActionBlock>

        <PageStackBlock>
          <ItemStackBlock gap="XXS">
            {ranking.num_candidates === 2 && (
              <TagBlock color="PRIMARY" backgroundColor="PRIMARY_LIGHT">
                <FormattedMessage id="Duo long" />
              </TagBlock>
            )}
            <PageHeader headline={<WordWrap>{ranking.name}</WordWrap>} text={<FormattedMessage id="ranking candidate info" />} />
          </ItemStackBlock>

          <ButtonBlock
            background="PRIMARY"
            color="WHITE"
            onClick={() => this.initAddCandidate()}
          >
            <FormattedMessage id="Add candidate" />
          </ButtonBlock>

          <Divider />

          {rankingsStore.isItemError && <GenericErrorComponent />}

          <ListStackBlock>
            <CandidatesList
              chapterId={ranking.chapter_id}
              selected={selected}
              onCandidateClick={(_: any, id: number) => {
                multiVoting ? this.candidateClick(id) : this.vote([id]);
              }}
            />
          </ListStackBlock>

          {multiVoting > 0 && (
            <StickyBottomContainerBlock>
              <HorizontalStackBlock justified={true}>
                <NonGapStackBlock>
                  <Paragraph weight="BOLD">
                    <FormattedMessage
                      id="ranking multivotes counter"
                      values={{
                        count: selected.length,
                        max: multiVoting
                      }}
                    />
                  </Paragraph>
                  <TextElement>
                    <FormattedMessage id="multi vote ranking" />
                  </TextElement>
                </NonGapStackBlock>


                <ButtonBlock
                  inline={true}
                  slim={true}
                  background={multiDisabled ? 'GRAY800' : 'PRIMARY'}
                  onClick={() => this.vote(selected)}
                  disabled={multiDisabled}
                >
                  <FormattedMessage id="Save" />
                </ButtonBlock>
              </HorizontalStackBlock>
            </StickyBottomContainerBlock>
          )}
        </PageStackBlock>

        <HelpSubject subject="rankings" hidden={true} />
      </ExtraMarginBlock>
    );
  }

  renderAddCandidateView() {
    const { form, rankingsStore } = this.props;

    if (rankingsStore.isCandidateItemLoading) {
      return <LoadingOverlayComponent />;
    }

    return (
      <>
        <TopActionBlock>
          <CancelClickLink onClick={() => this.finishAddCandidate()} />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Add candidate" />
          </Headline.Large>

          {rankingsStore.candidateItemLoadingState === 'update_error' && (
            <GenericErrorComponent />
          )}

          <TextInputComponent
            name="name"
            label={<FormattedMessage id="Name" />}
            {...form.bindInput('name')}
            autoFocus={true}
          />

          <ButtonBlock
            background="PRIMARY"
            onClick={() => this.performAddCandidate()}
          >
            <FormattedMessage id="Add candidate" />
          </ButtonBlock>
        </PageStackBlock>

        <HelpSubject subject="rankings" />
      </>
    );
  }

  renderVoting() {
    const { rankingsStore } = this.props;
    if (!rankingsStore.item) {
      return null;
    }

    switch (rankingsStore.item.ranking_type) {
      case 'profile_ranking':
        return this.renderStudentVoting();

      default:
        return this.renderCandidateVoting();
    }
  }

  renderManuallyView(selectedWinners: (string | undefined)[], ranking: RankingModelType) {
    const filled = selectedWinners.filter((element) => element !== '');
    return (
      <>
        <Divider />

        <ItemStackBlock gap="XXS">
          <Headline.Medium>
            <FormattedMessage id="Mannual placing" />
          </Headline.Medium>
          <Paragraph size="L" weight="NORMAL" color="TEXT_LIGHT">
            <FormattedMessage id="Mannual placing info" />
          </Paragraph>
        </ItemStackBlock>

        {filled.length > 0 ? (
          <>
            <LinkBlock
              inline={true}
              slim={true}
              to={
                ROUTE_RANKINGS +
                '/' +
                ranking.chapter_id +
                '/' +
                ranking.id +
                '/winner'
              }
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
            >
              <IconComponent icon="PEN" />
              <FormattedMessage id="Edit" />
            </LinkBlock>
            <ListStackBlock>
              <ButtonListBlock>
                {selectedWinners.map((element, index) => {
                  return (
                    <ButtonListBlockItem key={index}>
                      <ButtonListBlockItemPadding>
                        <HorizontalStackBlock>
                          <NumberListBlock>
                            <NumberListBlock.ListItemElement noMargin={true}>
                              <NumberListBlock.NumberElement>
                                {index + 1}
                              </NumberListBlock.NumberElement>
                              <Headline.Small>
                                <BreakingText>{element}</BreakingText>
                              </Headline.Small>
                            </NumberListBlock.ListItemElement>
                          </NumberListBlock>
                        </HorizontalStackBlock>
                      </ButtonListBlockItemPadding>
                    </ButtonListBlockItem>
                  );
                })}
              </ButtonListBlock>
            </ListStackBlock>
          </>
        ) : (
          <LinkBlock
            to={
              ROUTE_RANKINGS +
              '/' +
              ranking.chapter_id +
              '/' +
              ranking.id +
              '/winner'
            }
            background="PRIMARY"
            color="WHITE"
          >
            <FormattedMessage id="Select winner manually" />
          </LinkBlock>

        )}
      </>
    );
  }

  renderShowView() {
    const { applicationStore, rankingsStore } = this.props;

    if (!applicationStore.isOrganizer) {
      // just to be sure
      return null;
    }

    const ranking = rankingsStore.item;
    if (!ranking) {
      // satisfy TS
      return null;
    }

    const [votes, moreVotes] = rankingsStore.splittedItemRanking;

    const selectedWinners = [];
    if (ranking.select_winner_manually) {
      selectedWinners.push(
        ranking.first_place,
        ranking.second_place,
        ranking.third_place
      );
    }

    let warning;
    if (rankingsStore.removeVoteWarning) {
      warning = (
        <ConfirmBoxComponent
          onConfirm={() => this.confirmRemoveFromRanking()}
          onAbort={() => this.finishRemoveFromRanking()}
          header={<FormattedMessage id="ranking remove vote header" />}
          text={<FormattedMessage id="ranking remove vote confirm" />}
          confirmText={<FormattedMessage id="Remove candidates" />}
          abortText={<FormattedMessage id="Cancel" />}
          confirmColor="RED"
        />
      );
    }

    const { isEditAllowed } = applicationStore;

    const rankingGroup = ranking.chapter?.group?.id;
    const profileGroup = applicationStore.currentUser?.group?.id;
    const isOtherGroup = !!rankingGroup && profileGroup !== rankingGroup;


    // Split into two Views

    return (
      <>
        <TopActionBlock>
          <BackLink to={backRouteFromMatch(ROUTE_RANKINGS, this.props.match)} />
        </TopActionBlock>

        <PageStackBlock>
          <ItemStackBlock gap="XXS">
            {ranking.num_candidates === 2 && (
              <TagBlock color="PRIMARY" backgroundColor="PRIMARY_LIGHT">
                <FormattedMessage id="Duo long" />
              </TagBlock>
            )}
            <PageHeader headline={<WordWrap>{ranking.name}</WordWrap>} text={<FormattedMessage id="ranking show info" />} />
          </ItemStackBlock>

          {isEditAllowed && (
            <LinkBlock
              inline={true}
              slim={true}
              to={
                ROUTE_RANKINGS +
                '/' +
                ranking.chapter_id +
                '/' +
                ranking.id +
                '/edit'
              }
              background="PRIMARY_LIGHT"
              color="PRIMARY_DARK"
            >
              <IconComponent icon="settings" />
              <FormattedMessage id="Settings" />
            </LinkBlock>
          )}

          {ranking.select_winner_manually ? (
            this.renderManuallyView(selectedWinners, ranking)
          ) : (
            <>
              {isEditAllowed && !isOtherGroup && (
                <>
                  <Link
                    to={
                      ROUTE_RANKINGS + '/' + ranking.chapter_id + '/' + ranking.id
                    }
                  >
                    <EveryCard>
                      <EveryCardPadding>
                        <EveryCardBody>
                          <EveryCardHeadline>
                            <FormattedMessage id="Your vote" />
                          </EveryCardHeadline>
                          <TextElement>
                            <RankingOwnVoteComponent ranking={ranking} />
                          </TextElement>
                        </EveryCardBody>
                      </EveryCardPadding>
                    </EveryCard>
                  </Link>

                  <Divider />
                </>
              )}

              <ItemStackBlock gap="S">
                <UppercaseHeading>
                  <FormattedMessage id="ranks header" />
                </UppercaseHeading>
                {!votes ? (
                  <>
                    <Paragraph>
                      <FormattedMessage id="ranking rank info" />
                    </Paragraph>
                  </>
                ) : (
                  <>
                    <ListStackBlock>
                      <ButtonListBlock>
                        {votes.map((item, idx) => (
                          <RankingRankItem
                            key={idx}
                            item={item}
                            onRemove={
                              isEditAllowed
                                ? (ids) => this.initRemoveFromRanking(ids)
                                : undefined
                            }
                          />
                        ))}
                      </ButtonListBlock>
                    </ListStackBlock>

                    {!!moreVotes?.length && (
                      <>
                        <Divider />

                        <Headline.Small>
                          <FormattedMessage id="Further candidates" />
                        </Headline.Small>

                        <ListStackBlock>
                          <ButtonListBlock>
                            {moreVotes.map((item, idx) => (
                              <RankingRankItem
                                key={idx}
                                item={item}
                                onRemove={
                                  isEditAllowed
                                    ? (ids) => this.initRemoveFromRanking(ids)
                                    : undefined
                                }
                              />
                            ))}
                          </ButtonListBlock>
                        </ListStackBlock>
                      </>
                    )}
                  </>
                )}
              </ItemStackBlock>
            </>
          )}
        </PageStackBlock>

        {warning}

        <HelpSubject subject="rankings" />
      </>
    );
  }

  renderEditForm() {
    const { rankingsStore, form } = this.props;
    const isAddMode = this.isAddMode();

    const ranking = rankingsStore.item;

    let extra;
    if (form.confirm) {
      extra = (
        <ConfirmBoxComponent
          header={<FormattedMessage id="confirm delete header" />}
          text={
            <FormattedMessage
              id="confirm delete item"
              values={{
                item: <FormattedMessage id="This ranking" />
              }}
            />
          }
          confirmText={<FormattedMessage id="Remove" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => this.performDelete()}
          onAbort={() => this.finishDelete()}
          confirmColor="RED"
        />
      );
    } else if (rankingsStore.itemLoadingState === 'update_error') {
      extra = <GenericErrorComponent />;
    }

    let cancelLink = backRouteFromMatch(ROUTE_RANKINGS, this.props.match);
    if (!isAddMode && rankingsStore.item) {
      cancelLink =
        ROUTE_RANKINGS +
        '/' +
        rankingsStore.item.chapter_id +
        '/' +
        rankingsStore.item.id +
        '/show';
    }

    return (
      <>
        <TopActionBlock>
          <CancelLink to={cancelLink} />
        </TopActionBlock>

        <PageStackBlock>

          <ItemStackBlock gap="XXS">
            {ranking && ranking.num_candidates === 2 && (
              <TagBlock color="PRIMARY" backgroundColor="PRIMARY_LIGHT">
                <FormattedMessage id="Duo long" />
              </TagBlock>
            )}
            <Headline.Large>
              <FormattedMessage
                id={this.isAddMode() ? 'Add ranking' : 'Edit ranking'}
              />
            </Headline.Large>
          </ItemStackBlock>

          {extra}

          <TextInputComponent
            name="name"
            label={<FormattedMessage id="Ranking name" />}
            {...form.bindInput('name')}
            autoFocus={true}
          />

          {isAddMode && (
            <>
              <FormStackBlock>
                <CustomCheckboxBlock>
                  <CustomCheckboxBlock.CheckboxElement
                    id="pair"
                    {...form.bindCheckbox('pair')}
                  />
                  <CustomCheckboxBlock.LabelElement htmlFor="pair">
                    <FormattedMessage id="Duo long" />
                    <TextElement>
                      <FormattedMessage id={form.values.pair ? 'ranking label duo vote' : 'ranking label single vote'} />
                    </TextElement>
                  </CustomCheckboxBlock.LabelElement>
                </CustomCheckboxBlock>


                {/* <RadioButtonComponent
                  name="pair"
                  value="no"
                  label={<FormattedMessage id="ranking one candidate" />}
                  {...form.bindRadioButton('pair', 'no')}
                />
                <RadioButtonComponent
                  name="pair"
                  value="yes"
                  label={<FormattedMessage id="ranking two candidates" />}
                  {...form.bindRadioButton('pair', 'yes')}
                /> */}
              </FormStackBlock>
            </>
          )}


          {!isAddMode && (
            <CustomCheckboxBlock>
              <CustomCheckboxBlock.CheckboxElement
                id="select_winner_manually"
                {...form.bindCheckbox('select_winner_manually')}
              />
              <CustomCheckboxBlock.LabelElement htmlFor="select_winner_manually">
                <FormattedMessage id="Select winner manually" />
                <TextElement>
                  <FormattedMessage id="Mannual placing label" />
                </TextElement>
              </CustomCheckboxBlock.LabelElement>
            </CustomCheckboxBlock>
          )}


          <ButtonBlock background="PRIMARY" onClick={() => this.handleSubmit()}>
            <FormattedMessage id={isAddMode ? 'Add' : 'Save'} />
          </ButtonBlock>

          {!isAddMode && (
            <>
              <Divider />
              <DeleteButton onClick={() => this.confirmDelete()} />
            </>
          )}
        </PageStackBlock>

        <HelpSubject subject="rankings" />
      </>
    );
  }

  renderSelectWinner() {
    const { rankingsStore, form } = this.props;
    const ranking = rankingsStore.item;

    if (!ranking) {
      return null;
    }

    const [votes] = rankingsStore.splittedItemRanking;
    const useVoting = !form.values.select_winner_manually;

    return (
      <>
        <TopActionBlock>
          <BackLink
            to={
              backRouteFromMatch(ROUTE_RANKINGS, this.props.match) +
              '/' +
              ranking.id +
              '/show'
            }
          />
        </TopActionBlock>

        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Select winner" />
          </Headline.Large>

          {useVoting ? (
            <>
              <ButtonBlock
                background="PRIMARY"
                onClick={() => this.handleSubmit()}
              >
                <FormattedMessage id="Save" />
              </ButtonBlock>

              {!votes ? (
                <>
                  <Paragraph>
                    <FormattedMessage id="ranking rank info" />
                  </Paragraph>
                </>
              ) : (
                <ListStackBlock>
                  <ButtonListBlock>
                    {votes.map((item, idx) => (
                      <RankingRankItem key={idx} item={item} />
                    ))}
                  </ButtonListBlock>
                </ListStackBlock>
              )}
            </>
          ) : (
            <>
              <FormStackBlock>
                <TextareaComponent
                  name="first_place"
                  label={<FormattedMessage id="First place" />}
                  height={6}
                  {...form.bindInput('first_place')}
                />

                <TextareaComponent
                  name="second_place"
                  label={<FormattedMessage id="Second place" />}
                  height={6}
                  {...form.bindInput('second_place')}
                />

                <TextareaComponent
                  name="third_place"
                  label={<FormattedMessage id="Third place" />}
                  height={6}
                  {...form.bindInput('third_place')}
                />
              </FormStackBlock>

              <ButtonBlock
                background="PRIMARY"
                onClick={() => this.handleSubmit()}
              >
                <FormattedMessage id="Save" />
              </ButtonBlock>
            </>
          )}
        </PageStackBlock>

        <HelpSubject subject="rankings" />
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderError() {
    return this.renderPage(
      <>
        <GenericErrorComponent />

        <HelpSubject subject="rankings" />
      </>
    );
  }

  // renderReadOnly() {
  //   const { applicationStore } = this.props;

  //   return this.renderPage(
  //     <TextElement  color="TEXT_LIGHT">
  //       <RoleSpecificLabel
  //         currentUser={applicationStore.currentUser}
  //         idStudent="rankings closed student"
  //         idOrganizer="locked item info"
  //         values={{ item: <FormattedMessage id="Rankings" /> }}
  //       />
  //     </TextElement>
  //   );
  // }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink
            to={backRouteFromMatch(ROUTE_RANKINGS, this.props.match)}
          />
        </TopActionBlock>
        <PageStackBlock>
          <Headline.Large>
            <FormattedMessage id="Rankings" />
          </Headline.Large>

          {content}
        </PageStackBlock>
      </>
    );
  }

  render() {
    const { view, rankingsStore } = this.props;

    if (rankingsStore.isItemLoading) {
      return this.renderLoading();
    }

    if (rankingsStore.itemLoadingState === 'error') {
      // update errors need to keep displaying the form
      return this.renderError();
    }

    if (view === 'show') {
      return this.renderShowView();
    }

    // if (!applicationStore.isEditAllowed) {
    //   // do not display vote/edit/new page if in read only mode
    //   return this.renderReadOnly();
    // }

    if (view === 'vote') {
      if (rankingsStore.addCandidateActive) {
        return this.renderAddCandidateView();
      }

      return this.renderVoting();
    }

    if (view === 'winner') {
      return this.renderSelectWinner();
    }

    return this.renderEditForm();
  }
}

export default (props: any) => {
  const form = useForm({ pair: false });
  return <RankingDetail {...props} form={form} />;
};
