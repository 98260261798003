import IconBlock from 'blocks/IconBlock';
import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface ImageBlockProps {
  background?: keyof typeof COLORS;
  width?: number;
  height?: number;
  cover?: boolean;
  rounded?: boolean;
  bordered?: boolean;
  borderedLarge?: boolean;
  active?: boolean;
  fullWidth?: boolean;
  fullWidthPadding?: boolean;
  shrinkedImage?: boolean;
  radius?: boolean;
  shrinkedWidth?: boolean
}

const ImageBlock = styled.div<ImageBlockProps>`
  flex-shrink: ${(props) => (props.width ? '0' : 'inherit')};
  justify-content: center;
  align-items: center;
  display: flex;
  width: ${(props) => (props.width ? props.width + 'em' : 'auto')};
  height: ${(props) => (props.height ? props.height + 'em' : 'auto')};
  background: ${(props) =>
    props.background ? COLORS[props.background] : 'transparent'};

  img {
    max-width: ${(props) => (props.shrinkedImage ? '70%' : '100%')};
    max-height: ${(props) => (props.shrinkedImage ? '70%' : '100%')};
  }

  ${(props) =>
    props.shrinkedWidth &&
    `
    img {
      width: 70%;
    }
  `};

  ${(props) =>
    props.radius &&
    `
    border-radius: 0.625rem;

    img {
      border-radius: 0.625rem;
    }
  `};
  ${(props) =>
    props.cover &&
    `
    img {
      @supports (object-fit: cover) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  `};
  ${(props) =>
    props.rounded &&
    `
    padding: 0.3125em;

    img {
      border-radius: 50%;
    }
  `};
  ${(props) =>
    props.bordered &&
    `
    margin: 0.3125em 0 0.3125em 0.3125em;
    border-radius: 0.3125em;
    overflow: hidden;
  `}
  ${(props) =>
    props.borderedLarge &&
    `
    margin: 0.625em 0.625em 0 0.625em;
    border-radius: 0.3125em;
    overflow: hidden;
  `}
  ${(props) =>
    props.active &&
    `
    background: rgba(${COLORS.PRIMARY_RGB}, 0.2);
    position: relative;

    > ${IconBlock} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      opacity: 0.5;
    }
  `};
  ${(props) =>
    props.fullWidth &&
    `
    margin: 0 -1.25em;
    width: calc(100% + 2.5em);
    ${props.fullWidthPadding ? 'padding: 1.25em;' : ''}
  `}
`;

export default ImageBlock;
