import { types, Instance } from 'mobx-state-tree';

const SponsorChapterSettingsModel = types.model('SponsorChapterSettingsModel', {
  layout_key: types.maybe(types.string)
});

export const createSponsorChapterSettingsModel = (
  data?: any,
  createEmptyModel: boolean = true
): SponsorChapterSettingsModelType | undefined => {
  if (!data) {
    return createEmptyModel ? SponsorChapterSettingsModel.create() : undefined;
  }

  return SponsorChapterSettingsModel.create({
    layout_key: data?.layout_key || undefined
  });
};

export type SponsorChapterSettingsModelType = Instance<
  typeof SponsorChapterSettingsModel
>;
export default SponsorChapterSettingsModel;
