import styled from 'styled-components';
import COLORS from 'utils/constants/colors';

interface MessageBlockProps {
  warning?: boolean;
  error?: boolean;
  static?: boolean;
  success?: boolean;
  borderless?: boolean;
  small?: boolean;
}

const MessageBlock = styled.div<MessageBlockProps>`
  position: fixed;
  z-index: 999;
  bottom: 1em;
  border: 2px solid;
  border-radius: 0.3125em;
  width: calc(100% - 2.5em);
  max-width: calc(40em - 2.5em);
  padding: 0.625em;
  background-color: ${COLORS.WHITE};
  background-image: linear-gradient(
    rgba(${COLORS.SECONDARY_DARK_RGB}, 0.1) 0%,
    rgba(${COLORS.SECONDARY_DARK_RGB}, 0.1) 100%
  );
  color: ${COLORS.SECONDARY_DARK};
  ${props =>
    props.static &&
    `
    position: static;
    z-index:1;
    width: 100%;
  `};
  ${props =>
    props.warning &&
    `
    color: ${COLORS.PRIMARY};
    background-image: linear-gradient(rgba(${COLORS.PRIMARY_RGB}, 0.1) 0%, rgba(${COLORS.PRIMARY_RGB}, 0.1) 100%);
  `};
  ${props =>
    props.error &&
    `
    color: ${COLORS.RED};
    background-image: linear-gradient(rgba(${COLORS.RED_RGB}, 0.1) 0%, rgba(${COLORS.RED_RGB}, 0.1) 100%);
  `};
  ${props =>
    props.success &&
    `
    color: ${COLORS.GREEN};
    background: rgba(${COLORS.GREEN_RGB}, .14);
  `};
  ${props =>
    props.borderless &&
    `
    border: none;
  `};
  ${props =>
    props.small &&
    `
    background: none;
    border-radius: 3.375em;
    width: fit-content !important;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
  `};
`;

export default MessageBlock;
