import {
  Instance,
  types
} from 'mobx-state-tree';
import { createArrayWithoutTransform } from 'utils/create-map';
import mandatoryId from 'utils/store/mandatoryId';

const AccountModel = types
  .model('AccountModel', {
    id: types.identifierNumber,
    auth_token: types.maybe(types.string),
    mobile_number: types.maybe(types.string),
    mobile_number_to_confirm: types.maybe(types.string),
    first_name: types.maybe(types.string),
    last_name: types.maybe(types.string),
    // field to store password during account setup process
    // TODO move this somewhere to temporary field in SetupStore?
    password: types.maybe(types.string),
    profile_id: types.maybe(types.number),
    textile_order_ids: types.array(types.number),
    is_book_organizer: types.maybe(types.boolean),
    textile_order_ids_organizer: types.array(types.number)
  })
export const createAccountModel = (data: any): AccountModelType | undefined => {
  if (!data) {
    return undefined;
  }

  return AccountModel.create({
    id: mandatoryId(data.id),
    auth_token: data.auth_token || undefined,
    mobile_number: data.mobile_number || undefined,
    mobile_number_to_confirm: data.mobile_number_to_confirm || undefined,
    first_name: data.first_name || undefined,
    last_name: data.last_name || undefined,
    password: data.password || undefined,
    profile_id: data.profile?.id || undefined,
    textile_order_ids: createArrayWithoutTransform(data.textile_order_ids),
    is_book_organizer: data.is_book_organizer || undefined,
    textile_order_ids_organizer: createArrayWithoutTransform(data.textile_order_ids_organizer)
  });
};

export type AccountModelType = Instance<typeof AccountModel>;
export default AccountModel;
