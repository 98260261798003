import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding,
  EveryCardFooter
} from 'blocks/EveryCard/EveryCard';
import PreserveWhitespace from 'elements/PreserveWhitespace';
import WordWrap from 'elements/WordWrap';
import { CommentModelType } from 'models/CommentModel';
import { ROUTE_COMMENTS } from 'utils/constants/routes';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';import IconComponent from 'components/IconComponent';

interface CommentsListItemProps {
  comment: CommentModelType;
  displayAuthor?: boolean;
  displayProfile?: boolean;
  asEditLink?: boolean;
  editLinkFrom?: string;
}

export const CommentsListItem = ({
  comment,
  displayAuthor,
  displayProfile,
  asEditLink,
  editLinkFrom
}: CommentsListItemProps) => {
  const block = (
    <>
      <EveryCard>
        <EveryCardPadding>
          <EveryCardBody>
            <EveryCardHeadline>
              <WordWrap>
                <PreserveWhitespace>{comment.comment}</PreserveWhitespace>
              </WordWrap>
            </EveryCardHeadline>
            {displayAuthor && comment.author && (
              <Paragraph  color="TEXT_LIGHT">
                <FormattedMessage id="Comment from short" />{' '}
                {comment.author.name}
              </Paragraph>
            )}
            {displayProfile && comment.profile && (
              <Paragraph  color="TEXT_LIGHT">
                <FormattedMessage id="Comment for short" />{' '}
                {comment.profile.name}
              </Paragraph>
            )}
          </EveryCardBody>
        </EveryCardPadding>

        {!comment.print && (
          <EveryCardFooter>
            <TextElement  color="TEXT_DARK">
              <IconComponent icon="WARNING_TRIANGLE" fill="RED" />
              <FormattedMessage id="Will not be printed" />
            </TextElement>
          </EveryCardFooter>
        )}
      </EveryCard>
    </>
  );

  if (asEditLink) {
    const query = !editLinkFrom ? '' : '?from=' + editLinkFrom;
    return <Link to={ROUTE_COMMENTS + '/' + comment.id + query}>{block}</Link>;
  }

  return block;
};

export default CommentsListItem;
