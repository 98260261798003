import React, { useContext, useEffect, useState } from 'react';

import CardBlock from 'blocks/CardBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBox from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import ProgressbarComponent from 'components/ProgressbarComponent';
import TextElement from 'components/TextElement/TextElement';
import TrustSymbolsComponent from 'components/TrustSymbolsComponent';
import BackLink from 'domain/Links/BackLink';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';

import { useQueryClient } from '@tanstack/react-query';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import ButtonBlock from 'blocks/ButtonBlock';
import { HasAccessContext } from 'contexts/HasAccessContext';
import InputMessage from 'elements/InputMessage';
import { intl } from 'i18n';
import { useCalculatorOverviewQuery } from 'queries/textile_deals/useOrderOverviewQueries';
import { useCreateTextileCheckoutQuery } from 'queries/textile_deals/useTextileCheckoutQueries';
import { useRestartCheckoutQuery, useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { atLeastOrderStarted } from 'utils/hasAccess';
import TextileCalculatorPrice from './Components/TextileCalculatorPrice';

const OrderCalculatorScreen: React.FC<HistoryProps> = (props) => {
  const [startOrderRequested, setStartOrderRequested] = useState<
    string | undefined
  >();
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);
  const accessContext = useContext(HasAccessContext);
  const calculatorOverview = useCalculatorOverviewQuery(textileOrderId);
  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);
  const createTextileCheckout = useCreateTextileCheckoutQuery(textileOrderId);
  const restartCheckoutQuery = useRestartCheckoutQuery(textileOrderId)

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  useEffect(() => {
    accessContext.checkSectionAccess('calculator');
  }, [accessContext]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="textile order overview" />}
            text={<FormattedMessage id="textile order overview calc" />}
          ></PageHeader>
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (calculatorOverview.isError) {
    return renderPage(
      <GenericErrorComponent
        onRetryClick={() => calculatorOverview.refetch()}
      />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (
    !textileAppState ||
    calculatorOverview.isLoading ||
    !calculatorOverview.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const startCheckout = () => {
    if (!textileOrderId) {
      return;
    }

    createTextileCheckout.mutate(textileOrderId, {
      onSettled: () => {
        props.history.push(textileOrderRoute(props.match, '/checkout'));
      },
      onError: () => {
        setStartOrderRequested(undefined)
        return;
      }
    })
  };

  const restartCheckout = () => {
    if (!textileOrderId) {
      return;
    }

    restartCheckoutQuery.mutate(textileOrderId, {
      onSuccess: () => {
        props.history.push(textileOrderRoute(props.match, '/checkout'));
      },
      onError: () => {
        setStartOrderRequested(undefined)
      }
    })
  };

  const requestAuthorChange = () => {
    setStartOrderRequested(undefined);

    props.history.push(
      textileOrderRoute(props.match, '/calculator/change_author')
    );
  };

  const checkOwnerCheckout = (): boolean => {
    if (!textileAppState.textile_profile) {
      return false;
    }

    if (
      calculatorOverview.data.checkout_author_id ===
      textileAppState.textile_profile.id
    ) {
      return true;
    }

    return false;
  };

  const renderCalculator = () => {
    const profilesCount = calculatorOverview.data.active_students || 0;
    const submittedProfilesCount =
      calculatorOverview.data.submitted_preorders_students || 0;

    if (!profilesCount) {
      return null;
    }

    const textilePrice = calculatorOverview.data.price;
    const error = calculatorOverview.data.error;

    const day = intl.formatDate(textileOrder.data.preorder_deadline, {
      day: '2-digit'
    });
    const month = intl.formatDate(textileOrder.data.preorder_deadline, {
      month: '2-digit'
    });
    const date = day + '/' + month;

    let extra;

    if (startOrderRequested === 'checkout') {
      extra = (
        <ConfirmBox
          header={<FormattedMessage id="textile checkout start header" />}
          text={<FormattedMessage id="textile checkout start info" />}
          confirmText={<FormattedMessage id="Start now" />}
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => startCheckout()}
          onAbort={() => setStartOrderRequested(undefined)}
          confirmColor="PRIMARY"
        />
      );
    }

    if (startOrderRequested === 'changeAuthor') {
      extra = (
        <ConfirmBox
          header={<FormattedMessage id="textile checkout exists header" />}
          text={<FormattedMessage id="textile checkout exists info" />}
          confirmText={
            <FormattedMessage id="textile checkout exists takeover" />
          }
          abortText={<FormattedMessage id="Cancel" />}
          onConfirm={() => requestAuthorChange()}
          onAbort={() => setStartOrderRequested(undefined)}
          confirmColor="PRIMARY"
        />
      );
    }

    return renderPage(
      <>
        {extra}

        <ItemStackBlock gap="L">
          <ItemStackBlock gap="XS">
            {textileAppState.textile_order.preorder_open ? (
              <Paragraph>
                <IconComponent icon="CLOCK_CIRCLE" fill="BLACK" />
                {textileOrder.data.preorder_deadline ? (
                  <>
                    <FormattedMessage id="preorder open til" /> {date}
                  </>
                ) : (
                  <FormattedMessage id="preorder open time" />
                )}
              </Paragraph>
            ) : (
              <Paragraph>
                <IconComponent icon="PAUSE" fill="BLACK" />
                <FormattedMessage id="editing state paused" />
              </Paragraph>
            )}
            <ProgressbarComponent
              isStatic={true}
              progressStatus={(submittedProfilesCount / profilesCount) * 100}
              color="PRIMARY"
            />
            <TextElement>
              <FormattedMessage
                id="textile preorder students count"
                values={{
                  count: submittedProfilesCount,
                  number: profilesCount
                }}
              />
            </TextElement>

            <Link to={textileOrderRoute(props.match, '/order/overview')}>
              <CardBlock>
                <PaddingBlock arrow={true}>
                  <TextElement color="BLACK">
                    <FormattedMessage id="Pre-Orders overview" />
                  </TextElement>
                </PaddingBlock>
              </CardBlock>
            </Link>
          </ItemStackBlock>

          <Divider />

          {!error ? (
            <>
              <TextileCalculatorPrice
                textilePrice={textilePrice}
                isCheckout={true}
                discountsLink={textileOrderRoute(props.match, '/discounts')}
              />

              <Divider />

              {calculatorOverview.data.has_checkout ? (
                <>
                  {!checkOwnerCheckout() ? (
                    <ButtonBlock
                      background="PRIMARY_LIGHT"
                      color="PRIMARY_DARK"
                      onClick={() => setStartOrderRequested('changeAuthor')}
                    >
                      <FormattedMessage id="textile checkout exists takeover" />
                    </ButtonBlock>
                  ) : (
                    <>
                      {atLeastOrderStarted(
                        textileAppState.textile_order.order_state
                      ) ? (
                        <ButtonBlock
                          background="PRIMARY"
                          onClick={() =>
                            props.history.push(
                              textileOrderRoute(props.match, '/checkout')
                            )
                          }
                        >
                          <FormattedMessage id="To checkout" />
                        </ButtonBlock>
                      ) : (
                        <ButtonBlock
                          background="PRIMARY"
                          onClick={() => restartCheckout()}
                        >
                          <FormattedMessage id="Start checkout" />
                        </ButtonBlock>
                      )}
                    </>
                  )}
                </>
              ) : (
                <ButtonBlock
                  background="PRIMARY"
                  onClick={() => setStartOrderRequested('checkout')}
                >
                  <FormattedMessage id="Start checkout" />
                </ButtonBlock>
              )}
            </>
          ) : (
            <>{error && <>{error.errors.map((itm) => <InputMessage error={true}>{itm}</InputMessage>)}</>}</>
          )}

          <IconListBlock>
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="Free shipping" />
            </IconListElement>
            <IconListDivider />
            <IconListElement icon="TICK_CIRCLE" fill="GREEN">
              <FormattedMessage id="Climate neutral print" />
            </IconListElement>
          </IconListBlock>

          <TrustSymbolsComponent />
        </ItemStackBlock>
      </>
    );
  };

  return renderCalculator();
};

export default OrderCalculatorScreen;
