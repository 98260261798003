import React from 'react';
import { FormattedMessage } from 'react-intl';

import MessageBlock from 'blocks/MessageBlock';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import Paragraph from 'components/Paragraph/Paragraph';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';

const FormErrors = ({
  errors
}: {
  errors?: { [key: string]: string[] };
}) => (
  <>
    {errors ? (
      <MessageBlock static={true} error={true}>
        <NonGapStackBlock>
          <Paragraph  color="RED">
            <FormattedMessage id="funnel form error info" />
          </Paragraph>
          <ul>
            <ListStackBlock>
              {Object.entries(errors).map(([field, message]) => (
                <li key={field}>{message}</li>
              ))}
            </ListStackBlock>
          </ul>
        </NonGapStackBlock>
      </MessageBlock>
    ) : (
      <GenericErrorComponent />
    )}
  </>
);

export default FormErrors;
