import { intl } from 'i18n';
import React from 'react';

import SupportTicketScreen from 'screens/SupportTicketScreen/SupportTicketScreen';
import { ROUTE_RANKINGS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

export const RankingIdeasScreen = ({ match }: HistoryProps) => (
  <SupportTicketScreen
    subject="idealist_rankings"
    backTo={ROUTE_RANKINGS + (!match.params.chapterId ? '' : '/' + match.params.chapterId)}
    organizerOnly={true}
    message={intl.formatMessage({ id: 'zap_ideas_rankings'})}
  />
);

export default RankingIdeasScreen;
