import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import CharCounterComponent from 'components/CharCounterComponent/CharCounterComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import TextInputComponent from 'components/Inputs/TextInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import CancelLink from 'domain/Links/CancelLink';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { CoverChapterSettingsModelType } from 'models/chapter-settings/CoverChapterSettingsModel';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ROUTE_LAYOUT } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import InputMessage from 'elements/InputMessage';
import Paragraph from 'components/Paragraph/Paragraph';

interface SpineTextScreenProps {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  form: FormType;
}

const MAX_LENGTH = 80;

@inject('applicationStore', 'chaptersStore')
@observer
class SpineTextScreen extends React.Component<
SpineTextScreenProps & HistoryProps
> {
  componentDidMount() {
    this.loadCover();
  }

  async loadCover() {
    const { chaptersStore, form } = this.props;

    form.reset();

    const cover = await chaptersStore.getCoverChapter();

    if (cover && !cover.layout_key) {
      this.props.history.replace(ROUTE_LAYOUT + '/theme');
      return;
    }

    form.setField(
      'spine_text',
      (cover?.settings as CoverChapterSettingsModelType)?.spine_text || ''
    );
  }

  async save() {
    const { chaptersStore, form } = this.props;

    const cover = chaptersStore.itemIfCover;
    if (!cover) {
      return;
    }

    form.setLoading(true);
    form.resetErrors();

    try {
      await chaptersStore.updateChapterSettings(cover.id, {
        spine_text: form.values.spine_text || ''
      });

      // form.setLoading(false);
      this.props.history.push(ROUTE_LAYOUT + '/cover');
    } catch (error: any) {
      if (!handleFormError(form, error)) {
        form.setError('base', true);
      }

      form.setLoading(false);
    }
  }

  renderForm() {
    const { form } = this.props;

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="Spine text" />
        </Headline.Large>

        <InputMessage warning={true}>
          <Paragraph>
            <FormattedMessage id="spine info" />
          </Paragraph>
        </InputMessage>
        <ItemStackBlock gap="XXS">
          <TextInputComponent
            name="spine_text"
            label={<FormattedMessage id="Enter spine text" />}
            {...form.bindInput('spine_text')}
            autoFocus={true}
            maxLength={MAX_LENGTH}
          />

          <CharCounterComponent
            max={MAX_LENGTH}
            value={form.values.spine_text}
          />
        </ItemStackBlock>

        {form.errors.base && <GenericErrorComponent />}

        <ButtonBlock background="PRIMARY" onClick={() => this.save()}>
          <FormattedMessage id="Save" />
        </ButtonBlock>
      </>
    );
  }

  renderPage(content: any) {
    return (
      <>
        <TopActionBlock>
          <CancelLink to={ROUTE_LAYOUT + '/cover'} />
        </TopActionBlock>


        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.loadCover()} />
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  render() {
    const { chaptersStore, form } = this.props;

    if (chaptersStore.isItemLoading || form.loading) {
      return this.renderLoading();
    }

    if (chaptersStore.isItemLoadError) {
      return this.renderError();
    }

    return this.renderForm();
  }
}

export default (props: SpineTextScreenProps) => {
  const form = useForm();
  // @ts-ignore
  return <SpineTextScreen {...props} form={form} />;
};
