import React from 'react';
import { Link } from 'react-router-dom';

import CardBlock from 'blocks/CardBlock';
import ImageBlock from 'blocks/ImageBlock';
import MediaBlock from 'blocks/MediaBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import StatusBlock from 'blocks/StatusBlock';
import TaglineBlock from 'blocks/TaglineBlock';
import IconComponent from 'components/IconComponent';
import Headline from 'elements/Headline';
import TextElement from 'components/TextElement/TextElement';

interface LinkboxComponentProps {
  imagePath?: string;
  link: string;
  noImage?: boolean;
  statusInfo?: string;
  statusSuccess?: boolean;
  title?: string;
}

function LinkboxComponent({
  imagePath,
  link,
  noImage = false,
  statusInfo,
  statusSuccess = true,
  title
}: LinkboxComponentProps) {
  return (
    <Link to={link}>
      <CardBlock>
        <MediaBlock>
          {!noImage && (
            <ImageBlock background="GRAY900" width={3.75}>
              <img src={imagePath} alt="" />
            </ImageBlock>
          )}
          <PaddingBlock arrow={true}>
            <Headline.Small color="BLACK">{title}</Headline.Small>
            <TaglineBlock>
              <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
              <TextElement  color="TEXT_DARK">Platziert</TextElement>
            </TaglineBlock>
            {statusInfo && (
              <StatusBlock>
                <TextElement  color="GRAY10">
                  {statusSuccess ? (
                    <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                  ) : (
                    <IconComponent icon="WARNING_CIRCLE" fill="PRIMARY" />
                  )}
                  {statusInfo}
                </TextElement>
              </StatusBlock>
            )}
          </PaddingBlock>
        </MediaBlock>
      </CardBlock>
    </Link>
  );
}

export default LinkboxComponent;
