import React, { useContext, useEffect, useRef } from 'react';

import { TextilePrice } from 'api/textile_deals/fetchTextilePrices';
import { Textile } from 'api/textile_deals/fetchTextiles';
import ButtonBlock from 'blocks/ButtonBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import InfoBoxComponent from 'components/InfoBoxComponent/InfoBoxComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import TrustSymbolsComponent from 'components/TrustSymbolsComponent';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Cookies from 'js-cookie';
import { useTextilePriceFromFormQuery } from 'queries/textile_deals/useTextilePriceQueries';
import { useTextilesQuery } from 'queries/textile_deals/useTextileQueries';
import { FormattedMessage } from 'react-intl';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import { FormType, useForm } from 'utils/hooks/useForm';
import { snakeCase } from 'utils/snakeCase';
import TextileCalculatorForm from './Components/TextileCalculatorForm';
import TextileCalculatorPrice from './Components/TextileCalculatorPrice';

interface FormProps {
  form: FormType;
}

const TextileCalculator: React.FC<HistoryProps & FormProps> = (props) => {
  const sendTimeoutHandle = useRef<NodeJS.Timeout | undefined>();
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const textiles = useTextilesQuery(textileOrderId);
  const textilePrice = useTextilePriceFromFormQuery();

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  const setupForm = (textiles?: Textile[], price?: TextilePrice) => {
    if (Cookies.get('textile_pricing_config')) {
      return;
    }

    if (!textiles || textiles.length === 0) {
      return;
    }

    textiles.forEach((textile) => {
      let amount;
      if (price && price.price) {
        price.price.items.forEach((itm) => {
          if (itm.textile_id === textile.id) {
            amount = itm.amount;
          }
        });
      }
      props.form.setField(
        'amount_' + snakeCase(textile.name),
        // @ts-ignore
        amount?.toString() || '15'
      );
    });
  };

  const getPrice = () => {
    const { form } = props;

    if (!textileOrderId || !textiles.data || textiles.data.length === 0) {
      return;
    }

    form.resetErrors();
    form.setLoading(true);

    const items: { id: number; amount: number }[] = [];

    textiles.data.forEach((textile) => {
      items.push({
        id: textile.id,
        amount: form.values['amount_' + snakeCase(textile.name)]
      });
    });

    textilePrice.mutate(
      { textileOrderId, data: items },
      {
        onSettled: () => {
          form.setLoading(false);
        }
      }
    );
  };

  const scheduleGetPrice = () => {
    clearTimeout(sendTimeoutHandle.current);
    sendTimeoutHandle.current = setTimeout(() => {
      getPrice();
    }, 1000);
  };

  useEffect(() => {
    accessContext.checkSectionAccess('calculator');

    setupForm(textiles.data, textilePrice.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textiles.data]);

  useEffect(() => {
    scheduleGetPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.values]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '')} />
        </TopActionBlock>
        <PageStackBlock>
          <PageHeader
            text={<FormattedMessage id="textile calculator text" />}
            headline={<FormattedMessage id="Calculator" />}
          ></PageHeader>
          {content}
        </PageStackBlock>
      </>
    );
  };

  if (textiles.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textiles.refetch()} />
    );
  }

  if (Object.keys(props.form.errors).length > 0) {
    return renderPage(
      <InfoBoxComponent error={true}>
        <FormattedMessage id="calculator error" />
      </InfoBoxComponent>
    );
  }

  if (textiles.isLoading || !textiles.data) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const changeInput = (value: string, field: string) => {
    const { form } = props;

    if (parseInt(value) < 0) {
      form.setField(field, '0');
    } else {
      form.setField(field, value);
    }
    form.setLoading(true);
    form.resetErrors();
  };

  const offerAsPDF = () => {
    props.history.push(textileOrderRoute(props.match, '/calculator/offer'));
  };

  const renderCalculator = () => {
    const { form } = props;

    if (textiles.data.length === 0) {
      return null;
    }

    const isLoading = form.loading;

    return renderPage(
      <ItemStackBlock gap="L">
        <TextileCalculatorForm
          form={form}
          changeInput={changeInput}
          textiles={textiles.data}
          errors={textilePrice.data?.errors}
          match={props.match}
        />

        {isLoading ? (
          <LoadingIndicatorComponent />
        ) : (
          !textilePrice.data?.errors && (
            <>
              <TextileCalculatorPrice
                textilePrice={textilePrice.data}
                discountsLink={textileOrderRoute(props.match, '/discounts')}
              />

              <Divider />
              <ButtonBlock background="PRIMARY" onClick={() => offerAsPDF()}>
                <FormattedMessage id="Offer as PDF" />
              </ButtonBlock>
            </>
          )
        )}

        <IconListBlock>
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="Free shipping" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="Climate neutral print" />
          </IconListElement>
        </IconListBlock>

        <TrustSymbolsComponent />
      </ItemStackBlock>
    );
  };

  return renderCalculator();
};

const TextileCalculatorScreen = (props: any) => {
  // Read last config from cookie
  const cookie = Cookies.get('textile_pricing_config');

  let formValues: { [key: string]: string } = {};

  const textileOrderId = textileOrderIdFromMatch(props.match);

  if (cookie) {
    let cookieValues;
    try {
      cookieValues = JSON.parse(cookie);
    } catch (e) {}
    if (typeof cookieValues === 'object') {
      if (cookieValues['textile_order_id'] === textileOrderId) {
        cookieValues.form_values.forEach(
          (cookieValue: { name: string; amount: number }) => {
            formValues[
              'amount_' + snakeCase(cookieValue.name)
            ] = cookieValue.amount.toString();
          }
        );
      }
    }
  }

  const form = useForm(formValues);
  return <TextileCalculator {...props} form={form} />;
};

export default TextileCalculatorScreen;
