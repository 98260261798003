import React from 'react';

import { PrintPicture } from '../elements/PicturePrintComponent';
// TODO remove fallback entirely
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import textLayout from '../templates/text-layout.json';
import TextTemplateComponent, {
  TextTemplateConfig
} from '../templates/TextTemplateComponent';
import { CommonLayoutPreviewProps } from './CommonLayoutPreviewProps';
import scalePreview from './scale-preview';

interface TextPreviewComponentProps extends CommonLayoutPreviewProps {
  config: TextTemplateConfig;

  text?: string;
  picture?: PrintPicture | null;
  long?: boolean;
}

export class TextPreviewComponent extends React.Component<
  TextPreviewComponentProps
> {
  shouldComponentUpdate() {
    // preview will be forced to redraw on config update only
    return false;
  }

  generateText(long = false) {
    const { chapter } = this.props;
    const headline =
      '## ' +
      (chapter?.title ? chapter.title : 'Eine schöne Geschichte') +
      '\n\n';
    const text =
      'Es war einmal eine alte Geis, die hatte sieben junge Geislein, und hatte sie lieb, wie eine Mutter ihre Kinder lieb hat. Eines Tages wollte sie in den Wald gehen und Futter holen, da rief sie alle sieben herbei und sprach „liebe Kinder, ich will hinaus in den Wald, seid auf eurer Hut vor dem Wolf, wenn er herein kommt, so frißt er Euch alle mit Haut und Haar. Der Bösewicht verstellt sich oft, aber an seiner rauhen Stimme und an seinen schwarzen Füßen werdet ihr ihn gleich erkennen.“ Die Geislein sagten, „liebe Mutter, wir wollen uns schon in Acht nehmen, Ihr könnt ohne Sorge fortgehen.“ Da meckerte die Alte und machte sich getrost auf den Weg.\n\n' +
      'Es dauerte nicht lange, so klopfte jemand an die Hausthür und rief „macht auf, ihr lieben Kinder, eure Mutter ist da und hat jedem von Euch etwas mitgebracht.“ Aber die Geiserchen hörten an der rauhen Stimme daß es der Wolf war, „wir machen nicht auf,“ riefen sie, „du bist unsere Mutter nicht, die hat eine feine und liebliche Stimme, aber deine Stimme ist rauh; du bist der Wolf.“ Da gieng der Wolf fort zu einem Krämer, und kaufte sich ein großes Stück Kreide: die aß er und machte damit seine Stimme fein. Dann kam er zurück, klopfte an die Hausthür und rief „macht auf, ihr lieben Kinder, eure Mutter ist da und hat jedem von Euch etwas mitgebracht.“ Aber der Wolf hatte seine schwarze Pfote in das Fenster gelegt, das sahen die Kinder und riefen „wir machen nicht auf, unsere Mutter hat keinen schwarzen Fuß, wie du: du bist der Wolf.“ Da lief der Wolf zu einem Bäcker und sprach „ich habe mich an den Fuß gestoßen, streich mir Teig darüber.“ Und als ihm der Bäcker die Pfote bestrichen hatte, so lief er zum Müller und sprach „streu mir weißes Mehl auf meine Pfote.“ Der Müller dachte „der Wolf will einen betrügen“ und weigerte sich, aber der Wolf sprach „wenn du es nicht thust, so fresse ich dich.“ Da fürchtete sich der Müller und machte ihm die Pfote weiß. Ja, das sind die Menschen.\n\n' +
      '### So kann’s gehen\n\n' +
      'Nun gieng der Bösewicht zum drittenmal zu der Hausthüre, klopfte an und sprach „macht mir auf, Kinder, euer liebes Mütterchen ist heim gekommen und hat jedem von Euch etwas aus dem Walde mitgebracht.“ Die Geiserchen riefen „zeig uns erst deine Pfote, damit wir wissen daß du unser liebes Mütterchen bist.“ Da legte er die Pfote ins Fenster, und als sie sahen daß sie weiß war, so glaubten sie es wäre alles wahr, was er sagte, und machten die Thüre auf. Wer aber hereinkam, das war der Wolf. Sie erschraken und wollten sich verstecken.';
    return headline + (long ? text + '\n\n' + text : text);
  }

  render() {
    const {
      config,
      print,
      colors,
      pageHeaderStyle,
      sectionHeaderStyle,
      background,
      start,
      scaled,
      chapter,
      text,
      picture,
      title,
      firstPageOnly,
      long
    } = this.props;

    let previewPicture;
    if (picture) {
      previewPicture = picture;
    } else if (picture !== null) {
      // if picture is null do not display default
      previewPicture = {
        url: '/images/content-preview/album_landscape_2.jpg'
      };
    }

    // TODO remove fallback for text layout
    return (
      <>
        <TextTemplateComponent
          print={print}
          firstPageOnly={firstPageOnly}
          config={config}
          colors={colors || chapter?.color_scheme?.templateColors || []}
          pageHeaderStyle={pageHeaderStyle || chapter?.font?.pageHeaderStyle}
          sectionHeaderStyle={
            sectionHeaderStyle || chapter?.font?.sectionHeaderStyle
          }
          background={
            background ||
            (chapter?.background?.id || '') +
              (chapter?.background?.extension || '') ||
            undefined
          }
          startPosition={start || 'left'}
          startPage={this.props.startPage || 1}
          render={
            !scaled ? undefined : (content, key) => scalePreview(content, key)
          }
          title={title || chapter?.title}
          text={typeof text !== 'string' ? this.generateText(long) : text}
          picture={previewPicture}
        />
      </>
    );
  }
}

export default TextPreviewComponent;
