import React, { MouseEvent, ReactNode } from 'react';

import ItemStackBlock from 'blocks/ItemStackBlock';
import LinkBlock from 'blocks/LinkBlock';
import IconComponent from 'components/IconComponent';
import SharingComponent from 'components/SharingComponent';
import SearchListInput from 'elements/SearchListInput';
import UppercaseHeading from 'elements/UppercaseHeading';
import { FormattedMessage } from 'react-intl';
import useForm, { FormType } from 'utils/hooks/useForm';

import { StudentType, TextileStudent } from 'api/textile_deals/fetchStudentList';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import { sortByFirstNameThenLastName, sortByLastNameThenFirstName, sortByName } from 'utils/sort-functions';
import TextileProfileListItem from './TextileProfileListItem';

interface PublicTextileProfileListProps {
  students: TextileStudent[];
  showSorting?: boolean;
  onProfileClick?: (
    e: MouseEvent,
    profileId: number,
    type?: StudentType
  ) => void;
  sortingLink?: string;
  textileOrderId?: number;
  sorting?: 'first_name' | 'last_name';
  inviteUrl?: string;
}

interface FormProps {
  form: FormType;
}


const TextileProfileList: React.FC<PublicTextileProfileListProps & FormProps> = (props) => {
  const filteredStudents = (): TextileStudent[] => {
    const {form, sorting, students} = props;
    const filteredStudents: TextileStudent[] = [];
    const nameLowercase = form.values.filter ? form.values.filter.trim().toLowerCase() : '';

    students.forEach((itm) => {
      if (
        itm.name &&
        itm.name.toLowerCase().indexOf(nameLowercase) > -1
      ) {
        filteredStudents.push(itm);
      }
    })


    if (sorting) {
      if (sorting === 'first_name') {
        filteredStudents.sort(sortByFirstNameThenLastName);
      } else {
        filteredStudents.sort(sortByLastNameThenFirstName);
      }
    } else {
      filteredStudents.sort(sortByName);
    }

    return filteredStudents;
  }

  const renderList = () => {
        // TODO Display some loading indicator for 'updating' loading state?
        const {
          form,
          onProfileClick,
          showSorting,
          sortingLink,
          textileOrderId,
          inviteUrl
        } = props;


        let heading: ReactNode;
        let students = filteredStudents();

        heading = (
          <FormattedMessage
            id="students filter count"
            values={{
              count: students.length
            }}
          />
        );


        return (
          <ItemStackBlock gap="L">
            <SearchListInput form={form} placeholderId="Find student" />

            {showSorting && (
              <LinkBlock
                inline={true}
                slim={true}
                background="PRIMARY_LIGHT"
                color="PRIMARY_DARK"
                to={sortingLink || textileOrderRoute(textileOrderId, '')}
              >
                <IconComponent icon="sort_v" />
                <FormattedMessage id="Print sorting order" />
              </LinkBlock>
            )}

            <ItemStackBlock gap="M">
              <UppercaseHeading>{heading}</UppercaseHeading>

              {students.length !== 0 && students.map((profile) => (
                <TextileProfileListItem
                  key={profile.id}
                  profile={profile}
                  onClick={onProfileClick}
                />
              ))}
            </ItemStackBlock>

            {students.length === 0 && (
              <SharingComponent
                label="invite header"
                buttonOutside={true}
                modalHeader="invite link"
                message="Nuggit invite text"
                buttonColor="WHATSAPP"
                url={inviteUrl}
              />
            )}
          </ItemStackBlock>
        );
            }
  return renderList();
}

export default (props: PublicTextileProfileListProps) => {
  const form = useForm();
  // @ts-ignore
  return <TextileProfileList {...props} form={form} />;
};
