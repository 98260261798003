import config from 'config';
import {
  detach,
  flow,
  getEnv,
  IMSTMap,
  Instance,
  types
} from 'mobx-state-tree';
import { assert } from 'utils/assert';
import { createMapWithTransform } from 'utils/create-map';
import { moveItem } from 'utils/item-sorting';
import { sortByField, sortByName, sortByTitle } from 'utils/sort-functions';

import ChapterModel, {
  ChapterModelType, CHAPTER_TYPES_EXCLUDED_FROM_TOC, CHAPTER_TYPES_WITH_LAYOUT_EDITOR, createChapterModel
} from './ChapterModel';
import { ChapterType } from './ChapterTypeEnum';
import ContentDashboardModel, {
  createContentDashboardModel
} from './ContentDashboardModel';
import DiscountDashboardModel, {
  createDiscountDashboardModel
} from './DiscountDashboardModel';
import MainDashboardModel, {
  createMainDashboardModel
} from './MainDashboardModel';
import { LayoutStoreType } from './print/LayoutStore';
import { ProfileAsAuthorModelType } from './ProfileAsAuthorModel';
import { AdvancedStoreEnv } from './StoreEnv';

export type AllowedGroupSpecificChapter = 'any' | 'group' | 'global' | false;

const LoadingStateEnum = types.enumeration([
  'loading',
  'updating',
  'error',
  'update_error',
  'remove_error'
]);

const CHAPTER_TYPE_ORDER: { [key in ChapterType]?: number } = {
  ranking: 10,
  quote: 20,
  album: 30
};

interface StoreEnv extends AdvancedStoreEnv {
  layoutStore: LayoutStoreType;
}

const sortedChaptersWithoutCover = (
  chapters?: IMSTMap<any>
): ChapterModelType[] => {
  if (!chapters) {
    return [];
  }

  const list: ChapterModelType[] = [];

  for (const chapter of chapters.values()) {
    if (chapter.chapter_type !== 'cover') {
      list.push(chapter);
    }
  }

  list.sort(sortByField('sorting'));

  return list;
};

const ChaptersStore = types
  .model('ChaptersStore', {
    // list of all chapters
    chapters: types.maybe(types.map(ChapterModel)),
    chaptersLoadingState: types.maybe(LoadingStateEnum),
    // single item
    itemLoadingState: types.maybe(LoadingStateEnum),
    item: types.maybe(ChapterModel),

    // dashboard
    mainDashboard: types.maybe(MainDashboardModel),
    mainDashboardLoadingState: types.maybe(LoadingStateEnum),
    discountDashboard: types.maybe(DiscountDashboardModel),
    discountDashboardLoadingState: types.maybe(LoadingStateEnum),
    contentDashboard: types.maybe(ContentDashboardModel)
  })
  .actions((self) => {
    const getAllChapters = flow(function* (includeBook: boolean = false) {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      self.chaptersLoadingState = 'loading';
      self.chapters = undefined;

      try {
        const result: any = yield client.getAllChapters(includeBook);

        if (!result) {
          throw new Error('No response from server');
        }

        const book = includeBook ? result.book : undefined;
        const chapters = includeBook ? result.chapters : result;

        if (!Array.isArray(chapters)) {
          throw new Error('No valid response from server');
        }

        self.chapters = createMapWithTransform(chapters, createChapterModel);

        if (book) {
          applicationStore.setBook(book);
        }

        self.chaptersLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ChaptersStore | getChapters', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.chaptersLoadingState = undefined;
          return;
        }

        self.chaptersLoadingState = 'error';
      }
    });

    const getChapter = flow(function* (chapterId: number) {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      self.itemLoadingState = 'loading';
      self.item = undefined;

      try {
        const result: any = yield client.getChapter(chapterId);

        if (!result) {
          throw new Error('No response from server');
        }

        self.item = createChapterModel(result);

        self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ChaptersStore | getChapter', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    const getCoverChapter = flow(function* () {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      self.itemLoadingState = 'loading';
      self.item = undefined;

      try {
        const result: any = yield client.getCoverChapter();

        if (!result) {
          throw new Error('No response from server');
        }

        self.item = createChapterModel(result);

        if (result.book) {
          applicationStore.setBook(result.book);
        }

        self.itemLoadingState = undefined;
        return self.item;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ChaptersStore | getCoverChapter', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        self.itemLoadingState = 'error';
      }
    });

    const createChapter = flow(function* (item: any, addToList = true) {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      self.itemLoadingState = 'loading';
      self.item = undefined;

      try {
        const result: any = yield client.createChapter(item);

        if (!result) {
          throw new Error('No response from server');
        }

        const chapter = createChapterModel(result);
        self.item = chapter;

        if (addToList && self.chapters && chapter) {
          // create new model as we cannot put the same model into the tree twice
          self.chapters.put(createChapterModel(chapter)!);
        }

        self.itemLoadingState = undefined;

        return chapter;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ChaptersStore | createChapter', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        if (client.isFormError(error)) {
          self.itemLoadingState = undefined;
          throw error;
        }

        self.itemLoadingState = 'update_error';
      }
    });

    const updateChapter = flow(function* (chapterId: number, patch: any) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        // self.itemLoadingState = 'loading';

        const chapter: any = yield client.updateChapter(chapterId, patch);

        if (!chapter || !chapter.id) {
          throw new Error('No response from server');
        }

        if (self.chapters && self.chapters.has(chapter.id.toString())) {
          const model = createChapterModel(chapter);
          if (model) {
            self.chapters.put(model);
          }
        }

        if (self.item?.id === chapter.id) {
          detach(self.item!);
          self.item = createChapterModel(chapter);
        }
        // self.itemLoadingState = undefined;

        return chapter;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ChaptersStore | updateChapter', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          throw error; // throw error so screens abort update flow
        }

        if (client.isFormError(error)) {
          // self.itemLoadingState = undefined;
          throw error;
        }

        // self.itemLoadingState = 'update_error';
        throw new Error('update_error');
      }
    });

    const swapChapters = flow(function* (
      chapterId: number,
      swapChapterId: number
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        // self.itemLoadingState = 'loading';

        const swap: any = yield client.swapChapters(chapterId, swapChapterId);

        if (
          swap.first_element?.id &&
          swap.second_element?.id &&
          typeof swap.first_element.sorting === 'number' &&
          typeof swap.second_element.sorting === 'number'
        ) {
          if (self.chapters) {
            const firstElement = self.chapters.get(
              swap.first_element.id.toString()
            );
            if (firstElement) {
              firstElement.sorting = swap.first_element.sorting;
            }

            const secondElement = self.chapters.get(
              swap.second_element.id.toString()
            );
            if (secondElement) {
              secondElement.sorting = swap.second_element.sorting;
            }
          }

          if (self.item) {
            if (self.item.id === swap.first_element.id) {
              self.item.sorting = swap.first_element.sorting;
            } else if (self.item.id === swap.second_element.id) {
              self.item.sorting = swap.second_element.sorting;
            }
          }
        }

        // self.itemLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ChaptersStore | swapChapters', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          return;
        }

        // self.itemLoadingState = 'update_error';
        throw new Error('update_error');
      }
    });

    const updateChapterSettings = flow(function* (
      chapterId: number,
      patch: any
    ) {
      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);
      try {
        // self.itemLoadingState = 'loading';

        const chapter = yield client.updateChapterSettings(chapterId, patch);

        if (!chapter || !chapter.id) {
          throw new Error('No response from server');
        }

        const model = createChapterModel(chapter);

        if (model) {
          if (self.chapters?.has(chapter.id.toString())) {
            self.chapters.put(model);
          }

          if (self.item && self.item.id === chapter.id) {
            self.item = createChapterModel(chapter);
          }
          // self.itemLoadingState = undefined;
        }

        return model;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ChaptersStore | updateChapterSettings',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          // self.itemLoadingState = undefined;
          throw error; // throw error so screens abort update flow
        }

        if (client.isFormError(error)) {
          // self.itemLoadingState = undefined;
          throw error;
        }

        // self.itemLoadingState = 'update_error';
        throw new Error('update_error');
      }
    });

    const removeChapter = flow(function* (
      chapterId: number,
      removeFromList = true
    ) {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      self.itemLoadingState = 'loading';

      try {
        yield client.removeChapter(chapterId);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ChaptersStore | removeChapter', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.itemLoadingState = undefined;
          return;
        }

        // not found is not an error when removing
        if (!client.isNotFound(error)) {
          self.itemLoadingState = 'remove_error';
          throw error;
        }
      }

      if (removeFromList && self.chapters) {
        self.chapters.delete(chapterId.toString());
      }

      if (self.item && self.item.id === chapterId) {
        self.item = undefined;
      }

      self.itemLoadingState = undefined;
    });

    const moveChapter = flow(function* (oldIndex: number, newIndex: number) {
      assert(self.chapters);

      // 'rescue' cover chapter because it will be filtered out for sorting
      let cover;
      for (const chapter of self.chapters.values()) {
        if (chapter.chapter_type === 'cover') {
          cover = chapter;
          break;
        }
      }

      const { items, map } = moveItem(
        // @ts-ignore
        sortedChaptersWithoutCover(self.chapters),
        oldIndex,
        newIndex,
        1
      );

      for (const chapter of self.chapters.values()) {
        detach(chapter);
      }

      self.chapters = map;
      if (cover) {
        self.chapters!.put(cover);
      }

      const { client, applicationStore } = getEnv<AdvancedStoreEnv>(self);

      try {
        // Do not set loading state, this would block the UI
        // self.loadingState = undefined;

        yield client.setChaptersSorting(items as ChapterModelType[]);
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ChaptersStore | moveChapter', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          // self.loadingState = undefined;
          return;
        }

        // self.loadingState = 'error';
        throw error;
      }

      // self.loadingState = undefined;
    });

    const clearCurrentItem = () => {
      self.item = undefined;
      self.itemLoadingState = undefined;
    };

    // dashboard
    const getMainDashboard = flow(function* () {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      self.mainDashboardLoadingState = 'loading';
      self.chapters = undefined;
      self.mainDashboard = undefined;

      try {
        const result: any = yield client.getMainDashboard();

        if (!result) {
          throw new Error('No response from server');
        }

        self.mainDashboard = createMainDashboardModel(result);

        if (result.book) {
          applicationStore.setBook(result.book);
        }

        if (Array.isArray(result.client_states)) {
          applicationStore.setClientStates(result.client_states);
        }

        self.mainDashboardLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error('ChaptersStore | getMainDashboard', error, error.body);
        }

        if (applicationStore.handleAppError(error)) {
          self.mainDashboardLoadingState = undefined;
          return;
        }

        self.mainDashboardLoadingState = 'error';
      }
    });

    const getContentDashboard = flow(function* () {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      self.chaptersLoadingState = 'loading';
      self.chapters = undefined;
      self.contentDashboard = undefined;

      try {
        const result: any = yield client.getContentDashboard();

        if (!result) {
          throw new Error('No response from server');
        }

        let chapters: any[] = [];
        if (Array.isArray(result.ranking_chapters)) {
          chapters = chapters.concat(result.ranking_chapters);
        }
        if (Array.isArray(result.quote_chapters)) {
          chapters = chapters.concat(result.quote_chapters);
        }
        if (Array.isArray(result.album_chapters)) {
          chapters = chapters.concat(result.album_chapters);
        }
        if (result.factsheet) {
          chapters.push(result.factsheet);
        }
        if (result.yearbook) {
          chapters.push(result.yearbook);
        }

        self.chapters = createMapWithTransform(chapters, createChapterModel);

        self.contentDashboard = createContentDashboardModel({
          has_factsheet: !!result.factsheet,
          questions_count: result.questions_count,
          answers_count: result.questions_answered,
          has_slot1_photo: result.present_photos?.slot1,
          has_slot2_photo: result.present_photos?.slot2,
          has_slot3_photo: result.present_photos?.slot3,
          has_creative_page: result.present_photos?.creative_page,
          has_text: result.has_text,

          comments_count: result.comments?.on_profile_count,
          comments_as_author_count: result.comments?.as_author_count,

          has_yearbook_text: result.has_yearbook_text,
          has_yearbook_photo: result.has_yearbook_photo
        });

        self.chaptersLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ChaptersStore | getContentDashboard',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.chaptersLoadingState = undefined;
          return;
        }

        self.chaptersLoadingState = 'error';
      }
    });

    const getDiscountDashboard = flow(function* () {
      const { client, applicationStore } = getEnv<StoreEnv>(self);

      self.discountDashboardLoadingState = 'loading';
      self.discountDashboard = undefined;

      try {
        const result: any = yield client.getDiscountDashboard();

        if (!result) {
          throw new Error('No response from server');
        }

        self.discountDashboard = createDiscountDashboardModel(result);

        if (result.book) {
          applicationStore.setBook(result.book);
        }

        self.discountDashboardLoadingState = undefined;
      } catch (error: any) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(
            'ChaptersStore | getDiscountDashboard',
            error,
            error.body
          );
        }

        if (applicationStore.handleAppError(error)) {
          self.discountDashboardLoadingState = undefined;
          return;
        }

        self.discountDashboardLoadingState = 'error';
      }
    });

    const clear = () => {
      self.chapters = undefined;
      self.item = undefined;
      self.mainDashboard = undefined;
      self.contentDashboard = undefined;
    };

    return {
      getAllChapters,
      getChapter,
      getCoverChapter,
      createChapter,
      updateChapter,
      swapChapters,
      updateChapterSettings,
      removeChapter,
      moveChapter,
      clearCurrentItem,

      getMainDashboard,
      getContentDashboard,
      getDiscountDashboard,

      clear
    };
  })
  .views((self) => {
    return {
      get isListLoading(): boolean {
        return self.chaptersLoadingState === 'loading' ||
          self.chaptersLoadingState === 'updating'
          ? true
          : false;
      },
      get isListError(): boolean {
        return self.chaptersLoadingState === 'error' ||
          self.chaptersLoadingState === 'update_error'
          ? true
          : false;
      },

      get isItemLoading(): boolean {
        return self.itemLoadingState === 'loading';
      },
      get isItemLoadError(): boolean {
        return self.itemLoadingState === 'error';
      },
      get isItemError(): boolean {
        return (
          self.itemLoadingState === 'error' ||
          self.itemLoadingState === 'update_error'
        );
      },
      get isItemUpdateError(): boolean {
        return self.itemLoadingState === 'update_error';
      },
      get isItemRemoveError(): boolean {
        return self.itemLoadingState === 'remove_error';
      },

      get hasAnyChapters(): boolean {
        return !!self.chapters?.size;
      },

      get cover(): ChapterModelType | undefined {
        if (self.item?.chapter_type === 'cover') {
          return self.item;
        }

        if (!self.chapters) {
          return undefined;
        }

        for (const chapter of self.chapters.values()) {
          if (chapter.chapter_type === 'cover') {
            return chapter;
          }
        }

        return undefined;
      },
      get sortedChaptersWithoutCover(): ChapterModelType[] {
        return sortedChaptersWithoutCover(self.chapters);
      },
      get sortedChaptersWithLayoutEditor(): ChapterModelType[] {
        if (!self.chapters?.size) {
          return [];
        }

        const list: ChapterModelType[] = [];

        for (const chapter of self.chapters.values()) {
          if (
            CHAPTER_TYPES_WITH_LAYOUT_EDITOR.indexOf(chapter.chapter_type) > -1
          ) {
            list.push(chapter);
          }
        }

        list.sort(sortByField('sorting'));

        return list;
      },

      get allowedChapterTypes() {
        if (!self.chapters) {
          return undefined;
        }

        const allowedTypes: {
          table_of_contents: boolean;
          factsheet: AllowedGroupSpecificChapter;
          yearbook: AllowedGroupSpecificChapter;
        } = {
          table_of_contents: true,
          factsheet: false,
          yearbook: false
        };

        let hasFactsheetChapter = false;
        let hasGlobalFactsheetChapter = false;

        let hasYearbookChapter = false;
        let hasGlobalYearbookChapter = false;

        for (const chapter of self.chapters.values()) {
          switch (chapter.chapter_type) {
            case 'table_of_contents':
              allowedTypes.table_of_contents = false;
              break;

            case 'factsheet':
              hasFactsheetChapter = true;
              if (!chapter.group) {
                hasGlobalFactsheetChapter = true;
              }
              break;

            case 'yearbook':
              hasYearbookChapter = true;
              if (!chapter.group) {
                hasGlobalYearbookChapter = true;
              }
              break;

            default:
          }
        }

        if (!hasFactsheetChapter) {
          allowedTypes.factsheet = 'any';
        } else if (!hasGlobalFactsheetChapter) {
          allowedTypes.factsheet = 'group';
        }

        if (!hasYearbookChapter) {
          allowedTypes.yearbook = 'any';
        } else if (!hasGlobalYearbookChapter) {
          allowedTypes.yearbook = 'group';
        }

        return allowedTypes;
      },
      getChapterGroupIds(chapterType: ChapterType): number[] {
        if (!self.chapters?.size) {
          return [];
        }

        const ids: number[] = [];

        for (const chapter of self.chapters.values()) {
          if (chapter.chapter_type === chapterType && chapter.group?.id) {
            ids.push(chapter.group.id);
          }
        }

        return ids;
      },

      getChaptersByGroupId(id: number): ChapterModelType[] {
        if (!self.chapters?.size) {
          return [];
        }

        const chapters: ChapterModelType[] = [];

        for (const chapter of self.chapters.values()) {
          if (chapter.group?.id === id) {
            chapters.push(chapter);
          }
        }

        return chapters;
      },
      // dashboard views
      dashboardOrganizers(filterId?: number): ProfileAsAuthorModelType[] {
        if (!self.mainDashboard?.organizers?.size) {
          return [];
        }

        const profiles: ProfileAsAuthorModelType[] = [];

        for (const profile of self.mainDashboard.organizers.values()) {
          if (profile.id !== filterId) {
            profiles.push(profile);
          }
        }

        profiles.sort(sortByName);
        return profiles;
      },

      get firstFactsheetChapter(): ChapterModelType | undefined {
        if (self.chapters?.size) {
          for (const chapter of self.chapters.values()) {
            if (chapter.chapter_type === 'factsheet') {
              return chapter;
            }
          }
        }

        return undefined;
      },
      get firstYearbookChapter(): ChapterModelType | undefined {
        if (self.chapters?.size) {
          for (const chapter of self.chapters.values()) {
            if (chapter.chapter_type === 'yearbook') {
              return chapter;
            }
          }
        }

        return undefined;
      },
      get studentChapters(): ChapterModelType[] {
        if (!self.chapters?.size) {
          return [];
        }

        const chapters: ChapterModelType[] = [];

        for (const chapter of self.chapters.values()) {
          // TODO do we need to do some other filtering here?
          if (chapter.chapter_type === 'factsheet') {
            continue;
          }

          if (chapter.chapter_type === 'yearbook') {
            continue;
          }

          chapters.push(chapter);
        }

        chapters.sort((a, b) => {
          if (a.chapter_type === b.chapter_type) {
            return sortByTitle(a, b);
          }

          const aOrder: any = CHAPTER_TYPE_ORDER[a.chapter_type] || 9999;
          const bOrder: any = CHAPTER_TYPE_ORDER[b.chapter_type] || 9999;

          if (aOrder < bOrder) {
            return -1;
          }
          if (aOrder > bOrder) {
            return 1;
          }
          return 0;
        });

        return chapters;
      },

      // layout views
      get itemIfCover(): ChapterModelType | undefined {
        if (self.item?.chapter_type === 'cover') {
          return self.item;
        }
        return undefined;
      },
      get toc(): ChapterModelType[] {
        if (!self.chapters?.size) {
          return [];
        }

        const list: ChapterModelType[] = [];

        for (const chapter of self.chapters.values()) {
          if (
            CHAPTER_TYPES_EXCLUDED_FROM_TOC.indexOf(chapter.chapter_type) > -1
          ) {
            continue;
          }

          if (!chapter.list_in_toc || !chapter.print || !chapter.title) {
            continue;
          }

          list.push(chapter);
        }

        list.sort(sortByField('sorting'));
        return list;
      },

      get allFonts() {
        let fonts = config.layout.defaultFonts;

        if (self.chapters?.size) {
          for (const chapter of self.chapters.values()) {
            if (chapter.layout?.fontSpecs) {
              fonts = fonts.concat(chapter.layout.fontSpecs);
            }

            if (chapter.font?.specs) {
              fonts = fonts.concat(chapter.font.specs);
            }
          }
        }

        return fonts;
      },

      get notListedInToc() {
        const notListed: ChapterModelType[] = [];

        if (self.chapters?.size) {
          for (const chapter of self.chapters.values()) {
            if (
              CHAPTER_TYPES_EXCLUDED_FROM_TOC.indexOf(chapter.chapter_type) > -1
            ) {
              continue;
            }

            if (!chapter.list_in_toc) {
              notListed.push(chapter);
            }
          }
        }

        notListed.sort(sortByField('sorting'));
        return notListed;
      }
    };
  });

export type ChaptersStoreType = Instance<typeof ChaptersStore>;
export default ChaptersStore;
