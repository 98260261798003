import { Boundary } from './ContentChunker';

// Boolean flag means: true => text fits; false => text doesn't fit
export default function shrinkText(
  textContent: string,
  startingFontSize: number,
  minFontSize: number,
  style: string,
  [outerWidth, outerHeight]: Boundary,
  shrinkingStep: number = 1,
  fontFamily?: string,
  letterSpacing?: string
): [number, boolean] {
  const outerElement = document.createElement('div');
  outerElement.setAttribute(
    'style',
    `display: block; visibility: hidden; width: ${outerWidth}px;  height: ${outerHeight}; ${style}`
  );

  const innerElement = document.createElement('span');
  outerElement.appendChild(innerElement);

  document.body.appendChild(outerElement);

  let fontSize = startingFontSize;
  while (true) {
    innerElement.setAttribute('style', `font-size: ${fontSize}px;${fontFamily ? `font-family:${fontFamily};` : ''}`);
    if (letterSpacing) {
      innerElement.setAttribute('style', innerElement.getAttribute('style') + `letter-spacing: ${letterSpacing}`)
    }
    innerElement.innerText = textContent;


    // measure
    const { height, width } = innerElement.getBoundingClientRect();
    if (height < outerHeight && width < outerWidth) {
      outerElement.remove();
      return [fontSize, false];
    } else if (fontSize < minFontSize) {
      outerElement.remove();
      return [minFontSize, true];
    }

    fontSize -= shrinkingStep;
  }
}
