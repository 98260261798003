import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import ConfirmBoxMultiComponent, {
  ConfirmOption
} from 'components/ConfirmBoxMultiComponent.tsx/ConfirmBoxMultiComponent';
import CopyTextInput from 'components/Inputs/CopyTextInput';
import Paragraph from 'components/Paragraph/Paragraph';
import { intl } from 'i18n';
import { inject, observer } from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import COLORS from 'utils/constants/colors';

interface SharingComponentProps {
  buttonOutside: boolean;
  modalHeader: string;
  message: string;
  label: string;
  buttonColor: keyof typeof COLORS;
  url?: string;
  onDidInteract?: () => void;
  applicationStore?: ApplicationStoreType;
}

@inject('applicationStore')
@observer
class SharingComponent extends React.Component<SharingComponentProps> {
  copyInput: React.RefObject<CopyTextInput>;
  copyInputButton: React.RefObject<CopyTextInput>;

  constructor(props: SharingComponentProps) {
    super(props);
    this.copyInput = React.createRef();
    this.copyInputButton = React.createRef();
  }

  state = {
    showModal: false
  };

  share = async () => {
    const { message, url, onDidInteract } = this.props;

    onDidInteract && onDidInteract();

    const shareData = {
      text: intl.formatMessage({ id: message }),
      url
    };

    let shareAPI: any;
    shareAPI = window.navigator;

    if (shareAPI.share && shareAPI.canShare && shareAPI.canShare(shareData)) {
      try {
        await shareAPI.share(shareData);
      } catch (error: any) {
        // Nothing to do
      }
      return;
    }

    this.setState({ showModal: true });
  };

  select = () => {
    const currentRef = this.copyInput.current || this.copyInputButton.current;
    if (currentRef) {
      // @ts-ignore
      const input = currentRef._textAreaRef;
      if (input) {
        input.style.display = 'block';
        input.select();
        input.setSelectionRange(0, 99999); // for mobile

        document.execCommand('copy');

        // unselect again
        input.setSelectionRange(0, 0);
        input.blur();
        input.style.display = 'none';

        this.showFlashMessage();
      }
    }
  };

  selectOnlyLink = () => {
    const currentRef = this.copyInput.current || this.copyInputButton.current;
    if (currentRef) {
      // @ts-ignore
      const input = currentRef._inputRef;
      if (input) {
        input.select();
        input.setSelectionRange(0, 99999); // for mobile

        document.execCommand('copy');

        // unselect again
        input.setSelectionRange(0, 0);
        input.blur();

        this.showFlashMessage();
      }
    }
  };

  connectToWhatsApp = () => {
    const { message, url } = this.props;
    this.setState({ showModal: false });

    const link =
      // 'https://wa.me/?text=' +  // wtf? in Chrome this url only works in incognito mode!?
      'https://api.whatsapp.com/send?phone=&text=' +
      encodeURIComponent(intl.formatMessage({ id: message }, { link: url }));

    window.open(link);
  };

  showFlashMessage = () => {
    const { applicationStore, onDidInteract } = this.props;

    onDidInteract && onDidInteract();

    applicationStore!.setFlashMessage(
      intl.formatMessage({ id: 'Link copied' })
    );
  };

  renderModal = () => {
    const { modalHeader } = this.props;
    const options: ConfirmOption[] = [
      {
        text: intl.formatMessage({ id: 'invite whatsapp' }),
        color: 'WHATSAPP',
        action: () => this.connectToWhatsApp()
      },
      {
        text: intl.formatMessage({ id: 'Link copy' }),
        color: 'SECONDARY_DARK',
        action: () => {
          this.setState({ showModal: false });
          this.selectOnlyLink();
        }
      }
    ];

    return (
      <ConfirmBoxMultiComponent
        header={<FormattedMessage id={modalHeader} />}
        abortText={<FormattedMessage id="Cancel" />}
        onAbort={() => {
          this.setState({ showModal: false });
        }}
        options={options}
        abortColor="RED_DARK"
      />
    );
  };

  render() {
    const { url, label, message, buttonOutside, buttonColor } = this.props;
    const { showModal } = this.state;

    if (!url) {
      return null;
    }

    return (
      <ItemStackBlock gap="XS">
        <ItemStackBlock gap="XXS">
          <Paragraph>
            <FormattedMessage id={label} />
          </Paragraph>

          {buttonOutside ? (
            <CopyTextInput
              name="invite-link"
              value={url}
              message={intl.formatMessage({ id: message })}
              ref={this.copyInput}
              showFlashMessage={() => this.showFlashMessage()}
            />
          ) : (
            <CopyTextInput
              name="invite-link"
              value={url}
              message={intl.formatMessage({ id: message })}
              buttonInsideClick={() => this.share()}
              buttonInside={true}
              ref={this.copyInputButton}
              showFlashMessage={() => this.showFlashMessage()}
            />
          )}
        </ItemStackBlock>

        {buttonOutside && (
          <ButtonBlock background={buttonColor} onClick={() => this.share()}>
            <FormattedMessage id="Link share" />
          </ButtonBlock>
        )}

        {showModal && this.renderModal()}
      </ItemStackBlock>
    );
  }
}

export default SharingComponent;
