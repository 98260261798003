import React, { ReactNode } from 'react';

import TextElement from 'components/TextElement/TextElement';
import COLORS from 'utils/constants/colors';
import FONT_SIZES from 'utils/constants/fontSizes';
import FONT_WEIGHTS from 'utils/constants/fontWeights';

const Paragraph = ({
  size,
  color,
  weight,
  children,
  noBreak,
  textAlign,
  textDecoration,
}: {
  size?: keyof typeof FONT_SIZES;
  color?: keyof typeof COLORS;
  weight?: keyof typeof FONT_WEIGHTS;
  children: ReactNode;
  noBreak?: boolean;
  textAlign?: "center" | "right" | "left";
  textDecoration?: "underline"
}) => {
  size = size ? size : "M";
  color = color ? color : "TEXT_DARK";
  weight = weight ? weight : "NORMAL";
  return (
    <TextElement size={size} color={color} weight={weight} noBreak={noBreak} textAlign={textAlign} textDecoration={textDecoration} as="p">
      {children}
    </TextElement>
  )
}

export default Paragraph;
