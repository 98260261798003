import styled from 'styled-components';
import SPACES from 'utils/constants/spaces';

const VCenter = styled.div<{ gap?: keyof typeof SPACES }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-top: ${(props) => (props.gap ? SPACES[props.gap] : SPACES.S)};
  }
`;

export default VCenter;
