const env = process.env.NUGGIT_ENVIRONMENT || 'development';

export const NUGGIT_DEV = env === 'development' ? true : false;
export const NUGGIT_STAGING = env === 'staging' ? true : false;

export default {
  apiUrl: process.env.NUGGIT_API_URL || '/api/v1',
  apiCorsPolicy: process.env.NUGGIT_API_CORS || 'include', // include|same-origin - see https://mzl.la/2JIauRr

  env: {
    environment: env,
    development: NUGGIT_DEV,
    staging: NUGGIT_STAGING,
    production: env === 'production' ? true : false,
    devOrStaging: NUGGIT_DEV || NUGGIT_STAGING ? true : false
  },

  maxSchools: 50,
  years: 4,
  lastMonthForCurrentYear: 7, // 1 = jan .. 12 = dec

  // Percent of student profiles necessary to choose a delivery date
  deliveryDateEnabled: true,
  deliveryDatePercentage: 30,

  passwordMinLength: 6,

  websiteUrl: 'https://www.nuggit.de/',
  tosUrl: '/documents/Nutzungsbedingungen-nuggit.pdf',
  agbUrl: 'https://nuggit.de/agb',
  privacyUrl: 'https://www.nuggit.de/app/datenschutz',
  imprintUrl: 'https://www.nuggit.de/app/impressum',
  whatsappSupport: '4915901954136', // format: 49170123456 (no +, 00 or spaces!)
  whatsappSupportError: '4915901954136',
  whatsappMessage: 'Hallo Manuel 👋 Ich hab ne Frage:',
  whatsappMessageSignUp: 'Hi, ich hab bei der Anmeldung die falsche Nummer angegeben glaube ich. Könnt ihr das bitte umstellen?',
  callSupportNumber: '+4915901954136',

  signupRefName: 'signupRef',
  affiliateTokenName: 'affiliateToken',
  affiliateAmount: 50,
  minimumAffiliateAmount: 50,

  iban: 'DE92 1007 0124 0161 3199 00',
  bic: 'DEUTDEDB101',

  layout: {
    baseUrl: '/layouts',
    textileBaseUrl: '/layouts/textiles',
    defaultFonts: [
      ['Roboto', [400, 500, 700]],
      ['Bebas Neue', [400]],
      ['Noto Color Emoji', [400]],
    ],
    defaultFont: 'Roboto',
    defaultWeight: 400,
    defaultSize: '12px',
    defaultLineHeight: '1.45em',
    defaultColor: '#000000'
  },

  // Days the recommend editorial deadline is before the order deadline
  editorialDeadlineLeadDays: 14,

  recaptchaSiteKey: '6LeeoQsaAAAAAKlecxKdyQgk6sWudpXZyWRLwUJn'
};
