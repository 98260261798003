import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import ButtonBlock from 'blocks/ButtonBlock';
import FooterWrapper from 'blocks/FooterBlock/FooterWrapper';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import PasswordInputComponent from 'components/Inputs/PasswordInputComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import SuccessContainerComponent from 'components/SuccessContainerComponent';
import Center from 'elements/Center';
import Headline from 'elements/Headline';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ROUTE_HOME, ROUTE_LOGIN_PAGE } from 'utils/constants/routes';
import { HistoryProps, paramFromMatch } from 'utils/history';
import useForm, { FormType, handleFormError } from 'utils/hooks/useForm';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import PageHeader from 'components/PageHeader/PageHeader';

interface PasswortResetScreenProps {
  applicationStore: ApplicationStoreType;
  form: FormType;
}

@inject('applicationStore')
@observer
class PasswortResetScreen extends React.Component<
PasswortResetScreenProps & HistoryProps
> {
  getTokenOrRedirect() {
    const token = paramFromMatch(this.props.match, 'token');
    if (!token) {
      this.props.history.replace(ROUTE_LOGIN_PAGE);
    }

    return token;
  }

  componentDidMount() {
    const token = this.getTokenOrRedirect();
    if (token) {
      this.props.applicationStore.checkPasswordResetToken(token);
    }
  }

  async handleSubmit() {
    const { applicationStore, form } = this.props;

    const token = this.getTokenOrRedirect();
    if (!token) {
      return;
    }

    form.resetErrors();

    try {
      await applicationStore.resetPassword(token, form.values.password || '');
    } catch (error: any) {
      handleFormError(form, error);
    }
  }

  renderPage(content: any) {
    return (
      <FooterWrapper>
        <SignupHeaderComponent logoRoute={ROUTE_HOME} />

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </FooterWrapper>
    );
  }

  renderSuccess() {
    return this.renderPage(
      <SuccessContainerComponent>
        <Headline.Large>
          <FormattedMessage id="password saved" />
        </Headline.Large>

        <ItemStackBlock gap="S">
          <Paragraph>
            <FormattedMessage id="password reset done" />
          </Paragraph>

          <Paragraph>
            <Link to={ROUTE_LOGIN_PAGE}>
              <FormattedMessage id="Go to login" />
            </Link>
          </Paragraph>
        </ItemStackBlock>
      </SuccessContainerComponent>
    );
  }

  renderError() {
    const {
      applicationStore: { authState }
    } = this.props;

    if (authState && authState.error === 'token_invalid') {
      return this.renderPage(
        <>
          <Headline.Large>
            <FormattedMessage id="password reset header" />
          </Headline.Large>

          <ItemStackBlock gap="S">
            <Paragraph>
              <FormattedMessage id="reset token invalid" />
            </Paragraph>

            <Center>
              <Paragraph>
                <Link to={ROUTE_LOGIN_PAGE}>
                  <FormattedMessage id="Go to login" />
                </Link>
              </Paragraph>
            </Center>
          </ItemStackBlock>
        </>
      );
    }

    return this.renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="password reset header" />
        </Headline.Large>

        <GenericErrorComponent />
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderForm() {
    const { applicationStore, form } = this.props;

    return this.renderPage(
      <>
        <PageHeader headline={<FormattedMessage id="password reset header" />} text={<FormattedMessage id="password reset text" />} />

        {applicationStore.isPasswordResetError && <GenericErrorComponent />}

        <PasswordInputComponent
          name="password"
          label={<FormattedMessage id="New password" />}
          {...form.bindInput('password')}
          autoFocus={true}
          noHotjar={true}
        />

        <ButtonBlock
          background="PRIMARY"
          onClick={() => this.handleSubmit()}
        >
          <FormattedMessage id="Next" />
        </ButtonBlock>
      </>
    );
  }

  render() {
    const { applicationStore } = this.props;

    if (applicationStore.isAuthenticating) {
      return this.renderLoading();
    }

    if (
      applicationStore.isAuthenticationError &&
      !applicationStore.isPasswordResetError
    ) {
      return this.renderError();
    }

    if (applicationStore.isPasswordReset) {
      return this.renderSuccess();
    }

    return this.renderForm();
  }
}

export default (props: any) => {
  const form = useForm();
  return <PasswortResetScreen {...props} form={form} />;
};
