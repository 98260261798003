import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundRoute } from 'screens/NotFoundScreen';
import {
  ROUTE_SETTINGS, ROUTE_SET_GROUP
} from 'utils/constants/routes';

import DeleteStudentScreen from './screens/AccountScreen/DeleteStudentScreen';
import ManageStudentsScreen from './screens/AccountScreen/ManageStudentsScreen';
import MyAccountScreen from './screens/AccountScreen/MyAccountScreen';
import MyGroupScreen from './screens/AccountScreen/MyGroupScreen';
import MyNameScreen from './screens/AccountScreen/MyNameScreen';
import StudentDetailConfirmNumberScreen from './screens/AccountScreen/StudentDetailConfirmNumberScreen';
import StudentDetailGroupScreen from './screens/AccountScreen/StudentDetailGroupScreen';
import StudentDetailNameScreen from './screens/AccountScreen/StudentDetailNameScreen';
import StudentDetailNumberScreen from './screens/AccountScreen/StudentDetailNumberScreen';
import StudentDetailScreen from './screens/AccountScreen/StudentDetailScreen';
import StudentPrintScreen from './screens/AccountScreen/StudentPrintScreen';
import SettingsFinalizeGroupSelection from './screens/FinalizeScreen/SettingsFinalizeGroupSelection';
import ActivateGroupsScreen from './screens/GroupsScreen/ActivateGroupsScreen';
import GroupDetailScreen from './screens/GroupsScreen/GroupDetailScreen';
import GroupsScreen from './screens/GroupsScreen/GroupsScreen';
import GroupUsedChaptersScreen from './screens/GroupsScreen/GroupUsedChaptersScreen';
import StudentsWithoutGroupScreen from './screens/StudentsWithoutGroupScreen';

// TODO: Refator settings, use reusable Components for account and organizer settings views, clean up studentDetailScreen
const SettingsRouter = () => (
  <Switch>
    {/* <Route
      path={ROUTE_SETTINGS + '/account/number'}
      component={MyNumberScreen}
    />

    <Route
      path={ROUTE_SETTINGS + '/account/confirm_number'}
      component={MyNumberConfirmScreen}
    /> */}
    <Route path={ROUTE_SETTINGS + '/account/name'} component={MyNameScreen} />
    <Route path={ROUTE_SETTINGS + '/account/group'} component={MyGroupScreen} />
    {/* <Route
      path={ROUTE_SETTINGS + '/account/password'}
      component={ChangePasswordScreen}
    /> */}
    <Route path={ROUTE_SETTINGS + '/account'} component={MyAccountScreen} />

    <Route
      path={ROUTE_SETTINGS + '/students/add'}
      component={StudentDetailScreen}
    />
    <Route path={ROUTE_SETTINGS + '/students/:id/delete_student'} component={DeleteStudentScreen} />

    <Route
      path={ROUTE_SETTINGS + '/students/:id/name'}
      component={StudentDetailNameScreen}
    />
    <Route
      path={ROUTE_SETTINGS + '/students/:id/number'}
      component={StudentDetailNumberScreen}
    />
    <Route
      path={ROUTE_SETTINGS + '/students/:id/confirm_number'}
      component={StudentDetailConfirmNumberScreen}
    />
    <Route
      path={ROUTE_SETTINGS + '/students/:id/group'}
      component={StudentDetailGroupScreen}
    />
    <Route
      path={ROUTE_SETTINGS + '/students/:id/print'}
      component={StudentPrintScreen}
    />
    <Route
      path={ROUTE_SETTINGS + '/students/:id'}
      component={StudentDetailScreen}
    />
    <Route
      path={ROUTE_SETTINGS + '/students'}
      component={ManageStudentsScreen}
    />

    <Route
      path={ROUTE_SETTINGS + '/groups'}
      exact={true}
      component={GroupsScreen}
    />
    <Route
      path={ROUTE_SETTINGS + '/groups/activate'}
      component={ActivateGroupsScreen}
    />
    <Route path={ROUTE_SETTINGS + '/groups/:id/chapters'} component={GroupUsedChaptersScreen} />
    <Route
      path={ROUTE_SETTINGS + '/groups/:id'}
      component={GroupDetailScreen}
    />

    <Route path={ROUTE_SET_GROUP} component={SettingsFinalizeGroupSelection} />

    <Route path={ROUTE_SETTINGS + '/students_without_group'} component={StudentsWithoutGroupScreen} />

    <NotFoundRoute />
  </Switch>
);

export default SettingsRouter;
