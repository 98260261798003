export const ONE_COLUMN_SETTINGS = {
  maxFontSize: 163, // 46 * 3,543 = 163 => 163 upscale to 360dpi
  minFontSize: 163, // 46 * 3,543 = 163 => 163 upscale to 360dpi
  steps: 1,
  width: 3061, // 864 * 3,543 = 3061,1 => 3061 upscale to 360dpi
  baseCaseMaxNames: 24,
};

export const TWO_COLUMN_SETTINGS = {
  maxFontSize: 163, // 46 * 3,543 = 163 => 163 upscale to 360dpi
  minFontSize: 121, // 34 * 3,543 = 120,5 => 121 upscale to 360dpi
  steps: 4,
  width: 1502, // 424 * 3,543 = 1502,2 => 1502 upscale to 360dpi
  base_case_max_names: 64
};

export const THREE_COLUMN_SETTINGS = {
  maxFontSize: 128, // 36 * 3,543 = 127,5 => 128 upscale to 360dpi
  minFontSize: 85, // 24 * 3,543 = 85 => 85 upscale to 360dpi
  steps: 2,
  width: 992, // 280 * 3,543 = 992 => 992 upscale to 360dpi
  base_case_max_names: 134
};

export const FOUR_COLUMN_SETTINGS = {
  maxFontSize: 96, // 27 * 3,543 = 95,7 => 96 upscale to 360dpi
  minFontSize: 74, // 21 * 3,543 = 74,4 => 74 upscale to 360dpi
  steps: 2,
  width: 744, // 210 * 3,543 = 744 => 744 upscale to 360dpi
  base_case_max_names: 203
};

export const FIVE_COLUMN_SETTINGS = {
  maxFontSize: 92, // 26 * 3,543 = 92,1 => 92 upscale to 360dpi
  minFontSize: 7, // 2 * 3,543 = 7 => 7 upscale to 360dpi
  softcapMinFontSize: 71, // 20 * 3,543 = 70,8 => 71 upscale to 360dpi
  steps: 2,
  width: 609, // 172 * 3,543 = 609,4 => 609 upscale to 360dpi
  base_case_max_names: undefined
};

export const getSettingsByColumn = (columnCount: number) => {
  switch (columnCount) {
    case 1:
      return ONE_COLUMN_SETTINGS;
    case 2:
      return TWO_COLUMN_SETTINGS;
    case 3:
      return THREE_COLUMN_SETTINGS;
    case 4:
      return FOUR_COLUMN_SETTINGS;
    case 5:
      return FIVE_COLUMN_SETTINGS;
    default:
      return ONE_COLUMN_SETTINGS;
  }
}