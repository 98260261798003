import React from 'react';

import ButtonBlock from 'blocks/ButtonBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import SignupHeaderComponent from 'components/HeaderComponent/SignupHeaderComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import CopyTextInput from 'components/Inputs/CopyTextInput';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import SuccessContainerComponent from 'components/SuccessContainerComponent';
import config from 'config';
import ContactOnWhatsappButton from 'domain/Buttons/ContactOnWhatsappButton';
import VisitWebsiteButton from 'domain/Buttons/VisitWebsiteButton';
import Bodytext from 'elements/Bodytext';
import Headline from 'elements/Headline';
import {
  inject,
  observer
} from 'mobx-react';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { SetupStoreType } from 'models/SetupStore';
import { FormattedMessage } from 'react-intl';
import { ROUTE_JOIN, ROUTE_SIGNUP } from 'utils/constants/routes';
import { HistoryProps, isPush } from 'utils/history';

interface SignupDoneScreenProps {
  applicationStore: ApplicationStoreType;
  setupStore: SetupStoreType;
  token?: string;
}

@inject('applicationStore', 'setupStore')
@observer
class SignupDoneScreen extends React.Component<
SignupDoneScreenProps & HistoryProps
> {
  componentDidMount() {
    const { setupStore } = this.props;

    if (!setupStore.isInfoComplete) {
      this.redirectToStart();
      return;
    }

    if (isPush(this.props.history)) {
      this.signup();
    }
  }

  redirectToStart() {
    const { setupStore } = this.props;

    if (setupStore.token) {
      this.props.history.replace(ROUTE_JOIN + '/' + setupStore.token);
    } else {
      this.props.history.replace(ROUTE_SIGNUP);
    }
  }

  async signup() {
    const { setupStore } = this.props;

    if (!setupStore.isInfoComplete || !setupStore.mayStartSignup) {
      return;
    }

    try {
      await setupStore.signup();

      // reset auth token sent indicator, just to be sure
      this.props.applicationStore.resetAuthState();
    } catch (error: any) {
      // this is a form error
      const { body } = error;
      if (body) {
        // TODO add ability to handle nested errors to useForm's handleFormError() method?
        const errors = {
          ...body.errors,
          ...body.errors?.profile,
          ...body.errors?.book,
          ...body.errors?.account
        };

        if (errors.mobile_number && errors.mobile_number.length) {
          this.props.history.replace(ROUTE_SIGNUP + '/number', {
            error: errors.mobile_number[0]
          });
          return;
        } else if (errors.password && errors.password.length) {
          this.props.history.replace(ROUTE_SIGNUP + '/password', {
            error: errors.password[0]
          });
          return;
        }

        // TODO handle other errors as well?
        this.redirectToStart();
      }
    }
  }

  resendLink() {
    const {
      setupStore: { account }
    } = this.props;
    if (!account || !account.mobile_number) {
      return;
    }

    // password reset will send verification message for new profiles
    this.props.applicationStore.initiatePasswordReset(account.mobile_number);
  }

  renderPage(content?: any) {
    return (
      <>
        <SignupHeaderComponent />

        <Headline.Large>
          <FormattedMessage id="signup default header" />
        </Headline.Large>

        {content}
      </>
    );
  }

  renderSimplePage(content?: any) {
    return (
      <>
        <SignupHeaderComponent />

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  renderLoading() {
    return this.renderPage(<LoadingOverlayComponent />);
  }

  renderDone() {
    const { setupStore, applicationStore } = this.props;

    return this.renderSimplePage(
      <SuccessContainerComponent>
        <PageHeader headline={<FormattedMessage id="signup done header" />} text={<FormattedMessage id="signup done text" />} />

        {setupStore.account && setupStore.account.mobile_number && (
          <>
            <Paragraph weight="BOLD">
              {setupStore.account.mobile_number}
            </Paragraph>

            {applicationStore.isTokenRequested ? (
              <Paragraph>

                <FormattedMessage id="link resent" />
              </Paragraph>
            ) : (
              <ButtonBlock
                link={true}
                background="WHITE"
                color="PRIMARY"
                onClick={() => this.resendLink()}
              >
                <Paragraph>

                  <FormattedMessage id="Resend link" />
                </Paragraph>
              </ButtonBlock>
            )}
          </>
        )}

        <ItemStackBlock gap="S" marginTop="XL">
          <Paragraph>
            <FormattedMessage id="support wrong number" />
          </Paragraph>

          <Paragraph>
            <ContactOnWhatsappButton
              text={config.whatsappMessageSignUp}
              messageId="Contact support"
            />
          </Paragraph>

          {config.env.devOrStaging && setupStore.hint && (
            <CopyTextInput
              name="login-hint"
              label="Login hint"
              value={setupStore.hint}
            />
          )}
        </ItemStackBlock>
      </SuccessContainerComponent>
    );
  }

  renderTokenInvalid() {
    return this.renderPage(
      <>
        <ItemStackBlock gap="L">
          <Bodytext>
            <FormattedMessage id="signup invalid token text" />
          </Bodytext>

          <ItemStackBlock gap="S">
            <ContactOnWhatsappButton />
            <VisitWebsiteButton />
          </ItemStackBlock>
        </ItemStackBlock>
      </>
    );
  }

  renderError() {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={() => this.signup()} />
    );
  }

  renderProblem() {
    return this.renderPage(<GenericErrorComponent />);
  }

  render() {
    const { setupStore, applicationStore } = this.props;

    if (applicationStore.isAuthenticating) {
      return this.renderLoading();
    }

    const signupState = setupStore.signupLoadingState;

    if (/*signupState === 'done_new_school' ||*/ signupState === 'done') {
      return this.renderDone();
    }

    if (!setupStore.isInfoComplete) {
      // this should not happen, but we never know
      return this.renderProblem();
    }

    if (signupState === 'error') {
      return this.renderError();
    }

    if (signupState === 'token_invalid') {
      return this.renderTokenInvalid();
    }

    return this.renderLoading();
  }
}

export default SignupDoneScreen;
