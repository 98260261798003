import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import ImageBlock from 'blocks/ImageBlock';
import IconComponent from 'components/IconComponent';
import { FactsheetChapterSettingsModelType } from 'models/chapter-settings/FactsheetChapterSettingsModel';
import { PhotoModelType, PhotoType } from 'models/PhotoModel';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';

interface FactsheetPhotoItemProps {
  type: PhotoType;
  settings: FactsheetChapterSettingsModelType;
  photo?: PhotoModelType;
}

const FactsheetPhotoItem = ({
  type,
  settings,
  photo
}: FactsheetPhotoItemProps) => {
  const isPhotoPresent = !!photo;
  const isPreviewPresent =
    photo && photo.photo && photo.photo.preview ? true : false;

  let headline: ReactNode;
  let slotNumber: ReactNode;
  switch (type) {
    case 'creative_page':
      headline = <FormattedMessage id="Creative page" />;
      break;

    case 'slot1':
      headline = settings.slot1_photo_name || <FormattedMessage id="Photo 1" />;
      slotNumber = 1;
      break;

    case 'slot2':
      headline = settings.slot2_photo_name || <FormattedMessage id="Photo 2" />;
      slotNumber = 2;
      break;

    case 'slot3':
      headline = settings.slot3_photo_name || <FormattedMessage id="Photo 3" />;
      slotNumber = 3;
      break;

    default:
  }

  return (
    <>
      <EveryCard>
        <EveryCardPadding>
          <ImageBlock
            background="GRAY900"
            width={3.0625}
            height={3.0625}
            cover={true}
          >
            {!isPhotoPresent ? (
              <Paragraph size="L" color="GRAY800">
                {slotNumber}
              </Paragraph>
            ) : isPreviewPresent ? (
              <img src={photo!.photo!.preview} alt="" />
            ) : (
              <IconComponent icon="HOURGLASS" fill="PRIMARY" size={2} />
            )}
          </ImageBlock>
          <EveryCardBody>
            <EveryCardHeadline>{headline}</EveryCardHeadline>
            <TextElement  color="TEXT_DARK">
              {!isPhotoPresent ? (
                <>
                  <IconComponent icon="WARNING_CIRCLE" fill="WARNING" />
                  <FormattedMessage id="Not yet placed" />
                </>
              ) : isPreviewPresent ? (
                <>
                  <IconComponent icon="TICK_CIRCLE" fill="GREEN" />
                  <FormattedMessage id="Placed" />
                </>
              ) : (
                <FormattedMessage id="Photo is being processed" />
              )}
            </TextElement>
          </EveryCardBody>
        </EveryCardPadding>
      </EveryCard>
    </>
  );
};

export default FactsheetPhotoItem;
