import { get, patch, uri } from "api/BaseApi";

export interface TextileDeliveryDate {
  id: number;
  order_deadline: Date;
  payment_deadline: Date;
  delivery_date: Date;
  available_amount: number;
  payment_possible: boolean
}

export const getTextileDeliveryDates = (textileOrderId?: number): Promise<TextileDeliveryDate[]> => {
  return get(`/textile_delivery_dates?textile_order_id=${textileOrderId}`);
}

export const chooseTextileDeliveryDate = (
  id: number,
  textileOrderId?: number
): Promise<TextileDeliveryDate> => {
  return patch(`/textile_delivery_dates/${uri(id)}/choose`, {
    textile_order_id: textileOrderId
  });
}

export const changeTextileDeliveryDateAfterCheckout =(
  id: number,
  textileOrderId?: number
): Promise<TextileDeliveryDate> => {
  return patch(`/textile_delivery_dates/${uri(id)}/change_after_checkout`, {
    textile_order_id: textileOrderId
  });
}