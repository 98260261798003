import React from 'react';

import style from 'styled-components';
import COLORS from 'utils/constants/colors';
import SPACES from 'utils/constants/spaces';

interface DividerProps {
  stackBlockGap?: keyof typeof SPACES;
  useStackBlockGap?: boolean;
  color?: keyof typeof COLORS;
}

const DividerLine = style.hr<DividerProps>`
  margin: ${(props) => props.useStackBlockGap ? `0` : `calc(${SPACES.XL} - ${props.stackBlockGap ? SPACES[props.stackBlockGap] : SPACES.L}) 0`};
  border: none;
  border-top: 1px solid ${(props) => props.color ? props.color : COLORS.GRAY900};
`;

const Divider = ({
  stackBlockGap,
  useStackBlockGap,
  color
}: {
  stackBlockGap?: keyof typeof SPACES;
  useStackBlockGap?: boolean;
  color?: keyof typeof COLORS;
}) => <DividerLine useStackBlockGap={useStackBlockGap} stackBlockGap={stackBlockGap} color={color}/>;

export default Divider;
