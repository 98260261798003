/* eslint-disable react/style-prop-object */
import { TextileDiscount } from 'api/textile_deals/fetchTextileDiscounts';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import { IconListElement } from 'blocks/IconListBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import NonGapStackBlock from 'components/NonGapStackBlock/NonGapStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const AmountDiscountItem = ({
  discounts,
  preordersAmount,
  formAmount
}: {
  discounts: TextileDiscount[];
  preordersAmount: number;
  formAmount: number;
}) => {
  const defaultDiscount = {
    checked: false,
    name: 'Mengen-Rabatt',
    value: 0,
    identifier: 'nuggit_volume_0'
  };
  let activeDiscount =
    discounts.find((discount) => discount.checked) || defaultDiscount;

  if (formAmount < 65) {
    activeDiscount = defaultDiscount;
  }

  if (formAmount >= 65 && formAmount < 90) {
    activeDiscount = discounts[0];
  }

  if (formAmount >= 90) {
    activeDiscount = discounts[discounts.length - 1];
  }

  return (
    <>
      <IconListElement
        icon={activeDiscount.checked ? 'TICK_CIRCLE' : 'DOT'}
        fill={activeDiscount.checked ? 'GREEN' : 'GRAY950'}
      >
        <ItemStackBlock gap="XS">
          <HorizontalStackBlock justified={true}>
            <Paragraph>{activeDiscount.name}</Paragraph>
            <Paragraph noBreak={true}>
              <FormattedNumber
                value={activeDiscount.value}
                style="currency"
                currency="EUR"
              />
              <FormattedMessage id="per piece" />
            </Paragraph>
          </HorizontalStackBlock>
          <ItemStackBlock gap="XXS">
            <NonGapStackBlock>
              <TextElement size="S">
                <FormattedMessage
                  id="current form preorder amount"
                  values={{ amount: formAmount }}
                />
              </TextElement>

              {discounts.map((discount) => {
                return (
                  <TextElement>
                    <FormattedMessage
                      id="textile preorder amount discount"
                      key={discount.identifier}
                      values={{
                        amount: discount.amount,
                        discounts: (
                          <FormattedNumber
                            value={discount.value}
                            style="currency"
                            currency="EUR"
                          />
                        )
                      }}
                    />
                  </TextElement>
                );
              })}
            </NonGapStackBlock>
          </ItemStackBlock>
        </ItemStackBlock>
      </IconListElement>
    </>
  );
};

export default AmountDiscountItem;
