/* eslint-disable react/style-prop-object */
import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import {
  MinimalSizeAmount,
  MinimalVariantAmount
} from 'api/textile_deals/fetchOverviewPreorder';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import { TextilePriceItem } from 'api/textile_deals/fetchTextilePrices';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import HorizontalStackBlock from 'blocks/HorizontalStackBlock';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import ProgressbarComponent from 'components/ProgressbarComponent';
import TextElement from 'components/TextElement/TextElement';
import BackClickLink from 'domain/Links/BackClickLink';
import Headline from 'elements/Headline';
import NakedLink from 'elements/NakedLink';
import { intl } from 'i18n';
import { useOrderOverviewQuery } from 'queries/textile_deals/useOrderOverviewQueries';
import { useTextileOrderForPreorderQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { MiniPreview } from 'screens/textile_design_settings/screens/colors/MiniPreview';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import isTextileLocked from 'utils/isTextileLocked';
import { usedDiscountsValue } from 'utils/usedDiscountsValue';

const TextileOrderOverviewScreen: React.FC<HistoryProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const queryClient = useQueryClient();
  const textileAppState = queryClient.getQueryData<TextileAppState>([
    'textile_app_state',
    textileOrderId
  ]);

  const orderOverview = useOrderOverviewQuery(textileOrderId);
  const textileOrder = useTextileOrderForPreorderQuery(textileOrderId);

  useEffect(() => {
    if (
      textileAppState?.textile_profile.is_student ||
      textileAppState?.textile_profile.is_applicant
    ) {
      props.history.replace(textileOrderRoute(props.match, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textileAppState?.textile_profile]);

  const renderPage = (content: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackClickLink onClick={() => props.history.goBack()} />
        </TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (orderOverview.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => orderOverview.refetch()} />
    );
  }

  if (textileOrder.isError) {
    return renderPage(
      <GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />
    );
  }

  if (
    !textileAppState ||
    orderOverview.isLoading ||
    !orderOverview.data ||
    textileOrder.isLoading ||
    !textileOrder.data
  ) {
    return renderPage(<LoadingIndicatorComponent />);
  }

  const renderOverview = () => {
    const preorderOverview = orderOverview.data;

    const profilesCount = preorderOverview.active_students || 0;
    const submittedProfilesCount =
      preorderOverview.submitted_preorders_students || 0;

    if (!profilesCount) {
      return null;
    }

    const textilePrice = preorderOverview.price;
    const order = preorderOverview.orders;
    const preordersAmount = preorderOverview.preorders
      ? preorderOverview.preorders.length
      : 0;
    // const order = preorderOverview.allCompleteOrder;
    // const preordersAmount = preorderOverview.allPreorders.length;

    const day = intl.formatDate(textileOrder.data.preorder_deadline, {
      day: '2-digit'
    });
    const month = intl.formatDate(textileOrder.data.preorder_deadline, {
      month: '2-digit'
    });
    const date = day + '/' + month;

    return renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="textile order overview" />}
          text={<FormattedMessage id="textile order overview total" />}
        />

        {!isTextileLocked(
          textileAppState.textile_order.order_state,
          'atLeastOrderPlaced'
        ) && (
          <ItemStackBlock gap="XS">
            {textileAppState.textile_order.preorder_open ? (
              <Paragraph>
                <IconComponent icon="CLOCK_CIRCLE" fill="BLACK" />
                {textileOrder.data.preorder_deadline ? (
                  <>
                    <FormattedMessage id="preorder open til" /> {date}
                  </>
                ) : (
                  <FormattedMessage id="preorder open time" />
                )}
              </Paragraph>
            ) : textileAppState.textile_order.order_state ===
              'preorder_paused' ? (
              <Paragraph>
                <IconComponent icon="PAUSE" fill="BLACK" />
                <FormattedMessage id="preorder state paused" />
              </Paragraph>
            ) : (
              <Paragraph>
                <IconComponent icon="LOCK" fill="BLACK" />
                <FormattedMessage id="editing state locked" />
              </Paragraph>
            )}
            <ProgressbarComponent
              isStatic={true}
              progressStatus={(submittedProfilesCount / profilesCount) * 100}
              color="PRIMARY"
            />
            <TextElement>
              <FormattedMessage
                id="textile preorder students count"
                values={{
                  count: submittedProfilesCount,
                  number: profilesCount
                }}
              />
            </TextElement>
          </ItemStackBlock>
        )}

        {preordersAmount !== 0 && (
          <EveryCard disabled={true}>
            <EveryCardPadding>
              <EveryCardHeadline>
                <FormattedMessage id="Total amount" />
              </EveryCardHeadline>
              <EveryCardBody right={true}>
                <TextElement color="TEXT_DARK" size="L" weight="BOLD">
                  {preordersAmount}
                </TextElement>
              </EveryCardBody>
            </EveryCardPadding>
          </EveryCard>
        )}

        {order &&
          order.map((itm) => {
            return (
              <ItemStackBlock gap="S" key={itm.textile_name}>
                <Paragraph color="TEXT_LIGHT" textAlign="right" size="XS">
                  <FormattedMessage id="Pieces" />
                </Paragraph>
                <HorizontalStackBlock justified={true}>
                  <Paragraph size="L" weight="BOLD">
                    {itm.textile_name}
                  </Paragraph>
                  <Paragraph size="M" weight="BOLD">
                    {itm.textile_amount}
                  </Paragraph>
                </HorizontalStackBlock>
                <Divider color="BLACK" useStackBlockGap={true} />

                {itm.textile_variants.map((variant: MinimalVariantAmount) => {
                  return (
                    <ItemStackBlock gap="S" key={variant.textile_color?.id}>
                      {variant.textile_color &&
                        variant.textile_color.color_hex && (
                          <HorizontalStackBlock justified={true}>
                            <HorizontalStackBlock gap="S">
                              <MiniPreview
                                colorCode={variant.textile_color.color_hex}
                              />
                              <Paragraph size="M" weight="BOLD">
                                {variant.textile_color?.display_name}
                              </Paragraph>
                            </HorizontalStackBlock>
                            <Paragraph size="M" weight="BOLD">
                              {variant.variant_amount}
                            </Paragraph>
                          </HorizontalStackBlock>
                        )}
                      <Divider useStackBlockGap={true} />

                      {variant.sizes.map((size: MinimalSizeAmount) => {
                        return (
                          <ItemStackBlock gap="XXS" key={size.size}>
                            <HorizontalStackBlock justified={true}>
                              <HorizontalStackBlock gap="S">
                                <Paragraph color="TEXT_LIGHT">
                                  {size.size}
                                </Paragraph>
                              </HorizontalStackBlock>
                              <Paragraph color="TEXT_LIGHT">
                                {size.size_amount}
                              </Paragraph>
                            </HorizontalStackBlock>
                            <Divider useStackBlockGap={true} />
                          </ItemStackBlock>
                        );
                      })}
                    </ItemStackBlock>
                  );
                })}
              </ItemStackBlock>
            );
          })}

        {isTextileLocked(
          textileAppState.textile_order.order_state,
          'atLeastOrderPlaced'
        ) &&
          textilePrice && (
            <>
              <Headline.Medium>
                <FormattedMessage id="Textile discount price" />
              </Headline.Medium>
              <ItemStackBlock gap="XXS">
                {textilePrice.price.items.map((itm: TextilePriceItem) => {
                  return (
                    <div key={itm.name}>
                      <ItemStackBlock gap="XXS">
                        <HorizontalStackBlock justified={true}>
                          <Paragraph size="M" weight="BOLD">
                            {itm.name}
                          </Paragraph>

                          <Paragraph size="M" weight="BOLD">
                            <FormattedNumber
                              value={itm.end_price}
                              style="currency"
                              currency="EUR"
                            />
                          </Paragraph>
                        </HorizontalStackBlock>
                        <HorizontalStackBlock justified={true}>
                          <Paragraph size="S" color="TEXT_LIGHT">
                            {itm.amount}{' '}
                            <FormattedMessage id="textile pieces" />
                          </Paragraph>

                          <Paragraph size="S" color="TEXT_LIGHT">
                            <FormattedMessage
                              id="textile discount amount info"
                              values={{
                                amount: (
                                  <FormattedNumber
                                    value={usedDiscountsValue(
                                      textilePrice.discounts
                                    )}
                                    style="currency"
                                    currency="EUR"
                                  />
                                )
                              }}
                            />
                          </Paragraph>
                        </HorizontalStackBlock>
                      </ItemStackBlock>

                      <Divider />
                    </div>
                  );
                })}
                <ItemStackBlock gap="S" marginTop="M">
                  <ItemStackBlock gap="XXS">
                    <HorizontalStackBlock
                      gap="XXS"
                      centered={true}
                      noMarginLeft={true}
                    >
                      <TextElement size="L" weight="BOLD" color="BLACK">
                        <FormattedMessage id="Gross price possible" />
                      </TextElement>
                      <NakedLink
                        to={textileOrderRoute(props.match, '/discounts')}
                      >
                        <IconComponent icon="INFO_CIRCLE" />
                      </NakedLink>
                    </HorizontalStackBlock>
                    <Paragraph color="TEXT_LIGHT" size="XS">
                      <FormattedMessage id="textile include vat and used discounts" />
                    </Paragraph>
                  </ItemStackBlock>

                  <Paragraph size="HEADLINE_L" weight="EXTRA_BOLD">
                    <FormattedNumber
                      value={textilePrice.price.end_price}
                      style="currency"
                      currency="EUR"
                    />
                  </Paragraph>
                </ItemStackBlock>
              </ItemStackBlock>
            </>
          )}
      </>
    );
  };

  return renderOverview();
};

export default TextileOrderOverviewScreen;
