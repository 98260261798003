import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import TopActionBlock from 'blocks/TopActionBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import TextElement from 'components/TextElement/TextElement';import { ApplicationStoreType } from 'models/ApplicationStore';
import { ChaptersStoreType } from 'models/ChaptersStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { FactsheetsStoreType } from 'screens/factsheets/FactsheetsStore';
import StudentsWithoutGroup from 'screens/settings/screens/StudentsWithoutGroup';
import { assert } from 'utils/assert';
import { ROUTE_CONTENT, ROUTE_YEARBOOKS } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';
import chapterIdFromMatch from 'utils/history/chapter-id-from-match';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';

interface Props {
  applicationStore: ApplicationStoreType;
  chaptersStore: ChaptersStoreType;
  profilesStore: ProfilesStoreType;
  factsheetsStore: FactsheetsStoreType;
}

@inject('applicationStore', 'chaptersStore', 'profilesStore', 'factsheetsStore')
@observer
class SplitYearbookChapterScreen extends React.Component<Props & HistoryProps> {
  componentDidMount() {
    const {
      applicationStore: { isOrganizer, book }
    } = this.props;
    assert(book);
    const groupsApplied = book.groups_applied;

    if (!(isOrganizer && groupsApplied)) {
      this.props.history.push(ROUTE_CONTENT);
    }

    // Fetch in parallel, do not await
    this.loadChapter();
    this.loadStudents();
  }

  loadStudents() {
    this.props.profilesStore.getStudents();
  }

  loadChapter() {
    const chapterId = this.getChapterId();
    assert(chapterId);
    return this.props.chaptersStore.getChapter(chapterId);
  }

  getChapterId() {
    return chapterIdFromMatch(this.props.match);
  }

  getChapter() {
    return this.props.chaptersStore.item;
  }

  async split() {
    const id = this.getChapterId();
    assert(id);
    await this.props.factsheetsStore.splitYearbookChapter(id);
    this.props.applicationStore.setDefaultFlashMessage('saved');
    this.props.history.push(ROUTE_CONTENT);
  }

  backToChapter() {
    this.props.history.push(this.getChapterRoute());
  }

  getChapterRoute() {
    const id = this.getChapterId();
    assert(id);
    return ROUTE_YEARBOOKS + '/' + id;
  }

  renderPage(content: React.ReactChild) {
    return (
      <>
        <TopActionBlock>
          <Link to={this.getChapterRoute()}>
            <TextElement  color="GRAY10">
              <IconComponent icon="ARROW_LEFT" fill="CURRENT_COLOR" />{' '}
              <FormattedMessage id="Back" />
            </TextElement>
          </Link>
        </TopActionBlock>

        <PageStackBlock>
          {content}
        </PageStackBlock>
      </>
    );
  }

  renderError(onRetryClick: () => void) {
    return this.renderPage(
      <GenericErrorComponent onRetryClick={onRetryClick} />
    );
  }

  render() {
    const {
      chaptersStore: { itemLoadingState: chapterLoadingState },
      factsheetsStore: { splitChapterLoadingState },
      profilesStore: { isStudentsLoading, isStudentsError }
    } = this.props;

    // Loading states
    if (
      chapterLoadingState === 'loading' ||
      isStudentsLoading ||
      splitChapterLoadingState === 'loading'
    ) {
      return <LoadingOverlayComponent />;
    }

    // Error states
    if (chapterLoadingState === 'error') {
      return this.renderError(() => this.loadChapter());
    }
    if (isStudentsError) {
      return this.renderError(() => this.loadStudents());
    }
    if (splitChapterLoadingState === 'error') {
      return this.renderError(() => this.split());
    }

    // Students without groups
    const { studentsWithoutGroup } = this.props.profilesStore;
    if (studentsWithoutGroup.length > 0) {
      // Render student list
      return this.renderPage(
        <StudentsWithoutGroup students={studentsWithoutGroup} yearbook={true} />
      );
    }

    // Render confirm
    return (
      <ConfirmBoxComponent
        header={<FormattedMessage id="chapter split confirm header" />}
        text={<FormattedMessage id="chapter split confirm text" />}
        confirmText={<FormattedMessage id="chapter split button" />}
        abortText={<FormattedMessage id="Cancel" />}
        onConfirm={() => this.split()}
        onAbort={() => this.backToChapter()}
      />
    );
  }
}

export default SplitYearbookChapterScreen;
