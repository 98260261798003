import { inject, observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonBlock from 'blocks/ButtonBlock';
import { IconListBlock, IconListElement } from 'blocks/IconListBlock';
import IconListDivider from 'blocks/IconListBlock/IconListDivider';
import TopActionBlock from 'blocks/TopActionBlock';
import HelpSubject from 'components/HelpButton/HelpSubject';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';
import LoadingOverlayComponent from 'components/LoadingOverlayComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import BackLink from 'domain/Links/BackLink';
import { intl } from 'i18n';
import { ApplicationStoreType } from 'models/ApplicationStore';
import { ProfilesStoreType } from 'models/ProfilesStore';
import { ROUTE_ACCOUNT_DASHBOARD, ROUTE_DASHBOARD } from 'utils/constants/routes';
import { HistoryProps } from 'utils/history';

interface JoinBookScreenProps {
  applicationStore: ApplicationStoreType;
  profilesStore: ProfilesStoreType;
}

@inject('applicationStore', 'profilesStore')
@observer
class JoinBookScreen extends React.Component<
  JoinBookScreenProps & HistoryProps
> {
  state = {
    loadingState: undefined
  };

  componentDidMount() {
    const { applicationStore, history } = this.props;

    if (applicationStore.clientStates?.has('join_book_role_select')) {
      history.replace(ROUTE_ACCOUNT_DASHBOARD);
    }
  }

  private async requestOrganizerRole() {
    const { profilesStore, applicationStore } = this.props;

    const { currentUser } = applicationStore;

    if (!currentUser) {
      return;
    }

    this.setState({
      loadingState: 'loading'
    });

    try {
      if (currentUser.role === 'applicant') {
        await profilesStore!.requestRoleChange('applicant_to_organizer');
      }

      if (currentUser.role === 'student') {
        await profilesStore!.requestRoleChange('student_to_organizer');
      }

      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'role change requested flash' }),
        'success'
      );

      applicationStore.checkAuthenticatedBook(true);

      this.props.history.push(ROUTE_DASHBOARD);
    } catch (error: any) {
      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'role change request error flash' }),
        'error'
      );

      this.setState({
        loadingState: 'error'
      });
    }
  }

  private async requestStudent() {
    const { profilesStore, applicationStore } = this.props;

    const { currentUser } = applicationStore;

    if (!currentUser) {
      return;
    }

    this.setState({
      loadingState: 'loading'
    });

    try {
      await profilesStore!.requestRoleChange('applicant_to_student');

      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'role change requested flash' }),
        'success'
      );

      applicationStore.checkAuthenticatedBook(true);

      this.props.history.push(ROUTE_DASHBOARD);
    } catch (error: any) {
      applicationStore!.setFlashMessage(
        intl.formatMessage({ id: 'role change request error flash' }),
        'error'
      );

      this.setState({
        loadingState: 'error'
      });
    }
  }

  private async stayStudent() {
    const { applicationStore } = this.props;

    const { currentUser } = applicationStore;

    if (!currentUser) {
      return;
    }

    this.setState({
      loadingState: 'loading'
    });

    if (currentUser.role !== 'applicant') {
      try {
        await applicationStore.updateClientState(
          'join_book_role_select',
          'sent'
        );

        this.props.history.push(
          ROUTE_DASHBOARD
        );
      } catch (errors: any) {
        this.setState({
          loadingState: 'error'
        });
      }
    }
  }

  private renderTopAction() {
    return (
      <TopActionBlock>
        <BackLink to={ROUTE_ACCOUNT_DASHBOARD} />
      </TopActionBlock>
    );
  }

  private renderPage(content?: any) {
    return (
      <>
        {this.renderTopAction()}

        <PageStackBlock>{content}</PageStackBlock>

        <HelpSubject hidden={true} />
      </>
    );
  }

  private renderOnboarding() {
    const { applicationStore } = this.props;
    const { currentUser } = applicationStore;

    if (!currentUser) {
      return null;
    }

    return this.renderPage(
      <>
        <PageHeader
          headline={<FormattedMessage id="onboarding organizer header" />}
          text={<FormattedMessage id="onboarding organizer text" />}
        />

        <IconListBlock>
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding organizer text 1" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding organizer text 2" />
          </IconListElement>
          <IconListDivider />
          <IconListElement icon="TICK_CIRCLE" fill="GREEN">
            <FormattedMessage id="onboarding organizer text 3" />
          </IconListElement>
        </IconListBlock>

        <ListStackBlock>
          <ButtonBlock
            background="PRIMARY"
            onClick={() => this.requestOrganizerRole()}
          >
            <FormattedMessage id="Become organizer" />
          </ButtonBlock>
          <ButtonBlock
            background="PRIMARY_LIGHT"
            color="PRIMARY_DARK"
            onClick={() => currentUser.role !== 'applicant' ? this.stayStudent() : this.requestStudent()}
          >
            <FormattedMessage id="Not become organizer" />
          </ButtonBlock>
        </ListStackBlock>
      </>
    );
  }

  render() {
    if (this.state.loadingState === 'loading') {
      return <LoadingOverlayComponent />;
    }

    return this.renderOnboarding();
  }
}

export default JoinBookScreen;
