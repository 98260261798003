import { destroy, get, patch, post, uri } from 'api/BaseApi';
import { SwapResponse } from 'api/NuggitApi';
import { sortPositions } from 'utils/item-sorting';

export interface TextileGroup {
  id: number;
  name: string;
  student_count?: number;
  profile_names: GroupNamesBack[];
  teacher_names: GroupNamesBack[];
}

export interface GroupNamesBack {
  first_name?: string;
  last_name?: string;
}

export const getTextileGroups = (
  textileOrderId: number
): Promise<TextileGroup[]> => {
  return get('/textile_groups/?textile_order_id=' + textileOrderId);
};

export const getTextileGroup = (id: number, textileOrderId: number): Promise<TextileGroup> => {
  return get(
    '/textile_groups/' + uri(id) + '?textile_order_id=' + textileOrderId
  );
}

export const updateTextileGroup = (groupId: number, groupPatch: any, textileOrderId: number): Promise<TextileGroup> => {
  return patch('/textile_groups/' + uri(groupId), {
    textile_group: groupPatch,
    textile_order_id: textileOrderId
  });
}

export const removeTextileGroup = (id: number, textileOrderId: number) => {
  return destroy(
    '/textile_groups/' + uri(id) + '?textile_order_id=' + textileOrderId
  );
}

export const createTextileGroup = (
  textileOrderId: number,
  group_name: string
): Promise<TextileGroup> => {
  return post('/textile_groups', {
    textile_group: { name: group_name },
    textile_order_id: textileOrderId
  });
};

export const setTextileGroupsSorting = (
  textileGroups: TextileGroup[],
  textileOrderId: number
): Promise<SwapResponse> => {
  return post('/textile_groups/sorting', {
    positions: sortPositions(textileGroups),
    textile_order_id: textileOrderId
  });
}
