import { types, Instance } from 'mobx-state-tree';

import booleanOrUndefined from 'utils/store/booleanOrUndefined';
import mandatoryId from 'utils/store/mandatoryId';
import ProfileAsAuthorModel, {
  createProfileAsAuthorModel
} from './ProfileAsAuthorModel';

const AnswerModel = types.model('AnswerModel', {
  id: types.identifierNumber,
  answer: types.maybe(types.string),
  unanswered: types.maybe(types.boolean),
  print: types.maybe(types.boolean),
  profile: types.maybe(ProfileAsAuthorModel),
  author: types.maybe(ProfileAsAuthorModel)
});

export const createAnswerModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  return AnswerModel.create({
    id: mandatoryId(data.id),
    answer: data.answer || undefined,
    unanswered: booleanOrUndefined(data.unanswered),
    print: booleanOrUndefined(data.print),
    profile: createProfileAsAuthorModel(data.profile),
    author: createProfileAsAuthorModel(data.author)
  });
};

export type AnswerModelType = Instance<typeof AnswerModel>;
export default AnswerModel;
