import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import CheckboxBlock from 'blocks/CheckboxBlock';
import { FormType } from 'utils/hooks/useForm';
import ListStackBlock from 'components/ListStackBlock/ListStackBlock';

const Checkboxes = ({
  form,
  options,
  name,
  translate = true
}: {
  form: FormType;
  options: string[];
  name: string;
  translate?: boolean;
}) => (
  <ListStackBlock>
    {options.map((option, index) => (
      <CheckboxBlock key={option} bordered={true}>
        <CheckboxBlock.CheckboxElement
          id={`${name}-${index}`}
          checked={form.values[name].includes(option)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const oldValue: string = form.values[name];
            let oldList: string[] = [];
            if (oldValue !== '') {
              oldList = form.values[name].split(', ');
            }
            if (!Array.isArray(oldList)) {
              throw new Error(`Form value ${name} must be an array`);
            }
            const newList = event.target.checked
              ? [...oldList, option]
              : oldList.filter((d: string) => d !== option);
            form.setField(name, newList.join(', '));
          }}
        />
        <CheckboxBlock.LabelElement htmlFor={`${name}-${index}`}>
          {translate ? (
            <FormattedMessage id={`funnel travel ${name} ${option}`} />
          ) : (
            option
          )}
        </CheckboxBlock.LabelElement>
      </CheckboxBlock>
    ))}
  </ListStackBlock>
);

export default Checkboxes;
