import { types, Instance } from 'mobx-state-tree';

import convertLayoutTags from './convert-layout-tags';

const BackgroundModel = types.model('BackgroundModel', {
  id: types.identifier,
  name: types.maybe(types.string),
  tags: types.array(types.string),
  origin: types.enumeration(['unknown', 'library', 'api']),
  extension: types.maybe(types.string),
  printLeft: types.maybe(types.string),
  printRight: types.maybe(types.string),
  previewLeft: types.maybe(types.string),
  previewRight: types.maybe(types.string)
});

export const createBackgroundModel = (data?: any) => {
  if (!data) {
    return undefined;
  }

  const origin = data.id ? 'api' : data.key ? 'library' : 'unknown';
  const id = !data.id ? data.key : data.id.toString();

  if (!id) {
    return undefined;
  }

  return BackgroundModel.create({
    id,
    name: data.name?.de || data.name || undefined,
    origin,
    tags: convertLayoutTags(data.tags),
    extension: data.extension || undefined
    // TODO pictures
  });
};

export type BackgroundModelType = Instance<typeof BackgroundModel>;
export default BackgroundModel;
